import React, {Fragment, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentCertificate} from "../../../store/reducers/eimzo/eimzoReducer";
import {PermissionRoamingEmpowermentReject} from "../../../enum/permission/RoamingPermission";
import checkPermission from "../../../enum/permission/CheckPermission";
import toastPermission from "../../../enum/permission/ToastPermission";
import {FormProvider, useForm} from "react-hook-form";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import {personDeclineEmpowermentAsync} from "../../../store/reducers/roaming/empowerment/empowermentReducer";
import {faBan} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../IconButton";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import SignActionWithSelectCertificateWrapper from "../../common/SignActionWithSelectCertificateWrapper";
import IconFolderCancel from "../../../assets/icon/IconFolderCancel";

const PersonDeclineEmpowerment = ({id, ...props}) => {
    const dispatch = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const methods = useForm({
        defaultValues: {
            comment: '',
        }
    });
    const {register, handleSubmit, reset, formState: {errors}} = methods
    const currentCertificate = useSelector(selectCurrentCertificate);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        if (dispatch(checkPermission(PermissionRoamingEmpowermentReject))) setShow(true)
        else dispatch(toastPermission())
    };

    const personDeclineEmpowermentHandler = (formData) => {
        if(currentCertificate?.keyId) {
            setLoading(true);

            personDeclineEmpowermentAsync(currentCertificate, {id, comment: formData.comment}, lang)
                .then(response => {
                    handleClose();
                    setLoading(false);
                    reset();
                })
                .catch(errors => {
                    setLoading(false)
                })
        }
    };

    return (
        <Fragment>
            <IconButton
                onClick={handleShow}
                variant={'phoenix-secondary'}
                size="sm"
                iconClassName="me-1"
                icon={""}
                className="btn-davr px-3 py-0 bg-white d-flex gap-2"
                {...props}
            >
                <IconFolderCancel />
                {t(lang, 'roaming.empowerment.decline_button')}
            </IconButton>

            <Modal show={show} onHide={handleClose}>
                <FormProvider {...methods}>
                    <Form onSubmit={handleSubmit(personDeclineEmpowermentHandler)} id="PersonDeclineEmpowerment">
                        <Modal.Header closeButton>
                            <Modal.Title>{t(lang, "roaming.empowerment.decline_modal.title")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>{t(lang, "roaming.common.modal.comment")}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder={t(lang, "roaming.common.modal.comment.placeholder")}
                                    isInvalid={errors?.comment}
                                    {...register('comment', {
                                        required: t(lang, 'roaming.validations.is_required'),
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">{errors?.comment?.message}</Form.Control.Feedback>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                {t(lang, "roaming.empowerment.cancel_button")}
                            </Button>
                            <SignActionWithSelectCertificateWrapper isForm>
                                <Button
                                    variant="danger"
                                    className="d-flex align-items-center"
                                    type="submit"
                                    disabled={loading}
                                    form="PersonDeclineEmpowerment"
                                >
                                    {loading && <Spinner className="me-2" animation="border" role="status" size="sm"/>}
                                    {t(lang, 'roaming.empowerment.decline_button')}
                                </Button>
                            </SignActionWithSelectCertificateWrapper>
                        </Modal.Footer>
                    </Form>
                </FormProvider>
            </Modal>

        </Fragment>
    );
};

export default PersonDeclineEmpowerment;
