import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {Translate} from "../../../lang/lang";
import {FormProvider, useForm} from "react-hook-form";
import {getOrganizationInfoAsync} from "../../../store/reducers/roaming/roamingReducer";
import dayjs from "dayjs";
import {sendFreeFormDocumentAsync} from "../../../store/reducers/roaming/free-form/freeFormDocumentReducer";
import toast from "react-hot-toast";
import {Form} from "react-bootstrap";
import FreeFormDocumentForm from "./FreeFormDocumentForm";

const SendFreeFormDocumentForm = () => {
    const [loading, setLoading] = useState(false);
    const activeOrganization = useSelector(selectActiveOrganization);
    const lang = useSelector(selectLang);
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            freeform: {number: '', date: null},
            receiver: {inn: '', name: ''},
            sender: {inn: '', name: ''},
            file: '',
        }
    });

    useEffect(() => {
        getOrganizationInfoAsync(activeOrganization.inn)
            .then(company => {
                methods.setValue('sender.name', company?.name || '');
                methods.setValue('sender.inn', company?.inn || '');
            })
            .catch(error => {
                methods.setValue('sender.name', '');
                methods.setValue('sender.inn', '');
                console.log(error);
            })
    }, [activeOrganization]);

    const onSubmit = (formData) => {
        setLoading(true);

        sendFreeFormDocumentAsync({
            info: {
                number: formData.freeform.number,
                date: dayjs(formData.freeform.date).format("YYYY-MM-DD"),
            },
            receiver: {
                inn: formData.receiver.inn,
                name: formData.receiver.name,
            },
            file_content: formData.file.split("base64,")[1],
        })
            .then(() => {
                toast.success(t(lang, 'roaming.free_form_document.save.toast.success'));
                setLoading(false);
            })
            .catch(() => {
                toast.error(t(lang, 'roaming.free_form_document.save.toast.error'));
                setLoading(false);
            })
    }

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <FreeFormDocumentForm loading={loading} />
            </Form>
        </FormProvider>
    );
};

export default SendFreeFormDocumentForm;
