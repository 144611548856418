import React, {Fragment, useEffect, useState} from 'react';
import {DefineByExecutor} from "../../../../order/OrderShipmentDateMethodStrategyType";
import {useDispatch, useSelector} from "react-redux";
import {selectDateFormat, selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {
    createSigner,
    deleteSigner,
    loadSigners
} from "../../../../store/reducers/edi/return-invoice/returnInvoiceReducer";
import dayjs from "dayjs";
import AddEdiContractForm from "../../../contract/forms/edi/AddEdiContractForm";
import EditContract from "../../contract/EditContract";
import {Button, Col, Row, Spinner, Form, ButtonGroup} from "react-bootstrap";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import SelectContract from "../../../common/SelectContract";
import IconButton from "../../../IconButton";
import {faPencil, faPlus} from "@fortawesome/free-solid-svg-icons";
import SelectInvoiceModal from "../SelectInvoiceModal";
import ReturnInvoiceMember from "./ReturnInvoiceMember";
import ReturnInvoiceItems from "../ReturnInvoiceItems";
import PropTypes from "prop-types";
import SelectDirector from "../../signare/SelectDirector";
import SelectAccountant from "../../signare/SelectAccountant";
import SelectReleaser from "../../signare/SelectReleaser";

const ReturnInvoiceForm = ({loading, productMapNames, existingNumber}) => {

    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const dateFormat = useSelector(selectDateFormat);
    const {register, formState: {errors}, getValues, setValue} = useFormContext();
    const contractDefineStrategy = getValues('strategies.contract_define_method_strategy');
    const allowEditContract = contractDefineStrategy === DefineByExecutor.id;
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const contract = useWatch({name: 'contractInfo'});

    useEffect(() => {
        dispatch(loadSigners());
    }, []);

    const addModalClose = () => setShowAddModal(false);
    const addModalShow = () => setShowAddModal(true);
    const editModalClose = () => setShowEditModal(false);
    const editModalShow = () => setShowEditModal(true);

    const onContractSelected = (contract) => {
        if (contract) {
            setValue('contractInfo.date', dayjs(contract.info.date).toDate());
            setValue('contractInfo.number', contract.info.number);
            setValue('contractInfo.id', contract.id);
        } else {
            setValue('contractInfo.date', null);
            setValue('contractInfo.number', null);
            setValue('contractInfo.id', null);
        }
    };

    return (
        <Fragment>
            <AddEdiContractForm show={showAddModal} onClose={addModalClose}/>
            {showEditModal && <EditContract id={contract.id} show={showEditModal} onClose={editModalClose}/>}


            <Row className={'align-items-center mb-2'}>
                <Col>
                    <h5>{t(lang, "edi.common.invoice")}</h5>
                </Col>
                <Col xs={"auto"} className="text-center">
                    <Button type="submit" disabled={loading}>
                        {loading &&
                            <Spinner className="align-middle me-2" animation="border" role="switch"
                                     size="sm"/>}
                        {t(lang, "edi.common.save_button")}
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={6} className={'pe-1'}>
                    <div className="bg-200 p-2 h-100">
                        <Row>
                            <Col xs={12} lg={6} sm={6} className="mb-2">
                                <Form.Label>
                                    {t(lang, "edi.common.invoice.number")}
                                    <span className={'text-danger'}>*</span>
                                </Form.Label>
                                <Form.Control name="returnInvoiceInfo.number"
                                              type="text"
                                              placeholder={t(lang, "edi.common.invoice.number.placeholder")}
                                              isInvalid={errors?.returnInvoiceInfo?.number}
                                              isValid={Object.keys(errors).length > 0 && !errors?.returnInvoiceInfo?.number}
                                              {...register('returnInvoiceInfo.number', {
                                                  required: t(lang, "edi.common.forms.validations.is_required"),
                                                  validate: (value) => {
                                                      if (existingNumber) {
                                                          if (value === existingNumber)
                                                              return t(lang, "edi.common.forms.validations.is_exists", {name: t(lang, "edi.common.invoice.number")});
                                                      }
                                                      return true;
                                                  }
                                              })}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{errors?.returnInvoiceInfo?.number?.message}</Form.Control.Feedback>
                            </Col>
                            <Col xs={12} lg={6} sm={6} className="mb-2">
                                <Form.Label>
                                    {t(lang, "edi.common.invoice.date")}
                                    <span className={'text-danger'}>*</span>
                                </Form.Label>
                                <Controller name={'returnInvoiceInfo.date'}
                                            rules={{
                                                required: t(lang, "edi.common.forms.validations.is_required")
                                            }}
                                            render={({field}) => (
                                                <DatePicker
                                                    placeholderText={t(lang, "edi.common.invoice.date.placeholder")}
                                                    selected={field.value}
                                                    onChange={field.onChange}
                                                    dateFormat={dateFormat}
                                                    wrapperClassName={classNames({
                                                        'is-invalid': errors?.returnInvoiceInfo?.date,
                                                    })}
                                                    className={classNames('form-control', {
                                                        'is-invalid': errors?.returnInvoiceInfo?.date,
                                                        'is-valid': Object.keys(errors).length > 0 && !errors?.returnInvoiceInfo?.date
                                                    })}
                                                />
                                            )}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{errors?.returnInvoiceInfo?.date?.message}</Form.Control.Feedback>
                            </Col>
                            <Col xs={12} lg={6} sm={6} className="mb-2">
                                <Form.Label>
                                    {t(lang, "edi.common.shipment.number")}
                                    <span className={'text-danger'}>*</span>
                                </Form.Label>
                                <Form.Control name="returnShipmentInfo.number"
                                              type="text"
                                              placeholder={t(lang, "edi.common.shipment.number.placeholder")}
                                              disabled
                                              isInvalid={errors?.returnShipmentInfo?.number}
                                              isValid={Object.keys(errors).length > 0 && !errors?.returnShipmentInfo?.number}
                                              {...register('returnShipmentInfo.number', {
                                                  required: t(lang, "edi.common.forms.validations.is_required")
                                              })}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{errors?.returnShipmentInfo?.number?.message}</Form.Control.Feedback>
                            </Col>
                            <Col xs={12} lg={6} sm={6} className="mb-2">
                                <Form.Label>
                                    {t(lang, "edi.common.shipment.date")}
                                    <span className={'text-danger'}>*</span>
                                </Form.Label>
                                <Controller name={'returnShipmentInfo.date'}
                                            rules={{
                                                required: t(lang, "edi.common.forms.validations.is_required")
                                            }}
                                            render={({field}) => (
                                                <DatePicker
                                                    placeholderText={t(lang, "edi.common.invoice.date.placeholder")}
                                                    selected={field.value}
                                                    onChange={field.onChange}
                                                    dateFormat={dateFormat}
                                                    disabled
                                                    wrapperClassName={classNames({
                                                        'is-invalid': errors?.returnShipmentInfo?.date,
                                                    })}
                                                    className={classNames('form-control', {
                                                        'is-invalid': errors?.returnShipmentInfo?.date,
                                                        'is-valid': Object.keys(errors).length > 0 && !errors?.returnShipmentInfo?.date
                                                    })}
                                                />
                                            )}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{errors?.returnShipmentInfo?.date?.message}</Form.Control.Feedback>
                            </Col>

                            {!allowEditContract &&
                                <Fragment>
                                    <Col xs={12} lg={6} sm={6} className="mb-2">
                                        <Form.Label>
                                            {t(lang, "edi.common.contract.number")}
                                            <span className={'text-danger'}>*</span>
                                        </Form.Label>
                                        <Form.Control name="contractInfo.number"
                                                      placeholder={t(lang, "edi.common.contract.number.placeholder")}
                                                      type="text"
                                                      isInvalid={errors?.contractInfo?.number}
                                                      isValid={Object.keys(errors).length > 0 && !errors?.contractInfo?.number}
                                                      {...register('contractInfo.number', {
                                                          required: t(lang, "edi.common.forms.validations.is_required")
                                                      })}
                                                      disabled
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors?.contractInfo?.number?.message}</Form.Control.Feedback>
                                    </Col>
                                    <Col xs={12} lg={6} sm={6} className="mb-2">
                                        <Form.Label>
                                            {t(lang, "edi.common.contract.date")}
                                            <span className={'text-danger'}>*</span>
                                        </Form.Label>
                                        <Controller name={'contractInfo.date'}
                                                    rules={{
                                                        required: t(lang, "edi.common.forms.validations.is_required")
                                                    }}
                                                    render={({field}) => (
                                                        <DatePicker
                                                            placeholderText={t(lang, "edi.common.invoice.date.placeholder")}
                                                            selected={field.value}
                                                            onChange={field.onChange}
                                                            dateFormat={dateFormat}
                                                            wrapperClassName={classNames({
                                                                'is-invalid': errors?.contractInfo?.date,
                                                            })}
                                                            className={classNames('form-control', {
                                                                'is-invalid': errors?.contractInfo?.date,
                                                                'is-valid': Object.keys(errors).length > 0 && !errors?.contractInfo?.date
                                                            })}
                                                            disabled
                                                        />
                                                    )}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors?.contractInfo?.date?.message}</Form.Control.Feedback>
                                    </Col>
                                </Fragment>
                            }

                            {allowEditContract &&
                                <Col xs={12}>
                                    <Form.Label>
                                        {t(lang, "edi.common.contract")}
                                        <span className={'text-danger'}>*</span>
                                    </Form.Label>
                                    <div className="d-flex">
                                        <Controller name={'contractInfo.number'}
                                                    rules={{
                                                        required: t(lang, "edi.common.forms.validations.is_required")
                                                    }}
                                                    render={({field}) => (
                                                        <SelectContract
                                                            defaultValue={field.value}
                                                            classNamePrefix="react-select rounded-end-0 border-end"
                                                            placeholder={t(lang, "edi.common.select_button")}
                                                            className={classNames('w-100', {
                                                                'is-invalid': !!errors?.contractInfo?.number
                                                            })}
                                                            wrapperClassName={classNames({
                                                                'is-invalid': !!errors?.contractInfo?.number
                                                            })}
                                                            onChange={contract => {
                                                                onContractSelected(contract);
                                                            }}
                                                        />
                                                    )}
                                        />
                                        <ButtonGroup>
                                            {contract?.id &&
                                                <IconButton className="rounded-start-0"
                                                            onClick={editModalShow}
                                                            icon={faPencil}
                                                            variant="primary"/>}
                                            <IconButton className="rounded-start-0"
                                                        onClick={addModalShow}
                                                        icon={faPlus}
                                                        variant="warning"/>
                                        </ButtonGroup>
                                    </div>
                                    <Form.Control.Feedback
                                        type="invalid">{errors?.contractInfo?.number?.message}</Form.Control.Feedback>
                                </Col>
                            }

                            <Col xs={12} lg={allowEditContract ? 6 : 12} sm={!allowEditContract ? 12 : 6}
                                 className="mb-2">
                                <Form.Label>
                                    {t(lang, "edi.common.shipping_address")}
                                </Form.Label>
                                <Form.Control name="loadingAddress"
                                              type="text"
                                              placeholder={t(lang, "edi.common.shipping_address.placeholder")}
                                              isValid={Object.keys(errors).length > 0 && !errors?.loadingAddress}
                                              {...register('loadingAddress')}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>

                <Col xs={12} md={6} className={'ps-1'}>
                    <div className="bg-200 p-2 h-100">
                        <Row className="d-flex flex-lg-column flex-row">
                            <Col md={12} className="mb-2">
                                <Form.Label>
                                    {t(lang, "edi.return_invoice.new_return_invoice.invoice.number")}
                                    <span className={'text-danger'}>*</span>
                                </Form.Label>
                                <Form.Control name="invoiceInfo.number"
                                              type="text"
                                              placeholder={t(lang, "edi.return_invoice.new_return_invoice.invoice.number.placeholder")}
                                              disabled
                                              isInvalid={errors?.invoiceInfo?.number}
                                              isValid={Object.keys(errors).length > 0 && !errors?.invoiceInfo?.number}
                                              {...register('invoiceInfo.number', {
                                                  required: t(lang, "edi.common.forms.validations.is_required")
                                              })}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{errors?.invoiceInfo?.number?.message}</Form.Control.Feedback>
                            </Col>
                            <Col md={12} className="mb-2">
                                <Form.Label>
                                    {t(lang, "edi.return_invoice.new_return_invoice.invoice.date")}
                                    <span className={'text-danger'}>*</span>
                                </Form.Label>
                                <Controller name={'invoiceInfo.date'}
                                            rules={{
                                                required: t(lang, "edi.common.forms.validations.is_required")
                                            }}
                                            render={({field}) => (
                                                <DatePicker
                                                    placeholderText={t(lang, "edi.common.invoice.date.placeholder")}
                                                    selected={field.value}
                                                    onChange={field.onChange}
                                                    dateFormat={dateFormat}
                                                    disabled
                                                    wrapperClassName={classNames({
                                                        'is-invalid': errors?.invoiceInfo?.date,
                                                    })}
                                                    className={classNames('form-control', {
                                                        'is-invalid': errors?.invoiceInfo?.date,
                                                        'is-valid': Object.keys(errors).length > 0 && !errors?.invoiceInfo?.date
                                                    })}
                                                />
                                            )}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.invoiceInfo?.date?.message}
                                </Form.Control.Feedback>
                            </Col>
                            <Col md={12} className="d-grid my-2 d-lg-flex align-items-end">
                                <SelectInvoiceModal/>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <Row >
                <Col xs={12} md={6} className={'pe-1'}>
                    <h5 className={'my-2'}>{t(lang, "edi.common.customer")}</h5>
                    <div className="bg-200 p-2">
                        <Row>
                            <ReturnInvoiceMember rootName={'customer'}/>
                        </Row>
                    </div>
                </Col>

                <Col xs={12} md={6} className={'ps-1'}>
                    <h5 className={'my-2'}>{t(lang, "edi.common.executor")}</h5>
                    <div className="bg-200 p-2">
                        <Row>
                            <ReturnInvoiceMember rootName={'executor'}/>
                        </Row>
                    </div>
                </Col>
            </Row>

            <ReturnInvoiceItems productMapNames={productMapNames}/>

            <Row>
                <h5 className={'my-2'}>{t(lang, "edi.common.signers")}</h5>
                <Col xs={12} md={6} className={'pe-1'}>
                    <div className="bg-200 p-2">
                        <Row>
                            <Col xs={12} className="mb-2">
                                <Form.Label>{t(lang, "edi.common.director")}</Form.Label>
                                <Controller name={"director"}
                                            render={({field}) => (
                                                <SelectDirector
                                                    onCreate={(director) => {
                                                        dispatch(createSigner(director));
                                                        field.onChange(director.label);
                                                    }}
                                                    onDelete={deleteSigner}
                                                />
                                            )}
                                />
                            </Col>
                            <Col xs={12} className="mb-2">
                                <Form.Label>{t(lang, "edi.common.accountant")}</Form.Label>
                                <Controller name={"accountant"}
                                            render={({field}) => (
                                                <SelectAccountant
                                                    onCreate={(accountant) => {
                                                        dispatch(createSigner(accountant));
                                                        field.onChange(accountant.label);
                                                    }}
                                                    onDelete={deleteSigner}
                                                />
                                            )}
                                />
                            </Col>
                            <Col xs={12}>
                                <Form.Label>{t(lang, "edi.common.releaser")}</Form.Label>
                                <Controller name={"releaser"}
                                            render={({field}) => (
                                                <SelectReleaser
                                                    onCreate={(releaser) => {
                                                        dispatch(createSigner(releaser));
                                                        field.onChange(releaser.label);
                                                    }}
                                                    onDelete={deleteSigner}
                                                />
                                            )}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col cs={12} md={6} className={'ps-1'}>
                    <div className="bg-200 p-2">
                        <Row>
                            <Col xs={12} className="mb-2">
                                <Form.Label>{t(lang, "edi.common.shipper")}</Form.Label>
                                <Form.Control
                                    placeholder={t(lang, "edi.common.shipper.placeholder")}
                                    type="text"
                                    isValid={Object.keys(errors).length > 0 && !errors?.proxyInfo?.name}
                                    {...register('proxyInfo.name')}
                                />
                            </Col>
                            <Col xs={12} className="mb-2">
                                <Form.Label>{t(lang, "edi.common.attorney.number")}</Form.Label>
                                <Form.Control
                                    placeholder={t(lang, "edi.common.attorney.number.placeholder")}
                                    type="text"
                                    isValid={Object.keys(errors).length > 0 && !errors?.proxyInfo?.number}
                                    {...register('proxyInfo.number')}
                                />
                            </Col>
                            <Col xs={12}>
                                <Form.Label>{t(lang, "edi.common.attorney.date")}</Form.Label>
                                <Controller name="proxyInfo.date"
                                            render={
                                                ({field}) => (
                                                    <DatePicker
                                                        placeholderText={t(lang, "edi.common.attorney.date.placeholder")}
                                                        selected={field.value}
                                                        onChange={field.onChange}
                                                        dateFormat="dd.MM.yyyy"
                                                        className={classNames('form-control', {
                                                            'is-valid': Object.keys(errors).length > 0
                                                        })}
                                                    />
                                                )
                                            }
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <Row className={'mt-2'}>
                <Col className="text-end">
                    <Button type="submit" disabled={loading}>
                        {loading &&
                            <Spinner className="align-middle me-2" animation="border" role="switch"
                                     size="sm"/>}
                        {t(lang, "edi.common.save_button")}
                    </Button>
                </Col>
            </Row>

        </Fragment>
    );
};
ReturnInvoiceForm.propTypes = {
    loading: PropTypes.bool,
    existingNumber: PropTypes.string
};

ReturnInvoiceForm.defaultProps = {
    loading: false,
    existingNumber: ''
}
export default ReturnInvoiceForm;
