import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {Col, Row, Form, Card} from "react-bootstrap";
import RoamingInvoiceFormSingleSideTypeSelector from "./RoamingInvoiceFormSingleSideTypeSelector";
import {loadExchangeTypes} from "../../../store/reducers/roaming/roamingReferenceReducer";
import RoamingInvoiceFormMember from "./RoamingInvoiceFormMember";
import RoamingInvoiceFormForeignCompanyInfo from "./RoamingInvoiceFormForeignCompanyInfo";
import RoamingInvoiceFormExchangeInfo from "./RoamingInvoiceFormExchangeInfo";

const fieldName = 'buyer';

const RoamingInvoiceFormCardCustomer = ({isEditOrCopy}) => {
    const dispatch = useDispatch();
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();
    const isSingleSided = useWatch({name: `isSingleSided`, exact: true});
    const hasInvestmentObject = useWatch({name: `hasInvestmentObject`, exact: true});
    const hasExchange = useWatch({name: `hasExchange`, exact: true});
    const [company, setCompany] = useState(null);
    const [eventCount, setEventCount] = useState(0);

    useEffect(() => {
        isSingleSided && setCompany(null);
    }, [isSingleSided])

    useEffect(() => {
        hasExchange && dispatch(loadExchangeTypes());
    }, [hasExchange])

    const onChangeCompany = async (company) => {
        setCompany(company);

        // if is copy or edit, prevent from conflict of values
        {
            if (isEditOrCopy && eventCount === 0) {
                setEventCount(eventCount + 1);
                return;
            }
        }

        if (company) {
            form.setValue(`${fieldName}.name`, company.short_name || company.name);
            form.setValue(`${fieldName}.account`, company.account || (company.bank_accounts?.length > 0 ? company.bank_accounts[0].account : ''))
            form.setValue(`${fieldName}.bankId`, company.mfo || (company.bank_accounts?.length > 0 ? company.bank_accounts[0].bankId : ''))
            form.setValue(`${fieldName}.address`, company.address);
            form.setValue(`${fieldName}.mobile`, '');
            form.setValue(`${fieldName}.workPhone`, '');
            form.setValue(`${fieldName}.oked`, company.oked);
            form.setValue(`${fieldName}.districtId`, company.district_id);
            form.setValue(`${fieldName}.director`, company.director);
            form.setValue(`${fieldName}.accountant`, company.accountant);
            form.setValue(`${fieldName}.vatRegCode`, company.vat_reg_code);
            form.setValue(`${fieldName}.vatRegStatus`, company.vat_rate_status);
            form.setValue(`${fieldName}.branch`, null);
        } else {
            form.setValue(`${fieldName}.name`, '');
            form.setValue(`${fieldName}.account`, '')
            form.setValue(`${fieldName}.bankId`, '')
            form.setValue(`${fieldName}.address`, '');
            form.setValue(`${fieldName}.mobile`, '');
            form.setValue(`${fieldName}.workPhone`, '');
            form.setValue(`${fieldName}.oked`, '');
            form.setValue(`${fieldName}.districtId`, null);
            form.setValue(`${fieldName}.director`, '');
            form.setValue(`${fieldName}.accountant`, '');
            form.setValue(`${fieldName}.vatRegCode`, '');
            form.setValue(`${fieldName}.vatRegStatus`, '');
            form.setValue(`${fieldName}.branch`, null);
        }
    };

    return (
        <Row>
            <Col xs={12} className="d-flex flex-wrap justify-content-between">
                {!isSingleSided &&
                    <Fragment>

                        <Form.Group className="switch-container mb-2 gap-2 align-items-center">
                            <Form.Check.Label
                                htmlFor={"switch-1"}
                                className="mb-0 switch-label">{t(lang, "roaming.invoice.exchange")}</Form.Check.Label>
                            <label className="switch">
                                <Form.Check type="switch" id={"switch-1"} className="d-flex align-items-center">
                                    <Form.Check.Input
                                        disabled={!!hasInvestmentObject}
                                        {...form.register(`hasExchange`, {
                                            shouldUnregister: true
                                        })}
                                        type="checkbox"/>
                                    <span className="slider"></span>
                                </Form.Check>
                            </label>
                        </Form.Group>

                        {!!company?.is_investment_object_company &&
                            <Form.Group className="switch-container mb-2 gap-2 align-items-center">
                                <Form.Check.Label
                                    htmlFor={"switch-2"}
                                    className="mb-0 switch-label">{t(lang, "roaming.invoice.investment_object")}</Form.Check.Label>
                                <label className="switch">
                                    <Form.Check type="switch" id={"switch-2"} className="d-flex align-items-center">
                                        <Form.Check.Input {...form.register(`hasInvestmentObject`, {
                                            shouldUnregister: true
                                        })} type="checkbox"/>
                                        <span className="slider"></span>
                                    </Form.Check>
                                </label>
                            </Form.Group>
                        }
                    </Fragment>
                }

                <Form.Group className="switch-container mb-2 gap-2 align-items-center">
                    <Form.Check.Label
                        htmlFor={"switch-3"}
                        className="mb-0 switch-label">{t(lang, "roaming.invoice.singleSided")}</Form.Check.Label>
                    <label className="switch">
                        <Form.Check type="switch" id={"switch-3"} className="d-flex align-items-center">
                            <Form.Check.Input {...form.register(`isSingleSided`, {
                                shouldUnregister: true
                            })} type="checkbox"/>
                            <span className="slider"></span>
                        </Form.Check>
                    </label>
                </Form.Group>

            </Col>
            <Col xs={12}>
                {isSingleSided ? (
                    <Fragment>
                        <Card className={'p-2 rounded-4 border-0'}>
                            <Form.Group className="mb-2">
                                <Controller
                                    name="singleSidedType"
                                    render={({field}) => (
                                        <RoamingInvoiceFormSingleSideTypeSelector
                                            id={'singleSidedType'}
                                            placeholder={t(lang, 'roaming.invoice.single_member_invoice_type')}
                                            defaultType={field.value}
                                            onChange={field.onChange}/>
                                    )}
                                />
                            </Form.Group>
                            <RoamingInvoiceFormForeignCompanyInfo/>
                        </Card>

                    </Fragment>
                ) : (
                    <Fragment>
                        <Card className={'p-2 rounded-4 border-0'}>
                            <RoamingInvoiceFormMember
                                fieldName={fieldName}
                                isSearchable={true}
                                onChangeCompany={onChangeCompany}
                                company={company}
                            />
                            {/*{hasInvestmentObject && <RoamingInvoiceFormInvestmentInfo />}*/}
                            {hasExchange && <RoamingInvoiceFormExchangeInfo/>}
                        </Card>

                    </Fragment>
                )}
            </Col>
        </Row>
    );
};

export default RoamingInvoiceFormCardCustomer;
