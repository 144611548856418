import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {selectLang, updateShowInProgressAsync} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {selectAccountRolePermissions} from "../../../store/reducers/role/roleReducer";
import checkPermission from "../../../enum/permission/CheckPermission";
import {PermissionRoamingContractCreate} from "../../../enum/permission/RoamingPermission";
import toast from "react-hot-toast";
import {getOrganizationBillingInfoAsync} from "../../../store/reducers/billing/billingReducer";
import EventBus from "../../../eventbus/EventBus";
import {DOCUMENT_SIGN_FAILED, DOCUMENT_SIGN_STARTED, DOCUMENT_SIGN_SUCCESS} from "../../../eventbus/romaing/signEvents";
import ContractForm from "../../../components/roaming/contracts/form/ContractForm";

const SendContract = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const lang = useSelector(selectLang)
    const t = Translate
    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingContractCreate))) {
            navigate('/roaming/contracts/inbox')
        }
    }, [permission])

    useEffect(() => {
        const signStartedHandler = EventBus.on(DOCUMENT_SIGN_STARTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })
        const signSuccessHandler = EventBus.on(DOCUMENT_SIGN_SUCCESS, () => {
            toast.success(t(lang, "edi.document.sign.notification.success"))
            dispatch(getOrganizationBillingInfoAsync());
            dispatch(updateShowInProgressAsync(false))
        });
        const signFailedHandler = EventBus.on(DOCUMENT_SIGN_FAILED, (data) => {
            toast.error(t(lang, data.message))
            dispatch(updateShowInProgressAsync(false))
        });

        return () => {
            EventBus.remove(DOCUMENT_SIGN_STARTED, signStartedHandler)
            EventBus.remove(DOCUMENT_SIGN_SUCCESS, signSuccessHandler)
            EventBus.remove(DOCUMENT_SIGN_FAILED, signFailedHandler)
        }
    }, [])

    return (
        <ContractForm />
    );
};

export default SendContract;
