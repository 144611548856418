import React from 'react';
import {Navbar} from 'react-bootstrap'
import NavbarBrand from "../NavbarBrand";
import NavbarTopNav from "./NavbarTopNav";
import NavbarTopRightSide from "./NavbarTopRightSide";
import {useAppContext} from "../../../pheonix/providers/AppProvider";
import {useBreakpoints} from "../../../pheonix/providers/BreakpointsProvider";
import classNames from "classnames";

const NavbarTopHorizontal = () => {

    const {
        config: {
            navbarPosition,
            openNavbarVertical,
            navbarTopShape,
            navbarTopAppearance
        }
    } = useAppContext();

    const { breakpoints } = useBreakpoints();


    return (
        <Navbar
            className={classNames('navbar-top fixed-top', {
                'navbar-slim': navbarTopShape === 'slim',
                'navbar-darker': navbarTopAppearance === 'darker',
            })}
            expand="lg"
            variant=""
        >
            <NavbarBrand />
            {!(navbarPosition === 'combo' && breakpoints.down('lg')) && (
                <Navbar.Collapse
                    className="navbar-top-collapse order-1 order-lg-0 justify-content-start pb-0"
                    in={openNavbarVertical}
                >
                    <NavbarTopNav />
                </Navbar.Collapse>
            )}
            <NavbarTopRightSide />
        </Navbar>
    );
};

export default NavbarTopHorizontal;
