import React, {Fragment, useState} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {selectCurrentCertificate} from "../../../store/reducers/eimzo/eimzoReducer";
import {customerCancelEmpowermentAsync} from "../../../store/reducers/roaming/empowerment/empowermentReducer";
import IconButton from "../../IconButton";
import {faBan} from "@fortawesome/free-solid-svg-icons";
import {Button, Modal, Spinner} from "react-bootstrap";
import SignActionWithSelectCertificateWrapper from "../../common/SignActionWithSelectCertificateWrapper";
import PropTypes from "prop-types";
import IconFolderCancel from "../../../assets/icon/IconFolderCancel";
const CustomerCancelEmpowerment = ({id, ...props}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const currentCertificate = useSelector(selectCurrentCertificate);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const customerCancelEmpowermentHandler = () => {
        setLoading(true);
        customerCancelEmpowermentAsync(currentCertificate, id, lang)
            .then(response => {
                handleClose();
                setLoading(false);
            })
            .catch(errors => {
                setLoading(false)
            })
    };

    return (
        <Fragment>
            <IconButton
                onClick={handleShow}
                variant={'phoenix-secondary'}
                size="sm"
                iconClassName="me-1"
                icon={""}
                className="btn-davr px-3 py-0 bg-white d-flex gap-2"
                {...props}
            >
                <IconFolderCancel />
                {t(lang, 'roaming.empowerment.customer_cancel_button')}
            </IconButton>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, "roaming.empowerment.customer_cancel_modal.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t(lang, "roaming.empowerment.customer_cancel_modal.body")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(lang, "roaming.empowerment.cancel_button")}
                    </Button>
                    <SignActionWithSelectCertificateWrapper>
                        <Button
                            variant="primary"
                            className="d-flex align-items-center"
                            onClick={customerCancelEmpowermentHandler}
                            disabled={loading}
                        >
                            {loading && <Spinner className="me-2" animation="border" role="status" size="sm"/>}
                            {t(lang, 'roaming.empowerment.customer_cancel_button')}
                        </Button>
                    </SignActionWithSelectCertificateWrapper>
                </Modal.Footer>
            </Modal>

        </Fragment>
    );
};

CustomerCancelEmpowerment.propTypes = {
    id: PropTypes.string,
};
CustomerCancelEmpowerment.defaultProps = {
    id: "",
};
export default CustomerCancelEmpowerment;
