import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {selectCurrentCertificate} from "../../../../store/reducers/eimzo/eimzoReducer";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {signFreeFormDocumentAsync} from "../../../../store/reducers/roaming/free-form/freeFormDocumentReducer";
import SignActionWithSelectCertificateWrapper from "../../../common/SignActionWithSelectCertificateWrapper";
import IconButton from "../../../IconButton";
import {Spinner} from "react-bootstrap";
import IconSignature from "../../../../assets/icon/IconSignature";

const SignFreeFormDocument = ({id, ...props}) => {
    const [loading, setLoading] = useState(false);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const lang = useSelector(selectLang);
    const t = Translate;

    const signFreeFormDocument = () => {
        setLoading(true);
        signFreeFormDocumentAsync({
            id: id,
            certificate: currentCertificate,
        })
            .finally(() => setLoading(false))
    }
    return (
        <SignActionWithSelectCertificateWrapper>
            <IconButton
                size="sm"
                {...props}
                variant="phoenix-warning"
                onClick={signFreeFormDocument}
                className={'btn-davr bg-primary-davr text-white d-flex gap-2'}
            >
                <IconSignature />
                {t(lang, 'roaming.free_form_document.view.action.sign')}
                {loading && <Spinner className='align-middle ms-2' animation='border' role='switch' size='sm'/>}
            </IconButton>
        </SignActionWithSelectCertificateWrapper>
    );
};

export default SignFreeFormDocument;
