import React from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {Translate} from "../../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import classNames from "classnames";
import ActionButton from "../../../common/ActionButton";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import SelectCompanyCatalog from "../../../IKPU/SelectCompanyCatalog";
import SelectMeasurement from "../../../common/SelectMeasurement";
import {Form} from "react-bootstrap";
import Badge from "../../../common/Badge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const EmpowermentItem = ({root, index, allowDelete, onDeleteClicked}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const {register, formState: {errors}, setValue, control} = useFormContext();


    const itemRootName = `${root}.${index}`;
    let itemErrors = {};

    if (errors && errors[root] && errors[root][index])
        itemErrors = errors[root][index];


    const onChangeCatalog = (catalog) => {
        setValue(`${root}.${index}.catalogClassName`, catalog?.name);
    };

    return (
        <tr>
            <td>
                {index + 1}
            </td>
            <td>
                <Form.Control name={`${itemRootName}.product`}
                              placeholder={t(lang, "")}
                              type="text"
                              isInvalid={itemErrors?.product}
                              isValid={Object.keys(errors).length > 0 && !itemErrors?.product}
                              {...register(`${itemRootName}.product`, {
                                  required: t(lang, 'roaming.validations.is_required'),
                              })}
                />
                <Form.Control.Feedback type="invalid">{itemErrors?.product?.message}</Form.Control.Feedback>
            </td>
            <td>
                <Form.Control name={`${itemRootName}.quantity`}
                              placeholder={t(lang, "")}
                              type="text"
                              isInvalid={itemErrors?.quantity}
                              isValid={Object.keys(errors).length > 0 && !itemErrors?.quantity}
                              {...register(`${itemRootName}.quantity`, {
                                  required: t(lang, 'roaming.validations.is_required'),
                              })}
                />
                <Form.Control.Feedback type="invalid">{itemErrors?.quantity?.message}</Form.Control.Feedback>
            </td>
            <td>
                <Controller name={`${itemRootName}.measurementId`}
                            control={control}
                            rules={{
                                required: t(lang, 'roaming.validations.is_required'),
                            }}
                            render={({field}) => (
                                <SelectMeasurement
                                    id={`${itemRootName}.measurementId`}
                                    menuPortalTarget={document.body}
                                    className={classNames({
                                        "is-invalid": itemErrors?.measurementId,
                                        "is-valid": Object.keys(errors).length > 0 && !itemErrors?.measurementId
                                    })}
                                    placeholder={t(lang, "")}
                                    defaultMeasureId={field.value}
                                    onChange={(measurement) => field.onChange(measurement?.measureId)}
                                    isClearable
                                />
                            )}
                />
                <Form.Control.Feedback type="invalid">{itemErrors?.measurementId?.message}</Form.Control.Feedback>
            </td>
            <td>
                <Controller
                    name={`items.${index}.catalogClassCode`}
                    rules={{
                        required: t(lang, 'roaming.validations.is_required')
                    }}
                    render={
                        ({field}) => (
                            <SelectCompanyCatalog
                                size="sm"
                                variant={itemErrors?.catalogCode ? 'outline-danger' : 'outline-primary'}
                                textButton={field.value || t(lang, "roaming.common.select.placeholder")}
                                defaultCatalogCode={field.value}
                                className={classNames({
                                    "is-invalid": itemErrors?.catalogClassCode,
                                    "is-valid": Object.keys(errors).length > 0 && !itemErrors?.catalogClassCode
                                })}
                                onChange={(catalog) => {
                                    onChangeCatalog(catalog);
                                    field.onChange(catalog?.class_code || null);
                                }}
                            />
                        )
                    }
                />
                <Form.Control.Feedback type="invalid">{itemErrors?.catalogClassCode?.message}</Form.Control.Feedback>
            </td>
            <td>
                <Form.Control name={`${itemRootName}.catalogClassName`}
                              placeholder={t(lang,"")}
                              type="text"
                              {...register(`${itemRootName}.catalogClassName`, {
                                  required: t(lang, 'roaming.validations.is_required')
                              })}
                              className={classNames({
                                  "is-invalid": itemErrors?.catalogClassName,
                                  "is-valid": Object.keys(errors).length > 0 && !itemErrors?.catalogClassName
                              })}
                />
                <Form.Control.Feedback type="invalid">{itemErrors?.catalogClassName?.message}</Form.Control.Feedback>
            </td>
            <td>
                {allowDelete &&  <Badge className={'px-2 py-2 fs-8 rounded-3 cursor-pointer'} bg="danger-davr" variant="phoenix" >
                    <FontAwesomeIcon icon={faTrashAlt} onClick={() => onDeleteClicked(index)}/>
                </Badge>}
            </td>
        </tr>
    );
};

export default EmpowermentItem;
