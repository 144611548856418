import classNames from 'classnames';
import PhoenixContainer from './PhoenixContainer';

const Section = ({ className, containerClassName, ...rest }) => {
    return (
        <section className={classNames(className)}>
            <PhoenixContainer className={containerClassName} {...rest} />
        </section>
    );
};

export default Section;
