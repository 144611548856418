import React, {Fragment, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {loadDraftWaybillV2Async} from "../../../store/reducers/roaming/waybill-v2/romaingWaybillV2Reducer";
import SpinnerComponent from "../../../components/common/SpinnerComponent";
import WaybillV2 from "../../../components/roaming/waybill-v2/WaybillV2";
const ViewDraftWaybillV2 = ({modalId}) => {

    const navigate = useNavigate();
    const {id} = useParams();
    const [content, setContent] = useState(null);
    const [draftWaybill, setDraftWaybill] = useState(null);
    const activeOrganization = useSelector(selectActiveOrganization);

    useEffect(() => {
        loadDraftWaybill();
    }, [modalId, id, activeOrganization])

    const loadDraftWaybill = () => {
        loadDraftWaybillV2Async(modalId ? modalId : id)
            .then(loadedDraftWaybill => {
                const parsedContent = JSON.parse(loadedDraftWaybill.content);
                setContent(loadedDraftWaybill.content);
                customizeKeys(parsedContent, loadedDraftWaybill.created_at)
            })
            .catch(error => console.log(error))
    }

    const customizeKeys = (waybill, created_at) => {

        const transportType = waybill?.Roadway ? waybill.Roadway : waybill?.Railway ? waybill.Railway : null

        return setDraftWaybill({
            id: modalId ? modalId : id,
            created_at: created_at,
            executor: {
                inn_or_pinfl: waybill.Consignor.TinOrPinfl,
                name: waybill.Consignor.Name,
            },
            customer: {
                inn_or_pinfl: waybill.Consignee.TinOrPinfl,
                name: waybill.Consignee.Name,
            },
            freight_forwarder: {
                inn_or_pinfl: waybill.FreightForwarder.TinOrPinfl,
                name: waybill.FreightForwarder.Name,
            },
            carrier: {
                inn_or_pinfl: waybill.Carrier.TinOrPinfl,
                name: waybill.Carrier.Name,
            },
            responsible_person: {
                inn_or_pinfl: waybill.ResponsiblePerson.Pinfl,
                name: waybill.ResponsiblePerson.FullName,
            },
            info: {
                number: waybill.WaybillDoc.WaybillNo,
                date: waybill.WaybillDoc.WaybillDate
            },
            contract: {
                number: waybill.ContractDoc?.ContractNo || null,
                date: waybill.ContractDoc?.ContractDate || null
            },
            client_contract_info: {
                inn_or_pinfl: waybill.Client?.TinOrPinfl || null,
                name: waybill.Client?.Name || null,
                number: waybill.Client?.ContractNo || null,
                date: waybill.Client?.ContractDate || null
            },
            payer_contract_info: {
                inn_or_pinfl: waybill.Payer?.TinOrPinfl || null,
                name: waybill.Payer?.Name || null,
                number: waybill.Payer?.ContractNo || null,
                date: waybill.Payer?.ContractDate ||  null
            },
            transport_type: waybill.TransportType,
            delivery_type: waybill.DeliveryType,
            roadway: {
                driver: {
                    inn_or_pinfl: transportType?.Driver?.Pinfl,
                    name: transportType?.Driver?.FullName,
                },
                truck: {
                    model: transportType?.Truck?.Model || null,
                    registration_number: transportType?.Truck?.RegNo || null
                } || null,
                trainCarriageNumber: transportType?.TrainCarriageNumber || null,
                trainDirection: transportType?.TrainDirection || null,
                product_groups: [
                    {
                        loading_point: {
                            region_code: transportType?.ProductGroups[0].LoadingPoint.RegionId,
                            region_name: transportType?.ProductGroups[0].LoadingPoint.RegionName,
                            district_code: transportType?.ProductGroups[0].LoadingPoint.DistrictCode,
                            district_name: transportType?.ProductGroups[0].LoadingPoint.DistrictName,
                            village_code: transportType?.ProductGroups[0].LoadingPoint.MahallaId,
                            village_name: transportType?.ProductGroups[0].LoadingPoint.MahallaName,
                            street: transportType?.ProductGroups[0].LoadingPoint.Address,
                            longitude: transportType?.ProductGroups[0].LoadingPoint.Latitude,
                            latitude: transportType?.ProductGroups[0].LoadingPoint.Longitude,
                        },
                        unloading_point: {
                            region_code: transportType?.ProductGroups[0].UnloadingPoint.RegionId,
                            region_name: transportType?.ProductGroups[0].UnloadingPoint.RegionName,
                            district_code: transportType?.ProductGroups[0].UnloadingPoint.DistrictCode,
                            district_name: transportType?.ProductGroups[0].UnloadingPoint.DistrictName,
                            village_code: transportType?.ProductGroups[0].UnloadingPoint.MahallaId,
                            village_name: transportType?.ProductGroups[0].UnloadingPoint.MahallaName,
                            street: transportType?.ProductGroups[0].UnloadingPoint.Address,
                            longitude: transportType?.ProductGroups[0].UnloadingPoint.Latitude,
                            latitude: transportType?.ProductGroups[0].UnloadingPoint.Longitude,
                        },
                        products: transportType?.ProductGroups[0].ProductInfo.Products.map(item => {
                            return {
                                name: item.ProductName,
                                catalog_code: item.CatalogCode,
                                catalog_name: item.CatalogName,
                                package_code: item.PackageCode,
                                measurement: item.PackageName,
                                quantity: item.Amount,
                                price: item.Price,
                                total: item.DeliverySum,
                            }
                        })
                    }
                ]
            },
        });
    }

    return (
        <Fragment>
            {!draftWaybill && <SpinnerComponent />}
            {draftWaybill && <WaybillV2 waybill={draftWaybill} content={content} loadWaybill={loadDraftWaybill}/>}
        </Fragment>
    );
};

export default ViewDraftWaybillV2;
