import {
    RoamingWaybillV2StatusConsignorReturnAccepted,
    RoamingWaybillV2StatusCustomerAccepted,
    RoamingWaybillV2StatusCustomerRejected, RoamingWaybillV2StatusExecutorCanceled,
    RoamingWaybillV2StatusExecutorSent, RoamingWaybillV2StatusResponsiblePersonAccepted,
    RoamingWaybillV2StatusResponsiblePersonGiven, RoamingWaybillV2StatusResponsiblePersonRejected,
    RoamingWaybillV2StatusResponsiblePersonReturned,
    RoamingWaybillV2StatusResponsiblePersonTillReturned, RoamingWaybillV2StatusTaxCommitteeRevoked
} from "./WaybillV2Status";

export class WaybillV2Wrapper {

    constructor(waybill) {
        this.waybill = waybill
    }

    isExecutor(identifier) {
        return this.waybill.executor.inn === identifier || this.waybill.executor.inn_or_pinfl === identifier
    }

    isCustomer(identifier) {
        return this.waybill.customer.inn === identifier || this.waybill.customer.inn_or_pinfl === identifier
    }

    isResponsiblePerson(identifier) {
        return this.waybill.responsible_person.inn === identifier || this.waybill.responsible_person.inn_or_pinfl === identifier
    }

    allowCancelStatus() {
        return this.waybill.status === RoamingWaybillV2StatusExecutorSent
    }

    allowCancel(identifier) {
        return this.allowCancelStatus() && this.isExecutor(identifier)
    }

    allowExecutorReturnAcceptStatus() {
        return this.waybill.status === RoamingWaybillV2StatusResponsiblePersonTillReturned || this.waybill.status === RoamingWaybillV2StatusResponsiblePersonReturned
    }

    allowExecutorReturnAccept(identifier) {
        return this.allowExecutorReturnAcceptStatus() && this.isExecutor(identifier)
    }

    allowCustomerAcceptStatus() {
        return this.waybill.status === RoamingWaybillV2StatusResponsiblePersonGiven
    }

    allowCustomerAccept(identifier) {
        return this.allowCustomerAcceptStatus() && this.isCustomer(identifier)
    }

    allowCustomerDeclineStatus() {
        return this.waybill.status === RoamingWaybillV2StatusResponsiblePersonGiven
    }

    allowCustomerDecline(identifier) {
        return this.allowCustomerDeclineStatus() && this.isCustomer(identifier)
    }

    allowCreateInvoice(identifier) {
        return this.isCustomerAcceptedStatus() && this.isExecutor(identifier);
    }

    isCustomerAcceptedStatus() {
        return this.waybill.status === RoamingWaybillV2StatusCustomerAccepted;
    }

    isResponsiblePersonRejectedOrAcceptedStatus() {
        return this.waybill.status === RoamingWaybillV2StatusExecutorSent
    }

    isResponsiblePersonRejectedOrAccepted(identifier) {
        return this.isResponsiblePersonRejectedOrAcceptedStatus() && this.isResponsiblePerson(identifier)
    }

    isResponsiblePersonReturnOrGivenStatus() {
        return this.waybill.status === RoamingWaybillV2StatusResponsiblePersonAccepted
    }

    isResponsiblePersonReturnOrGiven(identifier) {
        return this.isResponsiblePersonReturnOrGivenStatus() && this.isResponsiblePerson(identifier)
    }

    isResponsiblePersonReturnStatus() {
        return this.waybill.status === RoamingWaybillV2StatusResponsiblePersonGiven || this.waybill.status === RoamingWaybillV2StatusCustomerRejected
    }

    isResponsiblePersonReturn(identifier) {
        return this.isResponsiblePersonReturnStatus() && this.isResponsiblePerson(identifier)
    }

}

const waybillWrapper = new WaybillV2Wrapper()

export const TypeDefault = 0
export const TypeAdditional = 1
export const TypeCorrective = 4


export const waybillV2Types = [
    {value: TypeDefault, label: 'roaming.invoice.send_form.type.option.0'},
    {value: TypeAdditional, label: 'roaming.invoice.send_form.type.option.1'},
    {value: TypeCorrective, label: 'roaming.invoice.send_form.type.option.4'},
]

export const getHistoriesActions = (action) => {
    switch (action) {
        case RoamingWaybillV2StatusExecutorSent:
            return {
                label: "roaming.action_seal.sent_text",
                bg: "#f9fafd",
                className: ""
            }
        case RoamingWaybillV2StatusExecutorCanceled:
            return {
                label: "roaming.waybill.v2.status.consignor.canceled",
                bg: "rgba(237, 85, 101, 0.1)",
                className: "border-danger text-danger"
            }
        case RoamingWaybillV2StatusResponsiblePersonRejected :
            return {
                label: "roaming.waybill.v2.status.responsible.person.rejected",
                bg: "rgba(237, 85, 101, 0.1)",
                className: "border-danger text-danger"
            }
        case RoamingWaybillV2StatusResponsiblePersonAccepted :
            return {
                label: "roaming.waybill.v2.status.responsible.person.accepted",
                bg: "rgba(26, 179, 148, 0.1)",
                className: "border-success text-success"
            }
        case RoamingWaybillV2StatusResponsiblePersonTillReturned :
            return {
                label: "roaming.waybill.v2.status.responsible.person.till.returned",
                bg: "rgba(26, 179, 148, 0.1)",
                className: "border-success text-success"
            }
        case RoamingWaybillV2StatusResponsiblePersonGiven :
            return {
                label: "roaming.waybill.v2.status.responsible.person.given",
                bg: "rgba(26, 179, 148, 0.1)",
                className: "border-success text-success"
            }
        case RoamingWaybillV2StatusCustomerRejected :
            return {
                label: "roaming.waybill.v2.status.consignee.rejected",
                bg: "rgba(237, 85, 101, 0.1)",
                className: "border-danger text-danger"
            }
        case RoamingWaybillV2StatusCustomerAccepted :
            return {
                label: "roaming.waybill.v2.status.consignee.accepted",
                bg: "rgba(26, 179, 148, 0.1)",
                className: "border-success text-success"
            }
        case RoamingWaybillV2StatusResponsiblePersonReturned :
            return {
                label: "roaming.waybill.v2.status.responsible.person.returned",
                bg: "rgba(26, 179, 148, 0.1)",
                className: "border-success text-success"
            }
        case RoamingWaybillV2StatusConsignorReturnAccepted :
            return {
                label: "roaming.waybill.v2.status.consignor.return.accepted",
                bg: "rgba(26, 179, 148, 0.1)",
                className: "border-success text-success"
            }
        case RoamingWaybillV2StatusTaxCommitteeRevoked :
            return {
                label: "roaming.waybill.v2.status.tax.committee.revoked",
                bg: "rgba(237, 85, 101, 0.1)",
                className: "border-danger text-danger"
            }
    }
}
