import React, {Fragment, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {selectCurrentCertificate} from "../../../store/reducers/eimzo/eimzoReducer";
import {PermissionRoamingEmpowermentAccept} from "../../../enum/permission/RoamingPermission";
import checkPermission from "../../../enum/permission/CheckPermission";
import toastPermission from "../../../enum/permission/ToastPermission";
import {personAcceptEmpowermentAsync} from "../../../store/reducers/roaming/empowerment/empowermentReducer";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../IconButton";
import PropTypes from "prop-types";
import {Button, Modal, Spinner} from "react-bootstrap";
import SignActionWithSelectCertificateWrapper from "../../common/SignActionWithSelectCertificateWrapper";
import IconSignature from "../../../assets/icon/IconSignature";

const PersonAcceptEmpowerment = ({id, ...props}) => {
    const dispatch  = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const currentCertificate = useSelector(selectCurrentCertificate);


    const handleClose = () => setShow(false);
    const handleShow = () => {
        if (dispatch(checkPermission(PermissionRoamingEmpowermentAccept))) setShow(true)
        else dispatch(toastPermission())
    };

    const personAcceptEmpowermentHandler = () => {
        setLoading(true);
        personAcceptEmpowermentAsync(currentCertificate, id, lang)
            .then(response => {
                handleClose();
                setLoading(false);
            })
            .catch(errors => {
                setLoading(false)
            })
    };

    return (
        <Fragment>
            <IconButton
                onClick={handleShow}
                variant="phoenix-secondary"
                size="sm"
                iconClassName="me-1"
                icon={""}
                className={'btn-davr bg-primary-davr gap-1 text-white'}
                {...props}
            >
                <IconSignature />
                {t(lang, 'roaming.empowerment.approve_button')}
            </IconButton>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, "roaming.empowerment.accept_modal.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t(lang, "roaming.empowerment.accept_modal.body")}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(lang, "roaming.empowerment.cancel_button")}
                    </Button>
                    <SignActionWithSelectCertificateWrapper>
                        <Button id="acceptButton" variant="primary" className="d-flex align-items-center" onClick={personAcceptEmpowermentHandler} disabled={loading}>
                            {loading && <Spinner className="me-2" animation="border" role="status" size="sm"/>}
                            {t(lang, 'roaming.empowerment.approve_button')}
                        </Button>
                    </SignActionWithSelectCertificateWrapper>
                </Modal.Footer>
            </Modal>

        </Fragment>
    );
};

PersonAcceptEmpowerment.propTypes = {
    id: PropTypes.string,
};
PersonAcceptEmpowerment.defaultProps = {
    id: "",
};

export default PersonAcceptEmpowerment;
