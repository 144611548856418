import React from 'react';

const HistoryActionSeal = ({serialNumber, signingTime, statusName, fullName, variant, bg}) => {
    return (
        <div className={`border-300 my-2 p-2 ${variant}`} style={{borderRadius: '10px', border: '10px solid', background: `${bg}`}}>
            <div className="d-flex justify-content-between">
                <span className="me-3 text-secondary">№ {serialNumber}</span>
                <span className="text-secondary">{signingTime}</span>
            </div>
            <p className={`fs-7 fw-semi-bold text-center my-3`}>{statusName}</p>
            <p className="m-0 fs-7 text-secondary">
                {fullName}
            </p>
        </div>
    );
};

export default HistoryActionSeal;
