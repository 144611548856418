import EventBus from "../../../eventbus/EventBus";
import {
    ADD_BRANCH_FAILED,
    ADD_BRANCH_REQUESTED,
    ADD_BRANCH_SUCCESS,
    EDIT_BRANCH_FAILED,
    EDIT_BRANCH_SUCCESS,
    EDIT_BRANCH_REQUESTED,
    DELETE_BRANCH_FAILED,
    DELETE_BRANCH_REQUESTED,
    DELETE_BRANCH_SUCCESS,
    BIND_WAREHOUSE_SUCCESS,
    BIND_WAREHOUSE_FAILED
} from "../../../eventbus/edi/branch/branchEvents";
import branchService from "../../services/branch/branchService";
import {createSlice} from "@reduxjs/toolkit";
export const STORAGE_BRANCH = "BranchesDataTableSettings"

const initialState = {
    branches: [],
    branchUses: [],
    branchesDataTableSettings: {}
}

export const branchSlice = createSlice({
    name: 'branch',
    initialState,
    reducers: {
        updateBranches: (state, action) => {
            state.branches = action.payload;
        },
        updateBranchUses: (state, action) => {
            state.branchUses = action.payload;
        },

        loadBranchDataTableColumn: (state) => {
            const defaultColumns = {
                index: true,
                name: true,
                code: true,
                branch_name: true,
                branch_code: true,
                bind_warehouse: true,
                button_action: true,
            }
            let storageColumn = JSON.parse(localStorage.getItem(STORAGE_BRANCH))
            if(!storageColumn) {
                storageColumn = defaultColumns
                localStorage.setItem(STORAGE_BRANCH, JSON.stringify(storageColumn));
            } else {
                storageColumn = {...defaultColumns, ...storageColumn}
            }
            state.branchesDataTableSettings = storageColumn
        },

        changeBranchDataTableColumn: (state, action) => {
            state.branchesDataTableSettings = action.payload
            localStorage.setItem(STORAGE_BRANCH, JSON.stringify(state.branchesDataTableSettings))
        },
    },
});

export const loadBranchesAsync = () => dispatch => {
    branchService.getBranches()
        .then((response) => {
            dispatch(branchSlice.actions.updateBranches(response.data));
        })
        .catch(error => {
            console.log(error);
        });
}

export const addBranchAsync = ({name, code, inn, roaming_branch}) => {
    EventBus.dispatch(ADD_BRANCH_REQUESTED, {name, code, inn, roaming_branch});

    return new Promise((resolve, reject) => {
        branchService.addBranch({name, code, inn, roaming_branch})
            .then(response => {
                const branch = response.data;
                EventBus.dispatch(ADD_BRANCH_SUCCESS, branch);
                resolve(branch);
            })
            .catch(error => {
                reject({
                    nameExists: error?.response?.data?.name_exists || false,
                    codeExists: error?.response?.data?.code_exists || false
                })
                EventBus.dispatch(ADD_BRANCH_FAILED, error);
            });
    });
}

export const loadBranchUsesAsync = () => dispatch => {
    branchService.getBranchUse()
        .then(response => {
            dispatch(branchSlice.actions.updateBranchUses(response.data));
        })
        .catch(error => {
            console.log(error);
        });
}

export const loadBranchAsync = (id) => {
    return new Promise((resolve, reject) => {
        branchService.getBranch(id)
            .then(response => {
                resolve(response.data);
            })
            .catch(e => {
                reject(e);
            });
    })
}

export const editBranchAsync = (id, payload) => {
    return new Promise((resolve, reject) => {
        EventBus.dispatch(EDIT_BRANCH_REQUESTED, payload);

        branchService.edit(id, payload)
            .then((response) => {
                EventBus.dispatch(EDIT_BRANCH_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch(error => {
                reject({
                    nameExists: error?.response?.data?.name_exists || false,
                    codeExists: error?.response?.data?.code_exists || false
                })
                EventBus.dispatch(EDIT_BRANCH_FAILED, error);
            })
    })
}

export const deleteBranchAsync = ({id}) => {
    EventBus.dispatch(DELETE_BRANCH_REQUESTED, {id});

    return new Promise((resolve, reject) => {
        branchService.deleteBranch({id})
            .then((response) => {
                EventBus.dispatch(DELETE_BRANCH_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(DELETE_BRANCH_FAILED, error.message);
                reject()
            })
    });
};

export const bindBranchWarehouseAsync = (data) => {
    return new Promise((resolve, reject) => {
        branchService.bindWarehouse(data)
            .then((response) => {
                EventBus.dispatch(BIND_WAREHOUSE_SUCCESS, response.data)
                resolve(response.data)
            })
            .catch((error) => {
                EventBus.dispatch(BIND_WAREHOUSE_FAILED, error.message)
                reject()
            })
    })
}

export const selectBranches = state => state.branch.branches;

export const selectBranchUses = (state) => state.branch.branchUses

export const selectBranchDataTable = (state) => state.branch.branchesDataTableSettings

export const {loadBranchDataTableColumn} = branchSlice.actions
export const {changeBranchDataTableColumn} = branchSlice.actions

export default branchSlice.reducer;
