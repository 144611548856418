import React, {Fragment} from 'react';
import {Button, Modal} from "react-bootstrap";

const ButtonWithConfirmModal = ({title, description, button, onConfirm, showModal, onClose}) => {
    const handleClose = () => {
        onClose()
    };
    const onClickConfirm = () => {
        onConfirm()
    }
    return (
        <Fragment>
            <Modal
                show={showModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                {description && <Modal.Body>{description}</Modal.Body>}
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Закрыть
                    </Button>
                    <Button onClick={onClickConfirm} variant={button.variant}>{button.text}</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default ButtonWithConfirmModal;
