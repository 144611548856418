import React, {useState} from 'react';
import toast from "react-hot-toast";
import {downloadFile} from "../../../../helpers/excel-uploader";
import dayjs from "dayjs";
import {loadDraftPDFAsync} from "../../../../store/reducers/roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import {selectDateDayJSFormat, selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import IconButton from "../../../IconButton";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {Spinner} from "react-bootstrap";
import IconCloudDownload from "../../../../assets/icon/IconCloudDownload";

const RoamingDraftInvoiceDownloadToPdf = ({invoice, actionPdf, ...props}) => {

    const lang = useSelector(selectLang);
    const t = Translate;
    const dateFormat = useSelector(selectDateDayJSFormat);
    const [loading, setLoading] = useState(false);

    const onDownloadToPdf = () => {
        setLoading(true);
        actionPdf(invoice.id)
            .then((response) => {
                setLoading(false);
                downloadFile({
                    content: response,
                    contentType: response.type,
                    fileName: `${invoice.info.number}-${dayjs(invoice.info.date).format(dateFormat)}`,
                });
            })
            .catch(error => {
                setLoading(false);
                toast.error(t(lang, "roaming.invoice.print_failed_error"))
            })
    }

    return (
        <IconButton {...props}
                    transform="shrink-3"
                    size="sm"
                    variant={'phoenix-info'}
                    className={'btn-davr bg-white text-dark d-flex gap-2'}
                    onClick={onDownloadToPdf}
        >
            {loading ? <Spinner className="align-middle ms-2" size="sm" animation="border" role="switch"/> : <IconCloudDownload />}
            <span className="d-none d-sm-inline-block">{t(lang, 'edi.contract_formation.view.download_pdf')}</span>
        </IconButton>
    );
};

export default RoamingDraftInvoiceDownloadToPdf;
