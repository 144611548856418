import React, {Fragment, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectAccountRolePermissions} from "../../../../store/reducers/role/roleReducer";
import checkPermission from "../../../../enum/permission/CheckPermission";
import {PermissionRoamingShipmentUpdate} from "../../../../enum/permission/RoamingPermission";
import {getOrganizationBillingInfoAsync} from "../../../../store/reducers/billing/billingReducer";
import EventBus from "../../../../eventbus/EventBus";
import {
    SAVE_DRAFT_WAYBILL_SUCCEED,
    SEND_WAYBILL_SUCCEED
} from "../../../../eventbus/romaing/waybill/roamingWaybillEvents";
import {loadDraftWaybillAsync} from "../../../../store/reducers/roaming/waybill/roamingWaybillReducer";
import SpinnerComponent from "../../../common/SpinnerComponent";
import EditWaybillForm from "../form/EditWaybillForm";

const EditWaybill = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const [draftWaybill, setDraftWaybill] = useState(null);
    const dispatch = useDispatch()

    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingShipmentUpdate))) {
            navigate('/roaming/waybills/draft')
        }
    }, [permission])

    useEffect(() => {
        loadDraftWaybill();
    }, [id])

    useEffect(() => {
        const onSendWaybillSucceedHandler = EventBus.on(SEND_WAYBILL_SUCCEED, waybill => {
            navigate(`/roaming/waybill/${waybill.id}`)
            dispatch(getOrganizationBillingInfoAsync());
        });

        const onSaveDraftWaybillSucceedHandler = EventBus.on(SAVE_DRAFT_WAYBILL_SUCCEED, draftWaybill => {
            navigate(`/roaming/waybill/draft/${draftWaybill.id}`)
        })

        return () => {
            EventBus.remove(SEND_WAYBILL_SUCCEED, onSendWaybillSucceedHandler);
            EventBus.remove(SAVE_DRAFT_WAYBILL_SUCCEED, onSaveDraftWaybillSucceedHandler);
        }
    }, [])

    const loadDraftWaybill = () => {
        loadDraftWaybillAsync(id)
            .then(loadedDraftWaybill => {
                const parsedContent = JSON.parse(loadedDraftWaybill.content);
                setDraftWaybill(parsedContent);
            })
            .catch(error => console.log(error))
    }

    return (
        <Fragment>
            {!draftWaybill && <SpinnerComponent />}
            {draftWaybill && <EditWaybillForm id={id} waybill={draftWaybill} />}
        </Fragment>
    );
};

export default EditWaybill;
