import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import DateFormatChooser from "./DateFormatChooser";
import NumberFormatChooser from "./NumberFormatChooser";
import {Card} from "react-bootstrap";

const AccountSettings = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    return (
        <Fragment>
            <h4 className={'fs-8 fw-semi-bold second-text'}>{t(lang, 'hippo.user.settings.profile.format_title')}</h4>
            <Card.Footer className="bg-card p-2 rounded-2 d-flex flex-column gap-2 h-100">
                <DateFormatChooser/>
                <NumberFormatChooser/>
            </Card.Footer>

        </Fragment>
    );
};

export default AccountSettings;
