import React, {useState} from 'react';
import {selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {syncAsync} from "../../../../store/reducers/roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import PropTypes from "prop-types";
import IconButton from "../../../IconButton";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";

const RoamingInvoiceSync = ({id, ...props}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const [loading, setLoading] = useState(false);
    const syncInvoice = () => {
        setLoading(true);
        syncAsync(id)
            .then()
            .catch()
            .finally(() => setLoading(false))
    };
    return (
        <IconButton
            icon={faRefresh}
            variant="phoenix-primary"
            onClick={syncInvoice}
            size="sm"
            className={'btn-davr bg-white text-dark d-flex gap-2'}
            iconClassName="text-primary-davr fs-8"
            disabled={loading}
            {...props}>
            {t(lang, "roaming.empowerment.sync")}
        </IconButton>
    );
};

RoamingInvoiceSync.propTypes = {
    id: PropTypes.string
}

RoamingInvoiceSync.defaulProps = {
    id: ""
}
export default RoamingInvoiceSync;
