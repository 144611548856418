import React, {useEffect} from 'react';
import {Translate} from "../../../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat} from "../../../../../store/reducers/main/main";
import {selectProductCatalogs} from "../../../../../store/reducers/edi/invoice/invoiceReducer";
import {selectCompanyCatalogs} from "../../../../../store/reducers/roaming/roamingReducer";
import {Controller, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import numeral from "numeral";
import {Col, Row, Table, Form} from "react-bootstrap";
import classNames from "classnames";
import OriginLinkSelector from "../../../../common/OriginLinkSelector";
import ReactSelect from "react-select";
import SelectCompanyCatalog from "../../../../IKPU/SelectCompanyCatalog";
import {InvoiceNdsZeroNoneVatPayers} from "../../../../../enum/edi/invoice/invoiceStatus";

const itemRootName = "items";

const InvoiceItems = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const numberFormat = useSelector(selectNumberFormat);
    const storageCatalogs = useSelector(selectProductCatalogs);
    const catalogs = useSelector(selectCompanyCatalogs);

    const {setValue, formState: {errors}, getValues} = useFormContext();
    const {fields} = useFieldArray({name: itemRootName});
    const watchFieldArray = useWatch({name: itemRootName});
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index]
        }
    })

    // set default catalogs/benefits/item_packages
    useEffect(() => {
        controlledFields.map((item, index) => {
            if (item.catalogCode)
                return;

            const catalog = catalogs.find(c => c.class_code === storageCatalogs?.[item.product]?.catalogClassCode);
            if (catalog) {
                onChangeCatalog(index, catalog)
                onChangeOrigin(index, storageCatalogs[item.product].origin);

                const itemPackage = catalog.package_names.find(p => p.code.toString() === storageCatalogs?.[item.product]?.packageCode);
                const benefit = catalog.lgotas.find(b => b.new_id === storageCatalogs?.[item.product]?.benefitId || b.id === storageCatalogs?.[item.product]?.benefitId);
                if (itemPackage)
                    onChangePackage(index, itemPackage)
                if (benefit)
                    onChangeBenefit(index, benefit)
            }
        })
    }, [storageCatalogs, catalogs])

    // on change bulk catalog/privilege
    const onBulkChangeCatalog = (catalog, benefit) => {
        for (let i = 0; i < controlledFields.length; i++) {
            onChangeCatalog(i, catalog);
            onChangeBenefit(i, benefit);
        }
    };

    const onBulkChangeOrigin = (origin) => {
        for (let i = 0; i < controlledFields.length; i++) {
            onChangeOrigin(i, origin);
        }
    };

    const onChangeOrigin = (index, origin) => {
        setValue(`${itemRootName}.${index}.origin`, origin || null);
    };

    const onChangeCatalog = (index, catalog) => {
        if (catalog) {
            setValue(`${itemRootName}.${index}.catalogCode`, catalog.class_code);
            setValue(`${itemRootName}.${index}.catalogName`, catalog.name);
            setValue(`${itemRootName}.${index}.packageNames`, catalog.package_names);
            setValue(`${itemRootName}.${index}.usePackage`, catalog.use_package);
            setValue(`${itemRootName}.${index}.origin`, null);
        } else {
            setValue(`${itemRootName}.${index}.catalogCode`, null);
            setValue(`${itemRootName}.${index}.catalogName`, null);
            setValue(`${itemRootName}.${index}.packageNames`, []);
            setValue(`${itemRootName}.${index}.usePackage`, false);
            setValue(`${itemRootName}.${index}.origin`, null);
        }
        onChangeBenefit(index, null);
        onChangePackage(index, null);
    };

    const onChangeBenefit = (index, benefit) => {
        if (benefit) {
            const invoiceDate = getValues('invoiceInfo.date');
            const ndsRate = invoiceDate?.getFullYear() > 2022 ? 12 : 15;
            const benefitId = invoiceDate?.getFullYear() >= 2022 ? benefit.new_id : benefit.id

            let benefitPrice = 0;

            if (benefit.type === InvoiceNdsZeroNoneVatPayers)
                benefitPrice = getValues(`${itemRootName}.${index}.total`) * ndsRate / 100;

            setValue(`${itemRootName}.${index}.benefitId`, benefitId)
            setValue(`${itemRootName}.${index}.benefitName`, benefit.name)
            setValue(`${itemRootName}.${index}.benefitType`, benefit.type)
            setValue(`${itemRootName}.${index}.benefitPrice`, benefitPrice)
        } else {
            setValue(`${itemRootName}.${index}.benefitId`, null)
            setValue(`${itemRootName}.${index}.benefitName`, null)
            setValue(`${itemRootName}.${index}.benefitType`, null)
            setValue(`${itemRootName}.${index}.benefitPrice`, null)
        }
    };

    const onChangePackage = (index, itemPackage) => {
        if (itemPackage) {
            setValue(`${itemRootName}.${index}.packageCode`, itemPackage.code)
            setValue(`${itemRootName}.${index}.packageName`, lang === "ru" ? itemPackage.name_ru : itemPackage.name_uz);
        } else {
            setValue(`${itemRootName}.${index}.packageCode`, null)
            setValue(`${itemRootName}.${index}.packageName`, null);
        }
    };

    const subtotal = controlledFields.reduce((acc, item) => acc + item.total, 0);
    const tax = controlledFields.reduce((acc, item) => acc + item.nds_value, 0);

    return (
        <Row>
            <Col xs={12} className="text-end">
                <Row className={'align-items-center'}>
                    <Col xs={6}>
                        <h4 className={'text-start'}>{t(lang, "edi.common.items")}</h4>
                    </Col>
                    <Col xs={6}>
                        <SelectCompanyCatalog
                            // variant="soft-secondary"
                            size="sm"
                            className="w-auto"
                            textButton={t(lang, "edi.common.select_common_catalog_class_code_button")}
                            onChange={onBulkChangeCatalog}
                        />
                    </Col>
                </Row>
            </Col>
            <Col xs={12}>
                <Table responsive bordered hover className="mt-3 mb-0 border-300 overflow-auto">
                    <thead className="bg-200 text-dark">
                    <tr className="fs--1 text-center">
                        <th className={'fw-semi-bold'}>№</th>
                        <th className={'fw-semi-bold'}>
                            {t(lang, "edi.common.items.product.catalog_class_code")}
                            <span className={'text-danger'}>*</span>
                        </th>
                        <th className={'fw-semi-bold'}>{t(lang, "edi.common.items.product")}</th>
                        <th className={'fw-semi-bold'}>{t(lang, "edi.common.items.product.benefit")}</th>
                        <th className={'fw-semi-bold'}>{t(lang, "edi.common.items.product.barcode")}</th>
                        <th className={'fw-semi-bold'}>{t(lang, "edi.common.items.product.measurement")}</th>
                        <th className={'fw-semi-bold'}>{t(lang, "edi.common.items.product.quantity")}</th>
                        <th className={'fw-semi-bold'}>{t(lang, "edi.common.items.product.price")}</th>
                        <th className={'fw-semi-bold'}>{t(lang, "edi.common.items.product.total")}</th>
                        <th className={'fw-semi-bold'}>{t(lang, "edi.common.items.product.nds")}</th>
                        <th className={'fw-semi-bold'}>{t(lang, "edi.common.items.product.nds_value")}</th>
                        <th className={'fw-semi-bold'}>{t(lang, "edi.common.items.product.total_with_nds")}</th>
                        <th className={'fw-semi-bold'}>
                            {/*{t(lang, "edi.common.origin")}*/}
                            {/*<br/>*/}
                            <Controller name={`common.origin`}
                                        render={() => {
                                            return <OriginLinkSelector isTitle={true}
                                                                       onChange={origin => onBulkChangeOrigin(origin)}/>
                                        }}/>
                        </th>
                        {/*<th>*/}
                        {/*    {t(lang, "edi.common.origin")}*/}
                        {/*    <RoamingInvoiceOriginSelect catalogCode={null}*/}
                        {/*                                  defaultOriginType={null}*/}
                        {/*                                  onChange={onBulkChangeOrigin}*/}
                        {/*    />*/}
                        {/*</th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    {controlledFields.map((item, index) => {
                        const itemName = `${itemRootName}.${index}`;
                        const itemErrors = errors && errors[itemRootName] && errors[itemRootName][index];

                        const benefitId = item.benefitId;
                        const usePackage = item.usePackage;
                        const packageNames = item.packageNames;
                        const activePackage = packageNames.find(p => p.code === item.packageCode) || null

                        return (
                            <tr className="fw-semi-bold text-center align-middle fs--1" key={item.id}>
                                <td className="p-0">{index + 1}</td>
                                <td className="px-0 py-1">
                                    <Controller name={`${itemName}.catalogCode`}
                                                rules={{
                                                    required: t(lang, "edi.common.forms.validations.is_required")
                                                }}
                                                render={({field}) => (
                                                    <SelectCompanyCatalog
                                                        textButton={field.value || t(lang, "edi.common.button.select")}
                                                        defaultCatalogCode={field.value}
                                                        defaultLgota={benefitId}
                                                        onChange={(catalog, benefit) => {
                                                            onChangeCatalog(index, catalog)
                                                            onChangeBenefit(index, benefit)
                                                        }}
                                                        variant={itemErrors?.catalogCode ? 'outline-danger' : 'outline-primary'}
                                                    />
                                                )}
                                    />
                                </td>
                                <td className="p-0">{item.product}</td>
                                <td className="p-0">
                                    {benefitId ?
                                        <span
                                            className="text-success">{t(lang, "edi.common.items.product.selected")}</span>
                                        :
                                        <span
                                            className="text-warning">{t(lang, "edi.common.items.product.not_selected")}</span>
                                    }
                                </td>
                                <td className="p-0">{item.barcode}</td>
                                <td className="p-0">
                                    {usePackage &&
                                        <>
                                            <Controller name={`${itemName}.packageCode`}
                                                        rules={{
                                                            required: t(lang, "edi.common.forms.validations.is_required")
                                                        }}
                                                        render={({field}) => {
                                                            return (
                                                                <ReactSelect options={packageNames}
                                                                             hideSelectedOptions
                                                                             isClearable
                                                                             placeholder={t(lang, "edi.common.button.select")}
                                                                             getOptionLabel={option => lang === "ru" ? `${option.name_ru} - ${option.code}` : `${option.name_uz} - ${option.code}`}
                                                                             getOptionValue={option => option.code}
                                                                             classNamePrefix="react-select"
                                                                             menuPortalTarget={document.body}
                                                                             className={classNames({
                                                                                 'is-invalid': itemErrors?.packageCode
                                                                             })}
                                                                             value={activePackage}
                                                                             onChange={(itemPackage) => onChangePackage(index, itemPackage)}
                                                                />
                                                            )
                                                        }
                                                        }
                                            />

                                            <Form.Control.Feedback
                                                type="invalid">{itemErrors?.packageCode?.message}</Form.Control.Feedback></>
                                    }
                                    {!usePackage && <>{item.measurement}</>}
                                </td>
                                <td className="p-0">{item.quantity}</td>
                                <td className="p-0">{item.price}</td>
                                <td className="p-0">{item.total}</td>
                                <td className="p-0">{item.nds_rate}</td>
                                <td className="p-0">{item.nds_value}</td>
                                <td className="p-0">{item.total_with_nds}</td>
                                <td className="p-0">
                                    <Controller name={`${itemName}.origin`}
                                                rules={{required: t(lang, 'edi.common.forms.validations.is_required')}}
                                                render={() => {
                                                    return <OriginLinkSelector linkAppendClassName={classNames({
                                                        'text-danger': itemErrors?.origin
                                                    })}
                                                                               defaultOriginType={item.origin}
                                                                               onChange={origin => onChangeOrigin(index, origin)}/>
                                                }}

                                    />
                                </td>
                                {/*<td className="p-0">*/}
                                {/*    <Controller name={`${itemName}.origin`}*/}
                                {/*                rules={{*/}
                                {/*                    required: t(lang, 'edi.common.forms.validations.is_required')*/}
                                {/*                }}*/}
                                {/*                render={({field}) => (*/}
                                {/*                    <RoamingInvoiceOriginSelector catalogCode={item.catalogCode}*/}
                                {/*                                                  defaultOriginType={field.value}*/}
                                {/*                                                  onChange={(origin) => onChangeOrigin(index, origin)}*/}
                                {/*                                                  selectProps={{*/}
                                {/*                                                      className: classNames({*/}
                                {/*                                                          'is-invalid': itemErrors?.origin*/}
                                {/*                                                      }),*/}
                                {/*                                                      menuPortalTarget: document.body*/}
                                {/*                                                  }}*/}
                                {/*                    />*/}
                                {/*                )}*/}
                                {/*    />*/}
                                {/*    <Form.Control.Feedback type="invalid">{itemErrors?.origin?.message}</Form.Control.Feedback>*/}
                                {/*</td>*/}
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
            </Col>
            <div className={"px-3"}>
                <Row className="g-0 justify-content-end bg-200">
                    <Col xs={"auto"}>
                        <Table borderless size="sm" className="fs--1 text-end">
                            <tbody>
                            <tr>
                                <th className="text-900">{t(lang, 'roaming.common.subtotal')}:</th>
                                <td className="fw-semi-bold">
                                    {numeral(subtotal).format(numberFormat)}
                                </td>
                            </tr>
                            <tr>
                                <th className="text-900">{t(lang, 'roaming.common.tax')}:</th>
                                <td className="fw-semi-bold">
                                    {numeral(tax).format(numberFormat)}
                                </td>
                            </tr>
                            <tr className="border-top">
                                <th className="text-900">{t(lang, 'roaming.common.total')}:</th>
                                <td className="fw-semi-bold">
                                    {numeral(subtotal + tax).format(numberFormat)}
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>

        </Row>
    );
};

export default InvoiceItems;
