import numeral from "numeral";

export function registerCustomNumberFormats() {
    const roundingValue = 2;

    numeral.register('format', 'decimal', {
        regexps: {
            format: /(d)/,
            unformat: /(d)/
        },
        format(value,format, roundingFunction) {
            // console.log(value)
            let major =  value?.toFixed(roundingValue).toString();
            let commaIndex = major.replace(",", ".").indexOf(".");

            let minor = "";
            if (commaIndex > -1) {
                minor =  major.substr(commaIndex + 1);
                major =  major.substr(0, commaIndex);
            }

            major = this?.formatNumberMajorPart(major) || 0
            return `${major}.${minor}`
        },

        unformat(value) {
            let newValue = value ? value.slice(0, value.lastIndexOf(".")) : value;
            return parseFloat(newValue.replace(/ /g, ""));
        },

        formatNumberMajorPart(value) {
            let result = ''
            value = value ? value.split('').reverse().join('') : value
            for (let i = 0; i < value.length; i++) {
                if (i > 0 && i % 3 === 0)
                    result = `${result} `
                result = `${result}${value[i]}`
            }

            if (result.length && result[result.length - 1] === ' ')
                result = result ? result.substr(0, result.length - 1) : result

            return result.split('').reverse().join('')
        },
    });
    numeral.register('format', 'comma', {
        regexps: {
            format: /(d)/,
            unformat: /(d)/
        },
        format: function (value,format, roundingFunction) {
            let major = value.toFixed(roundingValue).toString();
            let commaIndex = major.replace(",", ".").indexOf(".");

            let minor = "";
            if (commaIndex > -1) {
                minor = major.substr(commaIndex + 1);
                major = major.substr(0, commaIndex);
            }

            major = this?.formatNumberMajorPart(major) || 0
            return `${major}.${minor}`
        },

        unformat(value) {
            let newValue = value.slice(0, value.lastIndexOf("."));
            return parseFloat(newValue.replace(/,/g, ""));
        },

        formatNumberMajorPart(value) {
            let result = ''
            value = value.split('').reverse().join('')
            for (let i = 0; i < value.length; i++) {
                if (i > 0 && i % 3 === 0)
                    result = `${result},`
                result = `${result}${value[i]}`
            }

            if (result.length && result[result.length - 1] === ' ')
                result = result.substr(0, result.length - 1)

            return result.split('').reverse().join('')
        },
    });
    numeral.register('format', 'dot', {
        regexps: {
            format: /(d)/,
            unformat: /(d)/
        },
        format: function (value,format, roundingFunction) {
            let major = value.toFixed(roundingValue).toString();
            let commaIndex = major.replace(",", ".").indexOf(".");

            let minor = "";
            if (commaIndex > -1) {
                minor = major.substr(commaIndex + 1);
                major = major.substr(0, commaIndex);
            }

            major = this?.formatNumberMajorPart(major) || 0
            return `${major}.${minor}`
        },
        unformat(value) {
            let newValue = value.slice(0, value.lastIndexOf("."));
            return parseFloat(newValue.replace(/./g, ""));
        },

        formatNumberMajorPart(value) {
            let result = ''
            value = value.split('').reverse().join('')
            for (let i = 0; i < value.length; i++) {
                if (i > 0 && i % 3 === 0)
                    result = `${result}.`
                result = `${result}${value[i]}`
            }

            if (result.length && result[result.length - 1] === ' ')
                result = result.substr(0, result.length - 1)

            return result.split('').reverse().join('')
        },
    });
    numeral.register('format', 'money', {
        regexps: {
            format: /(d)/,
            unformat: /(d)/
        },
        format(value,format, roundingFunction) {
            let major = value.toFixed(roundingValue).toString();
            let commaIndex = major.replace(",", ".").indexOf(".");

            let minor = "";
            if (commaIndex > -1) {
                minor = major.substr(commaIndex + 1);
                major = major.substr(0, commaIndex);
            }

            return this.formatNumberMajorPart(major)
        },

        unformat(value) {
            let newValue = value.slice(0, value.lastIndexOf("."));
            return parseFloat(newValue.replace(/ /g, ""));
        },

        formatNumberMajorPart(value) {
            let result = ''
            value = value.split('').reverse().join('')
            for (let i = 0; i < value.length; i++) {
                if (i > 0 && i % 3 === 0)
                    result = `${result} `
                result = `${result}${value[i]}`
            }

            if (result.length && result[result.length - 1] === ' ')
                result = result.substr(0, result.length - 1)

            return result.split('').reverse().join('')
        },
    })
}

(() => {
    function decimalAdjust(type, value, exp) {
        // Если степень не определена, либо равна нулю...
        if (typeof exp === 'undefined' || +exp === 0) {
            return Math[type](value);
        }
        value = +value;
        exp = +exp;
        // Если значение не является числом, либо степень не является целым числом...
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
            return NaN;
        }
        // Сдвиг разрядов
        value = value.toString().split('e');
        value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
        // Обратный сдвиг
        value = value.toString().split('e');
        return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
    }

    // Десятичное округление к ближайшему
    if (!Math.round10) {
        Math.round10 = function (value, exp) {
            return decimalAdjust('round', value, exp);
        };
    }

    // Десятичное округление вниз
    if (!Math.floor10) {
        Math.floor10 = function (value, exp) {
            return decimalAdjust('floor', value, exp);
        };
    }

    // Десятичное округление вверх
    if (!Math.ceil10) {
        Math.ceil10 = function (value, exp) {
            return decimalAdjust('ceil', value, exp);
        };
    }
})()
