import React, {Fragment, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentCertificate} from "../../../../store/reducers/eimzo/eimzoReducer";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {useForm} from "react-hook-form";
import checkPermission from "../../../../enum/permission/CheckPermission";
import {PermissionRoamingShipmentReject} from "../../../../enum/permission/RoamingPermission";
import toastPermission from "../../../../enum/permission/ToastPermission";
import {customerDeclineWaybillAsync} from "../../../../store/reducers/roaming/waybill/roamingWaybillReducer";
import SelectCertificateWrapperComponent from "../../../common/SelectCertificateWrapperComponent";
import IconButton from "../../../IconButton";
import {faBan} from "@fortawesome/free-solid-svg-icons";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import IconFolderCancel from "../../../../assets/icon/IconFolderCancel";

const CustomerDeclineWaybill = ({id, ...props}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const lang = useSelector(selectLang);
    const t = Translate;

    const {register, formState: {errors}, handleSubmit} = useForm()

    const handleClose = () => setShow(false);
    const handleShow = () => {
        if (dispatch(checkPermission(PermissionRoamingShipmentReject))) setShow(true)
        else dispatch(toastPermission())
    };

    const declineWaybillHandler = (formData) => {
        setLoading(true);

        customerDeclineWaybillAsync(currentCertificate, {
            id: id,
            comment: formData.comment,
        })
            .then(() => handleClose())
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }

    return (
        <Fragment>
            <SelectCertificateWrapperComponent
                cancelCertificate={true}
                submitButton={
                    <IconButton
                        size="sm"
                        {...props}
                        icon={''}
                        id="declineWaybill"
                        iconClassName="me-1"
                        onClick={handleShow}
                        className="btn-davr px-3 py-0 bg-white d-flex gap-2"
                        transform="shrink-3"
                        variant={'phoenix-secondary'}
                    >
                        <IconFolderCancel/>
                        {t(lang, 'roaming.common.decline')}
                    </IconButton>
                }
                openDialogButtonText={t(lang, 'roaming.common.decline')}
            />

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit(declineWaybillHandler)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t(lang, 'roaming.waybill.decline_waybill.modal.title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>{t(lang, 'roaming.common.label.comment')}</Form.Label>
                            <Form.Control
                                as="textarea" rows={3}
                                isInvalid={errors?.comment}
                                placeholder={t(lang, 'roaming.common.leave_comment')}
                                {...register('comment', {required: t(lang, 'roaming.common.forms.validations.is_required')})}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.comment?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>{t(lang, 'roaming.common.close')}</Button>

                        <Button variant="danger" type="submit">
                            {loading && <Spinner className="me-1" animation="border" role="status" size="sm"/>}
                            {t(lang, 'roaming.common.decline')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    );
};

export default CustomerDeclineWaybill;
