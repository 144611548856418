import React, {Fragment, useState} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import IconButton from "../../IconButton";
import {faTelegram} from "@fortawesome/free-brands-svg-icons";
import {Button, Modal} from "react-bootstrap";

const NotificationTelegramModal = () => {
    const [show, setShow] = useState(false);
    const t = Translate
    const lang = useSelector(selectLang)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Fragment>
            <IconButton
                variant="phoenix-secondary"
                size={"sm"}
                icon={faTelegram}
                iconClassName="fs-7 text-primary-davr align-middle"
                className={'btn-davr bg-white text-dark gap-2'}
                transform="shrink-3"
                onClick={handleShow}
            >
                {t(lang, "notification.table.telegram.settings")}
            </IconButton>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton className="d-flex align-items justify-content-between">
                    <p className="fs-0 m-0 fw-bold">{t(lang, "notification.table.telegram_bot.modal.title")}</p>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p className="mb-2">1. {t(lang, "notification.table.telegram_bot.instructions.click_button")} - <b>"{t(lang, "notification.table.telegram_bot.instructions.send_notification")}"</b>
                        </p>
                        <p className="mb-2">2. {t(lang, "notification.table.telegram_bot.instructions.open_app")} - <b>"Telegram"</b>
                        </p>
                        <p className="mb-2">3. {t(lang, "notification.table.telegram_bot.instructions.run_bot")} - <b>"/start"</b>
                        </p>
                        <p className="mb-2">4. {t(lang, "notification.table.telegram_bot.instructions.click_button")} - <b>"{t(lang, "notification.table.telegram_bot.instructions.send_phone")}"</b>
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size={"sm"} onClick={handleClose}>
                        {t(lang, "notification.close_button")}
                    </Button>
                    <IconButton variant="primary" icon={faTelegram} transform="shrink-3" size={"sm"}
                                iconClassName={"fs-7 align-middle"}
                                href="https://t.me/HippoNotificationBot" onClick={handleClose} target="_blank">
                        {t(lang, "notification.table.telegram_bot.modal.send")}
                    </IconButton>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default NotificationTelegramModal;
