import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectDateDayJSFormat, selectLang, selectNumberFormat} from "../../store/reducers/main/main";
import {selectActiveOrganization} from "../../store/reducers/auth/authReducer";
import {selectCurrentCertificate} from "../../store/reducers/eimzo/eimzoReducer";
import {
    loadRoamingActAsync, loadRoamingActsCountAsync,
    selectActsFilterOptions,
    selectCount,
    selectRoamingActs
} from "../../store/reducers/roaming/act/actReducer";
import {useNavigate} from "react-router-dom";
import {Translate} from "../../lang/lang";
import dayjs from "dayjs";
import Badge from "../../components/common/Badge";
import IconFolderDownload from "../../assets/icon/IconFolderDownload";
import numeral from "numeral";
import {GetViewerText, VIEW_DRAFT, VIEW_INBOX, VIEW_OUTBOX} from "../../enum/roaming/TypeViewers";
import {ActWrapperClass} from "../../enum/roaming/act/ActStatus";
import IconTransaction from "../../assets/icon/IconTransaction";
import RevealDropdown, {RevealDropdownTrigger} from "../../components/common/RevealDropdown";
import {Card, Dropdown} from "react-bootstrap";
import {DRAFT} from "../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faEye} from "@fortawesome/free-solid-svg-icons";
import AdvanceTableWrapper from "../../components/table/AdvanceTableWrapper";
import Flex from "../../components/common/Flex";
import AdvanceTable from "../../components/table/AdvanceTable";
import DashboardDatatable from "./DashboardDatatable";

const DashboardActDatatable = ({setActCount}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const activeOrganization = useSelector(selectActiveOrganization);
    const numberFormat = useSelector(selectNumberFormat);
    const roamingActs  = useSelector(selectRoamingActs);
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat)
    const count = useSelector(selectCount);
    const lang = useSelector(selectLang);
    const t = Translate;
    const [viewer, setViewer] = useState("inbox")

    useEffect(() => {
        let params = {page: 1, limit: 3,type_viewer: viewer}
        dispatch(loadRoamingActAsync(params));
        dispatch(loadRoamingActsCountAsync({...params}))
    }, [activeOrganization, viewer]);

    useEffect(() => {
        setActCount(count)
    }, [count]);

    const columns = [
        {
            accessor: 'contractor',
            Header: t(lang, "edi.order.datatable.header.row.contractor"),
            cellProps: {
                className: 'ps-3'
            },
            headerProps: {width: 300, className: 'ps-3'},
            Cell: ({row: {original}}) => {
                return activeOrganization.inn === original.customer.inn
                    ? <div className={'d-flex align-items-center gap-2'}>
                        <IconTransaction />
                        <div>
                             <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, "roaming.verification_act.datatable.body.row.verification_act", {
                                 name: original?.info?.number,
                                 date: dayjs(original?.info?.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                             })}
                             </span>
                            <span className={'fs-8 main-text fw-bold text-truncate'}>{original.executor.name}</span>
                            <i className={'d-block fs-9 second-text'}>{original.executor.inn || original.executor.pinfl}</i>
                        </div>
                    </div>
                    : <div className={'d-flex align-items-center gap-2'}>
                        <IconTransaction />
                        <div>
                             <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, "roaming.verification_act.datatable.body.row.verification_act", {
                                 name: original?.info?.number,
                                 date: dayjs(original?.info?.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                             })}
                             </span>
                            <span className={'fs-8 main-text fw-bold text-truncate'}>{original.customer.name}</span>
                            <i className={'d-block fs-9 second-text'}>{original.customer.inn || original.customer.pinfl}</i>
                        </div>
                    </div>
            }
        },
        {
            accessor: 'total',
            Header: t(lang,"roaming.act.datatable.header.row.total_sum"),
            cellProps: {
                className: 'text-end'
            },
            headerProps: {className: "text-end"},
            Cell: ({row: {original}}) => {
                let subtotal = 0
                if (original?.content === undefined){
                    subtotal = original?.items?.reduce((acc, item) => acc + item.total, 0);
                }else {
                    const Jsonparse = JSON.parse(original?.content)
                    subtotal = Jsonparse?.ProductList?.Products.reduce((acc, item) => acc + item.TotalSum, 0)
                }
                return (
                    // <span>{numeral(subtotal).format(numberFormat)}</span>
                    <Fragment>
                        <strong>{numeral.formats[numberFormat].format(subtotal)}</strong>
                        <span className={'ms-1 second-text fs-9'}>UZS</span>
                    </Fragment>
                )
            }
        },
        viewer !== VIEW_DRAFT && {
            accessor: 'status',
            Header: t(lang, "edi.order.datatable.header.row.status"),
            cellProps: {
                className: 'text-end pe-3'
            },
            headerProps: {
                className: 'text-end pe-3'
            },
            Cell: ({row: {original}}) => {
                const actWrapper = new ActWrapperClass(original)

                return <div className={'d-inline-block text-center'}>
                    <Badge
                        variant={"phoenix"}
                        bg={actWrapper.getRoamingStatusVariant(activeOrganization.pinfl, activeOrganization.inn)}
                        className={'px-3 py-1 fs-9 text-uppercase w-100 text-decoration-none fw-medium border-0 rounded-4'}
                    >
                        {t(lang, actWrapper.getRoamingStatus(activeOrganization.pinfl,activeOrganization.inn))}
                    </Badge>
                </div>
            }
        },
        {
            accessor: 'action',
            Header: "",
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                        <RevealDropdown>
                            <Dropdown.Item
                                onClick={() => {
                                    if (viewer === DRAFT) {
                                        navigate('/roaming/draft-act/' + original.id)
                                    } else {
                                        navigate('/roaming/act/' +  original.id)
                                    }
                                }}>
                                <span>{t(lang, "roaming.invoices.table_header.show")}</span>
                            </Dropdown.Item>
                        </RevealDropdown>
                    </RevealDropdownTrigger>
                );
            }
        },
    ].filter(column => typeof column === 'object' && column !== null)


    return (
        <DashboardDatatable columns={columns} data={roamingActs} setViewer={setViewer} />
    );
};

export default DashboardActDatatable;
