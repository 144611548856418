import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import SelectCompanyCatalog from "../../../IKPU/SelectCompanyCatalog";
import ReactSelect from "react-select";
import classNames from "classnames";
import SelectMeasurement from "../../../common/SelectMeasurement";
import IconButton from "../../../IconButton";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import Cleave from "cleave.js/react";
import SelectNdsVatRate from "../../../common/SelectNdsVatRate";
import {Form} from "react-bootstrap";
import Badge from "../../../common/Badge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Product = ({ index, removeFieldProduct, onChangeAmounts }) => {
    const lang = useSelector(selectLang)
    const t = Translate
    const { register, watch, getValues, setValue, formState: { errors: productError }, trigger, control } = useFormContext()
    const product = watch(`products.${index}`)
    const errors = productError.products || []
    const catalog = useWatch({name: `products.${index}.catalog`, exact: true})
    const packages = useWatch({name: `products.${index}.packages`, exact: true})
    const { count, summa, vatRate, measureId, packageName, packageCode } = product
    const customStyles = {
        measurement: {
            control: base => ({
                ...base,
                minHeight: 34,
                height: 34
            })
        }
    };

    useEffect(() => {
        if(count | summa | vatRate){
            let nCount = +count
            let nSumma = +summa
            let nVatRate = +vatRate
            let deliverySum = nCount * nSumma
            let vatSum = (deliverySum / 100) * nVatRate
            let deliverySumWithVat = deliverySum + vatSum
            deliverySum = deliverySum.toFixed(2)
            vatSum = vatSum.toFixed(2)
            deliverySumWithVat = deliverySumWithVat.toFixed(2)
            setValue(`products.${index}.deliverySum`, deliverySum)
            setValue(`products.${index}.vatSum`, vatSum)
            setValue(`products.${index}.deliverySumWithVat`, deliverySumWithVat)
            onChangeAmounts()
        }
    }, [count, summa, vatRate]);

    const onChangeCatalog = (catalog) => {
        setValue(`products.${index}.catalog`, catalog)
        setValue(`products.${index}.catalogName`, catalog?.name)
        trigger(`products.${index}.catalogCode`)
    }

    useEffect(() => {
        if (catalog && packages) {
            if (measureId !== "")
                setValue(`products.${index}.measureId`, "")

            const name = lang === "ru" ? packages.name_ru : packages.name_uz
            if (packageName !== name)
                setValue(`products.${index}.packageName`, name)
            if (packageCode !== packages.code)
                setValue(`products.${index}.packageCode`, packages.code);
        } else {
            if (packageName !== null)
                setValue(`products.${index}.packageName`, null)
            if (packageCode !== null)
                setValue(`products.${index}.packageCode`, null);
        }
    }, [packages, catalog])

    const onChangeVatRate = (data) => {
        if(data === null){
            setValue(`products.${index}.vatRate`, 0)
            setValue(`products.${index}.withoutVat`, true)
        }
        else {
            setValue(`products.${index}.vatRate`, data)
            setValue(`products.${index}.withoutVat`, false)
        }
    }


    return (
        <tr>
            <td className="p-1 text-center">
                {index+1}
            </td>
            <td className="p-1">
                <Form.Control
                    style={{height: '37px'}}
                    {...register(`products.${index}.name`, {
                        // required: "edi.common.forms.validations.is_required"
                    })}
                    className={`${errors[index]?.name && 'is-invalid'} p-1`}
                />
                <Form.Control.Feedback type="invalid">
                    {t(lang, errors[index]?.name?.message)}
                </Form.Control.Feedback>
            </td>
            <td className="p-1" >
                <Controller
                    name={`products.${index}.catalogCode`}
                    control={control}
                    rules={{
                        required: "edi.common.forms.validations.is_required"
                    }}
                    render={({field}) => (
                        <SelectCompanyCatalog
                            fullWidth
                            variant={errors[index]?.catalogCode ? 'outline-danger' : 'outline-primary'}
                            textButton={product.catalogCode || 'Выбрать'}
                            defaultCatalogCode={product.catalogCode}
                            onChange={(c) => {
                                onChangeCatalog(c)
                                field.onChange(c?.class_code || null)
                            }}
                            className={`${errors[index]?.catalogCode && 'is-invalid'} py-1`}
                            style={{height: '37px'}}
                        />
                    )}
                />
                <Form.Control.Feedback type="invalid">
                    {t(lang, errors[index]?.catalogCode?.message)}
                </Form.Control.Feedback>
            </td>
            <td className="p-1" >
                <Form.Control
                    style={{height: '37px'}}
                    {...register(`products.${index}.barCode`)}
                    className="p-1"
                />
            </td>

            {catalog?.use_package ?
                <td className="p-1">
                    <Controller name={`products.${index}.packages`}
                                rules={{
                                    required: t(lang, "roaming.validations.is_required")
                                }}
                                render={({field}) => (
                                    <ReactSelect options={catalog?.package_names}
                                                 placeholder={t(lang, "roaming.common.select.placeholder")}
                                                 getOptionLabel={option => lang === "ru" ? option.name_ru : option.name_uz}
                                                 getOptionValue={option => option.code}
                                                 defaultValue={catalog?.package_names.find(p => p.code === field.value?.code)}
                                                 menuPortalTarget={document.body}
                                                 hideSelectedOptions
                                                 className={classNames({
                                                     'is-invalid': errors[index]?.packages
                                                 })}
                                                 isClearable
                                                 onChange={field.onChange}
                                                 style={{height: '37px'}}
                                    />
                                )}
                    />

                    <Form.Control.Feedback
                        type="invalid">{errors[index]?.packages?.message}</Form.Control.Feedback>
                </td>
                :
                <td className="p-1">
                    <Controller
                        name={`products.${index}.measureId`}
                        control={control}
                        rules={{
                            // required: "edi.common.forms.validations.is_required"
                        }}
                        render={({field}) => (
                            <SelectMeasurement
                                onChange={(data) => field.onChange(data.measureId)}
                                placeholder=""
                                defaultMeasureId={field.value}
                                styles={customStyles.measurement}
                                className={`${errors[index]?.measureId && 'is-invalid'}`}
                                menuPortalTarget={document.body}
                                style={{height: '37px'}}
                            />
                        )}
                    />
                    <Form.Control.Feedback type="invalid">
                        {t(lang, errors[index]?.measureId?.message)}
                    </Form.Control.Feedback>
                </td>
            }

            <td className="p-1">
                <Controller
                    name={`products.${index}.count`}
                    control={control}
                    rules={{
                        // required: "edi.common.forms.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            style={{height: '37px'}}
                            onChange={({target: {value}}) => field.onChange(value?.replace(/(?!-)[^0-9.]/g, ""))}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 2
                            }}
                            value={field.value}
                            className={`${errors[index]?.count && 'is-invalid'} p-1 form-control`}
                        />
                    )}
                />
                <Form.Control.Feedback type="invalid">
                    {t(lang, errors[index]?.count?.message)}
                </Form.Control.Feedback>
            </td>
            <td className="p-1">
                <Controller
                    name={`products.${index}.summa`}
                    control={control}
                    rules={{
                        // required: "edi.common.forms.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            style={{height: '37px'}}
                            onChange={({target: {value}}) => field.onChange(value?.replace(/(?!-)[^0-9.]/g, ""))}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 2
                            }}
                            value={field.value}
                            className={`${errors[index]?.summa && 'is-invalid'} p-1 form-control`}
                        />
                    )}
                />
                <Form.Control.Feedback type="invalid">
                    {t(lang, errors[index]?.summa?.message)}
                </Form.Control.Feedback>
            </td>
            <td className="p-1">
                <Controller
                    name={`products.${index}.deliverySum`}
                    control={control}
                    rules={{
                        // required: "edi.common.forms.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            style={{height: '37px'}}
                            {...field}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 2
                            }}
                            className={`${errors[index]?.deliverySum && 'is-invalid'} p-1 form-control`}
                        />
                    )}
                />
                <Form.Control.Feedback type="invalid">
                    {t(lang, errors[index]?.deliverySum?.message)}
                </Form.Control.Feedback>
            </td>
            <td className="p-1">
                <Controller
                    name={`products.${index}.vatRate`}
                    control={control}
                    rules={{
                        // required: "edi.common.forms.validations.is_required"
                    }}
                    render={() => (
                        <SelectNdsVatRate
                            onChange={(data) => onChangeVatRate(data)}
                            defaultValue={vatRate}
                            style={{height: '37px'}}
                        />
                    )}
                />
            </td>
            <td className="p-1">
                <Controller
                    name={`products.${index}.vatSum`}
                    control={control}
                    rules={{
                        // required: "edi.common.forms.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            style={{height: '37px'}}
                            {...field}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 2
                            }}
                            className={`${errors[index]?.vatSum && 'is-invalid'} p-1 form-control`}
                        />
                    )}
                />
                <Form.Control.Feedback type="invalid">
                    {t(lang, errors[index]?.vatSum?.message)}
                </Form.Control.Feedback>
            </td>
            <td className="p-1">
                <Controller
                    name={`products.${index}.deliverySumWithVat`}
                    control={control}
                    rules={{
                        // required: "edi.common.forms.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            style={{height: '37px'}}
                            {...field}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 2
                            }}
                            className={`${errors[index]?.deliverySumWithVat && 'is-invalid'} p-1 form-control`}
                        />
                    )}
                />
                <Form.Control.Feedback type="invalid">
                    {t(lang, errors[index]?.deliverySumWithVat?.message)}
                </Form.Control.Feedback>
            </td>
            <td className="text-center p-1">
                {
                    getValues("products").length > 1 && <Badge
                        className={'px-2 py-2 fs-8 rounded-3 cursor-pointer'}
                        bg="danger-davr"
                        variant="phoenix"
                    >
                        <FontAwesomeIcon icon={faTrashAlt} onClick={() => removeFieldProduct(index)}  />
                    </Badge>
                }

            </td>
        </tr>

    );
};

export default Product;
