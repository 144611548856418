import { Nav, Navbar } from 'react-bootstrap';
import { routes } from '../../../sitemap';
import { capitalize } from '../../../pheonix/helpers/utils';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import {
    UilArrowFromRight,
    UilLeftArrowToLeft
} from '@iconscout/react-unicons';
import { useAppContext } from '../../../pheonix/providers/AppProvider';
import classNames from 'classnames';
import Button from '../../common/Button';
import NavbarTopNav from '../navbar-top/NavbarTopNav';
import { useBreakpoints } from '../../../pheonix/providers/BreakpointsProvider';
import NavbarVerticalCollapseProvider from './NavbarVerticalCollapseProvider';
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {Link} from "react-router-dom";

const NavbarVertical = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const {
        config: {
            navbarPosition,
            openNavbarVertical,
            navbarVerticalAppearance,
            isNavbarVerticalCollapsed
        },
        setConfig
    } = useAppContext();

    const { breakpoints } = useBreakpoints();

    return (
        <NavbarVerticalCollapseProvider>
            <Navbar
                className={classNames('navbar-vertical', {
                    'navbar-darker': navbarVerticalAppearance === 'darker',
                })}
                expand="lg"
                variant=""
            >
                <Navbar.Collapse id="navbarVerticalCollapse" in={openNavbarVertical} >
                    <div className="navbar-vertical-content">
                        <Nav className="flex-column" as="ul" id="navbarVerticalNav">
                            {routes.map(route => (
                                <Nav.Item key={route.label}>
                                    {!route.labelDisabled && (
                                        <>
                                            <p className="navbar-vertical-label">
                                                {capitalize(route.label)}
                                            </p>
                                            <hr className="navbar-vertical-line" />
                                        </>
                                    )}
                                    <NavbarVerticalMenu level={1} routes={route.pages} />
                                </Nav.Item>
                            ))}
                        </Nav>

                        {navbarPosition === 'combo' && breakpoints.down('lg') && (
                            <div className="move-container">
                                <div className="navbar-vertical-divider">
                                    <hr className="navbar-vertical-hr" />
                                </div>
                                <NavbarTopNav />
                            </div>
                        )}
                    </div>
                </Navbar.Collapse>
                <div className="navbar-vertical-footer">
                    <Button
                        className="navbar-vertical-toggle border-0 fw-semi-bold w-100 white-space-nowrap d-flex align-items-center"
                        onClick={() => {
                            setConfig({
                                isNavbarVerticalCollapsed: !isNavbarVerticalCollapsed
                            });
                        }}
                    >
                        {isNavbarVerticalCollapsed ? (
                            <UilArrowFromRight size={16} className="mb-1" />
                        ) : (
                            <>
                                <UilLeftArrowToLeft size={16} />
                                <span className="ms-2">{t(lang, 'task.board.common.close')}</span>
                            </>
                        )}
                    </Button>
                </div>
            </Navbar>
        </NavbarVerticalCollapseProvider>
    );
};

export default NavbarVertical;
