import { initialConfig } from '../../config';
import { setItemToStore } from '../helpers/utils';

export const configReducer = (state, action) => {
    switch (action.type) {
        case 'SET_CONFIG': {
            const { payload } = action;
            Object.keys(payload).forEach(key => {
                if (
                    [
                        'theme',
                        'navbarTopShape',
                        'navbarPosition',
                        'navbarTopAppearance',
                        'navbarVerticalAppearance',
                        'isRTL',
                        'isNavbarVerticalCollapsed'
                    ].includes(key)
                ) {
                    setItemToStore(key, String(payload[key]));
                }
            });
            return {
                ...state,
                ...payload
            };
        }
        case 'REFRESH':
            return {
                ...state
            };
        case 'RESET':
            localStorage.clear();
            return {
                ...initialConfig
            };
        default:
            return state;
    }
};
