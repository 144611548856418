//declaring statuses
export const CustomerSent = 7;
export const CustomerCanceled = 17;
export const PersonDeclined = 9;
export const PersonAccepted = 15;
export const ExecutorDeclined = 20;
export const ExecutorAccepted = 30;

//status badge-variants
const DARK = 'dark';
const PRIMARY = 'primary';
const SECONDARY = 'secondary-davr';
const INFO = 'primary-davr';
const WARNING = 'warning-davr';
const DANGER = 'danger-davr';
const SUCCESS = 'success-davr';

//declaring viewers
export const VIEWER_CUSTOMER = 'outbox';
export const VIEWER_EXECUTOR = 'inbox';
export const VIEWER_PERSON = 'person';
export const VIEWER_DRAFT = 'draft';

//exporting class for longer usage
export class EmpowermentWrapper {
    _empowerment;

    constructor(empowerment) {
        let vm = this;
        vm._empowerment = empowerment;
    }

    GetStatuses(viewer) {
        switch(viewer) {
            case VIEWER_CUSTOMER:
                return [
                    {id: CustomerSent, name: "roaming.empowerment.status.7.customer"},
                    {id: CustomerCanceled, name: "roaming.empowerment.status.17.customer"},
                    {id: PersonDeclined, name: "roaming.empowerment.status.9.customer"},
                    {id: PersonAccepted, name: "roaming.empowerment.status.15.customer"},
                    {id: ExecutorDeclined, name: "roaming.empowerment.status.20.customer"},
                    {id: ExecutorAccepted, name: "roaming.empowerment.status.30.customer"},
                ]
            case VIEWER_EXECUTOR:
                return [
                    {id: CustomerCanceled, name: "roaming.empowerment.status.17.executor"},
                    {id: PersonDeclined, name: "roaming.empowerment.status.9.executor"},
                    {id: PersonAccepted, name: "roaming.empowerment.status.15.executor"},
                    {id: ExecutorDeclined, name: "roaming.empowerment.status.20.executor"},
                    {id: ExecutorAccepted, name: "roaming.empowerment.status.30.executor"},
                ]
            case VIEWER_PERSON:
                return [
                    {id: CustomerSent, name: "roaming.empowerment.status.7.person"},
                    {id: CustomerCanceled, name: "roaming.empowerment.status.17.person"},
                    {id: PersonDeclined, name: "roaming.empowerment.status.9.person"},
                    {id: PersonAccepted, name: "roaming.empowerment.status.15.person"},
                    {id: ExecutorDeclined, name: "roaming.empowerment.status.20.person"},
                    {id: ExecutorAccepted, name: "roaming.empowerment.status.30.person"},
                ]
        }
    }
    GetStatusTranslationKey(inn, pinfl) {
        let vm = this;
        if(vm.isCustomer(inn, pinfl)) {
            switch(vm._empowerment.status) {
                case CustomerSent:
                    return "roaming.empowerment.status.7.customer"
                case CustomerCanceled:
                    return "roaming.empowerment.status.17.customer"
                case PersonDeclined:
                    return "roaming.empowerment.status.9.customer"
                case PersonAccepted:
                    return "roaming.empowerment.status.15.customer"
                case ExecutorDeclined:
                    return "roaming.empowerment.status.20.customer"
                case ExecutorAccepted:
                    return "roaming.empowerment.status.30.customer"
            }
        }
        else if(vm.isExecutor(inn, pinfl)) {
            switch (vm._empowerment.status) {
                case CustomerCanceled:
                    return "roaming.empowerment.status.17.executor"
                case PersonDeclined:
                    return "roaming.empowerment.status.9.executor";
                case PersonAccepted:
                    return "roaming.empowerment.status.15.executor"
                case ExecutorDeclined:
                    return "roaming.empowerment.status.20.executor"
                case ExecutorAccepted:
                    return "roaming.empowerment.status.30.executor"
            }
        }
        else {
            switch(vm._empowerment.status) {
                case CustomerSent:
                    return "roaming.empowerment.status.7.person"
                case CustomerCanceled:
                    return "roaming.empowerment.status.17.person"
                case PersonDeclined:
                    return "roaming.empowerment.status.9.person"
                case PersonAccepted:
                    return "roaming.empowerment.status.15.person"
                case ExecutorDeclined:
                    return "roaming.empowerment.status.20.person"
                case ExecutorAccepted:
                    return "roaming.empowerment.status.30.person"
            }
        }
    }
    GetStatusBadgeVariant(inn, pinfl) {
        let vm = this;
        if(vm.isCustomer(inn, pinfl)) {
            switch (vm._empowerment.status) {
                case CustomerSent:
                    return INFO
                case CustomerCanceled:
                    return SECONDARY
                case PersonDeclined:
                    return DANGER
                case PersonAccepted:
                    return SUCCESS
                case ExecutorDeclined:
                    return DANGER
                case ExecutorAccepted:
                    return SUCCESS
            }
        }
        else if(vm.isExecutor(inn, pinfl)) {
            switch (vm._empowerment.status) {
                case CustomerCanceled:
                    return WARNING;
                case PersonDeclined:
                    return DANGER;
                case PersonAccepted:
                    return INFO
                case ExecutorDeclined:
                    return DANGER
                case ExecutorAccepted:
                    return SUCCESS
            }
        }
        else {
            switch (vm._empowerment.status) {
                case CustomerSent:
                    return INFO
                case CustomerCanceled:
                    return WARNING
                case PersonDeclined:
                    return DANGER
                case PersonAccepted:
                    return SUCCESS
                case ExecutorDeclined:
                    return DANGER
                case ExecutorAccepted:
                    return SUCCESS
            }
        }
    }

    allowSync() {
        let vm = this;
        switch (vm._empowerment.status) {
            case CustomerSent:
                return true;
            case PersonAccepted:
                return true;
            default:
                return false;
        }
    }
    allowCustomerCancel(inn, pinfl) {
        let vm = this;
        return vm.isCustomer(inn, pinfl) && vm._empowerment.status === CustomerSent;
    }
    allowPersonAccept(inn, pinfl) {
        let vm = this;
        return vm.isPerson(inn, pinfl) && vm._empowerment.status === CustomerSent;
    }
    allowPersonDecline(inn, pinfl) {
        let vm = this;
        return vm.isPerson(inn, pinfl) && vm._empowerment.status === CustomerSent;
    }
    allowExecutorAccept(inn, pinfl) {
        let vm = this;
        return vm.isExecutor(inn, pinfl) && vm._empowerment.status === PersonAccepted;
    }
    allowExecutorDecline(inn, pinfl) {
        let vm = this;
        return vm.isExecutor(inn, pinfl) && vm._empowerment.status === PersonAccepted;
    }

    allowCustomerCopy(inn, pinfl) {
        let vm = this;
        if(vm._empowerment.customer.inn === inn || vm._empowerment.customer.pinfl === pinfl)
            return true;
    }
    isCustomer(inn, pinfl) {
        let vm = this;
        if(vm._empowerment.customer.inn === inn || vm._empowerment.customer.pinfl === pinfl)
            return true;
    }
    isExecutor(inn, pinfl) {
        let vm = this;
        if(vm._empowerment.executor.inn === inn || vm._empowerment.executor.pinfl === pinfl)
            return true;
    }
    isPerson(inn, pinfl) {
        let vm = this;
        if(vm._empowerment.person_info.person_inn === inn || vm._empowerment.person_info.person_inn === pinfl)
            return true;
    }
}
