import {Translate} from "../../lang/lang";
import toast from "react-hot-toast";

const toastPermission = () => (_,getState) => {
    const lang = getState().main?.settings?.settings?.lang?.find((l) => l?.is_active)?.code || 'ru'
    const t = Translate

    return toast(t(lang, 'system.permission.toast'), {
        position: "top-right",
        duration: 5000,
    })
};

export default toastPermission;
