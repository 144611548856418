import {
    UilFileGraph,
    UilFileLandscape, UilFileLandscapeAlt,
    UilFilePlusAlt, UilFileShareAlt,
    UilFileShieldAlt,
    UilFilesLandscapes, UilFilesLandscapesAlt,
    UilInvoice, UilPostcard
} from "@iconscout/react-unicons";

export const SYSTEM_PROVIDER_INN = "308011454"

export const COMPANY_CATALOGS_INITIALIZATION_STATE_NOT_STARTED = 0
export const COMPANY_CATALOGS_INITIALIZATION_STATE_LOADING = 1
export const COMPANY_CATALOGS_INITIALIZATION_STATE_COMPLETE = 2

export const MEASUREMENTS_INITIALIZATION_STATE_NOT_STARTED = 0
export const MEASUREMENTS_INITIALIZATION_STATE_LOADING = 1
export const MEASUREMENTS_INITIALIZATION_STATE_COMPLETE = 2

export const roamingServices = [
    {
        to: "/roaming/invoice/send",
        title: "roaming.dashboard.invoices.title",
        description: "roaming.dashboard.invoices.description",
        className: "border-end border-bottom",
        icon: UilInvoice
    },
    {
        to: "/roaming/contract/send",
        title: "roaming.dashboard.contracts.title",
        description: "roaming.dashboard.contracts.description",
        className: "border-end border-bottom",
        icon: UilFilePlusAlt
    },
    {
        to: "/roaming/empowerment/send",
        title: "roaming.dashboard.empowerments",
        description: "roaming.dashboard.empowerments_title",
        className: "border-end border-bottom",
        icon: UilFileShieldAlt
    },
    {
        to: "/roaming/verification-act/send",
        title: "roaming.dashboard.verification_act.title",
        description: "roaming.dashboard.verification_act.description",
        className: "border-end border-bottom",
        icon: UilFilesLandscapesAlt
    },
    {
        to: "/roaming/waybill/send",
        title: "roaming.dashboard.waybill.title",
        description: "roaming.dashboard.waybill.subtitle",
        className: "border-bottom border-end",
        icon: UilPostcard
    },
    {
        to: "/roaming/act/register",
        title: "roaming.act.dashboard.title",
        description: "roaming.act.dashboard.description",
        className: "border-end border-bottom",
        icon: UilFileLandscapeAlt
    },
    {
        to: "/roaming/free-forms/send",
        title: "roaming.dashboard.free_form_document.title",
        description: "roaming.dashboard.free_form_document.description",
        className: "border-end",
        icon: UilFileShareAlt
    },
]
