import axios from "axios";

class ProtocolService {
    getOrderProtocolInfo(id) {
        return axios.get(`/protocol/order/get/${id}`)
    }

    getReturnOrderProtocolInfo(id) {
        return axios.get(`/protocol/return-order/get/${id}`)
    }
}

const protocolService = new ProtocolService();

export default protocolService;
