import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    changeFreeFormsFilterOptions,
    selectFreeFormDocumentsCount,
    selectFreeFormDocumentsFilterOptions
} from "../../../store/reducers/roaming/free-form/freeFormDocumentReducer";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import IconButton from "../../IconButton";
import {faFilter, faPlus, faRefresh} from "@fortawesome/free-solid-svg-icons";
import {Link, useNavigate} from "react-router-dom";
import Badge from "../../common/Badge";
import {Col, Collapse, Form, Row} from "react-bootstrap";
import FreeFormDocumentDataTableFilter from "./FreeFormDocumentDataTableFilter";
import {scrollEvent} from "../../../helpers/scrollEvent";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import IconPlus from "../../../assets/icon/IconPlus";
import IconFilter from "../../../assets/icon/IconFilter";
import IconStroke from "../../../assets/icon/IconStroke";
import IconCaretDown from "../../../assets/icon/IconCaretDown";

const FreeFormDocumentDataTableHeader = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const freeFormDocumentCount = useSelector(selectFreeFormDocumentsCount)
    const filter = useSelector(selectFreeFormDocumentsFilterOptions);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            created_on_start: filter?.created_on_start && dayjs(filter?.created_on_start).toDate() || null,
            created_on_end: filter?.created_on_end && dayjs(filter?.created_on_end).toDate() || null,
            updated_on_start: filter?.updated_on_start && dayjs(filter?.updated_on_start).toDate() || null,
            updated_on_end: filter?.updated_on_end && dayjs(filter?.updated_on_end).toDate() || null,
            document_number: filter?.document_number || null,
            contractor_inn: filter?.contractor_inn || null,
            status: filter?.status || null,
        }
    });

    const toggleCollapse = () => setIsCollapsed(!isCollapsed);
    const onSubmitFilter = ({document_number, created_on_start, created_on_end, updated_on_start, updated_on_end, contractor_inn, ...filters}) => {
        created_on_start = created_on_start && dayjs(created_on_start).format("YYYY-MM-DD");
        created_on_end = created_on_end && dayjs(created_on_end).format("YYYY-MM-DD");
        updated_on_start = updated_on_start && dayjs(updated_on_start).format("YYYY-MM-DD 00:00:00");
        updated_on_end = updated_on_end && dayjs(updated_on_end).format("YYYY-MM-DD 23:59:59");

        dispatch(changeFreeFormsFilterOptions({
            document_number: document_number || null,
            created_on_start: created_on_start,
            created_on_end: created_on_end,
            updated_on_start: updated_on_start,
            updated_on_end: updated_on_end,
            contractor_inn: contractor_inn || null,
            page: 1,
            ...filters,
        }));
    };

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('roaming-free-form-data-table-header'))
        return () => window.removeEventListener('scroll', () => scrollEvent('roaming-free-form-data-table-header'))
    }, []);

    return (
        <Fragment>
            <Row id={'roaming-free-form-data-table-header'} className="flex-between-center align-items-center my-3 position-sticky z-index-1" style={{top: '4rem'}}>
                <Collapse in={isCollapsed}>
                    <Col sm={12}>
                        <FormProvider {...methods}>
                            <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                                <FreeFormDocumentDataTableFilter/>
                            </Form>
                        </FormProvider>
                        <hr/>
                    </Col>
                </Collapse>

                <Col cols={'auto'} className={'d-flex gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'} style={{width: 35, height: 35}}>
                        <IconArrowLeft />
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.free_form_document.datatable.title")}: {freeFormDocumentCount}</h5>
                        <span className={'second-text fs-9'}>{t(lang, 'edi.navbar.routes.documents')}</span>
                    </div>
                </Col>

                <Col xs={8} sm="auto" className={'d-flex gap-2'}>
                    <IconButton
                        size="sm"
                        icon={faRefresh}
                        className="btn-davr px-4 py-0 bg-white"
                        iconClassName={'fs-7 text-primary-davr'}
                        transform="shrink-3"
                        variant="phoenix-secondary"
                        onClick={methods.handleSubmit(onSubmitFilter)}
                    >
                            <span className="d-none d-sm-inline-block ms-1 fw-semi-bold">
                                {t(lang, 'roaming.free_form_document.datatable.header.navigation.refresh')}
                            </span>
                    </IconButton>

                    <IconButton
                        onClick={toggleCollapse}
                        className="btn-davr ps-2 pe-1 py-0 bg-white text-dark gap-2"
                    >
                        <div className={'d-flex align-items-center'}>
                            <IconFilter />
                            <span className="d-none d-sm-inline-block ms-1 fs-8 text-nowrap fs-md-9">{t(lang, "roaming.invoices.table_header.filter")}</span>
                        </div>
                        <div className={'d-flex justify-content-between gap-2'}>
                            <IconStroke />
                            <IconCaretDown />
                        </div>
                    </IconButton>

                    <Link to="/roaming/free-forms/send">
                        <IconButton
                            variant="phoenix-secondary"
                            size="sm"
                            transform="shrink-3"
                            className="btn-davr px-3 py-0 bg-primary-davr"
                        >
                            <IconPlus />
                            <span className="d-none d-sm-inline-block ms-1 fs-8 text-white text-nowrap fs-md-9">
                                {t(lang, "edi.common.button.create")}
                            </span>
                        </IconButton>
                    </Link>
                </Col>
            </Row>
        </Fragment>
    );
};

export default FreeFormDocumentDataTableHeader;
