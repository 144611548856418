import React, {Fragment, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    activateCertificateAsync,
    selectCurrentCertificate,
    setCurrentCertificate
} from "../../store/reducers/eimzo/eimzoReducer";
import {selectLang} from "../../store/reducers/main/main";
import {Translate} from "../../lang/lang";
import checkPermission from "../../enum/permission/CheckPermission";
import toastPermission from "../../enum/permission/ToastPermission";
import toast from "react-hot-toast";
import IconButton from "../IconButton";
import {faSignature} from "@fortawesome/free-solid-svg-icons";
import {Button, Modal} from "react-bootstrap";
import SelectCertificate from "../eimzo/SelectCertificate";
import IconSignature from "../../assets/icon/IconSignature";
import classNames from "classnames";

const SelectCertificateWrapperComponent = ({submitButton, openDialogButtonText, permission, icon, cancelCertificate, alertBtn, cancel, ...props}) => {
    const submitButtonId = submitButton?.props?.id;
    const currentCertificate = useSelector(selectCurrentCertificate);
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const handleShow = () => {
        setShow(true)
    };

    const onSelectCertificate = certificate => {
        activateCertificateAsync(certificate)
            .then(activatedCertificate => {
                dispatch(setCurrentCertificate(activatedCertificate));
                handleClose();
                submitButtonId && document.getElementById(submitButtonId).click();
                toast.success('Certificate success activated');
            })
            .catch(e => {
                toast.error('Error')
                console.log(e)
            });
    }
    const handleClose = () => setShow(false);

    return (
        <Fragment>
            {currentCertificate
                ? <Fragment>{submitButton}</Fragment>
                : <Fragment>
                    {cancelCertificate ? submitButton : <IconButton
                        size="sm"
                        variant={'phoenix-secondary'}
                        className={classNames('',{
                            'btn-link text-dark' : alertBtn,
                            'btn-davr bg-primary-davr gap-1 text-white': !cancel,
                            'btn-white-davr': cancel,
                        })}
                        onClick={handleShow}
                        {...props}
                    >
                        {(!alertBtn && !cancel) && <IconSignature/>}
                        <span className={'d-none d-sm-inline-block ms-1 fs-8 text-nowrap fs-md-9'}>{openDialogButtonText}</span>
                    </IconButton>}
                </Fragment>
            }
            <Modal backdrop="static" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, 'roaming.components.select_certificate.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SelectCertificate
                        onChange={onSelectCertificate}
                        placeholder={t(lang, 'roaming.components.select_certificate.title')}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose}>{t(lang, 'edi.common.button.close')}</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default SelectCertificateWrapperComponent;
