import React, {Fragment, useEffect, useState} from "react";
import classNames from "classnames";
import {Col, Form, Modal, Row} from "react-bootstrap";
import Select from "react-select";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {Translate} from "../../lang/lang";
import { selectCompanyCatalogs } from "../../store/reducers/roaming/roamingReducer";
import IconAlert from "../common/IconAlert";
import Button from "../common/Button";
import Badge from "../common/Badge";
import FalconCloseButton from "../common/FalconCloseButton";
import CustomFloatingLabelSelect from "../floating-custom-select/CustomFloatingLabelSelect";

const SelectCompanyCatalog = ({
                                  onChange,
                                  fullWidth,
                                  className,
                                  textButton,
                                  defaultCatalogCode,
                                  defaultLgota,
                                  ...props
                              }) => {
    const [catalog, setCatalog] = useState(null);
    const [lgota, setLgota] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const companyCatalogs = useSelector(selectCompanyCatalogs);
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        if (defaultCatalogCode && typeof (defaultCatalogCode) === 'string')
            setCatalog(companyCatalogs.find((catalog) => catalog.class_code === defaultCatalogCode) || null)
    }, [defaultCatalogCode, companyCatalogs])

    useEffect(() => {
        if (catalog)
            setLgota(catalog?.lgotas.find((catalogLgota) => catalogLgota.id === defaultLgota || catalogLgota.new_id === defaultLgota) || null)
    }, [defaultLgota, catalog])

    const getOptionCatalogValue = (option) => option.class_code;
    const getOptionCatalogLabel = (option) => `${option.class_code} ${option.name}`;

    const formatCatalogLabel = (option) => {
        return (
            <div className="fs--1">
                <span className="text-black fw-bold me-1">{option.class_code}</span>-
                <span className="ms-1 text-facebook">{option.name}</span>
            </div>
        )
    }

    const getOptionLgotaValue = (option) => option.new_id || option.id;
    const getOptionLgotaLabel = (option) => `${option.new_id} ${option.id} ${option.name}`;

    const formatLgotaLabel = (option) => {
        return (
            <div className="fs--1">
                <h5 className="fs--1 fw-bold">
                    {option.type === 1 &&
                        <span className="text-success">{t(lang, "edi.common.privilege.type.1")}</span>}
                    {option.type === 2 &&
                        <span className="text-primary">{t(lang, "edi.common.privilege.type.2")}</span>}
                </h5>
                <p>
                    <span className="text-success fw-bold me-1">{option.new_id};</span>
                    <span className="text-warning fw-bold me-1">{option.id}</span>-
                    <span className="ms-1 text-facebook">{option.name}</span>
                </p>
            </div>
        )
    };

    const onSaveHandler = () => {
        onChange(catalog, lgota)
        handleClose()
    }

    const handleClose = () => {
        setModalShow(false);
    }
    const handleShow = () => setModalShow(true);

    return (
        <Fragment>
            <Button bg={"phoenix-secondary"} className={classNames("w-100 fw-semi-bold",{'w-100': fullWidth}, className)} {...props} onClick={handleShow}>
                {catalog?.class_code && textButton  || t(lang, 'edi.common.button.select')}
            </Button>
            {modalShow && <Modal className={'davr-modal'} show={true} onHide={handleClose} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>{t(lang, 'edi.select_company_catalog.title')}</Modal.Title>
                    <FalconCloseButton onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm="12" className="mb-3">
                                <CustomFloatingLabelSelect
                                    {...props}
                                    label={t(lang, 'Идентификационный код продукции и услуг')}
                                    isClearable
                                    value={catalog}
                                    options={companyCatalogs}
                                    onChange={setCatalog}
                                    hideSelectedOptions
                                    getOptionLabel={getOptionCatalogLabel}
                                    getOptionValue={getOptionCatalogValue}
                                    formatOptionLabel={formatCatalogLabel}
                                />
                        </Col>
                        {catalog?.lgotas?.length > 0 &&
                            <Col sm="12" className="mb-3">
                                    <CustomFloatingLabelSelect
                                        {...props}
                                        label={t(lang, 'Льгота продукции или услуг')}
                                        isClearable
                                        value={lgota}
                                        options={catalog?.lgotas}
                                        onChange={setLgota}
                                        hideSelectedOptions
                                        getOptionLabel={getOptionLgotaLabel}
                                        getOptionValue={getOptionLgotaValue}
                                        formatOptionLabel={formatLgotaLabel}
                                    />
                            </Col>
                        }
                        {catalog && (catalog?.lgotas?.length > 0
                            ?
                            <Col sm="12">
                                <div className={'w-100'}>
                                    <Badge variant={'phoenix'} bg="success" className={'w-100 py-2'}>
                                        <p className="mb-2">Льгота для этого товара найдена</p>
                                        <b>Можете выбрать льготу!</b>
                                    </Badge>
                                </div>
                            </Col>
                            :
                            <Col sm="12">
                                <div className="w-100">
                                    <Badge variant={'phoenix'} bg="info" className={'w-100 py-2'}>
                                        <p className="mb-2">Льгота для этого товара не найдена</p>
                                        <b>Можете продолжать работу!</b>
                                    </Badge>
                                </div>
                            </Col>)
                        }
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={'btn-white-davr'} variant="phoenix-secondary" onClick={handleClose}>{t(lang, 'edi.common.button.close')}</Button>
                    <Button className={'btn-primary-davr'} variant="phoenix-secondary" onClick={onSaveHandler}>{t(lang, 'edi.common.button.select')}</Button>
                </Modal.Footer>
            </Modal>}
        </Fragment>
    );
};

export default SelectCompanyCatalog;
