import {IndividualManufacture, NotIncluded, Resale, Service} from "../../roaming/roaming-invoice/RoamingInvoiceWrapper";

export const InvoiceCreated = 0
export const InvoiceSend = 1
export const InvoiceDeleted = 2
export const InvoiceCanceled = 3
export const CustomerApproved = 4
export const CustomerDeclined = 5

export const GetStatusTextInvoice = status => {
    switch (status) {
        case InvoiceCreated:
            return "edi.invoice.status.invoice_created"
        case InvoiceSend:
            return "edi.invoice.status.invoice_send"
        case InvoiceDeleted:
            return "edi.invoice.status.invoice_deleted"
        case InvoiceCanceled:
            return "edi.invoice.status.invoice_canceled"
        case CustomerApproved:
            return "edi.invoice.status.customer_approved"
        case CustomerDeclined:
            return "edi.invoice.status.customer_declined"
        default:
            return "edi.invoice.status.default"
    }
}

export const getStatusColorInvoice = status => {
    switch (status) {
        case InvoiceCreated:
            return {
                bg: 'secondary',
            }
        case InvoiceSend:
            return {
                bg: 'primary',
                variant: 'phoenix'
            }
        case InvoiceCanceled:
            return {
                bg: 'warning',
                variant: 'phoenix'
            }
        case InvoiceDeleted:
            return {
                bg: 'danger',
            }
        case CustomerApproved:
            return {
                bg: 'success',
                variant: 'phoenix'
            }
        case CustomerDeclined:
            return {
                bg: 'danger',
            }
        default:
            return {
                bg: 'secondary',
            }
    }
}

export const InvoiceStatuses = [
    {id: InvoiceCreated, name: GetStatusTextInvoice(InvoiceCreated)},
    {id: InvoiceSend, name: GetStatusTextInvoice(InvoiceSend)},
    {id: InvoiceDeleted, name: GetStatusTextInvoice(InvoiceDeleted)},
    {id: InvoiceCanceled, name: GetStatusTextInvoice(InvoiceCanceled)},
    {id: CustomerApproved, name: GetStatusTextInvoice(CustomerApproved)},
    {id: CustomerDeclined, name: GetStatusTextInvoice(CustomerDeclined)}
]


export const InvoiceNdsZeroNoneVatPayers = 1;

class InvoiceWrapper {
    getNdsInfo(ndsStatusInfo) {
        let ndsInfo = null;
        if(ndsStatusInfo && ndsStatusInfo.is_safe) {
            switch(true) {
                case ndsStatusInfo.is_suspended:
                    ndsInfo = ndsStatusInfo.registration_code;
                    break;
                case ndsStatusInfo.is_active:
                    ndsInfo = ndsStatusInfo.registration_code;
                    break;
                default:
                    break;
            }
        }
        return ndsInfo;
    }

    getItemOriginText(type) {
        switch (type) {
            case IndividualManufacture:
                return "roaming.invoice.items.origin_types.1";
            case Resale:
                return "roaming.invoice.items.origin_types.2";
            case Service:
                return "roaming.invoice.items.origin_types.3";
            case NotIncluded:
                return "roaming.invoice.items.origin_types.4";
            default:
                return;
        }
    }
}

const invoiceWrapper = new InvoiceWrapper();
export default invoiceWrapper;
