import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import {selectAccountRolePermissions} from "../../../store/reducers/role/roleReducer";
import checkPermission from "../../../enum/permission/CheckPermission";
import PermissionBadge from "../../../components/common/PermissionBadge";
import {PermissionRoamingContractView} from "../../../enum/permission/RoamingPermission";
import ContractsDataTable from "../../../components/roaming/contracts/ContractsDataTable";

const RoamingContracts = () => {

    const { type_viewer } = useParams()
    const dispatch = useDispatch()
    const t = Translate;
    const lang = useSelector(selectLang);
    const navigate = useNavigate()
    //important
    useSelector(selectAccountRolePermissions)

    if (!type_viewer) {
        navigate('/roaming/contracts/inbox')
    }

    /*if(!dispatch(checkPermission(PermissionRoamingContractView))) {
        return <PermissionBadge>
            <span>{t(lang, 'system.permission.roaming.contracts.datatable.no_access.title')}</span>
        </PermissionBadge>
    }*/

    return (
      <ContractsDataTable typeViewer={type_viewer} />
    );
};

export default RoamingContracts;
