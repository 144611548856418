import React from 'react';
import {selectLang} from "../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import CustomFloatingLabelSelect from "../../floating-custom-select/CustomFloatingLabelSelect";
import {TypeDefault, waybillV2Types} from "../../../enum/roaming/waybill-v2/WaybillV2Wrapper";

const WaybillV2TypeSelect = ({defaultType, onChange, placeholder,...props}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    return (
        <CustomFloatingLabelSelect
            options={waybillV2Types}
            defaultValue={TypeDefault}
            value={waybillV2Types.find(o => o.value === defaultType)}
            onChange={(option) => onChange(option.value)}
            getOptionValue={option => option.value}
            getOptionLabel={option => t(lang, option.label)}
            hideSelectedOptions
            label={placeholder}
            {...props}
        />
    );
};

export default WaybillV2TypeSelect;
