import classNames from 'classnames';
// @ts-ignore
import avatar from '../../assets/img/company.png';
import { Children, PropsWithChildren } from 'react';
import Badge from "./Badge";

const Avatar = ({
                    size,
                    src,
                    variant = 'image',
                    rounded = 'circle',
                    status,
                    className,
                    imageClassName,
                    thumbnail,
                    children,
                    name
                }) => {
    return (
        <div
            className={classNames(className, `avatar avatar-${size}`, {
                [`status-${status}`]: status
            })}
        >
            {variant === 'image' && (
                <img
                    src={src ? src : avatar}
                    alt="avatar"
                    className={classNames(imageClassName, {
                        'img-thumbnail bg-white': thumbnail,
                        'avatar-placeholder': !src,
                        'rounded-circle': rounded === 'circle',
                        'rounded-soft': rounded === 'soft'
                    })}
                />
            )}

            {variant === 'name' && (
                <div style={{width: 35, height: 35}}>
                    <Badge bg="primary-davr" variant="phoenix"
                           className={classNames('d-block w-100 h-100 p-0 fs-9 d-flex align-items-center justify-content-center', {
                               'rounded-circle': rounded === 'circle',
                               'rounded-soft': rounded === 'soft'
                           })}
                    >
                        <span className={'main-text text-uppercase'}>{name?.split(" ")?.[0]?.[0]}</span>
                        <span className={'main-text text-uppercase'}>{name?.split(" ")?.[1]?.[0]}</span>
                    </Badge>
                </div>
            )}
            {variant === 'emoji' && (
                <div
                    className={classNames('avatar-emoji', {
                        'rounded-circle': rounded === 'circle',
                        'rounded-soft': rounded === 'soft'
                    })}
                >
          <span role="img" aria-label="Emoji">
            {children}
          </span>
                </div>
            )}
        </div>
    );
};

export const AvatarGroup = ({
                                children,
                                className,
                                total,
                                size
                            }) => {
    return (
        <div className={classNames(className, 'avatar-group')}>
            {children}
            {total && total > Children.count(children) && (
                <Avatar size={size} variant="name">
                    +{total - Children.count(children)}
                </Avatar>
            )}
        </div>
    );
};

Avatar.Group = AvatarGroup;

export default Avatar;
