import React, {Fragment, useState} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {Col, Row, Form, Card, FloatingLabel} from "react-bootstrap";
import FindPerson from "../../../edi/attorney/FindPerson";
import classNames from "classnames";

const EmpowermentPerson = ({draftEmpowerment}) => {
    const {register, formState: {errors}, control, setValue} = useFormContext();
    const [eventCount, setEventCount] = useState(0)
    const lang = useSelector(selectLang);
    const t = Translate;

    const onChangePerson = (event) => {
        setEventCount(eventCount + 1)

        setValue("personInfo.pinfl", event?.pinfl);
        if (!draftEmpowerment || eventCount > 2) {
            setValue("personInfo.fullName", event?.name);
        }
    }

    return (
        <Fragment>
            <h4 className={'fs-8 fw-medium second-text ms-3 mt-4'}>{t(lang, "roaming.empowerment.empowerment_person")}</h4>
            <Card className="p-2">
                <Row>
                    <Col xs={12} sm={4} className="mb-2">
                        <Controller
                            name="personInfo.inn"
                            control={control}
                            render={({field}) => (
                                <FindPerson
                                    placeholder={t(lang, "roaming.common.inn")}
                                    inputProps={{
                                        isInvalid: errors?.personInfo?.inn,
                                        isValid: Object.keys(errors).length > 0 && !errors?.personInfo?.inn,
                                        placeholder: t(lang, "roaming.common.inn_or_pinfl.placeholder")
                                }}
                                    onChange={(person) => {
                                        onChangePerson(person);
                                        field.onChange(person?.inn || '')
                                    }}
                                    inputGroupProps={{
                                        className: classNames({
                                            'is-invalid': errors?.personInfo?.inn,
                                            'is-valid': Object.keys(errors).length > 0 && !errors?.personInfo?.inn
                                        }),
                                    }}
                                    defaultInnOrPinfl={field.value}
                                    ref={field.ref}
                                />
                            )}
                            rules={{
                                required: t(lang, 'roaming.validations.is_required'),
                            }}
                        />
                        <Form.Control.Feedback type="invalid">{errors?.personInfo?.inn?.message}</Form.Control.Feedback>
                    </Col>
                    <Col xs={12} sm={4} className="mb-2">
                        <FloatingLabel label={t(lang, "roaming.common.inn_or_pinfl")}>
                            <Form.Control name="personInfo.pinfl"
                                          type="text"
                                          {...register("personInfo.pinfl")}
                                          disabled
                            />
                        </FloatingLabel>
                    </Col>
                    <Col xs={12} lg={4} className="mb-2">
                        <FloatingLabel label={t(lang, "roaming.empowerment.empowerment_person")}>
                            <Form.Control name="personInfo.fullName"
                                          type="text"
                                          placeholder={t(lang, "roaming.empowerment.empowerment_person")}
                                          isValid={Object.keys(errors).length > 0 && !errors?.personInfo?.name}
                                          {...register("personInfo.fullName")}
                            />
                        </FloatingLabel>

                    </Col>
                </Row>
            </Card>

        </Fragment>
    );
};

export default EmpowermentPerson;
