import React from 'react';

const IconFolderCancel = ({width = 24, height = 24, color = "none"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 15L12 13M12 13L14 11M12 13L10 11M12 13L14 15M3 16.8V6C3 4.89543 3.89543 4 5 4H8.67452C9.1637 4 9.40886 4 9.63904 4.05526C9.84311 4.10425 10.0379 4.18526 10.2168 4.29492C10.4186 4.41857 10.5918 4.59182 10.9375 4.9375L12 6H17.8C18.9201 6 19.4798 6 19.9076 6.21799C20.2839 6.40973 20.5905 6.71547 20.7822 7.0918C21.0002 7.51962 21.0002 8.07967 21.0002 9.19978L21.0002 16.7998C21.0002 17.9199 21.0002 18.48 20.7822 18.9078C20.5905 19.2841 20.2837 19.5905 19.9074 19.7822C19.48 20 18.921 20 17.8031 20H6.19691C5.07899 20 4.5192 20 4.0918 19.7822C3.71547 19.5905 3.40973 19.2839 3.21799 18.9076C3 18.4798 3 17.9201 3 16.8Z"
                stroke="#58B3D3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    );
};

export default IconFolderCancel;
