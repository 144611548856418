import React, {useEffect} from 'react';
import LogoutContent from "../../../components/form/auth/LogoutContent";
import {useDispatch} from "react-redux";
import {logoutAsync} from "../../../store/reducers/auth/authReducer";
import {useLocation, useNavigate} from "react-router-dom";

const SignOut = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {pathname} = useLocation()

    useEffect(() => {
        dispatch(logoutAsync())
        return () => {
            navigate('/authentication')
        }
    }, [])

    return (
        <div className={'text-center'}>
            <LogoutContent />
        </div>
    );
};

export default SignOut;
