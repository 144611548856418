import React from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {markPackageOptions} from "../../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import ReactSelect from "react-select";
import CustomFloatingLabelSelect from "../../floating-custom-select/CustomFloatingLabelSelect";

const MarkPackageTypeSelector = ({defaultPackageType, onChange, selectProps}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    return (
        <CustomFloatingLabelSelect
            options={markPackageOptions}
            isSearchable={!defaultPackageType}
            isClearable={true}
            getOptionLabel={option => t(lang, option.label)}
            getOptionValue={option => option.value}
            label={t(lang, "roaming.common.items.mark_package_type")}
            onChange={(option) => onChange(option ? option.value : null)}
            value={markPackageOptions.find(m => m.value === defaultPackageType) || null}
            {...selectProps}
        />
    );
};

export default MarkPackageTypeSelector;
