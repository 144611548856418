import {createSlice} from "@reduxjs/toolkit";
import ContractService from "../../services/contract/ContractService";
import EventBus from "../../../eventbus/EventBus";
import {
    EDIT_CONTRACT_FAILED,
    EDIT_CONTRACT_REQUESTED, EDIT_CONTRACT_SUCCEED,
    REGISTER_CONTRACT_FAILED,
    REGISTER_CONTRACT_REQUESTED,
    REGISTER_CONTRACT_SUCCEED
} from "../../../eventbus/contractEvents";

const LAST_USED_CONTRACT_ID = 'contractId';
export const STORAGE_CONTRACT = "ContractsDataTableSettings"
const initialState = {
    contracts: [],
    contractId: localStorage.getItem(LAST_USED_CONTRACT_ID) || '',
    contractsDataTableSettings: {}
}
export const contractSlice = createSlice({
    name: "contract",
    initialState,
    reducers: {
        setContracts: (state, action) => {
            state.contracts = action.payload
        },
        changeContractId: (state, action) => {
            state.contractId = action.payload;
        },
        saveContractId: (state) => {
            const contractId = state.contractId;
            localStorage.setItem(LAST_USED_CONTRACT_ID, contractId);
        },

        loadContractDataTableColumn: (state) => {
            let storageColumn = JSON.parse(localStorage.getItem(STORAGE_CONTRACT))
            if(!storageColumn) {
                storageColumn = {
                    index: true,
                    number: true,
                    date: true,
                    contractor: true,
                    button_action: true,
                };
                localStorage.setItem(STORAGE_CONTRACT, JSON.stringify(storageColumn))
            }
            state.contractsDataTableSettings = storageColumn
        },

        changeContractDataTableColumn: (state, action) => {
            state.contractsDataTableSettings = action.payload
            localStorage.setItem(STORAGE_CONTRACT, JSON.stringify(state.contractsDataTableSettings))
        },
    }
})

export const loadContractsAsync = () => (dispatch) => {
    ContractService.getContracts().then(response => {
        dispatch(contractSlice.actions.setContracts(response.data));
    });
}
export const loadContractAsync = (id) => {
    return new Promise((resolve, reject) => {
        ContractService.getContract(id)
            .then(response => {
                resolve(response.data);
            })
            .catch(e => {
                reject(e);
            });
    })
}
export const registerContractAsync = (payload) => {
    return new Promise((resolve, reject) => {
        EventBus.dispatch(REGISTER_CONTRACT_REQUESTED, payload);

        ContractService.register(payload)
            .then(response => {
                EventBus.dispatch(REGISTER_CONTRACT_SUCCEED, response.data);
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(REGISTER_CONTRACT_FAILED, error)
                reject({number_exists: !!error?.response?.data?.number_exists})
            })
    })
}
export const editContractAsync = (id, payload) => {
    return new Promise((resolve, reject) => {
        EventBus.dispatch(EDIT_CONTRACT_REQUESTED, payload);

        ContractService.edit(id, payload)
            .then((response) => {
                EventBus.dispatch(EDIT_CONTRACT_SUCCEED, response.data);
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(EDIT_CONTRACT_FAILED, error);
                reject({number_exists: !!error?.response?.data?.number_exists})
            })
    })
}

export const changeContractId = contractSlice.actions.changeContractId;
export const saveContractId = contractSlice.actions.saveContractId;

export const selectContracts = (state) => state.contract.contracts;
export const selectContractId = (state) => state.contract.contractId;

export const selectContractDataTable = (state) => state.contract.contractsDataTableSettings

export const {loadContractDataTableColumn} = contractSlice.actions
export const {changeContractDataTableColumn} = contractSlice.actions

export default contractSlice.reducer
