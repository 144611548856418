
export const CarrierSent = 15;
export const CarrierCancel = 17;
export const CustomerDecline = 20;
export const CustomerAccept = 30;


export const VIEW_INBOX = 'inbox';
export const VIEW_OUTBOX = 'outbox';
export const VIEW_DRAFTS = 'draft';
export const RESPONSIBLE_PERSON = 'responsible-person';

const waybillStatus = (id, name) => {
    return {
        id,
        name
    }
}

const VIEW_OUTBOX_STATUSES = [
    waybillStatus(CarrierSent, 'roaming.waybill.status.viewer_is_carrier.carrier_sent'),
    waybillStatus(CarrierCancel, 'roaming.waybill.status.viewer_is_carrier.carrier_cancel'),
    waybillStatus(CustomerDecline, 'roaming.waybill.status.viewer_is_carrier.customer_decline'),
    waybillStatus(CustomerAccept, 'roaming.waybill.status.viewer_is_carrier.customer_accept'),
];

const VIEW_INBOX_STATUSES = [
    waybillStatus(CarrierSent, 'roaming.waybill.status.viewer_is_customer.carrier_sent'),
    waybillStatus(CarrierCancel, 'roaming.waybill.status.viewer_is_customer.carrier_cancel'),
    waybillStatus(CustomerDecline, 'roaming.waybill.status.viewer_is_customer.customer_decline'),
    waybillStatus(CustomerAccept, 'roaming.waybill.status.viewer_is_customer.customer_accept'),
];

export const getWaybillStatuses = (viewer) => {
    switch (viewer) {
        case VIEW_OUTBOX:
            return VIEW_OUTBOX_STATUSES;
        case VIEW_INBOX:
            return VIEW_INBOX_STATUSES;
        default:
            return [];
    }
}

export const getWaybillStatusText = (waybill, inn) => {
    if (waybill.customer.inn === inn) {
        switch (waybill.status) {
            case CarrierSent:
                return 'roaming.waybill.status.viewer_is_customer.carrier_sent';
            case CarrierCancel:
                return 'roaming.waybill.status.viewer_is_customer.carrier_cancel';
            case CustomerDecline:
                return 'roaming.waybill.status.viewer_is_customer.customer_decline';
            case CustomerAccept:
                return 'roaming.waybill.status.viewer_is_customer.customer_accept';
            default:
                return ''
        }
    } else if (waybill.carrier.inn === inn) {
        switch (waybill.status) {
            case CarrierSent:
                return 'roaming.waybill.status.viewer_is_carrier.carrier_sent';
            case CarrierCancel:
                return 'roaming.waybill.status.viewer_is_carrier.carrier_cancel';
            case CustomerDecline:
                return 'roaming.waybill.status.viewer_is_carrier.customer_decline';
            case CustomerAccept:
                return 'roaming.waybill.status.viewer_is_carrier.customer_accept';
            default:
                return ''
        }
    }
}

export const getWaybillStatusVariant = (waybill, inn) => {
    if (waybill.customer.inn === inn) {
        switch (waybill.status) {
            case CarrierSent:
                return 'warning-davr';
            case CarrierCancel:
                return 'warning-davr';
            case CustomerDecline:
                return 'danger-davr';
            case CustomerAccept:
                return 'success-davr';
            default:
                return 'secondary-davr'
        }
    } else if (waybill.carrier.inn === inn) {
        switch (waybill.status) {
            case CarrierSent:
                return 'primary-davr';
            case CarrierCancel:
                return 'secondary-davr';
            case CustomerDecline:
                return 'danger-davr';
            case CustomerAccept:
                return 'success-davr';
            default:
                return 'secondary-davr'
        }
    }
}
