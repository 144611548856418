import React, {useEffect, useState} from 'react';
import {selectLang} from "../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../lang/lang";
import Select from 'react-select'
import PropTypes from "prop-types";
import CustomFloatingLabelSelect from "../floating-custom-select/CustomFloatingLabelSelect";

const SelectStatus = ({onChange, statuses, defaultStatus, id, placeholder, ...props}) => {
    const [value, setValue] = useState(null);

    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        if (defaultStatus) {
            const defaultValue = statuses.find(s => s.id === defaultStatus);
            setValue(defaultValue);
        }
    }, [defaultStatus])

    const onChangeHandler = (option) => {
        onChange(option?.id);
        setValue(option);
    }

    const getOptionValue = option => option.id;
    const getOptionLabel = option => t(lang, option.name);

    return (
            <CustomFloatingLabelSelect
                {...props}
                label={placeholder}
                value={value}
                options={statuses}
                hideSelectedOptions
                onChange={onChangeHandler}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
            />
    );
};

SelectStatus.propTypes = {
    statuses: PropTypes.array,
    onChange: PropTypes.func
}
SelectStatus.defaultProps = {
    statuses: [],
    onChange: () => {
    }
}

export default SelectStatus;
