import React, {Fragment} from 'react';
import {useFormContext} from "react-hook-form";
import {selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import UsernameInputForm from "../../../username-input-form/UsernameInputForm";
import {Button, Form} from "react-bootstrap";
import Flex from "../../../common/Flex";

const ForgotPasswordStepOne = ({
                                   header = () => {
                                   }
                               }) => {
    const {formState: {errors}, watch} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;
    const username = watch('username')

    return (
        <Fragment>
            <div>
                {header()}
                <h4 className={'text-center mb-2 text-dark'}>{t(lang, 'Введите номер телефона')}</h4>
            </div>
            <Flex className={"flex-column justify-content-between scrollbar"} style={{height: "calc(100% - 60px)"}}>
                <UsernameInputForm name={'username'} shouldExist={true} isInvalid={errors?.username}/>
                <Form.Group className="mt-3">
                    <Button form={"form-forget-password"}
                            className={`${(!username || username?.length < 12) ? "bg-secondary-davr" : "bg-primary-davr"} w-100`}
                            type={!username || username?.length < 12 ? "button" : "submit"}>
                        {t(lang, 'authentication.forgot-password.send_confirmation_code')}
                    </Button>
                </Form.Group>
            </Flex>
        </Fragment>
    );
};

export default ForgotPasswordStepOne;
