import React, {useEffect} from 'react';
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {
    getWaybillV2CustomerCount,
    getWaybillV2DraftsCount,
    getWaybillV2ExecutorCount, getWaybillV2ResponsiblePersonCount,
    loadWaybillV2CustomerAsync,
    loadWaybillV2DraftsAsync,
    loadWaybillV2ExecutorAsync,
    loadWaybillV2ResponsiblePersonAsync,
    selectWaybillsV2FilterOptions
} from "../../../store/reducers/roaming/waybill-v2/romaingWaybillV2Reducer";
import {RESPONSIBLE_PERSON, VIEW_DRAFTS, VIEW_INBOX, VIEW_OUTBOX} from "../../../enum/roaming/waybill/WaybillStatus";
import {DELETE_WAYBILL_V2_SUCCEED} from "../../../eventbus/romaing/waybill-v2/roamingWaybillV2Events";
import toast from "react-hot-toast";
import EventBus from "../../../eventbus/EventBus";
import WaybillV2DataTable from "../../../components/roaming/waybill-v2/WaybillV2DataTable";

const WaybillsV2 = () => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const {viewer} = useParams();
    const dispatch = useDispatch();
    const filter = useSelector(selectWaybillsV2FilterOptions);
    const activeOrganization = (useSelector(selectActiveOrganization));

    useEffect(() => {
        if (viewer === VIEW_INBOX) {
            dispatch(loadWaybillV2CustomerAsync({...filter}));
            dispatch(getWaybillV2CustomerCount({...filter}));
        } else if (viewer === VIEW_OUTBOX) {
            dispatch(loadWaybillV2ExecutorAsync({...filter}));
            dispatch(getWaybillV2ExecutorCount({...filter}));
        } else if (viewer === VIEW_DRAFTS) {
            dispatch(loadWaybillV2DraftsAsync({...filter}));
            dispatch(getWaybillV2DraftsCount({...filter}));
        } else if (viewer === RESPONSIBLE_PERSON) {
            dispatch(loadWaybillV2ResponsiblePersonAsync({...filter}));
            dispatch(getWaybillV2ResponsiblePersonCount({...filter}));
        }
    }, [activeOrganization, filter, viewer])

    useEffect(() => {
        const onWaybillDeletedSuccess = EventBus.on(DELETE_WAYBILL_V2_SUCCEED, () => {
            dispatch(loadWaybillV2DraftsAsync({...filter}));
            dispatch(getWaybillV2DraftsCount({...filter}));
            toast.success(t(lang, "edi.common.toast.success"))
        })

        return () => {
            EventBus.remove(DELETE_WAYBILL_V2_SUCCEED, onWaybillDeletedSuccess)
        }

    }, []);

    return (
        <WaybillV2DataTable viewer={viewer} />
    );
};

export default WaybillsV2;
