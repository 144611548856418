import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectCertificates} from "../../store/reducers/eimzo/eimzoReducer";
import {selectDateDayJSFormat} from "../../store/reducers/main/main";
import {CertificatePfx} from "../../plugins/eimzo";
import ReactSelect from 'react-select'
import classNames from "classnames";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import Badge from "../common/Badge";

const formatOptionLabel = format => {
    return certificateInto => {
        const validFrom = dayjs(certificateInto.validFromDate)
        const validEndDate = dayjs(certificateInto.validEndDate)

        return (
            <div className={`d-flex flex-column`}>
                <div className="notification-body fs-9">
                    {certificateInto.companyName ? <p className={"d-flex fs-8 mb-0"}>
                        <span className="text-uppercase">{certificateInto.companyName}</span>
                    </p> : <p className={"d-flex fs-8 mb-0 text-uppercase"}>{certificateInto.issuedPerson}</p>}
                    {certificateInto.innNumber && <Badge variant={'phoenix'} bg={'success-davr'} className={"d-inline"}>{certificateInto.innNumber}</Badge>}
                    {certificateInto.pinfl && <Badge variant={'phoenix'} bg={'primary-davr'} className={"d-inline ms-2"}>{certificateInto.pinfl}</Badge>}
                </div>
               {/* <span className="notification-times fs--1">
                    <p className={classNames('mb-0', {
                        'text-danger': certificateInto.isExpired
                    })}>От {validFrom.format(format)} До {validEndDate.format(format)} </p>
                </span>*/}
            </div>
        )
    };
}

const SelectCertificate = ({onChange, auth, ...props}) => {
    const certificates = useSelector(selectCertificates);
    const [options, setOptions] = useState([]);
    const dateDayJSFormat = useSelector(selectDateDayJSFormat)
    const today = new Date();

    useEffect(() => {
        setOptions(certificates.map(certificate => {
            console.log(certificate)
            const pfxCertificate = new CertificatePfx(certificate);
            return {
                innNumber: pfxCertificate.innNumber,
                pinfl: pfxCertificate.pinfl,
                companyName: pfxCertificate.companyName,
                issuedPerson: pfxCertificate.issuedPerson,
                validEndDate: pfxCertificate.validEndDate,
                validFromDate: pfxCertificate.validFromDate,
                value: `${certificate.disk}${certificate.alias}`,
                isDisabled: pfxCertificate.validEndDate.getTime() <= today.getTime(),
                isExpired: pfxCertificate.validEndDate.getTime() <= today.getTime()
            };
        }))
    }, [certificates]);

    const onOptionChange = option => {
        const certificate = certificates.find(certificate => `${certificate.disk}${certificate.alias}` === option?.value) || null;
        onChange(certificate);
    }

    return (
        <div className={'certificate'}>
            <ReactSelect options={options} onChange={onOptionChange} {...props} formatOptionLabel={formatOptionLabel(dateDayJSFormat)}/>
        </div>
    );
};

SelectCertificate.propTypes = {
    onChange: PropTypes.func,
    isInvalid: PropTypes.bool
};

SelectCertificate.defaultProps = {
    onChange: () => {
    },
    isInvalid: false
}

export default SelectCertificate;
