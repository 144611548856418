export const ADD_ATTORNEY_REQUESTED = 'ADD_ATTORNEY_REQUESTED';
export const ADD_ATTORNEY_SUCCESS = 'ADD_ATTORNEY_SUCCESS';
export const ADD_ATTORNEY_FAILED = 'ADD_ATTORNEY_FAILED';

export const EDIT_ATTORNEY_REQUESTED = "EDIT_ATTORNEY_REQUESTED";
export const EDIT_ATTORNEY_SUCCESS = "EDIT_ATTORNEY_SUCCESS";
export const EDIT_ATTORNEY_FAILED = "EDIT_ATTORNEY_FAILED";

export const SIGN_ATTORNEY_REQUESTED = "SIGN_ATTORNEY_REQUESTED";
export const SIGN_ATTORNEY_SUCCESS = "SIGN_ATTORNEY_SUCCESS";
export const SIGN_ATTORNEY_FAILED = "SIGN_ATTORNEY_FAILED";

export const CANCEL_ATTORNEY_REQUESTED = "CANCEL_ATTORNEY_REQUESTED";
export const CANCEL_ATTORNEY_SUCCESS = "CANCEL_ATTORNEY_SUCCESS";
export const CANCEL_ATTORNEY_FAILED = "CANCEL_ATTORNEY_FAILED";

export const ACCEPT_ATTORNEY_REQUESTED = "ACCEPT_ATTORNEY_REQUESTED";
export const ACCEPT_ATTORNEY_SUCCESS = "ACCEPT_ATTORNEY_SUCCESS";
export const ACCEPT_ATTORNEY_FAILED = "ACCEPT_ATTORNEY_FAILED";

export const DECLINE_ATTORNEY_REQUESTED = "DECLINE_ATTORNEY_REQUESTED";
export const DECLINE_ATTORNEY_SUCCESS = "DECLINE_ATTORNEY_SUCCESS";
export const DECLINE_ATTORNEY_FAILED = "DECLINE_ATTORNEY_FAILED";
