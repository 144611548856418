export const WarehouseItemUpdated = 1
export const ContractorDebtUpdated = 2

export const pushUpdateFunc = (type, payload) => {
    switch (type) {
        case WarehouseItemUpdated:
            return {type: WarehouseItemUpdated, payload: payload};
        case ContractorDebtUpdated:
            return {type: ContractorDebtUpdated, payload: payload};
        default:
            return null
    }
}
