import React, {Fragment} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {selectDateFormat, selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {Col, Row, Form, FormControl, Card, FloatingLabel} from "react-bootstrap";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import DatePickerComponent from "../../../common/DatePickerComponent";

const DetailsForm = () => {
    const {register, formState: {errors}, control} = useFormContext();
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Fragment>
            <h4 className={'fs-8 fw-medium second-text ms-3'}>{t(lang, 'roaming.waybill.send.details_form.title')}</h4>
            <Card className="p-2">
                <Row>
                    <Col className="my-1" xs="12" md="4">
                        <Form.Group>
                            <FloatingLabel label={t(lang, 'roaming.waybill.send.details_form.shipment_number')}>
                                <Form.Control
                                    type="text"
                                    isInvalid={errors?.shipment?.number}
                                    placeholder={t(lang, 'roaming.waybill.send.details_form.shipment_number')}
                                    {...register('shipment.number', {
                                        required: t(lang, 'edi.common.forms.validations.is_required')
                                    })}
                                />
                            </FloatingLabel>

                            <FormControl.Feedback type="invalid">
                                {errors?.shipment?.number?.message}
                            </FormControl.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="my-1" xs="12" md="4">
                        <Form.Group>
                            <FloatingLabel label={t(lang, 'roaming.waybill.send.details_form.contract_number')}>
                                <Form.Control
                                    type="text"
                                    isInvalid={errors?.contract?.number}
                                    placeholder={t(lang, 'roaming.waybill.send.details_form.contract_number.placeholder')}
                                    {...register('contract.number', {
                                        required: t(lang, 'edi.common.forms.validations.is_required')
                                    })}
                                />
                            </FloatingLabel>

                            <FormControl.Feedback type="invalid">
                                {errors?.contract?.number?.message}
                            </FormControl.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="my-1" xs="12" md="4">
                        <Form.Group>
                            <FloatingLabel label={t(lang, 'roaming.waybill.send.details_form.waybill_number')}>
                                <Form.Control
                                    type="text"
                                    isInvalid={errors?.waybill?.number}
                                    placeholder={t(lang, 'roaming.waybill.send.details_form.waybill_number.placeholder')}
                                    {...register('waybill.number', {
                                        required: t(lang, 'edi.common.forms.validations.is_required')
                                    })}
                                />
                            </FloatingLabel>

                            <FormControl.Feedback type="invalid">
                                {errors?.waybill?.number?.message}
                            </FormControl.Feedback>
                        </Form.Group>
                    </Col>

                    <Col className="my-1" xs="12" md="4">
                        <Form.Group>
                            <Controller
                                control={control}
                                name='shipment.date'
                                rules={{
                                    required: t(lang, 'edi.common.forms.validations.is_required'),
                                    max: {value: new Date(), message: t(lang, 'roaming.waybill.send.details_form.validations.shipment.date.more_than_current_date')}
                                }}
                                render={({field}) => (
                                    <DatePickerComponent
                                        field={field}
                                        isClearable
                                        dateFormat={dateFormat}
                                        wrapperClassName={classNames({'is-invalid': errors?.shipment?.date})}
                                        className={classNames('form-control', {'is-invalid': errors?.shipment?.date})}
                                        placeholderText={t(lang, 'roaming.waybill.send.details_form.shipment_date')}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.shipment?.date?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="my-1" xs="12" md="4">
                        <Form.Group>
                            <Controller
                                control={control}
                                name='contract.date'
                                rules={{required: t(lang, 'edi.common.forms.validations.is_required')}}
                                render={({field}) => (
                                    <DatePickerComponent
                                        field={field}
                                        isClearable
                                        wrapperClassName={classNames({'is-invalid': errors?.contract?.date})}
                                        className={classNames('form-control', {'is-invalid': errors?.contract?.date})}
                                        placeholderText={t(lang, 'roaming.waybill.send.details_form.contract_date')}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.contract?.date?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="my-1" xs="12" md="4">
                        <Form.Group>
                            <Controller
                                control={control}
                                name='waybill.date'
                                rules={{required: t(lang, 'edi.common.forms.validations.is_required')}}
                                render={({field}) => (
                                    <DatePickerComponent
                                        field={field}
                                        isClearable
                                        wrapperClassName={classNames({'is-invalid': errors?.waybill?.date})}
                                        className={classNames('form-control', {'is-invalid': errors?.waybill?.date})}
                                        placeholderText={t(lang, 'roaming.waybill.send.details_form.waybill_date')}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.waybill?.date?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
            </Card>

        </Fragment>
    );
};

export default DetailsForm;
