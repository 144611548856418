import React, {Fragment} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    changeNumberFormat,
    NumberFormatOptions,
    selectLang,
    selectNumberFormat
} from "../../../store/reducers/main/main";
import {FloatingLabel, Form} from "react-bootstrap";
import {Translate} from "../../../lang/lang";

const NumberFormatChooser = () => {
    const dispatch = useDispatch();
    const numberFormat = useSelector(selectNumberFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    const onChange = (type) => {
        dispatch(changeNumberFormat(type));
    }

    return (
        <Fragment>
            <FloatingLabel label={t(lang, 'hippo.user.settings.profile.value')}>
                <Form.Select size="sm"
                             value={numberFormat}
                             onChange={(event) => onChange(event.target.value)}
                >
                    {NumberFormatOptions.map(format => {
                        return (
                            <option value={format.type} key={format.type}>
                                {format.value}
                            </option>
                        )
                    })}
                </Form.Select>
            </FloatingLabel>
        </Fragment>
    );
};

export default NumberFormatChooser;
