import React, {Fragment, useState} from 'react';
import {useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {Card, Col, Row, Table} from "react-bootstrap";
import IconButton from "../../../IconButton";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import Product from "./Product";
import Cleave from "cleave.js/react";
import numeral from "numeral";
import {getNumberValue} from "../../../../helpers/utils";
import {numberFormat} from "../../../../pheonix/helpers/utils";
const Products = ({ fieldsProduct, addFieldProduct, removeFieldProduct }) => {
    const [totalAmounts, setTotalAmounts] = useState({
        deliverySum: null,
        vatSum: null,
        deliverySumWithVat: null
    })
    const { watch } = useFormContext()
    const lang = useSelector(selectLang)
    const numberFormat = useSelector(selectNumberFormat);
    const t = Translate
    const watchProducts = watch('products')

    const calcAmounts = () => {
        let totalDeliverySum = watchProducts.reduce((total, product) => total + +product.deliverySum, 0)
        let totalVatSum = watchProducts.reduce((total, product) => total + +product.vatSum, 0)
        let totalDeliverySumWithVat = watchProducts.reduce((total, product) => total + +product.deliverySumWithVat, 0)
        totalDeliverySum = totalDeliverySum.toFixed(2)
        totalVatSum = totalVatSum.toFixed(2)
        totalDeliverySumWithVat = totalDeliverySumWithVat.toFixed(2)
        setTotalAmounts({
            deliverySum: totalDeliverySum,
            vatSum: totalVatSum,
            deliverySumWithVat: totalDeliverySumWithVat
        })
    }


    return (
        <Fragment>
            <Row className={'align-items-center'}>
                <Col>
                    <h4 className="mb-0 ms-3 mb-2 fs-9 second-text fw-medium">{t(lang, "roaming.contracts.add.products.title")}</h4>
                </Col>
            </Row>

            <Card className={'py-1 px-2 rounded-4 border-0'}>
                <Table responsive className="scrollbar view-table border-900 mb-0 fs-9 second-text fw-semi-bold">
                    <thead className="text-dark border-bottom">
                    <tr>
                        <th scope="col" className="fw-medium  align-middle text-center">{t(lang, "roaming.contracts.add.products.sequence")}</th>
                        <th scope="col" className="fw-medium  align-middle text-center">{t(lang, "roaming.contracts.add.products.name")}</th>
                        <th scope="col" className="fw-medium  align-middle text-center">{t(lang, "roaming.contracts.add.products.catalog")}</th>
                        <th scope="col" className="fw-medium  align-middle text-center">{t(lang, "roaming.contracts.add.products.barcode")}</th>
                        <th scope="col" className="fw-medium  align-middle text-center">{t(lang, "roaming.contracts.add.products.measure")}</th>
                        <th scope="col" className="fw-medium  align-middle text-center">{t(lang, "roaming.contracts.add.products.quantity")}</th>
                        <th scope="col" className="fw-medium  align-middle text-center">{t(lang, "roaming.contracts.add.products.price")}</th>
                        <th scope="col" className="fw-medium  align-middle text-center">{t(lang, "roaming.contracts.add.products.sum")}</th>
                        <th scope="col" className="fw-medium  align-middle text-center">{t(lang, "roaming.contracts.add.products.vat_rate")}</th>
                        <th scope="col" className="fw-medium  align-middle text-center">{t(lang, "roaming.contracts.add.products.vat_sum")}</th>
                        <th scope="col" className="fw-medium  align-middle text-center">{t(lang, "roaming.contracts.add.products.sum_with_vat_sum")}</th>
                        <th scope="col">
                            <IconButton icon={faPlus}
                                        variant={'phoenix-primary'}
                                        onClick={addFieldProduct}
                                        className={'bg-primary-davr py-2 px-2 rounded-3'}
                                        iconClassName={'text-white'}
                            />
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {fieldsProduct.map((product, index) => (
                        <Product key={product.id} product={product} index={index} removeFieldProduct={removeFieldProduct} onChangeAmounts={calcAmounts}/>
                    ))}
                    </tbody>
                </Table>
                <Row className="g-0 justify-content-end">
                    <Col xs="auto">
                        {/*<Table borderless size="sm" className="fs-9 text-end">
                            <tbody>
                            <tr>
                                <th className="text-900">{t(lang, 'roaming.common.subtotal')}:</th>
                                <td className="fw-medium">
                                    {totalAmounts?.deliverySum ? numeral['formats'][numberFormat].format(totalAmounts?.deliverySum || 0) : 0}
                                </td>
                            </tr>
                            <tr>
                                <th className="text-900">{t(lang, 'roaming.common.tax')}:</th>
                                <td className="fw-medium">
                                    {totalAmounts?.vatSum ? numeral['formats'][numberFormat].format(totalAmounts?.vatSum || 0) : 0}
                                </td>
                            </tr>
                                <tr>
                                    <th className="text-900">{t(lang, 'roaming.common.total')}:</th>
                                    <td className="fw-medium">
                                        {totalAmounts?.deliverySumWithVat ? numeral['formats'][numberFormat].format(totalAmounts?.deliverySumWithVat || 0) : 0}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>*/}
                    </Col>
                </Row>
            </Card>
        </Fragment>
    );
};

export default Products;
