import React, {Fragment, useState} from 'react';
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import {Controller, useFormContext} from "react-hook-form";
import {Button, Card, FloatingLabel, Form, Modal} from "react-bootstrap";
import classNames from "classnames";
import Cleave from "cleave.js/react";

const AddUserStepOne = ({close}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const {formState: {errors}, control, register} = useFormContext()
    const [openClose] = useState(false)

    const handleClose = () => {
        close(openClose)
    };

    return (
        <Card className={'p-1'}>
            <Form.Group>
                <FloatingLabel label={t(lang, "users.modal.placeholder_with_title.full_name")}>
                    <Form.Control
                        type="text"
                        placeholder={t(lang, "users.modal.placeholder_with_title.full_name")}
                        {...register('name', {
                            required: t(lang, 'edi.common.forms.validations.is_required')
                        })}
                        isInvalid={errors.name}
                    />
                </FloatingLabel>
                <Form.Control.Feedback type="invalid">
                    {errors.name && errors.name.message}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="input-group gap-2 flex-nowrap my-2">
                <FloatingLabel label={"Код"} className={'w-25'}>
                    <Form.Control value={"+998"} placeholder={"Код"}/>
                </FloatingLabel>
                <Controller
                    control={control}
                    name={'username'}
                    rules={{
                        required: t(lang, "edi.common.forms.validations.is_required"),
                        minLength: {value: 9, message: t(lang, "edi.common.forms.validations.is_required")},
                    }}
                    render={({field}) => (
                        <FloatingLabel label={"Номер телефона"} className={'w-75'}>
                            <Cleave
                                value={field.value}
                                placeholder={"Номер телефона"}
                                options={{
                                    delimiters: [' ', ' ', ' ', ' '],
                                    blocks: [2, 3, 2, 2],
                                    numericOnly: true
                                }}
                                onChange={(username) => field.onChange(username)}
                                className={classNames('form-control', {
                                    'is-invalid': errors?.username,
                                    'is-valid': Object.keys(errors).length > 0 && !errors?.username
                                })}
                            />
                        </FloatingLabel>
                    )}/>
                <Form.Control.Feedback type="invalid">
                    {errors?.username?.message}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
                <Controller
                    control={control}
                    name='password'
                    render={({field}) => (
                        <FloatingLabel label={t(lang, "authentication.forgot-password.new_password.placeholder")}>
                            <input
                                type="password"
                                placeholder={t(lang, "authentication.forgot-password.new_password.placeholder")}
                                onChange={(password) => field.onChange(password)}
                                className={classNames('form-control', {
                                    'is-invalid': errors?.password,
                                    'is-valid': Object.keys(errors).length > 0 && !errors?.password,
                                })}
                            />
                        </FloatingLabel>
                    )}
                    rules={{
                        required: t(lang, "edi.common.forms.validations.is_required"),
                        minLength: 1
                    }}
                />
                <Form.Control.Feedback type="invalid">
                    {errors?.password?.message}
                </Form.Control.Feedback>
            </Form.Group>

        </Card>
    );
};

export default AddUserStepOne;
