import React, {Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {
    roamingHideModal,
    selectIsLoading, setRoamingCommonHideModal
} from "../../../store/reducers/roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import {selectDateDayJSFormat, selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {
    loadEmpowermentsAsync,
    selectCountEmpowerments,
    selectEmpowerments,
    selectFilterOptions, setId, setShowModal, viewPage, changeFilterOptionsAsync
} from "../../../store/reducers/roaming/empowerment/empowermentReducer";
import {VIEW_DRAFT} from "../../../enum/roaming/TypeViewers";
import {EmpowermentWrapper, VIEWER_DRAFT, VIEWER_PERSON} from "../../../enum/roaming/empowerment/EmpowermentStatus";
import Badge from "../../common/Badge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faFile, faList12, faListCheck, faTableCellsLarge, faUser} from "@fortawesome/free-solid-svg-icons";
import SliceCompanyName from "../../common/SliceCompanyName";
import TypeViewerAction from "../../common/TypeViewerAction";
import AdvanceTableWrapper from "../../table/AdvanceTableWrapper";
import EmpowermentDataTableHeader from "./EmpowermentDataTableHeader";
import AdvanceTable from "../../table/AdvanceTable";
import AdvanceTableCustomPagination from "../../table/AdvanceTableCustomPagination";
import ViewModalPage from "../../common/ViewModalPage";
import ViewEmpowerment from "../../../pages/roaming/empowerment/ViewEmpowerment";
import DraftEmpowerment from "../../../pages/roaming/empowerment/DraftEmpowerment";
import {Card, Dropdown} from "react-bootstrap";
import dayjs from "dayjs";
import IconFolderDownload from "../../../assets/icon/IconFolderDownload";
import RevealDropdown, {RevealDropdownTrigger} from "../../common/RevealDropdown";
import {useNavigate} from "react-router-dom";
import {checkViewer} from "../../../enum/roaming/checkViewer";

const EmpowermentDataTable = ({viewer}) => {
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat);
    const activeOrganization = useSelector(selectActiveOrganization);
    const empowerments = useSelector(selectEmpowerments);
    const isLoading = useSelector(selectIsLoading);
    const dispatch = useDispatch();
    const filters = useSelector(selectFilterOptions);
    const count = useSelector(selectCountEmpowerments);
    const {id, draftId, showModal} = useSelector(viewPage)
    const hideModal = useSelector(roamingHideModal)
    const navigate = useNavigate();

    const lang = useSelector(selectLang);
    const t = Translate;

    const openViewModalPage = (e,id) => {
        const ctrlKey = e.ctrlKey
        const history = window.open

        if (viewer === VIEW_DRAFT) {
            navigate('/roaming/draft-empowerment/'+id)
           /* if (ctrlKey) {
                history('/roaming/draft-empowerment/'+id, '_blank')
            } else {
                dispatch(setId({draftId: id}))
                dispatch(setShowModal(true))
            }*/
        } else {
            navigate('/roaming/empowerment/'+id)

            /*if (ctrlKey) {
                history('/roaming/empowerment/'+id, '_blank')
            } else {
                dispatch(setId({id}))
                dispatch(setShowModal(true))
            }*/
        }
    }

    const onHideModalPage = () => {
        dispatch(setShowModal(false))
    }

    // to close the modal on page change
    useEffect(() => {
        if(hideModal) {
            onHideModalPage()
            dispatch(setRoamingCommonHideModal(null))
        }
    }, [])

    const columns = [
        {
            accessor: 'created_at',
            Header:  () => t(lang, "roaming.contracts.datatable.header.row.created_at"),
            Cell:  ({row: {original}}) => <span className={'fw-semi-bold'}>{dayjs(original.created_at, "YYYY-MM-DD").format(currentDateDayJSFormat)}</span>
        },
        {
            accessor: 'contractor',
            Header: <span>{t(lang, "edi.common.label.organization")} | {t(lang, "roaming.common.inn_or_pinfl")}</span> ,
            cellProps: {
                className: 'pe-4'
            },
            headerProps: {width: 300},
            Cell: ({row: {original}}) => {
                const empowermentWrapper = new EmpowermentWrapper(original);
                const isPerson = empowermentWrapper.isPerson(activeOrganization.inn, activeOrganization.pinfl);
                const isCustomer = empowermentWrapper.isCustomer(activeOrganization.inn, activeOrganization.pinfl);
                const isExecutor = empowermentWrapper.isExecutor(activeOrganization.inn, activeOrganization.pinfl);

                return (
                    <Fragment>
                        {(isPerson || isCustomer) && <div className={'d-flex align-items-center gap-2'}>
                            {checkViewer(viewer)}
                            <div>
                             <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, 'roaming.empowerment.contact_info.from.contact_date', {
                                 "contract_number": original?.info?.number,
                                 "contract_date": original?.info?.start
                             })}
                             </span>
                                <span className={'fs-8 main-text fw-bold text-truncate'}>{original.executor.name}</span>
                                <i className={'d-block fs-9 second-text'}>{original.executor.inn || original.executor.pinfl}</i>
                            </div>
                        </div>}

                        {isExecutor &&  <div className={'d-flex align-items-center gap-2'}>
                            {checkViewer(viewer)}
                            <div>
                             <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, 'roaming.empowerment.contact_info.from.contact_date', {
                                 "contract_number": original?.info?.number,
                                 "contract_date": original?.info?.start
                             })}
                             </span>
                                <span className={'fs-8 main-text fw-bold text-truncate'}>{original.customer.name}</span>
                                <i className={'d-block fs-9 second-text'}>{original.customer.inn || original.customer.pinfl}</i>
                            </div>
                        </div>}
                    </Fragment>
                )
            }
        },
        {
            accessor: 'fullName',
            Header: viewer !== VIEWER_PERSON ? <span>{t(lang, "roaming.empowerment.empowerment_person")} | {t(lang, "roaming.common.inn_or_pinfl")}</span>  : t(lang, "roaming.empowerment.empowerment_person"),
            cellProps: {className: ''},
            Cell: ({row: {original}}) => {
                const empowermentWrapper = new EmpowermentWrapper(original);
                const isPerson = empowermentWrapper.isPerson(activeOrganization.inn, activeOrganization.pinfl);
                return (
                    <Fragment>
                        {isPerson && <Fragment>
                            <span className={'fs-8 main-text fw-bold text-truncate'}>{original.customer.name}</span>
                            <i className={'d-block fs-9 second-text'}>{original.customer.pinfl || original.customer.inn}</i>
                        </Fragment>}
                        {!isPerson && <Fragment>
                            <span className={'fs-8 main-text fw-bold text-truncate'}>{original.person_info.full_name}</span>
                            <i className={'d-block fs-9 second-text'}>{original.person_info.person_inn}</i>
                        </Fragment>}
                        {/*{!isPerson && <span>{original.person_info.full_name}</span>}*/}
                    </Fragment>
                )
            }
        },
        viewer !== VIEWER_DRAFT && {
            accessor: 'status',
            Header:  t(lang, "roaming.dashboard.empowerments.status"),
            headerProps: {
              className: "text-center"
            },
            cellProps: {
              className: "text-center"
            },
            Cell: ({row: {original}}) => {
                const empowermentWrapper = new EmpowermentWrapper(original);
                return (
                    <div className={'d-inline-block text-center'}>
                        <Badge
                            bg={empowermentWrapper.GetStatusBadgeVariant(activeOrganization.inn, activeOrganization.pinfl)}
                            variant={"phoenix"}
                            className={'px-3 py-1 fs-9 text-uppercase w-100 text-decoration-none fw-medium border-0 rounded-4'}
                        >
                            {t(lang, empowermentWrapper.GetStatusTranslationKey(activeOrganization.inn, activeOrganization.pinfl))}
                        </Badge>
                    </div>
                )
            }
        },
        {
            accessor: 'action',
            Header: "",
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                        <RevealDropdown>
                            <Dropdown.Item
                                onClick={(event) => {
                                    openViewModalPage(event, original.id)
                                }}>
                                <span>{t(lang, "roaming.invoices.table_header.show")}</span>
                            </Dropdown.Item>
                        </RevealDropdown>
                    </RevealDropdownTrigger>
                );
            }
        },
    ].filter(column => typeof column === 'object' && column !== null)

    useEffect(() => {
        dispatch(loadEmpowermentsAsync(viewer, {viewer_inn: activeOrganization.pinfl || activeOrganization.inn, ...filters}));
    }, [viewer, activeOrganization, filters]);

    const onPageChange = (page) => {
        dispatch(changeFilterOptionsAsync({page: page}))
    }
    const onLimitChange = (limit) => {
        dispatch(changeFilterOptionsAsync({limit: limit, page: 1}))
    }

    return (
        <Fragment>

            <AdvanceTableWrapper
                columns={columns}
                data={empowerments}
                selectionColumnWidth={40}
                pagination
                perPage={1000}
                selection
                sortable
            >
                <EmpowermentDataTableHeader viewer={viewer} table/>
                <Card className={'p-0 rounded-4'}>
                    <TypeViewerAction to={"/roaming/empowerments"} typeViewer={viewer} />
                    <Card.Body className={'p-0'}>
                        <AdvanceTable
                            table
                            headerClassName="text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            isLoading={isLoading}
                            tableProps={{
                                size: 'sm',
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                </Card>

                <AdvanceTableCustomPagination
                    count={count}
                    limit={filters.limit}
                    page={filters.page}
                    onPageChange={onPageChange}
                    onLimitChange={onLimitChange}
                />
            </AdvanceTableWrapper>

            <ViewModalPage show={showModal} onHide={onHideModalPage}>
                {id && <ViewEmpowerment modalId={id} />}
                {draftId && <DraftEmpowerment modalId={draftId} />}
            </ViewModalPage>

        </Fragment>
    );
};

export default EmpowermentDataTable;
