import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {
    getOrganizationUsersAsync,
    selectActiveOrganization,
    selectUsers
} from "../../../store/reducers/auth/authReducer";
import {loadAccountRolesAsync, selectAccountRoles} from "../../../store/reducers/role/roleReducer";
import EventBus from "../../../eventbus/EventBus";
import {ADD_USER_ORGANIZATION_SUCCESS} from "../../../eventbus/authEvents";
import {DELETE_ROLE_SUCCESS, EDIT_ACCOUNT_ROLE_SUCCESS, EDIT_ROLE_SUCCESS} from "../../../eventbus/roleEvents";
import ActionButton from "../../common/ActionButton";
import checkPermission from "../../../enum/permission/CheckPermission";
import toastPermission from "../../../enum/permission/ToastPermission";
import {PermissionSystemRoleBindAccount} from "../../../enum/permission/RolePermission";
import DeleteUser from "./DeleteUser";
import AdvanceTable from "../../table/AdvanceTable";
import AdvanceTableWrapper from "../../table/AdvanceTableWrapper";
import BindRoleIntoAccountFormModal from "./BindRoleIntoAccountFormModal";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import {Card, Dropdown} from "react-bootstrap";
import RevealDropdown, {RevealDropdownTrigger} from "../../common/RevealDropdown";
import NotificationUpdate from "../../user/notification/NotificationUpdate";
import NotificationDelete from "../../user/notification/NotificationDelete";

const UsersDatatable = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch()
    const activeOrganization = useSelector(selectActiveOrganization)
    const accountRoles = useSelector(selectAccountRoles)
    const users = useSelector(selectUsers);
    const [role, setRole] = useState(null)
    const [account, setAccount] = useState(null)
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        dispatch(getOrganizationUsersAsync())
        dispatch(loadAccountRolesAsync())
    }, [activeOrganization])


    useEffect(() => {
        const onUserAddedHandler = EventBus.on(ADD_USER_ORGANIZATION_SUCCESS, () => {
            dispatch(getOrganizationUsersAsync())
            dispatch(loadAccountRolesAsync())
        })
        const onUserEditedRoleHandler = EventBus.on(EDIT_ACCOUNT_ROLE_SUCCESS, () => {
            dispatch(loadAccountRolesAsync())
        })
        const onRoleEditHandler = EventBus.on(EDIT_ROLE_SUCCESS, () => {
            dispatch(loadAccountRolesAsync())
        })

        const onRoleDeleteHandler = EventBus.on(DELETE_ROLE_SUCCESS, () => {
            dispatch(loadAccountRolesAsync())
        })

        return () => {
            EventBus.remove(ADD_USER_ORGANIZATION_SUCCESS, onUserAddedHandler)
            EventBus.remove(EDIT_ACCOUNT_ROLE_SUCCESS, onUserEditedRoleHandler)
            EventBus.remove(EDIT_ROLE_SUCCESS, onRoleEditHandler)
            EventBus.remove(DELETE_ROLE_SUCCESS, onRoleDeleteHandler)
        }
    }, [])

    const filterRoleByUsername = (username) => {
        return accountRoles?.find(acc => {
            return acc?.account?.username === username
        })
    }
    const onBindRoleToAccount = (account) => {
        let accountRole = filterRoleByUsername(account.username);
        setAccount(account)
        if (accountRole)
            setRole({
                name: accountRole.role.name,
                permissions: accountRole.role.permissions,
                id: accountRole.role.id,
            })
        setShowModal(true)
    }
    const onHideBindRoleModal = () => {
        setShowModal(false)
        setAccount(null)
        setRole(null)
    }

    const columns = [
        {
            accessor: 'name',
            Header: t(lang, "users.datatable.full_name"),
            cellProps: {
                className: 'ps-3'
            },
            headerProps: {className: 'ps-3'},
            Cell: ({row: {original}}) => {
                return (
                    <span className={"fw-medium"}>
                        {original?.name}
                    </span>
                )
            },
        },
        {
            accessor: 'username',
            Header: t(lang, "edi.branch.datatable.header.row.code"),
            disableSortBy: true,
            Cell: rowData => {
                const {username} = rowData?.row?.original
                return (
                    <a href={'tel:' + username}>
                        {username}
                    </a>
                )
            },
            cellProps: {className: 'text-center'},
            headerProps: {className: 'text-center'},
        },
        {
            accessor: 'action',
            Header: "",
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                        <RevealDropdown>
                            <Dropdown.Item>
                                <DeleteUser username={original.username}/>
                            </Dropdown.Item>
                        </RevealDropdown>
                    </RevealDropdownTrigger>
                );
            }
        },
    ];


    return (
        <Fragment>
            <AdvanceTableWrapper
                columns={columns}
                data={users}
                sortable
                pagination
                perPage={1000}
                selection
            >
                <Card className={'p-0 rounded-4'}>
                    <Card.Body className={'p-0'}>
                        <AdvanceTable
                            table
                            headerClassName="text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                className: 'fs-8 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                </Card>
            </AdvanceTableWrapper>
            {account &&
                <BindRoleIntoAccountFormModal defaultRole={role} account={account} show={showModal}
                                              onHide={onHideBindRoleModal}/>}
        </Fragment>
    );
};

export default UsersDatatable;
