import React from 'react';
import ReactDOM from 'react-dom/client';
import {HashRouter, RouterProvider} from 'react-router-dom'

import './pheonix/assets/css/theme.min.css'
import './pheonix/assets/css/user.min.css'
import 'react-datepicker/dist/react-datepicker.css';
import './main.css'

import {router} from "./Router";
import BreakpointsProvider from "./pheonix/providers/BreakpointsProvider";
import SettingsPanelProvider from "./pheonix/providers/SettingsPanelProvider";
import AppProvider from "./pheonix/providers/AppProvider";
import {Provider} from "react-redux";
import store from "./store/store";
import {Toaster} from "react-hot-toast";
import AppLayout from "./layout/AppLayout";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
        <Provider store={store}>
            <AppProvider>
                <SettingsPanelProvider>
                    <BreakpointsProvider>
                        <AppLayout />
                        <Toaster position={'top-center'} reverseOrder={true}/>
                    </BreakpointsProvider>
                </SettingsPanelProvider>
            </AppProvider>
        </Provider>

);
