import React, {Fragment, useState} from 'react';
import {useFieldArray, useFormContext} from "react-hook-form";
import {selectLang} from "../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {Table, Form, ListGroup, Accordion} from "react-bootstrap";

const NotificationEventsTable = ({notification = []}) => {
    const {control} = useFormContext();
    const [isCheckAll, setIsCheckAll] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const {fields, replace, append} = useFieldArray({
        control,
        name: 'eventCodes'
    })

    const handleSelectAll = () => {
        setIsCheckAll(!isCheckAll);

        const arr = [];
        notification.forEach(notificationValues => {
            notificationValues.notifications.forEach(val => {
                arr.push({value: val.value});
            })
        })
        replace(arr);

        if (isCheckAll) {
            replace([]);
        }
    }

    const handleClick = (el, notification) => {
        if (el.target.checked) {
            append({value: notification})
        } else {
            replace(fields.filter(field => field.value !== notification))
        }
    }

    const isChecked = (val) => {
        let checked = fields.find(item => {
            return item.value === val;
        });
        return !!checked
    }

    return (
        <Fragment>
           {/* <ListGroup.Item>
                <th className={'d-flex border-white border-0 border-bottom table-primary align-items-center'}/>
                <th className="p-0">
                    <Form.Check type="checkbox" className="m-0">
                        <Form.Check.Label className={'text-dark m-0'}>
                            <Form.Check.Input type="checkbox" onClick={handleSelectAll} className={"ps-2"}
                                              defaultChecked={isCheckAll}/>
                            {t(lang, "notification.check_all")}
                        </Form.Check.Label>
                    </Form.Check>
                </th>
            </ListGroup.Item>*/}
            <Accordion>
                {notification.map((item, index) => {
                  return (
                      <Accordion.Item eventKey={index.toString()} className="border-bottom border-300 p-0 fw-medium text-dark">
                          <Accordion.Header className={'mx-2'}>{t(lang, item.text)}</Accordion.Header>
                          <Accordion.Body className={'p-0'}>
                              <ListGroup className={'bg-card'}>
                                  {item.notifications.map((val, valIndex) => {
                                      return (
                                          <ListGroup.Item className={'d-flex justify-content-between rounded-0 border-left-0 border-right-0 p-1'}>
                                              <span>{t(lang, val.text)}</span>
                                              <Form.Check
                                                  type="checkbox"
                                                  className="form-check mb-0">
                                                  <Form.Check.Label className="form-label text-dark">
                                                      <Form.Check.Input id={val.value}
                                                                        type="checkbox"
                                                                        defaultValue={isChecked(val.value)}
                                                                        onChange={e => {
                                                                            handleClick(e, val.value);
                                                                        }}
                                                                        name={val.text}
                                                                        checked={isChecked(val.value)}/>
                                                  </Form.Check.Label>
                                              </Form.Check>
                                          </ListGroup.Item>
                                      )
                                  })}
                              </ListGroup>
                          </Accordion.Body>
                      </Accordion.Item>
                  )
                })}
            </Accordion>
        </Fragment>
    );
};

export default NotificationEventsTable;
