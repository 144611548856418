import React, {Fragment, useEffect} from 'react';
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {selectProductCatalogs} from "../../../store/reducers/edi/invoice/invoiceReducer";
import {selectCompanyCatalogs} from "../../../store/reducers/roaming/roamingReducer";
import {useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {ReturnInvoiceNdsZeroNoneVatPayers} from "../../../enum/edi/return-invoice/ReturnInvoiceStatus";
import {Col, Row, Table} from "react-bootstrap";
import SelectCompanyCatalog from "../../IKPU/SelectCompanyCatalog";
import ReturnInvoiceItemsTable from "./ReturnInvoiceItemsTable";
import numeral from "numeral";

const itemRootName = "items";

const ReturnInvoiceItems = ({productMapNames}) => {

    const lang = useSelector(selectLang);
    const t = Translate;
    const numberFormat = useSelector(selectNumberFormat);
    const storageCatalogs = useSelector(selectProductCatalogs);
    const catalogs = useSelector(selectCompanyCatalogs)

    const {register, setValue, formState: {errors}, getValues} = useFormContext();
    const {fields} = useFieldArray({name: itemRootName});
    const watchFieldArray = useWatch({name: itemRootName});
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index]
        };
    });

    const ndsOptions = [
        {value: null, label: 'No'},
        {value: 0, label: '0'},
        {value: 12, label: '12'},
        {value: 15, label: '15'}
    ];

    // calculate and set total
    useEffect(() => {
        const totalSum = controlledFields.reduce((acc, item) => acc + item.totalWithNds, 0);
        setValue('returnInvoiceTotalSum', totalSum);
    }, [controlledFields])

    // map names
    useEffect(() => {
        if (productMapNames.length) {
            // loop form fields
            for (let fieldIndex = 0; fieldIndex < controlledFields.length; fieldIndex++) {
                for (let productMapIndex = 0; productMapIndex < productMapNames.length; productMapIndex++) {
                    let fieldProductMapFound = false;
                    const productMap = productMapNames[productMapIndex];

                    for (let productIndex = 0; productIndex < productMap.products.length; productIndex++) {
                        const {customer_product_name, executor_product_name} = productMap.products[productIndex];

                        // if found set executor product name
                        if (customer_product_name.trim() === controlledFields[fieldIndex].product.trim()) {
                            setValue(`${itemRootName}.${fieldIndex}.product`, executor_product_name);
                            fieldProductMapFound = true;
                            break;
                        }
                    }
                    if (fieldProductMapFound)
                        break;
                }
            }
        }
    }, [productMapNames])

    // set default catalogs/benefits/item_packages
    useEffect(() => {
        controlledFields.map((item, index) => {
            if (item.catalogCode)
                return;

            const catalog = catalogs.find(c => c.class_code === storageCatalogs?.[item.product]?.catalogClassCode);
            if (catalog) {
                onChangeCatalog(index, catalog);
                onChangeOrigin(index, storageCatalogs[item.product].origin);

                const itemPackage = catalog.package_names.find(p => p.code.toString() === storageCatalogs?.[item.product]?.packageCode);
                const benefit = catalog.lgotas.find(b => b.new_id === storageCatalogs?.[item.product]?.benefitId || b.id === storageCatalogs?.[item.product]?.benefitId);
                if (itemPackage)
                    onChangePackage(index, itemPackage)
                if (benefit)
                    onChangeBenefit(index, benefit)
            }
        })
    }, [storageCatalogs, catalogs])

    // on change bulk catalog/privilege
    const onBulkChangeCatalog = (catalog, benefit) => {
        for (let i = 0; i < controlledFields.length; i++) {
            onChangeCatalog(i, catalog);
            onChangeBenefit(i, benefit);
        }
    };

    const onBulkChangeOrigin = (origin) => {
        for (let i = 0; i < controlledFields.length; i++) {
            onChangeOrigin(i, origin);
        }
    };

    const onChangeOrigin = (index, origin) => {
        setValue(`${itemRootName}.${index}.origin`, origin || null);
    };

    const onChangeCatalog = (index, catalog) => {
        if (catalog) {
            setValue(`${itemRootName}.${index}.catalogCode`, catalog.class_code);
            setValue(`${itemRootName}.${index}.catalogName`, catalog.name);
            setValue(`${itemRootName}.${index}.packageNames`, catalog.package_names);
            setValue(`${itemRootName}.${index}.usePackage`, catalog.use_package);
            setValue(`${itemRootName}.${index}.origin`, null);
        } else {
            setValue(`${itemRootName}.${index}.catalogCode`, null);
            setValue(`${itemRootName}.${index}.catalogName`, null);
            setValue(`${itemRootName}.${index}.packageNames`, []);
            setValue(`${itemRootName}.${index}.usePackage`, false);
            setValue(`${itemRootName}.${index}.origin`, null);
        }

        onChangeBenefit(index, null);
        onChangePackage(index, null);
    };

    const onChangeBenefit = (index, benefit) => {
        if (benefit) {
            const invoiceDate = getValues('returnInvoiceInfo.date');
            const ndsRate = invoiceDate?.getFullYear() > 2022 ? 12 : 15;
            const benefitId = invoiceDate?.getFullYear() >= 2022 ? benefit.new_id : benefit.id
            let benefitPrice = 0;

            if (benefit.type === ReturnInvoiceNdsZeroNoneVatPayers)
                benefitPrice = getValues(`${itemRootName}.${index}.total`) * ndsRate / 100;

            setValue(`${itemRootName}.${index}.benefitId`, benefitId)
            setValue(`${itemRootName}.${index}.benefitName`, benefit.name)
            setValue(`${itemRootName}.${index}.benefitType`, benefit.type)
            setValue(`${itemRootName}.${index}.benefitPrice`, benefitPrice);
        } else {
            setValue(`${itemRootName}.${index}.benefitId`, null)
            setValue(`${itemRootName}.${index}.benefitName`, null)
            setValue(`${itemRootName}.${index}.benefitType`, null)
            setValue(`${itemRootName}.${index}.benefitPrice`, null)
        }
    };

    const onChangePackage = (index, itemPackage) => {
        if (itemPackage) {
            setValue(`${itemRootName}.${index}.packageCode`, itemPackage.code)
            setValue(`${itemRootName}.${index}.packageName`, lang === "ru" ? itemPackage.name_ru : itemPackage.name_uz);
        } else {
            setValue(`${itemRootName}.${index}.packageCode`, null)
            setValue(`${itemRootName}.${index}.packageName`, null);
        }
    };

    const onChangeQuantity = (index, quantity) => {
        const itemName = `${itemRootName}.${index}`;
        const ndsRate = +getValues(`${itemName}.ndsRate`) || 0

        const total = getValues(`${itemName}.price`) * quantity;
        const ndsValue = total * ndsRate / 100;
        const totalWithNds = total + ndsValue;

        setValue(`${itemName}.quantity`, quantity);
        setValue(`${itemName}.total`, total);
        setValue(`${itemName}.ndsValue`, ndsValue);
        setValue(`${itemName}.totalWithNds`, totalWithNds);
    };

    const onChangeNdsRate = (index, nds) => {
        const itemName = `${itemRootName}.${index}`;
        const ndsRate = +nds || 0

        const total = getValues(`${itemName}.price`) * getValues(`${itemName}.quantity`);
        const ndsValue = total * ndsRate / 100;
        const totalWithNds = total + ndsValue;
        setValue(`${itemName}.ndsRate`, ndsRate)
        setValue(`${itemName}.nds`, ndsRate);
        setValue(`${itemName}.total`, total);
        setValue(`${itemName}.ndsValue`, ndsValue);
        setValue(`${itemName}.totalWithNds`, totalWithNds);
    }

    useEffect(() => {
        controlledFields.forEach((item, index) => {
            const itemName = `${itemRootName}.${index}`;
            const ndsRate = itemName.ndsRate || 0
            const total = getValues(`${itemName}.price`) * getValues(`${itemName}.quantity`);
            const ndsValue = total * ndsRate / 100
            return setValue(`${itemName}.totalWithNds`, total + ndsValue)
        })
    }, [])

    const subTotal = controlledFields.reduce((acc, item) => acc + (item.price * item.quantity), 0);
    const tax = controlledFields.reduce((acc, item) => {
        return acc + ((item.price * item.quantity) * item.ndsRate) / 100
    }, 0);

    return (
        <Fragment>
            <Row className={'align-items-center mt-3'}>
                <Col xs={12} md={6}>
                    <h5>{t(lang, "edi.common.items")}</h5>
                </Col>
                <Col xs={12} md={6} className="text-end">
                    <SelectCompanyCatalog variant="phoenix-primary"
                                          size="sm"
                                          className="w-auto"
                                          textButton={t(lang, "edi.common.select_common_catalog_class_code_button")}
                                          onChange={onBulkChangeCatalog}
                    />
                </Col>
            </Row>

            <ReturnInvoiceItemsTable
                itemRootName={itemRootName}
                controlledFields={controlledFields}
                ndsOptions={ndsOptions}
                onBulkChangeOrigin={onBulkChangeOrigin}
                onChangeBenefit={onChangeBenefit}
                onChangeCatalog={onChangeCatalog}
                onChangeNdsRate={onChangeNdsRate}
                onChangeOrigin={onChangeOrigin}
                onChangeQuantity={onChangeQuantity}
                onChangePackage={onChangePackage}
                productMapNames={productMapNames}
            />

            <Row className="g-0 justify-content-end bg-200">
                <Col xs={"auto"}>
                    <Table borderless size="sm" className="fs--1 text-end">
                        <tbody>
                        <tr>
                            <th className="text-900">{t(lang, 'roaming.common.subtotal')}:</th>
                            <td className="fw-semi-bold">
                                {numeral(subTotal).format(numberFormat)}
                            </td>
                        </tr>
                        <tr>
                            <th className="text-900">{t(lang, 'roaming.common.tax')}:</th>
                            <td className="fw-semi-bold">
                                {numeral(tax).format(numberFormat)}
                            </td>
                        </tr>
                        <tr className="border-top">
                            <th className="text-900">{t(lang, 'roaming.common.total')}:</th>
                            <td className="fw-semi-bold">
                                {numeral(subTotal + tax).format(numberFormat)}
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>

        </Fragment>
    );
};

export default ReturnInvoiceItems;
