import React, {Fragment, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import toast from "react-hot-toast";
import {
    ACCEPT_FREE_FORM_DOCUMENT_SUCCEED,
    CANCEL_FREE_FORM_DOCUMENT_SUCCEED, DECLINE_FREE_FORM_DOCUMENT_SUCCEED,
    SIGN_FREE_FORM_DOCUMENT_SUCCEED
} from "../../../eventbus/romaing/free-form/roamingFreeFormDocumentEvents";
import EventBus from "../../../eventbus/EventBus";
import {loadFreeFormDocumentAsync} from "../../../store/reducers/roaming/free-form/freeFormDocumentReducer";
import SpinnerComponent from "../../../components/common/SpinnerComponent";
import FreeFormDocument from "../../../components/roaming/free-form/FreeFormDocument";

const ViewFreeFormDocument = ({modalId}) => {
    const {id} = useParams();
    const [freeFormDocument, setFreeFormDocument] = useState(null);
    const activeOrganization = useSelector(selectActiveOrganization);

    useEffect(() => {
        loadFreeFormDocument();
    }, [modalId,id, activeOrganization])

    useEffect(() => {
        const onSignFreeFormDocumentSucceedHandler = EventBus.on(SIGN_FREE_FORM_DOCUMENT_SUCCEED, () => {
            toast.success("Success");
            loadFreeFormDocument();
        });

        const onCancelFreeFormDocumentSucceedHandler = EventBus.on(CANCEL_FREE_FORM_DOCUMENT_SUCCEED, () => {
            toast.success("Success");
            loadFreeFormDocument();
        });

        const onAcceptFreeFormDocumentSucceedHandler = EventBus.on(ACCEPT_FREE_FORM_DOCUMENT_SUCCEED, () => {
            toast.success("Success");
            loadFreeFormDocument();
        });

        const onDeclineFreeFormDocumentSucceedHandler = EventBus.on(DECLINE_FREE_FORM_DOCUMENT_SUCCEED, () => {
            toast.success("Success");
            loadFreeFormDocument();
        });

        return () => {
            EventBus.remove(SIGN_FREE_FORM_DOCUMENT_SUCCEED, onSignFreeFormDocumentSucceedHandler);
            EventBus.remove(CANCEL_FREE_FORM_DOCUMENT_SUCCEED, onCancelFreeFormDocumentSucceedHandler);
            EventBus.remove(ACCEPT_FREE_FORM_DOCUMENT_SUCCEED, onAcceptFreeFormDocumentSucceedHandler);
            EventBus.remove(DECLINE_FREE_FORM_DOCUMENT_SUCCEED, onDeclineFreeFormDocumentSucceedHandler);
        }
    }, [])

    const loadFreeFormDocument = () => {
        loadFreeFormDocumentAsync(modalId ? modalId : id)
            .then(loadedDocument => {
                setFreeFormDocument(loadedDocument)
            })
            .catch(error => console.log(error))
    }


    return (
        <Fragment>
            {freeFormDocument && <FreeFormDocument document={freeFormDocument}/>}
            {!freeFormDocument && <SpinnerComponent />}
        </Fragment>
    );
};

export default ViewFreeFormDocument;
