import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Translate} from "../../lang/lang";
import EventBus from "../../eventbus/EventBus";
import {ACTIVATE_PACKAGE_SUCCEED} from "../../eventbus/billingEvents";
import {getOrganizationBillingInfoAsync} from "../../store/reducers/billing/billingReducer";
import BalanceInfoCard from "../../components/user/billing/BalanceInfoCard";
import {selectActiveOrganization} from "../../store/reducers/auth/authReducer";

const ACTIVE_PACKAGES = '1';
const ALL_PACKAGES = '2';
const OTHER = '3'
const Billing = () => {
    const navigate= useNavigate();
    const activeOrganization = useSelector(selectActiveOrganization);

    const dispatch = useDispatch();
    const t = Translate;

    const loadBillingInfo = () => {
        dispatch(getOrganizationBillingInfoAsync())
    }

    useEffect(() => {
        loadBillingInfo();
    }, [activeOrganization])


    useEffect(() => {
        const onActivePackageSucceedHandler = EventBus.on(ACTIVATE_PACKAGE_SUCCEED, () => {
            dispatch(getOrganizationBillingInfoAsync());
        });

        return () => {
            EventBus.remove(ACTIVATE_PACKAGE_SUCCEED, onActivePackageSucceedHandler);
        }
    }, [])

    return (
        <BalanceInfoCard />
    );
};

export default Billing;
