import React from 'react';
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {Button, Col, InputGroup, Row, Form} from "react-bootstrap";
import {faArrowRotateRight, faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const MassAddRoamingInvoiceImportDetails = ({tableRef, sheetArrayName, skipRowsArrayName, sheetIndex, onResetColumns}) => {
    const specificSheetSkipRowsArrayName = `${skipRowsArrayName}.${sheetIndex}.count_row`;
    const skipRows = useWatch({name: specificSheetSkipRowsArrayName});
    const {setValue, formState: {errors}} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;

    let columnErrors = {};
    if (errors && errors[sheetArrayName] && errors[sheetArrayName][sheetIndex])
        columnErrors = errors[sheetArrayName][sheetIndex];

    const incrementSkipRows = () => {
        setValue(specificSheetSkipRowsArrayName, skipRows + 1);
    };
    const decrementSkipRows = () => {
        setValue(specificSheetSkipRowsArrayName, skipRows - 1);
    };
    const resetColumns = () => {
        onResetColumns();
    }

    const scrollToLeft = () => tableRef.current.scrollLeft -= 400;
    const scrollToRight = () => tableRef.current.scrollLeft += 400;

    return (
        <Row className="p-3">
            <Col xs="12" sm="6" md="auto">
                <Form.Group>
                    <Form.Label>{t(lang, 'roaming.invoice.mass_add.import_details.ignore_top_lines')}</Form.Label>
                    <InputGroup>
                        <Button variant="outline-secondary" onClick={decrementSkipRows}>-</Button>
                        <Controller
                            name={specificSheetSkipRowsArrayName}
                            render={({field}) => (
                                <Form.Control
                                    type="number"
                                    value={field.value}
                                    onChange={field.onChange}
                                    className="input-spin-none"
                                />
                            )}
                        />
                        <Button variant="outline-secondary" onClick={incrementSkipRows}>+</Button>
                    </InputGroup>
                </Form.Group>
            </Col>

            <Col xs="12" sm="auto" className="my-3 ms-sm-auto align-self-end">
                <Button onClick={resetColumns} variant="phoenix-warning">
                    <FontAwesomeIcon className="me-2" icon={faArrowRotateRight}/>
                    {t(lang, 'roaming.invoice.mass_add.import_details.reset_columns')}
                </Button>
            </Col>

            <div className="w-100 d-flex flex-between-center my-3">
                <span className="text-danger">{columnErrors?.message}</span>

                <div className="w-50 text-end">
                    <Button variant="phoenix-secondary" className="mx-1" onClick={scrollToLeft}>
                        <FontAwesomeIcon icon={faChevronLeft}/>
                        <FontAwesomeIcon icon={faChevronLeft}/>
                    </Button>
                    <Button variant="phoenix-secondary" className="mx-1" onClick={scrollToRight}>
                        <FontAwesomeIcon icon={faChevronRight}/>
                        <FontAwesomeIcon icon={faChevronRight}/>
                    </Button>
                </div>
            </div>
        </Row>

    );
};

export default MassAddRoamingInvoiceImportDetails;
