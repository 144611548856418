import React from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {Controller, useFormContext} from "react-hook-form";
import {Col, FloatingLabel, Form, InputGroup, Row} from "react-bootstrap";
import RoamingInvoiceVatRegStatusBadge from "../RoamingInvoiceVatRegStatusBadge";
import FindCompanySearchable from "../../organization/FindCompanySearchable";
import SelectBankAccount from "../../common/SelectBankAccount";
import {selectLang} from "../../../store/reducers/main/main";
import RoamingInvoiceFormDistrictSelector from "./RoamingInvoiceFormDistrictSelector";
import RoamingInvoiceFormBranchSelector from "./RoamingInvoiceFormBranchSelector";
import ReactSelect from "react-select";
import CustomFloatingLabelSelect from "../../floating-custom-select/CustomFloatingLabelSelect";

const RoamingInvoiceFormMember = ({
                                      fieldName,
                                      isSearchable,
                                      onChangeCompany,
                                      company,
                                  }) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();

    const onChangeBankAccount = (option) => {
        if (option) {
            form.setValue(`${fieldName}.account`, option.account);
            form.setValue(`${fieldName}.bankId`, option.bank_id);
        } else {
            form.setValue(`${fieldName}.account`, '');
            form.setValue(`${fieldName}.bankId`, '');
        }
    };
    return (
        <Row className={'flex-column gap-2'}>
            <Col xs={12}>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <FloatingLabel label={t(lang, 'roaming.contracts.add.contract_info.name')}>
                                <Form.Control
                                    name={`${fieldName}.name`}
                                    className={'text-truncate'}
                                    placeholder={t(lang, '')}
                                    isInvalid={form.formState.errors?.[fieldName]?.name}
                                    {...form.register(`${fieldName}.name`, {
                                        required: t(lang, 'items.common.validations.is_required'),
                                        shouldUnregister: true
                                    })}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Control.Feedback type="invalid">{form.formState.errors?.[fieldName]?.name?.message}</Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Controller
                                name={`${fieldName}.innOrPinfl`}
                                shouldUnregister={true}
                                rules={{
                                    required: t(lang, 'items.common.validations.is_required'),
                                }}
                                render={({field, fieldState: {error}}) => (
                                    <FindCompanySearchable
                                        id={fieldName}
                                        defaultInn={field.value}
                                        onChange={(company) => {
                                            onChangeCompany(company);
                                            field.onChange(company ? (company.person_num || company.inn) : null)
                                        }}
                                        placeholder={`${t(lang, 'roaming.common.inn_or_pinfl')} *`}
                                        disabled={!isSearchable}
                                        inputProps={{
                                            isSearchable: !field.value,
                                            menuPortalTarget: document.body,
                                            styles: {
                                                control: base => ({
                                                    ...base,
                                                    border: error?.message && '1px solid #e63757'
                                                }),
                                                container: (provided) => ({
                                                    ...provided,
                                                    margin: '-1px'
                                                }),
                                                menuPortal: (provided) => ({
                                                    ...provided,
                                                    zIndex: 9999
                                                })
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Col>
            <Col xs={12}>
                <Form.Group>
                    <FloatingLabel label={t(lang, 'roaming.common.address')}>
                        <Form.Control
                            name={`${fieldName}.address`}
                            placeholder={t(lang, '')}
                            {...form.register(`${fieldName}.address`, {
                                          shouldUnregister: true
                                      })}
                        />
                    </FloatingLabel>
                </Form.Group>
            </Col>
            <Col xs={12}>
                <Controller
                    name={`${fieldName}.districtId`}
                    shouldUnregister={true}
                    render={({field}) => (
                        <RoamingInvoiceFormDistrictSelector
                            id={`${fieldName}.districtId`}
                            label={t(lang, 'roaming.common.district')}
                            defaultDistrictId={field.value}
                            onChange={field.onChange}
                            selectProps={{
                                styles: {
                                    container: (provided) => ({
                                        ...provided,
                                        margin: '-1px'
                                    }),
                                    menuPortal: (provided) => ({
                                        ...provided,
                                        zIndex: 9999
                                    })
                                }
                            }}/>
                    )}
                />
            </Col>
            <Col xs={12}>
                <Row>
                    <Col xs={12} md={5}>
                        <Form.Group>
                            <Controller name={`${fieldName}.account`} render={({field}) => (
                                <SelectBankAccount
                                    id={`${fieldName}.account`}
                                    bankAccounts={company?.bank_accounts || []}
                                    onChange={onChangeBankAccount}
                                    label={t(lang, 'roaming.common.bank_account')}
                                    defaultId={field.value}
                                />
                            )} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={5}>
                        <Form.Group>
                            <FloatingLabel label={t(lang, 'roaming.common.bank_mfo')}>
                                <Form.Control name={`${fieldName}.bankId`}
                                              placeholder={t(lang, 'roaming.common.bank_mfo')}
                                              {...form.register(`${fieldName}.bankId`, {
                                                  shouldUnregister: true
                                              })}
                                />
                            </FloatingLabel>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={2}>
                        <Form.Group>
                            <FloatingLabel label={t(lang, 'roaming.common.bank_mfo')}>
                                <Form.Control name={`${fieldName}.oked`}
                                              placeholder={t(lang, 'roaming.common.bank_mfo')}
                                              {...form.register(`${fieldName}.oked`, {
                                                  shouldUnregister: true
                                              })}
                                />
                            </FloatingLabel>
                        </Form.Group>
                    </Col>
                </Row>
            </Col>
            <Col xs={12}>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <FloatingLabel label={t(lang, 'roaming.common.nds_code')}>
                                <Form.Control name={`${fieldName}.vatRegCode`}
                                              placeholder={t(lang, 'roaming.common.nds_code')}
                                              {...form.register(`${fieldName}.vatRegCode`, {
                                                  shouldUnregister: true
                                              })}
                                />
                            </FloatingLabel>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <FloatingLabel label={t(lang, 'roaming.common.nds_status')}>
                                <Form.Control name={`${fieldName}.vatRegStatus`}
                                              placeholder={t(lang, 'roaming.common.nds_status')}
                                              {...form.register(`${fieldName}.vatRegStatus`, {
                                                  shouldUnregister: true
                                              })}
                                />
                            </FloatingLabel>
                        </Form.Group>
                    </Col>
                </Row>
            </Col>
            <Col xs={12}>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <FloatingLabel label={t(lang, 'roaming.common.director')}>
                                <Form.Control name={`${fieldName}.director`}
                                              placeholder={t(lang, 'roaming.common.director')}
                                              {...form.register(`${fieldName}.director`, {
                                                  shouldUnregister: true
                                              })}
                                />
                            </FloatingLabel>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <FloatingLabel label={t(lang, 'roaming.common.accountant')}>
                                <Form.Control name={`${fieldName}.accountant`}
                                              placeholder={t(lang, 'roaming.common.accountant')}
                                              {...form.register(`${fieldName}.accountant`, {
                                                  shouldUnregister: true
                                              })}
                                />
                            </FloatingLabel>
                        </Form.Group>
                    </Col>
                </Row>
            </Col>
            <Col xs={12}>
                {company && company.branches.length > 0 && <Form.Group>
                        <Controller name={`${fieldName}.branch`}
                                    shouldUnregister={true}
                                    render={({field}) => (
                                        <RoamingInvoiceFormBranchSelector
                                            id={`${fieldName}.branch`}
                                            placeholder={t(lang, 'roaming.invoice.branch')}
                                            branches={company.branches}
                                            defaultBranchCode={field.value ? field.value.code : null}
                                            onChange={field.onChange}
                                        />
                                    )}
                        />
                </Form.Group>}
                <Form.Group as={Row} className="mb-2" hidden>
                    <Form.Label className={'fw-semi-bold fs-9'} column xs="12" sm="4" md="12" lg="4">{t(lang, 'roaming.common.work_phone')}</Form.Label>
                    <Col xs={12} sm={8} md={12} lg={8}>
                        <Form.Control name={`${fieldName}.workPhone`}
                                      placeholder={t(lang, 'roaming.common.work_phone.placeholder')}
                                      {...form.register(`${fieldName}.workPhone`, {
                                          shouldUnregister: true
                                      })}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2" hidden>
                    <Form.Label className={'fw-semi-bold fs-9'} column xs="12" sm="4" md="12" lg="4">{t(lang, 'roaming.common.mobile')}</Form.Label>
                    <Col xs={12} sm={8} md={12} lg={8}>
                        <Form.Control name={`${fieldName}.mobile`}
                                      placeholder={t(lang, 'roaming.common.mobile.placeholder')}
                                      {...form.register(`${fieldName}.mobile`, {
                                          shouldUnregister: true
                                      })}
                        />
                    </Col>
                </Form.Group>
            </Col>
        </Row>
    );
};

export default RoamingInvoiceFormMember;
