import React, {useState} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectCurrentCertificate} from "../../../store/reducers/eimzo/eimzoReducer";
import {selectLang} from "../../../store/reducers/main/main";
import EventBus from "../../../eventbus/EventBus";
import {
    ROAMING_INVOICE_BULK_ACTION_FINISHED,
    ROAMING_INVOICE_BULK_ACTION_STARTED
} from "../../../eventbus/romaing/roaming-invoice/roamingInvoiceEvents";
import {
    loadInvoicesAsync,
    sendAsync
} from "../../../store/reducers/roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import {DRAFT} from "../../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import {ACTION_PROGRESS_BAR_CLOSED} from "../../../eventbus/actionProgressBarEvents";
import SelectCertificateWrapperComponent from "../../common/SelectCertificateWrapperComponent";
import IconButton from "../../IconButton";
import {Spinner} from "react-bootstrap";
import {faSignature} from "@fortawesome/free-solid-svg-icons";
import IconSignature from "../../../assets/icon/IconSignature";

const RoamingInvoiceDraftSendAll = ({filters, predefinedFilters, setCount, handleShowProgressBar, updateActionResult, ...props}) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const activeCertificate = useSelector(selectCurrentCertificate);

    // button loader
    const [loading, setLoading] = useState(false);

    const onSendTriggered = async () => {
        if (!window.confirm(t(lang, 'roaming.invoice.send_button_all_confirmation_text')))
            return;

        let stop = false
        const onActionProgressBarCloseHandler = EventBus.on(ACTION_PROGRESS_BAR_CLOSED, () => {
            stop = true
        })

        handleShowProgressBar();
        EventBus.dispatch(ROAMING_INVOICE_BULK_ACTION_STARTED);

        // load invoices
        let invoices = []
        let page = 1
        let limit = 1000;
        while (true) {
            const finalFilters = {...filters, ...predefinedFilters, limit: limit, page: page}
            const data = await loadInvoicesAsync(DRAFT, finalFilters)
            if (data.length <= 0)
                break

            for (let i = 0; i < data.length; i++) {
                invoices.push(data[i])
            }
            page++
        }

        setCount(invoices.length);

        const taskSize = 10;
        const tasks = []

        const signer = async invoice => {
            if (stop) {
                return
            }
            try {
                await sendAsync(activeCertificate, lang, invoice.content);
                updateActionResult({
                    number: invoice.info.number,
                    date: invoice.info.date,
                    contractor: {
                        name: invoice.customer.name,
                        inn: invoice.customer.inn
                    }
                });
            } catch (error) {
                updateActionResult({
                    number: invoice.info.number,
                    date: invoice.info.date,
                    contractor: {
                        name: invoice.customer.name,
                        inn: invoice.customer.inn
                    },
                    error: error?.message
                })
            }
        }

        for (let i = 0; i < invoices.length; i++) {
            tasks.push(signer(invoices[i]))

            if (tasks.length >= taskSize) {
                await Promise.all(tasks)
                tasks.splice(0, tasks.length)
            }
        }

        if (tasks.length > 0) {
            await Promise.all(tasks)
            tasks.splice(0, tasks.length)
        }

        EventBus.remove(ACTION_PROGRESS_BAR_CLOSED, onActionProgressBarCloseHandler)
        EventBus.dispatch(ROAMING_INVOICE_BULK_ACTION_FINISHED);
    };

    return (
        <SelectCertificateWrapperComponent
            className={'btn-davr bg-primary-davr gap-1 text-white'}
            openDialogButtonText={t(lang, "roaming.invoice.send_button_all")}
            icon={<IconSignature />}
            submitButton={
            <IconButton icon={faSignature}
                        variant="phoenix-primary"
                        id="DraftSendAll"
                        disabled={loading}
                        onClick={onSendTriggered}
                        className="btn-davr bg-white"
                        {...props}
            >
                {loading && <Spinner size="sm" className='align-middle me-2' animation='border' role='switch'/>}
                {t(lang, "roaming.invoice.send_button_all")}
            </IconButton>
        }  />
    );
};

export default RoamingInvoiceDraftSendAll;
