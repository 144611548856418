import React, {useEffect, useRef, useState} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import ReactSelect from "react-select";
import {incomeTypes, roamingInvoiceTypes} from "../../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import CustomFloatingLabelSelect from "../../floating-custom-select/CustomFloatingLabelSelect";

const RoamingInvoiceFormIncomeTypeSelector = ({defaultIncomeType, onChange}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    const handleChange = (option) => {
        onChange(option)
    }
    return (
        <div className={'select'}>
            <CustomFloatingLabelSelect
                options={incomeTypes}
                label={t(lang, 'roaming.invoice.incomeType')}
                getOptionValue={option => option.value}
                getOptionLabel={option => t(lang, option.label)}
                value={incomeTypes.find(o => o.value === defaultIncomeType) || null}
                onChange={(option) => handleChange(option ? option.value : null)}
                isClearable={true}
            />
        </div>

    );
};

export default RoamingInvoiceFormIncomeTypeSelector;
