import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const STORAGE_DATE_FORMAT = 'dateFormat';
const STORAGE_NUMBER_FORMAT = 'numberFormat';

export const NumberFormatOptions = [
    {type: 'comma', value: '100,000.00'},
    {type: 'decimal', value: '100 000.00'}
]

export const DateFormatOptions = [
    'dd-MM-yyyy',
    'dd.MM.yyyy',
    'dd/MM/yyyy',
    'yyyy-MM-dd',
    'yyyy.MM.dd',
    'yyyy/MM/dd'
]

export const initialState = {
    lang: localStorage.getItem('lang') || 'ru',
    showInProgress: false,
    dateFormat: localStorage.getItem(STORAGE_DATE_FORMAT) || 'dd-MM-yyyy',
    numberFormat: localStorage.getItem(STORAGE_NUMBER_FORMAT) || 'decimal',
    systemMoneyFormat: 'money',
}

export const mainSlice =createSlice({
    name: 'main',
    initialState,
    reducers: {
        setLanguage: (state,action) => {
            localStorage.setItem('lang', action.payload)
            state.lang = action.payload
        },
        updateShowInProgress: (state, action) => {
            state.showInProgress = action.payload
        },
        changeDateFormat: (state, action) => {
            state.dateFormat = action.payload;
            localStorage.setItem(STORAGE_DATE_FORMAT, state.dateFormat);
        },
        changeNumberFormat: (state, action) => {
            state.numberFormat = action.payload;
            localStorage.setItem(STORAGE_NUMBER_FORMAT, state.numberFormat);
        },
    }
})

export const selectLang = state => {
    const activeLang = state.settings.settings?.lang?.find(l => l?.is_active) || null
    return activeLang?.code || "ru"
}
export const selectDateDayJSFormat = (state) => state.main.dateFormat.replace(/y/g, 'Y').replace(/d/g, 'D');
export const selectSystemMoneyFormat = (state) => state.main.systemMoneyFormat;
export const selectDateFormat = (state) => state.main.dateFormat;
export const selectNumberFormat = (state) => state.main.numberFormat;
export const updateShowInProgressAsync = data => dispatch => {
    dispatch(mainSlice.actions.updateShowInProgress(data))
}
export const selectShowInProgress = (state) => state.main.showInProgress

export const {setLanguage,changeDateFormat,changeDashboardPath,changeNumberFormat} = mainSlice.actions
export default mainSlice.reducer

