import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectWarehouses} from "../../../../store/reducers/warehouse/warehouseReducer";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {bindBranchWarehouseAsync} from "../../../../store/reducers/branch/branchReducer";
import toast from "react-hot-toast";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/creatable";
import SelectBranch from "../../../common/SelectBranch";

const BindWarehouseModal = ({show, setShow}) => {

    const warehouse = useSelector(selectWarehouses)
    const lang = useSelector(selectLang);
    const t = Translate;
    const [loading, setLoading] = useState(false)
    const [selectedValue,setSelectedValue] = useState([])

    const methods = useForm({
        defaultValues: {
            branch: null,
            warehouses: []
        }
    })

    const branchName = methods.watch('branch')

    useEffect(() => {
        setSelectedValue(branchName ?  branchName?.warehouses : [])
    }, [branchName])

    const onHide = () => {
        methods.reset()
        setShow(false)
    }

    const handleChange = (e) => {
        setSelectedValue(e.map(x => {
            return {
                id: x.id,
                name: x.name
            }
        }))
    }

    const onSubmit = (formData) => {
        setLoading(true)
        bindBranchWarehouseAsync({
            id: formData.branch.id,
            warehouses:  selectedValue?.map((item => {
                return item.id
            }))
        })
            .then(() => onHide())
            .catch(() => toast.error(t(lang, 'edi.common.toast.error')))
            .finally(() => setLoading(false))
    }


    return (
        <Modal show={show} onHide={onHide} size={'md'}>
            <Modal.Header closeButton>
                <Modal.Title>{t(lang, "edi.branch.bind.warehouses.button")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormProvider {...methods}>
                    <Form id={'bindWarehouse'} onSubmit={methods.handleSubmit(onSubmit)}>
                        <Form.Group>
                            <Form.Label>{t(lang, 'edi.branch.datatable.header.title')}</Form.Label>
                            <Controller name={'branch'} rules={{
                                required: t(lang, 'roaming.validations.is_required')
                            }} render={({field}) => (
                                <SelectBranch
                                    placeholder={t(lang, "edi.common.button.select")}
                                    onChange={field.onChange}
                                />
                            )} />
                            <Form.Control.Feedback className={'d-block'} type={'invalid'}>{methods.formState.errors?.branch?.message}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{t(lang, 'warehouse.common.item.title.warehouses')}</Form.Label>
                            <Controller name={'warehouses'} render={({field})=> (
                                <CreatableSelect
                                    isMulti
                                    getOptionValue={(option)=> option?.id}
                                    getOptionLabel={(option) => option?.name}
                                    options={warehouse}
                                    value={selectedValue}
                                    onChange={handleChange}
                                    placeholder={t(lang, "edi.common.button.select")}
                                />
                            )} />
                        </Form.Group>
                    </Form>
                </FormProvider>
            </Modal.Body>
            <Modal.Footer>
                <Button type='button' variant='falcon-primary' onClick={onHide}>{t(lang, 'edi.common.button.close')}</Button>
                <Button type='submit' form={'bindWarehouse'} variant='primary' disabled={loading}>
                    {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                    {t(lang, 'edi.common.button.save')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

BindWarehouseModal.prototype = {
    show: PropTypes.bool,
    setShow: PropTypes.func
}
export default BindWarehouseModal;
