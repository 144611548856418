import React, {useState} from 'react';
import {selectCurrentCertificate} from "../../../../store/reducers/eimzo/eimzoReducer";
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import EventBus from "../../../../eventbus/EventBus";
import {
    WAYBILL_V2_BULK_ACTION_FINISHED,
    WAYBILL_V2_BULK_ACTION_STARTED
} from "../../../../eventbus/romaing/waybill-v2/roamingWaybillV2Events";
import {sendWaybillV2Async} from "../../../../store/reducers/roaming/waybill-v2/romaingWaybillV2Reducer";
import SelectCertificateWrapperComponent from "../../../common/SelectCertificateWrapperComponent";
import IconButton from "../../../IconButton";
import {faSignature} from "@fortawesome/free-solid-svg-icons";
import {Spinner} from "react-bootstrap";
import IconSignature from "../../../../assets/icon/IconSignature";

const WaybillV2DraftSend = ({waybill, waybills, handleShowProgressBar, updateActionResult, ...props}) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const activeCertificate = useSelector(selectCurrentCertificate);

    // button loader
    const [loading, setLoading] = useState(false);

    // send
    const send = () => {
        if (waybills.length > 0)
            bulkSend();
        else
            singleSend();
    }


    // single send document
    const singleSend = () => {
        setLoading(true);
        sendWaybillV2Async(activeCertificate, lang, {hashCode: waybill.content})
            .then(() => {
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            })
    }

    // bulk send document
    const bulkSend = async () => {
        handleShowProgressBar();
        EventBus.dispatch(WAYBILL_V2_BULK_ACTION_STARTED);

        const taskSize = 10;
        const tasks = []

        const signer = async waybill => {
            try {
                await sendWaybillV2Async(activeCertificate, lang, {hashCode: waybill.content});
                updateActionResult({
                    number: waybill.info.number,
                    date: waybill.info.date,
                    contractor: {
                        name: waybill.customer.name,
                        inn: waybill.customer.inn_or_pinfl || waybill.customer.inn
                    }
                });
            } catch (error) {
                updateActionResult({
                    number: waybill.info.number,
                    date: waybill.info.date,
                    contractor: {
                        name: waybill.customer.name,
                        inn: waybill.customer.inn_or_pinfl || waybill.customer.inn
                    },
                    error: error?.message
                })
            }
        }

        for (let i = 0; i < waybills.length; i++) {
            tasks.push(signer(waybills[i]))

            if (tasks.length >= taskSize) {
                await Promise.all(tasks)
                tasks.splice(0, tasks.length)
            }
        }

        if (tasks.length > 0) {
            await Promise.all(tasks)
            tasks.splice(0, tasks.length)
        }
        EventBus.dispatch(WAYBILL_V2_BULK_ACTION_FINISHED);
    }


    return (
        <SelectCertificateWrapperComponent
            openDialogButtonText={t(lang, "roaming.invoice.send_button")}
            submitButton={ <IconButton
                                       variant="phoenix-secondary"
                                       id="Send"
                                       disabled={loading}
                                       onClick={send}
                                       className={"btn-primary-davr m-0"}
                                       {...props}
            >
                {loading ? <Spinner size="sm" className='align-middle me-2' animation='border' role='switch'/> : <IconSignature /> }
                {t(lang, "roaming.invoice.send_button")}
            </IconButton>
            } />
    );
};

export default WaybillV2DraftSend;
