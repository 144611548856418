import React, {Fragment, useState} from 'react';
import {selectLang} from "../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {Button, Modal} from "react-bootstrap";
import Badge from "../../common/Badge";

const ShowNoteModal = ({notes, bg, title}) => {
    const [show, setShow] = useState(false)
    const lang = useSelector(selectLang)
    const t = Translate

    const handleClose = () => {
        setShow(false)
    }


    return (
        <Fragment>
            <Badge
                onClick={() => setShow(true)}
                bg={bg}
                variant={'phoenix'}
                size="sm"
                transform="shrink-3"
                className={'fw-semi-bold cursor-pointer'}
            >
                {title}
            </Badge>
            <Modal size="md" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang,"roaming.common.comment")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{notes}</p>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};

export default ShowNoteModal;
