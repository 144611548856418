import React from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import {selectAccountRolePermissions} from "../../../store/reducers/role/roleReducer";
import {PermissionRoamingVerificationActView} from "../../../enum/permission/RoamingPermission";
import checkPermission from "../../../enum/permission/CheckPermission";
import PermissionBadge from "../../../components/common/PermissionBadge";
import VerificationActDataTable from "../../../components/roaming/verification-act/VerificationActDataTable";

const RoamingVerificationActs = () => {
    const { type_viewer } = useParams()
    const dispatch = useDispatch()
    const t = Translate;
    const lang = useSelector(selectLang);
    //important
    useSelector(selectAccountRolePermissions)

    if (!dispatch(checkPermission(PermissionRoamingVerificationActView))) {
        return <PermissionBadge>
            <span>{t(lang, 'system.permission.roaming.verification-act.datatable.no_access.title')}</span>
        </PermissionBadge>
    }

    return (
       <VerificationActDataTable typeViewer={type_viewer}/>
    );
};

export default RoamingVerificationActs;
