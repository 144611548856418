import {createSlice} from "@reduxjs/toolkit";
import EventBus from "../../../eventbus/EventBus";
import {
    ROAMING_INVOICE_SETTINGS_SERVICE_DATATABLE_COLUMNS_FAILED,
    ROAMING_INVOICE_SETTINGS_SERVICE_DATATABLE_COLUMNS_REQUESTED,
    ROAMING_INVOICE_SETTINGS_SERVICE_DATATABLE_COLUMNS_SUCCESS
} from "../../../eventbus/romaing/roaming-invoice/roamingInvoiceEvents";
import settingService from "../../../store/services/settings/settingsService";


const edoSettingsSlice = createSlice({
    name: "edoSettings",
    initialState: {},
    reducers: {}
})

export const selectSettings = state => state.settings.settings?.edo;

export const updateEdoSettings = ({payload}) => {
    EventBus.dispatch(ROAMING_INVOICE_SETTINGS_SERVICE_DATATABLE_COLUMNS_REQUESTED)
    return new Promise((resolve, reject) => {
        settingService.updateEdoSettings({payload})
            .then((response) => {
                EventBus.dispatch(ROAMING_INVOICE_SETTINGS_SERVICE_DATATABLE_COLUMNS_SUCCESS)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(ROAMING_INVOICE_SETTINGS_SERVICE_DATATABLE_COLUMNS_FAILED)
                reject(error)
            })
    })
}



export default edoSettingsSlice.reducer;
