import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Translate} from "../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {ndsOptions} from "../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import {Col, Modal, OverlayTrigger, Row, Tooltip, Form} from "react-bootstrap";
import ReactSelect from "react-select";
import FalconCloseButton from "./FalconCloseButton";
import CustomFloatingLabelSelect from "../floating-custom-select/CustomFloatingLabelSelect";

const VatRateLinkSelector = ({onChange, defaultVatRate, linkProps}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [show, setShow] = useState(false);
    const [taxRate, setTaxRate] = useState(null);


    useEffect(() => {
        if (taxRate?.value !== defaultVatRate)
            setTaxRate(ndsOptions.find(o => o.value === defaultVatRate));
    }, [defaultVatRate])

    const onChangeHandler = (option) => {
        setTaxRate(option);
        onChange(option.value);
        option && setShow(false);
    }

    const link = <span onClick={() => setShow(true)}
                       className="text-warning cursor-pointer fs--1"
                       {...linkProps}
    >
        {taxRate && t(lang, taxRate.label)}
    </span>

    return (
        <Fragment>
            {taxRate && <OverlayTrigger overlay={<Tooltip>{t(lang, taxRate.label)}</Tooltip>}>
                {link}
            </OverlayTrigger>}
            {!taxRate && link}
            {show &&
                <Modal show={true}
                       className={'davr-modal'}
                       onHide={() => setShow(false)}
                       size="xl"
                       aria-labelledby="contained-modal-title-vcenter"
                       centered
                >
                    <Modal.Header>
                        <Modal.Title>{t(lang, 'items.common.tax_rate_link_selector_modal.title')}</Modal.Title>
                        <FalconCloseButton onClick={() => setShow(false)}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12}>
                                <Form.Group>
                                    <CustomFloatingLabelSelect
                                        options={ndsOptions}
                                        hideSelectedOptions
                                        label={t(lang, "roaming.common.items.nds")}
                                        getOptionLabel={option => t(lang, option.label)}
                                        getOptionValue={option => option.value}
                                        isSearchable={false}
                                        defaultMenuIsOpen={true}
                                        value={null}
                                        onChange={(option) => onChangeHandler(option)}
                                    />
                                 </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            }
        </Fragment>
    );
};

export default VatRateLinkSelector;
