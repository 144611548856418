import axios from "axios";

class ExcelService {
    getTemplates() {
        return axios.get('/excel/template/get')
    }

    getOrganizationTemplates() {
        return axios.get('/excel/organization/template/get')
    }

    updateOrganizationTemplates(templates) {
        return axios.post('/excel/organization/template/update', {
            templates: templates
        });
    }
    parse(base64Content) {
        return axios.post('/excel/parse', {
            base64Content: base64Content,
        })
    }
    parseMultiSheet(base64Content) {
        return axios.post('/excel/parse-multi-sheet', {
            base64Content: base64Content,
        })
    }
}

const excelService = new ExcelService();
export default excelService;
