import React, {Fragment, useEffect, useState} from 'react';
import {Translate} from "../../../lang/lang";
import {useDispatch, useSelector} from "react-redux";
import {selectDateDayJSFormat, selectLang, updateShowInProgressAsync} from "../../../store/reducers/main/main";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {
    EmpowermentWrapper,
    ExecutorDeclined,
    PersonDeclined
} from "../../../enum/roaming/empowerment/EmpowermentStatus";
import {selectCurrentCertificate} from "../../../store/reducers/eimzo/eimzoReducer";
import {Link, useNavigate, useParams} from "react-router-dom";
import {loadMeasurementsAsync, selectMeasurements} from "../../../store/reducers/roaming/roamingReducer";
import EventBus from "../../../eventbus/EventBus";
import {
    CUSTOMER_CANCEL_EMPOWERMENT_FAILED,
    CUSTOMER_CANCEL_EMPOWERMENT_SUCCESS, EMPOWERMENT_SEND_FAILED,
    EMPOWERMENT_SEND_REQUESTED,
    EMPOWERMENT_SEND_SUCCESS,
    EXECUTOR_ACCEPT_EMPOWERMENT_FAILED,
    EXECUTOR_ACCEPT_EMPOWERMENT_SUCCESS,
    EXECUTOR_DECLINE_EMPOWERMENT_FAILED,
    EXECUTOR_DECLINE_EMPOWERMENT_SUCCESS,
    PERSON_ACCEPT_EMPOWERMENT_FAILED,
    PERSON_ACCEPT_EMPOWERMENT_SUCCESS,
    PERSON_DECLINE_EMPOWERMENT_FAILED,
    PERSON_DECLINE_EMPOWERMENT_SUCCESS,
    SYNC_EMPOWERMENT_SUCCESS
} from "../../../eventbus/romaing/empowerment/empowermentEvents";
import toast from "react-hot-toast";
import {getOrganizationBillingInfoAsync} from "../../../store/reducers/billing/billingReducer";
import {sendEmpowermentAsync} from "../../../store/reducers/roaming/empowerment/empowermentReducer";
import WarningAlert from "../../common/WarningAlert";
import {Card, Col, Row} from "react-bootstrap";
import IconButton from "../../IconButton";
import {faClone, faEdit, faSignature} from "@fortawesome/free-solid-svg-icons";
import SelectCertificateWrapperComponent from "../../common/SelectCertificateWrapperComponent";
import {
    PermissionRoamingEmpowermentAccept,
    PermissionRoamingEmpowermentUpdate
} from "../../../enum/permission/RoamingPermission";
import checkPermission from "../../../enum/permission/CheckPermission";
import toastPermission from "../../../enum/permission/ToastPermission";
import SyncEmpowerment from "./SyncEmpowerment";
import CustomerCancelEmpowerment from "./CustomerCancelEmpowerment";
import PersonDeclineEmpowerment from "./PersonDeclineEmpowerment";
import PersonAcceptEmpowerment from "./PersonAcceptEmpowerment";
import ExecutorDeclineEmpowerment from "./ExecutorDeclineEmpowerment";
import ExecutorAcceptEmpowerment from "./ExecutorAcceptEmpowerment";
import PrintEmpowerment from "./PrintEmpowerment";
import dayjs from "dayjs";
import Flex from "../../common/Flex";
import EmpowermentViewTable from "./EmpowermentViewTable";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import Badge from "../../common/Badge";
import {GetStatusTextReturnOrder} from "../../../enum/edi/return-order/ReturnOrderStatus";
import {scrollEvent} from "../../../helpers/scrollEvent";
import IconSignature from "../../../assets/icon/IconSignature";

const Empowerment = ({empowerment, draftEmpowermentJson, modalId}) => {
    const dateFormat = useSelector(selectDateDayJSFormat);
    const lang = useSelector(selectLang);
    const t = Translate;
    const activeOrganization = useSelector(selectActiveOrganization);
    const empowermentWrapper = new EmpowermentWrapper(empowerment);
    const [eventMessage, setEventMessage] = useState(null);
    const measurements = useSelector(selectMeasurements)
    const dispatch = useDispatch()
    const currentCertificate = useSelector(selectCurrentCertificate);
    const {id} = useParams();
    const navigate = useNavigate();
    const allowCustomerCancel = empowermentWrapper?.allowCustomerCancel(activeOrganization?.inn, activeOrganization?.pinfl);
    const allowPersonAccept = empowermentWrapper?.allowPersonAccept(activeOrganization?.inn, activeOrganization?.pinfl);
    const allowPersonDecline = empowermentWrapper?.allowPersonDecline(activeOrganization?.inn, activeOrganization?.pinfl);
    const allowExecutorAccept = empowermentWrapper?.allowExecutorAccept(activeOrganization?.inn, activeOrganization?.pinfl);
    const allowExecutorDecline = empowermentWrapper?.allowExecutorDecline(activeOrganization?.inn, activeOrganization?.pinfl);
    const allowCopy = empowermentWrapper?.allowCustomerCopy(activeOrganization?.inn, activeOrganization?.pinfl);
    const allowSync = empowermentWrapper?.allowSync();
    const showDeclineNotes = PersonDeclined === empowerment.status || ExecutorDeclined === empowerment.status

    useEffect(() => {
        dispatch(loadMeasurementsAsync(lang));
        //success
        const onCustomerCancelEmpowermentSuccessHandler = EventBus.on(CUSTOMER_CANCEL_EMPOWERMENT_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.empowerment.alert.success_event_title'),
                text: t(lang, 'roaming.empowerment.alert.empowerment_cancel_success')
            });
        })

        const onPersonAcceptEmpowermentSuccessHandler = EventBus.on(PERSON_ACCEPT_EMPOWERMENT_SUCCESS, () => {
            dispatch(getOrganizationBillingInfoAsync());
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.empowerment.alert.success_event_title'),
                text: t(lang, 'roaming.empowerment.alert.empowerment_accept_success')
            });
        })

        const onPersonDeclineEmpowermentSuccessHandler = EventBus.on(PERSON_DECLINE_EMPOWERMENT_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.empowerment.alert.success_event_title'),
                text: t(lang, 'roaming.empowerment.alert.empowerment_decline_success')
            });
        })

        const onExecutorAcceptEmpowermentSuccessHandler = EventBus.on(EXECUTOR_ACCEPT_EMPOWERMENT_SUCCESS, () => {
            dispatch(getOrganizationBillingInfoAsync());
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.empowerment.alert.success_event_title'),
                text: t(lang, 'roaming.empowerment.alert.empowerment_accept_success')
            });
        })

        const onExecutorDeclineEmpowermentSuccessHandler = EventBus.on(EXECUTOR_DECLINE_EMPOWERMENT_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.empowerment.alert.success_event_title'),
                text: t(lang, 'roaming.empowerment.alert.empowerment_decline_success')
            });
        })

        const onSyncEmpowermentSuccessHandler = EventBus.on(SYNC_EMPOWERMENT_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang,'roaming.empowerment.alert.success_event_title'),
                text: t(lang,'roaming.empowerment.alert.empowerment_sync_success')
            });
        })

        //fail
        const onCustomerCancelEmpowermentFailedHandler = EventBus.on(CUSTOMER_CANCEL_EMPOWERMENT_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang,'roaming.empowerment.alert.failed_event_title'),
                text: error.message
            });
        })

        const onPersonAcceptEmpowermentFailedHandler = EventBus.on(PERSON_ACCEPT_EMPOWERMENT_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang,'roaming.empowerment.alert.failed_event_title'),
                text: error.message
            });
        })

        const onPersonDeclineEmpowermentFailedHandler = EventBus.on(PERSON_DECLINE_EMPOWERMENT_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang,'roaming.empowerment.alert.failed_event_title'),
                text: error.message
            })
        })

        const onExecutorAcceptEmpowermentFailedHandler = EventBus.on(EXECUTOR_ACCEPT_EMPOWERMENT_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang,'roaming.empowerment.alert.failed_event_title'),
                text: error.message
            });
        })

        const onExecutorDeclineEmpowermentFailedHandler = EventBus.on(EXECUTOR_DECLINE_EMPOWERMENT_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang,'roaming.empowerment.alert.failed_event_title'),
                text: error.message
            });
        })

        const onSendEmpowermentRequestedHandler = EventBus.on(EMPOWERMENT_SEND_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onSendEmpowermentSuccessHandler = EventBus.on(EMPOWERMENT_SEND_SUCCESS, () => {
            toast.success(t(lang, "edi.common.toast.success"))
            navigate(`/roaming/empowerment/${modalId ? modalId : id}`)
            dispatch(updateShowInProgressAsync(false))
        })

        const onSendEmpowermentFailedHandler = EventBus.on(EMPOWERMENT_SEND_FAILED, () => {
            toast.error(t(lang, "edi.common.toast.error"))
            dispatch(updateShowInProgressAsync(false))
        })

        return () => {
            EventBus.remove(CUSTOMER_CANCEL_EMPOWERMENT_SUCCESS, onCustomerCancelEmpowermentSuccessHandler);
            EventBus.remove(PERSON_ACCEPT_EMPOWERMENT_SUCCESS, onPersonAcceptEmpowermentSuccessHandler);
            EventBus.remove(PERSON_DECLINE_EMPOWERMENT_SUCCESS, onPersonDeclineEmpowermentSuccessHandler);
            EventBus.remove(EXECUTOR_ACCEPT_EMPOWERMENT_SUCCESS, onExecutorAcceptEmpowermentSuccessHandler);
            EventBus.remove(EXECUTOR_DECLINE_EMPOWERMENT_SUCCESS, onExecutorDeclineEmpowermentSuccessHandler);
            EventBus.remove(SYNC_EMPOWERMENT_SUCCESS, onSyncEmpowermentSuccessHandler);

            EventBus.remove(CUSTOMER_CANCEL_EMPOWERMENT_FAILED, onCustomerCancelEmpowermentFailedHandler);
            EventBus.remove(PERSON_ACCEPT_EMPOWERMENT_FAILED, onPersonAcceptEmpowermentFailedHandler);
            EventBus.remove(PERSON_DECLINE_EMPOWERMENT_FAILED, onPersonDeclineEmpowermentFailedHandler);
            EventBus.remove(EXECUTOR_ACCEPT_EMPOWERMENT_FAILED, onExecutorAcceptEmpowermentFailedHandler);
            EventBus.remove(EXECUTOR_DECLINE_EMPOWERMENT_FAILED, onExecutorDeclineEmpowermentFailedHandler);

            EventBus.remove(EMPOWERMENT_SEND_REQUESTED, onSendEmpowermentRequestedHandler)
            EventBus.remove(EMPOWERMENT_SEND_SUCCESS, onSendEmpowermentSuccessHandler)
            EventBus.remove(EMPOWERMENT_SEND_FAILED, onSendEmpowermentFailedHandler)
        }
    }, [])

    const getMeasure = measurementId => {
        return measurements.find(x => x.measureId === measurementId) || null
    }

    const signEmpowerment = () => {
        sendEmpowermentAsync(currentCertificate, lang, draftEmpowermentJson)
            .then(response => {})
            .catch(error => {})
    }

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('roaming-empowerment-view'))
        return () => window.removeEventListener('scroll', () => scrollEvent('roaming-empowerment-view'))
    }, []);

    return (
        <Fragment>
            {eventMessage && <WarningAlert text={eventMessage.text} title={eventMessage.title} variant={eventMessage.variant}/>}
            <Row className={'align-items-center justify-content-between my-3 position-sticky z-index-1'} style={{top: '4rem'}} id={'roaming-empowerment-view'}>
                <Col lg={5} className={'d-flex gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'}
                            style={{width: 35, height: 35}}>
                        <IconArrowLeft/>
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "edi.common.attorney")} № {empowerment.info.number}</h5>
                        <div className={'d-flex align-items-center gap-2'}>
                            <span className={'second-text fs-9'}>
                                {empowerment.created_at}
                            </span>
                            <Badge bg={empowermentWrapper.GetStatusBadgeVariant(activeOrganization?.inn, activeOrganization?.pinfl)}
                                   variant={'phoenix'}
                                   className={'px-2 py-1 fs-9 text-uppercase text-decoration-none fw-medium border-0 rounded-4'}>
                                     {t(lang, empowermentWrapper.GetStatusTranslationKey(activeOrganization.inn, activeOrganization.pinfl))}
                            </Badge>
                        </div>
                    </div>
                </Col>
                <Col lg={"auto"} className={'d-flex justify-content-end gap-2'}>
                    {allowCopy &&
                        <IconButton
                            variant={'phoenix-secondary'}
                            size="sm"
                            icon={faClone}
                            as={Link}
                            to={`/roaming/empowerment/actions/${empowerment.id}?status=${empowerment?.status || null}`}
                            className="btn-davr px-3 py-0 bg-white d-flex gap-2"
                            iconClassName="text-primary-davr fs-8"
                        >
                            {t(lang, "roaming.common.copy")}
                        </IconButton>}

                    {
                        empowerment.status ?
                            <Fragment>
                                {allowSync && <SyncEmpowerment id={empowerment.id}/>}
                                <PrintEmpowerment id={empowerment.id} />
                                {allowCustomerCancel && <CustomerCancelEmpowerment id={empowerment.id}/>}
                                {allowPersonDecline && <PersonDeclineEmpowerment id={empowerment.id} />}
                                {allowExecutorDecline && <ExecutorDeclineEmpowerment  id={empowerment.id}/>}
                                {allowPersonAccept && <PersonAcceptEmpowerment id={empowerment.id}/>}
                                {allowExecutorAccept && <ExecutorAcceptEmpowerment id={empowerment.id} />}
                            </Fragment>
                            :
                            <Fragment>
                                <IconButton
                                    size="sm"
                                    icon={faEdit}
                                    variant={'phoenix-secondary'}
                                    iconClassName="text-primary-davr fs-8"
                                    className="btn-davr px-3 py-0 bg-white d-flex gap-2"
                                    type="submit"
                                    as={dispatch(checkPermission(PermissionRoamingEmpowermentUpdate)) ? Link : 'div'}
                                    onClick={()=>!dispatch(checkPermission(PermissionRoamingEmpowermentUpdate)) && dispatch(toastPermission())}
                                    to={`/roaming/empowerment/draft-edit/${modalId ? modalId : id}`}
                                >{t(lang,"edi.common.button.edit")}</IconButton>
                                <SelectCertificateWrapperComponent
                                    permission={PermissionRoamingEmpowermentAccept}
                                    submitButton={
                                        <IconButton
                                            onClick={signEmpowerment}
                                            id="sendButton"
                                            size="sm"
                                            transform="shrink-3"
                                            variant="phoenix-secondary"
                                            className={'btn-davr bg-primary-davr gap-1 text-white'}
                                            icon={""}>
                                                <IconSignature />
                                                {t(lang, 'roaming.common.send')}
                                        </IconButton>
                                }
                                    openDialogButtonText={t(lang, 'roaming.act_form_send')}
                                />
                            </Fragment>
                    }
                </Col>
            </Row>

            <Card className={'mt-2'}>
                <Card.Body className={'pb-0'}>
                    <h2 className="fw-semi-bold text-center">
                        {t(lang, "edi.common.attorney")}<i>{empowerment.notes}</i>
                    </h2>
                    <h5 className="fw-semi-bold text-center second-text">
                        <i>{empowerment.id}</i>
                    </h5>
                </Card.Body>
                <Card.Footer as={Row} className={'border-0 justify-content-between fs-8 pb-3'}>
                    <Col xs={12} md={6} className={'px-1'}>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "edi.common.shipment.number")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text'}>{empowerment.info.number}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.empowerment.start_date")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text'}>{dayjs(empowerment.info.start, 'YYYY-MM-DD').format(dateFormat)}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.empowerment.end_date")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text'}>{dayjs(empowerment.info.end, 'YYYY-MM-DD').format(dateFormat)}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className={'px-1'}>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "edi.common.contract.number")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text'}>{empowerment.contract.number}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "edi.common.contract.date")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text'}>{dayjs(empowerment.contract.date, "YYYY-MM-DD").format(dateFormat)}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Card.Footer>
            </Card>

            <Card className={'mt-2'}>
                <Card.Footer as={Row} className={'border-0 justify-content-between fs-8 pb-3'}>
                    <Col xs={12} md={4} className={'px-1'}>
                        <h5 className="fw-semi-bold fs-9 second-text text-uppercase">{t(lang, "edi.common.label.organization")}</h5>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "roaming.contract.company.info.name")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block text-truncate'}>{empowerment.customer.name}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "roaming.common.inn")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block text-truncate'}>{empowerment.customer.inn}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "roaming.common.director")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block text-truncate'}>{empowerment.customer.director}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "roaming.common.accountant")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block text-truncate'}>{empowerment.customer.accountant}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} md={4} className={'px-1'}>
                        <h5 className="fw-semi-bold fs-9 second-text text-uppercase">{t(lang, "roaming.empowerment.contractor")}</h5>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "roaming.contract.company.info.name")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block text-truncate'}>{empowerment.executor.name}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "roaming.common.inn")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block text-truncate'}>{empowerment.executor.inn}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "roaming.common.director")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block text-truncate'}>{empowerment.executor.director}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "roaming.common.accountant")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block text-truncate'}>{empowerment.executor.accountant}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} md={4} className={'px-1'}>
                        <h5 className="fw-semi-bold fs-9 second-text text-uppercase">{t(lang, "roaming.empowerment.empowerment_person")}</h5>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "roaming.dashboard.empowerments.full_name")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block text-truncate'}>{empowerment.person_info.full_name}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "edi.common.label.inn")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block text-truncate'}>{empowerment.person_info.person_inn}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "roaming.empowerment.contract_material_assets")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block text-truncate'}>{t(lang, 'roaming.empowerment.contact_info.from.contact_date', {contract_number: empowerment.contract.number, contract_date: dayjs(empowerment.contract.date, 'YYYY-MM-DD').format(dateFormat)})}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Card.Footer>
            </Card>

            <Card className={'mt-2 p-2'}>
                <h5 className="text-uppercase ms-3 mb-0 py-2 second-text fs-9">{t(lang, "roaming.empowerment.items.title")}</h5>
                <Card.Body className={'p-0 pt-5 border border-1 rounded-2'}>
                    <EmpowermentViewTable empowerment={empowerment} getMeasure={getMeasure} />
                </Card.Body>
            </Card>

        </Fragment>
    );
};

export default Empowerment;
