import {
    CUSTOMER_APPROVE_RETURN_ORDER_FAILED,
    CUSTOMER_APPROVE_RETURN_ORDER_REQUESTED,
    CUSTOMER_APPROVE_RETURN_ORDER_SUCCEED,
    EXECUTOR_APPROVE_RETURN_ORDER_FAILED,
    EXECUTOR_APPROVE_RETURN_ORDER_REQUESTED,
    EXECUTOR_APPROVE_RETURN_ORDER_SUCCEED,
    EXECUTOR_CANCELED_DECLINE_RETURN_ORDER_FAILED,
    EXECUTOR_CANCELED_DECLINE_RETURN_ORDER_REQUESTED,
    EXECUTOR_CANCELED_DECLINE_RETURN_ORDER_SUCCEED,
    EXECUTOR_DECLINE_RETURN_ORDER_FAILED,
    EXECUTOR_DECLINE_RETURN_ORDER_REQUESTED,
    EXECUTOR_DECLINE_RETURN_ORDER_SUCCEED,
    REGISTER_RETURN_ORDER_FAILED,
    REGISTER_RETURN_ORDER_REQUESTED,
    REGISTER_RETURN_ORDER_SUCCESS, RETURN_ORDER_IDENTIFY_ITEMS_FAILED,
    RETURN_ORDER_IDENTIFY_ITEMS_REQUESTED, RETURN_ORDER_IDENTIFY_ITEMS_SUCCEED, RETURN_ORDER_RESUMED,
    RETURN_ORDER_STOP_FAILED,
    RETURN_ORDER_STOP_REQUESTED,
    RETURN_ORDER_STOP_SUCCEED
} from "../../../../eventbus/edi/return-order/returnOrderEvents";

import {createSlice} from "@reduxjs/toolkit";
import returnOrderService from "../../../services/edi/return-order/returnOrderService";
import EventBus from "../../../../eventbus/EventBus";

export const STORAGE_RETURN_ORDER = "ReturnOrderDataTableSettings"

const initialState = {
    returnOrders: [],
    countReturnOrders: 0,
    filterOptions: {page: 1, limit: 10},
    returnOrderDataTableSettings: {}
}

export const returnOrderSlice = createSlice({
    name: 'return-order',
    initialState,
    reducers: {
        updateReturnOrders: (state, action) => {
            state.returnOrders = action.payload
        },
        updateCountReturnOrders: (state, action) => {
            state.countReturnOrders = action.payload
        },
        updateFilterReturnOrder: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },

        loadReturnOrderDataTableColumn: (state) => {
            let storageColumn = JSON.parse(localStorage.getItem(STORAGE_RETURN_ORDER))
            if (!storageColumn) {
                storageColumn = {
                    index: true,
                    status: true,
                    date: true,
                    total_sum: true,
                    unloading_point: true,
                    expire_date: true,
                    contractor: true
                };
                localStorage.setItem(STORAGE_RETURN_ORDER, JSON.stringify(storageColumn))
            }
            state.returnOrderDataTableSettings = storageColumn
        },

        changeReturnOrderDataTableColumn: (state, action) => {
            state.returnOrderDataTableSettings = action.payload
            localStorage.setItem(STORAGE_RETURN_ORDER, JSON.stringify(state.returnOrderDataTableSettings))
        },
    },
})

export const registerReturnOrderAsync = (payload) => {
    EventBus.dispatch(REGISTER_RETURN_ORDER_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        returnOrderService.registerReturnOrder(payload)
            .then(response => {
                resolve(response.data);
                EventBus.dispatch(REGISTER_RETURN_ORDER_SUCCESS, response.data);
            })
            .catch(errors => {
                reject({
                    creatorIsNotCustomer: errors?.response?.data?.creator_is_not_customer || false,
                    branchNotFound: errors?.response?.data?.branch_not_found || false,
                    numberExists: errors?.response?.data?.number_exists || false,
                    customerSystemIdExists: errors?.response?.data?.customer_system_id_exists || false,
                });
                EventBus.dispatch(REGISTER_RETURN_ORDER_FAILED, errors);
            })
    })
}

export const loadReturnOrdersAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    returnOrderService.getReturnOrders(params)
        .then(response => {
            dispatch(returnOrderSlice.actions.updateReturnOrders(response.data))
        })
        .catch(error => {
            console.log(error);
        })
}

export const loadCountReturnOrdersAsync = (params) => (dispatch) => {
    let {limit, page, ...others} = params
    returnOrderService.getCountReturnOrders(others)
        .then(response => {
            dispatch(returnOrderSlice.actions.updateCountReturnOrders(response.data))
        })
        .catch(error => {
            console.log(error);
        })
}

export const changeFilterReturnOrdersAsync = (params) => (dispatch) => {
    return dispatch(returnOrderSlice.actions.updateFilterReturnOrder(params))
}

export const loadReturnOrderAsync = id => {
    return new Promise((resolve, reject) => {
        returnOrderService.getReturnOrder(id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export const returnOrderCustomerApproveAsync = ({id, comment}) => {
    EventBus.dispatch(CUSTOMER_APPROVE_RETURN_ORDER_REQUESTED)

    return new Promise((resolve, reject) => {
        returnOrderService.returnOrderCustomerApprove({id, comment})
            .then(response => {
                EventBus.dispatch(CUSTOMER_APPROVE_RETURN_ORDER_SUCCEED, response.data);
                resolve(response.data);
            }).catch(error => {
            EventBus.dispatch(CUSTOMER_APPROVE_RETURN_ORDER_FAILED)
            reject(error)
        })
    })
}

export const returnOrderExecutorApproveAsync = ({id, comment}) => {
    EventBus.dispatch(EXECUTOR_APPROVE_RETURN_ORDER_REQUESTED);

    return new Promise((resolve, reject) => {
        returnOrderService.returnOrderExecutorApprove({id, comment})
            .then(response => {
                EventBus.dispatch(EXECUTOR_APPROVE_RETURN_ORDER_SUCCEED, response.data);
                resolve(response.data);
            }).catch(error => {
            EventBus.dispatch(EXECUTOR_APPROVE_RETURN_ORDER_FAILED)
            reject(error)
        })
    })
}

export const returnOrderExecutorDeclineAsync = ({id, reason, comment}) => {
    EventBus.dispatch(EXECUTOR_DECLINE_RETURN_ORDER_REQUESTED);

    return new Promise((resolve, reject) => {
        returnOrderService.executorDecline({id, reason, comment})
            .then(response => {
                EventBus.dispatch(EXECUTOR_DECLINE_RETURN_ORDER_SUCCEED, response.data);
                resolve(response.data);
            }).catch(error => {
            EventBus.dispatch(EXECUTOR_DECLINE_RETURN_ORDER_FAILED);
            reject(error)
        })
    })
}

export const returnOrderExecutorCancelDeclineAsync = ({id, comment}) => {
    EventBus.dispatch(EXECUTOR_CANCELED_DECLINE_RETURN_ORDER_REQUESTED);

    return new Promise((resolve, reject) => {
        returnOrderService.executorCancelDecline({id, comment})
            .then(response => {
                EventBus.dispatch(EXECUTOR_CANCELED_DECLINE_RETURN_ORDER_SUCCEED, response.data)
                resolve(response.data)
            }).catch(error => {
            EventBus.dispatch(EXECUTOR_CANCELED_DECLINE_RETURN_ORDER_FAILED);
            reject(error)
        })
    })
}

export const stopReturnOrderAsync = ({id, comment}) => {
    EventBus.dispatch(RETURN_ORDER_STOP_REQUESTED);

    return new Promise((resolve, reject) => {
        returnOrderService.stopReturnOrder({id, comment})
            .then(response => {
                EventBus.dispatch(RETURN_ORDER_STOP_SUCCEED, response.data)
                resolve(response.data)
            }).catch(error => {
            EventBus.dispatch(RETURN_ORDER_STOP_FAILED)
            reject(error)
        })
    })
}

export const resumeReturnOrderAsync = ({id, comment}) => {
    return new Promise((resolve, reject) => {
        returnOrderService.resumeReturnOrder({id, comment})
            .then(response => {
                EventBus.dispatch(RETURN_ORDER_RESUMED, response.data);
                resolve(response.data);
            }).catch(error => reject(error))
    })
}
export const printReturnOrderAsync = (id) => {
    return new Promise((resolve, reject) => {
        returnOrderService.printReturnOrder(id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}
export const uploadReturnOrderToExcelAsync = ({uri, id}) => {
    return new Promise((resolve, reject) => {
        returnOrderService.uploadReturnOrderToExcel({uri, id})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}
export const uploadReturnOrdersToExcelAsync = ({uri, page, limit, ...filters}) => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};

    return new Promise((resolve, reject) => {
        returnOrderService.uploadReturnOrdersToExcel({params: params, uri: uri})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}
export const uploadReturnOrdersWithItemsToExcelAsync = ({uri, page, limit, ...filters}) => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};

    return new Promise((resolve, reject) => {
        returnOrderService.uploadReturnOrdersWithItemsToExcel({params: params, uri: uri})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}


export const returnOrderIdentifyItems = ({id}) => {
    EventBus.dispatch(RETURN_ORDER_IDENTIFY_ITEMS_REQUESTED);

    return new Promise((resolve, reject) => {
        returnOrderService.returnOrderIdentifyItems({orderId: id})
            .then(response => {
                EventBus.dispatch(RETURN_ORDER_IDENTIFY_ITEMS_SUCCEED, response.data)
                resolve(response.data)
            }).catch(error => {
            EventBus.dispatch(RETURN_ORDER_IDENTIFY_ITEMS_FAILED);
            reject(error)
        })
    })
}

export const selectReturnOrders = (state) => state.returnOrder.returnOrders
export const selectCountReturnOrders = (state) => state.returnOrder.countReturnOrders
export const selectFilterOptions = (state) => state.returnOrder.filterOptions

export const selectReturnOrderDataTable = (state) => state.returnOrder.returnOrderDataTableSettings

export const {loadReturnOrderDataTableColumn} = returnOrderSlice.actions
export const {changeReturnOrderDataTableColumn} = returnOrderSlice.actions

export default returnOrderSlice.reducer;
