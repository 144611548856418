import axios from "axios";

class RoamingVerificationActService {
    get(id) {
        return axios.get(`/roaming/verification-act/get/${id}`)
    }

    getDraft(id) {
        return axios.get(`/roaming/verification-act/draft/get/${id}`)
    }

    getDraftVerificationActs(params) {
        return axios.get('/roaming/verification-act/drafts/get', {
            params: params
        })
    }

    getDraftVerificationActsCount(params) {
        return axios.get('/roaming/verification-act/drafts/count', {
            params: params
        })
    }

    getExecutorVerificationActs(params) {
        return axios.get('/roaming/verification-act/executor/get', {
            params: params
        })
    }

    getCustomerVerificationActs(params) {
        return axios.get('/roaming/verification-act/customer/get', {
            params: params
        })
    }

    getExecutorVerificationActsCount(params) {
        return axios.get('/roaming/verification-act/executor/count', {
            params: params
        })
    }

    getCustomerVerificationActsCount(params) {
        return axios.get('/roaming/verification-act/customer/count', {
            params: params
        })
    }

    registerRoamingVerificationAct({ id, signature, ownerInn, lang }) {
        return axios.post("/roaming/verification-act/send", {
            id: id,
            signature: signature,
            owner_inn: ownerInn,
            lang: lang,
        });
    }

    saveDraftRoamingVerificationAct({content}) {
        return axios.post('/roaming/verification-act/draft/save', {
            content: content
        })
    }

    cancel(id, {signature, lang}) {
        return axios.post(`/roaming/verification-act/cancel/${id}`, {
            signature: signature,
            lang: lang
        })
    }

    customerAccept(id, {signature, lang}) {
        return axios.post(`/roaming/verification-act/customer/accept/${id}`, {
            signature: signature,
            lang: lang
        })
    }

    customerDecline(id, {signature, comment, lang}) {
        return axios.post(`/roaming/verification-act/customer/decline/${id}`, {
            signature: signature,
            comment: comment,
            lang: lang
        })
    }
    getAcceptHashCode(id) {
        return axios.get(`/roaming/verification-act/hashcode/accept/${id}`, {
            transformResponse: [data => data]
        })
    }

    getCancelHashCode(id) {
        return axios.get(`/roaming/verification-act/hashcode/cancel/${id}`, {
            transformResponse: [data => data]
        })
    }

    getDeclineHashCode(id, comment) {
        return axios.get(`/roaming/verification-act/hashcode/decline/${id}`, {
            transformResponse: [data => data],
            params: {comment: comment}
        })
    }
}

const roamingVerificationActService = new RoamingVerificationActService();

export default roamingVerificationActService;
