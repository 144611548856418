import React, {useState} from 'react';
import {Card, Col, Nav, Row, Tab, Tabs} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {GetViewerIcon, GetViewerText, VIEW_DRAFT, VIEW_INBOX, VIEW_OUTBOX} from "../../enum/roaming/TypeViewers";
import classNames from "classnames";
import Flex from "./Flex";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {Translate} from "../../lang/lang";
import roamingInvoiceWrapper from "../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import {RESPONSIBLE_PERSON} from "../../enum/roaming/waybill/WaybillStatus";

const TypeViewerAction = ({to, typeViewer, draft, responsiblePerson}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Nav className={'d-flex gap-2 px-2 pt-2'} variant="tabs" defaultActiveKey={typeViewer}>
            <Nav.Item>
                <Nav.Link as={Link} to={`${to}/${VIEW_INBOX}`} className={classNames('mb-1 py-1',{
                    'main-text': VIEW_INBOX === typeViewer,
                    'second-text': VIEW_INBOX !== typeViewer,
                })}>
                    <span className={'fw-medium'}>{t(lang, GetViewerText(VIEW_INBOX))}</span>
                </Nav.Link>
                {VIEW_INBOX === typeViewer && <span style={{height: 6}} className={'w-100 d-block bg-primary-davr rounded-3 rounded-top rounded-bottom-0'}/>}
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link} to={`${to}/${VIEW_OUTBOX}`} className={classNames('mb-1 py-1',{
                    'main-text': VIEW_OUTBOX === typeViewer,
                    'second-text': VIEW_OUTBOX !== typeViewer,
                })}>
                    <span className={'fw-medium'}>{t(lang, GetViewerText(VIEW_OUTBOX))}</span>
                </Nav.Link>
                {VIEW_OUTBOX === typeViewer && <span style={{height: 6}} className={'w-100 d-block bg-primary-davr rounded-3 rounded-top rounded-bottom-0'}/>}
            </Nav.Item>
            {responsiblePerson && <Nav.Item>
                <Nav.Link as={Link} to={`${to}/${RESPONSIBLE_PERSON}`} className={classNames('mb-1 py-1', {
                    'main-text': RESPONSIBLE_PERSON === typeViewer,
                    'second-text': RESPONSIBLE_PERSON !== typeViewer,
                })}>
                    <span className={'fw-medium'}>{t(lang, GetViewerText(RESPONSIBLE_PERSON))}</span>
                </Nav.Link>
                {RESPONSIBLE_PERSON === typeViewer && <span style={{height: 6}}
                                                    className={'w-100 d-block bg-primary-davr rounded-3 rounded-top rounded-bottom-0'}/>}
            </Nav.Item>}
            {!draft && <Nav.Item>
                <Nav.Link as={Link} to={`${to}/${VIEW_DRAFT}`} className={classNames('mb-1 py-1', {
                    'main-text': VIEW_DRAFT === typeViewer,
                    'second-text': VIEW_DRAFT !== typeViewer,
                })}>
                    <span className={'fw-medium'}>{t(lang, GetViewerText(VIEW_DRAFT))}</span>
                </Nav.Link>
                {VIEW_DRAFT === typeViewer && <span style={{height: 6}}
                                                    className={'w-100 d-block bg-primary-davr rounded-3 rounded-top rounded-bottom-0'}/>}
            </Nav.Item>}
        </Nav>
    );
};

export default TypeViewerAction;
