import React, {useEffect} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {currencyGetAsync, selectCurrency} from "../../store/reducers/currency/currencyReducer";
import {selectActiveOrganization} from "../../store/reducers/auth/authReducer";
import {selectLang} from "../../store/reducers/main/main";
import {Translate} from "../../lang/lang";
import {FormProvider, useForm} from "react-hook-form";
import EventBus from "../../eventbus/EventBus";
import {
    CURRENCY_ACTIVATE_FAILED,
    CURRENCY_ACTIVATE_SUCCESS,
    CURRENCY_CONVERTER_CONVERT_FAILED,
    CURRENCY_DEACTIVATE_FAILED, CURRENCY_DEACTIVATE_SUCCESS, CURRENCY_UPDATE_RATE_FAILED, CURRENCY_UPDATE_RATE_SUCCESS
} from "../../eventbus/currency/currencyEvents";
import toast from "react-hot-toast";
import CurrencySelectForm from "../../components/user/currency/CurrencySelectForm";

const Currency = () => {

    const activeOrganization = useSelector(selectActiveOrganization)
    const currency = useSelector(selectCurrency)
    const dispatch = useDispatch()
    const lang = useSelector(selectLang)
    const t = Translate
    const methods = useForm({
        defaultValues: {
            currency
        }
    })

    useEffect(() => {
        loadCurrency()
    },[activeOrganization])

    const loadCurrency = () => {
        dispatch(currencyGetAsync())
    }

    useEffect(() => {
        const onCurrencyActivateSuccessHandler = EventBus.on(CURRENCY_ACTIVATE_SUCCESS, () => {
            loadCurrency()
            toast.success(t(lang,"main.common.activate"))
        })

        const onCurrencyActivateFailedHandler = EventBus.on(CURRENCY_ACTIVATE_FAILED, () => {
            toast.error(t(lang,"main.common.error"))
        })

        const onCurrencyUpdateRateSuccessHandler = EventBus.on(CURRENCY_UPDATE_RATE_SUCCESS, () => {
            loadCurrency()
            toast.success(t(lang,"edi.common.toast.success"))
        })

        const onCurrencyUpdateRateFailedHandler = EventBus.on(CURRENCY_UPDATE_RATE_FAILED, rate => {
            if (rate.status === 409) {
                toast.error(t(lang,"main.common.conflict"))
            } else {
                toast.error(t(lang,"main.common.error"))
            }
        })

        const onCurrencyDeActivateSuccessHandler = EventBus.on(CURRENCY_DEACTIVATE_SUCCESS, () => {
            loadCurrency()
            toast.success(t(lang,"main.common.deactivate"))
        })

        const onCurrencyDeActivateFailedHandler = EventBus.on(CURRENCY_DEACTIVATE_FAILED, () => {
            toast.error(t(lang,"main.common.error"))
        })

        const onCurrencyConverterConvertFailedHandler = EventBus.on(CURRENCY_CONVERTER_CONVERT_FAILED, () => {
            toast.error(t(lang,"main.common.error"))
        })


        return () => {
            EventBus.remove(CURRENCY_ACTIVATE_SUCCESS, onCurrencyActivateSuccessHandler)
            EventBus.remove(CURRENCY_ACTIVATE_FAILED, onCurrencyActivateFailedHandler)
            EventBus.remove(CURRENCY_UPDATE_RATE_SUCCESS, onCurrencyUpdateRateSuccessHandler)
            EventBus.remove(CURRENCY_UPDATE_RATE_FAILED, onCurrencyUpdateRateFailedHandler)
            EventBus.remove(CURRENCY_DEACTIVATE_SUCCESS, onCurrencyDeActivateSuccessHandler)
            EventBus.remove(CURRENCY_DEACTIVATE_FAILED, onCurrencyDeActivateFailedHandler)
            EventBus.remove(CURRENCY_CONVERTER_CONVERT_FAILED, onCurrencyConverterConvertFailedHandler)
        }
    },[])


    return (
        <Row>
            <Col xs={12} xxl={8}>
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit()}>
                        <CurrencySelectForm />
                    </Form>
                </FormProvider>
            </Col>
        </Row>
    );
};

export default Currency;
