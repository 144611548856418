import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {
    selectOrganizationActivePackages,
    selectOrganizationBillingInfo
} from "../../../store/reducers/billing/billingReducer";
import {selectLang, selectSystemMoneyFormat} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {Card, Col, Row} from "react-bootstrap";
import Flex from "../../common/Flex";
import numeral from "numeral";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import Badge from "../../common/Badge";
import {GetStatusTextReturnOrder} from "../../../enum/edi/return-order/ReturnOrderStatus";
import {Link, useNavigate} from "react-router-dom";
import {scrollEvent} from "../../../helpers/scrollEvent";
import OrganizationActivePackages from "./OrganizationActivePackages";
import dayjs from "dayjs";


const BalanceInfoCard = () => {
    const billingInfo = useSelector(selectOrganizationBillingInfo);
    const systemMoneyFormat = useSelector(selectSystemMoneyFormat);
    const activePackages = useSelector(selectOrganizationActivePackages);
    const lang = useSelector(selectLang);
    const t = Translate;
    const navigate = useNavigate();

    const [activePackage, setActivePackage] = useState(null)

    useEffect(() => {
        if (activePackages) {
            setActivePackage(activePackages[0])
        }
    }, [activePackages]);

    return (
        <Fragment>
            <Row>
                <Col xs={12} md={6} lg={6} className={''}>
                    <Card className={'rounded-4 p-3 fw-light card-bg-img'}>
                        <h5 className={'fs-8'}>{t(lang, 'Тариф')}: {activePackage?.package?.name}</h5>
                        <div className="">
                            <span className="h3 me-2">{t(lang, 'Осталось дней')}:</span>
                            <span className="h3">
                                {Math.floor((+dayjs(activePackage?.expires_at) - dayjs()) / (1000 * 60 * 60 * 24))}
                            </span>
                        </div>
                    </Card>
                </Col>
                <Col xs={12} md={6} lg={6}>
                    <Card className={'rounded-4 p-3 fw-light card-bg-img'}>
                        <h5 className={'fs-8'}>{t(lang, 'main.navbar.nav_item.billing.balance')}</h5>
                        <div className="">
                            <span className="h3 me-2">{t(lang, 'main.billing.info.total')}:</span>
                            <span className="h3">
                                {numeral['formats'][systemMoneyFormat].format(billingInfo?.balance || 0)}
                                <span className="ms-1">{t(lang, 'main.billing.balance_info_card.organization.sum')}</span>
                            </span>
                        </div>
                    </Card>
                </Col>
            </Row>
            <OrganizationActivePackages setActivePackage={setActivePackage}/>
        </Fragment>
    );
};

export default BalanceInfoCard;
