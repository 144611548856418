import EventBus from "../eventbus/EventBus";
import {PUSH_UPDATE_CONTRACTOR_DEBT_STATES_UPDATED} from "../eventbus/contractor/contractor-push-update-events";
import {PUSH_UPDATE_ITEM_STATE_UPDATED} from "../eventbus/itemEvents";

const EventNameMapper = {
    'warehouse-item-state-updated': PUSH_UPDATE_ITEM_STATE_UPDATED, 'contractor-debt-state-updated': PUSH_UPDATE_CONTRACTOR_DEBT_STATES_UPDATED,
};

class PushUpdate {
    _resurrectConnection = false;
    _ws = null;
    _token = null;

    _setupHandlers() {
        const self = this;
        self._ws.onclose = (a, b, c, d, e, f, g, h) => {
            if (self._resurrectConnection) {
                setTimeout(() => {
                    self.connect(self._token)
                }, 5000)
            }
        };
        self._ws.onmessage = (event) => {
            const eventData = JSON.parse(event.data);
            const {event_name: eventName, payload: payload} = eventData
            self._emitUpdate(eventName, payload)
        };
    }

    _emitUpdate(eventName, payload) {
        const busEventName = EventNameMapper[eventName];
        if (busEventName) {
            EventBus.dispatch(busEventName, payload)
        }
    }

    connect(token) {
        const self = this;
        if (!token) return;

        try {
            self._resurrectConnection = true;
            self._token = token;

            self._ws = new WebSocket(`${process.env.REACT_APP_PUSH_UPDATE_HOST}/api/ws?token=${token}`);
            self._setupHandlers();
        } catch (error) {
            console.log(error)
        }
    }

    disconnect() {
        try {
            const ws = this._ws;
            this._resurrectConnection = false;
            this._token = null;
            this._ws = null;

            if (ws) ws.close()
        } catch {

        }
    }
}

const singleton = new PushUpdate()

export default singleton;
