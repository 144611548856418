import React from 'react';
import SelectOrderStrategy from "../../order/select/SelectOrderStrategy";
import {OrderContractDefineMethodStrategies} from "../../../../order/GetStrategies";

const OrderContractDefineMethodStrategy = React.forwardRef(({onChange, ...props}, ref) => (
    <SelectOrderStrategy onChange={onChange} strategies={OrderContractDefineMethodStrategies} {...props} ref={ref}/>
));


export default OrderContractDefineMethodStrategy;
