import React, {useEffect, useState} from 'react';
import ReactSelect from 'react-select';
import {useSelector} from 'react-redux';
import {selectLang} from "../../store/reducers/main/main";
import {Translate} from "../../lang/lang";
import {selectBranches} from "../../store/reducers/branch/branchReducer";
import PropTypes from "prop-types";

const SelectBranch = React.forwardRef(({defaultValue, onChange, id, placeholder, ...props}, ref) => {
    const branches = useSelector(selectBranches);
    const lang = useSelector(selectLang);
    const t = Translate;
    const [value, setValue] = useState(null)
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        if (defaultValue !== null) {
            const val = branches.find(branch => branch?.id === defaultValue)
            setValue(val)
            setSelectedOption(val)
        } else {
            setValue(null)
            setSelectedOption(null)
        }
    },[defaultValue])

    const onChangeItem = option => {
        setValue(option)
        onChange(option)
        setSelectedOption(option+"")
    }

    const getOptionValue = option => option?.id
    const getOptionLabel = option => option?.name
    const formatOptionLabel = option => {
        return(
            <span>{option.name}</span>
        )
    }

    return (
        <div className="select-container">
            <ReactSelect
                placeholder={""}
                options={branches}
                inputId={id}
                className="select w-100"
                formatOptionLabel={formatOptionLabel}
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                value={value}
                onChange={onChangeItem}
                isClearable
                hideSelectedOptions
                noOptionsMessage={() => t(lang, "edi.toast.find_company_no_option")}
                onInputChange={(option) => setSelectedOption(value ? true : option)}
                onBlur={() => !value && setSelectedOption(false)}
                {...props}
            />
            <label htmlFor={id} className={selectedOption ? "label-active" : "label-inactive"}>{placeholder}</label>
        </div>

    )
});

SelectBranch.propTypes = {
    onChange: PropTypes.func
}

SelectBranch.defaultProps = {
    onChange: () => {}
}

export default SelectBranch;
