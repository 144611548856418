import React, {Fragment, useEffect} from 'react';
import {useWatch} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {scrollEvent} from "../../../../helpers/scrollEvent";
import {Col, Row, Spinner} from "react-bootstrap";
import IconArrowLeft from "../../../../assets/icon/IconArrowLeft";
import SelectCertificateWrapperComponent from "../../../common/SelectCertificateWrapperComponent";
import IconButton from "../../../IconButton";
import {faBookmark, faSignature} from "@fortawesome/free-solid-svg-icons";
import IconSignature from "../../../../assets/icon/IconSignature";
import {useNavigate} from "react-router-dom";
import DetailsFormOne from "./DetailsFormOne";
import MembersInfoFormV2 from "./MembersInfoFormV2";
import DetailsFormV2 from "./DetailsFormV2";
import AdditionalInformationRailwayV2 from "./AdditionalInformationRailwayV2";
import AdditionalInformationV2 from "./AdditionalInformationV2";
import {Railway} from "./SendWaybillFormV2";
import WaybillItemsV2Form from "./WaybillItemsV2Form";
import DeliveryForm from "./DeliveryForm";

const WaybillFormV2 = ({isEditing, isSaving, isSending, content, shipmentId}) => {
    const navigate = useNavigate()
    const t = Translate;
    const lang = useSelector(selectLang);

    const transportType = useWatch({name: 'transport_type' , exact: true})

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('roaming-waybill-v2-form-header'))
        return () => window.removeEventListener('scroll', () => scrollEvent('roaming-waybill-v2-form-header'))
    }, []);

    return (
        <Fragment>

            <Row id={'roaming-waybill-v2-form-header'} className={'my-2 justify-content-between align-items-center position-sticky'} style={{top: '4rem'}}>
                <Col xl={6} sm={12} className={'d-flex align-items-center gap-3'}>
                    <button type={"button"} onClick={() => navigate(-1)} className={'main-text btn-back'} style={{width: 35, height: 35}}>
                        <IconArrowLeft />
                    </button>
                    <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "edi.navbar.routes.edo.waybill.v2")}</h5>
                </Col>
                <Col className={'d-flex justify-content-end gap-2'}>
                    <IconButton
                        name="save"
                        variant="phoenix-secondary"
                        size="lg"
                        className="btn-white-davr d-flex gap-2 align-items-center"
                        iconClassName={'text-primary-davr'}
                        type={'submit'}
                    >
                        {isSaving && <Spinner className="me-2" animation="border" role="status" size="sm"/>}
                        {t(lang, 'roaming.common.save')}
                    </IconButton>

                    <SelectCertificateWrapperComponent
                        size="lg"
                        className="w-100 mt-2 mt-md-0 w-md-auto btn-primary-davr"
                        name="send"
                        openDialogButtonText={t(lang, 'roaming.common.send')}
                        submitButton={
                            <IconButton
                                variant="phoenix-secondary"
                                size="lg"
                                className="w-100 mt-2 mt-md-0 w-md-auto btn-primary-davr"
                                id="sendButton"
                                name="send"
                                type="submit"
                            >
                                {isSending ? <Spinner className="me-2" animation="border" role="status" size="sm"/> : <IconSignature />}
                                {t(lang, 'roaming.invoice.send_button')}
                            </IconButton>
                        }/>
                </Col>
            </Row>

            <DetailsFormOne />
            <MembersInfoFormV2 isEditing={isEditing} shipmentId={shipmentId}/>
            <DetailsFormV2 />
            {+transportType === Railway ?
                <AdditionalInformationRailwayV2 /> :
                <AdditionalInformationV2 isEditing={isEditing} content={content}/>
            }
            <WaybillItemsV2Form isEditing={isEditing} content={content} />
            <DeliveryForm />
        </Fragment>
    );
};

export default WaybillFormV2;
