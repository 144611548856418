import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {loadRolesAsync, selectRoles} from "../../../store/reducers/role/roleReducer";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import Badge from "../../common/Badge";
import ActionButton from "../../common/ActionButton";
import {faEdit, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import checkPermission from "../../../enum/permission/CheckPermission";
import {PermissionSystemRoleDelete, PermissionSystemRoleUpdate} from "../../../enum/permission/RolePermission";
import toastPermission from "../../../enum/permission/ToastPermission";
import EventBus from "../../../eventbus/EventBus";
import {ADD_ROLE_SUCCESS, DELETE_ROLE_SUCCESS, EDIT_ROLE_SUCCESS} from "../../../eventbus/roleEvents";
import toast from "react-hot-toast";
import AdvanceTable from "../../table/AdvanceTable";
import AdvanceTableWrapper from "../../table/AdvanceTableWrapper";
import DeleteRole from "./DeleteRole";
import EditRoleModal from "./EditRoleModal";

const RolesDatatable = () => {
    const dispatch = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;
    const roles = useSelector(selectRoles);
    const [idRole, setIdRole] = useState(null)
    const [editingRole, setEditingRole] = useState(null)
    const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false)
    const [showEditRoleModal, setShowEditRoleModal] = useState(false)

    const activeOrganization = useSelector(selectActiveOrganization)

    const columns = [
        {
            Header: "#",
            id: "row",
            Cell: ({row}) => {
                return row.index + 1;
            }
        },
        {
            accessor: 'name',
            Header: t(lang, "edi.common.label.name"),
            disableSortBy: true,
            cellProps: {
                className: 'fw-medium',
                width: '75%'
            },
            Cell:({row : {original}}) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <span>{original.name}</span>
                        {original.is_default && <Badge  variant={'phoenix'} bg="info" text="dark" className="ms-5 fs--1">
                            <strong>{t(lang, 'system.permission.role.datatable.default.role.title')}</strong>
                        </Badge >}
                    </div>
                )
            }
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row}) => {
                return (
                    <>
                        <ActionButton icon={faEdit} title="Изменить" variant="phoenix-warning" className="mx-1" size={'sm'} transform="shrink-3"
                                      onClick={() => {
                                          if (dispatch(checkPermission(PermissionSystemRoleUpdate))) {
                                              setEditingRole(row.original);
                                              setShowEditRoleModal(true)
                                          } else {
                                              dispatch(toastPermission())
                                          }
                                      }}/>
                        {!row?.original?.is_default &&
                            <ActionButton icon={faTrashAlt} title="Удалить" variant="action" className="mx-1" onClick={() => {
                                if (dispatch(checkPermission(PermissionSystemRoleDelete))) {
                                    setIdRole(row.original.id);
                                    setShowDeleteRoleModal(true)
                                } else {
                                    dispatch(toastPermission())
                                }
                            }}/>}
                    </>
                );
            }
        }
    ];

    useEffect(() => {
        dispatch(loadRolesAsync())
    }, [activeOrganization])

    useEffect(() => {
        const onRoleAddedHandler = EventBus.on(ADD_ROLE_SUCCESS, () => {
            toast.success(t(lang, "edi.common.toast.success"))
            dispatch(loadRolesAsync());
        });
        const onRoleDeletedHandler = EventBus.on(DELETE_ROLE_SUCCESS, () => {
            toast.success(t(lang, "edi.common.toast.success"))
            dispatch(loadRolesAsync());
        });
        const onRoleEditHandler = EventBus.on(EDIT_ROLE_SUCCESS, () => {
            toast.success(t(lang, "edi.common.toast.success"))
            dispatch(loadRolesAsync());
        });
        return () => {
            EventBus.remove(ADD_ROLE_SUCCESS, onRoleAddedHandler)
            EventBus.remove(DELETE_ROLE_SUCCESS, onRoleDeletedHandler)
            EventBus.remove(EDIT_ROLE_SUCCESS, onRoleEditHandler)
        }
    }, []);

    return (
        <Fragment>
            <AdvanceTableWrapper
                columns={columns}
                data={roles}
                pagination
                perPage={1000}
            >
                <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                        className: 'fs--1 mb-0 overflow-hidden'
                    }}
                />
            </AdvanceTableWrapper>
            <DeleteRole id={idRole} show={showDeleteRoleModal} onClose={() => {
                setIdRole(null);
                setShowDeleteRoleModal(false)
            }}/>
            {editingRole && <EditRoleModal role={editingRole} show={showEditRoleModal} onClose={() => {
                setEditingRole(null);
                setShowEditRoleModal(false)
            }}/>}
        </Fragment>
    );
};

export default RolesDatatable;
