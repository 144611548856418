import IconSignature from "../../assets/icon/IconSignature";
import {Fragment, useState} from "react";
import IconButton from "../IconButton";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../common/FalconCloseButton";
import {FormProvider, useForm} from "react-hook-form";
import SelectCertificateWrapperComponent from "../common/SelectCertificateWrapperComponent";
import EventBus from "../../eventbus/EventBus";
import {
    WAYBILL_V2_BULK_ACTION_FINISHED,
    WAYBILL_V2_BULK_ACTION_STARTED
} from "../../eventbus/romaing/waybill-v2/roamingWaybillV2Events";
import {useSelector} from "react-redux";
import {Translate} from "../../lang/lang";
import {selectCurrentCertificate} from "../../store/reducers/eimzo/eimzoReducer";
import {selectLang} from "../../store/reducers/main/main";

export const modalCommentLink = (SelectCertificate, displayName) => {
    const ModalCommentLink = ({
                                  id,
                                  waybills = [],
                                  handleShowProgressBar,
                                  updateActionResult,
                                  requestApi = () => {
                                  },
                                  btnText,
                                  variant,
                                  mTitle,
                                  cancel,
                                  ...props
                              }) => {

        const lang = useSelector(selectLang);
        const t = Translate;
        const activeCertificate = useSelector(selectCurrentCertificate);

        const [show, setShow] = useState(false);

        const methods = useForm({
            defaultValues: {
                comment: ''
            }
        })

        const handleShow = () => setShow(true);
        const handleClose = () => setShow(false);

        const [loading, setLoading] = useState(false); // cancel

        const onSubmit = (formData) => {
            if (waybills.length > 0)
                bulkCancel(formData.comment);
            else
                singleCancel(formData.comment);
        };

        const singleCancel = (comment) => {
            setLoading(true);
            requestApi(activeCertificate, lang, id, comment)
                .then(() => {
                    setLoading(false)
                })
                .catch((error) => {
                    setLoading(false)
                })
        }

        const bulkCancel = async (comment) => {
            handleShowProgressBar();
            EventBus.dispatch(WAYBILL_V2_BULK_ACTION_STARTED);
            for (let i = 0; i < waybills.length; i++) {
                const waybill = waybills[i];
                try {
                    await requestApi(activeCertificate, lang, waybill.id, comment);
                    updateActionResult({
                        number: waybill.info.number,
                        date: waybill.info.date,
                        contractor: {
                            name: waybill.customer.name,
                            inn: waybill.customer.inn
                        }
                    });
                } catch (error) {
                    updateActionResult({
                        number: waybill.info.number,
                        date: waybill.info.date,
                        contractor: {
                            name: waybill.customer.name,
                            inn: waybill.customer.inn
                        },
                        error: error?.message
                    })
                }
            }
            EventBus.dispatch(WAYBILL_V2_BULK_ACTION_FINISHED);
        }

        return <Fragment>

            <IconButton
                onClick={handleShow}
                variant={"phoenix-secondary"}
                size="sm"
                className={'btn-white-davr'}
                {...props}
            >
                <IconSignature/>
                {t(lang, btnText ? btnText : "roaming.common.decline")}
            </IconButton>
            <Modal className={'davr-modal'} show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>
                        {t(lang, mTitle)}
                    </Modal.Title>
                    <FalconCloseButton onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    <FormProvider {...methods}>
                        <Form id={'comment-form'} onSubmit={methods.handleSubmit(onSubmit)}>
                            <Form.Group>
                                <Form.Label>
                                    {t(lang, "roaming.invoice.comment_modal.description")}
                                </Form.Label>
                                <Form.Control
                                    as={"textarea"}
                                    type="text"
                                    placeholder={t(lang, "roaming.invoice.comment_modal.description")}
                                    isInvalid={methods.formState?.errors?.comment}
                                    {...methods.register('comment', {
                                        required: t(lang, "roaming.common.forms.validations.is_required")
                                    })}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{methods.formState?.errors?.comment?.message}</Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                    </FormProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={'btn-white-modal'} variant="secondary" size={"sm"} onClick={handleClose}>
                        {t(lang, "roaming.common.cancel")}
                    </Button>
                    <SelectCertificateWrapperComponent
                        cancel={cancel}
                        openDialogButtonText={t(lang, "roaming.free_form_document.view.action.sign")}
                        submitButton={
                            <Button id={displayName || "ModalCommentLink"}
                                    variant={"phoenix-secondary"}
                                    type="submit"
                                    size={"sm"}
                                    disabled={loading}
                                    form={'comment-form'}
                            >
                                {loading ? <Spinner className='align-middle me-2' animation='border' role='switch'
                                                    size='sm'/> : <IconSignature/>}
                                {t(lang, btnText ? btnText : "roaming.common.decline")}
                            </Button>}/>
                </Modal.Footer>
            </Modal>
        </Fragment>
    }

    ModalCommentLink.displayName = displayName || "ModalCommentLink"

    return ModalCommentLink
}
