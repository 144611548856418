export const excelFileUploader = ({content, fileName}) => {
    const link = document.createElement("a");
    document.body.appendChild(link);

    const blobFile = new Blob([content], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    const blobUrl = window.URL.createObjectURL(blobFile);

    link.href = blobUrl;
    link.download = fileName;
    link.click();
    link.remove();
    window.URL.revokeObjectURL(blobUrl);
}

export const downloadFile = ({ content, contentType, fileName }) => {
    const link = document.createElement("a");
    document.body.appendChild(link);

    const blobFile = new Blob([content], { type: contentType });
    const blobUrl = window.URL.createObjectURL(blobFile);

    link.href = blobUrl;
    link.download = fileName;
    link.click();
    link.remove();
    window.URL.revokeObjectURL(blobUrl);
};
