import React, {Fragment, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import IconButton from "../IconButton";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import SearchItem from "../draft-item/SearchItem";
import {Translate} from "../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";

const SelectItemModal = ({onChange, buttonProps, defaultItemId, index}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    return (
        <Fragment>
            {/*<Modal.Title>{t(lang, 'items.common.draft.select_item')}</Modal.Title>*/}
            <SearchItem
                index={index}
                onChange={onChange}
                defaultItemId={defaultItemId}
            />
        </Fragment>
    );
};

export default SelectItemModal;
