import React, {Fragment, useEffect, useState} from 'react';
import {selectOrganizationTemplates} from "../../../store/reducers/excel/excelReducer";
import {useDispatch, useSelector} from "react-redux";
import {
    selectInvoiceFilterOptions, uploadCustomerInvoicesToExcelAsync, uploadCustomerInvoicesWithItemsToExcelAsync,
    uploadExecutorInvoicesToExcelAsync, uploadExecutorInvoicesWithItemsToExcelAsync
} from "../../../store/reducers/roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import {selectLang} from "../../../store/reducers/main/main";
import {useParams} from "react-router-dom";
import {Translate} from "../../../lang/lang";
import {TYPE_ROAMING_INVOICES, TYPE_ROAMING_INVOICES_WITH_ITEMS} from "../../../enum/excel/ExcelTemplateTypes";
import {excelFileUploader} from "../../../helpers/excel-uploader";
import {DRAFT, VIEWER_CUSTOMER, VIEWER_EXECUTOR} from "../../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import checkPermission from "../../../enum/permission/CheckPermission";
import toastPermission from "../../../enum/permission/ToastPermission";
import {PermissionRoamingInvoiceDownloadExcel} from "../../../enum/permission/RoamingPermission";
import {Dropdown, Form} from "react-bootstrap";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import IconCloudUpload from "../../../assets/icon/IconCloudUpload";

const UploadRoamingInvoiceListToExcel = ({...props}) => {

    const [roamingInvoicesWithItemsTypeTemplates, setRoamingInvoicesWithItemsTypeTemplates] = useState([]);
    const [roamingInvoicesTypeTemplates, setRoamingInvoicesTypeTemplates] = useState([]);
    const organizationTemplates = useSelector(selectOrganizationTemplates);

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [shouldLoadAll, setShouldLoadAll] = useState(false);
    const filter = {...useSelector(selectInvoiceFilterOptions)};
    const lang = useSelector(selectLang);
    const {viewer} = useParams();
    const t = Translate;

    useEffect(() => {
        const roamingInvoicesTemplates = organizationTemplates.filter(template => template.type === TYPE_ROAMING_INVOICES) || [];
        const roamingInvoicesWithItemsTemplates = organizationTemplates.filter(template => template.type === TYPE_ROAMING_INVOICES_WITH_ITEMS) || [];

        setRoamingInvoicesTypeTemplates(roamingInvoicesTemplates);
        setRoamingInvoicesWithItemsTypeTemplates(roamingInvoicesWithItemsTemplates);
    }, [organizationTemplates])

    const uploadExcel = (uri) => {
        setLoading(true);

        if (shouldLoadAll)
            filter.limit = null;

        if (viewer === VIEWER_CUSTOMER) {
            uploadCustomerInvoicesToExcelAsync({...filter, uri: uri})
                .then(loadedContent => {
                    excelFileUploader({
                        content: loadedContent,
                        fileName: t(lang, 'roaming.invoices.excel_upload.file_name'),
                    })
                })
                .catch(error => console.log(error))
                .finally(() => setLoading(false))
        } else if (viewer === VIEWER_EXECUTOR) {
            uploadExecutorInvoicesToExcelAsync({...filter, uri: uri})
                .then(loadedContent => {
                    excelFileUploader({
                        content: loadedContent,
                        fileName: t(lang, 'roaming.return_invoices_with_items.excel_upload.file_name'),
                    })
                })
                .catch(error => console.log(error))
                .finally(() => setLoading(false))
        }
    }

    const uploadExcelWithItems = (uri) => {
        setLoading(true);

        if (shouldLoadAll)
            filter.limit = null;

        if (viewer === VIEWER_CUSTOMER) {
            uploadCustomerInvoicesWithItemsToExcelAsync({...filter, uri: uri})
                .then(loadedContent => {
                    excelFileUploader({
                        content: loadedContent,
                        fileName: t(lang, 'roaming.invoices.excel_upload.file_name'),
                    })
                })
                .catch(error => console.log(error))
                .finally(() => setLoading(false))
        } else if (viewer === VIEWER_EXECUTOR) {
            uploadExecutorInvoicesWithItemsToExcelAsync({...filter, uri: uri})
                .then(loadedContent => {
                    excelFileUploader({
                        content: loadedContent,
                        fileName: t(lang, 'roaming.return_invoices_with_items.excel_upload.file_name'),
                    })
                })
                .catch(error => console.log(error))
                .finally(() => setLoading(false))
        }
    }

    const uploadInvoices = (uri) => {
        if (dispatch(checkPermission(PermissionRoamingInvoiceDownloadExcel))) uploadExcel(uri)
        else dispatch(toastPermission())
    };

    const uploadInvoicesWithItems = (uri) => {
        if (dispatch(checkPermission(PermissionRoamingInvoiceDownloadExcel))) uploadExcelWithItems(uri)
        else dispatch(toastPermission())
    };

    if (viewer === DRAFT) {
        return false
    }

    return (
        <Fragment>
            <Dropdown {...props} >
                <Dropdown.Toggle bsPrefix="toggle" className={'btn-davr px-5 py-0 bg-white text-dark'} disabled={loading}>
                    {loading
                        ?
                        <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>
                        :
                        <IconCloudUpload />
                    }
                    <span className="d-none d-sm-inline-block fw-semi-bold fs-8 ms-1 text-nowrap fs-md-9">
                        {t(lang, 'roaming.invoices.datatable.header.navigation.dropdown.export_to_excel')}
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className='navbar-dropdown-caret p-2 dropdown-menu-center'>
                    <Form.Group className="switch-container mb-2 gap-2 align-items-center">
                        <label className="switch">
                            <Form.Check type="switch" id={"download_all"} className="d-flex align-items-center">
                                <Form.Check.Input onChange={(e) => setShouldLoadAll(e.target.checked)} type="checkbox"/>
                                <span className="slider"></span>
                            </Form.Check>
                        </label>
                        <Form.Check.Label
                            htmlFor={"download_all"}
                            className="mb-0 switch-label">{t(lang, 'roaming.invoices.datatable.header.navigation.dropdown.download_all')}</Form.Check.Label>
                    </Form.Group>
                    <Dropdown.Header className="text-black p-1">{t(lang, 'roaming.invoices.datatable.header.navigation.dropdown.item.export_orders_to_excel')}</Dropdown.Header>
                        {roamingInvoicesTypeTemplates.map((template, index) => (
                            <Dropdown.Item className="ps-4 py-0" key={index} onClick={() => uploadInvoices(template.uri)}>{t(lang, `edi.template.name.${template.name}`)}</Dropdown.Item>
                        ))}
                    <Dropdown.Header className="text-black p-1">{t(lang, 'roaming.invoices.datatable.header.navigation.dropdown.item.export_orders_with_items_to_excel')}</Dropdown.Header>
                    {roamingInvoicesWithItemsTypeTemplates.map((template, index) => (
                        <Dropdown.Item className="ps-4 py-0" key={index} onClick={() => uploadInvoicesWithItems(template.uri)}>{t(lang, `edi.template.name.${template.name}`)}</Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </Fragment>
    );
};

export default UploadRoamingInvoiceListToExcel;
