import React, {useRef, useState} from 'react';
import {Translate} from "../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {selectCompanyCatalogs} from "../../store/reducers/roaming/roamingReducer";
import ReactSelect from "react-select";
import {Form} from "react-bootstrap";
import CustomFloatingLabelSelect from "../floating-custom-select/CustomFloatingLabelSelect";

const formatOptionLabel = (option) => {
    return (
        <div className="fs-9 mt-3">
            <span className="text-black fw-bold me-1">{option.class_code}</span>-
            <span className="ms-1 text-facebook">{option.name}</span>
        </div>
    )
}

const SelectOrganizationCatalog = ({onChange, selectProps, placeholder}) => {

    const catalogs = useSelector(selectCompanyCatalogs);
    const [value, setValue] = useState(null);

    const handleChange = (option) => {
        setValue(option)
        onChange(option)
    }

    return <CustomFloatingLabelSelect
            options={catalogs}
            hideSelectedOptions
            getOptionLabel={option => option.class_code}
            getOptionValue={option => `${option.class_code} ${option.name}`}
            formatOptionLabel={formatOptionLabel}
            label={placeholder}
            onChange={(option) => handleChange(option)}
            value={value}
            {...selectProps}
        />
};

export default SelectOrganizationCatalog;
