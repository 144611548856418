import React from 'react';
import {Card, Dropdown, ListGroup} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faPersonWalkingArrowRight, faPhone} from "@fortawesome/free-solid-svg-icons";
import IconMessage from "../../../assets/icon/IconMessage";
import PaymentInstruction from "./PaymentInstruction";
import Flex from "../../common/Flex";
import {faTelegram} from "@fortawesome/free-brands-svg-icons";
import NavbarTopBarMessageDropdown from "./NavbarTopBarMessageDropdown";
import NavbarTopBarMailDropdown from "./NavbarTopBarMailDropdown";
import NavbarTopBarBellDropdown from "./NavbarTopBarBellDropdown";
import IconStroke from "../../../assets/icon/IconStroke";

const NavigatorTopBar = () => {
    return (
        <div className={'d-flex align-items-center bg-100 rounded-5 px-3 py-1'}>
            <NavbarTopBarMessageDropdown />
            <IconStroke />
            <NavbarTopBarMailDropdown />
            <IconStroke />
            <NavbarTopBarBellDropdown />
        </div>
    );
};

export default NavigatorTopBar;
