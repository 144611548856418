import React, {Fragment, useEffect, useState} from 'react';
import {selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {Controller, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {selectCompanyCatalogs} from "../../../../store/reducers/roaming/roamingReducer";
import {loadCatalogByCode} from "../../../../store/reducers/roaming/roamingReferenceReducer";
import {FloatingLabel, Form} from "react-bootstrap";
import CatalogLinkSelector from "../../../common/CatalogLinkSelector";
import CatalogPackageLinkSelector from "../../../common/CatalogPackageLinkSelector";
import SelectMeasurement from "../../../common/SelectMeasurement";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import Badge from "../../../common/Badge";
import Cleave from "cleave.js/react";


const itemFieldName = 'items'

const ItemRowV2 = ({index, remove, allowDelete, isEditing, content}) => {

    const lang = useSelector(selectLang);
    const t = Translate;
    const companyCatalogs = useSelector(selectCompanyCatalogs);

    const [catalogPackages, setCatalogPackages] = useState(null)
    const {register, formState: {errors}, watch, setValue} = useFormContext();
    const {update} = useFieldArray({name: itemFieldName});
    const rootName = `${itemFieldName}.${index}`;
    const item = useWatch({name: rootName});
    const itemQuantity = watch(`${rootName}.quantity`);
    const itemPrice = watch(`${rootName}.price`);
    const catalogCode = watch(`${rootName}.catalog_code`)
    const packageCode = watch(`${rootName}.package_code`)

    let itemErrors = {};
    if (errors && errors[itemFieldName] && errors[itemFieldName][index])
        itemErrors = errors[itemFieldName][index];

    useEffect(() => {
        recalculate();
    }, [itemQuantity, itemPrice]);

    useEffect( () => {
        if ((isEditing || content) && companyCatalogs.length > 0) {
            if (catalogCode) {
                let catalog  =  loadCatalogByCode(catalogCode)
                setCatalogPackages(catalog)
            }
        }
    }, []);
    useEffect( () => {
        if (companyCatalogs.length > 0) {
            if (catalogCode) {
                let code = onCatalogChanged(companyCatalogs.find(i => i.class_code  === catalogCode))
                if  (!code) {
                    let catalog = loadCatalogByCode(catalogCode);
                    setCatalogPackages(catalog)
                }
            }
        }
    }, [companyCatalogs, catalogCode])

    const recalculate = () => {
        item.quantity = itemQuantity;
        item.price = itemPrice;
        item.total = +itemQuantity * +itemPrice;

        update(index, item);
    }

    const onCatalogChanged = (option) => {
        if (option) {
            setCatalogPackages(option)
        } else {
            setCatalogPackages(null)
        }
        setValue(`${rootName}.measurement`, null);
        return option
    };

    const deleteField = () => {
        remove(index);
    }

    return (
        <tr>
            <td>
                {index + 1}
            </td>
            <td className="p-1">
                <FloatingLabel label={t(lang, 'roaming.waybill.send.shipment_items_form.table.product')}>
                    <Form.Control
                        placeholder={t(lang, 'roaming.waybill.send.shipment_items_form.table.product')}
                        {...register(`${rootName}.name`, {required: t(lang, "edi.common.forms.validations.is_required")})}
                    />
                </FloatingLabel>

                <Form.Control.Feedback className={'d-block'} type="invalid">
                    {itemErrors?.name?.message}
                </Form.Control.Feedback>
            </td>
            <td className="p-1">
                <Controller
                    name={`${rootName}.catalog_code`}
                    rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                    render={
                        ({field}) => (
                            <CatalogLinkSelector
                                defaultCatalog={field.value}
                                textButton={field.value || t(lang, "roaming.common.select.placeholder")}
                                onChange={(catalog) => {
                                    field.onChange(catalog?.class_code || null)
                                    setValue(`${rootName}.package_code`, null);
                                }}
                            />
                        )
                    }
                />
                <Form.Control.Feedback className={'d-block'} type="invalid">
                    {itemErrors?.catalog_code?.message}
                </Form.Control.Feedback>
            </td>

            <td className="p-1">

                {catalogPackages ? <Fragment>
                        <Controller name={`${rootName}.package_code`}
                                    rules={{
                                        required: t(lang, 'items.common.validations.is_required')
                                    }}
                                    render={({field}) => (
                                        <CatalogPackageLinkSelector
                                            textButton={field.value || t(lang, "roaming.common.select.placeholder")}
                                            defaultCatalogPackage={packageCode?.code ? packageCode.code : packageCode}
                                            catalogPackages={catalogPackages?.package_names}
                                            onChange={field.onChange}
                                            /*linkProps={{
                                                className: classNames({
                                                    'text-primary cursor-pointer fs--1': !error?.package,
                                                    'text-danger cursor-pointer fs--1': error?.package
                                                })
                                            }}*/
                                        />
                                    )}

                        />
                        <Form.Control.Feedback className={'d-block'} type="invalid">
                            {itemErrors?.package_code?.message}
                        </Form.Control.Feedback>
                    </Fragment>
                    :
                    <Controller
                        name={`${rootName}.measurement`}
                        rules={{required: t(lang, 'edi.common.forms.validations.is_required')}}
                        render={({field}) => (
                            <SelectMeasurement
                                isClearable
                                defaultMeasureId={field.value}
                                menuPortalTarget={document.body}
                                placeholder={t(lang, "edi.common.select_button")}
                                onChange={measurement => field.onChange(measurement?.measureId)}
                                className={classNames({"is-invalid": itemErrors?.measureId})}
                                wrapperClassName={classNames({'is-invalid': errors?.measureId})}
                            />
                        )}
                    />}
                <Form.Control.Feedback className={'d-block'} type="invalid">
                    {itemErrors?.measurement?.message}
                </Form.Control.Feedback>
            </td>


            <td className="p-1">
                <Controller
                    name={`${rootName}.quantity`}
                    rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                    render={({field}) => (
                        <FloatingLabel label={t(lang, 'roaming.waybill.send.shipment_items_form.table.quantity')}>
                            <Cleave
                                {...field}
                                options={{numeral: true, delimiter: '', numeralDecimalScale: 4}}
                                className={'form-control'}
                                placeholder={t(lang, 'roaming.waybill.send.shipment_items_form.table.quantity')}
                            />
                        </FloatingLabel>
                    )}
                />
                <Form.Control.Feedback className={'d-block'} type="invalid">
                    {itemErrors?.quantity?.message}
                </Form.Control.Feedback>
            </td>
            <td className="p-1">
                <Controller
                    name={`${rootName}.price`}
                    rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                    render={({field}) => (
                        <FloatingLabel label={t(lang, 'roaming.waybill.send.shipment_items_form.table.price')}>
                            <Cleave
                                {...field}
                                options={{numeral: true, delimiter: ''}}
                                className={'form-control'}
                                placeholder={t(lang, 'roaming.waybill.send.shipment_items_form.table.price')}
                            />
                        </FloatingLabel>

                    )}
                />
                <Form.Control.Feedback className={'d-block'} type="invalid">
                    {itemErrors?.price?.message}
                </Form.Control.Feedback>
            </td>
            <td className="p-1">
                <Form.Control
                    disabled={true}
                    value={item.total}
                    {...register(`${rootName}.total`)}
                    placeholder={t(lang, 'roaming.waybill.send.shipment_items_form.table.total')}
                />
            </td>
            <td className="text-center p-1 align-middle">
                {allowDelete && <Badge
                    onClick={deleteField}
                    className={'px-2 py-2 fs-8 rounded-3 cursor-pointer'}
                    bg="danger-davr"
                    variant="phoenix"
                >
                    <FontAwesomeIcon icon={faTrashAlt} />
                </Badge>}
            </td>
        </tr>
    );
};

export default ItemRowV2;
