import React, {useState} from 'react';
import {loadFreeFormDocumentZipFileAsync} from "../../../../store/reducers/roaming/free-form/freeFormDocumentReducer";
import {selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {Spinner} from "react-bootstrap";
import {faFileZipper} from "@fortawesome/free-solid-svg-icons";
import SignActionWithSelectCertificateWrapper from "../../../common/SignActionWithSelectCertificateWrapper";
import IconButton from "../../../IconButton";

const DownloadFreeFormDocumentZipFile = ({id}) => {
    const [loading, setLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const downloadZip = () => {
        setLoading(true);

        loadFreeFormDocumentZipFileAsync(id)
            .then(response => {
                const link = document.createElement("a");
                document.body.appendChild(link);

                const blobFile = new Blob([response], {type: 'application/zip'});
                const blobUrl = window.URL.createObjectURL(blobFile);

                link.href = blobUrl;
                link.download = `${id}.zip`;
                link.click();
                link.remove();
                window.URL.revokeObjectURL(blobUrl);

            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }
    return (
        <SignActionWithSelectCertificateWrapper>
            <IconButton size='sm'
                        onClick={downloadZip}
                        variant='phoenix-warning'
                        icon={faFileZipper}
                        className={'btn-davr bg-white text-dark d-flex gap-2'}
                        iconClassName={"text-primary-davr fs-8"}
            >
                {t(lang, 'roaming.free_form_document.view.download_zip')}
                {loading && <Spinner className='align-middle ms-2' animation='border' role='switch' size='sm'/>}
            </IconButton>
        </SignActionWithSelectCertificateWrapper>
    );
};

export default DownloadFreeFormDocumentZipFile;
