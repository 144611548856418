import React, {Fragment} from 'react';
import {useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {Accordion, Col, Row, Form, Card} from "react-bootstrap";
import IconButton from "../../IconButton";
import {faPlus, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Badge from "../../common/Badge";

const Parts = ({ fieldsPart, addFieldPart, removeFieldPart }) => {
    const { register, formState: {errors: partErrors} } = useFormContext()
    const errors = partErrors.parts || []
    const lang = useSelector(selectLang)
    const t = Translate



    return (
        <Fragment>
            <Row className={'align-items-end mb-2'}>
                <Col>
                    <h5 className="mb-0 ms-3 fs-9 second-text fw-medium">{t(lang, "roaming.contracts.add.parts.title")}</h5>
                </Col>
                <Col className="d-flex justify-content-end">
                    <IconButton
                        onClick={addFieldPart}
                        variant="phoenix-primary"
                        icon={faPlus}
                        size="md"
                        transform="shrink-3"
                        className="btn-davr px-3 py-0 bg-primary-davr-outline fs-8"
                    >
                        {t(lang, "roaming.contracts.add.parts.add_part")}
                    </IconButton>
                </Col>
            </Row>
            <Card className={'py-1 px-2 rounded-4 border-0'}>
                <Accordion alwaysOpen={true} defaultActiveKey={fieldsPart.map(p => p.id)}>
                    {fieldsPart.map((part, index) => (
                        <Accordion.Item className={`${errors[index] ? 'border-danger' : ""} border-0`} key={part.id}  eventKey={part.id}>
                            <Accordion.Header>
                                <div className="w-100">
                                    <Form.Control
                                        {...register(`parts.${index}.title`, {
                                            // required: "edi.common.forms.validations.is_required"
                                        })}
                                        className={errors[index]?.title && 'is-invalid'}
                                        placeholder={t(lang, "roaming.contracts.add.parts.name:placeholder")}
                                        type="text"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {t(lang, errors[index]?.title?.message)}
                                    </Form.Control.Feedback>
                                </div>
                                {fieldsPart.length > 1 && <Badge
                                    className={'p-2 ms-2 fs-8 rounded-3 cursor-pointer'}
                                    bg="danger-davr"
                                    variant="phoenix"
                                    onClick={() => fieldsPart.length > 1 && removeFieldPart(index)}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt}/>
                                </Badge>}
                            </Accordion.Header>
                            <Accordion.Body>
                                <Form.Control
                                    {...register(`parts.${index}.body`, {
                                        // required: "edi.common.forms.validations.is_required"
                                    })}
                                    className={errors[index]?.body && 'is-invalid'}
                                    placeholder={t(lang, "roaming.contracts.add.parts.body:placeholder")}
                                    as="textarea"
                                    rows={10}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t(lang, errors[index]?.body?.message)}
                                </Form.Control.Feedback>
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
                {/*<Row>
                    <Col className="text-end mt-4">
                        <IconButton
                            onClick={addFieldPart}
                            variant="phoenix-primary"
                            icon={faPlus}
                            size="sm"
                            transform="shrink-3"
                            className={'fw-semi-bold'}
                        >{t(lang, "roaming.contracts.add.parts.add_part")}
                        </IconButton>
                    </Col>
                </Row>*/}
            </Card>
        </Fragment>
    );
};

export default Parts;
