import axios from 'axios';

class AttorneyService {
    addAttorney(payload) {
        return axios.post("/attorney/owner/register", payload);
    }

    editAttorney(id, payload) {
        return axios.post(`/attorney/owner/update/${id}`, payload);
    }

    getAttorney(id) {
        return axios.get(`/attorney/get/${id}`)
    }

    getAttorneys(params) {
        return axios.get('/attorney/get', {
            params: params
        })
    }

    getCountAttorneys(params) {
        return axios.get('/attorney/count', {
            params: params
        })
    }

    deleteAttorney(id) {
        return axios.post(`/attorney/owner/delete/${id}`)
    }

    /* --- Actions --- */

    getSignHashCode(id) {
        return axios.get(`/attorney/sign/hash/${id}`)
    }

    signAttorney({id, signature}) {
        return axios.post(`/attorney/sign/${id}`, {
            signature: signature,
        })
    }

    getCancelHashCode(id) {
        return axios.get(`/attorney/cancel/hash/${id}`)
    }

    cancelAttorney({id, signature}) {
        return axios.post(`/attorney/cancel/${id}`, {
            signature: signature,
        })
    }

    getAcceptHashCode(id) {
        return axios.get(`/attorney/accept/hash/${id}`)
    }

    acceptAttorney({id, signature}) {
        return axios.post(`/attorney/accept/${id}`, {
            signature: signature,
        })
    }

    getDeclineHashCode(id) {
        return axios.get(`/attorney/decline/hash/${id}`)
    }

    declineAttorney({id, signature}) {
        return axios.post(`/attorney/decline/${id}`, {
            signature: signature,
        })
    }

    printAttorney(id){
        return axios.get(`/pdf/attorney/${id}`,{
            responseType:'blob'
        })
    }
}

const attorneyService = new AttorneyService();

export default attorneyService;
