import React, {useState} from 'react';
import {selectLang} from "../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {printEmpowermentAsync} from "../../../store/reducers/roaming/empowerment/empowermentReducer";
import printJS from "print-js";
import {Spinner} from "react-bootstrap";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../IconButton";

const PrintEmpowerment = ({id}) => {
    const [loading, setLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const onPrint = () => {
        setLoading(true);
        printEmpowermentAsync(id)
            .then((response) => {
                setLoading(false);
                const blob = new Blob([response], {type: "application/pdf"});
                const blobUrl = window.URL.createObjectURL(blob);
                printJS(blobUrl);
            })
            .catch((err) => {
                setLoading(false);
            });
    };
    return (
        <IconButton
            variant="phoenix-secondary"
            size="sm"
            icon={faPrint}
            onClick={onPrint}
            iconClassName="text-primary-davr fs-8"
            className="btn-davr px-3 py-0 bg-white d-flex gap-2"
        >
            <span>{t(lang, "edi.common.button.print")}</span>
            {loading &&
                <Spinner
                    className="align-middle ms-2"
                    size="sm"
                    animation="border"
                    role="switch"
                />
            }
        </IconButton>
    );
};

export default PrintEmpowerment;
