import React from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {useFormContext, useWatch} from "react-hook-form";
import {selectLang} from "../../../store/reducers/main/main";
import {Form} from "react-bootstrap";

const RoamingInvoiceFormHideReportCommittentSwitcher = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();
    const hasCommittent = useWatch({name: `productList.hasCommittent`, exact: true});

    if (!hasCommittent) return null;

    return (
        <>
            <Form.Group className="switch-container gap-2 align-items-center">
                <Form.Check.Label
                    htmlFor={"hideReportCommittent"}
                    className="mb-0 switch-label">{t(lang, 'roaming.invoice.hideReportCommittent')}</Form.Check.Label>
                <label className="switch">
                    <Form.Check type="switch" id={"hideReportCommittent"} className="d-flex align-items-center">
                        <Form.Check.Input {...form.register("productList.hideReportCommittent")} type="checkbox"/>
                        <span className="slider"></span>
                    </Form.Check>
                </label>
            </Form.Group>
        </>


    );
};

export default RoamingInvoiceFormHideReportCommittentSwitcher;
