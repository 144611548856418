import React from 'react';

const IconBell = ({width = 24, height = 24, color = "none"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 25 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.5 17V18C15.5 19.6569 14.1569 21 12.5 21C10.8431 21 9.5 19.6569 9.5 18V17M15.5 17H9.5M15.5 17H19.0905C19.473 17 19.6652 17 19.8201 16.9478C20.116 16.848 20.3475 16.6156 20.4473 16.3198C20.4997 16.1643 20.4997 15.9715 20.4997 15.5859C20.4997 15.4172 20.4995 15.3329 20.4863 15.2524C20.4614 15.1004 20.4024 14.9563 20.3126 14.8312C20.2651 14.7651 20.2048 14.7048 20.0858 14.5858L19.6963 14.1963C19.5706 14.0706 19.5 13.9001 19.5 13.7224V10C19.5 6.134 16.366 2.99999 12.5 3C8.63401 3.00001 5.5 6.13401 5.5 10V13.7224C5.5 13.9002 5.42924 14.0706 5.30357 14.1963L4.91406 14.5858C4.79476 14.7051 4.73504 14.765 4.6875 14.8312C4.59766 14.9564 4.53815 15.1004 4.5132 15.2524C4.5 15.3329 4.5 15.4172 4.5 15.586C4.5 15.9715 4.5 16.1642 4.55245 16.3197C4.65225 16.6156 4.8848 16.848 5.18066 16.9478C5.33556 17 5.52701 17 5.90956 17H9.5"
                stroke="#9BA2B1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default IconBell;
