import React, {
    createContext,
    useContext,
    useEffect,
    useReducer
} from 'react';
import { getColor, getItemFromStore } from '../helpers/utils';
import { initialConfig } from '../../config';
import { configReducer } from '../reducers/ConfigReducer';

export const AppContext = createContext({});

const AppProvider = ({ children }) => {
    const configState = {
        isNavbarVerticalCollapsed: getItemFromStore(
            'isNavbarVerticalCollapsed',
            initialConfig.isNavbarVerticalCollapsed
        ),
        openNavbarVertical: initialConfig.openNavbarVertical,
        theme: getItemFromStore('theme', initialConfig.theme),
        navbarTopAppearance: getItemFromStore(
            'navbarTopAppearance',
            initialConfig.navbarTopAppearance
        ),
        navbarVerticalAppearance: getItemFromStore(
            'navbarVerticalAppearance',
            initialConfig.navbarVerticalAppearance
        ),
        navbarPosition: getItemFromStore(
            'navbarPosition',
            initialConfig.navbarPosition
        ),
        navbarTopShape: getItemFromStore(
            'navbarTopShape',
            initialConfig.navbarTopShape
        ),
        isRTL: getItemFromStore('isRTL', initialConfig.isRTL)
    };

    const [config, configDispatch] = useReducer(configReducer, configState);

    const setConfig = (payload) => {
        configDispatch({
            type: 'SET_CONFIG',
            payload
        });
    };

    const toggleTheme = () => {
        configDispatch({
            type: 'SET_CONFIG',
            payload: {
                theme: config.theme === 'dark' ? 'light' : 'dark'
            }
        });
    };

    const getThemeColor = (name) => {
        return getColor(name);
    };

    useEffect(() => {
        if (config.navbarTopShape === 'slim') {
            document.body.classList.add('nav-slim');
        } else {
            document.body.classList.remove('nav-slim');
        }

        if (config.navbarPosition === 'combo') {
            document.documentElement.classList.add('navbar-combo');
        } else {
            document.documentElement.classList.remove('navbar-combo');
        }

        if (config.navbarPosition === 'dual') {
            setConfig({
                navbarTopShape: 'default'
            });
            document.documentElement.classList.add('dual-nav');
        } else {
            document.documentElement.classList.remove('dual-nav');
        }

        if (config.navbarPosition === 'horizontal') {
            document.documentElement.classList.add('navbar-horizontal');
        } else {
            document.documentElement.classList.remove('navbar-horizontal');
        }

        if (config.isNavbarVerticalCollapsed) {
            document.documentElement.classList.add('navbar-vertical-collapsed');
        } else {
            document.documentElement.classList.remove('navbar-vertical-collapsed');
        }
    }, [config]);

    return (
        <AppContext.Provider
            value={{ config, setConfig, toggleTheme, getThemeColor, configDispatch }}
        >
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => useContext(AppContext);

export default AppProvider;
