import React, {useRef} from 'react';
import {OverlayTrigger, Popover} from "react-bootstrap";
import {Translate} from "../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {isNdsPayerCertificateActive, isNdsPayerCertificatePending} from '../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper';

const pinflFormatRegEx = /^\d{14}$/
const RoamingInvoiceVatRegStatusBadge = ({vatRegStatus, vatRegCode, identifier}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const ref = useRef(null);

    const getBadgeText = (identifier, vatRegCode, vatRegStatus) => {
        if (pinflFormatRegEx.test(identifier) && !vatRegCode && !vatRegStatus)
            return t(lang, 'roaming.invoice.nds_status.person');

        if (vatRegCode && vatRegStatus === isNdsPayerCertificateActive)
            return t(lang, 'roaming.invoice.nds_status.20');

        if (vatRegCode && vatRegStatus === isNdsPayerCertificatePending)
            return t(lang, 'roaming.invoice.nds_status.22');

        return t(lang, 'roaming.invoice.nds_status.default');
    };

    const badgeText = getBadgeText(identifier, vatRegCode, +vatRegStatus);

    return (
        <OverlayTrigger trigger={['hover', 'focus']}
                        placement={'right'}
                        overlay={
                            <Popover className='ms-0'>
                                <Popover.Body>{t(lang, 'roaming.common.nds_status')}</Popover.Body>
                            </Popover>
                        }
                        target={ref.current}
        >
            <span className={'ms-2 fs-9'} ref={ref}>{badgeText}</span>
        </OverlayTrigger>
    );
};

export default RoamingInvoiceVatRegStatusBadge;
