import {Form} from "react-bootstrap";
import numeral from "numeral";
import ActionButton from "../../../common/ActionButton";
import React, {useEffect} from "react";
import {Controller, useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import dayjs from "dayjs";
import {selectLang, selectNumberFormat} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import SelectCompanyCatalog from "../../../IKPU/SelectCompanyCatalog";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";


const ItemRow = ({allowDelete, onDelete, arrayFieldName, index, item}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const {register, formState: {errors}, watch, setValue} = useFormContext();
    const rootName = `${arrayFieldName}.${index}`;
    const numberFormat = useSelector(selectNumberFormat);



    const quantity = +watch(`${rootName}.quantity`);
    const price = +watch(`${rootName}.price`);
    let nds = +watch(`${rootName}.nds`);
    const privilege = watch(`${rootName}.benefit`)
    const orderDate = watch('info.date')

    const total = `${rootName}.total`;
    const ndsValue = `${rootName}.ndsValue`;
    const totalWithNds = `${rootName}.totalWithNds`;

    useEffect(() => {
        // setValue(total, numeral.formats[numberFormat].format(price * quantity));
        setValue(total, numeral(price * quantity).format(numberFormat));
        if (nds) {
            const calculatedNds = price * quantity * nds / 100;
            const calculatedTotalWithNds = price * quantity + calculatedNds;

            // setValue(ndsValue, numeral.formats[numberFormat].format(calculatedNds));
            setValue(ndsValue, numeral(calculatedNds).format(numberFormat));
            // setValue(totalWithNds, numeral.formats[numberFormat].format(calculatedTotalWithNds));
            setValue(totalWithNds, numeral(calculatedTotalWithNds).format(numberFormat));
        } else {
            const calculatedTotalWithNds = price * quantity;

            setValue(ndsValue, '0');
            setValue(totalWithNds, numeral(calculatedTotalWithNds).format(numberFormat));
        }
    }, [quantity, price, nds])


    let itemErrors = {};
    if (errors && errors[arrayFieldName] && errors[arrayFieldName][index])
        itemErrors = errors[arrayFieldName][index];


    const ndsOptions = [
        {value: null, label: 'No'},
        {value: 0, label: '0'},
        {value: 12, label: '12'},
        {value: 15, label: '15'}
    ];

    const onDeleteClicked = () => {
        onDelete(item);
    };

    const onChangeCatalog = (catalog, privilege) => {
        setValue(`${rootName}.catalogClassName`, catalog ? catalog.name : null);
        if(privilege) {
            const isCurrentYear = orderDate && dayjs().year() === orderDate.getFullYear();
            setValue(`${rootName}.benefit`, isCurrentYear ? privilege.newLgotaId : privilege.lgotaId);
        } else {
            setValue(`${rootName}.benefit`, null);
        }
    }


    return (
        <tr>
            <td className="p-1">
                <Form.Control
                    style={{height: "37px"}}
                    placeholder={t(lang, "edi.common.items.product.placeholder")}
                    className="p-1"
                    {...register(`${rootName}.name`, {
                        required: t(lang, "edi.common.forms.validations.is_required"),
                    })}
                    isInvalid={itemErrors?.name} isValid={Object.keys(errors).length > 0 && !itemErrors?.name}/>
                <Form.Control.Feedback type="invalid">
                    {itemErrors?.name?.message}
                </Form.Control.Feedback>
            </td>
            <td className="p-1">
                <Form.Control
                    placeholder={t(lang, "edi.common.items.product.customer_id.placeholder")}
                    isValid={Object.keys(errors).length > 0}
                    {...register(`${rootName}.customerSystemId`)}
                />
            </td>
            <td className="p-1">
                <Form.Control
                    placeholder={t(lang, "edi.common.items.product.customer_id.placeholder")}
                    isValid={Object.keys(errors).length > 0}
                    {...register(`${rootName}.customerShipmentId`)}
                />
            </td>
            <td className="p-1">
                <Form.Control
                    placeholder={t(lang, "edi.common.items.product.barcode.placeholder")}
                    isValid={Object.keys(errors).length > 0}
                    {...register(`${rootName}.barcode`)}
                />
            </td>
            <td className="p-1">
                <Form.Control
                    placeholder={t(lang, "edi.common.items.product.product_code.placeholder")}
                    isValid={Object.keys(errors).length > 0}
                    {...register(`${rootName}.productCode`)}
                />
            </td>
            <td className="p-1">
                <Controller name={`${rootName}.catalogClassCode`}
                            render={({field}) => (
                                <SelectCompanyCatalog size="sm"
                                                      variant="outline-primary"
                                                      textButton={field.value || t(lang, "edi.common.button.select")}
                                                      defaultCatalogCode={field.value}
                                                      defaultLgota={privilege}
                                                      onChange={(catalog, privilege) => {
                                                          field.onChange(catalog ? catalog.class_code : null);
                                                          onChangeCatalog(catalog, privilege);
                                                      }}
                                />
                            )}
                />
            </td>
            <td className="p-1">
                <Form.Control
                    style={{height: "37px"}}
                    placeholder={t(lang, "edi.common.items.product.measurement.placeholder")}
                    className="p-1"
                    isInvalid={itemErrors?.measurement}
                    isValid={Object.keys(errors).length > 0 && !itemErrors?.measurement}
                    {...register(`${rootName}.measurement`, {
                        required: t(lang, "edi.common.forms.validations.is_required")
                    })}
                />
                <Form.Control.Feedback type="invalid">
                    {itemErrors?.measurement?.message}
                </Form.Control.Feedback>
            </td>
            <td className="p-1">
                <Form.Control
                    style={{height: "37px"}}
                    placeholder={t(lang, "edi.common.items.product.quantity.placeholder")}
                    className="p-1"
                    isInvalid={itemErrors?.quantity}
                    isValid={Object.keys(errors).length > 0 && !itemErrors?.quantity}
                    {...register(`${rootName}.quantity`, {
                        required: t(lang, "edi.common.forms.validations.is_required"),
                        pattern: {
                            value: /^[1-9](\d*)((.)\d+)?$/,
                            message: t(lang, "edi.common.forms.validations.invalid_format")
                        },
                    })}
                />
                <Form.Control.Feedback type="invalid">
                    {itemErrors?.quantity?.message}
                </Form.Control.Feedback>
            </td>
            <td className="p-1">
                <Form.Control
                    style={{height: "37px"}}
                    placeholder={t(lang, "edi.common.items.product.price.placeholder")}
                    className='form-control p-1'
                    isInvalid={itemErrors?.price}
                    isValid={Object.keys(errors).length > 0 && !itemErrors?.price}
                    {...register(`${rootName}.price`, {
                        required: t(lang, "edi.common.forms.validations.is_required"),
                        pattern: {
                            value: /^[1-9](\d*)((.)\d+)?$/,
                            message: t(lang, "edi.common.forms.validations.invalid_format")
                        },
                    })}
                />
                <Form.Control.Feedback type="invalid">
                    {itemErrors?.price?.message}
                </Form.Control.Feedback>
            </td>
            <td className="p-1">
                <Form.Control
                    style={{height: "37px"}}
                    {...register(`${rootName}.total`)}
                    readOnly={true}
                    className="p-1"
                />
            </td>
            <td className="p-1">
                <Form.Select
                    style={{height: "37px"}}
                    className="p-1"
                    {...register(`${rootName}.nds`)}>
                    {ndsOptions.map(ndsOption => {
                        return <option value={ndsOption.value} key={ndsOption.value}>{ndsOption.label}</option>
                    })}
                </Form.Select>
            </td>
            <td className="p-1">
                <Form.Control
                    style={{height: "37px"}}
                    {...register(`${rootName}.ndsValue`)}
                    readOnly={true}
                    className="p-1"
                />
            </td>
            <td className="p-1">
                <Form.Control
                    style={{height: "37px"}}
                    {...register(`${rootName}.totalWithNds`)}
                    readOnly={true}
                    className="p-1"
                />
            </td>
            <td className="text-end p-1">
                <ActionButton
                    icon={faTrashAlt}
                    title="Delete"
                    variant="action"
                    className="p-1"
                    onClick={onDeleteClicked}
                              disabled={!allowDelete}/>
            </td>
        </tr>
    );
};

export default ItemRow;
