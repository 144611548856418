import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import EventBus from "../../../eventbus/EventBus";
import {SEND_FREE_FORM_DOCUMENT_SUCCEED} from "../../../eventbus/romaing/free-form/roamingFreeFormDocumentEvents";
import SendFreeFormDocumentForm from "../../../components/roaming/free-form/SendFreeFormDocumentForm";

const SendFreeFormDocument = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const onSendFreeFormDocumentSucceedHandler = EventBus.on(SEND_FREE_FORM_DOCUMENT_SUCCEED, freeFormDocument => {
            navigate(`/roaming/free-form/${freeFormDocument.id}`);
        });

        return () => {
            EventBus.remove(SEND_FREE_FORM_DOCUMENT_SUCCEED, onSendFreeFormDocumentSucceedHandler);
        }
    }, [])
    return (
      <SendFreeFormDocumentForm />
    );
};

export default SendFreeFormDocument;
