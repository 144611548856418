import {createSlice} from "@reduxjs/toolkit";

import shipmentService from "../../../services/edi/shipment/shipmentService";
import EventBus from "../../../../eventbus/EventBus";
import {
    CUSTOMER_APPROVE_SHIPMENT_FAILED,
    CUSTOMER_APPROVE_SHIPMENT_REQUESTED,
    CUSTOMER_APPROVE_SHIPMENT_SUCCESS,
    CUSTOMER_DECLINE_SHIPMENT_FAILED,
    CUSTOMER_DECLINE_SHIPMENT_REQUESTED,
    CUSTOMER_DECLINE_SHIPMENT_SUCCESS,
    CUSTOMER_EDITED_SHIPMENT,
    DELETE_SHIPMENT_FAILED,
    DELETE_SHIPMENT_REQUESTED,
    DELETE_SHIPMENT_SUCCESS,
    EDIT_SHIPMENT_FAILED,
    EDIT_SHIPMENT_REQUESTED,
    EDIT_SHIPMENT_SUCCEED,
    EXECUTOR_APPROVE_SHIPMENT_FAILED,
    EXECUTOR_APPROVE_SHIPMENT_REQUESTED,
    EXECUTOR_APPROVE_SHIPMENT_SUCCESS,
    REGISTER_SHIPMENT_FAILED,
    REGISTER_SHIPMENT_REQUESTED,
    REGISTER_SHIPMENT_SUCCEED
} from "../../../../eventbus/shipmentEvents";
export const STORAGE_SHIPMENT = "ShipmentsDataTableSettings"

const initialState = {
    shipments: [],
    countShipments: 0,
    cars: [],
    drivers: [],
    carSerials: [],
    filterOptions: {page: 1, limit: 10},
    shipmentsDataTableSettings: {}
}
const shipmentReducer = createSlice({
    name: "shipment",
    initialState,
    reducers:{
        updateShipments: (state, action) => {
            state.shipments = action.payload
        },
        updateCountShipments: (state, action) => {
            state.countShipments = action.payload
        },
        updateFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },

        loadProviders: (state) => {
            const storage = JSON.parse(localStorage.getItem('shipmentProvidersInfo'));

            if (storage?.['drivers']) {
                state.drivers = storage['drivers'];
            }

            if (storage?.['cars']) {
                state.cars = storage['cars'];
            }

            if (storage?.['carSerials']) {
                state.carSerials = storage['carSerials'];
            }
        },

        saveProviders: (state) => {
            const data = {
                cars: state.cars,
                drivers: state.drivers,
                carSerials: state.carSerials,
            };
            localStorage.setItem('shipmentProvidersInfo', JSON.stringify(data))
        },

        loadDataTableColumn: (state) => {
            let storageColumn = JSON.parse(localStorage.getItem(STORAGE_SHIPMENT))
            if(!storageColumn) {
                storageColumn = {
                    index: true,
                    number: true,
                    date: true,
                    total_sum: true,
                    branch: true,
                    invoice: true,
                    contractor: true
                };
                localStorage.setItem(STORAGE_SHIPMENT, JSON.stringify(storageColumn))
            }
            state.shipmentsDataTableSettings = storageColumn
        },

        changeDataTableColumn: (state, action) => {
            state.shipmentsDataTableSettings = action.payload
            localStorage.setItem(STORAGE_SHIPMENT, JSON.stringify(state.shipmentsDataTableSettings))
        },
    }
})

export const registerShipmentAsync = (payload) => {
    EventBus.dispatch(REGISTER_SHIPMENT_REQUESTED);

    return new Promise((resolve, reject) => {
        shipmentService.registerShipment(payload)
            .then(response => {
                EventBus.dispatch(REGISTER_SHIPMENT_SUCCEED, response.data)
                resolve(response.data);
            }).catch((e) => {
            EventBus.dispatch(REGISTER_SHIPMENT_FAILED)
            reject(e)
        })
    })
}

export const printShipmentAsync = (id) => {
    return new Promise((resolve, reject) => {
        shipmentService.printShipment(id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export const editShipmentAsync = (id,payload) => {
    EventBus.dispatch(EDIT_SHIPMENT_REQUESTED);

    return new Promise((resolve, reject) => {
        shipmentService.editShipment(id, payload).then(response => {
            EventBus.dispatch(EDIT_SHIPMENT_SUCCEED, response.data);
            resolve(response);
        }).catch(error => {
            EventBus.dispatch(EDIT_SHIPMENT_FAILED)
            reject(error);
        });
    })
}

export const shipmentCustomerEditAsync = (id,payload) => {
    return new Promise((resolve, reject) => {
        shipmentService.shipmentCustomerEdit(id, payload).then(response => {
            EventBus.dispatch(CUSTOMER_EDITED_SHIPMENT, response.data);
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    })
}

export const shipmentDeleteAsync = ({id, reason, comment}) => {
    EventBus.dispatch(DELETE_SHIPMENT_REQUESTED, {id, reason, comment});

    return new Promise((resolve, reject) => {
        shipmentService.deleteShipment({id, reason, comment})
            .then((response) => {
                EventBus.dispatch(DELETE_SHIPMENT_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(DELETE_SHIPMENT_FAILED, error.message);
                reject(error)
            })
    });
};

export const loadShipments = ({page, limit, ...filters}) => (dispatch) => {

    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    shipmentService.getShipments(params)
        .then(response => {
            dispatch(shipmentReducer.actions.updateShipments(response.data))
        })
        .catch(error => console.log(error))
}

export const loadShipmentAsync = (id) => {
    return new Promise((resolve, reject) => {
        shipmentService.getShipment(id)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    })
}

export const customerShipmentApproveAsync = ({id, comment}) => {
    EventBus.dispatch(CUSTOMER_APPROVE_SHIPMENT_REQUESTED, {id, comment})

    return new Promise((resolve, reject) => {
        shipmentService.customerShipmentApprove({id, comment})
            .then(response => {
                EventBus.dispatch(CUSTOMER_APPROVE_SHIPMENT_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(CUSTOMER_APPROVE_SHIPMENT_FAILED, error)
                reject(error)
            })
    });
}

export const loadCountShipments = (params) => (dispatch) => {
    let {limit, page, ...others} = params
    shipmentService.getCountShipments(others)
        .then(response => {
            dispatch(shipmentReducer.actions.updateCountShipments(response.data))
        })
        .catch(error => console.log(error))
}

export const changeFilterOptionsAsync = (params) => (dispatch) => {
    return dispatch(shipmentReducer.actions.updateFilterOptions(params))
}

export const executorApproveShipmentAsync = ({id, comment}) => {
    EventBus.dispatch(EXECUTOR_APPROVE_SHIPMENT_REQUESTED, {id, comment});

    return new Promise((resolve, reject) => {
        shipmentService.executorApproveShipment({id, comment})
            .then((response) => {
                EventBus.dispatch(EXECUTOR_APPROVE_SHIPMENT_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(EXECUTOR_APPROVE_SHIPMENT_FAILED, error);
                reject(error)
            });
    });
};

export const customerDeclineShipmentAsync = ({id, reason, comment}) => {
    EventBus.dispatch(CUSTOMER_DECLINE_SHIPMENT_REQUESTED, {id, reason});

    return new Promise((resolve, reject) => {
        shipmentService.customerDeclineShipment({id, reason, comment})
            .then((response) => {
                EventBus.dispatch(CUSTOMER_DECLINE_SHIPMENT_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(CUSTOMER_DECLINE_SHIPMENT_FAILED, error);
                reject(error);
            })
    });
};

export const uploadShipmentToExcelAsync = ({uri, id}) => {
    return new Promise((resolve, reject) => {
        shipmentService.uploadShipmentToExcel({uri, id})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
};
export const uploadShipmentsToExcelAsync = ({uri, page, limit, ...filters}) => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};

    return new Promise((resolve, reject) => {
        shipmentService.uploadShipmentsToExcel({uri: uri, params: params})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
};
export const uploadShipmentsWithItemsToExcelAsync = ({uri, page, limit, ...filters}) => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};

    return new Promise((resolve, reject) => {
        shipmentService.uploadShipmentsWithItemsToExcel({uri: uri, params: params})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
};

export const shipmentLoadProviders = shipmentReducer.actions.loadProviders;
export const shipmentSaveProviders = shipmentReducer.actions.saveProviders;

export const selectShipments = (state) => state.shipment.shipments
export const selectCountShipments = (state) => state.shipment.countShipments
export const selectFilterOptions = (state) => state.shipment.filterOptions

export const selectShipmentCarOptions = (state) => state.shipment.cars;
export const selectShipmentDriverOptions = (state) => state.shipment.drivers;
export const selectShipmentCarSerialOptions = (state) => state.shipment.carSerials;
export const selectShipmentsDataTable = (state) => state.shipment.shipmentsDataTableSettings
export const {loadDataTableColumn, changeDataTableColumn} = shipmentReducer.actions

export default shipmentReducer.reducer
