import React, {useEffect, useState} from 'react';
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectCompanyCatalogs} from "../../../store/reducers/roaming/roamingReducer";
import {useNavigate, useParams} from "react-router-dom";
import {selectLang, updateShowInProgressAsync} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {selectAccountRolePermissions} from "../../../store/reducers/role/roleReducer";
import checkPermission from "../../../enum/permission/CheckPermission";
import {PermissionRoamingContractUpdate} from "../../../enum/permission/RoamingPermission";
import {loadDraftContract} from "../../../store/reducers/roaming/contracts/roamingContractReducer";
import EventBus from "../../../eventbus/EventBus";
import {DOCUMENT_SIGN_FAILED, DOCUMENT_SIGN_STARTED, DOCUMENT_SIGN_SUCCESS} from "../../../eventbus/romaing/signEvents";
import toast from "react-hot-toast";
import {getOrganizationBillingInfoAsync} from "../../../store/reducers/billing/billingReducer";
import ContractForm from "../../../components/roaming/contracts/form/ContractForm";
import dayjs from "dayjs";

const EditContract = () => {
    const [draftContract, setDraftContract] = useState({})
    const activeOrganization = useSelector(selectActiveOrganization)
    const catalogs = useSelector(selectCompanyCatalogs);
    const navigate = useNavigate()
    const { id } = useParams();
    const dispatch = useDispatch()
    const lang = useSelector(selectLang)
    const t = Translate

    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingContractUpdate))) {
            navigate('/roaming/contracts/draft')
        }
    }, [permission])

    const load = () => {
        loadDraftContract(id)
            .then(response => {
                let contentContract = JSON.parse(response.data.content)
                let contract = {
                    contractId: contentContract.ContractId,
                    hasVat: contentContract.HasVat,
                    owner: {
                        tin: contentContract.Owner.Tin,
                        name: contentContract.Owner.Name,
                        address: contentContract.Owner.Address,
                        workPhone: contentContract.Owner.WorkPhone,
                        mobile: contentContract.Owner.Mobile,
                        fax: contentContract.Owner.Fax,
                        oked: contentContract.Owner.Oked,
                        account: contentContract.Owner.Account,
                        bankId: contentContract.Owner.BankId,
                        fizTin: contentContract.Owner.FizTin,
                        fio: contentContract.Owner.Fio,
                        branchCode: contentContract.Owner.BranchCode,
                        branchName: contentContract.Owner.BranchName
                    },
                    clients: contentContract.Clients.map((client) => ({
                        tin: client.Tin,
                        name: client.Name,
                        address: client.Address,
                        workPhone: client.WorkPhone,
                        mobile: client.Mobile,
                        fax: client.Fax,
                        oked: client.Oked,
                        account: client.Account,
                        bankId: client.BankId,
                        fizTin: client.FizTin,
                        fio: client.Fio,
                        branchCode: client.BranchCode,
                        branchName: client.BranchName
                    })),
                    contractDoc: {
                        contractName: contentContract.ContractDoc.ContractName,
                        contractNo: contentContract.ContractDoc.ContractNo,
                        contractDate: new Date(contentContract?.ContractDoc?.ContractDate).getTime() ? dayjs(contentContract.ContractDoc.ContractDate).toDate() : null,
                        contractExpireDate: new Date(contentContract?.ContractDoc?.ContractExpireDate).getTime() ? dayjs(contentContract.ContractDoc.ContractExpireDate).toDate() : null,
                        contractPlace: contentContract.ContractDoc.ContractPlace
                    },
                    products: contentContract.Products.map((product) => {
                        const catalog = catalogs?.find(c => c?.class_code === product?.CatalogCode)
                        const packages = catalog?.package_names?.find(p => p?.code === +product?.PackageCode)

                        return {
                            ordNo: product.OrdNo,
                            catalogCode: product.CatalogCode,
                            catalogName: product.CatalogName,
                            barCode: product.BarCode,
                            name: product.Name,
                            measureId: `${product.MeasureId}`,
                            packageCode: +product?.PackageCode,
                            packageName: product?.PackageName,
                            catalog: catalog,
                            packages: packages,
                            count: product.Count,
                            summa: product.Summa,
                            deliverySum: product.DeliverySum,
                            vatRate: product.VatRate,
                            vatSum: product.VatSum,
                            deliverySumWithVat: product.DeliverySumWithVat,
                            withoutVat: product.WithoutVat
                        }
                    }),
                    parts: contentContract.Parts.map((part) => ({
                        ordNo: part.OrdNo,
                        title: part.Title,
                        body: part.Body
                    }))
                }
                setDraftContract(contract)
            })
            .catch(error => {
                console.log(error);
                navigate('/roaming/verification-acts/inbox')
            })
    }

    useEffect(() => {
        load()
    }, [id, activeOrganization?.inn])

    useEffect(() => {
        const signStartedHandler = EventBus.on(DOCUMENT_SIGN_STARTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })
        const signSuccessHandler = EventBus.on(DOCUMENT_SIGN_SUCCESS, () => {
            toast.success(t(lang, "edi.document.sign.notification.success"))
            dispatch(getOrganizationBillingInfoAsync());
            dispatch(updateShowInProgressAsync(false))
        });
        const signFailedHandler = EventBus.on(DOCUMENT_SIGN_FAILED, (data) => {
            toast.error(t(lang, data.message))
            dispatch(updateShowInProgressAsync(false))
        });

        return () => {
            EventBus.remove(DOCUMENT_SIGN_STARTED, signStartedHandler)
            EventBus.remove(DOCUMENT_SIGN_SUCCESS, signSuccessHandler)
            EventBus.remove(DOCUMENT_SIGN_FAILED, signFailedHandler)
        }
    }, [])

    return (
        <ContractForm contract={draftContract} />
    );
};

export default EditContract;
