export const TYPE_ORDER = 1;
export const TYPE_ORDERS = 2;
export const TYPE_ORDERS_WITH_ITEMS = 3;
export const TYPE_SHIPMENT = 4;
export const TYPE_SHIPMENTS = 5;
export const TYPE_SHIPMENTS_WITH_ITEMS = 6;
export const TYPE_INVOICE = 7;
export const TYPE_INVOICES = 8;
export const TYPE_INVOICES_WITH_ITEMS = 9;
export const TYPE_RETURN_ORDER = 10;
export const TYPE_RETURN_ORDERS = 11;
export const TYPE_RETURN_ORDERS_WITH_ITEMS = 12;
export const TYPE_RETURN_SHIPMENT = 13;
export const TYPE_RETURN_SHIPMENTS = 14;
export const TYPE_RETURN_SHIPMENTS_WITH_ITEMS = 15;
export const TYPE_RETURN_INVOICE = 16;
export const TYPE_RETURN_INVOICES = 17;
export const TYPE_RETURN_INVOICES_WITH_ITEMS = 18;

export const TYPE_ROAMING_INVOICES = 19;
export const TYPE_ROAMING_INVOICES_WITH_ITEMS = 20;

export const TYPE_ITEMS = 21;

export const GetTemplateTypeText = type => {
    switch (type) {
        case TYPE_ORDER:
            return 'main.templates.type.order';
        case TYPE_ORDERS:
            return 'main.templates.type.orders';
        case TYPE_ORDERS_WITH_ITEMS:
            return 'main.templates.type.orders_with_items';
        case TYPE_SHIPMENT:
            return 'main.templates.type.shipment';
        case TYPE_SHIPMENTS:
            return 'main.templates.type.shipments';
        case TYPE_SHIPMENTS_WITH_ITEMS:
            return 'main.templates.type.shipments_with_items';
        case TYPE_INVOICE:
            return 'main.templates.type.invoice';
        case TYPE_INVOICES:
            return 'main.templates.type.invoices';
        case TYPE_INVOICES_WITH_ITEMS:
            return 'main.templates.type.invoices_with_items';
        case TYPE_RETURN_ORDER:
            return 'main.templates.type.return_order';
        case TYPE_RETURN_ORDERS:
            return 'main.templates.type.return_orders';
        case TYPE_RETURN_ORDERS_WITH_ITEMS:
            return 'main.templates.type.return_orders_with_items';
        case TYPE_RETURN_SHIPMENT:
            return 'main.templates.type.return_shipment';
        case TYPE_RETURN_SHIPMENTS:
            return 'main.templates.type.return_shipments';
        case TYPE_RETURN_SHIPMENTS_WITH_ITEMS:
            return 'main.templates.type.return_shipments_with_items';
        case TYPE_RETURN_INVOICE:
            return 'main.templates.type.return_invoice';
        case TYPE_RETURN_INVOICES:
            return 'main.templates.type.return_invoices';
        case TYPE_RETURN_INVOICES_WITH_ITEMS:
            return 'main.templates.type.return_invoices_with_items';
        case TYPE_ROAMING_INVOICES:
            return 'main.templates.type.roaming_invoices';
        case TYPE_ROAMING_INVOICES_WITH_ITEMS:
            return 'main.templates.type.roaming_invoices_with_items';
        case TYPE_ITEMS:
            return 'main.templates.type.items';
    }
};

const TemplateType = (id, name) => {
    return {
        id,
        name
    }
}

export const GetTemplateTypes = [
    TemplateType(TYPE_ORDER, GetTemplateTypeText(TYPE_ORDER)),
    TemplateType(TYPE_ORDERS, GetTemplateTypeText(TYPE_ORDERS)),
    TemplateType(TYPE_ORDERS_WITH_ITEMS, GetTemplateTypeText(TYPE_ORDERS_WITH_ITEMS)),
    TemplateType(TYPE_SHIPMENT, GetTemplateTypeText(TYPE_SHIPMENT)),
    TemplateType(TYPE_SHIPMENTS, GetTemplateTypeText(TYPE_SHIPMENTS)),
    TemplateType(TYPE_SHIPMENTS_WITH_ITEMS, GetTemplateTypeText(TYPE_SHIPMENTS_WITH_ITEMS)),
    TemplateType(TYPE_INVOICE, GetTemplateTypeText(TYPE_INVOICE)),
    TemplateType(TYPE_INVOICES, GetTemplateTypeText(TYPE_INVOICES)),
    TemplateType(TYPE_INVOICES_WITH_ITEMS, GetTemplateTypeText(TYPE_INVOICES_WITH_ITEMS)),
    TemplateType(TYPE_RETURN_ORDER, GetTemplateTypeText(TYPE_RETURN_ORDER)),
    TemplateType(TYPE_RETURN_ORDERS, GetTemplateTypeText(TYPE_RETURN_ORDERS)),
    TemplateType(TYPE_RETURN_ORDERS_WITH_ITEMS, GetTemplateTypeText(TYPE_RETURN_ORDERS_WITH_ITEMS)),
    TemplateType(TYPE_RETURN_SHIPMENT, GetTemplateTypeText(TYPE_RETURN_SHIPMENT)),
    TemplateType(TYPE_RETURN_SHIPMENTS, GetTemplateTypeText(TYPE_RETURN_SHIPMENTS)),
    TemplateType(TYPE_RETURN_SHIPMENTS_WITH_ITEMS, GetTemplateTypeText(TYPE_RETURN_SHIPMENTS_WITH_ITEMS)),
    TemplateType(TYPE_RETURN_INVOICE, GetTemplateTypeText(TYPE_RETURN_INVOICE)),
    TemplateType(TYPE_RETURN_INVOICES, GetTemplateTypeText(TYPE_RETURN_INVOICES)),
    TemplateType(TYPE_RETURN_INVOICES_WITH_ITEMS, GetTemplateTypeText(TYPE_RETURN_INVOICES_WITH_ITEMS)),
    TemplateType(TYPE_ROAMING_INVOICES, GetTemplateTypeText(TYPE_ROAMING_INVOICES)),
    TemplateType(TYPE_ROAMING_INVOICES_WITH_ITEMS, GetTemplateTypeText(TYPE_ROAMING_INVOICES_WITH_ITEMS)),
    TemplateType(TYPE_ITEMS, GetTemplateTypeText(TYPE_ITEMS)),
];
