import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectCertificates} from "../../store/reducers/eimzo/eimzoReducer";
import {selectDateDayJSFormat} from "../../store/reducers/main/main";
import {CertificatePfx} from "../../plugins/eimzo";
import {Card} from "react-bootstrap";
import Flex from "../common/Flex";
import IconEImzo from "../../assets/icon/IconEImzo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import IconEImzoDanger from "../../assets/icon/IconEImzoDanger";
import classNames from "classnames";
import dayjs from "dayjs";

const CardSelectCertificate = ({onClick}) => {
    const certificatesData = useSelector(selectCertificates);
    const [certificate, setCertificates] = useState([]);
    const dateDayJSFormat = useSelector(selectDateDayJSFormat)
    const today = new Date();

    useEffect(() => {
        setCertificates(certificatesData.map(certificate => {
            const pfxCertificate = new CertificatePfx(certificate);
            return {
                innNumber: pfxCertificate.innNumber,
                pinfl: pfxCertificate.pinfl,
                companyName: pfxCertificate.companyName,
                issuedPerson: pfxCertificate.issuedPerson,
                validEndDate: pfxCertificate.validEndDate,
                validFromDate: pfxCertificate.validFromDate,
                value: `${certificate.disk}${certificate.alias}`,
                isDisabled: pfxCertificate.validEndDate.getTime() <= today.getTime(),
                isExpired: pfxCertificate.validEndDate.getTime() <= today.getTime()
            };
        }))
    }, [certificatesData]);

    const click = option => {
        const certificate = certificatesData.find(certificate => `${certificate.disk}${certificate.alias}` === option?.value) || null;
        onClick(certificate);
    }

    return (
        <div className={"scrollbar"}>
            {certificate?.map(i =>
                <>
                    <Card key={i.innNumber}
                          onClick={() => !i.isExpired && click(i)}
                          className={classNames("rounded-3 p-2 mt-2", {
                              "border-danger": i.isExpired,
                              "cursor-pointer": !i.isExpired
                          })}
                          style={i.isExpired ? {borderColor: "red", cursor: "no-drop"} : {}}
                    >
                        <Flex className={'justify-content-between align-items-center'}>
                            <Flex>
                                {i.isExpired ? <IconEImzoDanger/> : <IconEImzo/>}
                                <Flex className={"flex-column justify-content-center ms-2"}>
                                    {i.innNumber && <p className={"fs-10 second-text m-0"}>{i.innNumber}</p>}
                                    {i.companyName &&
                                        <h2 className={'fs-9 text-dark text-uppercase'}>{i.companyName}</h2>}
                                    {i.issuedPerson &&
                                        <p className={"fs-10 second-text m-0 text-uppercase"}>{i.issuedPerson}</p>}
                                </Flex>
                            </Flex>
                            <FontAwesomeIcon icon={faChevronRight}
                                             className={`me-2 ${i.isExpired ? "text-danger" : "text-secondary"}`}/>
                        </Flex>
                    </Card>
                    {i.isExpired && <p className={"text-danger m-0 fs-9 px-4"}>Срок ЕЦП ключа
                        истек: {dayjs(i.validEndDate).format(dateDayJSFormat)}</p>}
                </>
            )}
        </div>
    );
};

export default CardSelectCertificate;