import axios from 'axios'

class InvoiceService {
    invoiceRegister(payload) {
        return axios.post(`/invoice/executor/register`, payload)
    }

    printInvoice(id) {
        return axios.get(`/pdf/invoice/${id}`, {
            responseType: "blob"
        })
    }

    invoiceDelete({id, comment}) {
        return axios.post(`/invoice/executor/delete/${id}`, {
            comment: comment,
        })
    }

    getInvoices(params) {
        return axios.get('/invoice/get', {
            params: params
        })
    }

    getCount(params) {
        return axios.get('/invoice/count', {
            params: params
        })
    }

    getInvoice(id) {
        return axios.get(`/invoice/get/${id}`)
    }

    getSignTicket(id) {
        return axios.get(`/invoice/sign-ticket/${id}`)
    }

    uploadInvoiceToExcel({uri, id}) {
        return axios.get(`${uri}/${id}`,{
            responseType: "arraybuffer",
        })
    }

    uploadInvoicesToExcel({uri, params}) {
        return axios.get(uri, {
            params: params,
            responseType: "arraybuffer",
        })
    }

    uploadInvoicesWithItemsToExcel({uri, params}) {
        return axios.get(uri, {
            params: params,
            responseType: "arraybuffer",
        })
    }
}

const invoiceService = new InvoiceService();
export default  invoiceService;
