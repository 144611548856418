import React from 'react';
import {useNavigate} from "react-router-dom";
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectDateFormat, selectLang} from "../../../store/reducers/main/main";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import {Col, Row, Form, InputGroup, Button} from "react-bootstrap";
import {
    TypeAdditional,
    TypeAdditionalAmendOfExpenses,
    TypeAdditionalWithoutPayment, TypeCorrective, TypeCorrectiveAmendOfExpenses,
    TypeCorrectiveWithoutPayment
} from "../../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";

const RoamingInvoiceFormOldInvoiceInfo = () => {
    const navigate = useNavigate();
    const t = Translate;
    const lang = useSelector(selectLang);
    const dateFormat = useSelector(selectDateFormat);
    const form = useFormContext();
    const facturaType = useWatch({name: `facturaType`, exact: true});

    const onSearchInvoice = () => {
        const id = form.getValues(`oldFacturaDoc.oldFacturaId`);
        navigate({search: `?invoice_id=${id}&type=${facturaType}`});
    };

    switch (facturaType) {
        case TypeAdditional:
        case TypeCorrective:
        case TypeCorrectiveAmendOfExpenses:
        case TypeAdditionalAmendOfExpenses:
        case TypeCorrectiveWithoutPayment:
        case TypeAdditionalWithoutPayment:
            return (
                <Row className="g-2">
                    <Col xs={12} md={4} lg={4}>
                        <Form.Group>
                            <Form.Label>
                                {t(lang, 'roaming.invoice.id')}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <InputGroup className={classNames({'is-invalid': form.formState.errors?.oldFacturaDoc?.oldFacturaId} )}>
                                <Form.Control name={`oldFacturaDoc.oldFacturaId`}
                                              placeholder={t(lang, 'roaming.invoice.id.placeholder')}
                                              isInvalid={form.formState.errors?.oldFacturaDoc?.oldFacturaId}
                                              {...form.register(`oldFacturaDoc.oldFacturaId`, {
                                                  required: t(lang, 'items.common.validations.is_required'),
                                                  shouldUnregister: true
                                              })}
                                />
                                <Button onClick={onSearchInvoice}>{t(lang, 'roaming.invoice.find')}</Button>
                            </InputGroup>
                            <Form.Control.Feedback type="invalid">{form.formState.errors?.oldFacturaDoc?.oldFacturaId?.message}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Label>
                            {t(lang, 'roaming.invoice.old_invoice.number')}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control name={`oldFacturaDoc.oldFacturaNo`}
                                      placeholder={t(lang, 'roaming.invoice.old_invoice.number.placeholder')}
                                      isInvalid={form.formState.errors?.oldFacturaDoc?.oldFacturaNo}
                                      disabled
                                      {...form.register(`oldFacturaDoc.oldFacturaNo`, {
                                          required: t(lang, 'items.common.validations.is_required'),
                                          shouldUnregister: true
                                      })}
                        />
                        <Form.Control.Feedback type="invalid">{form.formState.errors?.oldFacturaDoc?.oldFacturaNo?.message}</Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Label>
                            {t(lang, 'roaming.invoice.old_invoice.date')}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <Controller name={`oldFacturaDoc.oldFacturaDate`}
                                    rules={{
                                        required: t(lang, 'items.common.validations.is_required'),
                                        shouldUnregister: true
                                    }}
                                    render={({field}) => (
                                        <DatePicker selected={field.value}
                                                    onChange={field.onChange}
                                                    placeholderText={t(lang, 'roaming.invoice.old_invoice.date.placeholder')}
                                                    dateFormat={dateFormat}
                                                    disabled
                                                    className={classNames('form-control', {
                                                        'is-invalid': form.formState.errors?.oldFacturaDoc?.oldFacturaDate,
                                                    })}
                                                    wrapperClassName={classNames({
                                                        'is-invalid': form.formState.errors?.oldFacturaDoc?.oldFacturaDate
                                                    })}
                                        />
                                    )}
                        />
                        <Form.Control.Feedback type="invalid">{form.formState.errors?.oldFacturaDoc?.oldFacturaDate?.message}</Form.Control.Feedback>
                    </Col>
                </Row>
            );
        default:
            return null;
    }
};

export default RoamingInvoiceFormOldInvoiceInfo;
