import React, {Fragment, useEffect} from 'react';
import {Controller, useFieldArray, useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectDateFormat, selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import IconButton from "../../../IconButton";
import {Dropdown, Form, Table} from "react-bootstrap";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import Cleave from "cleave.js/react";
import VerificationActContractDocument from "./VerificationActContractDocument";
import RevealDropdown, {RevealDropdownTrigger} from "../../../common/RevealDropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DatePickerComponent from "../../../common/DatePickerComponent";

const VerificationActContracts = ({index, contract, appendContract, removeContract, onChange}) => {
    const {register, getFieldState, setValue, control, getValues, watch} = useFormContext();
    const {fields: fieldsDocument, append: appendDocument, remove: removeDocument} = useFieldArray({
        control: control,
        name: `verificationActContracts.${index}.verificationActContractItems`
    })
    const {
        openBalance: watchOpenBalance,
        verificationActContractItems: watchFieldsDocument
    } = watch(`verificationActContracts.${index}`)
    const errors = getFieldState(`verificationActContracts.${index}`).error || []
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    const addFieldDocument = () => {
        appendDocument({
            ownerOperationDate: "",
            ownerOperationName: "",
            ownerDebit: 0.00,
            ownerCredit: 0.00,
            partnerOperationDate: "",
            partnerOperationName: "",
            partnerDebit: 0.00,
            partnerCredit: 0.00
        })
    }

    const removeFieldDocument = async index => {
        removeDocument(index)
    }
    const setPartnerOpenDebit = (sum) => {
        setValue(`verificationActContracts.${index}.openBalance.partnerDebit`, sum)
        onChangeSumHandler()
    }
    const setPartnerOpenCredit = (sum) => {
        setValue(`verificationActContracts.${index}.openBalance.partnerCredit`, sum)
        onChangeSumHandler()
    }

    const onChangeSumHandler = () => {
        let sumOpenDebit = watchFieldsDocument.reduce((total, debit) => total + +debit.ownerDebit, +watchOpenBalance.ownerDebit)
        let sumOpenCredit = watchFieldsDocument.reduce((total, credit) => total + +credit.ownerCredit, +watchOpenBalance.ownerCredit)
        let sumCloseDebit = 0
        let sumCloseCredit = 0
        if (sumOpenDebit > sumOpenCredit) {
            sumCloseDebit = sumOpenDebit - sumOpenCredit
        } else {
            sumCloseCredit = sumOpenCredit - sumOpenDebit
        }
        setValue(`verificationActContracts.${index}.totalBalance.ownerDebit`, sumOpenDebit)
        setValue(`verificationActContracts.${index}.totalBalance.ownerCredit`, sumOpenCredit)
        setValue(`verificationActContracts.${index}.totalBalance.partnerDebit`, sumOpenCredit)
        setValue(`verificationActContracts.${index}.totalBalance.partnerCredit`, sumOpenDebit)
        setValue(`verificationActContracts.${index}.closeBalance.ownerDebit`, sumCloseDebit)
        setValue(`verificationActContracts.${index}.closeBalance.ownerCredit`, sumCloseCredit)
        setValue(`verificationActContracts.${index}.closeBalance.partnerDebit`, sumCloseCredit)
        setValue(`verificationActContracts.${index}.closeBalance.partnerCredit`, sumCloseDebit)
        onChange()
    }

    useEffect(() => {
        onChangeSumHandler()
    }, [watchFieldsDocument]);

    return (
        <Fragment>
                <tbody>
                <tr className="position-relative py-2 fs-9 second-text fw-semi-bold">
                    <td style={{ width: "12.5%" }} className="text-center p-1 align-middle">
                        <Controller
                            control={control}
                            name={`verificationActContracts.${index}.contractDate`}
                            rules={{
                                required: "edi.common.forms.validations.is_required"
                            }}
                            render={({field}) => (
                                <DatePickerComponent
                                    field={field}
                                    dateFormat={dateFormat}
                                    wrapperClassName={errors?.contractDate?.message && "is-invalid"}
                                    className={`${errors?.contractDate?.message && "is-invalid"} form-control p-2`}
                                    placeholderText={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.contract.date")}
                                />
                            )}
                        />
                    </td>
                    <td style={{ width: "12.5%" }} className="text-center p-1 align-middle">
                        <Form.Control
                            {...register(`verificationActContracts.${index}.contractNo`)}
                            placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.contract.number")}
                            className="p-2"
                        />
                    </td>
                    <td style={{ width: "12.5%" }} className="text-center p-1 align-middle">
                        <Form.Control
                            disabled={true}
                            className="p-2"
                        />
                    </td>
                    <td style={{ width: "12.5%" }} className="text-center p-1 align-middle">
                        <Form.Control
                            disabled={true}

                            className="p-2"
                        />
                    </td>
                    <td style={{ width: "12.5%" }} className="text-center p-1 align-middle">
                        <Form.Control
                            disabled={true}

                            className="p-2"
                        />
                    </td>
                    <td style={{ width: "12.5%" }} className="text-center p-1 align-middle">
                        <Form.Control
                            disabled={true}
                            className="p-2"
                        />
                    </td>
                    <td style={{ width: "12.5%" }} className="text-center p-1 align-middle">
                        <Form.Control
                            disabled={true}
                            className="p-2"
                        />
                    </td>
                    <td style={{ width: "12.5%" }} className="text-center p-1 align-middle">
                        <Form.Control
                            disabled={true}
                            className="p-2"
                        />
                    </td>
                    <td>
                        <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm" className={'p-0'}>
                            <RevealDropdown dropdownMenuClassName={'p-0'}>
                                <Dropdown.Item className="text-primary fs-8" onClick={appendContract}>
                                    <FontAwesomeIcon className="me-1" icon={faPlus}/>
                                    <span>{t(lang, "roaming.common.add")}</span>
                                </Dropdown.Item>
                                {getValues('verificationActContracts').length > 1 && <Dropdown.Item className="text-danger fs-8" onClick={() => removeContract(index)}>
                                    <FontAwesomeIcon className="me-1" icon={faTrash}/>
                                    <span>{t(lang, "roaming.invoice.delete")}</span>
                                </Dropdown.Item>}
                            </RevealDropdown>
                        </RevealDropdownTrigger>
                        {/*<IconButton

                            variant="phoenix-primary"
                            icon={faPlus}
                            size="sm"
                            className="mx-1 px-2"
                        />
                        <IconButton

                            disabled={}
                            variant="phoenix-danger"
                            icon={faTrash}
                            size="sm"
                            className="mx-1 px-2"
                        />*/}
                    </td>
                </tr>
                <tr>
                    <td className="text-center align-middle"
                        colSpan="2">
                        <Form.Control disabled={true} defaultValue={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.open_balance.title")} />
                    </td>
                    <td className="text-center align-middle p-1">
                        <Controller
                            name={`verificationActContracts.${index}.openBalance.ownerDebit`}
                            control={control}
                            rules={{
                                required: "edi.common.forms.validations.is_required"
                            }}
                            render={({field}) => (
                                <Cleave
                                    onChange={({target: {rawValue}}) => {
                                        let value = +(parseFloat(rawValue)?.toFixed(2)) || 0
                                        field.onChange(value)
                                        setPartnerOpenCredit(value)
                                    }}
                                    options={{
                                        numeral: true,
                                        delimiter: ' ',
                                        numeralDecimalScale: 2
                                    }}
                                    value={0}
                                    placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.owner.debit")}
                                    className={`${errors[index]?.count && 'is-invalid'} p-2 form-control`}
                                />
                            )}
                        />
                    </td>
                    <td className="text-center align-middle p-1">
                        <Controller
                            name={`verificationActContracts.${index}.openBalance.ownerCredit`}
                            control={control}
                            rules={{
                                required: "edi.common.forms.validations.is_required"
                            }}
                            render={({field}) => (
                                <Cleave
                                    onChange={({target: {rawValue}}) => {
                                        let value = +(parseFloat(rawValue)?.toFixed(2)) || 0
                                        field.onChange(value)
                                        setPartnerOpenDebit(value)
                                    }}
                                    options={{
                                        numeral: true,
                                        delimiter: ' ',
                                        numeralDecimalScale: 2
                                    }}
                                    value={0}
                                    placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.owner.credit")}
                                    className={`${errors[index]?.count && 'is-invalid'} p-2 form-control`}
                                />
                            )}
                        />
                    </td>
                    <td className="text-center align-middle"
                        colSpan="2">
                        <Form.Control disabled={true} defaultValue={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.open_balance.title")} />
                    </td>
                    <td className="text-center align-middle p-1">
                        <Controller
                            name={`verificationActContracts.${index}.openBalance.partnerDebit`}
                            control={control}
                            rules={{
                                required: "edi.common.forms.validations.is_required"
                            }}
                            render={({field}) => (
                                <Cleave
                                    {...field}
                                    options={{
                                        numeral: true,
                                        delimiter: ' ',
                                        numeralDecimalScale: 2
                                    }}
                                    placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.partner.debit")}
                                    className={`${errors[index]?.count && 'is-invalid'} p-2 form-control`}
                                    readOnly
                                />
                            )}
                        />
                    </td>
                    <td className="text-center align-middle p-1">
                        <Controller
                            name={`verificationActContracts.${index}.openBalance.partnerCredit`}
                            control={control}
                            rules={{
                                required: "edi.common.forms.validations.is_required"
                            }}
                            render={({field}) => (
                                <Cleave
                                    {...field}
                                    options={{
                                        numeral: true,
                                        delimiter: ' ',
                                        numeralDecimalScale: 2
                                    }}
                                    placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.partner.credit")}
                                    className={`${errors[index]?.count && 'is-invalid'} p-2 form-control`}
                                    readOnly
                                />
                            )}
                        />
                    </td>
                    <td className=""></td>
                </tr>
                {fieldsDocument.map((document, indexDoc) => (
                    <tr key={document.id}>
                        <VerificationActContractDocument indexContract={index} index={indexDoc}
                                                         appendDocument={addFieldDocument}
                                                         removeDocument={removeFieldDocument}
                                                         onChange={onChangeSumHandler}/>
                    </tr>
                ))}
                <tr>
                    <td className="text-center align-middle"
                        colSpan="2">
                        <Form.Control disabled={true} defaultValue={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.total_balance.title")} />
                    </td>
                    <td className="text-center align-middle p-1">
                        <Controller
                            name={`verificationActContracts.${index}.totalBalance.ownerDebit`}
                            control={control}
                            rules={{
                                required: "edi.common.forms.validations.is_required"
                            }}
                            render={({field}) => (
                                <Cleave
                                    {...field}
                                    options={{
                                        numeral: true,
                                        delimiter: ' ',
                                        numeralDecimalScale: 2
                                    }}
                                    placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.owner.debit")}
                                    className={`${errors[index]?.count && 'is-invalid'} p-2 form-control`}
                                    disabled
                                />
                            )}
                        />
                    </td>
                    <td className="text-center align-middle p-1">
                        <Controller
                            name={`verificationActContracts.${index}.totalBalance.ownerCredit`}
                            control={control}
                            rules={{
                                required: "edi.common.forms.validations.is_required"
                            }}
                            render={({field}) => (
                                <Cleave
                                    {...field}
                                    options={{
                                        numeral: true,
                                        delimiter: ' ',
                                        numeralDecimalScale: 2
                                    }}
                                    placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.owner.credit")}
                                    className={`${errors[index]?.count && 'is-invalid'} p-2 form-control`}
                                    disabled
                                />
                            )}
                        />
                    </td>
                    <td className="text-center align-middle"
                        colSpan="2">
                        <Form.Control disabled={true} defaultValue={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.total_balance.title")} />
                    </td>
                    <td className="text-center align-middle p-1">
                        <Controller
                            name={`verificationActContracts.${index}.totalBalance.partnerDebit`}
                            control={control}
                            rules={{
                                required: "edi.common.forms.validations.is_required"
                            }}
                            render={({field}) => (
                                <Cleave
                                    {...field}
                                    options={{
                                        numeral: true,
                                        delimiter: ' ',
                                        numeralDecimalScale: 2
                                    }}
                                    placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.partner.debit")}
                                    className={`${errors[index]?.count && 'is-invalid'} p-2 form-control`}
                                    disabled
                                />
                            )}
                        />
                    </td>
                    <td className="text-center align-middle p-1">
                        <Controller
                            name={`verificationActContracts.${index}.totalBalance.partnerCredit`}
                            control={control}
                            rules={{
                                required: "edi.common.forms.validations.is_required"
                            }}
                            render={({field}) => (
                                <Cleave
                                    {...field}
                                    options={{
                                        numeral: true,
                                        delimiter: ' ',
                                        numeralDecimalScale: 2
                                    }}
                                    placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.partner.credit")}
                                    className={`${errors[index]?.count && 'is-invalid'} p-2 form-control`}
                                    disabled
                                />
                            )}
                        />
                    </td>
                    <td className=""></td>
                </tr>
                <tr>
                    <td className="text-center align-middle"
                        colSpan="2">
                        <Form.Control disabled={true} defaultValue={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.close_balance_by_contract.title")} />
                    </td>
                    <td className="text-center align-middle p-1">
                        <Controller
                            name={`verificationActContracts.${index}.closeBalance.ownerDebit`}
                            control={control}
                            rules={{
                                required: "edi.common.forms.validations.is_required"
                            }}
                            render={({field}) => (
                                <Cleave
                                    {...field}
                                    options={{
                                        numeral: true,
                                        delimiter: ' ',
                                        numeralDecimalScale: 2
                                    }}
                                    placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.owner.debit")}
                                    className={`${errors[index]?.count && 'is-invalid'} p-2 form-control`}
                                    disabled
                                />
                            )}
                        />
                    </td>
                    <td className="text-center align-middle p-1">
                        <Controller
                            name={`verificationActContracts.${index}.closeBalance.ownerCredit`}
                            control={control}
                            rules={{
                                required: "edi.common.forms.validations.is_required"
                            }}
                            render={({field}) => (
                                <Cleave
                                    {...field}
                                    options={{
                                        numeral: true,
                                        delimiter: ' ',
                                        numeralDecimalScale: 2
                                    }}
                                    placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.owner.credit")}
                                    className={`${errors[index]?.count && 'is-invalid'} p-2 form-control`}
                                    disabled
                                />
                            )}
                        />
                    </td>
                    <td className="text-center align-middle"
                        colSpan="2">
                        <Form.Control disabled={true} defaultValue={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.close_balance_by_contract.title")} />
                    </td>
                    <td className="text-center align-middle p-1">
                        <Controller
                            name={`verificationActContracts.${index}.closeBalance.partnerDebit`}
                            control={control}
                            rules={{
                                required: "edi.common.forms.validations.is_required"
                            }}
                            render={({field}) => (
                                <Cleave
                                    {...field}
                                    options={{
                                        numeral: true,
                                        delimiter: ' ',
                                        numeralDecimalScale: 2
                                    }}
                                    placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.partner.debit")}
                                    className={`${errors[index]?.count && 'is-invalid'} p-2 form-control`}
                                    disabled
                                />
                            )}
                        />
                    </td>
                    <td className="text-center align-middle p-1">
                        <Controller
                            name={`verificationActContracts.${index}.closeBalance.partnerCredit`}
                            control={control}
                            rules={{
                                required: "edi.common.forms.validations.is_required"
                            }}
                            render={({field}) => (
                                <Cleave
                                    {...field}
                                    options={{
                                        numeral: true,
                                        delimiter: ' ',
                                        numeralDecimalScale: 2
                                    }}
                                    placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.partner.credit")}
                                    className={`${errors[index]?.count && 'is-invalid'} p-2 form-control`}
                                    disabled
                                />
                            )}
                        />
                    </td>
                    <td className=""></td>
                </tr>
                </tbody>
        </Fragment>
    );
};

export default VerificationActContracts;
