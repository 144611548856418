import {createSlice} from "@reduxjs/toolkit";
import ProtocolService from "../../services/protocol/protocolService";

export const protocolSlice = createSlice({
    name: 'protocol',
    initialState: {
        orderActions: [],
        returnOrderActions: [],
    },
    reducers: {
        setOrderActions: (state, action) => {
            const {histories} = action.payload;
            state.orderActions = histories;
        },
        setReturnOrderActions: (state, action) => {
            const {histories} = action.payload;
            state.returnOrderActions = histories;
        },
    }
});

export const getOrderProtocolInfoAsync = id => dispatch => {
    ProtocolService.getOrderProtocolInfo(id)
        .then(response => dispatch(protocolSlice.actions.setOrderActions(response.data)))
        .catch(error => console.log(error))
}

export const getReturnOrderProtocolInfoAsync = id => dispatch => {
    ProtocolService.getReturnOrderProtocolInfo(id)
        .then(response => dispatch(protocolSlice.actions.setReturnOrderActions(response.data)))
        .catch(error => console.log(error))
}

export const selectOrderProtocolActions = state => state.protocol.orderActions;
export const selectReturnOrderProtocolActions = state => state.protocol.returnOrderActions;

export default protocolSlice.reducer;
