import React from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {Translate} from "../../lang/lang";
import IconButton from "../IconButton";
import {Modal} from "react-bootstrap";
import {faRemove} from "@fortawesome/free-solid-svg-icons";

const ViewModalPage = ({show, children, onHide}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    return (
        <Modal show={show} onHide={onHide} fullscreen style={{padding: '15px'}}>
            <Modal.Body>
                <div className="d-flex justify-content-end mb-1">
                    <IconButton
                        className={'d-flex align-items-center'}
                        onClick={onHide}
                        variant={'outline-primary'}
                        icon={faRemove}>
                        {t(lang, 'main.common.modal.close')}
                    </IconButton>
                </div>

                {children}
            </Modal.Body>
        </Modal>
    );
};

export default ViewModalPage;
