import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Translate} from "../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import ReactSelect, {components} from "react-select";
import {Button, Modal} from "react-bootstrap";
import {roamingInvoiceTypes} from "../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import CreatableSelect from "react-select/creatable";
import CustomFloatingLabelSelectCreatable from "../floating-custom-select/CustomFloatingLabelSelectCreatable";

const SelectBankAccount = ({bankAccounts, onChange, label, defaultId}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [maxSize, setMaxSize] = useState(5);
    const [value, setValue] = useState(null);

    let options = bankAccounts;

    useEffect(() => {
        setValue(options.find(i => i.account === defaultId))
    }, [defaultId, options]);

    const onChangeHandler = (option) => {
        onChange(option);
        setValue(option)
    };

    const onInputChange = (option) => {
        options = bankAccounts.filter((o) => {
            return !!(o.account.includes(option) || o.bank_id.includes(option));
        })
    };

    const MenuList = ({children, ...props}) => {
        return (
            <components.MenuList {...props}>
                {Array.isArray(children) ? children.slice(0, maxSize) : children}
                {maxSize < options.length &&
                    <div className="text-primary text-center cursor-pointer"
                         onClick={() => setMaxSize(prevState => prevState + 5)}>
                        {t(lang, 'roaming.common.bank_account_selector.click_to_see_more')}
                    </div>
                }
            </components.MenuList>
        )
    };

    const formatOptionLabel = (option) => {
        return (
            <div>{option.account}</div>
        )
    };

    return (
        <div className={'select'}>
            <CustomFloatingLabelSelectCreatable
                options={options}
                label={label}
                components={{MenuList}}
                formatOptionLabel={(option) => option?.__isNew__ ? option.label : formatOptionLabel(option)}
                getOptionLabel={(option) => `${option.account} ${option.bank_id}`}
                getOptionValue={(option) => option.account}
                value={value}
                onChange={onChangeHandler}
                formatCreateLabel={(inputValue) => t(lang, 'edi.common.create_new', {value: inputValue})}
            />
        </div>
    );
};

export default SelectBankAccount;
