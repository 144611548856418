import React, {Fragment, useState} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {Controller, useFormContext} from "react-hook-form";
import RoamingInvoiceFormMember from "./RoamingInvoiceFormMember";
import {Col, Row, Form, Card} from "react-bootstrap";
import {selectLang} from "../../../store/reducers/main/main";
import RoamingInvoiceFormIncomeTypeSelector from "./RoamingInvoiceFormIncomeTypeSelector";
import RoamingInvoiceVatRegStatusBadge from "../RoamingInvoiceVatRegStatusBadge";

const fieldName = 'seller';

const RoamingInvoiceFormCardExecutor = ({isEditOrCopy}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();
    const [company, setCompany] = useState(null);
    const [eventCount, setEventCount] = useState(0);

    const onChangeCompany = async (company) => {
        setCompany(company);

        // if is copy or edit, prevent from conflict of values
        {
            if (isEditOrCopy && eventCount === 0) {
                setEventCount(eventCount + 1);
                return;
            }
        }

        if (company) {
            form.setValue(`${fieldName}.name`, company.name);
            form.setValue(`${fieldName}.account`, company.account || (company.bank_accounts?.length > 0 ? company.bank_accounts[0].account : ''))
            form.setValue(`${fieldName}.bankId`, company.mfo || (company.bank_accounts?.length > 0 ? company.bank_accounts[0].bankId : ''))
            form.setValue(`${fieldName}.address`, company.address);
            form.setValue(`${fieldName}.mobile`, '');
            form.setValue(`${fieldName}.workPhone`, '');
            form.setValue(`${fieldName}.oked`, company.oked);
            form.setValue(`${fieldName}.districtId`, company.district_id);
            form.setValue(`${fieldName}.director`, company.director);
            form.setValue(`${fieldName}.accountant`, company.accountant);
            form.setValue(`${fieldName}.vatRegCode`, company.vat_reg_code);
            form.setValue(`${fieldName}.vatRegStatus`, company.vat_rate_status);
            form.setValue(`${fieldName}.branch`, null);
        } else {
            form.setValue(`${fieldName}.name`, '');
            form.setValue(`${fieldName}.account`, '')
            form.setValue(`${fieldName}.bankId`, '')
            form.setValue(`${fieldName}.address`, '');
            form.setValue(`${fieldName}.mobile`, '');
            form.setValue(`${fieldName}.workPhone`, '');
            form.setValue(`${fieldName}.oked`, '');
            form.setValue(`${fieldName}.districtId`, null);
            form.setValue(`${fieldName}.director`, '');
            form.setValue(`${fieldName}.accountant`, '');
            form.setValue(`${fieldName}.vatRegCode`, '');
            form.setValue(`${fieldName}.vatRegStatus`, '');
            form.setValue(`${fieldName}.branch`, null);
        }
    };

    return (
        <Fragment>
            {company && <RoamingInvoiceVatRegStatusBadge vatRegStatus={company?.vat_rate_status}
                                              vatRegCode={company?.vat_reg_code}
                                              identifier={company?.person_num || company?.inn}
            />}

            <Card className={'p-2 rounded-4 border-0'}>
                <RoamingInvoiceFormMember fieldName={fieldName}
                                          isSearchable={false}
                                          onChangeCompany={onChangeCompany}
                                          company={company}
                />

                {(!company && !company?.vat_rate_status && !company?.vat_reg_code) &&
                    <Form.Group as={Row}>
                        <Col xs={12}>
                            <Controller name={`incomeType`}
                                        render={({field}) => (
                                            <RoamingInvoiceFormIncomeTypeSelector defaultIncomeType={field.value}
                                                                                  onChange={field.onChange}
                                            />
                                        )}
                            />
                        </Col>
                    </Form.Group>
                }
            </Card>
        </Fragment>
    );
};

export default RoamingInvoiceFormCardExecutor;
