import React from "react";
import SelectOrderStrategy from "./SelectOrderStrategy";
import {
    OrderContractDefineMethodStrategies,
    OrderProductNameDefineMethodStrategies
} from "../../../../order/GetStrategies";

const SelectOrderProductNameDefineMethodStrategy = React.forwardRef(({onChange, ...props}, ref) => (
    <SelectOrderStrategy onChange={onChange} strategies={OrderProductNameDefineMethodStrategies} {...props} ref={ref}/>
));

export default SelectOrderProductNameDefineMethodStrategy;
