import React, {Fragment, useEffect} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {selectDateFormat, selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import IconButton from "../../../IconButton";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import Cleave from "cleave.js/react";
import {Dropdown, Form} from "react-bootstrap";
import DatePicker from "react-datepicker";
import RevealDropdown, {RevealDropdownTrigger} from "../../../common/RevealDropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const VerificationActContractDocument = ({indexContract, index, appendDocument, removeDocument, onChange}) => {
    const { register, getFieldState, setValue, watch, getValues, control } = useFormContext();
    const {ownerDebit, ownerCredit} = watch(`verificationActContracts.${indexContract}.verificationActContractItems.${index}`)
    const errors = getFieldState(`verificationActContracts.${indexContract}.verificationActContractItems.${index}`).error || []
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    const setPartnerDebit = (sum) => {
        setValue(`verificationActContracts.${indexContract}.verificationActContractItems.${index}.partnerDebit`, sum)
    }
    const setPartnerCredit = (sum) => {
        setValue(`verificationActContracts.${indexContract}.verificationActContractItems.${index}.partnerCredit`, sum)
    }

    const setPartnerDate = (date) => {
        let partnerDate = getValues(`verificationActContracts.${indexContract}.verificationActContractItems.${index}.partnerOperationDate`)
        let ownerOldDate = getValues(`verificationActContracts.${indexContract}.verificationActContractItems.${index}.ownerOperationDate`)
        if(!partnerDate || (new Date(partnerDate).getTime()) === (new Date(ownerOldDate).getTime()))
            setValue(`verificationActContracts.${indexContract}.verificationActContractItems.${index}.partnerOperationDate`, date, {shouldValidate: true})
    }

    const setPartnerDocument = (document) => {
        let parnerDocument = getValues(`verificationActContracts.${indexContract}.verificationActContractItems.${index}.partnerOperationName`)
        let ownerOldDocument = getValues(`verificationActContracts.${indexContract}.verificationActContractItems.${index}.ownerOperationName`)
        if(!parnerDocument || parnerDocument === ownerOldDocument)
            setValue(`verificationActContracts.${indexContract}.verificationActContractItems.${index}.partnerOperationName`, document, {shouldValidate: true})
    }

    useEffect(() => {
        onChange()
    }, [ownerDebit, ownerCredit]);

    return (
        <Fragment>
            <td style={{ width: "12.5%" }} className="text-center align-middle p-1">
                <Controller
                    control={control}
                    name={`verificationActContracts.${indexContract}.verificationActContractItems.${index}.ownerOperationDate`}
                    rules={{
                        required: "edi.common.forms.validations.is_required"
                    }}
                    render={({field}) => (
                        <DatePicker
                            onChange={(date) => {
                                setPartnerDate(date)
                                field.onChange(date)
                            }}
                            dateFormat={dateFormat}
                            selected={field.value}
                            wrapperClassName={errors?.ownerOperationDate?.message && "is-invalid"}
                            className={`${errors?.ownerOperationDate?.message && "is-invalid"} form-control p-2`}
                            placeholderText={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.owner.date")}
                        />
                    )}
                />
            </td>
            <td style={{ width: "12.5%" }} className="text-center align-middle p-1">
                <Controller
                    control={control}
                    name={`verificationActContracts.${indexContract}.verificationActContractItems.${index}.ownerOperationName`}
                    rules={{
                        required: "edi.common.forms.validations.is_required"
                    }}
                    render={({field: {onChange, value}}) => (
                        <Form.Control
                            onChange={(event) => {
                                setPartnerDocument(event.target.value)
                                onChange(event.target.value)
                            }}
                            value={value}
                            isInvalid={errors?.ownerOperationName}
                            placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.owner.document")}
                            className="p-2"
                        />
                    )}
                />
            </td>
            <td style={{ width: "12.5%" }} className="text-center align-middle p-1">
                <Controller
                    name={`verificationActContracts.${indexContract}.verificationActContractItems.${index}.ownerDebit`}
                    control={control}
                    rules={{
                        required: "edi.common.forms.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            onChange={({target: {rawValue}}) => {
                                let value = +(parseFloat(rawValue)?.toFixed(2)) || 0
                                field.onChange(value)
                                setPartnerCredit(value)
                            }}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 2
                            }}
                            value={field.value}
                            placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.owner.debit")}
                            className={`${errors?.ownerDebit?.message && 'is-invalid'} p-2 form-control`}
                        />
                    )}
                />
            </td>
            <td style={{ width: "12.5%" }} className="text-center align-middle p-1">
                <Controller
                    name={`verificationActContracts.${indexContract}.verificationActContractItems.${index}.ownerCredit`}
                    control={control}
                    rules={{
                        required: "edi.common.forms.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            onChange={({target: {rawValue}}) => {
                                let value = +(parseFloat(rawValue)?.toFixed(2)) || 0
                                field.onChange(value)
                                setPartnerDebit(value)
                            }}
                            value={field.value}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 2
                            }}

                            placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.owner.credit")}
                            className={`${errors?.ownerCredit?.message && 'is-invalid'} p-2 form-control`}
                        />
                    )}
                />
            </td>
            <td style={{ width: "12.5%" }} className="text-center align-middle p-1">
                <Controller
                    control={control}
                    name={`verificationActContracts.${indexContract}.verificationActContractItems.${index}.partnerOperationDate`}
                    rules={{
                        required: "edi.common.forms.validations.is_required"
                    }}
                    render={({field}) => (
                        <DatePicker
                            onChange={field.onChange}
                            dateFormat={dateFormat}
                            selected={field.value}
                            wrapperClassName={errors?.partnerOperationDate?.message && "is-invalid"}
                            className={`${errors?.partnerOperationDate?.message && "is-invalid"} form-control p-2`}
                            placeholderText={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.partner.date")}
                        />
                    )}
                />
            </td>
            <td style={{ width: "12.5%" }} className="text-center align-middle p-1">
                <Form.Control
                    {...register(`verificationActContracts.${indexContract}.verificationActContractItems.${index}.partnerOperationName`)}
                    placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.partner.document")}
                    className="p-2"
                />
            </td>
            <td style={{ width: "12.5%" }} className="text-center align-middle p-1">
                <Controller
                    name={`verificationActContracts.${indexContract}.verificationActContractItems.${index}.partnerDebit`}
                    control={control}
                    rules={{
                        required: "edi.common.forms.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            {...field}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 2
                            }}

                            placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.partner.debit")}
                            className={`${errors?.partnerDebit?.message && 'is-invalid'} p-2 form-control`}
                            readOnly
                        />
                    )}
                />
            </td>
            <td style={{ width: "12.5%" }} className="text-center align-middle p-1">
                <Controller
                    name={`verificationActContracts.${indexContract}.verificationActContractItems.${index}.partnerCredit`}
                    control={control}
                    rules={{
                        required: "edi.common.forms.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            {...field}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 2
                            }}

                            placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.partner.credit")}
                            className={`${errors?.partnerCredit?.message && 'is-invalid'} p-2 form-control`}
                            readOnly
                        />
                    )}
                />
            </td>
            <td style={{ width: "12.5%" }} className="px-0">
                    <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm" className={'p-0'}>
                        <RevealDropdown dropdownMenuClassName={'p-0'}>
                            <Dropdown.Item className="fs-8" onClick={appendDocument}>
                                <span>{t(lang, "roaming.common.add")}</span>
                            </Dropdown.Item>
                            {getValues(`verificationActContracts.${indexContract}.verificationActContractItems`).length > 1 && <Dropdown.Item className="fs-8" onClick={() => removeDocument(index)}>
                                <span>{t(lang, "roaming.invoice.delete")}</span>
                            </Dropdown.Item>}
                        </RevealDropdown>
                    </RevealDropdownTrigger>
            </td>
        </Fragment>
    );
};

export default VerificationActContractDocument;
