import React from 'react';
import classNames from "classnames";
import ReactSelect from "react-select";
import {useSelector} from "react-redux";
import {Col, Form} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";

const SelectTemplateComponent = ({index, label, options, arrayFieldName}) => {
    const {control, formState: {errors}} = useFormContext();
    const rootName = `${arrayFieldName}.${index}`;
    const lang = useSelector(selectLang);
    const t = Translate;

    let fieldErrors = {};
    if (errors && errors[arrayFieldName] && errors?.[arrayFieldName]?.[index]) {
            fieldErrors = errors?.[arrayFieldName]?.[index];
        }

    return (
        <>
            <Col xs={12} lg={6} key={index}>
                <Form.Label>{t(lang, label)}</Form.Label>

                <Controller
                    control={control}
                    name={`${arrayFieldName}.${index}.values`}
                    rules={{
                        required: t(lang, 'main.settings.tab.templates.form.validation.is_required')
                    }}
                    render={({field}) => (
                        <ReactSelect
                            isMulti
                            options={options}
                            value={field.value}
                            onChange={field.onChange}
                            classNamePrefix="react-select"
                            getOptionValue={(option) => option.uri}
                            className={classNames({'is-invalid': fieldErrors?.values?.message})}
                            getOptionLabel={(option) => t(lang, `edi.template.name.${option.name}`)}
                            placeholder={t(lang, 'main.settings.tab.templates.form.select.placeholder')}
                            noOptionsMessage={() => t(lang, 'main.settings.tab.templates.form.selector.no_options')}
                        />
                    )}
                />

                <Form.Control.Feedback type="invalid">
                    {fieldErrors?.values?.message}
                </Form.Control.Feedback>
            </Col>
        </>
    );
};

export default SelectTemplateComponent;
