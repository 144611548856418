import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {selectLang} from "../../../../store/reducers/main/main";
import {useFormContext} from "react-hook-form";
import {Form} from "react-bootstrap";
import ConvertQuantityToWord from "../../../../filter/ConvertQuantityToWord";
import ActionButton from "../../../common/ActionButton";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";

const Item = ({root, index, allowDelete, onDeleteClicked}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const {register, formState: {errors}, setValue, watch} = useFormContext();

    const itemRootName = `${root}.${index}`;
    let itemErrors = {};

    if (errors && errors[root] && errors[root][index])
        itemErrors = errors[root][index];

    const quantity = watch(`${itemRootName}.quantity`);

    useEffect(() => {
        let convertedToWordQuantity = ConvertQuantityToWord.getText(+quantity);
        setValue(`${itemRootName}.quantityInWords`, convertedToWordQuantity);
    }, [quantity])

    return (
        <tr>
            <td>
                {index + 1}
            </td>
            <td>
                <Form.Control name={`${itemRootName}.product`}
                              style={{height: "37px"}}
                              placeholder={t(lang, "edi.common.items.product.placeholder")}
                              className="p-1"
                              isInvalid={itemErrors?.product}
                              isValid={Object.keys(errors).length > 0 && !itemErrors?.product}
                              {...register(`${itemRootName}.product`, {
                                  required: t(lang, "edi.common.forms.validations.is_required"),
                              })}  />
                <Form.Control.Feedback type="invalid">
                    {itemErrors?.product?.message}
                </Form.Control.Feedback>
            </td>
            <td>
                <Form.Control placeholder={t(lang, "edi.common.items.product.measurement.placeholder")}
                              style={{height: "37px"}}
                              className="p-1"
                              isInvalid={itemErrors?.measurement}
                              isValid={Object.keys(errors).length > 0 && !itemErrors?.measurement}
                              {...register(`${itemRootName}.measurement`, {
                                  required: t(lang, "edi.common.forms.validations.is_required")
                              })}
                />
                <Form.Control.Feedback type="invalid">
                    {itemErrors?.measurement?.message}
                </Form.Control.Feedback>
            </td>
            <td>
                <Form.Control name={`${itemRootName}.quantity`}
                              type="text"
                              isInvalid={itemErrors?.quantity}
                              isValid={Object.keys(errors).length > 0 && !itemErrors?.quantity}
                              {...register(`${itemRootName}.quantity`, {
                                  required: t(lang, 'edi.common.forms.validations.is_required'),
                              })}
                />
                <Form.Control.Feedback type="invalid">{itemErrors?.quantity?.message}</Form.Control.Feedback>
            </td>
            <td>
                <Form.Control placeholder={t(lang, "edi.common.items.product.quantity_in_words")}
                              style={{height: "37px"}}
                              className="p-1"
                              disabled
                              {...register(`${itemRootName}.quantityInWords`)}
                />
            </td>
            <td>
                <ActionButton icon={faTrashAlt}
                              title="Delete"
                              variant="action"
                              className="p-1 mx-auto"
                              onClick={() => onDeleteClicked(index)}
                              disabled={!allowDelete}
                />
            </td>
        </tr>
    )
};


export default Item;
