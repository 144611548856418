import {createSlice} from "@reduxjs/toolkit";
import {ContractorDebtUpdated, WarehouseItemUpdated} from "../../../enum/pushupdate/PushUpdateNotification";


const pushUpdateNotificationSlice = createSlice({
    name: "pushUpdateNotification",
    initialState: {
        pushUpdateNotificationData: []
    },
    reducers: {
        addPushUpdateNotification: (state, action) => {
            let pushUpdateNotifications = state.pushUpdateNotificationData
            if (action.payload.type === WarehouseItemUpdated && pushUpdateNotifications.length) {
                const temp = pushUpdateNotifications.filter(item => item.payload.item_id !== action.payload.payload.item_id)
                temp.unshift(action.payload)
                state.pushUpdateNotificationData = temp
            } else if (action.payload.type === ContractorDebtUpdated && pushUpdateNotifications.length) {
                const temp = pushUpdateNotifications.filter(item => item.payload.id !== action.payload.payload.id)
                temp.unshift(action.payload)
                state.pushUpdateNotificationData = temp
            } else {
                pushUpdateNotifications.unshift(action.payload)
                state.pushUpdateNotificationData = pushUpdateNotifications
            }
        },
        deleteOneNotification: (state, action) => {
            state.pushUpdateNotificationData = state.pushUpdateNotificationData.filter((item, ind) => ind !== action.payload)
        },
        clearAllNotification: (state) => {
            state.pushUpdateNotificationData = []
        }
    }
})

export const selectPushUpdateNotification = (state) => state.pushUpdateNotification.pushUpdateNotificationData

export const pushUpdateNotification = (payload) => (dispatch) => {
    dispatch(pushUpdateNotificationSlice.actions.addPushUpdateNotification(payload));
};

export const pushUpdateNotificationContractorDebt = (payload) => (dispatch) => {
    dispatch(pushUpdateNotificationSlice.actions.addPushUpdateNotification(payload));
}

export const deletePushUpdateNotification = (payload) => (dispatch) => {
    dispatch(pushUpdateNotificationSlice.actions.deleteOneNotification(payload))
}

export const clearAllPushUpdateNotifications = () => (dispatch) => {
    dispatch(pushUpdateNotificationSlice.actions.clearAllNotification())
}


export default pushUpdateNotificationSlice.reducer
