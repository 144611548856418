import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectDateDayJSFormat, selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {selectMeasurements} from "../../../store/reducers/roaming/roamingReducer";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {RoamingInvoiceViewPageWrapper} from "../../../enum/roaming/roaming-invoice/RoamingInvoiceViewPageWrapper";
import {
    markOptions,
    roamingInvoiceTypes, singleSidedInvoiceTypes,
    TypeAdditional, TypeAdditionalAmendOfExpenses, TypeCorrective, TypeCorrectiveAmendOfExpenses
} from "../../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import EventBus from "../../../eventbus/EventBus";
import {
    ROAMING_INVOICE_CUSTOMER_ACCEPT_FAILED,
    ROAMING_INVOICE_CUSTOMER_ACCEPT_SUCCESS,
    ROAMING_INVOICE_CUSTOMER_DECLINE_SUCCESS,
    ROAMING_INVOICE_EXECUTOR_CANCEL_FAILED,
    ROAMING_INVOICE_EXECUTOR_CANCEL_SUCCESS,
    ROAMING_INVOICE_PERSON_ACCEPT_FAILED,
    ROAMING_INVOICE_PERSON_ACCEPT_SUCCESS,
    ROAMING_INVOICE_PERSON_DECLINE_FAILED,
    ROAMING_INVOICE_PERSON_DECLINE_SUCCESS,
    ROAMING_INVOICE_SEND_FAILED,
    ROAMING_INVOICE_SYNC_FAILED,
    ROAMING_INVOICE_SYNC_SUCCESS
} from "../../../eventbus/romaing/roaming-invoice/roamingInvoiceEvents";
import checkPermission from "../../../enum/permission/CheckPermission";
import {
    PermissionRoamingInvoiceCreate,
    PermissionRoamingInvoiceUpdate
} from "../../../enum/permission/RoamingPermission";
import {ZERO_VAT_PRIVILEGE_TYPE} from "../../../enum/roaming/LgotaTypes";
import WarningAlert from "../../common/WarningAlert";
import {Button, Card, Col, Row} from "react-bootstrap";
import RoamingInvoiceDraftDelete from "./RoamingInvoiceDraftDelete";
import RoamingInvoiceDraftSend from "./RoamingInvoiceDraftSend";
import RoamingDraftInvoiceDownloadToPdf from "./view/RoamingDraftInvoiceDownloadToPdf";
import RoamingDraftInvoicePrint from "./view/RoamingDraftInvoicePrint";
import RoamingInvoiceExecutorCancel from "./RoamingInvoiceExecutorCancel";
import RoamingInvoiceCustomerAccept from "./RoamingInvoiceCustomerAccept";
import RoamingInvoiceCustomerDecline from "./RoamingInvoiceCustomerDecline";
import RoamingInvoicePersonAccept from "./RoamingInvoicePersonAccept";
import RoamingInvoicePersonDecline from "./RoamingInvoicePersonDecline";
import IconButton from "../../IconButton";
import {Link, useNavigate} from "react-router-dom";
import toastPermission from "../../../enum/permission/ToastPermission";
import {faClone, faPencil, faPlus} from "@fortawesome/free-solid-svg-icons";
import {
    loadDraftPDFAsync,
    loadPDFAsync
} from "../../../store/reducers/roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import RoamingInvoiceSync from "./view/RoamingInvoiceSync";
import Badge from "../../common/Badge";
import {GetStatusTextReturnOrder} from "../../../enum/edi/return-order/ReturnOrderStatus";
import dayjs from "dayjs";
import RoamingInvoiceVatRegStatusBadge from "../RoamingInvoiceVatRegStatusBadge";
import RoamingInvoiceViewTable from "./view/RoamingInvoiceViewTable";
import DeclinedHistoryActionSeal from "../history/DeclinedHistoryActionSeal";
import AcceptedHistoryActionSeal from "../history/AcceptedHistoryActionSeal";
import CanceledHistoryActionSeal from "../history/CanceledHistoryActionSeal";
import SentHistoryActionSeal from "../history/SentHistoryActionSeal";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import {scrollEvent} from "../../../helpers/scrollEvent";
import IconAlert from "../../common/IconAlert";

const RoamingInvoice = ({invoice}) => {
    const numberFormat = useSelector(selectNumberFormat);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const lang = useSelector(selectLang);
    const t = Translate;
    const measurements = useSelector(selectMeasurements);
    const activeOrganization = useSelector(selectActiveOrganization);
    const invoiceWrapper = new RoamingInvoiceViewPageWrapper(invoice);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [zeroVatPrivilegesInfo, setZeroVatPrivilegesInfo] = useState([])

    const canExecutorCancel = invoiceWrapper.canExecutorCancel(activeOrganization.pinfl || activeOrganization.inn);
    const canCustomerAccept = invoiceWrapper.canCustomerAccept(activeOrganization.pinfl || activeOrganization.inn);
    const canCustomerDecline = invoiceWrapper.canCustomerDecline(activeOrganization.pinfl || activeOrganization.inn);
    const canPersonAccept = invoiceWrapper.canPersonAccept(activeOrganization.pinfl || activeOrganization.inn);
    const canPersonDecline = invoiceWrapper.canPersonDecline(activeOrganization.pinfl || activeOrganization.inn);
    const canSync = invoiceWrapper.canSync();
    const canCopy = invoiceWrapper.canCopy(activeOrganization.pinfl || activeOrganization.inn)
    const canEditDraft = invoiceWrapper.canEditDraft(activeOrganization.pinfl || activeOrganization.inn);
    const canCreateAdditionalInvoice = invoiceWrapper.canCreateAdditionalInvoice(activeOrganization.pinfl || activeOrganization.inn);
    const canCreateCorrectiveInvoice = invoiceWrapper.canCreateCorrectiveInvoice(activeOrganization.pinfl || activeOrganization.inn);
    const canCreateAdditionalAmendOfExpensesInvoice = invoiceWrapper.canCreateAdditionalAmendOfExpensesInvoice(activeOrganization.pinfl || activeOrganization.inn);
    const canCreateCorrectiveAmendOfExpensesInvoice = invoiceWrapper.canCreateCorrectiveAmendOfExpensesInvoice(activeOrganization.pinfl || activeOrganization.inn);

    const getMeasure = id => measurements.find(m => m.measureId === id) || null;
    const getMark = type => markOptions.find(m => m.value === type) || null;

    const isEmpowered = invoiceWrapper.isEmpowered();
    const isWaybilled = invoiceWrapper.isWaybilled();
    const isAdditionalOrCorrective = invoiceWrapper.isAdditionalOrCorrective();
    const isSingleSide = invoiceWrapper.isSingleSide();
    const hasMedical = invoiceWrapper.hasMedical();
    const hasExcise = invoiceWrapper.hasExcise();
    const hasMark = invoiceWrapper.hasMark();
    const hasCommittent = invoiceWrapper.hasCommittent();
    const hasExchange = invoiceWrapper.hasExchange();
    const isReportHiddenForCommittent = invoiceWrapper.isReportHiddenForCommittent();
    const isDraft = invoiceWrapper.isDraft();
    const isDeclined = invoiceWrapper.isStatusDeclinedByCustomerOrPerson();

    const excise = invoice.items.reduce((acc, item) => acc + item.excise_value, 0) || 0;
    const medical = invoice.items.reduce((acc, item) => acc + item.base_price, 0) || 0;

    const roamingInvoiceCreate = () => {
        return dispatch(checkPermission(PermissionRoamingInvoiceCreate))
    }

    const [eventMessage, setEventMessage] = useState(null);

    useEffect(() => {
        //success

        const onExecutorCancelSuccessHandler = EventBus.on(ROAMING_INVOICE_EXECUTOR_CANCEL_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
                text: t(lang, 'roaming.invoice.alert.invoice_cancel_success')
            });
        })
        const onCustomerAcceptSuccessHandler = EventBus.on(ROAMING_INVOICE_CUSTOMER_ACCEPT_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
                text: t(lang, 'roaming.invoice.alert.invoice_accept_success')
            });
        })
        const onCustomerDeclineSuccessHandler = EventBus.on(ROAMING_INVOICE_CUSTOMER_DECLINE_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
                text: t(lang, 'roaming.invoice.alert.invoice_decline_success')
            });
        })
        const onPersonAcceptSuccessHandler = EventBus.on(ROAMING_INVOICE_PERSON_ACCEPT_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
                text: t(lang, 'roaming.invoice.alert.invoice_accept_success')
            });
        })
        const onPersonDeclineSuccessHandler = EventBus.on(ROAMING_INVOICE_PERSON_DECLINE_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.empowerment.alert.success_event_title'),
                text: t(lang, 'roaming.invoice.alert.invoice_decline_success')
            });
        })
        const onInvoiceSyncSuccessHandler = EventBus.on(ROAMING_INVOICE_SYNC_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
                text: t(lang, 'roaming.invoice.alert.invoice_sync_success')
            });
        })

        //fail

        const onExecutorCancelFailedHandler = EventBus.on(ROAMING_INVOICE_EXECUTOR_CANCEL_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error.message
            });
        })
        const onCustomerAcceptFailedHandler = EventBus.on(ROAMING_INVOICE_CUSTOMER_ACCEPT_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error.message
            });
        })
        const onCustomerDeclineFailedHandler = EventBus.on(ROAMING_INVOICE_PERSON_DECLINE_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error.message
            });
        })
        const onPersonAcceptFailedHandler = EventBus.on(ROAMING_INVOICE_PERSON_ACCEPT_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error.message
            });
        })
        const onPersonDeclineFailedHandler = EventBus.on(ROAMING_INVOICE_PERSON_DECLINE_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error.message
            })
        })
        const onInvoiceSyncFailedHandler = EventBus.on(ROAMING_INVOICE_SYNC_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error.message
            })
        })
        const onDraftSendFailedHandler = EventBus.on(ROAMING_INVOICE_SEND_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error.message
            })
        })

        return () => {
            EventBus.remove(ROAMING_INVOICE_EXECUTOR_CANCEL_SUCCESS, onExecutorCancelSuccessHandler)
            EventBus.remove(ROAMING_INVOICE_CUSTOMER_ACCEPT_SUCCESS, onCustomerAcceptSuccessHandler)
            EventBus.remove(ROAMING_INVOICE_CUSTOMER_DECLINE_SUCCESS, onCustomerDeclineSuccessHandler)
            EventBus.remove(ROAMING_INVOICE_PERSON_ACCEPT_SUCCESS, onPersonAcceptSuccessHandler)
            EventBus.remove(ROAMING_INVOICE_PERSON_DECLINE_SUCCESS, onPersonDeclineSuccessHandler)
            EventBus.remove(ROAMING_INVOICE_SYNC_SUCCESS, onInvoiceSyncSuccessHandler)

            EventBus.remove(ROAMING_INVOICE_EXECUTOR_CANCEL_FAILED, onExecutorCancelFailedHandler)
            EventBus.remove(ROAMING_INVOICE_CUSTOMER_ACCEPT_FAILED, onCustomerAcceptFailedHandler)
            EventBus.remove(ROAMING_INVOICE_PERSON_DECLINE_FAILED, onCustomerDeclineFailedHandler)
            EventBus.remove(ROAMING_INVOICE_PERSON_ACCEPT_FAILED, onPersonAcceptFailedHandler)
            EventBus.remove(ROAMING_INVOICE_PERSON_DECLINE_FAILED, onPersonDeclineFailedHandler)
            EventBus.remove(ROAMING_INVOICE_SYNC_FAILED, onInvoiceSyncFailedHandler)
            EventBus.remove(ROAMING_INVOICE_SEND_FAILED, onDraftSendFailedHandler)
        }

    }, [])

    useEffect(() => {
        const privileges = []

        if (invoice) {
            invoice.items.forEach((item) => {
                if (item.lgota_id) {
                    let privilege = privileges.find(x => {
                        return x.id === item.lgota_id && x.lgota_type === item.lgota_type
                    }) || null

                    if (!privilege) {
                        privilege = {
                            id: item.lgota_id,
                            lgota_name: item.lgota_name,
                            lgota_type: item.lgota_type,
                            lgota_vat_sum: 0,
                            lgota_vat_total_sum: 0,
                        }

                        privileges.push(privilege)
                    }

                    privilege.lgota_vat_sum += item.lgota_vat_sum
                    privilege.lgota_vat_total_sum += item.total
                }
            });
        }

        setZeroVatPrivilegesInfo(privileges.filter(x => x.lgota_type === ZERO_VAT_PRIVILEGE_TYPE))
    }, [invoice])

    const isZeroVatPrivilegesExists = zeroVatPrivilegesInfo.length > 0
    const facturaType = roamingInvoiceTypes.find((type) => type.value === invoice?.factura_type)

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('roaming-invoice-view'))
        return () => window.removeEventListener('scroll', () => scrollEvent('roaming-invoice-view'))
    }, []);

    return (
        <Fragment>
            {eventMessage &&
                <IconAlert className={`alert-davr-${eventMessage.variant} mb-2`} variant={eventMessage.variant}>
                    <p className="mb-0 fs-8">{t(lang, eventMessage.title)}</p>
                    <p className="mb-0">{t(lang, eventMessage.text)}</p>
                    <div className="d-flex justify-content-end">
                        <Button variant={'phoenix-secondary'} className={'btn-link text-danger'}
                                href="https://t.me/+lpgw1qdQN0k1MWMy">{t(lang, "main.warning_alert.request_button")}</Button>
                    </div>
                </IconAlert>}

            {!eventMessage && invoice?.roaming_error &&
                <IconAlert className={`alert-davr-danger mb-2`} variant={'danger'}>
                    <p className="mb-0 fs-8">{t(lang, 'Ошибка от роуминга')}</p>
                    <p className="mb-0">{t(lang, invoice?.roaming_error.message)}</p>
                    <div className="d-flex justify-content-end">
                        <Button variant={'phoenix-secondary'} className={'btn-link text-danger'}
                                href="https://t.me/+lpgw1qdQN0k1MWMy">{t(lang, "main.warning_alert.request_button")}</Button>
                    </div>
                </IconAlert>}

            <Row id={'roaming-invoice-view'} className={'justify-content-between position-sticky z-index-1'}
                 style={{top: '4rem'}}>
                <Col xs={"auto"} className={'d-flex gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'}
                            style={{width: 35, height: 35}}>
                        <IconArrowLeft/>
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.common.invoice")} № {invoice?.info?.number}</h5>
                        {!isDraft && <div className={'d-flex align-items-center gap-2'}>
                            <span className={'second-text fs-9'}>
                                {invoice.created_at}
                            </span>
                            <Badge bg={invoiceWrapper.getStatusVariant(activeOrganization.inn)}
                                   variant={'phoenix'}
                                   className={'px-2 py-1 fs-9 text-uppercase text-decoration-none fw-medium border-0 rounded-4'}>
                                {t(lang, invoiceWrapper.getStatusText(activeOrganization.inn))}
                            </Badge>
                        </div>}
                    </div>
                </Col>
                <Col xs={"auto"} className={'d-flex justify-content-end gap-2'}>
                    {isDraft && <Fragment>
                        <RoamingDraftInvoicePrint actionPdf={loadDraftPDFAsync} size="sm" variant="phoenix-secondary"
                                                  id={invoice.id}/>
                        <RoamingDraftInvoiceDownloadToPdf actionPdf={loadDraftPDFAsync} invoice={invoice}/>
                    </Fragment>}

                    {isDraft && <RoamingInvoiceDraftDelete id={invoice.id} hasTitle size="sm"/>}
                    {!isDraft && <Fragment>
                        <RoamingDraftInvoicePrint actionPdf={loadPDFAsync} size="sm" variant="phoenix-secondary"
                                                  id={invoice.id}/>
                        <RoamingDraftInvoiceDownloadToPdf actionPdf={loadPDFAsync} invoice={invoice}/>
                    </Fragment>}
                    {canSync && <RoamingInvoiceSync id={invoice.id}/>}

                    {canEditDraft &&
                        <IconButton size='sm'
                                    to={`/roaming/invoice/send?draft_invoice_id=${invoice.id}&is_edit=true`}
                                    as={dispatch(checkPermission(PermissionRoamingInvoiceUpdate)) ? Link : 'div'}
                                    onClick={() => !dispatch(checkPermission(PermissionRoamingInvoiceUpdate)) && dispatch(toastPermission())}
                                    variant='phoenix-warning'
                                    icon={faPencil}
                                    className={'btn-davr bg-white text-dark d-flex gap-2'}
                                    iconClassName={"text-primary-davr fs-8"}
                        >
                            {t(lang, 'roaming.common.edit')}
                        </IconButton>
                    }
                    {canCopy && isDraft &&
                        <Link to={`/roaming/invoice/send?draft_invoice_id=${invoice.id}`}>
                            <IconButton size='sm'
                                        variant='phoenix-primary'
                                        icon={faClone}
                                        className={'btn-davr bg-white text-dark d-flex gap-2'}
                                        iconClassName={"text-primary-davr fs-8"}
                            >
                                {t(lang, 'roaming.invoice.copy')}
                            </IconButton>
                        </Link>}


                    {canCopy && !isDraft &&
                        <Link to={`/roaming/invoice/send?invoice_id=${invoice.id}`}>
                            <IconButton size='sm'
                                        variant='phoenix-primary'
                                        icon={faClone}
                                        className={'btn-davr bg-white text-dark d-flex gap-2'}
                                        iconClassName={"text-primary-davr fs-8"}
                            >
                                {t(lang, 'roaming.invoice.copy')}
                            </IconButton>
                        </Link>}
                    {canCreateAdditionalInvoice &&
                        <IconButton size='sm'
                                    to={`/roaming/invoice/send?invoice_id=${invoice.id}&type=${TypeAdditional}`}
                                    as={roamingInvoiceCreate() ? Link : 'div'}
                                    onClick={() => !roamingInvoiceCreate() && dispatch(toastPermission())}
                                    variant='phoenix-success'
                                    icon={faPlus}
                                    className={'btn-davr bg-white text-dark d-flex gap-2'}
                                    iconClassName={"text-primary-davr fs-8"}
                        >
                            {t(lang, 'roaming.invoice.send_form.type.option.1')}
                        </IconButton>
                    }
                    {canCreateCorrectiveInvoice &&
                        <IconButton size='sm'
                                    to={`/roaming/invoice/send?invoice_id=${invoice.id}&type=${TypeCorrective}`}
                                    as={roamingInvoiceCreate() ? Link : 'div'}
                                    onClick={() => !roamingInvoiceCreate() && dispatch(toastPermission())}
                                    variant='phoenix-success'
                                    icon={faPlus}
                                    className={'btn-davr bg-white text-dark d-flex gap-2'}
                                    iconClassName={"text-primary-davr fs-8"}
                        >
                            {t(lang, 'roaming.invoice.send_form.type.option.4')}
                        </IconButton>
                    }
                    {canCreateAdditionalAmendOfExpensesInvoice &&

                        <IconButton size='sm'
                                    to={`/roaming/invoice/send?invoice_id=${invoice.id}&type=${TypeAdditionalAmendOfExpenses}`}
                                    as={roamingInvoiceCreate() ? Link : 'div'}
                                    onClick={() => !roamingInvoiceCreate() && dispatch(toastPermission())}
                                    variant='phoenix-success'
                                    icon={faPlus}
                                    className={'btn-davr bg-white text-dark d-flex gap-2'}
                                    iconClassName={"text-primary-davr fs-8"}
                        >
                            {t(lang, 'roaming.invoice.send_form.type.option.6')}
                        </IconButton>
                    }

                    {canCreateCorrectiveAmendOfExpensesInvoice &&
                        <IconButton size='sm'
                                    to={`/roaming/invoice/send?invoice_id=${invoice.id}&type=${TypeCorrectiveAmendOfExpenses}`}
                                    as={roamingInvoiceCreate() ? Link : 'div'}
                                    onClick={() => !roamingInvoiceCreate() && dispatch(toastPermission())}
                                    variant='phoenix-success'
                                    icon={faPlus}
                                    className={'btn-davr bg-white text-dark d-flex gap-2'}
                                    iconClassName={"text-primary-davr fs-8"}
                        >
                            {t(lang, 'roaming.invoice.send_form.type.option.5')}
                        </IconButton>
                    }
                    {canExecutorCancel && <RoamingInvoiceExecutorCancel id={invoice.id}/>}
                    {canCustomerDecline && <RoamingInvoiceCustomerDecline id={invoice.id}/>}
                    {canPersonDecline && <RoamingInvoicePersonDecline id={invoice.id}/>}
                    {canCustomerAccept && <RoamingInvoiceCustomerAccept id={invoice.id}/>}
                    {canPersonAccept && <RoamingInvoicePersonAccept id={invoice.id}/>}
                    {isDraft && <RoamingInvoiceDraftSend invoice={invoice} size="sm"/>}
                </Col>
            </Row>

            <Card className={'mt-2'}>
                <Card.Body className={'pb-0'}>
                    <h2 className="fw-semi-bold text-center">
                        {t(lang, "roaming.common.invoice")}<i>{invoice.notes}</i>
                    </h2>
                    <h5 className="fw-semi-bold text-center second-text">
                        <i>{invoice.id}</i>
                    </h5>
                </Card.Body>
                <Card.Footer as={Row} className={'border-0 justify-content-between fs-8 pb-3'}>
                    <Col xs={12} md={6} className={'px-1'}>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.invoice.number")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text'}>{invoice.info.number}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>

                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.invoice.date")}</span>
                                </Col>
                                <Col md={8}>
                                    <span
                                        className={'main-text'}>{dayjs(invoice.info.date, "YYYY-MM-DD").format(dateFormat)}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>

                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.invoice.updated_at")}</span>
                                </Col>
                                <Col md={8}>
                                    <span
                                        className={'main-text'}>{dayjs(invoice.updated_at, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    {isAdditionalOrCorrective && <Col xs={12} md={6} className={'px-1'}>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.common.invoice")}</span>
                                </Col>
                            </Row>

                            <Row className={'py-1'}>
                                <Col md={6}>
                                    <span className={'second-text'}>{t(lang, "roaming.invoice.old_invoice")}</span>
                                </Col>
                                <Col md={6}>
                                    {isDraft &&
                                        <Link className={'main-text'} to={`../${invoice.old_invoice.id}`}>
                                            <i className="fw-semi-bold">{invoice.old_invoice.number}</i>
                                        </Link>
                                    }
                                    {!isDraft &&
                                        <Link className={'main-text'} to={`${invoice.old_invoice.id}`}>
                                            <i className="fw-semi-bold">{invoice.old_invoice.number}</i>
                                        </Link>
                                    }
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>

                            <Row className={'py-1'}>
                                <Col md={6}>
                                    <span className={'second-text'}>{t(lang, "roaming.invoice.old_invoice.date")}</span>
                                </Col>
                                <Col md={6}>
                                    <span
                                        className={'main-text'}>{dayjs(invoice.info.date, "YYYY-MM-DD").format(dateFormat)}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>}

                    {isEmpowered && <Col xs={12} md={6} className={'px-1'}>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.common.empowerment")}</span>
                                </Col>
                            </Row>

                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span
                                        className={'second-text'}>{t(lang, "roaming.common.empowerment.number")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text'}>{invoice.empowerment.number}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>

                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.common.empowerment.date")}</span>
                                </Col>
                                <Col md={8}>
                                    <span
                                        className={'main-text'}>{dayjs(invoice.empowerment.date, "YYYY-MM-DD").format(dateFormat)}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>}

                    <Col xs={12} md={6} className={'px-1'}>
                        <div className={`bg-card p-2 rounded-2 ${(isEmpowered || isAdditionalOrCorrective) && "mt-2"}`}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.common.contract")}</span>
                                </Col>
                            </Row>

                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.contract.number")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text'}>{invoice.contract.number}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>

                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.contract.date")}</span>
                                </Col>
                                <Col md={8}>
                                    <span
                                        className={'main-text'}>{dayjs(invoice.contract.date, "YYYY-MM-DD").format(dateFormat)}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Card.Footer>
            </Card>

            <Card className={'mt-2'}>
                <Card.Footer as={Row} className={'border-0 justify-content-between fs-8 pb-3 py-1'}>
                    <Col xs={12} md={6} className={'px-1'}>
                        <h5 className="mb-1 fw-bold second-text text-uppercase">{t(lang, 'roaming.common.executor')}</h5>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "edi.common.company")}</span>
                                </Col>
                                <Col md={8}>
                                    <span title={invoice.executor.name} className={'main-text w-100 text-truncate d-block h-100'}>{invoice.executor.name}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "edi.common.label.inn")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-block h-100'}>{invoice.executor.inn}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.common.address")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-block h-100'}>{invoice.executor.address}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.common.director")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-block h-100'}>{invoice.executor.director}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.common.accountant")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-block h-100'}>{invoice.executor.accountant}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.common.bank")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-block h-100'}>{invoice.executor.bank_name}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.common.bank_account")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-block h-100'}>{invoice.executor.account}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.common.bank_mfo")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-block h-100'}>{invoice.executor.mfo}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.common.nds_code")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-block h-100'}>{invoice.executor.nds_registration_code}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.common.nds_status")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-block h-100'}>
                                        <RoamingInvoiceVatRegStatusBadge
                                            identifier={invoice.executor.inn}
                                            vatRegStatus={invoice.executor.nds_status}
                                            vatRegCode={invoice.executor.nds_registration_code}
                                        />
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    {isSingleSide ?  <Col xs={12} md={6}>
                        <div className="p-2 h-100">
                            <div className='border-300 my-2 p-2 bg-100 second-text'
                                 style={{borderRadius: '10px', border: '5px solid'}}>
                                <p className={`fs-1 fw-semi-bold text-center text-uppercase my-3`}>{t(lang, singleSidedInvoiceTypes.find(o => o.value === parseInt(invoice.single_sided_type))?.label)}</p>
                            </div>
                        </div>
                    </Col> : <Col xs={12} md={6} className={'px-1'}>
                        <h5 className="mb-1 fw-bold second-text text-uppercase">{t(lang, 'roaming.common.customer')}</h5>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "edi.common.company")}</span>
                                </Col>
                                <Col md={8}>
                                    <span title={invoice.executor.name} className={'main-text w-100 text-truncate d-block h-100'}>
                                        {invoice.customer.name}
                                       {/* {(invoice.customer.branch.code && invoice.customer.branch.name) && <b
                                            className="ms-2">({invoice.customer.branch.code} - {invoice.customer.branch.name})</b>}*/}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.common.inn")}</span>
                                </Col>
                                <Col md={8}>
                                    <span
                                        className={'main-text w-100 text-truncate d-block h-100'}>{invoice.customer.inn}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.common.address")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-block h-100'}>{invoice.executor.address}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.common.director")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-block h-100'}>{invoice.customer.director}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.common.accountant")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-block h-100'}>{invoice.executor.accountant}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.common.bank")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-block h-100'}>{invoice.customer.bank_name}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.common.bank_account")}</span>
                                </Col>
                                <Col md={8}>
                                    <span
                                        className={'main-text w-100 text-truncate d-block h-100'}>{invoice.customer.account}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.common.bank_mfo")}</span>
                                </Col>
                                <Col md={8}>
                                    <span
                                        className={'main-text w-100 text-truncate d-block h-100'}>{invoice.customer.mfo}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.common.nds_code")}</span>
                                </Col>
                                <Col md={8}>
                                    <span
                                        className={'main-text w-100 text-truncate d-block h-100'}>{invoice.customer.nds_registration_code}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.common.nds_status")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-block h-100'}>
                                        <RoamingInvoiceVatRegStatusBadge identifier={invoice.customer.inn}
                                                                         vatRegStatus={invoice.customer.nds_status}
                                                                         vatRegCode={invoice.customer.nds_registration_code}
                                        />
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            {hasExchange &&<Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.invoice.exchange")}</span>
                                </Col>
                                <Col md={8}>
                                    <span
                                        className={'main-text w-100 text-truncate d-block h-100'}>{invoice.lot_id}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>}
                        </div>
                    </Col>}

                </Card.Footer>
            </Card>

            <Card className={'mt-2 p-2'}>
                <h5 className="text-uppercase ms-3 mb-0 py-2 second-text">{t(lang, "roaming.common.items.title")}</h5>
                <Card.Body className={'p-0 pt-5 border border-1 rounded-2'}>
                    <RoamingInvoiceViewTable
                        invoice={invoice}
                        getMeasure={getMeasure}
                        hasCommittent={hasCommittent}
                        hasExcise={hasExcise}
                        hasMark={hasMark}
                        hasMedical={hasMedical}
                        getMark={getMark}
                        excise={excise}
                        medical={medical}
                    />
                </Card.Body>
            </Card>

            <Fragment>
                {!isDraft &&
                    <Row className="mt-3 flex-column flex-md-row">
                        <Col xs={12} lg={6} md={6}
                             className="d-flex justify-content-center flex-column align-items-start">
                            {invoice.histories.map((history) => {
                                if (invoiceWrapper.isExecutorSent(history.action))
                                    return <SentHistoryActionSeal key={history.action}
                                                                  serialNumber={history.certificate.serial_number}
                                                                  signingTime={history.certificate.signing_time}
                                                                  fullName={history.certificate.full_name}
                                    />;
                                else if (invoiceWrapper.isExecutorCanceled(history.action))
                                    return <CanceledHistoryActionSeal key={history.action}
                                                                      serialNumber={history.certificate.serial_number}
                                                                      signingTime={history.certificate.signing_time}
                                                                      fullName={history.certificate.full_name}
                                    />;
                            })}
                        </Col>
                        <Col xs={12} lg={6} md={6}
                             className="d-flex justify-content-center flex-column align-items-end">
                            {invoice.histories.map((history) => {
                                if (invoiceWrapper.isCustomerOrPersonDeclined(history.action))
                                    return <DeclinedHistoryActionSeal key={history.action}
                                                                      serialNumber={history.certificate.serial_number}
                                                                      signingTime={history.certificate.signing_time}
                                                                      fullName={history.certificate.full_name}
                                    />
                                else if (invoiceWrapper.isCustomerOrPersonAccepted(history.action))
                                    return <AcceptedHistoryActionSeal key={history.action}
                                                                      serialNumber={history.certificate.serial_number}
                                                                      signingTime={history.certificate.signing_time}
                                                                      fullName={history.certificate.full_name}
                                    />
                            })}
                        </Col>
                    </Row>
                }
                <Row>
                    {zeroVatPrivilegesInfo.map(privilege =>
                        <div key={privilege.id}>
                            <i className="text-dark">
                                <strong className="mx-1"><span
                                    className="text-danger">*</span> {t(lang, "edi.roaming.invoice.this_invoice_contains_privilege")} {t(lang, "edi.roaming.invoice.grouped_privilege_info_id")}
                                </strong>{privilege.id}
                                <strong
                                    className="mx-1">{t(lang, "edi.roaming.invoice.grouped_privilege_info_name")} </strong><span
                                className="text-primary">{privilege.lgota_name}</span>
                                <strong
                                    className="mx-1">{t(lang, "edi.roaming.invoice.grouped_privilege_info_total")} </strong>{privilege.lgota_vat_total_sum} сўм
                                <strong
                                    className="mx-1">{t(lang, "edi.roaming.invoice.grouped_privilege_info_benefit_vat_total_sum")} </strong>{privilege.lgota_vat_sum} сўм</i>
                        </div>
                    )}
                </Row>
            </Fragment>

        </Fragment>
    );
};

export default RoamingInvoice;
