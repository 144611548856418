import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectDateFormat, selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {Controller, useFieldArray, useFormContext} from "react-hook-form";
import {Button, Card, Col, Form, Row, Spinner, Table} from "react-bootstrap";
import classNames from "classnames";
import FindCompanySearchable from '../../../organization/FindCompanySearchable';
import DatePicker from "react-datepicker";
import SelectBranch from '../../../common/SelectBranch';
import SelectOrderExecutorEditStrategy from '../select/SelectOrderExecutorEditStrategy';
import SelectOrderExecutorCreateInvoiceStrategy from '../select/SelectOrderExecutorCreateInvoiceStrategy';
import SelectOrderShipmentDateMethodStrategy from '../select/SelectOrderShipmentDateMethodStrategy';
import SelectOrderProductNameDefineMethodStrategy from '../select/SelectOrderProductNameDefineMethodStrategy';
import SelectOrderItemQuantityLimitStrategy from '../select/SelectOrderItemQuantityLimitStrategy';
import IconButton from "../../../IconButton";
import ItemRow from './ItemRow';
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import SelectOrderContractDefineMethodStrategy
    from "../../return-shipment/select/SelectOrderContractDefineMethodStrategy";

const itemsFieldName = 'items';

const OrderForm = ({loading, existingErrors}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const [allowDeleteItems, setAllowDeleteItems] = useState(false);
    const dateFormat = useSelector(selectDateFormat);

    const {register, formState: {errors}, control, setValue, watch} = useFormContext();
    const {append, fields, remove} = useFieldArray({name: itemsFieldName});

    useEffect(() => {
        setAllowDeleteItems(fields.length > 1);
    }, [fields]);

    const addNewItem = () => {
        append({
            name: '',
            customerSystemId: '',
            customerShipmentId: '',
            barcode: '',
            productCode: '',
            catalogClassCode: '',
            catalogClassName: '',
            measurement: '',
            quantity: '',
            price: '',
            total: '',
            nds: 'No',
            ndsValue: '',
            totalWithNds: '0',
        });
    };

    const onChangeCompany = (company) => {
        setValue('executor', {
            name: company?.name || '',
            inn: company?.inn || ''
        });
    };

    const onNewItemClicked = () => {
        addNewItem();
    };

    const onDeleteItem = (item) => {
        const index = fields.indexOf(item);
        remove(index);
    };

    return (
        <Fragment>

            <Row className={'my-3'}>
                <h5 className="mb-2">{t(lang, "edi.orders.add_order.order_details.title")}</h5>
                <Col xs={12} md={6} className={'pe-1'}>
                    <div className="bg-200 p-2 h-100">
                        <Row>
                            <Col xs={12}  md={6}>
                                <Form.Group className={'mb-2'}>
                                    <Form.Label>{t(lang, "edi.common.executor")}</Form.Label>
                                    <Form.Control disabled={true}
                                                  {...register('executor.name')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12}  md={6}>
                                <Form.Group className={'mb-2'}>
                                    <Form.Label>{t(lang, "edi.common.order.number")}</Form.Label>
                                    <Form.Control type="text"
                                                  placeholder={t(lang, "edi.common.order.number.placeholder")}
                                                  isInvalid={!!errors?.info?.number}
                                                  isValid={Object.keys(errors).length > 0 && !errors?.info?.number}
                                                  {...register('info.number', {
                                                      required: t(lang, "edi.common.forms.validations.is_required"),
                                                      validate: (value) => {
                                                          if (existingErrors?.numberExists) {
                                                              if (value === existingErrors.numberExists) {
                                                                  return t(lang, "edi.common.forms.validations.is_exists", {name: t(lang, "edi.common.forms.keywords.number")});
                                                              }
                                                              return true;
                                                          }
                                                      }
                                                  })}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.info?.number?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12}  md={6}>
                                <Form.Group className={'mb-2'}>
                                    <Form.Label>{t(lang, "edi.common.contract.number")}</Form.Label>
                                    <Form.Control type="text"
                                                  placeholder={t(lang, "edi.common.contract.number.placeholder")}
                                                  isInvalid={!!errors?.contract?.number}
                                                  isValid={Object.keys(errors).length > 0 && !errors?.contract?.number}
                                                  {...register('contract.number', {
                                                      required: t(lang, "edi.common.forms.validations.is_required")
                                                  })}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.contract?.number?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12}  md={6}>
                                <Form.Group className={'mb-2'}>
                                    <Form.Label>{t(lang, "edi.common.shipping_address")}</Form.Label>
                                    <Controller control={control} render={({field}) => (
                                        <SelectBranch
                                            defaultBranchId={field.value?.id}
                                            placeholder={t(lang, "edi.common.shipping_address.placeholder")}
                                            className={classNames({
                                                'is-invalid': errors?.deliveryAddress,
                                            })}
                                            wrapperClassName={classNames({
                                                'is-invalid': errors?.deliveryAddress,
                                            })}
                                            onChange={(branch) => {
                                                field.onChange(branch);
                                            }}/>
                                    )}
                                                name="deliveryAddress"
                                                rules={{
                                                    required: t(lang, "edi.common.forms.validations.is_required"),
                                                    validate: (branch) => {
                                                        if (existingErrors?.branchNotFound) {
                                                            if (branch.id === existingErrors.branchNotFound) {
                                                                return t(lang, "edi.common.forms.validations.not_found", {name: t(lang, "edi.common.branch")});
                                                            }
                                                            return true;
                                                        }
                                                    }
                                                }}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"><>{errors?.deliveryAddress?.message}</></Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group>
                                    <Form.Label>{t(lang, "edi.common.items.product.customer_shipment_id")}</Form.Label>
                                    <Form.Control
                                        placeholder={t(lang, "edi.common.items.product.customer_id.placeholder")}
                                        isValid={Object.keys(errors).length > 0 && !errors?.customerShipmentId}
                                        {...register('customerShipmentId')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col xs={12} md={6} className={'ps-1'}>
                    <div className="bg-200 p-2 h-100">
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group className={'mb-2'}>
                                    <Form.Label>{t(lang, "edi.common.inn")}</Form.Label>
                                    <Controller name="executor.inn"
                                                control={control}
                                                render={({field}) => (
                                                    <FindCompanySearchable onChange={(company) => {
                                                        onChangeCompany(company);
                                                        field.onChange(company?.inn);
                                                    }}
                                                                           inputProps={{
                                                                               isInvalid: errors?.executor?.inn,
                                                                               isValid: Object.keys(errors).length > 0 && !errors?.executor?.inn,
                                                                               placeholder: t(lang, "edi.toast.find_company_searchable:placeholder")
                                                                           }}
                                                                           inputGroupProps={{
                                                                               className: classNames({
                                                                                   'is-invalid': errors?.executor?.inn,
                                                                                   'is-valid': Object.keys(errors).length > 0 && !errors?.executor?.inn
                                                                               }),
                                                                           }}
                                                                           ref={field.ref}
                                                    />
                                                )}
                                                rules={{
                                                    required: t(lang, "edi.common.forms.validations.is_required"),
                                                }}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid">{errors?.executor?.inn?.message}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className={'mb-2'}>
                                    <Form.Label>{t(lang, "edi.common.order.date")}</Form.Label>
                                    <Controller
                                        control={control}
                                        name='info.date'
                                        render={({field}) => (
                                            <DatePicker
                                                placeholderText={t(lang, "edi.common.order.date.placeholder")}
                                                wrapperClassName={classNames({
                                                    'is-invalid': errors?.info?.date,
                                                    'is-valid': Object.keys(errors).length > 0 && !errors?.info?.date
                                                })}
                                                dateFormat={dateFormat}
                                                selected={field.value}
                                                onChange={(date) => field.onChange(date)}
                                                className={classNames('form-control', {
                                                    'is-invalid': errors?.info?.date,
                                                    'is-valid': Object.keys(errors).length > 0 && !errors?.info?.date
                                                })}
                                            />
                                        )}
                                        rules={{
                                            required: t(lang, "edi.common.forms.validations.is_required")
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.info?.date?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className={'mb-2'}>
                                    <Form.Label>{t(lang, "edi.common.contract.date")}</Form.Label>
                                    <Controller
                                        control={control}
                                        name='contract.date'
                                        render={({field}) => (
                                            <DatePicker
                                                placeholderText={t(lang, "edi.common.contract.date.placeholder")}
                                                wrapperClassName={classNames({
                                                    'is-invalid': errors?.contract?.date,
                                                    'is-valid': Object.keys(errors).length > 0 && !errors?.contract?.date
                                                })}
                                                dateFormat={dateFormat}
                                                selected={field.value}
                                                onChange={(date) => field.onChange(date)}
                                                className={classNames('form-control', {
                                                    'is-invalid': errors?.contract?.date,
                                                    'is-valid': Object.keys(errors).length > 0 && !errors?.contract?.date
                                                })}
                                            />
                                        )}
                                        rules={{
                                            required: t(lang, "edi.common.forms.validations.is_required")
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.contract?.date?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className={'mb-2'}>
                                    <Form.Label>{t(lang, "edi.orders.add_order.order_details.final_shipment_date")}</Form.Label>
                                    <Controller
                                        control={control}
                                        name='expiresOn'
                                        render={({field}) => (
                                            <DatePicker
                                                placeholderText={t(lang, "edi.orders.add_order.order_details.final_shipment_date.placeholder")}
                                                wrapperClassName={classNames({
                                                    'is-invalid': errors?.expiresOn,
                                                    'is-valid': Object.keys(errors).length > 0 && !errors?.expiresOn
                                                })}
                                                dateFormat={dateFormat}
                                                selected={field.value}
                                                onChange={(date) => field.onChange(date)}
                                                className={classNames('form-control', {
                                                    'is-invalid': errors?.expiresOn,
                                                    'is-valid': Object.keys(errors).length > 0 && !errors?.expiresOn
                                                })}
                                            />
                                        )}
                                        rules={{
                                            required: t(lang, "edi.common.forms.validations.is_required"),
                                            validate: (value) => {
                                                if (value < watch("info.date")) {
                                                    return t(lang, "edi.common.forms.validations.invalid_format")
                                                }
                                                return true;
                                            }
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        <>{errors?.expiresOn?.message}</>
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group>
                                    <Form.Label>{t(lang, "edi.common.items.product.customer_system_id")}</Form.Label>
                                    <Form.Control
                                        placeholder={t(lang, "edi.common.items.product.customer_id.placeholder")}
                                        isInvalid={!!errors?.customerSystemId}
                                        isValid={Object.keys(errors).length > 0 && !errors?.customerSystemId}
                                        {...register('customerSystemId', {
                                            validate: (value) => {
                                                if (existingErrors?.customerSystemIdExists) {
                                                    if (value === existingErrors.customerSystemIdExists) {
                                                        return t(lang, "edi.common.forms.validations.is_exists", {name: t(lang, "edi.common.forms.keywords.id")});
                                                    }
                                                    return true;
                                                }
                                            }
                                        })}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid">{errors?.customerSystemId?.message}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <Row className="g-3 mb-3">
                <Col xs={12} className="pe-lg-2">
                    <h5 className="mb-2">{t(lang, "edi.orders.add_order.order_lifecycle.title")}</h5>
                    <div className="bg-200 p-2">
                            <Row>
                                <Col xs={12} md={6} xl={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t(lang, "edi.orders.add_order.order_lifecycle.executor_edit_strategy.label")}</Form.Label>
                                        <Controller
                                            render={({field}) => (
                                                <SelectOrderExecutorEditStrategy
                                                    onChange={(strategy) => {
                                                        field.onChange(strategy)
                                                    }}
                                                    placeholder={t(lang, "edi.common.select_button")}
                                                    className={classNames({'is-invalid': !!errors?.executorEditStrategy})}
                                                />
                                            )}
                                            name="executorEditStrategy"
                                            rules={{
                                                required: t(lang, "edi.common.forms.validations.is_required")
                                            }}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid"><>{errors?.executorEditStrategy?.message}</></Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} xl={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t(lang, "edi.orders.add_order.order_lifecycle.executor_invoice_creation_strategy.label")}</Form.Label>
                                        <Controller
                                            render={({field}) => (
                                                <SelectOrderExecutorCreateInvoiceStrategy
                                                    onChange={(strategy) => {
                                                        field.onChange(strategy);
                                                    }}
                                                    placeholder={t(lang, "edi.common.select_button")}
                                                    className={classNames({'is-invalid': !!errors?.executorCreateInvoiceStrategy})}
                                                />
                                            )}
                                            name="executorCreateInvoiceStrategy"
                                            rules={{
                                                required: t(lang, "edi.common.forms.validations.is_required")
                                            }}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid"><>{errors?.executorCreateInvoiceStrategy?.message}</></Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} xl={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t(lang, "edi.orders.add_order.order_lifecycle.shipment_date_strategy.label")}</Form.Label>
                                        <Controller
                                            name="shipmentDateStrategy"
                                            render={({field}) => (
                                                <SelectOrderShipmentDateMethodStrategy
                                                    onChange={(strategy) => {
                                                        field.onChange(strategy);
                                                    }}
                                                    placeholder={t(lang, "edi.common.select_button")}
                                                    className={classNames({'is-invalid': !!errors?.shipmentDateStrategy})}
                                                />
                                            )}
                                            rules={{
                                                required: t(lang, "edi.common.forms.validations.is_required"),
                                            }}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid"><>{errors?.shipmentDateStrategy?.message}</></Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} xl={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t(lang, "edi.orders.add_order.order_lifecycle.goods_name_strategy.label")}</Form.Label>
                                        <Controller
                                            render={({field}) => (
                                                <SelectOrderProductNameDefineMethodStrategy
                                                    onChange={(strategy) => {
                                                        field.onChange(strategy);
                                                    }}
                                                    placeholder={t(lang, "edi.common.select_button")}
                                                    className={classNames({'is-invalid': !!errors?.productNameDefineStrategy})}
                                                />
                                            )}
                                            name="productNameDefineStrategy"
                                            rules={{
                                                required: t(lang, "edi.common.forms.validations.is_required")
                                            }}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid"><>{errors?.productNameDefineStrategy?.message}</></Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} xl={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t(lang, "edi.orders.add_order.order_lifecycle.contract_info_strategy.label")}</Form.Label>
                                        <Controller
                                            render={({field}) => (
                                                <SelectOrderContractDefineMethodStrategy
                                                    onChange={strategy => {
                                                        field.onChange(strategy);
                                                    }}
                                                    placeholder={t(lang, "edi.common.select_button")}
                                                    className={classNames({'is-invalid': !!errors?.contractDefineStrategy})}
                                                />
                                            )}
                                            name="contractDefineStrategy"
                                            rules={{
                                                required: t(lang, "edi.common.forms.validations.is_required")
                                            }}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors?.contractDefineStrategy?.message}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col xs={12} md={6} xl={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t(lang, "edi.orders.add_order.order_lifecycle.goods_quantity_strategy.label")}</Form.Label>
                                        <Controller
                                            render={({field}) => (
                                                <SelectOrderItemQuantityLimitStrategy
                                                    onChange={(strategy) => {
                                                        field.onChange(strategy);
                                                    }}
                                                    placeholder={t(lang, "edi.common.select_button")}
                                                    className={classNames({'is-invalid': !!errors?.itemQuantityLimitStrategy})}
                                                />
                                            )}
                                            name="itemQuantityLimitStrategy"
                                            rules={{
                                                required: t(lang, "edi.common.forms.validations.is_required")
                                            }}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid"><>{errors?.itemQuantityLimitStrategy?.message}</></Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                    </div>
                </Col>
            </Row>

            <Row className="g-3 mb-3">
                <Col xs={12} className="pe-lg-2 mb-3">
                    <div className="h-100">
                            <Row className="align-items-end">
                                <Col>
                                    <h5 className="mb-0">{t(lang, "edi.common.items")}</h5>
                                </Col>
                                <Col xs="auto">
                                    <IconButton variant="phoenix-primary" size="sm" icon={faPlus} transform="shrink-3"
                                                className="me-2" onClick={onNewItemClicked}>
                                        <span className="d-none d-sm-inline-block ms-1">
                                            {t(lang, "edi.common.add_button")}
                                        </span>
                                    </IconButton>
                                </Col>
                            </Row>
                            <Table bordered responsive className="border-bottom fs--1 border-500 mt-3 mb-0">
                                <thead className="text-dark bg-200">
                                <tr>
                                    <th className={'fw-semi-bold'} scope="col">{t(lang, "edi.common.items.product")}</th>
                                    <th className={'fw-semi-bold'} scope="col">{t(lang, "edi.common.items.product.customer_system_id")}</th>
                                    <th className={'fw-semi-bold'} scope="col">{t(lang, "edi.common.items.product.customer_shipment_id")}</th>
                                    <th className={'fw-semi-bold'} scope="col">{t(lang, "edi.common.items.product.barcode")}</th>
                                    <th className={'fw-semi-bold'} scope="col">{t(lang, "edi.common.items.product.product_code")}</th>
                                    <th className={'fw-semi-bold'} scope="col">{t(lang, "edi.common.items.product.catalog_class_code")}</th>
                                    <th className={'fw-semi-bold'} scope="col">{t(lang, "edi.common.items.product.measurement")}</th>
                                    <th className={'fw-semi-bold'} scope="col">{t(lang, "edi.common.items.product.quantity")}</th>
                                    <th className={'fw-semi-bold'} scope="col">{t(lang, "edi.common.items.product.price")}</th>
                                    <th className={'fw-semi-bold'} scope="col">{t(lang, "edi.common.items.product.total")}</th>
                                    <th className={'fw-semi-bold'} scope="col">{t(lang, "edi.common.items.product.nds")}</th>
                                    <th className={'fw-semi-bold'} scope="col">{t(lang, "edi.common.items.product.nds_value")}</th>
                                    <th className={'fw-semi-bold'} scope="col">{t(lang, "edi.common.items.product.total_with_nds")}</th>
                                    <th className={'fw-semi-bold'} scope="col"/>
                                </tr>
                                </thead>
                                <tbody>
                                {fields.map((item, index) => (
                                    <ItemRow item={item} key={item.id} allowDelete={allowDeleteItems}
                                             onDelete={onDeleteItem} arrayFieldName={itemsFieldName} index={index}/>
                                ))}
                                </tbody>
                            </Table>

                            <div className="border-dashed-bottom my-3"/>

                        <Row>
                            <Col className="text-end">
                                <Button type="submit" disabled={loading}>
                                    {loading && <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="me-2"
                                    />}
                                    {t(lang, "edi.common.save_button")}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

        </Fragment>
    );
};

OrderForm.propTypes = {
    loading: PropTypes.bool,
    existingErrors: PropTypes.object,
};

OrderForm.defaultProps = {
    loading: false,
    existingErrors: {},
};


export default OrderForm;
