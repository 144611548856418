import React, {Fragment} from 'react';
import {Col, Row, Table} from "react-bootstrap";
import numeral from "numeral";
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";

const ActViewTable = ({act, getMeasure, subtotal}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const numberFormat = useSelector(selectNumberFormat);

    return (
        <Fragment>
            <Table bordered responsive className="table-view scrollbar border-900 mb-0 fs-9 fw-semi-bold">
                <thead className="text-dark border-bottom bg-card second-text text-center">
                <tr>
                    <th rowSpan={2}>№</th>
                    <th className="fw-semi-bold align-middle">{t(lang, "edi.common.items.product.catalog_class_code")}</th>
                    <th className="fw-semi-bold align-middle">{t(lang, "edi.common.items.product.catalog_class_name")}</th>
                    <th className="fw-semi-bold align-middle">{t(lang, "edi.common.items.product")}</th>
                    <th className="fw-semi-bold align-middle">{t(lang, "edi.common.measurement")}</th>
                    <th className="fw-semi-bold align-middle">{t(lang, "edi.common.items.product.quantity")}</th>
                    <th className="fw-semi-bold align-middle">{t(lang, "edi.common.items.product.price")}</th>
                    <th className="fw-semi-bold align-middle">{t(lang, "edi.common.items.product.total")}</th>
                </tr>
                <tr>
                    <th className="fw-semi-bold p-0 align-middle">1</th>
                    <th className="fw-semi-bold p-0 align-middle">2</th>
                    <th className="fw-semi-bold p-0 align-middle">3</th>
                    <th className="fw-semi-bold p-0 align-middle">4</th>
                    <th className="fw-semi-bold p-0 align-middle">5</th>
                    <th className="fw-semi-bold p-0 align-middle">6</th>
                    <th className="fw-semi-bold p-0 align-middle">7</th>
                </tr>
                </thead>
                <tbody>
                {act.items.map((item, index) => {
                    return (
                        <tr key={item.ordering_number}>
                            <td className="fw-semi-bold align-middle">{index + 1}</td>
                            <td className="fw-semi-bold text-start">{item?.catalog?.code}</td>
                            <td className="fw-semi-bold align-middle">{item?.catalog?.name}</td>
                            <td className="fw-semi-bold align-middle">{item?.name}</td>
                            <td className="fw-semi-bold align-middle">{item.measure_id ? getMeasure(item.measure_id)?.name : item.packageName}</td>
                            <td className="fw-semi-bold align-middle">{item?.quantity}</td>
                            <td className="fw-semi-bold align-middle">{numeral(+item?.price).format(numberFormat)}</td>
                            <td className="fw-semi-bold text-end">{numeral(item?.total).format(numberFormat)}</td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>

            <Row className="g-0 justify-content-end bg-card bg-100">
                <Col xs="auto">
                    <Table borderless size="sm" className="fs--1 text-end">
                        <tbody>
                        <tr>
                            <th className="text-900">{t(lang, "edi.common.items.product.total")}:</th>
                            <td className="fw-semi-bold">
                                {numeral(subtotal).format(numberFormat)}
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Fragment>
    );
};

export default ActViewTable;
