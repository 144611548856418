import React, {Fragment} from 'react';
import {Table, Form} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {Controller, useFormContext} from "react-hook-form";
import OriginLinkSelector from "../../common/OriginLinkSelector";
import SelectCompanyCatalog from "../../IKPU/SelectCompanyCatalog";
import classNames from "classnames";
import ReactSelect from "react-select";
import numeral from "numeral";
import Cleave from "cleave.js/react";

const ReturnInvoiceItemsTable = ({
                                     controlledFields,
                                     itemRootName,
                                     onChangeCatalog,
                                     onBulkChangeOrigin,
                                     onChangeBenefit,
                                     productMapNames,
                                     onChangeQuantity,
                                     onChangeNdsRate,
                                     onChangeOrigin,
                                     onChangePackage,
                                     ndsOptions
                                 }) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const numberFormat = useSelector(selectNumberFormat);

    const {register, setValue, formState: {errors}, getValues} = useFormContext();


    return (
        <Fragment>
            <Table bordered responsive className="border-bottom fs--1 border-500 mt-2 mb-0">
                <thead className="text-dark bg-200">
                <tr className="fs--1">
                    <th className={'fw-semi-bold text-start'}>№</th>
                    <th className={'fw-semi-bold text-start'}>
                        {t(lang, "edi.common.items.product.catalog_class_code")}
                        <span className={'text-danger'}>*</span>
                    </th>
                    <th className={'fw-semi-bold text-start'}>{t(lang, "edi.common.items.product")}</th>
                    <th className={'fw-semi-bold text-start'}>{t(lang, "edi.common.items.product.benefit")}</th>
                    <th className={'fw-semi-bold text-start'}>{t(lang, "edi.common.items.product.barcode")}</th>
                    <th className={'fw-semi-bold text-start'}>{t(lang, "edi.common.items.product.measurement")}</th>
                    <th className={'fw-semi-bold text-start'}>
                        {t(lang, "edi.common.items.product.quantity")}
                        <span className={'text-danger'}>*</span>
                    </th>
                    <th className={'fw-semi-bold text-start'}>{t(lang, "edi.common.items.product.price")}</th>
                    <th className={'fw-semi-bold text-start'}>{t(lang, "edi.common.items.product.total")}</th>
                    <th className={'fw-semi-bold text-start'}>{t(lang, "edi.common.items.product.nds")}</th>
                    <th className={'fw-semi-bold text-start'}>{t(lang, "edi.common.items.product.nds_value")}</th>
                    <th className={'fw-semi-bold text-start'}>{t(lang, "edi.common.items.product.total_with_nds")}</th>
                    <th className={'fw-semi-bold text-start'}>
                        <Controller name={`common.origin`}
                                    render={() => {
                                        return <OriginLinkSelector isTitle={true}
                                                                   onChange={origin => onBulkChangeOrigin(origin)}/>
                                    }}/>
                    </th>
                    {/*<th>*/}
                    {/*    {t(lang, "edi.common.origin")}*/}
                    {/*    <RoamingInvoiceOriginSelector catalogCode={null}*/}
                    {/*                                  defaultOriginType={null}*/}
                    {/*                                  onChange={onBulkChangeOrigin}*/}
                    {/*    />*/}
                    {/*</th>*/}
                </tr>
                </thead>
                <tbody>
                {controlledFields.map((item, index) => {
                    const itemName = `${itemRootName}.${index}`;
                    const itemErrors = errors && errors[itemRootName] && errors[itemRootName][index];

                    const benefitId = item.benefitId;
                    const usePackage = item.usePackage;
                    const packageNames = item.packageNames;
                    const activePackage = packageNames.find(p => p.code === item.packageCode) || null

                    return (
                        <tr className="fw-semi-bold text-center align-middle fs--1" key={item.id}>
                            <td className="p-0">{index + 1}</td>
                            <td className="p-0 w-25 w-lg-auto">
                                <Controller name={`${itemName}.catalogCode`}
                                            rules={{
                                                required: t(lang, "edi.common.forms.validations.is_required")
                                            }}
                                            render={({field}) => (
                                                <SelectCompanyCatalog defaultCatalogCode={field.value}
                                                                      defaultLgota={benefitId}
                                                                      textButton={field.value || t(lang, "edi.common.button.select")}
                                                                      className={classNames('text-primary', {
                                                                          'text-danger': itemErrors?.catalog
                                                                      })}
                                                                      onChange={(catalog, benefit) => {
                                                                          onChangeCatalog(index, catalog);
                                                                          onChangeBenefit(index, benefit);
                                                                      }}
                                                                      variant={itemErrors?.catalogCode ? 'outline-danger' : 'outline-primary'}
                                                />
                                            )}
                                />
                            </td>
                            <td>
                                {productMapNames.length &&
                                    <Form.Control name="product"
                                                  type="text"
                                                  isInvalid={itemErrors?.product}
                                                  isValid={Object.keys(errors).length > 0 && !itemErrors?.product}
                                                  {...register(`${itemName}.product`, {
                                                      required: t(lang, "edi.common.forms.validations.is_required")
                                                  })}
                                    />
                                }
                                {!productMapNames.length && <Fragment>{item.product}</Fragment>}
                            </td>
                            <td className="p-0">
                                {benefitId ? <span
                                        className="text-success">{t(lang, "edi.common.items.product.selected")}</span> :
                                    <span
                                        className="text-warning">{t(lang, "edi.common.items.product.not_selected")}</span>
                                }
                            </td>
                            <td className="p-0">{item.barcode}</td>
                            <td className="p-0">
                                {usePackage &&
                                    <Fragment>
                                        <Controller name={`${itemName}.packageCode`}
                                                    rules={{
                                                        required: t(lang, "edi.common.forms.validations.is_required")
                                                    }}
                                                    render={({field}) => (
                                                        <ReactSelect options={packageNames}
                                                                     hideSelectedOptions
                                                                     isClearable
                                                                     placeholder={t(lang, "edi.common.button.select")}
                                                                     getOptionLabel={option => lang === "ru" ? `${option.name_ru} - ${option.code}` : `${option.name_uz} - ${option.code}`}
                                                                     getOptionValue={option => option.code}
                                                                     classNamePrefix="react-select"
                                                                     menuPortalTarget={document.body}
                                                                     className={classNames({
                                                                         'is-invalid': itemErrors?.packageCode
                                                                     })}
                                                                     onChange={(itemPackage) => onChangePackage(index, itemPackage)}
                                                                     value={activePackage}
                                                        />
                                                    )}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{itemErrors?.packageCode?.message}</Form.Control.Feedback></Fragment>
                                }
                                {!usePackage && <>{item.measurement}</>}
                            </td>
                            <td className="p-0" style={{width: "40px"}}>
                                <Controller name={`${itemName}.quantity`}
                                            rules={{
                                                required: t(lang, "edi.common.forms.validations.is_required")
                                            }}
                                            render={({field}) => (
                                                <Cleave
                                                    className={classNames("form-control", {
                                                        'is-invalid': itemErrors?.quantity,
                                                        'is-valid': Object.keys(errors).length > 0 && !itemErrors?.quantity
                                                    })}
                                                    options={{
                                                        numeral: true,
                                                        delimiter: '',
                                                        numeralDecimalScale: 3
                                                    }}
                                                    onChange={(event) => onChangeQuantity(index, +event.target.rawValue)}
                                                    value={field.value}
                                                />
                                            )}
                                />
                            </td>

                            <td className="px-1 text-nowrap">{numeral(item.price).format(numberFormat)}</td>
                            <td className="px-1 text-nowrap">{numeral(item.quantity * item.price).format(numberFormat)}</td>
                            <td className="p-1">
                                <Form.Select {...register(`${itemName}.ndsRate`)}
                                             onChange={(event) => onChangeNdsRate(index, event.target.value)}>
                                    {ndsOptions.map(ndsOption => {
                                        return <option value={ndsOption.value}
                                                       key={ndsOption.value}>{ndsOption.label}</option>
                                    })}
                                </Form.Select>
                            </td>
                            <td className="px-1 text-nowrap">{numeral(item.ndsValue).format(numberFormat)}</td>
                            <td className="px-1 text-nowrap">{numeral(item.totalWithNds).format(numberFormat)}</td>
                            <td className="px-1">
                                <Controller name={`${itemName}.origin`}
                                            rules={{required: t(lang, 'edi.common.forms.validations.is_required')}}
                                            render={() => {
                                                return <OriginLinkSelector linkAppendClassName={classNames({
                                                    'text-danger': itemErrors?.origin
                                                })}
                                                                           defaultOriginType={item.origin}
                                                                           onChange={origin => onChangeOrigin(index, origin)}/>
                                            }}/>
                            </td>
                            {/*<td className="p-0">*/}
                            {/*    <Controller name={`${itemName}.origin`}*/}
                            {/*                rules={{*/}
                            {/*                    required: t(lang, 'edi.common.forms.validations.is_required')*/}
                            {/*                }}*/}
                            {/*                render={({field}) => (*/}
                            {/*                    <RoamingInvoiceOriginSelector catalogCode={item.catalogCode}*/}
                            {/*                                                  defaultOriginType={field.value}*/}
                            {/*                                                  onChange={(origin) => onChangeOrigin(index, origin)}*/}
                            {/*                                                  selectProps={{*/}
                            {/*                                                      className: classNames({*/}
                            {/*                                                          'is-invalid': itemErrors?.origin*/}
                            {/*                                                      }),*/}
                            {/*                                                      menuPortalTarget: document.body*/}
                            {/*                                                  }}*/}
                            {/*                    />*/}
                            {/*                )}*/}
                            {/*    />*/}
                            {/*    <Form.Control.Feedback type="invalid">{itemErrors?.origin?.message}</Form.Control.Feedback>*/}
                            {/*</td>*/}
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        </Fragment>
    );
};

export default ReturnInvoiceItemsTable;
