import React, {Fragment, useEffect} from 'react';
import {useParams} from "react-router-dom";
import {Translate} from "../../../lang/lang";
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import roamingInvoiceWrapper from "../../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {
    changeFilterOptionsAsync,
    loadRoamingInvoicesAsync, loadRoamingInvoicesCountAsync,
    selectCount, selectInvoiceFilterOptions,
    selectRoamingInvoices
} from "../../../store/reducers/roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import {loadSettings} from "../../../store/reducers/settings/settingsReducer";
import {selectSettings} from "../../../store/reducers/settings/edoSettingsReducer";
import EventBus from "../../../eventbus/EventBus";
import {
    ROAMING_INVOICE_SETTINGS_SERVICE_DATATABLE_COLUMNS_FAILED,
    ROAMING_INVOICE_SETTINGS_SERVICE_DATATABLE_COLUMNS_SUCCESS
} from "../../../eventbus/romaing/roaming-invoice/roamingInvoiceEvents";
import toast from "react-hot-toast";
import {selectAccountRolePermissions} from "../../../store/reducers/role/roleReducer";
import checkPermission from "../../../enum/permission/CheckPermission";
import {PermissionRoamingInvoiceView} from "../../../enum/permission/RoamingPermission";
import PermissionBadge from "../../../components/common/PermissionBadge";
import TypeViewerAction from "../../../components/common/TypeViewerAction";
import RoamingInvoiceDataTable from "../../../components/roaming/invoice/RoamingInvoiceDataTable";
import SpinnerComponent from "../../../components/common/SpinnerComponent";
import {Card} from "react-bootstrap";

const RoamingInvoices = () => {

    const {viewer} = useParams();
    const t = Translate;
    const lang = useSelector(selectLang);
    useSelector(selectAccountRolePermissions)
    const viewers = roamingInvoiceWrapper.GetViewers();
    const activeOrganization = useSelector(selectActiveOrganization);
    const dispatch = useDispatch()
    const settingServiceEdo = useSelector(selectSettings)
    const roamingInvoices = useSelector(selectRoamingInvoices);
    const count = useSelector(selectCount)

    const filters = useSelector(selectInvoiceFilterOptions);

    const loadSetting = () => {
        dispatch(loadSettings())
    }

    // load invoices
    const loadInvoices = () => {
        dispatch(loadRoamingInvoicesAsync(viewer, {...filters, sort: settingServiceEdo.invoice_settings.sort, desc: settingServiceEdo.invoice_settings.desc}));
        dispatch(loadRoamingInvoicesCountAsync(viewer, {...filters}));
    }

  /*  useEffect(() => {
        loadSetting()
    }, [activeOrganization])*/

    // pagination options
    const onPageChange = (page) => dispatch(changeFilterOptionsAsync({...filters, page: page}));
    const onLimitChange = (limit) => dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}));

    useEffect(() => {
        const roamingInvoiceSettingsDatatableColumnsSuccessHandler = EventBus.on(ROAMING_INVOICE_SETTINGS_SERVICE_DATATABLE_COLUMNS_SUCCESS, () => {
            loadSetting()
            toast.success(t(lang, "edi.common.toast.success"))
        })
        const roamingInvoiceSettingsDatatableColumnsFailedHandler = EventBus.on(ROAMING_INVOICE_SETTINGS_SERVICE_DATATABLE_COLUMNS_FAILED, () => {
            loadSetting()
            toast.error(t(lang, "edi.common.toast.error"))
        })

        return () => {
            EventBus.remove(ROAMING_INVOICE_SETTINGS_SERVICE_DATATABLE_COLUMNS_SUCCESS, roamingInvoiceSettingsDatatableColumnsSuccessHandler)
            EventBus.remove(ROAMING_INVOICE_SETTINGS_SERVICE_DATATABLE_COLUMNS_FAILED, roamingInvoiceSettingsDatatableColumnsFailedHandler)
        }
    }, [])


  /*  if (!dispatch(checkPermission(PermissionRoamingInvoiceView))) {
        return <PermissionBadge>
            <span>{t(lang, 'system.permission.roaming.invoice.datatable.no_access.title')}</span>
        </PermissionBadge>
    }*/

    return (
        <Fragment>


            {settingServiceEdo &&
                <RoamingInvoiceDataTable
                    settingServiceEdo={settingServiceEdo}
                    activeColumns={settingServiceEdo?.invoice_settings?.invoice_datatable_column}
                    viewer={viewer}
                    changeFilterOptionsAsync={changeFilterOptionsAsync}
                    loadInvoices={loadInvoices}
                    roamingInvoices={roamingInvoices}
                    count={count}
                    filters={filters}
                    onPageChange={onPageChange}
                    onLimitChange={onLimitChange}
                />

            }
            {!settingServiceEdo && <SpinnerComponent />}

        </Fragment>
    );
};

export default RoamingInvoices;
