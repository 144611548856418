import React from 'react';

const IconChevronLeftDuo = ({width = 24, height = 24, color = "none"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M17 16L13 12L17 8M11 16L7 12L11 8" stroke="#2E3035" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>

    );
};

export default IconChevronLeftDuo;
