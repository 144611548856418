import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {selectDateFormat, selectLang} from "../../../store/reducers/main/main";
import {Controller, useFormContext} from "react-hook-form";
import {Translate} from "../../../lang/lang";
import {Row, Form, Col, Button, FloatingLabel, Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import SelectStatus from "../../common/SelectStatus";
import {FreeFormDocumentStatuses} from "../../../enum/roaming/free-form/FreeFormDocumentStatus";
import DatePickerComponent from "../../common/DatePickerComponent";

const FreeFormDocumentDataTableFilter = () => {
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const {register} = useFormContext();
    const t = Translate;
    return (
        <Fragment>
            <Card className={'p-1'}>
                <Row className="g-3">
                    <Form.Group as={Col} md={6} lg={4}>
                        <FloatingLabel label={t(lang, 'roaming.free_form_document.datatable.header.filter.document_number.label')}>
                            <Form.Control
                                {...register('document_number')}
                                placeholder={t(lang, 'roaming.free_form_document.datatable.header.filter.document_number.label')}
                            />
                        </FloatingLabel>

                    </Form.Group>
                    <Form.Group as={Col} md={6} lg={4}>
                        <Controller
                            name="created_on_start"
                            render={({field}) => (
                                <DatePickerComponent
                                    field={field}
                                    isClearable
                                    placeholderText={t(lang, 'roaming.free_form_document.datatable.header.filter.created_on_start.label')}
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md={6} lg={4}>
                        <Controller
                            name="created_on_end"
                            render={({field}) => (
                                <DatePickerComponent
                                    field={field}
                                    isClearable
                                    placeholderText={t(lang, 'roaming.free_form_document.datatable.header.filter.created_on_end.label')}
                                />
                            )}
                        />
                    </Form.Group>

                    <Form.Group as={Col} md={6} lg={4}>
                        <Controller
                            name="status"
                            render={({field}) => (
                                <SelectStatus
                                    id={"status"}
                                    isClearable
                                    defaultStatus={field.value}
                                    statuses={FreeFormDocumentStatuses}
                                    onChange={field.onChange}
                                    placeholder={t(lang, 'roaming.free_form_document.datatable.header.filter.status.label')}
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md={6} lg={4}>
                        <Controller
                            name="updated_on_start"
                            render={({field}) => (
                                <DatePickerComponent
                                    field={field}
                                    isClearable
                                    placeholderText={t(lang, 'roaming.free_form_document.datatable.header.filter.updated_on_start.label')}
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md={6} lg={4}>
                        <Controller
                            name="updated_on_end"
                            render={({field}) => (
                                <DatePickerComponent
                                    field={field}
                                    isClearable
                                    placeholderText={t(lang, 'roaming.free_form_document.datatable.header.filter.updated_on_end.label')}
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md={6} lg={4}>
                        <FloatingLabel label={t(lang, 'roaming.free_form_document.datatable.header.filter.contractor_inn.label')}>
                            <Form.Control
                                type="search"
                                className="search-input"
                                {...register("contractor_inn")}
                                placeholder={t(lang, 'roaming.free_form_document.datatable.header.filter.contractor_inn.label')}
                            />
                        </FloatingLabel>
                    </Form.Group>
                </Row>
            </Card>

            <Row className="mt-1 g-3 justify-content-end">
                <Form.Group as={Col} sm="auto" controlId="showChange" className={"justify-content-end d-flex mt-0"}>
                    <Button variant="primary" type='submit' className="btn-davr px-3 py-0 bg-primary-davr">{t(lang, "roaming.free_form_document.datatable.header.filter.submit.label")}</Button>
                </Form.Group>
            </Row>
        </Fragment>
    );
};

export default FreeFormDocumentDataTableFilter;
