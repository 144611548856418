import React, {Fragment, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectAccountRolePermissions} from "../../../store/reducers/role/roleReducer";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {selectLang, updateShowInProgressAsync} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import checkPermission from "../../../enum/permission/CheckPermission";
import {PermissionRoamingActView} from "../../../enum/permission/RoamingPermission";
import toast from "react-hot-toast";
import EventBus from "../../../eventbus/EventBus";
import {
    ROAMING_ACT_ACCEPT_FAILED,
    ROAMING_ACT_ACCEPT_REQUESTED,
    ROAMING_ACT_ACCEPT_SUCCESS, ROAMING_ACT_CANCEL_FAILED,
    ROAMING_ACT_CANCEL_REQUESTED, ROAMING_ACT_CANCEL_SUCCESS,
    ROAMING_ACT_DECLINE_FAILED,
    ROAMING_ACT_DECLINE_REQUESTED,
    ROAMING_ACT_DECLINE_SUCCESS
} from "../../../eventbus/romaing/act/roamingActEvents";
import {loadActAsync} from "../../../store/reducers/roaming/act/actReducer";
import Act from "../../../components/roaming/act/view/Act";
import SpinnerComponent from "../../../components/common/SpinnerComponent";
import {getOrganizationBillingInfoAsync} from "../../../store/reducers/billing/billingReducer";

const ViewAct = ({modalId}) => {
    const navigate = useNavigate()
    const permission = useSelector(selectAccountRolePermissions)
    const activeOrganization = useSelector(selectActiveOrganization)
    const [act, setAct] = useState(null);
    const {id} = useParams();
    const dispatch = useDispatch()
    const lang = useSelector(selectLang)
    const t = Translate;

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingActView))) {
                navigate(-1)
        }
    }, [permission])

    useEffect(() => {
        loadAct()
    }, [modalId, id, activeOrganization]);


    useEffect(() => {
        const onActAcceptRequestedHandler = EventBus.on(ROAMING_ACT_ACCEPT_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
            loadAct();
        });

        const onActAcceptSucceessHandler = EventBus.on(ROAMING_ACT_ACCEPT_SUCCESS, () => {
            toast.success(t(lang, "edi.common.toast.success"));
            dispatch(getOrganizationBillingInfoAsync());

            dispatch(updateShowInProgressAsync(false))
            loadAct();
        });

        const onActAcceptFailedHandler = EventBus.on(ROAMING_ACT_ACCEPT_FAILED, () => {
            toast.error(t(lang, "roaming.act_form_save_error"))
            dispatch(updateShowInProgressAsync(false))
            loadAct();
        });

        const onActDeclineRequestedHandler = EventBus.on(ROAMING_ACT_DECLINE_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
            loadAct()
        })

        const onActDeclineSuccessHandler = EventBus.on(ROAMING_ACT_DECLINE_SUCCESS, () => {
            toast.success(t(lang, "edi.common.toast.success"))
            dispatch(updateShowInProgressAsync(false))
            loadAct()
        })

        const onActDeclineFailedHandler = EventBus.on(ROAMING_ACT_DECLINE_FAILED, () => {
            toast.error(t(lang, "roaming.act_form_save_error"))
            dispatch(updateShowInProgressAsync(false))
            loadAct()
        })

        const onActCancelRequestedHandler = EventBus.on(ROAMING_ACT_CANCEL_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
            loadAct()
        })

        const onActCancelSuccessHandler = EventBus.on(ROAMING_ACT_CANCEL_SUCCESS, () => {
            toast.success(t(lang, "edi.common.toast.success"))
            dispatch(updateShowInProgressAsync(false))
            loadAct()
        })

        const onActCancelFailedHandler = EventBus.on(ROAMING_ACT_CANCEL_FAILED, () => {
            toast.error(t(lang, "roaming.act_form_save_error"))
            dispatch(updateShowInProgressAsync(false))
            loadAct()
        })


        return () => {
            EventBus.remove(ROAMING_ACT_ACCEPT_REQUESTED, onActAcceptRequestedHandler);
            EventBus.remove(ROAMING_ACT_ACCEPT_SUCCESS, onActAcceptSucceessHandler);
            EventBus.remove(ROAMING_ACT_ACCEPT_FAILED, onActAcceptFailedHandler);
            EventBus.remove(ROAMING_ACT_DECLINE_REQUESTED, onActDeclineRequestedHandler);
            EventBus.remove(ROAMING_ACT_DECLINE_SUCCESS, onActDeclineSuccessHandler);
            EventBus.remove(ROAMING_ACT_DECLINE_FAILED, onActDeclineFailedHandler);
            EventBus.remove(ROAMING_ACT_CANCEL_REQUESTED, onActCancelRequestedHandler);
            EventBus.remove(ROAMING_ACT_CANCEL_SUCCESS, onActCancelSuccessHandler);
            EventBus.remove(ROAMING_ACT_CANCEL_FAILED, onActCancelFailedHandler);
        }
    },[])

    const loadAct = async () => {
        const getAct = await loadActAsync(modalId ? modalId : id)
        setAct(getAct?.data)
    };


    return (
        <Fragment>
            {act && <Act modalId={modalId} act={act}/>}
            {!act && <SpinnerComponent />}
        </Fragment>
    );
};

export default ViewAct;
