import axios from "axios";


class ContractFormationService {
    getContractFormationAll(params) {
        return axios.get(`/contract-formation/document`,{
            params: params
        })
    }

    getContractFormationCount(params) {
        return axios.get(`/contract-formation/document/count`,{
            params: params
        })
    }

    getContractFormationOne(id) {
        return axios.get(`/contract-formation/document/${id}`)
    }

    contractFormationRegister(ContractSend) {
        return axios.post(`/contract-formation/contract/register`,ContractSend)
    }

    contractFormationUpdate({id, editContract}) {
        return axios.post(`/contract-formation/contract/update/${id}`, editContract)
    }

    contractFormationUpdateItem({id, updateItem}) {
        return axios.post(`/contract-formation/contract/item/update/${id}`, updateItem)
    }

    contractFormationSend({id,contractdata}) {
        return axios.post(`/contract-formation/contract/send/${id}`, contractdata)
    }

    getContractFormationHashcode({id}) {
        return axios.get(`/contract-formation/contract/hashcode/send/${id}`, {
            transformResponse: [data => data],
        })
    }

    contractFormationSign({id, signature, lang}) {
        return axios.post(`/contract-formation/contract/sign/${id}`,{
            signature,
            lang,
        })
    }

    downloadFileContractFormation({file_id, document_id}) {
        return axios.post(`/contract-formation/contract/file`, {
            document_id,
            file_id,
        }, {responseType: "blob"})
    }

    pdfContractFormation(uid) {
        return axios.get(`/contract-formation/contract/pdf/${uid}`, {
            responseType: "blob"
        })
    }


}


const contractFormationService = new ContractFormationService()

export default contractFormationService;
