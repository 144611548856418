import { Navbar } from 'react-bootstrap';
import { useAppContext } from '../../../pheonix/providers/AppProvider';
import classNames from 'classnames';
import NavbarBrand from '../NavbarBrand';
import NavItems from '../nav-items/NavItems';
import NavItemsSlim from '../nav-items/NavItemsSlim';
// import DropdownSearchBox from 'components/common/DropdownSearchBox';
// import SearchResult from 'components/common/SearchResult';
import { useBreakpoints } from '../../../pheonix/providers/BreakpointsProvider';
import NavbarTopRightSide from "./NavbarTopRightSide";

const NavbarTopDefault = () => {
    const {
        config: { navbarTopShape, navbarTopAppearance }
    } = useAppContext();

    const { breakpoints } = useBreakpoints();

    return (
        <Navbar
            className={classNames('navbar-top fixed-top pe-2', {
                'navbar-slim': navbarTopShape === 'slim',
                'navbar-darker': navbarTopAppearance === 'darker'
            })}
            expand
            variant=""
        >
            <div className="navbar-collapse justify-content-between">
                <NavbarBrand />

                {navbarTopShape === 'default' ? (
                    <>
                       {/* {breakpoints.up('lg') && (
                            <DropdownSearchBox
                                className="navbar-top-search-box"
                                inputClassName="rounded-pill"
                                size="sm"
                                style={{ width: '25rem' }}
                            >
                                <SearchResult />
                            </DropdownSearchBox>
                        )}*/}
                        {/*<NavItems />*/}
                        <NavbarTopRightSide />
                    </>
                ) : (
                    <NavItemsSlim />
                )}
            </div>
        </Navbar>
    );
};

export default NavbarTopDefault;
