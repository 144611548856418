import React, {Fragment, useState} from 'react';
import {Translate} from "../../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Controller, useFormContext} from "react-hook-form";
import {Col, Row, Form, Card, FloatingLabel} from "react-bootstrap";
import FindCompanySearchable from "../../../organization/FindCompanySearchable";
import classNames from "classnames";
import SelectDistrict from "../../SelectDistrict";

const EmpowermentMember = ({title, root, inn, draftEmpowerment}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const {register, formState: {errors}, control, setValue} = useFormContext();
    const [districtId, setDistrictId] = useState(null);
    const [eventCount, setEventCount] = useState(0)

    const onChangeCompany = (event) => {
        setEventCount(eventCount + 1)

        setDistrictId(event ? event.district_id : null);
        setValue(`${root}.pinflOrInn`, event?.person_num || event?.inn);
        if (!draftEmpowerment || draftEmpowerment.status || eventCount > 1) {
            setValue(`${root}.name`, event?.name);
            setValue(`${root}.address`, event?.address);
            setValue(`${root}.director`, event?.director);
            setValue(`${root}.accountant`, event?.accountant);
        }
    };


    return (
        <Fragment>
            <h4 className={'fs-8 fw-medium second-text ms-3'}>{title}</h4>
            <Card className="p-2 h-100">
                <Row>
                    <Col xs={12} xl={6} md={6}>
                        <FindCompanySearchable defaultInn={inn}
                                               placeholder={t(lang, "admin.dashboard.organization.title")}
                                               disabled={root === "customerInfo" ? true : false}
                                               onChange={(company) => {
                                                   onChangeCompany(company);
                                               }}
                        />
                    </Col>
                    <Col xs={12} xl={6} md={6}>
                        <FloatingLabel label={t(lang, "roaming.common.inn_or_pinfl")}>
                            <Form.Control name={`${root}.pinflOrInn`}
                                          type="text"
                                          disabled
                                          isValid={Object.keys(errors).length > 0 && !errors[root]?.pinflOrInn}
                                          isInvalid={errors[root]?.pinflOrInn}
                                          {...register(`${root}.pinflOrInn`, {
                                              required: t(lang, 'roaming.validations.is_required'),
                                          })}
                            />
                        </FloatingLabel>
                        <Form.Control.Feedback type="invalid">{errors[root]?.pinflOrInn?.message}</Form.Control.Feedback>
                    </Col>
                   {/* <Col xs={12} className="mt-2">
                        <FloatingLabel label={title}>
                            <Form.Control name={`${root}.name`}
                                          type="text"
                                          placeholder={title}
                                          isValid={Object.keys(errors).length > 0 && !errors[root]?.name}
                                          {...register(`${root}.name`)}
                            />
                        </FloatingLabel>
                    </Col>*/}
                    <Col xs={12} className="mt-2">
                        <FloatingLabel label={t(lang, "roaming.common.address")}>
                            <Form.Control name={`${root}.address`}
                                          type="text"
                                          placeholder={t(lang, "roaming.common.address.placeholder")}
                                          isValid={Object.keys(errors).length > 0 && !errors[root]?.address}
                                          {...register(`${root}.address`)}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mt-2">
                        <Controller control={control}
                                    name={`${root}.districtId`}
                                    rules={{
                                        required: t(lang, 'roaming.validations.is_required'),
                                    }}
                                    render={({field}) => (
                                        <SelectDistrict
                                            className={classNames({
                                                "is-invalid": errors[root]?.districtId,
                                            })}
                                            onChange={(district) => {
                                                setDistrictId(district?.districtId || null);
                                                field.onChange(district?.districtId);
                                            }}
                                            placeholder={t(lang, "roaming.common.district")}
                                            isClearable
                                            defaultDistrictId={districtId}
                                        />
                                    )}
                        />
                        <Form.Control.Feedback
                            type="invalid">{errors[root]?.districtId?.message}</Form.Control.Feedback>
                    </Col>
                    <Col xs={12} sm={6} className="mt-2">
                        <FloatingLabel label={t(lang, "roaming.common.cellPhone")}>
                            <Form.Control name={`${root}.cellPhone`}
                                          type="text"
                                          placeholder={t(lang, "roaming.common.cellPhone")}
                                          isValid={Object.keys(errors).length > 0 && !errors[root]?.cellPhone}
                                          {...register(`${root}.cellPhone`)}
                            />
                        </FloatingLabel>

                    </Col>
                    <Col xs={12} sm={6} className="mt-2">
                        <FloatingLabel label={t(lang, "roaming.common.housePhone")}>
                            <Form.Control name={`${root}.housePhone`}
                                          type="text"
                                          placeholder={t(lang, "roaming.common.housePhone")}
                                          isValid={Object.keys(errors).length > 0 && !errors[root]?.housePhone}
                                          {...register(`${root}.housePhone`)}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col xs={12} xl={6} md={6} className="mt-2">
                        <FloatingLabel label={t(lang, "roaming.common.director")}>
                            <Form.Control name={`${root}.director`}
                                          type="text"
                                          placeholder={t(lang, "roaming.common.director")}
                                          isValid={Object.keys(errors).length > 0 && !errors[root]?.director}
                                          {...register(`${root}.director`)}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col xs={12} xl={6} md={6} className="mt-2">
                        <FloatingLabel label={t(lang, "roaming.common.accountant")}>
                            <Form.Control name={`${root}.accountant`}
                                          type="text"
                                          placeholder={t(lang, "roaming.common.accountant")}
                                          isValid={Object.keys(errors).length > 0 && !errors[root]?.accountant}
                                          {...register(`${root}.accountant`)}
                            />
                        </FloatingLabel>
                    </Col>
                </Row>
            </Card>
        </Fragment>
    );
};

export default EmpowermentMember;
