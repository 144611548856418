export const scrollEvent =  (id) => {
    const verticalScrollPx = window.scrollY || window.pageYOffset;
    const roamingHeader = document.getElementById(id) || 'div'

    if (verticalScrollPx <= 20) {
        if (roamingHeader) {
            // roamingHeader.style.backgroundColor = 'transparent';
            roamingHeader.classList?.remove(...['scroll-event', 'py-2', 'shadow'])
        }
    } else {
        if (roamingHeader) {
            // roamingHeader.style.backgroundColor = 'rgba(242, 243, 246, 1)';
            roamingHeader.classList?.add(...['scroll-event', 'py-2', 'shadow'])
        }
    }
}
