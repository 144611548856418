import React, {Fragment, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {Translate} from "../../lang/lang";
import checkPermission from "../../enum/permission/CheckPermission";
import {PermissionEdiOrderApprove} from "../../enum/permission/EdiPermission";
import toastPermission from "../../enum/permission/ToastPermission";
import {CustomerApproveAsync} from "../../store/reducers/edi/order/orderReducer";
import IconButton from "../IconButton";
import {Button, Modal, Spinner, Form} from "react-bootstrap";
import PropTypes from "prop-types";

const ApproveAction = ({id, approveAsync, iconTitle, modalTitle, btnTitle, icon,permission,variant, ...props}) => {

    const [show, setShow] = useState(false);
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch()
    const handleClose = () => setShow(false);
    const handleShow = () => {
        if (permission) {
            if(dispatch(checkPermission(permission))) setShow(true);
            else dispatch(toastPermission())
        } else {
            setShow(true);
        }
    }

    const onApproved = () => {
        setLoading(true);
        approveAsync({id, comment})
            .then(() => {
                handleClose();
            })
            .catch((e) => {
                console.log(e);
            }).finally(() => setLoading(false))
    }

    return (
        <Fragment>
            <IconButton
                onClick={handleShow}
                variant={variant || "phoenix-primary"}
                size="sm"
                iconClassName="me-1"
                icon={icon}
                className={'fw-semi-bold'}
                {...props}
            >
                <span className={'fw-semi-bold'}>{t(lang, iconTitle)}</span>
            </IconButton>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, modalTitle)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>{t(lang, 'edi.common.label.comment')}</Form.Label>
                        <Form.Control
                            as="textarea" rows={3}
                            placeholder={t(lang, 'edi.common.leave_comment')}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>{t(lang, 'edi.common.button.close')}</Button>
                    <Button variant="primary" className="d-flex align-items-center" disabled={loading} onClick={onApproved}>
                        {loading && <Spinner className="me-2" animation="border" role="status" size="sm" />}
                        {t(lang, btnTitle)}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

ApproveAction.propTypes = {
    id: PropTypes.string,
    approveAsync: PropTypes.func,
    iconTitle: PropTypes.string,
    modalTitle: PropTypes.string
}

ApproveAction.defaultProps = {
    id: "",
    approveAsync: () => {},
    iconTitle: "",
    modalTitle: ""
}

export default ApproveAction;
