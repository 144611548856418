import React, {Fragment, useEffect, useState} from 'react';
import {useFieldArray} from "react-hook-form";
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {selectLang} from "../../../../store/reducers/main/main";
import IconButton from "../../../IconButton";
import {Card, Col, Row, Table} from "react-bootstrap";
import {faBookmark, faPlus, faSignature} from "@fortawesome/free-solid-svg-icons";
import ActItemRow from "./ActItemRow";
import SignActionWithSelectCertificateWrapper from "../../../common/SignActionWithSelectCertificateWrapper";

const ActFormDataTable = () => {
    const [allowDeleteItems, setAllowDeleteItems] = useState(false);
    const itemActFieldItems = 'itemAct';
    const {append, fields, remove} = useFieldArray({name: itemActFieldItems});
    const lang = useSelector(selectLang);
    const t = Translate;

    const addNewItem = () => {
        append({
            catalogClassCode: '',
            Items: '',
            measurementId: '',
            packageCode: null,
            packageName: null,
            packages: null,
            quantity: '',
            price: '',
            totalSum: '',
        });
    };

    const onNewItemClicked = () => {
        addNewItem();
    };

    const onDeleteItem = index => {
        remove(index);
    };

    useEffect(() => {
        setAllowDeleteItems(fields.length > 1);
    }, [fields]);


    return (
        <Fragment>
            <Row className="align-items-center justify-content-between mt-3">
                <Col sm={12}>
                    <h4 className={'fs-8 fw-medium second-text ms-3'}>{t(lang, "edi.common.items")}</h4>
                </Col>
            </Row>

            <Card className={'py-1 px-2 rounded-4 border-0'}>
                <Table  responsive className="scrollbar view-table border-900 mb-0 fs-9 second-text fw-semi-bold">
                    <thead className="text-dark border-bottom">
                    <tr className="position-relative py-2 fs-9 second-text fw-semi-bold">
                        <th className={'fw-semi-bold fw-semi-bold text-center align-middle'} scope="col">#</th>
                        <th className={'fw-semi-bold fw-semi-bold text-center align-middle'} scope="col">{t(lang, "edi.common.items.product.catalog_class_code")}</th>
                        <th className={'fw-semi-bold fw-semi-bold text-center align-middle'} scope="col">{t(lang, "edi.common.items.product")}</th>
                        <th style={{minWidth: 150}} className={'fw-semi-bold fw-semi-bold text-center align-middle'} scope="col">{t(lang, "edi.common.items.product.measurement")}</th>
                        <th className={'fw-semi-bold fw-semi-bold text-center align-middle'} scope="col">{t(lang, "edi.common.items.product.quantity")}</th>
                        <th className={'fw-semi-bold fw-semi-bold text-center align-middle'} scope="col">{t(lang, "edi.common.items.product.price")}</th>
                        <th className={'fw-semi-bold fw-semi-bold text-center align-middle'} scope="col">{t(lang, "edi.common.items.product.total")}</th>
                        <th style={{minWidth: '30px', width: '30px'}}
                            className=" align-middle text-center">
                            <IconButton icon={faPlus}
                                        variant={'phoenix-primary'}
                                        onClick={onNewItemClicked}
                                        className={'bg-primary-davr py-2 px-2 rounded-3'}
                                        iconClassName={'text-white'}
                            />
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {fields.map((item, index) => (
                        <ActItemRow key={item.id} allowDelete={allowDeleteItems}
                                    onDelete={onDeleteItem} arrayActFieldName={itemActFieldItems} index={index}/>
                    ))}
                    </tbody>
                </Table>
            </Card>
        </Fragment>
    );
};

export default ActFormDataTable;
