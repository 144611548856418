import React, {Fragment, useState} from 'react';
import EventBus from "../../../eventbus/EventBus";
import {
    DELETE_WAYBILL_V2_FAILED, DELETE_WAYBILL_V2_REQUESTED, DELETE_WAYBILL_V2_SUCCEED,
    WAYBILL_V2_BULK_ACTION_FINISHED, WAYBILL_V2_BULK_ACTION_STARTED
} from "../../../eventbus/romaing/waybill-v2/roamingWaybillV2Events";
import {useSelector} from "react-redux";
import {deleteWaybillV2DraftAsync} from "../../../store/reducers/roaming/waybill-v2/romaingWaybillV2Reducer";
import IconButton from "../../IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import FalconCloseButton from "../../common/FalconCloseButton";
import {Button, Modal, Spinner} from "react-bootstrap";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";

const WaybillV2DraftDelete = ({id, waybills, hasTitle, handleShowProgressBar, updateActionResult, ...props}) => {

    const t = Translate;
    const lang = useSelector(selectLang);

    // show/hide confirmation modal
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const deleteDraft = () => {
        if (waybills?.length > 0)
            bulkDelete();
        else
            singleDelete();
    }

    // delete single document
    const singleDelete = () => {
        setLoading(true);
        EventBus.dispatch(DELETE_WAYBILL_V2_REQUESTED, id);
        deleteWaybillV2DraftAsync(id)
            .then(() => {
                setLoading(false);
                EventBus.dispatch(DELETE_WAYBILL_V2_SUCCEED, id);
                handleClose();
            })
            .catch(() => {
                EventBus.dispatch(DELETE_WAYBILL_V2_FAILED, id);
                setLoading(false);
            })
    }

    const bulkDelete = async () => {
        handleShowProgressBar();
        EventBus.dispatch(WAYBILL_V2_BULK_ACTION_STARTED);
        for (let i = 0; i < waybills.length; i++) {
            const waybill = waybills[i];
            try {
                await deleteWaybillV2DraftAsync(waybills[i].id);
                updateActionResult({
                    number: waybill.info.number,
                    date: waybill.info.date,
                    contractor: {
                        name: waybill.customer.name,
                        inn: waybill.customer.inn
                    }
                });
            } catch (error) {
                updateActionResult({
                    number: waybill.info.number,
                    date: waybill.info.date,
                    contractor: {
                        name: waybill.customer.name,
                        inn: waybill.customer.inn
                    },
                    error: error?.message
                })
            }
        }
        EventBus.dispatch(WAYBILL_V2_BULK_ACTION_FINISHED);
        handleClose();
    }

    return (
        <Fragment>
            {
                hasTitle ? <span>{t(lang, "roaming.invoice.delete")}</span>
                    :  <IconButton onClick={handleShow}
                                   variant="phoenix-secondary"
                                   className={'btn-white-davr d-flex align-items-center'}
                                   icon={!hasTitle && faTrash}
                                   {...props}
                    >
                        {hasTitle && t(lang, "roaming.invoice.delete")}
                    </IconButton>
            }

            <Modal className={'davr-modal'} show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>
                        {t(lang, "roaming.invoice.delete_draft_modal_title")}
                    </Modal.Title>
                    <FalconCloseButton onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    {t(lang, "roaming.invoice.delete_draft_modal_body")}
                </Modal.Body>
                <Modal.Footer>
                    <Button className={'btn-white-davr'} variant="phoenix-secondary" onClick={handleClose}>
                        {t(lang, "roaming.common.cancel_button")}
                    </Button>
                    <Button className={'btn-primary-davr'} variant="phoenix-secondary"
                            disabled={loading}
                            onClick={deleteDraft}
                    >
                        {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                        {t(lang, "roaming.invoice.delete")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default WaybillV2DraftDelete;
