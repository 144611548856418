import React, {Fragment, useEffect, useState} from 'react';
import {selectDateFormat, selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {Controller, useFormContext} from "react-hook-form";
import dayjs from "dayjs";
import {Row, Form, Col} from "react-bootstrap";
import FindPerson from "../FindPerson";
import classNames from "classnames";
import DatePicker from "react-datepicker";

const AttorneyPerson = () => {

    const lang = useSelector(selectLang);
    const t = Translate;
    const [person, setPerson] = useState(null);
    const dateFormat = useSelector(selectDateFormat);

    const {register, formState: {errors}, control, setValue} = useFormContext();

    useEffect(() => {
        setValue("personInfo.inn", person?.inn);
        setValue("personInfo.pinfl", person?.pinfl);
        setValue("personInfo.fullName", person?.name);
        setValue("personInfo.passportInfo.issuer", person?.passport_issuer);
        setValue("personInfo.passportInfo.issuedOn", person?.passport_issued_date && dayjs(person?.passport_issued_date).toDate());
        setValue("personInfo.passportInfo.serial", person?.passport_serial);
        setValue("personInfo.passportInfo.number", person?.passport_number);
    }, [person])

    return (
        <Fragment>
            <h4 className={'text-center mb-2'}>{t(lang, "edi.attorney.person")}</h4>
            <Row>
                <Col xs={12} lg={6}>
                    <div className="bg-200 p-3 h-100">
                        <Row>
                            <Col xs={12} lg={6} className="mb-2">
                                <Form.Label>{t(lang, "edi.common.inn")}</Form.Label>
                                <Controller name="personInfo.inn"
                                            control={control}
                                            rules={{
                                                required: t(lang, "edi.common.forms.validations.is_required"),
                                            }}
                                            render={({field}) => (
                                                <FindPerson
                                                    defaultInnOrPinfl={field.value}
                                                    onChange={(person) => {
                                                        setPerson(person);
                                                        field.onChange(person?.inn)
                                                    }}
                                                    inputProps={{
                                                        isInvalid: errors?.personInfo?.inn,
                                                        isValid: Object.keys(errors).length > 0 && !errors?.personInfo?.inn,
                                                        placeholder: t(lang, "edi.common.inn.placeholder"),
                                                    }}
                                                    inputGroupProps={{
                                                        className: classNames({
                                                            'is-invalid': errors?.personInfo?.inn,
                                                            'is-valid': Object.keys(errors).length > 0 && !errors?.personInfo?.inn
                                                        }),
                                                    }}
                                                    ref={field.ref}
                                                />
                                            )}

                                />
                                <Form.Control.Feedback type="invalid">{errors?.personInfo?.inn?.message}</Form.Control.Feedback>
                            </Col>
                            <Col xs={12} lg={6} className="mb-2">
                                <Form.Label>{t(lang, "edi.common.inn_or_pinfl")}</Form.Label>
                                <Form.Control name="personInfo.pinfl"
                                              type="text"
                                              {...register("personInfo.pinfl")}
                                              disabled
                                />
                            </Col>

                            <Col xs={12} lg={6} className="mb-2">
                                <Form.Label>{t(lang, "edi.common.name")}</Form.Label>
                                <Form.Control
                                    placeholder={t(lang, "edi.common.name.placeholder")}
                                    name="personInfo.fullName"
                                    type="text"
                                    isInvalid={errors?.personInfo?.fullName}
                                    isValid={Object.keys(errors).length > 0 && !errors?.personInfo?.fullName}
                                    {...register("personInfo.fullName", {
                                        required: t(lang, "edi.common.forms.validations.is_required")
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.personInfo?.fullName?.message}
                                </Form.Control.Feedback>
                            </Col>
                            <Col xs={12} lg={6} className="mb-2">
                                <Form.Label>{t(lang, "edi.attorneys.add_attorney.person.position")}</Form.Label>
                                <Form.Control
                                    placeholder={t(lang, "edi.attorneys.add_attorney.person.position.placeholder")}
                                    name="personInfo.position"
                                    type="text"
                                    isInvalid={errors?.personInfo?.position}
                                    isValid={Object.keys(errors).length > 0 && !errors?.personInfo?.position}
                                    {...register("personInfo.position", {
                                        required: t(lang, "edi.common.forms.validations.is_required")
                                    })}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{errors?.personInfo?.position?.message}</Form.Control.Feedback>
                            </Col>


                        </Row>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="bg-200 p-3 h-100">
                        <Row>
                            <Col xs={12} lg={6} className="mb-2 mb-lg-0">
                                <Form.Label>{t(lang, "edi.attorneys.add_attorney.person.passport_issued_on")}</Form.Label>
                                <Controller control={control}
                                            name="personInfo.passportInfo.issuedOn"
                                            rules={{
                                                required: t(lang, "edi.common.forms.validations.is_required"),
                                                validate: (value) => {
                                                    if (value > new Date()) {
                                                        return t(lang, "edi.common.forms.validations.invalid_format")
                                                    }
                                                    return true;
                                                },
                                            }}
                                            render={
                                                ({field}) => (
                                                    <DatePicker
                                                        selected={field.value}
                                                        onChange={field.onChange}
                                                        placeholderText={t(lang, "edi.attorneys.add_attorney.person.passport_issued_on.placeholder")}
                                                        wrapperClassName={classNames({
                                                            'is-invalid': errors?.personInfo?.passportInfo?.issuedOn
                                                        })}
                                                        className={classNames('form-control', {
                                                            'is-invalid': errors.personInfo?.passportInfo?.issuedOn,
                                                            'is-valid': Object.keys(errors).length > 0 && !errors?.personInfo?.passportInfo?.issuedOn
                                                        })}
                                                        dateFormat={dateFormat}
                                                    />
                                                )
                                            }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.passportInfo?.issuedOn?.message}
                                </Form.Control.Feedback>
                            </Col>
                            <Col xs={12} lg={6} className="mb-2">
                                <Form.Label>{t(lang, "edi.attorneys.add_attorney.person.passport_issuer")}</Form.Label>
                                <Form.Control
                                    placeholder={t(lang, "edi.attorneys.add_attorney.person.passport_issuer.placeholder")}
                                    name="personInfo.passportInfo.issuer"
                                    type="text"
                                    isInvalid={errors?.passportInfo?.issuer}
                                    isValid={Object.keys(errors).length > 0 && !errors?.passportInfo?.issuer}
                                    {...register("personInfo.passportInfo.issuer", {
                                        required: t(lang, "edi.common.forms.validations.is_required")
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.passportInfo?.issuer?.message}
                                </Form.Control.Feedback>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Form.Label>{t(lang, "edi.attorneys.add_attorney.person.passport_serial")}</Form.Label>
                                <Form.Control
                                    placeholder={t(lang, "edi.attorneys.add_attorney.person.passport_serial.placeholder")}
                                    name="personInfo.passportInfo.serial"
                                    type="text"
                                    isInvalid={errors?.passportInfo?.serial}
                                    isValid={Object.keys(errors).length > 0 && !errors?.passportInfo?.serial}
                                    {...register("personInfo.passportInfo.serial", {
                                        required: t(lang, "edi.common.forms.validations.is_required")
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.passportInfo?.serial?.message}
                                </Form.Control.Feedback>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Form.Label>{t(lang, "edi.attorneys.add_attorney.person.passport_number")}</Form.Label>
                                <Form.Control
                                    placeholder={t(lang, "edi.attorneys.add_attorney.person.passport_number.placeholder")}
                                    name="Name"
                                    type="personInfo.passportInfo.number"
                                    className="input-spin-none"
                                    isInvalid={errors?.passportInfo?.number}
                                    isValid={Object.keys(errors).length > 0 && !errors?.passportInfo?.number}
                                    {...register("personInfo.passportInfo.number", {
                                        required: t(lang, "edi.common.forms.validations.is_required")
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.passportInfo?.number?.message}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Fragment>
    );
};

export default AttorneyPerson;
