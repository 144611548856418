import React, {Fragment, useEffect} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {VIEW_INBOX} from "../../enum/roaming/TypeViewers";

const RoamingNavigate = () => {

    const {pathname} = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        navigate(`${pathname}/${VIEW_INBOX}`)
    }, [])

    return (
      <Fragment></Fragment>
    );
};

export default RoamingNavigate;
