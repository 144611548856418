import {createSlice} from "@reduxjs/toolkit";
import ExcelService from "../../services/excel/excelService";

const initialState = {
    templates: [],
    organizationTemplates: [],
}

const excelReducer = createSlice({
    name: "excel",
    initialState,
    reducers: {
        updateTemplates: (state, action) => {
            state.templates = action.payload
        },
        updateOrganizationTemplates: (state, action) => {
            state.organizationTemplates = action.payload
        },
    }
})
export const selectTemplates = (state) => state.excel.templates;
export const selectOrganizationTemplates = (state) => state.excel.organizationTemplates;

export const loadTemplatesAsync = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        ExcelService.getTemplates()
            .then(response => {
                dispatch(excelReducer.actions.updateTemplates(response.data));
                resolve(true);
            })
            .catch(error => {
                reject(error);
            })
    })
};

export const loadOrganizationTemplatesAsync = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        ExcelService.getOrganizationTemplates()
            .then(response => {
                dispatch(excelReducer.actions.updateOrganizationTemplates(response.data));
                resolve(true);
            })
            .catch(error => {
                reject(error);
            })
    })
};

export const updateOrganizationTemplatesAsync = (templates) => {
    return new Promise((resolve, reject) => {
        ExcelService.updateOrganizationTemplates(templates)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    });
}
export const parseExcelFileAsync = (base64Content) => {
    return new Promise((resolve, reject) => {
        ExcelService.parse(base64Content)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export const parseMultiSheetExcelFileAsync = (base64Content) => {
    return new Promise((resolve, reject) => {
        ExcelService.parseMultiSheet(base64Content)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export default excelReducer.reducer
