import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import {registerContractAsync} from "../../../../store/reducers/contract/contractReducer";
import EventBus from "../../../../eventbus/EventBus";
import {REGISTER_CONTRACT_FAILED, REGISTER_CONTRACT_SUCCEED} from "../../../../eventbus/contractEvents";
import toast from "react-hot-toast";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import ContractForm from './EdiContractForm';

const AddEdiContractForm = ({show, onClose}) => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const lang = useSelector(selectLang);
    const t = Translate;
    const [loading, setLoading] = useState(false);
    const [existNumber, setExistNumber] = useState(null);

    const methods = useForm({
        defaultValues: {
            info: {
                number: '',
                date: null
            },
            customer: {
                inn: '',
                name: ''
            },
            executor: {
                inn: activeOrganization.inn,
                name: activeOrganization.name
            }
        }
    });

    const onSubmit = (form) => {
        setLoading(true)
        registerContractAsync({
            info: {
                name: "",
                number: form.info.number,
                date: dayjs(form.info.date).format('YYYY-MM-DD'),
                expire_date: null,
                place: ""
            },
            executor: {
                inn: form.executor.inn,
                name: form.executor.name,
                address: "",
                work_phone: "",
                mobile: "",
                fax: "",
                oked: "",
                account: "",
                mfo: "",
                director_inn: "",
                director_name: "",
                branch_code: "",
                branch_name: "",
                role: ""
            },
            customer: {
                inn: form.customer.inn,
                name: form.customer.name,
                address: "",
                work_phone: "",
                mobile: "",
                fax: "",
                oked: "",
                account: "",
                mfo: "",
                director_inn: "",
                director_name: "",
                branch_code: "",
                branch_name: "",
                role: ""
            },
            items: [],
            parts: [],
            notes: ""
        })
            .then(() => {
                methods.reset();
                onClose();
            })
            .catch((message) => {
                if (message.number_exists) {
                    setExistNumber(form.info.number);
                    methods.trigger('info.number')
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        const contractRegisterSuccess = EventBus.on(REGISTER_CONTRACT_SUCCEED, () => {
            toast.success(t(lang, "edi.common.toast.success"))
        });
        const contractRegisterError = EventBus.on(REGISTER_CONTRACT_FAILED, (data) => {
            toast.error(t(lang, data.message))
        });
        return () => {
            EventBus.remove(REGISTER_CONTRACT_SUCCEED, contractRegisterSuccess)
            EventBus.remove(REGISTER_CONTRACT_FAILED, contractRegisterError)
        }
    }, [])

    return (
        <Modal
            centered
            size="xl"
            show={show}
            onHide={onClose}
            onExit={() => methods.reset()}
        >
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">{t(lang, 'edi.contract.register-form.title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ContractForm existNumber={existNumber}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type='button' variant='default' onClick={onClose}>{t(lang, 'edi.common.button.close')}</Button>
                        <Button type='submit' variant='primary' disabled={loading}>
                            {loading &&
                                <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                            {t(lang, 'edi.common.button.add')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </FormProvider>
        </Modal>
    );
};

export default AddEdiContractForm;
