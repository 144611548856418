import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {selectDateDayJSFormat, selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {
    roamingHideModal,
    selectIsLoading, setRoamingCommonHideModal, setShowModal,
    viewPage
} from "../../../store/reducers/roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import roamingInvoiceWrapper, {
    DRAFT,
    RoamingInvoiceWrapper,
    StatusDeclinedByCustomerOrPerson, StatusSentToCustomer, VIEWER_CUSTOMER, VIEWER_EXECUTOR, VIEWER_PERSON
} from "../../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import Badge from "../../common/Badge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faEye} from "@fortawesome/free-solid-svg-icons";
import {Button, Card, Col, Row} from "react-bootstrap";
import dayjs from "dayjs";
import numeral from "numeral";
import ShowNoteModal from "./ShowNoteModal";
import RoamingInvoiceDraftDelete from "./RoamingInvoiceDraftDelete";
import {ROAMING_INVOICE_DRAFT_DELETE_SUCCESS} from "../../../eventbus/romaing/roaming-invoice/roamingInvoiceEvents";
import EventBus from "../../../eventbus/EventBus";
import AdvanceTableWrapper from "../../table/AdvanceTableWrapper";
import AdvanceTable from "../../table/AdvanceTable";
import RoamingInvoiceDataTableHeader from "./RoamingInvoiceDataTableHeader";
import AdvanceTableCustomPagination from "../../table/AdvanceTableCustomPagination";
import ViewModalPage from "../../common/ViewModalPage";
import ViewRoamingInvoice from "../../../pages/roaming/invoice/ViewRoamingInvoice";
import ViewRoamingInvoiceDraft from "../../../pages/roaming/invoice/ViewRoamingInvoiceDraft";
import TypeViewerAction from "../../common/TypeViewerAction";
import IconFolderDownload from "../../../assets/icon/IconFolderDownload";
import RevealDropdown, {RevealDropdownTrigger} from "../../common/RevealDropdown";
import {Dropdown} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import IconOutboxFolder from "../../../assets/icon/IconOutboxFolder";
import IconInboxFolder from "../../../assets/icon/IconInboxFolder";
import IconDraftFolder from "../../../assets/icon/IconDraftFolder";
import {checkViewer} from "../../../enum/roaming/checkViewer";
const RoamingInvoiceDataTable = ({
                                     viewer,
                                     isSelectable,
                                     predefinedFilters,
                                     onSelect,
                                     activeColumns,
                                     changeFilterOptionsAsync,
                                     roamingInvoices,
                                     loadInvoices,
                                     filters,
                                     count,
                                     onPageChange,
                                     onLimitChange,
                                     returnInvoice,
                                     excelRegistry
                                 }) => {
    const navigate = useNavigate();
    const activeOrganization = useSelector(selectActiveOrganization);
    const lang = useSelector(selectLang);
    const t = Translate;
    const isLoading = useSelector(selectIsLoading);
    const dispatch = useDispatch();
    const numberFormat = useSelector(selectNumberFormat);
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat);
    const {id, draftId, showModal} = useSelector(viewPage);
    const hideModal = useSelector(roamingHideModal);

    const invoicePositive = roamingInvoices?.filter((sumPositive) => sumPositive?.total_info?.total_with_nds > 0)
    const invoiceNegative = roamingInvoices?.filter((sumNegative) => sumNegative?.total_info?.total_with_nds < 0)
    const positiveSumPage = invoicePositive?.reduce((acc, cur) => acc + cur?.total_info?.total_with_nds, 0)
    const negativeSumPage = invoiceNegative?.reduce((acc, cur) => acc + cur?.total_info?.total_with_nds, 0)

    // status filter/tab (key)
    const statuses = roamingInvoiceWrapper.getStatuses(viewer);
    const [activeKey, setActiveKey] = useState(0);
    const [statusName, setStatusName] = useState(0);

    const getActiveColumns = (activeColumns, columns = []) => {
        if (activeColumns?.length) {
            return columns?.filter((col, index) => activeColumns[index]?.active)
        } else {
            return columns
        }
    }

    const openViewModalPage = (e,id) => {
        const ctrlKey = e.ctrlKey
        const history = window.open

        if (viewer === DRAFT) {
            navigate('/roaming/invoice/draft/'+id)
            /*if (ctrlKey) {
                history('/roaming/invoice/draft/'+id, '_blank')
            } else {
                dispatch(setId({draftId: id}))
                dispatch(setShowModal(true))
            }*/
        } else {
            navigate('/roaming/invoice/' + id)
            /*if (ctrlKey) {
                history('/roaming/invoice/' + id, '_blank')
            } else {
                dispatch(setId({id}))
                dispatch(setShowModal(true))
            }*/
        }
    }

    const onHideModalPage = () => {
        dispatch(setShowModal(false))
    }

    // to close the modal on page change
    useEffect(() => {
        if(hideModal) {
            onHideModalPage()
            dispatch(setRoamingCommonHideModal(null))
        }
    }, [])

    useEffect(() => {
        if (filters.status) {
            setActiveKey(filters.status)
            setStatusName(statuses.find(i => i.id === filters.status)?.name)
        } else {
            setActiveKey(0);
            setStatusName(statuses.find(i => i.id === 0)?.name)
        }
    }, [filters])

    const cols = [
        {
            accessor: 'created_at',
            Header: t(lang, "roaming.invoices.created_at"),
            Cell: ({row: {original}}) => {
                return <span className={'fw-semi-bold'}>{dayjs(original.created_at, "YYYY-MM-DD HH:mm:ss").format(currentDateDayJSFormat)}</span>
            }
        },
        {
            accessor: 'contractor',
            Header: t(lang, "roaming.invoices.datatable.header.row.contractor"),
            cellProps: {
                className: 'pe-4'
            },
            headerProps: {width: 300},
            Cell: ({row: {original}}) => {
                if (original.single_sided_type === 0) {
                    if (activeOrganization.inn === original.executor.inn || activeOrganization.pinfl === original.executor.inn)
                        return <div className={'d-flex align-items-center gap-2'}>
                            {checkViewer(viewer)}
                            <div>
                                <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, 'roaming.empowerment.contact_info.from.contact_date', {
                                        "contract_number": original?.info?.number,
                                        "contract_date": original?.info?.date
                                    })}
                                </span>
                                <span className={'fs-8 main-text fw-bold text-truncate'}>{original.customer.name}</span>
                                <i className={'d-block fs-9 second-text'}>{original.customer.inn}</i>
                            </div>
                        </div>
                    else
                        return <div className={'d-flex align-items-center gap-2'}>
                            {checkViewer(viewer)}
                            <div>
                                <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, 'roaming.empowerment.contact_info.from.contact_date', {
                                        "contract_number": original?.info?.number,
                                        "contract_date": original?.info?.date
                                    })}
                            </span>
                                <span className={'fs-8 main-text fw-bold text-truncate'}>{original.customer.name}</span>
                                <i className={'d-block fs-9 second-text'}>{original.customer.inn}</i>
                            </div>
                        </div>
                }
                if (original?.content) {
                    if (activeOrganization?.inn === original?.executor?.inn || activeOrganization?.pinfl === original?.executor?.inn)
                        return <div className={'d-flex align-items-center gap-2'}>
                            {checkViewer(viewer)}
                            <div>
                                <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, 'roaming.empowerment.contact_info.from.contact_date', {
                                        "contract_number": original?.info?.number,
                                        "contract_date": original?.info?.date
                                    })}
                            </span>
                                <span className={'fs-8 main-text fw-bold text-truncate'}>{original.customer.name}</span>
                                <i className={'d-block fs-9 second-text'}>{original.customer.inn}</i>
                            </div>
                        </div>
                    else
                        return <div className={'d-flex align-items-center gap-2'}>
                            {checkViewer(viewer)}
                            <div>
                                <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, 'roaming.empowerment.contact_info.from.contact_date', {
                                        "contract_number": original?.info?.number,
                                        "contract_date": original?.info?.date
                                    })}
                            </span>
                                <span className={'fs-8 main-text fw-bold text-truncate'}>{original.customer.name}</span>
                                <i className={'d-block fs-9 second-text'}>{original.customer.inn}</i>
                            </div>
                        </div>
                }
                return null;
            }
        },
        {
            accessor: 'contract_num',
            Header: t(lang, "roaming.invoices.datatable.header.row.contract_num"),
            Cell: ({row: {original}}) => {
                return <span className={'second-text fs-9'}>№ {original?.contract?.number}</span>
            }
        },
        {
            accessor: 'total_sum',
            Header: t(lang, "roaming.invoices.datatable.header.row.total_sum"),
            cellProps: {className: 'pe-3'},
            headerProps: {className: 'text-end pe-3'},
            Cell: ({row: {original}}) => {
                return (
                    <span className={'d-block text-end fw-semi-bold'}>
                        {/*<strong>{numeral((original.total_info.total_with_nds)).format(numberFormat)}</strong>*/}
                        <strong>{numeral['formats'][numberFormat].format((original.total_info.total_with_nds))}</strong>
                        <span className={'ms-1 second-text fs-9'}>UZS</span>
                    </span>
                )
            }
        },
       {
            accessor: 'status',
            Header: () => t(lang, "roaming.invoices.datatable.header.row.status"),
            headerProps: {className: 'text-center'},
            cellProps: {className: 'text-center'},
            Cell: ({row: {original}}) => {
                const invoiceWrapper = new RoamingInvoiceWrapper(original)
                const showDeclineModal = StatusDeclinedByCustomerOrPerson === original.status
                if (showDeclineModal) {
                    return (
                        <ShowNoteModal
                            notes={original.notes}
                            bg={invoiceWrapper.getStatusVariant(activeOrganization.pinfl || activeOrganization.inn)}
                            title={t(lang, invoiceWrapper.getStatusText(activeOrganization.pinfl || activeOrganization.inn))}
                        />
                    )
                } else {
                    return (
                        <div className={'d-inline-block text-center'}>
                            <Badge
                                className={'px-3 py-1 fs-9 text-uppercase w-100 text-decoration-none fw-medium border-0 rounded-4'}
                                bg={invoiceWrapper.getStatusVariant(activeOrganization.pinfl || activeOrganization.inn) !== 'dark' ?
                                    invoiceWrapper.getStatusVariant(activeOrganization.pinfl || activeOrganization.inn) : 'secondary'}
                                variant={invoiceWrapper.getStatusVariant(activeOrganization.pinfl || activeOrganization.inn) !== 'dark' ?
                                    'phoenix' : null}
                            >
                                {t(lang, invoiceWrapper.getStatusText(activeOrganization.pinfl || activeOrganization.inn))}
                            </Badge>
                        </div>
                    )
                }
            }
        },
        {
            accessor: 'action',
            Header: "",
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                        <RevealDropdown>
                            <Dropdown.Item
                                onClick={(event) => {
                                    openViewModalPage(event, original.id)
                                }}>
                                    <span>{t(lang, "roaming.invoices.table_header.show")}</span>
                            </Dropdown.Item>
                            <Fragment>
                                {viewer === DRAFT ? <Dropdown.Item className="text-danger">
                                    <RoamingInvoiceDraftDelete
                                        dataTable
                                        id={original.id}
                                        size="sm"
                                    />
                                </Dropdown.Item> : null}
                            </Fragment>
                        </RevealDropdown>
                    </RevealDropdownTrigger>
                );
            }
        },
    ];
    const columns = getActiveColumns(activeColumns, cols);

    if (viewer === DRAFT) {
        let index = cols.findIndex(i => i.accessor === 'status')
        if (index !== -1) {
            columns.splice(index, 1)
        }
    }

    // push a new column if is selectable (choose action)
    if (isSelectable)
        columns.push({
            accessor: 'none',
            Header: '123',
            disableSortBy: true,
            headerProps: {
                className: 'text-center'
            },
            cellProps: {
                className: 'text-center'
            },
            Cell: ({row: {original}}) => {
                return (
                    <Button variant="primary" size="sm" className="mb-1" onClick={() => onSelect(original)}>
                        {t(lang, "roaming.common.select.placeholder")}
                    </Button>
                )
            }
        })

    // load invoices
    useEffect(() => {
        const onDraftDeletedHandler = EventBus.on(ROAMING_INVOICE_DRAFT_DELETE_SUCCESS, () => {
            loadInvoices();
        })

        loadInvoices();

        return () => {
            EventBus.remove(ROAMING_INVOICE_DRAFT_DELETE_SUCCESS, onDraftDeletedHandler);
        }
    }, [activeOrganization, filters])

    // save previous viewer for match in reducer
    const usePrevious = (value) => {
        const ref = useRef();

        useEffect(() => {
            ref.current = value;
        }, [value])

        return ref.current;
    };
    const previousViewer = usePrevious(viewer);

    // change filters on viewer change
    useEffect(() => {
        if ((viewer && previousViewer) && viewer !== previousViewer) {
            let params = {...filters};

            // initialize status
            params.status = StatusSentToCustomer;

            if (previousViewer === VIEWER_CUSTOMER && (viewer === VIEWER_EXECUTOR || viewer === DRAFT)) {
                params.customer_inn = null;
                params.executor_inn = null;
                params.customer_branch_code = null;
                params.executor_branch_code = null;
            }

            if ((previousViewer === VIEWER_EXECUTOR || previousViewer === DRAFT) && viewer === VIEWER_CUSTOMER) {
                params.customer_inn = null;
                params.executor_inn = null;
                params.customer_branch_code = null;
                params.executor_branch_code = null;
            }

            if (previousViewer === VIEWER_CUSTOMER && viewer === VIEWER_PERSON) {
                params.customer_inn = null;
                params.customer_branch_code = null;
            }

            if ((previousViewer === VIEWER_EXECUTOR || previousViewer === DRAFT) && viewer === VIEWER_PERSON) {
                params.executor_inn = null;
                params.executor_branch_code = null;
            }

            if (previousViewer === VIEWER_PERSON && viewer === VIEWER_CUSTOMER) {
                params.customer_inn = null;
                params.customer_branch_code = null;
            }

            if (previousViewer === VIEWER_PERSON && (viewer === VIEWER_EXECUTOR || viewer === DRAFT)) {
                params.executor_inn = null;
                params.executor_branch_code = null;
            }
            dispatch(changeFilterOptionsAsync(params));
        }
    }, [viewer])

    return (
        <Fragment>
                <AdvanceTableWrapper
                    columns={columns}
                    data={roamingInvoices}
                    pagination
                    perPage={1000}
                    selection
                    sortable={true}
                    selectionColumnWidth={50}
                >
                    <RoamingInvoiceDataTableHeader
                        returnInvoice={returnInvoice}
                        excelRegistry={excelRegistry}
                        viewer={viewer}
                        predefinedFilters={predefinedFilters}
                        loadInvoices={loadInvoices}
                        positiveSumPage={positiveSumPage}
                        negativeSumPage={negativeSumPage}
                        table
                        changeFilterOptionsAsync={changeFilterOptionsAsync}
                        filters={filters}
                        count={count}
                    />
                    <Card className={'p-0 rounded-4'}>
                        <Card.Body className={'p-0'}>
                            <Row>
                                <Col sm={12} xl={6}>
                                    <TypeViewerAction to={"/roaming/invoices"} typeViewer={viewer} />
                                </Col>
                                <Fragment>
                                    {(viewer !== DRAFT && !predefinedFilters?.hasOwnProperty("status")) && <Col sm={12} xl={6} className={'d-flex justify-content-end align-items-center'}>
                                        <Dropdown className={'me-2'}>
                                            <Dropdown.Toggle
                                                variant=""
                                                className="p-0 dropdown-caret-none text-decoration-none">
                                                {t(lang, statusName)}
                                                <FontAwesomeIcon icon={faChevronDown} className="ms-2"/>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className='py-2'>
                                                {statuses.map((status, index) => (
                                                    <Dropdown.Item key={index}  onClick={() => {
                                                        setActiveKey(status.id)
                                                        setStatusName(status.name)
                                                        dispatch(changeFilterOptionsAsync({...filters, status: status.id !== 0 ? status.id : null}))
                                                    }}>
                                                        <span
                                                            className={`${status.id === activeKey ? 'text-primary-davr fw-semi-bold' : 'second-text'}`}>{t(lang, status.name)}</span>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>}
                                </Fragment>
                            </Row>
                            <AdvanceTable
                                table
                                headerClassName="text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                isLoading={isLoading}
                                tableProps={{
                                    size: 'sm',
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                        </Card.Body>
                    </Card>
                    <AdvanceTableCustomPagination
                        count={count}
                        limit={filters.limit}
                        page={filters.page}
                        onLimitChange={onLimitChange}
                        onPageChange={onPageChange}
                    />
                </AdvanceTableWrapper>

            <ViewModalPage onHide={onHideModalPage} show={showModal} >
                {id && <ViewRoamingInvoice modalId={id}/>}
                {draftId && <ViewRoamingInvoiceDraft modalId={draftId} />}
            </ViewModalPage>

        </Fragment>
    );
};

export default RoamingInvoiceDataTable;
