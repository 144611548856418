import React, {Fragment} from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import FalconCloseButton from "../../common/FalconCloseButton";
import {useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectCurrency} from "../../../store/reducers/currency/currencyReducer";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";

const CurrencyModal = ({show, setShow, UpdateRate, cur}) => {

    const {register, getValues} = useFormContext()
    const lang = useSelector(selectLang)
    const t = Translate

    return (
        <Fragment>
            <Modal show={show}
                   className={'davr-modal'}
                   onHide={() => setShow(false)}
                   aria-labelledby="contained-modal-title-vcenter"
                   centered>
                <Modal.Header>
                    <Modal.Title>{t(lang, 'main.top.nav.profile_dropdown.dropdown_item.currency')}</Modal.Title>
                    <FalconCloseButton onClick={() => setShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Control
                            type="number"
                            className={"text-center"}
                            {...register(`currency.${cur?.index}.rate`)}
                            disabled={cur?.original?.is_national}
                            defaultValue={cur?.original?.rate}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={'btn-white-davr'} onClick={() => setShow(false)}>
                        {t(lang, 'items.common.cancel')}
                    </Button>
                    <Button className={'btn-primary-davr'} onClick={() => UpdateRate(cur?.original?.id, cur?.index)}>
                        {t(lang, 'roaming.common.save')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default CurrencyModal;
