import React, {useState} from 'react';
import {selectCurrentCertificate} from "../../../../store/reducers/eimzo/eimzoReducer";
import {useSelector} from "react-redux";
import roamingWaybillV2Service from "../../../../store/services/roaming/waybill-v2/roamingWaybillV2Service";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import EventBus from "../../../../eventbus/EventBus";
import {ACTION_PROGRESS_BAR_CLOSED} from "../../../../eventbus/actionProgressBarEvents";
import {
    WAYBILL_V2_BULK_ACTION_FINISHED,
    WAYBILL_V2_BULK_ACTION_STARTED
} from "../../../../eventbus/romaing/waybill-v2/roamingWaybillV2Events";
import {sendWaybillV2Async} from "../../../../store/reducers/roaming/waybill-v2/romaingWaybillV2Reducer";
import SelectCertificateWrapperComponent from "../../../common/SelectCertificateWrapperComponent";
import IconButton from "../../../IconButton";
import {Spinner} from "react-bootstrap";
import IconSignature from "../../../../assets/icon/IconSignature";

const WaybillV2DraftSendAll = ({
                                   filters,
                                   predefinedFilters,
                                   setCount,
                                   handleShowProgressBar,
                                   updateActionResult,
                                   ...props
                               }) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const activeCertificate = useSelector(selectCurrentCertificate);

    // button loader
    const [loading, setLoading] = useState(false);

    const loadAllDrafts = ({page, limit, ...filters}) => {
        const skip = (page - 1) * limit;
        const params = {skip, limit, ...filters};

        return roamingWaybillV2Service.getWaybillV2Drafts(params)
            .then(res => res.data)
            .catch((e) => console.log(e))
    }

    const onSendTriggered = async () => {
        if (!window.confirm(t(lang, 'roaming.invoice.send_button_all_confirmation_text')))
            return;

        let stop = false
        const onActionProgressBarCloseHandler = EventBus.on(ACTION_PROGRESS_BAR_CLOSED, () => {
            stop = true
        })

        handleShowProgressBar();
        EventBus.dispatch(WAYBILL_V2_BULK_ACTION_STARTED);

        // load waybills
        let waybills = []
        let page = 1
        let limit = 1000;
        while (true) {
            const finalFilters = {...filters, ...predefinedFilters, limit: limit, page: page}
            const data = await loadAllDrafts(finalFilters)
            if (data.length <= 0)
                break

            for (let i = 0; i < data.length; i++) {
                waybills.push(data[i])
            }
            page++
        }

        setCount(waybills.length);

        const taskSize = 10;
        const tasks = []

        const signer = async waybill => {
            if (stop) {
                return
            }
            try {
                await sendWaybillV2Async(activeCertificate, lang, {hashCode: waybill.content});
                updateActionResult({
                    number: waybill.info.number,
                    date: waybill.info.date,
                    contractor: {
                        name: waybill.customer.name,
                        inn: waybill.customer.inn_or_pinfl || waybill.customer.inn
                    }
                });
            } catch (error) {
                updateActionResult({
                    number: waybill.info.number,
                    date: waybill.info.date,
                    contractor: {
                        name: waybill.customer.name,
                        inn: waybill.customer.inn_or_pinfl || waybill.customer.inn
                    },
                    error: error?.message
                })
            }
        }

        for (let i = 0; i < waybills.length; i++) {
            tasks.push(signer(waybills[i]))

            if (tasks.length >= taskSize) {
                await Promise.all(tasks)
                tasks.splice(0, tasks.length)
            }
        }

        if (tasks.length > 0) {
            await Promise.all(tasks)
            tasks.splice(0, tasks.length)
        }

        EventBus.remove(ACTION_PROGRESS_BAR_CLOSED, onActionProgressBarCloseHandler)
        EventBus.dispatch(WAYBILL_V2_BULK_ACTION_FINISHED);
    };

    return (
        <SelectCertificateWrapperComponent submitButton={
            <IconButton
                variant="phoenix-secondary"
                id="DraftSendAll"
                disabled={loading}
                onClick={onSendTriggered}
                className={"btn-primary-davr m-0"}
                {...props}
            >
                {loading ? <Spinner size="sm" className='align-middle me-2' animation='border' role='switch'/> :
                    <IconSignature/>}

                {t(lang, "roaming.invoice.send_button_all")}
            </IconButton>
        } openDialogButtonText={t(lang, "roaming.invoice.send_button_all")}
        />
    );
};

export default WaybillV2DraftSendAll;
