import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {useFormContext} from "react-hook-form";
import {
    changeActiveSigner, clearActiveSigner,
    selectReturnInvoiceActiveDirector,
    selectReturnInvoiceDirectors
} from "../../../store/reducers/edi/return-invoice/returnInvoiceReducer";
import ActionButton from "../../common/ActionButton";
import CreatableSelect from "react-select/creatable";

const SelectDirector = ({onCreate, onDelete, ...props}) => {
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const directors = useSelector(selectReturnInvoiceDirectors);
    const activeDirector = useSelector(selectReturnInvoiceActiveDirector);
    const {setValue} = useFormContext();

    const formatOptionLabel = (option) => {
        const deleteOption = option => dispatch(onDelete(option));
        return (
            <div className="d-flex justify-content-between text-dark">
                <div>{option.label}</div>
                {!option.lastSelected && <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-1" onClick={(event) => {
                    event.stopPropagation();
                    deleteOption(option);
                }}
                />}
            </div>
        )
    }

    const createOption = (label) => {
        let newOption = {
            label,
            arrayFieldName: "directors",
            lastSelected: false,
        }
        onCreate(newOption);
    };

    const formatCreateLabel = label => `${t(lang, "edi.common.add_button")} ${label}`;
    const noOptionsMessage = () => t(lang, "edi.common.director.no_options.message");

    useEffect(() => {
        if (activeDirector) {
            setValue('director', activeDirector.label)
        } else {
            setValue('director', '')
        }
    },[activeDirector])

    return (
        <CreatableSelect options={directors}
                         onChange={(director) => director ? dispatch(changeActiveSigner(director)) : dispatch(clearActiveSigner(activeDirector))}
                         onCreateOption={createOption}
                         formatCreateLabel={formatCreateLabel}
                         formatOptionLabel={(option) => option?.__isNew__ ? option.label : formatOptionLabel(option)}
                         getOptionValue={option => option.label}
                         getOptionLabel={option => option.label}
                         noOptionsMessage={() => noOptionsMessage()}
                         value={activeDirector}
                         placeholder={t(lang, "edi.common.button.select")}
                         isClearable
                         hideSelectedOptions
                         classNamePrefix="react-select"
                         {...props}
        />
    );
};

export default SelectDirector;
