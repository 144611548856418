import React, {Fragment, useState} from 'react';
import {ButtonGroup, Dropdown, DropdownButton} from "react-bootstrap";
import Button from "./Button";
import IconPlus from "../../assets/icon/IconPlus";
import {Translate} from "../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faGear} from "@fortawesome/free-solid-svg-icons";
import {roamingServices} from "../../enum/roaming/roamingWrapper";
import {Link} from "react-router-dom";
import {settingsServices} from "../../enum/settings/settingsServices";

const DashboardDropdownButton = ({settings}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    const [btnType, setBtnType] = useState(settings || false)
    const wrapper = btnType ?  settingsServices : roamingServices;

    return (
        <ButtonGroup>
            <Button onClick={() => !settings ? setBtnType(!btnType) : () => {}} className="btn-davr px-3 py-0 bg-primary-davr text-white" variant="falcon-default">
                <div className={'d-flex align-items-center'}>
                    {btnType ? <Fragment>
                        <FontAwesomeIcon icon={faGear} className={'text-white me-2'}/>
                        <span className="d-none d-sm-inline-block fw-middle ms-1 fs-8 text-nowrap fs-md-9">{t(lang, "main.top.nav.profile_dropdown.dropdown_item.settings")}</span>
                    </Fragment> : <Fragment>
                        <IconPlus/>
                        <span className="d-none d-sm-inline-block fw-middle ms-1 fs-8 text-nowrap fs-md-9">{t(lang, "roaming.common.add.btn")}</span>
                       </Fragment>}
                   </div>
            </Button>
            <DropdownButton className="border-left-0 btn-davr px-0 bg-primary-davr text-white" variant="falcon-default" as={ButtonGroup} title="" id="bg-nested-dropdown">
                {wrapper.map((item, index) => <Dropdown.Item className={'d-flex justify-content-between'} as={Link} to={item?.query ? `${item.to}?${item.query}=${true}` : item.to} key={index}>
                    <span>{t(lang, item.title)}</span>
                    <FontAwesomeIcon icon={faChevronRight} />
                </Dropdown.Item>)}
            </DropdownButton>
        </ButtonGroup>
    );
};

export default DashboardDropdownButton;
