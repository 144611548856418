import React from 'react';
import {Card, Dropdown, ListGroup} from "react-bootstrap";
import {Link} from "react-router-dom";
import IconMessage from "../../../assets/icon/IconMessage";
import PaymentInstruction from "./PaymentInstruction";
import IconMail from "../../../assets/icon/IconMail";

const NavbarTopBarMailDropdown = () => {
    return (
        <Dropdown navbar={true} as={'li'}>
            <Dropdown.Toggle
                bsPrefix="toggle"
                as={Link}
                to="#!"
                className="p-1 nav-link text-center"
            >
                <IconMail />
            </Dropdown.Toggle>
            <Dropdown.Menu className="navbar-dropdown-caret py-0 shadow border border-300 dropdown-menu-end">
                <Card className="dropdown-menu-notification shadow-none border-0">
                    <ListGroup variant="flush" className=" fw-normal fs--1 scrollbar" style={{width: '12rem'}}>
                        <ListGroup.Item as={Link} className={'text-decoration-none'}>
                            <PaymentInstruction />
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default NavbarTopBarMailDropdown;
