import useToggleStyle from "./pheonix/hooks/useToggleStyle";
import {useAppContext} from './pheonix/providers/AppProvider';
import {Fragment, PropsWithChildren, useEffect} from 'react';
import {Outlet, redirect, useLocation} from 'react-router-dom';
import Section from './components/common/Section';
import {Col, Row, Spinner} from "react-bootstrap";
import {
    INITIALIZATION_STATE_COMPLETE,
    selectActiveOrganization,
    selectInitializationState,
    selectIsAuthorized,
    authStatus
} from "./store/reducers/auth/authReducer";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {loadAccountPermissionAsync} from "./store/reducers/role/roleReducer";
import {loadCompany} from "./store/reducers/roaming/roamingReferenceReducer";
import {registerCustomNumberFormats} from "./helpers/customNumberFormat";

axios.defaults.baseURL = process.env.REACT_APP_HOST;
axios.defaults.withCredentials = true;
registerCustomNumberFormats()
const App = () => {
    const {isStylesheetLoaded} = useToggleStyle();
    const {pathname} = useLocation();
    const dispatch = useDispatch()
    const activeOrganization = useSelector(selectActiveOrganization);
    const isAuthorized = useSelector(selectIsAuthorized);
    const navigate = useNavigate()
    const status = useSelector(authStatus);

    const {
        config: {theme, isRTL}
    } = useAppContext();


    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        if (activeOrganization) {
            dispatch(loadAccountPermissionAsync());
            dispatch(loadCompany(activeOrganization.person_num || activeOrganization.inn));
        }
    }, [activeOrganization])


    return (
        <Loader>
            {
                !isStylesheetLoaded ?
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        backgroundColor: theme === 'dark' ? '#000' : '#fff'
                    }}/> :
                       <Outlet />
            }
        </Loader>
    );
};

const Loader = ({children}) => {
    const initializationState = useSelector(selectInitializationState);
    const dispatch = useDispatch();
    const activeOrganization = useSelector(selectActiveOrganization)

    // useEffect(() => {
    //     dispatch(authInitializeAsync())
    //     dispatch(eimzoInitializeAsync())
    // }, []);

    useEffect(() => {
        // activeOrganization && dispatch(loadAccountPermissionAsync())
    }, [activeOrganization])


    if (initializationState === INITIALIZATION_STATE_COMPLETE)
        return children;

    return (
        <Section className="py-0">
            <Row className="flex-center min-vh-100 py-6">
                <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4 text-center">
                    <Spinner animation="grow"/>
                </Col>
            </Row>
        </Section>
    );
};

export default App;
