import React, {Fragment, useEffect, useState} from 'react';
import useQuery from "../../../components/roaming/form/useQuery";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {selectAccountRolePermissions} from "../../../store/reducers/role/roleReducer";
import {selectItems} from "../../../store/reducers/item/itemReducer";
import {
    loadCatalogsByCode,
    selectCompany,
    selectCurrentTurnOver
} from "../../../store/reducers/roaming/roamingReferenceReducer";
import {selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {useNavigate} from "react-router-dom";
import checkPermission from "../../../enum/permission/CheckPermission";
import {PermissionRoamingInvoiceCreate} from "../../../enum/permission/RoamingPermission";
import {
    loadRoamingInvoiceAsync,
    loadRoamingInvoiceDraftAsync
} from "../../../store/reducers/roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import {getSaleItemAsync} from "../../../store/reducers/warehouse/saleReducer";
import {loadWaybillAsync} from "../../../store/reducers/roaming/waybill/roamingWaybillReducer";
import {uniq} from "underscore";
import {Button, Modal} from "react-bootstrap";
import numeral from "numeral";
import SendRoamingInvoiceForm from "../../../components/roaming/invoice/send/SendRoamingInvoiceForm";
import FalconCloseButton from "../../../components/common/FalconCloseButton";

const SendRoamingInvoice = () => {
    const {invoice_id, draft_invoice_id, sale_id, waybill_id, type, is_edit} = useQuery();
    const dispatch = useDispatch();
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentTurnOver = useSelector(selectCurrentTurnOver);
    const permission = useSelector(selectAccountRolePermissions);
    const items = useSelector(selectItems);
    const navigate = useNavigate();
    const [invoice, setInvoice] = useState(null);
    const [draftInvoice, setDraftInvoice] = useState(null);
    const [sale, setSale] = useState(null);
    const [waybill, setWaybill] = useState(null);
    const [catalogs, setCatalogs] = useState([]);
    const [turnOverAlert, setTurnOverAlert] = useState(false)
    const company = useSelector(selectCompany);
    const lang = useSelector(selectLang);
    const t = Translate;
    const numberFormat = useSelector(selectNumberFormat);

    useEffect(() => {
        if (company && currentTurnOver) {
            if (!company.vat_reg_code) {
                if (currentTurnOver.currentTurnover > currentTurnOver.limit) {
                    setTurnOverAlert(true)
                }
            }
        }
    }, [currentTurnOver, company])

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingInvoiceCreate))) {
            navigate(-1)
        }
    }, [permission])

    useEffect(() => {
        invoice_id && loadRoamingInvoiceAsync(invoice_id)
            .then((loadedInvoice) => {
                setInvoice(loadedInvoice);
            })
            .catch((error) => {
                setInvoice(null);
            });
    }, [invoice_id, activeOrganization]);

    useEffect(() => {
        draft_invoice_id && loadRoamingInvoiceDraftAsync(draft_invoice_id)
            .then((loadedDraftInvoice) => {
                setDraftInvoice(loadedDraftInvoice);
            })
            .catch((error) => {
                draftInvoice(null);
            })
    }, [draft_invoice_id, activeOrganization]);

    useEffect(() => {
        sale_id && getSaleItemAsync({saleId: sale_id})
            .then((response) => {
                const sale = response.data;
                setSale(sale);
            })
            .catch((error) => {
                setSale(null);
            })
    }, [sale_id, activeOrganization]);

    useEffect(() => {
        waybill_id && loadWaybillAsync(waybill_id)
            .then((response) => {
                setWaybill(response);
            })
            .catch((error) => {
                console.log(error);
                setWaybill(null);
            })
    }, [waybill_id, activeOrganization])

    const loadCatalogsByCodeEffectOneAsync = async () => {
        if (invoice) {
            try {
                const unknownCatalogCodes = uniq(invoice.items.map((item) => item.catalog?.code || ""));
                const catalogs = await loadCatalogsByCode({codes: unknownCatalogCodes});
                setCatalogs(catalogs);
            } catch (e) {
                setCatalogs([]);
            }
        }
    }

    useEffect( () => {
        loadCatalogsByCodeEffectOneAsync()
    }, [invoice]);

    const loadCatalogsByCodeEffectTwoAsync = async () => {
        if (draftInvoice) {
            try {
                const roamingInvoiceJson = JSON.parse(draftInvoice.content);
                const unknownCatalogCodes = uniq(roamingInvoiceJson.ProductList.Products.map((item) => item.CatalogCode || ""));
                const catalogs = await loadCatalogsByCode({codes: unknownCatalogCodes});
                setCatalogs(catalogs);
            } catch (e) {
                setCatalogs([]);
            }
        }
    }

    useEffect( () => {
        loadCatalogsByCodeEffectTwoAsync()
    }, [draftInvoice])

    const loadCatalogsByCodeEffectThreeAsync = async () => {
        if (sale) {
            try {
                const unknownCatalogCodes = sale.items.map((item) => {
                    const originalItem = items.find(i => i.item.name.trim().toLowerCase() === item.warehouse_item.name.trim().toLowerCase());
                    return (originalItem && originalItem.item.tax && originalItem.item.tax.catalog?.code) ? originalItem.item.tax.catalog.code : "";
                });
                const catalogs = await loadCatalogsByCode({codes: unknownCatalogCodes});
                setCatalogs(catalogs);
            } catch (e) {
                setCatalogs([]);
            }
        }
    }

    useEffect( () => {
        loadCatalogsByCodeEffectThreeAsync()
    }, [sale, items]);

    const loadCatalogsByCodeEffectFourAsync = async () => {
        if (waybill) {
            try {
                const unknownCatalogCodes = uniq(waybill.items.map((item) => item.catalog?.code || ""));
                const catalogs = await loadCatalogsByCode({codes: unknownCatalogCodes});
                setCatalogs(catalogs)
            } catch (e) {
                setCatalogs([]);
            }
        }
    }

    useEffect( () => {
        loadCatalogsByCodeEffectFourAsync()
    }, [waybill]);

    return (
        <Fragment>
            {turnOverAlert && <Modal
                show={true}
                className={'davr-modal'}
                onHide={() => setTurnOverAlert(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">{t(lang, 'roaming.invoice.alert.current-turnover.title')}</Modal.Title>
                    <FalconCloseButton onClick={() => setTurnOverAlert(false)} />
                </Modal.Header>
                <Modal.Body>
                    {!!currentTurnOver &&
                        <p className="fw-bold">
                            {t(lang, 'roaming.invoice.alert.current-turnover.body', {
                                sum: numeral(currentTurnOver.currentTurnover).format(numberFormat),
                                limit: numeral(currentTurnOver.limit).format(numberFormat)
                            })}
                        </p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button className={'btn-primary-davr'} onClick={() => setTurnOverAlert(false)}>{t(lang, 'roaming.invoice.alert.current-turnover.button.ok')}</Button>
                </Modal.Footer>
            </Modal>}

            <SendRoamingInvoiceForm invoice={invoice}
                                    draftInvoice={draftInvoice}
                                    sale={sale}
                                    waybill={waybill}
                                    catalogs={catalogs}
                                    type={type}
                                    isEdit={is_edit}
                                    isEditOrCopy={!!(invoice || draftInvoice)}
            />

        </Fragment>
    );
};

export default SendRoamingInvoice;
