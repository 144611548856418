import React, {useEffect, useState} from 'react';
import ReactSelect from "react-select";
import {singleSidedInvoiceTypes} from "../../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import {selectLang} from "../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import CustomFloatingLabelSelect from "../../floating-custom-select/CustomFloatingLabelSelect";

const RoamingInvoiceFormSingleSideTypeSelector = ({defaultType, onChange, placeholder}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [value, setValue] = useState(null);

    useEffect(() => {
        setValue(singleSidedInvoiceTypes.find(o => o.value === defaultType))
    }, [defaultType]);

    const handleChange = (option) => {
        setValue(option)
        onChange(option)
    }

    return <div className={'select'}>
        <CustomFloatingLabelSelect
            options={singleSidedInvoiceTypes}
            getOptionValue={option => option.value}
            getOptionLabel={option => t(lang, option.label)}
            value={value}
            onChange={(option) => handleChange(option.value)}
            isOptionDisabled={option => option.disabled}
            isClearable={false}
            isSearchable={false}
            label={placeholder}
        />
   </div>
};

export default RoamingInvoiceFormSingleSideTypeSelector;
