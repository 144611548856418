import React, {Fragment, useState} from 'react';
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import {Controller, useForm} from "react-hook-form";
import {bindRoleIntoAccountAsync} from "../../../store/reducers/role/roleReducer";
import {Button, Col, Form, Modal, Spinner} from "react-bootstrap";
import SelectRole from "./SelectRole";

const BindRoleIntoAccountFormModal = ({account, defaultRole, onHide, show}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const [loading, setLoading] = useState(false)

    const {handleSubmit, control, formState: {errors}} = useForm({
        defaultValues: {
            role: defaultRole || null,
            username: account.username
        },
    });

    const onSubmit = (formData) => {
        setLoading(true);
        bindRoleIntoAccountAsync({
            account_id: account.id,
            roleId: formData.role.id
        }).then(r => {
            setLoading(false)
            onHide()
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <Fragment>
            <Modal show={show} onHide={onHide}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header>
                        <Modal.Title>{t(lang, "hippo.user.settings.bind_role_into_to_account_form.title")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="text-start mb-3">
                            <Form.Label>{t(lang, "edi.branch.datatable.header.row.code")}</Form.Label>
                            <Form.Control
                                value={account.username}
                                disabled/>
                        </Form.Group>
                        <Form.Group as={Col} md={12} lg={12}>
                            <Form.Label>Роль</Form.Label>
                            <Controller
                                name="role"
                                control={control}
                                rules={{
                                    required: t(lang, "edi.common.forms.validations.is_required")
                                }}
                                render={({field}) => (
                                    <SelectRole defaultRole={defaultRole}
                                                onChange={role => field.onChange(role)}/>
                                )}
                            />
                            <div className={"text-danger small"}>
                                {errors?.role?.message}
                            </div>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={onHide}>
                            {t(lang, "edi.common.cancel_button")}
                        </Button>
                        <Button variant="primary" disabled={loading} type="submit">
                            {loading && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                            />}
                            {t(lang, "edi.common.save_button")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    );
};

export default BindRoleIntoAccountFormModal;
