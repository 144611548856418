import React, {Fragment} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {Col, Row, Form, Card, FloatingLabel} from "react-bootstrap";
import FindCompanySearchable from "../../../organization/FindCompanySearchable";
import FindPerson from "../../../edi/attorney/FindPerson";

const OwnerInfo = () => {
    const { register, setValue, formState: { errors }, control } = useFormContext()
    const activeOrganization = useSelector(selectActiveOrganization)
    const lang = useSelector(selectLang)
    const t = Translate
    const onChangeHandler = async data => {
        if(data) {
            setValue('ownerTin', data.person_num || data.inn)
            setValue('ownerName', data.name)
            setValue('ownerFizTin', data.director_pinfl || data.director_inn)
            setValue('ownerFizFio', data.director)
        }
    }

    const onChangeDirectorHandler = (director) => {
        if(director) {
            setValue('ownerFizTin', director.pinfl || director.inn, {shouldValidate: true})
            setValue('ownerFizFio', director.name, {shouldValidate: true})
        } else {
            setValue('ownerFizTin', '')
            setValue('ownerFizFio', '')
        }
    }

    return (
        <Fragment>
            <h4 className={'fs-8 fw-medium second-text ms-3'}>{t(lang, "roaming.verification_act.add.verification_act.owner_info.title")}</h4>

            <Card className="p-2">
                <Row>
                    <Col sm="6" className="mb-2">
                        <Controller
                            name="ownerTin"
                            control={control}
                            render={() => (
                                <FindCompanySearchable
                                    id={'ownerTin'}
                                    placeholder={t(lang, "roaming.common.label.name")}
                                    defaultInn={activeOrganization.pinfl || activeOrganization.inn}
                                    onChange={onChangeHandler}
                                    disabled={true}
                                />
                            )}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.ownerTin?.message)}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-2">
                        <FloatingLabel label={t(lang, "roaming.verification_act.add.verification_act.owner_info.inn_or_pinfl")}>
                            <Form.Control
                                {...register("ownerName", {
                                    required: "edi.common.forms.validations.is_required"
                                })}
                                placeholder={t(lang, "roaming.verification_act.add.verification_act.owner_info.inn_or_pinfl")}
                                className={errors?.ownerName?.message && "is-invalid"}
                            />
                        </FloatingLabel>
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.ownerName?.message)}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-2">
                        <Controller
                            name="ownerFizTin"
                            control={control}
                            rules={{
                                required: "edi.common.forms.validations.is_required"
                            }}
                            render={({field}) => (
                                <FindPerson
                                    placeholder={t(lang, "roaming.verification_act.add.verification_act.owner_info.person_inn_or_pinfl")}
                                    id={'ownerFizTin'}
                                    onChange={onChangeDirectorHandler}
                                    defaultInnOrPinfl={field.value}
                                    inputProps={{
                                        className: errors?.ownerFizTin?.message && 'is-invalid',
                                    }}
                                    inputGroupProps={{
                                        className: errors?.ownerFizTin?.message && 'is-invalid'
                                    }}
                                />
                            )}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.ownerFizTin?.message)}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-2">
                        <FloatingLabel label={t(lang, "roaming.verification_act.add.verification_act.owner_info.person_name")}>
                            <Form.Control
                                {...register("ownerFizFio", {
                                    required: "edi.common.forms.validations.is_required"
                                })}
                                placeholder={t(lang, "roaming.verification_act.add.verification_act.owner_info.person_name")}
                                className={errors?.ownerFizFio?.message && "is-invalid"}
                            />
                        </FloatingLabel>
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.ownerFizFio?.message)}
                        </Form.Control.Feedback>
                    </Col>
                </Row>
            </Card>
        </Fragment>
    );
};

export default OwnerInfo;
