import React, {useState} from 'react';
import {Form} from "react-bootstrap";

const ndsOptions = [
    {value: "", label: 'Без НДС'},
    {value: 0, label: '0%'},
    {value: 12, label: '12%'},
    {value: 15, label: '15%'}
];
const SelectNdsVatRate = ({onChange, defaultValue, ...props}) => {
    const [value, setValue] = useState(defaultValue);
    const onChangeHandler = (event) => {
        let { value: rate } = event.target
        setValue(rate)
        if(rate) return onChange(Number(rate))
        return onChange(null)
    }
    return (
        <Form.Select value={value} onChange={onChangeHandler} {...props}>
            {ndsOptions.map((ndsOption, index) => {
                return <option value={ndsOption.value} key={index}>{ndsOption.label}</option>
            })}
        </Form.Select>
    );
};

export default SelectNdsVatRate;
