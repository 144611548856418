import RoamingService from "../store/services/roaming/roamingService";

const fromCharCode = String.fromCharCode;
const cb_utob = c => {
    if (c.length < 2) {
        let cc = c.charCodeAt(0);
        return cc < 0x80 ? c
            : cc < 0x800 ? (fromCharCode(0xc0 | (cc >>> 6))
                    + fromCharCode(0x80 | (cc & 0x3f)))
                : (fromCharCode(0xe0 | ((cc >>> 12) & 0x0f))
                    + fromCharCode(0x80 | ((cc >>> 6) & 0x3f))
                    + fromCharCode(0x80 | (cc & 0x3f)));
    } else {
        let cc = 0x10000
            + (c.charCodeAt(0) - 0xD800) * 0x400
            + (c.charCodeAt(1) - 0xDC00);
        return (fromCharCode(0xf0 | ((cc >>> 18) & 0x07))
            + fromCharCode(0x80 | ((cc >>> 12) & 0x3f))
            + fromCharCode(0x80 | ((cc >>> 6) & 0x3f))
            + fromCharCode(0x80 | (cc & 0x3f)));
    }
};
const re_utob = /[\uD800-\uDBFF][\uDC00-\uDFFFF]|[^\x00-\x7F]/g;
const utob = u => {
    return u.replace(re_utob, cb_utob);
};
const _encode = u => {
    return btoa(utob(u))
};
const encode = (u, urisafe) => {
    return !urisafe
        ? _encode(u)
        : _encode(u).replace(/[+\/]/g, function (m0) {
            return m0 === '+' ? '-' : '_';
        }).replace(/=/g, '');
}

export default class Eimzo {
    _url;
    _hostname;
    _apiKey;
    _isConnected;

    get isConnected() {
        return this._isConnected;
    }

    loadDisks() {
        const self = this;
        return new Promise((resolve, reject) => {
            self._makeRequest({plugin: "pfx", name: "list_disks"})
                .then(response => {
                    resolve(response);
                }, reject);
        });
    }

    getCertificates() {
        return new Promise((resolve, reject) => {
            const self = this;
            self.loadDisks()
                .then(({disks, success}) => {
                    if (success) {
                        const promises = [];

                        disks.forEach(disk => {
                            promises.push(self._makeRequest({
                                plugin: "pfx",
                                name: "list_certificates",
                                arguments: [disk]
                            }));
                        });

                        const loadedCertificates = [];

                        Promise.all(promises).then(promiseResults => {
                            promiseResults.forEach(({certificates, success}) => {
                                if (success) {
                                    certificates.forEach(certificate => {
                                        loadedCertificates.push({...certificate, keyId: null, activateTimeStamp: null});
                                    })
                                }
                            });

                            resolve(loadedCertificates);
                        })
                            .catch(reject);

                    } else {
                        reject();
                    }
                }, reject)
        });
    }

    activate(certificate) {
        const pfx = new CertificatePfx(certificate)
        return new Promise((resolve, reject) => {
            const data = {
                plugin: pfx.type,
                name: 'load_key',
                arguments: [
                    pfx.disk,
                    pfx.path,
                    pfx.name,
                    (pfx.type === 'pfx' ? pfx.alias : pfx.serialNumber)
                ]
            };

            this._makeRequest(data)
                .then(({success, keyId}) => {
                    if (success) {
                        this.createPkcs7("activation", keyId)
                            .then(({signatureHex, signerSerialNumber}) => {
                                const activatedCertificate = {...certificate, keyId: keyId, activateTimeStamp: new Date().getTime()};

                                resolve(activatedCertificate);
                            }, ({invalidPassword, canceled}) => {
                                reject({invalidPassword, canceled})
                            })
                    } else {
                        reject();
                    }
                }, reject);
        });
    }

    createPkcs7(hashCode, keyId) {
        return new Promise((resolve, reject) => {
            const data = {
                plugin: "pkcs7",
                name: "create_pkcs7",
                arguments: [
                    encode(hashCode),
                    keyId,
                    "no"
                ]
            };
            this._makeRequest(data)
                .then(({success, pkcs7_64, signature_hex, signer_serial_number, className, reason}) => {
                    if (success) {
                        resolve({pkcs7: pkcs7_64, signatureHex: signature_hex, signerSerialNumber: signer_serial_number});
                        return
                    }

                    if (className === 'IOException') {
                        reject({invalidPassword: true});
                    } else if (reason === 'Ввод пароля отменен') {
                        reject({canceled: true})
                    } else {
                        reject({reason});
                    }
                }, q => {
                    console.log(q);
                })
                .catch((q) => {
                    console.log(q);
                });
        });
    }

    createPkcs7WithTimestamp(hashCode, keyId) {
        return new Promise((resolve, reject) => {
            const data = {
                plugin: "pkcs7",
                name: "create_pkcs7",
                arguments: [
                    encode(hashCode),
                    keyId,
                    "no"
                ]
            };
            this._makeRequest(data)
                .then(({success, pkcs7_64, signature_hex, signer_serial_number, className, reason}) => {
                    if (success) {
                        RoamingService.attachTimestamp(pkcs7_64)
                            .then(({data: {status: attachTimestampStatus, pkcs7b64: signature}}) => {
                                if (attachTimestampStatus === 1)
                                    resolve({pkcs7: signature, signatureHex: signature_hex, signerSerialNumber: signer_serial_number});
                                else
                                    reject({reason: "Неверный ответ при прикреплении штампа времени к подписи"});
                            }, () => {
                                reject({reason: "Не удалось прикрепить штамп времени к подписи"});
                            })
                    } else if (className === 'IOException') {
                        reject({invalidPassword: true});
                    } else if (reason === 'Ввод пароля отменен') {
                        reject({canceled: true})
                    } else {
                        reject({reason});
                    }
                }, console.log)
                .catch(console.log);
        });
    }

    attachPkcs7(pkcs7, keyId){
        return new Promise((resolve, reject) => {
            const data = {
                plugin: "pkcs7",
                name: "append_pkcs7_attached",
                arguments: [
                    pkcs7,
                    keyId
                ]
            };
            this._makeRequest(data)
                .then(({success, pkcs7_64, signature_hex, signer_serial_number, className, reason}) => {
                    if (success) {
                        resolve({pkcs7: pkcs7_64, signatureHex: signature_hex, signerSerialNumber: signer_serial_number});
                        return
                    }

                    if (className === 'IOException') {
                        reject({invalidPassword: true});
                    } else if (reason === 'Ввод пароля отменен') {
                        reject({canceled: true})
                    } else {
                        reject({reason});
                    }
                }, q => {
                    console.log(q);
                })
                .catch((q) => {
                    console.log(q);
                });
        });
    }

    attachPkcs7WithTimestamp(pkcs7, keyId) {
        return new Promise((resolve, reject) => {
            const data = {
                plugin: "pkcs7",
                name: "append_pkcs7_attached",
                arguments: [
                    pkcs7,
                    keyId
                ]
            };
            this._makeRequest(data)
                .then(({success, pkcs7_64, signature_hex, signer_serial_number, className, reason}) => {
                    if (success) {
                        RoamingService.attachTimestamp(pkcs7_64)
                            .then(({data: {status: attachTimestampStatus, pkcs7b64: signature}}) => {
                                if (attachTimestampStatus === 1)
                                    resolve({pkcs7: signature, signatureHex: signature_hex, signerSerialNumber: signer_serial_number});
                                else
                                    reject({reason: "Неверный ответ при прикреплении штампа времени к подписи"});
                            }, () => {
                                reject({reason: "Не удалось прикрепить штамп времени к подписи"});
                            })
                    } else if (className === 'IOException') {
                        reject({invalidPassword: true});
                    } else if (reason === 'Ввод пароля отменен') {
                        reject({canceled: true})
                    } else {
                        reject({reason});
                    }
                }, console.log)
                .catch(console.log);
        });
    }

    attachTimestamp(pkcs7, serialNumber, timestamp) {
        return new Promise((resolve, reject) => {
            const data = {
                plugin: "pkcs7",
                name: "attach_timestamp_token_pkcs7",
                arguments: [
                    pkcs7,
                    serialNumber,
                    timestamp
                ]
            };
            this._makeRequest(data)
                .then(({success, pkcs7_64, className, reason}) => {
                    if (success) {
                        resolve({pkcs7: pkcs7_64});
                        return
                    }

                    if (className === 'IOException') {
                        reject({invalidPassword: true});
                    } else if (reason === 'Ввод пароля отменен') {
                        reject({canceled: true})
                    } else {
                        reject({reason});
                    }
                }, q => {
                    console.log(q);
                })
                .catch((q) => {
                    console.log(q);
                });
        });
    }

    constructor(url, hostname, apiKey) {
        this._url = url;
        this._hostname = hostname;
        this._apiKey = apiKey;
        this._isConnected = false;

        this._init();
    }

    _init() {
        this._makeRequest({
            name: 'apikey',
            arguments: [this._hostname, this._apiKey]
        }).then(({success}) => {
            this._isConnected = success;
        }, () => {
            console.log('e-imzo initialization failed')
        });
    }

    _makeRequest(data) {
        return new Promise((resolve, reject) => {
            let socket;
            try {
                socket = new WebSocket(this._url);
                socket.onopen = () => {
                    socket.send(JSON.stringify(data));
                };
                socket.onmessage = ({data}) => {
                    socket.close();
                    resolve(JSON.parse(data));
                };
                socket.onerror = () => {
                    socket.close();
                    reject();
                };
            } catch (e) {
                reject();
            }
        });
    }
}

export class CertificatePfx {
    get type() {
        return this._type;
    }

    get disk() {
        return this._disk;
    }

    get path() {
        return this._path;
    }

    get name() {
        return this._name;
    }

    get serialNumber() {
        return this._serialNumber;
    }

    get validFromDate() {
        return this._validFromDate;
    }

    get validEndDate() {
        return this._validEndDate;
    }

    get innNumber() {
        return this._innNumber;
    }

    get pinfl() {
        return this._pinfl;
    }

    get companyName() {
        return this._companyName;
    }

    get issuedPerson() {
        return this._issuedPerson;
    }

    get alias() {
        return this._alias;
    }

    constructor(eimzoCertificateObject) {
        const aliasValues = eimzoCertificateObject.alias.split(',');
        let innNumber = this._getValueFromSeparatedValuesArray(aliasValues, '1.2.860.3.16.1.1', null);
        if (innNumber === null) {
            innNumber = this._getValueFromSeparatedValuesArray(aliasValues, 'INN', null);
            if (innNumber === null)
                innNumber = this._getValueFromSeparatedValuesArray(aliasValues, 'UID', '');
        }

        const pinfl = this._getValueFromSeparatedValuesArray(aliasValues, '1.2.860.3.16.1.2', null);

        const todaysLastMoment = new Date();
        todaysLastMoment.setHours(23);
        todaysLastMoment.setMinutes(59);
        todaysLastMoment.setSeconds(59);
        todaysLastMoment.setMilliseconds(999);

        let validFrom = this._getValueFromSeparatedValuesArray(aliasValues, 'validfrom', null);
        if (validFrom === null || validFrom === undefined)
            validFrom = todaysLastMoment;
        else
            validFrom = new Date(validFrom.split('.').join('-'));

        let validTo = this._getValueFromSeparatedValuesArray(aliasValues, 'validto', null);
        if (validTo === null || validTo === undefined)
            validTo = todaysLastMoment;
        else
            validTo = new Date(validTo.split('.').join('-'));

        this._type = 'pfx';
        this._disk = eimzoCertificateObject.disk;
        this._path = eimzoCertificateObject.path;
        this._name = eimzoCertificateObject.name;
        this._serialNumber = this._getValueFromSeparatedValuesArray(aliasValues, 'serialnumber', '');
        this._validFromDate = new Date(validFrom);
        this._validEndDate = new Date(validTo);
        this._innNumber = innNumber;
        this._pinfl = pinfl;
        this._companyName = this._getValueFromSeparatedValuesArray(aliasValues, 'o', '');
        this._issuedPerson = this._getValueFromSeparatedValuesArray(aliasValues, 'cn', '');
        this._alias = eimzoCertificateObject.alias;
    }

    _getValueFromSeparatedValuesArray(values, key, defaultValue) {
        const keyInUpperCase = `${key.toUpperCase()}=`;

        for (let i = 0; i < values.length; i++) {
            const value = values[i];
            if (value.length >= keyInUpperCase.length)
                if (value.substr(0, keyInUpperCase.length).toUpperCase() === keyInUpperCase)
                    return value.substr(keyInUpperCase.length);
        }

        if (typeof (defaultValue) === 'undefined')
            defaultValue = null;

        return defaultValue;
    }
}
