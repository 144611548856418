import React, {Fragment} from 'react';
import {Table} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";

const EmpowermentViewTable = ({empowerment,getMeasure}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const numberFormat = useSelector(selectNumberFormat);
    return (
        <Fragment>
            <Table bordered responsive className="table-view scrollbar border-900 mb-0 fs-9 fw-semi-bold">
                <thead className="text-dark border-bottom bg-card second-text text-center">
                <tr>
                    <th className="fw-semi-bold align-middle" rowSpan={2}>{t(lang, 'edi.attorney.data_table.product_number')}</th>
                    <th className="fw-semi-bold align-middle">{t(lang, 'roaming.common.items.product')}</th>
                    <th className="fw-semi-bold align-middle">{t(lang, 'roaming.common.items.catalog_class_code')}</th>
                    <th className="fw-semi-bold align-middle">{t(lang, 'roaming.common.items.quantity')}</th>
                    <th className="fw-semi-bold align-middle">{t(lang, 'roaming.common.items.measurement')}</th>
                </tr>
                <tr>
                    <th className="fw-semi-bold align-middle">1</th>
                    <th className="fw-semi-bold align-middle">2</th>
                    <th className="fw-semi-bold align-middle">3</th>
                    <th className="fw-semi-bold align-middle">4</th>
                </tr>
                </thead>
                <tbody>
                {empowerment.items.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td className="fw-semi-bold align-middle">{index + 1}</td>
                            <td className="fw-semi-bold align-middle text-nowrap">{item.name}</td>
                            <td className="fw-semi-bold align-middle">{item.catalog.code}</td>
                            <td className="fw-semi-bold align-middle">{item.quantity}</td>
                            <td className="fw-semi-bold align-middle">{getMeasure(item.measure_id)?.name}</td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        </Fragment>
    );
};

export default EmpowermentViewTable;
