import axios from "axios";

class ShipmentService {
    registerShipment(payload) {
        return axios.post(`/shipment/executor/register`, payload)
    }

    printShipment(id) {
        return axios.get(`/pdf/shipment/${id}`, {
            responseType: "blob"
        })
    }

    editShipment(id, payload) {
        return axios.post(`/shipment/executor/edit/${id}`, payload)
    }

    deleteShipment({id, reason, comment}) {
        return axios.post(`/shipment/executor/delete/${id}`, {
            reason: reason,
            comment: comment,
        })
    }

    shipmentCustomerEdit(id, payload) {
        return axios.post(`/shipment/customer/edit/${id}`, payload)
    }

    getShipment(id) {
        return axios.get(`/shipment/get/${id}`)
    }

    customerShipmentApprove({id, comment}) {
        return axios.post(`/shipment/customer/approve/${id}`, {
            comment: comment
        })
    }

    getShipments(params) {
        return axios.get('/shipment/get', {
            params: params
        })
    }

    getCountShipments(params) {
        return axios.get('/shipment/count', {
            params: params
        })
    }

    executorApproveShipment({id, comment}) {
        return axios.post(`/shipment/executor/approve/${id}`, {
            comment: comment
        })
    }

    customerDeclineShipment({id, reason, comment}) {
        return axios.post(`/shipment/customer/decline/${id}`, {
            reason: reason,
            comment: comment,
        })
    }

    uploadShipmentToExcel({uri, id}) {
        return axios.get(`${uri}/${id}`,{
            responseType: "arraybuffer",
        })
    }

    uploadShipmentsToExcel({uri, params}) {
        return axios.get(uri, {
            params: params,
            responseType: "arraybuffer",
        })
    }

    uploadShipmentsWithItemsToExcel({uri, params}) {
        return axios.get(uri, {
            params: params,
            responseType: "arraybuffer",
        })
    }
}

const shipmentService = new ShipmentService();

export default shipmentService
