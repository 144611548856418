import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {selectCount} from "../../../store/reducers/roaming/vertification-act/vertificationActReducer";
import PropTypes from "prop-types";
import {Col, Row} from "react-bootstrap";
import Badge from "../../common/Badge";
import IconButton from "../../IconButton";
import checkPermission from "../../../enum/permission/CheckPermission";
import {PermissionRoamingVerificationActCreate} from "../../../enum/permission/RoamingPermission";
import {Link, useNavigate} from "react-router-dom";
import toastPermission from "../../../enum/permission/ToastPermission";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {scrollEvent} from "../../../helpers/scrollEvent";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
const VerificationActDataTableHeader = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const verificationActCount = useSelector(selectCount)
    const lang = useSelector(selectLang)
    const t = Translate

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent("roaming-verification-act-data-table-header"))
        return () => window.removeEventListener('scroll', () => scrollEvent("roaming-verification-act-data-table-header"))
    }, []);

    return (
        <Row id={'roaming-verification-act-data-table-header'} className="flex-between-center align-items-center my-3 position-sticky z-index-1" style={{top: '4rem'}}>
            <Col cols={'auto'} className={'d-flex gap-3'}>
                <button onClick={() => navigate(-1)} className={'main-text btn-back'} style={{width: 35, height: 35}}>
                    <IconArrowLeft />
                </button>
                <div>
                    <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.verification_act.datatable.title")}: {verificationActCount}</h5>
                    <span className={'second-text fs-9'}>{t(lang, 'edi.navbar.routes.documents')}</span>
                </div>
            </Col>
            {/*<Col xs={4} sm="auto" className="d-flex flex-column pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.verification_act.datatable.title")}</h5>
                <div className="mt-1">
                    {t(lang, "roaming.common.count")}:
                    <Badge variant={'phoenix'} bg='success' className='ms-2'>
                        {verificationActCount}
                    </Badge>
                </div>
            </Col>*/}
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                <div id="invoice-actions">
                    <IconButton
                        as={Link}
                       /* as={dispatch(checkPermission(PermissionRoamingVerificationActCreate)) ? Link : 'div'}
                        onClick={()=>!dispatch(checkPermission(PermissionRoamingVerificationActCreate)) && dispatch(toastPermission())}*/
                        to="/roaming/verification-act/send"
                        className="btn-davr px-3 py-0 bg-primary-davr"
                        transform="shrink-3"
                        size="sm"
                        icon={faPlus}
                    >
                        <span className="d-none d-sm-inline-block ms-1 fw-semi-bold">{t(lang, "roaming.verification_act.datatable.register_verification_act")}</span>
                    </IconButton>
                </div>
            </Col>
        </Row>
    );
};

VerificationActDataTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default VerificationActDataTableHeader;
