import React, {Fragment, useState} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {Controller, useFormContext} from "react-hook-form";
import {selectLang} from "../../../store/reducers/main/main";
import PropTypes from "prop-types";
import FindPerson from "../../edi/attorney/FindPerson";
import {Col, Row, Form, FloatingLabel} from "react-bootstrap";

const RoamingInvoiceFormCardReleaserInfo = ({isEditOrCopy}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();
    const [eventCount, setEventCount] = useState(0);

    const onChangePerson = (personInfo) => {
        // if is copy or edit, prevent from conflict of values
        {
            if (isEditOrCopy && eventCount === 0) {
                setEventCount(eventCount + 1);
                return;
            }
        }

        if (personInfo) {
            form.setValue(`itemReleasedDoc.itemReleasedPinfl`, personInfo.pinfl || personInfo.inn);
            form.setValue(`itemReleasedDoc.itemReleasedFio`, personInfo.name);
        } else {
            form.setValue(`itemReleasedDoc.itemReleasedPinfl`, '');
            form.setValue(`itemReleasedDoc.itemReleasedFio`, '');
        }
    };
    return (
        <Fragment>
            <Form.Group as={Row} className="mb-2">
                <Col sm={12}>
                    <Controller name={`itemReleasedDoc.itemReleasedPinfl`}
                                render={({field}) => (
                                    <FindPerson defaultInnOrPinfl={field.value}
                                                onChange={onChangePerson}
                                                placeholder={`${t(lang, 'roaming.invoice.releaser.pinfl')} *`}
                                    />
                                )}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Col sm={12}>
                    <FloatingLabel label={`${t(lang, 'roaming.invoice.releaser.full_name')} *`}>
                        <Form.Control name={`itemReleasedDoc.itemReleasedFio`}
                                      placeholder={`${t(lang, 'roaming.invoice.releaser.full_name')} *`}
                                      isInvalid={form.formState.errors?.itemReleasedDoc?.itemReleasedFio}
                                      {...form.register(`itemReleasedDoc.itemReleasedFio`, {
                                          required: {
                                              value: !!form.getValues(`itemReleasedDoc.itemReleasedPinfl`),
                                              message: t(lang, 'items.common.validations.is_required')
                                          }
                                      })}
                        />
                    </FloatingLabel>
                    <Form.Control.Feedback type="invalid">{form.formState.errors?.itemReleasedDoc?.itemReleasedFio?.message}</Form.Control.Feedback>
                </Col>
            </Form.Group>
        </Fragment>
    );
};

RoamingInvoiceFormCardReleaserInfo.propTypes = {
    isEditOrCopy: PropTypes.bool
}

RoamingInvoiceFormCardReleaserInfo.defaultProps = {
    isEditOrCopy: false
}

export default RoamingInvoiceFormCardReleaserInfo;
