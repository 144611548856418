import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {
    changeWaybillsV2FilterOptions,
    getWaybillV2CustomerCount,
    getWaybillV2DraftsCount,
    getWaybillV2ExecutorCount,
    getWaybillV2ResponsiblePersonCount,
    loadWaybillV2CustomerAsync,
    loadWaybillV2DraftsAsync,
    loadWaybillV2ExecutorAsync,
    loadWaybillV2ResponsiblePersonAsync,
    selectWaybillsV2,
    selectWaybillsV2Count,
    selectWaybillsV2FilterOptions,
    selectWaybillsV2Loading,
    setShowModal,
    viewPage
} from "../../../store/reducers/roaming/waybill-v2/romaingWaybillV2Reducer";
import {selectDateDayJSFormat, selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {RESPONSIBLE_PERSON, VIEW_DRAFTS, VIEW_INBOX, VIEW_OUTBOX} from '../../../enum/roaming/waybill/WaybillStatus';
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import {checkViewer} from "../../../enum/roaming/checkViewer";
import Badge from "../../common/Badge";
import {getWaybillV2Status, VIEW_OUTBOX_STATUSES} from "../../../enum/roaming/waybill-v2/WaybillV2Status";
import RevealDropdown, {RevealDropdownTrigger} from "../../common/RevealDropdown";
import {Card, Col, Dropdown, Row} from "react-bootstrap";
import WaybillV2DraftDelete from "./WaybillV2DraftDelete";
import {changeWaybillsFilterOptions} from "../../../store/reducers/roaming/waybill/roamingWaybillReducer";
import TypeViewerAction from "../../common/TypeViewerAction";
import AdvanceTable from "../../table/AdvanceTable";
import AdvanceTableCustomPagination from "../../table/AdvanceTableCustomPagination";
import AdvanceTableWrapper from "../../table/AdvanceTableWrapper";
import numeral from "numeral";
import WaybillV2DataTableHeader from "./WaybillV2DataTableHeader";
import {DRAFT} from "../../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import statuses from "statuses";

const WaybillV2DataTable = ({viewer, predefinedFilters}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const t = Translate;
    const lang = useSelector(selectLang);
    const waybills = useSelector(selectWaybillsV2);
    const count = useSelector(selectWaybillsV2Count);
    const loading = useSelector(selectWaybillsV2Loading);
    const filter = useSelector(selectWaybillsV2FilterOptions);
    const numberFormat = useSelector(selectNumberFormat);
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat);
    const {id, draftId, showModal} = useSelector(viewPage)
    const [activeKey, setActiveKey] = useState(0);
    const [statusName, setStatusName] = useState(0);
    const viewer_inn_or_pinfl = activeOrganization?.pinfl || activeOrganization?.inn;

    const loadWaybills = () => {
        if (viewer === VIEW_INBOX) {
            dispatch(loadWaybillV2CustomerAsync({...filter, ...predefinedFilters}));
            dispatch(getWaybillV2CustomerCount({...filter, ...predefinedFilters}));
        } else if (viewer === VIEW_OUTBOX) {
            dispatch(loadWaybillV2ExecutorAsync({...filter, ...predefinedFilters}));
            dispatch(getWaybillV2ExecutorCount({...filter, ...predefinedFilters}));
        } else if (viewer === VIEW_DRAFTS) {
            dispatch(loadWaybillV2DraftsAsync({...filter, ...predefinedFilters}));
            dispatch(getWaybillV2DraftsCount({...filter, ...predefinedFilters}));
        } else if (viewer === RESPONSIBLE_PERSON) {
            dispatch(loadWaybillV2ResponsiblePersonAsync({...filter}));
            dispatch(getWaybillV2ResponsiblePersonCount({...filter}));
        }
    }

    useEffect(() => {
        if (predefinedFilters) {
            loadWaybills()
        }
    }, [predefinedFilters])

    const openViewModalPage = (e, id) => {


        if (viewer === VIEW_DRAFTS) {
            navigate('/roaming/waybill-v2/draft/' + id)

        } else {
            navigate('/roaming/waybill-v2/' + id)

        }
    }

    const onHideModalPage = () => {
        dispatch(setShowModal(false))
    }

    const columns = [
        {
            accessor: 'created_at',
            Header: t(lang, 'roaming.waybill.datatable.row.created_at'),
            Cell: rowData => {
                const {original} = rowData.row
                return dayjs(original.created_at, "DD.MM.YYYY").format("DD.MM.YYYY")
            }
        },
        {
            accessor: 'contractor',
            Header: t(lang, 'roaming.waybill.datatable.row.contractor'),
            cellProps: {
                className: 'pe-4'
            },
            headerProps: {width: 300},
            Cell: ({row: {original}}) => {
                if (viewer_inn_or_pinfl === original.executor.inn || viewer_inn_or_pinfl === original.executor.inn_or_pinfl)
                    return <div className={'d-flex align-items-center gap-2'}>
                        {checkViewer(viewer)}
                        <div>
                             <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, "roaming.verification_act.datatable.body.row.verification_act", {
                                 name: original?.info?.number,
                                 date: dayjs(original?.info?.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                             })}
                             </span>
                            <span className={'fs-8 main-text fw-bold text-truncate'}>{original.customer?.name}</span>
                            <i className={'d-block fs-9 second-text'}>{original.customer?.inn_or_pinfl || original.customer?.inn}</i>
                        </div>
                    </div>
                else
                    return <div className={'d-flex align-items-center gap-2'}>
                        {checkViewer(viewer)}
                        <div>
                             <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, "roaming.verification_act.datatable.body.row.verification_act", {
                                 name: original?.info?.number,
                                 date: dayjs(original?.info?.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                             })}
                             </span>
                            <span className={'fs-8 main-text fw-bold text-truncate'}>{original.executor.name}</span>
                            <i className={'d-block fs-9 second-text'}>{original.executor?.inn || original.executor?.inn_or_pinfl}</i>
                        </div>
                    </div>
            }
        },
        {
            accessor: 'contract',
            Header: t(lang, 'roaming.waybill.datatable.row.contract'),
            Cell: ({row: {original}}) => {
                return (
                    <span>
                        {original.contract && t(lang, 'roaming.waybill.datatable.row.contract_number_from_contract_date', {
                            contract_number: original?.contract?.number,
                            contract_date: dayjs(original.contract?.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                        })}
                    </span>
                )
            }
        },
        {
            accessor: 'total',
            Header: t(lang, 'roaming.waybill.datatable.row.total'),
            cellProps: {
                className: 'text-end pe-5'
            },
            Cell: ({row: {original}}) => {
                const total = original.total ? original?.total : original.roadway?.product_groups[0]?.products?.reduce((acc, item) => acc + item.total, 0);
                return (
                    <Fragment>
                        <strong>{total ? numeral?.formats[numberFormat]?.format(total) : 0}</strong>
                        <span className={'ms-1 second-text fs-9'}>UZS</span>
                    </Fragment>
                )
            }
        },
        ...(viewer !== VIEW_DRAFTS ? [
            {
                accessor: 'status',
                Header: t(lang, 'roaming.waybill.datatable.row.status'),
                cellProps: {
                    className: 'text-center'
                },
                headerProps: {
                    className: 'text-center'
                },
                Cell: ({row: {original}}) => {
                    return (
                        <div className={'d-inline-block text-center'}>
                            <Badge
                                variant={"phoenix"}
                                bg={getWaybillV2Status(original.status).variant}
                                className={'px-3 py-1 fs-9 text-uppercase w-100 text-decoration-none fw-medium border-0 rounded-4'}
                            >
                                {t(lang, getWaybillV2Status(original.status).label)}
                            </Badge>
                        </div>
                    )
                }
            }
        ] : []),
        {
            accessor: 'action',
            Header: "",
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                        <RevealDropdown>
                            <Dropdown.Item
                                onClick={(event) => {
                                    openViewModalPage(event, original.id)
                                }}>
                                <span>{t(lang, "roaming.invoices.table_header.show")}</span>
                            </Dropdown.Item>
                            {viewer === VIEW_DRAFTS && <Dropdown.Item>
                                <WaybillV2DraftDelete id={original.id}
                                                      size="sm"
                                                      hasTitle={true}
                                />
                            </Dropdown.Item>}
                        </RevealDropdown>
                    </RevealDropdownTrigger>
                );
            }
        },

    ]

    const onLimitChange = limit => {
        dispatch(changeWaybillsFilterOptions({...filter, limit: limit, page: 1}))
    }

    const onPageChange = page => {
        dispatch(changeWaybillsFilterOptions({...filter, page: page}))
    }

    useEffect(() => {
        setStatusName(VIEW_OUTBOX_STATUSES.find(i => i.id === filter.status)?.name)
        setActiveKey(VIEW_OUTBOX_STATUSES.find(i => i.id === filter.status)?.id)
    }, [filter])

    return (
        <Fragment>
            <AdvanceTableWrapper
                selection
                pagination
                perPage={1000}
                data={waybills}
                columns={columns}
            >
                <WaybillV2DataTableHeader
                    loadWaybills={loadWaybills}
                    waybillCount={count}
                    viewer={viewer}
                    predefinedFilters={predefinedFilters}
                    table
                />
                <Card className={'p-0 rounded-4'}>
                    <Row>
                        <Col sm={12} xl={6}>
                            <TypeViewerAction to={'/roaming/v2-waybills'} typeViewer={viewer} responsiblePerson={true}/>
                        </Col>
                        <Fragment>
                            {(viewer !== DRAFT && !predefinedFilters?.hasOwnProperty("status")) &&
                                <Col sm={12} xl={6} className={'d-flex justify-content-end align-items-center'}>
                                    <Dropdown className={'me-2'}>
                                        <Dropdown.Toggle
                                            variant=""
                                            className="p-0 dropdown-caret-none text-decoration-none">
                                            <span
                                                className={'fs--1'}>{t(lang, statusName ? statusName : "roaming.waybill.v2.status.all")}</span>
                                            <FontAwesomeIcon icon={faChevronDown} className="ms-2"/>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className='py-2'>
                                            {VIEW_OUTBOX_STATUSES.map((status, index) => (
                                                <Dropdown.Item key={index} onClick={() => {
                                                    setActiveKey(status.id)
                                                    setStatusName(status.name)
                                                    dispatch(changeWaybillsV2FilterOptions({
                                                        ...filter,
                                                        status: status.id !== 0 ? status.id : null
                                                    }))
                                                }}>
                                                    <span
                                                        className={`${status.id === activeKey ? 'text-primary fw-semi-bold' : ''}`}>{t(lang, status.name)}</span></Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>}
                        </Fragment>
                    </Row>
                    <AdvanceTable
                        table
                        rowClassName="align-middle white-space-nowrap"
                        headerClassName="text-900 text-nowrap align-middle"
                        tableProps={{
                            loading: loading,
                            size: 'sm',
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card>

                <AdvanceTableCustomPagination
                    count={count}
                    limit={filter.limit}
                    page={filter.page}
                    onLimitChange={onLimitChange}
                    onPageChange={onPageChange}
                />
            </AdvanceTableWrapper>
        </Fragment>
    );
};

export default WaybillV2DataTable;
