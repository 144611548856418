import React from 'react';

const IconAlertError = ({width = 16, height = 16, color = "none"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.75 5.75L7.99995 7.99995M7.99995 7.99995L10.2499 10.2499M7.99995 7.99995L5.75 10.2499M7.99995 7.99995L10.2499 5.75M8 14.75C4.27208 14.75 1.25 11.7279 1.25 8C1.25 4.27208 4.27208 1.25 8 1.25C11.7279 1.25 14.75 4.27208 14.75 8C14.75 11.7279 11.7279 14.75 8 14.75Z"
                stroke="#FF3B30" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default IconAlertError;
