import React, {Fragment, useState} from 'react';
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {Controller, useFormContext} from "react-hook-form";
import {selectLang} from "../../../../store/reducers/main/main";
import {Card, Col, Row, Form, FloatingLabel} from "react-bootstrap";
import FindCompanySearchable from "../../../organization/FindCompanySearchable";
import SelectRegion from "../SelectRegion";
import SelectStations from "../SelectStations";

const AdditionalInformationRailwayV2Point = ({title, titleLoadingPoint, railwayName, trusteeName}) => {

    const lang = useSelector(selectLang);
    const t = Translate;
    const [regionId, setRegionId] = useState(null)

    const {register, setValue, getValues, watch, formState: {errors}, control} = useFormContext();


    return (
        <Fragment>
            <h4 className={'fs-8 fw-medium second-text'}>{t(lang, title)}</h4>
            <Card className={'p-1 mt-2'}>
                <Row>
                    <Form.Group as={Col} sm={12}>
                        <Controller
                            name={`${trusteeName}.pinfl`}
                            render={({field}) => (
                                <FindCompanySearchable
                                    placeholder={<>
                                        {t(lang, 'roaming.contracts.add.clients_info.inn_or_pinfl')}
                                        <span className="text-primary-davr">*</span>
                                    </>}
                                    onChange={(railway_loading_point) => {
                                        field.onChange(railway_loading_point ? railway_loading_point?.person_num ? railway_loading_point?.person_num : railway_loading_point?.inn : null)
                                        setValue(`${trusteeName}.name`, railway_loading_point?.short_name || railway_loading_point?.name)
                                    }}
                                    defaultInn={field.value}
                                    inputProps={{
                                        className: `${errors?.executor?.inn_or_pinfl && 'is-invalid'} w-100`,
                                    }}
                                />
                            )}
                        />
                        <Form.Control.Feedback className={'d-block'} type="invalid">
                            {errors?.[railwayName]?.pinfl?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm={12}>
                        <FloatingLabel label={<>
                            {t(lang, 'roaming.waybill.send.members_info_form.customer.name')}
                            <span className="text-primary-davr">*</span>
                        </>}>
                            <Form.Control
                                type="text"
                                readOnly={true}
                                placeholder={<>
                                    {t(lang, 'roaming.waybill.send.members_info_form.customer.name')}
                                    <span className="text-primary-davr">*</span>
                                </>}
                                {...register(`${trusteeName}.name`)}
                            />
                        </FloatingLabel>
                    </Form.Group>
                </Row>
            </Card>

            <h4 className={'fs-8 fw-medium second-text mt-3'}>{t(lang, titleLoadingPoint)}</h4>
            <Card className={'p-1'}>
                <Row className={'flex-column gap-1'}>
                    <Form.Group as={Col} sm={12}>
                        <SelectRegion
                            placeholder={t(lang, "roaming.waybill.v2.send.region")}
                            isClearable
                            onChange={(region) => {
                                if  (region) {
                                    setRegionId(region.code)
                                } else {
                                    setRegionId(null)
                                    setValue(`${railwayName}.station_id`, null)
                                }
                            }}
                        />
                    </Form.Group>
                    <Form.Group as={Col} sm={12}>
                        <Controller
                            name={`${railwayName}.station_id`}
                            render={({field}) => (
                                <SelectStations
                                    placeholder={<>
                                        {t(lang, 'roaming.waybill.v2.send.railway.loading_point.name')}
                                        <span className="text-primary-davr">*</span>
                                    </>}
                                    regionId={regionId}
                                    defaultId={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                        <Form.Control.Feedback className={'d-block'} type="invalid">
                            {errors?.[railwayName]?.station_id?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
            </Card>
        </Fragment>
    );
};

export default AdditionalInformationRailwayV2Point;
