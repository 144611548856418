import React from 'react';
import {useLocation} from "react-router-dom";

const UseQuery = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const queryParams = {};
    for (const [key, value] of searchParams.entries()) {
        queryParams[key] = value;
    }
    return queryParams;
};

export default UseQuery;
