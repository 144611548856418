import React, {Fragment, useEffect, useState} from 'react';
import {Translate} from "../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {selectWarehouses} from "../../store/reducers/roaming/roamingReferenceReducer";
import {Button, Col, Form, Modal, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import IconButton from "../IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import Divider from "./Divider";
import FalconCloseButton from "./FalconCloseButton";
import CustomFloatingLabelSelect from "../floating-custom-select/CustomFloatingLabelSelect";

const WarehouseLinkSelector = ({defaultWarehouseId, onChange, linkProps}) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const warehouses = useSelector(selectWarehouses);
    const [show, setShow] = useState(false);
    const [warehouse, setWarehouse] = useState(null);

    const link = <span className="text-primary cursor-pointer fs--1"
                       onClick={() => setShow(true)}
                       {...linkProps}
    >
        {warehouse ? `${warehouse.warehouseName}` : t(lang, 'roaming.invoice.warehouse')}
    </span>

    const onChangeHandler = (option) => {
        setWarehouse(option);
        onChange(option);
        option && setShow(false);
    }

    useEffect(() => {
        if (warehouse?.id !== defaultWarehouseId)
            setWarehouse(warehouses.find(w => w.id === defaultWarehouseId) || null);
    }, [defaultWarehouseId, warehouses])

    return (
        <Fragment>
            {warehouse && <OverlayTrigger overlay={<Tooltip>{warehouse.warehouseName}</Tooltip>}>
                {link}
            </OverlayTrigger>}
            {!warehouse && link}
            {show &&
                <Modal show={true}
                       className={'davr-modal'}
                       onHide={() => setShow(false)}
                       size="xl"
                       aria-labelledby="contained-modal-title-vcenter"
                       centered
                >
                    <Modal.Header>
                        <Modal.Title>{t(lang, 'roaming.invoice.warehouse')}</Modal.Title>
                        <FalconCloseButton onClick={() => setShow(false)} />
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="gy-2">
                            <Col xs={12}>
                                <Form.Group>
                                    <CustomFloatingLabelSelect
                                        options={warehouses}
                                        placeholder={t(lang, 'roaming.invoice.warehouse')}
                                        getOptionValue={option => option.id}
                                        getOptionLabel={option => option.warehouseName}
                                        isSearchable={!defaultWarehouseId}
                                        defaultMenuIsOpen={!warehouse}
                                        isClearable={true}
                                        value={null}
                                        onChange={onChangeHandler}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {warehouse &&
                            <Fragment>
                                <Divider/>
                                <Row>
                                    <Col xs={12}>
                                        <h5 className="text-info">{t(lang, 'roaming.invoice.warehouse')}</h5>
                                        <div className="mt-3 fw-bold fs--1">
                                            <span className="text-primary">{warehouse.id} - </span>
                                            <span className="text-dark">{warehouse.warehouseName}</span>
                                            <IconButton
                                                variant="phoenix-secondary"
                                                size="sm"
                                                icon={faTrash}
                                                iconClassName={"text-danger"}
                                                className="ms-2"
                                                title={t(lang, 'items.common.delete')}
                                                onClick={() => onChangeHandler(null)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Fragment>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => setShow(false)}>
                            {t(lang, 'items.common.cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </Fragment>
    );
};

export default WarehouseLinkSelector;
