import {createSlice} from "@reduxjs/toolkit";
import {Translate} from "../../../../lang/lang";
import roamingWaybillV2Service from "../../../services/roaming/waybill-v2/roamingWaybillV2Service";
import EventBus from "../../../../eventbus/EventBus";
import {
    CONSIGNOR_CANCEL_FAILED,
    CONSIGNOR_CANCEL_SUCCEED,
    CUSTOMER_ACCEPT_FAILED,
    CUSTOMER_ACCEPT_SUCCEED,
    CUSTOMER_DECLINE_FAILED,
    CUSTOMER_DECLINE_SUCCEED,
    HASHCODE_CANCEL_FAILED,
    HASHCODE_CANCEL_REQUESTED,
    RESPONSIBLE_PERSON_ACCEPT_FAILED,
    RESPONSIBLE_PERSON_ACCEPT_SUCCEED,
    RESPONSIBLE_PERSON_GIVE_FAILED,
    RESPONSIBLE_PERSON_GIVE_SUCCEED,
    RESPONSIBLE_PERSON_REJECT_FAILED,
    RESPONSIBLE_PERSON_REJECT_SUCCEED,
    RESPONSIBLE_PERSON_RETURN_FAILED,
    RESPONSIBLE_PERSON_RETURN_SUCCEED,
    RESPONSIBLE_PERSON_TILL_RETURN_FAILED,
    RESPONSIBLE_PERSON_TILL_RETURN_SUCCEED,
    RETURN_ACCEPT_FAILED,
    RETURN_ACCEPT_SUCCEED,
    SAVE_DRAFT_WAYBILL_v2_FAILED,
    SAVE_DRAFT_WAYBILL_v2_REQUESTED,
    SAVE_DRAFT_WAYBILL_v2_SUCCEED,
    SEND_WAYBILL_V2_FAILED,
    SEND_WAYBILL_V2_REQUESTED,
    SEND_WAYBILL_V2_SUCCEED,
    WAYBILL_V2_SYNC_FAILED,
    WAYBILL_V2_SYNC_REQUESTED,
    WAYBILL_V2_SYNC_SUCCESS
} from "../../../../eventbus/romaing/waybill-v2/roamingWaybillV2Events";
import {errorSender} from "../roamingInvoiceReducer/roamingInvoiceReducer";
import {attachPkcs7WithTimestamp, createPkcs7WithTimestamp} from "../../eimzo/eimzoReducer";
import waybillV2Service from "../../../services/roaming/waybill-v2/roamingWaybillV2Service";


const t = Translate;

export const transportTypeOptions = [
    {text: 'Перевозка автомобильным транспортом', value: 1},
    {text: 'Перевозка по авиа-линиям', value: 2},
    {text: 'Перевозка по железной дороге', value: 3},
    {text: 'Перевозка по морским путям', value: 4}
]

export const deliveryTypeOptions = [
    {value: 1, label: "roaming.waybill.send.delivery_details_form.delivery_type.from_warehouse_to_warehouse"},
    {value: 2, label: "roaming.waybill.send.delivery_details_form.delivery_type.from_seller_to_buyer"},
];


const roamingWaybillV2Slice = createSlice({
    name: 'roamingWaybillV2',
    initialState: {
        waybillsV2: [],
        waybillsCountV2: 0,
        isLoadingV2: false,
        filterOptionsV2: {
            page: 1, limit: 10
        },
        transports: [],
        isLoading: false,
        viewPage: {
            id: null,
            draftId: null,
            showModal: false
        },
        railwayStations: [],
        region: []
    },
    reducers: {
        setShowModal: (state, action) => {
            if (!action.payload) {
                state.viewPage = {id: null, draftId: null, showModal: false}
            } else {
                state.viewPage.showModal = action.payload
            }
        },
        setId: (state, {payload}) => {
            const {draftId, id} = payload
            if (draftId) {
                state.viewPage.draftId = draftId
            } else if (id) {
                state.viewPage.id = id
            }
        },
        setTransports: (state, action) => {
            state.transports = action.payload
        },
        updateIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        updateWaybillsV2: (state, action) => {
            state.waybillsV2 = action.payload;
        },
        updateWaybillsV2Count: (state, action) => {
            state.waybillsCountV2 = action.payload;
        },
        updateWaybillsV2FilterOptions: (state, action) => {
            state.filterOptionsV2 = {...state.filterOptionsV2, ...action.payload}
        },
        updateWaybillV2RailwayStations: (state, action) => {
            state.railwayStations = action.payload
        },
        updateWaybillV2Region: (state, action) => {
            state.region = action.payload
        }
    }
})

export const selectWaybillsV2 = state => state.roamingWaybillV2.waybillsV2;
export const selectWaybillsV2Loading = state => state.roamingWaybillV2.isLoading;
export const selectWaybillsV2Count = state => state.roamingWaybillV2.waybillsCountV2;
export const selectWaybillsV2FilterOptions = state => state.roamingWaybillV2.filterOptionsV2;
export const selectTransports = (state) => state.roamingWaybillV2.transports
export const selectRailwayStation = (state) => state.roamingWaybillV2.railwayStations
export const selectRegion = (state) => state.roamingWaybillV2.region
export const viewPage = state => state.roamingWaybillV2.viewPage


export const getTransportsAsync = (innOrPinf) => (dispatch) => {
    return new Promise((resolve, reject) => {
        roamingWaybillV2Service.getTransport(innOrPinf)
            .then((res) => {
                dispatch(roamingWaybillV2Slice.actions.setTransports(res.data))
                resolve(res.data)
            })
            .catch(() => reject())
    })
}
export const getCompanyAddressAsync = (innOrPinf) => {
    return new Promise((resolve, reject) => {
        roamingWaybillV2Service.getCompanyAddresses(innOrPinf)
            .then((res) => {
                resolve(res.data)
            })
            .catch(() => reject())
    })
}

export const getWaybillVillageAsync = (soatoCode) => {
    return new Promise((resolve, reject) => {
        roamingWaybillV2Service.getWaybillVillage(soatoCode)
            .then((res) => {
                // dispatch(roamingWaybillV2Slice.actions.setWaybillVillage(res.data))
                resolve(res.data)
            })
            .catch(() => reject())
    })
}

export const getWaybillRegionAsync = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        roamingWaybillV2Service.getWaybillRegion()
            .then((res) => {
                dispatch(roamingWaybillV2Slice.actions.updateWaybillV2Region(res.data))
                resolve(res.data)
            })
            .catch(() => reject())
    })
}

export const getWaybillStationsAsync = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        roamingWaybillV2Service.getWaybillStations()
            .then((res) => {
                dispatch(roamingWaybillV2Slice.actions.updateWaybillV2RailwayStations(res.data))
                resolve(res.data)
            })
            .catch(() => reject())
    })
}

export const getWaybillDistrictAsync = (regionCode) => {
    return new Promise((resolve, reject) => {
        roamingWaybillV2Service.getWaybillDistrict(regionCode)
            .then((res) => {
                // dispatch(roamingWaybillV2Slice.actions.setWaybillDistrict(res.data))
                resolve(res.data)
            })
            .catch(() => reject())
    })
}

export const sendWaybillV2Async = async (certificate, lang, waybill) => {

    try {
        EventBus.dispatch(SEND_WAYBILL_V2_REQUESTED);
        const {id, hashCode} = waybill;
        const {pkcs7: signature} = await createPkcs7WithTimestamp(hashCode, certificate.keyId);
        await roamingWaybillV2Service.sendRoamingWaybillV2({
            id: id,
            signature: signature,
        })
            .then(response => EventBus.dispatch(SEND_WAYBILL_V2_SUCCEED, response.data))
    } catch (error) {
        let errorMessage;
        const errorResponse = error?.response?.data;
        if (errorResponse.content) {
            const parsedError = JSON.parse(errorResponse.content);
            errorMessage = {message: parsedError?.ErrorMessage}
        } else if (errorResponse?.not_allowed_inn) {
            errorMessage = {error: error, message: t(lang, 'roaming.waybill.error.message.not_allowed_inn')}
        } else if (errorResponse?.content_parse_failed) {
            errorMessage = {error: error, message: t(lang, 'roaming.waybill.error.message.content_parse_failed')}
        } else if (errorResponse.roaming_error_message) {
            errorMessage = {
                error: error,
                message: JSON.parse(errorResponse.roaming_error_message)?.ErrorMessage || errorResponse.roaming_error_message
            }
        } else {
            errorMessage = {message: t(lang, 'roaming.waybill.error.message.default_error')}
        }
        EventBus.dispatch(SEND_WAYBILL_V2_FAILED, errorMessage);
        throw errorMessage
    }
}


export const changeWaybillsV2FilterOptions = (params) => dispatch => {
    return dispatch(roamingWaybillV2Slice.actions.updateWaybillsV2FilterOptions(params));
}

export const loadWaybillV2CustomerAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};
    dispatch(roamingWaybillV2Slice.actions.updateIsLoading(true));

    return new Promise((resolve, reject) => {
        roamingWaybillV2Service.getWaybillsV2Customer(params)
            .then(response => {
                dispatch(roamingWaybillV2Slice.actions.updateWaybillsV2(response.data));
                dispatch(roamingWaybillV2Slice.actions.updateIsLoading(false));
                resolve();
            })
            .catch(error => reject(error))
    })
}

export const getWaybillV2CustomerCount = (params) => dispatch => {
    return new Promise((resolve, reject) => {
        roamingWaybillV2Service.getWaybillsV2CustomerCount(params)
            .then(response => {
                dispatch(roamingWaybillV2Slice.actions.updateWaybillsV2Count(response.data));
                resolve();
            }).catch(error => reject(error))
    })
}

export const loadWaybillV2ExecutorAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};
    dispatch(roamingWaybillV2Slice.actions.updateIsLoading(true));

    return new Promise((resolve, reject) => {
        roamingWaybillV2Service.getWaybillsV2Executor(params)
            .then(response => {
                dispatch(roamingWaybillV2Slice.actions.updateWaybillsV2(response.data));
                dispatch(roamingWaybillV2Slice.actions.updateIsLoading(false));
                resolve();
            })
            .catch(error => reject(error))
    })
}

export const getWaybillV2ExecutorCount = (params) => dispatch => {
    return new Promise((resolve, reject) => {
        roamingWaybillV2Service.getWaybillsV2ExecutorCount(params)
            .then(response => {
                dispatch(roamingWaybillV2Slice.actions.updateWaybillsV2Count(response.data));
                resolve();
            }).catch(error => reject(error))
    })
}

export const loadWaybillV2Async = (id) => {
    return new Promise((resolve, reject) => {
        roamingWaybillV2Service.getWaybillV2(id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export const waybillCancelAsync = (certificate, lang, id) => {
    EventBus.dispatch(HASHCODE_CANCEL_REQUESTED, id);
    let errorResponse;

    return new Promise((resolve, reject) => {
        waybillV2Service.getCancelHashCode(id)
            .then(({data: hashCode}) => {
                createPkcs7WithTimestamp(hashCode, certificate.keyId)
                    .then(({pkcs7: signature}) => {
                        waybillV2Service.consignorCancel(id, signature)
                            .then(() => {
                                EventBus.dispatch(CONSIGNOR_CANCEL_SUCCEED)
                                resolve()
                            })
                            .catch((e) => {
                                errorResponse = errorSender(e.response.data, lang)
                                EventBus.dispatch(CONSIGNOR_CANCEL_FAILED, errorResponse)
                                reject(errorResponse)
                            })
                    })
                    .catch(() => {
                        errorResponse = {message: t(lang, "roaming.empowerment.alert.error.createPkcs7Async")}
                        EventBus.dispatch(CONSIGNOR_CANCEL_FAILED, errorResponse)
                        reject(errorResponse)
                    })
            })
            .catch(() => {
                errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getCancelHashCode")}
                EventBus.dispatch(CONSIGNOR_CANCEL_FAILED, errorResponse)
                reject(errorResponse)
            })
    })
}

export const waybillAcceptAsync = (certificate, lang, id) => {
    EventBus.dispatch(HASHCODE_CANCEL_REQUESTED, id);
    let errorResponse;

    return new Promise((resolve, reject) => {
        waybillV2Service.getAcceptHashCode(id)
            .then(({data: hashCode}) => {
                attachPkcs7WithTimestamp(hashCode, certificate.keyId)
                    .then(({pkcs7: signature}) => {
                        waybillV2Service.customerAccept(id, signature)
                            .then(() => {
                                EventBus.dispatch(CUSTOMER_ACCEPT_SUCCEED)
                                resolve()
                            })
                            .catch((e) => {
                                errorResponse = errorSender(e.response.data, lang)
                                EventBus.dispatch(CUSTOMER_ACCEPT_FAILED, errorResponse)
                                reject(errorResponse)
                            })
                    })
                    .catch(() => {
                        errorResponse = {message: t(lang, "roaming.empowerment.alert.error.createPkcs7Async")}
                        EventBus.dispatch(HASHCODE_CANCEL_FAILED, errorResponse)
                        reject(errorResponse)
                    })
            })
            .catch(() => {
                errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getCancelHashCode")}
                EventBus.dispatch(CUSTOMER_ACCEPT_FAILED, errorResponse)
                reject(errorResponse)
            })
    })
}

export const waybillDeclineAsync = (certificate, lang, id) => {
    EventBus.dispatch(HASHCODE_CANCEL_REQUESTED, id);
    let errorResponse;

    return new Promise((resolve, reject) => {
        waybillV2Service.getDeclineHashCode(id)
            .then(({data: hashCode}) => {
                createPkcs7WithTimestamp(hashCode, certificate.keyId)
                    .then(({pkcs7: signature}) => {
                        waybillV2Service.customerDecline(id, signature)
                            .then(() => {
                                EventBus.dispatch(CUSTOMER_DECLINE_SUCCEED)
                                resolve()
                            })
                            .catch((e) => {
                                errorResponse = errorSender(e.response.data, lang)
                                EventBus.dispatch(CUSTOMER_DECLINE_FAILED, errorResponse)
                                reject(errorResponse)
                            })
                    })
                    .catch(() => {
                        errorResponse = {message: t(lang, "roaming.empowerment.alert.error.createPkcs7Async")}
                        EventBus.dispatch(CUSTOMER_DECLINE_FAILED, errorResponse)
                        reject(errorResponse)
                    })
            })
            .catch(() => {
                errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getCancelHashCode")}
                EventBus.dispatch(CUSTOMER_DECLINE_FAILED, errorResponse)
                reject(errorResponse)
            })
    })
}

export const waybillReturnAcceptAsync = (certificate, lang, id) => {
    EventBus.dispatch(HASHCODE_CANCEL_REQUESTED, id);
    let errorResponse;

    return new Promise((resolve, reject) => {
        waybillV2Service.getReturnAcceptHashCode(id)
            .then(({data: hashCode}) => {
                createPkcs7WithTimestamp(hashCode, certificate.keyId)
                    .then(({pkcs7: signature}) => {
                        waybillV2Service.returnAccept(id, signature)
                            .then((res) => {
                                EventBus.dispatch(RETURN_ACCEPT_SUCCEED, res.data)
                                resolve(res.data)
                            })
                            .catch((e) => {
                                errorResponse = errorSender(e.response.data, lang)
                                EventBus.dispatch(RETURN_ACCEPT_FAILED, errorResponse)
                                reject(errorResponse)
                            })
                    })
                    .catch(() => {
                        errorResponse = {message: t(lang, "roaming.empowerment.alert.error.createPkcs7Async")}
                        EventBus.dispatch(RETURN_ACCEPT_FAILED, errorResponse)
                        reject(errorResponse)
                    })
            })
            .catch(() => {
                errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getCancelHashCode")}
                EventBus.dispatch(RETURN_ACCEPT_FAILED, errorResponse)
                reject(errorResponse)
            })
    })
}

// responsible person
export const waybillResponsiblePersonReject = (certificate, lang, id, comment) => {
    EventBus.dispatch(HASHCODE_CANCEL_REQUESTED, id);
    let errorResponse;

    return new Promise((resolve, reject) => {
        waybillV2Service.getHashCodeResponsiblePersonReject(id)
            .then(({data: hashCode}) => {
                createPkcs7WithTimestamp(hashCode, certificate.keyId)
                    .then(({pkcs7: signature}) => {
                        waybillV2Service.responsiblePersonReject(id, signature, comment)
                            .then((res) => {
                                EventBus.dispatch(RESPONSIBLE_PERSON_REJECT_SUCCEED, res.data)
                                resolve(res.data)
                            })
                            .catch((e) => {
                                errorResponse = errorSender(e.response.data, lang)
                                EventBus.dispatch(RESPONSIBLE_PERSON_REJECT_FAILED, errorResponse)
                                reject(errorResponse)
                            })
                    })
                    .catch(() => {
                        errorResponse = {message: t(lang, "roaming.empowerment.alert.error.createPkcs7Async")}
                        EventBus.dispatch(RESPONSIBLE_PERSON_REJECT_FAILED, errorResponse)
                        reject(errorResponse)
                    })
            })
            .catch(() => {
                errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getCancelHashCode")}
                EventBus.dispatch(RESPONSIBLE_PERSON_REJECT_FAILED, errorResponse)
                reject(errorResponse)
            })
    })
}

export const waybillResponsiblePersonAccept = (certificate, lang, id) => {
    EventBus.dispatch(HASHCODE_CANCEL_REQUESTED, id);
    let errorResponse;

    return new Promise((resolve, reject) => {
        waybillV2Service.getHashCodeResponsiblePersonAccept(id)
            .then(({data: hashCode}) => {
                attachPkcs7WithTimestamp(hashCode, certificate.keyId)
                    .then(({pkcs7: signature}) => {
                        waybillV2Service.responsiblePersonAccept(id, signature)
                            .then((res) => {
                                EventBus.dispatch(RESPONSIBLE_PERSON_ACCEPT_SUCCEED, res.data)
                                resolve(res.data)
                            })
                            .catch((e) => {
                                errorResponse = errorSender(e.response.data, lang)
                                EventBus.dispatch(RESPONSIBLE_PERSON_ACCEPT_FAILED, errorResponse)
                                reject(errorResponse)
                            })
                    })
                    .catch(() => {
                        errorResponse = {message: t(lang, "roaming.empowerment.alert.error.createPkcs7Async")}
                        EventBus.dispatch(RESPONSIBLE_PERSON_ACCEPT_FAILED, errorResponse)
                        reject(errorResponse)
                    })
            })
            .catch(() => {
                errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getCancelHashCode")}
                EventBus.dispatch(RESPONSIBLE_PERSON_ACCEPT_FAILED, errorResponse)
                reject(errorResponse)
            })
    })
}

export const waybillResponsiblePersonTillReturn = (certificate, lang, id, comment) => {
    EventBus.dispatch(HASHCODE_CANCEL_REQUESTED, id);
    let errorResponse;

    return new Promise((resolve, reject) => {
        waybillV2Service.getHashCodeResponsiblePersonTillReturn(id)
            .then(({data: hashCode}) => {
                createPkcs7WithTimestamp(hashCode, certificate.keyId)
                    .then(({pkcs7: signature}) => {
                        waybillV2Service.responsiblePersonTillReturn(id, signature, comment)
                            .then((res) => {
                                EventBus.dispatch(RESPONSIBLE_PERSON_TILL_RETURN_SUCCEED, res.data)
                                resolve(res.data)
                            })
                            .catch((e) => {
                                errorResponse = errorSender(e.response.data, lang)
                                EventBus.dispatch(RESPONSIBLE_PERSON_TILL_RETURN_FAILED, errorResponse)
                                reject(errorResponse)
                            })
                    })
                    .catch(() => {
                        errorResponse = {message: t(lang, "roaming.empowerment.alert.error.createPkcs7Async")}
                        EventBus.dispatch(RESPONSIBLE_PERSON_TILL_RETURN_FAILED, errorResponse)
                        reject(errorResponse)
                    })
            })
            .catch(() => {
                errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getCancelHashCode")}
                EventBus.dispatch(RESPONSIBLE_PERSON_TILL_RETURN_FAILED, errorResponse)
                reject(errorResponse)
            })
    })
}

export const waybillResponsiblePersonGive = (certificate, lang, id) => {
    EventBus.dispatch(HASHCODE_CANCEL_REQUESTED, id);
    let errorResponse;

    return new Promise((resolve, reject) => {
        waybillV2Service.getHashCodeResponsiblePersonGive(id)
            .then(({data: hashCode}) => {
                createPkcs7WithTimestamp(hashCode, certificate.keyId)
                    .then(({pkcs7: signature}) => {
                        waybillV2Service.responsiblePersonGive(id, signature)
                            .then((res) => {
                                EventBus.dispatch(RESPONSIBLE_PERSON_GIVE_SUCCEED, res.data)
                                resolve(res.data)
                            })
                            .catch((e) => {
                                errorResponse = errorSender(e.response.data, lang)
                                EventBus.dispatch(RESPONSIBLE_PERSON_GIVE_FAILED, errorResponse)
                                reject(errorResponse)
                            })
                    })
                    .catch(() => {
                        errorResponse = {message: t(lang, "roaming.empowerment.alert.error.createPkcs7Async")}
                        EventBus.dispatch(RESPONSIBLE_PERSON_GIVE_FAILED, errorResponse)
                        reject(errorResponse)
                    })
            })
            .catch(() => {
                errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getCancelHashCode")}
                EventBus.dispatch(RESPONSIBLE_PERSON_GIVE_FAILED, errorResponse)
                reject(errorResponse)
            })
    })
}

export const waybillResponsiblePersonReturn = (certificate, lang, id, comment) => {
    EventBus.dispatch(HASHCODE_CANCEL_REQUESTED, id);
    let errorResponse;

    return new Promise((resolve, reject) => {
        waybillV2Service.getHashCodeResponsiblePersonReturn(id)
            .then(({data: hashCode}) => {
                createPkcs7WithTimestamp(hashCode, certificate.keyId)
                    .then(({pkcs7: signature}) => {
                        waybillV2Service.responsiblePersonReturn(id, signature, comment)
                            .then((res) => {
                                EventBus.dispatch(RESPONSIBLE_PERSON_RETURN_SUCCEED, res.data)
                                resolve(res.data)
                            })
                            .catch((e) => {
                                errorResponse = errorSender(e.response.data, lang)
                                EventBus.dispatch(RESPONSIBLE_PERSON_RETURN_FAILED, errorResponse)
                                reject(errorResponse)
                            })
                    })
                    .catch(() => {
                        errorResponse = {message: t(lang, "roaming.empowerment.alert.error.createPkcs7Async")}
                        EventBus.dispatch(RESPONSIBLE_PERSON_RETURN_FAILED, errorResponse)
                        reject(errorResponse)
                    })
            })
            .catch(() => {
                errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getCancelHashCode")}
                EventBus.dispatch(RESPONSIBLE_PERSON_RETURN_FAILED, errorResponse)
                reject(errorResponse)
            })
    })
}


export const waybillV2SyncAsync = async (id) => {
    EventBus.dispatch(WAYBILL_V2_SYNC_REQUESTED, id);
    try {
        const response = await waybillV2Service.sync(id);
        EventBus.dispatch(WAYBILL_V2_SYNC_SUCCESS, id);
        return response.data;
    } catch (e) {
        let errorMessage = {message: e.response.data};
        EventBus.dispatch(WAYBILL_V2_SYNC_FAILED, errorMessage);
        console.log(e);
    }
};

// draft

export const saveDraftWaybillV2Async = (data) => {
    EventBus.dispatch(SAVE_DRAFT_WAYBILL_v2_REQUESTED)
    return new Promise((resolve, reject) => {
        roamingWaybillV2Service.saveDraftWaybillV2(data)
            .then((res) => {
                EventBus.dispatch(SAVE_DRAFT_WAYBILL_v2_SUCCEED, res.data)
                resolve(res.data)
            })
            .catch(() => {
                EventBus.dispatch(SAVE_DRAFT_WAYBILL_v2_FAILED)
                reject()
            })
    })
}

export const loadWaybillV2DraftsAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};
    dispatch(roamingWaybillV2Slice.actions.updateIsLoading(true));

    return new Promise((resolve, reject) => {
        roamingWaybillV2Service.getWaybillV2Drafts(params)
            .then(response => {
                dispatch(roamingWaybillV2Slice.actions.updateWaybillsV2(response.data));
                dispatch(roamingWaybillV2Slice.actions.updateIsLoading(false));
                resolve(response.data);
            })
            .catch(error => reject(error))
    })
}

export const getWaybillV2DraftsCount = (params) => dispatch => {
    return new Promise((resolve, reject) => {
        roamingWaybillV2Service.getWaybillV2DraftsCount(params)
            .then(response => {
                dispatch(roamingWaybillV2Slice.actions.updateWaybillsV2Count(response.data));
                resolve();
            }).catch(error => reject(error))
    })
}

export const loadWaybillV2ResponsiblePersonAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};
    dispatch(roamingWaybillV2Slice.actions.updateIsLoading(true));

    return new Promise((resolve, reject) => {
        roamingWaybillV2Service.getWaybillV2ResponsiblePersons(params)
            .then(response => {
                dispatch(roamingWaybillV2Slice.actions.updateWaybillsV2(response.data));
                dispatch(roamingWaybillV2Slice.actions.updateIsLoading(false));
                resolve(response.data);
            })
            .catch(error => reject(error))
    })
}

export const getWaybillV2ResponsiblePersonCount = (params) => dispatch => {
    return new Promise((resolve, reject) => {
        roamingWaybillV2Service.getWaybillV2ResponsiblePersonsCount(params)
            .then(response => {
                dispatch(roamingWaybillV2Slice.actions.updateWaybillsV2Count(response.data));
                resolve();
            }).catch(error => reject(error))
    })
}


export const loadDraftWaybillV2Async = id => {
    return new Promise((resolve, reject) => {
        roamingWaybillV2Service.getDraftWaybillV2(id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export const deleteWaybillV2DraftAsync = (id) => {
    return new Promise((resolve, reject) => {
        roamingWaybillV2Service.deleteDraft(id)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error)
            });
    });
};

export const {setShowModal, setId} = roamingWaybillV2Slice.actions
export default roamingWaybillV2Slice.reducer
