import {Card, Dropdown, ListGroup} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Scrollbar from '../../common/Scrollbar';
import Button from '../../common/Button';
import { notifications as notificationData } from '../../../data/notifications';
import NotificationItem from '../../common/NotificationItem';
import classNames from 'classnames';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {
    clearAllPushUpdateNotifications,
    selectPushUpdateNotification
} from "../../../store/reducers/pushupdate/pushUpdateNotification";
import {Translate} from "../../../lang/lang";
import {isIterableArray} from "../../../helpers/utils";
import Notification from "../../common/Notification";
import Badge from "../../common/Badge";

const NotificationDropdownMenu = ({ className }) => {

    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState(false);
    const notifications = useSelector(selectPushUpdateNotification)
    const [allRead, setAllRead] = useState(false)
    const lang = useSelector(selectLang);
    const t = Translate;
    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const clearAllNotification = () => {
        dispatch(clearAllPushUpdateNotifications())
        setIsOpen(false)
        setAllRead(false)
    }
    useEffect(() => {
        if (notifications.length) {
            setAllRead(true)
        } else {
            setAllRead(false)
        }
    }, [notifications]);

    return (
        <Dropdown.Menu
            align="end"
            className={classNames(
                className,
                'navbar-dropdown-caret py-0 notification-dropdown-menu shadow border border-300'
            )}
        >
            <Card className="position-relative border-0">
                <Card.Header className="p-2">
                    <div className="d-flex justify-content-between align-items-center">
                        <h5 className="text-black mb-0">{t(lang, "notification.name")}</h5>
                        <Badge variant={'phoenix'} bg={'info'} className="fs-8 fw-normal">
                            {notifications.length}
                        </Badge>
                    </div>
                </Card.Header>
                <Card.Body className="p-0" style={{ minHeight: '10rem' }}>
                        <ListGroup>
                            {isIterableArray(notifications) ?
                                notifications.map((notification, index) => (
                                    <ListGroup.Item key={index} className={"hover-bg-200"}>
                                        <Notification index={index} notification={notification}/>
                                    </ListGroup.Item>
                                )) : <h6 className={"text-center m-0 py-2 text-dark fw-normal"}>
                                    {t(lang, "notification.no_data")}</h6>}
                        </ListGroup>
                </Card.Body>
                <Card.Footer className="py-2 text-center border-top">
                    <Link className="card-link text-danger fw-semi-bold" onClick={clearAllNotification} to="#!">
                        {t(lang, "notification.clear_all")}
                    </Link>
                </Card.Footer>
            </Card>
        </Dropdown.Menu>
    );
};

export default NotificationDropdownMenu;
