import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectDateDayJSFormat, selectLang} from "../../store/reducers/main/main";
import {selectActiveOrganization} from "../../store/reducers/auth/authReducer";
import {
    loadEmpowermentsAsync,
    selectCountEmpowerments,
    selectEmpowerments,
    selectFilterOptions
} from "../../store/reducers/roaming/empowerment/empowermentReducer";
import {
    selectIsLoading
} from "../../store/reducers/roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import {useNavigate} from "react-router-dom";
import {Translate} from "../../lang/lang";
import {EmpowermentWrapper, VIEWER_DRAFT} from "../../enum/roaming/empowerment/EmpowermentStatus";
import Badge from "../../components/common/Badge";
import IconTransaction from "../../assets/icon/IconTransaction";
import RevealDropdown, {RevealDropdownTrigger} from "../../components/common/RevealDropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {Dropdown} from "react-bootstrap";
import {VIEW_DRAFT} from "../../enum/roaming/TypeViewers";
import DashboardDatatable from "./DashboardDatatable";

const DashboardEmpowermentsDatatable = ({setEmpowermentCount}) => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat);
    const empowerments = useSelector(selectEmpowerments);
    const isLoading = useSelector(selectIsLoading);
    const dispatch = useDispatch();
    const filters = useSelector(selectFilterOptions);
    const count = useSelector(selectCountEmpowerments);
    const navigate = useNavigate();
    const lang = useSelector(selectLang);
    const t = Translate;
    const [viewer, setViewer] = useState("outbox")

    useEffect(() => {
        dispatch(loadEmpowermentsAsync(viewer, {page: 1, limit: 3, viewer_inn: activeOrganization?.pinfl || activeOrganization?.inn, ...filters}));
    }, [viewer, activeOrganization]);

    useEffect(() => {
        setEmpowermentCount(count)
    }, [count]);

    const columns = [
        {
            accessor: 'contractor',
            Header: <span>{t(lang, "edi.common.label.organization")}</span> ,
            cellProps: {
                className: 'ps-3'
            },
            headerProps: {width: 300, className: "ps-3"},
            Cell: ({row: {original}}) => {
                const empowermentWrapper = new EmpowermentWrapper(original);
                const isPerson = empowermentWrapper.isPerson(activeOrganization.inn, activeOrganization.pinfl);
                const isCustomer = empowermentWrapper.isCustomer(activeOrganization.inn, activeOrganization.pinfl);
                const isExecutor = empowermentWrapper.isExecutor(activeOrganization.inn, activeOrganization.pinfl);

                return (
                    <Fragment>
                        {(isPerson || isCustomer) && <div className={'d-flex align-items-center gap-2'}>
                            <IconTransaction />
                            <div>
                             <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, 'roaming.empowerment.contact_info.from.contact_date', {
                                 "contract_number": original?.info?.number,
                                 "contract_date": original?.info?.start
                             })}
                             </span>
                                <span className={'fs-8 main-text fw-bold text-truncate'}>{original.executor.name}</span>
                                <i className={'d-block fs-9 second-text'}>{original.executor.inn || original.executor.pinfl}</i>
                            </div>
                        </div>}

                        {isExecutor &&  <div className={'d-flex align-items-center gap-2'}>
                            <IconTransaction />
                            <div>
                             <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, 'roaming.empowerment.contact_info.from.contact_date', {
                                 "contract_number": original?.info?.number,
                                 "contract_date": original?.info?.start
                             })}
                             </span>
                                <span className={'fs-8 main-text fw-bold text-truncate'}>{original.customer.name}</span>
                                <i className={'d-block fs-9 second-text'}>{original.customer.inn || original.customer.pinfl}</i>
                            </div>
                        </div>}
                    </Fragment>
                )
            }
        },
        viewer !== VIEWER_DRAFT && {
            accessor: 'status',
            Header:  t(lang, "roaming.dashboard.empowerments.status"),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            Cell: ({row: {original}}) => {
                const empowermentWrapper = new EmpowermentWrapper(original);
                return (
                    <div className={'d-inline-block text-center'}>
                        <Badge
                            bg={empowermentWrapper.GetStatusBadgeVariant(activeOrganization.inn, activeOrganization.pinfl)}
                            variant={"phoenix"}
                            className={'px-3 py-1 fs-9 text-uppercase w-100 text-decoration-none fw-medium border-0 rounded-4'}
                        >
                            {t(lang, empowermentWrapper.GetStatusTranslationKey(activeOrganization.inn, activeOrganization.pinfl))}
                        </Badge>
                    </div>
                )
            }
        },
        {
            accessor: 'action',
            Header: "",
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                        <RevealDropdown>
                            <Dropdown.Item
                                onClick={(event) => {
                                    if (viewer === VIEW_DRAFT) {
                                        navigate('/roaming/draft-empowerment/'+original.id)
                                    } else {
                                        navigate('/roaming/empowerment/'+original.id)
                                    }
                                }}>
                                <span>{t(lang, "roaming.invoices.table_header.show")}</span>
                            </Dropdown.Item>
                        </RevealDropdown>
                    </RevealDropdownTrigger>
                );
            }
        },
    ].filter(column => typeof column === 'object' && column !== null);

    return (
        <DashboardDatatable columns={columns} data={empowerments} setViewer={setViewer} />
    );
};

export default DashboardEmpowermentsDatatable;
