import React from 'react';

const IconSearch = ({width = 18, height = 18, color = "none"}) => {
    return (
        <svg  width={width} height={height} viewBox="0 0 18 18" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.6 12.6L15 15M8.6 14.2C5.50721 14.2 3 11.6928 3 8.6C3 5.50721 5.50721 3 8.6 3C11.6928 3 14.2 5.50721 14.2 8.6C14.2 11.6928 11.6928 14.2 8.6 14.2Z"
                stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default IconSearch;
