import React, {Fragment} from 'react';
import {useWatch} from "react-hook-form";
import useQuery from "../../form/useQuery";
import {Col, Row} from "react-bootstrap";
import AdditionalInformationV2Point from "./AdditionalInformationV2Point";

const AdditionalInformationV2 = ({isEditing, content}) => {

    const {draft_waybill_id, waybill_id, shipment_id} = useQuery();

    const executorInnOrPinfl = useWatch({name: 'executor.inn_or_pinfl', exact: true})
    const customerInnOrPinfl = useWatch({name: 'customer.inn_or_pinfl', exact: true})
    const branchCode = useWatch({name: 'branch_code', exact: true})

    return (
        <Fragment>
            <Row>
                <Col sm={12} md={6}>
                    <AdditionalInformationV2Point
                        pointName={'loading_point'}
                        inn={executorInnOrPinfl}
                        title={'roaming.waybill.v2.send.loading_point.title'}
                        isEditing={isEditing || draft_waybill_id || waybill_id || shipment_id}
                        content={content}
                    />
                </Col>
                <Col sm={12} md={6}>
                    <AdditionalInformationV2Point
                        pointName={'unloading_point'}
                        inn={customerInnOrPinfl}
                        title={'roaming.waybill.v2.send.unloading_point.title'}
                        customer={customerInnOrPinfl}
                        isEditing={isEditing || draft_waybill_id || waybill_id || shipment_id}
                        content={content}
                        branchCode={branchCode}
                    />
                </Col>
            </Row>
        </Fragment>
    );
};

export default AdditionalInformationV2;
