import React from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import PermissionBadge from "../../../components/common/PermissionBadge";
import {PermissionRoamingActView} from "../../../enum/permission/RoamingPermission";
import checkPermission from "../../../enum/permission/CheckPermission";
import {selectAccountRolePermissions} from "../../../store/reducers/role/roleReducer";
import ActDataTable from "../../../components/roaming/act/ActDataTable";


const RoamingActs = () => {
    const { type_viewer } = useParams()
    const dispatch = useDispatch()
    const t = Translate
    const lang = useSelector(selectLang);
    //important
    useSelector(selectAccountRolePermissions)

    if(!dispatch(checkPermission(PermissionRoamingActView))) {
        return <PermissionBadge>
            <span>{t(lang, 'system.permission.roaming.act.datatable.no_access.title')}</span>
        </PermissionBadge>
    }

    return  <ActDataTable typeViewer={type_viewer}/>

};

export default RoamingActs;
