import React, {useEffect, useState} from 'react';
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import {selectCurrentCertificate} from "../../../../store/reducers/eimzo/eimzoReducer";
import {selectLang} from "../../../../store/reducers/main/main";
import {useNavigate} from "react-router-dom";
import {Translate} from "../../../../lang/lang";
import dayjs from "dayjs";
import {generateIdAsync, loadCompanyProductCatalogsAsync} from "../../../../store/reducers/roaming/roamingReducer";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import ContractInfo from "./ContractInfo";
import OwnerInfo from "./OwnerInfo";
import ClientInfo from "./ClientInfo";
import {save, send} from "../../../../store/reducers/roaming/contracts/roamingContractReducer";
import Products from "./Products";
import Parts from "../Parts";
import SignActionWithSelectCertificateWrapper from "../../../common/SignActionWithSelectCertificateWrapper";
import IconButton from "../../../IconButton";
import {faPlus, faSignature} from "@fortawesome/free-solid-svg-icons";
import IconArrowLeft from "../../../../assets/icon/IconArrowLeft";
import SelectCertificateWrapperComponent from "../../../common/SelectCertificateWrapperComponent";
import IconSignature from "../../../../assets/icon/IconSignature";

const ContractForm = ({contract}) => {

    const activeOrganization = useSelector(selectActiveOrganization)
    const currentCertificate = useSelector(selectCurrentCertificate);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const t = Translate
    const lang = useSelector(selectLang)

    const [loading, setLoading] = useState(false);

    const methods = useForm({
        defaultValues: {
            contractId: "",
            hasVat: false,
            owner: {
                tin: "",
                name: "",
                address: "",
                workPhone: "",
                mobile: "",
                fax: "",
                oked: "",
                account: "",
                bankId: "",
                fizTin: "",
                fio: "",
                branchCode: "",
                branchName: ""
            },
            clients: [
                {
                    tin: "",
                    name: "",
                    address: "",
                    workPhone: "",
                    mobile: "",
                    fax: "",
                    oked: "",
                    account: "",
                    bankId: "",
                    fizTin: "",
                    fio: "",
                    branchCode: "",
                    branchName: ""
                }
            ],
            contractDoc: {
                contractName: "",
                contractNo: "",
                contractDate: "",
                contractExpireDate: "",
                contractPlace: ""
            },
            products: [
                {
                    ordNo: 1,
                    catalogCode: null,
                    catalogName: null,
                    barCode: "",
                    name: "",
                    measureId: "",
                    packageCode: null,
                    packageName: null,
                    packages: null,
                    count: null,
                    summa: null,
                    deliverySum: null,
                    vatRate: 0,
                    vatSum: null,
                    deliverySumWithVat: null,
                    withoutVat: false
                }
            ],
            parts: [
                {
                    ordNo: 1,
                    title: "",
                    body: ""
                }
            ]
        }
    });

    const {fields: fieldsClient, append: appendClient, remove: removeClient} = useFieldArray({
        control: methods.control,
        name: "clients",
        focusAppend: false
    })
    const {
        fields: fieldsProduct,
        append: appendProduct,
        remove: removeProduct
    } = useFieldArray({control: methods.control, name: "products", focusAppend: false})
    const {fields: fieldsPart, append: appendPart, remove: removePart} = useFieldArray({
        control: methods.control,
        name: "parts",
        focusAppend: false
    })

    const addFieldClient = () => {
        appendClient({
            tin: "",
            name: "",
            address: "",
            workPhone: "",
            mobile: "",
            fax: "",
            oked: "",
            account: "",
            bankId: "",
            fizTin: "",
            fio: "",
            branchCode: "",
            branchName: ""
        })
    }

    const removeFieldClient = index => {
        removeClient(index)
    }

    const addFieldProduct = () => {
        appendProduct({
            ordNo: fieldsProduct.length,
            catalogCode: null,
            catalogName: null,
            barCode: "",
            name: "",
            measureId: "",
            packageCode: null,
            packageName: null,
            packages: null,
            count: null,
            summa: null,
            deliverySum: null,
            vatRate: 0,
            vatSum: null,
            deliverySumWithVat: null,
            withoutVat: false
        })
    }

    const removeFieldProduct = index => {
        removeProduct(index)
    }

    const addFieldPart = () => {
        appendPart({
            ordNo: fieldsPart.length,
            title: "",
            body: "",
        })
    }

    const removeFieldPart = index => {
        removePart(index)
    }

    const signAndSend = async (data) => {
        let contract = {
            ContractId: data?.contractId || (await generateIdAsync(1))[0],
            HasVat: data.products.some((product => !product.withoutVat)),
            ContractDoc: {
                ContractName: data.contractDoc.contractName,
                ContractNo: data.contractDoc.contractNo,
                ContractDate: dayjs(data.contractDoc.contractDate).format("YYYY-MM-DD") || null,
                ContractExpireDate: dayjs(data.contractDoc.contractExpireDate).format("YYYY-MM-DD") || null,
                ContractPlace: data.contractDoc.contractPlace
            },
            Owner: {
                Tin: data.owner.tin,
                Name: data.owner.name,
                Address: data.owner.address,
                WorkPhone: data.owner.workPhone,
                Mobile: data.owner.mobile,
                Fax: data.owner.fax,
                Oked: data.owner.oked,
                Account: data.owner.account,
                BankId: data.owner.bankId,
                FizTin: data.owner.fizTin,
                Fio: data.owner.fio,
                BranchCode: data.owner.branchCode,
                BranchName: data.owner.branchName
            },
            Clients: data.clients.map(client => ({
                Tin: client.tin,
                Name: client.name,
                Address: client.address,
                WorkPhone: client.workPhone,
                Mobile: client.mobile,
                Fax: client.fax,
                Oked: client.oked,
                Account: client.account,
                BankId: client.bankId,
                FizTin: client.fizTin,
                Fio: client.fio,
                BranchCode: client.branchCode,
                BranchName: client.branchName
            })),
            Products: data.products.map((product, index) => ({
                OrdNo: index + 1,
                CatalogCode: product.catalogCode,
                CatalogName: product.catalogName,
                BarCode: product.barCode,
                Name: product.name,
                MeasureId: product.measureId,
                PackageCode: product.packageCode ? `${product.packageCode}` : null,
                PackageName: product.packageName || null,
                Count: +product.count,
                Summa: +product.summa,
                DeliverySum: +product.deliverySum,
                VatRate: +product.vatRate,
                VatSum: +product.vatSum,
                DeliverySumWithVat: +product.deliverySumWithVat,
                WithoutVat: product.withoutVat
            })),
            Parts: data.parts.map((part, index) => ({
                OrdNo: index + 1,
                Title: part.title,
                Body: part.body
            }))
        }
        let {data: responseContract} = await send({
            contract: contract,
            certificate: currentCertificate,
            lang: lang
        })
        return responseContract
    }

    const saveDraft = async (data) => {
        let contract = {
            ContractId: data?.contractId || (await generateIdAsync(1))[0],
            HasVat: data.products.some((product => !product.withoutVat)),
            ContractDoc: {
                ContractName: data.contractDoc.contractName,
                ContractNo: data.contractDoc.contractNo,
                ContractDate: dayjs(data.contractDoc.contractDate).format("YYYY-MM-DD") || null,
                ContractExpireDate: dayjs(data.contractDoc.contractExpireDate).format("YYYY-MM-DD") || null,
                ContractPlace: data.contractDoc.contractPlace
            },
            Owner: {
                Tin: data.owner.tin,
                Name: data.owner.name,
                Address: data.owner.address,
                WorkPhone: data.owner.workPhone,
                Mobile: data.owner.mobile,
                Fax: data.owner.fax,
                Oked: data.owner.oked,
                Account: data.owner.account,
                BankId: data.owner.bankId,
                FizTin: data.owner.fizTin,
                Fio: data.owner.fio,
                BranchCode: data.owner.branchCode,
                BranchName: data.owner.branchName
            },
            Clients: data.clients.map(client => ({
                Tin: client.tin,
                Name: client.name,
                Address: client.address,
                WorkPhone: client.workPhone,
                Mobile: client.mobile,
                Fax: client.fax,
                Oked: client.oked,
                Account: client.account,
                BankId: client.bankId,
                FizTin: client.fizTin,
                Fio: client.fio,
                BranchCode: client.branchCode,
                BranchName: client.branchName
            })),
            Products: data.products.map((product, index) => ({
                OrdNo: index + 1,
                CatalogCode: product.catalogCode,
                CatalogName: product.catalogName,
                BarCode: product.barCode,
                Name: product.name,
                MeasureId: product.measureId,
                PackageCode: product.packageCode ? `${product.packageCode}` : null,
                PackageName: product.packageName || null,
                Count: +product.count,
                Summa: +product.summa,
                DeliverySum: +product.deliverySum,
                VatRate: +product.vatRate,
                VatSum: +product.vatSum,
                DeliverySumWithVat: +product.deliverySumWithVat,
                WithoutVat: product.withoutVat
            })),
            Parts: data.parts.map((part, index) => ({
                OrdNo: index + 1,
                Title: part.title,
                Body: part.body
            }))
        }
        let {data: responseContract} = await save({
            contract: contract,
            certificate: currentCertificate,
            lang: lang
        })
        return responseContract
    }

    useEffect(() => {
        let contractKeyProperty = contract && Object.keys(contract)
        if (contractKeyProperty?.length) {
            contractKeyProperty.forEach(key => {
                methods.setValue(key, contract[key])
            })
        }
    }, [contract]);

    useEffect(() => {
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization.inn, lang))
    }, [activeOrganization?.inn]);
    const onSubmit = async (data, event) => {
        try {
            setLoading(true)
            let {name} = event.nativeEvent.submitter
            if (name === "draft") {
                let response = await saveDraft(data)
                if (response?.id) navigate(`/roaming/contract/draft/${response.id}`)
            } else if (name === "sign" && currentCertificate?.keyId) {
                let response = await signAndSend(data)
                if (response?.id) navigate(`/roaming/contract/${response.id}`)
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    window.addEventListener('scroll', () => {
        const verticalScrollPx = window.scrollY || window.pageYOffset;
        const roamingHeader = document.getElementById('roaming-contract-form-header')

        if (verticalScrollPx <= 20) {
            if (roamingHeader) {
                roamingHeader.style.backgroundColor = 'transparent';
                roamingHeader.classList?.remove('shadow')
                roamingHeader.classList?.remove('py-2')
            }
        } else {
            if (roamingHeader) {
                roamingHeader.style.backgroundColor = 'rgba(242, 243, 246, 1)';
                roamingHeader.classList?.add('shadow')
                roamingHeader.classList?.add('py-2')
            }
        }
    });

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)} id="ContractForm">
                <Row id={'roaming-contract-form-header'} className={'my-2 justify-content-between align-items-center position-sticky z-index-1'} style={{top: '4rem'}}>
                    <Col xl={6} sm={12} className={'d-flex align-items-center gap-3'}>
                        <button onClick={() => navigate(-1)} className={'main-text btn-back'} style={{width: 35, height: 35}}>
                            <IconArrowLeft />
                        </button>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.invoices.add.title")}</h5>
                    </Col>
                    <Col xl={6} sm={12} className={'d-flex justify-content-end gap-2'}>
                        <IconButton
                            className={'btn-davr bg-primary-davr-outline'}
                            name="draft" variant="phoenix-warning" size="lg"
                            type="submit"
                        >
                            {t(lang, "roaming.invoice.save_button")}
                        </IconButton>
                        <SelectCertificateWrapperComponent
                            size="lg"
                            icon={<IconSignature/>}
                            className={'btn-davr bg-primary-davr gap-1 text-white'}
                            submitButton={
                                <IconButton icon={faSignature}
                                            variant="phoenix-primary"
                                            size="lg"
                                            className="w-100 mt-2 mt-md-0 w-md-auto fw-semi-bold"
                                            name="sign"
                                            type="submit"
                                            form="ContractForm"
                                >
                                    {t(lang, "roaming.invoice.send_button")}
                                </IconButton>
                            }
                            openDialogButtonText={t(lang, "roaming.invoice.send_button")}/>
                    </Col>
                </Row>

                <h5 className="mb-2 ms-3 fw-medium fs-9 second-text">{t(lang, "roaming.contracts.add.contract_info.title")}</h5>
                <Row>
                    <Col sm={12} className="mb-3">
                        <Card className={'p-2 rounded-4 border-0'}>
                            <ContractInfo/>
                        </Card>
                    </Col>

                    <Col sm="6" className="mb-2">
                        <OwnerInfo/>
                    </Col>

                    {fieldsClient.map((client, index) => (
                        <Col sm="6" className="mb-2" key={client.id}>
                            <ClientInfo index={index} addFieldClient={addFieldClient}
                                        removeFieldClient={removeFieldClient}/>
                        </Col>
                    ))}

                    <Col sm={12} className={'d-flex justify-content-center'}>
                        <IconButton
                            onClick={addFieldClient}
                            icon={faPlus}
                            size="md"
                            transform="shrink-3"
                            className="btn-davr px-3 py-0 bg-primary-davr-outline my-3 fs-8"
                        >
                            {t(lang, "roaming.common.contract.add.new.contractor")}
                        </IconButton>
                    </Col>

                    <Col sm="12" className="mb-4">
                        <Products fieldsProduct={fieldsProduct} addFieldProduct={addFieldProduct}
                                  removeFieldProduct={removeFieldProduct}/>
                    </Col>

                    <Col sm="12" className="mb-4">
                        <Parts fieldsPart={fieldsPart} addFieldPart={addFieldPart} removeFieldPart={removeFieldPart}/>
                    </Col>
                </Row>
            </Form>
        </FormProvider>
    );
};

export default ContractForm;
