import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, CloseButton } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import IconCancel from "../../assets/icon/IconCancel";
import IconAlertError from "../../assets/icon/IconAlertError";
import IconAlertPrimary from "../../assets/icon/IconAlertPrimary";
import IconAlertWarning from "../../assets/icon/IconAlertWarning";

const IconAlert = ({
                       variant,
                       dismissible,
                       children,
                       className,
                       onClose,
                       ...rest
                   }) => {
    const icon = {
        primary: <IconAlertPrimary />,
        info: 'info-circle',
        warning: <IconAlertWarning />,
        danger: <IconAlertError />
    };
    return (
        <div
            className={classNames(className, 'd-flex align-items-start py-1 px-2 rounded-3')}
            {...rest}
        >
            <div className={`me-1`}>
                {/*<FontAwesomeIcon icon={icon[variant]} className="text-white fs-3" />*/}
                {icon[variant]}
            </div>
            <div className="flex-1 align-items-center">{children}</div>
            {/*{dismissible && <CloseButton onClick={onClose} />}*/}
        </div>
    );
};

IconAlert.propTypes = {
    variant: PropTypes.oneOf(['success', 'danger', 'warning', 'info']).isRequired,
    dismissible: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    onClose: PropTypes.func
};

export default IconAlert;
