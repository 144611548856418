import React, {Fragment} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {Col, Row, Form, Card, FloatingLabel} from "react-bootstrap";
import Cleave from "cleave.js/react";
const AdditionalInformation = () => {
    const {register} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Fragment>
            <h4 className={'fs-8 fw-medium second-text ms-3'}>{t(lang, 'roaming.waybill.send.additional_information.title')}</h4>
            <Card className="p-2">
                <Row>
                    <Col className="my-1" xs={12} md={6}>
                        <Form.Group>
                            <FloatingLabel label={t(lang, 'roaming.waybill.send.additional_information.giver_fio')}>
                                <Form.Control
                                    type="text"
                                    {...register("giver_fio")}
                                    placeholder={t(lang, 'roaming.waybill.send.additional_information.giver_fio')}
                                />
                            </FloatingLabel>

                        </Form.Group>
                    </Col>
                    <Col className="my-1" xs={12} md={6}>
                        <Form.Group>
                            <FloatingLabel label={t(lang, 'roaming.waybill.send.additional_information.taker_fio')}>
                                <Form.Control
                                    type="text"
                                    {...register("taker_fio")}
                                    placeholder={t(lang, 'roaming.waybill.send.additional_information.taker_fio')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                    </Col>
                    <Col className="my-1" xs={12} md={4}>
                        <Form.Group>
                            <FloatingLabel label={t(lang, 'roaming.waybill.send.additional_information.special_notes')}>
                                <Form.Control
                                    type="text"
                                    {...register("special_notes")}
                                    placeholder={t(lang, 'roaming.waybill.send.additional_information.special_notes')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                    </Col>

                    <Col className="my-1" xs={12} md={4}>
                        <Form.Group>
                            <Controller
                                name="delivery_distance.total"
                                render={({field}) => (
                                    <FloatingLabel label={t(lang, 'roaming.waybill.send.additional_information.delivery_distance')}>
                                        <Cleave
                                            {...field}
                                            className="form-control"
                                            options={{numeral: true, delimiter: ''}}
                                            placeholder={t(lang, 'roaming.waybill.send.additional_information.delivery_distance')}
                                        />
                                    </FloatingLabel>
                                )}
                            />
                        </Form.Group>
                    </Col>
                    <Col className="my-1" xs={12} md={4}>
                        <Form.Group>
                            <Controller
                                name="delivery_distance.in_city"
                                render={({field}) => (
                                    <FloatingLabel label={t(lang, 'roaming.waybill.send.additional_information.delivery_distance_in_city')}>
                                        <Cleave
                                            {...field}
                                            className="form-control"
                                            options={{numeral: true, delimiter: ''}}
                                            placeholder={t(lang, 'roaming.waybill.send.additional_information.delivery_distance_in_city')}
                                        />
                                    </FloatingLabel>
                                )}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Card>
        </Fragment>
    );
};

export default AdditionalInformation;
