import React, {Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectDateDayJSFormat, selectLang} from "../../../store/reducers/main/main";
import {
    changeFreeFormsFilterOptions,
    selectFreeFormDocuments, selectFreeFormDocumentsCount, selectFreeFormDocumentsFilterOptions,
    selectFreeFormDocumentsLoading, setId, setShowModal, viewPage
} from "../../../store/reducers/roaming/free-form/freeFormDocumentReducer";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {
    roamingHideModal,
    setRoamingCommonHideModal
} from "../../../store/reducers/roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import dayjs from "dayjs";
import Badge from "../../common/Badge";
import {GetStatusText, GetStatusVariant} from "../../../enum/roaming/free-form/FreeFormDocumentStatus";
import AdvanceTableWrapper from "../../table/AdvanceTableWrapper";
import FreeFormDocumentDataTableHeader from "./FreeFormDocumentDataTableHeader";
import AdvanceTable from "../../table/AdvanceTable";
import AdvanceTableCustomPagination from "../../table/AdvanceTableCustomPagination";
import ViewFreeFormDocument from "../../../pages/roaming/free-forms/ViewFreeFormDocument";
import ViewModalPage from "../../common/ViewModalPage";
import {useNavigate} from "react-router-dom";
import {Card, Dropdown} from "react-bootstrap";
import TypeViewerAction from "../../common/TypeViewerAction";
import IconFolderDownload from "../../../assets/icon/IconFolderDownload";
import {checkViewer} from "../../../enum/roaming/checkViewer";
import RevealDropdown, {RevealDropdownTrigger} from "../../common/RevealDropdown";

const FreeFormDocumentDataTable = ({viewType}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const t = Translate;
    const lang = useSelector(selectLang);
    const count = useSelector(selectFreeFormDocumentsCount);
    const loading = useSelector(selectFreeFormDocumentsLoading);
    const freeFormDocuments = useSelector(selectFreeFormDocuments);
    const filter = useSelector(selectFreeFormDocumentsFilterOptions);
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat);

    const {id, showModal} = useSelector(viewPage)
    const hideModal = useSelector(roamingHideModal)

    const openViewModalPage = (e, id) => {
        // const ctrlKey = e.ctrlKey
        // const history = window.open

        // if (ctrlKey) {
        // history('/roaming/free-form/'+id, '_blank')
        // } else {
        // dispatch(setId(id))
        // dispatch(setShowModal(true))
        // }
        navigate('/roaming/free-form/' + id)

    }

    const onHideModalPage = () => {
        if (showModal) {
            dispatch(setShowModal(false))
        }
    }

    // to close the modal on page change
    useEffect(() => {
        if (hideModal) {
            onHideModalPage()
            dispatch(setRoamingCommonHideModal(null))
        }
    }, [])

    const columns = [
        {
            accessor: 'created_at',
            Header: t(lang, 'roaming.free_form_document.datatable.row.document_created_at'),
            Cell: ({row: {original}}) => {
                return dayjs(original.created_at).format(currentDateDayJSFormat)
            }
        },
        {
            accessor: 'contractor',
            Header: t(lang, 'roaming.free_form_document.datatable.row.document_counterparty'),
            cellProps: {
                className: 'pe-4'
            },
            headerProps: {width: 300},
            Cell: ({row: {original}}) => {
                return activeOrganization.inn === original.sender.inn
                    ? <div className={'d-flex align-items-center gap-2'}>
                        {checkViewer(viewType)}
                        <div>
                             <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, "roaming.verification_act.datatable.body.row.verification_act", {
                                 name: original?.info?.number,
                                 date: dayjs(original?.info?.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                             })}
                             </span>
                            <span className={'fs-8 main-text fw-bold text-truncate'}>{original.receiver.name}</span>
                            <i className={'d-block fs-9 second-text'}>{original.receiver.inn || original.receiver.pinfl}</i>
                        </div>
                    </div>
                    : <div className={'d-flex align-items-center gap-2'}>
                        {checkViewer(viewType)}
                        <div>
                             <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, "roaming.verification_act.datatable.body.row.verification_act", {
                                 name: original?.info?.number,
                                 date: dayjs(original?.info?.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                             })}
                             </span>
                            <span className={'fs-8 main-text fw-bold text-truncate'}>{original.sender.name}</span>
                            <i className={'d-block fs-9 second-text'}>{original.sender.inn || original.sender.pinfl}</i>
                        </div>
                    </div>
            }
        },
        {
            accessor: 'status',
            Header: t(lang, 'roaming.free_form_document.datatable.row.document_status'),
            cellProps: {
                className: 'text-center'
            },
            headerProps: {
                className: 'text-center'
            },
            Cell: ({row: {original}}) => {
                return (
                    <div className={'d-inline-block text-center'}>
                        <Badge variant={'phoenix'} bg={GetStatusVariant(original.status)}
                               className={'px-3 py-1 fs-9 text-uppercase w-100 text-decoration-none fw-medium border-0 rounded-4'}>
                            {t(lang, GetStatusText(original.status))}
                        </Badge>
                    </div>
                )
            }
        },
        {
            accessor: 'action',
            Header: "",
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                        <RevealDropdown>
                            <Dropdown.Item
                                onClick={(event) => {
                                    openViewModalPage(event, original.id)
                                }}>
                                <span>{t(lang, "roaming.invoices.table_header.show")}</span>
                            </Dropdown.Item>
                        </RevealDropdown>
                    </RevealDropdownTrigger>
                );
            }
        },
    ];

    const onPageChange = page => dispatch(changeFreeFormsFilterOptions({...filter, page: page}));
    const onLimitChange = selectedOption => dispatch(changeFreeFormsFilterOptions({limit: selectedOption, page: 1}));

    return (
        <Fragment>
            <AdvanceTableWrapper
                pagination
                perPage={1000}
                columns={columns}
                data={freeFormDocuments}
                selection
            >
                <FreeFormDocumentDataTableHeader/>

                <Card className={'p-0 rounded-4'}>
                    <Card.Body className={'p-0'}>
                        <TypeViewerAction to={'/roaming/free-forms'} typeViewer={viewType} draft={true}/>

                        <AdvanceTable
                            table
                            rowClassName="align-middle white-space-nowrap"
                            headerClassName="text-900 text-nowrap align-middle"
                            tableProps={{
                                size: 'sm',
                                loading,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                </Card>

                <AdvanceTableCustomPagination
                    count={count}
                    limit={filter.limit}
                    page={filter.page}
                    onLimitChange={onLimitChange}
                    onPageChange={onPageChange}
                />
            </AdvanceTableWrapper>

            <ViewModalPage show={showModal} onHide={onHideModalPage}>
                {id && <ViewFreeFormDocument modalId={id}/>}
            </ViewModalPage>
        </Fragment>
    );
};

export default FreeFormDocumentDataTable;
