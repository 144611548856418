import axios from "axios"

class SettingsService {
    getSettings() {
        return axios.get("/settings/organization/get");
    }

    updateEdiSettings({payload}) {
        return axios.post("/settings/organization/update-edi", payload);
    }

    updateEdoSettings({payload}) {
        return axios.post("/settings/organization/update-edo", payload)
    }

    updateLangSettings({payload}) {
        return axios.post("/settings/organization/update-lang", payload);
    }

    updateInvoiceRegistrySettings(payload) {
        return axios.post("/settings/organization/update-invoice-registry", payload);
    }
    getDefaultSettings() {
        return axios.get(`/settings/organization-account/get`)
    }
}

const settingService = new SettingsService();
export default settingService;
