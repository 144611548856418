import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {selectCurrentCertificate} from "../../../store/reducers/eimzo/eimzoReducer";
import {useNavigate} from "react-router-dom";
import {selectLang, updateShowInProgressAsync} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {selectAccountRolePermissions} from "../../../store/reducers/role/roleReducer";
import checkPermission from "../../../enum/permission/CheckPermission";
import {PermissionRoamingActCreate} from "../../../enum/permission/RoamingPermission";
import {
    generateIdAsync,
    loadCompanyProductCatalogsAsync,
    loadMeasurementsAsync
} from "../../../store/reducers/roaming/roamingReducer";
import toast from "react-hot-toast";
import EventBus from "../../../eventbus/EventBus";
import {
    ROAMING_ACT_DRAFT_FAILED,
    ROAMING_ACT_DRAFT_REQUESTED, ROAMING_ACT_DRAFT_SUCCESS,
    ROAMING_ACT_REGISTER_FAILED,
    ROAMING_ACT_REGISTER_REQUESTED,
    ROAMING_ACT_REGISTER_SUCCESS
} from "../../../eventbus/romaing/act/roamingActEvents";
import {getOrganizationBillingInfoAsync} from "../../../store/reducers/billing/billingReducer";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import {ActFormSendAsync, ActFormSendDraft} from "../../../store/reducers/roaming/act/actReducer";
import {Col, Form, Row} from "react-bootstrap";
import ActFormInfo from "../../../components/roaming/act/form/ActFormInfo";
import ActFormOwnerBuyer from "../../../components/roaming/act/form/ActFormOwnerBuyer";
import ActFormDataTable from "../../../components/roaming/act/form/ActFormDataTable";
import IconButton from "../../../components/IconButton";
import {faBookmark, faSignature} from "@fortawesome/free-solid-svg-icons";
import SignActionWithSelectCertificateWrapper from "../../../components/common/SignActionWithSelectCertificateWrapper";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import {scrollEvent} from "../../../helpers/scrollEvent";

const SendAct = () => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const lang = useSelector(selectLang);
    const t = Translate;
    const permission = useSelector(selectAccountRolePermissions)

    const methods = useForm({
        defaultValues: {
            act: {
                info: null,
                date: null,
            },
            contract: {
                number: null,
                date: null,
            },
            ActComment: '',
            ActSeller: {
                inn: activeOrganization.inn,
                name: activeOrganization.name,
                pinfl: activeOrganization.pinfl,
            },
            SellerTin: null,
            SellerName: '',
            SellerBranchCode: '',
            SellerBranchName: '',
            BuyerBranchName: '',
            BuyerBranchCode: '',
            ActBuyer: {
                inn: '',
                name: '',
                pinfl: '',
            },
            itemAct: [
                {
                    catalogClassCode: '',
                    catalogClassName: '',
                    Items: '',
                    measurementId: '',
                    packageNode: null,
                    packageName: null,
                    packages: null,
                    quantity: null,
                    price: null,
                    totalSum: '',
                }
            ]
        },
    });

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingActCreate))) {
            navigate(-1)
        }
    }, [permission])

    useEffect(() => {
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization.inn, lang));
        dispatch(loadMeasurementsAsync(lang));

        methods.setValue('ActSeller', {
            name: activeOrganization?.name || '',
            inn: activeOrganization?.inn || '',
            pinfl: activeOrganization?.pinfl || '',
        });

    }, [activeOrganization])

    useEffect(() => {
        const onSendActRequestedHandler = EventBus.on(ROAMING_ACT_REGISTER_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onSendActSuccessHandler = EventBus.on(ROAMING_ACT_REGISTER_SUCCESS, act => {
            toast.success(t(lang, "edi.common.toast.success"))
            dispatch(getOrganizationBillingInfoAsync());
            navigate(`/roaming/act/${act.id}`)
            dispatch(updateShowInProgressAsync(false))
        })

        const onSendActFailedHandler = EventBus.on(ROAMING_ACT_REGISTER_FAILED, () => {
            toast.error(t(lang, "edi.common.toast.error"))
            dispatch(updateShowInProgressAsync(false))
        })

        const onDraftSendActRequestedHandler = EventBus.on(ROAMING_ACT_DRAFT_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onDraftSendActSuccessHandler = EventBus.on(ROAMING_ACT_DRAFT_SUCCESS, act => {
            toast.success(t(lang, "roaming.act_form_save_success"))
            navigate(`/roaming/draft-act/${act.id}`)
            dispatch(updateShowInProgressAsync(false))
        })

        const onDraftSendActFailedHandler = EventBus.on(ROAMING_ACT_DRAFT_FAILED, () => {
            toast.error(t(lang, "roaming.act_form_save_error"))
            dispatch(updateShowInProgressAsync(false))
        })

        return () => {
            EventBus.remove(ROAMING_ACT_REGISTER_REQUESTED, onSendActRequestedHandler)
            EventBus.remove(ROAMING_ACT_REGISTER_SUCCESS, onSendActSuccessHandler)
            EventBus.remove(ROAMING_ACT_REGISTER_FAILED, onSendActFailedHandler)
            EventBus.remove(ROAMING_ACT_DRAFT_REQUESTED, onDraftSendActRequestedHandler)
            EventBus.remove(ROAMING_ACT_DRAFT_SUCCESS, onDraftSendActSuccessHandler)
            EventBus.remove(ROAMING_ACT_DRAFT_FAILED, onDraftSendActFailedHandler)
        }

    },[])

    const onSubmit = async (formData, event) => {
        const buttonType = event.nativeEvent.submitter.name
        const [ActId, ActProductId] = await generateIdAsync(2)

        const ActSend = JSON.stringify({
            ActId,
            ActDoc: {
                ActNo: formData.act.info,
                ActDate: dayjs(formData.act.date).format('YYYY-MM-DD'),
                ActText: formData.ActComment,
            },
            ContractDoc: {
                ContractNo: formData.contract.number,
                ContractDate: dayjs(formData.contract.date).format('YYYY-MM-DD'),
            },
            SellerTin: formData.ActSeller.pinfl || formData.ActSeller.inn,
            SellerName: formData.ActSeller.name,
            SellerBranchCode: formData.SellerBranchCode,
            SellerBranchName: formData.SellerBranchName,
            BuyerTin: formData.ActBuyer.pinfl || formData.ActBuyer.inn,
            BuyerName: formData.ActBuyer.name,
            BuyerBranchName: formData.BuyerBranchName,
            BuyerBranchCode: formData.BuyerBranchCode,
            ProductList: {
                ActProductId,
                Tin: formData.ActSeller.pinfl || formData.ActSeller.inn,
                Products: formData.itemAct.map((item, index) => {
                    return {
                        OrdNo: index + 1,
                        Name: item.Items,
                        CatalogCode: item.catalogClassCode,
                        CatalogName: item.catalogClassName,
                        PackageCode: item.packageCode ? `${item.packageCode}` : null,
                        PackageName: item.packageName,
                        MeasureId: item.measurementId,
                        Count: item.quantity,
                        Summa: item.price,
                        TotalSum: item.totalSum,
                    }
                })
            },
        })

        if(buttonType === "send") {
            if(currentCertificate?.keyId) {
                await ActFormSendAsync(currentCertificate, lang, ActSend, ActId, formData.ActSeller.pinfl || formData.ActSeller.inn)
            }
        } else if(buttonType === "draft") {
            await ActFormSendDraft(ActSend)
        }
    };


    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('roaming-act-form-header'))
        return () => window.removeEventListener('scroll', () => scrollEvent('roaming-act-form-header'))
    }, []);

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)} id="ActForm">

                <Row id={'roaming-act-form-header'} className={'my-2 justify-content-between align-items-center position-sticky z-index-1'} style={{top: '4rem'}}>
                    <Col xl={6} sm={12} className={'d-flex align-items-center gap-3'}>
                        <button type={"button"} onClick={() => navigate(-1)} className={'main-text btn-back'} style={{width: 35, height: 35}}>
                            <IconArrowLeft />
                        </button>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.common.new.empowerment")}</h5>
                    </Col>
                    <Col className={'d-flex justify-content-end gap-2'}>
                        <IconButton
                            className={'btn-davr bg-primary-davr-outline'}
                            type="submit"
                            variant="warning"
                            name="draft"
                        >
                            {t(lang, "roaming.act_form_save")}
                        </IconButton>

                        <SignActionWithSelectCertificateWrapper isForm className={'btn-davr bg-primary-davr gap-1 text-white'}>
                            <IconButton
                                form="ActForm"
                                className={'btn-davr bg-primary-davr gap-1 text-white'}
                                type="submit"
                                name="send"
                                variant="phoenix-primary"
                            >{t(lang, 'roaming.act_form_send')}
                            </IconButton>
                        </SignActionWithSelectCertificateWrapper>
                    </Col>
                </Row>

                <ActFormInfo/>
                <ActFormOwnerBuyer/>
                <ActFormDataTable/>
            </Form>
        </FormProvider>
    );
};

export default SendAct;
