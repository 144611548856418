import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {selectLang, updateShowInProgressAsync} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {
    loadDraftEmpowerment,
    loadEmpowermentAsync
} from "../../../store/reducers/roaming/empowerment/empowermentReducer";
import toast from "react-hot-toast";
import EventBus from "../../../eventbus/EventBus";
import {
    EMPOWERMENT_DRAFT_FAILED,
    EMPOWERMENT_DRAFT_REQUESTED, EMPOWERMENT_DRAFT_SUCCESS,
    EMPOWERMENT_SEND_FAILED,
    EMPOWERMENT_SEND_REQUESTED,
    EMPOWERMENT_SEND_SUCCESS
} from "../../../eventbus/romaing/empowerment/empowermentEvents";
import SpinnerComponent from "../../../components/common/SpinnerComponent";
import EmpowermentFillForm from "../../../components/roaming/empowerments/form/EmpowermentFillForm";

const FillEmpowerment = () => {
    const { search } = useLocation();
    const activeOrganization = useSelector(selectActiveOrganization);
    const [empowerment, setEmpowerment] = useState(null);
    const [draftEmpowerment, setDraftEmpowerment] = useState(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const lang = useSelector(selectLang)
    const {id} = useParams();
    const t = Translate;

    const query = useMemo(() => {
        return new URLSearchParams(search);
    }, [search])

    useEffect( () => {
        loadEmpowerment()
    },[id, activeOrganization])

    const loadEmpowerment = async () => {
        const status = JSON.parse(query.get("status"));
        if (status) {
            loadEmpowermentAsync(id)
                .then(loadedEmpowerment => {
                    setEmpowerment(loadedEmpowerment)
                })
                .catch(errors => {
                    setEmpowerment(null)
                    console.log(errors)
                    toast.error(t(lang,"roaming.common.error_not_found"))
                })
        } else {
            loadDraftEmpowerment(id)
                .then(getDraft => {
                    setDraftEmpowerment(JSON.parse(getDraft?.data?.content))
                })
                .catch(errors => {
                    setDraftEmpowerment(null)
                    console.log(errors)
                    toast.error(t(lang,"roaming.common.error_not_found"))
                })
        }
    };

    useEffect(() => {
        const onEmpowermentSentRequestedHandler = EventBus.on(EMPOWERMENT_SEND_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onEmpowermentSentSuccessHandler = EventBus.on(EMPOWERMENT_SEND_SUCCESS, empowerment => {
            dispatch(updateShowInProgressAsync(false))
            toast.success(t(lang, 'roaming.toast.empowerment_send_success'))
            navigate(`/roaming/empowerment/${empowerment.id}`)
        })

        const onEmpowermentSentFailedHandler = EventBus.on(EMPOWERMENT_SEND_FAILED, (error) => {
            dispatch(updateShowInProgressAsync(false))
            toast.error(error.message)
        })

        const onEmpowermentDraftRequestedHandler = EventBus.on(EMPOWERMENT_DRAFT_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onEmpowermentDraftSuccessHandler = EventBus.on(EMPOWERMENT_DRAFT_SUCCESS, empowermentId => {
            dispatch(updateShowInProgressAsync(false))
            toast.success(t(lang, 'roaming.act_form_save_success'))
            navigate(`../../draft-empowerment/${empowermentId.id}`)
        })

        const onEmpowermentDraftFailedHandler = EventBus.on(EMPOWERMENT_DRAFT_FAILED, () => {
            dispatch(updateShowInProgressAsync(false))
            toast.success(t(lang, 'roaming.act_form_save_error'))
        })

        return () => {
            EventBus.remove(EMPOWERMENT_SEND_REQUESTED, onEmpowermentSentRequestedHandler);
            EventBus.remove(EMPOWERMENT_SEND_SUCCESS, onEmpowermentSentSuccessHandler);
            EventBus.remove(EMPOWERMENT_SEND_FAILED, onEmpowermentSentFailedHandler);
            EventBus.remove(EMPOWERMENT_DRAFT_REQUESTED, onEmpowermentDraftRequestedHandler);
            EventBus.remove(EMPOWERMENT_DRAFT_SUCCESS, onEmpowermentDraftSuccessHandler);
            EventBus.remove(EMPOWERMENT_DRAFT_FAILED, onEmpowermentDraftFailedHandler);
        }
    },[])

    const draftEmpowermentParse = {
        info: {
            number: draftEmpowerment?.EmpowermentDoc?.EmpowermentNo,
            start: draftEmpowerment?.EmpowermentDoc?.EmpowermentDateOfIssue,
            end: draftEmpowerment?.EmpowermentDoc?.EmpowermentDateOfExpire,
        },
        customer: {
            inn: draftEmpowerment?.BuyerTin,
            name: draftEmpowerment?.Buyer?.Name,
            director: draftEmpowerment?.Buyer?.Director,
            accountant: draftEmpowerment?.Buyer?.Accountant,
            bank_name: '',
        },
        executor: {
            inn: draftEmpowerment?.SellerTin,
            name: draftEmpowerment?.Seller?.Name,
            director: draftEmpowerment?.Seller?.Director,
            accountant: draftEmpowerment?.Seller?.Accountant,
            bank_name: '',
        },
        person_info: {
            person_inn: draftEmpowerment?.Agent?.AgentTin,
            full_name: draftEmpowerment?.Agent?.Fio,
        },
        contract: {
            date: draftEmpowerment?.ContractDoc?.ContractDate,
            number: draftEmpowerment?.ContractDoc?.ContractNo,
        },
        status: true,
        items: draftEmpowerment?.ProductList?.Products.map(item => {
            return {
                ordering_number: item?.OrdNo,
                name: item?.Name,
                quantity: item?.Count,
                measure_id: item?.MeasureId,
                catalog: {
                    code: item?.CatalogCode,
                    name: item?.CatalogName,
                }
            }
        })
    }


    return (
        <Fragment>
            {(!empowerment && !draftEmpowerment) ? <SpinnerComponent /> : <EmpowermentFillForm empowerment={empowerment || draftEmpowermentParse} />}
        </Fragment>
    );
};

export default FillEmpowerment;
