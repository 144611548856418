import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {selectOrganizationActivePackages} from "../../../store/reducers/billing/billingReducer";
import {Card, Col, ListGroup, Row} from "react-bootstrap";
import classNames from "classnames";
import ActivePackageCard from "./ActivePackageCard";

const OrganizationActivePackages = ({setActivePackage}) => {
    const activePackages = useSelector(selectOrganizationActivePackages);
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Fragment>
            <Row className={classNames('py-2', {
                'd-flex justify-content-center': activePackages && activePackages.length < 3
            })}>
                <Col xs={12} className="text-center">
                    {!activePackages &&
                    <h2 className={'fw-semi-bold'}>{t(lang, 'main.billing.active_packages_card.no_active_packages')}</h2>}
                </Col>

                <div className={'p-3'}>
                    <ListGroup as={Card}>
                        {activePackages && activePackages.map((activePackage, index) => (
                            <ActivePackageCard key={index} activePackage={activePackage} setActivePackage={setActivePackage} />
                        ))}
                    </ListGroup>
                </div>
            </Row>
        </Fragment>
    );
};

export default OrganizationActivePackages;
