import {createSlice} from "@reduxjs/toolkit";
import {
    ACCEPT_FREE_FORM_DOCUMENT_FAILED,
    ACCEPT_FREE_FORM_DOCUMENT_REQUESTED, ACCEPT_FREE_FORM_DOCUMENT_SUCCEED,
    CANCEL_FREE_FORM_DOCUMENT_FAILED,
    CANCEL_FREE_FORM_DOCUMENT_REQUESTED, CANCEL_FREE_FORM_DOCUMENT_SUCCEED, DECLINE_FREE_FORM_DOCUMENT_FAILED, DECLINE_FREE_FORM_DOCUMENT_REQUESTED, DECLINE_FREE_FORM_DOCUMENT_SUCCEED,
    SEND_FREE_FORM_DOCUMENT_FAILED,
    SEND_FREE_FORM_DOCUMENT_REQUESTED,
    SEND_FREE_FORM_DOCUMENT_SUCCEED, SIGN_FREE_FORM_DOCUMENT_FAILED,
    SIGN_FREE_FORM_DOCUMENT_REQUESTED, SIGN_FREE_FORM_DOCUMENT_SUCCEED
} from "../../../../eventbus/romaing/free-form/roamingFreeFormDocumentEvents";
import {attachTimestampAsync, createPkcs7WithTimestamp} from "../../eimzo/eimzoReducer";
import EventBus from "../../../../eventbus/EventBus";
import RoamingFreeFormDocumentService from '../../../services/roaming/free-form/freeFormDocumentService'
import RoamingService from "../../../services/roaming/roamingService";
export const roamingFreeFormDocumentSlice = createSlice({
    name: 'roamingFreeFormDocument',
    initialState: {
        isLoading: false,
        freeFormDocuments: [],
        freeFormDocumentsCount: 0,
        filterOptions: {page: 1, limit: 10},
        viewPage: {
            id: null,
            showModal: false
        }
    },
    reducers: {
        setShowModal: (state, action) => {
            if (!action.payload) {
                state.viewPage = {id: null, showModal: false}
            } else {
                state.viewPage.showModal = action.payload
            }
        },
        setId: (state, action) => {
            state.viewPage.id = action.payload
        },
        updateIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        updateFreeFormDocuments: (state, action) => {
            state.freeFormDocuments = action.payload;
        },
        updateFreeFormDocumentsCount: (state, action) => {
            state.freeFormDocumentsCount = action.payload;
        },
        updateFreeFormDocumentsFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload};
        },
    }
});


export const selectFreeFormDocuments = state => state.roamingFreeFormDocument.freeFormDocuments;
export const selectFreeFormDocumentsLoading = state => state.roamingFreeFormDocument.isLoading;
export const selectFreeFormDocumentsCount = state => state.roamingFreeFormDocument.freeFormDocumentsCount;
export const selectFreeFormDocumentsFilterOptions = state => state.roamingFreeFormDocument.filterOptions;
export const viewPage = state => state.roamingFreeFormDocument.viewPage

export const loadFreeFormDocumentAsync = id => {
    return new Promise((resolve, reject) => {
        RoamingFreeFormDocumentService.getFreeFormDocument(id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
};
export const sendFreeFormDocumentAsync = (payload) => {
    EventBus.dispatch(SEND_FREE_FORM_DOCUMENT_REQUESTED);

    return new Promise((resolve, reject) => {
        RoamingFreeFormDocumentService.registerFreeFormDocument(payload)
            .then(response => {
                EventBus.dispatch(SEND_FREE_FORM_DOCUMENT_SUCCEED, response.data);
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(SEND_FREE_FORM_DOCUMENT_FAILED);
                reject(error)
            })
    })
};
export const loadFreeFormSenderDocumentsAsync = ({page, limit, ...filters}) => (dispatch) => {
    dispatch(roamingFreeFormDocumentSlice.actions.updateIsLoading(true));

    return new Promise((resolve, reject) => {
        const skip = (page - 1) * limit;
        const params = {skip, limit, ...filters};

        RoamingFreeFormDocumentService.getFreeFormSenderDocuments(params)
            .then(response => {
                dispatch(roamingFreeFormDocumentSlice.actions.updateFreeFormDocuments(response.data));
                dispatch(roamingFreeFormDocumentSlice.actions.updateIsLoading(false));
                resolve();
            }).catch(error => reject(error))
    })
};
export const loadFreeFormSenderDocumentsCountAsync = (params) => dispatch => {
    const {limit, page, ...others} = params;

    return new Promise((resolve, reject) => {
        RoamingFreeFormDocumentService.getFreeFormSenderDocumentsCount(others)
            .then(response => {
                dispatch(roamingFreeFormDocumentSlice.actions.updateFreeFormDocumentsCount(response.data));
                resolve();
            }).catch(error => reject(error))
    })
};

export const loadFreeFormReceiverDocumentsAsync = ({page, limit, ...filters}) => (dispatch) => {
    dispatch(roamingFreeFormDocumentSlice.actions.updateIsLoading(true));

    return new Promise((resolve, reject) => {
        const skip = (page - 1) * limit;
        const params = {skip, limit, ...filters};

        RoamingFreeFormDocumentService.getFreeFormReceiverDocuments(params)
            .then(response => {
                dispatch(roamingFreeFormDocumentSlice.actions.updateFreeFormDocuments(response.data));
                dispatch(roamingFreeFormDocumentSlice.actions.updateIsLoading(false));
                resolve();
            }).catch(error => reject(error))
    })
};
export const loadFreeFormReceiverDocumentsCountAsync = (params) => dispatch => {
    const {limit, page, ...others} = params;

    return new Promise((resolve, reject) => {
        RoamingFreeFormDocumentService.getFreeFormReceiverDocumentsCount(others)
            .then(response => {
                dispatch(roamingFreeFormDocumentSlice.actions.updateFreeFormDocumentsCount(response.data));
                resolve();
            }).catch(error => reject(error))
    })
};

export const loadFreeFormDocumentPdfFileAsync = (id) => {
    return new Promise((resolve, reject) => {
        RoamingFreeFormDocumentService.getFreeFormDocumentPdfFile(id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
};
export const loadFreeFormDocumentZipFileAsync = (id) => {
    return new Promise((resolve, reject) => {
        RoamingFreeFormDocumentService.getFreeFormDocumentZipFile(id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
};
export const changeFreeFormsFilterOptions = (params) => dispatch => {
    return dispatch(roamingFreeFormDocumentSlice.actions.updateFreeFormDocumentsFilterOptions(params));
};
export const signFreeFormDocumentAsync = async ({id, certificate}) => {
    EventBus.dispatch(SIGN_FREE_FORM_DOCUMENT_REQUESTED);

    try {
        const {data: hashCode} = await RoamingFreeFormDocumentService.getSignHashCode(id);

        const {pkcs7: signature, signatureHex, signerSerialNumber} = await createPkcs7WithTimestamp(hashCode, certificate.keyId);
        const {data: timeStamp} = await RoamingService.getTimestamp(signatureHex);
        const {pkcs7: roamingSignature} = await attachTimestampAsync(signature, signerSerialNumber, timeStamp);

        const response = await RoamingFreeFormDocumentService.signFreeFormDocument({id: id, signature: roamingSignature});
        EventBus.dispatch(SIGN_FREE_FORM_DOCUMENT_SUCCEED, response.data);
    } catch (error) {
        EventBus.dispatch(SIGN_FREE_FORM_DOCUMENT_FAILED, error);
    }
};
export const cancelFreeFormDocumentAsync = async ({id, certificate}) => {
    EventBus.dispatch(CANCEL_FREE_FORM_DOCUMENT_REQUESTED);

    try {
        const {data: hashCode} = await RoamingFreeFormDocumentService.getCancelHashCode(id);

        const {pkcs7: signature, signatureHex, signerSerialNumber} = await createPkcs7WithTimestamp(hashCode, certificate.keyId);
        const {data: timeStamp} = await RoamingService.getTimestamp(signatureHex);
        const {pkcs7: roamingSignature} = await attachTimestampAsync(signature, signerSerialNumber, timeStamp);

        const response = await RoamingFreeFormDocumentService.cancelFreeFormDocument({id: id, signature: roamingSignature});
        EventBus.dispatch(CANCEL_FREE_FORM_DOCUMENT_SUCCEED, response.data);
    } catch (error) {
        EventBus.dispatch(CANCEL_FREE_FORM_DOCUMENT_FAILED, error);
    }
};
export const acceptFreeFormDocumentAsync = async ({id, certificate}) => {
    EventBus.dispatch(ACCEPT_FREE_FORM_DOCUMENT_REQUESTED);

    try {
        const {data: hashCode} = await RoamingFreeFormDocumentService.getAcceptHashCode(id);

        const {pkcs7: signature, signatureHex, signerSerialNumber} = await createPkcs7WithTimestamp(hashCode, certificate.keyId);
        const {data: timeStamp} = await RoamingService.getTimestamp(signatureHex);
        const {pkcs7: roamingSignature} = await attachTimestampAsync(signature, signerSerialNumber, timeStamp);

        const response = await RoamingFreeFormDocumentService.acceptFreeFormDocument({id: id, signature: roamingSignature});
        EventBus.dispatch(ACCEPT_FREE_FORM_DOCUMENT_SUCCEED, response.data);
    } catch (error) {
        EventBus.dispatch(ACCEPT_FREE_FORM_DOCUMENT_FAILED, error);
    }
};
export const declineFreeFormDocumentAsync = async ({id, certificate}) => {
    EventBus.dispatch(DECLINE_FREE_FORM_DOCUMENT_REQUESTED);

    try {
        const {data: hashCode} = await RoamingFreeFormDocumentService.getDeclineHashCode(id);

        const {pkcs7: signature, signatureHex, signerSerialNumber} = await createPkcs7WithTimestamp(hashCode, certificate.keyId);
        const {data: timeStamp} = await RoamingService.getTimestamp(signatureHex);
        const {pkcs7: roamingSignature} = await attachTimestampAsync(signature, signerSerialNumber, timeStamp);

        const response = await RoamingFreeFormDocumentService.declineFreeFormDocument({id: id, signature: roamingSignature});
        EventBus.dispatch(DECLINE_FREE_FORM_DOCUMENT_SUCCEED, response.data);
    } catch (error) {
        EventBus.dispatch(DECLINE_FREE_FORM_DOCUMENT_FAILED, error);
    }
};


export default roamingFreeFormDocumentSlice.reducer;
export const {setId, setShowModal} = roamingFreeFormDocumentSlice.actions
