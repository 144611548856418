import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import ReactSelect from "react-select";
import PropTypes from "prop-types";

const SelectOrderStrategy = React.forwardRef(({onChange, strategies, ...props}, ref) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const [options, setOptions] = useState(null);

    useEffect(() => {
        const translatedStrategies = strategies.map(strategy => {
            return {
                label: t(lang, strategy.name),
                value: strategy.id
            }
        })
        setOptions(translatedStrategies);
    }, [lang])

    const onChangeHandler = option => {
        const strategy = strategies.find(s => {
            return s.id === option.value
        }) || null;

        onChange(strategy);
    };

    return <ReactSelect hideSelectedOptions={true} onChange={onChangeHandler} options={options}  {...props} ref={ref}/>
});

SelectOrderStrategy.propTypes = {
    onChange: PropTypes.func,
    strategies: PropTypes.array
}

export default SelectOrderStrategy
