import React, {Fragment} from 'react';
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {selectDateFormat, selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {Card, Col, FloatingLabel, Form, Row} from "react-bootstrap";
import DatePickerComponent from "../../../common/DatePickerComponent";
import {Railway} from "./SendWaybillFormV2";
import SelectTransport from "../SelectTransport";

const DetailsFormV2 = () => {

    const {register, formState: {errors}, control, watch} = useFormContext();
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    const delivery_type = useWatch({name: "delivery_type", exact: true})
    const transportType = useWatch({name: "transport_type", exact: true})


    return (
        <Card className={'p-1 mt-2 mb-3'}>
            <Row>
                {
                    +delivery_type === 2 && <Fragment>
                        <Form.Group as={Col} sm={12} md={3}>
                            <FloatingLabel label={<>
                                {t(lang, 'roaming.waybill.send.details_form.contract_number')}
                                <span className="text-primary-davr">*</span>
                            </>}>
                                <Form.Control
                                    type="text"
                                    placeholder={<>
                                        {t(lang, 'roaming.waybill.send.details_form.contract_number')}
                                        <span className="text-primary-davr">*</span>
                                    </>}
                                    {...register('contract_info.number', {
                                        required: delivery_type === 2 ? t(lang, "edi.common.forms.validations.is_required") : false
                                    })}
                                />
                            </FloatingLabel>
                            <Form.Control.Feedback className={'d-block'} type="invalid">
                                {errors?.contract_info?.number?.message}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} sm={12} md={3}>
                            <Controller
                                control={control}
                                name='contract_info.date'
                                rules={{
                                    required: delivery_type === 2 ? t(lang, "edi.common.forms.validations.is_required") : false,
                                    max: {
                                        value: new Date(),
                                        message: t(lang, 'roaming.waybill.send.details_form.validations.shipment.date.more_than_current_date')
                                    }
                                }}
                                render={({field}) => (
                                    <DatePickerComponent
                                        field={field}
                                        isClearable
                                        dateFormat={dateFormat}
                                        placeholderText={<>
                                            {t(lang, 'roaming.waybill.send.details_form.contract_date')}
                                            <span className="text-primary-davr">*</span>
                                        </>}
                                    />
                                )}
                            />
                            <Form.Control.Feedback className={'d-block'} type="invalid">
                                {errors?.contract_info?.date?.message}
                            </Form.Control.Feedback>

                        </Form.Group>
                    </Fragment>
                }

                {
                    +transportType === Railway ? <Fragment>
                        <Form.Group as={Col} sm={12} md={3}>
                            <FloatingLabel label={<>
                                {t(lang, 'roaming.waybill.send.details_form.train_carriage_number')}
                                <span className="text-primary-davr">*</span>
                            </>}>
                                <Form.Control
                                    type="text"
                                    placeholder={<>
                                        {t(lang, 'roaming.waybill.send.details_form.train_carriage_number')}
                                        <span className="text-primary-davr">*</span>
                                    </>}
                                    {...register('railway.train_carriage_number', {
                                        required: t(lang, "edi.common.forms.validations.is_required")
                                    })}
                                />
                            </FloatingLabel>
                            <Form.Control.Feedback className={'d-block'} type="invalid">
                                {errors?.railway?.train_carriage_number?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} sm={12} md={3}>
                            <FloatingLabel label={<>
                                {t(lang, 'roaming.waybill.send.details_form.train_direction')}
                                <span className="text-primary-davr">*</span>
                            </>}>
                                <Form.Control
                                    type="text"
                                    placeholder={<>
                                        {t(lang, 'roaming.waybill.send.details_form.train_direction')}
                                        <span className="text-primary-davr">*</span>
                                    </>}
                                    {...register('railway.train_direction', {
                                        required: t(lang, "edi.common.forms.validations.is_required")
                                    })}
                                />
                            </FloatingLabel>
                            <Form.Control.Feedback className={'d-block'} type="invalid">
                                {errors?.railway?.train_direction?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Fragment> : <Fragment>
                        <Form.Group as={Col} sm={12} md={3}>
                            <Controller
                                rules={{
                                    required: t(lang, "edi.common.forms.validations.is_required")
                                }}
                                name={'transport.registration_number'}
                                render={({field}) => (
                                    <SelectTransport
                                        placeholder={t(lang, "Транспорт")}
                                        onChange={field.onChange}
                                        defaultTransportNumber={field.value}
                                    />
                                )}
                            />
                            <Form.Control.Feedback className={'d-block'} type="invalid">
                                {errors?.transport?.registration_number?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Fragment>
                }
            </Row>
        </Card>
    );
};

export default DetailsFormV2;
