import React, {Fragment, useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {selectCurrentCertificate} from "../../../../store/reducers/eimzo/eimzoReducer";
import {FormProvider, useForm} from "react-hook-form";
import {Translate} from "../../../../lang/lang";
import {faBan} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../IconButton";
import {Button, Form, Modal} from "react-bootstrap";
import SignActionWithSelectCertificateWrapper from "../../../common/SignActionWithSelectCertificateWrapper";
import IconFolderCancel from "../../../../assets/icon/IconFolderCancel";

const CancelVerificationAct = ({cancelVerificationActHandler, disabled, ...props}) => {
    const currentCertificate = useSelector(selectCurrentCertificate)
    const lang = useSelector(selectLang);
    const t = Translate;
    const [show, setShow] = useState(false);

    const { register, formState: {errors}, handleSubmit, reset } = methods
    const methods = useForm();
    const handleClose = () => {
        reset();
        setShow(false);
    };

    const formSubmit = ({comment}) => {
        if(currentCertificate?.keyId) {
            cancelVerificationActHandler({
                comment: comment
            })
            reset();
            setShow(false);
        }
    };
    return (
        <Fragment>
            <IconButton
                variant="phoenix-secondary"
                size="sm"
                icon={""}
                disabled={disabled}
                className="btn-davr px-3 py-0 bg-white d-flex gap-2"
                iconClassName={'fs-8 text-primary-davr'}
                onClick={() => setShow(true)}
                {...props}
            >
                <IconFolderCancel />
                {t(lang, "edi.common.cancel_decline")}
            </IconButton>

            <Modal size="md" show={show} onHide={handleClose}>
                <FormProvider {...methods}>
                    <Form onSubmit={handleSubmit(formSubmit)} id="CancelVerificationActForm">
                        <Modal.Header closeButton>
                            <Modal.Title>VerificationAct cancel</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>Comment</Form.Label>
                                <Form.Control
                                    name="Name"
                                    as="textarea" rows={3}
                                    placeholder="Please enter a comment to cancel"
                                    isInvalid={errors?.comment?.message}
                                    {...register("comment", {
                                        required: {
                                            value: true,
                                            message: "This field is required!"
                                        }
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.comment?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => {handleClose()}}>{t(lang, "edi.common.button.close")}</Button>
                            <SignActionWithSelectCertificateWrapper isForm>
                                <Button
                                    variant="danger"
                                    className="d-flex align-items-center"
                                    disabled={disabled}
                                    type="submit"
                                    form="CancelVerificationActForm"
                                >{t(lang, "edi.common.button.yes")}
                                </Button>
                            </SignActionWithSelectCertificateWrapper>
                        </Modal.Footer>
                    </Form>
                </FormProvider>
            </Modal>
        </Fragment>
    );
};

export default CancelVerificationAct;
