import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentCertificate} from "../../../../store/reducers/eimzo/eimzoReducer";
import {
    loadCompanyProductCatalogsAsync, loadMeasurementsAsync,
    selectCompanyCatalogs
} from "../../../../store/reducers/roaming/roamingReducer";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {FormProvider, useForm} from "react-hook-form";
import {
    saveDraftWaybillV2Async,
    sendWaybillV2Async
} from "../../../../store/reducers/roaming/waybill-v2/romaingWaybillV2Reducer";
import toast from "react-hot-toast";
import {Railway, Roadway} from "./SendWaybillFormV2";
import axios from "axios";
import {TypeAdditional, TypeCorrective, TypeDefault} from "../../../../enum/roaming/waybill-v2/WaybillV2Wrapper";
import dayjs from "dayjs";
import {Form} from "react-bootstrap";
import WaybillFormV2 from "./WaybillFormV2";
import ErrorAlert from "../../../common/ErrorAlert";

const EditWaybillV2Form = ({waybill}) => {

    const {id:paramId} = useParams()
    const navigate = useNavigate()
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isSending, setIsSending] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const catalogs = useSelector(selectCompanyCatalogs)
    const alertRef = useRef(null);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const waybillTransportType = waybill?.Roadway ? waybill?.Roadway : waybill?.Railway ? waybill?.Railway : null

    const methods = useForm({
        defaultValues: {
            id: waybill.id,
            executor: {
                inn_or_pinfl: waybill?.Consignor?.TinOrPinfl,
                name: waybill?.Consignor?.Name,
            },
            customer: {
                inn_or_pinfl: waybill?.Consignee?.TinOrPinfl,
                name: waybill?.Consignee?.Name,
            },
            freight_forwarder: {
                inn_or_pinfl: waybill?.FreightForwarder?.TinOrPinfl,
                name: waybill?.FreightForwarder?.Name,
            },
            carrier: {
                inn_or_pinfl: waybill?.Carrier?.TinOrPinfl,
                name: waybill?.Carrier?.Name,
            },
            responsible_person: {
                inn_or_pinfl: waybill?.ResponsiblePerson?.Pinfl,
                name: waybill?.ResponsiblePerson?.FullName,
            },
            driver: {
                inn_or_pinfl: waybillTransportType?.Driver?.Pinfl,
                name: waybillTransportType?.Driver?.FullName,
            },
            executor_is_freight_forwarder: false,
            customer_is_freight_forwarder: false,
            freight_forwarder_is_carrier: false,
            responsible_person_is_driver: false,
            executor_is_client_employer: false,
            customer_is_client_employer: false,
            waybill_type: waybill?.WaybillLocalType ||  TypeDefault,
            oldWaybillDoc: {
                oldWaybillId: waybill?.OldWaybillDoc?.OldWaybillLocalId || '',
                oldWaybillNo: waybill?.OldWaybillDoc?.OldWaybillNo || null,
                oldWaybillDate: waybill?.OldWaybillDoc?.OldWaybillDate && new Date(waybill?.OldWaybillDoc?.OldWaybillDate) || null
            },
            client_inn_or_pinfl: waybill?.Client?.TinOrPinfl,
            delivery_type: waybill.DeliveryType,
            transport_type: waybill.TransportType,
            total_distance: waybill?.TotalDistance?.toString() || null,
            delivery_cost: waybill?.DeliveryCost?.toString() || null,
            total_delivery_cost: waybill?.TotalDistance?.toString() || null,
            waybill_info: {
                number: waybill.WaybillDoc.WaybillNo,
                date: new Date(waybill.WaybillDoc.WaybillDate)
            },
            contract_info: {
                number: waybill?.ContractDoc?.ContractNo || null,
                date: waybill.ContractDoc?.ContractDate ? new Date(waybill.ContractDoc?.ContractDate) : null
            },
            client_contract_info: {
                number: waybill.Client?.ContractNo || null,
                date: waybill.Client?.ContractDate ?  new Date(waybill.Client?.ContractDate) : null
            },
            payer_contract_info: {
                number: waybill.Payer?.ContractNo || null,
                date: waybill.Payer?.ContractDate ?  new Date(waybill.Payer?.ContractDate) : null
            },
            transport: {
                registration_number: waybillTransportType?.Truck?.RegNo
            },
            railway: {
                train_carriage_number: waybillTransportType?.TrainCarriageNumber || null,
                train_direction: waybillTransportType?.TrainDirection || null
            },
            loading_point: {
                region_code: waybillTransportType?.ProductGroups[0]?.LoadingPoint.RegionId,
                region_name: waybillTransportType?.ProductGroups[0]?.LoadingPoint.RegionName,
                district_code: waybillTransportType?.ProductGroups[0]?.LoadingPoint.DistrictCode,
                district_name: waybillTransportType?.ProductGroups[0]?.LoadingPoint.DistrictName,
                village_code: waybillTransportType?.ProductGroups[0]?.LoadingPoint.MahallaId,
                village_name: waybillTransportType?.ProductGroups[0]?.LoadingPoint.MahallaName,
                street: waybillTransportType?.ProductGroups[0]?.LoadingPoint.Address,
                longitude: waybillTransportType?.ProductGroups[0]?.LoadingPoint.Longitude,
                latitude: waybillTransportType?.ProductGroups[0]?.LoadingPoint.Latitude,
            },
            unloading_point: {
                region_code: waybillTransportType?.ProductGroups[0]?.UnloadingPoint.RegionId,
                region_name: waybillTransportType?.ProductGroups[0]?.UnloadingPoint.RegionName,
                district_code: waybillTransportType?.ProductGroups[0]?.UnloadingPoint.DistrictCode,
                district_name: waybillTransportType?.ProductGroups[0]?.UnloadingPoint.DistrictName,
                village_code: waybillTransportType?.ProductGroups[0]?.UnloadingPoint.MahallaId,
                village_name: waybillTransportType?.ProductGroups[0]?.UnloadingPoint.MahallaName,
                street: waybillTransportType?.ProductGroups[0]?.UnloadingPoint.Address,
                longitude: waybillTransportType?.ProductGroups[0]?.UnloadingPoint.Longitude,
                latitude: waybillTransportType?.ProductGroups[0]?.UnloadingPoint.Latitude,
            },
            items: waybillTransportType?.ProductGroups[0].ProductInfo.Products.map(item => {
                return {
                    name: item.ProductName,
                    catalog_code: item.CatalogCode,
                    catalog_name: item.CatalogName,
                    package_code: item.PackageCode,
                    measurement: item.PackageName,
                    quantity: item.Amount,
                    price: item.Price,
                    total: item.DeliverySum,
                }
            })
        }
    })

    useEffect(() => {
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization?.inn, lang));
        dispatch(loadMeasurementsAsync(lang));

        return () => {
            setIsSaving(false);
            setIsSending(false);
        }
    }, []);

    const sendWaybill = ({id, hashCode}) => {
        setIsSending(true);

        sendWaybillV2Async(currentCertificate, lang, {id, hashCode})
            .then(() => {
                toast.success(t(lang, 'roaming.waybill.send.toast.register_success'))
                navigate(`/roaming/v2-waybills/outbox`)
            })
            .catch(error => {
                setIsSending(false);
                setErrorMessage(error.message || null);
                alertRef.current.scrollIntoView();
            })
    }

    const saveDraftWaybill = (data) => {
        setIsSaving(true);
        let res
        if  (data.hasOwnProperty("content")) {
            res = {
                id: data.id,
                content: data.content
            }
        } else {
            res = {
                id: data.id,
                hashCode: data.hashCode
            }
        }

        saveDraftWaybillV2Async(res)
            .then(() => {
                toast.success(t(lang, 'roaming.waybill.save.toast.saved_success'))
                navigate(`/roaming/waybill-v2/draft/${paramId}`)
            })
            .catch(error => {
                setIsSaving(false);
                setErrorMessage(error?.message || null);
                alertRef.current.scrollIntoView();
            })
    }

    const onSend = async (formData, event) => {
        const actionType = event.nativeEvent.submitter.name;

        const total_distance = typeof formData.total_distance === 'string' ? formData.total_distance.replace(/ /g, '') : formData.total_distance
        const delivery_cost = typeof formData.delivery_cost === 'string' ? formData.delivery_cost.replace(/ /g, '') : formData.delivery_cost
        const total_delivery_cost = typeof formData.total_delivery_cost === 'string' ? formData.total_delivery_cost.replace(/ /g, '') : formData.total_delivery_cost

        const data = {
            id: waybill.id,
            delivery_type: formData.delivery_type,
            transport_type: formData.transport_type,
            total_distance: +total_distance,
            delivery_cost: +delivery_cost,
            total_delivery_cost: +total_delivery_cost,
            waybill_info: formData.waybill_info.number ? {
                number: formData.waybill_info.number,
                date: dayjs(formData.waybill_info.date).format('YYYY-MM-DD')
            } : null,
            contract_info: formData.contract_info.number ? {
                number: formData.contract_info.number || null,
                date: formData.contract_info?.date ? dayjs(formData.contract_info.date).format('YYYY-MM-DD') : null
            } : null,
            customer_is_freight_forwarder: formData.customer_is_freight_forwarder,
            executor_is_freight_forwarder: formData.executor_is_freight_forwarder,
            freight_forwarder_is_carrier: formData.freight_forwarder_is_carrier,
            type: +formData.waybill_type,
            responsible_person: {
                inn_or_pinfl: formData.responsible_person.inn_or_pinfl,
                name:formData.responsible_person.name
            },
            responsible_person_is_driver: formData.responsible_person_is_driver,
            loading_point: {
                company_address_id: formData.loading_point.company_address_id,  // optional
                branch_id: formData.loading_point.branch_id, // optional
                branch_code: formData.loading_point.branch_code, // optional
                region_code: formData.loading_point.region_code, // required when company_address_id = null and branch_id = null
                region_name: formData.loading_point.region_name, // required when company_address_id = null and branch_id = null
                district_code: formData.loading_point.district_code, // required when company_address_id = null and branch_id = null
                district_name: formData.loading_point.district_name, // required when company_address_id = null and branch_id = null
                village_code: formData.loading_point.village_code, // required when company_address_id = null and branch_id = null
                village_name: formData.loading_point.village_name, // required when company_address_id = null and branch_id = null
                street: formData.loading_point.street, // required when company_address_id = null and branch_id = null
                longitude: +formData.loading_point.longitude, // required when company_address_id = null and branch_id = null
                latitude: +formData.loading_point.latitude,// required when company_address_id = null and branch_id = null
            },
            unloading_point: {
                company_address_id: formData.unloading_point.company_address_id,  // optional
                branch_id: formData.unloading_point.branch_id, // optional
                branch_code: formData.unloading_point.branch_code, // optional
                region_code: formData.unloading_point.region_code, // required when company_address_id = null and branch_id = null
                region_name: formData.unloading_point.region_name, // required when company_address_id = null and branch_id = null
                district_code: formData.unloading_point.district_code, // required when company_address_id = null and branch_id = null
                district_name: formData.unloading_point.district_name, // required when company_address_id = null and branch_id = null
                village_code: formData.unloading_point.village_code, // required when company_address_id = null and branch_id = null
                village_name: formData.unloading_point.village_name, // required when company_address_id = null and branch_id = null
                street: formData.unloading_point.street, // required when company_address_id = null and branch_id = null
                longitude: +formData.unloading_point.longitude, // required when company_address_id = null and branch_id = null
                latitude: +formData.unloading_point.latitude,// required when company_address_id = null and branch_id = null
            },
            executor_is_client_employer: formData.client_inn_or_pinfl === formData.executor.inn_or_pinfl,
            customer_is_client_employer: formData.client_inn_or_pinfl === formData.customer.inn_or_pinfl,
            client_contract_info: formData?.client_contract_info?.number ? {
                number: formData.client_contract_info?.number || null,
                date: formData.client_contract_info?.date ? dayjs(formData.client_contract_info.date).format('YYYY-MM-DD') : null
            } : null,
            payer_contract_info: formData?.payer_contract_info?.number ? {
                number: formData.payer_contract_info?.number || null,
                date: formData.payer_contract_info?.date ? dayjs(formData.payer_contract_info.date).format('YYYY-MM-DD') : null
            } : null,
            items: formData.items.map(x => {return {
                name: x.name,
                catalog_code: x.catalog_code,
                package_code: x.package_code?.code, // optional
                measurement: x.package_code?.code?.toString() || x.package_code?.toString() || x.measurement,  // package code/package name,
                quantity: +x.quantity,
                price: +x.price,
                total: +x.total
            }})
        }

        if (data.delivery_type === 2) {
            data['contract_info'] = {
                number: formData.contract_info.number,
                date: dayjs(formData.contract_info.date).format('YYYY-MM-DD')
            }
            data['customer'] = {
                inn_or_pinfl: formData.customer.inn_or_pinfl,
                name: formData.customer.name
            }
        }

        if (!data.customer_is_freight_forwarder && !data.executor_is_freight_forwarder) {
            data['freight_forwarder'] = {
                inn_or_pinfl: formData.freight_forwarder.inn_or_pinfl,
                name:formData.freight_forwarder.name
            }
        }

        if (!data.freight_forwarder_is_carrier) {
            data['carrier'] = {
                inn_or_pinfl: formData.carrier.inn_or_pinfl,
                name:formData.carrier.name
            }
        }

        if (!data.responsible_person_is_driver) {
            data['driver'] = {
                inn_or_pinfl: formData.driver.inn_or_pinfl,
                name:formData.driver.name
            }
        }

        if (data.type === TypeAdditional || data.type === TypeCorrective) {
            data['old_waybill'] = {
                id: formData.oldWaybillDoc?.oldWaybillId,
                number: formData.oldWaybillDoc?.oldWaybillNo,
                date: dayjs(formData.oldWaybillDoc?.oldWaybillDate).format("YYYY-MM-DD")
            }
        }

        if (data.transport_type === Roadway) {
            data['transport'] = {
                registration_number: formData.transport?.registration_number?.registration_number || formData.transport?.registration_number,
            }
        }

        if (data.transport_type === Railway) {
            data['train_carriage_number'] = formData.railway?.train_carriage_number
            data['train_direction'] = formData.railway?.train_direction
        }


        const response = await axios.post('/document-generator/roaming/waybill-v2/generate/send-hash-code', data)

        if (actionType === 'save') {
            await saveDraftWaybill({
                id: response.data?.id,
                content: response.data?.hashcode
            })
        } else if (actionType === 'send') {
            await sendWaybill({
                id: response.data?.id,
                hashCode: response.data?.hashcode,
            });
        }
    }

    return (
        <FormProvider {...methods}>
            {errorMessage && <ErrorAlert ref={alertRef} errorMessage={errorMessage}/>}
            <Form onSubmit={methods.handleSubmit(onSend)}>
                <WaybillFormV2 isEditing={true} isSending={isSending} isSaving={isSaving} />
            </Form>
        </FormProvider>
    );
};

export default EditWaybillV2Form;
