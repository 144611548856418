import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {selectCurrentCertificate} from "../../../../store/reducers/eimzo/eimzoReducer";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {declineFreeFormDocumentAsync} from "../../../../store/reducers/roaming/free-form/freeFormDocumentReducer";
import IconButton from "../../../IconButton";
import {faBan} from "@fortawesome/free-solid-svg-icons";
import {Spinner} from "react-bootstrap";
import SignActionWithSelectCertificateWrapper from "../../../common/SignActionWithSelectCertificateWrapper";

const DeclineFreeFormDocument = ({id, ...props}) => {
    const [loading, setLoading] = useState(false);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const lang = useSelector(selectLang);
    const t = Translate;

    const declineFreeFormDocument = () => {
        setLoading(true);
        declineFreeFormDocumentAsync({
            id: id,
            certificate: currentCertificate,
        }).finally(() => setLoading(false))
    }
    return (
        <SignActionWithSelectCertificateWrapper>
            <IconButton
                size="sm"
                {...props}
                icon={faBan}
                variant="phoenix-warning"
                onClick={declineFreeFormDocument}
                className={'btn-davr bg-white text-dark d-flex gap-2'}
                iconClassName={"text-primary-davr fs-8"}
            >
                {t(lang, 'roaming.free_form_document.view.action.cancel')}
                {loading && <Spinner className='align-middle ms-2' animation='border' role='switch' size='sm'/>}
            </IconButton>
        </SignActionWithSelectCertificateWrapper>
    );
};

export default DeclineFreeFormDocument;
