import React from 'react';
import {Table} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";

const WaybillViewTable = ({waybill, getItemMeasurementText}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const numberFormat = useSelector(selectNumberFormat);
    return (
        <Table bordered responsive className="table-view scrollbar border-900 mb-0 fs-9 fw-semi-bold">
            <thead className="text-dark border-bottom bg-card second-text text-center">
            <tr>
                <th rowSpan={3}>№</th>
                <th rowSpan={2} className="fw-semi-bold align-middle">{t(lang, 'roaming.waybill.view.datatable.row.product')}</th>
                <th rowSpan={2} className="fw-semi-bold align-middle">{t(lang, 'roaming.waybill.view.datatable.row.product.catalog_class_code')}</th>
                <th rowSpan={2} className="fw-semi-bold align-middle">{t(lang, 'roaming.waybill.view.datatable.row.product.measurement')}</th>
                <th rowSpan={2} className="fw-semi-bold align-middle">{t(lang, 'roaming.waybill.view.datatable.row.product.quantity')}</th>
                <th rowSpan={2} className="fw-semi-bold align-middle">{t(lang, 'roaming.waybill.view.datatable.row.product.price')}</th>
                <th rowSpan={2} className="fw-semi-bold align-middle">{t(lang, 'roaming.waybill.view.datatable.row.product.total')}</th>
                <th rowSpan={2} className="fw-semi-bold align-middle">{t(lang, 'roaming.waybill.view.datatable.row.product.road_haulage_cost')}</th>
                <th rowSpan={2} className="fw-semi-bold align-middle">{t(lang, 'roaming.waybill.view.datatable.row.product.follow_documents')}</th>
                <th rowSpan={2} className="fw-semi-bold align-middle">{t(lang, 'roaming.waybill.view.datatable.row.product.method_define_weight')}</th>
                <th rowSpan={2} className="fw-semi-bold align-middle">{t(lang, 'roaming.waybill.view.datatable.row.product.load_class')}</th>
                <th colSpan={2} className="fw-semi-bold align-middle">{t(lang, 'roaming.waybill.view.datatable.row.product.mass')}</th>
            </tr>
            <tr>
                <th className="fw-semi-bold align-middle">{t(lang, 'roaming.waybill.view.datatable.row.product.brutto')}</th>
                <th className="fw-semi-bold align-middle">{t(lang, 'roaming.waybill.view.datatable.row.product.netto')}</th>
            </tr>
            <tr>
                <th className="fw-semi-bold align-middle">1</th>
                <th className="fw-semi-bold align-middle">2</th>
                <th className="fw-semi-bold align-middle">3</th>
                <th className="fw-semi-bold align-middle">4</th>
                <th className="fw-semi-bold align-middle">5</th>
                <th className="fw-semi-bold align-middle">6</th>
                <th className="fw-semi-bold align-middle">7</th>
                <th className="fw-semi-bold align-middle">8</th>
                <th className="fw-semi-bold align-middle">9</th>
                <th className="fw-semi-bold align-middle">10</th>
                <th className="fw-semi-bold align-middle">11</th>
                <th className="fw-semi-bold align-middle">12</th>
            </tr>
            </thead>
            <tbody>
            {waybill.items.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="fw-semi-bold align-middle">{index + 1}</td>
                        <td className="fw-semi-bold align-middle">{item.name}</td>
                        <td className="fw-semi-bold align-middle">{item.catalog.code}</td>
                        <td className="fw-semi-bold align-middle">{item?.measure_id ? getItemMeasurementText(item.measure_id)?.name : item.packageName}</td>
                        <td className="fw-semi-bold align-middle">{item.quantity}</td>
                        <td className="fw-semi-bold align-middle">{item.price}</td>
                        <td className="fw-semi-bold align-middle">{item.total}</td>
                        <td className="fw-semi-bold align-middle">{item.road_haulage_cost}</td>
                        <td className="fw-semi-bold align-middle">{item.follow_documents}</td>
                        <td className="fw-semi-bold align-middle">{item.method_define_weight}</td>
                        <td className="fw-semi-bold align-middle">{item.load_class}</td>
                        <td className="fw-semi-bold align-middle">{item.weight_brutto}</td>
                        <td className="fw-semi-bold align-middle">{item.weight_netto}</td>
                    </tr>
                )
            })}
            </tbody>
        </Table>
    );
};

export default WaybillViewTable;
