import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectCurrentCertificate} from "../../../../store/reducers/eimzo/eimzoReducer";
import {selectLang} from "../../../../store/reducers/main/main";
import {FormProvider, useForm} from "react-hook-form";
import {Translate} from "../../../../lang/lang";
import {generateIdAsync} from "../../../../store/reducers/roaming/roamingReducer";
import dayjs from "dayjs";
import {
    verificationActSaveDraft,
    verificationActSignAndRegister
} from "../../../../store/reducers/roaming/vertification-act/vertificationActReducer";
import {Col, Form, Row} from "react-bootstrap";
import VerificationActInfo from "./VerificationActInfo";
import OwnerInfo from "./OwnerInfo";
import PartnerInfo from "./PartnerInfo";
import VerificationActDescription from "./VerificationActDescription";
import Acts from "./Acts";
import IconArrowLeft from "../../../../assets/icon/IconArrowLeft";
import IconButton from "../../../IconButton";
import SelectCertificateWrapperComponent from "../../../common/SelectCertificateWrapperComponent";
import {scrollEvent} from "../../../../helpers/scrollEvent";

const VerificationActForm = ({verificationAct}) => {
    const currentCertificate = useSelector(selectCurrentCertificate);
    const lang = useSelector(selectLang);
    const navigate = useNavigate();
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            verificationActId: "",
            verificationActDoc: {
                verificationActNo: "",
                verificationActDate: "",
                verificationActText: ""
            },
            ownerTin: "",
            ownerName: "",
            ownerFizTin: "",
            ownerFizFio: "",
            partnerTin: "",
            partnerName: "",
            partnerFizTin: "",
            partnerFizFio: "",
            verificationActContracts: [
                {
                    contractNo: "",
                    contractDate: "",
                    verificationActContractItems: [
                        {
                            ownerOperationDate: "",
                            ownerOperationName: "",
                            ownerDebit: 0.00,
                            ownerCredit: 0.00,
                            partnerOperationDate: "",
                            partnerOperationName: "",
                            partnerDebit: 0.00,
                            partnerCredit: 0.00
                        }
                    ],
                    openBalance: {
                        ownerDebit: 0.00,
                        ownerCredit: 0.00,
                        partnerDebit: 0.00,
                        partnerCredit: 0.00
                    },
                    closeBalance: {
                        ownerDebit: 0.00,
                        ownerCredit: 0.00,
                        partnerDebit: 0.00,
                        partnerCredit: 0.00
                    },
                    totalBalance: {
                        ownerDebit: 0.00,
                        ownerCredit: 0.00,
                        partnerDebit: 0.00,
                        partnerCredit: 0.00
                    }
                }
            ],
            openBalance: {
                ownerDebit: 0.00,
                ownerCredit: 0.00,
                partnerDebit: 0.00,
                partnerCredit: 0.00
            },
            turnoverBalance: {
                ownerDebit: 0.00,
                ownerCredit: 0.00,
                partnerDebit: 0.00,
                partnerCredit: 0.00
            },
            closeBalance: {
                ownerDebit: 0.00,
                ownerCredit: 0.00,
                partnerDebit: 0.00,
                partnerCredit: 0.00
            }
        },
    });

    const signAndSend = async (data) => {
        if (currentCertificate?.keyId) {
            let verificationAct = {
                VerificationActId: data?.verificationActId || (await generateIdAsync(1))[0],
                VerificationActDoc: {
                    VerificationActNo: data.verificationActDoc.verificationActNo,
                    VerificationActDate: dayjs(data.verificationActDoc.verificationActDate).format("YYYY-MM-DD"),
                    VerificationActText: data.verificationActDoc.verificationActText
                },
                OwnerTin: data.ownerTin,
                OwnerName: data.ownerName,
                OwnerFizTin: data.ownerFizTin,
                OwnerFizFio: data.ownerFizFio,
                PartnerTin: data.partnerTin,
                PartnerName: data.partnerName,
                PartnerFizTin: data.partnerFizTin,
                PartnerFizFio: data.partnerFizFio,
                VerificationActContracts: data.verificationActContracts.map(verificationActContract => ({
                    ContractNo: verificationActContract.contractNo,
                    ContractDate: dayjs(verificationActContract.contractDate).format("YYYY-MM-DD"),
                    VerificationActContractItems: verificationActContract.verificationActContractItems.map(verificationActContractItem => ({
                        OwnerOperationDate: dayjs(verificationActContractItem.ownerOperationDate).format("YYYY-MM-DD"),
                        OwnerOperationName: verificationActContractItem.ownerOperationName,
                        OwnerDebit: verificationActContractItem?.ownerDebit?.toFixed(2),
                        OwnerCredit: verificationActContractItem?.ownerCredit?.toFixed(2),
                        PartnerOperationDate: dayjs(verificationActContractItem.partnerOperationDate).format("YYYY-MM-DD"),
                        PartnerOperationName: verificationActContractItem.partnerOperationName,
                        PartnerDebit: verificationActContractItem?.partnerDebit?.toFixed(2),
                        PartnerCredit: verificationActContractItem?.partnerCredit?.toFixed(2)
                    })),
                    OpenBalance: {
                        OwnerDebit: verificationActContract?.openBalance?.ownerDebit?.toFixed(2),
                        OwnerCredit: verificationActContract?.openBalance?.ownerCredit?.toFixed(2),
                        PartnerDebit: verificationActContract?.openBalance?.partnerDebit?.toFixed(2),
                        PartnerCredit: verificationActContract?.openBalance?.partnerCredit?.toFixed(2)
                    },
                    CloseBalance: {
                        OwnerDebit: verificationActContract?.closeBalance?.ownerDebit?.toFixed(2),
                        OwnerCredit: verificationActContract?.closeBalance?.ownerCredit?.toFixed(2),
                        PartnerDebit: verificationActContract?.closeBalance?.partnerDebit?.toFixed(2),
                        PartnerCredit: verificationActContract?.closeBalance?.partnerCredit?.toFixed(2)
                    },
                    TotalBalance: {
                        OwnerDebit: verificationActContract?.totalBalance?.ownerDebit?.toFixed(2),
                        OwnerCredit: verificationActContract?.totalBalance?.ownerCredit?.toFixed(2),
                        PartnerDebit: verificationActContract?.totalBalance?.partnerDebit?.toFixed(2),
                        PartnerCredit: verificationActContract?.totalBalance?.partnerCredit?.toFixed(2)
                    }

                })),
                OpenBalance: {
                    OwnerDebit: data?.openBalance?.ownerDebit?.toFixed(2),
                    OwnerCredit: data?.openBalance?.ownerCredit?.toFixed(2),
                    PartnerDebit: data?.openBalance?.partnerDebit?.toFixed(2),
                    PartnerCredit: data?.openBalance?.partnerCredit?.toFixed(2),
                },
                TurnoverBalance: {
                    OwnerDebit: data?.turnoverBalance?.ownerDebit?.toFixed(2),
                    OwnerCredit: data?.turnoverBalance?.ownerCredit?.toFixed(2),
                    PartnerDebit: data?.turnoverBalance?.partnerDebit?.toFixed(2),
                    PartnerCredit: data?.turnoverBalance?.partnerCredit?.toFixed(2),
                },
                CloseBalance: {
                    OwnerDebit: data?.closeBalance?.ownerDebit?.toFixed(2),
                    OwnerCredit: data?.closeBalance?.ownerCredit?.toFixed(2),
                    PartnerDebit: data?.closeBalance?.partnerDebit?.toFixed(2),
                    PartnerCredit: data?.closeBalance?.partnerCredit?.toFixed(2),
                }
            }
            const {data: responseVerificationAct} = await verificationActSignAndRegister({
                verificationAct: verificationAct,
                certificate: currentCertificate,
                lang: lang
            })
            return responseVerificationAct
        }
    }

    const saveDraft = async (data) => {
        let verificationAct = {
            VerificationActId: data?.verificationActId || (await generateIdAsync(1))[0],
            VerificationActDoc: {
                VerificationActNo: data.verificationActDoc.verificationActNo,
                VerificationActDate: dayjs(data.verificationActDoc.verificationActDate).format("YYYY-MM-DD"),
                VerificationActText: data.verificationActDoc.verificationActText
            },
            OwnerTin: data.ownerTin,
            OwnerName: data.ownerName,
            OwnerFizTin: data.ownerFizTin,
            OwnerFizFio: data.ownerFizFio,
            PartnerTin: data.partnerTin,
            PartnerName: data.partnerName,
            PartnerFizTin: data.partnerFizTin,
            PartnerFizFio: data.partnerFizFio,
            VerificationActContracts: data.verificationActContracts.map(verificationActContract => ({
                ContractNo: verificationActContract.contractNo,
                ContractDate: dayjs(verificationActContract.contractDate).format("YYYY-MM-DD"),
                VerificationActContractItems: verificationActContract.verificationActContractItems.map(verificationActContractItem => ({
                    OwnerOperationDate: dayjs(verificationActContractItem.ownerOperationDate).format("YYYY-MM-DD"),
                    OwnerOperationName: verificationActContractItem.ownerOperationName,
                    OwnerDebit: verificationActContractItem?.ownerDebit?.toFixed(2),
                    OwnerCredit: verificationActContractItem?.ownerCredit?.toFixed(2),
                    PartnerOperationDate: dayjs(verificationActContractItem.partnerOperationDate).format("YYYY-MM-DD"),
                    PartnerOperationName: verificationActContractItem.partnerOperationName,
                    PartnerDebit: verificationActContractItem?.partnerDebit?.toFixed(2),
                    PartnerCredit: verificationActContractItem?.partnerCredit?.toFixed(2)
                })),
                OpenBalance: {
                    OwnerDebit: verificationActContract?.openBalance?.ownerDebit?.toFixed(2),
                    OwnerCredit: verificationActContract?.openBalance?.ownerCredit?.toFixed(2),
                    PartnerDebit: verificationActContract?.openBalance?.partnerDebit?.toFixed(2),
                    PartnerCredit: verificationActContract?.openBalance?.partnerCredit?.toFixed(2)
                },
                CloseBalance: {
                    OwnerDebit: verificationActContract?.closeBalance?.ownerDebit?.toFixed(2),
                    OwnerCredit: verificationActContract?.closeBalance?.ownerCredit?.toFixed(2),
                    PartnerDebit: verificationActContract?.closeBalance?.partnerDebit?.toFixed(2),
                    PartnerCredit: verificationActContract?.closeBalance?.partnerCredit?.toFixed(2)
                },
                TotalBalance: {
                    OwnerDebit: verificationActContract?.totalBalance?.ownerDebit?.toFixed(2),
                    OwnerCredit: verificationActContract?.totalBalance?.ownerCredit?.toFixed(2),
                    PartnerDebit: verificationActContract?.totalBalance?.partnerDebit?.toFixed(2),
                    PartnerCredit: verificationActContract?.totalBalance?.partnerCredit?.toFixed(2)
                }

            })),
            OpenBalance: {
                OwnerDebit: data?.openBalance?.ownerDebit?.toFixed(2),
                OwnerCredit: data?.openBalance?.ownerCredit?.toFixed(2),
                PartnerDebit: data?.openBalance?.partnerDebit?.toFixed(2),
                PartnerCredit: data?.openBalance?.partnerCredit?.toFixed(2),
            },
            TurnoverBalance: {
                OwnerDebit: data?.turnoverBalance?.ownerDebit?.toFixed(2),
                OwnerCredit: data?.turnoverBalance?.ownerCredit?.toFixed(2),
                PartnerDebit: data?.turnoverBalance?.partnerDebit?.toFixed(2),
                PartnerCredit: data?.turnoverBalance?.partnerCredit?.toFixed(2),
            },
            CloseBalance: {
                OwnerDebit: data?.closeBalance?.ownerDebit?.toFixed(2),
                OwnerCredit: data?.closeBalance?.ownerCredit?.toFixed(2),
                PartnerDebit: data?.closeBalance?.partnerDebit?.toFixed(2),
                PartnerCredit: data?.closeBalance?.partnerCredit?.toFixed(2),
            }
        }
        const {data: responseVerificationAct} = await verificationActSaveDraft({
            verificationAct: verificationAct
        })
        return responseVerificationAct
    }

    const onSubmit = async (data, event) => {
        try {
            let {name} = event.nativeEvent.submitter
            if (name === "save") {
                let responseVerificationAct = await saveDraft(data)
                if (responseVerificationAct?.id) navigate(`/roaming/verification-act/draft/${responseVerificationAct.id}`)
            } else if (name === "sign" && currentCertificate?.keyId) {
                let responseVerificationAct = await signAndSend(data)
                if (responseVerificationAct?.id) navigate(`/roaming/verification-act/${responseVerificationAct.id}`)
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        let verificationActKeyProperty = verificationAct && Object.keys(verificationAct)
        if (verificationActKeyProperty?.length) {
            verificationActKeyProperty.forEach(key => {
                methods.setValue(key, verificationAct[key])
            })
        }
    }, [verificationAct]);

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('roaming-verification-act-send'))
        return () => window.removeEventListener('scroll', () => scrollEvent('roaming-verification-act-send'))
    }, []);

    return (
        <FormProvider {...methods}>
            <Form id="AddVerificationAct" onSubmit={methods.handleSubmit(onSubmit)}>
                <Row id={'roaming-verification-act-send'} className={'my-2 justify-content-between align-items-center position-sticky'} style={{top: '4rem'}}>
                    <Col xl={6} sm={12} className={'d-flex align-items-center gap-3'}>
                        <button type={"button"} onClick={() => navigate(-1)} className={'main-text btn-back'} style={{width: 35, height: 35}}>
                            <IconArrowLeft />
                        </button>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.dashboard.new.verification_act.title")}</h5>
                    </Col>
                    <Col xl={6} sm={12} className={'d-flex justify-content-end gap-2'}>
                        <IconButton
                            className={'btn-davr bg-primary-davr-outline'}
                            name="save" variant="phoenix-warning" size="lg"
                            type="submit"
                        >
                            {t(lang, "roaming.common.save")}
                        </IconButton>
                        <SelectCertificateWrapperComponent
                            size="lg"
                            className={'btn-davr bg-primary-davr gap-1 text-white'}
                            submitButton={
                                <IconButton
                                    variant="phoenix-primary"
                                    size="lg"
                                    className="w-100 mt-2 mt-md-0 w-md-auto fw-semi-bold"
                                    name="sign"
                                    type="submit"
                                    form="ContractForm"
                                >
                                    {t(lang, "roaming.common.send_button")}
                                </IconButton>
                            }
                            openDialogButtonText={t(lang, "roaming.common.send_button")}/>
                    </Col>
                </Row>

                <Row>
                    <Col sm="12" className="mb-2">
                        <VerificationActInfo />
                    </Col>
                    <Col sm="6" className="mb-2">
                        <OwnerInfo />
                    </Col>
                    <Col sm="6" className="mb-2">
                        <PartnerInfo />
                    </Col>
                    <Col sm="12" className="mb-2">
                        <VerificationActDescription />
                    </Col>
                    <Col sm="12" className="mb-4">
                        <Acts/>
                    </Col>
                </Row>
            </Form>
        </FormProvider>
    );
};

export default VerificationActForm;
