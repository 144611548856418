import React, {useEffect, useRef, useState} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import ReactSelect from "react-select";
import {roamingInvoiceTypes} from "../../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import CustomFloatingLabelSelect from "../../floating-custom-select/CustomFloatingLabelSelect";

const RoamingInvoiceFormTypeSelector = ({defaultType, onChange, selectProps, placeholder, id}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [value, setValue] = useState(null);

    useEffect(() => {
        setValue(roamingInvoiceTypes.find(o => o.value === defaultType))
    }, [defaultType]);

    const handleChange = (option) => {
        onChange(option)
    }

    return (
        <div className="select">
            <CustomFloatingLabelSelect
                label={placeholder}
                className="w-100"
                options={roamingInvoiceTypes}
                getOptionValue={option => option.value}
                getOptionLabel={option => t(lang, option.label)}
                value={value}
                onChange={(option) => handleChange(option.value)}
                isClearable={false}
                isSearchable={false}
                {...selectProps}
            />
        </div>
    );
};

export default RoamingInvoiceFormTypeSelector;
