import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {waybillV2SyncAsync} from "../../../../store/reducers/roaming/waybill-v2/romaingWaybillV2Reducer";
import IconButton from "../../../IconButton";

const WaybillV2Sync = ({id, ...props}) => {

    const lang = useSelector(selectLang);
    const t = Translate;

    const [loading, setLoading] = useState(false);

    const syncWaybill = () => {
        setLoading(true);
        waybillV2SyncAsync(id)
            .then()
            .catch()
            .finally(() => setLoading(false))
    };

    return (
        <IconButton
            icon={'fa-refresh'}
            variant="phoenix-secondary"
            onClick={syncWaybill}
            size="sm"
            iconClassName="text-primary-davr"
            className={'btn-white-davr d-flex gap-1 align-items-center'}
            disabled={loading}
            {...props}>
            {t(lang, "roaming.empowerment.sync")}
        </IconButton>
    );
};

export default WaybillV2Sync;
