import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectDateDayJSFormat, selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {selectCurrentCertificate} from "../../../store/reducers/eimzo/eimzoReducer";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {selectMeasurements} from "../../../store/reducers/roaming/roamingReducer";
import {ContractWrapperClass} from "../../../enum/roaming/contract/ContractStatus";
import {Translate} from "../../../lang/lang";
import dayjs from "dayjs";
import {
    cancelAsync,
    customerAcceptAsync,
    customerDeclineAsync,
    send
} from "../../../store/reducers/roaming/contracts/roamingContractReducer";
import {Card, Col, Row} from "react-bootstrap";
import checkPermission from "../../../enum/permission/CheckPermission";
import {
    PermissionRoamingContractAccept, PermissionRoamingContractReject,
    PermissionRoamingContractUpdate
} from "../../../enum/permission/RoamingPermission";
import toastPermission from "../../../enum/permission/ToastPermission";
import {faBan, faClone, faEdit, faSignature, faXmarkCircle} from "@fortawesome/free-solid-svg-icons";
import * as PropTypes from "prop-types";
import IconButton from "../../IconButton";
import {Link, useNavigate} from "react-router-dom";
import ActionContract from "./ActionContract";
import SignActionWithSelectCertificateWrapper from "../../common/SignActionWithSelectCertificateWrapper";
import Badge from "../../common/Badge";
import {GetStatusTextReturnOrder} from "../../../enum/edi/return-order/ReturnOrderStatus";
import {selectOrderExecutorEditStrategy} from "../../../order/GetStrategies";
import ContractViewTable from "./ContractViewTable";
import IconSignature from "../../../assets/icon/IconSignature";
import {scrollEvent} from "../../../helpers/scrollEvent";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";

const ContractView = ({contract}) => {
    const dispatch = useDispatch();
    const navigate =useNavigate();
    const lang = useSelector(selectLang);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const activeOrganization = useSelector(selectActiveOrganization);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const numberFormat = useSelector(selectNumberFormat);
    const measurements = useSelector(selectMeasurements);
    const [showCancelButton, setShowCancelButton] = useState(false);
    const [showAcceptButton, setShowAcceptButton] = useState(false);
    const [showDeclineButton, setShowDeclineButton] = useState(false);
    const [showCopyButton, setShowCopyButton] = useState(false);
    const contractWrapper = new ContractWrapperClass(contract);
    const t = Translate;
    const activeOrganizationPinflOrInn = activeOrganization.pinfl || activeOrganization.inn;
    const showDeclineNotes = contractWrapper.enumStatuses.buyerOrAgentIsDeclined === contract.status;

    useEffect(() => {
        setShowCancelButton(contractWrapper.canTheSellerCancled(activeOrganizationPinflOrInn))
        setShowAcceptButton(contractWrapper.canTheBuyerSign(activeOrganizationPinflOrInn))
        setShowDeclineButton(contractWrapper.canTheBuyerDecline(activeOrganizationPinflOrInn))
        setShowCopyButton(contractWrapper.allowCopy(activeOrganizationPinflOrInn))
    }, [contract, activeOrganization.inn])

    const executorSignAndSend = async (data) => {
        try {
            if (currentCertificate?.keyId) {
                const convertedContract = {
                    ContractId: data.id,
                    HasVat: data.has_vat,
                    ContractDoc: {
                        ContractName: data.info.name,
                        ContractNo: data.info.number,
                        ContractDate: dayjs(data.info.date, 'YYYY-MM-DD').format("YYYY-MM-DD"),
                        ContractExpireDate: dayjs(data.info.expire_date, 'YYYY-MM-DD').format("YYYY-MM-DD"),
                        ContractPlace: data.info.place
                    },
                    Owner: {
                        Tin: data.executor.inn,
                        Name: data.executor.name,
                        Address: data.executor.address,
                        WorkPhone: data.executor.work_phone,
                        Mobile: data.executor.mobile,
                        Fax: data.executor.fax,
                        Oked: data.executor.oked,
                        Account: data.executor.account,
                        BankId: data.executor.mfo,
                        FizTin: data.executor.director_inn,
                        Fio: data.executor.director_name,
                        BranchCode: data.executor.branch.branch_code,
                        BranchName: data.executor.branch.branch_name
                    },
                    Clients: data.contractors.map(client => ({
                        Tin: client.inn,
                        Name: client.name,
                        Address: client.address,
                        WorkPhone: client.work_phone,
                        Mobile: client.mobile,
                        Fax: client.fax,
                        Oked: client.oked,
                        Account: client.account,
                        BankId: client.mfo,
                        FizTin: client.director_inn,
                        Fio: client.director_name,
                        BranchCode: client.branch.branch_code,
                        BranchName: client.branch.branch_name
                    })),
                    Products: data.items.map((product, index) => ({
                        OrdNo: product.ordering_number || index + 1,
                        CatalogCode: product.catalog.code,
                        CatalogName: product.catalog.name,
                        BarCode: product.barcode,
                        Name: product.name,
                        MeasureId: product.measure_id,
                        PackageCode: product.packageCode ? `${product.packageCode}` : null,
                        PackageName: product.packageName,
                        Count: +product.quantity,
                        Summa: +product.price,
                        DeliverySum: +product.total,
                        VatRate: +product.vat_rate,
                        VatSum: +product.vat_sum,
                        DeliverySumWithVat: +product.total_with_nds,
                        WithoutVat: +product.without_vat
                    })),
                    Parts: data.parts.map((part, index) => ({
                        OrdNo: part.ordering_number || index + 1,
                        Title: part.title,
                        Body: part.body
                    }))
                }
                await send({
                    contract: convertedContract,
                    certificate: currentCertificate,
                    lang: lang
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    const totalWithNds = contract?.items.reduce((total, item) => item?.total_with_nds + total, 0)

    const getMeasure = id => measurements.find(m => m.measureId === id) || null;

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('roaming-contract-view'))
        return () => window.removeEventListener('scroll', () => scrollEvent('roaming-contract-view'))
    }, []);

    return (
        <Fragment>
            <Row className={'align-items-center justify-content-between my-3 position-sticky z-index-1'} style={{top: '4rem'}} id={'roaming-contract-view'}>
                <Col lg={"auto"} className={'d-flex gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'}
                            style={{width: 35, height: 35}}>
                        <IconArrowLeft/>
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.contract.title")} № {contract.info.number}</h5>
                        <div className={'d-flex align-items-center gap-2'}>
                            <span className={'second-text fs-9'}>
                                {contract.created_at}
                            </span>
                            <Badge bg={contractWrapper.getRoamingStatusVariant(activeOrganizationPinflOrInn)} variant={'phoenix'}
                                   className={'px-2 py-1 fs-9 text-uppercase text-decoration-none fw-medium border-0 rounded-4'}>{t(lang, GetStatusTextReturnOrder(contractWrapper.getRoamingStatus(activeOrganizationPinflOrInn)))}</Badge>
                        </div>
                    </div>
                </Col>
                <Col lg={"auto"} className={'d-flex justify-content-end align-items-center gap-2'}>
                    {showCopyButton &&
                        <IconButton
                            variant="phoenix-secondary"
                            size="sm"
                            icon={faClone}
                            as={Link}
                            to={contract.status ? `/roaming/contract/actions/${contract.id}?status=${contract?.status}` : `/roaming/contract/actions/${contract.id}?status=${null}`}
                            className="btn-davr px-3 py-0 bg-white"
                            transform="shrink-3"
                        >
                            {t(lang, "roaming.common.copy")}
                        </IconButton>}
                    {!contract?.status &&
                        <Fragment>
                            <SignActionWithSelectCertificateWrapper permission={PermissionRoamingContractUpdate}>
                                <IconButton
                                    size="sm"
                                    className="btn-davr px-3 py-0 bg-primary-davr d-flex gap-2"
                                    transform="shrink-3"
                                    onClick={() => executorSignAndSend(contract)}
                                    disabled={!currentCertificate}
                                >
                                    <IconSignature />
                                    {t(lang, 'edi.common.button.sign')}
                                </IconButton>
                            </SignActionWithSelectCertificateWrapper>
                            <IconButton
                                as={dispatch(checkPermission(PermissionRoamingContractUpdate)) ? Link : 'div'}
                                onClick={() => !dispatch(checkPermission(PermissionRoamingContractUpdate)) && dispatch(toastPermission())}
                                to={`/roaming/contract/draft/edit/${contract.id}`}
                                variant="phoenix-warning"
                                size="sm"
                                icon={faEdit}
                                iconClassName="me-1 fs-8"
                                transform="shrink-3"
                                className="btn-davr px-3 py-0 bg-white"
                            >{t(lang, 'roaming.common.edit')}</IconButton>
                        </Fragment>
                    }

                    {showCancelButton &&
                        <ActionContract
                            variant={'phoenix-secondary'}
                            iconText={"edi.common.cancel_decline"}
                            className="btn-davr px-3 py-0 bg-white d-flex gap-2"
                            transform="shrink-3"
                            actionContract={() => cancelAsync(currentCertificate, lang, contract)}
                        />
                    }

                    {showDeclineButton &&
                        <ActionContract
                            className="btn-davr px-3 py-0 bg-white d-flex gap-2"
                            transform="shrink-3"
                            variant={'phoenix-secondary'}
                            iconText={"edi.common.button.decline"}
                            disabled={!currentCertificate}
                            actionContract={comment => customerDeclineAsync(currentCertificate, lang, contract, comment)}
                        />
                    }

                    {showAcceptButton &&
                        <SignActionWithSelectCertificateWrapper>
                            <IconButton
                                transform="shrink-3"
                                className="btn-davr px-3 py-0 bg-primary-davr d-flex gap-2"
                                size="sm"
                                iconClassName="me-1"
                                onClick={() => customerAcceptAsync(currentCertificate, lang, contract)}
                                disabled={!currentCertificate}
                            >
                                <IconSignature />
                                {t(lang, 'edi.common.button.sign')}
                            </IconButton>
                        </SignActionWithSelectCertificateWrapper>
                    }
                </Col>
            </Row>

            <Card>
                <Card.Body className={'pb-0'}>
                    <h2 className="fw-semi-bold text-center">
                        {t(lang, "roaming.contract.title")}<i>{contract.notes}</i>
                    </h2>
                    <h5 className="fw-semi-bold text-center second-text">
                        <i>{contract.id}</i>
                    </h5>
                </Card.Body>
                <Card.Footer as={Row} className={'border-0 justify-content-between fs-8 pb-3'}>
                    <Col xs={12} md={6} className={'px-1'}>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>Номер:</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text'}>{contract?.info?.number}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>

                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>Наименование:</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text'}>{contract?.info?.name}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>

                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>Место заключения:</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text'}>{contract?.info?.place}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className={'px-1'}>
                        <div className={'bg-card p-2 rounded-2 h-100'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>Дата:</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text'}>{dayjs(contract?.info?.date, "YYYY-MM-DD").format(dateFormat)}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>Действителен до:</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text'}>{dayjs(contract?.info?.expire_date, "YYYY-MM-DD").format(dateFormat)}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Card.Footer>
            </Card>

            <Card className={'my-3'}>
                <Card.Footer as={Row} className={'border-0 justify-content-between'}>
                    <Col xs={12} md={6} className={'px-1'}>
                        <h4 className="fs-8 fw-semi-bold second-text">{t(lang, 'roaming.contract.company.owner')}</h4>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, 'roaming.contract.company.owner')}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'d-block main-text text-truncate'}>{contract?.executor?.name}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, 'roaming.contract.company.info.address')}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'d-block main-text text-truncate h-100'}>{contract?.executor?.address?.trim()}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, 'roaming.contract.company.info.phone')}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'d-block main-text text-truncate h-100'}>{contract?.executor?.work_phone}{contract?.executor?.mobile && `, ${contract?.executor?.mobile}`}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, 'roaming.contract.company.info.fax')}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'d-block main-text text-truncate h-100'}>{contract?.executor?.fax}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, 'roaming.contract.company.info.tin')}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'d-block main-text text-truncate h-100'}>{contract?.executor?.inn}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, 'roaming.contract.company.info.account')}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'d-block main-text text-truncate h-100'}>{contract?.executor?.oked}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, 'roaming.contract.company.info.bank')}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'d-block main-text text-truncate h-100'}>{contract?.executor?.address?.trim()}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, 'roaming.contract.company.info.mfo')}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'d-block main-text text-truncate h-100'}>{contract?.executor?.mfo}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className={'px-1'}>
                        <h4 className="fs-8 fw-semi-bold second-text">{t(lang, 'edi.common.customer')}</h4>
                        <div className={'bg-card p-2 rounded-2'}>
                            {contract.contractors?.map(contract =>
                                <Fragment>
                                    <Row className={'py-1'}>
                                        <Col md={4}>
                                            <span
                                                className={'second-text'}>{t(lang, 'roaming.contract.company.owner')}</span>
                                        </Col>
                                        <Col md={8}>
                                            <span className={'d-block main-text text-truncate'}>{contract.name}</span>
                                            <hr className={'m-0 line-color'}/>
                                        </Col>
                                    </Row>
                                    <Row className={'py-1'}>
                                        <Col md={4}>
                                            <span
                                                className={'second-text'}>{t(lang, 'roaming.contract.company.info.address')}</span>
                                        </Col>
                                        <Col md={8}>
                                            <span className={'d-block main-text text-truncate h-100'}>{contract.address?.trim()}</span>
                                            <hr className={'m-0 line-color'}/>
                                        </Col>
                                    </Row>
                                    <Row className={'py-1'}>
                                        <Col md={4}>
                                            <span
                                                className={'second-text'}>{t(lang, 'roaming.contract.company.info.phone')}</span>
                                        </Col>
                                        <Col md={8}>
                                            <span
                                                className={'d-block main-text text-truncate h-100'}>{contract.work_phone}{contract.mobile && `, ${contract?.executor?.mobile}`}</span>
                                            <hr className={'m-0 line-color'}/>
                                        </Col>
                                    </Row>
                                    <Row className={'py-1'}>
                                        <Col md={4}>
                                            <span
                                                className={'second-text'}>{t(lang, 'roaming.contract.company.info.fax')}</span>
                                        </Col>
                                        <Col md={8}>
                                            <span className={'d-block main-text text-truncate h-100'}>{contract.fax}</span>
                                            <hr className={'m-0 line-color'}/>
                                        </Col>
                                    </Row>
                                    <Row className={'py-1'}>
                                        <Col md={4}>
                                            <span
                                                className={'second-text'}>{t(lang, 'roaming.contract.company.info.tin')}</span>
                                        </Col>
                                        <Col md={8}>
                                            <span className={'d-block main-text text-truncate h-100'}>{contract.inn}</span>
                                            <hr className={'m-0 line-color'}/>
                                        </Col>
                                    </Row>
                                    <Row className={'py-1'}>
                                        <Col md={4}>
                                            <span
                                                className={'second-text'}>{t(lang, 'roaming.contract.company.info.account')}</span>
                                        </Col>
                                        <Col md={8}>
                                            <span className={'d-block main-text text-truncate h-100'}>{contract.oked}</span>
                                            <hr className={'m-0 line-color'}/>
                                        </Col>
                                    </Row>
                                    <Row className={'py-1'}>
                                        <Col md={4}>
                                            <span
                                                className={'second-text'}>{t(lang, 'roaming.contract.company.info.bank')}</span>
                                        </Col>
                                        <Col md={8}>
                                            <span className={'d-block main-text text-truncate h-100'}>{contract.address?.trim()}</span>
                                            <hr className={'m-0 line-color'}/>
                                        </Col>
                                    </Row>
                                    <Row className={'py-1'}>
                                        <Col md={4}>
                                            <span
                                                className={'second-text'}>{t(lang, 'roaming.contract.company.info.mfo')}</span>
                                        </Col>
                                        <Col md={8}>
                                            <span className={'d-block main-text text-truncate h-100'}>{contract.mfo}</span>
                                            <hr className={'m-0 line-color'}/>
                                        </Col>
                                    </Row>
                                </Fragment>
                            )}
                        </div>
                    </Col>
                </Card.Footer>
            </Card>

            <Card>
                <Card.Header className={'p-0 border-0'}>
                    <p className="text-center mt-4 fw-bold my-1">{t(lang, 'roaming.contract.description_1', {
                        owner: contract?.executor?.name,
                        owner_director: contract?.executor?.director_name
                    })} {contract?.contractors?.map(partner => t(lang, 'roaming.contract.description_2', {
                        partner: partner?.name,
                        partner_director: partner?.director_name
                    }))} {t(lang, 'roaming.contract.description_3')}</p>
                </Card.Header>
                <Card.Body className={'pb-1 pt-0 px-3'}>
                    <ContractViewTable contract={contract} getMeasure={getMeasure} totalWithNds={totalWithNds}/>
                </Card.Body>
            </Card>
            <Card className={'mt-3'}>
                <div className="p-2">
                    {contract?.parts?.map((part, index) => (
                        <div key={index}>
                            <h5 className="ms-4">{part?.ordering_number}. {part?.title}</h5>
                            {part?.body?.split('\n').map((text, index) => (
                                <p className="text-justify" key={index}>{text}</p>
                            ))}
                        </div>
                    ))}
                </div>
            </Card>
        </Fragment>
    );
};

export default ContractView;
