import React, {useContext, useEffect, useState} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import EventBus from '../../../../eventbus/EventBus';
import {FORGOT_PASSWORD_RESTORE_FAILED} from '../../../../eventbus/authEvents';
import toast from "react-hot-toast";
import {resetPasswordAsync, sendForgotPasswordVerificationCodeAsync} from "../../../../store/reducers/auth/authReducer";
import {Form} from "react-bootstrap";
import ForgotPasswordStepOne from "./ForgotPasswordStepOne";
import ForgotPasswordStepTwo from "./ForgotPasswordStepTwo";
import ForgotPasswordStepThree from "./ForgotPasswordStepThree";
import ForgotPasswordStepFour from "./ForgotPasswordStepFour";
import {AppContext} from "../../../../pheonix/providers/AppProvider";
import {selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import IconButton from "../../../IconButton";
import PropTypes from "prop-types";
import Flex from "../../../common/Flex";
import IconArrowLeft from "../../../../assets/icon/IconArrowLeft";
import {useNavigate} from "react-router-dom";

const ForgotPasswordFooter = ({onGoBack}) => {
    const {isRTL} = useContext(AppContext);
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Flex className="justify-content-center mb-3">
            <Form.Group className="mb-3 text-end">
                <IconButton variant="primary" className="ms-auto px-5 bg-primary-davr" type="submit"
                            icon={isRTL ? 'chevron-left' : 'chevron-right'} iconAlign="right"
                            transform="down-1 shrink-4">
                    {t(lang, 'authentication.forgot-password.next_button')}
                </IconButton>
            </Form.Group>
        </Flex>
    );
};

ForgotPasswordFooter.propTypes = {
    onGoBack: PropTypes.func.isRequired
}

const ForgotPasswordForm = ({header}) => {
    const methods = useForm();
    const navigate = useNavigate()

    const [step, setStep] = useState(1);
    const [ticket, setTicket] = useState(null);
    const [invalidConfirmCode, setInvalidConfirmCode] = useState('');

    useEffect(() => {
        const onForgetPasswordFailedHandler = EventBus.on(FORGOT_PASSWORD_RESTORE_FAILED, () => {
            toast.error(`Reset password failed`);
        });

        return () => {
            EventBus.remove(FORGOT_PASSWORD_RESTORE_FAILED, onForgetPasswordFailedHandler);
        }
    }, [])

    const onSubmit = ({username, password, ...formData}) => {

        username = '998' + username.replace(/ /g, '');
        let {confirmCode1, confirmCode2, confirmCode3, confirmCode4, confirmCode5, confirmCode6} = formData

        let confirmCode = confirmCode1 + confirmCode2 + confirmCode3 + confirmCode4 + confirmCode5 + confirmCode6
        switch (step) {
            case 1:
                sendForgotPasswordVerificationCodeAsync(username)
                    .then(ticket => {
                        setTicket(ticket);
                        setStep(step + 1);
                    })
                break;

            case 2:
                setStep(step + 1);
                break;

            case 3:
                resetPasswordAsync({
                    username: username,
                    new_password: password,
                    confirm_ticket: ticket,
                    confirm_code: confirmCode,
                })
                    .then(() => {
                        setStep(step + 1);
                    })
                    .catch(({invalidConfirmationCode}) => {
                        if (invalidConfirmationCode) {
                            setInvalidConfirmCode(confirmCode);
                            setStep(2);
                        }
                    })
                break;
        }
    }

    const onGoBack = () => {
        setStep(step - 1);
    };

    const getHeader = () => {
        if (step === 1 && header) return header
        return (
            <div className={'position-relative'}>
                <button onClick={() => navigate(-1)}
                        className={'main-text btn-back bg-secondary-davr position-absolute'}
                        style={{width: 35, height: 35, left: 0, bottom: 0}}>
                    <IconArrowLeft/>
                </button>
                <h5 className={'text-center text-dark'}>{step === 2 ? "ОТР" : "Восстановление"}</h5>
                <p className={'text-center fs-9 second-text'}>Забыли пароль</p>
            </div>
        )
    }

    return (
        <FormProvider {...methods}>
            <Form id={"form-forget-password"} onSubmit={methods.handleSubmit(onSubmit)} className={"h-100"}>
                <Flex className={"flex-column h-100 justify-content-between"}>
                    {step === 1 && <ForgotPasswordStepOne header={getHeader}/>}
                    {step === 2 && <ForgotPasswordStepTwo header={getHeader}
                                                          invalidConfirmCode={invalidConfirmCode}
                                                          footer={<ForgotPasswordFooter onGoBack={onGoBack}/>}/>}
                    {step === 3 && <ForgotPasswordStepThree header={getHeader}
                                                            footer={<ForgotPasswordFooter onGoBack={onGoBack}/>}/>}
                    {step === 4 && <ForgotPasswordStepFour/>}
                </Flex>
            </Form>
        </FormProvider>
    );
}

export default ForgotPasswordForm;
