const checkPermission = (permission) => (_, getState) =>{

    const accountPermissions = getState().role?.permissions;
    for (let i = 0; i <= accountPermissions?.length; i++) {
        if(accountPermissions[i] === permission) {
            return true
        }
    }
    return  false
}
export default checkPermission
