export const AttorneyRegistered = 0;
export const OwnerSigned = 15;
export const OwnerCancelled = 17;
export const ContractorDeclined = 20;
export const ContractorAccepted = 30;

export const GetStatusText = status => {
    switch (status) {
        case AttorneyRegistered:
            return 'edi.attorney.statuses.status_attorney_registered';
        case OwnerSigned:
            return 'edi.attorney.statuses.status_owner_signed';
        case OwnerCancelled:
            return 'edi.attorney.statuses.status_owner_cancelled';
        case ContractorDeclined:
            return 'edi.attorney.statuses.status_contractor_declined';
        case ContractorAccepted:
            return 'edi.attorney.statuses.status_contractor_accepted';
    }
}

export const GetStatusVariant = status => {
    switch (status) {
        case AttorneyRegistered:
            return 'secondary';
        case OwnerSigned:
            return 'primary';
        case OwnerCancelled:
            return 'warning';
        case ContractorDeclined:
            return 'danger';
        case ContractorAccepted:
            return 'success';
    }
}

const AttorneyStatus = (id, name) => {
    return {
        id,
        name
    }
}

export const AttorneyStatuses = [
    AttorneyStatus(AttorneyRegistered, GetStatusText(AttorneyRegistered)),
    AttorneyStatus(OwnerSigned, GetStatusText(OwnerSigned)),
    AttorneyStatus(OwnerCancelled, GetStatusText(OwnerCancelled)),
    AttorneyStatus(ContractorDeclined, GetStatusText(ContractorDeclined)),
    AttorneyStatus(ContractorAccepted, GetStatusText(ContractorAccepted)),
];
