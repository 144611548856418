import React from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import SignActionWithSelectCertificateWrapper from "../../../common/SignActionWithSelectCertificateWrapper";
import IconButton from "../../../IconButton";
import IconSignature from "../../../../assets/icon/IconSignature";

const CustomerAccept = ({ActAcceptHandler, disabled, ...props}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <SignActionWithSelectCertificateWrapper>
            <IconButton
                onClick={ActAcceptHandler}
                variant="phoenix-primary"
                size="sm"
                disabled={disabled}
                className={'btn-davr bg-primary-davr text-white d-flex gap-2'}
                {...props}
            >
                <IconSignature/>
                {t(lang, "edi.attorney.datatable.header.navigation.sign")}</IconButton>
        </SignActionWithSelectCertificateWrapper>
    );
};

export default CustomerAccept;
