import React from 'react';

const IconTariff = ({width = 46, height = 46, color = "none"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 40 40" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16 16H14.2002C13.0801 16 12.5196 16 12.0918 16.218C11.7155 16.4097 11.4097 16.7155 11.218 17.0918C11 17.5196 11 18.0801 11 19.2002V24.8002C11 25.9203 11 26.4801 11.218 26.9079C11.4097 27.2842 11.7155 27.5905 12.0918 27.7822C12.5192 28 13.079 28 14.1969 28H25.8031C26.921 28 27.48 28 27.9074 27.7822C28.2837 27.5905 28.5905 27.2842 28.7822 26.9079C29 26.4805 29 25.9215 29 24.8036V19.1969C29 18.079 29 17.5192 28.7822 17.0918C28.5905 16.7155 28.2837 16.4097 27.9074 16.218C27.4796 16 26.9203 16 25.8002 16H24M16 16H24M16 16C16 13.7909 17.7909 12 20 12C22.2091 12 24 13.7909 24 16"
                stroke="#58B3D3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default IconTariff;
