import React, {Fragment, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {loadWaybillV2Async} from "../../../store/reducers/roaming/waybill-v2/romaingWaybillV2Reducer";
import SpinnerComponent from "../../../components/common/SpinnerComponent";
import WaybillV2 from "../../../components/roaming/waybill-v2/WaybillV2";

const ViewWaybillV2 = ({modalId}) => {

    const {id} = useParams();
    const [waybill, setWaybill] = useState(null);
    const activeOrganization = useSelector(selectActiveOrganization);
    const dispatch = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        loadWaybill();
    }, [modalId, id, activeOrganization])

    const loadWaybill = () => {
        loadWaybillV2Async(modalId ? modalId : id).then(loadedWaybill => {
            setWaybill(loadedWaybill)
        }).catch(error => console.log(error))
    }

    return (
        <Fragment>
            {!waybill && <SpinnerComponent />}
            {waybill && <WaybillV2 waybill={waybill} loadWaybill={loadWaybill} />}
        </Fragment>
    );
};

export default ViewWaybillV2;
