import React from 'react';
import {Dropdown, Nav} from "react-bootstrap";
import ActiveOrganization from "../right-side/ActiveOrganization";
import OrganizationBalanceInfo from "../right-side/OrganizationBalanceInfo";
import TechSupportInformation from "../right-side/TechSupportInformation";
import ProfileDropdown from "../right-side/ProfileDropdown";
import {Link} from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import NotificationDropdownMenu from "../nav-items/NotificationDropdownMenu";
import PaymentInstruction from "../right-side/PaymentInstruction";
import NavigatorTopBar from "../right-side/NavigatorTopBar";
import Badge from "../../common/Badge";

const NavbarTopRightSide = () => {
    return (
        <Nav navbar className={"navbar-nav-icons gap-2 flex-row ms-lg-auto align-items-center"} as="ul">
            {/*<Nav.Item>
                <Dropdown autoClose="outside" className="h-100">
                    <Dropdown.Toggle
                        as={Link}
                        to="#!"
                        className="dropdown-caret-none nav-link h-100"
                        variant=""
                    >
                        <FeatherIcon icon="bell" size={20} />
                    </Dropdown.Toggle>
                    <NotificationDropdownMenu />
                </Dropdown>
            </Nav.Item>
            <OrganizationBalanceInfo />
            <ActiveOrganization />*/}
            {/*<PaymentInstruction className="cursor-pointer"/>*/}
            {/*<Nav.Item>
                <ThemeToggler className="px-2" />
            </Nav.Item>*/}
            {/*<TechSupportInformation />*/}
            <NavigatorTopBar />
            <ProfileDropdown />
        </Nav>
    );
};

export default NavbarTopRightSide;
