import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {selectDateFormat, selectLang} from "../../../../store/reducers/main/main";
import {Controller, useFormContext} from "react-hook-form";
import {getBankNameAsync} from "../../../../store/reducers/roaming/roamingReducer";
import {Col, Row, Form} from "react-bootstrap";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import FindCompanySearchable from "../../../organization/FindCompanySearchable";
import AttorneyPerson from "./AttorneyPerson";
import AttorneyItems from "./AttorneyItems";
import PropTypes from "prop-types";

const AttorneyForm = ({loading}) => {

    const lang = useSelector(selectLang);
    const t = Translate;
    const [contractor, setContractor] = useState(null);
    const [contractorBank, setContractorBank] = useState(null);
    const dateFormat = useSelector(selectDateFormat);

    const {register, formState: {errors}, control, watch, setValue} = useFormContext();


    useEffect( () => {
        setContractorInfo()
    }, [contractor])

    const setContractorInfo = async () => {
        setValue('contractorInfo.inn', contractor?.inn);
        setValue('contractorInfo.name', contractor?.name);
        setValue('contractorInfo.oked', contractor?.oked);
        setValue('contractorInfo.address', contractor?.address);
        setValue('contractorInfo.bankInfo.account', contractor?.account);
        setValue('contractorInfo.bankInfo.mfo', contractor?.mfo);

        if (contractor) {
            const bankInfo = await getBankNameAsync({mfo: contractor?.mfo, lang});
            setContractorBank(bankInfo);
        }
    }

    useEffect(() => {
        setValue('contractorInfo.bankInfo.name', contractorBank?.name);
    }, [contractorBank])

    return (
        <Fragment>
            <Row>
                <Col xs={12} lg={6}>
                    <h4>{t(lang, "edi.attorneys.add_attorney.attorney.title")}</h4>
                    <div className="bg-200 p-3">
                        <Row>
                            <Col xs={12} className="mb-2">
                                <Form.Label>{t(lang, "edi.common.attorney.number")}</Form.Label>
                                <Form.Control
                                    placeholder={t(lang, "edi.common.attorney.number.placeholder")}
                                    name="attorneyInfo.number"
                                    type="text"
                                    isInvalid={errors?.attorneyInfo?.number}
                                    isValid={Object.keys(errors).length > 0 && !errors?.attorneyInfo?.number}
                                    {...register("attorneyInfo.number", {
                                        required: t(lang, "edi.common.forms.validations.is_required")
                                    })}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{errors?.attorneyInfo?.number?.message}</Form.Control.Feedback>
                            </Col>
                            <Col xs={12} className="mb-2">
                                <Form.Label>{t(lang, "edi.attorneys.add_attorney.attorney.start_date")}</Form.Label>
                                <Controller control={control}
                                            name="attorneyInfo.start"
                                            defaultValue={new Date()}
                                            rules={{
                                                required: t(lang, "edi.common.forms.validations.is_required")
                                            }}
                                            render={
                                                ({field}) => (
                                                    <DatePicker
                                                        placeholderText={t(lang, "edi.attorneys.add_attorney.attorney.start_date.placeholder")}
                                                        selected={field.value}
                                                        onChange={field.onChange}
                                                        dateFormat={dateFormat}
                                                        wrapperClassName={classNames({
                                                            'is-invalid': errors?.attorneyInfo?.start
                                                        })}
                                                        className={classNames('form-control', {
                                                            'is-invalid': errors.attorneyInfo?.start,
                                                            'is-valid': Object.keys(errors).length > 0 && !errors.attorneyInfo?.start
                                                        })}
                                                    />
                                                )
                                            }
                                />
                                <Form.Control.Feedback
                                    type="invalid">{errors?.attorneyInfo?.start?.message}</Form.Control.Feedback>
                            </Col>
                            <Col xs={12}>
                                <Form.Label>{t(lang, "edi.attorneys.add_attorney.attorney.end_date")}</Form.Label>
                                <Controller control={control}
                                            name="attorneyInfo.end"
                                            rules={{
                                                required: t(lang, "edi.common.forms.validations.is_required"),
                                                validate: (value) => {
                                                    if (value < watch("attorneyInfo.start")) {
                                                        return t(lang, "edi.common.forms.validations.invalid_format")
                                                    }
                                                    return true;
                                                }
                                            }}
                                            render={
                                                ({field}) => (
                                                    <DatePicker
                                                        placeholderText={t(lang, "edi.attorneys.add_attorney.attorney.end_date.placeholder")}
                                                        selected={field.value}
                                                        onChange={field.onChange}
                                                        dateFormat={dateFormat}
                                                        wrapperClassName={classNames({
                                                            'is-invalid': errors?.attorneyInfo?.end
                                                        })}
                                                        className={classNames('form-control', {
                                                            'is-invalid': errors.attorneyInfo?.end,
                                                            'is-valid': Object.keys(errors).length > 0 && !errors.attorneyInfo?.end
                                                        })}
                                                    />
                                                )
                                            }
                                />
                                <Form.Control.Feedback
                                    type="invalid">{errors?.attorneyInfo?.end?.message}</Form.Control.Feedback>
                            </Col>
                        </Row>

                    </div>
                </Col>
                <Col xs={12} lg={6} className="mt-2 mt-lg-0">
                    <h4>{t(lang, "edi.common.contract")}</h4>
                    <div className="bg-200 p-3">
                        <Row>
                            <Col xs={12} className="mb-2">
                                <Form.Label>{t(lang, "edi.common.inn")}</Form.Label>
                                <Controller name="contractorInfo.inn"
                                            control={control}
                                            rules={{
                                                required: t(lang, "edi.common.forms.validations.is_required"),
                                            }}
                                            render={({field}) => (
                                                <FindCompanySearchable
                                                    onChange={setContractor}
                                                    inputProps={{
                                                        isInvalid: !!errors?.contractorInfo?.inn,
                                                        placeholder: t(lang, "edi.toast.find_company_searchable:placeholder"),
                                                    }}
                                                    inputGroupProps={{
                                                        className: classNames({'is-invalid': !!errors?.contractorInfo?.inn})
                                                    }}
                                                    defaultInn={field.value}
                                                />
                                            )}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{errors?.contractorInfo?.inn?.message}</Form.Control.Feedback>
                            </Col>
                            <Col xs={12} className="mb-2">
                                <Form.Label>{t(lang, "edi.common.name")}</Form.Label>
                                <Form.Control
                                    name="contractorInfo.name"
                                    placeholder={t(lang, "edi.common.name")}
                                    type="text"
                                    {...register("contractorInfo.name")}
                                    disabled
                                />
                            </Col>
                            <Col xs={12} md={6} className="mb-2 mb-lg-0">
                                <Form.Label>{t(lang, "edi.common.contract.number")}</Form.Label>
                                <Form.Control
                                    placeholder={t(lang, "edi.common.contract.number.placeholder")}
                                    name="contractInfo.number"
                                    type="text"
                                    isInvalid={errors?.contractInfo?.number}
                                    isValid={Object.keys(errors).length > 0 && !errors?.contractInfo?.number}
                                    {...register("contractInfo.number", {
                                        required: t(lang, "edi.common.forms.validations.is_required")
                                    })}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{errors?.contractInfo?.number?.message}</Form.Control.Feedback>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Label>{t(lang, "edi.common.contract.date")}</Form.Label>
                                <Controller control={control}
                                            name="contractInfo.date"
                                            rules={{
                                                required: t(lang, "edi.common.forms.validations.is_required"),
                                            }}
                                            render={
                                                ({field}) => (
                                                    <DatePicker
                                                        placeholderText={t(lang, "edi.common.contract.date.placeholder")}
                                                        selected={field.value}
                                                        onChange={field.onChange}
                                                        dateFormat={dateFormat}
                                                        wrapperClassName={classNames({
                                                            'is-invalid': errors?.contractInfo?.date
                                                        })}
                                                        className={classNames('form-control', {
                                                            'is-invalid': errors?.contractInfo?.date,
                                                            'is-valid': Object.keys(errors).length > 0 && !errors?.contractInfo?.date
                                                        })}
                                                    />
                                                )
                                            }
                                />
                                <Form.Control.Feedback
                                    type="invalid">{errors?.contractInfo?.date?.message}</Form.Control.Feedback>
                            </Col>
                        </Row>
                    </div>
                </Col>

                <Col xs={12} className="my-2">
                    <AttorneyPerson/>
                </Col>

                <Col xs={12} className="mt-2">
                    <AttorneyItems root={'items'} loading={loading}/>
                </Col>
            </Row>
        </Fragment>
    );
};

AttorneyForm.propTypes = {
    loading: PropTypes.bool,
};

AttorneyForm.defaultProps = {
    loading: false,
};

export default AttorneyForm;
