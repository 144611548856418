import React, {useEffect, useState} from 'react';
import {selectLang} from "../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import toast from "react-hot-toast";
import {Button, InputGroup, Spinner, Form, FloatingLabel} from "react-bootstrap";
import {getPersonInfoAsync} from "../../../store/reducers/roaming/roamingReducer";
import PropTypes from "prop-types";

const innFormatRegEx = /^(\d{9}|\d{14})$/

const FindPerson = React.forwardRef(({onChange, disabled, placeholder, inputGroupProps, inputProps, defaultInnOrPinfl}, ref) => {
    const [loading, setLoading] = useState(false);
    const [innOrPinfl, setInnOrPinfl] = useState('');
    const lang = useSelector(selectLang);
    const t = Translate;

    const findPerson = async () => {
        try {
            setLoading(true)
            onChange(await getPersonInfoAsync(innOrPinfl, lang))
        } catch (e) {
            toast.error(t(lang, 'edi.toast.find_company_failed'));
            onChange(null);
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        defaultInnOrPinfl && setInnOrPinfl(defaultInnOrPinfl);
    }, [defaultInnOrPinfl]);

    useEffect(() => {
        innFormatRegEx.test(innOrPinfl) ? findPerson() : onChange(null)
    }, [innOrPinfl]);

    return (
        <InputGroup {...inputGroupProps}>
            <FloatingLabel label={placeholder}>
                <Form.Control type="text" disabled={disabled} placeholder={placeholder} className="input-spin-none" value={innOrPinfl} onChange={e => setInnOrPinfl(e.target.value)} ref={ref} readOnly={loading} {...inputProps} />
            </FloatingLabel>
            {loading && <Spinner className="align-middle me-2" animation="border" role="switch" size="sm"/>}
        </InputGroup>
    )
});
FindPerson.propTypes = {
    onChange: PropTypes.func.isRequired
}

FindPerson.defaultProps = {
    onChange: () => {
    }
}
export default FindPerson;
