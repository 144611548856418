import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {Tab, Tabs} from "react-bootstrap";
import {EdiDefaultPermissions} from "../../../enum/permission/EdiPermission";
import {RoamingDefaultPermissions} from "../../../enum/permission/RoamingPermission";
import {AnotherDefaultPermission} from "../../../enum/permission/RolePermission";
import Permissions from './Permissions'
const EDI = 1
const Roaming = 2
const Another  = 3
const PermissionsDatatable = () => {
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Fragment>
            <h5 className={"ps-1 text-center my-2"}>{t(lang, "edi.permission.role_permissions_title")}</h5>
            <Tabs fill defaultActiveKey={EDI}>
                <Tab eventKey={EDI} title={'EDI'}>
                    <Permissions permission={EdiDefaultPermissions} name={'edi'} />
                </Tab>
                <Tab title={t(lang, 'edi.navbar.routes.edo')} eventKey={Roaming}>
                    <Permissions permission={RoamingDefaultPermissions} name={'roaming'} />
                </Tab>
                <Tab title={t(lang, 'main.top.nav.profile_dropdown.dropdown_item.miscellaneous')}  eventKey={Another}>
                    <Permissions permission={AnotherDefaultPermission} name={'another'} />
                </Tab>
            </Tabs>
        </Fragment>
    );
};

export default PermissionsDatatable;
