import React from 'react';

const IconSignature = ({width = 24, height = 24, color = "none"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3 18C6.333 14.364 8 11.4545 8 9.27273C8 6 7 6 6 6C5 6 3.968 7.18364 4 9.27273C4.034 11.5069 5.658 14.5931 6.5 15.8182C8 18 9 18.5455 10 16.9091L12 13.6364C12.333 16.5458 13.333 18 15 18C16 18 17.1464 15.4646 17.5 15.8182C18.1818 16.5 19 18 21 18"
                stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default IconSignature;
