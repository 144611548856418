import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {deleteRoleAsync} from "../../../store/reducers/role/roleReducer";
import toast from "react-hot-toast";
import ButtonWithConfirmModal from "../../common/ButtonWithConfirmModal";

const DeleteRole = ({id, show, onClose}) => {
    const lang = useSelector(selectLang);
    const [loading, setLoading] = useState(false);
    const t = Translate;
    const onConfirm = () => {
        setLoading(true);
        deleteRoleAsync({
            id: id
        }).then(res => {
            onClose();
        }).catch(error => {
            console.log(error)
            toast.error(t(lang, "hippo.user.settings.delete_role.error.message"));
        }).finally(() => setLoading(false))
    }

    const onCloseModal = () => {
        onClose()
    }

    return (
        <ButtonWithConfirmModal
            title={t(lang, 'edi.delete.role.title')}
            button={{text: "Удалить", variant: "danger"}}
            onConfirm={onConfirm}
            showModal={show}
            loading={loading}
            onClose={onCloseModal}
        />
    );
};

export default DeleteRole;
