import React, {useEffect, useState} from 'react';
import ReactSelect from "react-select";
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import CustomFloatingLabelSelect from "../../floating-custom-select/CustomFloatingLabelSelect";

const RoamingInvoiceFormBranchSelector = ({branches, defaultBranchCode, onChange, placeholder, id}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [value, setValue] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        setValue(branches.find(b => b.code === defaultBranchCode) || null)
        setSelectedOption(branches.find(b => b.code === defaultBranchCode) || null)
    }, [defaultBranchCode]);

    const handleChange = (option) => {
        onChange(option)
        setValue(option)
        setSelectedOption(option)
    }
    const formatOptionLabel = (option) => {
        return (
            <div>
                <span className="fw-bold me-2">{option.name}</span>
                <span>{option.code}</span>
            </div>
        )
    };

    return (
        <div className={'select'}>
            <CustomFloatingLabelSelect
                options={branches}
                isClearable={true}
                label={placeholder}
                getOptionLabel={option => `${option.name} ${option.code}`}
                getOptionValue={option => option.code}
                formatOptionLabel={formatOptionLabel}
                value={branches.find(b => b.code === defaultBranchCode) || null}
                onChange={(option) => handleChange(option)}
            />
        </div>

    );
};

export default RoamingInvoiceFormBranchSelector;
