import React, {useEffect} from 'react';
import NavbarTopHorizontal from "../components/navbar/navbar-top/NavbarTopHorizontal";
import {Container} from "react-bootstrap";
import classNames from "classnames";
import {Outlet, redirect, useLocation, useNavigate} from "react-router-dom";
import NavbarVertical from "../components/navbar/navbar-vertical/NavbarVertical";
import {useAppContext} from "../pheonix/providers/AppProvider";
import {useMainLayoutContext} from "../pheonix/providers/MainLayoutProvider";
import NavbarTopDefault from "../components/navbar/navbar-top/NavbarTopDefault";
import axios from "axios";
import {
    authStatus, getOrganizationUsersAsync,
    logoutAsync,
    selectActiveOrganization,
    selectIsAuthorized
} from "../store/reducers/auth/authReducer";
import {useDispatch, useSelector} from "react-redux";
import {loadOrganizationTemplatesAsync} from "../store/reducers/excel/excelReducer";
import {
    loadCompany,
    loadCountries, loadCustomerPrivileges,
    loadDistricts, loadExecutorPrivileges, loadMeasurements,
    loadRegions,
    loadStations, loadTurnoverPrivileges, loadWarehouses
} from "../store/reducers/roaming/roamingReferenceReducer";
import EventBus from "../eventbus/EventBus";
import {EDI_SETTINGS_SUCCESS} from "../eventbus/ediSettingsEvent";
import {loadDefaultSettings, loadSettings} from "../store/reducers/settings/settingsReducer";
import {
    invalidateCompanyClassCodesAsync,
    loadCompanyProductCatalogsAsync
} from "../store/reducers/roaming/roamingReducer";
import {selectLang} from "../store/reducers/main/main";
import PushUpdate from "../pushupdate";
import {
    loadOwnerBindings,
    loadSupervisorBindings
} from "../store/reducers/organization-binding/organizationBindingReducer";
import {currencyGetAsync} from "../store/reducers/currency/currencyReducer";
import {
    deleteContractor,
    loadAllContractorAsync,
    updateContractor, updateContractorDebt
} from "../store/reducers/contractor/contractorReducer";
import {getAllWarehousesAsync} from "../store/reducers/warehouse/warehouseReducer";
import {shipmentLoadProviders} from "../store/reducers/edi/shipment/shipmentReducer";
import {returnShipmentLoadProviders} from "../store/reducers/edi/return-shipment/returnShipmentReducer";
import {ContractorDebtUpdated, pushUpdateFunc, WarehouseItemUpdated} from "../enum/pushupdate/PushUpdateNotification";
import {
    pushUpdateNotification,
    pushUpdateNotificationContractorDebt
} from "../store/reducers/pushupdate/pushUpdateNotification";
import {
    ADD_CONTRACTOR_SUCCESS, CHANGE_CONTRACTOR_STARTING_DEBTS_SUCCEED,
    DELETE_CONTRACTOR_SUCCESS,
    EDIT_CONTRACTOR_SUCCESS
} from "../eventbus/contractor/contractorEvents";
import {PUSH_UPDATE_CONTRACTOR_DEBT_STATES_UPDATED} from "../eventbus/contractor/contractor-push-update-events";
import {PUSH_UPDATE_ITEM_STATE_UPDATED} from "../eventbus/itemEvents";
import dayjs from "dayjs";
import {BIND_CONTRACTOR_LABEL_SUCCESS} from "../eventbus/contractor/contractorLabelEvents";
import RoamingProviderBinding from "../components/roaming/RoamingProviderBinding";
import {
    ITEM_INITIALIZATION_STATE_COMPLETE,
    ITEM_INITIALIZATION_STATE_LOADING,
    itemSlice,
    loadItemsAsync,
    pushUpdateItem,
    setLoading,
    updateCategories,
    updateCategoriesTree,
    updateItems
} from "../store/reducers/item/itemReducer";

const MainLayout = () => {
    const lang = useSelector(selectLang);
    const isAuthorized = useSelector(selectIsAuthorized);
    const activeOrganization = useSelector(selectActiveOrganization);
    const navigate = useNavigate()
    const status = useSelector(authStatus);
    const {pathname} = useLocation()

    const {
        config: { navbarPosition }
    } = useAppContext();


    useEffect(() => {
        if (!isAuthorized && status === false) {
            navigate('/authentication')
        }
    }, [status])

    const { contentClass, footerClass } = useMainLayoutContext();

    const dispatch = useDispatch()

    useEffect(() => {
        PushUpdate.connect(localStorage.getItem("token"))

        const unauthorizedInterceptor = axios.interceptors.response.use(response => {
            return response
        }, error => {
            if (error?.response?.status === 401) {
                if (isAuthorized) {
                    dispatch(logoutAsync())
                }
            }
            return true
        })

        const ediSettingsSuccessHandler = EventBus.on(EDI_SETTINGS_SUCCESS, () => {
            dispatch(loadSettings())
        })

        return () => {
            PushUpdate.disconnect()
            axios.interceptors.response.eject(unauthorizedInterceptor);
            EventBus.remove(EDI_SETTINGS_SUCCESS, ediSettingsSuccessHandler)

        }
    }, [])

    useEffect(() => {
        if (isAuthorized) {
            dispatch(loadOrganizationTemplatesAsync());
            // dispatch(loadSettings())

            if (activeOrganization?.inn) {
                dispatch(loadCompany(activeOrganization.pinfl || activeOrganization.inn, {active_accounts: true, main_accounts: true, frozen_accounts: true}));
            }
        }

    }, [activeOrganization, isAuthorized])

    useEffect(() => {
        if (isAuthorized) {
            dispatch(loadMeasurements());
            dispatch(loadCountries());
            dispatch(loadRegions());
            dispatch(loadDistricts());
            dispatch(loadStations());
        }
    }, [isAuthorized]);

    const loadItems = () => {
        dispatch(setLoading(true));
        dispatch(itemSlice.actions.setItemInitializationState(ITEM_INITIALIZATION_STATE_LOADING))
        loadItemsAsync()
            .then(({categories, categoriesTree, data}) => {
                dispatch(setLoading(false));
                dispatch(updateItems(data.sort((a, b) => {
                    const nameA = a.item.name.trim().toLowerCase();
                    const nameB = b.item.name.trim().toLowerCase();

                    if (nameA < nameB)
                        return -1;

                    if (nameA > nameB)
                        return 1;

                    return 0;
                })));
                dispatch(updateCategories(categories));
                dispatch(updateCategories(categories));
                dispatch(updateCategoriesTree(categoriesTree));
            })
            .catch((error) => {
                dispatch(setLoading(false));
                dispatch(updateItems([]));
                dispatch(updateCategories([]));
                dispatch(updateCategoriesTree([]));
            })
            .finally(() => {
                dispatch(itemSlice.actions.setItemInitializationState(ITEM_INITIALIZATION_STATE_COMPLETE))
            })
    }

    // useEffect(() => {
    //     if (activeOrganization?.inn || activeOrganization?.pinfl) {
    //         dispatch(loadCompanyProductCatalogsAsync(activeOrganization.inn || activeOrganization.pinfl, lang));
    //         invalidateCompanyClassCodesAsync(activeOrganization.pinfl || activeOrganization.inn, lang)
    //             .then(() => {
    //             })
    //             .catch(() => {
    //             })
    //     }
    // }, [activeOrganization, lang])

    useEffect(() => {
        if (isAuthorized) {
            dispatch(loadOwnerBindings());
            dispatch(loadSupervisorBindings());
            dispatch(loadAllContractorAsync());
            dispatch(currencyGetAsync());
            dispatch(getOrganizationUsersAsync())
            dispatch(loadDefaultSettings())

            loadItems();
            dispatch(loadOrganizationTemplatesAsync());
            dispatch(loadSettings())
            dispatch(getAllWarehousesAsync({page: null, limit: null}))
            dispatch(shipmentLoadProviders()) // temporary
            dispatch(returnShipmentLoadProviders()) // temporary
            if (activeOrganization?.inn) {
                dispatch(loadCompany(activeOrganization.pinfl || activeOrganization.inn, {active_accounts: true, main_accounts: true, frozen_accounts: true}));
                dispatch(loadExecutorPrivileges({inn: activeOrganization.inn}));
                dispatch(loadCustomerPrivileges({inn: activeOrganization.inn}));
                dispatch(loadTurnoverPrivileges({inn: activeOrganization.inn, date: dayjs(new Date()).format("YYYY-MM-DD")}));
                dispatch(loadWarehouses({inn: activeOrganization.inn}));
            }


            // const onItemAddedHandler = EventBus.on(ADD_ITEM_SUCCESS, loadItems);
            // const onItemEditedHandler = EventBus.on(EDIT_ITEM_SUCCESS, loadItems);
            // const onItemDeleteHandler = EventBus.on(DELETE_ITEM_SUCCESS, loadItems);
            // const onCatalogsValidateHandler = EventBus.on(VALIDATE_ITEM_CATALOGS_SUCCESS, loadItems);
            // const onBulkAddItemsFinishedHandler = EventBus.on(BULK_ADD_ITEMS_FINISHED, loadItems);
            // const onBulkEditItemsFinishedHandler = EventBus.on(BULK_EDIT_ITEMS_FINISHED, loadItems);
            // const onBulkItemDeleteHandler = EventBus.on(BULK_DELETE_ITEMS_FINISHED, loadItems);
            // const onCatalogValidateHandler = EventBus.on(VALIDATE_ITEM_CATALOG_SUCCESS, loadItems);
            const onPushUpdateHandler = EventBus.on(PUSH_UPDATE_ITEM_STATE_UPDATED, ({item_id, warehouse_id, state, item_name,organization_id}) => {
                dispatch(pushUpdateItem({item_id, warehouse_id, state}));
                const pushUpdateWarehouseItem = pushUpdateFunc(WarehouseItemUpdated, {item_id, warehouse_id, state, item_name,organization_id})
                dispatch(pushUpdateNotification(pushUpdateWarehouseItem));
            });

            const onContractorAdd = EventBus.on(ADD_CONTRACTOR_SUCCESS, (contractor) => {
                dispatch(updateContractor(contractor));
            })
            const onContractorEditHandler = EventBus.on(EDIT_CONTRACTOR_SUCCESS, (contractor) => {
                dispatch(updateContractor(contractor));
            })
            const onContractorDeleteHandler = EventBus.on(DELETE_CONTRACTOR_SUCCESS, (contractor_id) => {
                dispatch(deleteContractor(contractor_id));
            })
            const onBindContractorLabelHandler = EventBus.on(BIND_CONTRACTOR_LABEL_SUCCESS, (contractor) => {
                dispatch(updateContractor(contractor));
            })
            const onContractorStartingDebtChanged = EventBus.on(CHANGE_CONTRACTOR_STARTING_DEBTS_SUCCEED, (contractor) => {
                dispatch(updateContractor(contractor));
            })

            const onPushUpdateContractorDebtStateHandler = EventBus.on(PUSH_UPDATE_CONTRACTOR_DEBT_STATES_UPDATED, (updated_contractor) => {
                const pushUpdateContractorDebt = pushUpdateFunc(ContractorDebtUpdated, updated_contractor)
                dispatch(pushUpdateNotificationContractorDebt(pushUpdateContractorDebt))
                dispatch(updateContractorDebt({contractorId: updated_contractor.id, debts: updated_contractor.debts}));
            });

            return () => {
                // EventBus.remove(ADD_PARTNER_SUCCEED, onPartnerAddedHandler);
                // EventBus.remove(EDIT_PARTNER_SUCCEED, onPartnerEditedHandler);
                //
                // EventBus.remove(ADD_ITEM_SUCCESS, onItemAddedHandler);
                // EventBus.remove(EDIT_ITEM_SUCCESS, onItemEditedHandler);
                // EventBus.remove(BULK_DELETE_ITEMS_FINISHED, onBulkItemDeleteHandler);
                // EventBus.remove(VALIDATE_ITEM_CATALOGS_SUCCESS, onCatalogsValidateHandler);
                // EventBus.remove(BULK_ADD_ITEMS_FINISHED, onBulkAddItemsFinishedHandler);
                // EventBus.remove(BULK_EDIT_ITEMS_FINISHED, onBulkEditItemsFinishedHandler);
                // EventBus.remove(DELETE_ITEM_SUCCESS, onItemDeleteHandler);
                // EventBus.remove(VALIDATE_ITEM_CATALOG_SUCCESS, onCatalogValidateHandler);
                EventBus.remove(PUSH_UPDATE_ITEM_STATE_UPDATED, onPushUpdateHandler);

                EventBus.remove(ADD_CONTRACTOR_SUCCESS, onContractorAdd)
                EventBus.remove(EDIT_CONTRACTOR_SUCCESS, onContractorEditHandler);
                EventBus.remove(DELETE_CONTRACTOR_SUCCESS, onContractorDeleteHandler);
                EventBus.remove(BIND_CONTRACTOR_LABEL_SUCCESS, onBindContractorLabelHandler)
                EventBus.remove(CHANGE_CONTRACTOR_STARTING_DEBTS_SUCCEED, onContractorStartingDebtChanged);

                EventBus.remove(PUSH_UPDATE_CONTRACTOR_DEBT_STATES_UPDATED, onPushUpdateContractorDebtStateHandler);
            }
        }
    }, [activeOrganization, isAuthorized])

    useEffect(() => {
        if (isAuthorized) {
            if (activeOrganization?.inn || activeOrganization?.pinfl) {
                dispatch(loadCompanyProductCatalogsAsync(activeOrganization.inn || activeOrganization.pinfl, lang));
                invalidateCompanyClassCodesAsync(activeOrganization.pinfl || activeOrganization.inn, lang)
                    .then(() => {
                    })
                    .catch(() => {
                    })
            }
        }

    }, [activeOrganization, lang,isAuthorized])



    return (
        <Container fluid className="px-0">
            {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
                <NavbarVertical />
            )}
            {navbarPosition === 'vertical' && <NavbarTopDefault />}
            {(navbarPosition === 'horizontal' || navbarPosition === 'combo') && (
                <NavbarTopHorizontal />
            )}
            <div style={{paddingTop: 70}} className={classNames(contentClass, 'content', 'px-3 pb-3')}>
                <RoamingProviderBinding />
                <Outlet />
            </div>
        </Container>
    );
};

export default MainLayout;
