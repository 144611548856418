import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Translate} from "../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import classNames from "classnames";
import {Col, Modal, OverlayTrigger, Row, Tooltip, Form} from "react-bootstrap";
import ReactSelect from "react-select";
import IconButton from "../IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import Divider from "./Divider";
import {roamingInvoiceItemOriginTypes} from "../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import FalconCloseButton from "./FalconCloseButton";
import CustomFloatingLabelSelect from "../floating-custom-select/CustomFloatingLabelSelect";

const OriginLinkSelector = ({defaultOriginType, onChange, linkProps, linkAppendClassName, isTitle, placeholder}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [show, setShow] = useState(false);
    const [origin, setOrigin] = useState(null);

    useEffect(() => {
        if (origin?.value !== defaultOriginType)
            setOrigin(roamingInvoiceItemOriginTypes.find(o => o.value === defaultOriginType));
    }, [defaultOriginType])

    const onChangeHandler = (option) => {
        setOrigin(option);
        onChange(option ? option.value : null);
        option && setShow(false);
    }

    const link = <span onClick={() => setShow(true)}
                       className={classNames('text-info cursor-pointer fs--1', linkAppendClassName)}
                       {...linkProps}
    >
        {origin && !isTitle ? t(lang, origin.label) : t(lang, 'roaming.common.items.origin')}
    </span>

    return (
        <Fragment>
            {origin && <OverlayTrigger overlay={<Tooltip>{t(lang, origin.label)}</Tooltip>}>
                {link}
            </OverlayTrigger>}
            {!origin && link}
            {show &&
                <Modal show={true}
                       className={'davr-modal'}
                       onHide={() => setShow(false)}
                       size="xl"
                       aria-labelledby="contained-modal-title-vcenter"
                       centered
                >
                    <Modal.Header>
                        <Modal.Title>{t(lang, 'roaming.common.items.origin')}</Modal.Title>
                        <FalconCloseButton onClick={() => setShow(false)}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12}>
                                <Form.Group>
                                    <CustomFloatingLabelSelect
                                        options={roamingInvoiceItemOriginTypes}
                                        hideSelectedOptions
                                        label={t(lang, "")}
                                        getOptionLabel={option => t(lang, option.label)}
                                        getOptionValue={option => option.value}
                                        isSearchable={false}
                                        defaultMenuIsOpen={!origin}
                                        value={null}
                                        onChange={(option) => onChangeHandler(option)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {origin &&
                            <Fragment>
                                <Divider/>
                                <Row>
                                    <Col xs={12}>
                                        <h5 className="text-info">{t(lang, 'roaming.common.items.origin')}</h5>
                                        <div className="mt-3 fw-bold fs--1">
                                            <span className="text-primary">{origin.value} - </span>
                                            <span className="text-dark">{t(lang, origin.label)}</span>
                                            <IconButton
                                                variant="phoenix-secondary"
                                                size="sm"
                                                icon={faTrash}
                                                iconClassName={"text-danger"}
                                                className="ms-2"
                                                title={t(lang, 'items.common.delete')}
                                                onClick={() => onChangeHandler(null)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Fragment>
                        }
                    </Modal.Body>
                </Modal>
            }
        </Fragment>
    );
};

export default OriginLinkSelector;
