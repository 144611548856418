import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {Col, Row, Table} from "react-bootstrap";
import numeral from "numeral";
import ConvertQuantityToWord from "../../../filter/ConvertQuantityToWord";

const ContractViewTable = ({contract,getMeasure,totalWithNds}) => {
    const numberFormat = useSelector(selectNumberFormat);
    const lang = useSelector(selectLang);
    const t = Translate;
    return (
        <Fragment>
            <div className={'border border-1 rounded-3 overflow-hidden'}>
                <Table bordered responsive className="table-view scrollbar border-900 mb-0 fs-9 fw-semi-bold">
                    <thead className="text-dark border-bottom bg-card second-text text-center">
                    <tr>
                        <th className=" fw-semi-bold align-middle" rowSpan="2">№</th>
                        <th className=" fw-semi-bold align-middle" rowSpan="2">{t(lang, "roaming.contract.items_datatable.name")}</th>
                        <th className=" fw-semi-bold align-middle" rowSpan="2">{t(lang, "roaming.contract.items_datatable.catalog")}</th>
                        <th className=" fw-semi-bold align-middle" rowSpan="2">{t(lang, "roaming.contract.items_datatable.barcode")}</th>
                        <th className=" fw-semi-bold align-middle" rowSpan="2">{t(lang, "roaming.contract.items_datatable.measurement")}</th>
                        <th className=" fw-semi-bold align-middle" rowSpan="2">{t(lang, "roaming.contract.items_datatable.quantity")}</th>
                        <th className=" fw-semi-bold align-middle" rowSpan="2">{t(lang, "roaming.contract.items_datatable.price")}</th>
                        <th className=" fw-semi-bold align-middle" rowSpan="2">{t(lang, "roaming.contract.items_datatable.total")}</th>
                        <th className=" fw-semi-bold align-middle text-center" colSpan="2">{t(lang, "roaming.contract.items_datatable.vat")}</th>
                        <th className=" fw-semi-bold align-middle" rowSpan="2">{t(lang, "roaming.contract.items_datatable.total_with_vat")}</th>
                    </tr>
                    <tr>
                        <th className="fw-semi-bold align-middle">{t(lang, "roaming.contract.items_datatable.vat.rate")}</th>
                        <th className="fw-semi-bold align-middle">{t(lang, "roaming.contract.items_datatable.vat.sum")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {contract?.items?.map((item, index) => (
                        <tr key={index}>
                            <td className="fw-semi-bold align-middle">{item?.ordering_number}</td>
                            <td className="fw-semi-bold align-middle">{item?.name}</td>
                            <td className="fw-semi-bold align-middle">{item?.catalog.code} - {item?.catalog.name}</td>
                            <td className="fw-semi-bold align-middle">{item?.barcode}</td>
                            <td className="fw-semi-bold align-middle">{item?.measure_id ? getMeasure(`${item?.measure_id}`)?.name : item.packageName}</td>
                            <td className="fw-semi-bold align-middle text-nowrap">{item?.quantity || 0}</td>
                            <td className="fw-semi-bold align-middle text-nowrap">{numeral(item?.price || 0).format(numberFormat)}</td>
                            <td className="fw-semi-bold align-middle text-nowrap">{numeral(item?.total || 0).format(numberFormat)}</td>
                            <td className="fw-semi-bold align-middle text-nowrap">{item?.vat_rate}</td>
                            <td className="fw-semi-bold align-middle text-nowrap">{numeral(item?.vat_sum || 0).format(numberFormat)}</td>
                            <td className="fw-semi-bold align-middle text-nowrap">{numeral(item?.total_with_nds || 0).format(numberFormat)}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <Row className="g-0 justify-content-end">
                    <Col xs="auto">
                        <Table borderless size="sm" className="fs-9 text-end">
                            <tbody>
                            <tr>
                                <th className="text-900">{t(lang, 'edi.common.total')}:</th>
                                <td className="fw-semi-bold">
                                    {numeral(contract?.items?.reduce((total, item) => item?.total + total, 0)).format(numberFormat)}
                                </td>
                            </tr>
                            <tr>
                                <th className="text-900">{t(lang, 'edi.common.nds_value')}:</th>
                                <td className="fw-semi-bold">
                                    {numeral(contract.items.reduce((total, item) => item.vat_sum + total, 0)).format(numberFormat)}
                                </td>
                            </tr>
                            <tr className="border-top">
                                <th className="text-900">{t(lang, 'edi.common.total_with_nds')}:</th>
                                <td className="fw-semi-bold">
                                    {numeral(totalWithNds).format(numberFormat)}
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
            <p className="fw-bold">
                {t(lang, "roaming.contract.items_datatable.amount_in_words_1")} {ConvertQuantityToWord.getText(totalWithNds)} ({t(lang, "roaming.contract.items_datatable.amount_in_words_2")}),
                ({numeral(totalWithNds).format(numberFormat)})
            </p>

        </Fragment>
    );
};

export default ContractViewTable;
