import {createSlice} from "@reduxjs/toolkit";
import {
    ROAMING_ACT_ACCEPT_FAILED,
    ROAMING_ACT_ACCEPT_REQUESTED,
    ROAMING_ACT_ACCEPT_SUCCESS,
    ROAMING_ACT_CANCEL_FAILED,
    ROAMING_ACT_CANCEL_REQUESTED,
    ROAMING_ACT_CANCEL_SUCCESS,
    ROAMING_ACT_DECLINE_FAILED,
    ROAMING_ACT_DECLINE_REQUESTED,
    ROAMING_ACT_DECLINE_SUCCESS,
    ROAMING_ACT_DRAFT_FAILED,
    ROAMING_ACT_DRAFT_REQUESTED,
    ROAMING_ACT_DRAFT_SUCCESS,
    ROAMING_ACT_REGISTER_FAILED,
    ROAMING_ACT_REGISTER_REQUESTED,
    ROAMING_ACT_REGISTER_SUCCESS,
    ROAMING_ACT_SYNC_FAILED,
    ROAMING_ACT_SYNC_REQUESTED,
    ROAMING_ACT_SYNC_SUCCESS
} from "../../../../eventbus/romaing/act/roamingActEvents";
import {attachPkcs7WithTimestamp, attachTimestampAsync, createPkcs7WithTimestamp} from "../../eimzo/eimzoReducer";
import RoamingService from "../../../services/roaming/roamingService";
import roamingService from "../../../services/roaming/roamingService";
import EventBus from "../../../../eventbus/EventBus";
import {VIEW_DRAFT, VIEW_INBOX, VIEW_OUTBOX} from "../../../../enum/roaming/TypeViewers";

export const roamingActSlice = createSlice({
    name: "roamingAct",
    initialState: {
        acts: [],
        filterOptions: {page: 1, limit: 10},
        count: 0,
        viewPage: {
            id: null,
            draftId: null,
            showModal: false
        }
    },
    reducers: {
        setShowModal:(state, action) => {
            if (!action.payload) {
                state.viewPage = {id: null, draftId: null, showModal:false}
            }else {
                state.viewPage.showModal = action.payload
            }
        },
        setId: (state, {payload}) => {
            const {draftId, id} = payload
            if (draftId) {
                state.viewPage.draftId = draftId
            }else if (id) {
                state.viewPage.id = id
            }
        },
        updateRoamingAct: (state, action) => {
            state.acts = action.payload
        },
        updateFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
        updateRoamingActsCount: (state, action) => {
            state.count = action.payload
        },
    }
})

export const selectRoamingActs = state => state.roamingActSlice.acts;
export const selectActsFilterOptions = state => state.roamingActSlice.filterOptions;
export const selectCount = state => state.roamingActSlice.count;
export const viewPage = state => state.roamingActSlice.viewPage

export const ActFormSendAsync = async (certificate, lang, ActSend, ActID, ActSeller) => {
    try {
        EventBus.dispatch(ROAMING_ACT_REGISTER_REQUESTED);

        const {pkcs7: signature, signatureHex, signerSerialNumber} = await createPkcs7WithTimestamp(ActSend, certificate.keyId);
        const {data: timeStamp} = await RoamingService.getTimestamp(signatureHex);
        const {pkcs7: roamingSignature} = await attachTimestampAsync(signature, signerSerialNumber, timeStamp);

        const registerActSend = await RoamingService.registerActFormSend(
            {
                id: ActID,
                signature: roamingSignature,
                executorInn: ActSeller,
                lang: lang,
            })
        EventBus.dispatch(ROAMING_ACT_REGISTER_SUCCESS, registerActSend.data)
        return registerActSend.data

    } catch (error) {
        EventBus.dispatch(ROAMING_ACT_REGISTER_FAILED, error);
        return error
    }
}

export const ActFormSendDraft = async (ActDraftSend) => {
    try{
        EventBus.dispatch(ROAMING_ACT_DRAFT_REQUESTED);

        await RoamingService.actFormSendDraft({act: ActDraftSend})
            .then(response => EventBus.dispatch(ROAMING_ACT_DRAFT_SUCCESS, response.data))

    }catch(error) {
        EventBus.dispatch(ROAMING_ACT_DRAFT_FAILED, error)
    }
}

export const loadActDraftAsync = (id) => {
    return new Promise((resolve, reject) => {
        RoamingService.getActDraft(id)
            .then(response => resolve(response))
            .catch(errors => reject(errors))
    })
}

export const loadActAsync = (id) => {
    return new Promise((resolve, reject) => {
        RoamingService.getAct(id)
            .then(response => resolve(response))
            .catch(errors => reject(errors))
    })
}

export const loadRoamingActAsync = ({type_viewer, page, limit, ...filters}) => async (dispatch) => {
    try {
        const skip = (page - 1) * limit
        const params = {skip, limit, ...filters}
        let acts = []
        if (type_viewer === VIEW_INBOX) {
            acts = (await roamingService.getCustomerAct(params)).data
        } else if (type_viewer === VIEW_OUTBOX) {
            acts = (await roamingService.getExecutorAct(params)).data
        } else if (type_viewer === VIEW_DRAFT) {
            acts = (await roamingService.getExecutorActDraft(params)).data
        }
        dispatch(roamingActSlice.actions.updateRoamingAct(acts))
    } catch (error) {
        console.log(error)
    }
}

export const changeFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(roamingActSlice.actions.updateFilterOptions({...params}))
}

export const loadRoamingActsCountAsync = ({type_viewer, page, limit, ...filters}) => async (dispatch) => {
    try {
        let count
        if (type_viewer === VIEW_INBOX) {
            count = (await roamingService.getCustomerActCount(filters)).data
        } else if (type_viewer === VIEW_OUTBOX) {
            count = (await roamingService.getExecutorActCount(filters)).data
        } else if (type_viewer === VIEW_DRAFT) {
            count = (await roamingService.getExecutorActDraftCount(filters)).data
        }
        dispatch(roamingActSlice.actions.updateRoamingActsCount(count))
    } catch (error) {
        console.log(error)
    }
}

export const ActCustomerAcceptHashCodeAsync = async (certificate, lang, act) => {
    try {
        const {data: hashCode} = await roamingService.getActAcceptHashCode(act.id)

        EventBus.dispatch(ROAMING_ACT_ACCEPT_REQUESTED, act.id);
        const {
            pkcs7: signature,
            signatureHex,
            signerSerialNumber
        } = await attachPkcs7WithTimestamp(hashCode, certificate.keyId);
        const {data: timeStamp} = await RoamingService.getTimestamp(signatureHex);
        const {pkcs7: roamingSignature} = await attachTimestampAsync(signature, signerSerialNumber, timeStamp);
        await roamingService.ActCustomerAccept(act.id, roamingSignature).then(response => EventBus.dispatch(ROAMING_ACT_ACCEPT_SUCCESS, response.data))
    } catch (error) {
        EventBus.dispatch(ROAMING_ACT_ACCEPT_FAILED, error);
    }
}

export const ActCustomerDeclineHashCodeAsync = async (certificate, lang, act, comment) => {
    try {
        const {data: hashCode} = await roamingService.getActDeclineHashCode(act.id, comment)

        EventBus.dispatch(ROAMING_ACT_DECLINE_REQUESTED, act.id);
        const {
            pkcs7: signature,
            signatureHex,
            signerSerialNumber
        } = await createPkcs7WithTimestamp(hashCode, certificate.keyId);
        const {data: timeStamp} = await RoamingService.getTimestamp(signatureHex);
        const {pkcs7: roamingSignature} = await attachTimestampAsync(signature, signerSerialNumber, timeStamp);
        await roamingService.ActCustomerDecline(act.id, roamingSignature, comment).then(response => EventBus.dispatch(ROAMING_ACT_DECLINE_SUCCESS, response.data))
    } catch (error) {
        EventBus.dispatch(ROAMING_ACT_DECLINE_FAILED, error);
    }
}

export const ActCancelHashCodeAsync = async (certificate, lang, act) => {
    try {
        const {data: hashCode} = await roamingService.getActCancelHashCode(act.id)

        EventBus.dispatch(ROAMING_ACT_CANCEL_REQUESTED, act.id);
        const {
            pkcs7: signature,
            signatureHex,
            signerSerialNumber
        } = await createPkcs7WithTimestamp(hashCode, certificate.keyId);
        const {data: timeStamp} = await RoamingService.getTimestamp(signatureHex);
        const {pkcs7: roamingSignature} = await attachTimestampAsync(signature, signerSerialNumber, timeStamp);
        await roamingService.ActCancel(act.id, roamingSignature).then(response => EventBus.dispatch(ROAMING_ACT_CANCEL_SUCCESS, response.data))
    } catch (error) {
        EventBus.dispatch(ROAMING_ACT_CANCEL_FAILED, error);
    }
}

export const syncActAsync = (id) => {
    EventBus.dispatch(ROAMING_ACT_SYNC_REQUESTED, id);
    return new Promise((resolve, reject) => {
        roamingService.syncAct(id)
            .then(response => {
                EventBus.dispatch(ROAMING_ACT_SYNC_SUCCESS);
                resolve(response.data)
            })
            .catch(error => {
                EventBus.dispatch(ROAMING_ACT_SYNC_FAILED)
                reject(error)
            })
    })
}

export default roamingActSlice.reducer
export const {setId, setShowModal} = roamingActSlice.actions
