import React, {Fragment, useState} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {loadBankInfo} from "../../../../store/reducers/roaming/roamingReducer";
import {Col, Row, Form, Card, FloatingLabel} from "react-bootstrap";
import IconButton from "../../../IconButton";
import {faPlus, faXmark} from "@fortawesome/free-solid-svg-icons";
import FindCompanySearchable from "../../../organization/FindCompanySearchable";
import classNames from "classnames";
import FindPerson from "../../../edi/attorney/FindPerson";
import IconCancel from "../../../../assets/icon/IconCancel";

const ClientInfo = ({index, addFieldClient, removeFieldClient}) => {
    const {register, getValues, setValue, formState: {errors: clientError}, control} = useFormContext()
    const [companyDetails, setCompanyDetails] = useState({})
    const [bankInfo, setBankInfo] = useState({})
    const errors = clientError.clients || []
    const lang = useSelector(selectLang)
    const t = Translate

    const onCompanyChange = async company => {
        setCompanyDetails(company)
        setValue(`clients.${index}.name`, company?.name)
        setValue(`clients.${index}.fizTin`, (company?.director_pinfl || company?.director_inn) || company?.person_num)
        setValue(`clients.${index}.fio`, company?.director_pinfl ? company?.director : company?.name)
        setValue(`clients.${index}.address`, company?.address || "")
        setValue(`clients.${index}.bankId`, company?.mfo || "")
        setValue(`clients.${index}.account`, company?.account || "")

        if (company?.mfo)
            setBankInfo(await loadBankInfo(company.mfo, lang))
        else
            setBankInfo({})
    }

    const onCompanyDirectorChange = (director) => {
        if (director) {
            setValue(`clients.${index}.fizTin`, director.pinfl || director.inn)
            setValue(`clients.${index}.fio`, director.name)
        } else {
            setValue(`clients.${index}.fizTin`, '')
            setValue(`clients.${index}.fio`, '')
        }
    }

    return (
        <Fragment>
            <Row className={'align-items-center'}>
                <Col>
                    <h5 className="ms-3 mb-0 fw-medium fs-9 second-text">{t(lang, "roaming.contracts.add.clients_info.title")}</h5>
                </Col>
                <Col className="text-end">
                    <IconButton
                        onClick={() => removeFieldClient(index)}
                        disabled={getValues('clients').length <= 1}
                        variant="phoenix-danger"
                        size="md"
                        className="me-3 mb-2 fw-semi-bold p-0 border-0"
                    >
                        <IconCancel width={15} height={15} />
                    </IconButton>
                </Col>
            </Row>
            <Card className={'p-2 rounded-4 border-0'}>
                <Row className={'flex-column gap-2'}>
                    {companyDetails?.branch &&
                        <Col sm="12">
                            <FloatingLabel label={t(lang, "roaming.contracts.add.clients_info.branch")}>
                                <Form.Control placeholder={t(lang, "roaming.contracts.add.clients_info.branch")}/>
                            </FloatingLabel>
                        </Col>
                    }
                    <Col sm={12}>
                        <Row>
                            <Col sm="6">
                                <FloatingLabel label={t(lang, "roaming.contracts.add.clients_info.name")}>
                                    <Form.Control
                                        {...register(`clients.${index}.name`, {
                                            // required: "edi.common.forms.validations.is_required"
                                        })}
                                        className={errors[index]?.name && 'is-invalid'}
                                        placeholder={t(lang, "roaming.contracts.add.clients_info.name")}
                                    />
                                </FloatingLabel>
                                <Form.Control.Feedback type="invalid">
                                    {t(lang, errors[index]?.name?.message)}
                                </Form.Control.Feedback>
                            </Col>
                            <Col sm="6">
                                <Controller
                                    name={`clients.${index}.tin`}
                                    control={control}
                                    rules={{
                                        required: "edi.common.forms.validations.is_required"
                                    }}
                                    render={({field}) => (
                                        <FindCompanySearchable
                                            id={`clients.${index}.tin`}
                                            placeholder={t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl")}
                                            onChange={(company) => {
                                                field.onChange(company?.person_num || company?.inn)
                                                onCompanyChange(company)
                                            }}
                                            defaultInn={field.value}
                                            inputProps={{
                                                isInvalid: errors[index]?.tin,
                                            }}
                                            inputGroupProps={{
                                                className: classNames('select',{
                                                    'is-invalid': errors[index]?.tin,
                                                })
                                            }}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t(lang, errors[index]?.tin?.message)}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={12}>
                        <Row>
                            <Col sm="6">
                                <FloatingLabel label={t(lang, "roaming.contracts.add.clients_info.full_name_person")}>
                                    <Form.Control
                                        {...register(`clients.${index}.fio`, {
                                            required: "",
                                        })}
                                        className={errors[index]?.fio && 'is-invalid'}
                                        placeholder={t(lang, "roaming.contracts.add.clients_info.full_name_person")}
                                    />
                                </FloatingLabel>
                                <Form.Control.Feedback type="invalid">
                                    {t(lang, errors[index]?.fio?.message)}
                                </Form.Control.Feedback>
                            </Col>
                            <Col sm="6">
                                <Controller
                                    name={`clients.${index}.fizTin`}
                                    control={control}
                                    rules={{
                                        required: "edi.common.forms.validations.is_required"
                                    }}
                                    render={({field}) => (
                                        <FindPerson
                                            placeholder={t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl_person")}
                                            onChange={onCompanyDirectorChange}
                                            defaultInnOrPinfl={field.value}
                                            inputProps={{
                                                className: errors[index]?.fizTin && 'is-invalid',
                                            }}
                                            inputGroupProps={{
                                                className: errors[index]?.fizTin && 'is-invalid'
                                            }}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t(lang, errors[index]?.fizTin?.message)}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={12}>
                        <FloatingLabel label={t(lang, "roaming.contracts.add.clients_info.address")}>
                            <Form.Control {...register(`clients.${index}.address`)}
                                          placeholder={t(lang, "roaming.contracts.add.clients_info.address")}/>
                        </FloatingLabel>
                    </Col>

                    <Col sm={12}>
                        <Row>
                            <Col sm="4">
                                <FloatingLabel label={t(lang, "roaming.contracts.add.clients_info.bank_account")}>
                                    <Form.Control {...register(`clients.${index}.account`)}
                                                  placeholder={t(lang, "roaming.contracts.add.clients_info.bank_account")}/>
                                </FloatingLabel>
                            </Col>
                            <Col sm="5">
                                <FloatingLabel label={t(lang, "roaming.contracts.add.clients_info.bank_address")}>
                                    <Form.Control defaultValue={bankInfo?.name}
                                                  placeholder={t(lang, "roaming.contracts.add.clients_info.bank_address")}/>
                                </FloatingLabel>
                            </Col>
                            <Col sm="3">
                                <FloatingLabel label={t(lang, "roaming.contracts.add.clients_info.mfo")}>
                                    <Form.Control {...register(`clients.${index}.bankId`)}
                                                  placeholder={t(lang, "roaming.contracts.add.clients_info.mfo")}/>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={12}>
                        <Row>
                            <Col sm="6">
                                <FloatingLabel label={t(lang, "roaming.contracts.add.clients_info.work_phone_number")}>
                                    <Form.Control
                                        placeholder={t(lang, "roaming.contracts.add.clients_info.work_phone_number")}/>
                                </FloatingLabel>
                            </Col>
                            <Col sm="6">
                                <FloatingLabel label={t(lang, "roaming.contracts.add.clients_info.mobile_number")}>
                                    <Form.Control
                                        placeholder={t(lang, "roaming.contracts.add.clients_info.mobile_number")}/>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </Fragment>
    );
};

export default ClientInfo;
