import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import {selectCurrentCertificate} from "../../../../store/reducers/eimzo/eimzoReducer";
import {
    loadCompanyProductCatalogsAsync, loadMeasurementsAsync,
    selectCompanyCatalogs
} from "../../../../store/reducers/roaming/roamingReducer";
import {selectLang, updateShowInProgressAsync} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import EventBus from "../../../../eventbus/EventBus";
import {
    ROAMING_ACT_DRAFT_FAILED,
    ROAMING_ACT_DRAFT_REQUESTED, ROAMING_ACT_DRAFT_SUCCESS,
    ROAMING_ACT_REGISTER_FAILED,
    ROAMING_ACT_REGISTER_REQUESTED,
    ROAMING_ACT_REGISTER_SUCCESS
} from "../../../../eventbus/romaing/act/roamingActEvents";
import toast from "react-hot-toast";
import {getOrganizationBillingInfoAsync} from "../../../../store/reducers/billing/billingReducer";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import {ActFormSendAsync, ActFormSendDraft} from "../../../../store/reducers/roaming/act/actReducer";
import {Form} from "react-bootstrap";
import ActFormInfo from "./ActFormInfo";
import ActFormOwnerBuyer from "./ActFormOwnerBuyer";
import ActFormDataTable from "./ActFormDataTable";

const DraftActForm = ({draftAct}) => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const catalogs = useSelector(selectCompanyCatalogs)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const lang = useSelector(selectLang);
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            act: {
                info: draftAct?.ActDoc?.ActNo,
                date: dayjs(draftAct?.ActDoc?.ActDate, 'YYYY-MM-DD').toDate(),
            },
            contract: {
                number: draftAct?.ContractDoc?.ContractNo,
                date: dayjs(draftAct?.ContractDoc?.ContractDate, 'YYYY-MM-DD').toDate(),
            },
            ActComment: draftAct?.ActDoc?.ActText,
            ActSeller: {
                inn: draftAct?.SellerTin,
                name: draftAct?.SellerName,
                pinfl: draftAct?.SellerTin,
            },
            SellerTin: draftAct?.SellerTin,
            SellerName: draftAct?.SellerName,
            SellerBranchCode: draftAct?.SellerBranchCode,
            SellerBranchName: draftAct?.SellerBranchName,
            BuyerBranchName: draftAct?.BuyerBranchName,
            BuyerBranchCode: draftAct?.BuyerBranchCode,
            ActBuyer: {
                inn: draftAct?.BuyerTin,
                name: draftAct?.BuyerName,
                pinfl: draftAct?.BuyerTin,
            },
            itemAct: draftAct?.ProductList?.Products.map(item => {
                const catalog = catalogs?.find(c => c?.class_code === item?.CatalogCode)
                const packages = catalog?.package_names.find(p => p?.code === +item?.PackageCode)


                return  {
                    catalogClassCode: item?.CatalogCode,
                    catalogClassName: item?.CatalogName,
                    Items: item?.Name,
                    measurementId: item.MeasureId,
                    packageCode: +item.PackageCode,
                    packageName: item.PackageName,
                    catalog: catalog,
                    packages: packages,
                    quantity: item?.Count,
                    price: item?.Summa,
                    totalSum: item?.TotalSum,
                }
            })
        },
    });


    useEffect( () => {
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization.inn, lang));
        dispatch(loadMeasurementsAsync(lang));

        methods.setValue('ActSeller', {
            name: activeOrganization?.name || '',
            inn: activeOrganization?.inn || '',
            pinfl: activeOrganization?.pinfl || '',
        });

        methods.setValue('ActBuyer', {
            inn: draftAct?.BuyerTin,
            name: draftAct?.BuyerName,
            pinfl: draftAct?.BuyerTin,
        })

    }, [activeOrganization])

    useEffect(() => {
        const onSendActRequestedHandler = EventBus.on(ROAMING_ACT_REGISTER_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onSendActSuccessHandler = EventBus.on(ROAMING_ACT_REGISTER_SUCCESS, () => {
            toast.success(t(lang, "edi.common.toast.success"))
            dispatch(getOrganizationBillingInfoAsync());
            navigate(`/roaming/act/${draftAct.ActId}`)
            dispatch(updateShowInProgressAsync(false))
        })

        const onSendActFailedHandler = EventBus.on(ROAMING_ACT_REGISTER_FAILED, () => {
            toast.error(t(lang, "edi.common.toast.error"))
            dispatch(updateShowInProgressAsync(false))
        })

        const onDraftSendActRequestedHandler = EventBus.on(ROAMING_ACT_DRAFT_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onDraftSendActSuccessHandler = EventBus.on(ROAMING_ACT_DRAFT_SUCCESS, () => {
            toast.success(t(lang, "roaming.act_form_save_success"))
            navigate(`/roaming/draft-act/${draftAct.ActId}`)
            dispatch(updateShowInProgressAsync(false))
        })

        const onDraftSendActFailedHandler = EventBus.on(ROAMING_ACT_DRAFT_FAILED, () => {
            toast.error(t(lang, "roaming.act_form_save_error"))
            dispatch(updateShowInProgressAsync(false))
        })

        return () => {
            EventBus.remove(ROAMING_ACT_REGISTER_REQUESTED, onSendActRequestedHandler)
            EventBus.remove(ROAMING_ACT_REGISTER_SUCCESS, onSendActSuccessHandler)
            EventBus.remove(ROAMING_ACT_REGISTER_FAILED, onSendActFailedHandler)
            EventBus.remove(ROAMING_ACT_DRAFT_REQUESTED, onDraftSendActRequestedHandler)
            EventBus.remove(ROAMING_ACT_DRAFT_SUCCESS, onDraftSendActSuccessHandler)
            EventBus.remove(ROAMING_ACT_DRAFT_FAILED, onDraftSendActFailedHandler)
        }

    },[])

    const onSubmit = async (formData, event) => {
        const buttonType = event.nativeEvent.submitter.name

        const ActFormSend = JSON.stringify({
            ActId: draftAct.ActId,
            ActDoc: {
                ActNo: formData.act.info,
                ActDate: dayjs(formData.act.date).format('YYYY-MM-DD'),
                ActText: formData.ActComment,
            },
            ContractDoc: {
                ContractNo: formData.contract.number,
                ContractDate: dayjs(formData.contract.date).format('YYYY-MM-DD'),
            },
            SellerTin: formData.ActSeller.pinfl || formData.ActSeller.inn,
            SellerName: formData.ActSeller.name,
            SellerBranchCode: formData.SellerBranchCode,
            SellerBranchName: formData.SellerBranchName,
            BuyerTin: formData.ActBuyer.pinfl || formData.ActBuyer.inn,
            BuyerName: formData.ActBuyer.name,
            BuyerBranchName: formData.BuyerBranchName,
            BuyerBranchCode: formData.BuyerBranchCode,
            ProductList: {
                ActProductId: draftAct.ProductList.ActProductId,
                Tin: formData.ActSeller.pinfl || formData.ActSeller.inn,
                Products: formData.itemAct.map((item, index) => {
                    return {
                        OrdNo: index + 1,
                        Name: item.Items,
                        CatalogCode: item.catalogClassCode,
                        CatalogName: item.catalogClassName,
                        MeasureId: item.measurementId,
                        PackageCode: item.packageCode ? `${item.packageCode}` : null,
                        PackageName: item.packageName,
                        Count: item.quantity,
                        Summa: item.price,
                        TotalSum: item.totalSum,
                    }
                })
            },
        })


        if(buttonType === "send") {
            if(currentCertificate?.keyId) {
                await ActFormSendAsync(currentCertificate, lang, ActFormSend, draftAct.ActId, formData.ActSeller.pinfl || formData.ActSeller.inn)
            }
        } else if(buttonType === "draft") {
            await ActFormSendDraft(ActFormSend)
        }
    };


    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)} id="ActForm">
                <ActFormInfo/>
                <ActFormOwnerBuyer/>
                <ActFormDataTable/>
            </Form>
        </FormProvider>
    );
};

export default DraftActForm;
