import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {selectLang} from "../../../../store/reducers/main/main";
import {useFieldArray} from "react-hook-form";
import IconButton from "../../../IconButton";
import Flex from "../../../common/Flex";
import {Button, Spinner, Table} from "react-bootstrap";
import Item from "./Item";

const AttorneyItems = ({root, loading}) => {

    const lang = useSelector(selectLang);
    const t = Translate;

    const [allowDeleteItems, setAllowDeleteItems] = useState(false);
    const {append, fields, remove} = useFieldArray({name: root});

    const addNewItem = () => {
        const item = {
            product: "",
            measurementId: "",
            quantity: "",
            catalogClassCode: "",
            catalogClassName: "",
        };
        append(item);
    };

    const onDeleteItem = index => remove(index);

    useEffect(() => {
        setAllowDeleteItems(fields.length > 1);
    }, [fields]);

    return (
        <Fragment>
            <Flex justifyContent="between" alignItems="center">
                <h4>{t(lang, "edi.common.items")}</h4>
                <IconButton variant="falcon-default" size="sm" icon="plus" transform="shrink-3" className="me-2"
                            onClick={() => addNewItem()}>
                                <span className="d-none d-sm-inline-block ms-1">
                                    {t(lang, "edi.common.add_button")}
                                </span>
                </IconButton>
            </Flex>

            <Table bordered responsive className="border-bottom fs--1 border-500 mt-2 mb-0">
                <thead className="text-dark bg-200">
                <tr>
                    <th className="fw-semi-bold text-center align-middle">#</th>
                    <th className="fw-semi-bold text-center align-middle">{t(lang, "edi.common.items.product")}</th>
                    <th className="fw-semi-bold text-center align-middle">{t(lang, "edi.common.items.product.measurement")}</th>
                    <th className="fw-semi-bold text-center align-middle">{t(lang, "edi.common.items.product.quantity")}</th>
                    <th className="fw-semi-bold text-center align-middle">{t(lang, "edi.common.items.product.quantity_in_words")}</th>
                    <th className="fw-semi-bold text-center align-middle"></th>
                </tr>
                </thead>
                <tbody>
                {fields.map((item, index) => (
                    <Item key={item.id} root={root} index={index} allowDelete={allowDeleteItems} onDeleteClicked={onDeleteItem}/>
                ))}
                </tbody>
            </Table>

            <div className="d-flex justify-content-end mt-3">
                <Button type="submit" disabled={loading}>
                    {loading &&
                        <Spinner className="align-middle me-2" animation="border" role="switch"
                                 size="sm"/>}
                    {t(lang, "edi.common.button.create")}
                </Button>
            </div>

        </Fragment>
    );
};

export default AttorneyItems;
