import {createSlice} from "@reduxjs/toolkit";
import ContractFormationService from "../../../services/edi/contract-formation/contractFormationService";
import {signDocAndGetRoamingSignature} from "../../roaming/roamingReducer";
import {
    REGISTER_CONTRACT_FORMATION_FAILED,
    REGISTER_CONTRACT_FORMATION_REQUESTED,
    REGISTER_CONTRACT_FORMATION_SUCCESS,
    SEND_CONTRACT_FORMATION_FAILED,
    SEND_CONTRACT_FORMATION_REQUESTED,
    SEND_CONTRACT_FORMATION_SUCCESS,
    SIGN_CONTRACT_FORMATION_FAILED,
    SIGN_CONTRACT_FORMATION_REQUESTED,
    SIGN_CONTRACT_FORMATION_SUCCESS,
    UPDATE_CONTRACT_FORMATION_FAILED,
    UPDATE_CONTRACT_FORMATION_REQUESTED,
    UPDATE_CONTRACT_FORMATION_SUCCESS,
    UPDATE_ITEM_CONTRACT_FORMATION_FAILED,
    UPDATE_ITEM_CONTRACT_FORMATION_REQUESTED,
    UPDATE_ITEM_CONTRACT_FORMATION_SUCCESS
} from "../../../../eventbus/edi/contract-formation/contractFormationEvents";
import EventBus from "../../../../eventbus/EventBus";

const STORAGE_IMPORT_DETAILS = 'importDetailsContractFormation';
const STORAGE_IMPORT_SKIP_ROWS = 'skipRows';
const STORAGE_IMPORT_NAME_COLUMN_INDEX = 'nameColumnIndex';
const STORAGE_IMPORT_CATALOG_CODE_COLUMN_INDEX = 'catalogCodeColumnIndex';
const STORAGE_IMPORT_MEASUREMENT_OR_PACKAGES_COLUMN_INDEX = 'measurementOrPackagesColumnIndex';
const STORAGE_IMPORT_BARCODE_COLUMN_INDEX = 'barcodeColumnIndex';
const STORAGE_IMPORT_QUANTITY_COLUMN_INDEX = 'quantityColumnIndex';
const STORAGE_IMPORT_PRICE_COLUMN_INDEX = 'priceColumnIndex';
const STORAGE_IMPORT_NDS_RATE_COLUMN_INDEX = 'ndsRateColumnIndex';
export const STORAGE_CONTRACT_FORMATION = "ContractFormationDataTableSettings"

export const contractFormationSlice = createSlice({
    name: 'contractFormation',
    initialState: {
        contracts: [],
        filterOptions: {page: 1, limit: 10},
        countContract: 0,
        contractFormationExcelOption: {
            skipRows: 0,
            nameColumnIndex: 0,
            catalogCodeColumnIndex: 1,
            measurementOrPackagesColumnIndex: 2,
            barcodeColumnIndex: 3,
            quantityColumnIndex: 4,
            priceColumnIndex: 5,
            ndsRateColumnIndex: 6,
        },
        contractFormationDataTableSettings: {}
    },
    reducers: {
        setContracts: (state, action) => {
            state.contracts = action.payload
        },
        updateFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
        updateCountContract: (state, action) => {
            state.countContract = action.payload
        },
        loadContractFormationExcel: (state) => {
            const storage = JSON.parse(localStorage.getItem(STORAGE_IMPORT_DETAILS));

            if (storage?.[STORAGE_IMPORT_SKIP_ROWS]) {
                state.contractFormationExcelOption.skipRows = storage[STORAGE_IMPORT_SKIP_ROWS]
            }

            if (storage?.[STORAGE_IMPORT_NAME_COLUMN_INDEX]) {
                state.contractFormationExcelOption.nameColumnIndex = storage[STORAGE_IMPORT_NAME_COLUMN_INDEX]
            }

            if (storage?.[STORAGE_IMPORT_CATALOG_CODE_COLUMN_INDEX]) {
                state.contractFormationExcelOption.catalogCodeColumnIndex = storage[STORAGE_IMPORT_CATALOG_CODE_COLUMN_INDEX]
            }

            if (storage?.[STORAGE_IMPORT_MEASUREMENT_OR_PACKAGES_COLUMN_INDEX]) {
                state.contractFormationExcelOption.measurementOrPackagesColumnIndex = storage[STORAGE_IMPORT_MEASUREMENT_OR_PACKAGES_COLUMN_INDEX]
            }

            if (storage?.[STORAGE_IMPORT_BARCODE_COLUMN_INDEX]) {
                state.contractFormationExcelOption.barcodeColumnIndex = storage[STORAGE_IMPORT_BARCODE_COLUMN_INDEX]
            }

            if (storage?.[STORAGE_IMPORT_QUANTITY_COLUMN_INDEX]) {
                state.contractFormationExcelOption.quantityColumnIndex = storage[STORAGE_IMPORT_QUANTITY_COLUMN_INDEX]
            }

            if (storage?.[STORAGE_IMPORT_PRICE_COLUMN_INDEX]) {
                state.contractFormationExcelOption.priceColumnIndex = storage[STORAGE_IMPORT_PRICE_COLUMN_INDEX]
            }

            if (storage?.[STORAGE_IMPORT_NDS_RATE_COLUMN_INDEX]) {
                state.contractFormationExcelOption.ndsRateColumnIndex = storage[STORAGE_IMPORT_NDS_RATE_COLUMN_INDEX]
            }
        },
        saveImportDetailsExcel: (state, action) => {
            const data = {
                skipRows: action.payload.skipRows,
                nameColumnIndex: action.payload.nameColumnIndex,
                catalogCodeColumnIndex: action.payload.catalogCodeColumnIndex,
                measurementOrPackagesColumnIndex: action.payload.measurementOrPackagesColumnIndex,
                barcodeColumnIndex: action.payload.barcodeColumnIndex,
                quantityColumnIndex: action.payload.quantityColumnIndex,
                priceColumnIndex: action.payload.priceColumnIndex,
                ndsRateColumnIndex: action.payload.ndsRateColumnIndex,
            };
            localStorage.setItem(STORAGE_IMPORT_DETAILS, JSON.stringify(data));
        },
        changeContractFormationExcel: (state,action) => {
            state.contractFormationExcelOption = action.payload
        },

        loadContractFormationDataTableColumn: (state) => {
            const defaultColumns = {
                index: true,
                name: true,
                contractor: true,
                status: true,
                uid: false,
                created_date: true,
                total_sum: true,
                button_action: true
            };
            let storageColumn = JSON.parse(localStorage.getItem(STORAGE_CONTRACT_FORMATION))
            if(!storageColumn) {
                storageColumn = defaultColumns
                localStorage.setItem(STORAGE_CONTRACT_FORMATION, JSON.stringify(storageColumn))
            } else {
                storageColumn = {...defaultColumns, ...storageColumn}
            }
            state.contractFormationDataTableSettings = storageColumn
        },

        changeContractFormationDataTableColumn: (state, action) => {
            state.contractFormationDataTableSettings = action.payload
            localStorage.setItem(STORAGE_CONTRACT_FORMATION, JSON.stringify(state.contractFormationDataTableSettings))
        },
    }
})


export const loadContractsAsync = ({page, limit, ...filters}) => async (dispatch) => {
    try {
        const skip = (page - 1) * limit
        const params = {skip, limit, ...filters}

        let contractFormation = (await ContractFormationService.getContractFormationAll(params))
        dispatch(contractFormationSlice.actions.setContracts(contractFormation.data.documents))
    } catch (error) {
        console.log(error)
    }
}



export const loadContractsCountAsync = (params) => (dispatch) => {
    let { limit, page, ...others} = params
    ContractFormationService.getContractFormationCount(others)
        .then(response => {
            dispatch(contractFormationSlice.actions.updateCountContract(response.data.count))
        })
        .catch(error => {
            console.log(error);
        })
}

export const changeFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(contractFormationSlice.actions.updateFilterOptions({...params}))
}

export const loadContractOneAsync = (id) => {
    return new Promise((resolve,reject) => {
        ContractFormationService.getContractFormationOne(id)
            .then(response => resolve(response))
            .catch(error => reject(error))
    })
}

export const registerContractFormationAsync = (ContractSend) => {
    EventBus.dispatch(REGISTER_CONTRACT_FORMATION_REQUESTED)

    return new Promise(() => {
        ContractFormationService.contractFormationRegister(ContractSend)
            .then(response => EventBus.dispatch(REGISTER_CONTRACT_FORMATION_SUCCESS, response))
            .catch((error) => {
                EventBus.dispatch(REGISTER_CONTRACT_FORMATION_FAILED, error.response.data)
                window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
            })
    })
}

export const updateContractFormationAsync = ({id, editContract}) => {
    EventBus.dispatch(UPDATE_CONTRACT_FORMATION_REQUESTED)

    return new Promise(() => {
        ContractFormationService.contractFormationUpdate({id, editContract})
            .then(response => {
                EventBus.dispatch(UPDATE_CONTRACT_FORMATION_SUCCESS, response.data.contract)
            })
            .catch((error) => {
                EventBus.dispatch(UPDATE_CONTRACT_FORMATION_FAILED, error.response.data)
                window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
            })
    })
}

export const updateItemContractFormationAsync = ({id, updateItem}) => {
    EventBus.dispatch(UPDATE_ITEM_CONTRACT_FORMATION_REQUESTED)
    return new Promise((resolve, reject) => {
        ContractFormationService.contractFormationUpdateItem({id, updateItem})
            .then(response => {
                EventBus.dispatch(UPDATE_ITEM_CONTRACT_FORMATION_SUCCESS, response.data)
                resolve(response.data)
            })
            .catch(error => {
                EventBus.dispatch(UPDATE_ITEM_CONTRACT_FORMATION_FAILED, error.response.data)
                reject(error.response.data)
            })
    })
}

export const sendContractFormationAsync = ({id,contractdata}) => {
    EventBus.dispatch(SEND_CONTRACT_FORMATION_REQUESTED)

    return new Promise(() => {
        ContractFormationService.contractFormationSend({id, contractdata})
            .then(response => {
                EventBus.dispatch(SEND_CONTRACT_FORMATION_SUCCESS, response.data.contract)
            })
            .catch((error) => {
                EventBus.dispatch(SEND_CONTRACT_FORMATION_FAILED, error.response.data)
                window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
            })
    })
}

export const signContractFormationAsync = async ({certificate, lang, contract}) => {
    try {
        EventBus.dispatch(SIGN_CONTRACT_FORMATION_REQUESTED)

        const hashCode = await ContractFormationService.getContractFormationHashcode({id: contract.id})
        let pkcs7_64 = await signDocAndGetRoamingSignature(hashCode.data, certificate)

        const signContractFormation = await ContractFormationService.contractFormationSign(
            {
                id: contract.id,
                signature: pkcs7_64,
                lang
            })

        EventBus.dispatch(SIGN_CONTRACT_FORMATION_SUCCESS, signContractFormation.data)
        return signContractFormation.data
    }catch (error) {
        EventBus.dispatch(SIGN_CONTRACT_FORMATION_FAILED, error.response.data)
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }
}

export const ContractFormationFileDownload = ({file_id, doc_id}) => {
    return new Promise((resolve, reject) => {
        ContractFormationService.downloadFileContractFormation({file_id: file_id, document_id: doc_id})
            .then(response =>{
                resolve(response.data)
            })
            .catch(error =>{
                reject(error)
            })
    })
}

export const ContractFormationPdfDownload = (uid) => {
    return new Promise((resolve,reject) => {
        ContractFormationService.pdfContractFormation(uid)
            .then(response => {
                resolve(response.data)
            })
            .catch(error =>{
                reject(error)
            })
    })
}

export const changeContractFormationExcelOptions = ({...params}) => dispatch => {
    dispatch(contractFormationSlice.actions.changeContractFormationExcel({...params}))
}

export const selectLoadContractFormationExcel = contractFormationSlice.actions.loadContractFormationExcel
export const selectSaveContractFormationExcel = contractFormationSlice.actions.saveImportDetailsExcel
export const selectContractFormationExcelOptions = state => state.contractFormation.contractFormationExcelOption

export const selectContractsFormation = state => state.contractFormation.contracts
export const selectContractFormationFilterOptions = state => state.contractFormation.filterOptions;
export const selectContractsCount = state => state.contractFormation.countContract


export const selectContractFormationDataTable = state => state.contractFormation.contractFormationDataTableSettings

export const {loadContractFormationDataTableColumn} = contractFormationSlice.actions
export const {changeContractFormationDataTableColumn} = contractFormationSlice.actions


export default contractFormationSlice.reducer;
