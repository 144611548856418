import React from 'react';
import {selectRailwayStation} from "../../../store/reducers/roaming/waybill-v2/romaingWaybillV2Reducer";
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import CustomFloatingLabelSelect from "../../floating-custom-select/CustomFloatingLabelSelect";

const SelectStations = ({defaultId, regionId, placeholder, ...props}) => {

    const lang = useSelector(selectLang);
    const t = Translate;

    const railwayStations = useSelector(selectRailwayStation)

    return (
        <CustomFloatingLabelSelect
            isClearable
            options={regionId ? railwayStations.filter(x => x.region_code === regionId) : railwayStations}
            label={placeholder}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
        />
    );
};

export default SelectStations;
