import IconOutboxFolder from "../../assets/icon/IconOutboxFolder";
import React from "react";
import IconDraftFolder from "../../assets/icon/IconDraftFolder";
import IconInboxFolder from "../../assets/icon/IconInboxFolder";

export const checkViewer = (viewer) => {
    switch (viewer) {
        case "inbox":
            return <IconOutboxFolder/>
        case "outbox":
            return <IconInboxFolder/>
        default :
            return <IconDraftFolder/>
    }
}
