import React, {Fragment} from 'react';
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {UnimportantOption} from "../../../../enum/edi/attorney/AttorneyFieldOptions";
import {Form} from "react-bootstrap";

const ColumnSelector = ({index, columnsOptions, arrayFieldName}) => {
    const columns = useWatch({name: arrayFieldName});
    const rootName = `${arrayFieldName}.${index}`;
    const {setValue} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;

    const onSelectColumn = (selectedOption, onFieldChange) => {
        const alreadySelectedColumnIndex = columns.findIndex(columnKey => columnKey === selectedOption);

        if (alreadySelectedColumnIndex !== -1) {
            const alreadySelectedColumnFieldName = `${arrayFieldName}.${alreadySelectedColumnIndex}`;

            setValue(alreadySelectedColumnFieldName, UnimportantOption.key);
            onFieldChange(selectedOption);
        } else {
            onFieldChange(selectedOption);
        }
    }

    return (
        <Fragment>
            <th className="px-1" key={index} style={{width: "200px"}}>
                <Form.Group>
                    <Controller
                        name={rootName}
                        render={({field}) => (
                            <Form.Select style={{minWidth: "200px"}} value={field.value} onChange={event => onSelectColumn(event.target.value, field.onChange)}>
                                {columnsOptions.map((option, index) => (
                                    <option key={index} value={option.key}>{t(lang, option.label)}</option>
                                ))}
                            </Form.Select>
                        )}
                    />
                </Form.Group>
            </th>
        </Fragment>
    );
};

export default ColumnSelector;
