import React, {useEffect} from 'react';
import {Outlet, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../store/reducers/main/main";
import {selectActiveOrganization} from "../store/reducers/auth/authReducer";
import dayjs from "dayjs";
import EventBus from "../eventbus/EventBus";
import {ACTIVATE_DEMO_SUCCEED} from "../eventbus/billingEvents";
import {loadMeasurementsAsync} from "../store/reducers/roaming/roamingReducer";
import {getOrganizationBillingInfoAsync} from "../store/reducers/billing/billingReducer";
import {loadCompanyCurrentTurnOver} from "../store/reducers/roaming/roamingReferenceReducer";

const RoamingLayout = () => {

    const activeOrganization = useSelector(selectActiveOrganization);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(loadMeasurementsAsync(lang))
    }, [lang]);

    useEffect(() => {
        const activateDemoPeriodHandler = EventBus.on(ACTIVATE_DEMO_SUCCEED, () => {
            dispatch(getOrganizationBillingInfoAsync());
        });

        return () => {
            EventBus.remove(ACTIVATE_DEMO_SUCCEED, activateDemoPeriodHandler);
        }
    }, [])

    useEffect(() => {
        const date = dayjs(new Date())
        dispatch(loadCompanyCurrentTurnOver(date.format('YYYY')))
    }, [activeOrganization])

    /*if (!activeOrganization?.inn) {
       navigate('/')
    }*/

    return (
        <Outlet />
    );
};

export default RoamingLayout;
