import {Translate} from "../lang/lang";
import toast from "react-hot-toast";

export const copyToBuffer =(e,text) => (_, getState) => {
    e.stopPropagation()
    e.preventDefault();
    const lang = getState().main?.settings?.settings?.lang?.find((l) => l?.is_active)?.code || 'ru'
    const t = Translate
    navigator.clipboard.writeText(text)

    return toast.success(t(lang, 'main.common.copied.clipboard'), {
        position: "top-center",
        duration: 5000,
    })
}
