import {createSlice} from "@reduxjs/toolkit";
import itemService from "../../services/item/itemService";

export const draftItemSlice = createSlice({
    name: 'draftItem',
    initialState: {
        draftItems: [],
        count: 0,
        filters: {},
        pagination: {page: 1, limit: 10},
        organization: null,
    },
    reducers: {
        setDraftItems: (state, action) => {
            state.draftItems = action.payload
        },
        setCountDraftItems: (state, action) => {
            state.count = action.payload
        },
        updateFilters: (state, action) => {
            state.filters = action.payload
        },
        updatePagination: (state, action) => {
            state.pagination = action.payload;
        },
        updateOrganization: (state, action) => {
            state.organization = action.payload;
        }
    }
})


export const loadDraftItemsAsync = (filters, pagination) => (dispatch) => {
    let params = {...filters};

    if (pagination) {
        const page = pagination.page;
        const limit = pagination.limit;
        const skip = (page - 1) * limit;

        params = {...filters, skip, limit}
    }

    itemService.getDraftItems(params)
        .then((response) => {
            dispatch(draftItemSlice.actions.setDraftItems(response.data))
        })
        .catch((error) => {
            console.log(error);
            dispatch(draftItemSlice.actions.setDraftItems([]));
        })
}
export const loadCountDraftItemsAsync = (filters) => (dispatch) => {
    const params = {...filters};
    itemService.getCountDraftItems(params)
        .then((response) => {
            dispatch(draftItemSlice.actions.setCountDraftItems(response.data));
        })
        .catch((error) => {
            console.log(error);
            dispatch(draftItemSlice.actions.setCountDraftItems(0));
        })
}

export const loadDraftItemAsync = (id) => {
    return new Promise((resolve, reject) => {
        itemService.getDraftItem(id)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject();
            })
    })
}

export const updateFiltersAsync = (filters) => (dispatch) => {
    dispatch(draftItemSlice.actions.updateFilters(filters));
}

export const approveDraftItemAsync = (payload) => {
    return new Promise((resolve, reject) => {
        itemService.approveDraftItem(payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error.response.data);
            })
    })
}

export const bindDraftItemAsync = (payload) => {
    return new Promise((resolve, reject) => {
        itemService.bindDraftItem(payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            })
    })
}


export const loadOrganizationAsync = () => (dispatch) => {
    itemService.getOrganization()
        .then((response) => {
            dispatch(draftItemSlice.actions.updateOrganization(response.data))
        })
        .catch(() => {
            dispatch(draftItemSlice.actions.updateOrganization(null));
        })
}

export const updateOrganizationAsync = (payload) => {
    return new Promise((resolve, reject) => {
        itemService.updateOrganization(payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
}

export const deleteDraftItemAsync = (id) => {
    return new Promise((resolve, reject) => {
        itemService.deleteDraftItem(id)
            .then(() => {
                resolve();
            })
            .catch((error) => {
                console.log(error);
                reject();
            })
    })
}

export const updatePaginationAsync = (params) => (dispatch) => {
    dispatch(draftItemSlice.actions.updatePagination(params));
}


export const selectDraftItems = state => state.draftItem.draftItems;
export const selectCountDraftItems = state => state.draftItem.count;
export const selectFilters = state => state.draftItem.filters;
export const selectPagination = state => state.draftItem.pagination;
export const selectOrganization = state => state.draftItem.organization;

export default draftItemSlice.reducer;
