import React, {Fragment, useEffect} from 'react';
import DetailsForm from "./DetailsForm";
import DeliveryDetailsForm from "./DeliveryDetailsForm";
import MembersInfoForm from "./MembersInfoForm";
import AdditionalInformation from "./AdditionalInformation";
import WaybillItemsForm from "./WaybillItemsForm";
import IconButton from "../../../IconButton";
import {faBookmark, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {Col, Row, Spinner} from "react-bootstrap";
import SelectCertificateWrapperComponent from "../../../common/SelectCertificateWrapperComponent";
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import IconArrowLeft from "../../../../assets/icon/IconArrowLeft";
import {useNavigate} from "react-router-dom";
import SignActionWithSelectCertificateWrapper from "../../../common/SignActionWithSelectCertificateWrapper";
import {scrollEvent} from "../../../../helpers/scrollEvent";

const WaybillForm = (props) => {
    const navigate = useNavigate()
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('roaming-waybill-form-header'))
        return () => window.removeEventListener('scroll', () => scrollEvent('roaming-waybill-form-header'))
    }, []);

    return (
        <Fragment>

            <Row id={'roaming-waybill-form-header'} className={'my-2 justify-content-between align-items-center position-sticky'} style={{top: '4rem'}}>

                <Col xl={6} sm={12} className={'d-flex align-items-center gap-3'}>
                    <button type={"button"} onClick={() => navigate(-1)} className={'main-text btn-back'} style={{width: 35, height: 35}}>
                        <IconArrowLeft />
                    </button>
                    <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.dashboard.new.waybill.title")}</h5>
                </Col>
                <Col className={'d-flex justify-content-end gap-2'}>
                    <IconButton
                        className={'btn-davr bg-primary-davr-outline'}
                        type="submit"
                        variant="warning"
                        name="save"
                    >
                        {props.isSaving && <Spinner className="me-2" animation="border" role="status" size="sm"/>}
                        {t(lang, 'roaming.common.save')}
                    </IconButton>

                    <SignActionWithSelectCertificateWrapper isForm className={'btn-davr bg-primary-davr gap-1 text-white'}>
                        <IconButton
                            // form="ActForm"
                            className={'btn-davr bg-primary-davr gap-1 text-white'}
                            type="submit"
                            name="send"
                            variant="phoenix-primary"
                        >
                            {props.isSending && <Spinner className="me-2" animation="border" role="status" size="sm"/>}
                            {t(lang, 'roaming.common.send')}
                        </IconButton>
                    </SignActionWithSelectCertificateWrapper>
                </Col>
            </Row>

            <DetailsForm/>
            <DeliveryDetailsForm/>
            <MembersInfoForm/>
            <AdditionalInformation/>
            <WaybillItemsForm />
        </Fragment>
    );
};

export default WaybillForm;
