import React, {Fragment, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {selectAccountRolePermissions} from "../../../store/reducers/role/roleReducer";
import checkPermission from "../../../enum/permission/CheckPermission";
import {PermissionRoamingActView} from "../../../enum/permission/RoamingPermission";
import {loadActDraftAsync} from "../../../store/reducers/roaming/act/actReducer";
import dayjs from "dayjs";
import SpinnerComponent from "../../../components/common/SpinnerComponent";
import Act from "../../../components/roaming/act/view/Act";

const DraftActView = ({modalId}) => {
    const [draftAct, setDraftAct] = useState(null);
    const activeOrganization = useSelector(selectActiveOrganization)
    const {id} = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingActView))) {
            navigate(-1)
        }
    }, [permission])

    useEffect(() => {
        loadDraftAct()
    }, [modalId, id, activeOrganization]);


    const loadDraftAct = async () => {
        try {
            const  getDraftAct = await loadActDraftAsync(modalId ? modalId : id)
            setDraftAct(JSON.parse(getDraftAct?.data?.content))

        }catch (errors) {
            navigate("../acts/draft")
        }
    }

    const act = {
        id: draftAct?.ActId,
        info: {
            number: draftAct?.ActDoc?.ActNo,
            date: dayjs(draftAct?.ActDoc?.ActDate).format('YYYY-MM-DD'),
            text: draftAct?.ActDoc?.ActText,
        },
        contract: {
            number: draftAct?.ContractDoc?.ContractNo,
            date: dayjs(draftAct?.ContractDoc?.ContractDate).format('YYYY-MM-DD'),
        },
        executor: {
            inn: draftAct?.SellerTin,
            name: draftAct?.SellerName,
            branch: {
                branch_code: draftAct?.SellerBranchCode,
                branch_name: draftAct?.SellerBranchName,
            }
        },
        customer: {
            inn: draftAct?.BuyerTin,
            name: draftAct?.BuyerName,
            branch: {
                branch_code: draftAct?.BuyerBranchCode,
                branch_name: draftAct?.BuyerBranchName,
            }
        },
        items: draftAct?.ProductList?.Products?.map(item => {
            return {
                catalog: {
                    code: item?.CatalogCode,
                    name: item?.CatalogName,
                },
                measure_id: item.MeasureId,
                packageCode: item.PackageCode || null,
                packageName: item.PackageName || null,
                name: item?.Name,
                ordering_number: item?.OrdNo,
                price: item?.Summa,
                quantity: item?.Count,
                total: item?.TotalSum,
            }
        }),
        product_id: draftAct?.ActProductId,
    }

    return (
        <Fragment>
            {draftAct && <Act modalId={modalId} act={act} draftAct={draftAct}/>}
            {!draftAct && <SpinnerComponent />}
        </Fragment>
    );
};

export default DraftActView;
