import React, {Fragment, useEffect, useState} from 'react';
import {Button, OverlayTrigger, Tooltip, Modal, Form, Row, Col} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {selectMeasurements} from "../../store/reducers/roaming/roamingReducer";
import {Translate} from "../../lang/lang";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../IconButton";
import Divider from "./Divider";
import ReactSelect from "react-select";
import FalconCloseButton from "./FalconCloseButton";
import CustomFloatingLabelSelect from "../floating-custom-select/CustomFloatingLabelSelect";

const MeasurementLinkSelector = ({onChange, defaultMeasurement, linkProps}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const measurements = useSelector(selectMeasurements);
    const [show, setShow] = useState(false);
    const [measurement, setMeasurement] = useState(null);

    useEffect(() => {
        if (defaultMeasurement?.measureId !== measurement?.measureId)
            setMeasurement(defaultMeasurement);
    }, [defaultMeasurement, measurements])

    const onChangeHandler = (option) => {
        setMeasurement(option);
        onChange(option);
        option && setShow(false);
    }

    const link = <span onClick={() => setShow(true)}
                       className="text-danger cursor-pointer fs--1"
                       {...linkProps}
    >
        {measurement ? `${measurement.measureId}` : t(lang, 'items.common.roaming_measurement')}
    </span>

    return (
        <Fragment>
            {measurement && <OverlayTrigger overlay={<Tooltip>{measurement.name}</Tooltip>}>
                {link}
            </OverlayTrigger>}
            {!measurement && link}

            <Modal show={show}
                   className={'davr-modal'}
                   onHide={() => setShow(false)}
                   size="xl"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <Modal.Header>
                    <Modal.Title>{t(lang, 'items.common.measurement_link_selector_modal.title')}</Modal.Title>
                    <FalconCloseButton onClick={() => setShow(false)}/>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <Form.Group>
                                <CustomFloatingLabelSelect
                                    options={measurements}
                                    label={t(lang, 'items.common.roaming_measurement')}
                                    isClearable
                                    hideSelectedOptions
                                    defaultMenuIsOpen={!measurement}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.measureId}
                                    placeholder={t(lang, "items.common.select")}
                                    value={null}
                                    onChange={onChangeHandler}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {measurement &&
                        <>
                            <Divider/>
                            <Row>
                                <Col xs={12}>
                                    <h5 className="text-info">{t(lang, 'items.common.measurement_link_selector.chosen_measurement')}</h5>
                                    <div className="mt-3 fw-bold fs--1">
                                        <span className="text-primary">{measurement.measureId}</span> |
                                        <span className="text-dark">{measurement.name}</span>
                                        <IconButton
                                            variant="phoenix-secondary"
                                            size="sm"
                                            icon={faTrash}
                                            iconClassName={"text-danger"}
                                            className="ms-2 fw-semi-bold"
                                            title={t(lang, 'items.common.delete')}
                                            onClick={() => onChangeHandler(null)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button className={'btn-primary-davr'} onClick={() => setShow(false)}>
                        {t(lang, 'items.common.cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default MeasurementLinkSelector;
