import React, {useEffect, useState} from 'react';
import ReactSelect from "react-select";
import {Translate} from "../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import CustomFloatingLabelSelect from "../floating-custom-select/CustomFloatingLabelSelect";

const SelectDeliveryType = ({defaultType, options, onChange, selectProps, placeholder, id, ...props}) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const [value, setValue] = useState(null);

    useEffect(() => {
        setValue(options.find(o => o.value === defaultType))
    }, [defaultType]);

    const handleChange = (option) => {
        onChange(option)
    }

    return (
            <CustomFloatingLabelSelect
                value={value}
                label={placeholder}
                hideSelectedOptions={true}
                options={options}
                getOptionValue={option => option.value}
                getOptionLabel={option => t(lang, option.label)}
                onChange={handleChange}
                {...props}
            />
    );
};

export default SelectDeliveryType;
