export const PermissionSystemRoleView = 1
export const PermissionSystemRoleCreate = 2
export const PermissionSystemRoleUpdate = 3
export const PermissionSystemRoleDelete = 4
export const PermissionSystemRoleBindAccount = 5

export const AnotherDefaultPermission = [
    {
        text: 'hippo.user.settings.roles',
        permissions: [
            {
                text: 'system.permission.role.view',
                value: PermissionSystemRoleView
            },
            {
                text: 'system.permission.role.create',
                value: PermissionSystemRoleCreate
            },
            {
                text: 'system.permission.role.update',
                value: PermissionSystemRoleUpdate
            },
            {
                text: 'system.permission.role.delete',
                value: PermissionSystemRoleDelete
            },
            {
                text: 'system.permission.role.bind_to_account',
                value: PermissionSystemRoleBindAccount
            },
        ]
    }
]
