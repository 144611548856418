import React from 'react';

const IconAppStore = ({width = 18, height = 18, color = "none"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 17 20" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12.36 0.276855C12.4866 1.42708 12.0307 2.56054 11.3645 3.39368C10.6694 4.21385 9.55725 4.84118 8.4741 4.76038C8.33232 3.6536 8.88114 2.47669 9.49551 1.75866C10.1899 0.94382 11.3958 0.319541 12.36 0.276855ZM15.9059 6.91213C15.785 6.98428 13.7818 8.17991 13.8037 10.6183C13.8289 13.5628 16.3793 14.5362 16.4106 14.5469C16.3953 14.6155 16.0112 15.9517 15.0546 17.3085C14.2557 18.5029 13.4188 19.6707 12.0894 19.692C11.4574 19.7066 11.0307 19.5248 10.586 19.3355C10.1222 19.1379 9.63882 18.9321 8.88266 18.9321C8.08099 18.9321 7.57618 19.1445 7.08933 19.3494C6.66841 19.5266 6.2609 19.6981 5.68656 19.7217C4.42047 19.7682 3.45318 18.4465 2.62539 17.2627C0.970551 14.8457 -0.317643 10.4513 1.4096 7.46103C2.24655 5.99447 3.77256 5.05005 5.40377 5.0249C6.12208 5.01021 6.81101 5.28704 7.41496 5.52971C7.87681 5.71529 8.28896 5.8809 8.62654 5.8809C8.92304 5.8809 9.32362 5.72191 9.7905 5.53661C10.5263 5.24459 11.4267 4.88721 12.344 4.98374C12.9713 5.00203 14.7565 5.23147 15.9083 6.91069L15.9059 6.91213Z"
                  fill="white"/>
        </svg>

    );
};

export default IconAppStore;
