import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {selectCurrentCertificate} from "../../../store/reducers/eimzo/eimzoReducer";
import {
    selectCountEmpowerments,
    changeFilterOptionsAsync
} from "../../../store/reducers/roaming/empowerment/empowermentReducer";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {Col, Collapse, Form, Row} from "react-bootstrap";
import Badge from "../../common/Badge";
import EmpowermentDataTableFilter from "./EmpowermentDataTableFilter";
import IconButton from "../../IconButton";
import {faFilter, faPlus, faRefresh, faSignature, faTrash} from "@fortawesome/free-solid-svg-icons";
import checkPermission from "../../../enum/permission/CheckPermission";
import {PermissionRoamingEmpowermentCreate} from "../../../enum/permission/RoamingPermission";
import {Link, useNavigate} from "react-router-dom";
import toastPermission from "../../../enum/permission/ToastPermission";
import {VIEWER_DRAFT} from "../../../enum/roaming/empowerment/EmpowermentStatus";
import PropTypes from "prop-types";
import IconFilter from "../../../assets/icon/IconFilter";
import IconStroke from "../../../assets/icon/IconStroke";
import IconCaretDown from "../../../assets/icon/IconCaretDown";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import {scrollEvent} from "../../../helpers/scrollEvent";

const EmpowermentDataTableHeader = ({selectedRowIds, onClickBulkSign, page, viewer}) => {
    const dispatch = useDispatch();
    const methods = useForm();
    const currentCertificate = useSelector(selectCurrentCertificate);
    const empowermentCount = useSelector(selectCountEmpowerments)
    const navigate = useNavigate()

    const lang = useSelector(selectLang);
    const t = Translate;

    const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent("roaming-empowermant-data-table-header"))
        return () => window.removeEventListener('scroll', () => scrollEvent("roaming-empowermant-data-table-header"))
    }, []);

    const onSubmitFilter = ({...filters}) => {
        dispatch(changeFilterOptionsAsync({
            page: 1,
            ...filters
        }))
    }
    const onBulkSign = async () => {
        let selectEmpowerment = Object.keys(selectedRowIds)?.map((key) => {
            let value = selectedRowIds[key]
            if (value) {
                return page[key].original
            }
            return false
        })
        onClickBulkSign(selectEmpowerment)
        return selectEmpowerment
    }

    return (
        <Row id={'roaming-empowermant-data-table-header'}
             className="flex-between-center align-items-center my-3 position-sticky z-index-1" style={{top: '4rem'}}>
            <Col cols={'auto'} className={'d-flex gap-3'}>
                <button onClick={() => navigate(-1)} className={'main-text btn-back'} style={{width: 35, height: 35}}>
                    <IconArrowLeft/>
                </button>
                <div>
                    <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.common.empowerment")}: {empowermentCount}</h5>
                    <span className={'second-text fs-9'}>{t(lang, 'edi.navbar.routes.documents')}</span>
                </div>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                {Object.keys(selectedRowIds).length > 0 ? (
                    <div className="d-flex gap-2">
                        <IconButton
                            onClick={onBulkSign}
                            disabled={!currentCertificate}
                            variant="phoenix-secondary"
                            size="sm"
                            type="button"
                            icon={faSignature}
                            iconClassName="me-1"
                            className="btn-davr ps-2 pe-1 py-0 bg-white text-dark gap-2"
                        >
                            {t(lang, "edi.attorney.datatable.header.navigation.sign")}
                        </IconButton>
                        <IconButton
                            variant="phoenix-secondary"
                            size="sm"
                            type="button"
                            icon={faTrash}
                            iconClassName="me-1"
                            className="btn-davr ps-2 pe-1 py-0 bg-white text-dark gap-2"
                        >{t(lang, "edi.attorney.datatable.header.navigation.delete")}
                        </IconButton>
                    </div>
                ) : (
                    <div className={'d-flex gap-2'} id="attorney-actions">

                        {
                            viewer === VIEWER_DRAFT
                                ?
                                ''
                                :
                                <Fragment>
                                    {/* <IconButton
                                        variant="phoenix-secondary"
                                        size="sm"
                                        transform="shrink-3"
                                        icon={faRefresh}
                                        className="mx-2 fw-semi-bold"
                                        onClick={methods.handleSubmit(onSubmitFilter)}
                                    >
                                    <span className="d-none d-sm-inline-block ms-1 fw-semi-bold">
                                        {t(lang, "edi.orders.order.resume_order_button")}
                                    </span>
                                    </IconButton>*/}
                                    <IconButton
                                        onClick={() => setIsOpenFilterCollapse(!isOpenFilterCollapse)}
                                        className="btn-davr ps-2 pe-1 py-0 bg-white text-dark gap-2"
                                        size="sm"
                                        transform="shrink-3"
                                    >
                                        <div className={'d-flex align-items-center'}>
                                            <IconFilter/>
                                            <span
                                                className="d-none d-sm-inline-block ms-1 fs-8 text-nowrap fs-md-9">{t(lang, "roaming.invoices.table_header.filter")}</span>
                                        </div>
                                        <div className={'d-flex justify-content-between gap-2'}>
                                            <IconStroke/>
                                            <IconCaretDown/>
                                        </div>
                                    </IconButton>
                                </Fragment>
                        }

                        <IconButton
                            as={Link}
                            to="/roaming/empowerment/send"
                            size="sm"
                            icon={faPlus}
                            className="btn-davr px-3 py-0 bg-primary-davr"
                            transform="shrink-3"
                        >
                            <span className="d-none d-sm-inline-block ms-1 fw-semi-bold">
                                {t(lang, "edi.attorney.datatable.header.navigation.create")}
                            </span>
                        </IconButton>

                    </div>
                )}
            </Col>
            <Col sm={12}>
                <Collapse in={isOpenFilterCollapse}>
                    <Col sm={12}>
                        <FormProvider {...methods}>
                            <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                                <EmpowermentDataTableFilter/>
                            </Form>
                        </FormProvider>
                    </Col>
                </Collapse>
            </Col>
        </Row>
    );
};

EmpowermentDataTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};


export default EmpowermentDataTableHeader;
