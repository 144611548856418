import React, {Fragment} from 'react';
import {useFormContext} from "react-hook-form";
import {selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {Card, Form} from "react-bootstrap";
import PropTypes from "prop-types";
import Flex from "../../../common/Flex";

const ForgotPasswordStepThree = ({
                                     footer, header = () => {
    }
                                 }) => {
    const {register, formState: {errors}, watch} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Fragment>
            <div>
                {header()}
                <h4 className={'text-center mb-2 text-dark'}>Новый пароль</h4>
            </div>
            <Flex className={"flex-column justify-content-between scrollbar"} style={{height: "calc(100% - 60px)"}}>
                <div>
                    <Form.Group className="mb-3">
                        <Form.Control
                            type='password'
                            name='password'
                            autoComplete='off'
                            isInvalid={errors.password}
                            className='form-control'
                            placeholder={t(lang, 'authentication.forgot-password.new_password.placeholder')}
                            {...register('password', {
                                required: t(lang, 'edi.common.forms.validations.is_required'),
                            })}
                        />
                        <Form.Control.Feedback
                            type='invalid'>{errors.password && errors.password?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Control
                            type='password'
                            autoComplete='off'
                            name='confirmPassword'
                            isInvalid={errors.confirmPassword}
                            className='form-control'
                            placeholder={t(lang, 'authentication.forgot-password.confirm_password.placeholder')}
                            {...register('confirmPassword', {
                                validate: value => value === watch('password') || t(lang, 'authentication.forgot-password.confirm_password.not_match')
                            })}
                        />
                        <Form.Control.Feedback
                            type='invalid'>{errors.confirmPassword && errors.confirmPassword?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Card className={"position-relative px-6 py-2 my-3"} style={{
                        background: "#FFA5001A",
                        borderColor: "#FFA500",
                        color: "#FFA500"
                    }}>
                        <p className={"m-0 position-absolute"} style={{
                            left: 18,
                            top: 8
                        }}>?</p>
                        <p className={"m-0"}>{`
                    Не менее 8 символов без пробелов,\n
                    Как минимум одна заглавная буква,\n
                    Только латинские буквы,\n
                    Как минимум одна цифра,\n
                    Другие допустимые символы:\n
                    ~ ! ? @ # $ % ^ & * _ - + ( ) [ ] { } > < / \\ | " ' . , : ;
                    `}</p>
                        {/*<p className="m-0">Не менее 8 символов без пробелов,</p>*/}
                        {/*<p className="m-0">Как минимум одна заглавная буква,</p>*/}
                        {/*<p className="m-0">Только латинские буквы,</p>*/}
                        {/*<p className="m-0">Как минимум одна цифра,</p>*/}
                        {/*<p className="m-0">Другие допустимые символы:</p>*/}
                        {/*<p className="m-0">{`~! ? @ # $ % ^ & * _ - + ( ) [ ] {} > < / \\ | " ' . , : ;`}</p>*/}
                    </Card>
                </div>
                {footer}
            </Flex>
        </Fragment>
    );
};

ForgotPasswordStepThree.propTypes = {
    footer: PropTypes.element
}

export default ForgotPasswordStepThree;
