import React, {Fragment, useState} from 'react';
import {Controller, useFieldArray, useFormContext} from "react-hook-form";
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {faPencil, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Button, Col, InputGroup, Modal, Row, Table, Form} from "react-bootstrap";
import IconButton from "../../IconButton";
import classNames from "classnames";
import {
    ForGroup,
    ForTransport,
    ForUsage,
    markPackageOptions
} from "../../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import MarkProductTypeSelector from "./MarkProductTypeSelector";
import MarkPackageTypeSelector from "./MarkPackageTypeSelector";
import FalconCloseButton from "../../common/FalconCloseButton";

const RoamingInvoiceFormItemMarkLinkSelector = ({
                                                    itemFieldName,
                                                    index,
                                                    item
                                                }) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();
    const [show, setShow] = useState(false);

    const {
        fields: kiz,
        append: addKiz,
        remove: removeKiz
    } = useFieldArray({name: `${itemFieldName}.mark.kiz`});
    const {
        fields: nomUpak,
        append: addNomUpak,
        remove: removeNomUpak
    } = useFieldArray({name: `${itemFieldName}.mark.nomUpak`});
    const {
        fields: identTransUpak,
        append: addIdentTransUpak,
        remove: removeIdentTransUpak
    } = useFieldArray({name: `${itemFieldName}.mark.identTransUpak`});

    const addKizItem = () => addKiz({code: ''});
    const addNomUpakItem = () => addNomUpak({code: ''});
    const addIdentTransUpakItem = () => addIdentTransUpak({code: ''});

    const removeKizItem = (index) => removeKiz(index);
    const removeNomUpakItem = (index) => removeNomUpak(index);
    const removeIdentTransUpakItem = (index) => removeIdentTransUpak(index);

    const validateIfIsMarked = () => {
        const values = form.getValues(`${itemFieldName}.mark`);
        if (values.productType) {
            const kizHasAnyMarkCode = values.kiz.some((mark) => !!mark.code);
            const nomUpakHasAnyMarkCode = values.nomUpak.some((mark) => !!mark.code);
            const identTransUpakHasAnyMarkCode = values.identTransUpak.some((mark) => !!mark.code);
            if (kizHasAnyMarkCode || nomUpakHasAnyMarkCode || identTransUpakHasAnyMarkCode) return true;
        }
        return false;
    };
    const isMarked = validateIfIsMarked();

    return (
        <Fragment>
            <IconButton icon={faPencil}
                        variant={isMarked ? 'phoenix-success' : 'phoenix-primary'}
                        style={{height: '40px'}}
                        onClick={() => setShow(true)}
            >
                {isMarked ? t(lang, 'roaming.invoice.items.hasMark') : t(lang, 'roaming.common.select.placeholder')}
            </IconButton>

            <Modal show={show}
                   className={'davr-modal'}
                   centered={true}
                   size="xl"
                   onHide={() => setShow(false)}
            >
                <Modal.Header>
                    <Modal.Title>{t(lang, 'roaming.invoice.mark')}</Modal.Title>
                    <FalconCloseButton onClick={() => setShow(false)}/>
                </Modal.Header>
                <Modal.Body>
                    <Row className={'align-items-center'}>
                        <Col xs={6}>
                            <Controller name={`${itemFieldName}.mark.productType`}
                                        render={({field}) => (
                                            <MarkProductTypeSelector
                                                defaultProductType={item?.mark?.productType}
                                                onChange={field.onChange}
                                                selectProps={{
                                                    menuPortalTarget: document.body,
                                                    styles: {
                                                        menuPortal: (provided) => ({
                                                            ...provided,
                                                            zIndex: 9999
                                                        })
                                                    }
                                                }}
                                            />
                                        )}
                            />
                        </Col>
                        <Col xs={6} >
                            {markPackageOptions.map((option, index) => (
                                <Row className={'d-flex'} key={option.value}>
                                    <Col xs={6}>
                                        <MarkPackageTypeSelector defaultPackageType={option.value}
                                                                 selectProps={{
                                                                     isDisabled: true
                                                                 }}
                                        />
                                    </Col>
                                    <Col xs={6}>
                                        {option.value === ForUsage &&
                                            <Fragment>
                                                {kiz.map((field, index) => (
                                                    <InputGroup key={field.id}
                                                                className={classNames({'mb-2': kiz.length > 1})}>
                                                        <Controller
                                                            name={`${itemFieldName}.mark.kiz.${index}.code`}
                                                            render={({field}) => (
                                                                <Form.Control
                                                                    className={'border-right-0'}
                                                                    name={`${itemFieldName}.mark.kiz.${index}.code`}
                                                                    value={item?.mark?.kiz?.[index]?.code}
                                                                    onChange={(e) => field.onChange(e.target.value)}
                                                                />
                                                            )}
                                                        />
                                                        {kiz.length > 1 &&
                                                            <IconButton icon={faTrash}
                                                                        variant={'danger'}
                                                                        title={t(lang, 'roaming.invoice.delete')}
                                                                        onClick={() => removeKizItem(index)}
                                                            />
                                                        }
                                                        <IconButton icon={faPlus}
                                                                    variant={'success'}
                                                                    title={t(lang, 'roaming.common.add')}
                                                                    onClick={addKizItem}
                                                        />
                                                    </InputGroup>
                                                ))}
                                            </Fragment>
                                        }
                                        {option.value === ForGroup &&
                                            <Fragment>
                                                {nomUpak.map((field, index) => (
                                                    <InputGroup key={field.id}
                                                                className={classNames({'mb-2': nomUpak.length > 1})}>
                                                        <Controller
                                                            name={`${itemFieldName}.mark.nomUpak.${index}.code`}
                                                            render={({field}) => (
                                                                <Form.Control
                                                                    name={`${itemFieldName}.mark.nomUpak.${index}.code`}
                                                                    value={item?.mark?.nomUpak?.[index]?.code}
                                                                    onChange={(e) => field.onChange(e.target.value)}
                                                                />
                                                            )}
                                                        />
                                                        {nomUpak.length > 1 &&
                                                            <IconButton icon={faTrash}
                                                                        variant={'danger'}
                                                                        title={t(lang, 'roaming.invoice.delete')}
                                                                        onClick={() => removeNomUpakItem(index)}
                                                            />
                                                        }
                                                        <IconButton icon={faPlus}
                                                                    variant={'success'}
                                                                    title={t(lang, 'roaming.common.add')}
                                                                    onClick={addNomUpakItem}
                                                        />
                                                    </InputGroup>
                                                ))}
                                            </Fragment>
                                        }
                                        {option.value === ForTransport &&
                                            <Fragment>
                                                {identTransUpak.map((field, index) => (
                                                    <InputGroup key={field.id}
                                                                className={classNames({'mb-2': identTransUpak.length > 1})}>
                                                        <Controller
                                                            name={`${itemFieldName}.mark.identTransUpak.${index}.code`}
                                                            render={({field}) => (
                                                                <Form.Control
                                                                    name={`${itemFieldName}.mark.identTransUpak.${index}.code`}
                                                                    value={item?.mark?.identTransUpak?.[index]?.code}
                                                                    onChange={(e) => field.onChange(e.target.value)}
                                                                />
                                                            )}
                                                        />
                                                        {identTransUpak.length > 1 &&
                                                            <IconButton icon={faTrash}
                                                                        variant={'danger'}
                                                                        title={t(lang, 'roaming.invoice.delete')}
                                                                        onClick={() => removeIdentTransUpakItem(index)}
                                                            />
                                                        }
                                                        <IconButton icon={faPlus}
                                                                    variant={'success'}
                                                                    title={t(lang, 'roaming.common.add')}
                                                                    onClick={addIdentTransUpakItem}
                                                        />
                                                    </InputGroup>
                                                ))}
                                            </Fragment>
                                        }
                                    </Col>
                                </Row>
                            ))}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShow(false)}>
                        {t(lang, 'roaming.common.cancel_button')}
                    </Button>
                </Modal.Footer>
            </Modal>

        </Fragment>
    );
};

export default RoamingInvoiceFormItemMarkLinkSelector;
