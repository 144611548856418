import {createSlice} from "@reduxjs/toolkit";
import Eimzo, {CertificatePfx} from "../../../plugins/eimzo";
import SignService from "../../services/sign/signService";
import RoamingService from "../../services/roaming/roamingService";


const eimzoUrl = process.env.REACT_APP_EIMZO_URL
const eimzoHost = process.env.REACT_APP_EIMZO_HOST;
const eimzoApiKey = process.env.REACT_APP_EIMZO_API_KEY;
const eimzo = new Eimzo(eimzoUrl, eimzoHost, eimzoApiKey)

const initialState = {
    certificates: [],
    currentCertificate: null,
}

export const eimzoSlice = createSlice({
    name: "eimzo",
    initialState,
    reducers: {
        setCertificates: (state, action) => {
            state.certificates = action.payload;
        },
        setCurrentCertificate: (state, action) => {
            state.currentCertificate = action.payload;
        }
    }
})

export const unsetCurrentCertificate = () => (dispatch) => {
    dispatch(eimzoSlice.actions.setCurrentCertificate(null))
}
export const selectCertificates = (state) => state.eimzo.certificates

export const selectCurrentCertificate = (state) => {
    return state.eimzo.currentCertificate;
}

export const loadCertificatesAsync = () => (dispatch) => {
    eimzo.getCertificates()
        .then(certificates => {
            dispatch(eimzoSlice.actions.setCertificates(certificates));
        })
};

export const initializeAsync = () => (dispatch) => {
    const waitConnectionDelay = 500;
    const connect = () => {
        if (eimzo.isConnected) {
            dispatch(loadCertificatesAsync());
        } else {
            setTimeout(connect, waitConnectionDelay);
        }
    }

    return new Promise((resolve) => {
        connect();
        resolve(true);
    });
}

export const activateCertificateAsync = (certificate) => {
    return eimzo.activate(certificate)
}

export const createPkcs7WithTimestamp = (hashCode, keyId) => {
    return eimzo.createPkcs7WithTimestamp(hashCode, keyId)
}

export const attachPkcs7WithTimestamp = (pkcs7, keyId) => {
    return eimzo.attachPkcs7WithTimestamp(pkcs7, keyId)
}

export const attachTimestampAsync = (roamingPkcs7, signerSerialNumber, timeStamp) => {
    return eimzo.attachTimestamp(roamingPkcs7, signerSerialNumber, timeStamp)
}

export const roamingSignAsync = (id, certificate) => {
    return new Promise(async (resolve, reject) => {
        let caughtError = {};
        SignService.getSignHashCode(id)
            .then(({data: {
                operator_hash_code: operatorHashCode,
                operator_uid: operatorUid,
                roaming_hash_code: roamingHashCode
            }}) => {
                eimzo.createPkcs7(operatorHashCode, certificate.keyId)
                    .then(({pkcs7: operatorSignature}) => {
                        eimzo.createPkcs7(roamingHashCode, certificate.keyId)
                            .then(({pkcs7: roamingPkcs7, signerSerialNumber, signatureHex}) => {
                                RoamingService.getTimestamp(signatureHex)
                                    .then(({data: timeStamp}) => {
                                        eimzo.attachTimestamp(roamingPkcs7, signerSerialNumber, timeStamp)
                                            .then(({pkcs7: roamingSignature}) => {
                                                const pfx = new CertificatePfx(certificate)
                                                SignService.signDocument(id, {
                                                    certificateSerial: signerSerialNumber,
                                                    comment: null,
                                                    operatorSignature: operatorSignature,
                                                    operatorUid: operatorUid,
                                                    roamingSignature: roamingSignature,
                                                    certificateOwner: pfx.issuedPerson
                                                })
                                                    .then((response) => {
                                                        resolve(response.data)
                                                    })
                                                    .catch((error) => {
                                                        if (error.response.data.roaming_error)
                                                            caughtError = {message: JSON.parse(error.response.data.roaming_error)?.ErrorMessage};
                                                        if (error.response.data.billing_balance_not_enough)
                                                            caughtError = {message: "edi.alert.errors.balance_not_enough"}
                                                        reject(caughtError)
                                                    })
                                            })
                                            .catch((error) => {
                                                caughtError = {message: 'edi.alert.errors.attach_time_stamp'}
                                                reject(caughtError);
                                            })
                                    })
                                    .catch((error) => {
                                        caughtError = {message: 'edi.alert.errors.get_time_stamp'}
                                        reject(caughtError);
                                    })
                            })
                            .catch((error) => {
                                caughtError = {message: 'edi.alert.errors.create_pkcs7'}
                                reject(caughtError);
                            })
                    })
                    .catch((error) => {
                        caughtError = {message: 'edi.alert.errors.create_pkcs7'}
                        reject(caughtError);
                    })
            })
            .catch((error) => {
                caughtError = {message: 'edi.alert.errors.get_sign_hash_code'}
                reject(caughtError);
            })
    })
}

export const roamingAttachAsync = (id, certificate) => {
    return new Promise((resolve, reject) => {
        let caughtError = {};
        SignService.getSignHashCode(id)
            .then(({data: {
                operator_hash_code: operatorHashCode,
                operator_uid: operatorUid,
                roaming_hash_code: roamingHashCode
            }}) => {
                eimzo.createPkcs7(operatorHashCode, certificate.keyId)
                    .then(({pkcs7: operatorSignature}) => {
                        eimzo.attachPkcs7(roamingHashCode, certificate.keyId)
                            .then(({pkcs7: roamingPkcs7, signerSerialNumber, signatureHex}) => {
                                RoamingService.getTimestamp(signatureHex)
                                    .then(({data: timeStamp}) => {
                                        eimzo.attachTimestamp(roamingPkcs7, signerSerialNumber, timeStamp)
                                            .then(({pkcs7: roamingSignature}) => {
                                                const pfx = new CertificatePfx(certificate)
                                                SignService.signDocument(id, {
                                                    certificateSerial: signerSerialNumber,
                                                    comment: null,
                                                    operatorSignature: operatorSignature,
                                                    operatorUid: operatorUid,
                                                    roamingSignature: roamingSignature,
                                                    certificateOwner: pfx.issuedPerson
                                                })
                                                    .then((response) => {
                                                        resolve(response.data)
                                                    })
                                                    .catch((error) => {
                                                        caughtError = {message: JSON.parse(error.response.data.roaming_error)?.ErrorMessage};
                                                        reject(caughtError)
                                                    })
                                            })
                                            .catch(() => {
                                                caughtError = {message: 'edi.alert.errors.attach_time_stamp'}
                                                reject(caughtError)
                                            })
                                    })
                                    .catch(() => {
                                        caughtError = {message: 'edi.alert.errors.get_time_stamp'}
                                        reject(caughtError)
                                    })
                            })
                            .catch(() => {
                                caughtError = {message: 'edi.alert.errors.attach_pkcs7'}
                                reject(caughtError)
                            })
                    })
                    .catch(() => {
                        caughtError = {message: 'edi.alert.errors.create_pkcs7'}
                        reject(caughtError)
                    })
            })
            .catch(() => {
                caughtError = {message: 'edi.alert.errors.get_sign_hash_code'}
                reject(caughtError)
            })
    })

}
export const roamingCancelAsync = async (id, comment, certificate) => {
    return new Promise((resolve, reject) => {
        let caughtError = {};
        SignService.getCancelHashCode(id)
            .then(({data: {
                operator_hash_code: operatorHashCode,
                operator_uid: operatorUid,
                roaming_hash_code: roamingHashCode
            }}) => {
                eimzo.createPkcs7(operatorHashCode, certificate.keyId)
                    .then(({pkcs7: operatorSignature}) => {
                        eimzo.createPkcs7(roamingHashCode, certificate.keyId)
                            .then(({pkcs7: roamingPkcs7, signerSerialNumber, signatureHex}) => {
                                RoamingService.getTimestamp(signatureHex)
                                    .then(({data: timeStamp}) => {
                                        eimzo.attachTimestamp(roamingPkcs7, signerSerialNumber, timeStamp)
                                            .then(({pkcs7: roamingSignature}) => {
                                                const pfx = new CertificatePfx(certificate)
                                                SignService.cancelDocument(id, {
                                                    certificateSerial: signerSerialNumber,
                                                    comment: comment,
                                                    operatorSignature: operatorSignature,
                                                    operatorUid: operatorUid,
                                                    roamingSignature: roamingSignature,
                                                    certificateOwner: pfx.issuedPerson
                                                })
                                                    .then((response) => {
                                                        resolve(response.data);
                                                    })
                                                    .catch((error) => {
                                                        caughtError = {message: JSON.parse(error.response.data.roaming_error)?.ErrorMessage};
                                                        reject(caughtError)
                                                    })
                                            })
                                            .catch(() => {
                                                caughtError = {message: 'edi.alert.errors.attach_time_stamp'}
                                                reject(caughtError)
                                            })
                                    })
                                    .catch(() => {
                                        caughtError = {message: 'edi.alert.errors.get_time_stamp'}
                                        reject(caughtError)
                                    })
                            })
                            .catch(() => {
                                caughtError = {message: 'edi.alert.errors.create_pkcs7'}
                                reject(caughtError);
                            })
                    })
                    .catch(() => {
                        caughtError = {message: 'edi.alert.errors.create_pkcs7'}
                        reject(caughtError);
                    })
            })
            .catch(() => {
                caughtError = {message: 'edi.alert.errors.get_cancel_hash_code'}
                reject(caughtError)
            })
    })
};
export const roamingDeclineAsync = (id, comment, certificate) => {
    return new Promise((resolve, reject) => {
        let caughtError = {};

        SignService.getDeclineHashCode(id, comment)
            .then(({data: {
                operator_hash_code: operatorHashCode,
                operator_uid: operatorUid,
                roaming_hash_code: roamingHashCode
            }}) => {
                eimzo.createPkcs7(operatorHashCode, certificate.keyId)
                    .then(({pkcs7: operatorSignature}) => {
                        eimzo.createPkcs7(roamingHashCode, certificate.keyId)
                            .then(({pkcs7: roamingPkcs7, signerSerialNumber, signatureHex}) => {
                                RoamingService.getTimestamp(signatureHex)
                                    .then(({data: timeStamp}) => {
                                        eimzo.attachTimestamp(roamingPkcs7, signerSerialNumber, timeStamp)
                                            .then(({pkcs7: roamingSignature}) => {
                                                const pfx = new CertificatePfx(certificate)
                                                SignService.declineDocument(id, {
                                                    certificateSerial: signerSerialNumber,
                                                    comment: comment,
                                                    operatorSignature: operatorSignature,
                                                    operatorUid: operatorUid,
                                                    roamingSignature: roamingSignature,
                                                    certificateOwner: pfx.issuedPerson
                                                })
                                                    .then((response) => {
                                                        resolve(response.data);
                                                    })
                                                    .catch((error) => {
                                                        caughtError = {message: JSON.parse(error.response.data.roaming_error)?.ErrorMessage};
                                                        reject(caughtError)
                                                    })
                                            })
                                            .catch(() => {
                                                caughtError = {message: 'edi.alert.errors.attach_time_stamp'}
                                                reject(caughtError)
                                            })
                                    })
                                    .catch(() => {
                                        caughtError = {message: 'edi.alert.errors.get_time_stamp'}
                                        reject(caughtError)
                                    })
                            })
                            .catch(() => {
                                caughtError = {message: 'edi.alert.errors.create_pkcs7'}
                                reject(caughtError)
                            })
                    })
                    .catch(() => {
                        caughtError = {message: 'edi.alert.errors.create_pkcs7'}
                        reject(caughtError)
                    })
            })
            .catch(() => {
                caughtError = {message: 'edi.alert.errors.get_decline_hash_code'}
                reject(caughtError)
            })
    })
};


export const {setCurrentCertificate} = eimzoSlice.actions
export default eimzoSlice.reducer
