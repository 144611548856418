import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {useFieldArray} from "react-hook-form";
import IconButton from "../../../IconButton";
import Flex from "../../../common/Flex";
import {Button, Card, Table} from "react-bootstrap";
import EmpowermentItem from "./EmpowermentItem";
import {faBookmark, faPlus} from "@fortawesome/free-solid-svg-icons";
import SignActionWithSelectCertificateWrapper from "../../../common/SignActionWithSelectCertificateWrapper";

const EmpowermentItems = ({root}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const {append, fields, remove} = useFieldArray({name: root});
    const [allowDeleteItems, setAllowDeleteItems] = useState(false);

    const addNewItem = () => {
        const item = {
            product: "",
            measurementId: "",
            quantity: "",
            catalogClassCode: "",
            catalogClassName: "",
        };
        append(item);
    };

    const onDeleteItem = index => remove(index);

    useEffect(() => {
        setAllowDeleteItems(fields.length > 1);
    }, [fields]);

    return (
        <Fragment>
            <Flex justifyContent="between" alignItems="center">
                <h4 className={'fs-8 fw-medium second-text ms-3'}>{t(lang, "roaming.common.items")}</h4>
            </Flex>

            <Card className={'py-1 px-2 rounded-4 border-0'}>
                <Table responsive className="scrollbar view-table border-900 mb-0 fs-9 second-text fw-semi-bold">
                    <thead className="text-dark border-bottom">
                    <tr className="position-relative py-2 fs-9 second-text fw-semi-bold">
                        <th className="text-center align-middle">#</th>
                        <th rowSpan={2} className="fw-semi-bold text-center align-middle">{t(lang, "roaming.common.items.product")}</th>
                        <th rowSpan={2} className="fw-semi-bold text-center align-middle">{t(lang, "roaming.common.items.quantity")}</th>
                        <th rowSpan={2} className="fw-semi-bold text-center align-middle">{t(lang, "roaming.common.items.measurement")}</th>
                        <th rowSpan={2} className="fw-semi-bold text-center align-middle">{t(lang, "roaming.common.items.catalog_class_code")}</th>
                        <th rowSpan={2} className="fw-semi-bold text-center align-middle">{t(lang, "roaming.common.items.catalog_class_name")}</th>
                        <th style={{minWidth: '30px', width: '30px'}}
                            className=" align-middle text-center">
                            <IconButton icon={faPlus}
                                        variant={'phoenix-primary'}
                                        onClick={addNewItem}
                                        className={'bg-primary-davr py-2 px-2 rounded-3'}
                                        iconClassName={'text-white'}
                            />
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {fields.map((item, index) => (
                        <EmpowermentItem key={item.id} root={root} index={index} allowDelete={allowDeleteItems}
                                         onDeleteClicked={onDeleteItem}/>
                    ))}
                    </tbody>
                </Table>
            </Card>
        </Fragment>
    );
};

export default EmpowermentItems;
