import React from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import {selectAccountRolePermissions} from "../../../store/reducers/role/roleReducer";
import checkPermission from "../../../enum/permission/CheckPermission";
import {PermissionRoamingEmpowermentView} from "../../../enum/permission/RoamingPermission";
import PermissionBadge from "../../../components/common/PermissionBadge";
import EmpowermentDataTable from "../../../components/roaming/empowerments/EmpowermentDataTable";

const RoamingEmpowerments = () => {
    const {viewer} = useParams();
    const dispatch = useDispatch()
    const t = Translate;
    const lang = useSelector(selectLang);
    //important
    useSelector(selectAccountRolePermissions)

    if(!dispatch(checkPermission(PermissionRoamingEmpowermentView))) {
        return <PermissionBadge>
            <span>{t(lang, 'system.permission.roaming.empowerment.datatable.no_access.title')}</span>
        </PermissionBadge>
    }
    return (
        <EmpowermentDataTable viewer={viewer} />
    );
};

export default RoamingEmpowerments;
