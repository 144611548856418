import {Translate} from "../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {selectCurrentCertificate} from "../../store/reducers/eimzo/eimzoReducer";
import {useState} from "react";
import SelectCertificateWrapperComponent from "../common/SelectCertificateWrapperComponent";
import IconButton from "../IconButton";
import EventBus from "../../eventbus/EventBus";
import {
    WAYBILL_V2_BULK_ACTION_FINISHED,
    WAYBILL_V2_BULK_ACTION_STARTED
} from "../../eventbus/romaing/waybill-v2/roamingWaybillV2Events";
import IconSignature from "../../assets/icon/IconSignature";
import {Spinner} from "react-bootstrap";

export const hashCodeCertificate = (displayName) => {

    const HashCodeCertificate = ({
                                     id,
                                     waybills = [],
                                     handleShowProgressBar,
                                     updateActionResult,
                                     requestApi = () => {
                                     },
                                     btnText,
                                     variant,
                                     cancel,
                                     ...props
                                 }) => {

        const lang = useSelector(selectLang);
        const t = Translate;
        const activeCertificate = useSelector(selectCurrentCertificate);

        const [loading, setLoading] = useState(false); // cancel

        const confirmation = () => {
            if (waybills.length > 0)
                bulkConfirmation();
            else
                singleConfirmation();
        };

        const singleConfirmation = () => {
            setLoading(true);
            requestApi(activeCertificate, lang, id)
                .then(() => {
                    setLoading(false)
                })
                .catch((error) => {
                    setLoading(false)
                })
        }

        const bulkConfirmation = async () => {
            handleShowProgressBar();
            EventBus.dispatch(WAYBILL_V2_BULK_ACTION_STARTED);
            for (let i = 0; i < waybills.length; i++) {
                const waybill = waybills[i];
                try {
                    await requestApi(activeCertificate, lang, waybill.id);
                    updateActionResult({
                        number: waybill.info.number,
                        date: waybill.info.date,
                        contractor: {
                            name: waybill.customer.name,
                            inn: waybill.customer.inn
                        }
                    });
                } catch (error) {
                    updateActionResult({
                        number: waybill.info.number,
                        date: waybill.info.date,
                        contractor: {
                            name: waybill.customer.name,
                            inn: waybill.customer.inn
                        },
                        error: error?.message
                    })
                }
            }
            EventBus.dispatch(WAYBILL_V2_BULK_ACTION_FINISHED);
        }

        return (
            <SelectCertificateWrapperComponent
                cancel={cancel}
                variant={'phoenix-secondary'}
                openDialogButtonText={btnText ? t(lang, btnText) : t(lang, "roaming.free_form_document.view.action.accept")}
                submitButton={
                    <IconButton
                        onClick={confirmation}
                        variant={"phoenix-secondary"}
                        type="submit"
                        className={'btn-white-davr'}
                        size={"sm"}
                        disabled={loading}
                        form={'comment-form'}
                        {...props}
                    >
                        {loading ? <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/> :
                            <IconSignature/>}
                        {btnText ? t(lang, btnText) : t(lang, "roaming.free_form_document.view.action.accept")}
                    </IconButton>}
            />
        )
    }

    HashCodeCertificate.displayName = displayName || "HashCodeCertificate"

    return HashCodeCertificate

}
