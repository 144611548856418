import axios from "axios";

class RoleService {
    getRoles() {
        return axios.get('/role/role/get')
    }

    getAccountRoles() {
        return axios.get('/role/account-role/get')
    }

    getAccountPermission() {
        return axios.get('/role/account-role/get-me')
    }

    addRole(payload) {
        return axios.post("/role/role/register", payload);
    }

    editRole(id, {name, permissions}) {
        return axios.post(`/role/role/edit/${id}`, {
            name: name,
            permissions: permissions
        })
    }

    bindRoleIntoAccount(data) {
        return axios.post(`/role/role/bind-account/${data.roleId}`, data)
    }

    deleteRole({id}) {
        return axios.post(`/role/role/delete/${id}`)
    }
}

const roleService = new RoleService();

export default roleService;
