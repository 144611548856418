import React, {Fragment} from 'react';
import {useNavigate} from "react-router-dom";
import {Translate} from "../../../../lang/lang";
import {useSelector} from "react-redux";
import {selectDateFormat, selectLang} from "../../../../store/reducers/main/main";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {TypeAdditional, TypeCorrective} from "../../../../enum/roaming/waybill-v2/WaybillV2Wrapper";
import {Button, Col, FloatingLabel, Form, InputGroup} from "react-bootstrap";
import classNames from "classnames";
import DatePickerComponent from "../../../common/DatePickerComponent";
import IconSearch from "../../../../assets/icon/IconSearch";

const WaybillV2OldDocInfo = () => {
    const navigate = useNavigate();
    const t = Translate;
    const lang = useSelector(selectLang);
    const dateFormat = useSelector(selectDateFormat);
    const form = useFormContext();
    const waybillType = useWatch({name: `waybill_type`, exact: true});

    const onSearchInvoice = () => {
        const id = form.getValues(`oldWaybillDoc.oldWaybillId`);
        navigate({search: `?waybill_id=${id}&type=${waybillType}`});
    };

    switch (waybillType) {
        case TypeAdditional:
        case TypeCorrective:
            return (
                <Fragment>
                    <Form.Group as={Col} sm={12} md={4}>
                        <InputGroup
                            className={classNames({'is-invalid': form.formState.errors?.oldWaybillDoc?.oldWaybillId})}>
                            <FloatingLabel className={'border-right-0'} label={<>
                                {t(lang, 'roaming.waybill.v2.send.id')}
                                <span className="text-primary-davr">*</span>
                            </>}>
                                <Form.Control name={`oldWaybillDoc.oldWaybillId`}
                                              placeholder={<>
                                                  {t(lang, 'roaming.waybill.v2.send.id')}
                                                  <span className="text-primary-davr">*</span>
                                              </>}
                                              className={'border-right-0'}
                                              isInvalid={form.formState.errors?.oldFacturaDoc?.oldFacturaId}
                                              {...form.register(`oldWaybillDoc.oldWaybillId`, {
                                                  required: t(lang, 'items.common.validations.is_required'),
                                                  shouldUnregister: true
                                              })}
                                />
                            </FloatingLabel>
                            <Button className={'bg-primary-davr'} onClick={onSearchInvoice}><IconSearch /></Button>
                        </InputGroup>
                        <Form.Control.Feedback
                            type="invalid">{form.formState.errors?.oldWaybillDoc?.oldWaybillId?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm={12} md={4}>
                        <FloatingLabel label={<>
                            {t(lang, 'roaming.waybill.v2.send.old.number')}
                            <span className="text-primary-davr">*</span>
                        </>}>
                            <Form.Control name={`oldWaybillDoc.oldWaybillNo`}
                                          placeholder={t(lang, 'roaming.waybill.v2.send.old.number:placeholder')}
                                          isInvalid={form.formState.errors?.oldWaybillDoc?.oldWaybillNo}
                                          disabled
                                          {...form.register(`oldWaybillDoc.oldWaybillNo`, {
                                              required: t(lang, 'items.common.validations.is_required'),
                                              shouldUnregister: true
                                          })}
                            />
                        </FloatingLabel>
                        <Form.Control.Feedback type="invalid">
                            {form.formState.errors?.oldWaybillDoc?.oldWaybillNo?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm={12} md={4}>
                        <Controller name={`oldWaybillDoc.oldWaybillDate`}
                                    rules={{
                                        required: t(lang, 'items.common.validations.is_required'),
                                        shouldUnregister: true
                                    }}
                                    render={({field}) => (
                                        <DatePickerComponent
                                            field={field}
                                            placeholderText={<>
                                                {t(lang, 'roaming.waybill.v2.send.old.date')}
                                                <span className="text-primary-davr">*</span>
                                            </>}
                                            dateFormat={dateFormat}
                                            disabled
                                            className={classNames('form-control', {
                                                'is-invalid': form.formState.errors?.oldWaybillDoc?.oldWaybillDate,
                                            })}
                                            wrapperClassName={classNames({
                                                'is-invalid': form.formState.errors?.oldWaybillDoc?.oldWaybillDate
                                            })}
                                        />
                                    )}
                        />
                        <Form.Control.Feedback
                            type="invalid">{form.formState.errors?.oldWaybillDoc?.oldWaybillDate?.message}</Form.Control.Feedback>
                    </Form.Group>
                </Fragment>
            )
        default:
            return null;
    }
};

export default WaybillV2OldDocInfo;
