import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {FormProvider, useForm} from "react-hook-form";
import {getRegistrationVerifyConfirmationTicketAsync, registerAsync} from "../../../../store/reducers/auth/authReducer";
import IconAlert from "../../../common/IconAlert";
import RegistrationFormStepOne from "./RegistrationFormStepOne";
import RegistrationFormStepTwo from "./RegistrationFormStepTwo";
import {Button, Form} from "react-bootstrap";
import Flex from "../../../common/Flex";


const RegistrationWithoutInnForm = ({hasLabel, header}) => {

    const lang = useSelector(selectLang);
    const t = Translate;
    const [step, setStep] = useState(1);
    const [invalidConfirmCode, setInvalidConfirmCode] = useState(null);
    const [confirmationTicket, setConfirmationTicket] = useState(null);
    const [somethingWentWrong, setSomethingWentWrong] = useState(false);
    const [showAgentCode, setShowAgentCode] = useState(false);


    const methods = useForm({
        defaultValues: {
            company: {
                name: '',
                referral_agent_code: null
            },
            account: {
                name: '',
                username: '',
                password: '',
                confirmPassword: '',
                confirmCode: ''
            },
            terms: null
        }
    });

    const onSubmit = (formData) => {
        const {confirmCode1, confirmCode2, confirmCode3, confirmCode4, confirmCode5, confirmCode6} = formData.account

        let username = formData.account?.username?.replace(/ /g, '')
        let confirmCode = confirmCode1 + confirmCode2 + confirmCode3 + confirmCode4 + confirmCode5 + confirmCode6

        username = '998' + username;

        switch (step) {
            case 1 :
                getRegistrationVerifyConfirmationTicketAsync(username)
                    .then(ticket => {
                        setConfirmationTicket(ticket);
                        setStep(step + 1);
                    }, () => {
                        setSomethingWentWrong(true);
                    })
                break;
            case 2 :
                const data = methods.getValues()
                registerAsync({
                    name: data.company?.name,
                    username: username,
                    password: data.account?.password,
                    fullName: data.account?.name,
                    confirmCode: confirmCode,
                    confirmTicket: confirmationTicket,
                })
                    .then(() => {
                        setSomethingWentWrong(false)
                    }, () => {
                        setSomethingWentWrong(true);
                    });
                break;
        }
    }

    return (
        <FormProvider {...methods}>
            {somethingWentWrong && <IconAlert variant="danger" className={'alert-davr-danger mb-2'} dismissible
                                              onClose={() => setSomethingWentWrong(false)}>
                <p className="mb-0">{t(lang, 'authorization.register.something_went_wrong')}</p>
            </IconAlert>}
            <Form onSubmit={methods.handleSubmit(onSubmit)} className={"h-100"}>
                {header}
                <Flex className={"flex-column justify-content-between scrollbar"} style={{height: "calc(100% - 55px)"}}>
                    <div>
                        {step === 1 && <RegistrationFormStepOne withoutInn hasLabel={hasLabel}/>}
                        {step === 2 &&
                            <RegistrationFormStepTwo hasLabel={hasLabel} invalidConfirmCode={invalidConfirmCode}/>}
                    </div>
                    <Form.Group>
                        <Button className="mt-3 w-100 bg-primary-davr"
                                type="submit"> {t(lang, 'authorization.register.button.next')}</Button>
                    </Form.Group>
                </Flex>
            </Form>
        </FormProvider>
    );
};

export default RegistrationWithoutInnForm;
