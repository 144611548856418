export const CURRENCY_GET_REQUESTED = "CURRENCY_GET_REQUESTED"
export const CURRENCY_GET_SUCCESS = "CURRENCY_GET_SUCCESS"
export const CURRENCY_GET_FAILED = "CURRENCY_GET_FAILED"

export const CURRENCY_UPDATE_RATE_REQUESTED = "CURRENCY_UPDATE_RATE_REQUESTED"
export const CURRENCY_UPDATE_RATE_SUCCESS = "CURRENCY_UPDATE_RATE_SUCCESS"
export const CURRENCY_UPDATE_RATE_FAILED = "CURRENCY_UPDATE_RATE_FAILED"

export const CURRENCY_ACTIVATE_REQUESTED = "CURRENCY_ACTIVATE_REQUESTED"
export const CURRENCY_ACTIVATE_SUCCESS = "CURRENCY_ACTIVATE_SUCCESS"
export const CURRENCY_ACTIVATE_FAILED = "CURRENCY_ACTIVATE_FAILED"

export const CURRENCY_DEACTIVATE_REQUESTED = "CURRENCY_DEACTIVATE_REQUESTED"
export const CURRENCY_DEACTIVATE_SUCCESS = "CURRENCY_DEACTIVATE_SUCCESS"
export const CURRENCY_DEACTIVATE_FAILED = "CURRENCY_DEACTIVATE_FAILED"

export const CURRENCY_CONVERTER_CONVERT_REQUESTED = "CURRENCY_CONVERTER_CONVERT_REQUESTED"
export const CURRENCY_CONVERTER_CONVERT_SUCCESS = "CURRENCY_CONVERTER_CONVERT_SUCCESS"
export const CURRENCY_CONVERTER_CONVERT_FAILED = "CURRENCY_CONVERTER_CONVERT_FAILED"
