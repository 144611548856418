import React, {Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang, updateShowInProgressAsync} from "../../store/reducers/main/main";
import {selectCurrentCertificate} from "../../store/reducers/eimzo/eimzoReducer";
import {selectActiveOrganization} from "../../store/reducers/auth/authReducer";
import {Translate} from "../../lang/lang";
import {
    bindSystemAsRoamingProvider,
    loadProviderBindings,
    selectIsSystemProviderEnabled,
    selectProviderBindings
} from "../../store/reducers/roaming/roamingReducer";
import toast from "react-hot-toast";
import EventBus from "../../eventbus/EventBus";
import {
    COMPANY_BINDING_FAILED,
    COMPANY_BINDING_STARTED,
    COMPANY_BINDING_SUCCESS
} from "../../eventbus/romaing/signEvents";
import {SYSTEM_PROVIDER_INN} from "../../enum/roaming/roamingWrapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWarning} from "@fortawesome/free-solid-svg-icons";
import Badge from "../common/Badge";
import {Alert, Button} from "react-bootstrap";
import SelectCertificateWrapperComponent from "../common/SelectCertificateWrapperComponent";
import classNames from "classnames";
import IconAlertPrimary from "../../assets/icon/IconAlertPrimary";

const RoamingProviderBinding = ({className, ...rest}) => {
    const lang = useSelector(selectLang)
    const currentCertificate = useSelector(selectCurrentCertificate);
    const providerBindings = useSelector(selectProviderBindings);
    const activeOrganization = useSelector(selectActiveOrganization)
    const dispatch = useDispatch()
    const t = Translate
    const isSystemProviderEnabled = useSelector(selectIsSystemProviderEnabled);

    useEffect(() => {
        if (activeOrganization?.inn) {
            dispatch(loadProviderBindings());
        }

    }, [activeOrganization])

    useEffect(() => {
        const signStartedHandler = EventBus.on(COMPANY_BINDING_STARTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })
        const signSuccessHandler = EventBus.on(COMPANY_BINDING_SUCCESS, () => {
            toast.success(t(lang, "edi.select_provider.sign.notification.success"))
            dispatch(updateShowInProgressAsync(false))
            dispatch(loadProviderBindings());
        });
        const signFailedHandler = EventBus.on(COMPANY_BINDING_FAILED, (data) => {
            toast.error(t(lang, data.message))
            dispatch(updateShowInProgressAsync(false))
        });

        return () => {
            EventBus.remove(COMPANY_BINDING_STARTED, signStartedHandler)
            EventBus.remove(COMPANY_BINDING_SUCCESS, signSuccessHandler)
            EventBus.remove(COMPANY_BINDING_FAILED, signFailedHandler)
        }
    }, [])

    const onBindSystemAsRoamingProviderClicked = async () => {
        let enabledProviderBindings = await providerBindings.filter(provider => {
            return provider.enabled || provider.provider_inn === SYSTEM_PROVIDER_INN
        })
        enabledProviderBindings = enabledProviderBindings.map(provider => provider.provider_inn)
        let hashCode = JSON.stringify({
            "ClientTin": activeOrganization.inn,
            "ProviderTins": enabledProviderBindings
        })
        await bindSystemAsRoamingProvider({
            hashCode: hashCode,
            certificate: currentCertificate
        })
    }

    if (isSystemProviderEnabled || providerBindings.length === 0)
        return null

    return (
        <Fragment>
            <div className={classNames(className, 'd-flex align-items-center mb-2 align-items-start alert-davr-primary py-1 px-2 rounded-3')} {...rest}>
                <div className={'mt-1 me-2'}>
                    <IconAlertPrimary/>
                </div>
                <div className={`me-1`}>
                    <Alert.Heading className="mb-0">
                        {t(lang, "edi.alert.warning.is_system_provider_enabled.title")}
                    </Alert.Heading>
                    <p className="mb-0 fw-medium fs-md-8 fs-sm-10 text-wrap">{t(lang, "edi.alert.warning.is_system_provider_enabled.description")}</p>
                </div>
                <SelectCertificateWrapperComponent alertBtn={true} submitButton={
                    <Button
                        onClick={onBindSystemAsRoamingProviderClicked}
                        variant="phoenix-success"
                        size="lg"
                    >
                        {t(lang, "edi.alert.warning.is_system_provider_enabled.activation_button")}
                    </Button>
                } openDialogButtonText={t(lang, "edi.alert.warning.is_system_provider_enabled.activation_button")}/>
            </div>
        </Fragment>
    );
};

export default RoamingProviderBinding;
