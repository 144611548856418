import React, {Fragment, useState} from 'react';
import {selectLang} from "../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import toast from "react-hot-toast";
import {activatePackageAsync} from "../../../store/reducers/billing/billingReducer";
import {Button, Modal, Spinner} from "react-bootstrap";

const PackageActivationModal = ({children, packageId, hasSoonExpiringPackages, serviceCode}) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const activatePackage = () => {
        setLoading(true);

        activatePackageAsync({
            packageId: packageId,
        })
            .then(() => {
                handleClose();
                toast.success(t(lang, 'main.billing.all_packages_card.activation.modal.success_activated'))
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }

    return (
        <Fragment>
            <div className="d-flex justify-content-center" onClick={handleShow}>
                {children}
            </div>

            <Modal show={show} onHide={handleClose} size={'lg'}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, 'main.billing.all_packages_card.activation.modal.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={'text-center'}>
                    {hasSoonExpiringPackages[serviceCode.code] && <p className="text-danger">{t(lang, 'main.billing.all_packages_card.activation.modal.warning.has_soon_expiring_package')}</p>}
                    {!hasSoonExpiringPackages[serviceCode.code] && <p>{t(lang, 'main.billing.all_packages_card.activation.modal.warning.message')}</p>}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose} variant="secondary">{t(lang, 'main.common.back')}</Button>
                    <Button onClick={activatePackage} disabled={hasSoonExpiringPackages[serviceCode.code]}>
                        {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                        {t(lang, 'main.common.activate')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default PackageActivationModal;
