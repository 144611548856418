import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import checkPermission from "../../../enum/permission/CheckPermission";
import toastPermission from "../../../enum/permission/ToastPermission";
import {PermissionSystemRoleCreate} from "../../../enum/permission/RolePermission";
import {addRoleAsync} from "../../../store/reducers/role/roleReducer";
import toast from "react-hot-toast";
import {Button, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FormProvider, useForm} from "react-hook-form";
import IconButton from "../../IconButton";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import RoleForm from "./RoleForm";
import PermissionsDatatable from "./PermissionsDatatable";

const AddRole = () => {
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const [loading, setLoading] = useState(false);
    const t = Translate;
    const [isShow, setIsShow] = useState(false)
    const handleShow = () => {
        if (dispatch(checkPermission(PermissionSystemRoleCreate))) setIsShow(true)
        else dispatch(toastPermission())
    };
    const handleClose = () => {
        methods.reset();
        setIsShow(false);
    };

    const methods = useForm({
        defaultValues: {
            Name: '',
            Permissions: {
                edi: [],
                roaming: [],
                another: []
            },
        },
    });
    const onSubmit = formData => {
        if (formData.Permissions.length === 0) {
            toast.error(t(lang, "hippo.user.settings.add_role.permission.required.message"))
            return
        }
        setLoading(true);
        let permissions = []
        for (const [key, value] of Object.entries(formData.Permissions)) {
            for (let i = 0; i < formData.Permissions[key].length; i++) {
                permissions.push(formData.Permissions[key][i].value)
            }
        }

        dispatch(addRoleAsync({
            name: formData.Name,
            permissions
        })).then((response) => {
        }).catch(error => {
            console.log(error)
            toast.error(t(lang, "hippo.user.settings.add_role.error.message"));
        }).finally(() => {
            setLoading(false);
            handleClose();
        });
    }

    return (
        <Row className="flex-between-center mb-3">
            <div className="d-flex align-items-center justify-content-between">
                <h5>{t(lang, "hippo.user.settings.roles")}</h5>
                <IconButton
                    variant="phoenix-secondary"
                    size="sm"
                    icon={faPlus}
                    transform="shrink-3"
                    className='me-2'
                    onClick={() => handleShow()}>
                    <span
                        className="d-none d-sm-inline-block ms-1">{t(lang, "edi.branch.datatable.header.row.navigation.create")}</span>
                </IconButton>
                <Modal show={isShow} onHide={handleClose} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>{t(lang, "hippo.user.role.form.title")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormProvider {...methods}>
                            <Form onSubmit={methods.handleSubmit(onSubmit)} id={'add-role'}>
                                <RoleForm/>
                                <PermissionsDatatable />
                            </Form>
                        </FormProvider>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {t(lang, "edi.common.cancel_button")}
                        </Button>
                        <Button variant="primary" type="submit" disabled={loading} form={'add-role'}>
                            {loading && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                            />}
                            {t(lang, "edi.common.add_button")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </Row>
    );
};

export default AddRole;
