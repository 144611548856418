import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Translate} from "../../../../lang/lang";
import {selectLang, updateShowInProgressAsync} from "../../../../store/reducers/main/main";
import {
    selectCompany,
    selectExecutorPrivileges,
    selectTurnoverPrivileges
} from "../../../../store/reducers/roaming/roamingReferenceReducer";
import {selectCurrentCertificate} from "../../../../store/reducers/eimzo/eimzoReducer";
import {selectItems} from "../../../../store/reducers/item/itemReducer";
import {selectCurrency} from "../../../../store/reducers/currency/currencyReducer";
import {ForPerson, TypeDefault} from "../../../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import {FormProvider, useForm} from "react-hook-form";
import {
    generateRoamingInvoiceSendHashCode,
    saveAsync,
    sendAsync
} from "../../../../store/reducers/roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import InProgress from "../../../common/InProgress";
import WarningAlert from "../../../common/WarningAlert";
import roamingInvoiceFormWrapper from "../../../../enum/roaming/roaming-invoice/RoamingInvoiceFormWrapper";
import {getNumberFixedValue} from "../../../../helpers/utils";
import currencyWrapper, {UZS} from "../../../../enum/currency/currencyWrapper";
import RoamingInvoiceForm from "../../form/RoamingInvoiceForm";
import IconAlert from "../../../common/IconAlert";
import {Button} from "react-bootstrap";

const ROUND_SCALE_FOUR = 4;
const ROUND_SCALE_TWO = 2;
const SendRoamingInvoiceForm = ({invoice, draftInvoice, sale, waybill, catalogs, type, isEdit, isEditOrCopy}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const t = Translate;
    const lang = useSelector(selectLang);
    const company = useSelector(selectCompany);
    const activeCertificate = useSelector(selectCurrentCertificate);
    const currencies = useSelector(selectCurrency);
    const items = useSelector(selectItems);
    const sellerCompanyPrivileges = useSelector(selectExecutorPrivileges);
    const turnoverCompanyPrivileges = useSelector(selectTurnoverPrivileges);
    const [errorMessage, setErrorMessage] = useState(null);

    const form = useForm({
        defaultValues: {
            facturaId: '',
            facturaType: TypeDefault,
            singleSidedType: ForPerson,
            isSingleSided: false,
            hasExchange: false,
            hasInvestmentObject: false,
            incomeType: null,
            facturaDoc: {
                facturaNo: '',
                facturaDate: null
            },
            oldFacturaDoc: {
                oldFacturaId: '',
                oldFacturaNo: '',
                oldFacturaDate: null
            },
            contractDoc: {
                contractId: null,
                contractNo: '',
                contractDate: null
            },
            facturaEmpowermentDoc: {
                empowermentNo: '',
                empowermentDateOfIssue: null,
                agentPinfl: '',
                agentFio: '',
            },
            itemReleasedDoc: {
                itemReleasedFio: '',
                itemReleasedPinfl: '',
            },
            exchange: {
                type: null,
                number: '',
            },
            waybillId: '',
            facturaInvestmentObjectDoc: {
                objectId: '',
                objectName: ''
            },
            seller: {
                innOrPinfl: '',
                name: '',
                account: '',
                bankId: '',
                address: '',
                mobile: '',
                workPhone: '',
                oked: '',
                districtId: '',
                director: '',
                accountant: '',
                vatRegCode: '',
                vatRegStatus: '',
                branch: null
            },
            buyer: {
                innOrPinfl: '',
                name: '',
                account: '',
                bankId: '',
                address: '',
                mobile: '',
                workPhone: '',
                oked: '',
                districtId: '',
                director: '',
                accountant: '',
                vatRegCode: '',
                vatRegStatus: '',
                branch: null
            },
            foreignCompany: {
                countryId: '',
                name: '',
                address: '',
                bank: '',
                account: '',
            },
            productList: {
                hasExcise: false,
                hasMarking: false,
                hasCommittent: false,
                hideReportCommittent: false,
                hasMedical: false,
                products: [
                    {
                        catalog: null,
                        package: null,
                        measurement: null,
                        lgota: null,
                        origin: null,
                        committentName: '',
                        committentTin: '',
                        committentVatRegCode: '',
                        committentVatRegStatus: '',
                        barcode: '',
                        warehouseId: null,
                        mark: {
                            productType: null,
                            kiz: [
                                {code: ''}
                            ],
                            nomUpak: [
                                {code: ''}
                            ],
                            identTransUpak: [
                                {code: ''}
                            ],
                        },
                        exchangeInfo: {
                            productCode: '',
                            productProperties: '',
                            planPositionId: 1,
                        },
                        name: '',
                        serial: '',
                        baseSumma: '',
                        profitRate: '',
                        count: '',
                        countTon: '',
                        countKm: '',
                        summa: '',
                        exciseRate: '',
                        exciseSum: '',
                        deliverySum: '',
                        vatRate: '',
                        vatSum: '',
                        deliverySumWithVat: '',
                    }
                ]
            },
            manualCalculation: false
        }
    });

    useEffect( () => {
        if (invoice) {
            // set facturaType, facturaDoc and oldFacturaDoc
            {
                if (type) {
                    form.setValue('facturaType', parseInt(type));
                    if (roamingInvoiceFormWrapper.isAdditionalOrCorrective(parseInt(type))) {
                        if (roamingInvoiceFormWrapper.isCorrective(parseInt(type))) {
                            form.setValue('facturaDoc', {
                                facturaNo: invoice.info.number,
                                facturaDate: null
                            })
                        }
                        form.setValue('oldFacturaDoc', {
                            oldFacturaId: invoice.id,
                            oldFacturaNo: invoice.info.number,
                            oldFacturaDate: dayjs(invoice.info.date).toDate()
                        });
                    } else {
                        form.setValue('facturaDoc', {
                            facturaNo: invoice.info.number,
                            facturaDate: dayjs(invoice.info.date).toDate()
                        })
                    }
                } else {
                    form.setValue('facturaType', invoice.factura_type);
                    if (roamingInvoiceFormWrapper.isAdditionalOrCorrective(invoice.factura_type)) {
                        form.setValue('oldFacturaDoc', {
                            oldFacturaId: invoice.old_invoice.id,
                            oldFacturaNo: invoice.old_invoice.number,
                            oldFacturaDate: dayjs(invoice.old_invoice.date).toDate()
                        });
                        form.setValue('facturaDoc', {
                            facturaNo: invoice.info.number,
                            facturaDate: dayjs(invoice.info.date).toDate()
                        });
                    } else {
                        form.setValue('facturaDoc', {
                            facturaNo: invoice.info.number,
                            facturaDate: dayjs(invoice.info.date).toDate()
                        })
                    }
                }
            }

            // set contractDoc
            {
                form.setValue('contractDoc', {
                    contractId: invoice.contract_id || null,
                    contractNo: invoice.contract.number,
                    contractDate: dayjs(invoice.contract.date).toDate()
                })
            }

            // set itemReleasedDoc
            {
                if (invoice.releaser) {
                    form.setValue('itemReleasedDoc', {
                        itemReleasedFio: invoice.releaser.name || '',
                        itemReleasedPinfl: invoice.releaser.pinfl || invoice.releaser.inn || '',
                    })
                }
            }

            // set facturaEmpowermentDoc
            {
                if (invoice.empowerment) {
                    form.setValue('facturaEmpowermentDoc', {
                        empowermentNo: invoice.empowerment.number || '',
                        empowermentDateOfIssue: invoice.empowerment.date_of_issue ? dayjs(invoice.empowerment.date_of_issue).toDate() : null,
                        agentPinfl: invoice.empowerment.pinfl || invoice.empowerment.inn || '',
                        agentFio: invoice.empowerment.name || '',
                    });
                }
            }

            // set seller
            {
                //TODO: if phone numbers/district info given set
                form.setValue('seller', {
                    innOrPinfl: invoice.executor.inn,
                    name: invoice.executor.name,
                    account: invoice.executor.account,
                    bankId: invoice.executor.mfo,
                    address: invoice.executor.address,
                    mobile: '',
                    workPhone: '',
                    oked: invoice.executor.oked,
                    districtId: null,
                    director: invoice.executor.director,
                    accountant: invoice.executor.accountant,
                    vatRegCode: invoice.executor.nds_registration_code,
                    vatRegStatus: invoice.executor.nds_status,
                    branch: (invoice.executor && invoice.executor.branch_code && invoice.executor.branch_name) ? {
                        code: invoice.executor.branch_code,
                        name: invoice.executor.branch_name
                    } : null
                });
            }

            // set buyer/singleSide info
            {
                if (roamingInvoiceFormWrapper.isSingleSided(invoice.single_sided_type)) {
                    form.setValue('isSingleSided', true);
                    form.setValue('singleSidedType', invoice.single_sided_type);
                    if (roamingInvoiceFormWrapper.isForImportService(invoice.single_sided_type) && invoice.foreign_company) {
                        form.setValue('foreignCompany', {
                            countryId: invoice.foreign_company.country_id || null,
                            name: invoice.foreign_company.name || '',
                            address: invoice.foreign_company.address || '',
                            bank: invoice.foreign_company.bank || '',
                            account: invoice.foreign_company.account || '',
                        });
                    }
                } else {
                    //TODO: if phone numbers/district info given set
                    form.setValue('buyer', {
                        innOrPinfl: invoice.customer.inn,
                        name: invoice.customer.name,
                        account: invoice.customer.account,
                        bankId: invoice.customer.mfo,
                        address: invoice.customer.address,
                        mobile: '',
                        workPhone: '',
                        oked: invoice.customer.oked,
                        districtId: null,
                        director: invoice.customer.director,
                        accountant: invoice.customer.accountant,
                        vatRegCode: invoice.customer.nds_registration_code,
                        vatRegStatus: invoice.customer.nds_status,
                        branch: (invoice.customer && invoice.customer.branch_code && invoice.customer.branch_name) ? {
                            code: invoice.customer.branch_code,
                            name: invoice.customer.branch_name
                        } : null
                    })
                }
            }

            // set waybill id info
            {
                form.setValue('waybillId', invoice.waybill_id || '');
            }

            // set incomeType
            {
                form.setValue('incomeType', invoice.income_type || null);
            }

            // set exchange info
            {
                if (invoice.lot_id) {
                    // set factura_investment_object_doc
                    {
                        if (invoice.factura_investment_object_doc && invoice.factura_investment_object_doc?.object_id && invoice.factura_investment_object_doc?.object_name) {
                            form.setValue(`hasInvestmentObject`, true);
                            form.setValue(`facturaInvestmentObjectDoc`, {
                                objectId: invoice.factura_investment_object_doc.object_id,
                                objectName: invoice.factura_investment_object_doc.object_name
                            })
                        }
                    }

                    // set exchange
                    {
                        const exchangeType = invoice.lot_id.substring(0, invoice.lot_id.lastIndexOf("-") + 1);
                        const exchangeNumber = invoice.lot_id.substring(invoice.lot_id.lastIndexOf("-") + 1);
                        form.setValue(`hasExchange`, true);
                        form.setValue('exchange', {
                            type: exchangeType,
                            number: exchangeNumber
                        });
                    }
                }
            }

            // set product list
            {
                form.setValue(`productList.hasExcise`, invoice.has_excise);
                form.setValue(`productList.hasMarking`, invoice.has_marking);
                form.setValue(`productList.hasCommittent`, invoice.has_committent);
                form.setValue(`productList.hideReportCommittent`, invoice.hide_report_committent);
                form.setValue(`productList.hasMedical`, invoice.has_medical);

                const products = invoice.items.map((item) => {
                    const json = {
                        catalog: null,
                        package: null,
                        measurement: null,
                        lgota: null,
                        origin: null,
                        committentName: item.committent_name || '',
                        committentTin: item.committent_inn || '',
                        committentVatRegCode: item.committent_nds_registration_code || '',
                        committentVatRegStatus: item.committent_nds_status || '',
                        barcode: item.barcode || '',
                        warehouseId: item.warehouse_id || null,
                        mark: {
                            productType: null,
                            kiz: [
                                {code: ''}
                            ],
                            nomUpak: [
                                {code: ''}
                            ],
                            identTransUpak: [
                                {code: ''}
                            ],
                        },
                        exchangeInfo: {
                            productCode: '',
                            productProperties: '',
                            planPositionId: 1,
                        },
                        name: item.name || '',
                        serial: item.serial || '',
                        baseSumma: item.base_price ? getNumberFixedValue(item.base_price, ROUND_SCALE_FOUR) : '',
                        profitRate: item.profit_rate ? getNumberFixedValue(item.profit_rate, ROUND_SCALE_FOUR) : '',
                        count: item.quantity ? getNumberFixedValue(item.quantity, ROUND_SCALE_FOUR) : '',
                        // countTon: '',
                        // countKm: '',
                        summa: item.price ? getNumberFixedValue(item.price, ROUND_SCALE_FOUR) : '',
                        exciseRate: item.excise_rate ? getNumberFixedValue(item.excise_rate, ROUND_SCALE_FOUR) : '',
                        exciseSum: item.excise_value ? getNumberFixedValue(item.excise_value, ROUND_SCALE_FOUR) : '',
                        deliverySum: item.total ? getNumberFixedValue(item.total, ROUND_SCALE_TWO) : '',
                        vatRate: item.without_vat ? '' : item.nds_rate.toString(),
                        vatSum: item.nds_value ? getNumberFixedValue(item.nds_value, ROUND_SCALE_TWO) : '',
                        deliverySumWithVat: item.total_with_nds ? getNumberFixedValue(item.total_with_nds, ROUND_SCALE_TWO) : '',
                    };

                    json['initialDeliverySum'] = item.total || null;
                    json['initialVatSum'] = item.nds_value || null;
                    json['initialDeliverySumWithVat'] = item.total_with_nds || null;

                    // set item tax values
                    {
                        let catalog = null;
                        let catalogPackage = null;
                        let lgota = null;
                        let origin = null;

                        if (item.catalog?.code)
                            catalog = catalogs.find(c => c.class_code === item.catalog?.code);

                        if (item.package?.code && catalog && catalog.use_package)
                            catalogPackage = catalog.package_names.find(p => p.code === parseInt(item.package.code))

                        if (invoice.has_lgota && item.lgota_id)
                            lgota = findLgota(item.lgota_id, catalog);

                        if (item.origin)
                            origin = item.origin;

                        json.catalog = catalog;
                        json.package = catalogPackage;
                        json.lgota = lgota;
                        json.origin = origin;
                    }

                    // set item mark
                    {
                        if (invoice.has_marking && item.marking && item.marking.product_type && (item.marking.kiz?.length || item.marking.nom_upak?.length || item.marking.ident_trans_upak?.length)) {
                            json.mark = {
                                productType: item.marking.product_type,
                                kiz: [{code: ""}],
                                nomUpak: [{code: ""}],
                                identTransUpak: [{code: ""}],
                            }

                            if (item.marking.kiz.length)
                                json.mark.kiz = item.marking.kiz.map((code) => ({code: code}))

                            if (item.marking.nom_upak.length)
                                item.marking.nom_upak.map((code) => ({code: code}))

                            if (item.marking.ident_trans_upak.length)
                                item.marking.ident_trans_upak.map((code) => ({code: code}))
                        }
                    }

                    // set item exchange
                    {
                        if (invoice.lot_id && item.exchange_info) {
                            json.exchangeInfo = {
                                productCode: item.exchange_info.product_code || '',
                                productProperties: item.exchange_info.product_properties || '',
                                planPositionId: item.exchange_info.plan_position_id || '',
                            }
                        }
                    }

                    return json;
                })
                form.setValue(`productList.products`, products);
            }
        }
    }, [invoice, type, catalogs, sellerCompanyPrivileges, turnoverCompanyPrivileges]);

    useEffect( () => {
        if (draftInvoice) {
            const roamingInvoiceJson = JSON.parse(draftInvoice.content);

            // set facturaId, facturaProductId, facturaType, facturaDoc and oldFacturaDoc
            {
                if (isEdit) {
                    form.setValue(`facturaId`, roamingInvoiceJson.FacturaId || '')
                }

                if (type) {
                    form.setValue('facturaType', parseInt(type));
                    if (roamingInvoiceFormWrapper.isAdditionalOrCorrective(parseInt(type))) {
                        if (roamingInvoiceFormWrapper.isCorrective(parseInt(type))) {
                            form.setValue('facturaDoc', {
                                facturaNo: roamingInvoiceJson.FacturaDoc.FacturaNo,
                                facturaDate: null
                            })
                        }
                        form.setValue('oldFacturaDoc', {
                            oldFacturaId: roamingInvoiceJson.OldFacturaDoc.OldFacturaId,
                            oldFacturaNo: roamingInvoiceJson.OldFacturaDoc.OldFacturaNo,
                            oldFacturaDate: dayjs(roamingInvoiceJson.OldFacturaDoc.OldFacturaDate).toDate()
                        });
                    } else {
                        form.setValue('facturaDoc', {
                            facturaNo: roamingInvoiceJson.FacturaDoc.FacturaNo,
                            facturaDate: dayjs(roamingInvoiceJson.FacturaDoc.FacturaDate).toDate()
                        })
                    }
                } else {
                    form.setValue('facturaType', roamingInvoiceJson.FacturaType);
                    if (roamingInvoiceFormWrapper.isAdditionalOrCorrective(roamingInvoiceJson.FacturaType)) {
                        form.setValue('oldFacturaDoc', {
                            oldFacturaId: roamingInvoiceJson.OldFacturaDoc.OldFacturaId,
                            oldFacturaNo: roamingInvoiceJson.OldFacturaDoc.OldFacturaNo,
                            oldFacturaDate: dayjs(roamingInvoiceJson.OldFacturaDoc.OldFacturaDate).toDate()
                        });
                        form.setValue('facturaDoc', {
                            facturaNo: roamingInvoiceJson.FacturaDoc.FacturaNo,
                            facturaDate: dayjs(roamingInvoiceJson.FacturaDoc.FacturaDate).toDate()
                        });
                    } else {
                        form.setValue('facturaDoc', {
                            facturaNo: roamingInvoiceJson.FacturaDoc.FacturaNo,
                            facturaDate: dayjs(roamingInvoiceJson.FacturaDoc.FacturaDate).toDate()
                        })
                    }
                }
            }

            // set contractDoc
            {
                form.setValue('contractDoc', {
                    contractId: roamingInvoiceJson.ContractId || null,
                    contractNo: roamingInvoiceJson.ContractDoc.ContractNo,
                    contractDate: dayjs(roamingInvoiceJson.ContractDoc.ContractDate).toDate()
                })
            }

            // set itemReleasedDoc
            {
                if (roamingInvoiceJson.ItemReleasedDoc) {
                    form.setValue('itemReleasedDoc', {
                        itemReleasedFio: roamingInvoiceJson.ItemReleasedDoc.ItemReleasedFio || '',
                        itemReleasedPinfl: roamingInvoiceJson.ItemReleasedDoc.ItemReleasedPinfl || '',
                    })
                }
            }

            // set facturaEmpowermentDoc
            {
                if (roamingInvoiceJson.FacturaEmpowermentDoc) {
                    form.setValue('facturaEmpowermentDoc', {
                        empowermentNo: roamingInvoiceJson.FacturaEmpowermentDoc.EmpowermentNo || '',
                        empowermentDateOfIssue: roamingInvoiceJson.FacturaEmpowermentDoc.EmpowermentDateOfIssue ? dayjs(roamingInvoiceJson.FacturaEmpowermentDoc.EmpowermentDateOfIssue).toDate() : null,
                        agentPinfl: roamingInvoiceJson.FacturaEmpowermentDoc.AgentPinfl || '',
                        agentFio: roamingInvoiceJson.FacturaEmpowermentDoc.AgentFio || '',
                    });
                }
            }

            // set seller
            {
                //TODO: if phone numbers/district info given set
                form.setValue('seller', {
                    innOrPinfl: roamingInvoiceJson.SellerTin,
                    name: roamingInvoiceJson.Seller.Name || '',
                    account: roamingInvoiceJson.Seller.Account || '',
                    bankId: roamingInvoiceJson.Seller.BankId || '',
                    address: roamingInvoiceJson.Seller.Address || '',
                    mobile: roamingInvoiceJson.Seller.Mobile || '',
                    workPhone: roamingInvoiceJson.Seller.WorkPhone || '',
                    oked: roamingInvoiceJson.Seller.Oked || '',
                    districtId: roamingInvoiceJson.Seller.DistrictId || null,
                    director: roamingInvoiceJson.Seller.Director || '',
                    accountant: roamingInvoiceJson.Seller.Accountant || '',
                    vatRegCode: roamingInvoiceJson.Seller.VatRegCode || '',
                    vatRegStatus: roamingInvoiceJson.Seller.VatRegStatus || '',
                    branch: (roamingInvoiceJson.Seller.BranchCode && roamingInvoiceJson.Seller.BranchName) ?  {
                        code: roamingInvoiceJson.Seller.BranchCode,
                        name: roamingInvoiceJson.Seller.BranchName
                    } : null
                });
            }

            // set buyer/singleSide info
            {
                if (roamingInvoiceFormWrapper.isSingleSided(roamingInvoiceJson.SingleSidedType)) {
                    form.setValue('isSingleSided', true);
                    form.setValue('singleSidedType', roamingInvoiceJson.SingleSidedType);
                    if (roamingInvoiceFormWrapper.isForImportService(roamingInvoiceJson.SingleSidedType) && roamingInvoiceJson.ForeignCompany) {
                        form.setValue('foreignCompany', {
                            countryId: roamingInvoiceJson.ForeignCompany.CountryId || null,
                            name: roamingInvoiceJson.ForeignCompany.Name || '',
                            address: roamingInvoiceJson.ForeignCompany.Address || '',
                            bank: roamingInvoiceJson.ForeignCompany.Bank || '',
                            account: roamingInvoiceJson.ForeignCompany.Account || '',
                        });
                    }
                } else {
                    //TODO: if phone numbers/district info given set
                    form.setValue('buyer', {
                        innOrPinfl: roamingInvoiceJson.BuyerTin,
                        name: roamingInvoiceJson.Buyer.Name || '',
                        account: roamingInvoiceJson.Buyer.Account || '',
                        bankId: roamingInvoiceJson.Buyer.BankId || '',
                        address: roamingInvoiceJson.Buyer.Address || '',
                        mobile: roamingInvoiceJson.Buyer.Mobile || '',
                        workPhone: roamingInvoiceJson.Buyer.WorkPhone || '',
                        oked: roamingInvoiceJson.Buyer.Oked || '',
                        districtId: roamingInvoiceJson.Buyer.DistrictId || null,
                        director: roamingInvoiceJson.Buyer.Director || '',
                        accountant: roamingInvoiceJson.Buyer.Accountant || '',
                        vatRegCode: roamingInvoiceJson.Buyer.VatRegCode || '',
                        vatRegStatus: roamingInvoiceJson.Buyer.VatRegStatus || '',
                        branch: (roamingInvoiceJson.Buyer.BranchCode && roamingInvoiceJson.Buyer.BranchName) ?  {
                            code: roamingInvoiceJson.Buyer.BranchCode,
                            name: roamingInvoiceJson.Buyer.BranchName
                        } : null
                    })
                }
            }

            // set waybill id info
            {
                form.setValue('waybillId', (roamingInvoiceJson.WaybillIds && Array.isArray(roamingInvoiceJson.WaybillIds) && roamingInvoiceJson.WaybillIds.length) ? roamingInvoiceJson.WaybillIds[0] : '');
            }

            // set incomeType
            {
                form.setValue('incomeType', (roamingInvoiceJson.IncomeType !== null || roamingInvoiceJson.IncomeType !== 0) ? roamingInvoiceJson.IncomeType : null);
            }

            // set exchange info
            {
                if (roamingInvoiceJson.LotId) {
                    // set factura_investment_object_doc
                    {
                        if (roamingInvoiceJson.FacturaInvestmentObjectDoc && roamingInvoiceJson.FacturaInvestmentObjectDoc?.ObjectId && roamingInvoiceJson.FacturaInvestmentObjectDoc?.ObjectName) {
                            form.setValue(`hasInvestmentObject`, true);
                            form.setValue(`facturaInvestmentObjectDoc`, {
                                objectId: roamingInvoiceJson.FacturaInvestmentObjectDoc.ObjectId,
                                objectName: roamingInvoiceJson.FacturaInvestmentObjectDoc.ObjectName
                            })
                        }
                    }

                    // set exchange
                    {
                        const exchangeType = roamingInvoiceJson.LotId.substring(0, roamingInvoiceJson.LotId.lastIndexOf("-") + 1);
                        const exchangeNumber = roamingInvoiceJson.LotId.substring(roamingInvoiceJson.LotId.lastIndexOf("-") + 1);
                        console.log(exchangeNumber, exchangeType, "asdasdasd");
                        form.setValue(`hasExchange`, true);
                        form.setValue('exchange', {
                            type: exchangeType,
                            number: exchangeNumber
                        });
                    }
                }
            }

            // set product list
            {
                form.setValue(`productList.hasExcise`, roamingInvoiceJson.ProductList.HasExcise);
                form.setValue(`productList.hasMarking`, roamingInvoiceJson.HasMarking);
                form.setValue(`productList.hasCommittent`, roamingInvoiceJson.ProductList.HasCommittent);
                form.setValue(`productList.hideReportCommittent`, roamingInvoiceJson.ProductList.HideReportCommittent);
                form.setValue(`productList.hasMedical`, roamingInvoiceJson.ProductList.HasMedical);

                const products = roamingInvoiceJson.ProductList.Products.map((item) => {
                    const json = {
                        catalog: null,
                        package: null,
                        measurement: null,
                        lgota: null,
                        origin: null,
                        committentName: item.CommittentName || '',
                        committentTin: item.CommittentTin || '',
                        committentVatRegCode: item.CommittentVatRegCode || '',
                        committentVatRegStatus: item.CommittentVatRegStatus || '',
                        barcode: item.Barcode || '',
                        warehouseId: item.WarehouseId || null,
                        mark: {
                            productType: null,
                            kiz: [
                                {code: ''}
                            ],
                            nomUpak: [
                                {code: ''}
                            ],
                            identTransUpak: [
                                {code: ''}
                            ],
                        },
                        exchangeInfo: {
                            productCode: '',
                            productProperties: '',
                            planPositionId: 1,
                        },
                        name: item.Name || '',
                        serial: item.Serial || '',
                        baseSumma: item.BaseSumma ? getNumberFixedValue(item.BaseSumma, ROUND_SCALE_FOUR) : '',
                        profitRate: item.ProfitRate ? getNumberFixedValue(item.ProfitRate, ROUND_SCALE_FOUR) : '',
                        count: item.Count ? getNumberFixedValue(item.Count, ROUND_SCALE_FOUR) : '',
                        // countTon: '',
                        // countKm: '',
                        summa: item.Summa ? getNumberFixedValue(item.Summa, ROUND_SCALE_FOUR) : '',
                        exciseRate: item.ExciseRate ? getNumberFixedValue(item.ExciseRate, ROUND_SCALE_FOUR) : '',
                        exciseSum: item.ExciseSum ? getNumberFixedValue(item.ExciseSum, ROUND_SCALE_FOUR) : '',
                        deliverySum: item.DeliverySum ? getNumberFixedValue(item.DeliverySum, ROUND_SCALE_TWO) : '',
                        vatRate: item.WithoutVat ? '' : item.VatRate.toString(),
                        vatSum: item.VatSum ? getNumberFixedValue(item.VatSum, ROUND_SCALE_TWO) : '',
                        deliverySumWithVat: item.DeliverySumWithVat ? getNumberFixedValue(item.DeliverySumWithVat, ROUND_SCALE_TWO) : '',
                    };

                    json['initialDeliverySum'] = item.DeliverySum || null;
                    json['initialVatSum'] = item.VatSum || null;
                    json['initialDeliverySumWithVat'] = item.DeliverySumWithVat || null;

                    // set item tax values
                    {
                        let catalog = null;
                        let catalogPackage = null;
                        let lgota = null;
                        let origin = null;

                        if (item.CatalogCode)
                            catalog = catalogs.find(c => c.class_code === item.CatalogCode);

                        if (item.PackageCode && catalog && catalog.use_package)
                            catalogPackage = catalog.package_names.find(p => p.code === parseInt(item.PackageCode))

                        if (roamingInvoiceJson.ProductList.HasLgota && item.LgotaId)
                            lgota = findLgota(parseInt(item.LgotaId), catalog);

                        if (item.Origin)
                            origin = item.Origin;

                        json.catalog = catalog;
                        json.package = catalogPackage;
                        json.lgota = lgota;
                        json.origin = origin;
                    }

                    // set item mark
                    {
                        if (roamingInvoiceJson.HasMarking && item.Marks && item.Marks.ProductType && (item.Marks.KIZ?.length || item.Marks.NomUpak?.length || item.Marks.IdentTransUpak?.length)) {
                            json.mark = {
                                productType: item.Marks.ProductType,
                                kiz: [{code: ""}],
                                nomUpak: [{code: ""}],
                                identTransUpak: [{code: ""}],
                            }

                            if (item.Marks.KIZ.length)
                                json.mark.kiz =  item.Marks.KIZ.map((code) => ({code: code}))

                            if (item.Marks.NomUpak.length)
                                json.mark.nomUpak = item.Marks.NomUpak.map((code) => ({code: code}))

                            if (item.Marks.IdentTransUpak.length)
                                json.mark.identTransUpak = item.Marks.IdentTransUpak.map((code) => ({code: code}))
                        }
                    }

                    // set item exchange
                    {
                        if (roamingInvoiceJson.LotId && item.ExchangeInfo) {
                            json.exchangeInfo = {
                                productCode: item.ExchangeInfo.ProductCode.toString() || '',
                                productProperties: item.ExchangeInfo.ProductProperties.toString() || '',
                                planPositionId: item.ExchangeInfo.PlanPositionId.toString() || '',
                            }
                        }
                    }

                    return json;
                });
                form.setValue(`productList.products`, products);
            }
        }
    }, [draftInvoice, isEdit, catalogs, type, sellerCompanyPrivileges, turnoverCompanyPrivileges]);

    useEffect(  () => {
        if (sale) {
            if (sale.contractor?.inn)
                form.setValue('buyer.innOrPinfl', sale.contractor.inn);

            const products = sale.items.map((item) => {
                const json = {
                    catalog: null,
                    package: null,
                    measurement: null,
                    lgota: null,
                    origin: null,
                    committentName: '',
                    committentTin: '',
                    committentVatRegCode: '',
                    committentVatRegStatus: '',
                    barcode: '',
                    warehouseId: null,
                    mark: {
                        productType: null,
                        kiz: [
                            {code: ''}
                        ],
                        nomUpak: [
                            {code: ''}
                        ],
                        identTransUpak: [
                            {code: ''}
                        ],
                    },
                    exchangeInfo: {
                        productCode: '',
                        productProperties: '',
                        planPositionId: '',
                    },
                    name: '',
                    serial: '',
                    baseSumma: '',
                    profitRate: '',
                    count: '',
                    countTon: '',
                    countKm: '',
                    summa: '',
                    exciseRate: '',
                    exciseSum: '',
                    deliverySum: '',
                    vatRate: '',
                    vatSum: '',
                    deliverySumWithVat: '',
                };
                const originalItem = items.find(i => i.item.name.trim().toLowerCase() === item.warehouse_item.name.trim().toLowerCase());

                // set sale values
                {
                    json.name = item.warehouse_item.name || '';
                    json.count = item.quantity || '';
                    json.summa = item.price.currency.name !== UZS ? getNumberFixedValue(currencyWrapper.convertToNationalPrice(currencies, item.price.currency.name, item.price.amount), ROUND_SCALE_FOUR) : getNumberFixedValue(item.price.amount, ROUND_SCALE_FOUR);
                }

                // set item values
                {
                    if (originalItem) {
                        json.barcode = originalItem.item.barcodes.length ? originalItem.item.barcodes[0] : '';

                        // set item tax values
                        {
                            let catalog = null;
                            let catalogPackage = null;
                            let lgota = null;
                            let origin = null;
                            let vatRate = '';

                            if (originalItem.item.tax) {
                                try {
                                    if (originalItem.item.tax.catalog?.code)
                                        catalog = catalogs.find(c => c.class_code === originalItem.item.tax.catalog.code);

                                    if (originalItem.item.tax.catalog?.package?.code && catalog)
                                        catalogPackage = catalog.package_names.find(p => p.code === parseInt(originalItem.item.tax.catalog.package.code))

                                    if (originalItem.item.tax.benefit?.id)
                                        lgota = findLgota(originalItem.item.tax.benefit.id, catalog);

                                    if (originalItem.item.tax.origin)
                                        origin = originalItem.item.tax.origin;

                                    if (originalItem.item.tax.tax_rate !== null)
                                        vatRate = originalItem.item.tax.tax_rate.toString();
                                } catch (error) {
                                    console.log("catalog not found: ", originalItem.item.tax.catalog.code);
                                }
                            }

                            json.catalog = catalog;
                            json.package = catalogPackage;
                            json.lgota = lgota;
                            json.origin = origin;
                            json.vatRate = vatRate;
                        }
                    }
                }

                return json;
            })
            form.setValue(`productList.products`, products);
        }
    }, [sale, items, catalogs, currencies, sellerCompanyPrivileges, turnoverCompanyPrivileges]);

    useEffect(() => {
        if (waybill) {
            form.setValue(`waybillId`, waybill.id);
            form.setValue(`contractDoc.contractNo`, waybill.contract_info.number);
            form.setValue(`contractDoc.contractDate`, waybill.contract_info.date ? dayjs(waybill.contract_info.date).toDate() : null);
            form.setValue(`buyer.innOrPinfl`, waybill.buyer.inn);

            const products = waybill.items.map((product) => {
                const json = {
                    catalog: null,
                    package: null,
                    measurement: null,
                    lgota: null,
                    origin: null,
                    committentName: '',
                    committentTin: '',
                    committentVatRegCode: '',
                    committentVatRegStatus: '',
                    barcode: '',
                    warehouseId: null,
                    mark: {
                        productType: null,
                        kiz: [
                            {code: ''}
                        ],
                        nomUpak: [
                            {code: ''}
                        ],
                        identTransUpak: [
                            {code: ''}
                        ],
                    },
                    exchangeInfo: {
                        productCode: '',
                        productProperties: '',
                        planPositionId: '',
                    },
                    name: '',
                    serial: '',
                    baseSumma: '',
                    profitRate: '',
                    count: '',
                    countTon: '',
                    countKm: '',
                    summa: '',
                    exciseRate: '',
                    exciseSum: '',
                    deliverySum: '',
                    vatRate: '',
                    vatSum: '',
                    deliverySumWithVat: '',
                };
                json.name = product.name || '';
                json.summa = product.price ? getNumberFixedValue(product.price, ROUND_SCALE_FOUR) : '';
                json.count = product.quantity ?  getNumberFixedValue(product.quantity, ROUND_SCALE_FOUR) : '';

                // set item tax info
                {
                    let catalog = null;
                    let catalogPackage = null;

                    if (product.catalog?.code) {
                        catalog = catalogs.find(c => c.class_code === product.catalog.code);

                        if (product.package?.code && catalog && catalog.use_package)
                            catalogPackage = catalog.package_names.find(p => p.code === parseInt(product.package.code))
                    }

                    json.catalog = catalog;
                    json.package = catalogPackage;
                }

                return json;
            });
            form.setValue(`productList.products`, products);
        }
    }, [waybill, catalogs]);

    useEffect(() => {
        if (invoice || draftInvoice || !company) return;
        form.setValue('seller', {
            innOrPinfl: company.person_num || company.inn,
            name: company.short_name || company.name,
            account: company.account || (company.bank_accounts?.length > 0 ? company.bank_accounts[0].account : ''),
            bankId: company.mfo || (company.bank_accounts?.length > 0 ? company.bank_accounts[0].bankId : ''),
            address: company.address,
            mobile: '',
            workPhone: '',
            oked: company.oked,
            districtId: company.district_id,
            director: company.director,
            accountant: company.accountant,
            vatRegCode: company.vat_reg_code,
            vatRegStatus: company.vat_rate_status,
            branch: null
        });
    }, [invoice, draftInvoice, company])

    const onSend = async (formData) => {
        try {
            setErrorMessage(null);
            const roamingInvoiceHashCode = await getDocumentGeneratorHashcode(formData);
            dispatch(updateShowInProgressAsync(true));
            sendAsync(activeCertificate, lang, roamingInvoiceHashCode)
                .then((invoice) => {
                    dispatch(updateShowInProgressAsync(false));
                    toast.success(t(lang, "roaming.invoice.alert.success"));
                    navigate(`/roaming/invoice/${invoice.id}`)
                })
                .catch((error) => {
                    dispatch(updateShowInProgressAsync(false));
                    setErrorMessage(error.message)
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
                })
        } catch (error) {
            console.log(error);
        }
    };

    const onSave = async (formData) => {
        try {
            const roamingInvoiceHashCode = await getDocumentGeneratorHashcode(formData);
            dispatch(updateShowInProgressAsync(true));
            saveAsync(roamingInvoiceHashCode, lang)
                .then((invoice) => {
                    dispatch(updateShowInProgressAsync(false));
                    toast.success(t(lang, "roaming.invoice.alert.success"));
                    navigate(`/roaming/invoice/draft/${invoice.id}`)
                })
                .catch((error) => {
                    dispatch(updateShowInProgressAsync(false));
                    setErrorMessage(error.message)
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
                })
        } catch (error) {
            console.log(error);
        }
    };

    const getDocumentGeneratorHashcode = async (formData) => {
        try {
            const roamingDateDayJsFormat = 'YYYY-MM-DD';
            const documentGeneratorJson = {
                type: parseInt(formData.facturaType),
                invoice_info: {
                    number: formData.facturaDoc.facturaNo,
                    date: dayjs(formData.facturaDoc.facturaDate).format(roamingDateDayJsFormat),
                },
                contract_info: {
                    number: formData.contractDoc.contractNo,
                    date: dayjs(formData.contractDoc.contractDate).format(roamingDateDayJsFormat),
                },
                empowerment_info: {
                    pinfl: formData.facturaEmpowermentDoc.agentPinfl || "",
                    name: formData.facturaEmpowermentDoc.agentFio || "",
                    number: formData.facturaEmpowermentDoc.empowermentNo || "",
                    date: formData.facturaEmpowermentDoc.empowermentDateOfIssue ? dayjs(formData.facturaEmpowermentDoc.empowermentDateOfIssue).format(roamingDateDayJsFormat) : null
                },
                releaser_info: {
                    pinfl: formData.itemReleasedDoc.itemReleasedPinfl || "",
                    name: formData.itemReleasedDoc.itemReleasedFio || "",
                },
                executor: {
                    inn: formData.seller.innOrPinfl || "",
                    name: formData.seller.name || "",
                    bank_account: formData.seller.account || "",
                    bank_id: formData.seller.bankId || "",
                    address: formData.seller.address || "",
                    mobile: formData.seller.mobile || "",
                    work_phone: formData.seller.workPhone || "",
                    oked: formData.seller.oked || "",
                    district_id: formData.seller.districtId || "",
                    director: formData.seller.director || "",
                    accountant: formData.seller.accountant || "",
                    vat_reg_code: formData.seller.vatRegCode || null,
                    vat_reg_status: formData.seller.vatRegStatus ? +formData.seller.vatRegStatus : null,
                    branch_code: formData.seller.branch?.code || "",
                    branch_name: formData.seller.branch?.name || ""
                },
                items: []
            };

            if (isEdit) {
                documentGeneratorJson["id"] = formData.facturaId
            }

            if (roamingInvoiceFormWrapper.hasIncome(parseInt(formData.incomeType))) {
                documentGeneratorJson["income_type"] = parseInt(formData.incomeType);
            }

            if (roamingInvoiceFormWrapper.isAdditionalOrCorrective(documentGeneratorJson.type)) {
                documentGeneratorJson["old_invoice"] = {
                    id: formData.oldFacturaDoc.oldFacturaId,
                    number: formData.oldFacturaDoc.oldFacturaNo,
                    date: dayjs(formData.oldFacturaDoc.oldFacturaDate).format(roamingDateDayJsFormat),
                }
            }

            if (formData.isSingleSided && roamingInvoiceFormWrapper.isSingleSided(parseInt(formData.singleSidedType))) {
                documentGeneratorJson["single_sided_type"] = parseInt(formData.singleSidedType);
                if (roamingInvoiceFormWrapper.isForImportService(parseInt(formData.singleSidedType))) {
                    documentGeneratorJson["foreign_company"] = {
                        id: formData.foreignCompany.countryId || "",
                        name: formData.foreignCompany.name || "",
                        address: formData.foreignCompany.address || "",
                        bank: formData.foreignCompany.bank || "",
                        account: formData.foreignCompany.account || "",
                    }
                }
            } else {
                documentGeneratorJson["customer"] = {
                    inn: formData.buyer.innOrPinfl || "",
                    name: formData.buyer.name || "",
                    bank_account: formData.buyer.account || "",
                    bank_id: formData.buyer.bankId || "",
                    address: formData.buyer.address || "",
                    mobile: formData.buyer.mobile || "",
                    work_phone: formData.buyer.workPhone || "",
                    oked: formData.buyer.oked || "",
                    district_id: formData.buyer.districtId || "",
                    director: formData.buyer.director || "",
                    accountant: formData.buyer.accountant || "",
                    vat_reg_code: formData.buyer.vatRegCode || null,
                    vat_reg_status: formData.buyer.vatRegStatus ? +formData.buyer.vatRegStatus : null,
                    branch_code: formData.buyer.branch?.code || "",
                    branch_name: formData.buyer.branch?.name || ""
                };
            }

            if (formData.hasExchange && formData.exchange.type && formData.exchange.number) {
                documentGeneratorJson["lot_id"] = `${formData.exchange.type}${formData.exchange.number}`
            }

            if (formData.contractDoc.contractId) {
                documentGeneratorJson["contract_id"] = parseInt(formData.contractDoc.contractId)
            }

            if (formData.waybillId) {
                documentGeneratorJson["waybill_id"] = parseInt(formData.waybillId)
            }

            if (formData.facturaInvestmentObjectDoc && formData.facturaInvestmentObjectDoc.objectId) {
                documentGeneratorJson["investment_object"] = {
                    id: formData.facturaInvestmentObjectDoc.objectId,
                    name: formData.facturaInvestmentObjectDoc.objectName,
                }
            }

            documentGeneratorJson.items = formData.productList.products.map((item, index) => {
                const json = {
                    catalog_code: item.catalog.class_code,
                    catalog_name: item.catalog.name,
                    barcode: item.barcode,
                    warehouse_id: item.warehouseId,
                    name: item.name,
                    measurement: item.package ? item.package.code.toString() : item.measurement.measureId.toString(),
                    quantity: parseFloat(getNumberFixedValue(item.count || 0, ROUND_SCALE_FOUR)),
                    price: parseFloat(getNumberFixedValue(item.summa || 0, ROUND_SCALE_FOUR)),
                    vat_rate: parseInt(item.vatRate),
                    origin: item.origin
                }

                if (formData.manualCalculation) {
                    json['total'] = parseFloat(item.deliverySum);
                    json['vat_sum'] = parseFloat(item.vatSum);
                    json['total_with_vat'] = parseFloat(item.deliverySumWithVat);
                }

                if (item.lgota) {
                    json["benefit"] = item.lgota?.new_id?.toString() || item.lgota?.id.toString();
                    json["benefit_name"] = item.lgota.name;
                    json["benefit_type"] = item.lgota.type;
                }

                if (formData.productList.hasCommittent && item.committentTin) {
                    json["committent_inn"] = item.committentTin || "";
                    json["committent_name"] = item.committentName || "";
                    json["committent_vat_reg_code"] = item.committentVatRegCode || null;
                    json["committent_vat_reg_status"] = item.committentVatRegStatus || null;
                }

                if (formData.productList.hasMedical && item.profitRate && item.baseSumma) {
                    json["profit_rate"] = parseFloat(getNumberFixedValue(item.profitRate, ROUND_SCALE_FOUR));
                    json["base_price"] = parseFloat(getNumberFixedValue(item.baseSumma, ROUND_SCALE_FOUR));
                    json["serial"] = item.serial || "";
                }

                if (formData.productList.hasExcise && item.exciseRate && item.exciseSum) {
                    json["excise_rate"] = parseFloat(getNumberFixedValue(item.exciseRate, ROUND_SCALE_FOUR));
                    json["excise_sum"] = parseFloat(getNumberFixedValue(item.exciseSum, ROUND_SCALE_FOUR));
                }

                if (formData.productList.hasMarking) {
                    if (item.mark && item.mark.productType && (item.mark.kiz.some(c => c.code) || item.mark.nomUpak.some(c => c.code) || item.mark.identTransUpak.some(c => c.code)))
                        json["marking"] = {
                            product_type: parseInt(item.mark.productType),
                            kiz: item.mark.kiz.filter(c => c.code).map(c => c.code),
                            nom_upak: item.mark.nomUpak.filter(c => c.code).map(c => c.code),
                            ident_trans_upak: item.mark.identTransUpak.filter(c => c.code).map(c => c.code)
                        };
                }

                if (documentGeneratorJson.lot_id) {
                    if (item.exchangeInfo && item.exchangeInfo.productCode && item.exchangeInfo.productProperties) {
                        json["exchange"] = {
                            product_code: item.exchangeInfo.productCode.toString() || "",
                            product_properties: item.exchangeInfo.productProperties.toString() || "",
                            plan_position_id: item.exchangeInfo.planPositionId.toString() || ""
                        };
                    }
                }

                return json;
            })

            console.log(documentGeneratorJson);
            const {hashcode} = await generateRoamingInvoiceSendHashCode(documentGeneratorJson);
            return hashcode;
        } catch (error) {
            throw error;
        }
    };

    const findLgota = (lgotaId, catalog) => {
        let lgota = null;

        lgota = sellerCompanyPrivileges.find(p => p.new_id === lgotaId || p.id === lgotaId);
        if (lgota) return lgota;

        lgota = turnoverCompanyPrivileges.find(p => p.id === lgotaId);
        if (lgota) return lgota;

        if (catalog)
            lgota = catalog.lgotas.find(l => l.new_id === lgotaId || l.id === lgotaId);

        return lgota;
    };


    return (
        <Fragment>
            <InProgress/>
            {errorMessage &&
                <IconAlert text={errorMessage} className={'alert-davr-danger mb-2'} dismissible variant={'danger'}>
                    <p className="mb-0 fs-8">{t(lang, 'Error')}</p>
                    <p className="mb-0">{t(lang, errorMessage)}</p>
                    <div className="d-flex justify-content-end">
                        <Button variant={'phoenix-secondary'} className={'btn-link text-danger'}
                                href="https://t.me/+lpgw1qdQN0k1MWMy">{t(lang, "main.warning_alert.request_button")}</Button>
                    </div>
                </IconAlert>}
            <FormProvider {...form}>
                <RoamingInvoiceForm
                    onSend={form.handleSubmit(onSend)}
                    onSave={form.handleSubmit(onSave)}
                    isEditOrCopy={isEditOrCopy}
                />
            </FormProvider>
        </Fragment>
    );
};

export default SendRoamingInvoiceForm;
