import React from 'react';
import IconArrowLeft from "../../../../assets/icon/IconArrowLeft";
import {useNavigate} from "react-router-dom";
import {Card} from "react-bootstrap";
import OrganizationChooser from "../../../organization/OrganizationChooser";

const ChooseOrganization = () => {

    const navigate = useNavigate()

    return (
        <Card className={'w-100 h-100 overflow-hidden rounded-5 p-3'}>
            <div>
                <div className={'position-relative'}>
                    <button onClick={() => navigate(-1)}
                            className={'main-text btn-back bg-secondary-davr position-absolute'}
                            style={{width: 35, height: 35, left: 0, bottom: 0}}>
                        <IconArrowLeft/>
                    </button>
                    <h5 className={'text-center text-dark'}>Выберите организацию</h5>
                    <p className={'text-center fs-9 second-text'}>Организация</p>
                </div>
            </div>
            <Card className={"p-1"} style={{height: "calc(100% - 55px)"}}>
                <OrganizationChooser/>
            </Card>
        </Card>
    );
};

export default ChooseOrganization;