import React, {useState} from 'react';
import EventBus from "../../../eventbus/EventBus";
import {
    ROAMING_INVOICE_BULK_ACTION_FINISHED,
    ROAMING_INVOICE_BULK_ACTION_STARTED
} from "../../../eventbus/romaing/roaming-invoice/roamingInvoiceEvents";
import {personAcceptAsync} from "../../../store/reducers/roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import {selectCurrentCertificate} from "../../../store/reducers/eimzo/eimzoReducer";
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import IconButton from "../../IconButton";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {Spinner} from "react-bootstrap";
import {PermissionRoamingInvoiceAccept} from "../../../enum/permission/RoamingPermission";
import SelectCertificateWrapperComponent from "../../common/SelectCertificateWrapperComponent";
import IconSignature from "../../../assets/icon/IconSignature";

const RoamingInvoicePersonAccept = ({id, invoices, handleShowProgressBar, updateActionResult, ...props}) => {

    const lang = useSelector(selectLang);
    const t = Translate;
    const activeCertificate = useSelector(selectCurrentCertificate);

    // button loader
    const [loading, setLoading] = useState(false);

    // accept invoice
    const personAccept = () => {
        if (invoices.length > 0)
            bulkAccept();
        else
            singleAccept();
    }

    // accept single document
    const singleAccept = () => {
        setLoading(true);
        personAcceptAsync(activeCertificate, lang, id)
            .then(() => {
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            })
    }

    // accept multiple documents
    const bulkAccept = async () => {
        handleShowProgressBar();
        EventBus.dispatch(ROAMING_INVOICE_BULK_ACTION_STARTED);
        for (let i = 0; i < invoices.length; i++) {
            const invoice = invoices[i];
            try {
                await personAcceptAsync(activeCertificate, lang, invoices[i].id);
                updateActionResult({
                    number: invoice.info.number,
                    date: invoice.info.date,
                    contractor: {
                        name: invoice.executor.name,
                        inn: invoice.executor.inn
                    }
                })
            } catch (error) {
                updateActionResult({
                    number: invoice.info.number,
                    date: invoice.info.date,
                    contractor: {
                        name: invoice.executor.name,
                        inn: invoice.executor.inn
                    },
                    error: error?.message
                });
            }
        }
        EventBus.dispatch(ROAMING_INVOICE_BULK_ACTION_FINISHED);
    }

    return (
        <SelectCertificateWrapperComponent
            className={'btn-davr bg-primary-davr gap-1 text-white'}
            icon={<IconSignature />}
            openDialogButtonText={t(lang, "roaming.common.accept")}
            permission={PermissionRoamingInvoiceAccept}
            submitButton={
                <IconButton
                    onClick={personAccept}
                    variant="phoenix-primary"
                    size="sm"
                    className="fw-semi-bold"
                    id="PersonAccept"
                    icon={faCheck}
                    disabled={loading}
                    {...props}
                >
                    {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                    {t(lang, 'roaming.common.accept')}
                </IconButton>}
        />
    );
};

export default RoamingInvoicePersonAccept;
