import React, {Fragment, useEffect} from 'react';
import {useFormContext} from "react-hook-form";
import {selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {Col, Form, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import Flex from "../../../common/Flex";

const ForgotPasswordStepTwo = ({
                                   invalidConfirmCode, footer, header = () => {
    }
                               }) => {
    const {register, formState: {errors}, trigger, setFocus, watch, setValue, getValues} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        if (invalidConfirmCode)
            trigger();
    }, [invalidConfirmCode]);
    const username = watch('username')

    return (
        <Fragment>
            <div>
                {header()}
                <h4 className={'text-center mb-2 text-dark'}>Введите код</h4>
                <p className={'text-center second-text fs-8'}>6-ти значный код был отправлен на ваш номер телефона <span
                    className={'text-info'}>+998 {username.replace(/(\d{2})\s(\d{3})\s(\d{2})/g, "$1 $2 **")}</span></p>
            </div>
            <Flex className={"flex-column justify-content-between scrollbar"} style={{height: "calc(100% - 60px)"}}>
                <div>
                    <Form.Group  className="mb-3" id={'smsForm'}>
                        <Flex>
                            <Col sm={2} className={'p-sm-1'}>
                                <Form.Control
                                    type="text"
                                    className={`${!!errors?.confirmCode1 ? "border border-danger" : ""}`}
                                    {...register('confirmCode1', {
                                        required: t(lang, "edi.common.forms.validations.is_required"),
                                    })}
                                    onKeyDown={(e) => {
                                        if (e.key === 'ArrowRight') {
                                            setFocus('confirmCode2')
                                        }
                                    }}
                                    onChange={(e) => {
                                        if (e.target.value) {
                                            let value = +e.nativeEvent.data;
                                            if (value || value === 0) {
                                                setValue('confirmCode1', value)
                                                setFocus('confirmCode2')
                                            } else {
                                                setValue('confirmCode1', getValues('confirmCode1'))
                                            }
                                        }
                                    }}
                                />
                            </Col>
                            <Col sm={2} className={'p-sm-1'}>
                                <Form.Control
                                    type="text"
                                    className={`${!!errors?.confirmCode2 ? "border border-danger" : ""}`}
                                    {...register('confirmCode2', {
                                        required: t(lang, "edi.common.forms.validations.is_required"),
                                    })}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Backspace' && !e.target.value || e.key === 'ArrowLeft') {
                                            setFocus('confirmCode1')
                                        }
                                        if (e.key === 'ArrowRight') {
                                            setFocus('confirmCode3')
                                        }
                                    }}
                                    onChange={(e) => {
                                        if (e.target.value) {
                                            let value = +e.nativeEvent.data;
                                            if (value || value === 0) {
                                                setValue('confirmCode2', value)
                                                setFocus('confirmCode3')
                                            } else {
                                                setValue('confirmCode2', getValues('confirmCode2'))
                                            }
                                        } else setFocus('confirmCode1')
                                    }}
                                />
                            </Col>
                            <Col sm={2} className={'p-sm-1'}>
                                <Form.Control
                                    type="text"
                                    className={`${!!errors?.confirmCode3 ? "border border-danger" : ""}`}
                                    {...register('confirmCode3', {
                                        required: t(lang, "edi.common.forms.validations.is_required"),
                                    })}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Backspace' && !e.target.value || e.key === 'ArrowLeft') {
                                            setFocus('confirmCode2')
                                        }
                                        if (e.key === 'ArrowRight') {
                                            setFocus('confirmCode4')
                                        }
                                    }}
                                    onChange={(e) => {
                                        if (e.target.value) {
                                            let value = +e.nativeEvent.data;
                                            if (value || value === 0) {
                                                setValue('confirmCode3', value)
                                                setFocus('confirmCode4')
                                            } else {
                                                setValue('confirmCode3', getValues('confirmCode3'))
                                            }
                                        } else setFocus('confirmCode2')
                                    }}
                                />
                            </Col>
                            <Col sm={2} className={'p-sm-1'}>
                                <Form.Control
                                    type="text"
                                    className={`${!!errors?.confirmCode4 ? "border border-danger" : ""}`}
                                    {...register('confirmCode4', {
                                        required: t(lang, "edi.common.forms.validations.is_required"),
                                    })}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Backspace' && !e.target.value || e.key === 'ArrowLeft') {
                                            setFocus('confirmCode3')
                                        }
                                        if (e.key === 'ArrowRight') {
                                            setFocus('confirmCode5')
                                        }
                                    }}
                                    onChange={(e) => {
                                        if (e.target.value) {
                                            let value = +e.nativeEvent.data;
                                            if (value || value === 0) {
                                                setValue('confirmCode4', value)
                                                setFocus('confirmCode5')
                                            } else {
                                                setValue('confirmCode4', getValues('confirmCode4'))
                                            }
                                        } else setFocus('confirmCode3')
                                    }}
                                />
                            </Col>
                            <Col sm={2} className={'p-sm-1'}>
                                <Form.Control
                                    type="text"
                                    className={`${!!errors?.confirmCode5 ? "border border-danger" : ""}`}
                                    {...register('confirmCode5', {
                                        required: t(lang, "edi.common.forms.validations.is_required"),
                                    })}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Backspace' && !e.target.value || e.key === 'ArrowLeft') {
                                            setFocus('confirmCode4')
                                        }
                                        if (e.key === 'ArrowRight') {
                                            setFocus('confirmCode6')
                                        }
                                    }}
                                    onChange={(e) => {
                                        if (e.target.value) {
                                            let value = +e.nativeEvent.data;
                                            if (value || value === 0) {
                                                setValue('confirmCode5', value)
                                                setFocus('confirmCode6')
                                            } else {
                                                setValue('confirmCode5', getValues('confirmCode5'))
                                            }
                                        } else setFocus('confirmCode4')
                                    }}
                                />
                            </Col>
                            <Col sm={2} className={'p-sm-1'}>
                                <Form.Control
                                    type="text"
                                    className={`${!!errors?.confirmCode6 ? "border border-danger" : ""}`}
                                    {...register('confirmCode6', {
                                        required: t(lang, "edi.common.forms.validations.is_required"),
                                    })}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Backspace' && !e.target.value || e.key === 'ArrowLeft') {
                                            setFocus('confirmCode5')
                                        }
                                    }}
                                    onChange={(e) => {
                                        if (e.target.value) {
                                            let value = +e.nativeEvent.data;
                                            if (value || value === 0) {
                                                setValue('confirmCode6', value)
                                            } else {
                                                setValue('confirmCode6', getValues('confirmCode6'))
                                            }
                                        } else setFocus('confirmCode5')
                                    }}
                                />
                            </Col>
                        </Flex>
                        <Form.Control.Feedback type="invalid">
                            {errors?.confirmCode?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </div>
                {footer}
            </Flex>
        </Fragment>
    );
};

ForgotPasswordStepTwo.propTypes = {
    confirmCodeError: PropTypes.bool,
    footer: PropTypes.element
}

export default ForgotPasswordStepTwo;
