import React, {useEffect, useState} from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {selectDistricts} from "../../store/reducers/roaming/roamingReducer";
import CustomFloatingLabelSelect from "../floating-custom-select/CustomFloatingLabelSelect";


const SelectDistrict = React.forwardRef(({onChange, defaultDistrictId, id, placeholder, ...props}, ref) => {
    const districts = useSelector(selectDistricts);
    const [value, setValue] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        if (districts.length > 0 && defaultDistrictId) {
            setValue(districts.find(d => d.districtId === defaultDistrictId))
            setSelectedOption(districts.find(d => d.districtId === defaultDistrictId))
        } else {
            setValue(null);
            setSelectedOption(null);
        }
    }, [districts, defaultDistrictId])

    useEffect(() => {
        onChangeHandler(value)
        setSelectedOption(value)
    }, [value])


    const onChangeHandler = (district) => {
        onChange(district)
        setValue(district)
        setSelectedOption(district)
    };

    const getOptionCatalogLabel = (option) => option.name;
    const getOptionCatalogValue = (option) => option.districtId;


    return (
            <CustomFloatingLabelSelect
                label={placeholder}
                getOptionLabel={getOptionCatalogLabel}
                getOptionValue={getOptionCatalogValue}
                hideSelectedOptions={true}
                options={districts}
                value={value}
                onChange={onChangeHandler}
                ref={ref}
                {...props}
            />
    )
});

SelectDistrict.propTypes = {
    onChange: PropTypes.func
}

SelectDistrict.defaultProps = {
    onChange: () => {
    }
}

export default SelectDistrict;
