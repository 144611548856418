import React, {Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectDateDayJSFormat, selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {
    changeFilterOptionsAsync, loadRoamingContractsAsync, loadRoamingContractsCountAsync,
    selectContractFilterOptions, selectCount,
    selectRoamingContracts, setId, setShowModal, viewPage
} from "../../../store/reducers/roaming/contracts/roamingContractReducer";
import {
    roamingHideModal,
    setRoamingCommonHideModal
} from "../../../store/reducers/roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import {VIEW_DRAFT, VIEW_INBOX} from "../../../enum/roaming/TypeViewers";
import dayjs from "dayjs";
import {ContractWrapperClass} from "../../../enum/roaming/contract/ContractStatus";
import numeral from "numeral";
import TypeViewerAction from "../../common/TypeViewerAction";
import {Translate} from "../../../lang/lang";
import AdvanceTableWrapper from "../../table/AdvanceTableWrapper";
import ContractDataTableHeader from "./ContractDataTableHeader";
import AdvanceTable from "../../table/AdvanceTable";
import AdvanceTableCustomPagination from "../../table/AdvanceTableCustomPagination";
import ViewModalPage from "../../common/ViewModalPage";
import ViewContract from "../../../pages/roaming/contract/ViewContract";
import Badge from "../../common/Badge";
import ViewDraftContract from "../../../pages/roaming/contract/ViewDraftContract";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendarDays,
    faCoins,
    faEye,
    faFile,
    faListCheck,
    faTableCellsLarge
} from "@fortawesome/free-solid-svg-icons";
import SliceCompanyName from "../../common/SliceCompanyName";
import {Card, Col, Dropdown, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import IconFolderDownload from "../../../assets/icon/IconFolderDownload";
import RevealDropdown, {RevealDropdownTrigger} from "../../common/RevealDropdown";
import {DRAFT} from "../../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import RoamingInvoiceDraftDelete from "../invoice/RoamingInvoiceDraftDelete";
import {checkViewer} from "../../../enum/roaming/checkViewer";

const ContractsDataTable = ({typeViewer}) => {

    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat);
    const activeOrganization = useSelector(selectActiveOrganization);
    const roamingInvoices = useSelector(selectRoamingContracts);
    const filters = useSelector(selectContractFilterOptions)
    const numberFormat = useSelector(selectNumberFormat);
    const count = useSelector(selectCount)
    const {id, draftId, showModal} = useSelector(viewPage)
    const hideModal = useSelector(roamingHideModal)

    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const t = Translate;

    const openViewModalPage = (e,id) => {
        const ctrlKey = e.ctrlKey
        const history = window.open

        if (typeViewer === VIEW_DRAFT) {
            navigate('/roaming/contract/draft/'+id)
         /*   if (ctrlKey) {
                history('/roaming/contract/draft/'+id, '_blank')
            } else {
                dispatch(setId({draftId: id}))
                dispatch(setShowModal(true))
            }*/
        } else {
            navigate('/roaming/contract/'+id)
           /* if (ctrlKey) {
                history('/roaming/contract/'+id, '_blank')
            } else {
                dispatch(setId({id}))
                dispatch(setShowModal(true))
            }*/
        }
    }

    const onHideModalPage = () => {
        dispatch(setShowModal(false))
    }

    // to close the modal on page change
    useEffect(() => {
        if(hideModal) {
            onHideModalPage()
            dispatch(setRoamingCommonHideModal(null))
        }
    }, [])

    const activeOrganizationPinflOrInn = activeOrganization.pinfl || activeOrganization.inn

    const columns = [
        {
            accessor: 'created_at',
            Header:  () => t(lang, "roaming.contracts.datatable.header.row.created_at"),
            Cell:  ({row: {original}}) => <span className={'fw-semi-bold'}>{dayjs(original.created_at, "YYYY-MM-DD").format(currentDateDayJSFormat)}</span>
        },
        {
            accessor: 'contractor',
            Header: () => t(lang, "roaming.contracts.datatable.header.row.contractor"),
            cellProps: {
                className: 'pe-4'
            },
            headerProps: {width: 300},
            Cell: ({row: {original}}) => {
                return typeViewer === VIEW_INBOX ? <div className={'d-flex align-items-center gap-2'}>
                        {checkViewer(typeViewer)}
                        <div>
                             <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, 'roaming.empowerment.contact_info.from.contact_date', {
                                 "contract_number": original?.info?.number,
                                 "contract_date": original?.info?.date
                             })}
                             </span>
                            <span className={'fs-8 main-text fw-bold text-truncate'}>{original.executor.name}</span>
                            <i className={'d-block fs-9 second-text'}>{original.executor.inn}</i>
                        </div>
                    </div> :
                    <div className={'d-flex align-items-center gap-2'}>
                        {checkViewer(typeViewer)}
                        <div>
                             <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, 'roaming.empowerment.contact_info.from.contact_date', {
                                 "contract_number": original?.info?.number,
                                 "contract_date": original?.info?.date
                             })}
                             </span>
                            <span className={'fs-8 main-text fw-bold text-truncate'}>{original.contractors[0].name}</span>
                            <i className={'d-block fs-9 second-text'}>{original.contractors[0].inn}</i>
                        </div>
                    </div>
            }
        },
        {
            accessor: 'total_sum',
            Header: () => t(lang, "roaming.contracts.datatable.header.row.total_sum"),
            cellProps: {className: 'pe-3'},
            headerProps: {className: 'text-end pe-2'},
            Cell: ({row: {original}}) => {
                if (typeViewer === VIEW_DRAFT) {
                    return (
                        <span className={'d-block text-end fw-semi-bold pe-2'}>
                             <strong>
                                 {numeral.formats[numberFormat].format((original?.total_info?.total_with_nds_value) || 0)}
                             </strong>
                            <span className={'ms-1 second-text fs-9'}>UZS</span>
                        </span>
                    )
                } else {
                    return (
                        <span className={'d-block text-end fw-semi-bold'}>
                            <strong>
                                {numeral.formats[numberFormat].format((original?.items?.reduce((total, item) => total + item?.total_with_nds, 0) || 0))}
                            </strong>
                            <span className={'ms-1 second-text fs-9'}>UZS</span>
                        </span>
                    )
                }
            }
        },
        (VIEW_DRAFT !== typeViewer && {
            accessor: 'status',
            Header: () => t(lang, "roaming.contracts.datatable.header.row.status"),
            headerProps: {className: 'text-center'},
            cellProps: {className: 'text-center'},
            Cell: ({row: {original}}) => {
                const contractWrapper = new ContractWrapperClass(original)
                return <div className={'d-inline-block text-center'}>
                    <Badge
                        variant={"phoenix"}
                        bg={contractWrapper.getRoamingStatusVariant(activeOrganizationPinflOrInn)}
                        className={'px-3 py-1 fs-9 text-uppercase w-100 text-decoration-none fw-medium border-0 rounded-4'}
                    >
                        {t(lang, contractWrapper.getRoamingStatus(activeOrganizationPinflOrInn))}
                    </Badge>
                </div>
            }
        }),
        {
            accessor: 'action',
            Header: "",
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                        <RevealDropdown>
                            <Dropdown.Item
                                onClick={(event) => {
                                    openViewModalPage(event, original.id)
                                }}>
                                <span>{t(lang, "roaming.invoices.table_header.show")}</span>
                            </Dropdown.Item>
                        </RevealDropdown>
                    </RevealDropdownTrigger>
                );
            }
        },

    ].filter(i => i);

    const onPageChange = async (page) => {
        dispatch(changeFilterOptionsAsync({...filters, page: page}))
    }

    const onLimitChange = async (limit) => {
        dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}))
    }

    useEffect(() => {
        let params = {...filters, type_viewer: typeViewer, viewer_inn: activeOrganizationPinflOrInn}
        dispatch(loadRoamingContractsAsync(params));
        dispatch(loadRoamingContractsCountAsync({...params}))
    }, [filters, activeOrganization, typeViewer]);

    return (
        <Fragment>
                <AdvanceTableWrapper
                    columns={columns}
                    data={roamingInvoices}
                    pagination
                    perPage={1000}
                    sortable
                    selection
                >
                    <ContractDataTableHeader table/>
                    <Card className={'p-0 rounded-4'}>
                        <Card.Body className={'p-0'}>
                            <TypeViewerAction to={'/roaming/contracts'} typeViewer={typeViewer} />
                            <AdvanceTable
                                table
                                headerClassName="text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    size: 'sm',
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                        </Card.Body>
                    </Card>

                    <AdvanceTableCustomPagination
                        count={count}
                        limit={filters.limit}
                        page={filters.page}
                        onLimitChange={onLimitChange}
                        onPageChange={onPageChange}
                    />
                </AdvanceTableWrapper>

            <ViewModalPage show={showModal} onHide={onHideModalPage}>
                {id && <ViewContract modalId={id} />}
                {draftId && <ViewDraftContract modalId={draftId} />}
            </ViewModalPage>

        </Fragment>
    );
};

export default ContractsDataTable;
