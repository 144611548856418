import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {Card} from "react-bootstrap";
import {loginWithCertificateAsync} from "../../../../store/reducers/auth/authReducer";
import {useNavigate} from "react-router-dom";
import IconArrowLeft from "../../../../assets/icon/IconArrowLeft";
import Flex from "../../../common/Flex";
import CardSelectCertificate from "../../../eimzo/CardSelectCertificate";

const LoginWithCertificate = () => {
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const onClick = (certificate) => {
        setLoading(true);
        dispatch(loginWithCertificateAsync(certificate))
            .then(() => {
                navigate('/');
            })
            .catch(() => {
            })
            .finally(() => setLoading(false));
    };

    return (
        <Card className={'w-100 h-100 overflow-hidden rounded-5 p-3'}>
            <Flex className={"flex-column w-100 h-100 justify-content-between"}>
                <div>
                    <div className={'position-relative'}>
                        <button onClick={() => navigate(-1)}
                                className={'main-text btn-back bg-secondary-davr position-absolute'}
                                style={{width: 35, height: 35, left: 0, bottom: 0}}>
                            <IconArrowLeft/>
                        </button>
                        <h5 className={'text-center text-dark'}>Вход в систему</h5>
                        <p className={'text-center fs-9 second-text'}>Через E-IMZO</p>
                    </div>
                </div>
                <Card className={'p-1'} style={{height: "calc(100% - 55px)"}}>
                    <CardSelectCertificate onClick={onClick}/>
                </Card>
            </Flex>
        </Card>
    )
};

export default LoginWithCertificate;
