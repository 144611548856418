import React, {Fragment, useState} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {selectCurrentCertificate} from "../../../store/reducers/eimzo/eimzoReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import checkPermission from "../../../enum/permission/CheckPermission";
import toastPermission from "../../../enum/permission/ToastPermission";
import IconButton from "../../IconButton";
import {Button, Form, Modal} from "react-bootstrap";
import SignActionWithSelectCertificateWrapper from "../../common/SignActionWithSelectCertificateWrapper";
import IconFolderCancel from "../../../assets/icon/IconFolderCancel";

const ActionContract = ({actionContract, disabled,permission,iconText,icon,variant, ...props}) => {
    const dispatch = useDispatch()
    const methods = useForm();
    const { register, formState: {errors}, handleSubmit, reset } = methods
    const currentCertificate = useSelector(selectCurrentCertificate);
    const lang = useSelector(selectLang);
    const t = Translate;

    const [show, setShow] = useState(false);

    const handleShow = () => {
        setShow(true)
    }
    const handleClose = () => {
        reset();
        setShow(false);
    };

    const formSubmit = ({comment}) => {
        if(currentCertificate?.keyId) {
            actionContract(comment)
            reset();
            setShow(false);
        }
    };

    return (
        <Fragment>
            <IconButton
                variant={variant}
                size="sm"
                icon={icon}
                disabled={disabled}
                iconClassName="me-1"
                onClick={handleShow}
                {...props}
            >
                <IconFolderCancel />
                {t(lang, iconText)}
            </IconButton>

            <Modal size="md" show={show} onHide={handleClose}>
                <FormProvider {...methods}>
                    <Form onSubmit={handleSubmit(formSubmit)} id="CancelContractForm">
                        <Modal.Header closeButton>
                            <Modal.Title >Contract cancel</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>Comment</Form.Label>
                                <Form.Control
                                    name="Name"
                                    as="textarea" rows={3}
                                    placeholder="Please enter a comment to cancel"
                                    isInvalid={errors?.comment?.message}
                                    {...register("comment", {
                                        required: {
                                            value: true,
                                            message: "This field is required!"
                                        }
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.comment?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => {
                                handleClose()
                            }}>{t(lang, "edi.common.button.close")}</Button>
                            <SignActionWithSelectCertificateWrapper isForm>
                                <Button
                                    variant="danger"
                                    className="d-flex align-items-center"
                                    form="CancelContractForm"
                                    disabled={disabled}
                                    type="submit"
                                >{t(lang, "edi.common.button.yes")}
                                </Button>
                            </SignActionWithSelectCertificateWrapper>
                        </Modal.Footer>
                    </Form>
                </FormProvider>
            </Modal>
        </Fragment>
    );
};

export default ActionContract;
