import React from 'react';

const IconCaretDown = ({width = 24, height = 24, color = "none"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M16 10L12 14L8 10" stroke="#9BA2B1" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    );
};

export default IconCaretDown;
