import React from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {syncEmpowermentAsync} from "../../../store/reducers/roaming/empowerment/empowermentReducer";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../IconButton";

const SyncEmpowerment = ({id, ...props}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const syncEmpowerment = () => {
        syncEmpowermentAsync(id)
            .then(response => {})
            .catch(error => {})
    };
    return (
        <IconButton
            icon={faRefresh}
            variant={'phoenix-secondary'}
            onClick={syncEmpowerment}
            size="sm"
            iconClassName="text-primary-davr fs-8"
            className="btn-davr px-3 py-0 bg-white d-flex gap-2"
            {...props}>
            {t(lang, "roaming.empowerment.sync")}
        </IconButton>
    );
};

export default SyncEmpowerment;
