import React, {Fragment, useEffect, useState} from 'react';
import {selectDateDayJSFormat, selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {selectCurrentCertificate} from "../../../store/reducers/eimzo/eimzoReducer";
import {
    roamingHideModal,
    setRoamingCommonHideModal
} from "../../../store/reducers/roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import {
    ActFormSendAsync,
    changeFilterOptionsAsync,
    loadRoamingActAsync,
    loadRoamingActsCountAsync,
    selectActsFilterOptions,
    selectCount,
    selectRoamingActs,
    setShowModal,
    viewPage
} from "../../../store/reducers/roaming/act/actReducer";
import {Translate} from "../../../lang/lang";
import {VIEW_DRAFT} from "../../../enum/roaming/TypeViewers";
import dayjs from "dayjs";
import {ActWrapperClass} from "../../../enum/roaming/act/ActStatus";
import Badge from "../../common/Badge";
import numeral from "numeral";
import TypeViewerAction from "../../common/TypeViewerAction";
import AdvanceTableWrapper from "../../table/AdvanceTableWrapper";
import CountableProgressBar from "../../common/CountableProgressBar";
import ActDataTableHeader from "./ActDataTableHeader";
import AdvanceTable from "../../table/AdvanceTable";
import AdvanceTableCustomPagination from "../../table/AdvanceTableCustomPagination";
import ViewModalPage from "../../common/ViewModalPage";
import ViewAct from "../../../pages/roaming/act/ViewAct";
import DraftActView from "../../../pages/roaming/act/DraftActView";
import {Card, Dropdown} from "react-bootstrap";
import RevealDropdown, {RevealDropdownTrigger} from "../../common/RevealDropdown";
import {useNavigate} from "react-router-dom";
import {checkViewer} from "../../../enum/roaming/checkViewer";

const ActDataTable = ({typeViewer}) => {
    const [ countSelectedAndSignedAct, SetCountSelectedAndSignedAct ] = useState(false)
    const [ showProgressBar, setShowProgressBar ] = useState(false)
    const [ lengthSignAct, setLengthSignAct ] = useState(0)
    const [ signResults, setSignResults ] = useState([])
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat)
    const activeOrganization = useSelector(selectActiveOrganization)
    const currentCertificate = useSelector(selectCurrentCertificate)
    const numberFormat = useSelector(selectNumberFormat);
    const roamingActs  = useSelector(selectRoamingActs)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const filters = useSelector(selectActsFilterOptions)
    const count = useSelector(selectCount)
    const {id, draftId, showModal} = useSelector(viewPage)
    const hideModal = useSelector(roamingHideModal)

    const lang = useSelector(selectLang);
    const t = Translate;

    const openViewModalPage = (e,id) => {

        if (typeViewer === VIEW_DRAFT) {
            navigate('/roaming/draft-act/'+id)

        }else {
            navigate('/roaming/act/'+id)

        }
    }

    const onHideModalPage = () => {
        dispatch(setShowModal(false))
    }

    // to close the modal on page change
    useEffect(() => {
        if(hideModal) {
            onHideModalPage()
            dispatch(setRoamingCommonHideModal(null))
        }
    }, [])

    const columns = [
        {
            accessor: 'created_date',
            Header: t(lang, "edi.order.datatable.header.row.created_date"),
            Cell: rowData => {
                const {created_at} = rowData.row.original
                return dayjs(created_at, "YYYY-MM-DD").format(currentDateDayJSFormat)
            }
        },
        {
            accessor: 'contractor',
            Header: t(lang, "edi.order.datatable.header.row.contractor"),
            cellProps: {
                className: 'pe-4'
            },
            headerProps: {width: 300},
            Cell: ({row: {original}}) => {
                return activeOrganization.inn === original.customer.inn
                    ? <div className={'d-flex align-items-center gap-2'}>
                        {checkViewer(typeViewer)}
                        <div>
                             <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, "roaming.verification_act.datatable.body.row.verification_act", {
                                 name: original?.info?.number,
                                 date: dayjs(original?.info?.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                             })}
                             </span>
                            <span className={'fs-8 main-text fw-bold text-truncate'}>{original.executor.name}</span>
                            <i className={'d-block fs-9 second-text'}>{original.executor.inn || original.executor.pinfl}</i>
                        </div>
                    </div>
                    : <div className={'d-flex align-items-center gap-2'}>
                        {checkViewer(typeViewer)}
                        <div>
                             <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, "roaming.verification_act.datatable.body.row.verification_act", {
                                 name: original?.info?.number,
                                 date: dayjs(original?.info?.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                             })}
                             </span>
                            <span className={'fs-8 main-text fw-bold text-truncate'}>{original.customer.name}</span>
                            <i className={'d-block fs-9 second-text'}>{original.customer.inn || original.customer.pinfl}</i>
                        </div>
                    </div>
            }
        },
        {
            accessor: 'info.date',
            Header: t(lang, "edi.common.contract"),
            Cell: rowData => {
                const {date} = rowData.row.original.contract.date
                return <span className={'second-text fw-light'}>{rowData.row.original.contract.number} от {dayjs(date, "YYYY-MM-DD").format(currentDateDayJSFormat)}</span>
            }
        },
        {
            accessor: 'total',
            Header: t(lang,"roaming.act.datatable.header.row.total_sum"),
            cellProps: {
                className: 'text-end pe-5'
            },
            Cell: ({row: {original}}) => {
                let subtotal = 0
                if (original?.content === undefined){
                    subtotal = original?.items?.reduce((acc, item) => acc + item.total, 0);
                }else {
                    const Jsonparse = JSON.parse(original?.content)
                    subtotal = Jsonparse?.ProductList?.Products.reduce((acc, item) => acc + item.TotalSum, 0)
                }
                return (
                    // <span>{numeral(subtotal).format(numberFormat)}</span>
                    <Fragment>
                        <strong>{numeral.formats[numberFormat].format(subtotal)}</strong>
                        <span className={'ms-1 second-text fs-9'}>UZS</span>
                    </Fragment>
                )
            }
        },
        typeViewer !== VIEW_DRAFT && {
            accessor: 'status',
            Header: t(lang, "edi.order.datatable.header.row.status"),
            cellProps: {
                className: 'text-center'
            },
            headerProps: {
                className: 'text-center'
            },
            Cell: ({row: {original}}) => {
                const actWrapper = new ActWrapperClass(original)

                return <div className={'d-inline-block text-center'}>
                    <Badge
                        variant={"phoenix"}
                        bg={actWrapper.getRoamingStatusVariant(activeOrganization.pinfl, activeOrganization.inn)}
                        className={'px-3 py-1 fs-9 text-uppercase w-100 text-decoration-none fw-medium border-0 rounded-4'}
                    >
                        {t(lang, actWrapper.getRoamingStatus(activeOrganization.pinfl,activeOrganization.inn))}
                    </Badge>
                </div>
            }
        },
        {
            accessor: 'action',
            Header: "",
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                        <RevealDropdown>
                            <Dropdown.Item
                                onClick={(event) => {
                                    openViewModalPage(event, original.id)
                                }}>
                                <span>{t(lang, "roaming.invoices.table_header.show")}</span>
                            </Dropdown.Item>
                        </RevealDropdown>
                    </RevealDropdownTrigger>
                );
            }
        },
    ].filter(column => typeof column === 'object' && column !== null)

    const onPageChange = async (page) => {
        dispatch(changeFilterOptionsAsync({...filters, page: page}))
    }

    const onLimitChange = async (limit) => {
        dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}))
    }

    const onBulkSignHandler = async (roamingActs) => {
        let notSignedActs = roamingActs.filter(roamingAct => !roamingAct.status)
        SetCountSelectedAndSignedAct(roamingActs.length - notSignedActs.length)
        setLengthSignAct(notSignedActs.length)
        setShowProgressBar(true)
        for (let i = 0; i < roamingActs.length; i++) {
            let responseInfo = await ActFormSendAsync(currentCertificate, lang, roamingActs[i].content, roamingActs[i].id, roamingActs[i].executor.inn)
            let result = responseInfo.status === 15
            setSignResults(old => [...old, result])
        }
        let params = {...filters, type_viewer: typeViewer, viewer_inn: activeOrganization.pinfl || activeOrganization.inn}
        dispatch(loadRoamingActAsync(params));
        dispatch(loadRoamingActsCountAsync({...params, viewer_inn: activeOrganization.pinfl || activeOrganization.inn}))
        setTimeout(() => {
            setSignResults([])
            setLengthSignAct(0)
            setShowProgressBar(false)
        }, 1000)
    }

    useEffect(() => {
        let params = {...filters, type_viewer: typeViewer, viewer_inn: activeOrganization.pinfl || activeOrganization.inn}
        dispatch(loadRoamingActAsync(params));
        dispatch(loadRoamingActsCountAsync({...params, viewer_inn: activeOrganization.pinfl || activeOrganization.inn}))
    }, [filters, activeOrganization, typeViewer]);

    return (
        <Fragment>
            <CountableProgressBar show={showProgressBar} results={signResults} signed={countSelectedAndSignedAct} length={lengthSignAct} />

            <AdvanceTableWrapper
                columns={columns}
                data={roamingActs}
                pagination
                perPage={1000}
                selection
                selectionColumnWidth={50}
            >
                <ActDataTableHeader table onClickBulkSign={onBulkSignHandler} typeViewer={typeViewer}/>

                <Card className={'p-0 rounded-4'}>
                    <TypeViewerAction to={'/roaming/acts'} typeViewer={typeViewer} />
                    <Card.Body className={'p-0'}>
                        <AdvanceTable
                            table
                            headerClassName="text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                </Card>

                <AdvanceTableCustomPagination
                    count={count}
                    limit={filters.limit}
                    page={filters.page}
                    onLimitChange={onLimitChange}
                    onPageChange={onPageChange}
                />
            </AdvanceTableWrapper>

            <ViewModalPage show={showModal} onHide={onHideModalPage}>
                {id && <ViewAct modalId={id} />}
                {draftId && <DraftActView modalId={draftId} />}
            </ViewModalPage>
        </Fragment>
    );
};

export default ActDataTable;
