import {createSlice} from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {
    WAREHOUSE_OPERATION_REVISION_REGISTER_FAILED,
    WAREHOUSE_OPERATION_REVISION_REGISTER_REQUESTED,
    WAREHOUSE_OPERATION_REVISION_REGISTER_SUCCESS,
    WAREHOUSE_OPERATION_REVISION_ADD_ITEM_FAILED,
    WAREHOUSE_OPERATION_REVISION_ADD_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_REVISION_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REVISION_APPROVED_ITEM_FAILED,
    WAREHOUSE_OPERATION_REVISION_APPROVED_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_REVISION_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REVISION_DELETE_FAILED,
    WAREHOUSE_OPERATION_REVISION_DELETE_ITEM_FAILED,
    WAREHOUSE_OPERATION_REVISION_DELETE_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_REVISION_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REVISION_DELETE_REQUESTED,
    WAREHOUSE_OPERATION_REVISION_DELETE_SUCCESS,
    WAREHOUSE_OPERATION_REVISION_UPDATE_ITEM_FAILED,
    WAREHOUSE_OPERATION_REVISION_UPDATE_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_REVISION_UPDATE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REVISION_NOTE_REQUESTED,
    WAREHOUSE_OPERATION_REVISION_NOTE_SUCCESS,
    WAREHOUSE_OPERATION_REVISION_NOTE_FAILED,
    WAREHOUSE_OPERATION_REVISION_DATE_REQUESTED,
    WAREHOUSE_OPERATION_REVISION_DATE_SUCCESS,
    WAREHOUSE_OPERATION_REVISION_DATE_FAILED,
    WAREHOUSE_OPERATION_REVISION_NUMBER_REQUESTED,
    WAREHOUSE_OPERATION_REVISION_NUMBER_SUCCESS, WAREHOUSE_OPERATION_REVISION_NUMBER_FAILED,
    WAREHOUSE_OPERATION_REVISION_EMPLOYEE_REQUESTED,
    WAREHOUSE_OPERATION_REVISION_EMPLOYEE_SUCCESS, WAREHOUSE_OPERATION_REVISION_EMPLOYEE_FAILED,
} from "../../../../eventbus/warehouse/revisionEvent";
import warehouseOperationService from "../../../services/warehouse/warehouseOperationService";
import EventBus from "../../../../eventbus/EventBus";

const currentDate = new Date()
const dateTimeStartFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
const dateTimeEndFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);

const revisionReducer = createSlice({
    name: "revision",
    initialState: {
        revisions: [],
        revisionCount: 0,
        filterOptions: {
            page: 1,
            limit: 10,
            date_time_start: dayjs(dateTimeStartFormat)?.format("YYYY-MM-DD HH:mm:ss"),
            date_time_end: dayjs(dateTimeEndFormat)?.format("YYYY-MM-DD HH:mm:ss"),
        },
        revisionDefaultsSettings: null,
    },
    reducers: {
        updateFilterOptions: (state, action) => {
            state.filterOptions = action.payload
        },
        updateRevisions: (state, action) => {
            state.revisions = action.payload
        },
        updateRevisionCount: (state, action) => {
            state.revisionCount = action.payload
        },
        setRevisionDefaultsSettings: (state, action) => {
            state.revisionDefaultsSettings = JSON.parse(action.payload)
            localStorage.setItem("RevisionSettings", action.payload)
        },
        loadRevisionDefaultsSettings: (state, action) => {
            const revisionDefaults = JSON.parse(localStorage.getItem("RevisionSettings"))
            state.revisionDefaultsSettings = revisionDefaults
        },
    },
})


export const changeFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(revisionReducer.actions.updateFilterOptions({...params}))
}

export const getRevisionsAsync = ({page, limit, ...filters}) => dispatch => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}

    return new Promise((resolve, reject) => {
        warehouseOperationService.getRevisions(params)
            .then((response) => {
                dispatch(revisionReducer.actions.updateRevisions(response.data))
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getRevisionsCountAsync = (params) => dispatch => {
    let {limit, page, ...filters} = params

    return new Promise((resolve, reject) => {
        warehouseOperationService.getRevisionsCount(filters)
            .then((response) => {
                dispatch(revisionReducer.actions.updateRevisionCount(response.data))
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const revisionRegisterAsync = (payload) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_REGISTER_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.revisionRegister(payload)
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_REGISTER_SUCCESS, response.data.id)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_REGISTER_FAILED)
                reject(error)
            })
    })
}

export const revisionAddItemAsync = ({revisionId, operation}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_ADD_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.addRevisionItem({revisionId, operation})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_ADD_ITEM_SUCCESS, revisionId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_ADD_ITEM_FAILED)
                reject(error)
            })
    })
}

export const getRevisionItemAsync = ({revisionId}) => {
    return new Promise((resolve, reject) => {
        warehouseOperationService.getRevision({revisionId})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const revisionDeleteItemAsync = ({revisionId, operationId}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_DELETE_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.deleteRevisionItem({revisionId, operationId})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_DELETE_ITEM_SUCCESS, revisionId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_DELETE_ITEM_FAILED)
                reject(error)
            })
    })
}

export const revisionUpdateItemAsync = ({revisionId, operation}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_UPDATE_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.updateRevisionItem({revisionId, operation})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_UPDATE_ITEM_SUCCESS, revisionId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_UPDATE_ITEM_FAILED)
                reject(error)
            })
    })
}

export const revisionApproveAsync = ({revisionId}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_APPROVED_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.approveRevision({revisionId})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_APPROVED_ITEM_SUCCESS, revisionId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_APPROVED_ITEM_FAILED)
                reject(error)
            })
    })
}

export const revisionDeleteAsync = ({revisionId}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_DELETE_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.revisionDelete({revisionId})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_DELETE_SUCCESS)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_DELETE_FAILED)
                reject(error)
            })
    })
}

export const revisionUpdateNoteAsync = ({revisionId, note}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_NOTE_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.revisionUpdateNote({revisionId, note})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_NOTE_SUCCESS, revisionId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_NOTE_FAILED)
                reject(error)
            })
    })
}

export const revisionUpdateDateAsync = ({revisionId, date}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_DATE_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.revisionUpdateDate({revisionId, date})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_DATE_SUCCESS, revisionId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_DATE_FAILED)
                reject(error)
            })
    })
}

export const revisionUpdateNumberAsync = ({revisionId, number}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_NUMBER_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.revisionUpdateNumber({revisionId, number})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_NUMBER_SUCCESS, revisionId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_NUMBER_FAILED)
                reject(error)
            })
    })
}

export const revisionChangeEmployeeAsync = ({revisionId, employeeId}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_EMPLOYEE_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.revisionChangeEmployee({revisionId, employeeId})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_EMPLOYEE_SUCCESS, response)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REVISION_EMPLOYEE_FAILED)
                reject(error)
            })
    })
}
export const revisionDownloadPdfAsync = ({revisionId}) => {
    return new Promise((resolve, reject) => {
        warehouseOperationService.revisionDownloadPdf({revisionId})
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}


export const selectRevisionFilterOptions = state => state.revision.filterOptions
export const selectRevisionCount = state => state.revision.revisionCount
export const selectRevisions = state => state.revision.revisions

export const selectRevisionDefaultsSettings = state => state.revision.revisionDefaultsSettings


export const {setRevisionDefaultsSettings, loadRevisionDefaultsSettings} = revisionReducer.actions


export default revisionReducer.reducer;
