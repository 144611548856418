import {createSlice} from "@reduxjs/toolkit";
import itemService from "../../services/item/itemService";

export const categorySlice = createSlice({
    name: 'category',
    initialState: {
        categories: [],
        count: 0,
        filters: {},
        pagination: {page: 1, limit: 10},
        allCategories: [],
    },
    reducers: {
        setCategories: (state, action) => {
            state.categories = action.payload;
        },
        setCountCategories: (state, action) => {
            state.count = action.payload;
        },
        updateFilters: (state, action) => {
            state.filters = action.payload;
        },
        updatePagination: (state, action) => {
            state.pagination = action.payload;
        },
        updateAllCategories: (state, action) => {
            state.allCategories = action.payload;
        }
    }
})


export const loadCategoriesAsync = (filters, pagination) => {
    let params = {...filters};

    if (pagination) {
        const page = pagination.page;
        const limit = pagination.limit;
        const skip = (page - 1) * limit;

        params = {...filters, skip, limit}
    }

    return new Promise((resolve, reject) => {
        itemService.getCategories(params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error)
            })
    })
}
export const loadCountCategoriesAsync = (filters) => {
    const params = {...filters};
    return new Promise((resolve, reject) => {
        itemService.getCountCategories(params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
}

export const loadCategoryAsync = (id) => {
    return new Promise((resolve, reject) => {
        itemService.getCategory(id)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject();
            })
    })
}

export const addCategoryAsync = (payload) => {
    return new Promise((resolve, reject) => {
        itemService.addCategory(payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error.response.data)
            })
    })
}

export const editCategoryAsync = (id, payload) => {
    return new Promise((resolve, reject) => {
        itemService.editCategory(id, payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error.response.data);
            })
    })
}

export const deleteCategoryAsync = (id) => {
    return new Promise((resolve, reject) => {
        itemService.deleteCategory(id)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            })
    })
}

export const updateFilters = (filters) => (dispatch) => {
    dispatch(categorySlice.actions.updateFilters(filters));
}
export const updatePagination = (pagination) => (dispatch) => {
    dispatch(categorySlice.actions.updatePagination(pagination));
}
export const updateCategories = (categories) => (dispatch) => {
    dispatch(categorySlice.actions.setCategories(categories));
}
export const updateAllCategories = (categories) => (dispatch) => {
    dispatch(categorySlice.actions.updateAllCategories(categories));
}

export const updateCount = (count) => (dispatch) => {
    dispatch(categorySlice.actions.setCountCategories(count));
}
export const selectCategories = (state) => state.category.categories;
export const selectCountCategories = (state) => state.category.count;
export const selectAllCategories = (state) => state.category.allCategories;

export const selectFilters = (state) => state.category.filters;

export const selectPagination = (state) => state.category.pagination;

export default categorySlice.reducer;
