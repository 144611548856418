import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import {selectLang, updateShowInProgressAsync} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {selectAccountRolePermissions} from "../../../../store/reducers/role/roleReducer";
import checkPermission from "../../../../enum/permission/CheckPermission";
import {PermissionRoamingVerificationActView} from "../../../../enum/permission/RoamingPermission";
import {loadDraftVerificationAct} from "../../../../store/reducers/roaming/vertification-act/vertificationActReducer";
import EventBus from "../../../../eventbus/EventBus";
import toast from "react-hot-toast";
import {
    DOCUMENT_CANCEL_FAILED,
    DOCUMENT_CANCEL_STARTED,
    DOCUMENT_CANCEL_SUCCESS,
    DOCUMENT_DECLINE_FAILED,
    DOCUMENT_DECLINE_STARTED,
    DOCUMENT_DECLINE_SUCCESS,
    DOCUMENT_SIGN_FAILED,
    DOCUMENT_SIGN_STARTED,
    DOCUMENT_SIGN_SUCCESS
} from "../../../../eventbus/romaing/signEvents";
import {getOrganizationBillingInfoAsync} from "../../../../store/reducers/billing/billingReducer";
import VerificationAct from "./VerificationAct";

const ViewDraftVerificationAct = ({modalId}) => {
    const [draftVerificationAct, setDraftVerificationAct] = useState({})
    const { id } = useParams();
    const navigate = useNavigate();
    const activeOrganization = useSelector(selectActiveOrganization);
    const lang = useSelector(selectLang);
    const t = Translate
    const dispatch = useDispatch()
    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingVerificationActView))) {
            navigate('/roaming/verification-acts/draft')
        }
    }, [permission])

    const load = () => {
        loadDraftVerificationAct(modalId ? modalId : id)
            .then(response => {
                let contentVerificationAct = JSON.parse(response.data.content)
                let verificationAct = {
                    id: contentVerificationAct.VerificationActId,
                    info: {
                        number: contentVerificationAct.VerificationActDoc.VerificationActNo,
                        date: contentVerificationAct.VerificationActDoc.VerificationActDate,
                        text: contentVerificationAct.VerificationActDoc.VerificationActText
                    },
                    executor: {
                        inn: contentVerificationAct.OwnerTin,
                        name: contentVerificationAct.OwnerName,
                        director_inn: contentVerificationAct.OwnerFizTin,
                        director_name: contentVerificationAct.OwnerFizFio,
                    },
                    customer: {
                        inn: contentVerificationAct.PartnerTin,
                        name: contentVerificationAct.PartnerName,
                        director_inn: contentVerificationAct.PartnerFizTin,
                        director_name: contentVerificationAct.PartnerFizFio
                    },
                    contracts: contentVerificationAct.VerificationActContracts.map(contract => ({
                        contract_no: contract.ContractNo,
                        contract_date: contract.ContractDate,
                        items: contract.VerificationActContractItems.map(document => ({
                            customer_operation_date: document.PartnerOperationDate,
                            customer_operation_name: document.PartnerOperationName,
                            customer_debit: +document?.PartnerDebit,
                            customer_credit: +document?.PartnerCredit,
                            executor_operation_date: document.OwnerOperationDate,
                            executor_operation_name: document.OwnerOperationName,
                            executor_debit: +document?.OwnerDebit,
                            executor_credit: +document?.OwnerCredit
                        })),
                        open_balance: {
                            customer_debit: +contract?.OpenBalance?.PartnerDebit,
                            customer_credit: +contract?.OpenBalance?.PartnerCredit,
                            executor_debit: +contract?.OpenBalance?.OwnerDebit,
                            executor_credit: +contract?.OpenBalance?.OwnerCredit
                        },
                        close_balance: {
                            customer_debit: +contract?.CloseBalance?.PartnerDebit,
                            customer_credit: +contract?.CloseBalance?.PartnerCredit,
                            executor_debit: +contract?.CloseBalance?.OwnerDebit,
                            executor_credit: +contract?.CloseBalance?.OwnerCredit
                        },
                        total_balance: {
                            customer_debit: +contract?.TotalBalance?.PartnerDebit,
                            customer_credit: +contract?.TotalBalance?.PartnerCredit,
                            executor_debit: +contract?.TotalBalance?.OwnerDebit,
                            executor_credit: +contract?.TotalBalance?.OwnerCredit
                        }
                    })),
                    open_balance: {
                        customer_debit: +contentVerificationAct?.OpenBalance?.PartnerDebit,
                        customer_credit: +contentVerificationAct?.OpenBalance?.PartnerCredit,
                        executor_debit: +contentVerificationAct?.OpenBalance?.OwnerDebit,
                        executor_credit: +contentVerificationAct?.OpenBalance?.OwnerCredit
                    },
                    turnover_balance: {
                        customer_debit: +contentVerificationAct?.TurnoverBalance?.PartnerDebit,
                        customer_credit: +contentVerificationAct?.TurnoverBalance?.PartnerCredit,
                        executor_debit: +contentVerificationAct?.TurnoverBalance?.OwnerDebit,
                        executor_credit: +contentVerificationAct?.TurnoverBalance?.OwnerCredit
                    },
                    close_balance: {
                        customer_debit: +contentVerificationAct?.CloseBalance?.PartnerDebit,
                        customer_credit: +contentVerificationAct?.CloseBalance?.PartnerCredit,
                        executor_debit: +contentVerificationAct?.CloseBalance?.OwnerDebit,
                        executor_credit: +contentVerificationAct?.CloseBalance?.OwnerCredit
                    }
                }
                return setDraftVerificationAct(verificationAct)
            })
            .catch(error => {
                console.log(error);
                navigate('/roaming/verification-acts/inbox')
            })
    }

    useEffect(() => {
        load()
    }, [modalId, id, activeOrganization.inn])

    useEffect(() => {
        // =========== DOCUMENT_SIGN
        const signStartedHandler = EventBus.on(DOCUMENT_SIGN_STARTED, () => dispatch(updateShowInProgressAsync(true)))
        const signedHandler = EventBus.on(DOCUMENT_SIGN_SUCCESS, () => {
            toast.success(t(lang, "edi.document.decline.notification.success"))
            dispatch(getOrganizationBillingInfoAsync());
            dispatch(updateShowInProgressAsync(false))
            navigate(`/roaming/verification-act/${modalId ? modalId : id}`)
        })
        const signFailedHandler = EventBus.on(DOCUMENT_SIGN_FAILED, (message) => {
            toast.error(t(lang, message))
            dispatch(updateShowInProgressAsync(false))
        });

        // =========== DOCUMENT_CANCEL
        const cancelStartedHandler = EventBus.on(DOCUMENT_CANCEL_STARTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })
        const canceledHandler = EventBus.on(DOCUMENT_CANCEL_SUCCESS, () => {
            toast.success(t(lang, "edi.document.decline.notification.success"))
            dispatch(updateShowInProgressAsync(false))
            load()
        })
        const cancelFailedHandler = EventBus.on(DOCUMENT_CANCEL_FAILED, (message) => {
            toast.error(t(lang, message))
            dispatch(updateShowInProgressAsync(false))
        })

        // =========== DOCUMENT_DECLINE
        const declineStartedHandler = EventBus.on(DOCUMENT_DECLINE_STARTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })
        const declinedHandler = EventBus.on(DOCUMENT_DECLINE_SUCCESS, () => {
            toast.success(t(lang, "edi.document.decline.notification.success"))
            dispatch(updateShowInProgressAsync(false))
            load()
        })
        const declineFailedHandler = EventBus.on(DOCUMENT_DECLINE_FAILED, (message) => {
            toast.error(t(lang, message))
            dispatch(updateShowInProgressAsync(false))
        })

        return () => {
            EventBus.remove(DOCUMENT_SIGN_STARTED, signStartedHandler)
            EventBus.remove(DOCUMENT_SIGN_SUCCESS, signedHandler)
            EventBus.remove(DOCUMENT_SIGN_FAILED, signFailedHandler)

            EventBus.remove(DOCUMENT_CANCEL_STARTED, cancelStartedHandler)
            EventBus.remove(DOCUMENT_CANCEL_SUCCESS, canceledHandler)
            EventBus.remove(DOCUMENT_CANCEL_FAILED, cancelFailedHandler)

            EventBus.remove(DOCUMENT_DECLINE_STARTED, declineStartedHandler)
            EventBus.remove(DOCUMENT_DECLINE_SUCCESS, declinedHandler)
            EventBus.remove(DOCUMENT_DECLINE_FAILED, declineFailedHandler)
        }
    }, [])

    return (!!Object.keys(draftVerificationAct).length &&
        <VerificationAct verificationAct={draftVerificationAct}/>
    );
};

export default ViewDraftVerificationAct;
