import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectDateDayJSFormat, selectLang} from "../../../store/reducers/main/main";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {Translate} from "../../../lang/lang";
import {
    AwaitSenderSign,
    getStatusHistory,
    getStatusHistoryBg,
    getStatusHistoryVariant,
    getStatusShowCustomerHistory,
    getStatusShowExecutorHistory,
    GetStatusText,
    GetStatusVariant,
    ReceiverAccept,
    SenderSent
} from "../../../enum/roaming/free-form/FreeFormDocumentStatus";
import {loadFreeFormDocumentPdfFileAsync} from "../../../store/reducers/roaming/free-form/freeFormDocumentReducer";
import {Card, Col, Row} from "react-bootstrap";
import SignFreeFormDocument from "./view/SignFreeFormDocument";
import CancelFreeFormDocument from "./view/CancelFreeFormDocument";
import AcceptFreeFormDocument from "./view/AcceptFreeFormDocument";
import DeclineFreeFormDocument from "./view/DeclineFreeFormDocument";
import dayjs from "dayjs";
import Badge from "../../common/Badge";
import DownloadFreeFormDocumentPdfFile from "./view/DownloadFreeFormDocumentPdfFile";
import DownloadFreeFormDocumentZipFile from "./view/DownloadFreeFormDocumentZipFile";
import HistoryActionSeal from "../history/HistoryActionSeal";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import {useNavigate} from "react-router-dom";
import {scrollEvent} from "../../../helpers/scrollEvent";

const FreeFormDocument = ({document}) => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const navigate = useNavigate()
    const lang = useSelector(selectLang);
    const t = Translate;
    const [viewPdf, setViewPdf] = useState('');

    const allowDownloadZipFile = document.status === ReceiverAccept;
    const allowSenderSign = activeOrganization.inn === document.sender.inn && document.status === AwaitSenderSign;
    const allowSenderCancel = activeOrganization.inn === document.sender.inn && document.status === SenderSent;
    const allowReceiverDecline = activeOrganization.inn === document.receiver.inn && document.status === SenderSent;
    const allowReceiverApprove = activeOrganization.inn === document.receiver.inn && document.status === SenderSent;

    const loadPdf = () => {
        loadFreeFormDocumentPdfFileAsync(document.id)
            .then(response => {
                const reader = new FileReader();
                reader.readAsDataURL(response);
                reader.onload = e => {
                    const base64 = e.target.result.split(',')[1];
                    setViewPdf(base64)
                }
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        loadPdf()
    }, [document])

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('roaming-invoice-view'))
        return () => window.removeEventListener('scroll', () => scrollEvent('roaming-invoice-view'))
    }, []);

    return (
        <Fragment>

            <Row id={'roaming-invoice-view'} className={'justify-content-between position-sticky z-index-1'}
                 style={{top: '4rem'}}>
                <Col xs={"auto"} className={'d-flex gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'}
                            style={{width: 35, height: 35}}>
                        <IconArrowLeft/>
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.free_form_document.view.title")} № {document?.info?.number}</h5>
                        <div className={'d-flex align-items-center gap-2'}>
                            <span className={'second-text fs-9'}>
                                {t(lang, 'roaming.free_form_document.view.free_form.date')}:
                                {dayjs(document.info.date, 'YYYY-MM-DD').format(dateFormat)}
                            </span>
                            <Badge
                                bg={GetStatusVariant(document.status)}
                                variant={'phoenix'}
                                className={'px-2 py-1 fs-9 text-uppercase text-decoration-none fw-medium border-0 rounded-4'}>
                                {t(lang, GetStatusText(document.status))}
                            </Badge>
                        </div>
                    </div>
                </Col>
                <Col xs={"auto"} className={'d-flex justify-content-end gap-2'}>
                    <DownloadFreeFormDocumentPdfFile id={document.id}/>
                    {allowDownloadZipFile && <DownloadFreeFormDocumentZipFile id={document.id}/>}
                    {allowSenderCancel && <CancelFreeFormDocument id={document.id} />}
                    {allowReceiverDecline && <DeclineFreeFormDocument id={document.id}/>}
                    {allowSenderSign && <SignFreeFormDocument id={document.id}/>}
                    {allowReceiverApprove && <AcceptFreeFormDocument id={document.id} />}
                </Col>
            </Row>

            <Card className={'mt-4'}>
                <Card.Footer as={Row} className={'border-0 justify-content-between fs-8 py-3 py-1'}>
                    <Col xs={12} md={6} className={'px-1'}>
                        <h5 className="mb-1 fw-bold second-text text-uppercase">{t(lang, 'roaming.free_form_document.view.sender.title')}</h5>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span
                                        className={'second-text'}>{t(lang, "roaming.free_form_document.view.sender.name")}</span>
                                </Col>
                                <Col md={8}>
                                    <span title={document.sender.name}
                                          className={'main-text w-100 text-truncate d-block h-100'}>{document.sender.name}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span
                                        className={'second-text'}>{t(lang, 'roaming.free_form_document.view.sender.inn')}</span>
                                </Col>
                                <Col md={8}>
                                    <span title={document.sender.inn}
                                          className={'main-text w-100 text-truncate d-block h-100'}>{document.sender.inn}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className={'px-1'}>
                        <h5 className="mb-1 fw-bold second-text text-uppercase">{t(lang, 'roaming.free_form_document.view.receiver.title')}</h5>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span
                                        className={'second-text'}>{t(lang, "roaming.free_form_document.view.receiver.name")}</span>
                                </Col>
                                <Col md={8}>
                                    <span title={document.receiver.name}
                                          className={'main-text w-100 text-truncate d-block h-100'}>
                                        {document.receiver.name}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span
                                        className={'second-text'}>{t(lang, 'roaming.free_form_document.view.receiver.inn')}</span>
                                </Col>
                                <Col md={8}>
                                    <span title={document.receiver.inn}
                                          className={'main-text w-100 text-truncate d-block h-100'}>{document.receiver.inn}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Card.Footer>
            </Card>

            {viewPdf &&
                <Card className={'mt-4 p-3'}>
                    <iframe src={`data:application/pdf;base64,${viewPdf}`} className="vh-100" width="100%"/>
                    <Row className="flex-column flex-md-row">
                        <Col xs={12} md={6} className="d-flex justify-content-center flex-column align-items-start">
                            {document?.histories?.map((history, index) => {
                                const showExecutorHistory = getStatusShowExecutorHistory(history?.action)
                                if (showExecutorHistory)
                                    return (
                                        <div key={index}>
                                            <HistoryActionSeal
                                                serialNumber={history?.certificate?.serial_number}
                                                signingTime={history?.certificate?.signing_time}
                                                fullName={history?.certificate?.full_name}
                                                statusName={t(lang, getStatusHistory(history?.action))}
                                                variant={getStatusHistoryVariant(history?.action)}
                                                bg={getStatusHistoryBg(history?.action)}
                                            />
                                        </div>
                                    )
                            })}
                        </Col>
                        <Col xs={12} md={6} className="d-flex justify-content-center flex-column align-items-end">
                            {document?.histories?.map((history, index) => {
                                const showCustomerHistory = getStatusShowCustomerHistory(history?.action)
                                if (showCustomerHistory)
                                    return (
                                        <div key={index}>
                                            <HistoryActionSeal
                                                serialNumber={history?.certificate?.serial_number}
                                                signingTime={history?.certificate?.signing_time}
                                                fullName={history?.certificate?.full_name}
                                                statusName={t(lang, getStatusHistory(history?.action))}
                                                variant={getStatusHistoryVariant(history?.action)}
                                                bg={getStatusHistoryBg(history?.action)}
                                            />
                                        </div>
                                    )
                            })}
                        </Col>
                    </Row>
                </Card>
            }
        </Fragment>
    );
};

export default FreeFormDocument;
