import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {useFieldArray} from "react-hook-form";
import {Card, Col, Row, Spinner, Table} from "react-bootstrap";
import IconButton from "../../../IconButton";
import {faBookmark, faPaperPlane, faPlus} from "@fortawesome/free-solid-svg-icons";
import WaybillItemRow from "./WaybillItemRow";
import SelectCertificateWrapperComponent from "../../../common/SelectCertificateWrapperComponent";

const itemsFieldName = 'products';


const WaybillItemsForm = () => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const {fields, append, remove} = useFieldArray({name: itemsFieldName});
    const allowDeleteRow = fields.length > 1;

    const addNewItem = () => {
        append({
            product: '',
            catalog_code: '',
            catalog_name: '',
            price: '',
            measureId: '',
            packageCode: null,
            packageName: null,
            packages: null,
            quantity: '',
            total: '',
            road_haulage_cost: '',
            follow_documents: '',
            weight_define_method: '',
            load_class: '',
            weight_brutto: '',
            weight_netto: '',
        });
    }

    return (
        <Fragment>
            <Row className="align-items-end g-2 mt-2">
                <Col>
                    <h4 className="mb-0 ms-3 mb-2 fs-9 second-text fw-medium">{t(lang, 'roaming.waybill.send.shipment_items_form.title')}</h4>
                </Col>
               {/* <Col xs="auto">
                    <IconButton
                        variant="phoenix-primary" size="sm" icon={faPlus} transform="shrink-3" className="me-2" onClick={addNewItem}>
                        <span className="d-none d-sm-inline-block ms-1">{t(lang, "roaming.common.add")}</span>
                    </IconButton>
                </Col>*/}
            </Row>

            <Card className={'py-1 px-2 rounded-4 border-0'}>
                <Table responsive className="scrollbar view-table border-900 mb-0 fs-9 second-text fw-semi-bold">
                    <thead className="text-dark border-bottom">
                    <tr>
                        <th className="fw-medium  align-middle text-center">#</th>
                        <th className="fw-medium  align-middle text-center" scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.product')}<span className="text-danger">*</span></th>
                        <th className="fw-medium  align-middle text-center" scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.catalog_code')}</th>
                        <th className="fw-medium  align-middle text-center" scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.price')}<span className="text-danger">*</span></th>
                        <th className="fw-medium  align-middle text-center" scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.measurement')}<span className="text-danger">*</span></th>
                        <th className="fw-medium  align-middle text-center" scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.quantity')}<span className="text-danger">*</span></th>
                        <th className="fw-medium  align-middle text-center" scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.total')}</th>
                        <th className="fw-medium  align-middle text-center" scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.road_haulage_cost')}<span className="text-danger">*</span></th>
                        <th className="fw-medium  align-middle text-center" scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.follow_documents')}</th>
                        <th className="fw-medium  align-middle text-center" scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.weight_define_method')}</th>
                        <th className="fw-medium  align-middle text-center" scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.load_class')}</th>
                        <th className="fw-medium  align-middle text-center" scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.weight_brutto')}</th>
                        <th className="fw-medium  align-middle text-center" scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.weight_netto')}</th>
                        <th className="fw-medium  align-middle text-center" scope="col">
                            <IconButton icon={faPlus}
                                        variant={'phoenix-primary'}
                                        onClick={addNewItem}
                                        className={'bg-primary-davr py-2 px-2 rounded-3'}
                                        iconClassName={'text-white'}
                            />
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {fields.map((item, index) => (
                        <WaybillItemRow
                            key={item.id}
                            index={index}
                            remove={remove}
                            allowDelete={allowDeleteRow}
                            arrayFieldName={itemsFieldName}
                        />
                    ))}
                    </tbody>
                </Table>
            </Card>
        </Fragment>
    );
};

export default WaybillItemsForm;
