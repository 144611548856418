import React, {Fragment, useEffect} from 'react';
import {router} from "../Router";
import {Route, RouterProvider, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    authStatus,
    initializeAsync as authInitializeAsync,
    selectIsAuthorized
} from "../store/reducers/auth/authReducer";
import {initializeAsync as eimzoInitializeAsync} from "../store/reducers/eimzo/eimzoReducer";


const AppLayout = () => {
    const isAuthorized = useSelector(selectIsAuthorized);
    const status = useSelector(authStatus);
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(authInitializeAsync())
        dispatch(eimzoInitializeAsync())
    }, []);


    return (
        <Fragment>
            <RouterProvider router={router}/>
        </Fragment>
    );
};

export default AppLayout;
