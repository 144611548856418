import axios from "axios";

class ProductMapService {
    getProductNames({customer_inn, executor_inn}) {
        const params = {
            customer_inn: customer_inn,
            executor_inn: executor_inn
        }
        return axios.get('/product-map/get', {params})
    }

    getProductIdentity({itemId}) {
        return axios.get(`/product-map/product-identity/get/${itemId}`)
    }

    deleteProductIdentity({item_id, bind_attribute_id}) {
        return axios.post(`/product-map/product-identity/delete-bind-attribute`, {
            item_id: item_id,
            bind_attribute_id: bind_attribute_id
        })
    }
}

const productMapService = new ProductMapService();

export default productMapService
