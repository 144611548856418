import React, {Fragment, useEffect, useState} from 'react';
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import ReactSelect from "react-select";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {useParams} from "react-router-dom";
import {Translate} from "../../../lang/lang";
import {getWaybillStatuses} from "../../../enum/roaming/waybill/WaybillStatus";
import {Controller} from "react-hook-form";
import CustomFloatingLabelSelect from "../../floating-custom-select/CustomFloatingLabelSelect";


const WaybillDataTableFilter = () => {
    const lang = useSelector(selectLang);
    const {viewer} = useParams();
    const t = Translate;

    const [value, setValue] = useState(null);

    const statusOptions = getWaybillStatuses(viewer);

    const onChangeHandler = (option) => {
        setValue(option)
    }

    return (
        <Fragment>
            <Card className={'p-1'}>
                <Row className="myjustify-content-between align-items-center">
                    <Col md={12}>
                        <Controller
                            name="status"
                            render={({field}) => (
                                <CustomFloatingLabelSelect
                                    value={value}
                                    isClearable
                                    options={statusOptions}
                                    hideSelectedOptions={true}
                                    getOptionValue={option => option.id}
                                    onChange={option => {
                                        field.onChange(option?.id)
                                        onChangeHandler(option)
                                    }}
                                    getOptionLabel={option => t(lang, option.name)}
                                    label={t(lang, 'roaming.waybill.datatable.header.filter.status_filter')}
                                />
                            )}
                        />
                    </Col>
                </Row>
            </Card>
            <Form.Group className={"justify-content-end d-flex"}>
                <Button className="float-end my-2 btn-davr px-3 py-0 bg-primary-davr"
                        type='submit'>{t(lang, "roaming.waybill.datatable.header.filter.show_button")}</Button>
            </Form.Group>
        </Fragment>
    );
};

export default WaybillDataTableFilter;
