import React, {Fragment} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {PermissionSystemRoleView} from "../../enum/permission/RolePermission";
import checkPermission from "../../enum/permission/CheckPermission";
import PermissionBadge from "../../components/common/PermissionBadge";
import {Translate} from "../../lang/lang";
import {selectLang} from "../../store/reducers/main/main";
import RolesDatatable from "../../components/settings/role/RolesDatatable";
import AddRole from "../../components/settings/role/AddRole";

const Role = () => {
    const dispatch = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Fragment>
            {
                dispatch(checkPermission(PermissionSystemRoleView)) ?
                    <Fragment>
                        <AddRole/>
                        <RolesDatatable/>
                    </Fragment> :
                    <PermissionBadge>
                        <span>{t(lang, 'system.permission.role.user.datatable.no_access.title')}</span>
                    </PermissionBadge>
            }
        </Fragment>
    );
};

export default Role;
