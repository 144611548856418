import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import roamingInvoiceWrapper, {
    defaultRoamingInvoiceDatatableColumns,
    DRAFT, StatusSentToCustomer, VIEWER_CUSTOMER,
    VIEWER_EXECUTOR, VIEWER_PERSON
} from "../../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import {clearFilterOptions} from "../../../store/reducers/roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import {Link, useLocation, useNavigate} from "react-router-dom";
import ActionProgressBar from "../../common/ActionProgressBar";
import IconButton from "../../IconButton";
import {Alert, Col, Form, Nav, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import PropTypes from "prop-types";
import UploadRoamingInvoiceListToExcel from "./UploadRoamingInvoiceListToExcel";
import RoamingInvoiceDraftSendAll from "./RoamingInvoiceDraftSendAll";
import RoamingInvoiceDraftSend from "./RoamingInvoiceDraftSend";
import RoamingInvoiceExecutorCancel from "./RoamingInvoiceExecutorCancel";
import RoamingInvoiceCustomerDecline from "./RoamingInvoiceCustomerDecline";
import RoamingInvoiceCustomerAccept from "./RoamingInvoiceCustomerAccept";
import RoamingInvoicePersonDecline from "./RoamingInvoicePersonDecline";
import RoamingInvoicePersonAccept from "./RoamingInvoicePersonAccept";
import RoamingInvoiceDataTableFilter from "./RoamingInvoiceDataTableFilter";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import IconCloudDownload from "../../../assets/icon/IconCloudDownload";
import IconFilter from "../../../assets/icon/IconFilter";
import IconPlus from "../../../assets/icon/IconPlus";
import IconCaretDown from "../../../assets/icon/IconCaretDown";
import IconStroke from "../../../assets/icon/IconStroke";
import {scrollEvent} from "../../../helpers/scrollEvent";
const RoamingInvoiceDataTableHeader = ({
                                           returnInvoice,
                                           excelRegistry,
                                           viewer,
                                           predefinedFilters,
                                           selectedRowIds,
                                           page,
                                           loadInvoices,
                                           positiveSumPage,
                                           negativeSumPage,
                                           changeFilterOptionsAsync,
                                           filters,
                                           count,
                                           ...props
                                       }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const lang = useSelector(selectLang)
    const t = Translate
    const numberFormat = useSelector(selectNumberFormat);
    const methods = useForm({
        defaultValues: {
            number: filters.number || null,
            date_start: filters.date_start ? dayjs(filters.date_start).toDate() :null,
            date_end: filters.date_end ? dayjs(filters.date_end).toDate() : null,
            created_at_start: filters.created_at_start ? dayjs(filters.created_at_start).toDate() : null,
            created_on_end: filters.created_on_end ? dayjs(filters.created_on_end).toDate() : null,
            contract_number: filters.contract_number || null,
            contract_date: filters.contract_date ?  dayjs(filters.contract_date).toDate() : null,
            customer_inn: filters.customer_inn || null,
            customer_branch_code: filters.customer_branch_code || null,
            executor_inn: filters.executor_inn || null,
            executor_branch_code: filters.executor_branch_code || null,
            start_total_sum: filters.start_total_sum || null,
            end_total_sum: filters.end_total_sum || null,
            status: filters.status || null
        },
    });



    // show/hide filter
    const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(returnInvoice || false);

    // get selected invoices
    const selectedRowIndexArray = Object.keys(selectedRowIds);
    const selectedInvoices = selectedRowIndexArray.reduce((result, selectedIndex) => {
        result.push(page[selectedIndex]?.original);
        return result;
    }, [])

    // allow bulk actions
    const allowExecutorBulkSign = viewer === DRAFT && selectedRowIndexArray.length > 0;
    const allowExecutorBulkDelete = viewer === DRAFT && selectedRowIndexArray.length > 0;
    const allowDraftSendAll = viewer === DRAFT;
    const allowExecutorBulkCancel = viewer === VIEWER_EXECUTOR && filters?.status === StatusSentToCustomer && selectedRowIndexArray.length > 0;
    const allowCustomerBulkActions = viewer === VIEWER_CUSTOMER && filters?.status === StatusSentToCustomer && selectedRowIndexArray.length > 0;
    const allowPersonBulkActions = viewer === VIEWER_PERSON && filters?.status === StatusSentToCustomer && selectedRowIndexArray.length > 0;

    // show progress modal
    const [showProgressBar, setShowProgressBar] = useState(false);
    const handleShowProgressBar = () => setShowProgressBar(true);
    const [customProgressBarCount, setCustomerProgressBarCount] = useState(null)
    const handleCloseProgressBar = () => {
        loadInvoices();
        setBulkActionResult([]);
        setShowProgressBar(false);
        setCustomerProgressBarCount(null);
    };

    // bulk action result
    const [bulkActionResult, setBulkActionResult] = useState([]);
    const updateActionResult = (result) => setBulkActionResult(prev => [...prev, result]);

    const onSubmitFilter = ({
                                number,
                                date_start,
                                date_end,
                                created_at_start,
                                created_at_end,
                                contract_number,
                                contract_date,
                                customer_inn,
                                customer_branch_code,
                                executor_inn,
                                executor_branch_code,
                                start_total_sum,
                                end_total_sum
                            }) => {
        const filterDateFormat = "YYYY-MM-DD";
        const filter = {
            created_at_start: created_at_start ? dayjs(created_at_start).format(filterDateFormat) : null,
            created_at_end: created_at_end ? dayjs(created_at_end).format(filterDateFormat) : null,
            date_start: date_start ? dayjs(date_start).format(filterDateFormat) : null,
            date_end: date_end ? dayjs(date_end).format(filterDateFormat) : null,
            number: number || null,
            contract_number: contract_number || null,
            contract_date: contract_date ? dayjs(contract_date).format(filterDateFormat) : null,
            start_total_sum: start_total_sum ? +start_total_sum : null,
            end_total_sum: end_total_sum ? +end_total_sum : null,
            customer_inn: customer_inn || null,
            customer_branch_code: customer_branch_code || null,
            executor_inn: executor_inn || null,
            executor_branch_code: executor_branch_code || null
        }

        if (excelRegistry || returnInvoice) {
            changeFilterOptionsAsync({...filters, ...filter})
        } else {
            dispatch(changeFilterOptionsAsync({...filters, ...filter}))
        }

    }

    const onResetFilter = () => {
        methods.reset()
        dispatch(clearFilterOptions({
            page: filters.page,
            limit: filters.limit,
        }))
    }

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('roaming-invoice-data-table-header'))
        return () => window.removeEventListener('scroll', () => scrollEvent('roaming-invoice-data-table-header'))
    }, []);

    return (
        <Fragment>
            <ActionProgressBar show={showProgressBar}
                               result={bulkActionResult}
                               count={customProgressBarCount || selectedInvoices.length}
                               onClose={handleCloseProgressBar}
            />

            <Row id={'roaming-invoice-data-table-header'} className="flex-between-center align-items-center my-3 position-sticky z-index-1" style={{top: '4rem'}}>
                <Col cols={'auto'} className={'d-flex gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'} style={{width: 35, height: 35}}>
                        <IconArrowLeft />
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.invoices.datatable.title")}: {count}</h5>
                        <span className={'second-text fs-9'}>{t(lang, 'edi.navbar.routes.documents')}</span>
                    </div>
                </Col>
                <Col cols={'auto'} className={'d-flex align-items-center justify-content-end gap-2'}>
                    {allowExecutorBulkCancel &&
                        <RoamingInvoiceExecutorCancel invoices={selectedInvoices}
                                                      handleShowProgressBar={handleShowProgressBar}
                                                      updateActionResult={updateActionResult}
                                                      size="sm"
                        />
                    }

                    {allowExecutorBulkSign &&
                        <RoamingInvoiceDraftSend invoices={selectedInvoices}
                                                 handleShowProgressBar={handleShowProgressBar}
                                                 updateActionResult={updateActionResult}
                                                 size="sm"
                        />
                    }

                    {allowDraftSendAll && <RoamingInvoiceDraftSendAll
                        handleShowProgressBar={handleShowProgressBar}
                        updateActionResult={updateActionResult}
                        filters={filters}
                        predefinedFilters={predefinedFilters}
                        setCount={setCustomerProgressBarCount}
                        size="sm"
                    />}

                    {allowCustomerBulkActions &&
                        <Fragment>
                            <RoamingInvoiceCustomerDecline invoices={selectedInvoices}
                                                           handleShowProgressBar={handleShowProgressBar}
                                                           updateActionResult={updateActionResult}
                                                           size="sm"
                            />
                            <RoamingInvoiceCustomerAccept invoices={selectedInvoices}
                                                          handleShowProgressBar={handleShowProgressBar}
                                                          updateActionResult={updateActionResult}
                                                          size="sm"
                            />
                        </Fragment>
                    }

                    {allowPersonBulkActions &&
                        <Fragment>
                            <RoamingInvoicePersonDecline invoices={selectedInvoices}
                                                         handleShowProgressBar={handleShowProgressBar}
                                                         updateActionResult={updateActionResult}
                                                         size="sm"
                            />
                            <RoamingInvoicePersonAccept invoices={selectedInvoices}
                                                        handleShowProgressBar={handleShowProgressBar}
                                                        updateActionResult={updateActionResult}
                                                        size="sm"
                            />
                        </Fragment>
                    }

                    <IconButton
                        size="sm"
                        as={Link}
                        className="btn-davr px-5 py-0 bg-white"
                        transform="shrink-3"
                        variant="phoenix-secondary"
                        to="/roaming/invoice/mass-add"
                    >
                        <IconCloudDownload />
                        <span className="d-none d-sm-inline-block ms-1 fs-8 text-nowrap fs-md-9">
                            {t(lang, "roaming.invoices.datatable.mass_add_invoice")}
                        </span>
                    </IconButton>

                    <UploadRoamingInvoiceListToExcel className="d-inline-block" />

                    <IconButton
                        onClick={() => setIsOpenFilterCollapse(!isOpenFilterCollapse)}
                        className="btn-davr ps-2 pe-1 py-0 bg-white text-dark gap-2"
                    >
                        <div className={'d-flex align-items-center'}>
                            <IconFilter />
                            <span className="d-none d-sm-inline-block ms-1 fs-8 text-nowrap fs-md-9">{t(lang, "roaming.invoices.table_header.filter")}</span>
                        </div>
                        <div className={'d-flex justify-content-between gap-2'}>
                            <IconStroke />
                            <IconCaretDown />
                        </div>
                    </IconButton>

                    <IconButton
                        as={Link}
                        size="sm"
                        transform="shrink-3"
                        className="btn-davr px-3 py-0 bg-primary-davr"
                        to="/roaming/invoice/send"
                    >
                        <IconPlus />
                        <span className="d-none d-sm-inline-block ms-1 fs-8 text-white text-nowrap fs-md-9">{t(lang, "roaming.invoices.add.title")}</span>
                    </IconButton>
                </Col>
                <Col sm={12}>
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                            <RoamingInvoiceDataTableFilter viewer={viewer} isOpenFilterCollapse={isOpenFilterCollapse}
                                                           predefinedFilters={predefinedFilters}
                                                           onResetFilter={onResetFilter}
                            />
                        </Form>
                    </FormProvider>
                </Col>
            </Row>
        </Fragment>
    );
};

RoamingInvoiceDataTableHeader.propTypes = {
    selectedRowIds: PropTypes.object,
};

RoamingInvoiceDataTableHeader.defaultProps = {
    selectedRowIds: {},
}

export default RoamingInvoiceDataTableHeader;
