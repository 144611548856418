import React from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {syncActAsync} from "../../../../store/reducers/roaming/act/actReducer";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../IconButton";

const SyncAct = ({id, ...props}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const syncAct = () => {
        syncActAsync(id)
            .then(response => {})
            .catch(error => {})
    }
    return (
        <IconButton
            icon={faRefresh}
            variant="phoenix-primary"
            onClick={syncAct}
            size="sm"
            {...props}
            className={'btn-davr bg-white text-dark d-flex gap-2'}
            iconClassName={"text-primary-davr fs-8"}
        >
            {t(lang, "roaming.empowerment.sync")}
        </IconButton>
    );
};

export default SyncAct;
