import React, {Fragment, useEffect, useState} from 'react';
import {Dropdown, Nav} from "react-bootstrap";
import {RouteItems, routes} from "../../../sitemap";
import {Link, useLocation} from "react-router-dom";
import {useBreakpoints} from "../../../pheonix/providers/BreakpointsProvider";
import TopNavMegaMenu from "./TopNavMegaMenu";
import TopNavItem from "./TopNavItem";
import {capitalize} from "../../../pheonix/helpers/utils";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import RedirectAlert from "../../common/RedirectAlert";
import classNames from "classnames";

const NavbarTopNav = () => {
    return (
        <Nav className="navbar-nav-top pb-4 pb-lg-0 scrollbar">
            {routes.map(route => (
                <NavbarTopNavItem route={route} key={route.label}/>
            ))}
        </Nav>
    );
};

const NavbarTopNavItem = ({route}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const Icon = route.icon
    const [show, setShow] = useState(false);
    const [showRedirectModal, setShowRedirectModal] = useState(false);
    const { pathname } = useLocation();

    const {breakpoints} = useBreakpoints();


    const handleMouseEnter = () => {
        if (breakpoints.up('lg')) {
            setShow(true);
        }
    };

    const handleMouseLeave = () => {
        if (breakpoints.up('lg')) {
            setShow(false);
        }
    };

    useEffect(() => {
        if (show) {
            setShow(false);
        }
    }, [pathname]);

    return (
       <Fragment>
           <Dropdown
               as={'li'}
               show={show}
               className="nav-item"
               key={route.label}
               autoClose="outside"
               onMouseEnter={handleMouseEnter}
               onMouseLeave={handleMouseLeave}
               onToggle={() => setShow(!show)}
           >
               <Dropdown.Toggle
                   as={route.to ? Link : "p"}
                   to={route.to}
                   variant=""
                   onClick={!route.to ? ()=>setShowRedirectModal(true) : () => {}}
                   className={classNames("mb-0 nav-link fs-8 lh-1 d-flex align-items-center cursor-pointer", {
                       'active-box-shadow': pathname.includes(route.to)
                   })}
               >
                   {t(lang, route.label)}
               </Dropdown.Toggle>
               {route.megaMenu ? (
                   <TopNavMegaMenu route={route} />
               ) : (
                   route.pages && <TopNavItem route={route} />
               )}
           </Dropdown>

           <RedirectAlert show={showRedirectModal} setShow={setShowRedirectModal} />
       </Fragment>
    )
}

export default NavbarTopNav;
