import React, {Fragment, useState} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {selectCurrentCertificate} from "../../../../store/reducers/eimzo/eimzoReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import checkPermission from "../../../../enum/permission/CheckPermission";
import toastPermission from "../../../../enum/permission/ToastPermission";
import {PermissionRoamingActReject} from "../../../../enum/permission/RoamingPermission";
import {faPause} from "@fortawesome/free-solid-svg-icons";
import {Button, Form, Modal} from "react-bootstrap";
import SignActionWithSelectCertificateWrapper from "../../../common/SignActionWithSelectCertificateWrapper";
import IconButton from "../../../IconButton";

const CustomerDecline = ({ActDeclineHandler, disabled, ...props}) => {
    const methods = useForm();
    const {register, formState: {errors}, handleSubmit, reset} = methods;
    const currentCertificate = useSelector(selectCurrentCertificate);
    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);

    const handleShow = () => {
        if (dispatch(checkPermission(PermissionRoamingActReject))) setShow(true)
        else dispatch(toastPermission())
    }
    const handleClose = () => {
        reset();
        setShow(false);
    };

    const formSubmit = ({comment}) => {
        if(currentCertificate?.keyId) {
            ActDeclineHandler(comment)
            reset();
            setShow(false);
        }
    };

    return (
        <Fragment>
            <IconButton
                variant="phoenix-warning"
                size="sm"
                icon={faPause}
                disabled={disabled}
                onClick={handleShow}
                className={'btn-davr bg-white text-dark d-flex gap-2'}
                iconClassName={"text-primary-davr fs-8"}
                {...props}
            >{t(lang,"edi.common.button.decline")}</IconButton>

            <Modal size="md" show={show} onHide={handleClose}>
                <FormProvider {...methods}>
                    <Form onSubmit={handleSubmit(formSubmit)} id="CustomerDeclineForm">
                        <Modal.Header closeButton>
                            <Modal.Title>Act decline</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>{t(lang,"edi.common.modal.reason")}</Form.Label>
                                <Form.Control
                                    name="reason"
                                    as="textarea" rows={3}
                                    placeholder={t(lang,"edi.common.modal.reason.placeholder")}
                                    isInvalid={errors?.comment?.message}
                                    {...register("comment", {
                                        required: {
                                            value: true,
                                            message: t(lang,"edi.common.forms.validations.is_required")
                                        }
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.comment?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => {handleClose()}}>{t(lang,"edi.common.button.close")}</Button>
                            <SignActionWithSelectCertificateWrapper isForm>
                                <Button
                                    variant="warning"
                                    className="d-flex align-items-center fw-semi-bold"
                                    disabled={disabled}
                                    type="submit"
                                    form="CustomerDeclineForm"
                                >{t(lang,"edi.common.button.decline")}
                                </Button>
                            </SignActionWithSelectCertificateWrapper>
                        </Modal.Footer>
                    </Form>
                </FormProvider>
            </Modal>
        </Fragment>
    );
};

export default CustomerDecline;
