import React, {Fragment} from 'react';
import {Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";
import logo from '../../assets/img/DavrBusiness.png'
import logoLight from '../../assets/img/DavrBusinessLight.png'
import {useBreakpoints} from "../../pheonix/providers/BreakpointsProvider";
import NavbarToggleButton from "./NavbarToggleButton";
import {useAppContext} from "../../pheonix/providers/AppProvider";
const NavbarBrand = () => {
    const { breakpoints } = useBreakpoints();

    const {
        config: { theme, isRTL },
        toggleTheme
    } = useAppContext();


    return (
        <Fragment>
            {breakpoints.down('lg') && <NavbarToggleButton />}
            <div className={'navbar-logo d-none d-lg-flex align-items-center '}>
                <Navbar.Brand
                    as={Link}
                    to="/"
                    className={'fs-6 me-1 me-sm-3 d-flex align-items-center gap-2 text-primary fw-bold'}
                >
                    <img src={theme === 'dark' ? logoLight : logo} width={130} height={30} alt="Hippo logo"/>
                </Navbar.Brand>
            </div>
        </Fragment>

    );
};

export default NavbarBrand;
