import React, {Fragment, useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {
    changeWaybillsV2FilterOptions,
    selectWaybillsV2Count,
    selectWaybillsV2FilterOptions, waybillAcceptAsync, waybillCancelAsync, waybillDeclineAsync,
    waybillResponsiblePersonAccept, waybillResponsiblePersonGive,
    waybillResponsiblePersonReject, waybillResponsiblePersonReturn,
    waybillResponsiblePersonTillReturn, waybillReturnAcceptAsync
} from "../../../store/reducers/roaming/waybill-v2/romaingWaybillV2Reducer";
import {
    RoamingWaybillV2StatusCustomerAccepted, RoamingWaybillV2StatusCustomerRejected,
    RoamingWaybillV2StatusExecutorSent, RoamingWaybillV2StatusResponsiblePersonAccepted,
    RoamingWaybillV2StatusResponsiblePersonGiven,
    RoamingWaybillV2StatusResponsiblePersonReturned, RoamingWaybillV2StatusResponsiblePersonTillReturned,
    VIEW_OUTBOX_STATUSES
} from "../../../enum/roaming/waybill-v2/WaybillV2Status";
import {VIEW_DRAFTS, VIEW_INBOX, VIEW_OUTBOX} from "../../../enum/roaming/waybill/WaybillStatus";
import {selectLang} from "../../../store/reducers/main/main";
import {FormProvider, useForm} from "react-hook-form";
import {Translate} from "../../../lang/lang";
import dayjs from "dayjs";
import ActionProgressBar from "../../common/ActionProgressBar";
import {Col, Collapse, Form, Row} from "react-bootstrap";
import WaybillDataTableFilter from "../waybill/WaybillDataTableFilter";
import {scrollEvent} from "../../../helpers/scrollEvent";
import WaybillV2DatatableFilter from "./WaybillV2DatatableFilter";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import WaybillV2ResponsiblePersonReject from "./action/WaybillV2ResponsiblePersonReject";
import WaybillV2ResponsiblePersonAccept from "./action/WaybillV2ResponsiblePersonAccept";
import WaybillV2ResponsiblePersonTillReturn from "./action/WaybillV2ResponsiblePersonTillReturn";
import WaybillV2ResponsiblePersonGive from "./action/WaybillV2ResponsiblePersonGive";
import WaybillV2ResponsiblePersonReturn from "./action/WaybillV2ResponsiblePersonReturn";
import WaybillV2ReturnAccepted from "./action/WaybillV2ReturnAccepted";
import WaybillV2ConsignorCancel from "./action/WaybillV2ConsignorCancel";
import WaybillV2CustomerAccept from "./action/WaybillV2CustomerAccept";
import WaybillV2CustomerDecline from "./action/WaybillV2CustomerDecline";
import WaybillV2DraftDelete from "./WaybillV2DraftDelete";
import WaybillV2DraftSend from "./action/WaybillV2DraftSend";
import WaybillV2DraftSendAll from "./action/WaybillV2DraftSendAll";
import IconButton from "../../IconButton";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import IconFilter from "../../../assets/icon/IconFilter";
import IconStroke from "../../../assets/icon/IconStroke";
import IconCaretDown from "../../../assets/icon/IconCaretDown";
import IconPlus from "../../../assets/icon/IconPlus";

const WaybillV2DataTableHeader = ({waybillCount, viewer, loadWaybills, selectedRowIds, page, predefinedFilters}) => {
    const count = useSelector(selectWaybillsV2Count);
    const activeOrganization = useSelector(selectActiveOrganization);
    const navigate = useNavigate();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const dispatch = useDispatch();
    const filters = useSelector(selectWaybillsV2FilterOptions);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [customProgressBarCount, setCustomerProgressBarCount] = useState(null)
    const [bulkActionResult, setBulkActionResult] = useState([]);
    const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(false);



    const selectedRowIndexArray = Object.keys(selectedRowIds);
    const selectedWaybills = selectedRowIndexArray.reduce((result, selectedIndex) => {
        result.push(page[selectedIndex]?.original);
        return result;
    }, [])

    const viewerIsDraft = viewer === VIEW_DRAFTS

    const allowExecutorBulkSign = viewerIsDraft && selectedRowIndexArray.length > 0;
    const allowExecutorBulkDelete = viewerIsDraft && selectedRowIndexArray.length > 0;
    const allowDraftSendAll = viewerIsDraft;
    const allowCustomerBulkActions = viewer === VIEW_INBOX && filters?.status === RoamingWaybillV2StatusExecutorSent && selectedRowIndexArray.length > 0;
    const allowCreateInvoice = viewer === VIEW_OUTBOX && filters?.status === RoamingWaybillV2StatusCustomerAccepted && !!filters?.customer_inn && selectedRowIndexArray.length > 0;

    const allowIsConsignor = !viewerIsDraft && filters.status === RoamingWaybillV2StatusExecutorSent && selectedRowIndexArray.length > 0;
    const allowIsConsignee = !viewerIsDraft && filters.status === RoamingWaybillV2StatusResponsiblePersonGiven && selectedRowIndexArray.length > 0;
    // const isDraftWaybill = !waybill.status && content;
    const allowReturnAccepted = !viewerIsDraft && (filters.status === RoamingWaybillV2StatusResponsiblePersonTillReturned || filters.status === RoamingWaybillV2StatusResponsiblePersonReturned) && selectedRowIndexArray.length > 0

    // responsible-person
    const allowResponsiblePersonRejectedOrAccepted = !viewerIsDraft && filters.status === RoamingWaybillV2StatusExecutorSent && selectedRowIndexArray.length > 0
    const allowResponsiblePersonReturnOrGiven = !viewerIsDraft && filters.status === RoamingWaybillV2StatusResponsiblePersonAccepted && selectedRowIndexArray.length > 0
    const allowResponsiblePersonReturn = !viewerIsDraft && (filters.status === RoamingWaybillV2StatusResponsiblePersonGiven || filters.status === RoamingWaybillV2StatusCustomerRejected) && selectedRowIndexArray.length > 0

    // show progress modal
    const handleShowProgressBar = () => setShowProgressBar(true);
    // bulk action result
    const updateActionResult = (result) => setBulkActionResult(prev => [...prev, result]);
    const handleCloseProgressBar = () => {
        loadWaybills();
        setBulkActionResult([]);
        setShowProgressBar(false);
        setCustomerProgressBarCount(null);
    };
    const methods = useForm({
        customer_inn: null,
        executor_inn: null,
        executor_branch_code: null,
        customer_branch_code: null,
        responsible_person_inn: null,
        number: null,
        registry_id: null,
        contract_number: null,
        contract_date: null,
        date_start: null,
        date_end: null,
        delivery_type: null,
        transport_type: null,
    });
    const t = Translate;
    const lang = useSelector(selectLang);

    const toggleCollapse = () => setIsCollapsed(!isCollapsed);
    const onSubmitFilter = (filter) => {
        const filterDateFormat = "YYYY-MM-DD";

        dispatch(changeWaybillsV2FilterOptions({
            ...filters,
            customer_inn: filter.customer_inn || null,
            executor_inn: filter.executor_inn || null,
            executor_branch_code: filter.executor_branch_code || null,
            customer_branch_code: filter.customer_branch_code || null,
            responsible_person_inn: filter.responsible_person_inn || null,
            number: filter.number || null,
            registry_id: filter.registry_id || null,
            contract_number: filter.contract_number || null,
            contract_date: filter.contract_date && dayjs(filter.contract_date).format(filterDateFormat) || null,
            date_start: filter.date_start && dayjs(filter.date_start).format(filterDateFormat) || null,
            date_end: filter.date_end && dayjs(filter.date_end).format(filterDateFormat) || null,
            created_at_start: filter.created_at_start && dayjs(filter.created_at_start).format(filterDateFormat) || null,
            created_at_end: filter.created_at_end && dayjs(filter.created_at_end).format(filterDateFormat) || null,
            delivery_type: filter.delivery_type || null,
            transport_type: filter.transport_type || null,
        }))
    };

    const onCreateInvoice = (waybills) => {
        let link = `/roaming/invoice/send?waybill_v2_id=`;
        waybills.map((waybill, index) => {
            link += waybill.id;
            if (index !== waybills.length - 1)
                link += ",";
        });
        navigate(link);
    };

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('roaming-waybill-v2-data-table-header'))
        return () => window.removeEventListener('scroll', () => scrollEvent('roaming-waybill-v2-data-table-header'))
    }, []);

    return (
        <Fragment>
            <ActionProgressBar show={showProgressBar}
                               result={bulkActionResult}
                               count={customProgressBarCount || selectedWaybills.length}
                               onClose={handleCloseProgressBar}
            />

            <Row id={'roaming-waybill-v2-data-table-header'} className="flex-between-center align-items-center my-3 position-sticky" style={{top: '4rem'}}>
                <Col xs={12} lg={4} className={'d-flex gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'} style={{width: 35, height: 35}}>
                        <IconArrowLeft />
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "edi.navbar.routes.edo.waybill.v2")}: {count}</h5>
                        <span className={'second-text fs-9'}>{t(lang, 'edi.navbar.routes.documents')}</span>
                    </div>
                </Col>

                <Col xs={12} lg={8} className={'d-flex align-items-center justify-content-end gap-2'}>
                    {allowResponsiblePersonRejectedOrAccepted && <Fragment>
                        <WaybillV2ResponsiblePersonReject
                            waybills={selectedWaybills}
                            handleShowProgressBar={handleShowProgressBar}
                            updateActionResult={updateActionResult}
                            requestApi={waybillResponsiblePersonReject}
                            btnText={"roaming.waybill.v2.title.responsible.person.rejected"}
                            variant={"falcon-danger"}
                            mTitle={"roaming.waybill.v2.responsible.person.reject"}
                            cancel={true}
                        />

                        <WaybillV2ResponsiblePersonAccept
                            waybills={selectedWaybills}
                            handleShowProgressBar={handleShowProgressBar}
                            updateActionResult={updateActionResult}
                            requestApi={waybillResponsiblePersonAccept}
                            btnText={"roaming.waybill.v2.title.responsible.person.accepted"}
                            variant={'falcon-success'}
                        />
                    </Fragment>}

                    {
                        allowResponsiblePersonReturnOrGiven && <Fragment>
                            <WaybillV2ResponsiblePersonTillReturn
                                waybills={selectedWaybills}
                                handleShowProgressBar={handleShowProgressBar}
                                updateActionResult={updateActionResult}
                                requestApi={waybillResponsiblePersonTillReturn}
                                btnText={"roaming.waybill.v2.title.responsible.person.returned"}
                                variant={'falcon-danger'}
                                mTitle={"roaming.waybill.v2.responsible.person.till.return"}
                            />

                            <WaybillV2ResponsiblePersonGive
                                waybills={selectedWaybills}
                                handleShowProgressBar={handleShowProgressBar}
                                updateActionResult={updateActionResult}
                                requestApi={waybillResponsiblePersonGive}
                                btnText={"roaming.waybill.send.additional_information.giver_fio"}
                            />

                        </Fragment>
                    }

                    {
                        allowResponsiblePersonReturn && <WaybillV2ResponsiblePersonReturn
                            waybills={selectedWaybills}
                            handleShowProgressBar={handleShowProgressBar}
                            updateActionResult={updateActionResult}
                            requestApi={waybillResponsiblePersonReturn}
                            btnText={"roaming.waybill.v2.title.responsible.person.returned"}
                            variant={'falcon-danger'}
                            mTitle={"roaming.waybill.v2.responsible.person.till.return"}
                        />
                    }

                    {allowReturnAccepted && <WaybillV2ReturnAccepted
                        size={"sm"}
                        waybills={selectedWaybills}
                        requestApi={waybillReturnAcceptAsync}
                        handleShowProgressBar={handleShowProgressBar}
                        updateActionResult={updateActionResult}
                    />}

                    {allowIsConsignor && <WaybillV2ConsignorCancel
                        size={"sm"}
                        waybills={selectedWaybills}
                        requestApi={waybillCancelAsync}
                        handleShowProgressBar={handleShowProgressBar}
                        updateActionResult={updateActionResult}
                        btnText={'roaming.free_form_document.view.action.cancel'}
                        variant={"falcon-default"}
                        cancel={true}
                    />}

                    {allowIsConsignee && <Fragment>
                        <WaybillV2CustomerAccept
                            size={"sm"}
                            requestApi={waybillAcceptAsync}
                            waybills={selectedWaybills}
                            handleShowProgressBar={handleShowProgressBar}
                            updateActionResult={updateActionResult}
                            btnText={"roaming.waybill.v2.title.consignee.accepted"}
                        />
                        <WaybillV2CustomerDecline
                            size={"sm"}
                            requestApi={waybillDeclineAsync}
                            waybills={selectedWaybills}
                            handleShowProgressBar={handleShowProgressBar}
                            updateActionResult={updateActionResult}
                            btnText={"roaming.waybill.v2.title.consignee.rejected"}
                            variant={"falcon-danger"}
                            cancel={true}
                        />
                    </Fragment>}

                    {allowExecutorBulkDelete && <WaybillV2DraftDelete
                        hasTitle
                        size={"sm"}
                        waybills={selectedWaybills}
                        handleShowProgressBar={handleShowProgressBar}
                        updateActionResult={updateActionResult}
                        cancel={true}
                    />}

                    {allowExecutorBulkSign && <WaybillV2DraftSend
                        waybills={selectedWaybills}
                        handleShowProgressBar={handleShowProgressBar}
                        updateActionResult={updateActionResult}
                        size="sm"
                    />}

                    {allowDraftSendAll &&
                        <WaybillV2DraftSendAll
                            handleShowProgressBar={handleShowProgressBar}
                            updateActionResult={updateActionResult}
                            filters={filters}
                            predefinedFilters={predefinedFilters}
                            setCount={setCustomerProgressBarCount}
                            size="sm"
                        />
                    }

                    {allowCreateInvoice &&
                        <IconButton size="sm"
                                    variant="phoenix-secondary"
                                    icon={faPlus}
                                    className={'btn-primary-davr'}
                                    onClick={() => onCreateInvoice(selectedWaybills)}
                        >
                            {t(lang, 'edi.common.new_invoice_button')}
                        </IconButton>
                    }

                    <IconButton
                        onClick={toggleCollapse}
                        className="btn-davr ps-2 pe-1 py-0 bg-white text-dark gap-2"
                    >
                        <div className={'d-flex align-items-center'}>
                            <IconFilter />
                            <span className="d-none d-sm-inline-block ms-1 fs-8 text-nowrap fs-md-9">{t(lang, "roaming.invoices.table_header.filter")}</span>
                        </div>
                        <div className={'d-flex justify-content-between gap-2'}>
                            <IconStroke />
                            <IconCaretDown />
                        </div>
                    </IconButton>

                    <IconButton
                        as={Link}
                        size="sm"
                        transform="shrink-3"
                        className="btn-davr px-3 py-0 bg-primary-davr"
                        to="/roaming/waybill-v2/send"
                    >
                        <IconPlus />
                        <span className="d-none d-sm-inline-block ms-1 fs-8 text-white text-nowrap fs-md-9">{t(lang, "roaming.create.waybill.v2")}</span>
                    </IconButton>

                </Col>

                <Col sm={12}>
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                            <WaybillV2DatatableFilter isCollapsed={isCollapsed} viewer={viewer} />
                        </Form>
                    </FormProvider>
                </Col>
            </Row>
        </Fragment>
    );
};

export default WaybillV2DataTableHeader;
