import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import NotificationEventsTable from "./NotificationEventsTable";
import {EdiDefaultNotification} from "../../../enum/notification/EdiDefaultNotification";
import {Accordion, Card, Tab, Tabs} from "react-bootstrap";
const Event = 1

const NotificationEvents = () => {
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Fragment>
            <Card className={'p-0 mt-3'}>
                <NotificationEventsTable notification={EdiDefaultNotification}/>
            </Card>
        </Fragment>
    );
};

export default NotificationEvents;
