import ReasonType from "./ReasonType";

export const SupplierAcceptorInvalid = ReasonType(1, 'edi.shipment.decline_reason_type.supplier_acceptor_invalid');
export const ShipmentDocumentInvalid = ReasonType(2, 'edi.shipment.decline_reason_type.shipment_document_invalid');
export const ShipmentDateInvalid = ReasonType(3, 'edi.shipment.decline_reason_type.shipment_date_invalid');
export const HygieneAndTemperatureInvalid = ReasonType(5, 'edi.shipment.decline_reason_type.hygiene_and_temperature_invalid');
export const PackValidationInvalid = ReasonType(6, 'edi.shipment.decline_reason_type.pack_validation_invalid');
export const ProductInOrderInvalid = ReasonType(7, 'edi.shipment.decline_reason_type.product_in_order_invalid');
export const ProductBarcodeInvalid = ReasonType(8, 'edi.shipment.decline_reason_type.product_barcode_invalid');
export const QuantityConflict = ReasonType(9, 'edi.shipment.decline_reason_type.quantity_conflict');

export const ShipmentDeclineReasonStatuses =  [
    SupplierAcceptorInvalid,
    ShipmentDocumentInvalid,
    ShipmentDateInvalid,
    HygieneAndTemperatureInvalid,
    PackValidationInvalid,
    ProductInOrderInvalid,
    ProductBarcodeInvalid,
    QuantityConflict
]

export const getShipmentDeclineReasonText = reason => {
    switch (reason) {
        case SupplierAcceptorInvalid.id:
            return SupplierAcceptorInvalid.name;
        case ShipmentDocumentInvalid.id:
            return ShipmentDocumentInvalid.name;
        case ShipmentDateInvalid.id:
            return ShipmentDateInvalid.name;
        case HygieneAndTemperatureInvalid.id:
            return HygieneAndTemperatureInvalid.name;
        case PackValidationInvalid.id:
            return PackValidationInvalid.name;
        case ProductInOrderInvalid.id:
            return ProductInOrderInvalid.name;
        case ProductBarcodeInvalid.id:
            return ProductBarcodeInvalid.name;
        case QuantityConflict.id:
            return QuantityConflict.name;
        default:
            return ''
    }
}
