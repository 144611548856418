import axios from "axios";

class WarehouseService {

    getAllWarehouses({params}) {
        return axios.get(`/warehouse/warehouse/get`, params)
    }

    getOneWarehouse({id}) {
        return axios.get(`/warehouse/warehouse/get/${id}`)
    }

    registerWarehouse({name}) {
        return axios.post(`/warehouse/warehouse/register`, name)
    }

    updateOneWarehouse({id, name}) {
        return axios.post(`/warehouse/warehouse/update/${id}`, name)
    }
}

const warehouseService = new WarehouseService()


export default warehouseService;
