import React, {useEffect, useState} from 'react';
import CustomFloatingLabelSelect from "../../floating-custom-select/CustomFloatingLabelSelect";

const SelectClientEmployer = ({options = [], onChange, defaultId, executorIsCustomer, placeholder, ...props}) => {

    const [value, setValue] = useState(null)

    useEffect(() => {
        setValue(options.find(i => i.inn === defaultId || i.person_num === defaultId) || null)
    }, [options, defaultId]);

    const handleChange = (option) => {
        onChange(option)
        setValue(option)
    }

    const formatOptionLabel = (option) => {
        return (
            <div className="fs--1">
                <span className="text-black fw-bold me-1">{option?.inn}</span>-
                {option?.person_num &&
                    <>
                        <span className="text-black fw-bold mx-1">{option?.person_num}</span>-
                    </>
                }
                <span className="ms-1 text-facebook">{option?.short_name || option?.name}</span>
            </div>
        )
    };

    return (
        <CustomFloatingLabelSelect
            label={placeholder}
            hideSelectedOptions
            value={value}
            options={executorIsCustomer ? options.filter(i => i.type === 'executor') : options}
            onChange={handleChange}
            formatOptionLabel={formatOptionLabel}
            getOptionValue={option => option?.person_num || option?.inn}
            getOptionLabel={option => `${option?.person_num} ${option?.inn} / ${option?.short_name || option?.name}`}
            {...props}
        />
    );
};

export default SelectClientEmployer;
