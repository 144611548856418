import React, {useRef} from "react";
import {Col, FloatingLabel, Form, Row} from "react-bootstrap";
import {useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";

const RegistrationFormStepTwo = ({hasLabel, invalidConfirmCode}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const {register, setFocus, formState: {errors}} = useFormContext();

    return <Form.Group as={Row} className="mb-3">
        <Col sm={2}>
            <Form.Control
                type="text"
                maxLength={1}
                className={`${!!errors.account?.confirmCode1 ? "border border-danger" : ""}`}
                {...register('account.confirmCode1', {
                    required: t(lang, "edi.common.forms.validations.is_required"),
                })}
                onChange={(e) => {
                    if (e.target.value) setFocus('account.confirmCode2')
                }}
            />
        </Col>
        <Col sm={2}>
            <Form.Control
                type="text"
                maxLength={1}
                className={`${!!errors.account?.confirmCode2 ? "border border-danger" : ""}`}
                {...register('account.confirmCode2', {
                    required: t(lang, "edi.common.forms.validations.is_required"),
                })}
                onChange={(e) => {
                    if (e.target.value) setFocus('account.confirmCode3')
                }}
            />
        </Col>
        <Col sm={2}>
            <Form.Control
                type="text"
                maxLength={1}
                className={`${!!errors.account?.confirmCode3 ? "border border-danger" : ""}`}
                {...register('account.confirmCode3', {
                    required: t(lang, "edi.common.forms.validations.is_required"),
                })}
                onChange={(e) => {
                    if (e.target.value) setFocus('account.confirmCode4')
                }}
            />
        </Col>
        <Col sm={2}>
            <Form.Control
                type="text"
                maxLength={1}
                className={`${!!errors.account?.confirmCode4 ? "border border-danger" : ""}`}
                {...register('account.confirmCode4', {
                    required: t(lang, "edi.common.forms.validations.is_required"),
                })}
                onChange={(e) => {
                    if (e.target.value) setFocus('account.confirmCode5')
                }}
            />
        </Col>
        <Col sm={2}>
            <Form.Control
                type="text"
                maxLength={1}
                className={`${!!errors.account?.confirmCode5 ? "border border-danger" : ""}`}
                style={{textAlign: "right"}}
                {...register('account.confirmCode5', {
                    required: t(lang, "edi.common.forms.validations.is_required"),
                })}
                onChange={(e) => {
                    if (e.target.value) setFocus('account.confirmCode6')
                }}
            />
        </Col>
        <Col sm={2}>
            <Form.Control
                type="text"
                maxLength={1}
                className={`${!!errors.account?.confirmCode6 ? "border border-danger" : ""}`}
                {...register('account.confirmCode6', {
                    required: t(lang, "edi.common.forms.validations.is_required"),
                })}
            />
        </Col>

        <Form.Control.Feedback type="invalid">
            {errors.account?.confirmCode?.message}
        </Form.Control.Feedback>
    </Form.Group>
};

export default RegistrationFormStepTwo;
