import React, {Fragment} from 'react';
import {Table} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectDateDayJSFormat, selectLang, selectNumberFormat} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import numeral from "numeral";
import dayjs from "dayjs";
import ConvertQuantityToWord from "../../../../filter/ConvertQuantityToWord";

const VerificationActViewTable = ({verificationAct}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const numberFormat = useSelector(selectNumberFormat);
    const dateFormat = useSelector(selectDateDayJSFormat);

    return (
        <Fragment>
            <div className={'border border-1 rounded-3 overflow-hidden'}>
                <Table bordered responsive className="border-bottom fs-8 border-500 mb-0">
                    <tbody>
                    <tr className="bg-card">
                        <td colSpan="4"
                            className="fw-semi-bold text-center">{t(lang, "roaming.verification_act.view.table.according_to", {company: verificationAct?.executor?.name})}</td>
                        <td colSpan="4"
                            className="fw-semi-bold text-center">{t(lang, "roaming.verification_act.view.table.according_to", {company: verificationAct?.customer?.name})}</td>
                    </tr>
                    <tr className="bg-card">
                        <td className="fw-semi-bold">{t(lang, "roaming.verification_act.view.table.document_date")}</td>
                        <td className="fw-semi-bold">{t(lang, "roaming.verification_act.view.table.document_number")}</td>
                        <td className="fw-semi-bold">{t(lang, "roaming.verification_act.view.table.debit")}</td>
                        <td className="fw-semi-bold">{t(lang, "roaming.verification_act.view.table.credit")}</td>
                        <td className="fw-semi-bold">{t(lang, "roaming.verification_act.view.table.document_date")}</td>
                        <td className="fw-semi-bold">{t(lang, "roaming.verification_act.view.table.document_number")}</td>
                        <td className="fw-semi-bold">{t(lang, "roaming.verification_act.view.table.debit")}</td>
                        <td className="fw-semi-bold">{t(lang, "roaming.verification_act.view.table.credit")}</td>
                    </tr>
                    <tr>
                        <td colSpan="2" className="fw-semi-bold text-center py-2 ps-3 pe-0">
                            <div className={'bg-card py-3 rounded-0'}>
                                {t(lang, "roaming.verification_act.view.table.open_balance")}
                            </div>
                        </td>
                        <td>{numeral(verificationAct?.open_balance?.executor_debit || 0).format(numberFormat)}</td>
                        <td>{numeral(verificationAct?.open_balance?.executor_credit || 0).format(numberFormat)}</td>
                        <td colSpan="2" className="fw-semi-bold text-center py-2 pe-3 ps-0">
                            <div className={'bg-card py-3 rounded-0'}>
                                {t(lang, "roaming.verification_act.view.table.open_balance")}
                            </div>
                        </td>
                        <td>{numeral(verificationAct?.open_balance?.customer_debit || 0).format(numberFormat)}</td>
                        <td>{numeral(verificationAct?.open_balance?.customer_credit || 0).format(numberFormat)}</td>
                    </tr>
                    {verificationAct?.contracts?.map((contract, index) => {
                        return [
                            <tr key={`hr-${index}`}>
                                <td colSpan="8"/>
                            </tr>,
                            <tr key={`company-${index}`}>
                                <td className="fw-semi-bold">{dayjs(contract?.contract_date, 'YYYY-MM-DD').format(dateFormat)}</td>
                                <td className="fw-semi-bold">{contract?.contract_no}</td>
                                <td colSpan="6"></td>
                            </tr>,
                            <tr key={`open-balance-${index}`}>
                                <td colSpan="2" className="fw-semi-bold text-center py-2 ps-3 pe-0">
                                    <div className={'bg-card py-3 rounded-0'}>
                                        {t(lang, "roaming.verification_act.view.table.open_balance_by_contract")}
                                    </div>
                                </td>
                                <td>{numeral(contract?.open_balance?.executor_debit || 0).format(numberFormat)}</td>
                                <td>{numeral(contract?.open_balance?.executor_credit || 0).format(numberFormat)}</td>
                                <td colSpan="2" className="fw-semi-bold text-center py-2 pe-3 ps-0">
                                    <div className={'bg-card py-3 rounded-0'}>
                                        {t(lang, "roaming.verification_act.view.table.open_balance_by_contract")}
                                    </div>
                                </td>
                                <td>{numeral(contract?.open_balance?.customer_debit || 0).format(numberFormat)}</td>
                                <td>{numeral(contract?.open_balance?.customer_credit || 0).format(numberFormat)}</td>
                            </tr>,
                            contract?.items?.map((item, index) => (
                                <tr key={index}>
                                    <td>{dayjs(item?.executor_operation_date, 'YYYY-MM-DD').format(dateFormat)}</td>
                                    <td>{item?.executor_operation_name}</td>
                                    <td>{numeral(item?.executor_debit || 0).format(numberFormat)}</td>
                                    <td>{numeral(item?.executor_credit || 0).format(numberFormat)}</td>
                                    <td>{dayjs(item?.customer_operation_date, 'YYYY-MM-DD').format(dateFormat)}</td>
                                    <td>{item?.customer_operation_name}</td>
                                    <td>{numeral(item?.customer_debit || 0).format(numberFormat)}</td>
                                    <td>{numeral(item?.customer_credit || 0).format(numberFormat)}</td>
                                </tr>
                            )),
                            <tr key={`total-balance-${index}`}>
                                <td colSpan="2" className="fw-semi-bold text-center py-2 ps-3 pe-0">
                                    <div className={'bg-card py-3 rounded-0'}>
                                        {t(lang, "roaming.verification_act.view.table.close_balance_by_contract")}
                                    </div>
                                </td>
                                <td className="bold">{numeral(contract?.total_balance?.executor_debit || 0).format(numberFormat)}</td>
                                <td className="bold">{numeral(contract?.total_balance?.executor_credit || 0).format(numberFormat)}</td>
                                <td colSpan="2" className="fw-semi-bold text-center py-2 pe-3 ps-0">
                                    <div className={'bg-card py-3 rounded-0'}>
                                        {t(lang, "roaming.verification_act.view.table.close_balance_by_contract")}
                                    </div>
                                </td>
                                <td className="bold">{numeral(contract?.total_balance?.customer_debit || 0).format(numberFormat)}</td>
                                <td className="bold">{numeral(contract?.total_balance?.customer_credit || 0).format(numberFormat)}</td>
                            </tr>,
                            <tr key={`close-balance-${index}`}>
                                <td colSpan="2" className="fw-semi-bold text-center py-2 ps-3 pe-0">
                                    <div className={'bg-card py-3 rounded-0'}>
                                        {t(lang, "roaming.verification_act.view.table.total_balance_by_contract")}
                                    </div>
                                </td>
                                <td className="bold">{numeral(contract?.close_balance?.executor_debit || 0).format(numberFormat)}</td>
                                <td className="bold">{numeral(contract?.close_balance?.executor_credit || 0).format(numberFormat)}</td>
                                <td colSpan="2" className="fw-semi-bold text-center py-2 pe-3 ps-0">
                                    <div className={'bg-card py-3 rounded-0'}>
                                        {t(lang, "roaming.verification_act.view.table.total_balance_by_contract")}
                                    </div>
                                </td>
                                <td className="bold">{numeral(contract?.close_balance?.customer_debit || 0).format(numberFormat)}</td>
                                <td className="bold">{numeral(contract?.close_balance?.customer_credit || 0).format(numberFormat)}</td>
                            </tr>
                        ]
                    })}
                    <tr>
                        <td colSpan="8"/>
                    </tr>
                    <tr>
                        <td colSpan="2" className="fw-semi-bold text-center py-2 ps-3 pe-0">
                            <div className={'bg-card py-3 rounded-0'}>
                                {t(lang, "roaming.verification_act.view.table.turnover_balance")}
                            </div>
                        </td>
                        <td className="bold">{numeral(verificationAct?.turnover_balance?.executor_debit || 0).format(numberFormat)}</td>
                        <td className="bold">{numeral(verificationAct?.turnover_balance?.executor_credit || 0).format(numberFormat)}</td>
                        <td colSpan="2" className="fw-semi-bold text-center py-2 pe-3 ps-0">
                            <div className={'bg-card py-3 rounded-0'}>
                                {t(lang, "roaming.verification_act.view.table.turnover_balance")}
                            </div>
                        </td>
                        <td className="bold">{numeral(verificationAct?.turnover_balance?.customer_debit || 0).format(numberFormat)}</td>
                        <td className="bold">{numeral(verificationAct?.turnover_balance?.customer_credit || 0).format(numberFormat)}</td>
                    </tr>
                    <tr>
                        <td colSpan="2" className="fw-semi-bold text-center py-2 ps-3 pe-0">
                            <div className={'bg-card py-3 rounded-0'}>
                                {t(lang, "roaming.verification_act.view.table.close_balance")}
                            </div>
                        </td>
                        <td className="bold">{numeral(verificationAct?.close_balance?.executor_debit || 0).format(numberFormat)}</td>
                        <td className="bold">{numeral(verificationAct?.close_balance?.executor_credit || 0).format(numberFormat)}</td>
                        <td colSpan="2" className="fw-semi-bold text-center py-2 pe-3 ps-0">
                            <div className={'bg-card py-3 rounded-0'}>
                                {t(lang, "roaming.verification_act.view.table.close_balance")}
                            </div>
                        </td>
                        <td className="bold">{numeral(verificationAct?.close_balance?.customer_debit || 0).format(numberFormat)}</td>
                        <td className="bold">{numeral(verificationAct?.close_balance?.customer_credit || 0).format(numberFormat)}</td>
                    </tr>
                    </tbody>
                </Table>
            </div>


                <Table bordered responsive className="border-bottom fs--1 border-500 mt-3 mb-0">
                    <tbody>
                    <tr className={'bg-card'}>
                        <td colSpan="4">{t(lang, "roaming.verification_act.view.according_to", {company: verificationAct?.executor?.name})}</td>
                        <td colSpan="4">{t(lang, "roaming.verification_act.view.according_to", {company: verificationAct?.customer?.name})}</td>
                    </tr>
                    <tr>
                        {
                            (!+verificationAct?.close_balance?.executor_debit && !+verificationAct?.close_balance?.customer_debit) &&
                            <Fragment>
                                <td colSpan="4"
                                    className="fw-semi-bold">{t(lang, "roaming.verification_act.view.conclusion.not_company", {
                                    verification_act_date: verificationAct?.info?.date
                                })}</td>
                                <td colSpan="4"
                                    className="fw-semi-bold">{t(lang, "roaming.verification_act.view.conclusion.not_company", {
                                    verification_act_date: verificationAct?.info?.date
                                })}</td>
                            </Fragment>
                        }
                        {
                            (!!+verificationAct?.close_balance?.executor_debit) &&
                            <Fragment>
                                <td colSpan="4"
                                    className="fw-semi-bold">{t(lang, "roaming.verification_act.view.conclusion", {
                                    verification_act_date: verificationAct?.info?.date,
                                    company: verificationAct?.executor?.name,
                                    close_balance: numeral(verificationAct?.close_balance?.executor_debit || 0).format(numberFormat),
                                    close_balance_in_words: ConvertQuantityToWord.getText(+verificationAct?.close_balance?.executor_debit || 0)
                                })}</td>
                                <td colSpan="4"
                                    className="fw-semi-bold">{t(lang, "roaming.verification_act.view.conclusion", {
                                    verification_act_date: verificationAct?.info?.date,
                                    company: verificationAct?.executor?.name,
                                    close_balance: numeral(verificationAct?.close_balance?.executor_debit || 0).format(numberFormat),
                                    close_balance_in_words: ConvertQuantityToWord.getText(+verificationAct?.close_balance?.executor_debit || 0)
                                })}</td>
                            </Fragment>
                        }
                        {
                            (!!+verificationAct?.close_balance?.customer_debit) &&
                            <Fragment>
                                <td colSpan="4"
                                    className="fw-semi-bold">{t(lang, "roaming.verification_act.view.conclusion", {
                                    verification_act_date: verificationAct?.info?.date,
                                    company: verificationAct?.customer?.name,
                                    close_balance: numeral(verificationAct?.close_balance?.customer_debit || 0).format(numberFormat),
                                    close_balance_in_words: ConvertQuantityToWord.getText(+verificationAct?.close_balance?.customer_debit || 0)
                                })}</td>
                                <td colSpan="4"
                                    className="fw-semi-bold">{t(lang, "roaming.verification_act.view.conclusion", {
                                    verification_act_date: verificationAct?.info?.date,
                                    company: verificationAct?.customer?.name,
                                    close_balance: numeral(verificationAct?.close_balance?.customer_debit || 0).format(numberFormat),
                                    close_balance_in_words: ConvertQuantityToWord.getText(+verificationAct?.close_balance?.customer_debit || 0)
                                })}</td>
                            </Fragment>
                        }
                    </tr>
                    <tr>
                        <td colSpan="4">{t(lang, "roaming.verification_act.view.from", {company: verificationAct?.executor?.name})}</td>
                        <td colSpan="4">{t(lang, "roaming.verification_act.view.from", {company: verificationAct?.customer?.name})}</td>
                    </tr>
                    <tr>
                        <td colSpan="4"
                            className="fw-semi-bold">{t(lang, "roaming.verification_act.view.responsible_person", {responsible_person: verificationAct?.executor?.director_name})}</td>
                        <td colSpan="4"
                            className="fw-semi-bold">{t(lang, "roaming.verification_act.view.responsible_person", {responsible_person: verificationAct?.customer?.director_name})}</td>
                    </tr>
                    </tbody>
                </Table>
        </Fragment>
);
};

export default VerificationActViewTable;
