import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {selectRegion} from "../../../store/reducers/roaming/waybill-v2/romaingWaybillV2Reducer";
import CustomFloatingLabelSelect from "../../floating-custom-select/CustomFloatingLabelSelect";

const SelectRegion = ({onChange, defaultRegionId, isDisabled, placeholder, ...props}) => {

    const lang = useSelector(selectLang);
    const t = Translate;
    const regions = useSelector(selectRegion);
    const [value, setValue] = useState(null)

    useEffect(() => {
        setValue(regions.find(i => i.code === defaultRegionId) || null)
    }, [regions, defaultRegionId]);

    const handleChange = (option) => {
        onChange(option)
        setValue(option)
    }

    return (
        <CustomFloatingLabelSelect
            label={placeholder}
            isDisabled={isDisabled}
            value={value}
            options={regions}
            onChange={(option) => handleChange(option)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.code}
            hideSelectedOptions
            {...props}
        />
    );
};

export default SelectRegion;
