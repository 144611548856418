import React, {Fragment} from 'react';
import toast from "react-hot-toast";
import {
    loadExchangeInfo,
    loadExchangeTypeWithExchangeNumber
} from "../../../store/reducers/roaming/roamingReferenceReducer";
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {Controller, useFormContext} from "react-hook-form";
import {selectLang} from "../../../store/reducers/main/main";
import {Button, Col, InputGroup, Row, Form, FloatingLabel} from "react-bootstrap";
import RoamingInvoiceFormExchangeTypeSelector from "./RoamingInvoiceFormExchangeTypeSelector";
import dayjs from "dayjs";
import IconSearch from "../../../assets/icon/IconSearch";

const RoamingInvoiceFormExchangeInfo = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();

    const onSearchExchange = async () => {
        try {
            const id = form.getValues('exchange.number');
            const buyerInn = form.getValues('buyer.innOrPinfl');

            const exchangeInfo = await loadExchangeTypeWithExchangeNumber({
                inn: buyerInn,
                lot_number: id
            });
            if (exchangeInfo && exchangeInfo.length) {
                const exchange = await loadExchangeInfo({
                    inn: buyerInn,
                    lot_id: `${exchangeInfo[0].prefix}${exchangeInfo[0].lot_id}`
                });
                onFillFromExchange(exchangeInfo, exchange);
            }
        } catch (e) {
            toast.error(t(lang, 'edi.common.toast.error'))
            console.log(e);
        }
    };

    const onFillFromExchange = (exchangeInfo, exchange) => {
        if (exchangeInfo) {
            form.setValue('exchange.type', exchangeInfo[0].prefix);
            form.setValue('exchange.number', exchangeInfo[0].lot_id);
        }

        if (exchange) {
            form.setValue('contractDoc.contractNo', exchange.contract_number || '');
            form.setValue('contractDoc.contractDate', exchange.contract_date ? dayjs(exchange.contract_date).toDate() : null);
            form.setValue('buyer.account', exchange.buyer_account || '');
            form.setValue(`productList.products`, exchange.products.map((product, index) => ({
                catalog: null,
                package: null,
                measurement: null,
                lgota: null,
                origin: null,
                committentName: '',
                committentTin: '',
                committentVatRegCode: '',
                committentVatRegStatus: '',
                barcode: '',
                warehouseId: null,
                mark: {
                    productType: null,
                    kiz: [
                        {code: ''}
                    ],
                    nomUpak: [
                        {code: ''}
                    ],
                    identTransUpak: [
                        {code: ''}
                    ],
                },
                exchangeInfo: {
                    productCode: product.product_code || '',
                    productProperties: product.product_properties || '',
                    planPositionId: product.plan_position_id || '',
                },
                name: product.name || '',
                serial: '',
                baseSumma: '',
                profitRate: '',
                count: product.quantity || '',
                countTon: '',
                countKm: '',
                summa: product.price || '',
                exciseRate: '',
                exciseSum: '',
                deliverySum: '',
                vatRate: '',
                vatSum: '',
                deliverySumWithVat: '',
            })));
        }
    };
    return (
        <Row>
            <Col sm={12} md={6}>
                <Form.Group className="mb-2 d-flex gap-2">
                        <FloatingLabel className={'w-100'} label={t(lang, 'roaming.invoice.exchange.number')}>
                            <Form.Control
                                name={`exchange.number`}
                                placeholder={t(lang, 'roaming.invoice.exchange.number.placeholder')}
                                {...form.register(`exchange.number`, {
                                    shouldUnregister: true
                                })}
                            />
                        </FloatingLabel>
                        <Button className={'bg-primary-davr'} onClick={onSearchExchange}>
                            <IconSearch />
                        </Button>
                </Form.Group>
            </Col>
            <Col sm={12} md={6}>
                <Form.Group className="mb-2">
                    <Controller name={`exchange.type`}
                                shouldUnregister={true}
                                render={({field}) => (
                                    <RoamingInvoiceFormExchangeTypeSelector defaultExchangeType={field.value}
                                                                            onChange={field.onChange}
                                    />
                                )}
                    />
                </Form.Group>
            </Col>
        </Row>
    );
};

export default RoamingInvoiceFormExchangeInfo;
