import React, {Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectDateDayJSFormat, selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {
    changeWaybillsFilterOptions,
    selectWaybills, selectWaybillsCount,
    selectWaybillsFilterOptions,
    selectWaybillsLoading, setId, setShowModal, viewPage
} from "../../../store/reducers/roaming/waybill/roamingWaybillReducer";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {
    roamingHideModal,
    setRoamingCommonHideModal
} from "../../../store/reducers/roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import {VIEW_DRAFT} from "../../../enum/roaming/TypeViewers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
   faEye,
} from "@fortawesome/free-solid-svg-icons";
import Badge from "../../common/Badge";
import dayjs from "dayjs";
import {getWaybillStatusText, getWaybillStatusVariant} from "../../../enum/roaming/waybill/WaybillStatus";
import numeral from "numeral";
import TypeViewerAction from "../../common/TypeViewerAction";
import AdvanceTableWrapper from "../../table/AdvanceTableWrapper";
import WaybillDataTableHeader from "./WaybillDataTableHeader";
import AdvanceTable from "../../table/AdvanceTable";
import AdvanceTableCustomPagination from "../../table/AdvanceTableCustomPagination";
import ViewModalPage from "../../common/ViewModalPage";
import ViewWaybill from "../../../pages/roaming/waybill/ViewWaybill";
import ViewDraftWaybill from "../../../pages/roaming/waybill/ViewDraftWaybill";
import {Card, Dropdown} from "react-bootstrap";
import IconFolderDownload from "../../../assets/icon/IconFolderDownload";
import RevealDropdown, {RevealDropdownTrigger} from "../../common/RevealDropdown";
import {useNavigate} from "react-router-dom";
import {checkViewer} from "../../../enum/roaming/checkViewer";

const WaybillDataTable = ({viewer}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const t = Translate;
    const lang = useSelector(selectLang);
    const waybills = useSelector(selectWaybills);
    const count = useSelector(selectWaybillsCount);
    const loading = useSelector(selectWaybillsLoading);
    const numberFormat = useSelector(selectNumberFormat);
    const filter = useSelector(selectWaybillsFilterOptions);
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat);
    const {id, draftId, showModal} = useSelector(viewPage)
    const hideModal = useSelector(roamingHideModal)

    const viewer_inn_or_pinfl = activeOrganization.pinfl || activeOrganization.inn;

    const openViewModalPage = (e,id) => {

        if (viewer === VIEW_DRAFT) {
            navigate('/roaming/waybill/draft/'+id)
        } else {
            navigate('/roaming/waybill/'+id)
        }
    }

    const onHideModalPage = () => {
        dispatch(setShowModal(false))
    }

    // to close the modal on page change
    useEffect(() => {
        if(hideModal) {
            onHideModalPage()
            dispatch(setRoamingCommonHideModal(null))
        }
    }, [])

    const columns = [
        {
            accessor: 'created_at',
            Header: t(lang, 'roaming.waybill.datatable.row.created_at'),
            Cell: rowData => {
                const {original} = rowData.row
                return dayjs(original.created_at, "YYYY-MM-DD").format(currentDateDayJSFormat)
            }
        },
        {
            accessor: 'contractor',
            Header: t(lang, 'roaming.waybill.datatable.row.contractor'),
            cellProps: {
                className: 'pe-4'
            },
            headerProps: {width: 300},
            Cell: ({row: {original}}) => {
                return viewer_inn_or_pinfl === original.customer.inn
                    ?
                    <div className={'d-flex align-items-center gap-2'}>
                        {checkViewer(viewer)}
                        <div>
                             <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, "roaming.verification_act.datatable.body.row.verification_act", {
                                 name: original?.info?.number,
                                 date: dayjs(original?.info?.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                             })}
                             </span>
                            <span className={'fs-8 main-text fw-bold text-truncate'}>{original.carrier.name}</span>
                            <i className={'d-block fs-9 second-text'}>{original.carrier.inn || original.carrier.pinfl}</i>
                        </div>
                    </div>
                    : <div className={'d-flex align-items-center gap-2'}>
                        {checkViewer(viewer)}
                        <div>
                             <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, "roaming.verification_act.datatable.body.row.verification_act", {
                                 name: original?.info?.number,
                                 date: dayjs(original?.info?.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                             })}
                             </span>
                            <span className={'fs-8 main-text fw-bold text-truncate'}>{original.customer.name}</span>
                            <i className={'d-block fs-9 second-text'}>{original.customer.inn || original.customer.pinfl}</i>
                        </div>
                    </div>
            }
        },
        {
            accessor: 'contract',
            Header: t(lang, 'roaming.waybill.datatable.row.contract'),
            Cell: ({row: {original}}) => {
                return (
                    <span className={'second-text fw-light'}>
                        {t(lang, 'roaming.waybill.datatable.row.contract_number_from_contract_date', {
                            contract_number: original.contract_info.number,
                            contract_date: dayjs(original.contract_info.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                        })}
                    </span>
                )
            }
        },

        {
            accessor: 'total',
            Header: t(lang, 'roaming.waybill.datatable.row.total'),
            cellProps: {
                className: 'text-end pe-5'
            },
            Cell: ({row: {original}}) => {
                const total = original.total ? original?.total : original.items.reduce((acc, item) => acc + item.total, 0);

                return (
                    <Fragment>
                        <strong>{numeral.formats[numberFormat].format(total)}</strong>
                        <span className={'ms-1 second-text fs-9'}>UZS</span>
                    </Fragment>
                )
            }
        },
        ...(viewer !== VIEW_DRAFT ? [
            {
                accessor: 'status',
                Header: t(lang, 'roaming.waybill.datatable.row.status'),

                Cell: ({row: {original}}) => {
                    return (
                        <div className={'d-inline-block text-center'}>
                            <Badge
                                variant={"phoenix"}
                                bg={getWaybillStatusVariant(original, viewer_inn_or_pinfl)}
                                className={'px-3 py-1 fs-9 text-uppercase w-100 text-decoration-none fw-medium border-0 rounded-4'}
                            >
                                {t(lang, getWaybillStatusText(original, viewer_inn_or_pinfl))}
                            </Badge>
                        </div>
                    )
                }
            }
        ] : []),
        {
            accessor: 'action',
            Header: "",
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                        <RevealDropdown>
                            <Dropdown.Item
                                onClick={(event) => {
                                    openViewModalPage(event, original.id)
                                }}>
                                <span>{t(lang, "roaming.invoices.table_header.show")}</span>
                            </Dropdown.Item>
                        </RevealDropdown>
                    </RevealDropdownTrigger>
                );
            }
        },
    ]

    const onLimitChange = limit => {
        dispatch(changeWaybillsFilterOptions({...filter, limit: limit, page: 1}))
    }

    const onPageChange = page => {
        dispatch(changeWaybillsFilterOptions({...filter, page: page}))
    }

    return (
        <Fragment>

            <AdvanceTableWrapper
                selection
                pagination
                perPage={1000}
                data={waybills}
                columns={columns}
            >
                <WaybillDataTableHeader />
                <Card  className={'p-0 rounded-4'}>
                    <TypeViewerAction to={'/roaming/waybills'} typeViewer={viewer} />
                    <AdvanceTable
                        table
                        rowClassName="align-middle white-space-nowrap"
                        headerClassName="text-900 text-nowrap align-middle"
                        tableProps={{
                            loading: loading,
                            size: 'sm',
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card>

                <AdvanceTableCustomPagination
                    count={count}
                    limit={filter.limit}
                    page={filter.page}
                    onLimitChange={onLimitChange}
                    onPageChange={onPageChange}
                />
            </AdvanceTableWrapper>

            <ViewModalPage show={showModal} onHide={onHideModalPage}>
                {id && <ViewWaybill modalId={id} />}
                {draftId && <ViewDraftWaybill modalId={draftId} />}
            </ViewModalPage>

        </Fragment>
    );
};

export default WaybillDataTable;
