import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {selectCompanyCatalogs} from "../../../store/reducers/roaming/roamingReducer";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import {loadContract, loadDraftContract} from "../../../store/reducers/roaming/contracts/roamingContractReducer";
import ContractForm from "../../../components/roaming/contracts/form/ContractForm";
import SpinnerComponent from "../../../components/common/SpinnerComponent";

const FillContract = () => {
    const { search } = useLocation();
    const activeOrganization = useSelector(selectActiveOrganization)
    const [draftContract, setDraftContract] = useState(null)
    const [contract, setContract] = useState(null)
    const catalogs = useSelector(selectCompanyCatalogs);
    const navigate = useNavigate()
    const {id} = useParams()
    const lang = useSelector(selectLang)
    const t = Translate

    const query = useMemo(() => {
        return new URLSearchParams(search);
    }, [search])

    useEffect( () => {
        loadContracts()
    },[id, activeOrganization])

    const loadContracts = async () => {
        const status = JSON.parse(query.get("status"));
        if (status) {
            loadContract(id)
                .then(loadedContract => {
                    let contract = {
                        owner: {
                            tin: loadedContract.data.executor.inn,
                            name: loadedContract.data.executor.name,
                            address: loadedContract.data.executor.address,
                            workPhone: loadedContract.data.executor.work_phone,
                            mobile: loadedContract.data.executor.mobile,
                            fax: loadedContract.data.executor.fax,
                            oked: loadedContract.data.executor.oked,
                            account: loadedContract.data.executor.account,
                            bankId: loadedContract.data.executor.mfo,
                            fizTin: loadedContract.data.executor.director_inn,
                            fio: loadedContract.data.executor.director_name,
                            branchCode: loadedContract?.data?.executor?.branch?.branch_code,
                            branchName: loadedContract?.data?.executor?.branch?.branch_name
                        },
                        clients: loadedContract.data.contractors.map((client) => ({
                            tin: client.inn,
                            name: client.name,
                            address: client.address,
                            workPhone: client.work_phone,
                            mobile: client.mobile,
                            fax: client.fax,
                            oked: client.oked,
                            account: client.account,
                            bankId: client.mfo,
                            fizTin: client.director_inn,
                            fio: client.director_name,
                            branchCode: client?.branch?.branch_code,
                            branchName: client?.branch?.branch_name
                        })),
                        contractDoc: {
                            contractName: loadedContract.data.info.name,
                            contractNo: loadedContract.data.info.number,
                            contractDate: loadedContract.data.info.date ? dayjs(loadedContract.data.info.date).toDate() : null,
                            contractExpireDate: loadedContract.data.info.expire_date ? dayjs(loadedContract.data.info.expire_date).toDate() : null,
                            contractPlace: loadedContract.data.info.place
                        },
                        products: loadedContract.data.items.map((product) => {
                            const catalog = catalogs?.find(c => c?.class_code === product?.CatalogCode)
                            const packages = catalog?.package_names?.find(p => p?.code === +product?.packageCode)


                            return {
                                ordNo: product.ordering_number,
                                catalogCode: product.catalog.code,
                                catalogName: product.catalog.name,
                                barCode: product.barcode,
                                name: product.name,
                                measureId: `${product.measure_id}`,
                                packageCode: +product?.packageCode,
                                packageName: product?.packageName,
                                catalog: catalog,
                                packages: packages,
                                count: product.quantity,
                                summa: product.price,
                                deliverySum: product.total,
                                vatRate: product.vat_rate,
                                vatSum: product.vat_sum,
                                deliverySumWithVat: product.total_with_nds,
                                withoutVat: product.without_vat
                            }
                        }),
                        parts: loadedContract.data.parts.map((part) => ({
                            ordNo: part.ordering_number,
                            title: part.title,
                            body: part.body
                        }))
                    }
                    setContract(contract)
                })
                .catch(errors => {
                    setContract(null)
                    console.log(errors)
                    toast.error(t(lang,"roaming.common.error_not_found"))
                })
        } else {
            loadDraftContract(id)
                .then(getDraft => {
                    const contractParse = JSON.parse(getDraft?.data?.content)
                    let contract = {
                        owner: {
                            tin: contractParse.Owner.Tin,
                            name: contractParse.Owner.Name,
                            address: contractParse.Owner.Address,
                            workPhone: contractParse.Owner.WorkPhone,
                            mobile: contractParse.Owner.Mobile,
                            fax: contractParse.Owner.Fax,
                            oked: contractParse.Owner.Oked,
                            account: contractParse.Owner.Account,
                            bankId: contractParse.Owner.BankId,
                            fizTin: contractParse.Owner.FizTin,
                            fio: contractParse.Owner.Fio,
                            branchCode: contractParse.Owner.BranchCode,
                            branchName: contractParse.Owner.BranchName
                        },
                        clients: contractParse.Clients.map((client) => ({
                            tin: client.Tin,
                            name: client.Name,
                            address: client.Address,
                            workPhone: client.WorkPhone,
                            mobile: client.Mobile,
                            fax: client.Fax,
                            oked: client.Oked,
                            account: client.Account,
                            bankId: client.BankId,
                            fizTin: client.FizTin,
                            fio: client.Fio,
                            branchCode: client.BranchCode,
                            branchName: client.BranchName
                        })),
                        contractDoc: {
                            contractName: contractParse.ContractDoc.ContractName,
                            contractNo: contractParse.ContractDoc.ContractNo,
                            contractDate: new Date(contractParse?.ContractDoc?.ContractDate).getTime() ? dayjs(contractParse?.ContractDoc?.ContractDate).toDate() : null,
                            contractExpireDate: new Date(contractParse?.ContractDoc?.ContractExpireDate).getTime() ? dayjs(contractParse?.ContractDoc?.ContractExpireDate).toDate() : null,
                            contractPlace: contractParse.ContractDoc.ContractPlace
                        },
                        products: contractParse.Products.map((product) => {
                            const catalog = catalogs?.find(c => c?.class_code === product?.CatalogCode)
                            const packages = catalog?.package_names?.find(p => p?.code === +product?.packageCode)

                            return {
                                ordNo: product.OrdNo,
                                catalogCode: product.CatalogCode,
                                catalogName: product.CatalogName,
                                barCode: product.BarCode,
                                name: product.Name,
                                measureId: `${product.MeasureId}`,
                                catalog: catalog,
                                packages: packages,
                                packageCode: +product?.packageCode,
                                packageName: product?.packageName,
                                count: product.Count,
                                summa: product.Summa,
                                deliverySum: product.DeliverySum,
                                vatRate: product.VatRate,
                                vatSum: product.VatSum,
                                deliverySumWithVat: product.DeliverySumWithVat,
                                withoutVat: product.WithoutVat
                            }
                        }),
                        parts: contractParse.Parts.map((part) => ({
                            ordNo: part.OrdNo,
                            title: part.Title,
                            body: part.Body
                        }))
                    }
                    setDraftContract(contract)
                })
                .catch(errors => {
                    setDraftContract(null)
                    console.log(errors)
                    toast.error(t(lang,"roaming.common.error_not_found"))
                })
        }
    };

    if (!contract && !draftContract){
        return <SpinnerComponent />
    } else {
        return <ContractForm contract={contract || draftContract}/>
    }
};

export default FillContract;
