import React, {Fragment, useState} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {loadBankInfo} from "../../../../store/reducers/roaming/roamingReducer";
import {Col, Row, Form, FloatingLabel, Card} from "react-bootstrap";
import FindCompanySearchable from "../../../organization/FindCompanySearchable";
import FindPerson from "../../../edi/attorney/FindPerson";

const OwnerInfo = () => {
    const {register, setValue, formState: {errors}, control} = useFormContext()
    const [companyDetails, setCompanyDetails] = useState({})
    const [bankInfo, setBankInfo] = useState({})
    const activeOrganization = useSelector(selectActiveOrganization)
    const lang = useSelector(selectLang)
    const t = Translate

    const onChangeHandler = async data => {
        if (data) {
            setCompanyDetails(data)
            setValue('owner.name', data.name)
            setValue('owner.tin', data.person_num || data.inn)
            setValue('owner.fizTin', data.director_pinfl || data.director_inn)
            setValue('owner.fio', data.director)
            setValue('owner.address', data.address)
            setValue('owner.bankId', data.mfo)
            setValue('owner.account', data.account)
            if (data.mfo) {
                setBankInfo(await loadBankInfo(data.mfo, lang))
            }
        }
    }

    const onChangeDirectorHandler = (director) => {
        if (director) {
            setValue('owner.fio', director.name)
            setValue('owner.fizTin', director.pinfl || director.inn)
        } else {
            setValue('owner.fio', '')
            setValue('owner.fizTin', '')
        }
    }

    return (
        <Fragment>
            <h5 className="mb-2 ms-3 fw-medium fs-9 second-text">{t(lang, "roaming.contracts.add.owner_info.title")}</h5>
            <Card className={'p-2 rounded-4 border-0'}>
                <Row className={'flex-column gap-2'}>
                    {companyDetails?.branch &&
                        <Col sm={12} className="mb-3">
                            <FloatingLabel label={t(lang, "roaming.contracts.add.owner_info.branch")}>
                                <Form.Control
                                    placeholder={t(lang, "roaming.contracts.add.owner_info.branch")}
                                />
                            </FloatingLabel>
                        </Col>
                    }

                    <Col sm={12}>
                        <Row>
                            <Col sm="6">
                                <FloatingLabel label={t(lang, "roaming.contracts.add.owner_info.name")}>
                                    <Form.Control
                                        {...register("owner.name", {
                                            // required: "edi.common.forms.validations.is_required"
                                        })}
                                        placeholder={t(lang, "roaming.contracts.add.owner_info.name")}
                                        className={errors?.owner?.name?.message && "is-invalid"}
                                    />
                                </FloatingLabel>
                                <Form.Control.Feedback type="invalid">
                                    {t(lang, errors?.owner?.name?.message)}
                                </Form.Control.Feedback>
                            </Col>
                            <Col sm="6">
                                <Controller
                                    name="owner.tin"
                                    control={control}
                                    // rules={{ required: true }}
                                    render={() => (
                                        <FindCompanySearchable
                                            defaultInn={activeOrganization.pinfl || activeOrganization.inn}
                                            onChange={onChangeHandler}
                                            disabled={true}
                                            placeholder={t(lang, "roaming.contracts.add.owner_info.inn_or_pinfl")}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={12}>
                        <Row>
                            <Col sm="6">
                                <FloatingLabel label={t(lang, "roaming.contracts.add.owner_info.full_name_person")}>
                                    <Form.Control
                                        {...register("owner.fio")}
                                        placeholder={t(lang, "roaming.contracts.add.owner_info.full_name_person")}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col sm="6">
                                <Controller
                                    name="owner.fizTin"
                                    control={control}
                                    render={({field}) => (
                                        <FindPerson
                                            defaultInnOrPinfl={field.value}
                                            onChange={onChangeDirectorHandler}
                                            placeholder={t(lang, "roaming.contracts.add.owner_info.inn_or_pinfl_person")}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={12}>
                        <Row>
                            <Col sm="4">
                                <FloatingLabel label={t(lang, "roaming.contracts.add.owner_info.bank_account")}>
                                    <Form.Control {...register("owner.account")}
                                                  placeholder={t(lang, "roaming.contracts.add.owner_info.bank_account")}/>
                                </FloatingLabel>
                            </Col>
                            <Col sm="5">
                                <FloatingLabel label={t(lang, "roaming.contracts.add.owner_info.bank_address")}>
                                    <Form.Control defaultValue={bankInfo?.name}
                                                  placeholder={t(lang, "roaming.contracts.add.owner_info.bank_address")}/>
                                </FloatingLabel>
                            </Col>
                            <Col sm="3">
                                <FloatingLabel label={t(lang, "roaming.contracts.add.owner_info.mfo")}>
                                    <Form.Control {...register("owner.bankId")}
                                                  placeholder={t(lang, "roaming.contracts.add.owner_info.mfo")}/>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={12}>
                        <FloatingLabel label={t(lang, "roaming.contracts.add.owner_info.address")}>
                            <Form.Control {...register("owner.address")}
                                          placeholder={t(lang, "roaming.contracts.add.owner_info.address")}/>
                        </FloatingLabel>
                    </Col>

                    <Col sm={12}>
                        <Row>
                            <Col sm="6">
                                <FloatingLabel label={t(lang, "roaming.contracts.add.owner_info.work_phone_number")}>
                                    <Form.Control placeholder={t(lang, "roaming.contracts.add.owner_info.work_phone_number")}/>
                                </FloatingLabel>
                            </Col>
                            <Col sm="6">
                                <FloatingLabel label={t(lang, "roaming.contracts.add.owner_info.mobile_number")}>
                                    <Form.Control placeholder={t(lang, "roaming.contracts.add.owner_info.mobile_number")}/>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </Fragment>
    );
};

export default OwnerInfo;
