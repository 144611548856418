import React, {Fragment, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setShowModal} from "../../../store/reducers/roaming/act/actReducer";
import {getOrganizationBillingInfoAsync} from "../../../store/reducers/billing/billingReducer";
import EventBus from "../../../eventbus/EventBus";
import {
    SAVE_DRAFT_WAYBILL_v2_SUCCEED,
    SEND_WAYBILL_V2_SUCCEED
} from "../../../eventbus/romaing/waybill-v2/roamingWaybillV2Events";
import {loadDraftWaybillV2Async} from "../../../store/reducers/roaming/waybill-v2/romaingWaybillV2Reducer";
import SpinnerComponent from "../../../components/common/SpinnerComponent";
import EditWaybillV2Form from "../../../components/roaming/waybill-v2/form/EditWaybillV2Form";

const EditWaybillV2 = () => {

    const navigate = useNavigate();
    const {id} = useParams();
    const [draftWaybill, setDraftWaybill] = useState(null);
    const dispatch = useDispatch()

    useEffect(() => {
        loadDraftWaybill();
    }, [id])

    useEffect(() => {
        const onSendWaybillSucceedHandler = EventBus.on(SEND_WAYBILL_V2_SUCCEED, waybill => {
            navigate(`/roaming/waybill-v2/${waybill.id}`)
            dispatch(getOrganizationBillingInfoAsync());
        });

        const onSaveDraftWaybillSucceedHandler = EventBus.on(SAVE_DRAFT_WAYBILL_v2_SUCCEED, draftWaybill => {
            navigate(`/roaming/waybill-v2/draft/${draftWaybill.id}`)
        })

        return () => {
            EventBus.remove(SEND_WAYBILL_V2_SUCCEED, onSendWaybillSucceedHandler);
            EventBus.remove(SAVE_DRAFT_WAYBILL_v2_SUCCEED, onSaveDraftWaybillSucceedHandler);
        }
    }, [])

    const loadDraftWaybill = () => {
        loadDraftWaybillV2Async(id)
            .then(loadedDraftWaybill => {
                const parsedContent = JSON.parse(loadedDraftWaybill.content);
                setDraftWaybill({...parsedContent, id: loadedDraftWaybill.id});
            })
            .catch(error => console.log(error))
    }

    return (
        <Fragment>
            {!draftWaybill && <SpinnerComponent/>}
            {draftWaybill && <EditWaybillV2Form id={id} waybill={draftWaybill}/>}
        </Fragment>
    );
};

export default EditWaybillV2;
