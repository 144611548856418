import React, {Fragment, useState} from 'react';
import {selectLang} from "../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {FormProvider, useForm} from "react-hook-form";
import {updateNotificationAsync} from "../../../store/reducers/telegram-notification/telegramNotification";
import IconButton from "../../IconButton";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import NotificationForm from "./NotificationForm";
import NotificationEvents from "./NotificationEvents";

const NotificationUpdate = ({notification}) => {
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const lang = useSelector(selectLang)
    const t = Translate

    const handleShow = () => setShow(true)
    const handleClose = () => {
        methods.reset()
        setShow(false)
    }
    const methods = useForm({
        defaultValues: {
            phoneNumber: notification.phone_number,
            eventCodes: notification.events.map(item => {
                return {
                    value: item
                }
            })
        }
    })

    const onSubmit = (formData) => {
        setLoading(true)
        const id = notification.id
        const phoneNumber = formData.phoneNumber
        const eventCodes = formData.eventCodes.map(item => item.value)

        updateNotificationAsync({phoneNumber: phoneNumber, eventCodes: eventCodes}, id)
            .then(() => handleClose())
            .catch(e => new Error(e))
            .finally(() => setLoading(false))
    }

    return (
        <Fragment>
            <span onClick={handleShow}>{t(lang, "roaming.common.edit")}</span>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton className={'bg-card'}>
                    <Modal.Title>{t(lang, "notification.update.events.modal")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={'bg-card'}>
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmit)} id={"notification-update"}>
                            <NotificationForm/>
                            <NotificationEvents/>
                        </Form>
                    </FormProvider>
                </Modal.Body>
                <Modal.Footer className={'bg-card'}>
                    <Button variant="secondary" disabled={loading} className="btn-davr px-3 py-0 bg-primary-davr"
                            type='submit' form={'notification-update'}>
                        {loading && <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />}
                        {t(lang, "notification.update_button")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default NotificationUpdate;
