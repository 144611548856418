import React from 'react';

const IconChevronRightDuo = ({width = 24, height = 24, color = "none"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M13 8L17 12L13 16M7 8L11 12L7 16" stroke="#2E3035" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>

    );
};

export default IconChevronRightDuo;
