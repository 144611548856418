export const StatusInProgress = 1;
export const StatusFinished = 2;

export const GetStatusText = status => {
    switch (status) {
        case StatusInProgress:
            return 'roaming.excel_registry.status.in_progress';
        case StatusFinished:
            return 'roaming.excel_registry.status.finished';
        default:
            return null
    }
}

export const GetStatusColor = status => {
    switch (status) {
        case StatusInProgress:
            return 'warning';
        case StatusFinished:
            return 'success';
        default:
            return null
    }
}

const ExcelRegistryStatus = (id, name) => {
    return {
        id,
        name
    }
}

export const ExcelRegistryStatuses = [
    ExcelRegistryStatus(StatusInProgress, GetStatusText(StatusInProgress)),
    ExcelRegistryStatus(StatusFinished, GetStatusText(StatusFinished)),
];
