import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentCertificate} from "../../../../store/reducers/eimzo/eimzoReducer";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {FormProvider, useForm} from "react-hook-form";
import {
    generateIdAsync,
    getOrganizationInfoAsync,
    loadCompanyProductCatalogsAsync, loadMeasurementsAsync
} from "../../../../store/reducers/roaming/roamingReducer";
import {
    saveDraftWaybillAsync,
    sendWaybillAsync
} from "../../../../store/reducers/roaming/waybill/roamingWaybillReducer";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import {Form} from "react-bootstrap";
import WaybillForm from "../form/WaybillForm";
import ErrorAlert from "../../../common/ErrorAlert";

const SendWaybillForm = () => {
    const [isSaving, setIsSaving] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const activeOrganization = useSelector(selectActiveOrganization);
    const alertRef = useRef(null);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            shipment: {number: "", date: null},
            contract: {number: "", date: null},
            waybill: {number: "", date: null},

            truck: {
                register_number: "",
                model: "",
            },
            trailer: {
                type: 1,
                register_number: "",
                model: "",
            },
            delivery_type: 1,
            driver: "",

            customer: {
                inn: "",
                name: "",
                branch: {
                    code: "",
                    name: ""
                },
                seller: {
                    inn: "",
                    name: ""
                },
                first_point_of_loading: "",
                second_point_of_loading: "",
            },

            carrier: {
                inn: "",
                name: "",
                branch: {
                    code: "",
                    name: ""
                },
                buyer: {
                    inn: "",
                    name: ""
                },
                first_point_of_unloading: "",
                second_point_of_unloading: "",
            },

            special_notes: "",
            point_of_redirect_name: "",
            point_of_redirect_address: "",
            giver_fio: "",
            giver_driver_fio: "",
            taker_fio: "",
            taker_driver_fio: "",
            delivery_distance: {
                total: "",
                in_city: ""
            },

            products: [
                {
                    product: '',
                    catalog_code: '',
                    catalog_name: '',
                    price: '',
                    measureId: '',
                    packageCode: null,
                    packageName: null,
                    packages: null,
                    quantity: '',
                    total: '',
                    road_haulage_cost: '',
                    follow_documents: '',
                    weight_define_method: '',
                    load_class: '',
                    weight_brutto: '',
                    weight_netto: '',
                }
            ]
        }
    });

    useEffect(() => {
        getOrganizationInfoAsync(activeOrganization.inn)
            .then(company => {
                methods.setValue('carrier.name', company?.name || '');
                methods.setValue('carrier.inn', company?.person_num || company?.inn);
            })
            .catch(error => {
                methods.setValue('carrier.name', '');
                methods.setValue('carrier.inn', '');
                console.log(error);
            })
    }, [activeOrganization]);

    useEffect(() => {
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization?.inn, lang));
        dispatch(loadMeasurementsAsync(lang));

        return () => {
            setIsSaving(false);
            setIsSending(false);
        }
    }, []);

    const sendWaybill = ({id, hashCode}) => {
        setIsSending(true);

        sendWaybillAsync(currentCertificate, lang, {id, hashCode})
            .then(() => toast.success(t(lang, 'roaming.waybill.send.toast.register_success')))
            .catch(error => {
                setIsSending(false);
                setErrorMessage(error.message || null);
                alertRef.current.scrollIntoView();
            })
    }

    const saveDraftWaybill = ({id, content}) => {
        setIsSaving(true);

        saveDraftWaybillAsync({id, content})
            .then(() => toast.success(t(lang, 'roaming.waybill.save.toast.saved_success')))
            .catch(error => {
                setIsSaving(false);
                setErrorMessage(error.message || null);
                alertRef.current.scrollIntoView();
            })
    }

    const onSubmit = async (formData, event) => {
        const actionType = event.nativeEvent.submitter.name;
        const [waybillId, waybillProductId] = await generateIdAsync(2);

        const data = JSON.stringify({
            WaybillId: waybillId,
            WaybillDoc: {
                WaybillNo: formData.shipment.number,
                WaybillDate: dayjs(formData.shipment.date).format('YYYY-MM-DD')
            },
            ContractDoc: {
                ContractNo: formData.contract.number,
                ContractDate: dayjs(formData.contract.date).format('YYYY-MM-DD')
            },
            TripTicketDoc: {
                TripTicketNo: formData.waybill.number,
                TripTicketDate: dayjs(formData.waybill.date).format('YYYY-MM-DD')
            },
            DriverFio: formData.driver,
            DeliveryType: formData.delivery_type,
            TrailerDoc: {
                TrailerType: formData.trailer.type,
                TrailerRegNo: formData.trailer.register_number,
                TrailerModel: formData.trailer.model
            },
            TruckDoc: {
                TruckRegNo: formData.truck.register_number,
                TruckModel: formData.truck.model
            },
            CustomerTin: formData.customer.inn,
            CustomerName: formData.customer.name,
            CustomerBranchCode: formData.customer.branch.code,
            CustomerBranchName: formData.customer.branch.name,
            SellerTin: formData.customer.seller.inn,
            SellerName: formData.customer.seller.name,

            CarrierTin: formData.carrier.inn,
            CarrierName: formData.carrier.name,
            CarrierBranchCode: formData.carrier.branch.code,
            CarrierBranchName: formData.carrier.branch.name,
            BuyerTin: formData.carrier.buyer.inn,
            BuyerName: formData.carrier.buyer.name,

            PointDocs: [
                {
                    PointOfLoading: formData.customer.first_point_of_loading,
                    PointOfUnloading: formData.carrier.first_point_of_unloading
                },
                {
                    PointOfLoading: formData.customer.second_point_of_loading,
                    PointOfUnloading: formData.carrier.second_point_of_unloading
                }
            ],

            SpecialNotes: formData.special_notes,
            PointOfRedirectName: formData.point_of_redirect_name,
            PointOfRedirectAddress: formData.point_of_redirect_address,
            GiverFio: formData.giver_fio,
            GiverDriverFio: formData.giver_driver_fio,
            TakerFio: formData.taker_fio,
            TakerDriverFio: formData.taker_driver_fio,
            DeliveryDistanceDoc: {
                DeliveryDistance: +formData.delivery_distance.total || 0,
                DeliveryDistanceInCity: +formData.delivery_distance.in_city || 0
            },

            ProductList: {
                WaybillProductId: waybillProductId,
                Tin: formData.carrier.inn,
                Products: formData.products.map((product, index) => {
                    return {
                        OrdNo: index + 1,
                        Name: product.product,
                        MeasureId: product.measureId,
                        PackageCode: product.packageCode ? `${product.packageCode}` : null,
                        PackageName: product.packageName,
                        Summa: product.price,
                        Count: product.quantity,
                        TotalSum: product.total,
                        RoadHaulageCost: product.road_haulage_cost || 0,
                        FollowDocuments: product.follow_documents,
                        MethodDefineWeight: product.weight_define_method,
                        LoadClass: product.load_class,
                        WeightBrutto: +product.weight_brutto || 0,
                        WeightNetto: +product.weight_netto || 0,
                        CatalogCode: product.catalog_code,
                        CatalogName: product.catalog_name,
                    }
                })
            }
        })

        if (actionType === 'send') {
            await sendWaybill({
                id: waybillId,
                hashCode: data,
            });
        } else if (actionType === 'save') {
            await saveDraftWaybill({
                id: waybillId,
                content: data,
            });
        }
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                {errorMessage && <ErrorAlert ref={alertRef} errorMessage={errorMessage}/>}
                <WaybillForm isSending={isSending} isSaving={isSaving} />
            </Form>
        </FormProvider>
    );
};

export default SendWaybillForm;
