import {forwardRef, useEffect, useState} from 'react';
import Select from "react-select";
import styled from "styled-components";
import {useAppContext} from "../../pheonix/providers/AppProvider";

export const FloatingLabelSelectWrapper = styled.div`
    position: relative;
`;

export const FloatingLabel = styled.label.withConfig({
    shouldForwardProp: (prop) => !['hasValue', 'hasInput'].includes(prop)
})`
    position: absolute;
    top: ${props => (props.hasValue || props.hasInput ? '12px' : '50%')};
    left: 10px;
    font-size: ${props => (props.hasValue || props.hasInput ? '12px' : '14px')};
    color: ${props => (props.hasValue || props.hasInput ? '#aaa' : (props.theme === "dark" ? 'rgb(159, 166, 188)' : '#000'))};
    padding: 0 5px;
    transition: 0.2s;
    font-weight: 400;
    pointer-events: none;
    transform: translateY(-50%);
    z-index: 1;
`;

const darkThemeStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: 'rgb(20, 24, 36)',
        borderColor: 'rgb(55, 62, 83)',
        color: 'rgb(159, 166, 188)',
        height: '40px',
        borderTopLeftRadius: state?.left ? '0px' : '8px',
        borderBottomLeftRadius: state?.left ? '0px' : '8px',
        borderBottomRightRadius: state?.right ? '0px' : '8px',
        borderTopRightRadius: state?.right ? '0px' : '8px',
        boxShadow: state.isFocused ? '0 0 0 0.25rem rgba(13, 110, 253, 0.25)' : provided.boxShadow,
        '&:hover': {
            borderColor: 'rgb(55, 62, 83)',
        }
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'rgb(159, 166, 188)',
        marginTop: "15px",
        fontSize: "12px",
        marginLeft: "6px",
    }),
    placeholder: (provided) => ({
        ...provided,
        color: 'rgb(148, 157, 181)',
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: 'rgb(20, 24, 36)',
        color: '#fff',
        zIndex: 9999,
    }),
    label: (provided) => ({
        ...provided,
        color: 'rgb(148, 157, 181)',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'rgb(55, 62, 83)' : state.isFocused ? 'rgb(30, 34, 46)' : 'rgb(20, 24, 36)',
        color: state.isSelected ? 'rgb(159, 166, 188)' : '#fff',
    }),
};

const CustomSelect = styled(Select)`
    .react-select__control {
        box-shadow: none;

        &:hover {
            border-color: #2684ff;
        }
    }

    .react-select__control--is-focused,
    .react-select__control--menu-is-open {
        border-color: #66afe9 !important;
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
    }

    .css-1nmdiq5-menu {
        z-index: 2;
    }

    .css-13cymwt-control {
        height: ${props => props.height || 'auto'};
        border-radius: ${props => (props.left ? '0 12px 12px 0' : props.right ? '12px 0 0 12px' : '8px')};
    }

    .react-select__single-value {
        margin-left: 2px;
        margin-top: 10px; /* Tanlangan qiymatni pastga joylashtirish */
        color: black; /* Bu joyda tanlangan qiymatni qora rangga o'rnatish mumkin */
    }

    .react-select__placeholder {
        margin-left: 2px;
        margin-top: 10px; /* Placeholder matnini pastga joylashtirish */
    }

    .react-select__input-container {
        margin-left: 10px;
        margin-top: 15px; /* Input ichidagi matnning pastga joylashishi */
    }

    .css-1dimb5e-singleValue {
        margin-left: 6px;
        font-size: 12px;
    }

    .react-select__input {
        margin: 0;
        padding: 0;
        line-height: 26px; /* Yozilayotganda tayoqchani yozuv bilan bir xil qilish uchun */
        font-size: 16px; /* Yozuvning hajmini moslashtirish */
    }

    .css-1p3m7a8-multiValue {
        margin-top: 15px;
    }

    .css-wsp0cs-MultiValueGeneric {
        padding: 0 0 0 6px;
        line-height: 15px;
    }
`;

const CustomFloatingLabelSelect = forwardRef(({label, options, value, async, isMulti, ...props}, ref) => {

    const {config: {theme}} = useAppContext();
    const [hasValue, setHasValue] = useState(false);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (isMulti) {
            setHasValue(value && value.length > 0);
        } else {
            setHasValue(!!value);
        }
    }, [value, isMulti]);

    const handleChange = (selectedOption) => {
        if (isMulti) {
            setHasValue(selectedOption && selectedOption.length > 0);
        } else {
            setHasValue(!!selectedOption);
        }
        if (props.onChange) {
            props.onChange(selectedOption);
        }
    };

    const handleInputChange = (value) => {
        setInputValue(value);
    };

    return (
        <FloatingLabelSelectWrapper>
            <FloatingLabel theme={theme}  hasValue={hasValue} hasInput={inputValue}>{label}</FloatingLabel>
            <CustomSelect
                {...props}
                options={options}
                onChange={handleChange}
                onInputChange={handleInputChange}
                inputValue={inputValue}
                value={value}
                placeholder=''
                isMulti={isMulti}
                menuPortalTarget={document.body}
                menuPlacement="auto"
                menuPosition="fixed"
                styles={theme === "dark" ? darkThemeStyles : {
                    menuPortal: base => ({...base, zIndex: 9999}),
                    menu: base => ({...base, zIndex: 9999}),
                    control: (provided, state) => ({
                        ...provided,
                        height: '40px',
                        borderTopLeftRadius: props?.left ? '0px' : '8px',
                        borderBottomLeftRadius: props?.left ? '0px' : '8px',
                        borderBottomRightRadius: props?.right ? '0px' : '8px',
                        borderTopRightRadius: props?.right ? '0px' : '8px',
                        borderColor: state.isFocused ? '#66afe9' : provided.borderColor,
                        boxShadow: state.isFocused ? '0 0 0 0.25rem rgba(13, 110, 253, 0.25)' : provided.boxShadow,
                    }),
                }}
            />
        </FloatingLabelSelectWrapper>
    );
});

export default CustomFloatingLabelSelect;
