import React, {Fragment, useMemo, useState} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {useFormContext, useWatch} from "react-hook-form";
import dayjs from "dayjs";
import {StatusAcceptedByCustomerOrPerson} from "../../../enum/edi/return-invoice/RoamingInvoiceWrapper";
import {
    loadInvoicesAsync,
    loadInvoicesCountAsync
} from "../../../store/reducers/roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import {Button, Modal} from "react-bootstrap";
import {VIEWER_EXECUTOR} from "../../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import RoamingInvoiceDataTable from "../../roaming/invoice/RoamingInvoiceDataTable";

const SelectInvoiceModal = () => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const activeOrganization = useSelector(selectActiveOrganization);
    const [show, setShow] = useState(false);
    const [returnInvoiceData, setReturnInvoiceData] = useState([])
    const [filterOptions, setFilterOptions] = useState({page: 1, limit: 10})
    const [count, setCount] = useState(0)

    const {setValue} = useFormContext();

    const totalSum = useWatch({name: 'returnInvoiceTotalSum'});
    const returnShipment = useWatch({name: 'returnShipmentInfo'});
    const customerInn = useWatch({name: 'customer.inn'});
    const contract = useWatch({name: 'contractInfo'});

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const onInvoiceChosen = (invoice) => {
        setValue('invoiceInfo.date', dayjs(invoice.info.date).toDate());
        setValue('invoiceInfo.number', invoice.info.number);
        setValue('invoiceInfo.id', invoice.id);
        handleClose();
    };

    const predefinedFilters = useMemo(() => {
        return {
            date_end: dayjs(returnShipment.date).format("YYYY-MM-DD"),
            contract_number: contract.number,
            contract_date: dayjs(contract.date).format("YYYY-MM-DD"),
            customer_inn: customerInn,
            status: StatusAcceptedByCustomerOrPerson,
            start_total_sum: Math.abs(totalSum)
        };
    }, [totalSum, returnShipment, customerInn, contract])

    const loadExecutorInvoice = () => {
        loadInvoicesAsync(VIEWER_EXECUTOR, {...filterOptions, ...predefinedFilters, viewer_inn: activeOrganization?.pinfl || activeOrganization?.inn})
            .then(response => setReturnInvoiceData(response))
            .catch(() => setReturnInvoiceData([]))
    }

    const loadExecutorInvoiceCount = () => {
        loadInvoicesCountAsync(VIEWER_EXECUTOR, {...filterOptions, ...predefinedFilters, viewer_inn: activeOrganization?.pinfl || activeOrganization?.inn})
            .then(response => setCount(response))
            .catch(() => setCount(0))
    }

    // load invoice
    const loadInvoices = () => {
        loadExecutorInvoice()
        loadExecutorInvoiceCount()
    }

    // filter option
    const changeFilterOptionsAsync = (filters) => {
        setFilterOptions({...filterOptions, ...filters})
    }

    // pagination option
    const onPageChange = (page) => changeFilterOptionsAsync({...filterOptions, page: page});
    const onLimitChange = (limit) => changeFilterOptionsAsync({...filterOptions, limit: limit, page: 1});

    const disabled = !contract.number || !contract.date;


    return (
        <Fragment>
            <Button className="w-100"
                    onClick={handleShow}
                    disabled={disabled}
            >
                {t(lang, "edi.common.select_button")}
            </Button>
            <Modal show={show} onHide={handleClose} fullscreen>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, "edi.invoice.choose_positive_invoice")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RoamingInvoiceDataTable viewer={VIEWER_EXECUTOR}
                                             isSelectable
                                             predefinedFilters={predefinedFilters}
                                             onSelect={onInvoiceChosen}
                                             returnInvoice
                                             roamingInvoices={returnInvoiceData}
                                             count={count}
                                             filters={filterOptions}
                                             changeFilterOptionsAsync={changeFilterOptionsAsync}
                                             loadInvoices={loadInvoices}
                                             onPageChange={onPageChange}
                                             onLimitChange={onLimitChange}
                    />
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};

export default SelectInvoiceModal;
