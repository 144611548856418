import React, {Fragment, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudDownload, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {Button, Col, Modal, Row} from 'react-bootstrap';
import Flex from "../../common/Flex";
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import PaymentOrderExcel from '../../../assets/excel/Obrazec_plateshnogo_porucheniya.xls'
const PaymentInstruction = ({...props}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Fragment>
            <Flex className={'gap-2'} alignItems={'center'} onClick={handleShow}>
                <FontAwesomeIcon  className={'fs-8'} icon={faInfoCircle} />
                <span>Пополнение</span>
            </Flex>

            <Modal show={show} size="lg" onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, 'main.top_nav.organization_balance.payment_instruction_modal.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>{t(lang, 'main.top_nav.organization_balance.payment_instruction_modal.payment_type.title')}</h5>
                    <p className="mb-3 mt-2 fs--1">
                        {t(lang, 'main.top_nav.organization_balance.payment_instruction_modal.description')}
                    </p>

                    <a className="fs--1" href={PaymentOrderExcel} download="Obrazec_plateshnogo_porucheniya.xls">
                        {t(lang, 'main.top_nav.organization_balance.payment_instruction_modal.download_link.title')}
                        <FontAwesomeIcon className="mx-1" icon={faCloudDownload} />
                    </a>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose}>{t(lang, 'main.top_nav.organization_balance.payment_instruction_modal.close_button')}</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default PaymentInstruction;
