import React, {Fragment, useState} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectDateFormat, selectLang} from "../../../store/reducers/main/main";
import {Controller, useFormContext} from "react-hook-form";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import {Col, Row, Form, FloatingLabel} from "react-bootstrap";
import FindPerson from "../../edi/attorney/FindPerson";
import DatePickerComponent from "../../common/DatePickerComponent";

const RoamingInvoiceFormCardEmpowermentInfo = ({isEditOrCopy}) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const dateFormat = useSelector(selectDateFormat);
    const form = useFormContext();
    const [eventCount, setEventCount] = useState(0);

    const onChangePerson = (personInfo) => {
        // if is copy or edit, prevent from conflict of values
        {
            if (isEditOrCopy && eventCount === 0) {
                setEventCount(eventCount + 1);
                return;
            }
        }

        if (personInfo) {
            form.setValue(`facturaEmpowermentDoc.agentPinfl`, personInfo.pinfl || personInfo.inn);
            form.setValue(`facturaEmpowermentDoc.agentFio`, personInfo.name);
        } else {
            form.setValue(`facturaEmpowermentDoc.agentPinfl`, '');
            form.setValue(`facturaEmpowermentDoc.agentFio`, '');
        }
    };

    return (
        <Row>
            <Form.Group as={Col} xs={12} md={6} className="mb-2">
                    <Controller name={`facturaEmpowermentDoc.agentPinfl`}
                                render={({field}) => (
                                    <FindPerson defaultInnOrPinfl={field.value}
                                                onChange={onChangePerson}
                                                placeholder={`${t(lang, 'roaming.invoice.empowerment_person.pinfl')} *`}
                                    />
                                )}
                    />
            </Form.Group>
            <Form.Group as={Col} xs={12} md={6} className="mb-2">
                <FloatingLabel label={`${t(lang, 'roaming.invoice.empowerment_person.name')} *`}>
                    <Form.Control name={`facturaEmpowermentDoc.agentFio`}
                                  placeholder={t(lang, 'roaming.invoice.empowerment_person.name.placeholder')}
                                  isInvalid={form.formState.errors?.facturaEmpowermentDoc?.agentFio}
                                  {...form.register(`facturaEmpowermentDoc.agentFio`, {
                                      required: {
                                          value: !!form.getValues(`facturaEmpowermentDoc.agentPinfl`),
                                          message: t(lang, 'items.common.validations.is_required')
                                      }
                                  })}
                    />
                </FloatingLabel>
                <Form.Control.Feedback
                    type="invalid">{form.formState.errors?.facturaEmpowermentDoc?.agentFio?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} xs={12} md={6} className="mb-2">
                <FloatingLabel label={`${t(lang, 'roaming.common.empowerment.number.placeholder')} *`}>
                    <Form.Control name={`facturaEmpowermentDoc.empowermentNo`}
                                  isInvalid={form.formState.errors?.facturaEmpowermentDoc?.empowermentNo}
                                  placeholder={`${t(lang, 'roaming.common.empowerment.number.placeholder')} *`}
                                  {...form.register(`facturaEmpowermentDoc.empowermentNo`, {
                                      required: {
                                          value: !!form.getValues(`facturaEmpowermentDoc.agentPinfl`),
                                          message: t(lang, 'items.common.validations.is_required')
                                      }
                                  })}
                    />
                </FloatingLabel>
                <Form.Control.Feedback
                    type="invalid">{form.formState.errors?.facturaEmpowermentDoc?.empowermentNo?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} xs={12} md={6} className="mb-2">
                <Controller
                    name={`facturaEmpowermentDoc.empowermentDateOfIssue`}
                    rules={{
                        required: {
                            value: !!form.getValues(`facturaEmpowermentDoc.agentPinfl`),
                            message: t(lang, 'items.common.validations.is_required')
                        }
                    }}
                    render={({field, fieldState: {error}}) => (
                        <DatePickerComponent
                            field={field}
                            placeholderText={`${t(lang, 'roaming.common.empowerment.date')} *`}
                            isClearable
                            className={classNames('form-control', {
                                'is-invalid': !!error?.message,
                            })}
                            wrapperClassName={classNames({
                                'is-invalid': !!error?.message
                            })}
                        />
                    )}
                />
                <Form.Control.Feedback
                    type="invalid">{form.formState.errors?.facturaEmpowermentDoc?.empowermentDateOfIssue?.message}</Form.Control.Feedback>
            </Form.Group>
        </Row>
    );
};

export default RoamingInvoiceFormCardEmpowermentInfo;
