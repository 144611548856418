import React, {Fragment} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectDateFormat, selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import DatePicker from "react-datepicker";
import {Col, Row, Form, FloatingLabel} from "react-bootstrap";
import DatePickerComponent from "../../../common/DatePickerComponent";

const ContractInfo = () => {
    const {register, formState: {errors}, control} = useFormContext()
    const lang = useSelector(selectLang)
    const t = Translate
    return (
        <Row className={'flex-column gap-2'}>
            <Form.Group as={Col} sm="12">
                <FloatingLabel label={t(lang, "roaming.contracts.add.contract_info.name")}>
                    <Form.Control
                        {...register("contractDoc.contractName", {
                            // required: "edi.common.forms.validations.is_required"
                        })}
                        className={errors?.contractDoc?.contractName?.message && "is-invalid"}
                        placeholder={t(lang, "roaming.contracts.add.contract_info.name")}
                    />
                </FloatingLabel>
                <Form.Control.Feedback type="invalid">
                    {t(lang, errors?.contractDoc?.contractName?.message)}
                </Form.Control.Feedback>
            </Form.Group>
            <Col>
                <Row>
                    <Form.Group as={Col} sm="3">
                        <FloatingLabel label={t(lang, "roaming.contracts.add.contract_info.contract_number")}>
                            <Form.Control
                                {...register("contractDoc.contractNo", {
                                    // required: "edi.common.forms.validations.is_required"
                                })}
                                className={errors?.contractDoc?.contractNo?.message && "is-invalid"}
                                placeholder={t(lang, "roaming.contracts.add.contract_info.contract_number")}
                            />
                        </FloatingLabel>
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.contractDoc?.contractNo?.message)}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm="3">
                        <FloatingLabel label={t(lang, "roaming.contracts.add.contract_info.place_conclusion")}>
                            <Form.Control
                                {...register("contractDoc.contractPlace", {
                                    // required: "edi.common.forms.validations.is_required"
                                })}
                                className={errors?.contractDoc?.contractPlace?.message && "is-invalid"}
                                placeholder={t(lang, "roaming.contracts.add.contract_info.place_conclusion")}
                            />
                        </FloatingLabel>
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.contractDoc?.contractPlace?.message)}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm="3">
                        <Controller
                            control={control}
                            name="contractDoc.contractDate"
                            rules={{required: "edi.common.forms.validations.is_required"}}
                            render={({field}) => (
                                <DatePickerComponent
                                    field={field}
                                    selected={field.value}
                                    wrapperClassName={errors?.contractDoc?.contractExpireDate?.message && "is-invalid"}
                                    className={`${errors?.contractDoc?.contractDate?.message && "is-invalid"} form-control`}
                                    placeholderText={t(lang, "roaming.contracts.add.contract_info.date_conclusion")}
                                />
                            )}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.contractDoc?.contractDate?.message)}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm="3">
                        <Controller
                            control={control}
                            name="contractDoc.contractExpireDate"
                            rules={{
                                // required: "edi.common.forms.validations.is_required"
                            }}
                            render={({field}) => (
                                <DatePickerComponent
                                    field={field}
                                    selected={field.value}
                                    wrapperClassName={errors?.contractDoc?.contractExpireDate?.message && "is-invalid"}
                                    className={`${errors?.contractDoc?.contractExpireDate?.message && "is-invalid"} form-control`}
                                    placeholderText={t(lang, "roaming.contracts.add.contract_info.expiring_on")}
                                />
                            )}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.contractDoc?.contractExpireDate?.message)}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
            </Col>
        </Row>
    );
};

export default ContractInfo;
