import React, {Fragment, useEffect} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Card, Col, Row} from "react-bootstrap";
import RoamingInvoiceFormCardInfo from "./RoamingInvoiceFormCardInfo";
import RoamingInvoiceFormCardOrigin from "./RoamingInvoiceFormCardOrigin";
import RoamingInvoiceFormCardExecutor from "./RoamingInvoiceFormCardExecutor";
import RoamingInvoiceFormCardCustomer from "./RoamingInvoiceFormCardCustomer";
import RoamingInvoiceFormCardItems from "./RoamingInvoiceFormCardItems";
import RoamingInvoiceFormCardEmpowermentInfo from "./RoamingInvoiceFormCardEmpowermentInfo";
import RoamingInvoiceFormCardReleaserInfo from "./RoamingInvoiceFormCardReleaserInfo";
import IconButton from "../../IconButton";
import SelectCertificateWrapperComponent from "../../common/SelectCertificateWrapperComponent";
import {faBookmark, faSignature} from "@fortawesome/free-solid-svg-icons";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import {useNavigate} from "react-router-dom";
import IconSignature from "../../../assets/icon/IconSignature";
import {scrollEvent} from "../../../helpers/scrollEvent";

const RoamingInvoiceForm = ({onSend, onSave, isEditOrCopy}) => {
    const navigate = useNavigate()
    const t = Translate;
    const lang = useSelector(selectLang);

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('roaming-invoice-form-header'))
        return () => window.removeEventListener('scroll', () => scrollEvent('roaming-invoice-form-header'))
    }, []);

    return (
        <Fragment>
            <Row id={'roaming-invoice-form-header'} className={'my-2 justify-content-between align-items-center position-sticky'} style={{top: '4rem'}}>
                <Col xl={6} sm={12} className={'d-flex align-items-center gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'} style={{width: 35, height: 35}}>
                        <IconArrowLeft />
                    </button>
                    <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.invoices.add.title")}</h5>
                </Col>
                <Col xl={6} sm={12} className={'d-flex justify-content-end gap-2'}>
                    <IconButton
                        variant="phoenix-warning"
                        size="lg"
                        className={'btn-davr bg-primary-davr-outline'}
                        onClick={onSave}>
                        {t(lang, "roaming.invoice.save_button")}
                    </IconButton>
                    <SelectCertificateWrapperComponent
                        size="lg"
                        icon={<IconSignature/>}
                        className={'btn-davr bg-primary-davr gap-1 text-white'}
                        submitButton={
                            <IconButton icon={faSignature}
                                        variant="phoenix-primary"
                                        size="lg"
                                        className="w-100 mt-2 mt-md-0 w-md-auto fw-semi-bold"
                                        id="sendButton"
                                        onClick={onSend}
                            >
                                {t(lang, "roaming.invoice.send_button")}
                            </IconButton>
                        }
                        openDialogButtonText={t(lang, "roaming.invoice.send_button")}/>
                </Col>
            </Row>
            <Row className="gy-3">
                <Col xs={12}>
                    <Card className={'p-2 rounded-4 border-0'}>
                        <RoamingInvoiceFormCardOrigin/>
                    </Card>
                </Col>
                <Col xs={12}>
                    <Card className={'p-2 rounded-4 border-0'}>
                        <RoamingInvoiceFormCardInfo/>
                    </Card>
                </Col>
                <Col xs={12} md={6} className={'pe-1'}>
                    <RoamingInvoiceFormCardExecutor isEditOrCopy={isEditOrCopy}/>
                </Col>
                <Col xs={12} md={6} className={'ps-1'}>
                    <RoamingInvoiceFormCardCustomer isEditOrCopy={isEditOrCopy}/>
                </Col>
                <Col xs={12}>
                    <RoamingInvoiceFormCardItems/>
                </Col>

                <Col xs={12}>
                    <Card className={'p-2 rounded-4 border-0'}>
                        <Row>
                            <Col xs={12} md={6}>
                                <RoamingInvoiceFormCardEmpowermentInfo isEditOrCopy={isEditOrCopy}/>
                            </Col>
                            <Col xs={12} md={6}>
                                <RoamingInvoiceFormCardReleaserInfo isEditOrCopy={isEditOrCopy}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default RoamingInvoiceForm;
