import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectDateDayJSFormat, selectLang, selectNumberFormat} from "../../../../store/reducers/main/main";
import {selectCurrentCertificate} from "../../../../store/reducers/eimzo/eimzoReducer";
import {VerificationActWrapper} from "../../../../enum/roaming/vertification-act/VerificationActWrapper";
import {Translate} from "../../../../lang/lang";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import {
    cancelAsync,
    customerAcceptAsync, customerDeclineAsync,
    verificationActSignAndRegister
} from "../../../../store/reducers/roaming/vertification-act/vertificationActReducer";
import dayjs from "dayjs";
import {Card, Col, Row} from "react-bootstrap";
import {faClone, faPen, faSignature} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../IconButton";
import {
    PermissionRoamingVerificationActAccept,
    PermissionRoamingVerificationActUpdate
} from "../../../../enum/permission/RoamingPermission";
import {Link, useNavigate} from "react-router-dom";
import SignActionWithSelectCertificateWrapper from "../../../common/SignActionWithSelectCertificateWrapper";
import checkPermission from "../../../../enum/permission/CheckPermission";
import toastPermission from "../../../../enum/permission/ToastPermission";
import CancelContract from "./CancelVerificationAct"
import DeclineContract from "./DeclineContract";
import Badge from "../../../common/Badge";
import {getStatusColorShipment, GetStatusTextShipment} from "../../../../enum/edi/shipment/shipmentStatus";
import VerificationActViewTable from "./VerificationActViewTable";
import {scrollEvent} from "../../../../helpers/scrollEvent";
import IconArrowLeft from "../../../../assets/icon/IconArrowLeft";
import IconSignature from "../../../../assets/icon/IconSignature";

const VerificationAct = ({verificationAct}) => {
    const dispatch= useDispatch()
    const navigate = useNavigate();
    const lang = useSelector(selectLang);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const numberFormat = useSelector(selectNumberFormat)
    const activeOrganization = useSelector(selectActiveOrganization);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const [showCancelButton, setShowCancelButton] = useState(false)
    const [showAcceptButton, setShowAcceptButton] = useState(false)
    const [showDeclineButton, setShowDeclineButton] = useState(false)
    const [showCopyButton, setShowCopyButton] = useState(false)
    const verificationActWrapper = new VerificationActWrapper(verificationAct)
    const t = Translate;
    const showDeclineNotes = verificationActWrapper.enumStatuses.buyerOrAgentIsDeclined === verificationAct.status;

    useEffect(() => {
        setShowCancelButton(verificationActWrapper.canTheSellerCancled(activeOrganization.inn))
        setShowAcceptButton(verificationActWrapper.canTheBuyerSign(activeOrganization.inn))
        setShowDeclineButton(verificationActWrapper.canTheBuyerDecline(activeOrganization.inn))
        setShowCopyButton(verificationActWrapper.allowCopy(activeOrganization.inn))
    }, [verificationAct, activeOrganization.inn])

    const executorSignAndSend = async (data) => {
        try {
            if(currentCertificate?.keyId) {
                let verificationAct = {
                    VerificationActId: data.id,
                    VerificationActDoc: {
                        VerificationActNo: data.info.number,
                        VerificationActDate: dayjs(data.info.date).format("YYYY-MM-DD"),
                        VerificationActText: data.info.text
                    },
                    OwnerTin: data.executor.inn,
                    OwnerName: data.executor.name,
                    OwnerFizTin: data.executor.director_inn,
                    OwnerFizFio: data.executor.director_name,
                    PartnerTin: data.customer.inn,
                    PartnerName: data.customer.name,
                    PartnerFizTin: data.customer.director_inn,
                    PartnerFizFio: data.customer.director_name,
                    VerificationActContracts: data.contracts.map(contract => ({
                        ContractNo: contract.contract_no,
                        ContractDate: dayjs(contract.contract_date).format("YYYY-MM-DD"),
                        VerificationActContractItems: contract.items.map(item => ({
                            OwnerOperationDate: dayjs(item.executor_operation_date).format("YYYY-MM-DD"),
                            OwnerOperationName: item.executor_operation_name,
                            OwnerDebit: item?.executor_debit?.toFixed(2),
                            OwnerCredit: item?.executor_credit?.toFixed(2),
                            PartnerOperationDate: dayjs(item.customer_operation_date).format("YYYY-MM-DD"),
                            PartnerOperationName: item.customer_operation_name,
                            PartnerDebit: item?.customer_debit?.toFixed(2),
                            PartnerCredit: item?.customer_credit?.toFixed(2)
                        })),
                        OpenBalance: {
                            OwnerDebit: contract?.open_balance?.executor_debit?.toFixed(2),
                            OwnerCredit: contract?.open_balance?.executor_credit?.toFixed(2),
                            PartnerDebit: contract?.open_balance?.customer_debit?.toFixed(2),
                            PartnerCredit: contract?.open_balance?.customer_credit?.toFixed(2)
                        },
                        CloseBalance: {
                            OwnerDebit: contract?.close_balance?.executor_debit?.toFixed(2),
                            OwnerCredit: contract?.close_balance?.executor_credit?.toFixed(2),
                            PartnerDebit: contract?.close_balance?.customer_debit?.toFixed(2),
                            PartnerCredit: contract?.close_balance?.customer_credit?.toFixed(2)
                        },
                        TotalBalance: {
                            OwnerDebit: contract?.total_balance?.executor_debit?.toFixed(2),
                            OwnerCredit: contract?.total_balance?.executor_credit?.toFixed(2),
                            PartnerDebit: contract?.total_balance?.customer_debit?.toFixed(2),
                            PartnerCredit: contract?.total_balance?.customer_credit?.toFixed(2)
                        }

                    })),
                    OpenBalance: {
                        OwnerDebit: data?.open_balance?.executor_debit?.toFixed(2),
                        OwnerCredit: data?.open_balance?.executor_credit?.toFixed(2),
                        PartnerDebit: data?.open_balance?.customer_debit?.toFixed(2),
                        PartnerCredit: data?.open_balance?.customer_credit?.toFixed(2),
                    },
                    TurnoverBalance: {
                        OwnerDebit: data?.turnover_balance?.executor_debit?.toFixed(2),
                        OwnerCredit: data?.turnover_balance?.executor_credit?.toFixed(2),
                        PartnerDebit: data?.turnover_balance?.customer_debit?.toFixed(2),
                        PartnerCredit: data?.turnover_balance?.customer_credit?.toFixed(2),
                    },
                    CloseBalance: {
                        OwnerDebit: data?.close_balance?.executor_debit?.toFixed(2),
                        OwnerCredit: data?.close_balance?.executor_credit?.toFixed(2),
                        PartnerDebit: data?.close_balance?.customer_debit?.toFixed(2),
                        PartnerCredit: data?.close_balance?.customer_credit?.toFixed(2),
                    }
                }
                await verificationActSignAndRegister({
                    verificationAct: verificationAct,
                    certificate: currentCertificate,
                    lang: lang
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('roaming-verification-act-view'))
        return () => window.removeEventListener('scroll', () => scrollEvent('roaming-verification-act-view'))
    }, []);


    return (
        <Fragment>
            <Row id={'roaming-verification-act-view'} className={'justify-content-between position-sticky z-index-1'} style={{top: '4rem'}}>
                <Col xs={"auto"} className={'d-flex gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'} style={{width: 35, height: 35}}>
                        <IconArrowLeft/>
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.verification_act.view.title")} № {verificationAct?.info?.number}</h5>
                        <div className={'d-flex align-items-center gap-2'}>
                            <span className={'second-text fs-9'}>
                                {verificationAct.created_at}
                            </span>
                            {showDeclineNotes && <Badge
                                bg={verificationActWrapper.getRoamingStatusVariant(activeOrganization.pinfl || activeOrganization.inn)}
                                variant={'phoenix'}
                                className={'px-2 py-1 fs-9 text-uppercase text-decoration-none fw-medium border-0 rounded-4'}>
                                {t(lang, verificationActWrapper.getRoamingStatus(activeOrganization.pinfl || activeOrganization.inn))}
                            </Badge>}
                        </div>
                    </div>
                </Col>
                <Col lg={"auto"} className={'d-flex gap-2 justify-content-end'}>
                    {showCopyButton &&
                        <IconButton
                            variant="phoenix-secondary"
                            size="sm"
                            icon={faClone}
                            as={Link}
                            to={verificationAct?.status ? `/roaming/verification-act/actions/${verificationAct.id}?status=${verificationAct?.status}` : `/roaming/verification-act/actions/${verificationAct.id}?status=${null}`}
                            className="btn-davr px-3 py-0 bg-white d-flex gap-2"
                            iconClassName={'fs-8 text-primary-davr'}
                        >
                            {t(lang, "roaming.common.copy")}
                        </IconButton>
                    }
                    {verificationAct?.status ?
                        <Fragment>
                            {showCancelButton &&
                                <CancelContract
                                    cancelVerificationActHandler={({comment}) => cancelAsync(currentCertificate, lang, verificationAct, comment)}
                                />
                            }

                            {showDeclineButton &&
                                <DeclineContract
                                    declineVerificationActHandler={({comment}) => customerDeclineAsync(currentCertificate, lang, verificationAct, comment)}
                                />
                            }

                            {showAcceptButton &&
                                <SignActionWithSelectCertificateWrapper permission={PermissionRoamingVerificationActAccept}>
                                    <IconButton
                                        size="sm"
                                        icon={""}
                                        className="btn-davr px-3 py-0 bg-primary-davr d-flex gap-2"
                                        onClick={() => customerAcceptAsync(currentCertificate, lang, verificationAct)}
                                        disabled={!currentCertificate}
                                    >
                                        <IconSignature />
                                        {t(lang, 'edi.common.button.sign')}
                                    </IconButton>
                                </SignActionWithSelectCertificateWrapper>
                            }
                        </Fragment>
                        :
                        <Fragment>
                            <IconButton
                                to={`/roaming/verification-act/draft/edit/${verificationAct.id}`}
                                as={Link}
                                variant="phoenix-secondary"
                                size="sm"
                                icon={faPen}
                                className="btn-davr px-3 py-0 bg-white d-flex gap-2"
                                iconClassName={'fs-8 text-primary-davr'}
                            >{t(lang, 'roaming.common.edit')}</IconButton>

                            <SignActionWithSelectCertificateWrapper permission={PermissionRoamingVerificationActAccept}>
                                <IconButton
                                    size="sm"
                                    icon={""}
                                    className="btn-davr px-3 py-0 bg-primary-davr d-flex gap-2"
                                    onClick={() => executorSignAndSend(verificationAct)}
                                    disabled={!currentCertificate}
                                >
                                    <IconSignature />
                                    {t(lang, 'edi.common.button.sign')}
                                </IconButton>
                            </SignActionWithSelectCertificateWrapper>
                        </Fragment>
                    }
                </Col>
            </Row>

            <Card className={'mt-3'}>
                <Card.Body className={'py-2'}>
                    <h2 className="fw-semi-bold text-center">
                        {t(lang, "roaming.dashboard.verification_act.title")}
                    </h2>
                    <h5 className="fw-semi-bold text-center second-text">
                        {t(lang, "roaming.verification_act.view.subInfo-1", {
                            owner: verificationAct?.executor?.name,
                            partner: verificationAct?.customer?.name
                        })}
                        {t(lang, "roaming.verification_act.view.subInfo-2", {
                            owner: verificationAct?.executor?.name,
                            partner: verificationAct?.customer?.name
                        })}
                    </h5>
                </Card.Body>
            </Card>

            <Card className={'mt-3'}>
                <Card.Body className={'border-0'}>
                    <p>{verificationAct?.info?.text}</p>
                    <VerificationActViewTable verificationAct={verificationAct} />
                </Card.Body>
            </Card>
        </Fragment>
    );
};

export default VerificationAct;
