import React from 'react';
import {useNavigate} from "react-router-dom";
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {useFormContext} from "react-hook-form";
import {loadCatalogsByCode, loadMySoliqUzContract} from "../../../store/reducers/roaming/roamingReferenceReducer";
import toast from "react-hot-toast";
import {uniq} from "underscore";
import {Button, Col, InputGroup, Row, Form, FloatingLabel} from "react-bootstrap";
import dayjs from "dayjs";
import IconSearch from "../../../assets/icon/IconSearch";

const RoamingInvoiceFormCardOrigin = () => {
    const navigate = useNavigate();
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();

    const onSearchContract = () => {
        const id = form.getValues('contractDoc.contractId');
        id && loadMySoliqUzContract(id)
            .then((contract) => {
                onFillFromMySoliqUzContract(contract);
                toast.success(t(lang, 'edi.common.toast.success'))
            })
            .catch((error) => {
                console.log(error);
                toast.error(t(lang, 'edi.common.toast.error'));
            })
    };

    const onFillFromMySoliqUzContract = async (contract) => {
        if (contract) {
            form.setValue(`contractDoc.contractId`, contract.contract_id);
            form.setValue(`contractDoc.contractNo`, contract.contract_no);
            form.setValue(`contractDoc.contractDate`, contract.contract_date ? dayjs(contract.contract_date).toDate() : null);

            if (contract.contragents.length)
                form.setValue(`buyer.innOrPinfl`,  contract.contragents[0].tin);


            const unknownCatalogCodes = uniq(contract.products.map((item) => item.product_code || ""));
            const catalogs = await loadCatalogsByCode({codes: unknownCatalogCodes});
            const products = contract.products.map((product) => {
                const json = {
                    catalog: null,
                    package: null,
                    measurement: null,
                    lgota: null,
                    origin: null,
                    committentName: '',
                    committentTin: '',
                    committentVatRegCode: '',
                    committentVatRegStatus: '',
                    barcode: '',
                    warehouseId: null,
                    mark: {
                        productType: null,
                        kiz: [
                            {code: ''}
                        ],
                        nomUpak: [
                            {code: ''}
                        ],
                        identTransUpak: [
                            {code: ''}
                        ],
                    },
                    exchangeInfo: {
                        productCode: '',
                        productProperties: '',
                        planPositionId: '',
                    },
                    name: '',
                    serial: '',
                    baseSumma: '',
                    profitRate: '',
                    count: '',
                    countTon: '',
                    countKm: '',
                    summa: '',
                    exciseRate: '',
                    exciseSum: '',
                    deliverySum: '',
                    vatRate: '',
                    vatSum: '',
                    deliverySumWithVat: '',
                };
                json.name = product.product_name || '';
                json.barcode = product.product_bar_code || '';
                json.count = product.count || '';
                json.summa = product.item_sum || '';

                // set item tax info
                {
                    let catalog = null;
                    let catalogPackage = null;
                    let vatRate = '';

                    if (product.product_code) {
                        catalog = catalogs.find(c => c.class_code === product.product_code);

                        if (product.package_code && catalog && catalog.use_package)
                            catalogPackage = catalog.package_names.find(p => p.code === parseInt(product.package_code))

                        if (product.vat_rate !== null)
                            vatRate = product.vat_rate.toString();
                    }

                    json.catalog = catalog;
                    json.package = catalogPackage;
                    json.vatRate = vatRate;
                }

                return json;
            });
            form.setValue(`productList.products`, products);
        }
    };

    const onSearchWaybill = () => {
        const id = form.getValues('waybillId');
        id && navigate({search: `?waybill_id=${id}`});
    };

    return (
        <Row className="g-2">
            <Col xs={12} md={6}>
                <Form.Group className={'d-flex gap-1'}>

                        <FloatingLabel className={'w-100'} label={t(lang, 'roaming.contract.id')}>
                            <Form.Control
                                placeholder={t(lang, 'roaming.contract.id')}
                                name={`contractDoc.contractId`}
                                {...form.register(`contractDoc.contractId`)}
                            />
                        </FloatingLabel>

                        <Button className={'bg-primary-davr'} onClick={onSearchContract}>
                            <IconSearch />
                        </Button>

                </Form.Group>
            </Col>
            <Col xs={12} md={6}>
                <Form.Group className={'d-flex gap-1'}>
                        <FloatingLabel className={'w-100'} label={t(lang, 'roaming.invoice.waybill.id')}>
                            <Form.Control
                                placeholder={t(lang, 'roaming.invoice.waybill.id')}
                                name={`waybillId`}
                                {...form.register(`waybillId`)}
                            />
                        </FloatingLabel>

                        <Button className={'bg-primary-davr'} onClick={onSearchWaybill}>
                            <IconSearch />
                        </Button>
                </Form.Group>
            </Col>
        </Row>
    );
};

export default RoamingInvoiceFormCardOrigin;
