import React, {Fragment, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {selectCurrentCertificate} from "../../../../store/reducers/eimzo/eimzoReducer";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import checkPermission from "../../../../enum/permission/CheckPermission";
import {PermissionRoamingVerificationActReject} from "../../../../enum/permission/RoamingPermission";
import toastPermission from "../../../../enum/permission/ToastPermission";
import IconButton from "../../../IconButton";
import {faXmarkCircle} from "@fortawesome/free-solid-svg-icons";
import {Button, Form, Modal} from "react-bootstrap";
import SignActionWithSelectCertificateWrapper from "../../../common/SignActionWithSelectCertificateWrapper";
import IconFolderCancel from "../../../../assets/icon/IconFolderCancel";

const DeclineContract = ({declineVerificationActHandler, disabled, ...props}) => {
    const dispatch = useDispatch()
    const currentCertificate = useSelector(selectCurrentCertificate)
    const lang = useSelector(selectLang);
    const t = Translate;
    const [show, setShow] = useState(false);

    const methods = useForm();
    const { register, formState: {errors}, handleSubmit, reset } = methods

    const handleShow = () => {
        if (dispatch(checkPermission(PermissionRoamingVerificationActReject))) setShow()
        else dispatch(toastPermission())
    }

    const handleClose = () => {
        reset();
        setShow(false);
    };

    const formSubmit = ({comment}) => {
        if(currentCertificate?.keyId) {
            declineVerificationActHandler({
                comment: comment
            })
            reset();
            setShow(false);
        }
    };

    return (
        <Fragment>
            <IconButton
                variant="phoenix-secondary"
                size="sm"
                icon={""}
                disabled={disabled}
                iconClassName="fs-8 text-primary-davr"
                onClick={handleShow}
                className="btn-davr px-3 py-0 bg-white d-flex gap-2"
                {...props}
            >
                <IconFolderCancel />
                {t(lang, "edi.common.button.decline")}
            </IconButton>

            <Modal size="md" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>VerificationAct decline</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormProvider {...methods}>
                        <Form onSubmit={handleSubmit(formSubmit)} id="DeclineVerificationActForm">
                            <Form.Group>
                                <Form.Label>Comment</Form.Label>
                                <Form.Control
                                    name="Name"
                                    as="textarea" rows={3}
                                    placeholder="Please enter a comment to decline"
                                    isInvalid={errors?.comment?.message}
                                    {...register("comment", {
                                        required: {
                                            value: true,
                                            message: "This field is required!"
                                        }
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.comment?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                    </FormProvider>
                </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {handleClose()}}>{t(lang, "edi.common.button.close")}</Button>
                        <SignActionWithSelectCertificateWrapper isForm>
                            <Button
                                variant="danger"
                                className="d-flex align-items-center"
                                disabled={disabled}
                                type="submit"
                                form="DeclineVerificationActForm"
                            >{t(lang, "edi.common.button.yes")}
                            </Button>
                        </SignActionWithSelectCertificateWrapper>
                    </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default DeclineContract;
