import React from 'react';
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {Table} from "react-bootstrap";
import numeral from "numeral";
const WaybillV2ViewTable = ({waybill, getItemMeasurementText}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const numberFormat = useSelector(selectNumberFormat);



    return (
        <Table bordered responsive className="table-view scrollbar border-900 mb-0 fs-9 fw-semi-bold">
            <thead className="text-dark border-bottom bg-card second-text text-center">
            <tr>
                <th rowSpan={3}>№</th>
                <th rowSpan={2} className="fw-semi-bold align-middle">{t(lang, 'roaming.waybill.view.datatable.row.product')}</th>
                <th rowSpan={2} className="fw-semi-bold align-middle">{t(lang, 'roaming.waybill.view.datatable.row.product.catalog_class_code')}</th>
                <th rowSpan={2} className="fw-semi-bold align-middle">{t(lang, 'roaming.waybill.view.datatable.row.product.measurement')}</th>
                <th rowSpan={2} className="fw-semi-bold align-middle">{t(lang, 'roaming.waybill.view.datatable.row.product.quantity')}</th>
                <th rowSpan={2} className="fw-semi-bold align-middle">{t(lang, 'roaming.waybill.view.datatable.row.product.price')}</th>
                <th rowSpan={2} className="fw-semi-bold align-middle">{t(lang, 'roaming.waybill.view.datatable.row.product.total')}</th>
            </tr>
            <tr/>
            <tr>
                <th className="fw-semi-bold align-middle">1</th>
                <th className="fw-semi-bold align-middle">2</th>
                <th className="fw-semi-bold align-middle">3</th>
                <th className="fw-semi-bold align-middle">4</th>
                <th className="fw-semi-bold align-middle">5</th>
                <th className="fw-semi-bold align-middle">6</th>
            </tr>
            </thead>
            <tbody>
            {waybill?.roadway?.product_groups[0]?.products?.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="fw-semi-bold align-middle">{index + 1}</td>
                        <td className="fw-semi-bold align-middle">{item.name}</td>
                        <td className="fw-semi-bold align-middle">{item.catalog?.code || item.catalog_code}</td>
                        <td className="fw-semi-bold align-middle">{item?.package?.code || item.measurement}</td>
                        <td className="fw-semi-bold align-middle">{item.quantity}</td>
                        <td className="fw-semi-bold align-middle">{numeral.formats[numberFormat].format(item.price)}</td>
                        <td className="fw-semi-bold align-middle">{numeral.formats[numberFormat].format(item.total)}</td>
                    </tr>
                )
            })}
            </tbody>
        </Table>
    );
};

export default WaybillV2ViewTable;
