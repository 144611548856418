import React, {useEffect} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {selectLang} from "../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../lang/lang";
import {getUsernameExistsAsync} from "../../store/reducers/auth/authReducer";
import {Card, FloatingLabel, Form} from 'react-bootstrap'
import Cleave from "cleave.js/react";
import classNames from "classnames";
const UsernameInputForm = ({name, shouldExist, shouldNotExist, hasLabel, ...props}) => {
    const {register, control, setFocus, getFieldState, formState: {errors}} = useFormContext();
    const {error} = getFieldState(name);
    const lang = useSelector(selectLang);
    const t = Translate;

    const validateShouldExist = async (value) => {
        if (shouldExist)
            return await getUsernameExistsAsync(value)
        return true;
    }

    const validateShouldNotExist = async (value) => {
        if (shouldNotExist)
            return !await getUsernameExistsAsync(value);
        return true;
    }

    useEffect(() => {
        setFocus(name)
    }, []);

    return (
        <Form.Group className='text-start' {...props}>
            <Card className={'p-1'}>
                <Form.Group className="input-group gap-2 flex-nowrap">
                    <FloatingLabel label={"Код"} className={'w-25'}>
                        <Form.Control value={"+998"} placeholder={"Код"}/>
                    </FloatingLabel>
                    <Controller control={control} name={name} rules={{
                        required: t(lang, "edi.common.forms.validations.is_required"),
                    }} render={({field}) => (
                        <FloatingLabel label={"Номер телефона"} className={'w-75'}>
                            <Cleave
                                placeholder={"Номер телефона"}
                                options={{
                                    delimiters: [' ', ' ', ' ', ' '],
                                    blocks: [2, 3, 2, 2],
                                    numericOnly: true
                                }}
                                onChange={(username) => field.onChange(username)}
                                className={classNames('form-control', {
                                    'is-invalid': error?.message,
                                })}
                            />
                        </FloatingLabel>
                    )}/>
                </Form.Group>
            </Card>
            <Form.Control.Feedback className={'d-block'} type='invalid'>{error?.message}</Form.Control.Feedback>
        </Form.Group>
    );
};

export default UsernameInputForm;
