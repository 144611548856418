import React, {useEffect, useRef, useState} from 'react';
import {Translate} from "../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {selectItems} from "../../store/reducers/item/itemReducer";
import ReactSelect ,{components} from "react-select";
import CreatableSelect from "react-select/creatable";
import CustomFloatingLabelSelectCreatable from "../floating-custom-select/CustomFloatingLabelSelectCreatable";

const SearchItem = ({onChange, defaultItemId, selectProps, index}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const items = useSelector(selectItems);
    const [value, setValue] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);

    /*useEffect(() => {
        console.log(123)
        setValue(items.find(i => i.item.id === defaultItemId) || null)
    }, [defaultItemId]);*/

    const handleChange = (option) => {
        if (!option) {
            onChange(option)
            setValue(option)
            return setSelectedOption(false)
        }
        setSelectedOption(option)
        setValue(option?.__isNew__ ? {name: option.value} : option)
        onChange(option)
    }

    const formatOptionLabel = (option) => {
        return <h6 className="fs-9 mb-0">{option?.item?.name || option?.name || null}</h6>
    }

    const MenuList = ({children, ...props}) => {
        return (
            <components.MenuList {...props}>
                {Array.isArray(children) ? children.slice(0, props.selectProps?.maxOptions) : children}
            </components.MenuList>
        )
    }

    return (
            <CustomFloatingLabelSelectCreatable
                options={items}
                components={{MenuList}}
                maxOptions={15}
                onChange={(option) => handleChange(option)}
                getOptionValue={option => option.item?.id}
                getOptionLabel={option => `${option.item?.name}`}
                formatOptionLabel={(option) => option?.__isNew__ ? option.label : formatOptionLabel(option)}
                formatCreateLabel={(inputValue) => t(lang, 'edi.common.create_new', {value: inputValue})}
                label={t(lang, 'items.common.draft.select_item')}
                // formatOptionLabel={formatOptionLabel}
                // isSearchable={!defaultItemId}
                isClearable
                value={value}
                menuPortalTarget={document.body}
                {...selectProps}
            />
    );
};

export default SearchItem;
