import React, {useRef, useState} from 'react';
import {Translate} from "../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {debounce} from "underscore";
import {loadCatalogsBySubPositionName} from "../../store/reducers/roaming/roamingReferenceReducer";
import AsyncSelect from "react-select/async";
import CustomFloatingLabelSelectAsync from "../floating-custom-select/CustomFloatingLabelSelectAsync";

const SelectTasnifCatalog = ({onChange, placeholder}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [value, setValue] = useState(null);

    const handleChange = (option) => {
        onChange(option)
        setValue(option)
    }

    const loadOptions = (query, callback) => {
        loadCatalogsBySubPositionName({sub_position_name: query})
            .then((catalogs) => {
                callback(catalogs);
            })
            .catch(() => {
                callback([]);
            })
    };
    const debouncedLoadOptions = debounce(loadOptions, 500);

    const formatOptionLabel = (option) => {
        return (
            <div className="fs-9 mt-3">
                <span className="text-black fw-bold me-1">{option.class_code}</span>-
                <span className="ms-1 text-facebook">{option.name}</span>
            </div>
        )
    };

    return <CustomFloatingLabelSelectAsync
            cacheOptions
            loadOptions={debouncedLoadOptions}
            formatOptionLabel={formatOptionLabel}
            label={placeholder}
            value={value}
            onChange={(option) => handleChange(option)}
        />
};

export default SelectTasnifCatalog;
