import React, {Fragment} from 'react';
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {EmpowermentWrapper} from "../../../enum/roaming/empowerment/EmpowermentStatus";
import {Col, Row, Form, Button, Card} from "react-bootstrap";
import {Controller} from "react-hook-form";
import SelectStatus from "../../common/SelectStatus";

const EmpowermentDataTableFilter = () => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const {viewer} = useParams();
    const empowermentWrapper = new EmpowermentWrapper();

    return (
        <Fragment>
            <Card className={'p-1'}>
                <Row className="g-3 justify-content-between align-items-center">
                    <Col xs={12}>
                        <Form.Group>
                            <Controller
                                name="status"
                                render={({field}) => (
                                    <SelectStatus
                                        statuses={empowermentWrapper.GetStatuses(viewer)}
                                        isClearable
                                        placeholder={t(lang, "edi.order.datatable.header.filter.status")}
                                        onChange={statusId => field.onChange(statusId || null)}
                                    />
                                )}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Card>
            <Form.Group className="mt-2 d-flex justify-content-end align-content-end w-100">
                <Button
                    className="btn-davr px-3 py-0 bg-primary-davr float-end"
                    type='submit'>{t(lang, "edi.attorney.datatable.header.filter.show")}
                </Button>
            </Form.Group>
        </Fragment>
    );
};

export default EmpowermentDataTableFilter;
