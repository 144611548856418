import React, {useEffect, useState} from 'react';
import {Translate} from "../../lang/lang";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, selectNumberFormat} from "../../store/reducers/main/main";
import {selectWarehouses} from "../../store/reducers/warehouse/warehouseReducer";
import {deletePushUpdateNotification} from "../../store/reducers/pushupdate/pushUpdateNotification";
import toast from "react-hot-toast";
import {ContractorDebtUpdated, WarehouseItemUpdated} from "../../enum/pushupdate/PushUpdateNotification";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Badge from "./Badge";
import numeral from "numeral";
const Notification = ({index, notification}) => {
    const t = Translate
    const lang = useSelector(selectLang)
    const [currencyArr, setCurrencyArr] = useState(null)
    const warehouses = useSelector(selectWarehouses)
    const numberFormat = useSelector(selectNumberFormat);
    const dispatch = useDispatch()
    const deleteOne = (index) => {
        dispatch(deletePushUpdateNotification(index))
        toast.error(t(lang,"notification.toast.notification_deleted"))
    }

    useEffect(() => {
        if (notification.type === ContractorDebtUpdated) {
            const debtsArray = notification?.payload?.debts?.map((item) => ({
                id: item.currency.id,
                name: item.currency.name,
                amount: item.amount
            }))
            setCurrencyArr(debtsArray)
        }
    }, [notification]);

    return (
        <div className={"notification-body"}>
            <div className={"d-flex align-items-start justify-content-between gap-1"}>
                <div>
                    {notification.type === WarehouseItemUpdated &&
                        <div>
                            <Badge variant={'phoenix'} bg='info' className='me-1'>
                                {notification?.payload?.item_name ? notification?.payload?.item_name : null}
                            </Badge>
                            {t(lang, "notification.warehouse_item.message.first_line")}
                            <Badge variant={'phoenix'} bg='info' className='mx-1'>
                                {(notification?.payload?.warehouse_id && warehouses.find(i => i.id === notification?.payload?.warehouse_id).name)}
                            </Badge>
                            {t(lang, "notification.warehouse_item.message.second_line")}
                            {notification?.payload?.state > 0 ? <Badge variant={'phoenix'} bg='success' className='mx-1'>
                                    {notification?.payload?.state}
                                </Badge>
                                :
                                <Badge variant={'phoenix'} bg='danger' className='mx-1'>
                                    {notification?.payload?.state}
                                </Badge>}
                        </div>}
                    {notification.type === ContractorDebtUpdated &&
                        <div>
                            <h6 className={"m-0 mb-1"}>{t(lang, "notification.contractor_debt.client_name")} <span
                                className={"text-primary fw-medium"}>{notification?.payload?.name}</span></h6>
                            <h6 className={"m-0 mb-1"}>{t(lang, "notification.contractor_debt.client_debt")}</h6>
                            <div className={"d-flex flex-wrap fs--1 gap-1"}>
                                {currencyArr && currencyArr.map(item => <Badge variant={'phoenix'} key={item.id}
                                                                               bg='primary'>
                                    <span>{numeral['formats'][numberFormat].format(item.amount)}</span>
                                    <small className={'ms-2 fw-bold text-info'}>{item.name}</small>
                                </Badge>)}
                            </div>
                        </div>}
                </div>
                <FontAwesomeIcon icon="fa-solid fa-xmark" style={{cursor: "pointer"}} transform="shrink-3"
                                 onClick={() => deleteOne(index)} size={"2x"} className="text-danger"/>
            </div>
        </div>
    );
};

export default Notification;
