import React, {Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectDateDayJSFormat, selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {
    loadRoamingVerificationActsAsync, loadRoamingVerificationActsCountAsync,
    selectCount,
    selectRoamingVerificationActs,
    selectVerificationActFilterOptions, setId, setShowModal, viewPage,changeFilterOptionsAsync
} from "../../../store/reducers/roaming/vertification-act/vertificationActReducer";
import {
    roamingHideModal,
    setRoamingCommonHideModal
} from "../../../store/reducers/roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import {Translate} from "../../../lang/lang";
import {VIEW_DRAFT, VIEW_INBOX} from "../../../enum/roaming/TypeViewers";
import dayjs from "dayjs";
import {VerificationActWrapper} from "../../../enum/roaming/vertification-act/VerificationActWrapper";
import Badge from "../../common/Badge";
import numeral from "numeral";
import TypeViewerAction from "../../common/TypeViewerAction";
import AdvanceTableWrapper from "../../table/AdvanceTableWrapper";
import AdvanceTable from "../../table/AdvanceTable";
import AdvanceTableCustomPagination from "../../table/AdvanceTableCustomPagination";
import VerificationActDataTableHeader from "./VerificationActDataTableHeader";
import ViewModalPage from "../../common/ViewModalPage";
import ViewVerificationAct from "../../../pages/roaming/verification-act/ViewVerificationAct";
import ViewDraftVerificationAct from "./view/ViewDraftVerificationAct";
import {Card, Dropdown} from "react-bootstrap";
import IconFolderDownload from "../../../assets/icon/IconFolderDownload";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RevealDropdown, {RevealDropdownTrigger} from "../../common/RevealDropdown";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {checkViewer} from "../../../enum/roaming/checkViewer";

const VerificationActDataTable = ({typeViewer}) => {
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat);
    const activeOrganization = useSelector(selectActiveOrganization);
    const roamingVerificationActs = useSelector(selectRoamingVerificationActs);
    const filters = useSelector(selectVerificationActFilterOptions)
    const numberFormat = useSelector(selectNumberFormat);
    const count = useSelector(selectCount)
    const lang = useSelector(selectLang);
    const {id, draftId, showModal} = useSelector(viewPage)
    const hideModal = useSelector(roamingHideModal)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const t = Translate;
    const activeOrganizationPinflOrInn = activeOrganization.pinfl || activeOrganization.inn

    const openViewModalPage = (e,id) => {
        // const ctrlKey = e.ctrlKey
        // const history = window.open

        if (typeViewer === VIEW_DRAFT) {
            navigate('/roaming/verification-act/draft/'+id)
            // if (ctrlKey) {
            //     history('/roaming/verification-act/draft/'+id, '_blank')
            // } else {
            //     dispatch(setId({draftId: id}))
            //     dispatch(setShowModal(true))
            // }
        }else {
            navigate('/roaming/verification-act/'+id)
            // if (ctrlKey) {
            //     history('/roaming/verification-act/'+id, '_blank')
            // } else {
            //     dispatch(setId({id}))
            //     dispatch(setShowModal(true))
            // }
        }
    }

    const onHideModalPage = () => {
        dispatch(setShowModal(false))
    }

    // to close the modal on page change
    useEffect(() => {
        if(hideModal) {
            onHideModalPage()
            dispatch(setRoamingCommonHideModal(null))
        }
    }, [])

    const columns = [
        {
            accessor: 'created_at',
            Header:  t(lang, "roaming.verification_act.datatable.header.row.created_at"),
            Cell: ({row: {original}}) => dayjs(original?.created_at, "YYYY-MM-DD").format(currentDateDayJSFormat)
        },
        {
            accessor: 'contractor',
            Header: t(lang, "edi.common.label.organization"),
            cellProps: {
                className: 'pe-4'
            },
            headerProps: {width: 300},
            Cell: ({row: {original}}) => {
                const viewer  = typeViewer === VIEW_INBOX

                return (
                    <Fragment>
                        {viewer && <div className={'d-flex align-items-center gap-2'}>
                            {checkViewer(typeViewer)}
                            <div>
                             <span className={'d-block fs-9 second-text'}>
                                    №  {t(lang, "roaming.verification_act.datatable.body.row.verification_act", {
                                 name: original?.info?.number,
                                 date: dayjs(original?.info?.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                             })}
                             </span>
                                <span className={'fs-8 main-text fw-bold text-truncate'}>{original.executor.name}</span>
                                <i className={'d-block fs-9 second-text'}>{original.executor.inn || original.executor.pinfl}</i>
                            </div>
                        </div>}

                        {!viewer &&  <div className={'d-flex align-items-center gap-2'}>
                            {checkViewer(typeViewer)}
                            <div>
                             <span className={'d-block fs-9 second-text'}>
                                    №  {t(lang, "roaming.verification_act.datatable.body.row.verification_act", {
                                 name: original?.info?.number,
                                 date: dayjs(original?.info?.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                             })}
                             </span>
                                <span className={'fs-8 main-text fw-bold text-truncate'}>{original.customer.name}</span>
                                <i className={'d-block fs-9 second-text'}>{original.customer.inn || original.customer.pinfl}</i>
                            </div>
                        </div>}
                    </Fragment>
                )
            }
        },
        {
            accessor: 'contracts',
            Header: t(lang, "roaming.verification_act.datatable.header.row.contracts"),
            Cell: ({row: {original}}) => {
                return (
                    original?.contracts?.map((contract, index) => {
                        if(VIEW_DRAFT !== typeViewer) {
                            return (
                                <span key={index} className={'second-text fw-light'}>
                                    {!!index && <br/>}{t(lang, "roaming.verification_act.datatable.body.row.contracts", {
                                    name: contract?.contract_no,
                                    date: dayjs(contract?.contract_date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                                })}
                                </span>
                            )
                        } else {
                            return (
                                <span key={index} className={'second-text fw-light'}>
                                    {!!index && <br/>}{t(lang, "roaming.verification_act.datatable.body.row.contracts", {
                                    name: contract?.number,
                                    date: dayjs(contract?.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                                })}
                                </span>
                            )
                        }
                    })
                )
            }
        },
        {
            accessor: 'total_sum',
            Header:  t(lang, "roaming.verification_act.datatable.header.row.total_sum"),
            cellProps: {
              className: 'text-end pe-5'
            },
            Cell: ({row: {original}}) => {
                if(original?.executor?.inn === activeOrganizationPinflOrInn){
                    return (
                        <strong>
                            {original?.close_balance?.executor_debit
                                ? `${t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.owner.debit")} ${numeral.formats[numberFormat].format((original?.close_balance?.executor_debit || 0))}`
                                : `${t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.owner.credit")} ${numeral.formats[numberFormat].format((original?.close_balance?.executor_credit || 0))}`
                            }
                            <span className={'ms-1 second-text fs-9'}>UZS</span>
                        </strong>
                    )
                } else {
                    return (
                        <strong>
                            {original?.close_balance?.customer_debit
                                ? `${t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.owner.debit")} ${numeral.formats[numberFormat].format((original?.close_balance?.customer_debit || 0))}`
                                : `${t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.owner.credit")} ${numeral.formats[numberFormat].format((original?.close_balance?.customer_credit || 0))}`
                            }
                            <span className={'ms-1 second-text fs-9'}>UZS</span>
                        </strong>
                    )
                }
            }
        },
        (VIEW_DRAFT !== typeViewer && {
            accessor: 'status',
            Header: t(lang, "roaming.verification_act.datatable.header.row.status"),
            Cell: ({row: {original}}) => {
                const verificationActWrapper = new VerificationActWrapper(original)

                return <div className={'d-inline-block text-center'}>
                        <Badge
                            bg={verificationActWrapper.getRoamingStatusVariant(activeOrganization.inn, activeOrganization.pinfl)}
                            variant={"phoenix"}
                            className={'px-3 py-1 fs-9 text-uppercase w-100 text-decoration-none fw-medium border-0 rounded-4'}
                        >
                            {t(lang, verificationActWrapper.getRoamingStatus(activeOrganization.inn, activeOrganization.pinfl))}
                        </Badge>
                    </div>
            }
        }),
        {
            accessor: 'action',
            Header: "",
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                        <RevealDropdown>
                            <Dropdown.Item
                                onClick={(event) => {
                                    openViewModalPage(event, original.id)
                                }}>
                                <span>{t(lang, "roaming.invoices.table_header.show")}</span>
                            </Dropdown.Item>
                        </RevealDropdown>
                    </RevealDropdownTrigger>
                );
            }
        },
    ].filter(i => i);

    const onPageChange = async (page) => {
        dispatch(changeFilterOptionsAsync({...filters, page: page}))
    }

    const onLimitChange = async (limit) => {
        dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}))
    }

    useEffect(() => {
        let params = {...filters, type_viewer: typeViewer, viewer_inn: activeOrganizationPinflOrInn}
        dispatch(loadRoamingVerificationActsAsync(params));
        dispatch(loadRoamingVerificationActsCountAsync({...params}))
    }, [filters, activeOrganization, typeViewer]);

    return (
        <Fragment>
            <VerificationActDataTableHeader table/>

            <AdvanceTableWrapper
                columns={columns}
                data={roamingVerificationActs}
                pagination
                perPage={1000}
                selection
            >
                <Card className={'p-0 rounded-4'}>
                    <TypeViewerAction to={'/roaming/verification-acts'} typeViewer={typeViewer} />
                    <Card.Body className={'p-0'}>
                        <AdvanceTable
                            table
                            headerClassName="text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                </Card>

                <AdvanceTableCustomPagination
                    count={count}
                    limit={filters.limit}
                    page={filters.page}
                    onLimitChange={onLimitChange}
                    onPageChange={onPageChange}
                />
            </AdvanceTableWrapper>

            <ViewModalPage show={showModal} onHide={onHideModalPage}>
                {id && <ViewVerificationAct modalId={id} />}
                {draftId && <ViewDraftVerificationAct modalId={draftId} />}
            </ViewModalPage>

        </Fragment>
    );
};

export default VerificationActDataTable;
