import React, {Fragment, useEffect, useState} from 'react';
import {
    changeWaybillsFilterOptions,
    selectWaybillsCount
} from "../../../store/reducers/roaming/waybill/roamingWaybillReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {FormProvider, useForm} from "react-hook-form";
import {Translate} from "../../../lang/lang";
import {faFilter, faPlus, faRefresh} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../IconButton";
import checkPermission from "../../../enum/permission/CheckPermission";
import {PermissionRoamingShipmentCreate} from "../../../enum/permission/RoamingPermission";
import {Link, useNavigate} from "react-router-dom";
import toastPermission from "../../../enum/permission/ToastPermission";
import {Col, Collapse, Form, Row} from "react-bootstrap";
import Badge from "../../common/Badge";
import WaybillDataTableFilter from "./WaybillDataTableFilter";
import {scrollEvent} from "../../../helpers/scrollEvent";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import IconFilter from "../../../assets/icon/IconFilter";
import IconStroke from "../../../assets/icon/IconStroke";
import IconCaretDown from "../../../assets/icon/IconCaretDown";
import IconPlus from "../../../assets/icon/IconPlus";

const WaybillDataTableHeader = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const waybillCount = useSelector(selectWaybillsCount)
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const methods = useForm();
    const t = Translate;

    const toggleCollapse = () => setIsCollapsed(!isCollapsed);
    const onSubmitFilter = (formData) => {
        dispatch(changeWaybillsFilterOptions({
            status: formData.status
        }))
    }

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('roaming-waybill-data-table-header'))
        return () => window.removeEventListener('scroll', () => scrollEvent('roaming-waybill-data-table-header'))
    }, []);

    return (
        <Fragment>
            <Row id={'roaming-waybill-data-table-header'} className="flex-between-center align-items-center my-3 position-sticky z-index-1" style={{top: '4rem'}}>
                <Collapse in={isCollapsed}>
                    <Col sm={12}>
                        <FormProvider {...methods}>
                            <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                                <WaybillDataTableFilter/>
                            </Form>
                        </FormProvider>
                    </Col>
                </Collapse>

                <Col cols={'auto'} className={'d-flex gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'} style={{width: 35, height: 35}}>
                        <IconArrowLeft />
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.waybill.datatable.title")}: {waybillCount}</h5>
                        <span className={'second-text fs-9'}>{t(lang, 'edi.navbar.routes.documents')}</span>
                    </div>
                </Col>

                <Col xs={8} sm="auto" className={'d-flex gap-2'}>

                    <IconButton
                        size="sm"
                        icon={faRefresh}
                        transform="shrink-3"
                        variant="phoenix-secondary"
                        className="btn-davr px-3 py-0 bg-white"
                        iconClassName={'fs-7 text-primary-davr'}
                        onClick={methods.handleSubmit(onSubmitFilter)}
                    >
                            <span className="d-none d-sm-inline-block ms-1 fw-semi-bold">
                                {t(lang, "roaming.waybill.datatable.header.navigation.refresh")}
                            </span>
                    </IconButton>

                    <IconButton
                        onClick={toggleCollapse}
                        className="btn-davr ps-2 pe-1 py-0 bg-white text-dark gap-2"
                    >
                        <div className={'d-flex align-items-center'}>
                            <IconFilter />
                            <span className="d-none d-sm-inline-block ms-1 fs-8 text-nowrap fs-md-9">{t(lang, "roaming.invoices.table_header.filter")}</span>
                        </div>
                        <div className={'d-flex justify-content-between gap-2'}>
                            <IconStroke />
                            <IconCaretDown />
                        </div>
                    </IconButton>

                    <IconButton
                     as={Link}
                        to="/roaming/waybill/send"
                        className="btn-davr px-3 py-0 bg-primary-davr"
                        variant="phoenix-secondary"
                        size="sm"
                        transform="shrink-3"
                    >
                        <IconPlus />
                        <span className="d-none d-sm-inline-block ms-1 fs-8 text-white text-nowrap fs-md-9">{t(lang, "edi.common.button.create")}</span>
                    </IconButton>

                </Col>
            </Row>
        </Fragment>
    );
};

export default WaybillDataTableHeader;
