import React, {Fragment} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {LangOptions, Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import {updateLangSettingsAsync} from "../../../store/reducers/settings/settingsReducer";
import {FloatingLabel, Form, Card} from "react-bootstrap";
import {useAppContext} from "../../../pheonix/providers/AppProvider";

const ChangeLanguage = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch();

    const onChangeLang = async lang => {
        const payload = {
            lang: LangOptions.map(l => {
                return {
                    code: l,
                    is_active: l === lang,
                }
            })
        }

        dispatch(updateLangSettingsAsync({payload}))
    }

    const {
        config: {theme},
        toggleTheme
    } = useAppContext();

    return (
        <Fragment>
            <h4 className={'fs-8 fw-semi-bold second-text'}>{t(lang, 'hippo.user.settings.profile.lang')}, Тема</h4>
            <Card.Footer className="bg-card p-2 rounded-2 d-flex flex-column gap-2 h-100">
                <FloatingLabel label={t(lang, 'hippo.user.settings.profile.lang')}>
                    <Form.Select size="sm"
                                 option={LangOptions}
                                 value={lang}
                                 onChange={(e) => onChangeLang(e.target.value)}
                    >
                        {LangOptions.map((option, index) => {
                            return <option value={option} key={index}>{option}</option>
                        })}
                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel label={t(lang, 'Тема')}>
                    <Form.Select size="sm"
                                 value={theme}
                                 onChange={(e) => toggleTheme()}
                    >
                        <option value={"light"}>Light</option>
                        <option value={"dark"}>Dark</option>
                    </Form.Select>
                </FloatingLabel>
            </Card.Footer>
        </Fragment>
    );
};

export default ChangeLanguage;
