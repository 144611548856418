import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {loginAsync, selectIsAuthorized} from "../../../store/reducers/auth/authReducer";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import EventBus from "../../../eventbus/EventBus";
import {REGISTRATION_SUCCESS} from "../../../eventbus/authEvents";
import {useNavigate} from "react-router-dom";
import {Card} from "react-bootstrap";
import RegistrationForm from "../../../components/form/auth/register/RegistrationForm";
import toast from "react-hot-toast";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";

const Register = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isAuthorized = useSelector(selectIsAuthorized);
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        const onRegisterSuccessHandler = EventBus.on(REGISTRATION_SUCCESS, ({username, password}) => {
            toast.success(`You have success registered`);
            toast.success(`Welcome to Hippo`);

            dispatch(loginAsync(username, password));
        });

        return () => {
            EventBus.remove(REGISTRATION_SUCCESS, onRegisterSuccessHandler);
        };
    }, []);

    return (
        <Card className={'w-100 h-100 overflow-hidden rounded-5 p-3'}>
            <RegistrationForm header={
                <div className={'position-relative'}>
                    <button onClick={() => navigate(-1)}
                            className={'main-text btn-back bg-secondary-davr position-absolute'}
                            style={{width: 35, height: 35, left: 0, bottom: 0}}>
                        <IconArrowLeft/>
                    </button>
                    <h5 className={'text-center text-dark'}>{t(lang, "authorization.register.title")}</h5>
                    <p className={'text-center fs-9 second-text'}>Через E-IMZO</p>
                </div>
            }/>
        </Card>
    );
};

export default Register;
