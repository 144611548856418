import React, {Fragment, useEffect, useState} from 'react';
import {Translate} from "../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {Button, Col, Modal, OverlayTrigger, Row, Tooltip, Form} from "react-bootstrap";
import IconButton from "../IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import Divider from "./Divider";
import SelectTasnifCatalog from "./SelectTasnifCatalog";
import SelectOrganizationCatalog from "./SelectOrganizationCatalog";
import SelectExactCatalog from "./SelectExactCatalog";
import FalconCloseButton from "./FalconCloseButton";
import classNames from "classnames";
import {selectCompanyCatalogs} from "../../store/reducers/roaming/roamingReducer";

const CatalogLinkSelector = ({defaultCatalog, onChange, className, linkProps, textButton, ...props}) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const companyCatalogs = useSelector(selectCompanyCatalogs);
    const [show, setShow] = useState(false);
    const [catalog, setCatalog] = useState(null);

    useEffect(() => {
        if (textButton) {
            setCatalog(companyCatalogs.find(i => i.class_code === defaultCatalog));
        } else {
            if (catalog?.class_code !== defaultCatalog?.class_code)
                setCatalog(defaultCatalog);
        }
    }, [defaultCatalog, companyCatalogs])

    const link = <span onClick={() => setShow(true)}
                       className={className ? className : 'text-dark cursor-pointer fs--1'}
                       {...linkProps}
    >
        {catalog ? `${catalog.class_code}` : t(lang, 'items.common.click_to_select_catalog_code')}
    </span>

    const onChangeHandler = (option) => {
        setCatalog(option);
        onChange(option);
        option && setShow(false);
    }

    return (
        <Fragment>
            {!textButton ?
                <Fragment>
                    {catalog && <OverlayTrigger overlay={catalog?.name && <Tooltip>{catalog.name}</Tooltip>}>
                        {link}
                    </OverlayTrigger>}
                    {!catalog && link}
                </Fragment> :
                <Button variant="phoenix-secondary"
                        className={classNames('w-100', className)} {...props}
                        onClick={() => setShow(true)}
                >
                    {catalog ? catalog?.class_code : textButton || t(lang, 'edi.common.button.select')}
                </Button>
            }
            <Modal show={show}
                   className={'davr-modal'}
                   onHide={() => setShow(false)}
                   size="xl"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <Modal.Header>
                    <Modal.Title>{t(lang, 'items.common.catalog_link_selector_modal.title')}</Modal.Title>
                    <FalconCloseButton onClick={() => setShow(false)}/>
                </Modal.Header>
                <Modal.Body>
                    <Row className="gy-2">
                        <Col xs={12}>
                            <Form.Group>
                                <SelectOrganizationCatalog
                                    placeholder={t(lang, 'items.common.catalog_link_selector.organization_catalogs')}
                                    onChange={onChangeHandler}/>
                            </Form.Group>
                        </Col>
                        <Col xs={12}>
                            <Form.Group>
                                <SelectTasnifCatalog
                                    placeholder={t(lang, 'items.common.catalog_link_selector.tasnif_catalogs')}
                                    onChange={onChangeHandler}/>
                            </Form.Group>
                        </Col>
                        <Col xs={12}>
                            <Form.Group>
                                <SelectExactCatalog
                                    placeholder={t(lang, 'items.common.catalog_link_selector.exact_catalog')}
                                    onChange={onChangeHandler}/>
                            </Form.Group>
                        </Col>
                    </Row>


                    {catalog &&
                        <>
                            <Divider/>
                            <Row>
                                <Col xs={12}>
                                    <h5 className="text-info">{t(lang, 'items.common.catalog_link_selector.chosen_catalog')}</h5>
                                    <div className="mt-3 fw-bold fs--1">
                                        <span className="text-primary">{catalog.class_code} - </span>
                                        <span className="text-dark">{catalog.name}</span>
                                        <IconButton
                                            variant="phpenix-secondary"
                                            size="sm"
                                            icon={faTrash}
                                            iconClassName={"text-danger"}
                                            className="ms-2 btn-white-davr"
                                            title={t(lang, 'items.common.delete')}
                                            onClick={() => onChangeHandler(null)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button className={'btn-primary-davr'} onClick={() => setShow(false)}>
                        {t(lang, 'items.common.cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default CatalogLinkSelector;
