import React from 'react';

const IconAlertWarning = ({width = 8, height = 13, color = "none"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 8 13" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.14648 3.57373C1.31728 3.04744 1.6304 2.57872 2.05103 2.21924C2.47166 1.85976 2.98331 1.62375 3.52979 1.53705C4.07626 1.45035 4.63646 1.51652 5.14771 1.72815C5.65895 1.93978 6.10121 2.28875 6.42651 2.73633C6.75182 3.18391 6.94713 3.71256 6.99061 4.26416C7.0341 4.81576 6.92384 5.36854 6.67269 5.86157C6.42153 6.3546 6.03937 6.76886 5.56761 7.05798C5.09585 7.34711 4.55331 7.50013 4 7.50013V8.24987M4.03735 11.25V11.325L3.96265 11.3251V11.25H4.03735Z"
                stroke="#FFA500" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default IconAlertWarning;
