import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {useParams} from "react-router-dom";
import {
    changeFreeFormsFilterOptions,
    loadFreeFormReceiverDocumentsAsync,
    loadFreeFormReceiverDocumentsCountAsync,
    loadFreeFormSenderDocumentsAsync,
    loadFreeFormSenderDocumentsCountAsync,
    selectFreeFormDocumentsFilterOptions
} from "../../../store/reducers/roaming/free-form/freeFormDocumentReducer";
import {faArrowDown, faArrowUp} from "@fortawesome/free-solid-svg-icons";
import TypeViewerAction from "../../../components/common/TypeViewerAction";
import FreeFormDocumentDataTable from "../../../components/roaming/free-form/FreeFormDocumentDataTable";

const RoamingFreeForms = () => {
    const dispatch = useDispatch();
    const t = Translate;
    const lang = useSelector(selectLang);
    const filter = useSelector(selectFreeFormDocumentsFilterOptions);
    const activeOrganization = (useSelector(selectActiveOrganization));
    const {viewType} = useParams();

    useEffect(() => {
        loadFreeFormDocuments();
    }, [filter, activeOrganization, viewType])

    useEffect(() => {
        onSwitchViewType(viewType)
    }, [viewType])

    const loadFreeFormDocuments = () => {
        switch (viewType) {
            case RoamingFreeForms.ViewTypeInbox:
                dispatch(loadFreeFormReceiverDocumentsAsync({...filter}));
                dispatch(loadFreeFormReceiverDocumentsCountAsync({...filter}));
                break;

            case RoamingFreeForms.ViewTypeOutbox:
                dispatch(loadFreeFormSenderDocumentsAsync({...filter}));
                dispatch(loadFreeFormSenderDocumentsCountAsync({...filter}));
                break;
        }
    }

    const onSwitchViewType = vt => {
        dispatch(changeFreeFormsFilterOptions({limit: filter.limit, page: 1}));
    }

    return (
        <Fragment>

            <FreeFormDocumentDataTable viewType={viewType}/>
        </Fragment>
    );
};

RoamingFreeForms.ViewTypeInbox = 'inbox'
RoamingFreeForms.ViewTypeOutbox = 'outbox'

export default RoamingFreeForms;
