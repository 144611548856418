import React, {Fragment, useEffect, useState} from 'react';
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {selectAccountRolePermissions} from "../../../store/reducers/role/roleReducer";
import checkPermission from "../../../enum/permission/CheckPermission";
import {PermissionRoamingEmpowermentView} from "../../../enum/permission/RoamingPermission";
import {loadDraftEmpowerment} from "../../../store/reducers/roaming/empowerment/empowermentReducer";
import {VIEWER_CUSTOMER} from "../../../enum/roaming/empowerment/EmpowermentStatus";
import SpinnerComponent from "../../../components/common/SpinnerComponent";
import Empowerment from "../../../components/roaming/empowerments/Empowerment";

const DraftEmpowerment = ({modalId}) => {
    const [draftEmpowerment, setDraftEmpowerment] = useState(null)
    const [draftEmpowermentJson, setDraftEmpowermentJson] = useState(null)
    const activeOrganization = useSelector(selectActiveOrganization)
    const {id} = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingEmpowermentView))) {
                navigate(-1)
        }
    }, [permission])

    useEffect(() => {
        loadEmpowerment()
    }, [modalId, id, activeOrganization])

    const loadEmpowerment = async () => {
        try {
            const getDraft = await loadDraftEmpowerment(modalId ? modalId : id)
            setDraftEmpowerment(JSON.parse(getDraft?.data?.content))
            setDraftEmpowermentJson(getDraft?.data?.content)
        }catch (error) {
            navigate(`/roaming/empowerments/${VIEWER_CUSTOMER}`)
        }
    }

    const empowerment = {
        id: draftEmpowerment?.EmpowermentId,
        product_id: draftEmpowerment?.ProductList?.EmpowermentProductId,
        agent_empowerment_id: draftEmpowerment?.Agent?.AgentEmpowermentId,
        info: {
            number: draftEmpowerment?.EmpowermentDoc?.EmpowermentNo,
            start: draftEmpowerment?.EmpowermentDoc?.EmpowermentDateOfIssue,
            end: draftEmpowerment?.EmpowermentDoc?.EmpowermentDateOfExpire,
        },
        customer: {
            inn: draftEmpowerment?.BuyerTin,
            name: draftEmpowerment?.Buyer?.Name,
            director: draftEmpowerment?.Buyer?.Director,
            accountant: draftEmpowerment?.Buyer?.Accountant,
        },
        executor: {
            inn: draftEmpowerment?.SellerTin,
            name: draftEmpowerment?.Seller?.Name,
            director: draftEmpowerment?.Seller?.Director,
            accountant: draftEmpowerment?.Seller?.Accountant,
        },
        person_info: {
            full_name: draftEmpowerment?.Agent?.Fio,
            person_inn: draftEmpowerment?.Agent?.AgentTin,
        },
        contract: {
            date: draftEmpowerment?.ContractDoc?.ContractDate,
            number: draftEmpowerment?.ContractDoc?.ContractNo,
        },
        items: draftEmpowerment?.ProductList?.Products.map(item => {
            return {
                ordering_number: item?.OrdNo,
                name: item?.Name,
                quantity: item?.Count,
                measure_id: item?.MeasureId,
                catalog: {
                    code: item?.CatalogCode,
                    name: item?.CatalogName,
                }
            }
        })
    }

    return (
        <Fragment>
            {draftEmpowerment && <Empowerment modalId={modalId} empowerment={empowerment} draftEmpowermentJson={draftEmpowermentJson}/>}
            {!draftEmpowerment && <SpinnerComponent />}
        </Fragment>
    );
};

export default DraftEmpowerment;
