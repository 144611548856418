import React, {Fragment} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectDateFormat, selectLang} from "../../../store/reducers/main/main";
import {Controller, useFormContext} from "react-hook-form";
import {Col, Row, Form, FloatingLabel} from "react-bootstrap";
import RoamingInvoiceFormTypeSelector from "./RoamingInvoiceFormTypeSelector";
import DatePicker from "react-datepicker";
import RoamingInvoiceFormOldInvoiceInfo from "./RoamingInvoiceFormOldInvoiceInfo";
import classNames from "classnames";
import DatePickerComponent from "../../common/DatePickerComponent";

const RoamingInvoiceFormCardInfo = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();

    return (
        <Fragment>
            <Row className={'align-items-start'}>
                <Col xs={12} className={'my-2'}>
                    <Form.Group>
                        <Controller
                            name={`facturaType`}
                            rules={{required: t(lang, 'items.common.validations.is_required')}}
                            render={({field, fieldState: {error}}) => (
                                <RoamingInvoiceFormTypeSelector
                                    id={'facturaType'}
                                    defaultType={field.value}
                                    onChange={field.onChange}
                                    placeholder={`${t(lang, 'roaming.invoice.type')} *`}
                                    selectProps={{
                                        menuPortalTarget: document.body,
                                        styles: {
                                            control: base => ({
                                                ...base,
                                                border: error?.message && '1px solid #e63757',
                                            }),
                                            container: (provided) => ({
                                                ...provided,
                                                margin: '-1px'
                                            }),
                                            menuPortal: (provided) => ({
                                                ...provided,
                                                zIndex: 9999
                                            })
                                        }
                                }}
                                />
                            )}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={3}>
                    <Form.Group>
                        <Controller
                            name={`facturaDoc.facturaDate`}
                            rules={{required: t(lang, 'items.common.validations.is_required'),}}
                            render={({field, fieldState: {error}}) => (
                                    <DatePickerComponent
                                        field={field}
                                        placeholderText={`${t(lang, "roaming.invoice.date")} *`}
                                        isClearable={true}
                                        className={classNames('form-control date-picker', {
                                            'is-invalid': form.formState.errors?.facturaDoc?.facturaDate,
                                        })}
                                        wrapperClassName={classNames({
                                            'is-invalid': form.formState.errors?.facturaDoc?.facturaDate
                                        })}
                                    />
                            )}
                        />
                        <Form.Control.Feedback type="invalid">{form.formState.errors?.facturaDoc?.facturaDate?.message}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col xs={12} md={3}>
                    <Form.Group>
                        <FloatingLabel className={'text-lowercase'} label={`${t(lang, "roaming.invoice.number")} *`}>
                            <Form.Control
                                placeholder={`${t(lang, "roaming.invoice.number")} *`}
                                name={`facturaDoc.facturaNo`}
                                isInvalid={form.formState.errors?.facturaDoc?.facturaNo}
                                {...form.register(`facturaDoc.facturaNo`, {
                                    required: t(lang, 'items.common.validations.is_required'),
                                })}
                            />
                        </FloatingLabel>

                        <Form.Control.Feedback type="invalid">{form.formState.errors?.facturaDoc?.facturaNo?.message}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                {/*<Col xs={12} md={3}>
                    <Form.Group hidden>
                        <Form.Label>{t(lang, 'roaming.invoice.id')}</Form.Label>
                        <Form.Control name={`facturaId`}
                                      {...form.register(`facturaId`)}
                        />
                    </Form.Group>
                </Col>*/}
                <Col xs={12} md={3}>
                    <Form.Group>
                        <FloatingLabel label={`${t(lang, "roaming.contract.number")} *`}>
                            <Form.Control
                                placeholder={`${t(lang, "roaming.contract.number")} *`}
                                name={`contractDoc.contractNo`}
                                isInvalid={form.formState.errors?.contractDoc?.contractNo}
                                {...form.register(`contractDoc.contractNo`, {
                                    required: t(lang, 'items.common.validations.is_required'),
                                })}
                            />
                        </FloatingLabel>
                        <Form.Control.Feedback type="invalid">{form.formState.errors?.contractDoc?.contractNo?.message}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col xs={12} md={3}>
                    <Form.Group>
                        <Controller
                            name={`contractDoc.contractDate`}
                            rules={{required: t(lang, 'items.common.validations.is_required'),}}
                            render={({field}) => (
                                <DatePickerComponent
                                    field={field}
                                    placeholderText={`${t(lang, "roaming.contract.date")} *`}
                                    isClearable
                                    className={classNames('form-control date-picker', {
                                        'is-invalid': form.formState.errors?.contractDoc?.contractDate,
                                    })}
                                    wrapperClassName={classNames({
                                        'is-invalid': form.formState.errors?.contractDoc?.contractDate
                                    })}
                                />
                            )}
                        />
                        <Form.Control.Feedback type="invalid">{form.formState.errors?.contractDoc?.contractDate?.message}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <RoamingInvoiceFormOldInvoiceInfo/>
        </Fragment>
    );
};

export default RoamingInvoiceFormCardInfo;
