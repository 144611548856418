import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectAccountRolePermissions} from "../../../store/reducers/role/roleReducer";
import checkPermission from "../../../enum/permission/CheckPermission";
import {PermissionRoamingShipmentCreate} from "../../../enum/permission/RoamingPermission";
import {getOrganizationBillingInfoAsync} from "../../../store/reducers/billing/billingReducer";
import EventBus from "../../../eventbus/EventBus";
import {SAVE_DRAFT_WAYBILL_SUCCEED, SEND_WAYBILL_SUCCEED} from "../../../eventbus/romaing/waybill/roamingWaybillEvents";
import SendWaybillForm from "../../../components/roaming/waybill/send/SendWaybillForm";

const SendWaybill = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingShipmentCreate))) {
            navigate(-1)
        }
    }, [permission])

    useEffect(() => {
        const onSendWaybillSucceedHandler = EventBus.on(SEND_WAYBILL_SUCCEED, waybill => {
            navigate(`/roaming/waybill/${waybill.id}`)
            dispatch(getOrganizationBillingInfoAsync());
        });

        const onSaveDraftWaybillSucceedHandler = EventBus.on(SAVE_DRAFT_WAYBILL_SUCCEED, draftWaybill => {
            navigate(`/roaming/waybill/draft/${draftWaybill.id}`)
        })

        return () => {
            EventBus.remove(SEND_WAYBILL_SUCCEED, onSendWaybillSucceedHandler);
            EventBus.remove(SAVE_DRAFT_WAYBILL_SUCCEED, onSaveDraftWaybillSucceedHandler);
        }
    }, [])

    return (
        <SendWaybillForm />
    );
};

export default SendWaybill;
