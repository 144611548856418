import React, {Fragment} from 'react';
import {selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {useFieldArray} from "react-hook-form";
import {Translate} from "../../../../lang/lang";
import {Card, Table} from "react-bootstrap";
import ItemRowV2 from "./ItemRowV2";
import IconButton from "../../../IconButton";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import Cleave from "cleave.js/react";
const WaybillItemsV2Form = ({isEditing, content}) => {

    const lang = useSelector(selectLang);
    const t = Translate;

    const {fields, append, remove, replace} = useFieldArray({name: 'items'});
    const allowDeleteRow = fields.length > 1;

    const addNewItem = () => {
        append({
            name: '',
            catalog_code: '',
            catalog_name: '',
            measurement: '',
            price: '',
            quantity: '',
            total: '',
        });
    }

    const addItemsInBulk = (items) => {
        if (!items?.length) {
            return false;
        }

        // reset waybill items
        replace([]);

        for (let i = 0; i < items?.length; i++) {
            const item = items[i];

            const itemPrice = item?.price ? Number(item.price.replace(/\s/g, '')?.replace(/,/g, '.')) : 0;
            const itemQuantity = item?.quantity ? Number(item.quantity.replace(/\s/g, '')?.replace(/,/g, '.')) : 0;
            const itemTotal = itemPrice * itemQuantity;

            const newItem = {
                name: item?.name || '',
                catalog_code: item?.catalogCode || '',
                catalog_name: item?.catalogName || '',
                package_code: item?.packageCode || '',
                measurement: item?.measurement || '',
                price: itemPrice,
                quantity: itemQuantity,
                total: itemTotal,
            };
            append(newItem);
        }
    };

    return (
        <Fragment>
            <h4 className={'fs-8 fw-medium second-text mt-3'}>{t(lang, 'roaming.waybill.send.shipment_items_form.title')}</h4>
            <Card className={'py-1 px-2 rounded-4 border-0'}>
                <Table responsive className="scrollbar view-table border-900 mb-0 fs-9 second-text fw-semi-bold">
                    <thead className="text-dark border-bottom">
                    <tr>
                        <th>#</th>
                        <th scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.product')}<span
                            className="text-primary-davr">*</span></th>
                        <th scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.catalog_code')}<span
                            className="text-primary-davr">*</span></th>
                        <th scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.measurement')}<span
                            className="text-primary-davr">*</span></th>
                        <th scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.quantity')}<span
                            className="text-primary-davr">*</span></th>
                        <th scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.price')}<span
                            className="text-primary-davr">*</span></th>
                        <th scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.total')}</th>
                        <th scope="col" className={'text-center p-1 align-middle'}>
                            <IconButton icon={faPlus}
                                        variant={'phoenix-primary'}
                                        onClick={addNewItem}
                                        className={'bg-primary-davr px-2 py-2 fs-8 rounded-3 cursor-pointer'}
                                        iconClassName={'text-white'}
                            />
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {fields.map((item, index) => (
                        <ItemRowV2
                            key={item.id}
                            index={index}
                            remove={remove}
                            allowDelete={allowDeleteRow}
                            isEditing={isEditing}
                            content={content}
                        />
                    ))}
                    </tbody>
                </Table>
            </Card>
        </Fragment>
    );
};

export default WaybillItemsV2Form;
