import React, {Fragment, useEffect} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {selectCurrency} from "../../../store/reducers/currency/currencyReducer";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {InputGroup, Form} from "react-bootstrap";
import CatalogLinkSelector from "../../common/CatalogLinkSelector";
import classNames from "classnames";
import OriginLinkSelector from "../../common/OriginLinkSelector";
import {loadCatalogByCode} from "../../../store/reducers/roaming/roamingReferenceReducer";
import RoamingInvoiceFormItemMarkLinkSelector from "./RoamingInvoiceFormItemMarkLinkSelector";
import SelectItemModal from "../../common/SelectItemModal";
import RoamingInvoiceFormItemRowCommittent from "./RoamingInvoiceFormItemRowCommittent";
import CatalogPackageLinkSelector from "../../common/CatalogPackageLinkSelector"
import Cleave from "cleave.js/react";
import {getNumberFixedValue, getNumberValue} from "../../../helpers/utils";
import currencyWrapper, {UZS} from "../../../enum/currency/currencyWrapper";
import VatRateLinkSelector from "../../common/VatRateLinkSelector";
import BenefitLinkSelector from "../../common/BenefitLinkSelector";
import WarehouseLinkSelector from "../../common/WarehouseLinkSelector";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import MeasurementLinkSelector from "../../common/MeasurementLinkSelector";
import Badge from "../../common/Badge";

const ROUND_SCALE_FOUR = 4;
const ROUND_SCALE_TWO = 2;

const RoamingInvoiceFormItemRow = ({
                                       hasMarking,
                                       hasMedical,
                                       hasExcise,
                                       hasCommittent,
                                       manualCalculation,
                                       reversedCalculation,
                                       insertNewItem,
                                       deleteItem,
                                       allowDeleteItem,
                                       fieldName,
                                       index,
                                       sellerCompanyPrivileges,
                                       turnoverCompanyPrivileges,
                                   }) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const currencies = useSelector(selectCurrency);
    const item = useWatch({name: `${fieldName}`});
    const form = useFormContext();
    const {error} = form.getFieldState(fieldName, form.formState);
    let lgotas = item.catalog?.lgotas || [];

    useEffect(() => {
        if (manualCalculation) return;
        //TODO: implement use count logic

        let shouldEnableManualCalculation = false
        if (reversedCalculation) {
            if (hasMedical) {
                if (hasExcise) {
                    const deliverySum = getNumberValue(item.deliverySumWithVat) * 100 / (100 + getNumberValue(item.vatRate));
                    const vatSum = getNumberValue(item.deliverySumWithVat) - getNumberValue(deliverySum);
                    const exciseSum = getNumberValue(deliverySum) * getNumberValue(item.exciseRate) / 100;
                    const deliverySumWithoutExcise = getNumberValue(deliverySum) - getNumberValue(exciseSum);

                    form.setValue(`${fieldName}.deliverySum`, getNumberFixedValue(deliverySum, ROUND_SCALE_TWO));
                    form.setValue(`${fieldName}.vatSum`, getNumberFixedValue(vatSum, ROUND_SCALE_TWO));
                    form.setValue(`${fieldName}.exciseSum`, getNumberFixedValue(exciseSum, ROUND_SCALE_FOUR));
                    if (getNumberValue(item.count) !== 0) {
                        const summa = getNumberValue(deliverySumWithoutExcise) / getNumberValue(item.count);
                        const basePrice = getNumberValue(summa) * (1 - getNumberValue(item.profitRate) / 100);

                        form.setValue(`${fieldName}.summa`, getNumberFixedValue(summa, ROUND_SCALE_FOUR));
                        form.setValue(`${fieldName}.baseSumma`, getNumberFixedValue(basePrice, ROUND_SCALE_FOUR));
                    }
                } else {
                    const deliverySum = getNumberValue(item.deliverySumWithVat) * 100 / (100 + getNumberValue(item.vatRate));
                    const vatSum = getNumberValue(item.deliverySumWithVat) - getNumberValue(deliverySum);

                    form.setValue(`${fieldName}.deliverySum`, getNumberFixedValue(deliverySum, ROUND_SCALE_TWO));
                    form.setValue(`${fieldName}.vatSum`, getNumberFixedValue(vatSum, ROUND_SCALE_TWO));
                    if (getNumberValue(item.count) !== 0) {
                        const summa = getNumberValue(deliverySum) / getNumberValue(item.count);
                        const basePrice = getNumberValue(summa) * (1 - getNumberValue(item.profitRate) / 100);

                        form.setValue(`${fieldName}.summa`, getNumberFixedValue(summa, ROUND_SCALE_FOUR));
                        form.setValue(`${fieldName}.baseSumma`, getNumberFixedValue(basePrice, ROUND_SCALE_FOUR));
                    }
                }
            } else {
                if (hasExcise) {
                    const deliverySum = getNumberValue(item.deliverySumWithVat) * 100 / (100 + getNumberValue(item.vatRate));
                    const vatSum = getNumberValue(item.deliverySumWithVat) - getNumberValue(deliverySum);
                    const exciseSum = getNumberValue(deliverySum) * getNumberValue(item.exciseRate) / 100;
                    const deliverySumWithoutExcise = getNumberValue(deliverySum) - getNumberValue(exciseSum);

                    form.setValue(`${fieldName}.deliverySum`, getNumberFixedValue(deliverySum, ROUND_SCALE_TWO));
                    form.setValue(`${fieldName}.vatSum`, getNumberFixedValue(vatSum, ROUND_SCALE_TWO));
                    form.setValue(`${fieldName}.exciseSum`, getNumberFixedValue(exciseSum, ROUND_SCALE_FOUR));
                    if (getNumberValue(item.count) !== 0) {
                        const summa = getNumberValue(deliverySumWithoutExcise) / getNumberValue(item.count);
                        form.setValue(`${fieldName}.summa`, getNumberFixedValue(summa, ROUND_SCALE_FOUR));
                    }
                } else {
                    const deliverySum = getNumberValue(item.deliverySumWithVat) * 100 / (100 + getNumberValue(item.vatRate));
                    const vatSum = getNumberValue(item.deliverySumWithVat) - getNumberValue(deliverySum);

                    form.setValue(`${fieldName}.deliverySum`, getNumberFixedValue(deliverySum, ROUND_SCALE_TWO));
                    form.setValue(`${fieldName}.vatSum`, getNumberFixedValue(vatSum, ROUND_SCALE_TWO));
                    if (getNumberValue(item.count) !== 0) {
                        const summa = getNumberValue(deliverySum) / getNumberValue(item.count);
                        form.setValue(`${fieldName}.summa`, getNumberFixedValue(summa, ROUND_SCALE_FOUR));
                    }
                }
            }
        } else {
            if (hasMedical) {
                const summa = getNumberValue(item.baseSumma) * (1 + getNumberValue(item.profitRate) / 100);
                if (hasExcise) {
                    const exciseSum = getNumberValue(item.count) * getNumberValue(item.summa) * getNumberValue(item.exciseRate) / 100;
                    const deliverySumWithoutExcise = getNumberValue(item.count) * getNumberValue(item.summa);
                    const deliverySum = getNumberValue(deliverySumWithoutExcise) + getNumberValue(exciseSum);
                    const vatSum = getNumberValue(deliverySum) * getNumberValue(item.vatRate) / 100;
                    const deliverySumWithVat = getNumberValue(deliverySum) + getNumberValue(vatSum);

                    form.setValue(`${fieldName}.exciseSum`, getNumberFixedValue(exciseSum, ROUND_SCALE_FOUR));
                    form.setValue(`${fieldName}.deliverySum`, getNumberFixedValue(deliverySum, ROUND_SCALE_TWO));
                    form.setValue(`${fieldName}.vatSum`, getNumberFixedValue(vatSum, ROUND_SCALE_TWO));
                    form.setValue(`${fieldName}.deliverySumWithVat`, getNumberFixedValue(deliverySumWithVat, ROUND_SCALE_TWO));
                } else {
                    const deliverySum = getNumberValue(item.count) * getNumberValue(summa);
                    const vatSum = getNumberValue(deliverySum) * getNumberValue(item.vatRate) / 100;
                    const deliverySumWithVat = getNumberValue(deliverySum) + getNumberValue(vatSum);

                    form.setValue(`${fieldName}.deliverySum`, getNumberFixedValue(deliverySum, ROUND_SCALE_TWO));
                    form.setValue(`${fieldName}.vatSum`, getNumberFixedValue(vatSum, ROUND_SCALE_TWO));
                    form.setValue(`${fieldName}.deliverySumWithVat`, getNumberFixedValue(deliverySumWithVat, ROUND_SCALE_TWO));
                }
                form.setValue(`${fieldName}.summa`, getNumberFixedValue(summa, ROUND_SCALE_FOUR));
            } else {
                if (hasExcise) {
                    const exciseSum = getNumberValue(item.count) * getNumberValue(item.summa) * getNumberValue(item.exciseRate) / 100;
                    const deliverySumWithoutExcise = getNumberValue(item.count) * getNumberValue(item.summa);
                    const deliverySum = getNumberValue(deliverySumWithoutExcise) + getNumberValue(exciseSum);
                    const vatSum = getNumberValue(deliverySum) * getNumberValue(item.vatRate / 100);
                    const deliverySumWithVat = getNumberValue(deliverySum) + getNumberValue(vatSum);

                    form.setValue(`${fieldName}.exciseSum`, getNumberFixedValue(exciseSum, ROUND_SCALE_FOUR));
                    form.setValue(`${fieldName}.deliverySum`, getNumberFixedValue(deliverySum, ROUND_SCALE_TWO));
                    form.setValue(`${fieldName}.vatSum`, getNumberFixedValue(vatSum, ROUND_SCALE_TWO));
                    form.setValue(`${fieldName}.deliverySumWithVat`, getNumberFixedValue(deliverySumWithVat, ROUND_SCALE_TWO));
                } else {
                    const deliverySum = getNumberValue(item.count) * getNumberValue(item.summa);
                    const vatSum = getNumberValue(deliverySum) * getNumberValue(item.vatRate) / 100;
                    const deliverySumWithVat = getNumberValue(deliverySum) + getNumberValue(vatSum);

                    if (typeof (item.initialDeliverySum) === 'number') {
                        if (item.initialDeliverySum !== deliverySum)
                            shouldEnableManualCalculation = true

                        form.setValue(`${fieldName}.deliverySum`, getNumberFixedValue(item.initialDeliverySum, ROUND_SCALE_TWO));
                        form.setValue(`${fieldName}.initialDeliverySum`, null);
                    } else {
                        form.setValue(`${fieldName}.deliverySum`, getNumberFixedValue(deliverySum, ROUND_SCALE_TWO));
                    }

                    if (typeof (item.initialVatSum) === 'number') {
                        if (item.initialVatSum !== vatSum)
                            shouldEnableManualCalculation = true

                        form.setValue(`${fieldName}.vatSum`, getNumberFixedValue(item.initialVatSum, ROUND_SCALE_TWO));
                        form.setValue(`${fieldName}.initialVatSum`, null);
                    } else {
                        form.setValue(`${fieldName}.vatSum`, getNumberFixedValue(vatSum, ROUND_SCALE_TWO));
                    }

                    if (typeof (item.initialDeliverySumWithVat) === 'number') {
                        if (item.initialDeliverySumWithVat !== deliverySumWithVat)
                            shouldEnableManualCalculation = true

                        form.setValue(`${fieldName}.deliverySumWithVat`, getNumberFixedValue(item.initialDeliverySumWithVat, ROUND_SCALE_TWO));
                        form.setValue(`${fieldName}.initialDeliverySumWithVat`, null);
                    } else {
                        form.setValue(`${fieldName}.deliverySumWithVat`, getNumberFixedValue(deliverySumWithVat, ROUND_SCALE_TWO));
                    }
                }
            }
        }

        if (shouldEnableManualCalculation)
            form.setValue('manualCalculation', true)
    }, [item.profitRate, item.baseSumma, item.count, item.summa, item.exciseRate, item.exciseSum, item.vatRate, item.vatSum, item.deliverySum, item.deliverySumWithVat, hasMedical, hasExcise, manualCalculation, reversedCalculation])

    const onChooseItem = async (item, index) => {
        form.setValue(`productList.products.${index}.name`, item?.item?.name || item?.value || null);
        form.setValue(`productList.products.${index}.barcode`, item?.item?.barcodes?.length ? item?.item?.barcodes[0] : '');
        if (item && !item?.__isNew__) {
            // set item price
            {
                let price = item.price.common_price.amount;
                if (item.price.common_price.currency.name !== UZS)
                    price = currencyWrapper.convertToNationalPrice(currencies, item.price.common_price.currency.name, item.price.common_price.amount);

                form.setValue(`productList.products.${index}.summa`, getNumberFixedValue(price, ROUND_SCALE_TWO));
            }

            // set item tax values
            {
                let catalog = null;
                let catalogPackage = null;
                let lgota = null;
                let origin = null;
                let vatRate = '';

                if (item.item.tax) {
                    try {
                        if (item.item.tax.catalog?.code)
                            catalog = await loadCatalogByCode(item.item.tax.catalog.code);

                        if (item.item.tax.catalog?.package?.code && catalog)
                            catalogPackage = catalog.package_names.find(p => p.code === parseInt(item.item.tax.catalog.package.code))

                        if (item.item.tax.benefit?.id)
                            lgota = findLgota(item.item.tax.benefit.id, catalog);

                        if (item.item.tax.origin)
                            origin = item.item.tax.origin;

                        if (item.item.tax.tax_rate !== null)
                            vatRate = item.item.tax.tax_rate.toString();
                    } catch (error) {
                        console.log(error);
                    }
                }

                form.setValue(`productList.products.${index}.catalog`, catalog);
                form.setValue(`productList.products.${index}.package`, catalogPackage);
                form.setValue(`productList.products.${index}.lgota`, lgota);
                form.setValue(`productList.products.${index}.origin`, origin);
                form.setValue(`productList.products.${index}.vatRate`, vatRate);
            }
        }
    };

    const findLgota = (lgotaId, catalog) => {
        let lgota = null;

        lgota = sellerCompanyPrivileges.find(p => p.new_id === lgotaId || p.id === lgotaId);
        if (lgota) return lgota;

        lgota = turnoverCompanyPrivileges.find(p => p.id === lgotaId);
        if (lgota) return lgota;

        if (catalog)
            lgota = catalog.lgotas.find(l => l.new_id === lgotaId || l.id === lgotaId);

        return lgota;
    };
    const onChangeCatalog = () => {
        form.setValue(`${fieldName}.package`, null);
        form.setValue(`${fieldName}.measurement`, null);
        form.setValue(`${fieldName}.lgota`, null);
        form.setValue(`${fieldName}.origin`, null);
    };

    return (
        <tr className="position-relative" style={{height: '50px'}}>
            <td className="p-0 align-middle text-center" style={{minWidth: '30px'}}>
                <span style={{height: '40px'}}>{index + 1}</span>
            </td>

            {hasCommittent && <RoamingInvoiceFormItemRowCommittent itemFieldName={fieldName}
                                                                   item={item}
            />}
            <td style={{minWidth: '250px'}} className="p-0">
                <Controller
                    name={`${fieldName}.name`}
                    rules={{
                        required: t(lang, 'items.common.validations.is_required')
                    }}
                    render={({field, fieldState: {error}}) => (
                        /*<Form.Control name={`${fieldName}.name`}
                                      className="px-1"
                                      style={{height: '40px', border: !error && 'none'}}
                                      isInvalid={!!error}
                                      value={item.name}
                                      onChange={(e) => field.onChange(e.target.value)}
                        />*/
                        <div className={'mt-1 w-90 mx-auto'}>
                            <SelectItemModal
                                index={index}
                                defaultItemId={field.value}
                                onChange={(item) => onChooseItem(item, index)}
                                buttonProps={{
                                    style: {
                                        height: '40px'
                                    },
                                }}
                            />
                        </div>
                    )}
                />
                {/* <SelectItemModal onChange={(item) => onChooseItem(item, index)}
                                     buttonProps={{
                                         style: {
                                             height: '40px'
                                         },
                                     }}
                    />*/}
            </td>
            <td style={{minWidth: '150px', width: '150px'}} className="p-0 align-middle text-center">
                <Controller name={`${fieldName}.catalog`}
                            rules={{
                                required: t(lang, 'items.common.validations.is_required')
                            }}
                            render={({field}) => (
                                <CatalogLinkSelector defaultCatalog={item.catalog}
                                                     onChange={(option) => {
                                                         field.onChange(option);
                                                         onChangeCatalog(option);
                                                     }}
                                                     linkProps={{
                                                         className: classNames({
                                                             'text-primary cursor-pointer fs--1': !error?.catalog,
                                                             'text-danger cursor-pointer fs--1': error?.catalog
                                                         })
                                                     }}
                                />
                            )}
                />
            </td>
            <td style={{minWidth: '190px', width: '190px'}} className="p-0 align-middle text-center">
                <Controller name={`${fieldName}.origin`}
                            rules={{
                                required: t(lang, 'items.common.validations.is_required')
                            }}
                            render={({field}) => (
                                <OriginLinkSelector defaultOriginType={item.origin}
                                                    onChange={field.onChange}
                                                    linkProps={{
                                                        className: classNames({
                                                            'text-primary cursor-pointer fs--1': !error?.origin,
                                                            'text-danger cursor-pointer fs--1': error?.origin
                                                        })
                                                    }}
                                />
                            )}
                />
            </td>
            {hasMarking &&
                <td style={{minWidth: '200px', width: '200px'}} className="p-0">
                    <div className="d-grid">
                        <RoamingInvoiceFormItemMarkLinkSelector itemFieldName={fieldName}
                                                                index={index + 1}
                                                                item={item}
                        />
                    </div>
                </td>
            }
            <td style={{minWidth: '140px', width: '140px'}} className="p-0">
                <Controller name={`${fieldName}.barcode`}
                            render={({field}) => (
                                <Form.Control name={`${fieldName}.barcode`}
                                              className="px-2 mt-1 w-90 mx-auto"
                                              style={{height: '40px'}}
                                              value={item.barcode}
                                              onChange={(e) => field.onChange(e.target.value)}
                                />
                            )}
                />
            </td>
            <td style={{minWidth: '150px', width: '150px'}} className="p-0 align-middle text-center">
                {item?.catalog?.use_package &&
                    <Controller name={`${fieldName}.package`}
                                rules={{
                                    required: t(lang, 'items.common.validations.is_required')
                                }}
                                render={({field}) => (
                                    <CatalogPackageLinkSelector catalogPackages={item?.catalog?.package_names}
                                                                defaultCatalogPackage={item.package}
                                                                onChange={field.onChange}
                                                                linkProps={{
                                                                    className: classNames({
                                                                        'text-primary cursor-pointer fs--1': !error?.package,
                                                                        'text-danger cursor-pointer fs--1': error?.package
                                                                    })
                                                                }}
                                    />
                                )}
                    />
                }
                {!item?.catalog?.use_package &&
                    <Controller name={`${fieldName}.measurement`}
                                rules={{
                                    required: t(lang, 'items.common.validations.is_required')
                                }}

                                render={({field}) => (
                                    <MeasurementLinkSelector onChange={field.onChange}
                                                             defaultMeasurement={item.measurement}
                                                             linkProps={{
                                                                 className: classNames({
                                                                     'text-primary cursor-pointer fs--1': !error?.measurement,
                                                                     'text-danger cursor-pointer fs--1': error?.measurement
                                                                 })
                                                             }}
                                    />
                                )}
                    />
                }
            </td>
            <td style={{minWidth: '120px', width: '120px'}} className="p-0">
                <Controller name={`${fieldName}.count`}
                            rules={{
                                required: t(lang, 'items.common.validations.is_required'),
                            }}
                            render={({field, fieldState: {error}}) => (
                                <Cleave htmlRef={field.ref}
                                        className={classNames('form-control px-2 text-end mt-1 w-90 mx-auto', {
                                            'is-invalid': !!error
                                        })}
                                        style={{height: '40px'}}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 188)
                                                e.target.value += "."
                                        }}
                                        options={{
                                            numeral: true,
                                            delimiter: ' ',
                                            numeralDecimalScale: ROUND_SCALE_FOUR,
                                        }}
                                        value={item.count}
                                        onChange={e => field.onChange(e.target.rawValue)}
                                />
                            )}
                />
            </td>

            {/*{!item?.catalog?.use_count ?*/}
            {/*    <td style={{minWidth: '120px', width: '120px'}} className="p-0">*/}
            {/*        <Controller name={`${fieldName}.count`}*/}
            {/*                    render={({field}) => (*/}
            {/*                        <Cleave className="form-control px-1"*/}
            {/*                                style={{height: '40px', border: 'none'}}*/}
            {/*                                htmlRef={field.ref}*/}
            {/*                                onKeyDown={(e) => {*/}
            {/*                                    if (e.keyCode === 188)*/}
            {/*                                        e.target.value += "."*/}
            {/*                                }}*/}
            {/*                                options={{*/}
            {/*                                    numeral: true,*/}
            {/*                                    delimiter: ' ',*/}
            {/*                                    numeralDecimalScale: ROUND_SCALE_FOUR,*/}
            {/*                                }}*/}
            {/*                                value={item.count}*/}
            {/*                                onChange={e => field.onChange(e.target.rawValue)}*/}
            {/*                        />*/}
            {/*                    )}*/}
            {/*        />*/}
            {/*    </td>*/}
            {/*    :*/}
            {/*    <td style={{minWidth: '240px', width: '240px'}} className="p-0">*/}
            {/*        <InputGroup>*/}
            {/*            <Controller name={`${fieldName}.countTon`}*/}
            {/*                        shouldUnregister={true}*/}
            {/*                        render={({field}) => (*/}
            {/*                            <Cleave className="form-control px-1"*/}
            {/*                                    style={{height: '40px', border: 'none'}}*/}
            {/*                                    htmlRef={field.ref}*/}
            {/*                                    onKeyDown={(e) => {*/}
            {/*                                        if (e.keyCode === 188)*/}
            {/*                                            e.target.value += "."*/}
            {/*                                    }}*/}
            {/*                                    options={{*/}
            {/*                                        numeral: true,*/}
            {/*                                        delimiter: ' ',*/}
            {/*                                        numeralDecimalScale: ROUND_SCALE_FOUR,*/}
            {/*                                    }}*/}
            {/*                                    value={item.countTon}*/}
            {/*                                    onChange={e => field.onChange(e.target.rawValue)}*/}
            {/*                            />*/}
            {/*                        )}*/}
            {/*            />*/}
            {/*            <Controller name={`${fieldName}.countKm`}*/}
            {/*                        shouldUnregister={true}*/}
            {/*                        render={({field}) => (*/}
            {/*                            <Cleave className="form-control px-1"*/}
            {/*                                    style={{height: '40px', border: 'none'}}*/}
            {/*                                    htmlRef={field.ref}*/}
            {/*                                    onKeyDown={(e) => {*/}
            {/*                                        if (e.keyCode === 188)*/}
            {/*                                            e.target.value += "."*/}
            {/*                                    }}*/}
            {/*                                    options={{*/}
            {/*                                        numeral: true,*/}
            {/*                                        delimiter: ' ',*/}
            {/*                                        numeralDecimalScale: ROUND_SCALE_FOUR,*/}
            {/*                                    }}*/}
            {/*                                    value={item.countKm}*/}
            {/*                                    onChange={e => field.onChange(e.target.rawValue)}*/}
            {/*                            />*/}
            {/*                        )}*/}
            {/*            />*/}
            {/*        </InputGroup>*/}
            {/*    </td>*/}
            {/*}*/}

            {hasMedical &&
                <Fragment>
                    <td style={{minWidth: '140px', width: '140px'}} className="p-0">
                        <Controller name={`${fieldName}.serial`}
                                    shouldUnregister={true}
                                    render={({field}) => (
                                        <Form.Control name={`${fieldName}.serial`}
                                                      className="px-1 mt-1 mx-auto w-90"
                                                      style={{height: '40px'}}
                                                      value={item.serial}
                                                      onChange={(e) => field.onChange(e.target.value)}
                                        />
                                    )}
                        />
                    </td>
                    <td style={{minWidth: '140px', width: '140px'}} className="p-0">
                        <Controller name={`${fieldName}.baseSumma`}
                                    shouldUnregister={true}
                                    rules={{
                                        required: t(lang, 'items.common.validations.is_required'),
                                    }}
                                    render={({field, fieldState: {error}}) => (
                                        <Cleave htmlRef={field.ref}
                                                className={classNames('form-control px-1 text-end mt-1 mx-auto w-90', {
                                                    'is-invalid': !!error
                                                })}
                                                style={{height: '40px'}}
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 188)
                                                        e.target.value += "."
                                                }}
                                                options={{
                                                    numeral: true,
                                                    delimiter: ' ',
                                                    numeralDecimalScale: ROUND_SCALE_FOUR,
                                                }}
                                                disabled={reversedCalculation && !manualCalculation}
                                                value={item.baseSumma}
                                                onChange={e => field.onChange(e.target.rawValue)}
                                        />
                                    )}
                        />
                    </td>
                    <td style={{minWidth: '140px', width: '140px'}} className="p-0">
                        <Controller name={`${fieldName}.profitRate`}
                                    shouldUnregister={true}
                                    render={({field}) => (
                                        <Cleave className="form-control px-1 text-end mt-1 mx-auto w-90"
                                                style={{height: '40px'}}
                                                htmlRef={field.ref}
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 188)
                                                        e.target.value += "."
                                                }}
                                                options={{
                                                    numeral: true,
                                                    delimiter: ' ',
                                                    numeralDecimalScale: ROUND_SCALE_FOUR,
                                                }}
                                                value={item.profitRate}
                                                onChange={e => field.onChange(e.target.rawValue)}
                                        />
                                    )}
                        />
                    </td>
                </Fragment>
            }
            <td style={{minWidth: '140px', width: '140px'}} className="p-0">
                <Controller name={`${fieldName}.summa`}
                            rules={{
                                required: t(lang, 'items.common.validations.is_required'),
                            }}
                            render={({field, fieldState: {error}}) => (
                                <Cleave htmlRef={field.ref}
                                        className={classNames('form-control px-1 text-end mt-1 w-90 mx-auto', {
                                            'is-invalid': !!error
                                        })}
                                        style={{height: '40px'}}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 188)
                                                e.target.value += "."
                                        }}
                                        options={{
                                            numeral: true,
                                            delimiter: ' ',
                                            numeralDecimalScale: ROUND_SCALE_FOUR,
                                        }}
                                        disabled={(reversedCalculation || hasMedical) && !manualCalculation}
                                        value={item.summa}
                                        onChange={e => field.onChange(e.target.rawValue)}
                                />
                            )}
                />
            </td>
            {hasExcise &&
                <Fragment>
                    <td style={{minWidth: '140px', width: '140px'}} className="p-0">
                        <Controller name={`${fieldName}.exciseRate`}
                                    shouldUnregister={true}
                                    render={({field}) => (
                                        <Cleave className="form-control px-1 text-end mt-1 mt-1 mx-auto w-90"
                                                style={{height: '40px'}}
                                                htmlRef={field.ref}
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 188)
                                                        e.target.value += "."
                                                }}
                                                options={{
                                                    numeral: true,
                                                    delimiter: ' ',
                                                    numeralDecimalScale: ROUND_SCALE_FOUR,
                                                }}
                                                value={item.exciseRate}
                                                onChange={e => field.onChange(e.target.rawValue)}
                                        />
                                    )}
                        />
                    </td>
                    <td style={{minWidth: '140px', width: '140px'}} className="p-0">
                        <Controller name={`${fieldName}.exciseSum`}
                                    shouldUnregister={true}
                                    render={({field}) => (
                                        <Cleave className="form-control px-1 text-end mt-1 mt-1 mx-auto w-90"
                                                style={{height: '40px'}}
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 188)
                                                        e.target.value += "."
                                                }}
                                                options={{
                                                    numeral: true,
                                                    delimiter: ' ',
                                                    numeralDecimalScale: ROUND_SCALE_FOUR,
                                                }}
                                                disabled={!manualCalculation}
                                                value={item.exciseSum}
                                                onChange={e => field.onChange(e.target.rawValue)}
                                        />
                                    )}
                        />
                    </td>
                </Fragment>
            }
            <td style={{minWidth: '150px', width: '150px'}} className="p-0">
                <Controller name={`${fieldName}.deliverySum`}
                            render={({field}) => (
                                <Cleave className="form-control px-1 text-end mt-1 mx-auto w-90"
                                        style={{height: '40px'}}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 188)
                                                e.target.value += "."
                                        }}
                                        options={{
                                            numeral: true,
                                            delimiter: ' ',
                                            numeralDecimalScale: ROUND_SCALE_TWO,
                                        }}
                                        disabled={!manualCalculation}
                                        value={item.deliverySum}
                                        onChange={e => field.onChange(e.target.rawValue)}
                                />
                            )}
                />
            </td>
            <td style={{minWidth: '60px', width: '60px'}} className="p-0 align-middle text-center">
                <Controller name={`${fieldName}.vatRate`}
                            render={({field}) => (
                                <VatRateLinkSelector defaultVatRate={item.vatRate}
                                                     onChange={field.onChange}
                                                     linkProps={{
                                                         className: classNames({
                                                             'text-primary cursor-pointer fs--1': !error?.vatRate,
                                                         })
                                                     }}
                                />
                            )}
                />
            </td>
            <td style={{minWidth: '130px', width: '130px'}} className="p-0">
                <Controller name={`${fieldName}.vatSum`}
                            render={({field}) => (
                                <Cleave className="form-control px-1 text-end mt-1 mx-auto w-90"
                                        style={{height: '40px'}}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 188)
                                                e.target.value += "."
                                        }}
                                        options={{
                                            numeral: true,
                                            delimiter: ' ',
                                            numeralDecimalScale: ROUND_SCALE_TWO,
                                        }}
                                        disabled={!manualCalculation}
                                        value={item.vatSum}
                                        onChange={e => field.onChange(e.target.rawValue)}
                                />
                            )}
                />
            </td>
            <td style={{minWidth: '160px', width: '160px'}} className="p-0">
                <Controller name={`${fieldName}.deliverySumWithVat`}
                            render={({field}) => (
                                <Cleave className="form-control px-1 text-end mt-1 mx-auto w-90"
                                        style={{height: '40px'}}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 188)
                                                e.target.value += "."
                                        }}
                                        options={{
                                            numeral: true,
                                            delimiter: ' ',
                                            numeralDecimalScale: ROUND_SCALE_TWO,
                                        }}
                                        disabled={!reversedCalculation && !manualCalculation}
                                        value={item.deliverySumWithVat}
                                        onChange={e => field.onChange(e.target.rawValue)}
                                />
                            )}
                />
            </td>
            <td style={{minWidth: '110px', width: '110px'}} className="p-0 align-middle text-center">
                <Controller name={`${fieldName}.lgota`}
                            render={({field}) => (
                                <BenefitLinkSelector defaultBenefit={item.lgota}
                                                     benefits={[...sellerCompanyPrivileges, ...turnoverCompanyPrivileges, ...lgotas]}
                                                     onChange={field.onChange}
                                                     linkProps={{
                                                         className: classNames({
                                                             'text-primary cursor-pointer fs--1': !error?.lgota,
                                                         })
                                                     }}
                                />
                            )}
                />
            </td>
            <td style={{minWidth: '100px', width: '100px'}} className="p-0 align-middle text-center">
                <Controller name={`${fieldName}.warehouseId`}
                            render={({field}) => (
                                <WarehouseLinkSelector defaultWarehouseId={item.warehouseId}
                                                       onChange={(option) => field.onChange(option ? option.id : null)}
                                                       linkProps={{
                                                           className: classNames({
                                                               'text-primary cursor-pointer fs--1 text-truncate': !error?.warehouseId,
                                                           })
                                                       }}
                                />
                            )}
                />
            </td>
            <td style={{minWidth: '30px', width: '30px'}} className="p-0 flex-wrap sticky-column">
                <div className="d-flex flex-column justify-content-center align-items-center"
                     style={{height: '40px', minHeight: '40px'}}
                >
                    {allowDeleteItem &&
                        <Badge className={'px-2 py-2 fs-8 rounded-3'} bg="danger-davr" variant="phoenix" onClick={() => deleteItem(index)}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Badge>
                        /* <FontAwesomeIcon icon={faTrashAlt}
                                          className="text-danger cursor-pointer mt-2"
                                          title={t(lang, 'roaming.invoice.delete')}
                                          onClick={() => deleteItem(index)}
                         />*/
                    }
                </div>
            </td>
        </tr>
    );
};

export default RoamingInvoiceFormItemRow;
