import React, {Fragment, useState} from 'react';
import {Translate} from "../../../lang/lang";
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {selectCurrentCertificate} from "../../../store/reducers/eimzo/eimzoReducer";
import {useForm} from "react-hook-form";
import {PermissionRoamingInvoiceReject} from "../../../enum/permission/RoamingPermission";
import checkPermission from "../../../enum/permission/CheckPermission";
import toastPermission from "../../../enum/permission/ToastPermission";
import {personDeclineAsync} from "../../../store/reducers/roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import EventBus from "../../../eventbus/EventBus";
import {
    ROAMING_INVOICE_BULK_ACTION_FINISHED,
    ROAMING_INVOICE_BULK_ACTION_STARTED
} from "../../../eventbus/romaing/roaming-invoice/roamingInvoiceEvents";
import IconButton from "../../IconButton";
import {faBan} from "@fortawesome/free-solid-svg-icons";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import SelectCertificateWrapperComponent from "../../common/SelectCertificateWrapperComponent";
import IconCancel from "../../../assets/icon/IconCancel";
import IconFolderCancel from "../../../assets/icon/IconFolderCancel";

const RoamingInvoicePersonDecline = ({id, invoices, handleShowProgressBar, updateActionResult, ...props}) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const activeCertificate = useSelector(selectCurrentCertificate);
    const dispatch = useDispatch()

    // form
    const {formState: {errors}, register, handleSubmit} = useForm({
        defaultValues: {
            comment: ''
        }
    });

    // show/hide modal
    const [show, setShow] = useState(false);
    const handleShow = () => {
        if (dispatch(checkPermission(PermissionRoamingInvoiceReject))) setShow(true)
        else dispatch(toastPermission())
    };
    const handleClose = () => setShow(false);

    // button loader
    const [loading, setLoading] = useState(false);

    // decline invoice
    const personDecline = (formData) => {
        if (invoices.length > 0)
            bulkDecline(formData.comment);
        else
            singleDecline(formData.comment)
    }

    // decline single document
    const singleDecline = (comment) => {
        setLoading(true);
        personDeclineAsync(activeCertificate, lang, id, comment)
            .then(() => {
                setLoading(false);
                handleClose();
            })
            .catch((error) => {
                setLoading(false);
            })
    };

    // decline multiple documents
    const bulkDecline = async (comment) => {
        handleShowProgressBar();
        EventBus.dispatch(ROAMING_INVOICE_BULK_ACTION_STARTED);
        for (let i = 0; i < invoices.length; i++) {
            const invoice = invoices[i];
            try {
                await personDeclineAsync(activeCertificate, lang, invoices[i].id, comment);
                updateActionResult({
                    number: invoice.info.number,
                    date: invoice.info.date,
                    contractor: {
                        name: invoice.executor.name,
                        inn: invoice.executor.inn
                    }
                });
            } catch (error) {
                updateActionResult({
                    number: invoice.info.number,
                    date: invoice.info.date,
                    contractor: {
                        name: invoice.executor.name,
                        inn: invoice.executor.inn
                    },
                    error: error?.message
                });
            }
        }
        EventBus.dispatch(ROAMING_INVOICE_BULK_ACTION_FINISHED);
    };

    return (
        <Fragment>
            <IconButton
                onClick={handleShow}
                variant="phoenix-danger"
                size="sm"
                className={'btn-davr bg-white text-dark gap-2'}
                {...props}
            >
                <IconFolderCancel />
                {t(lang, "roaming.common.decline")}
            </IconButton>

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit(personDecline)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {t(lang, "roaming.invoice.decline_modal.title")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Label>
                            {t(lang, "roaming.invoice.decline_modal.description")}
                        </Form.Label>
                        <Form.Control as={"textarea"}
                                      type="text"
                                      placeholder={t(lang, "roaming.invoice.decline_modal.description")}
                                      isInvalid={errors?.comment}
                                      {...register('comment', {
                                          required: t(lang, "roaming.common.forms.validations.is_required")
                                      })}
                        />
                        <Form.Control.Feedback type="invalid">{errors?.comment?.message}</Form.Control.Feedback>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {t(lang, "roaming.common.cancel")}
                        </Button>
                        <SelectCertificateWrapperComponent
                            openDialogButtonText={t(lang, "roaming.common.decline")}
                            submitButton={
                                <Button id="PersonDecline"
                                        variant="danger"
                                        type="submit"
                                        disabled={loading}
                                >
                                    {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                                    {t(lang, "roaming.common.decline")}
                                </Button>}/>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    );
};

export default RoamingInvoicePersonDecline;
