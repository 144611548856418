import {createSlice} from "@reduxjs/toolkit";
import itemService from "../../services/item/itemService";
import referenceDataAggregatorService from "../../services/referenceData/ReferenceDataAggregatorService";
export const ITEM_INITIALIZATION_STATE_NOT_STARTED = 0
export const ITEM_INITIALIZATION_STATE_LOADING = 1
export const ITEM_INITIALIZATION_STATE_COMPLETE = 2
export const itemSlice = createSlice({
    name: 'item',
    initialState: {
        filters: {
            searchTerm: '',
            sku: '',
            categoryId: null,
            code: '',
            hasCatalog: null,
            hasBarcode: null
        },
        pagination: {page: 1, limit: 10},
        items: [],
        categories: [],
        categoriesTree: [],
        loading: false,
        itemInitializationState: ITEM_INITIALIZATION_STATE_NOT_STARTED,
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setItems: (state, action) => {
            state.items = action.payload
        },
        pushUpdateItem: (state, action) => {
            const {item_id, warehouse_id, state: itemState} = action.payload;
            const items = [...state.items];

            const editingItemIndex = items.findIndex((i) => i.item.id === item_id);
            if (editingItemIndex > -1) {
                const editingWarehouseIndex = items[editingItemIndex].warehouse_states.warehouse_items.findIndex((i) => i.id === warehouse_id);
                if (editingWarehouseIndex > -1) {
                    items[editingItemIndex].warehouse_states.warehouse_items[editingWarehouseIndex].state = itemState;
                    state.items = items;
                }
            }
        },
        setCategories: (state, action) => {
            state.categories = action.payload;
        },
        setCategoriesTree: (state, action) => {
            state.categoriesTree = action.payload;
        },
        setItemInitializationState: (state, action) => {
            state.itemInitializationState = action.payload
        },
        setItemsDictionary: (state, {payload: rdaItems}) => {
            const newItemsDictionary = {};
            for (let i = 0; i < rdaItems.length; i++) {
                const rdaItem = rdaItems[i];
                newItemsDictionary[rdaItem.item.id] = rdaItem;
            }
            state.itemsDictionary = newItemsDictionary;
        },
    }
})

export const loadItemsAsync = (params) => {
    return new Promise((resolve, reject) => {
        referenceDataAggregatorService.getOrganizationItems(params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error)
                reject(error);
            })
    })
}
export const loadItemAsync = (id) => {
    return new Promise((resolve, reject) => {
        referenceDataAggregatorService.getOrganizationItem(id)
            .then(response => resolve(response.data))
            .catch((error) => {
                console.log(error);
                reject(error);
            })
    });
};
export const addItemAsync = (payload) => {
    return new Promise((resolve, reject) => {
        itemService.addItem(payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    });
};
export const validateItemCatalogsAsync = () => {
    return new Promise((resolve, reject) => {
        itemService.validateItemCatalogs()
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
}
export const validateItemCatalogAsync = (id) => {
    return new Promise((resolve, reject) => {
        itemService.validateItemCatalog(id)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
}
export const editItemAsync = (id, payload) => {
    return new Promise((resolve, reject) => {
        itemService.editItem(id, payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    });
};
export const deleteItemAsync = (id) => {
    return new Promise((resolve, reject) => {
        itemService.deleteItem(id)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error)
            })
    });
};
export const exportItemsAsync = (uri, params) => {
    return new Promise((resolve, reject) => {
        itemService.exportItems(uri, params)
            .then((response) => {
                resolve({content: response.data, contentType: response.headers['content-type']});
            })
            .catch((error) => {
                reject(error);
            })
    })
}

export const exportBulkItemsAsync = (uri, payload) => {
    return new Promise((resolve, reject) => {
        itemService.exportBulkItems(uri, payload)
            .then((response) => {
                resolve({content: response.data, contentType: response.headers['content-type']});
            })
            .catch((error) => {
                reject(error);
            })
    })
}
export const getProductGuideAsync = (barcode) => {
    return new Promise((resolve, reject) => {
        itemService.getProductGuide(barcode)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
}
export const pushUpdateItem = ({item_id, warehouse_id, state}) => (dispatch) => {
    dispatch(itemSlice.actions.pushUpdateItem({item_id, warehouse_id, state}));
};

export const setLoading = (value) => (dispatch) => dispatch(itemSlice.actions.setLoading(value));
export const updateFilters = (filters) => (dispatch) => dispatch(itemSlice.actions.setFilters(filters));
export const updatePagination = (pagination) => (dispatch) => dispatch(itemSlice.actions.setPagination(pagination));

export const updateItems = (items) => (dispatch) => dispatch(itemSlice.actions.setItems(items));
export const updateItemsDictionary = (items) => (dispatch) => dispatch(itemSlice.actions.setItemsDictionary(items));

export const updateCategories = (categories) => (dispatch) => dispatch(itemSlice.actions.setCategories(categories));
export const updateCategoriesTree = (categoriesTree) => (dispatch) => dispatch(itemSlice.actions.setCategoriesTree(categoriesTree));
export const selectFilterOptions = state => state.item.filters;
export const selectPaginationOptions = state => state.item.pagination;
export const selectItems = state => state.item.items;
export const selectLoading = state => state.item.loading;
export const selectCategories = state => state.item.categories;
export const selectCategoriesTree = state => state.item.categoriesTree;
export const selectItemInitializationState = state => state.item.itemInitializationState

export default itemSlice.reducer;
