import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {useNavigate} from "react-router-dom";
import {Translate} from "../../../../lang/lang";
import {FormProvider, useForm} from "react-hook-form";
import {selectCurrentCertificate} from "../../../../store/reducers/eimzo/eimzoReducer";
import {generateIdAsync} from "../../../../store/reducers/roaming/roamingReducer";
import dayjs from "dayjs";
import {
    verificationActSaveDraft,
    verificationActSignAndRegister
} from "../../../../store/reducers/roaming/vertification-act/vertificationActReducer";
import verificationAct from "../view/VerificationAct";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import VerificationActInfo from "../send/VerificationActInfo";
import OwnerInfo from "../send/OwnerInfo";
import PartnerInfo from "../send/PartnerInfo";
import VerificationActDescription from "../send/VerificationActDescription";
import Acts from "../send/Acts";
import SelectCertificateWrapperComponent from "../../../common/SelectCertificateWrapperComponent";

const VerificationActForm = () => {
    const currentCertificate = useSelector(selectCurrentCertificate);
    const lang = useSelector(selectLang);
    const navigate = useNavigate();
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            verificationActId: "",
            verificationActDoc: {
                verificationActNo: "",
                verificationActDate: "",
                verificationActText: ""
            },
            ownerTin: "",
            ownerName: "",
            ownerFizTin: "",
            ownerFizFio: "",
            partnerTin: "",
            partnerName: "",
            partnerFizTin: "",
            partnerFizFio: "",
            verificationActContracts: [
                {
                    contractNo: "",
                    contractDate: "",
                    verificationActContractItems: [
                        {
                            ownerOperationDate: "",
                            ownerOperationName: "",
                            ownerDebit: 0.00,
                            ownerCredit: 0.00,
                            partnerOperationDate: "",
                            partnerOperationName: "",
                            partnerDebit: 0.00,
                            partnerCredit: 0.00
                        }
                    ],
                    openBalance: {
                        ownerDebit: 0.00,
                        ownerCredit: 0.00,
                        partnerDebit: 0.00,
                        partnerCredit: 0.00
                    },
                    closeBalance: {
                        ownerDebit: 0.00,
                        ownerCredit: 0.00,
                        partnerDebit: 0.00,
                        partnerCredit: 0.00
                    },
                    totalBalance: {
                        ownerDebit: 0.00,
                        ownerCredit: 0.00,
                        partnerDebit: 0.00,
                        partnerCredit: 0.00
                    }
                }
            ],
            openBalance: {
                ownerDebit: 0.00,
                ownerCredit: 0.00,
                partnerDebit: 0.00,
                partnerCredit: 0.00
            },
            turnoverBalance: {
                ownerDebit: 0.00,
                ownerCredit: 0.00,
                partnerDebit: 0.00,
                partnerCredit: 0.00
            },
            closeBalance: {
                ownerDebit: 0.00,
                ownerCredit: 0.00,
                partnerDebit: 0.00,
                partnerCredit: 0.00
            }
        },
    });

    const signAndSend = async (data) => {
        if (currentCertificate?.keyId) {
            let verificationAct = {
                VerificationActId: data?.verificationActId || (await generateIdAsync(1))[0],
                VerificationActDoc: {
                    VerificationActNo: data.verificationActDoc.verificationActNo,
                    VerificationActDate: dayjs(data.verificationActDoc.verificationActDate).format("YYYY-MM-DD"),
                    VerificationActText: data.verificationActDoc.verificationActText
                },
                OwnerTin: data.ownerTin,
                OwnerName: data.ownerName,
                OwnerFizTin: data.ownerFizTin,
                OwnerFizFio: data.ownerFizFio,
                PartnerTin: data.partnerTin,
                PartnerName: data.partnerName,
                PartnerFizTin: data.partnerFizTin,
                PartnerFizFio: data.partnerFizFio,
                VerificationActContracts: data.verificationActContracts.map(verificationActContract => ({
                    ContractNo: verificationActContract.contractNo,
                    ContractDate: dayjs(verificationActContract.contractDate).format("YYYY-MM-DD"),
                    VerificationActContractItems: verificationActContract.verificationActContractItems.map(verificationActContractItem => ({
                        OwnerOperationDate: dayjs(verificationActContractItem.ownerOperationDate).format("YYYY-MM-DD"),
                        OwnerOperationName: verificationActContractItem.ownerOperationName,
                        OwnerDebit: verificationActContractItem?.ownerDebit?.toFixed(2),
                        OwnerCredit: verificationActContractItem?.ownerCredit?.toFixed(2),
                        PartnerOperationDate: dayjs(verificationActContractItem.partnerOperationDate).format("YYYY-MM-DD"),
                        PartnerOperationName: verificationActContractItem.partnerOperationName,
                        PartnerDebit: verificationActContractItem?.partnerDebit?.toFixed(2),
                        PartnerCredit: verificationActContractItem?.partnerCredit?.toFixed(2)
                    })),
                    OpenBalance: {
                        OwnerDebit: verificationActContract?.openBalance?.ownerDebit?.toFixed(2),
                        OwnerCredit: verificationActContract?.openBalance?.ownerCredit?.toFixed(2),
                        PartnerDebit: verificationActContract?.openBalance?.partnerDebit?.toFixed(2),
                        PartnerCredit: verificationActContract?.openBalance?.partnerCredit?.toFixed(2)
                    },
                    CloseBalance: {
                        OwnerDebit: verificationActContract?.closeBalance?.ownerDebit?.toFixed(2),
                        OwnerCredit: verificationActContract?.closeBalance?.ownerCredit?.toFixed(2),
                        PartnerDebit: verificationActContract?.closeBalance?.partnerDebit?.toFixed(2),
                        PartnerCredit: verificationActContract?.closeBalance?.partnerCredit?.toFixed(2)
                    },
                    TotalBalance: {
                        OwnerDebit: verificationActContract?.totalBalance?.ownerDebit?.toFixed(2),
                        OwnerCredit: verificationActContract?.totalBalance?.ownerCredit?.toFixed(2),
                        PartnerDebit: verificationActContract?.totalBalance?.partnerDebit?.toFixed(2),
                        PartnerCredit: verificationActContract?.totalBalance?.partnerCredit?.toFixed(2)
                    }

                })),
                OpenBalance: {
                    OwnerDebit: data?.openBalance?.ownerDebit?.toFixed(2),
                    OwnerCredit: data?.openBalance?.ownerCredit?.toFixed(2),
                    PartnerDebit: data?.openBalance?.partnerDebit?.toFixed(2),
                    PartnerCredit: data?.openBalance?.partnerCredit?.toFixed(2),
                },
                TurnoverBalance: {
                    OwnerDebit: data?.turnoverBalance?.ownerDebit?.toFixed(2),
                    OwnerCredit: data?.turnoverBalance?.ownerCredit?.toFixed(2),
                    PartnerDebit: data?.turnoverBalance?.partnerDebit?.toFixed(2),
                    PartnerCredit: data?.turnoverBalance?.partnerCredit?.toFixed(2),
                },
                CloseBalance: {
                    OwnerDebit: data?.closeBalance?.ownerDebit?.toFixed(2),
                    OwnerCredit: data?.closeBalance?.ownerCredit?.toFixed(2),
                    PartnerDebit: data?.closeBalance?.partnerDebit?.toFixed(2),
                    PartnerCredit: data?.closeBalance?.partnerCredit?.toFixed(2),
                }
            }
            const {data: responseVerificationAct} = await verificationActSignAndRegister({
                verificationAct: verificationAct,
                certificate: currentCertificate,
                lang: lang
            })
            return responseVerificationAct
        }
    }

    const saveDraft = async (data) => {
        let verificationAct = {
            VerificationActId: data?.verificationActId || (await generateIdAsync(1))[0],
            VerificationActDoc: {
                VerificationActNo: data.verificationActDoc.verificationActNo,
                VerificationActDate: dayjs(data.verificationActDoc.verificationActDate).format("YYYY-MM-DD"),
                VerificationActText: data.verificationActDoc.verificationActText
            },
            OwnerTin: data.ownerTin,
            OwnerName: data.ownerName,
            OwnerFizTin: data.ownerFizTin,
            OwnerFizFio: data.ownerFizFio,
            PartnerTin: data.partnerTin,
            PartnerName: data.partnerName,
            PartnerFizTin: data.partnerFizTin,
            PartnerFizFio: data.partnerFizFio,
            VerificationActContracts: data.verificationActContracts.map(verificationActContract => ({
                ContractNo: verificationActContract.contractNo,
                ContractDate: dayjs(verificationActContract.contractDate).format("YYYY-MM-DD"),
                VerificationActContractItems: verificationActContract.verificationActContractItems.map(verificationActContractItem => ({
                    OwnerOperationDate: dayjs(verificationActContractItem.ownerOperationDate).format("YYYY-MM-DD"),
                    OwnerOperationName: verificationActContractItem.ownerOperationName,
                    OwnerDebit: verificationActContractItem?.ownerDebit?.toFixed(2),
                    OwnerCredit: verificationActContractItem?.ownerCredit?.toFixed(2),
                    PartnerOperationDate: dayjs(verificationActContractItem.partnerOperationDate).format("YYYY-MM-DD"),
                    PartnerOperationName: verificationActContractItem.partnerOperationName,
                    PartnerDebit: verificationActContractItem?.partnerDebit?.toFixed(2),
                    PartnerCredit: verificationActContractItem?.partnerCredit?.toFixed(2)
                })),
                OpenBalance: {
                    OwnerDebit: verificationActContract?.openBalance?.ownerDebit?.toFixed(2),
                    OwnerCredit: verificationActContract?.openBalance?.ownerCredit?.toFixed(2),
                    PartnerDebit: verificationActContract?.openBalance?.partnerDebit?.toFixed(2),
                    PartnerCredit: verificationActContract?.openBalance?.partnerCredit?.toFixed(2)
                },
                CloseBalance: {
                    OwnerDebit: verificationActContract?.closeBalance?.ownerDebit?.toFixed(2),
                    OwnerCredit: verificationActContract?.closeBalance?.ownerCredit?.toFixed(2),
                    PartnerDebit: verificationActContract?.closeBalance?.partnerDebit?.toFixed(2),
                    PartnerCredit: verificationActContract?.closeBalance?.partnerCredit?.toFixed(2)
                },
                TotalBalance: {
                    OwnerDebit: verificationActContract?.totalBalance?.ownerDebit?.toFixed(2),
                    OwnerCredit: verificationActContract?.totalBalance?.ownerCredit?.toFixed(2),
                    PartnerDebit: verificationActContract?.totalBalance?.partnerDebit?.toFixed(2),
                    PartnerCredit: verificationActContract?.totalBalance?.partnerCredit?.toFixed(2)
                }

            })),
            OpenBalance: {
                OwnerDebit: data?.openBalance?.ownerDebit?.toFixed(2),
                OwnerCredit: data?.openBalance?.ownerCredit?.toFixed(2),
                PartnerDebit: data?.openBalance?.partnerDebit?.toFixed(2),
                PartnerCredit: data?.openBalance?.partnerCredit?.toFixed(2),
            },
            TurnoverBalance: {
                OwnerDebit: data?.turnoverBalance?.ownerDebit?.toFixed(2),
                OwnerCredit: data?.turnoverBalance?.ownerCredit?.toFixed(2),
                PartnerDebit: data?.turnoverBalance?.partnerDebit?.toFixed(2),
                PartnerCredit: data?.turnoverBalance?.partnerCredit?.toFixed(2),
            },
            CloseBalance: {
                OwnerDebit: data?.closeBalance?.ownerDebit?.toFixed(2),
                OwnerCredit: data?.closeBalance?.ownerCredit?.toFixed(2),
                PartnerDebit: data?.closeBalance?.partnerDebit?.toFixed(2),
                PartnerCredit: data?.closeBalance?.partnerCredit?.toFixed(2),
            }
        }
        const {data: responseVerificationAct} = await verificationActSaveDraft({
            verificationAct: verificationAct
        })
        return responseVerificationAct
    }

    const onSubmit = async (data, event) => {
        try {
            let {name} = event.nativeEvent.submitter
            if (name === "save") {
                let responseVerificationAct = await saveDraft(data)
                if (responseVerificationAct?.id) navigate(`/roaming/verification-act/draft/${responseVerificationAct.id}`)
            } else if (name === "sign" && currentCertificate?.keyId) {
                let responseVerificationAct = await signAndSend(data)
                if (responseVerificationAct?.id) navigate(`/roaming/verification-act/${responseVerificationAct.id}`)
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        let verificationActKeyProperty = verificationAct && Object.keys(verificationAct)
        if (verificationActKeyProperty?.length) {
            verificationActKeyProperty.forEach(key => {
                methods.setValue(key, verificationAct[key])
            })
        }
    }, [verificationAct]);

    return (
        <FormProvider {...methods}>
            <Form id="AddVerificationAct" onSubmit={methods.handleSubmit(onSubmit)}>
                <Row>
                    <Col sm="12" className="mb-2">
                        <VerificationActInfo/>
                    </Col>
                    <Col sm="6" className="mb-2">
                        <OwnerInfo/>
                    </Col>
                    <Col sm="6" className="mb-2">
                        <PartnerInfo/>
                    </Col>
                    <Col sm="12" className="mb-2">
                        <VerificationActDescription/>
                    </Col>
                    <Col sm="12" className="mb-2">
                        <Acts/>
                    </Col>
                    <Col sm="12">
                        <Card className="border-primary border-top-2">
                            <Card.Body className="text-end">
                                <Button
                                    name="save"
                                    type="submit"
                                    variant="phoenix-warning"
                                    form="AddVerificationAct"
                                    size="lg"
                                >{t(lang, "Сохранить в черновик")}
                                </Button>
                                <SelectCertificateWrapperComponent
                                    submitButton={
                                        <Button
                                            name="sign"
                                            type="submit"
                                            variant="phoenix-primary"
                                            form="AddVerificationAct"
                                            size="lg"
                                            className="ms-3"
                                            id="signVerificationAct"
                                            disabled={!currentCertificate}
                                        >{t(lang, "roaming.verification_act.add.verification_act.contracts.sign_and_send")}
                                        </Button>
                                    }
                                    openDialogButtonText={t(lang, 'roaming.verification_act.add.verification_act.contracts.sign_and_send')}
                                    size="lg"
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </FormProvider>
    );
};

export default VerificationActForm;
