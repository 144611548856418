import React from "react";

function SliceCompanyName({companyName}) {
    let start = companyName?.indexOf('"')
    let end = companyName?.lastIndexOf('"')


    return <div className={'text-center'}>
        <div className={'fw-bold'}>{companyName?.slice(start, end + 1)}</div>
        <div>{companyName?.slice(end + 1)}</div>
    </div>
}

export default SliceCompanyName
