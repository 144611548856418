import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import OrderService from "../../../services/edi/order/orderService";
import orderService from "../../../services/edi/order/orderService";
import EventBus from "../../../../eventbus/EventBus";
import {
    ADD_ORDER_FAILED,
    ADD_ORDER_REQUESTED,
    ADD_ORDER_SUCCESS,
    CUSTOMER_APPROVED_ORDER,
    EXECUTOR_APPROVED_ORDER,
    EXECUTOR_CANCEL_DECLINE_FAILED,
    EXECUTOR_CANCEL_DECLINE_REQUESTED,
    EXECUTOR_CANCEL_DECLINE_SUCCESS,
    EXECUTOR_DECLINE_ORDER_FAILED,
    EXECUTOR_DECLINE_ORDER_REQUESTED,
    EXECUTOR_DECLINE_ORDER_SUCCESS,
    IDENTIFY_ORDER_ITEMS_FAILED,
    IDENTIFY_ORDER_ITEMS_REQUESTED,
    IDENTIFY_ORDER_ITEMS_SUCCESS,
    RESUME_ORDER_FAILED,
    RESUME_ORDER_REQUESTED,
    RESUME_ORDER_SUCCESS,
    STOP_ORDER_FAILED,
    STOP_ORDER_REQUESTED,
    STOP_ORDER_SUCCESS
} from "../../../../eventbus/orderEvents";
export const STORAGE_ORDER = "OrdersDataTableSettings"

const initialState = {
    orders: [],
    systemOrderCustomers: [],
    countOrders: 0,
    filterOptions: {page: 1, limit: 10},
    ordersDataTableSettings: {},
    ediBreadcrumbData: null,
    ediReturnBreadcrumbData: null,
    isLoading: false
}
const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        updateOrders: (state, action) => {
            state.orders = action.payload
            state.isLoading = false
        },
        updateCountOrders: (state, action) => {
            state.countOrders = action.payload
        },
        loadOrderDataTableColumn: (state) => {
            const defaultColumns = {
                index: true,
                number: true,
                date: true,
                unloading_point: true,
                expire_date: true,
                created_date: true,
                status: true,
                contractor: true,
                customer_employee_code: false
            };
            let storageColumn = JSON.parse(localStorage.getItem(STORAGE_ORDER))
            if (!storageColumn) {
                storageColumn = defaultColumns;
                localStorage.setItem(STORAGE_ORDER, JSON.stringify(storageColumn))
            } else {
                storageColumn = {...defaultColumns, ...storageColumn}
            }
            state.ordersDataTableSettings = storageColumn
        },
        setEdiBreadcrumbData: (state, action) => {
            state.ediBreadcrumbData = action.payload
        },
        updateSystemOrderCustomers: (state, action) => {
            state.systemOrderCustomers = action.payload;
        },
        updateFilterOrder: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
        changeOrderDataTableColumn: (state, action) => {
            state.ordersDataTableSettings = action.payload
            localStorage.setItem(STORAGE_ORDER, JSON.stringify(state.ordersDataTableSettings))
        },
        orderGetStart: (state,action) => {
            state.isLoading = action.payload
        },
        setEdiReturnBreadcrumbData: (state, action) => {
            state.ediReturnBreadcrumbData = action.payload
        },
    }
})
export const changeFilterOrdersAsync = (params) => (dispatch) => {
    return dispatch(orderSlice.actions.updateFilterOrder(params))
}
export const loadSystemOrderCustomerAsync = () => async (dispatch) => {
    try {
        const {data: systemOrderCustomers} = await orderService.getSystemOrderCustomer();
        dispatch(orderSlice.actions.updateSystemOrderCustomers(systemOrderCustomers))
    } catch (error) {
        console.log(error);
    }

};


export const loadOrdersAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    dispatch(orderSlice.actions.orderGetStart(true))
    OrderService.getOrders(params)
        .then(response => {
            dispatch(orderSlice.actions.updateOrders(response.data))
        })
        .catch(error => {
            orderSlice.actions.orderGetStart(false)
            console.log(error);
        })
}

export const loadCountOrdersAsync = (params) => (dispatch) => {
    let {limit, page, ...others} = params
    OrderService.getCountOrders(others)
        .then(response => {
            dispatch(orderSlice.actions.updateCountOrders(response.data))
        })
        .catch(error => {
            console.log(error);
        })
}

export const ExecutorDeclineOrderAsync = ({id, reason, comment}) => {
    EventBus.dispatch(EXECUTOR_DECLINE_ORDER_REQUESTED, {id, reason});

    return new Promise((resolve, reject) => {
        OrderService.executorDeclineOrder({id, reason, comment})
            .then((response) => {
                resolve(response.data);
                EventBus.dispatch(EXECUTOR_DECLINE_ORDER_SUCCESS, response.data);
            })
            .catch((error) => {
                reject(error.message);
                EventBus.dispatch(EXECUTOR_DECLINE_ORDER_FAILED, error);
            })
    })
}

export const loadOrderAsync = id => {
    return new Promise((resolve, reject) => {
        orderService.getOrder(id)
            .then(response => {
                resolve(response.data);
            })
            .catch(e => {
                reject(e);
            });
    })
}

export const resumeOrderAsync = ({id, comment}) => {
    EventBus.dispatch(RESUME_ORDER_REQUESTED, {id, comment});

    return new Promise((resolve, reject) => {
        OrderService.resumeOrder({id, comment})
            .then((response) => {
                resolve(response.data);
                EventBus.dispatch(RESUME_ORDER_SUCCESS, response.data);
            })
            .catch((error) => {
                reject(error.message);
                EventBus.dispatch(RESUME_ORDER_FAILED, error);
            })

    });
};

export const ExecutorCancelDeclineAsync = ({id, comment}) => {
    EventBus.dispatch(EXECUTOR_CANCEL_DECLINE_REQUESTED, {id, comment});

    return new Promise((resolve, reject) => {
        OrderService.executorCancelDecline({id, comment})
            .then((response) => {
                resolve(response.data);
                EventBus.dispatch(EXECUTOR_CANCEL_DECLINE_SUCCESS, response.data);
            })
            .catch((error) => {
                reject(error.message);
                EventBus.dispatch(EXECUTOR_CANCEL_DECLINE_FAILED, error);
            })

    });
};

export const uploadOrderIntoExcelAsync = ({uri, id}) => {
    return new Promise((resolve, reject) => {
        OrderService.uploadOrderToExcel({uri, id})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export const identifyOrderItems = ({id}) => {
    EventBus.dispatch(IDENTIFY_ORDER_ITEMS_REQUESTED, {id});

    return new Promise((resolve, reject) => {
        OrderService.orderIdentifyItems({orderId: id})
            .then((response) => {
                resolve(response.data);
                EventBus.dispatch(IDENTIFY_ORDER_ITEMS_SUCCESS, response.data);
            })
            .catch((error) => {
                reject(error.message);
                EventBus.dispatch(IDENTIFY_ORDER_ITEMS_FAILED, error);
            })

    });
};

export const printOrderAsync = (id) => {
    return new Promise((resolve, reject) => {
        orderService.printOrder(id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export const CustomerApproveAsync = ({id, comment}) => {
    return new Promise((resolve, reject) => {
        OrderService.customerApprove({id, comment})
            .then(response => {
                EventBus.dispatch(CUSTOMER_APPROVED_ORDER, response.data)
                resolve(response.data);
            }).catch(error => {
            reject(error)
        })
    });
}

export const ExecutorApproveAsync = ({id, comment}) => {
    return new Promise((resolve, reject) => {
        OrderService.executorApprove({id, comment})
            .then(response => {
                EventBus.dispatch(EXECUTOR_APPROVED_ORDER, response.data);
                resolve(response.data)
            }).catch(error => {
            reject(error)
        })
    });
}

export const StopOrderAsync = ({id, comment}) => {
    EventBus.dispatch(STOP_ORDER_REQUESTED, {id, comment});
    return new Promise((resolve, reject) => {
        OrderService.stopOrder({id, comment})
            .then(response => {
                resolve(response.data)
                EventBus.dispatch(STOP_ORDER_SUCCESS, response.data);
            })
            .catch(error => {
                reject(error.message);
                EventBus.dispatch(STOP_ORDER_FAILED, error);
            })
    })

};

export const uploadSelectedOrdersToExcelAsync = (selectedOrdersIDs) => {
    return new Promise((resolve, reject) => {
        orderService.uploadSelectedOrdersToExcel(selectedOrdersIDs)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export const uploadOrderListIntoExcelAsync = ({uri, page, limit, ...filters}) => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};

    return new Promise((resolve, reject) => {
        OrderService.uploadOrderListToExcel({uri: uri, params: params})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export const uploadSelectedOrdersWithItemsToExcelAsync = (selectedOrdersIDs) => {
    return new Promise((resolve, reject) => {
        orderService.uploadSelectedOrdersWithItemsToExcel(selectedOrdersIDs)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export const uploadOrdersWithItemsToExcelAsync = ({uri, page, limit, ...filters}) => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};

    return new Promise((resolve, reject) => {
        OrderService.uploadOrdersWithItemsToExcel({uri: uri, params: params})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export const uploadBeltonOrderToExcelAsync = (id) => {
    return new Promise((resolve, reject) => {
        OrderService.uploadBeltonOrderToExcel(id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export const AddOrderAsync = (payload) => {
    EventBus.dispatch(ADD_ORDER_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        OrderService.addOrder(payload)
            .then(response => {
                resolve(response.data);
                EventBus.dispatch(ADD_ORDER_SUCCESS, response.data);
            })
            .catch(errors => {
                reject({
                    creatorIsNotCustomer: errors?.response?.data?.creator_is_not_customer || false,
                    branchNotFound: errors?.response?.data?.branch_not_found || false,
                    numberExists: errors?.response?.data?.number_exists || false,
                    customerSystemIdExists: errors?.response?.data?.customer_system_id_exists || false,
                });
                EventBus.dispatch(ADD_ORDER_FAILED, errors);
            })
    })
}

export const selectFilterOptions = (state) => state.ediOrder.filterOptions;
export const selectOrdersDataTable = (state) => state.ediOrder.ordersDataTableSettings
export const selectOrders = (state) => state.ediOrder.orders;
export const selectCountOrders = (state) => state.ediOrder.countOrders;
export const selectSystemOrderCustomers = (state) => state.ediOrder.systemOrderCustomers;
export const orderLoading = (state) => state.ediOrder.isLoading;
export const selectEdiBreadcrumbData = (state) => state.ediOrder.ediBreadcrumbData
export const selectEdiReturnBreadcrumbData = (state) => state.ediOrder.ediReturnBreadcrumbData
export const {loadOrderDataTableColumn,setEdiBreadcrumbData,changeOrderDataTableColumn,setEdiReturnBreadcrumbData} = orderSlice.actions
export default orderSlice.reducer
