import classNames from "classnames";
import {useSelector} from "react-redux";
import {Button, Tab, Table, Tabs} from "react-bootstrap";
import {useFormContext, useWatch} from "react-hook-form";
import React, {Fragment, memo, useEffect, useRef, useState} from 'react';
import {selectInvoiceRegistrySettings} from "../../../../store/reducers/settings/settingsReducer";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {
    ExcelRegistryFieldInvoiceNumber,
    ExcelRegistryFields,
    ExcelRegistryFieldUnimportant, GetExcelRegistryFieldLabel
} from "../../../../enum/roaming/roaming-invoice/RoamingInvoiceFieldOptions";
import ColumnSelector from "../../../edi/attorney/mass-import-attorney/ColumnSelector";
import MassAddRoamingInvoiceImportDetails from "./MassAddRoamingInvoiceImportDetails";


const sheetsFieldName = 'sheets';
const sheetsSkipRowsFieldName = 'sheets_skip_rows';
const sheetsColumnKeysFieldName = 'sheets_column_keys';

const MassAddRoamingInvoiceItemsForm = () => {
    const invoiceRegistrySettings = useSelector(selectInvoiceRegistrySettings);
    const sheets = useWatch({name: sheetsFieldName, exact: true});
    const {setValue} = useFormContext();

    useEffect(() => {
        if (sheets.length > 0) {
            initSheetsColumns();
            initSheetsSkipRows();
        }
    }, [sheets])

    const initSheetsColumns = () => {
        let sheetsColumns = [];

        const savedSheetColumns = invoiceRegistrySettings?.sheet_columns;

        for (let sheetIndex = 0; sheetIndex < sheets.length; sheetIndex++) {
            const sheet = sheets[sheetIndex];

            let maxSheetRowLength = 0;
            let sheetColumnKeys = [];

            // define sheet max column count
            for (let rowIndex = 0; rowIndex < sheet.data.length; rowIndex++) {
                const sheetRow = sheet.data[rowIndex];

                if (sheetRow.length > maxSheetRowLength) {
                    maxSheetRowLength = sheetRow.length;
                }
            }

            // fill sheet all column keys
            for (let index = 0; index < maxSheetRowLength; index++) {

                if (!!savedSheetColumns) {
                    let savedColumnFound = false;

                    for (let i = 0; i < savedSheetColumns.length; i++) {
                        const savedColumn = savedSheetColumns[i];

                        if (savedColumn.column_index === index && savedColumn.sheet_index === sheetIndex) {
                            savedColumnFound = true;
                            sheetColumnKeys.push(savedColumn.key_name);
                            break;
                        }
                    }

                    if (!savedColumnFound) {
                        sheetColumnKeys.push(ExcelRegistryFieldUnimportant);
                    }
                } else {
                    sheetColumnKeys.push(ExcelRegistryFieldUnimportant);
                }
            }

            sheetsColumns[sheetIndex] = sheetColumnKeys;
        }

        setValue(sheetsColumnKeysFieldName, sheetsColumns);
    }

    const initSheetsSkipRows = () => {
        let sheetsSkipRows = [];

        const savedSheetSkipRows = invoiceRegistrySettings?.sheet_skip_rows;

        for (let sheetIndex = 0; sheetIndex < sheets.length; sheetIndex++) {
            if (savedSheetSkipRows) {
                const savedSkipRows = savedSheetSkipRows.find(sheetSkipRows => sheetSkipRows.sheet_index === sheetIndex);

                if (savedSkipRows) {
                    sheetsSkipRows[sheetIndex] = {count_row: +savedSkipRows.count_row};
                } else {
                    sheetsSkipRows[sheetIndex] = {count_row: 0};
                }
            } else {
                sheetsSkipRows[sheetIndex] = {count_row: 0};
            }
        }

        setValue(sheetsSkipRowsFieldName, sheetsSkipRows);
    }

    if (sheets.length === 0)
        return false

    return (
        <Tabs fill className="border-bottom p-3 pb-0">
            {sheets.length > 0 && sheets.map((sheet, sheetIndex) => (
                <Tab key={sheetIndex} eventKey={sheetIndex} title={sheet.name} className="bg-light">
                    <TabContent
                        sheet={sheet}
                        sheetIndex={sheetIndex}
                        sheetsArrayName={sheetsFieldName}
                        sheetsSkipRowsArrayName={sheetsSkipRowsFieldName}
                        sheetsColumnKeysArrayName={sheetsColumnKeysFieldName}
                    />
                </Tab>
            ))}
        </Tabs>
    );
};

const TabContent = ({sheet, sheetIndex, sheetsArrayName, sheetsSkipRowsArrayName, sheetsColumnKeysArrayName}) => {
    const {setValue, clearErrors, setError} = useFormContext();

    const specificSheetColumnArrayName = `${sheetsColumnKeysArrayName}.${sheetIndex}`;
    const specificSheetSkipRowsArrayName = `${sheetsSkipRowsArrayName}.${sheetIndex}`;
    const skipRowsFieldName = `${specificSheetSkipRowsArrayName}.count_row`;
    const specificSheetArrayName = `${sheetsArrayName}.${sheetIndex}`;

    const sheetColumnKeys = useWatch({name: specificSheetColumnArrayName});
    const sheetSkipRows = useWatch({name: specificSheetSkipRowsArrayName});
    const skipRows = useWatch({name: skipRowsFieldName});

    const [limit, setLimit] = useState(20);
    const tableContent = useRef(null);
    const lang = useSelector(selectLang);
    const count = sheet.data.length;
    const t = Translate;

    useEffect(() => {
        validateSheetColumns();
    }, [sheetColumnKeys])

    const resetSheetColumns = () => {
        let newSheetColumnKeys = [];

        for (let i = 0; i < sheetColumnKeys.length; i++) {
            newSheetColumnKeys.push(ExcelRegistryFieldUnimportant);
        }

        setValue(specificSheetColumnArrayName, newSheetColumnKeys);
    }

    const validateSheetColumns = () => {
        if (!!sheetColumnKeys && sheetColumnKeys.length > 0) {
            if (sheetIndex === 0) {
                validateMainSheetColumns();
            } else {
                validateSecondarySheetColumns();
            }
        }
    }

    const validateMainSheetColumns = () => {
        let noErrorsFound = true;

        for (let i = 0; i < ExcelRegistryFields.length; i++) {
            const field = ExcelRegistryFields[i];
            let found = false;

            if (field.isRequired) {
                for (let j = 0; j < sheetColumnKeys.length; j++) {
                    if (field.key === sheetColumnKeys[j]) {
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    noErrorsFound = false;
                    setError(specificSheetArrayName, {
                        type: 'required',
                        message: t(lang, 'roaming.invoice.mass_add.columns.error_message', {column: t(lang, field.label)})
                    });
                    break;
                }
            }
        }

        if (noErrorsFound) {
            clearErrors(specificSheetArrayName);
        }
    }
    const validateSecondarySheetColumns = () => {
        let invoiceNumberColumnSelected = false;
        let secondaryColumnSelected = false;
        let secondaryColumnKey = "";
        let noErrorsFound = true;

        for (let index = 0; index < sheetColumnKeys.length; index++) {
            const columnKey = sheetColumnKeys[index];

            switch (columnKey) {
                case ExcelRegistryFieldInvoiceNumber:
                    invoiceNumberColumnSelected = true;
                    break;
                case ExcelRegistryFieldUnimportant:
                    continue;
                default:
                    secondaryColumnSelected = true;
                    secondaryColumnKey = columnKey;
                    break;
            }
        }

        if (!!secondaryColumnSelected && !invoiceNumberColumnSelected) {
            noErrorsFound = false;
            setError(specificSheetArrayName, {
                type: 'required',
                message: t(lang, 'roaming.invoice.mass_add.columns.sheet_error_message', {
                    required_column: t(lang, GetExcelRegistryFieldLabel(ExcelRegistryFieldInvoiceNumber)),
                    selected_column: t(lang, GetExcelRegistryFieldLabel(secondaryColumnKey)),
                })
            });
        }

        if (noErrorsFound) {
            clearErrors(specificSheetArrayName);
        }
    }

    if (!sheetColumnKeys || !sheetSkipRows || !sheet.data) {
        return false;
    }

    return (
        <Fragment>
            <MassAddRoamingInvoiceImportDetails
                sheetIndex={sheetIndex}
                tableRef={tableContent}
                sheetArrayName={sheetsArrayName}
                onResetColumns={resetSheetColumns}
                skipRowsArrayName={sheetsSkipRowsArrayName}
            />

            <hr/>

            <div className="table-responsive mt-3" ref={tableContent}>
                <Table size="sm" bordered>
                    <thead>
                    <tr>
                        <th className="text-center px-3 m-0" width={20}>№</th>
                        {sheetColumnKeys.map((column, index) => (
                            <ColumnSelector key={index} index={index} columnsOptions={ExcelRegistryFields} arrayFieldName={specificSheetColumnArrayName} />
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {sheet.data.slice(0, limit).map((row, rowIndex) => (
                        <tr className={classNames('fs--1', {'bg-400 text-dark': (rowIndex + 1) <= skipRows})} key={rowIndex}>
                            <th className="text-center px-3 m-0">{rowIndex + 1}</th>
                            {row.map((cell, cellIndex) => (
                                <th key={cellIndex}>{cell}</th>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>

            {count > limit && (
                <div className="w-100 text-center my-3">
                    <Button onClick={() => setLimit(limit + 20)}>{t(lang, 'roaming.invoice.mass_add.show_more_btn.title')}</Button>
                </div>
            )}
        </Fragment>
    )
}

export default memo(MassAddRoamingInvoiceItemsForm);
