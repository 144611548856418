import React, {Fragment, useState} from 'react';
import {useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {
    selectExecutorPrivileges,
    selectTurnoverPrivileges
} from "../../../store/reducers/roaming/roamingReferenceReducer";
import IconButton from "../../IconButton";
import {Card, Col, Form, Row, Table} from "react-bootstrap";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import RoamingInvoiceFormHideReportCommittentSwitcher from "./RoamingInvoiceFormHideReportCommittentSwitcher";
import classNames from "classnames";
import CatalogLinkSelector from "../../common/CatalogLinkSelector";
import OriginLinkSelector from "../../common/OriginLinkSelector";
import VatRateLinkSelector from "../../common/VatRateLinkSelector";
import BenefitLinkSelector from "../../common/BenefitLinkSelector";
import WarehouseLinkSelector from "../../common/WarehouseLinkSelector";
import RoamingInvoiceFormItemRow from "./RoamingInvoiceFormItemRow";
import numeral from "numeral";
import {getNumberValue} from "../../../helpers/utils";

const RoamingInvoiceFormCardItems = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const sellerCompanyPrivileges = useSelector(selectExecutorPrivileges);
    const turnoverCompanyPrivileges = useSelector(selectTurnoverPrivileges);
    const [reversedCalculation, setReversedCalculation] = useState(false);

    const form = useFormContext();
    const {fields, append, insert, remove,} = useFieldArray({name: 'productList.products'});

    const hasExcise = useWatch({name: `productList.hasExcise`, exact: true});
    const hasMarking = useWatch({name: `productList.hasMarking`, exact: true});
    const hasMedical = useWatch({name: `productList.hasMedical`, exact: true});
    const hasCommittent = useWatch({name: `productList.hasCommittent`, exact: true});
    const products = useWatch({name: `productList.products`});
    const manualCalculation = useWatch({name: `manualCalculation`, exact: true});
    const numberFormat = useSelector(selectNumberFormat);

    const addNewItem = () => {
        append({
            committentName: '',
            committentTin: '',
            committentVatRegCode: '',
            committentVatRegStatus: '',
            catalog: null,
            package: null,
            measurement: null,
            lgota: null,
            origin: null,
            barcode: '',
            warehouseId: null,
            mark: {
                productType: null,
                kiz: [
                    {code: ''}
                ],
                nomUpak: [
                    {code: ''}
                ],
                identTransUpak: [
                    {code: ''}
                ],
            },
            exchangeInfo: {
                productCode: '',
                productProperties: '',
                planPositionId: 1,
            },
            name: '',
            serial: '',
            baseSumma: '',
            profitRate: '',
            count: '',
            countTon: '',
            countKm: '',
            summa: '',
            exciseRate: '',
            exciseSum: '',
            deliverySum: '',
            vatRate: '',
            vatSum: '',
            deliverySumWithVat: '',
        }, {shouldFocus: false});
    };

    const insertNewItem = (index) => {
        insert(index + 1, {
            committentName: '',
            committentTin: '',
            committentVatRegCode: '',
            committentVatRegStatus: '',
            catalog: null,
            package: null,
            measurement: null,
            lgota: null,
            origin: null,
            barcode: '',
            warehouseId: null,
            mark: {
                productType: null,
                kiz: [
                    {code: ''}
                ],
                nomUpak: [
                    {code: ''}
                ],
                identTransUpak: [
                    {code: ''}
                ],
            },
            exchangeInfo: {
                productCode: '',
                productProperties: '',
                planPositionId: 1,
            },
            name: '',
            serial: '',
            baseSumma: '',
            profitRate: '',
            count: '',
            countTon: '',
            countKm: '',
            summa: '',
            exciseRate: '',
            exciseSum: '',
            deliverySum: '',
            vatRate: '',
            vatSum: '',
            deliverySumWithVat: '',
        });
    };

    const deleteItem = (index) => remove(index);

    const onBulkSetCatalog = (value) => {
        fields.map((field, index) => {
            form.setValue(`productList.products.${index}.catalog`, value);
            form.setValue(`productList.products.${index}.package`, null);
            form.setValue(`productList.products.${index}.measurement`, null);
            form.setValue(`productList.products.${index}.lgota`, null);
            form.setValue(`productList.products.${index}.origin`, null);
        });
    };

    const onBulkSetOrigin = (value) => {
        fields.map((field, index) => {
            form.setValue(`productList.products.${index}.origin`, value);
        });
    };

    const onBulkSetVatRate = (value) => {
        fields.map((field, index) => {
            form.setValue(`productList.products.${index}.vatRate`, value);
        });
    };

    const onBulkSetLgota = (value) => {
        fields.map((field, index) => {
            form.setValue(`productList.products.${index}.lgota`, value);
        });
    };

    const onBulkSetWarehouse = (value) => {
        fields.map((field, index) => {
            form.setValue(`productList.products.${index}.warehouseId`, value ? value.id : null);
        });
    };

    return (
        <Fragment>
            <div className="d-flex flex-wrap justify-content-between pb-0">
                <Form.Group className="switch-container mb-2 gap-2 align-items-center">
                    <Form.Check.Label
                        htmlFor={"hasExcise"}
                        className="mb-0 switch-label">{t(lang, "roaming.invoice.excise")}</Form.Check.Label>
                    <label className="switch">
                        <Form.Check type="switch" id={"hasExcise"} className="d-flex align-items-center">
                            <Form.Check.Input {...form.register(`productList.hasExcise`)} type="checkbox"/>
                            <span className="slider"></span>
                        </Form.Check>
                    </label>
                </Form.Group>

                <Form.Group className="switch-container mb-2 gap-2 align-items-center">
                    <Form.Check.Label
                        htmlFor={"hasMarking"}
                        className="mb-0 switch-label">{t(lang, "roaming.invoice.mark")}</Form.Check.Label>
                    <label className="switch">
                        <Form.Check type="switch" id={"hasMarking"} className="d-flex align-items-center">
                            <Form.Check.Input {...form.register(`productList.hasMarking`)} type="checkbox"/>
                            <span className="slider"></span>
                        </Form.Check>
                    </label>
                </Form.Group>

                <Form.Group className="switch-container mb-2 gap-2 align-items-center">
                    <Form.Check.Label
                        htmlFor={"medicalExecutor"}
                        className="mb-0 switch-label">{t(lang, "roaming.invoice.medicalExecutor")}</Form.Check.Label>
                    <label className="switch">
                        <Form.Check type="switch" id={"medicalExecutor"} className="d-flex align-items-center">
                            <Form.Check.Input {...form.register(`productList.hasMedical`)} type="checkbox"/>
                            <span className="slider"></span>
                        </Form.Check>
                    </label>
                </Form.Group>

                <Form.Group className="switch-container mb-2 gap-2 align-items-center">
                    <Form.Check.Label
                        htmlFor={"hasCommittent"}
                        className="mb-0 switch-label">{t(lang, "roaming.invoice.comissionAgent")}</Form.Check.Label>
                    <label className="switch">
                        <Form.Check type="switch" id={"hasCommittent"} className="d-flex align-items-center">
                            <Form.Check.Input {...form.register(`productList.hasCommittent`)} type="checkbox"/>
                            <span className="slider"></span>
                        </Form.Check>
                    </label>
                </Form.Group>

                <RoamingInvoiceFormHideReportCommittentSwitcher/>

                <Form.Group className="switch-container mb-2 gap-2 align-items-center">
                    <Form.Check.Label
                        htmlFor={"reversedCalculation"}
                        className="mb-0 switch-label">{t(lang, "roaming.invoice.reversed_calculation")}</Form.Check.Label>
                    <label className="switch">
                        <Form.Check type="switch" id={"reversedCalculation"} className="d-flex align-items-center">
                            <Form.Check.Input defaultChecked={reversedCalculation}
                                              onChange={(e) => setReversedCalculation(e.target.checked)}
                                              type="checkbox"/>
                            <span className="slider"></span>
                        </Form.Check>
                    </label>
                </Form.Group>

                <Form.Group className="switch-container mb-2 gap-2 align-items-center">
                    <Form.Check.Label
                        htmlFor={"manualCalculation"}
                        className="mb-0 switch-label">{t(lang, "roaming.invoice.manual_calculation")}</Form.Check.Label>
                    <label className="switch">
                        <Form.Check type="switch" id={"manualCalculation"} className="d-flex align-items-center">
                            <Form.Check.Input checked={manualCalculation}
                                              onChange={e => {
                                                  form.setValue('manualCalculation', e.target.checked)
                                              }}
                                              type="checkbox"/>
                            <span className="slider"></span>
                        </Form.Check>
                    </label>
                </Form.Group>
            </div>

            <Card className={'py-1 px-2 rounded-4 border-0'}>
                <Table responsive className="scrollbar view-table border-900 mb-0 fs-9 second-text fw-semi-bold">
                    <thead className="text-dark border-bottom">
                    <tr className="position-relative py-2 fs-9 second-text fw-semi-bold">
                        <th style={{minWidth: '30px', width: '30px'}}
                            className="fw-medium  align-middle text-center">№
                        </th>
                        {hasCommittent &&
                            <Fragment>
                                <th className="fw-medium  align-middle text-center ">
                                    {t(lang, 'roaming.invoice.comissionAgent.inn')}
                                    <span className="text-danger">*</span>
                                </th>
                                <th className="fw-medium  align-middle text-center">
                                    {t(lang, 'roaming.invoice.comissionAgent.name')}
                                    <span className="text-danger">*</span>
                                </th>
                                <th className="fw-medium  align-middle text-center">{t(lang, 'roaming.invoice.comissionAgent.nds_code')}</th>
                                <th className="fw-medium  align-middle text-center">{t(lang, 'roaming.invoice.comissionAgent.nds_status')}</th>
                            </Fragment>
                        }
                        <th className="fw-medium  align-middle text-center  border-">
                            {t(lang, 'roaming.common.items.product')}
                            <span className="text-danger">*</span>
                        </th>
                        <th style={{minWidth: '150px', width: '150px'}}
                            className="fw-medium  align-middle text-center ">
                            <div className="d-flex flex-column">
                                <CatalogLinkSelector defaultCatalog={null}
                                                     onChange={onBulkSetCatalog}
                                                     linkProps={{
                                                         className: classNames('text-primary cursor-pointer fs--1')
                                                     }}
                                />
                            </div>
                        </th>
                        <th style={{minWidth: '200px', width: '200px'}}
                            className="fw-medium  align-middle text-center ">
                            <div className="d-flex flex-column">
                                <OriginLinkSelector defaultOriginType={null}
                                                    onChange={onBulkSetOrigin}
                                                    linkProps={{
                                                        className: classNames('text-primary cursor-pointer fs--1')
                                                    }}
                                                    placeholder={t(lang, 'roaming.common.items.origin')}
                                />
                            </div>
                        </th>
                        {hasMarking &&
                            <th style={{minWidth: '200px', width: '200px'}}
                                className="fw-medium  align-middle text-center ">
                                {t(lang, 'roaming.invoice.mark')}
                            </th>
                        }
                        <th style={{minWidth: '140px', width: '140px'}}
                            className="fw-medium  align-middle text-center ">
                            {t(lang, "roaming.common.items.barcode")}
                        </th>
                        <th style={{minWidth: '150px', width: '150px'}}
                            className="fw-medium  align-middle text-center ">
                            {t(lang, 'roaming.common.items.measurement')}
                            <span className="text-danger">*</span>
                        </th>
                        <th style={{minWidth: '120px', width: '120px'}}
                            className="fw-medium  align-middle text-center ">
                            {t(lang, "roaming.common.items.quantity")}
                            <span className="text-danger">*</span>
                        </th>
                        {hasMedical &&
                            <Fragment>
                                <th style={{minWidth: '140px', width: '140px'}}
                                    className="fw-medium  align-middle text-center">
                                    {t(lang, 'roaming.common.items.medical.serial')}
                                </th>
                                <th style={{minWidth: '140px', width: '140px'}}
                                    className="fw-medium  align-middle text-center">
                                    {t(lang, 'roaming.common.items.medical.value')}
                                    <span className="text-danger">*</span>
                                </th>
                                <th style={{minWidth: '140px', width: '140px'}}
                                    className="fw-medium  align-middle text-center">
                                    {t(lang, 'roaming.common.items.medical.rate')}
                                </th>
                            </Fragment>
                        }
                        <th style={{minWidth: '140px', width: '140px'}}
                            className="fw-medium  align-middle text-center ">
                            {t(lang, "roaming.common.items.price")}
                            <span className="text-danger">*</span>
                        </th>
                        {hasExcise &&
                            <Fragment>
                                <th style={{minWidth: '140px', width: '140px'}}
                                    className="fw-medium  align-middle text-center">{t(lang, 'roaming.common.items.excise.rate', {icon: "%"})}</th>
                                <th style={{minWidth: '140px', width: '140px'}}
                                    className="fw-medium  align-middle text-center">{t(lang, 'roaming.common.items.excise')}</th>
                            </Fragment>
                        }
                        <th style={{minWidth: '150px', width: '150px'}}
                            className="fw-medium  align-middle text-center ">
                            {t(lang, "roaming.invoice.items.total")}
                        </th>
                        <th style={{minWidth: '100px', width: '100px'}}
                            className="fw-medium  align-middle text-center ">
                            <div className="d-flex flex-column">
                                {/*<span>{t(lang, "roaming.common.items.nds")}</span>*/}
                                <VatRateLinkSelector defaultVatRate={''}
                                                     onChange={onBulkSetVatRate}
                                                     linkProps={{
                                                         className: classNames('text-primary cursor-pointer fs--1')
                                                     }}
                                />
                            </div>
                        </th>
                        <th style={{minWidth: '150px', width: '150px'}}
                            className="fw-medium  align-middle text-center ">{t(lang, "roaming.common.items.nds_value")}</th>
                        <th style={{minWidth: '160px', width: '160px'}}
                            className="fw-medium  align-middle text-center ">{t(lang, "roaming.common.items.total_with_nds")}</th>
                        <th style={{minWidth: '130px', width: '130px'}}
                            className="fw-medium  align-middle text-center ">
                            <div className="d-flex flex-column">
                                <BenefitLinkSelector defaultBenefit={null}
                                                     benefits={[...sellerCompanyPrivileges, ...turnoverCompanyPrivileges]}
                                                     onChange={onBulkSetLgota}
                                                     linkProps={{
                                                         className: classNames('text-primary cursor-pointer fs--1')
                                                     }}
                                />
                            </div>
                        </th>
                        <th style={{minWidth: '100px', width: '100px'}}
                            className="fw-medium  align-middle text-center ">
                            <div className="d-flex flex-column">
                                <WarehouseLinkSelector defaultWarehouseId={null}
                                                       onChange={onBulkSetWarehouse}
                                                       linkProps={{
                                                           className: classNames('text-primary cursor-pointer fs--1 text-truncate')
                                                       }}
                                />
                            </div>
                        </th>
                        <th style={{minWidth: '30px', width: '30px'}}
                            className=" align-middle text-center sticky-column">
                            <IconButton icon={faPlus}
                                        variant={'phoenix-primary'}
                                        onClick={addNewItem}
                                        className={'bg-primary-davr py-2 px-2 rounded-3'}
                                        iconClassName={'text-white'}
                            />
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {fields.map((field, index) => (
                        <RoamingInvoiceFormItemRow key={field.id}
                                                   hasExcise={hasExcise}
                                                   hasMarking={hasMarking}
                                                   hasMedical={hasMedical}
                                                   hasCommittent={hasCommittent}
                                                   reversedCalculation={reversedCalculation}
                                                   manualCalculation={manualCalculation}
                                                   fieldName={`productList.products.${index}`}
                                                   index={index}
                                                   insertNewItem={insertNewItem}
                                                   deleteItem={deleteItem}
                                                   allowDeleteItem={fields.length > 1}
                                                   sellerCompanyPrivileges={sellerCompanyPrivileges}
                                                   turnoverCompanyPrivileges={turnoverCompanyPrivileges}
                        />
                    ))}
                    </tbody>
                </Table>
                <Row className="g-0 justify-content-end">
                    <Col xs="auto">
                        <Table borderless size="sm" className="fs-9 text-end">
                            <tbody>
                            <tr>
                                <th className="text-900">{t(lang, 'roaming.common.subtotal')}:</th>
                                <td className="fw-medium">
                                    {numeral(products.reduce((s, product) => {
                                        return s + getNumberValue(product.deliverySum);
                                    }, 0)).format(numberFormat)}
                                </td>
                            </tr>
                            <tr>
                                <th className="text-900">{t(lang, 'roaming.common.tax')}:</th>
                                <td className="fw-medium">
                                    {numeral(products.reduce((s, product) => {
                                        return s + getNumberValue(product.vatSum);
                                    }, 0)).format(numberFormat)}
                                </td>
                            </tr>
                            {hasExcise &&
                                <tr>
                                    <th className="text-900">{t(lang, 'roaming.invoice.excise_total')}:</th>
                                    <td className="fw-medium">
                                        {numeral(products.reduce((s, product) => {
                                            return s + getNumberValue(product.exciseSum);
                                        }, 0)).format(numberFormat)}
                                    </td>
                                </tr>
                            }
                            {/*
                                {hasMedical &&
                                    <tr>
                                        <th className="text-900">{t(lang, 'roaming.invoice.medical_total')}:</th>
                                        <td className="fw-medium">
                                            {numeral.formats[numberFormat].format(medical)}
                                        </td>
                                    </tr>
                                } */}
                            <tr className="border-top">
                                <th className="text-900">{t(lang, 'roaming.common.total')}:</th>
                                <td className="fw-medium">
                                    {numeral(products.reduce((s, product) => {
                                        return s + getNumberValue(product.deliverySumWithVat);
                                    }, 0)).format(numberFormat)}
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Card>
        </Fragment>
    );
};

export default RoamingInvoiceFormCardItems;
