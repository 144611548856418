import React, {Fragment, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {selectAccountRolePermissions} from "../../../store/reducers/role/roleReducer";
import checkPermission from "../../../enum/permission/CheckPermission";
import {PermissionRoamingEmpowermentView} from "../../../enum/permission/RoamingPermission";
import EventBus from "../../../eventbus/EventBus";
import {
    CUSTOMER_CANCEL_EMPOWERMENT_SUCCESS, EXECUTOR_ACCEPT_EMPOWERMENT_SUCCESS, EXECUTOR_DECLINE_EMPOWERMENT_SUCCESS,
    PERSON_ACCEPT_EMPOWERMENT_SUCCESS, PERSON_DECLINE_EMPOWERMENT_SUCCESS, SYNC_EMPOWERMENT_SUCCESS
} from "../../../eventbus/romaing/empowerment/empowermentEvents";
import {getOrganizationBillingInfoAsync} from "../../../store/reducers/billing/billingReducer";
import {
    loadDraftEmpowerment,
    loadEmpowermentAsync
} from "../../../store/reducers/roaming/empowerment/empowermentReducer";
import Empowerment from "../../../components/roaming/empowerments/Empowerment";
import SpinnerComponent from "../../../components/common/SpinnerComponent";

const ViewEmpowerment = ({modalId}) => {
    const {id} = useParams();
    const activeOrganization = useSelector(selectActiveOrganization);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const permission = useSelector(selectAccountRolePermissions)

    const [empowerment, setEmpowerment] = useState(null);

/*    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingEmpowermentView))) {
            navigate(-1)
        }
    }, [permission])*/

    const loadEmpowerment = () => {
        loadEmpowermentAsync(modalId ? modalId : id)
            .then(loadedEmpowerment => {
                setEmpowerment(loadedEmpowerment)
            })
            .catch(errors => console.log(errors))
    };

    useEffect(() => {
        loadEmpowerment()
    }, [modalId, id, activeOrganization]);

    useEffect(() => {
        const onCustomerCancelEmpowermentSuccessHandler = EventBus.on(CUSTOMER_CANCEL_EMPOWERMENT_SUCCESS, () => {
            loadEmpowerment();
        })

        const onPersonAcceptEmpowermentSuccessHandler = EventBus.on(PERSON_ACCEPT_EMPOWERMENT_SUCCESS, () => {
            dispatch(getOrganizationBillingInfoAsync());
            loadEmpowerment();
        })

        const onPersonDeclineEmpowermentSuccessHandler = EventBus.on(PERSON_DECLINE_EMPOWERMENT_SUCCESS, () => {
            loadEmpowerment();
        })

        const onExecutorAcceptEmpowermentSuccessHandler = EventBus.on(EXECUTOR_ACCEPT_EMPOWERMENT_SUCCESS, () => {
            dispatch(getOrganizationBillingInfoAsync());
            loadEmpowerment();
        })

        const onExecutorDeclineEmpowermentSuccessHandler = EventBus.on(EXECUTOR_DECLINE_EMPOWERMENT_SUCCESS, () => {
            loadEmpowerment();
        })

        const onSyncEmpowermentSuccessHandler = EventBus.on(SYNC_EMPOWERMENT_SUCCESS, () => {
            loadEmpowerment();
        })

        return () => {
            EventBus.remove(CUSTOMER_CANCEL_EMPOWERMENT_SUCCESS, onCustomerCancelEmpowermentSuccessHandler);
            EventBus.remove(PERSON_ACCEPT_EMPOWERMENT_SUCCESS, onPersonAcceptEmpowermentSuccessHandler);
            EventBus.remove(PERSON_DECLINE_EMPOWERMENT_SUCCESS, onPersonDeclineEmpowermentSuccessHandler);
            EventBus.remove(EXECUTOR_ACCEPT_EMPOWERMENT_SUCCESS, onExecutorAcceptEmpowermentSuccessHandler);
            EventBus.remove(EXECUTOR_DECLINE_EMPOWERMENT_SUCCESS, onExecutorDeclineEmpowermentSuccessHandler);
            EventBus.remove(SYNC_EMPOWERMENT_SUCCESS, onSyncEmpowermentSuccessHandler);
        }
    }, [])

    return (
        <Fragment>
            {empowerment && <Empowerment modalId={modalId} empowerment={empowerment}/>}
            {!empowerment && <SpinnerComponent />}
        </Fragment>
    );
};

export default ViewEmpowerment;
