import React, {useState} from 'react';
import {executorCancelAsync} from "../../../store/reducers/roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import {selectCurrentCertificate} from "../../../store/reducers/eimzo/eimzoReducer";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import EventBus from "../../../eventbus/EventBus";
import {
    ROAMING_INVOICE_BULK_ACTION_FINISHED,
    ROAMING_INVOICE_BULK_ACTION_STARTED
} from "../../../eventbus/romaing/roaming-invoice/roamingInvoiceEvents";
import SelectCertificateWrapperComponent from "../../common/SelectCertificateWrapperComponent";
import {PermissionRoamingInvoiceReject} from "../../../enum/permission/RoamingPermission";
import IconButton from "../../IconButton";
import {faCancel} from "@fortawesome/free-solid-svg-icons";
import IconCancel from "../../../assets/icon/IconCancel";
import IconFolderCancel from "../../../assets/icon/IconFolderCancel";

const RoamingInvoiceExecutorCancel = ({id, invoices, handleShowProgressBar, updateActionResult, ...props}) => {

    const lang = useSelector(selectLang);
    const t = Translate;
    const activeCertificate = useSelector(selectCurrentCertificate);

    // button loader
    const [loading, setLoading] = useState(false);

    // cancel invoice
    const executorCancel = () => {
        if (invoices.length > 0)
            bulkCancel();
        else
            singleCancel();
    };

    // cancel single document
    const singleCancel = () => {
        setLoading(true);
        executorCancelAsync(activeCertificate, lang, id)
            .then(() => {
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    // bulk cancel documents
    const bulkCancel = async () => {
        handleShowProgressBar();
        EventBus.dispatch(ROAMING_INVOICE_BULK_ACTION_STARTED);
        for (let i = 0; i < invoices.length; i++) {
            const invoice = invoices[i];
            try {
                await executorCancelAsync(activeCertificate, lang, invoices[i].id);
                updateActionResult({
                    number: invoice.info.number,
                    date: invoice.info.date,
                    contractor: {
                        name: invoice.customer.name,
                        inn: invoice.customer.inn
                    }
                });
            } catch (error) {
                updateActionResult({
                    number: invoice.info.number,
                    date: invoice.info.date,
                    contractor: {
                        name: invoice.customer.name,
                        inn: invoice.customer.inn
                    },
                    error: error?.message
                })
            }
        }
        EventBus.dispatch(ROAMING_INVOICE_BULK_ACTION_FINISHED);
    }

    return (
        <SelectCertificateWrapperComponent
            permission={PermissionRoamingInvoiceReject}
            openDialogButtonText={t(lang, "roaming.common.cancel")}
            className={'btn-davr bg-white text-dark'}
            icon={<IconFolderCancel />}
            submitButton={
                <IconButton
                    id="ExecutorCancel"
                    onClick={executorCancel}
                    variant="phoenix-danger"
                    size="sm"
                    icon={<IconFolderCancel />}
                    className="fw-semi-bold"
                    disabled={loading}
                    {...props}
                >
                    {t(lang, "roaming.common.cancel")}
                </IconButton>}
        />

    );
};

export default RoamingInvoiceExecutorCancel;
