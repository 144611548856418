import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectTransports} from "../../../store/reducers/roaming/waybill-v2/romaingWaybillV2Reducer";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import CustomFloatingLabelSelect from "../../floating-custom-select/CustomFloatingLabelSelect";

const SelectTransport = ({onChange, defaultTransportNumber, placeholder, ...props}) => {


    const transports = useSelector(selectTransports)
    const lang = useSelector(selectLang);
    const t = Translate;

    const [value, setValue] = useState(null)

    useEffect(() => {
        if  (transports.length === 1) {
            setValue(transports[0])
            onChange(transports[0])
        } else {
            setValue(transports.find(i => i.registration_number === defaultTransportNumber))
        }
    }, [transports, defaultTransportNumber]);

    const handleChange = (option) => {
        onChange(option)
        setValue(option)
    }

    return (
        <CustomFloatingLabelSelect
            label={placeholder}
            value={value}
            onChange={handleChange}
            options={transports}
            getOptionLabel={(option) => `${option.model} - ${option.registration_number}`}
            getOptionValue={(option) => option.registration_number}
            hideSelectedOptions
            isClearable
            {...props}
        />
    );
};

export default SelectTransport;
