import React, {useEffect, useState} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {selectDateFormat, selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {Col, Row, Form} from "react-bootstrap";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import FindCompanySearchable from "../../../organization/FindCompanySearchable";

const EdiContractForm = ({existNumber}) => {
    const {register, formState: {errors}, control, setValue, getValues} = useFormContext();
    const [defaultInn, setDefaultInn] = useState('');
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    const onCustomerChange = company => {
        if (company) {
            setValue('customer.inn', company.inn);
            setValue('customer.name', company.name);
        } else {
            setValue('customer.inn', '');
            setValue('customer.name', '');
        }
    };

    useEffect(() => {
        const inn = getValues('customer.inn');
        if (inn) {
            setDefaultInn(inn);
        }
    }, []);

    return (
        <Row>
            <Col xs={12} lg={6}>
                <div className="bg-200 p-3">
                    <Row>
                        <Col xs={12} lg={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t(lang, 'edi.contract.form.contract_number')}</Form.Label>
                                <Form.Control
                                    type='text'
                                    className='input-spin-none'
                                    placeholder={t(lang, 'edi.contract.form.contract_number')}
                                    isInvalid={errors.info?.number}
                                    {...register('info.number', {
                                        required: t(lang, 'edi.common.forms.validations.is_required'),
                                        validate: {
                                            existNumber: value => {
                                                if (existNumber && existNumber === value)
                                                    return t(lang, 'edi.contract.form.validations.exist_contract_number', {value: value});

                                                return true;
                                            }
                                        }
                                    })}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.info?.number?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col xs={12} lg={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t(lang, 'edi.common.customer.name')}</Form.Label>
                                <Form.Control
                                    type='text'
                                    readOnly={true}
                                    isInvalid={errors.customer?.name}
                                    placeholder={t(lang, 'edi.common.customer.name.placeholder')}
                                    {...register('customer.name', {required: t(lang, 'edi.common.forms.validations.is_required')})}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.customer?.name.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12}>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t(lang, 'edi.common.executor.name')}</Form.Label>
                                <Form.Control
                                    type='text'
                                    disabled={true}
                                    className={'bg-300'}
                                    placeholder={t(lang, 'edi.common.executor.name.placeholder')}
                                    {...register('executor.name')}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
            </Col>

            <Col xs={12} lg={6}>
                <div className="bg-200 p-3">
                    <Row>
                        <Col xs={12} lg={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t(lang, 'edi.contract.form.contract_date')}</Form.Label>
                                <Controller
                                    control={control}
                                    name="info.date"
                                    rules={{required: t(lang, 'edi.common.forms.validations.is_required')}}
                                    render={
                                        ({field}) => (
                                            <DatePicker
                                                isClearable
                                                selected={field.value}
                                                dateFormat={dateFormat}
                                                wrapperClassName={classNames({
                                                    'is-invalid': errors?.info?.date
                                                })}
                                                className={classNames("form-control", {
                                                    'is-invalid': errors?.info?.date
                                                })}
                                                onChange={field.onChange}
                                                placeholderText={t(lang, 'edi.common.forms.date.placeholder')}
                                            />
                                        )
                                    }
                                />

                                <Form.Control.Feedback type='invalid'>
                                    {errors.info?.date?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t(lang, 'edi.common.customer.inn')}</Form.Label>
                                <Controller name="customer.inn"
                                            render={({field}) => (
                                                <FindCompanySearchable
                                                    onChange={onCustomerChange}
                                                    defaultInn={defaultInn}
                                                    inputProps={{
                                                        isInvalid: !!errors?.customer?.inn,
                                                        placeholder: t(lang, 'edi.common.customer.inn.placeholder')
                                                    }}
                                                    inputGroupProps={{
                                                        className: classNames({'is-invalid': !!errors?.customer?.inn})
                                                    }}
                                                    ref={field.ref}
                                                />
                                            )}
                                            control={control}
                                            rules={{
                                                required: t(lang, 'edi.common.forms.validations.is_required')
                                            }}
                                />
                                <Form.Control.Feedback type="invalid">{errors?.customer?.inn?.message}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12}>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t(lang, 'edi.common.executor.inn')}</Form.Label>
                                <Form.Control
                                    type='number'
                                    disabled={true}
                                    placeholder={t(lang, 'edi.common.executor.inn.placeholder')}
                                    className='input-spin-none bg-300'
                                    {...register('executor.inn')}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    );
};

export default EdiContractForm;
