import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {FormProvider, useForm} from "react-hook-form";
import {activateCertificateAsync, createPkcs7WithTimestamp} from "../../../../store/reducers/eimzo/eimzoReducer";
import {
    getRegistrationVerifyConfirmationTicketAsync,
    getRegistrationVerifyHashCodeAsync,
    registerAsync
} from "../../../../store/reducers/auth/authReducer";
import toast from "react-hot-toast";
import {Button, Form} from "react-bootstrap";
import RegistrationFormStepOne from "./RegistrationFormStepOne";
import RegistrationFormStepTwo from "./RegistrationFormStepTwo";
import PropTypes from "prop-types";
import IconAlert from "../../../common/IconAlert";
import Flex from "../../../common/Flex";

const RegistrationForm = ({hasLabel, header}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            company: {
                name: '',
                inn: '',
                certificate: null,
            },
            account: {
                name: '',
                username: '',
                password: '',
                confirmPassword: '',
                confirmCode1: '',
                confirmCode2: '',
                confirmCode3: '',
                confirmCode4: '',
                confirmCode5: '',
                confirmCode6: '',
            },
            terms: null
        }
    });

    const [step, setStep] = useState(1);
    const [invalidConfirmCode, setInvalidConfirmCode] = useState(null);
    const [confirmationTicket, setConfirmationTicket] = useState(null);
    const [signatureHashCode, setSignatureHashCode] = useState(null);
    const [invalidCertificatePassword, setInvalidCertificatePassword] = useState(false);
    const [certificateEnterPasswordCanceled, setCertificateEnterPasswordCanceled] = useState(false);
    const [somethingWentWrong, setSomethingWentWrong] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [activeCertificate, setActiveCertificate] = useState(null);

    useEffect(() => {
        if (activeCertificate) {
            createPkcs7WithTimestamp(signatureHashCode, activeCertificate.keyId)
                .then(({pkcs7}) => {
                    const formData = methods.getValues();
                    const {
                        confirmCode1,
                        confirmCode2,
                        confirmCode3,
                        confirmCode4,
                        confirmCode5,
                        confirmCode6
                    } = formData.account
                    let username = formData.account?.username?.replace(/ /g, '')
                    let confirmCode = confirmCode1 + confirmCode2 + confirmCode3 + confirmCode4 + confirmCode5 + confirmCode6
                    username = '998' + username;

                    registerAsync({
                        inn: formData.company.inn,
                        name: formData.company.name,
                        signature: pkcs7,
                        username: username,
                        fullName: formData.account.name,
                        password: formData.account.password,
                        confirmCode: confirmCode,
                        confirmTicket: confirmationTicket,
                    })
                        .then(() => {
                            methods.reset();
                        }, ({invalidConfirmationCode, referralAgentNotFound}) => {
                            if (invalidConfirmationCode) {
                                setButtonDisabled(false);
                                setInvalidConfirmCode(formData.account.confirmCode);
                                methods.trigger();
                            }
                            if (referralAgentNotFound) {
                                toast.error(t(lang, 'authentication.register.agent.code.error'))
                            } else {
                                setSomethingWentWrong(true);
                            }
                        })
                }, () => {
                    setSomethingWentWrong(true);
                })
        }
    }, [activeCertificate]);

    useEffect(() => {
        const formData = methods.getValues();
        if (signatureHashCode) {
            setButtonDisabled(true);

            activateCertificateAsync(formData.company.certificate)
                .then(activatedCertificate => {
                    setActiveCertificate(activatedCertificate);
                }, ({invalidPassword, canceled}) => {
                    if (invalidPassword) {
                        setInvalidCertificatePassword(true)
                    } else if (canceled) {
                        setCertificateEnterPasswordCanceled(canceled);
                    } else {
                        setSomethingWentWrong(true);
                    }
                });
        }
    }, [signatureHashCode]);

    const onSubmit = async formData => {

        let username = formData.account?.username?.replace(/ /g, '')
        username = '998' + username;

        switch (step) {
            case 1:
                getRegistrationVerifyConfirmationTicketAsync(username)
                    .then(ticket => {
                        setConfirmationTicket(ticket);
                        setStep(step + 1);
                    }, () => {
                        setSomethingWentWrong(true);
                    })
                break;

            case 2:
                getRegistrationVerifyHashCodeAsync(formData.company.inn)
                    .then(hashCode => {
                        setSignatureHashCode(hashCode);
                    }, () => {
                        setSomethingWentWrong(true);
                    });
                break;
        }
    };

    const onRetryClicked = () => {
        setSomethingWentWrong(false);
        setCertificateEnterPasswordCanceled(false);
        setInvalidCertificatePassword(false);
        setButtonDisabled(false);
    }

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)} className={"h-100"}>
                {somethingWentWrong && <IconAlert variant="danger" className={'alert-davr-danger mb-2'} dismissible
                                                  onClose={onRetryClicked}>
                    <p className="mb-0">{t(lang, 'authorization.register.something_went_wrong')}</p>
                </IconAlert>}

                {certificateEnterPasswordCanceled &&
                    <IconAlert variant="primary" className={'alert-davr-primary mb-2'} dismissible
                               onClose={onRetryClicked}>
                        <p className="mb-0">{t(lang, 'authorization.register.eimzo_enter_password_canceled')}</p>
                    </IconAlert>}

                {invalidCertificatePassword &&
                    <IconAlert variant="primary" className={'alert-davr-primary mb-2'} dismissible
                               onClose={onRetryClicked}>
                        <p className="mb-0">{t(lang, 'authorization.register.entered_wrong_password')}</p>
                    </IconAlert>}
                {header}
                <Flex className={"flex-column justify-content-between scrollbar"} style={{height: "calc(100% - 55px)"}}>
                    <div>
                        {step === 1 && <RegistrationFormStepOne hasLabel={hasLabel}/>}
                        {step === 2 &&
                            <RegistrationFormStepTwo hasLabel={hasLabel} invalidConfirmCode={invalidConfirmCode}/>}
                    </div>
                    <Form.Group>
                        <Button className="mt-3 w-100 bg-primary-davr" type="submit"
                                disabled={buttonDisabled}> {step === 1 ? t(lang, 'authorization.register.button.next') : t(lang, 'authorization.register.button.register')} </Button>
                    </Form.Group>
                </Flex>
            </Form>
        </FormProvider>
    );
};

RegistrationForm.propTypes = {
    hasLabel: PropTypes.bool
};

RegistrationForm.defaultValue = {
    hasLabel: false
};

export default RegistrationForm;
