import React from 'react';

const IconStroke = ({width = 24, height = 24, color = "none"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 1 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 0V24" stroke="#9BA2B1" strokeOpacity="0.1"/>
        </svg>
    );
};

export default IconStroke;
