export const ADD_ITEM_REQUESTED = "ADD_ITEM_REQUESTED";
export const ADD_ITEM_SUCCESS = "ADD_ITEM_SUCCESS";
export const ADD_ITEM_FAILED = "ADD_ITEM_FAILED";

export const BULK_ADD_ITEMS_STARTED = "BULK_ADD_ITEMS_STARTED";
export const BULK_ADD_ITEMS_FINISHED = "BULK_ADD_ITEMS_FINISHED";

export const BULK_EDIT_ITEMS_STARTED = "BULK_EDIT_ITEMS_STARTED";
export const BULK_EDIT_ITEMS_FINISHED = "BULK_EDIT_ITEMS_FINISHED";

export const EDIT_ITEM_REQUESTED = "EDIT_ITEM_REQUESTED";
export const EDIT_ITEM_SUCCESS = "EDIT_ITEM_SUCCESS";
export const EDIT_ITEM_FAILED = "EDIT_ITEM_FAILED";

export const DELETE_ITEM_REQUESTED = "DELETE_ITEM_REQUESTED";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";
export const DELETE_ITEM_FAILED = "DELETE_ITEM_FAILED";

export const BULK_DELETE_ITEMS_STARTED = "BULK_DELETE_ITEMS_STARTED"
export const BULK_DELETE_ITEMS_FINISHED = "BULK_DELETE_ITEMS_FINISHED"

export const ADD_ITEM_IMAGES_REQUESTED = "ADD_ITEM_IMAGES_REQUESTED";
export const ADD_ITEM_IMAGES_SUCCESS = "ADD_ITEM_IMAGES_SUCCESS";
export const ADD_ITEM_IMAGES_FAILED = "ADD_ITEM_IMAGES_FAILED";

export const DELETE_ITEM_IMAGES_REQUESTED = "DELETE_ITEM_IMAGES_REQUESTED";
export const DELETE_ITEM_IMAGES_SUCCESS = "DELETE_ITEM_IMAGES_SUCCESS";
export const DELETE_ITEM_IMAGES_FAILED = "DELETE_ITEM_IMAGES_FAILED";

export const VALIDATE_ITEM_CATALOGS_REQUESTED = "VALIDATE_ITEM_CATALOGS_REQUESTED";
export const VALIDATE_ITEM_CATALOGS_SUCCESS = "VALIDATE_ITEM_CATALOGS_SUCCESS";
export const VALIDATE_ITEM_CATALOGS_FAILED = "VALIDATE_ITEM_CATALOGS_FAILED";

export const VALIDATE_ITEM_CATALOG_REQUESTED = "VALIDATE_ITEM_CATALOG_REQUESTED";
export const VALIDATE_ITEM_CATALOG_SUCCESS = "VALIDATE_ITEM_CATALOG_SUCCESS";
export const VALIDATE_ITEM_CATALOG_FAILED = "VALIDATE_ITEM_CATALOG_FAILED";

export const CHANGE_SETTINGS_SUCCESS = "CHANGE_SETTINGS_SUCCESS";

export const DELETE_DRAFT_ITEM_REQUESTED = "DELETE_DRAFT_ITEM_REQUESTED";
export const DELETE_DRAFT_ITEM_SUCCESS = "DELETE_DRAFT_ITEM_SUCCESS";
export const DELETE_DRAFT_ITEM_FAILED = "DELETE_DRAFT_ITEM_FAILED";

export const BULK_DELETE_DRAFT_ITEMS_REQUESTED = "BULK_DELETE_DRAFT_ITEMS_REQUESTED";
export const BULK_DELETE_DRAFT_ITEMS_SUCCESS = "BULK_DELETE_DRAFT_ITEMS_SUCCESS";

export const APPROVE_DRAFT_ITEM_REQUESTED = "APPROVE_DRAFT_ITEM_REQUESTED";
export const APPROVE_DRAFT_ITEM_SUCCESS = "APPROVE_DRAFT_ITEM_SUCCESS";
export const APPROVE_DRAFT_ITEM_FAILED = "APPROVE_DRAFT_ITEM_FAILED";

export const BIND_DRAFT_ITEM_REQUESTED = "BIND_DRAFT_ITEM_REQUESTED";
export const BIND_DRAFT_ITEM_SUCCESS = "BIND_DRAFT_ITEM_SUCCESS";
export const BIND_DRAFT_ITEM_FAILED = "BIND_DRAFT_ITEM_FAILED";

export const ADD_CATEGORY_REQUESTED = "ADD_CATEGORY_REQUESTED";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_FAILED = "ADD_CATEGORY_FAILED";

export const EDIT_CATEGORY_REQUESTED = "EDIT_CATEGORY_REQUESTED";
export const EDIT_CATEGORY_SUCCESS = "EDIT_CATEGORY_SUCCESS";
export const EDIT_CATEGORY_FAILED = "EDIT_CATEGORY_FAILED";

export const DELETE_CATEGORY_REQUESTED = "DELETE_CATEGORY_REQUESTED";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILED = "DELETE_CATEGORY_FAILED";

export const BULK_DELETE_CATEGORY_REQUESTED = "BULK_DELETE_CATEGORY_REQUESTED";
export const BULK_DELETE_CATEGORY_SUCCESS = "BULK_DELETE_CATEGORY_SUCCESS";
export const BULK_DELETE_CATEGORY_FAILED = "BULK_DELETE_CATEGORY_FAILED";

export const SCAN_SUCCESS = "SCAN_SUCCESS";
export const SCAN_FAILED = "SCAN_FAILED";

export const DELETE_PRODUCT_MAP_IDENTITY_REQUESTED = "DELETE_PRODUCT_MAP_IDENTITY_REQUESTED";
export const DELETE_PRODUCT_MAP_IDENTITY_SUCCESS = "DELETE_PRODUCT_MAP_IDENTITY_SUCCESS";
export const DELETE_PRODUCT_MAP_IDENTITY_FAILED = "DELETE_PRODUCT_MAP_IDENTITY_FAILED";

export const PUSH_UPDATE_ITEM_STATE_UPDATED = 'PUSH_UPDATE_ITEM_STATE_UPDATED'
