import React, {useEffect, useState} from 'react';
import ReactSelect from "react-select";
import {Translate} from "../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import CustomFloatingLabelSelect from "../floating-custom-select/CustomFloatingLabelSelect";

const ContractorBranchSelect = ({options, onChange, placeholder, id, value, ...props}) => {

    const t = Translate;
    const lang = useSelector(selectLang);

    const handleChange = (option) => {
        onChange(option)
    }

    return (
            <CustomFloatingLabelSelect
                options={options}
                label={placeholder}
                noOptionsMessage={() => t(lang, "roaming.invoices.datatable.filter.branches_not_found")}
                hideSelectedOptions
                value={value}
                onChange={handleChange}
                {...props}
            />
    );
};

export default ContractorBranchSelect;
