import * as OrderExecutorEditStrategy from "./OrderExecutorEditStrategyType";
import * as OrderExecutorCreateInvoiceStrategy from "./OrderExecutorCreateInvoiceStrategyType";
import * as OrderShipmentDateMethodStrategy from './OrderShipmentDateMethodStrategyType';
import * as OrderProductNameDefineMethodStrategy from './OrderProductNameDefineMethodStrategyType';
import * as OrderContractDefineMethodStrategy from './OrderContractDefineMethodStrategyType';
import * as OrderItemQuantityLimitStrategy from './OrderItemQuantityLimitStrategyType';

export const OrderExecutorEditStrategies = Object.keys(OrderExecutorEditStrategy).map(key => OrderExecutorEditStrategy[key]);
export const OrderExecutorCreateInvoiceStrategies = Object.keys(OrderExecutorCreateInvoiceStrategy).map(key => OrderExecutorCreateInvoiceStrategy[key]);
export const OrderShipmentDateMethodStrategies = Object.keys(OrderShipmentDateMethodStrategy).map(key => OrderShipmentDateMethodStrategy[key]);
export const OrderProductNameDefineMethodStrategies = Object.keys(OrderProductNameDefineMethodStrategy).map(key => OrderProductNameDefineMethodStrategy[key]);
export const OrderContractDefineMethodStrategies = Object.keys(OrderContractDefineMethodStrategy).map(key => OrderContractDefineMethodStrategy[key]);
export const OrderItemQuantityLimitStrategies = Object.keys(OrderItemQuantityLimitStrategy).map(key => OrderItemQuantityLimitStrategy[key]);

export const selectOrderExecutorEditStrategy = id => OrderExecutorEditStrategies.find(s => s.id === id);
export const selectOrderExecutorCreateInvoiceStrategy = id => OrderExecutorCreateInvoiceStrategies.find(s => s.id === id);
export const selectOrderShipmentDateMethodStrategy = id => OrderShipmentDateMethodStrategies.find(s => s.id === id);
export const selectOrderProductNameDefineMethodStrategy = id => OrderProductNameDefineMethodStrategies.find(s => s.id === id);
export const selectOrderContractDefineMethodStrategy = id => OrderContractDefineMethodStrategies.find(s => s.id === id);
export const selectOrderItemQuantityLimitStrategy = id => OrderItemQuantityLimitStrategies.find(s => s.id === id);
