import React from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectCurrentCertificate} from "../../../../store/reducers/eimzo/eimzoReducer";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import IconButton from "../../../IconButton";
import IconFolderCancel from "../../../../assets/icon/IconFolderCancel";
import SignActionWithSelectCertificateWrapper from "../../../common/SignActionWithSelectCertificateWrapper";
import {Form} from "react-bootstrap";

const ActCancel = ({ActCancelHandler, disabled, ...props}) => {
    const methods = useForm();
    const currentCertificate = useSelector(selectCurrentCertificate);
    const lang = useSelector(selectLang);
    const t = Translate;

    const formSubmit = () => {
        if(currentCertificate?.keyId) {
            ActCancelHandler()
        }
    };
    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(formSubmit)} className="d-inline-flex" id="ExecutorCancelForm">
                <SignActionWithSelectCertificateWrapper isForm>
                    <IconButton
                        variant="phoenix-danger"
                        size="sm"
                        disabled={disabled}
                        type="submit"
                        form="ExecutorCancelForm"
                        className={'btn-davr bg-white text-dark d-flex gap-2'}
                        iconClassName={"text-primary-davr fs-8"}
                        {...props}
                    >
                        <IconFolderCancel />
                        {t(lang,"edi.common.cancel_decline")}</IconButton>
                </SignActionWithSelectCertificateWrapper>
            </Form>
        </FormProvider>
    );
};

export default ActCancel;
