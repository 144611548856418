import React from 'react';
import Badge from "./Badge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWarning} from "@fortawesome/free-solid-svg-icons";

const PermissionBadge = ({children}) => {
    return (
        <Badge bg={'info'} variant={'phoenix'} icon={<FontAwesomeIcon icon={faWarning} />} className="d-flex align-items-center justify-content-center fs-6  gap-4 w-100 p-3 text-wrap">
            {children}
        </Badge>
    );
};

export default PermissionBadge;
