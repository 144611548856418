import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../store/reducers/auth/authReducer";
import {
    loadRoamingContractsAsync, loadRoamingContractsCountAsync, selectCount,
    selectRoamingContracts
} from "../../store/reducers/roaming/contracts/roamingContractReducer";
import {selectLang, selectNumberFormat} from "../../store/reducers/main/main";
import {useNavigate} from "react-router-dom";
import {Translate} from "../../lang/lang";
import dayjs from "dayjs";
import {GetViewerText, VIEW_DRAFT, VIEW_INBOX, VIEW_OUTBOX} from "../../enum/roaming/TypeViewers";
import Badge from "../../components/common/Badge";
import IconFolderDownload from "../../assets/icon/IconFolderDownload";
import numeral from "numeral";
import {ContractWrapperClass} from "../../enum/roaming/contract/ContractStatus";
import RevealDropdown, {RevealDropdownTrigger} from "../../components/common/RevealDropdown";
import {Card, Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faEye} from "@fortawesome/free-solid-svg-icons";
import AdvanceTableWrapper from "../../components/table/AdvanceTableWrapper";
import Flex from "../../components/common/Flex";
import AdvanceTable from "../../components/table/AdvanceTable";
import IconTransaction from "../../assets/icon/IconTransaction";
import DashboardDatatable from "./DashboardDatatable";

const DashboardContractDatatable = ({setContractCount}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const activeOrganization = useSelector(selectActiveOrganization);
    const roamingContracts = useSelector(selectRoamingContracts);
    const numberFormat = useSelector(selectNumberFormat);
    const count = useSelector(selectCount)
    const lang = useSelector(selectLang);
    const t = Translate;
    const activeOrganizationPinflOrInn = activeOrganization?.pinfl || activeOrganization?.inn

    const [viewer, setViewer] = useState("inbox")

    useEffect(() => {
        let params = {skip: 1, limit: 3, type_viewer: viewer}
        dispatch(loadRoamingContractsAsync(params));
        dispatch(loadRoamingContractsCountAsync({...params}))
    }, [activeOrganization, viewer]);

    useEffect(() => {
        setContractCount(count)
    }, [count]);

    const columns = [
        {
            accessor: 'contractor',
            Header: () => t(lang, "roaming.contracts.datatable.header.row.contractor"),
            cellProps: {
                className: 'ps-3'
            },
            headerProps: {width: 300, className: 'ps-3'},
            Cell: ({row: {original}}) => {
                return viewer === VIEW_INBOX ? <div className={'d-flex align-items-center gap-2'}>
                        <IconTransaction />
                        <div>
                             <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, 'roaming.empowerment.contact_info.from.contact_date', {
                                 "contract_number": original?.info?.number,
                                 "contract_date": original?.info?.date
                             })}
                             </span>
                            <span className={'fs-8 main-text fw-bold text-truncate'}>{original.executor.name}</span>
                            <i className={'d-block fs-9 second-text'}>{original.executor.inn}</i>
                        </div>
                    </div> :
                    <div className={'d-flex align-items-center gap-2'}>
                        <IconTransaction />
                        <div>
                             <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, 'roaming.empowerment.contact_info.from.contact_date', {
                                 "contract_number": original?.info?.number,
                                 "contract_date": original?.info?.date
                             })}
                             </span>
                            <span className={'fs-8 main-text fw-bold text-truncate'}>{original.contractors[0].name}</span>
                            <i className={'d-block fs-9 second-text'}>{original.contractors[0].inn}</i>
                        </div>
                    </div>
            }
        },
        {
            accessor: 'total_sum',
            Header: () => t(lang, "roaming.contracts.datatable.header.row.total_sum"),
            cellProps: {className: 'text-end'},
            headerProps: {className: 'text-end'},
            Cell: ({row: {original}}) => {
                if (viewer === VIEW_DRAFT) {
                    return (
                        <span className={'d-block text-end fw-semi-bold pe-2'}>
                             <strong>
                                 {numeral.formats[numberFormat].format((original?.total_info?.total_with_nds_value) || 0)}
                             </strong>
                            <span className={'ms-1 second-text fs-9'}>UZS</span>
                        </span>
                    )
                } else {
                    return (
                        <span className={'d-block text-end fw-semi-bold'}>
                            <strong>
                                {numeral.formats[numberFormat].format((original?.items?.reduce((total, item) => total + item?.total_with_nds, 0) || 0))}
                            </strong>
                            <span className={'ms-1 second-text fs-9'}>UZS</span>
                        </span>
                    )
                }
            }
        },
        (VIEW_DRAFT !== viewer && {
            accessor: 'status',
            Header: () => t(lang, "roaming.contracts.datatable.header.row.status"),
            headerProps: {className: 'text-end ps-3'},
            cellProps: {className: 'text-end ps-3'},
            Cell: ({row: {original}}) => {
                const contractWrapper = new ContractWrapperClass(original)
                return <div className={'d-inline-block text-center'}>
                    <Badge
                        variant={"phoenix"}
                        bg={contractWrapper.getRoamingStatusVariant(activeOrganizationPinflOrInn)}
                        className={'px-3 py-1 fs-9 text-uppercase w-100 text-decoration-none fw-medium border-0 rounded-4'}
                    >
                        {t(lang, contractWrapper.getRoamingStatus(activeOrganizationPinflOrInn))}
                    </Badge>
                </div>
            }
        }),
        {
            accessor: 'action',
            Header: "",
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                        <RevealDropdown>
                            <Dropdown.Item
                                onClick={() => {
                                    if (viewer === VIEW_DRAFT) {
                                        navigate('/roaming/contract/draft/'+original.id)
                                    } else {
                                        navigate('/roaming/contract/'+original.id)
                                    }
                                }}>
                                <span>{t(lang, "roaming.invoices.table_header.show")}</span>
                            </Dropdown.Item>
                        </RevealDropdown>
                    </RevealDropdownTrigger>
                );
            }
        },
    ].filter(i => i);

    return (
        <DashboardDatatable columns={columns} data={roamingContracts} setViewer={setViewer} />
    );
};

export default DashboardContractDatatable;
