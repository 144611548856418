import React, {useEffect, useState} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import ReactSelect from "react-select";
import CustomFloatingLabelSelect from "../../floating-custom-select/CustomFloatingLabelSelect";
import {selectCountries} from "../../../store/reducers/roaming/roamingReferenceReducer";

const RoamingInvoiceFormCountrySelector = ({defaultCountryId, onChange}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const countries = useSelector(selectCountries);

    const onChangeHandler = (option) => {
        onChange(option);
    };

    return (
            <CustomFloatingLabelSelect
                label={t(lang, "roaming.invoice.country")}
                options={countries}
                placeholder={t(lang, '')}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.countryId}
                isClearable
                onChange={option => onChangeHandler(option ? option.countryId : null)}
                value={countries.find(c => c.countryId === defaultCountryId) || null}
            />
    );
};

export default RoamingInvoiceFormCountrySelector;
