import React, {Fragment} from 'react';
import {Col, Row, Table} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import RoamingInvoiceVatRegStatusBadge from "../../RoamingInvoiceVatRegStatusBadge";
import numeral from "numeral";
import invoiceWrapper from "../../../../enum/edi/invoice/invoiceStatus";

const RoamingInvoiceViewTable = ({invoice,hasMark,hasExcise,hasMedical,hasCommittent,getMeasure, getMark,excise, medical}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const numberFormat = useSelector(selectNumberFormat);
    return (
        <Fragment>
            {hasMark && <Table bordered responsive className="table-view scrollbar border-900 mb-0 fs-9 fw-semi-bold">
                <thead className="text-dark border-bottom bg-card second-text text-center border-top-0">
                <tr>
                    <th rowSpan={2} className="fw-semi-bold align-middle">№</th>
                    <th rowSpan={2} className="fw-semi-bold align-middle">{t(lang, "roaming.invoice.items.product")}</th>
                    <th rowSpan={2}
                        className="fw-semi-bold align-middle">{t(lang, "roaming.invoice.items.mark_category")}</th>
                    <th colSpan={3}
                        className="fw-semi-bold align-middle">{t(lang, "roaming.invoice.items.mark_code")}</th>
                </tr>
                <tr>
                    <th className="fw-semi-bold align-middle">{t(lang, "roaming.invoice.items.package.0")}</th>
                    <th className="fw-semi-bold align-middle">{t(lang, "roaming.invoice.items.package.1")}</th>
                    <th className="fw-semi-bold align-middle">{t(lang, "roaming.invoice.items.package.2")}</th>
                </tr>
                </thead>
                <tbody>
                {invoice.items.map((item, index) => (
                    <tr key={item.ordering_number}>
                        <td className="fw-semi-bold align-middle">{index + 1}</td>
                        <td className="fw-semi-bold align-middle">{item.name}</td>
                        <td className="fw-semi-bold align-middle">{t(lang, getMark(item.marking?.product_type)?.label)}</td>
                        <td className="fw-semi-bold align-middle">
                            {item.marking?.kiz?.map(mark => (
                                <div key={mark}>{mark}</div>
                            ))}
                        </td>
                        <td className="fw-semi-bold align-middle">
                            {item.marking?.nom_upak?.map(mark => (
                                <div key={mark}>{mark}</div>
                            ))}
                        </td>
                        <td className="fw-semi-bold align-middle">
                            {item.marking?.ident_trans_upak?.map(mark => (
                                <div key={mark}>{mark}</div>
                            ))}
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>}

            <Table bordered responsive className="table-view scrollbar border-900 mb-0 fs-9 fw-semi-bold">
                <thead className="text-dark border-bottom bg-card second-text text-center">
                <tr>
                    <th rowSpan={3} className="fw-semi-bold align-middle">№</th>
                    <th rowSpan={2} className="fw-semi-bold align-middle">{t(lang, "roaming.invoice.items.product")}</th>
                    <th rowSpan={2} className="fw-semi-bold align-middle">{t(lang, "roaming.invoice.items.catalog")}</th>
                    {hasMedical && <th rowSpan={2}
                                       className="fw-semi-bold align-middle">{t(lang, "roaming.invoice.items.serial")}</th>}
                    <th rowSpan={2}
                        className="fw-semi-bold align-middle">{t(lang, "roaming.invoice.items.measurement")}</th>
                    {hasMedical && <th rowSpan={2}
                                       className="fw-semi-bold align-middle">{t(lang, "roaming.invoice.items.medical.value")}</th>}
                    {hasMedical && <th rowSpan={2}
                                       className="fw-semi-bold align-middle">{t(lang, "roaming.invoice.items.medical.rate")}</th>}
                    <th rowSpan={2} className="fw-semi-bold align-middle">{t(lang, "roaming.invoice.items.quantity")}</th>
                    <th rowSpan={2} className="fw-semi-bold align-middle">{t(lang, "roaming.invoice.items.price")}</th>
                    {hasExcise && <th colSpan={2}
                                      className="fw-semi-bold align-middle">{t(lang, "roaming.invoice.items.excise")}</th>}
                    <th rowSpan={2} className="fw-semi-bold align-middle">{t(lang, "roaming.invoice.items.total")}</th>
                    <th colSpan={2}
                        className="fw-semi-bold align-middle">{t(lang, "roaming.invoice.items.nds")}</th>
                    <th rowSpan={2}
                        className="fw-semi-bold align-middle">{t(lang, "roaming.invoice.items.total_with_nds")}</th>
                    <th rowSpan={2}
                        className="fw-semi-bold align-middle">{t(lang, "roaming.common.items.origin")}</th>
                </tr>
                <tr>
                    {hasExcise && <>
                        <th className="fw-semi-bold align-middle">{t(lang, "roaming.invoice.items.rate")}</th>
                        <th className="fw-semi-bold align-middle">{t(lang, "roaming.invoice.items.value")}</th>
                    </>}
                    <th className="fw-semi-bold align-middle">{t(lang, "roaming.invoice.items.rate")}</th>
                    <th className="fw-semi-bold align-middle">{t(lang, "roaming.invoice.items.value")}</th>
                </tr>
                </thead>
                <tbody>
                {invoice.items.map((item, index) => (
                    <tr key={item.ordering_number}>
                        <td className="fw-semi-bold align-middle">{index + 1}</td>
                        <td className="fw-semi-bold align-middle">
                            <div>{item.name}</div>
                            {hasCommittent &&
                                <div className="mt-3 d-flex flex-column border-top border-400">
                                    <div className="text-truncate" style={{maxWidth: '300px'}}>
                                                    <span
                                                        className="fw-bold me-2">{t(lang, 'roaming.invoice.committent')}:</span>
                                        <span>{item.committent_inn}</span>
                                        <b className="ms-2">{item.committent_name}</b>
                                    </div>
                                    <div>
                                                    <span
                                                        className="fw-bold me-2">{t(lang, 'roaming.common.nds_code')}:</span>
                                        <span>{item.committent_nds_registration_code}</span>
                                    </div>
                                    <div>
                                                    <span
                                                        className="fw-bold me-2">{t(lang, 'roaming.common.nds_status')}:</span>
                                        <RoamingInvoiceVatRegStatusBadge identifier={item.committent_inn}
                                                                         vatRegStatus={item.committent_nds_status}
                                                                         vatRegCode={item.committent_nds_registration_code}
                                        />
                                    </div>
                                </div>
                            }
                        </td>
                        <td className="fw-semi-bold align-middle">{item.catalog.code} - {item.catalog.name}</td>
                        {hasMedical && <td>{item.serial}</td>}
                        <td className="fw-semi-bold align-middle">{item.measure_id ? getMeasure(item.measure_id)?.name : item.package?.name}</td>
                        {hasMedical && <>
                            <td className="text-nowrap">{numeral(item.base_price).format(numberFormat)}</td>
                            <td className="text-nowrap">{numeral(item.profit_rate).format(numberFormat)}</td>
                        </>}
                        <td className="fw-semi-bold align-middle">{item.quantity}</td>
                        <td className="fw-semi-bold align-middle">{numeral(+item.price).format(numberFormat)}</td>
                        {hasExcise && <>
                            <td className="text-nowrap">{item.excise_rate}</td>
                            <td className="text-nowrap">{numeral(item.excise_value).format(numberFormat)}</td>
                        </>}
                        <td className="fw-semi-bold align-middle">{numeral(+item.total).format(numberFormat)}</td>
                        <td className="fw-semi-bold align-middle text-center text-nowrap">{item.without_vat ? 'БЕЗ НДС' : item.nds_rate}</td>
                        <td className="fw-semi-bold align-middle text-center text-nowrap">
                            {item.without_vat ? 'БЕЗ НДС' : numeral(+item.nds_value).format(numberFormat)}
                        </td>
                        <td className="fw-semi-bold align-middle text-nowrap">{numeral(+item.total_with_nds).format(numberFormat)}</td>
                        <td className="text-dark border-bottom bg-card second-text text-center">{t(lang, invoiceWrapper.getItemOriginText(item.origin))}</td>
                    </tr>
                ))}
                </tbody>
            </Table>

            <Row className="g-0 justify-content-end">
                <Col xs="auto">
                    <Table borderless size="sm" className="fs--1 text-end">
                        <tbody>
                        <tr>
                            <th className="text-900">{t(lang, 'roaming.common.subtotal')}:</th>
                            <td className="fw-semi-bold">
                                {numeral(invoice.total_info.total).format(numberFormat)}
                            </td>
                        </tr>
                        <tr>
                            <th className="text-900">{t(lang, 'roaming.common.tax')}:</th>
                            <td className="fw-semi-bold">
                                {numeral(invoice.total_info.nds_value).format(numberFormat)}
                            </td>
                        </tr>
                        {hasExcise &&
                            <tr>
                                <th className="text-900">{t(lang, 'roaming.invoice.excise_total')}:</th>
                                <td className="fw-semi-bold">
                                    {numeral(excise).format(numberFormat)}
                                </td>
                            </tr>
                        }

                        {hasMedical &&
                            <tr>
                                <th className="text-900">{t(lang, 'roaming.invoice.medical_total')}:</th>
                                <td className="fw-semi-bold">
                                    {numeral(medical).format(numberFormat)}
                                </td>
                            </tr>
                        }
                        <tr className="border-top">
                            <th className="text-900">{t(lang, 'roaming.common.total')}:</th>
                            <td className="fw-semi-bold">
                                {numeral(invoice.total_info.total_with_nds).format(numberFormat)}
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Fragment>
    );
};

export default RoamingInvoiceViewTable;
