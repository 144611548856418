export const ADD_CONTRACTOR_REQUESTED = 'ADD_CONTRACTOR_REQUESTED';
export const ADD_CONTRACTOR_SUCCESS = 'ADD_CONTRACTOR_SUCCESS';
export const ADD_CONTRACTOR_FAILED = 'ADD_CONTRACTOR_FAILED';

export const EDIT_CONTRACTOR_REQUESTED = 'EDIT_CONTRACTOR_REQUESTED';
export const EDIT_CONTRACTOR_SUCCESS = 'EDIT_CONTRACTOR_SUCCESS';
export const EDIT_CONTRACTOR_FAILED = 'EDIT_CONTRACTOR_FAILED';

export const DELETE_CONTRACTOR_REQUESTED = 'DELETE_CONTRACTOR_REQUESTED';
export const DELETE_CONTRACTOR_SUCCESS = 'DELETE_CONTRACTOR_SUCCESS';
export const DELETE_CONTRACTOR_FAILED = 'DELETE_CONTRACTOR_FAILED';

export const ADD_CONTRACTOR_NOTE_REQUESTED = 'ADD_CONTRACTOR_NOTE_REQUESTED';
export const ADD_CONTRACTOR_NOTE_SUCCESS = 'ADD_CONTRACTOR_NOTE_SUCCESS';
export const ADD_CONTRACTOR_NOTE_FAILED = 'ADD_CONTRACTOR_NOTE_FAILED';

export const EDIT_CONTRACTOR_NOTE_REQUESTED = 'EDIT_CONTRACTOR_NOTE_REQUESTED';
export const EDIT_CONTRACTOR_NOTE_SUCCESS = 'EDIT_CONTRACTOR_NOTE_SUCCESS';
export const EDIT_CONTRACTOR_NOTE_FAILED = 'EDIT_CONTRACTOR_NOTE_FAILED';

export const DELETE_CONTRACTOR_NOTE_REQUESTED = 'DELETE_CONTRACTOR_NOTE_REQUESTED';
export const DELETE_CONTRACTOR_NOTE_SUCCESS = 'DELETE_CONTRACTOR_NOTE_SUCCESS';
export const DELETE_CONTRACTOR_NOTE_FAILED = 'DELETE_CONTRACTOR_NOTE_FAILED';

export const SET_DEFAULT_CONTRACTOR_REQUESTED = 'SET_DEFAULT_CONTRACTOR_REQUESTED';
export const SET_DEFAULT_CONTRACTOR_SUCCESS = 'SET_DEFAULT_CONTRACTOR_SUCCESS';
export const SET_DEFAULT_CONTRACTOR_FAILED = 'SET_DEFAULT_CONTRACTOR_FAILED';

export const UN_SET_DEFAULT_CONTRACTOR_REQUESTED = 'UN_SET_DEFAULT_CONTRACTOR_REQUESTED';
export const UN_SET_DEFAULT_CONTRACTOR_SUCCESS = 'UN_SET_DEFAULT_CONTRACTOR_SUCCESS';
export const UN_SET_DEFAULT_CONTRACTOR_FAILED = 'UN_SET_DEFAULT_CONTRACTOR_FAILED';

export const CHANGE_CONTRACTOR_STARTING_DEBTS_REQUESTED = "CHANGE_CONTRACTOR_STARTING_DEBTS_REQUESTED";
export const CHANGE_CONTRACTOR_STARTING_DEBTS_SUCCEED = "CHANGE_CONTRACTOR_STARTING_DEBTS_SUCCEED";
export const CHANGE_CONTRACTOR_STARTING_DEBTS_FAILED = "CHANGE_CONTRACTOR_STARTING_DEBTS_FINISHED";
