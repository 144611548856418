import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {selectLang} from "../../../../store/reducers/main/main";
import {Controller, useForm} from "react-hook-form";
import {Button, Card, FloatingLabel, Form, Spinner} from "react-bootstrap";
import classNames from "classnames";
import Cleave from 'cleave.js/react'
import {Link, useNavigate} from "react-router-dom";
import {loginAsync} from '../../../../store/reducers/auth/authReducer';
import IconArrowLeft from "../../../../assets/icon/IconArrowLeft";
import Flex from "../../../common/Flex";

const LoginWithUsername = () => {

    const dispatch = useDispatch()

    const lang = useSelector(selectLang);
    const t = Translate;
    const navigate = useNavigate()

    const {register, formState: {errors}, control, handleSubmit} = useForm()
    const [loading, setLoading] = useState(false);

    const onSubmit = (formData) => {

        let username = formData.username.replace(/ /g, '')
        username = '998' + username;

        setLoading(true);
        dispatch(loginAsync(username, formData.password))
            .then(() => {
                // navigate('/')
            })
            .catch(() => {
            })
            .finally(() => setLoading(false))
    };

    return (
        <Card className={'w-100 h-100 overflow-hidden rounded-5 p-3'}>
            <Form onSubmit={handleSubmit(onSubmit)} className={'h-100'}>
                <div className={'position-relative'}>
                    <button onClick={() => navigate(-1)}
                            className={'main-text btn-back bg-secondary-davr position-absolute'}
                            style={{width: 35, height: 35, left: 0, bottom: 0}}>
                        <IconArrowLeft/>
                    </button>
                    <h5 className={'text-center text-dark'}>Вход в систему</h5>
                    <p className={'text-center fs-9 second-text'}>По паролю</p>
                </div>
                <Flex className={"flex-column justify-content-between scrollbar"} style={{height: "calc(100% - 55px)"}}>
                    <div>
                        <Card className={'p-1'}>
                            <Form.Group className="mb-3 input-group gap-2 flex-nowrap">
                                <FloatingLabel label={"Код"} className={'w-25'}>
                                    <Form.Control value={"+998"} placeholder={"Код"}/>
                                </FloatingLabel>
                                {/*<div className="input-group-prepend"><span className="input-group-text border-right-0">+998</span></div>*/}
                                <Controller control={control} name={'username'} rules={{
                                    required: t(lang, "edi.common.forms.validations.is_required")
                                }} render={({field}) => (
                                    <FloatingLabel label={"Номер телефона"} className={'w-75'}>
                                        <Cleave
                                            placeholder={"Номер телефона"}
                                            options={{
                                                delimiters: [' ', ' ', ' ', ' '],
                                                blocks: [2, 3, 2, 2],
                                                numericOnly: true
                                            }}
                                            onChange={(username) => field.onChange(username)}
                                            className={classNames('form-control', {
                                                'is-invalid': errors?.username,
                                            })}
                                        />
                                    </FloatingLabel>

                                )}/>
                                <Form.Control.Feedback type="invalid">{errors.username?.message}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <FloatingLabel label={t(lang, "edi.login.form.password")}>
                                    <Form.Control
                                        placeholder={t(lang, "edi.login.form.password")}
                                        className={errors.password && 'is-invalid'}
                                        {
                                            ...register("password", {
                                                required: t(lang, "edi.common.forms.validations.is_required"),
                                            })
                                        }
                                        type="password"
                                    />
                                </FloatingLabel>

                                <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
                            </Form.Group>
                        </Card>

                        <Link className="fs-9 mb-0 text-primary-davr"
                              to="../forgot-password">{t(lang, "edi.login.form.forgot_password")}</Link>
                    </div>
                    <Form.Group>
                        <Button type="submit" color="primary" className="mt-3 w-100 bg-primary-davr" disabled={loading}>
                            {loading &&
                                <Spinner className="align-middle me-2" animation="border" role="switch"
                                         size="sm"/>}
                            {t(lang, "edi.login.form.button.login")}
                        </Button>
                    </Form.Group>
                </Flex>
            </Form>
        </Card>
    );
};

export default LoginWithUsername;
