import React, {Fragment, useEffect} from 'react';
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import {Col, Row} from "react-bootstrap";
import IconArrowLeft from "../assets/icon/IconArrowLeft";
import {useSelector} from "react-redux";
import {selectLang} from "../store/reducers/main/main";
import {Translate} from "../lang/lang";
import {scrollEvent} from "../helpers/scrollEvent";
import DashboardDropdownButton from "../components/common/DashboardDropdownButton";
import useQuery from "../components/roaming/form/useQuery";
import {settingsQuery} from "../enum/settings/settingsServices";
import NotificationTelegramModal from "../components/user/notification/NotificationTelegramModal";
import NotificationRegister from "../components/user/notification/NotificationRegister";
import AddUser from "../components/settings/info-organization/AddUser";

const UserLayout = () => {
    const navigate= useNavigate();
    const lang = useSelector(selectLang);
    const t = Translate;

    const query = useQuery();

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('billing-title'))
        return () => window.removeEventListener('scroll', () => scrollEvent('billing-title'))
    }, []);

    return (
        <Fragment>
            <Row className={'align-items-center justify-content-between my-3 position-sticky z-index-1'} style={{top: '4rem'}} id={'billing-title'}>
                <Col lg={"auto"} className={'d-flex gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'}
                            style={{width: 35, height: 35}}>
                        <IconArrowLeft/>
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, settingsQuery(Object.keys(query)))}</h5>
                        <div className={'d-flex align-items-center gap-2'}>
                            <span className={'second-text fs-9'}>
                               {t(lang, "main.billing.charge.datatable.document")}
                            </span>
                        </div>
                    </div>
                </Col>
                <Col lg={"auto"} className={'d-flex justify-content-end gap-2'}>
                    {query['notification'] && <Fragment>
                        <NotificationTelegramModal/>
                        <NotificationRegister/>
                    </Fragment>}
                    {query['organization'] && <AddUser />}
                    <DashboardDropdownButton settings={true} />
                </Col>
            </Row>
            <Outlet />
        </Fragment>
    );
};

export default UserLayout;
