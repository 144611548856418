import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {selectDateFormat, selectLang} from "../../../store/reducers/main/main";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {useDispatch, useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {
    DRAFT,
    VIEWER_CUSTOMER,
    VIEWER_EXECUTOR,
    VIEWER_PERSON
} from "../../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import dayjs from "dayjs";
import {getOrganizationInfoAsync, loadCompanyBranchesAsync} from "../../../store/reducers/roaming/roamingReducer";
import FindCompanySearchable from "../../organization/FindCompanySearchable";
import {Col, Row, Form, Collapse, Button, FloatingLabel, Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import ReactSelect from "react-select";
import DatePickerComponent from "../../common/DatePickerComponent";
import ContractorBranchSelect from "../../common/ContractorBranchSelect";

const RoamingInvoiceDataTableFilter = ({viewer, predefinedFilters, isOpenFilterCollapse, onResetFilter}) => {
    const {register, setValue} = useFormContext();
    const dateFormat = useSelector(selectDateFormat);
    const activeOrganization = useSelector(selectActiveOrganization);
    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch();

    const showExecutorFilter = viewer === VIEWER_CUSTOMER || viewer === VIEWER_PERSON;
    const showCustomerFilter = viewer === VIEWER_EXECUTOR || viewer === VIEWER_PERSON || viewer === DRAFT;

    const [customer, setCustomer] = useState(null);
    const [executor, setExecutor] = useState(null);
    const [customerBranches, setCustomerBranches] = useState([]);
    const [executorBranches, setExecutorBranches] = useState([]);
    const [customerBranch, setCustomerBranch] = useState(null);
    const [executorBranch, setExecutorBranch] = useState(null);

    // set predefined filters
    useEffect( () => {
        if (predefinedFilters && Object.keys(predefinedFilters).length > 0) {
            let params = {...predefinedFilters};

            params.date_end = params?.date_end ? dayjs(params.date_end).toDate() : null;
            params.date_start = params?.date_start ? dayjs(params.date_start).toDate() : null;
            params.contract_date = params?.contract_date ? dayjs(params.contract_date).toDate() : null;

            Object.keys(params).forEach(key => {
                setValue(key, params[key]);
            })
        }
    }, [predefinedFilters])

    // set default executor || customer
    const switchViewer = async () => {
        switch (viewer) {
            case VIEWER_CUSTOMER:
                setCustomer(await getOrganizationInfoAsync(activeOrganization.pinfl || activeOrganization.inn));
                break;
            case VIEWER_EXECUTOR:
                setExecutor(await getOrganizationInfoAsync(activeOrganization.pinfl || activeOrganization.inn));
                break;
            case DRAFT:
                setExecutor(await getOrganizationInfoAsync(activeOrganization.pinfl || activeOrganization.inn));
                break;
            default:
                break;
        }

    }
    useEffect( () => {
        switchViewer()
    }, [viewer, activeOrganization])

    // save previous viewer for match in reducer
    const usePrevious = (value) => {
        const ref = useRef();

        useEffect(() => {
            ref.current = value;
        }, [value])

        return ref.current;
    };
    const previousViewer = usePrevious(viewer);

    // initialize values on viewer change
    useEffect(() => {
        if (previousViewer === VIEWER_CUSTOMER && (viewer === VIEWER_EXECUTOR || viewer === DRAFT)) {
            setCustomer(null);
            setExecutor(null);
        }

        if ((previousViewer === VIEWER_EXECUTOR || previousViewer === DRAFT) && viewer === VIEWER_CUSTOMER) {
            setCustomer(null);
            setExecutor(null);
        }

        if (previousViewer === VIEWER_CUSTOMER && viewer === VIEWER_PERSON) {
            setCustomer(null);
        }

        if ((previousViewer === VIEWER_EXECUTOR || previousViewer === DRAFT) && viewer === VIEWER_PERSON) {
            setExecutor(null);
        }

        if (previousViewer === VIEWER_PERSON && viewer === VIEWER_CUSTOMER) {
            setCustomer(null);
        }

        if (previousViewer === VIEWER_PERSON && (viewer === VIEWER_EXECUTOR || viewer === DRAFT)) {
            setExecutor(null);
        }
    }, [viewer])

    // set customer_inn and load branches
    const loadCompanyBranchesCustomer = async () => {
        if (customer) {
            setValue('customer_inn', customer.person_num || customer.inn);
            setCustomerBranches(await dispatch(loadCompanyBranchesAsync(customer.person_num || customer.inn)));
        } else {
            setValue('customer_inn', null);
            setCustomerBranches([]);
            setCustomerBranch(null);
        }
    }
    useEffect( () => {
        loadCompanyBranchesCustomer()
    }, [customer])

    // set executor_inn and load branches
    const loadCompanyBranchesExecutor = async () =>{
        if (executor) {
            setValue('executor_inn', executor.person_num || executor.inn);
            setExecutorBranches(await dispatch(loadCompanyBranchesAsync(executor.person_num || executor.inn)));
        } else {
            setValue('executor_inn', null);
            setExecutorBranches([]);
            setExecutorBranch(null);
        }
    }
    useEffect( () => {
        loadCompanyBranchesExecutor()
    }, [executor])

    // set customer branch
    useEffect(() => {
        if (customerBranch)
            setValue('customer_branch_code', customerBranch.branch_num);
        else
            setValue('customer_branch_code', null);
    }, [customerBranch])

    // set executor branch
    useEffect(() => {
        if (executorBranch)
            setValue('executor_branch_code', executorBranch.branch_num);
        else
            setValue('executor_branch_code', null);
    }, [executorBranch])

    return (
        <Fragment>
            <Collapse in={isOpenFilterCollapse}>
                <div>
                    <Card className={'mt-2 p-1'}>
                        <Row className='mb-3 g-3'>
                            <Form.Group as={Col} md={6} lg={3}>
                                <FloatingLabel label={t(lang, "roaming.invoices.table_header.number")}>
                                    <Form.Control name="number"
                                                  type="text"
                                                  placeholder={t(lang, "roaming.invoices.table_header.number")}
                                                  {...register("number")}
                                    />
                                </FloatingLabel>
                            </Form.Group>

                            <Form.Group as={Col} md={6} lg={3}>
                                <Controller
                                    name="date_start"
                                    render={({field}) => (
                                        <DatePickerComponent
                                            field={field}
                                            isClearable
                                            className='form-control'
                                            placeholderText={t(lang, "roaming.invoices.table_header.date_start")}
                                            dateFormat={dateFormat}
                                            selected={field.value}
                                        />
                                    )}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={6} lg={3}>
                                <Controller
                                    name="date_end"
                                    render={({field}) => (
                                        <DatePickerComponent
                                            field={field}
                                            isClearable={!predefinedFilters?.hasOwnProperty("date_end")}
                                            className='form-control'
                                            placeholderText={t(lang, "roaming.invoices.table_header.date_end")}
                                            disabled={predefinedFilters?.hasOwnProperty("date_end")}
                                            selected={field.value}
                                            defaultValue={field.value}
                                        />
                                    )}
                                />
                            </Form.Group>

                            {showExecutorFilter &&
                                <Form.Group as={Col} md={6} lg={3}>
                                    <Controller name="executor_inn"
                                                render={({field}) => (
                                                    <FindCompanySearchable
                                                        onChange={setExecutor}
                                                        placeholder={t(lang, "roaming.common.executor")}
                                                    />
                                                )}
                                                shouldUnregister
                                    />
                                </Form.Group>
                            }

                            {showCustomerFilter &&
                                <Form.Group as={Col} md={6} lg={3}>
                                    <Controller name="customer_inn"
                                                render={({field}) => (
                                                    <FindCompanySearchable
                                                        inputProps={{
                                                            disabled: predefinedFilters?.hasOwnProperty("customer_inn")
                                                        }}
                                                        defaultInn={predefinedFilters?.customer_inn}
                                                        onChange={setCustomer}
                                                        placeholder={t(lang, "roaming.common.customer")}
                                                    />
                                                )}
                                                shouldUnregister
                                    />
                                </Form.Group>
                            }
                            <Form.Group as={Col} md={6} lg={3}>
                                <Controller
                                    name="created_at_start"
                                    render={({field}) => (
                                        <DatePickerComponent
                                            field={field}
                                            isClearable={!predefinedFilters?.hasOwnProperty("created_at_start")}
                                            className='form-control'
                                            placeholderText={t(lang, "roaming.invoices.table_header.created_at_start")}
                                            disabled={predefinedFilters?.hasOwnProperty("created_at_start")}
                                            selected={field.value}
                                            defaultValue={field.value}
                                        />
                                    )}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={6} lg={3}>
                                <Controller
                                    name="created_at_end"
                                    render={({field}) => (
                                        <DatePickerComponent
                                            field={field}
                                            isClearable={!predefinedFilters?.hasOwnProperty("created_at_end")}
                                            className='form-control'
                                            placeholderText={t(lang, "roaming.invoices.table_header.created_at_end")}
                                            disabled={predefinedFilters?.hasOwnProperty("created_at_end")}
                                            dateFormat={dateFormat}
                                            selected={field.value}
                                            defaultValue={field.value}
                                        />
                                    )}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={6} lg={3}>
                                <FloatingLabel label={t(lang, "roaming.invoices.table_header.contract_number")}>
                                    <Form.Control name="contract_number"
                                                  type="text"
                                                  placeholder={t(lang, "roaming.invoices.table_header.contract_number")}
                                                  disabled={predefinedFilters?.hasOwnProperty("contract_number")}
                                                  {...register("contract_number")}
                                    />
                                </FloatingLabel>
                            </Form.Group>

                            <Form.Group as={Col} md={6} lg={3}>
                                <Controller
                                    name="contract_date"
                                    render={({field}) => (
                                        <DatePickerComponent
                                            field={field}
                                            isClearable={!predefinedFilters?.hasOwnProperty("contract_date")}
                                            className='form-control'
                                            placeholderText={t(lang, "roaming.invoices.table_header.contract_date")}
                                            disabled={predefinedFilters?.hasOwnProperty("contract_date")}
                                            dateFormat={dateFormat}
                                            selected={field.value}
                                        />
                                    )}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={6} lg={3}>
                                <Controller
                                    name="executor_branch_code"
                                    render={({field}) => (
                                        <ContractorBranchSelect
                                            options={executorBranches}
                                            placeholder={t(lang, "roaming.invoices.datatable.filter.executor_branch_code")}
                                            noOptionsMessage={() => t(lang, "roaming.invoices.datatable.filter.branches_not_found")}
                                            hideSelectedOptions
                                            isClearable
                                            value={executorBranch}
                                            onChange={setExecutorBranch}
                                            getOptionLabel={option => option.branch_name}
                                            getOptionValue={option => option.branch_num}
                                        />
                                    )}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={6} lg={3}>
                                <Controller name="customer_branch_code"
                                            render={({field}) => (
                                                <ContractorBranchSelect
                                                    options={customerBranches}
                                                    placeholder={t(lang, "roaming.invoices.datatable.filter.customer_branch_code")}
                                                    noOptionsMessage={() => t(lang, "roaming.invoices.datatable.filter.branches_not_found")}
                                                    hideSelectedOptions
                                                    value={customerBranch}
                                                    onChange={setCustomerBranch}
                                                    isClearable
                                                    getOptionLabel={option => option.branch_name}
                                                    getOptionValue={option => option.branch_num}
                                                />
                                            )}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={6} lg={3}>
                                <FloatingLabel label={t(lang, "roaming.invoices.datatable.filter.start_total_sum")}>
                                    <Form.Control name="start_total_sum"
                                                  type="text"
                                                  placeholder={t(lang, "roaming.invoices.datatable.filter.start_total_sum")}
                                                  disabled={predefinedFilters?.hasOwnProperty("start_total_sum")}
                                                  {...register('start_total_sum')}
                                    />
                                </FloatingLabel>
                            </Form.Group>

                            <Form.Group as={Col} md={6} lg={3}>
                                <FloatingLabel label={t(lang, "roaming.invoices.datatable.filter.end_total_sum")}>
                                    <Form.Control name="end_total_sum"
                                                  type="text"
                                                  placeholder={t(lang, "roaming.invoices.datatable.filter.end_total_sum")}
                                                  {...register('end_total_sum')}
                                    />
                                </FloatingLabel>
                            </Form.Group>
                        </Row>
                    </Card>
                    <Row className="my-2 g-3 justify-content-end">
                        <Col md={12} lg={3} className="d-flex align-items-end justify-content-end gap-2">
                            <Button className={'btn-davr bg-white text-dark'} variant="phoenix-primary" type='button' onClick={onResetFilter}>{t(lang, "items.common.clear_imported_file")}</Button>
                            <Button className={'btn-davr bg-primary-davr text-white'} variant="primary" type='submit'>{t(lang, "roaming.invoices.table_header.show")}</Button>
                        </Col>
                    </Row>
                </div>
            </Collapse>
        </Fragment>
    );
};

export default RoamingInvoiceDataTableFilter;
