import React from 'react';

const IconMail = ({width = 24, height = 24, color = "none"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 25 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5 6.5L10.6076 10.6123L10.6097 10.614C11.2878 11.1113 11.6271 11.3601 11.9988 11.4562C12.3272 11.5412 12.6725 11.5412 13.001 11.4562C13.3729 11.36 13.7132 11.1105 14.3926 10.6123L20.0002 6.5M3.5 15.8002V8.2002C3.5 7.08009 3.5 6.51962 3.71799 6.0918C3.90973 5.71547 4.21547 5.40973 4.5918 5.21799C5.01962 5 5.58009 5 6.7002 5H18.3002C19.4203 5 19.9796 5 20.4074 5.21799C20.7837 5.40973 21.0905 5.71547 21.2822 6.0918C21.5 6.5192 21.5 7.07899 21.5 8.19691V15.8036C21.5 16.9215 21.5 17.4805 21.2822 17.9079C21.0905 18.2842 20.7837 18.5905 20.4074 18.7822C19.98 19 19.421 19 18.3031 19H6.69691C5.57899 19 5.0192 19 4.5918 18.7822C4.21547 18.5905 3.90973 18.2842 3.71799 17.9079C3.5 17.4801 3.5 16.9203 3.5 15.8002Z"
                stroke="#9BA2B1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default IconMail;
