import React, {Fragment, useEffect, useState} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {selectDateFormat, selectLang} from "../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {Col, Row, Form, Spinner, Button, Card, FloatingLabel} from "react-bootstrap";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import FindCompanySearchable from "../../organization/FindCompanySearchable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudArrowUp} from "@fortawesome/free-solid-svg-icons";
import DatePickerComponent from "../../common/DatePickerComponent";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import IconButton from "../../IconButton";
import SignActionWithSelectCertificateWrapper from "../../common/SignActionWithSelectCertificateWrapper";
import {useNavigate} from "react-router-dom";
import {scrollEvent} from "../../../helpers/scrollEvent";

const FreeFormDocumentForm = ({loading}) => {
    const navigate = useNavigate()
    const {register, formState: {errors}, control, setValue, watch} = useFormContext();
    const [selectedFile, setSelectedFile] = useState(null);
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    const pdf = watch('file');
    const onSelectFile = event => {
        const [file] = event.target.files;
        const reader = new FileReader();
        setSelectedFile(file);

        reader.readAsDataURL(file);
        reader.onload = e => {
            setValue('file', e.target.result, {shouldValidate: true});
        }
    };


    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('roaming-free-form-send'))
        return () => window.removeEventListener('scroll', () => scrollEvent('roaming-free-form-send'))
    }, []);

    return (
        <Fragment>

            <Row id={'roaming-free-form-send'}
                 className={'my-2 justify-content-between align-items-center position-sticky z-index-1'}
                 style={{top: '4rem'}}>
                <Col xl={6} sm={12} className={'d-flex align-items-center gap-3'}>
                    <button type={"button"} onClick={() => navigate(-1)} className={'main-text btn-back'}
                            style={{width: 35, height: 35}}>
                        <IconArrowLeft/>
                    </button>
                    <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.free_form_document.new.datatable.title")}</h5>
                </Col>
                <Col className={'d-flex justify-content-end gap-2'}>
                    <div className="text-end">
                        <Button type="submit" className="btn-davr px-3 py-0 bg-primary-davr" >
                            {loading &&
                                <Spinner animation="border" role="switch" size="sm" className="btn-davr px-3 py-0 bg-primary-davr"/>}
                            {t(lang, 'roaming.free_form_document.save.button.submit.title')}
                        </Button>
                    </div>
                </Col>
            </Row>


            <h4 className={'fs-8 fw-medium second-text ms-3'}>{t(lang, 'roaming.free_form_document.save.document_info.title')}</h4>

            <Card className="p-2 mb-2">
                <Row>
                    <Form.Group as={Col} xs={12} md={6}>
                        <FloatingLabel label={t(lang, 'roaming.free_form_document.save.document_info.number.title')}>
                            <Form.Control
                                type="text"
                                isInvalid={errors?.freeform?.number}
                                placeholder={t(lang, 'roaming.free_form_document.save.document_info.number.title')}
                                {...register('freeform.number', {required: t(lang, 'roaming.validations.is_required')})}
                            />
                        </FloatingLabel>

                        <Form.Control.Feedback type="invalid">
                            {errors?.freeform?.number?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={6}>
                        <Controller
                            control={control}
                            name='freeform.date'
                            rules={{required: t(lang, 'roaming.validations.is_required')}}
                            render={({field}) => (
                                <DatePickerComponent
                                    field={field}
                                    isClearable
                                    wrapperClassName={classNames({'is-invalid': errors?.freeform?.date})}
                                    className={classNames('form-control', {'is-invalid': errors?.freeform?.date})}
                                    placeholderText={t(lang, 'roaming.free_form_document.save.document_info.date.title')}
                                />
                            )}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.freeform?.date?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
            </Card>

            <h4 className={'fs-8 fw-medium second-text ms-3'}>{t(lang, 'roaming.free_form_document.save.member_info.title')}</h4>

            <Row className={'mb-2'}>
                <Col xs={12} md={6}>
                    <Card className="p-2 h-100">
                        <h4 className={'fs-8 fw-medium second-text ms-3'}>{t(lang, 'roaming.free_form_document.save.member_info.sender')}</h4>
                        <Form.Group className="mb-2 mt-3">
                            <FloatingLabel label={t(lang, 'roaming.free_form_document.save.member_info.receiver.inn')}>
                                <Form.Control
                                    type="text"
                                    disabled={true}
                                    {...register('sender.inn')}
                                    placeholder={t(lang, 'roaming.free_form_document.save.member_info.receiver.inn')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group className="my-2">
                            <FloatingLabel label={t(lang, 'roaming.free_form_document.save.member_info.receiver.name')}>
                                <Form.Control
                                    type="text"
                                    disabled={true}
                                    {...register('sender.name')}
                                    placeholder={t(lang, 'roaming.free_form_document.save.member_info.receiver.name')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                    </Card>
                </Col>
                <Col xs={12} md={6}>
                    <Card className="p-2 h-100">
                        <h4 className={'fs-8 fw-medium second-text ms-3'}>{t(lang, 'roaming.free_form_document.save.member_info.receiver')}</h4>
                        <Form.Group className="mb-2 mt-3">
                            <Controller
                                name="receiver.inn"
                                rules={{required: t(lang, 'roaming.validations.is_required')}}
                                render={({field}) => (
                                    <FindCompanySearchable
                                        id={"receiver.inn"}
                                        placeholder={t(lang, 'roaming.free_form_document.save.member_info.receiver.inn')}
                                        defaultInn={field.value}
                                        inputProps={{
                                            isInvalid: errors?.receiver?.inn,
                                            placeholder: t(lang, ''),
                                        }}
                                        onChange={company => {
                                            field.onChange(company?.inn);
                                            setValue('receiver.name', company?.name || '');
                                        }}
                                        inputGroupProps={{className: classNames('select',{'is-invalid': errors?.receiver?.inn})}}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.receiver?.inn?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="my-2">
                            <FloatingLabel label={t(lang, 'roaming.free_form_document.save.member_info.receiver.name')}>
                                <Form.Control
                                    type="text"
                                    disabled={true}
                                    {...register('receiver.name', {required: t(lang, 'roaming.validations.is_required')})}
                                    placeholder={t(lang, 'roaming.free_form_document.save.member_info.receiver.name')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                    </Card>
                </Col>
            </Row>

            <h4 className={'fs-8 fw-medium second-text ms-3'}>{t(lang, 'roaming.free_form_document.save.file_upload.title')}</h4>

            <Card className="my-3 shadow-none border-dashed p-1">
                <Controller
                    name="file"
                    rules={{required: t(lang, 'roaming.free_form_document.save.file_upload.file_uploader.validation.is_required')}}
                    render={({}) => (
                        <Form.Control
                            type="file"
                            id="fileUploader"
                            className="d-none"
                            onChange={onSelectFile}
                            isInvalid={errors?.file}
                            accept="application/pdf"
                        />
                    )}
                />
                <div className="text-center cursor-pointer py-4">
                    <label htmlFor="fileUploader">
                        <h4>
                            <FontAwesomeIcon icon={faCloudArrowUp}/>
                            <span
                                className="ms-2">{t(lang, 'roaming.free_form_document.save.file_upload.file_uploader.title')}</span>
                            <span className="text-danger">*</span>
                        </h4>
                        <small className="text-danger my-0">{errors?.file?.message}</small>
                        {selectedFile && <span>{selectedFile?.name}</span>}
                    </label>
                </div>
            </Card>
        </Fragment>
    );
};

export default FreeFormDocumentForm;
