import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import {selectCurrentCertificate} from "../../../../store/reducers/eimzo/eimzoReducer";
import {loadMeasurementsAsync, selectMeasurements} from "../../../../store/reducers/roaming/roamingReducer";
import {
    selectDateDayJSFormat,
    selectLang,
    selectNumberFormat,
    updateShowInProgressAsync
} from "../../../../store/reducers/main/main";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Translate} from "../../../../lang/lang";
import {ActWrapperClass, CustomerDeclined} from "../../../../enum/roaming/act/ActStatus";
import EventBus from "../../../../eventbus/EventBus";
import {
    ROAMING_ACT_REGISTER_FAILED,
    ROAMING_ACT_REGISTER_REQUESTED,
    ROAMING_ACT_REGISTER_SUCCESS,
    ROAMING_ACT_SYNC_SUCCESS
} from "../../../../eventbus/romaing/act/roamingActEvents";
import toast from "react-hot-toast";
import {getOrganizationBillingInfoAsync} from "../../../../store/reducers/billing/billingReducer";
import {
    ActCancelHashCodeAsync,
    ActCustomerAcceptHashCodeAsync,
    ActCustomerDeclineHashCodeAsync,
    ActFormSendAsync
} from "../../../../store/reducers/roaming/act/actReducer";
import WarningAlert from "../../../common/WarningAlert";
import {Card, Col, Row} from "react-bootstrap";
import SelectCertificateWrapperComponent from "../../../common/SelectCertificateWrapperComponent";
import IconButton from "../../../IconButton";
import {faClone, faEdit, faSignature} from "@fortawesome/free-solid-svg-icons";
import SyncAct from "./SyncAct";
import CustomerAccept from "./CustomerAccept";
import CustomerDecline from "./CustomerDecline";
import ActCancel from "./ActCancel";
import Badge from "../../../common/Badge";
import dayjs from "dayjs";
import ActViewTable from "./ActViewTable";
import IconArrowLeft from "../../../../assets/icon/IconArrowLeft";
import {scrollEvent} from "../../../../helpers/scrollEvent";

const Act = ({act, draftAct, modalId}) => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const currentCertificate = useSelector(selectCurrentCertificate);
    const dispatch = useDispatch()
    const measurements = useSelector(selectMeasurements)
    const dateFormat = useSelector(selectDateDayJSFormat)
    const numberFormat = useSelector(selectNumberFormat);
    const lang = useSelector(selectLang);
    const {id} = useParams();
    const t = Translate;
    const actWrapper = new ActWrapperClass(act)
    const navigate = useNavigate()
    const [eventMessage, setEventMessage] = useState(null);

    const getMeasure = id => measurements?.find(x => x.measureId === id) || null

    const subtotal = act?.items.reduce((acc, item) => acc + item.total, 0)
    const [showAcceptButton, setShowAcceptButton] = useState(false)
    const [showDeclineButton, setShowDeclineButton] = useState(false)
    const [showCancelButton, setShowCancelButton] = useState(false)
    const [allowSync, setAllowSync] = useState(false)
    const showDeclineNotes = act.status === CustomerDeclined
    const showCopyButton = act.executor.inn === (activeOrganization.pinfl || activeOrganization.inn)

    useEffect(() => {

        dispatch(loadMeasurementsAsync(lang));
        setShowAcceptButton(actWrapper.canTheBuyerAccept(activeOrganization.inn, activeOrganization.pinfl))
        setShowDeclineButton(actWrapper.canTheBuyerDecline(activeOrganization.inn, activeOrganization.pinfl))
        setShowCancelButton(actWrapper.canTheSellerCancled(activeOrganization.inn, activeOrganization.pinfl))
        setAllowSync(actWrapper.allowSyncAct())

    }, [act, activeOrganization.inn])

    useEffect(() => {
        const onSyncActSuccessHandler = EventBus.on(ROAMING_ACT_SYNC_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.empowerment.alert.success_event_title'),
                text: t(lang, 'roaming.empowerment.alert.empowerment_sync_success')
            });
        })

        const onSendActRequestHandler = EventBus.on(ROAMING_ACT_REGISTER_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onSendActSuccessHandler = EventBus.on(ROAMING_ACT_REGISTER_SUCCESS, () => {
            toast.success(t(lang, "edi.common.toast.success"))
            dispatch(getOrganizationBillingInfoAsync());
            navigate(`../act/${modalId ? modalId : id}`)
            dispatch(updateShowInProgressAsync(false))
        })

        const onSendActFailedHandler = EventBus.on(ROAMING_ACT_REGISTER_FAILED, () => {
            toast.error(t(lang, "edi.common.toast.error"))
            dispatch(updateShowInProgressAsync(false))
        })

        return () => {
            EventBus.remove(ROAMING_ACT_SYNC_SUCCESS, onSyncActSuccessHandler)
            EventBus.remove(ROAMING_ACT_REGISTER_REQUESTED, onSendActRequestHandler)
            EventBus.remove(ROAMING_ACT_REGISTER_SUCCESS, onSendActSuccessHandler)
            EventBus.remove(ROAMING_ACT_REGISTER_FAILED, onSendActFailedHandler)
        }
    }, [])

    const signAct = async () => {
        let draftActStringify = JSON.stringify(draftAct)
        await ActFormSendAsync(currentCertificate, lang, draftActStringify, draftAct.ActId, draftAct.SellerTin)
    }

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('roaming-invoice-view'))
        return () => window.removeEventListener('scroll', () => scrollEvent('roaming-invoice-view'))
    }, []);

    return (
        <Fragment>
            {eventMessage &&
                <WarningAlert text={eventMessage.text} title={eventMessage.title} variant={eventMessage.variant}/>}

            <Row id={'roaming-invoice-view'} className={'justify-content-between position-sticky z-index-1'}
                 style={{top: '4rem'}}>
                <Col xs={"auto"} className={'d-flex gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'}
                            style={{width: 35, height: 35}}>
                        <IconArrowLeft/>
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.act_form.title")} № {act?.info?.number}</h5>
                        <div className={'d-flex align-items-center gap-2'}>
                            <span className={'second-text fs-9'}>
                                {t(lang, "edi.common.created.time")}:
                                {act.created_at}
                            </span>
                            {act.status && <Badge
                                bg={actWrapper.getRoamingStatusVariant(activeOrganization.pinfl, activeOrganization.inn)}
                                variant={'phoenix'}
                                className={'px-2 py-1 fs-9 text-uppercase text-decoration-none fw-medium border-0 rounded-4'}>
                                {t(lang, actWrapper.getRoamingStatus(activeOrganization.pinfl, activeOrganization.inn))}
                            </Badge>}
                        </div>
                    </div>
                </Col>
                <Col xs={"auto"} className={'d-flex justify-content-end gap-2'}>
                    {showCopyButton &&
                        <IconButton
                            variant="phoenix-primary"
                            size="sm"
                            icon={faClone}
                            as={Link}
                            to={act.status ? `/roaming/act/actions/${act.id}?status=${act?.status}` : `/roaming/act/actions/${act.id}?status=${null}`}
                            className={'btn-davr bg-white text-dark d-flex gap-2'}
                            iconClassName={"text-primary-davr fs-8"}>
                            {t(lang, "roaming.common.copy")}
                        </IconButton>
                    }
                    {act?.status ?
                        <>
                            {allowSync && <SyncAct id={act?.id}/>}
                            {showDeclineButton &&
                                <CustomerDecline
                                    disabled={!currentCertificate}
                                    ActDeclineHandler={(comment) => ActCustomerDeclineHashCodeAsync(currentCertificate, lang, act, comment)}
                                />}
                            {showCancelButton &&
                                <ActCancel
                                    disabled={!currentCertificate}
                                    ActCancelHandler={() => ActCancelHashCodeAsync(currentCertificate, lang, act)}
                                />}
                            {showAcceptButton &&
                                <CustomerAccept
                                    disabled={!currentCertificate}
                                    ActAcceptHandler={() => ActCustomerAcceptHashCodeAsync(currentCertificate, lang, act)}
                                />}
                        </>
                        :
                        <>
                            <IconButton
                                variant="phoenix-warning"
                                size="sm"
                                icon={faEdit}
                                type="submit"
                                as={Link}
                                to={`/roaming/act/draft-edit/${modalId ? modalId : id}`}
                                className={'btn-davr bg-white text-dark d-flex gap-2'}
                                iconClassName={"text-primary-davr fs-8"}
                            >
                                {t(lang, "edi.common.button.edit")}
                            </IconButton>
                            <SelectCertificateWrapperComponent
                                submitButton={
                                    <IconButton
                                        onClick={signAct}
                                        id="sendButton"
                                        size="sm"
                                        transform="shrink-3"
                                        variant="falcon-primary"
                                        icon={faSignature}
                                        className={'btn-davr bg-white text-dark d-flex gap-2'}
                                        iconClassName={"text-primary-davr fs-8"}
                                    >
                                        {t(lang, 'roaming.common.send')}
                                    </IconButton>
                                }
                                openDialogButtonText={t(lang, 'roaming.act_form_send')}
                            />
                        </>
                    }
                </Col>
            </Row>

            <Card className={'mt-2'}>
                <Card.Body className={'pb-0'}>
                    <h2 className={'fw-semi-bold text-center'}>
                        {t(lang, "roaming.act_form.title")}
                    </h2>
                    <h5 className="fw-semi-bold text-center second-text">
                        <i>{act.id}</i>
                    </h5>
                </Card.Body>
                <Card.Footer as={Row} className={'border-0 justify-content-between fs-8 pb-3'}>
                    <Col xs={12} md={6} className={'px-1'}>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.act_form.act_date")}</span>
                                </Col>
                                <Col md={8}>
                                    <span
                                        className={'main-text'}>{dayjs(act?.info?.date, 'YYYY-MM-DD').format(dateFormat)}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.act_form.act_number")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text'}>{act?.info?.number}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className={'px-1'}>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "roaming.act_form_contract_date")}</span>
                                </Col>
                                <Col md={8}>
                                    <span
                                        className={'main-text'}>{dayjs(act?.contract?.date, 'YYYY-MM-DD').format(dateFormat)}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span
                                        className={'second-text'}>{t(lang, "roaming.act_form_contract_number")}:</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text'}>{act?.contract?.number}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    {act?.info?.text && <Col xs={12} md={12} className={'px-1'}>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={2}>
                                    <span className={'second-text'}>{t(lang, "roaming.act_form_act_comment")}</span>
                                </Col>
                                <Col md={10}>
                                    <span
                                        className={'main-text'}>{act?.info?.text}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>}
                </Card.Footer>
            </Card>

            <Card className={'mt-2'}>
                <Card.Footer as={Row} className={'border-0 justify-content-between fs-8 pb-3 py-1'}>
                    <Col xs={12} md={6} className={'px-1'}>
                        <h5 className="mb-1 fw-bold second-text text-uppercase">{t(lang, "roaming.act_form_seller_title")}</h5>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "edi.common.company")}</span>
                                </Col>
                                <Col md={8}>
                                    <span title={act.executor.name}
                                          className={'main-text w-100 text-truncate d-block h-100'}>{act.executor.name}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, 'edi.common.label.inn')}</span>
                                </Col>
                                <Col md={8}>
                                    <span
                                        className={'main-text w-100 text-truncate d-block h-100'}>{act.executor.inn}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className={'px-1'}>
                        <h5 className="mb-1 fw-bold second-text text-uppercase">{t(lang, "roaming.act_form_buyer_name")}</h5>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "edi.common.company")}</span>
                                </Col>
                                <Col md={8}>
                                    <span title={act.customer.name}
                                          className={'main-text w-100 text-truncate d-block h-100'}>
                                        {act.customer.name}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, 'edi.common.label.inn')}</span>
                                </Col>
                                <Col md={8}>
                                    <span
                                        className={'main-text w-100 text-truncate d-block h-100'}>{act.customer.inn}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                </Card.Footer>
            </Card>
            <Card className={'mt-2 p-2'}>
                <Card.Body className={'p-0 border border-1 rounded-2'}>
                    <ActViewTable act={act} getMeasure={getMeasure} subtotal={subtotal}/>
                </Card.Body>
            </Card>

        </Fragment>
    );
};

export default Act;
