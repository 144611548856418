import React from 'react';
import {selectDateDayJSFormat, selectLang} from "../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../lang/lang";
import dayjs from "dayjs";
import {Button, Card, Col, Modal, ProgressBar, Row, Spinner} from "react-bootstrap";
import Spreadsheet from "react-spreadsheet";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const ActionProgressBar = ({show, count, result, onClose}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat);

    // progress bar value
    const currentProgressValue = (result.length/count).toFixed(1) * 100;

    // number of succeeded/failed actions
    const successResult = result.filter(res => !res.error);
    const failResult = result.filter(res => res.error);
    const isAllSucceeded = successResult.length === count;

    const items = failResult.map((item, index) => {
        return [
            {value: item.number, readOnly: true, className: 'text-primary'},
            {value: dayjs(item.date, "YYYY-MM-DD").format(currentDateDayJSFormat), readOnly: true},
            {value: item.contractor.inn, readOnly: true},
            {value: item.contractor.name, readOnly: true},
            {value: item?.error, className: 'text-danger', readOnly: true}
        ]
    })

    const headers = [
        t(lang, "actions.progressbar.columns.number"),
        t(lang, "actions.progressbar.columns.date"),
        t(lang, "actions.progressbar.columns.contractor.inn"),
        t(lang, "actions.progressbar.columns.contractor.name"),
        t(lang, "actions.progressbar.columns.error")
    ]

    const spreadSheet = [
        ...items
    ]

    return (
        <Modal backdrop="static" onHide={onClose} keyboard={false} show={show} fullscreen>
            <Modal.Header closeButton>
                {/*<FalconCloseButton onClick={onClose}/>*/}
            </Modal.Header>
            <Modal.Body style={{overflowX: "hidden"}}>
                <Row>
                    <Col xs={12} className="text-center my-3">
                        {result.length !== count &&
                            <Spinner
                                animation="border"
                                className="mb-3"
                                variant="primary"
                                style={{ width: "65px", height: "65px" }}
                            />
                        }
                        <div className="mt-5 w-100 w-sm-75 w-xl-50 px-sm-0 px-3 mx-auto">
                            <ProgressBar animated
                                         variant="primary"
                                         className="border border-400 shadow"
                                         now={currentProgressValue}
                                         label={`${currentProgressValue}%`}
                            />
                        </div>

                        <Row className="g-3 mt-3 w-100 justify-content-center">
                            <Col xl={1} xs={12} sm={4} className="text-center">
                                <h4 className="fs-4 fw-medium text-700">
                                    <p className="text-primary">{successResult.length}</p>
                                </h4>
                                <h6 className="text-primary">( {t(lang, "action.progressbar.succeeded")} )</h6>
                            </Col>
                            <Col xl={2} xs={12} sm={4} className="text-center">
                                <h4 className="fs-4 fw-medium text-700">
                                    <p>
                                        {t(lang, "action.progressbar.interval", {from: result.length !== count ? result.length + 1 : result.length, to: count})}
                                    </p>
                                </h4>
                                <h6 className="text-warning">( {t(lang, "action.progressbar.completed", {length: result.length})} )</h6>
                            </Col>
                            <Col xl={1} xs={12} sm={4} className="text-center">
                                <h4 className="fs-4 fw-medium text-700">
                                    <p className="text-danger">{failResult.length}</p>
                                </h4>
                                <h6 className="text-danger">( {t(lang, "action.progressbar.failed")} )</h6>
                            </Col>
                        </Row>
                    </Col>

                    {failResult.length > 0 &&
                        <Col xs={12} className="mt-3">
                            <Card className="shadow-none border" style={{overflowX: "auto"}}>
                                <Card.Header className="bg-light">
                                    <Card.Title className="text-danger">
                                        {t(lang, "")}
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body className="mx-auto">
                                    <Spreadsheet data={spreadSheet} columnLabels={headers} />
                                </Card.Body>
                            </Card>
                        </Col>
                    }

                    {isAllSucceeded &&
                        <Col xs={12} className="text-center py-3">
                            <h4 className="text-success">
                                <FontAwesomeIcon className="me-1" icon={faCheckCircle} />
                                {t(lang, "actions.progressbar.completed_successfully")}
                            </h4>
                        </Col>
                    }
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose}>{t(lang, "actions.progressbar.button.ready")}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ActionProgressBar;

ActionProgressBar.propTypes = {
    show: PropTypes.bool,
    count: PropTypes.number,
    result: PropTypes.array,
    onClose: PropTypes.func
}

ActionProgressBar.defaultProps = {
    show: false,
    count: 0,
    result: [],
    onClose: () => {}
}
