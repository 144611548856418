import React, {Fragment, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {selectLang, updateShowInProgressAsync} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {selectAccountRolePermissions} from "../../../store/reducers/role/roleReducer";
import checkPermission from "../../../enum/permission/CheckPermission";
import {PermissionRoamingContractView} from "../../../enum/permission/RoamingPermission";
import {loadContract} from "../../../store/reducers/roaming/contracts/roamingContractReducer";
import toast from "react-hot-toast";
import EventBus from "../../../eventbus/EventBus";
import {
    DOCUMENT_CANCEL_FAILED,
    DOCUMENT_CANCEL_STARTED,
    DOCUMENT_CANCEL_SUCCESS,
    DOCUMENT_DECLINE_FAILED,
    DOCUMENT_DECLINE_STARTED,
    DOCUMENT_DECLINE_SUCCESS,
    DOCUMENT_SIGN_FAILED,
    DOCUMENT_SIGN_STARTED,
    DOCUMENT_SIGN_SUCCESS
} from "../../../eventbus/romaing/signEvents";
import {getOrganizationBillingInfoAsync} from "../../../store/reducers/billing/billingReducer";
import SpinnerComponent from "../../../components/common/SpinnerComponent";
import ContractView from "../../../components/roaming/contracts/ContractView";

const ViewContract = ({modalId}) => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [contract, setContract] = useState(null)
    const activeOrganization = useSelector(selectActiveOrganization);
    const lang = useSelector(selectLang);
    const t = Translate
    const dispatch = useDispatch()
    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        permission.length && !dispatch(checkPermission(PermissionRoamingContractView)) && navigate(-1)
    }, [permission])

    const load = () => {
        loadContract(modalId ? modalId : id)
            .then(response => setContract(response.data))
            .catch(error => {
                console.log(error);
                navigate('/roaming/contracts/inbox')
            })
    }

    useEffect(() => {
        load()
    }, [modalId, id, activeOrganization])

    useEffect(() => {
        // =========== DOCUMENT_SIGN
        const signStartedHandler = EventBus.on(DOCUMENT_SIGN_STARTED, () => dispatch(updateShowInProgressAsync(true)))
        const signedHandler = EventBus.on(DOCUMENT_SIGN_SUCCESS, () => {
            toast.success(t(lang, "edi.document.decline.notification.success"))
            dispatch(getOrganizationBillingInfoAsync());
            dispatch(updateShowInProgressAsync(false))
            load()
        })
        const signFailedHandler = EventBus.on(DOCUMENT_SIGN_FAILED, (message) => {
            toast.error(t(lang, message))
            dispatch(updateShowInProgressAsync(false))
        });

        // =========== DOCUMENT_CANCEL
        const cancelStartedHandler = EventBus.on(DOCUMENT_CANCEL_STARTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })
        const canceledHandler = EventBus.on(DOCUMENT_CANCEL_SUCCESS, () => {
            toast.success(t(lang, "edi.document.decline.notification.success"))
            dispatch(updateShowInProgressAsync(false))
            load()
        })
        const cancelFailedHandler = EventBus.on(DOCUMENT_CANCEL_FAILED, (message) => {
            toast.error(t(lang, message))
            dispatch(updateShowInProgressAsync(false))
        })

        // =========== DOCUMENT_DECLINE
        const declineStartedHandler = EventBus.on(DOCUMENT_DECLINE_STARTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })
        const declinedHandler = EventBus.on(DOCUMENT_DECLINE_SUCCESS, () => {
            toast.success(t(lang, "edi.document.decline.notification.success"))
            dispatch(updateShowInProgressAsync(false))
            load()
        })
        const declineFailedHandler = EventBus.on(DOCUMENT_DECLINE_FAILED, (message) => {
            toast.error(t(lang, message))
            dispatch(updateShowInProgressAsync(false))
        })

        return () => {
            EventBus.remove(DOCUMENT_SIGN_STARTED, signStartedHandler)
            EventBus.remove(DOCUMENT_SIGN_SUCCESS, signedHandler)
            EventBus.remove(DOCUMENT_SIGN_FAILED, signFailedHandler)

            EventBus.remove(DOCUMENT_CANCEL_STARTED, cancelStartedHandler)
            EventBus.remove(DOCUMENT_CANCEL_SUCCESS, canceledHandler)
            EventBus.remove(DOCUMENT_CANCEL_FAILED, cancelFailedHandler)

            EventBus.remove(DOCUMENT_DECLINE_STARTED, declineStartedHandler)
            EventBus.remove(DOCUMENT_DECLINE_SUCCESS, declinedHandler)
            EventBus.remove(DOCUMENT_DECLINE_FAILED, declineFailedHandler)
        }
    }, [])

    return (
        <Fragment>
            {contract && <ContractView contract={contract}/>}
            {!contract && <SpinnerComponent />}
        </Fragment>
    );
};

export default ViewContract;
