import React, {useState} from 'react';
import {selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {loadDraftPDFAsync} from "../../../../store/reducers/roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import printJS from "print-js";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../IconButton";
import {Spinner} from "react-bootstrap";
import toast from "react-hot-toast";
import IconCloudDownload from "../../../../assets/icon/IconCloudDownload";

const RoamingDraftInvoicePrint = ({id, actionPdf, ...props}) => {

    const [loading, setLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const onPrint = () => {
        setLoading(true);
        actionPdf(id)
            .then(response => {
                setLoading(false);
                const blob = new Blob([response], {type: "application/pdf"});
                const blobUrl = window.URL.createObjectURL(blob);
                printJS(blobUrl);
            })
            .catch(error => {
                setLoading(false);
                toast.error(t(lang, "roaming.invoice.print_failed_error"))
            })
    }

    return (
        <IconButton {...props} icon={<IconCloudDownload />} transform="shrink-3" onClick={onPrint} className={'btn-davr bg-white text-dark d-flex gap-2'}>
            {loading ? <Spinner className="align-middle ms-2" size="sm" animation="border" role="switch"/> : <IconCloudDownload />}
            <span className="d-none d-sm-inline-block fw-semi-bold">{t(lang, 'roaming.invoice.print')}</span>
        </IconButton>
    );
};

export default RoamingDraftInvoicePrint;
