import React, {MouseEventHandler, PropsWithChildren} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {ButtonVariant} from "./common/Button";
const IconButton = ({
                        icon,
                        iconAlign = 'left',
                        iconClassName,
                        transform,
                        children,
                        ...rest
                    }) => (
    <Button {...rest}>
        {iconAlign === 'right' && children}
        <FontAwesomeIcon
            icon={icon}
            className={classNames(iconClassName, {
                'fw-semi-bold': true,
                'me-1': children && iconAlign === 'left',
                'ms-1': children && iconAlign === 'right'
            })}
            transform={transform}
        />
        {iconAlign === 'left' && children}
    </Button>
);

export default IconButton;
