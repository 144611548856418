import React from "react";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {selectMeasurements} from "../../store/reducers/roaming/roamingReducer";
import {useSelector} from "react-redux";
import ReactSelect from "react-select";
import CustomFloatingLabelSelect from "../floating-custom-select/CustomFloatingLabelSelect";

const SelectMeasurement = React.forwardRef(({onChange, defaultMeasureId, id, placeholder, ...props}, ref) => {
    const [value, setValue] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const measurements = useSelector(selectMeasurements)

    useEffect(() => {
        const defaultValue = measurements.find(measurement => measurement.measureId === defaultMeasureId);
        setValue(defaultValue);
        setSelectedOption(defaultValue);
    }, [measurements])

    const onChangeHandler = (measurement) => {
        setValue(measurement);
        onChange(measurement);
        setSelectedOption(measurement);
    }

    const getOptionLabel = option => option.name;
    const getOptionValue = option => option.measureId;

    return (
            <CustomFloatingLabelSelect
                label={placeholder}
                value={value}
                options={measurements}
                onChange={onChangeHandler}
                hideSelectedOptions={true}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                {...props}
            />
    )
});

SelectMeasurement.propTypes = {
    onChange: PropTypes.func
}

SelectMeasurement.defaultProps = {
    onChange: () => {
    }
}

export default SelectMeasurement;
