import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { Container, ContainerProps } from 'react-bootstrap';

const PhoenixContainer = ({
                              small,
                              className,
                              children
                          }) => {
    return (
        <Container
            bsPrefix={small ? 'container-small' : 'container'}
            className={classNames(className)}
        >
            {children}
        </Container>
    );
};

export default PhoenixContainer;
