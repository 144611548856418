import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import EventBus from "../../../eventbus/EventBus";
import {loadSettings} from "../../../store/reducers/settings/settingsReducer";
import {UPDATE_INVOICE_REGISTRY_SETTINGS_SUCCEED} from "../../../enum/settings/settingsEvents";
import {ADD_MASS_ROAMING_INVOICE_SUCCEED} from "../../../eventbus/romaing/excel-registry/excelRegistryEvents";
import MassAddRoamingInvoiceForm from "../../../components/roaming/invoice/mass-add/MassAddRoamingInvoiceForm";

const MassAddRoamingInvoice = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const addMassRoamingInvoiceSucceed = EventBus.on(ADD_MASS_ROAMING_INVOICE_SUCCEED, registry => {
            navigate(`/roaming/excel-registry/${registry.id}`);
        })

        const updateInvoiceRegistrySettingsSucceed = EventBus.on(UPDATE_INVOICE_REGISTRY_SETTINGS_SUCCEED, () => {
            dispatch(loadSettings());
        })

        return () => {
            EventBus.remove(ADD_MASS_ROAMING_INVOICE_SUCCEED, addMassRoamingInvoiceSucceed);
            EventBus.remove(UPDATE_INVOICE_REGISTRY_SETTINGS_SUCCEED, updateInvoiceRegistrySettingsSucceed);
        }
    }, [])

   /* useEffect(() => {
        dispatch(loadSettings());
    }, [])*/

    return (
       <MassAddRoamingInvoiceForm />
    );
};

export default MassAddRoamingInvoice;
