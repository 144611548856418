import React, {useState} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {selectCurrentCertificate} from "../../../store/reducers/eimzo/eimzoReducer";
import EventBus from "../../../eventbus/EventBus";
import {
    ROAMING_INVOICE_BULK_ACTION_FINISHED,
    ROAMING_INVOICE_BULK_ACTION_STARTED
} from "../../../eventbus/romaing/roaming-invoice/roamingInvoiceEvents";
import {sendAsync} from "../../../store/reducers/roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import SelectCertificateWrapperComponent from "../../common/SelectCertificateWrapperComponent";
import {Spinner} from "react-bootstrap";
import {faSignature} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../IconButton";
import IconSignature from "../../../assets/icon/IconSignature";

const RoamingInvoiceDraftSend = ({invoice, invoices, handleShowProgressBar, updateActionResult, ...props}) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const activeCertificate = useSelector(selectCurrentCertificate);

    // button loader
    const [loading, setLoading] = useState(false);

    // send
    const send = () => {
        if (invoices.length > 0)
            bulkSend();
        else
            singleSend();
    }

    const singleSend = () => {
        setLoading(true);
        sendAsync(activeCertificate, lang, invoice.content)
            .then(() => {
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            })
    }

    // bulk send document
    const bulkSend = async () => {
        handleShowProgressBar();
        EventBus.dispatch(ROAMING_INVOICE_BULK_ACTION_STARTED);

        const taskSize = 10;
        const tasks = []

        const signer = async invoice => {
            try {
                await sendAsync(activeCertificate, lang, invoice.content);
                updateActionResult({
                    number: invoice.info.number,
                    date: invoice.info.date,
                    contractor: {
                        name: invoice.customer.name,
                        inn: invoice.customer.inn
                    }
                });
            } catch (error) {
                updateActionResult({
                    number: invoice.info.number,
                    date: invoice.info.date,
                    contractor: {
                        name: invoice.customer.name,
                        inn: invoice.customer.inn
                    },
                    error: error?.message
                })
            }
        }

        for (let i = 0; i < invoices.length; i++) {
            tasks.push(signer(invoices[i]))

            if (tasks.length >= taskSize) {
                await Promise.all(tasks)
                tasks.splice(0, tasks.length)
            }
        }

        if (tasks.length > 0) {
            await Promise.all(tasks)
            tasks.splice(0, tasks.length)
        }
        EventBus.dispatch(ROAMING_INVOICE_BULK_ACTION_FINISHED);
    }


    return (
        <SelectCertificateWrapperComponent
            className={'btn-davr bg-primary-davr gap-1 text-white'}
            openDialogButtonText={t(lang, "roaming.invoice.send_button")}
            icon={<IconSignature />}
            submitButton={
            <IconButton icon={faSignature}
                        variant="phoenix-primary"
                        id="Send"
                        disabled={loading}
                        onClick={send}
                        className="fw-semi-bold"
                        {...props}
            >
                {loading && <Spinner size="sm" className='align-middle me-2' animation='border' role='switch'/>}
                {t(lang, "roaming.invoice.send_button")}
            </IconButton>
        }   />
    );
};

export default RoamingInvoiceDraftSend;
