import axios from 'axios';

class RoamingEmpowermentService {
    sendEmpowerment(params) {
        return axios.post('roaming/empowerment/send', params)
    }

    draftEmpowerment(empowerment) {
        return axios.post("/roaming/empowerment/draft/save", {
            content: empowerment
        })
    }

    getAcceptHashCode(id) {
        return axios.get(`/roaming/empowerment/hashcode/accept/${id}`)
    }
    getCancelHashCode(id) {
        return axios.get(`/roaming/empowerment/hashcode/cancel/${id}`,{
            transformResponse: [data => data]
        })
    }
    getDeclineHashCode(id, comment) {
        return axios.get(`/roaming/empowerment/hashcode/decline/${id}`,{
            transformResponse: [data => data],
            params: {comment: comment}
        })
    }

    customerCancelEmpowerment({id, signature}) {
        return axios.post(`roaming/empowerment/cancel/${id}`, {
            signature: signature
        })

    }
    personAcceptEmpowerment({id, signature}) {
        return axios.post(`roaming/empowerment/person/accept/${id}`, {
            signature
        })
    }
    personDeclineEmpowerment({id, signature, comment}) {
        return axios.post(`roaming/empowerment/person/decline/${id}`, {
            signature,
            comment
        })
    }
    executorAcceptEmpowerment({id, signature}) {
        return axios.post(`roaming/empowerment/executor/accept/${id}`, {
            signature
        })
    }
    executorDeclineEmpowerment({id, signature, comment}) {
        return axios.post(`roaming/empowerment/executor/decline/${id}`, {
            signature,
            comment
        })
    }

    syncEmpowerment(id) {
        return axios.get(`roaming/empowerment/sync/${id}`)
    }
    getEmpowerment(id) {
        return axios.get(`roaming/empowerment/get/${id}`)
    }

    getEmpowermentDraft(id) {
        return axios.get(`/roaming/empowerment/draft/get/${id}`)
    }

    getCustomerEmpowerments(params) {
        return axios.get('roaming/empowerment/customer/get', {
            params
        })
    }
    getCustomerCountEmpowerments(params) {
        return axios.get('roaming/empowerment/customer/count', {
            params
        })
    }
    getPersonEmpowerments(params) {
        return axios.get('roaming/empowerment/person/get', {
            params
        })
    }
    getPersonCountEmpowerments(params) {
        return axios.get('roaming/empowerment/person/count', {
            params
        })
    }

    getExecutorCountDraftEmpowerments(params) {
        return axios.get('/roaming/empowerment/drafts/count', {
            params
        })
    }

    getExecutorEmpowerments(params) {
        return axios.get('roaming/empowerment/executor/get', {
            params
        })
    }

    getExecutorDraftEmpowerments(params) {
        return axios.get('/roaming/empowerment/drafts/get', {
            params
        })
    }

    getExecutorCountEmpowerments(params) {
        return axios.get('roaming/empowerment/executor/count', {
            params
        })
    }

    printEmpowerment(id){
        return axios.get(`/pdf/roaming/empowerment/${id}`,{
            responseType:'blob'
        })
    }


}

const roamingEmpowermentService = new RoamingEmpowermentService();

export default roamingEmpowermentService;
