import React from 'react';

const IconAlertPrimary = ({width = 16, height = 14, color = "none"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 14" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 4.75004V7.75004M2.28418 9.3999C1.6021 10.5813 1.26117 11.1722 1.31211 11.6569C1.35655 12.0797 1.57844 12.4638 1.92236 12.7137C2.31653 13 2.99818 13 4.3614 13H11.6386C13.0018 13 13.6834 13 14.0775 12.7137C14.4215 12.4638 14.6435 12.0797 14.6879 11.6569C14.7388 11.1722 14.398 10.5813 13.7159 9.3999L10.0786 3.0999C9.39654 1.91851 9.05535 1.32792 8.61011 1.12968C8.22175 0.956773 7.778 0.956773 7.38964 1.12968C6.94459 1.32783 6.6036 1.91843 5.9221 3.09884L2.28418 9.3999ZM8.03809 10V10.075L7.96265 10.0752V10H8.03809Z"
                stroke="#5969FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default IconAlertPrimary;
