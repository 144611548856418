import ReasonType from "./ReasonType";

export const LackOfGoods = ReasonType(0, "edi.order.decline_reason_type.lack_of_goods");
export const ChangePriceOfGoods = ReasonType(1, "edi.order.decline_reason_type.change_price_of_goods");
export const ChangeProlongation = ReasonType(2, "edi.order.decline_reason_type.change_prolongation");
export const LackOfPaymentOrDebt = ReasonType(3, "edi.order.decline_reason_type.lack_of_payment_or_debt");
export const LackOfTransportation = ReasonType(4, "edi.order.decline_reason_type.lack_of_transportation");
export const LackOfPayment = ReasonType(5, "edi.order.decline_reason_type.lack_of_payment");
export const ForceMajorSituation = ReasonType(6, "edi.order.decline_reason_type.force_major_situation");

export const OrderDeclineReasonStatuses = [
    LackOfGoods,
    ChangePriceOfGoods,
    ChangeProlongation,
    LackOfPaymentOrDebt,
    LackOfTransportation,
    LackOfPayment,
    ForceMajorSituation,
]

export const getOrderDeclineReasonText = reason => {
    switch (reason) {
        case LackOfGoods.id:
            return LackOfGoods.name;
        case ChangePriceOfGoods.id:
            return ChangePriceOfGoods.name;
        case ChangeProlongation.id:
            return ChangeProlongation.name;
        case LackOfPaymentOrDebt.id:
            return LackOfPaymentOrDebt.name;
        case LackOfTransportation.id:
            return LackOfTransportation.name;
        case LackOfPayment.id:
            return LackOfPayment.name;
        case ForceMajorSituation.id:
            return ForceMajorSituation.name;
        default:
            return ''
    }
}
