import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {loadRolesAsync, selectRoles} from "../../../store/reducers/role/roleReducer";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import Select from "react-select";

const SelectRole = ({onChange, defaultRole}) => {
    const roles = useSelector(selectRoles);
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const [role, setRole] = useState(defaultRole || null)

    const onOptionChange = (option) => {
        onChange(option);
        setRole(option);
    }

    useEffect(() => {
        dispatch(loadRolesAsync())
    }, [])

    return (
        <Select
            isClearable
            defaultValue={role}
            options={roles}
            onChange={onOptionChange}
            hideSelectedOptions={true}
            getOptionLabel={option => t(lang, option.name)}
            getOptionValue={option => option.id}
            placeholder={t(lang, 'edi.common.form.select.placeholder')}
        />
    );
};

export default SelectRole;
