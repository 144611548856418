import React, {Fragment, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectAccountRolePermissions} from "../../../store/reducers/role/roleReducer";
import checkPermission from "../../../enum/permission/CheckPermission";
import {PermissionRoamingInvoiceView} from "../../../enum/permission/RoamingPermission";
import {
    loadRoamingInvoiceDraftAsync
} from "../../../store/reducers/roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import EventBus from "../../../eventbus/EventBus";
import {
    ROAMING_INVOICE_DRAFT_DELETE_SUCCESS,
    ROAMING_INVOICE_SEND_SUCCESS
} from "../../../eventbus/romaing/roaming-invoice/roamingInvoiceEvents";
import SpinnerComponent from "../../../components/common/SpinnerComponent";
import RoamingInvoice from "../../../components/roaming/invoice/RoamingInvoice";

const ViewRoamingInvoiceDraft = ({modalId}) => {
    const {id} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const permission = useSelector(selectAccountRolePermissions)
    const [draft, setDraft] = useState(null);

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingInvoiceView))) {
                navigate(-1)
        }
    }, [permission])

    const loadDraft = () => {
        loadRoamingInvoiceDraftAsync(modalId ? modalId : id)
            .then((loadedDraft) => {
                const content = JSON.parse(loadedDraft.content);
                try {
                    const viewKeys = {
                        content: loadedDraft.content,
                        id: content.FacturaId,
                        created_at: loadedDraft.created_at,
                        updated_at: loadedDraft.updated_at,
                        customer: {
                            inn: content.BuyerTin || '',
                            name: content.Buyer?.Name || '',
                            account: content.Buyer?.Account || '',
                            mfo: content.Buyer?.BankId || '',
                            director: content.Buyer?.Director || '',
                            accountant: content.Buyer?.Accountant || '',
                            oked: content.Buyer?.Oked || '',
                            address: content.Buyer?.Address || '',
                            nds_registration_code: content.Buyer?.VatRegCode || '',
                            nds_status: content.Buyer?.VatRegStatus || '',
                            tax_gap: content.Buyer?.TaxGap || '',
                            branch: {
                                code: content.Buyer?.BranchCode || '',
                                name: content.Buyer?.BranchName || ''
                            }
                        },
                        executor: {
                            inn: content.SellerTin,
                            name: content.Seller.Name,
                            account: content.Seller.Account,
                            mfo: content.Seller.BankId,
                            director: content.Seller.Director,
                            accountant: content.Seller.Accountant,
                            oked: content.Seller.Oked,
                            address: content.Seller.Address,
                            nds_registration_code: content.Seller.VatRegCode,
                            nds_status: content.Seller.VatRegStatus,
                            tax_gap: content.Seller.TaxGap,
                            branch: {
                                code: content.Seller.BranchCode,
                                name: content.Seller.BranchName
                            },
                        },
                        info: {
                            number: content.FacturaDoc.FacturaNo,
                            date: content.FacturaDoc.FacturaDate
                        },
                        contract: {
                            number: content.ContractDoc.ContractNo,
                            date: content.ContractDoc.ContractDate
                        },
                        old_invoice: {
                            id: content.OldFacturaDoc?.OldFacturaId || '',
                            number: content.OldFacturaDoc?.OldFacturaNo || '',
                            date: content.OldFacturaDoc?.OldFacturaDate || ''
                        },
                        notes: '',
                        status: null,
                        foreign_company: {
                            country_id: content.ForeignCompany?.CountryId || '',
                            name: content.ForeignCompany?.Name || '',
                            address: content.ForeignCompany?.Address || '',
                            account: content.ForeignCompany?.Account || ''
                        },
                        releaser: {
                            name: content.ItemReleasedDoc?.ItemReleasedFio || '',
                            pinfl: content.ItemReleasedDoc?.ItemReleasedPinfl || '',
                            inn: ''
                        },
                        empowerment: {
                            name: content.FacturaEmpowermentDoc?.AgentFio || '',
                            inn: '',
                            pinfl: content.FacturaEmpowermentDoc?.AgentPinfl || '',
                            number: content.FacturaEmpowermentDoc?.EmpowermentNo || '',
                            date_of_issue: content.FacturaEmpowermentDoc?.EmpowermentDateOfIssue || ''
                        },
                        total_info: {
                            total: loadedDraft.total_info.total,
                            nds_value: loadedDraft.total_info.nds_value,
                            total_with_nds: loadedDraft.total_info.total_with_nds
                        },
                        histories: [],
                        total_with_nds_after_adds: 0,
                        lot_id: content.LotId,
                        waybill_id: content.WaybillIds,
                        contract_id: content.ContractId,
                        factura_type: content.FacturaType,
                        single_sided_type: content.SingleSidedType,
                        income_type: content.IncomeType,
                        has_marking: content.HasMarking,
                        has_committent: content.ProductList.HasCommittent,
                        has_lgota: content.ProductList.HasLgota,
                        hide_report_committent: content.ProductList.HideReportCommittent,
                        has_medical: content.ProductList.HasMedical,
                        has_excise: content.ProductList.HasExcise,
                        has_vat: content.ProductList.HasVat,
                        is_empowered: false,
                        items: content.ProductList.Products.map((item) => {
                            return {
                                ordering_number: item.OrdNo,
                                committent_name: item.CommittentName,
                                committent_inn: item.CommittentTin,
                                committent_nds_registration_code: item.CommittentVatRegCode,
                                committent_nds_status: item.CommittentVatRegStatus,
                                name: item.Name,
                                catalog: {
                                    code: item.CatalogCode,
                                    name: item.CatalogName
                                },
                                package: {
                                    code: item.PackageCode,
                                    name: item.PackageName
                                },
                                origin: item.Origin,
                                barcode: item.Barcode,
                                measure_id: item.MeasureId,
                                base_price: +item.BaseSumma,
                                profit_rate: +item.ProfitRate,
                                price: +item.Summa,
                                nds_rate: item.VatRate,
                                nds_value: +item.VatSum,
                                excise_rate: +item.ExciseRate,
                                excise_value: +item.ExciseSum,
                                quantity: +item.Count,
                                without_vat: item.WithoutVat,
                                total: +item.DeliverySum,
                                total_with_nds: +item.DeliverySumWithVat,
                                lgota_id: item.LgotaId,
                                lgota_name: item.LgotaName,
                                lgota_type: item.LgotaType,
                                lgota_vat_sum: +item.LgotaVatSum,
                                serial: item.Serial,
                                marking: item.Marks ? {
                                    product_type: item.Marks.product_type,
                                    kiz: item.Marks.KIZ,
                                    nom_upak: item.Marks.NomUpak,
                                    ident_trans_upak: item.Marks.IdentTransUpak
                                } : null
                            }
                        }),
                        roaming_error: loadedDraft.roaming_error
                    }
                    setDraft(viewKeys);
                } catch (e) {
                    console.log(e);
                }
            })
            .catch(e => setDraft(null))
    }

    useEffect(() => {
        loadDraft();
    }, [id])

    useEffect(() => {
        const onRoamingInvoiceSendSuccessHandler = EventBus.on(ROAMING_INVOICE_SEND_SUCCESS, (invoice) => {
            navigate(`/roaming/invoice/${invoice.id}`)
        });
        const onRoamingInvoiceDraftDeleteSuccessHandler = EventBus.on(ROAMING_INVOICE_DRAFT_DELETE_SUCCESS, (draft) => {
            navigate(`/roaming/invoices/draft`)
        })

        return () => {
            EventBus.remove(ROAMING_INVOICE_SEND_SUCCESS, onRoamingInvoiceSendSuccessHandler);
            EventBus.remove(ROAMING_INVOICE_DRAFT_DELETE_SUCCESS, onRoamingInvoiceDraftDeleteSuccessHandler);
        }
    }, [])

    return (
        <Fragment>
            {draft && <RoamingInvoice invoice={draft} />}
            {!draft && <SpinnerComponent />}
        </Fragment>
    );
};

export default ViewRoamingInvoiceDraft;
