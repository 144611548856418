import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {selectDateFormat, selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {Controller, useFormContext} from "react-hook-form";
import {Card, Col, FloatingLabel, Row, Form} from "react-bootstrap";
import CustomFloatingLabelSelect from "../../../floating-custom-select/CustomFloatingLabelSelect";
import {
    deliveryTypeOptions,
    transportTypeOptions
} from "../../../../store/reducers/roaming/waybill-v2/romaingWaybillV2Reducer";
import WaybillV2TypeSelect from "../WaybillV2TypeSelect";
import DatePickerComponent from "../../../common/DatePickerComponent";
import WaybillV2OldDocInfo from "./WaybillV2OldDocInfo";

const DetailsFormOne = () => {
    const {register, formState: {errors}, control, watch} = useFormContext();
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Fragment>
            <h4 className={'fs-8 fw-medium second-text'}>{t(lang, 'items.common.details')}</h4>
            <Card className="p-2">
                <Row>
                    <Form.Group as={Col} sm={12} md={4}>
                        <FloatingLabel label={<>
                            {t(lang, 'roaming.waybill.send.details_form.shipment_number')}
                            <span className="text-primary-davr">*</span>
                        </>}>
                            <Form.Control
                                type="text"
                                placeholder={<>
                                    {t(lang, 'roaming.waybill.send.details_form.shipment_number')}
                                    <span className="text-primary-davr">*</span>
                                </>}
                                {...register('waybill_info.number', {
                                    required: t(lang, 'edi.common.forms.validations.is_required')
                                })}
                            />
                        </FloatingLabel>

                        <Form.Control.Feedback type="invalid" className={'d-block'}>
                            {errors?.waybill_info?.number?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} sm={12} md={4}>
                        <Controller
                            name={'waybill_type'}
                            rules={{required: t(lang, 'items.common.validations.is_required')}}
                            render={({field}) => (
                                <WaybillV2TypeSelect
                                    defaultType={field.value}
                                    onChange={field.onChange}
                                    placeholder={<>
                                        {t(lang, 'roaming.waybill.v2.send.type.select')}
                                        <span className="text-primary-davr">*</span>
                                    </>}
                                />
                            )}
                        />
                    </Form.Group>

                    <Form.Group as={Col} sm={12} md={4}>
                        <Controller
                            control={control}
                            name='waybill_info.date'
                            rules={{
                                required: t(lang, 'edi.common.forms.validations.is_required'),
                            }}
                            render={({field}) => (
                                <DatePickerComponent
                                    field={field}
                                    isClearable
                                    dateFormat={dateFormat}
                                    placeholderText={<>
                                        {t(lang, 'roaming.waybill.send.details_form.shipment_date')}
                                        <span className="text-primary-davr">*</span>
                                    </>}
                                />
                            )}
                        />
                        <Form.Control.Feedback type="invalid" className={'d-block'}>
                            {errors?.waybill_info?.date?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} sm={12} md={6}>
                        <Controller
                            rules={{
                                required: t(lang, 'edi.common.forms.validations.is_required'),
                            }}
                            name="delivery_type"
                            render={({field}) => (
                                <CustomFloatingLabelSelect
                                    hideSelectedOptions={true}
                                    options={deliveryTypeOptions}
                                    getOptionValue={option => option.value}
                                    getOptionLabel={option => t(lang, option.label)}
                                    onChange={option => field.onChange(option?.value)}
                                    value={deliveryTypeOptions.find(option => option.value === field.value)}
                                    label={<>
                                        {t(lang, 'roaming.waybill.send.delivery_details_form.delivery_type')}
                                        <span className="text-primary-davr">*</span>
                                    </>}
                                />
                            )}
                        />
                        <Form.Control.Feedback className={'d-block'} type="invalid">
                            {errors?.delivery_type?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} sm={12} md={6}>
                        <Controller
                            rules={{
                                required: t(lang, 'edi.common.forms.validations.is_required'),
                            }}
                            name="transport_type"
                            render={({field}) => (
                                <CustomFloatingLabelSelect
                                    hideSelectedOptions={true}
                                    options={transportTypeOptions}
                                    getOptionValue={option => option.value}
                                    getOptionLabel={option => t(lang, option.text)}
                                    onChange={option => field.onChange(option?.value)}
                                    value={transportTypeOptions.find(option => option.value === field.value)}
                                    label={
                                        <>
                                            {t(lang, 'roaming.waybill.send.delivery_details_transport_type')}
                                            <span className="text-primary-davr">*</span>
                                        </>
                                    }
                                />
                            )}
                        />
                        <Form.Control.Feedback className={'d-block'} type="invalid">
                            {errors?.transport_type?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <WaybillV2OldDocInfo />
                </Row>
            </Card>
        </Fragment>
    );
};

export default DetailsFormOne;
