import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import ActionButton from "../../../common/ActionButton";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import SelectMeasurement from "../../../common/SelectMeasurement";
import classNames from "classnames";
import ReactSelect from "react-select";
import SelectCompanyCatalog from "../../../IKPU/SelectCompanyCatalog";
import {Form} from "react-bootstrap";
import PropTypes from "prop-types";
import Badge from "../../../common/Badge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ActItemRow = ({allowDelete, onDelete, arrayActFieldName, index}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const {register, formState: {errors}, setValue, control} = useFormContext();
    const rootName = `${arrayActFieldName}.${index}`
    const item = useWatch({name: rootName});

    const {quantity, price, measurementId, packageCode, packageName } = item
    const catalog = useWatch({name: `${rootName}.catalog`, exact: true})
    const packages = useWatch({name: `${rootName}.packages`, exact: true})

    useEffect(() => {
        let total = quantity * price
        setValue(`${rootName}.totalSum`, total)

    }, [quantity, price])

    let itemErrors = {};
    if (errors && errors[arrayActFieldName])
        itemErrors = errors[arrayActFieldName][index];

    const onDeleteClicked = () => {
        onDelete(index);
    };

    const onChangeCatalog = (catalog) => {
        if (catalog) {
            setValue(`${rootName}.catalog`, catalog)
            setValue(`${rootName}.catalogClassCode`, catalog.class_code, {shouldValidate: true});
            setValue(`${rootName}.catalogClassName`, catalog.name, {shouldValidate: true});
        } else {
            setValue(`${rootName}.catalogClassCode`, null, {shouldValidate: true});
            setValue(`${rootName}.catalogClassName`, null, {shouldValidate: true});
        }
    };

    useEffect(() => {
        if (catalog && packages) {
            if (measurementId !== "")
                setValue(`${rootName}.measurementId`, "")

            const name = lang === "ru" ? packages.name_ru : packages.name_uz
            if (packageName !== name)
                setValue(`${rootName}.packageName`, name)
            if (packageCode !== packages.code)
                setValue(`${rootName}.packageCode`, packages.code);
        } else {
            if (packageName !== null)
                setValue(`${rootName}.packageName`, null)
            if (packageCode !== null)
                setValue(`${rootName}.packageCode`, null);
        }
    },[catalog, packages])

    return (
        <tr>
            <td className={'align-middle'}>
                {index + 1}
            </td>

            <td className={'p-1'}>
                <Controller
                    name={`${rootName}.catalogClassCode`}
                    control={control}
                    rules={{
                        required: t(lang, "edi.common.forms.validations.is_required")
                    }}
                    render={({field}) => (
                        <SelectCompanyCatalog
                            size="sm"
                            variant={itemErrors?.catalogClassCode ? 'outline-danger' : 'outline-primary'}
                            textButton={field.value || t(lang, "edi.common.select_button")}
                            onChange={(catalog) => {
                                onChangeCatalog(catalog)
                            }}
                            style={{height: '37px'}}
                        />
                    )}
                />
                <Form.Control.Feedback type="invalid">
                    {itemErrors?.catalogClassCode?.message}
                </Form.Control.Feedback>
            </td>

            <td className={'p-1'}>
                <Form.Control
                    style={{height: '37px'}}
                    name={`${rootName}.Items`}
                    placeholder={t(lang, "")}
                    {...register(`${rootName}.Items`, {
                        required: t(lang, "edi.common.forms.validations.is_required"),
                    })}
                    isInvalid={itemErrors?.Items}
                />

                <Form.Control.Feedback type="invalid">
                    {itemErrors?.Items?.message}
                </Form.Control.Feedback>
            </td>

            {catalog?.use_package ?
                <td className={'p-1'}>
                    <Controller name={`${rootName}.packages`}
                                rules={{
                                    required: t(lang, "roaming.validations.is_required")
                                }}
                                render={({field}) => (
                                    <div className="select-container">
                                        <ReactSelect options={catalog?.package_names}
                                                     placeholder={t(lang, "")}
                                                     getOptionLabel={option => lang === "ru" ? option.name_ru : option.name_uz}
                                                     getOptionValue={option => option.code}
                                                     defaultValue={catalog?.package_names.find(p => p.code === field.value?.code)}
                                                     menuPortalTarget={document.body}
                                                     hideSelectedOptions
                                                     className={classNames('select', {
                                                         'is-invalid': itemErrors?.packages
                                                     })}
                                                     isClearable
                                                     onChange={field.onChange}
                                                     style={{height: '37px'}}
                                        />
                                    </div>
                                )}
                    />

                    <Form.Control.Feedback
                        type="invalid">{itemErrors?.packages?.message}</Form.Control.Feedback>
                </td>
                :
                <td className={'p-1'}>
                    <Controller
                        name={`${rootName}.measurementId`}
                        control={control}
                        rules={{
                            required: t(lang, "edi.common.forms.validations.is_required")
                        }}
                        render={({field}) => {
                            return (
                                <SelectMeasurement
                                    placeholder={t(lang, "")}
                                    className={classNames({
                                        "is-invalid": itemErrors?.measurementId
                                    })}
                                    isClearable
                                    onChange={measurement => field.onChange(measurement?.measureId)}
                                    defaultMeasureId={field.value}
                                    menuPortalTarget={document.body}
                                    style={{height: '37px'}}
                                />
                            )
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {itemErrors?.measurementId?.message}
                    </Form.Control.Feedback>
                </td>
            }

            <td className={'p-1'}>
                <Form.Control
                    name={`${rootName}.quantity`}
                    type="number"
                    placeholder={t(lang, "")}
                    {...register(`${rootName}.quantity`, {
                        pattern: /^[0-9]+$/,
                        required: t(lang, "edi.common.forms.validations.is_required"),
                    })}
                    isInvalid={itemErrors?.quantity}
                    style={{height: '37px'}}
                />

                <Form.Control.Feedback type="invalid">
                    {itemErrors?.quantity?.message}
                </Form.Control.Feedback>
            </td>

            <td className={'p-1'}>
                <Form.Control
                    name={`${rootName}.price`}
                    type="number"
                    placeholder={t(lang, "")}
                    {...register(`${rootName}.price`, {
                        pattern: /^[0-9]+$/,
                        required: t(lang, "edi.common.forms.validations.is_required"),
                    })}
                    isInvalid={itemErrors?.price}
                    style={{height: '37px'}}
                />

                <Form.Control.Feedback type="invalid">
                    {itemErrors?.price?.message}
                </Form.Control.Feedback>
            </td>

            <td className={'p-1'}>
                <Form.Control
                    {...register(`${rootName}.totalSum`)}
                    disabled={true}
                    style={{height: '37px'}}
                />
            </td>

            <td className="p-1 text-end">
                {
                    allowDelete && <Badge
                        className={'px-2 py-2 fs-8 rounded-3 cursor-pointer'}
                        bg="danger-davr"
                        variant="phoenix"
                    >
                        <FontAwesomeIcon icon={faTrashAlt} onClick={onDeleteClicked}  />
                    </Badge>
                }
            </td>
        </tr>
    );
};


ActItemRow.propTypes = {
    allowDelete: PropTypes.bool,
    onDelete: PropTypes.func,
    arrayActFieldName: PropTypes.string,
    index: PropTypes.number,
};

ActItemRow.defaultProps = {
    allowDelete: false,
    onDelete: () => {
    },
    arrayActFieldName: "",
    index: 0,
};

export default ActItemRow;
