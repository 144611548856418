import React, {Fragment, useState} from 'react';
import {useSelector} from "react-redux";
import {selectCurrentCertificate} from "../../../../store/reducers/eimzo/eimzoReducer";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {carrierCancelWaybillAsync} from "../../../../store/reducers/roaming/waybill/roamingWaybillReducer";
import IconButton from "../../../IconButton";
import SelectCertificateWrapperComponent from "../../../common/SelectCertificateWrapperComponent";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {Button, Modal, Spinner} from "react-bootstrap";
import IconFolderCancel from "../../../../assets/icon/IconFolderCancel";

const CarrierCancelWaybill = ({id, ...props}) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const lang = useSelector(selectLang);
    const t = Translate;

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const cancelWaybillHandler = () => {
        setLoading(true);

        carrierCancelWaybillAsync({
            id: id,
            certificate: currentCertificate,
        })
            .then(() => handleClose())
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }

    return (
        <Fragment>
            <SelectCertificateWrapperComponent
                cancelCertificate={true}
                submitButton={
                    <IconButton
                        size="sm"
                        {...props}
                        icon={''}
                        id="cancelWaybill"
                        iconClassName="me-1"
                        onClick={handleShow}
                        className="btn-davr px-3 py-0 bg-white d-flex gap-2"
                        transform="shrink-3"
                        variant={'phoenix-secondary'}
                    >
                        <IconFolderCancel />
                        {t(lang, 'roaming.common.cancel')}
                    </IconButton>
                }
                openDialogButtonText={t(lang, 'roaming.common.cancel')}
            />

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, 'roaming.waybill.cancel_waybill.modal.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 className="text-danger">
                        {t(lang, 'roaming.waybill.cancel_waybill.modal.description')}
                    </h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>{t(lang, 'roaming.common.close')}</Button>

                    <Button variant="danger" onClick={cancelWaybillHandler}>
                        {loading && <Spinner className="me-1" animation="border" role="status" size="sm"/>}
                        {t(lang, 'roaming.common.cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default CarrierCancelWaybill;
