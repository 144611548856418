import React, {forwardRef} from 'react';
import Badge from "./Badge";
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {Translate} from "../../lang/lang";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ErrorAlert = forwardRef(({errorMessage}, ref) => {
    const lang = useSelector(selectLang);

    return (
        <div className={'w-100'}>
            <Badge className={'w-100 p-3 fs-7 d-flex justify-content-center align-items-center'} variant={'phoenix'} bg={'danger'}  iconPosition="start" icon={<FontAwesomeIcon icon={faCircleExclamation} className="fs-4 me-3"/>}>
                <span>{Translate(lang, 'roaming.waybill.error_alert.title')}</span>
                <p className="fs-8 fw-medium mb-2 text-wrap">{errorMessage}</p>
            </Badge>
        </div>
    );
});

export default ErrorAlert;
