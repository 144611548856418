export const AwaitCustomerApprove = 0
export const AwaitExecutorApprove = 1
export const ExecutorEdited = 2
export const ExecutorDeclined = 3
export const ExecutorApproved = 4
export const Stopped = 5
export const Finished = 6
export const Closed = 7
export const Expired = 8
export const InProgress = 9

const ReturnOrderStatus = (id, name) => {
    return {
        id,
        name
    }
}

export const GetStatusTextReturnOrder = status => {
    switch (status) {
        case AwaitExecutorApprove:
            return 'edi.return_order.status.await_customer_approve' // 'Подтверждён заказчиком'
        case ExecutorEdited:
            return 'edi.return_order.status.executor_edited' // 'Изменён поставщиком'
        case ExecutorDeclined:
            return 'edi.return_order.status.executor_declined' // 'Отклонён поставщиком'
        case ExecutorApproved:
            return 'edi.return_order.status.executor_approved' // 'Подтверждён поставщиком'
        case Stopped:
            return 'edi.return_order.status.stopped' // 'Приостановлен поставщиком'
        case Finished:
            return 'edi.return_order.status.finished' // 'Выполнен'
        case Closed:
            return 'edi.return_order.status.closed' // 'Закрыт'
        case Expired:
            return 'edi.return_order.status.expired' // 'Просрочен'
        case InProgress:
            return 'edi.return_order.status.in_progress' // 'Поставка'
        default:
            return 'edi.return_order.status.default' // 'Не подтверждён'
    }
}

// export const GetStatusVariant = status =>{
//     switch (status) {
//         case AwaitExecutorApprove:
//             return 'info'
//         case ExecutorEdited:
//             return 'warning'
//         case ExecutorDeclined:
//             return 'danger'
//         case ExecutorApproved:
//             return 'success'
//         case Stopped:
//             return 'secondary'
//         case Finished:
//             return 'success'
//         case Closed:
//             return 'secondary'
//         case Expired:
//             return 'warning'
//         case InProgress:
//             return 'primary'
//         default:
//             return 'dark'
//     }
// }

export const getStatusColorReturnOrder = status => {
    switch (status) {
        case AwaitExecutorApprove:
            return {
                bg: 'secondary',
            }
        case ExecutorEdited:
            return {
                bg: 'warning',
                variant: 'phoenix'
            }
        case ExecutorDeclined:
            return {
                bg: 'danger',
            }
        case ExecutorApproved:
            return {
                bg: 'primary',
                variant: 'phoenix'
            }
        case Stopped:
            return {
                bg: 'danger',
                variant: 'phoenix'
            }
        case Finished:
            return {
                bg: 'success',
                variant: 'phoenix'
            }
        case Closed:
            return {
                bg: 'info',
                variant: 'phoenix'
            }
        case Expired:
            return {
                bg:'warning',
            }
        case InProgress:
            return {
                bg: 'success',
                color: 'phoenix'
            }
        default:
            return {
                bg: 'secondary',
                variant: 'phoenix'
            }
    }
}

export const ReturnOrderStatuses = [
    ReturnOrderStatus(AwaitCustomerApprove, GetStatusTextReturnOrder(AwaitCustomerApprove)),
    ReturnOrderStatus(AwaitExecutorApprove, GetStatusTextReturnOrder(AwaitExecutorApprove)),
    ReturnOrderStatus(ExecutorEdited, GetStatusTextReturnOrder(ExecutorEdited)),
    ReturnOrderStatus(ExecutorDeclined, GetStatusTextReturnOrder(ExecutorDeclined)),
    ReturnOrderStatus(ExecutorApproved, GetStatusTextReturnOrder(ExecutorApproved)),
    ReturnOrderStatus(Stopped, GetStatusTextReturnOrder(Stopped)),
    ReturnOrderStatus(Finished, GetStatusTextReturnOrder(Finished)),
    ReturnOrderStatus(Closed, GetStatusTextReturnOrder(Closed)),
    ReturnOrderStatus(Expired, GetStatusTextReturnOrder(Expired)),
    ReturnOrderStatus(InProgress, GetStatusTextReturnOrder(InProgress))
]
