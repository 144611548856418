import React, {useImperativeHandle, useEffect, useState, useRef, Fragment} from 'react';
import {Translate} from "../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {getCompanies, getCompany} from "../../store/reducers/roaming/roamingReferenceReducer";
import {debounce} from "underscore";
import {InputGroup} from "react-bootstrap";
import AsyncSelect from 'react-select/async'
import classNames from "classnames";
import CustomFloatingLabelSelect from "../floating-custom-select/CustomFloatingLabelSelect";
import CustomFloatingLabelSelectAsync from "../floating-custom-select/CustomFloatingLabelSelectAsync";
const innFormatRegEx = /^(\d{9}|\d{14})$/


const FindCompanySearchable = React.forwardRef(({onChange, inputGroupProps, inputProps, defaultInn, disabled, placeholder, id}, ref) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [value, setValue] = useState(null);
    const onChangeHandler = (OrganizationInfo) => {
        onChange(OrganizationInfo)
        setValue(OrganizationInfo)
    };
    const loadOptions = (query, callback) => {
        if (query) {
            getCompanies({
                q: query,
                active_accounts: true,
                frozen_accounts: true,
                main_accounts: true
            })
                .then((organizations) => {
                    callback(organizations);
                })
                .catch(() => {
                    callback([])
                })
        } else {
            callback([]);
        }
    };
    const debouncedLoadOptions = debounce(loadOptions, 500);

    const formatOptionLabel = (option) => {
        return (
            <div className="fs-9">
                <span className="text-black fw-bold me-1">{option?.inn}</span>-
                {option?.person_num &&
                    <>
                        <span className="text-black fw-bold mx-1">{option?.person_num}</span>-
                    </>
                }
                <span className="ms-1 text-facebook">{option?.short_name || option?.name}</span>
            </div>
        )
    };
    useEffect(() => {
        findCompanyAsync()
    }, [defaultInn]);

    const findCompanyAsync = async () => {
        if (!defaultInn) {
            setValue(null)
            return onChange(null)
        }
        if (innFormatRegEx.test(defaultInn)) {
            if (value?.inn === defaultInn || value?.person_num === defaultInn)
                return;
            let organization = await getCompany(defaultInn, {
                active_accounts: true,
                frozen_accounts: true,
                main_accounts: true
            });
            setValue(organization);
            onChange(organization);
        }
    }

    return (
        <InputGroup className={'select'} {...inputGroupProps}>
            <CustomFloatingLabelSelectAsync
                loadOptions={debouncedLoadOptions}
                ref={ref}
                isDisabled={disabled}
                formatOptionLabel={formatOptionLabel}
                getOptionValue={option => option?.person_num || option?.inn}
                getOptionLabel={option => `${option?.person_num} ${option?.inn} / ${option?.name}`}
                label={placeholder}
                style={{width: "100%"}}
                value={value}
                defaultOptions
                onChange={onChangeHandler}
                isClearable
                noOptionsMessage={() => t(lang, "edi.toast.find_company_no_option")}
                className={classNames('w-100')}
                {...inputProps}
            />
        </InputGroup>
    )
});

export default FindCompanySearchable;
