import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import {selectCurrentCertificate} from "../../../../store/reducers/eimzo/eimzoReducer";
import {selectDateDayJSFormat, selectLang} from "../../../../store/reducers/main/main";
import {loadMeasurementsAsync, selectMeasurements} from "../../../../store/reducers/roaming/roamingReducer";
import {Translate} from "../../../../lang/lang";
import {
    CarrierSent,
    CustomerDecline,
    getWaybillStatusText,
    getWaybillStatusVariant
} from "../../../../enum/roaming/waybill/WaybillStatus";
import {
    DeliveryTypeOptions, sendWaybillAsync,
    TrailerTypeOptions
} from "../../../../store/reducers/roaming/waybill/roamingWaybillReducer";
import toast from "react-hot-toast";
import {Card, Col, Row, Spinner} from "react-bootstrap";
import {
    PermissionRoamingShipmentAccept,
    PermissionRoamingShipmentUpdate
} from "../../../../enum/permission/RoamingPermission";
import SelectCertificateWrapperComponent from "../../../common/SelectCertificateWrapperComponent";
import IconButton from "../../../IconButton";
import checkPermission from "../../../../enum/permission/CheckPermission";
import {Link, useNavigate} from "react-router-dom";
import toastPermission from "../../../../enum/permission/ToastPermission";
import {faClone, faPencil, faSignature} from "@fortawesome/free-solid-svg-icons";
import CarrierCancelWaybill from "./CarrierCancelWaybill";
import CustomerDeclineWaybill from "./CustomerDeclineWaybill";
import CustomerAcceptWaybill from "./CustomerAcceptWaybill";
import Badge from "../../../common/Badge";
import WaybillViewTable from "./WaybillViewTable";
import IconArrowLeft from "../../../../assets/icon/IconArrowLeft";
import {GetStatusTextReturnOrder} from "../../../../enum/edi/return-order/ReturnOrderStatus";
import {scrollEvent} from "../../../../helpers/scrollEvent";
import IconSignature from "../../../../assets/icon/IconSignature";

const Waybill = ({waybill, content = null}) => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const [loading, setLoading] = useState(false);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const measurements = useSelector(selectMeasurements);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const t = Translate;

    const viewer_inn_or_pinfl = activeOrganization.pinfl || activeOrganization.inn;
    const isCarrier = viewer_inn_or_pinfl === waybill.carrier.inn;
    const isCustomer = viewer_inn_or_pinfl === waybill.customer.inn;

    const isDraftWaybill = !waybill.status && content;
    const allowCancel = isCarrier && waybill.status === CarrierSent;
    const allowDecline = isCustomer && waybill.status === CarrierSent;
    const allowAccept = isCustomer && waybill.status === CarrierSent;
    const showDeclineNotes = CustomerDecline === waybill.status
    const showCopyButton = waybill.carrier.inn === viewer_inn_or_pinfl

    const getItemMeasurementText = (id) => measurements.find(measurement => measurement.measureId === id) || null

    const getDeliveryTypeText = (id) => {
        const deliveryType = DeliveryTypeOptions.find(type => type.value === id);
        return deliveryType?.label;
    }

    const getTrailerTypeText = (id) => {
        const deliveryType = TrailerTypeOptions.find(type => type.value === id);
        return deliveryType?.label;
    }

    const signWaybill = () => {
        setLoading(true);

        sendWaybillAsync(currentCertificate, lang, {
            id: waybill.id,
            hashCode: content
        })
            .then(() => toast.success(t(lang, 'roaming.waybill.send.toast.register_success')))
            .catch(error => toast.error(error))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        dispatch(loadMeasurementsAsync(lang));
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('roaming-waybill-view'))
        return () => window.removeEventListener('scroll', () => scrollEvent('roaming-waybill-view'))
    }, []);

    return (
        <Fragment>
            <Row className={'align-items-center justify-content-between my-3 position-sticky z-index-1'} style={{top: '4rem'}} id={'roaming-waybill-view'}>
                <Col lg={"auto"} className={'d-flex gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'}
                            style={{width: 35, height: 35}}>
                        <IconArrowLeft/>
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.waybill.view.title")} № {waybill.info.number}</h5>
                        <div className={'d-flex align-items-center gap-2'}>
                            <span className={'second-text fs-9'}>
                                {waybill.created_at}
                            </span>
                            <Badge bg={getWaybillStatusVariant(waybill, viewer_inn_or_pinfl)} variant={'phoenix'}
                                   className={'px-2 py-1 fs-9 text-uppercase text-decoration-none fw-medium border-0 rounded-4'}>
                                {t(lang, getWaybillStatusText(waybill, viewer_inn_or_pinfl))}
                            </Badge>
                        </div>
                    </div>
                </Col>
                <Col lg={"auto"} className={'d-flex justify-content-end align-items-center gap-2'}>
                    {showCopyButton &&
                        <IconButton
                            variant="phoenix-secondary"
                            className="btn-davr px-3 py-0 bg-white d-flex gap-2"
                            iconClassName={'fs-8 text-primary-davr'}
                            size="sm"
                            icon={faClone}
                            as={Link}
                            to={waybill.status ? `/roaming/waybill/actions/${waybill.id}?status=${waybill?.status}` : `/roaming/waybill/actions/${waybill.id}?status=${null}`}
                        >
                            {t(lang, "roaming.common.copy")}
                        </IconButton>
                    }
                    {isDraftWaybill && (
                        <IconButton
                            to={`/roaming/waybill/edit/${waybill.id}`}
                            as={Link}
                            size="sm"
                            transform="shrink-3"
                            variant="phoenix-secondary"
                            className="btn-davr px-3 py-0 bg-white d-flex gap-2"
                            iconClassName={'fs-8 text-primary-davr'}
                            icon={faPencil}>
                            <span className="d-none d-sm-inline-block ms-1">{t(lang, 'roaming.common.edit')}</span>
                        </IconButton>
                    )}
                    {allowCancel && <CarrierCancelWaybill  id={waybill.id} />}
                    {allowDecline && <CustomerDeclineWaybill  id={waybill.id} />}
                    {isDraftWaybill && (
                        <SelectCertificateWrapperComponent
                            submitButton={
                                <IconButton
                                    onClick={signWaybill}
                                    id="sendButton"
                                    size="sm"
                                    variant={'phoenix-secondary'}
                                    className={'btn-davr bg-primary-davr gap-1 text-white'}
                                    transform="shrink-3"
                                >
                                    {loading ? <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/> : <IconSignature />}
                                    {t(lang, 'roaming.common.send')}
                                </IconButton>
                            }
                            openDialogButtonText={t(lang, 'roaming.common.send')}
                        />
                    )}
                    {allowAccept && <CustomerAcceptWaybill  id={waybill.id} />}
                </Col>
            </Row>

            <Card>
                <Card.Body className={'pb-0'}>
                    <h2 className="fw-semi-bold text-center">
                        {t(lang, "roaming.waybill.view.title")}
                    </h2>
                    <h5 className="fw-semi-bold text-center second-text">
                        <i>{waybill.id}</i>
                    </h5>
                </Card.Body>
                <Card.Footer as={Row} className={'border-0 justify-content-between fs-8 pb-3'}>
                    <Col xs={12} md={6} lg={4} className={'px-1'}>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "roaming.waybill.view.contract_number")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>{waybill?.info?.number}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>

                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "roaming.waybill.view.contract_date")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>{waybill.contract_info.date}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>

                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "roaming.waybill.view.delivery_number")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>{waybill.delivery_info.number}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>

                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "roaming.waybill.view.contract_number")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>{waybill.contract_info.number}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={4} className={'px-1'}>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "roaming.waybill.view.delivery_type")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>{t(lang, getDeliveryTypeText(waybill.delivery_type))}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "roaming.waybill.view.trailer_type")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>{t(lang, getTrailerTypeText(waybill.trailer_info.trailer_type))}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "roaming.waybill.view.driver_fio")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>{waybill.driver_fio}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "roaming.waybill.view.trailer_info.loader_info.model")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>{waybill.trailer_info.loader_info.model}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>

                        </div>
                    </Col>
                    <Col xs={12} md={12} lg={4} className={'px-1'}>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "roaming.waybill.view.truck_info.model")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>{waybill.truck_info.model}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "roaming.waybill.view.trailer_info.loader_info.number")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>{waybill.trailer_info.loader_info.number}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "roaming.waybill.view.truck_info.number")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>{waybill.truck_info.number}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Card.Footer>
            </Card>

            <Card className={'mt-2'}>
                <Card.Footer as={Row} className={'border-0 justify-content-between fs-8 pb-3 pt-1'}>
                    <Col xs={12} lg={6} className={'px-1'}>
                        <h4 className="fs-8 fw-semi-bold second-text">{t(lang, 'roaming.waybill.view.carrier')}</h4>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "edi.common.company")}:</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>{waybill.carrier.name}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>

                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "edi.common.label.inn")}:</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>{waybill.carrier.inn}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col xs={12} lg={6} className={'px-1'}>
                        <h4 className="fs-8 fw-semi-bold second-text">{t(lang, 'roaming.waybill.view.customer')}</h4>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "edi.common.company")}:</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>{waybill.customer.name}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>

                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "edi.common.label.inn")}:</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>{waybill.customer.inn}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col xs={12} lg={6} className={'px-1 mt-2'}>
                        <h4 className="fs-8 fw-semi-bold second-text">{t(lang, 'roaming.waybill.view.buyer')}</h4>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "edi.common.company")}:</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>{waybill.buyer.name}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>

                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "edi.common.label.inn")}:</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>{waybill.buyer.inn}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col xs={12} lg={6} className={'px-1 mt-2'}>
                        <h4 className="fs-8 fw-semi-bold second-text">{t(lang, 'roaming.waybill.view.seller')}</h4>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "edi.common.company")}:</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>{waybill.seller.name}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>

                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "edi.common.label.inn")}:</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>{waybill.seller.inn}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col xs={12} lg={6} className={'px-1 mt-2'}>
                        <h4 className="fs-8 fw-semi-bold second-text">{t(lang, 'roaming.waybill.view.unloading')}</h4>
                        <div className={'bg-card p-2 rounded-2'}>
                            {waybill.point_infos.map((info, index) => (
                                <Row key={index} className={'py-1'}>
                                    <Col md={4}>
                                        <span className={'second-text d-block text-truncate'}>{t(lang, 'roaming.waybill.view.point_of_unloading', {number: index + 1})}:</span>
                                    </Col>
                                    <Col md={8}>
                                        <span className={'main-text d-block h-100 text-truncate'}>{info.point_of_unloading}</span>
                                        <hr className={'m-0 line-color'}/>
                                    </Col>
                                </Row>
                            ))}
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "roaming.waybill.view.point_of_redirect_address")}:</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>{waybill.point_of_redirect_address}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col xs={12} lg={6} className={'px-1 mt-2'}>
                        <h4 className="fs-8 fw-semi-bold second-text">{t(lang, 'roaming.waybill.view.unloading')}</h4>
                        <div className={'bg-card p-2 rounded-2'}>
                            {waybill.point_infos.map((info, index) => (
                                <Row key={index} className={'py-1'}>
                                    <Col md={4}>
                                        <span className={'second-text d-block text-truncate'}>{t(lang, 'roaming.waybill.view.point_of_unloading', {number: index + 1})}:</span>
                                    </Col>
                                    <Col md={8}>
                                        <span className={'main-text d-block h-100 text-truncate'}>{info.point_of_loading}</span>
                                        <hr className={'m-0 line-color'}/>
                                    </Col>
                                </Row>
                            ))}
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>{t(lang, "roaming.waybill.view.point_of_redirect_name")}:</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>{waybill.point_of_redirect_name}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                </Card.Footer>
            </Card>

            <Card className={'mt-2 p-2'}>
                <Card.Body className={'p-0 border border-1 rounded-2'}>
                    <WaybillViewTable waybill={waybill} getItemMeasurementText={getItemMeasurementText}/>
                    <div className={'p-3 py-0'}>
                        <Row className={''}>
                            <Col xs={12} md={6}>
                                <div className="row">
                                    <span className="col mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.view.giver')}</span>
                                    <span className="col mb-1 ps-0">{waybill.giver_fio}
                                        <hr className={waybill.giver_fio ? "m-0" : "mb-0 text-dark"}
                                            style={{height: "2px"}}/></span>
                                </div>
                                <div className="row">
                                    <span
                                        className="col mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.view.taker')}</span>
                                    <span className="col mb-1 ps-0">{waybill.taker_fio}
                                        <hr className={waybill.taker_fio ? "m-0" : "mb-0 text-dark"}
                                            style={{height: "2px"}}/></span>
                                </div>
                                <div className="row">
                                    <span
                                        className="col mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.view.special_notes')}</span>
                                    <span className="col mb-1 ps-0">{waybill.special_notes}
                                        <hr className={waybill.special_notes ? "m-0" : "mb-0 text-dark"}
                                            style={{height: "2px"}}/></span>
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className="row">
                                    <span
                                        className="col fw-bold">{t(lang, 'roaming.waybill.view.delivery_distance_in_city')}</span>
                                    <span className="col">{waybill?.distance_info?.delivery_distance_in_city}
                                        <hr className={waybill?.distance_info?.delivery_distance_in_city ? "m-0" : "my-1 text-dark"}
                                            style={{height: "2px"}}/></span>
                                </div>
                                <div className="row">
                                    <span
                                        className="col fw-bold">{t(lang, 'roaming.waybill.view.delivery_distance')}</span>
                                    <span className="col">{waybill?.distance_info?.delivery_distance}
                                        <hr className={waybill?.distance_info?.delivery_distance ? "m-0" : "my-0 text-dark"}
                                            style={{height: "2px"}}/></span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
            </Card>

        </Fragment>
    );
};

export default Waybill;
