import {createSlice} from "@reduxjs/toolkit";
import roamingReferenceService from "../../services/roaming/roamingReferenceService";


const initialState = {
    company: null,
    currentTurnOver: null,
    executorPrivileges: [],
    customerPrivileges: [],
    turnoverPrivileges: [],
    warehouses: [],
    measurements: [],
    countries: [],
    regions: [],
    districts: [],
    stations: [],
    exchangeTypes: []
}
export const roamingReferenceSlice = createSlice({
    name: "roamingReference",
    initialState,
    reducers: {
        setCompany: (state, action) => {
            state.company = action.payload;
        },
        setCurrentTurnOver: (state, action) => {
            state.currentTurnOver = action.payload;
        },
        setExecutorPrivileges: (state, action) => {
            state.executorPrivileges = action.payload;
        },
        setCustomerPrivileges: (state, action) => {
            state.customerPrivileges = action.payload;
        },
        setTurnoverPrivileges: (state, action) => {
            state.turnoverPrivileges = action.payload;
        },
        setWarehouses: (state, action) => {
            state.warehouses = action.payload;
        },
        setMeasurements: (state, action) => {
            state.measurements = action.payload;
        },
        setCountries: (state, action) => {
            state.countries = action.payload;
        },
        setRegions: (state, action) => {
            state.regions = action.payload;
        },
        setDistricts: (state, action) => {
            state.districts = action.payload;
        },
        setStations: (state, action) => {
            state.stations = action.payload;
        },
        setExchangeTypes: (state, action) => {
            state.exchangeTypes = action.payload;
        }
    }
})


export const loadCompany = (innOrPinfl, params) => (dispatch) => {
    if (!innOrPinfl) {
        roamingReferenceSlice.actions.setCompany(null);
        return;
    }

    roamingReferenceService.getCompany(innOrPinfl, params)
        .then((response) => {
            dispatch(roamingReferenceSlice.actions.setCompany(response.data));
        })
        .catch((error) => {
            console.log(error);
            dispatch(roamingReferenceSlice.actions.setCompany(null));
        })
};
export const loadCompanyCurrentTurnOver = (year) => (dispatch) => {
    roamingReferenceService.getMyCurrentTurnOver(year)
        .then((response) => {
            dispatch(roamingReferenceSlice.actions.setCurrentTurnOver(response.data));
        })
        .catch((error) => {
            console.log(error);
            dispatch(roamingReferenceSlice.actions.setCurrentTurnOver(null));
        })
};

export const getCompany = (innOrPinfl, params) => {
    return new Promise((resolve, reject) => {
        roamingReferenceService.getCompany(innOrPinfl, params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            })
    })
};
export const getCompanies = (params) => {
    return new Promise((resolve, reject) => {
        roamingReferenceService.getCompanies(params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error)
            })
    })
}
export const loadExecutorPrivileges = (params) => (dispatch) => {
    roamingReferenceService.getExecutorCompanyPrivileges(params)
        .then((response) => {
            dispatch(roamingReferenceSlice.actions.setExecutorPrivileges(response.data))
        })
        .catch((error) => {
            console.log(error);
            dispatch(roamingReferenceSlice.actions.setExecutorPrivileges([]))
        })
};
export const loadCustomerPrivileges = (params) => (dispatch) => {
    roamingReferenceService.getCustomerCompanyPrivileges(params)
        .then((response) => {
            dispatch(roamingReferenceSlice.actions.setCustomerPrivileges(response.data))
        })
        .catch((error) => {
            console.log(error);
            dispatch(roamingReferenceSlice.actions.setCustomerPrivileges([]))
        })
};
export const loadTurnoverPrivileges = (params) => (dispatch) => {
    roamingReferenceService.getTurnoverPrivileges(params)
        .then((response) => {
            dispatch(roamingReferenceSlice.actions.setTurnoverPrivileges(response.data));
        })
        .catch((error) => {
            console.log(error);
            dispatch(roamingReferenceSlice.actions.setTurnoverPrivileges([]));
        })
};
export const loadWarehouses = (params) => (dispatch) => {
    roamingReferenceService.getWarehouses(params)
        .then((response) => {
            dispatch(roamingReferenceSlice.actions.setWarehouses(response.data));
        })
        .catch((error) => {
            console.log(error);
            dispatch(roamingReferenceSlice.actions.setWarehouses([]))
        })
};
export const loadMeasurements = () => (dispatch) => {
    roamingReferenceService.getMeasurements()
        .then((response) => {
            dispatch(roamingReferenceSlice.actions.setMeasurements(response.data));
        })
        .catch((error) => {
            console.log(error);
            dispatch(roamingReferenceSlice.actions.setMeasurements([]));
        })
};
export const loadCountries = () => (dispatch) => {
    roamingReferenceService.getCountries()
        .then((response) => {
            dispatch(roamingReferenceSlice.actions.setCountries(response.data));
        })
        .catch((error) => {
            console.log(error);
            dispatch(roamingReferenceSlice.actions.setCountries([]));
        })
};
export const loadRegions = () => (dispatch) => {
    roamingReferenceService.getRegions()
        .then((response) => {
            dispatch(roamingReferenceSlice.actions.setRegions(response.data));
        })
        .catch((error) => {
            console.log(error);
            dispatch(roamingReferenceSlice.actions.setRegions([]));
        })
};
export const loadDistricts = () => (dispatch) => {
    roamingReferenceService.getDistricts()
        .then((response) => {
            dispatch(roamingReferenceSlice.actions.setDistricts(response.data));
        })
        .catch((error) => {
            console.log(error);
            dispatch(roamingReferenceSlice.actions.setDistricts([]));
        })
};
export const loadStations = () => (dispatch) => {
    roamingReferenceService.getStations()
        .then((response) => {
            dispatch(roamingReferenceSlice.actions.setStations(response.data));
        })
        .catch((error) => {
            console.log(error);
            dispatch(roamingReferenceSlice.actions.setStations([]));
        })
};
export const loadCatalogsByCode = (payload) => {
    return new Promise((resolve, reject) => {
        roamingReferenceService.getCatalogsByCode(payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
};
export const loadCatalogByCode = (code) => {
    return new Promise((resolve, reject) => {
        roamingReferenceService.getCatalogByCode(code)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
};
export const loadCatalogsBySubPositionName = (params) => {
    return new Promise((resolve, reject) => {
        roamingReferenceService.getCatalogsBySubPositionName(params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
}
export const loadInvestmentObjectInfo = (params) => {
    return new Promise((resolve, reject) => {
        roamingReferenceService.getInvestmentObject(params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
};
export const loadExchangeInfo = (params) => {
    return new Promise((resolve, reject) => {
        roamingReferenceService.getExchangeInfo(params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
};
export const loadExchangeTypeWithExchangeNumber = (params) => {
    return new Promise((resolve, reject) => {
        roamingReferenceService.getExchangeTypeWithExchangeNumber(params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
};
export const loadExchangeTypes = (params) => (dispatch) => {
    roamingReferenceService.getExchangeTypes(params)
        .then((response) => {
            dispatch(roamingReferenceSlice.actions.setExchangeTypes(response.data));
        })
        .catch((error) => {
            dispatch(roamingReferenceSlice.actions.setExchangeTypes([]));
            console.log(error);
        })
}
export const loadMySoliqUzContract = (id) => {
    return new Promise((resolve, reject) => {
        roamingReferenceService.getMySoliqUzConract(id)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
};

export const selectCompany = state => state.roamingReference.company;
export const selectCurrentTurnOver = state => state.roamingReference.currentTurnOver;
export const selectExecutorPrivileges = state => state.roamingReference.executorPrivileges;
export const selectCustomerPrivileges = state => state.roamingReference.customerPrivileges;
export const selectTurnoverPrivileges = state => state.roamingReference.turnoverPrivileges;
export const selectWarehouses = state => state.roamingReference.warehouses;
export const selectMeasurements = state => state.roamingReference.measurements;
export const selectCountries = state => state.roamingReference.countries;
export const selectRegions = state => state.roamingReference.regions;
export const selectDistricts = state => state.roamingReference.districts;
export const selectStations = state => state.roamingReference.stations;
export const selectExchangeTypes = state => state.roamingReference.exchangeTypes;

export default roamingReferenceSlice.reducer
