import axios from "axios";

class RoamingReferenceService {
    constructor() {
        this.baseURL = "/roaming-reference";
    }

    getCompany(innOrPinfl, params) {
        return axios.get(`${this.baseURL}/company/get/${innOrPinfl}`, {params})
    };

    getMyCurrentTurnOver(year) {
        return axios.get(`${this.baseURL}/get-current-turnover/${year}`)
    };

    getCompanies(params) {
        return axios.get(`${this.baseURL}/company/search`, {params})
    };

    getExecutorCompanyPrivileges(params) {
        return axios.get(`${this.baseURL}/get-seller-company-lgotas`, {params})
    };

    getCustomerCompanyPrivileges(params) {
        return axios.get(`${this.baseURL}/get-buyer-company-lgotas`, {params})
    };

    getTurnoverPrivileges(params) {
        return axios.get(`${this.baseURL}/get-turnover-company-lgotas`, {params})
    };

    getWarehouses(params) {
        return axios.get(`${this.baseURL}/get-warehouses`, {params});
    };

    getMeasurements() {
        return axios.get(`${this.baseURL}/get-measurements`);
    };

    getCountries() {
        return axios.get(`${this.baseURL}/get-countries`);
    };

    getRegions() {
        return axios.get(`${this.baseURL}/get-regions`);
    };

    getDistricts() {
        return axios.get(`${this.baseURL}/get-districts`);
    };

    getStations() {
        return axios.get(`${this.baseURL}/get-stations`);
    };

    getInvestmentObject(params) {
        return axios.get(`${this.baseURL}/get-investment-object`, {params});
    }

    getExchangeInfo(params) {
        return axios.get(`${this.baseURL}/get-exchange`, {params});
    }

    getExchangeTypeWithExchangeNumber(params) {
        return axios.get(`${this.baseURL}/get-exchange-type-with-lot-number`, {params});
    }

    getExchangeTypes(params) {
        return axios.get(`${this.baseURL}/get-exchange-types`, {params});
    }

    getCatalogsByCode(payload) {
        return axios.post(`${this.baseURL}/get-catalogs-by-code`, payload)
    }

    getCatalogByCode(code) {
        return axios.get(`${this.baseURL}/get-catalog-by-code/${code}`)
    }

    getCatalogsBySubPositionName(params) {
        return axios.get(`${this.baseURL}/get-catalogs-by-sub-position-name`, {params})
    }

    getMySoliqUzConract(id) {
        return axios.get(`${this.baseURL}/get-my-soliq-uz-contract/${id}`)
    }
}

const roamingReferenceService = new RoamingReferenceService();
export default roamingReferenceService;
