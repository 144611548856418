import React, {Fragment} from 'react';
import {ForImportService} from "../../../enum/edi/return-invoice/RoamingInvoiceWrapper";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {selectLang} from "../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {Col, Row, Form, FloatingLabel} from "react-bootstrap";
import RoamingInvoiceFormCountrySelector from "./RoamingInvoiceFormCountrySelector";

const RoamingInvoiceFormForeignCompanyInfo = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();
    const singleSidedType = useWatch({name: `singleSidedType`, exact: true});

    if (singleSidedType !== ForImportService)
        return null

    return (
        <Fragment>

            <Row>
                <Form.Group as={Col} xs={12} className="mb-2">
                    <Controller name={`foreignCompany.countryId`}
                                render={({field}) => (
                                    <RoamingInvoiceFormCountrySelector
                                        defaultCountryId={field.value}
                                        onChange={field.onChange}
                                    />
                                )}
                    />
                </Form.Group>

                <Form.Group as={Col} xs={12} className="mb-2">
                    <FloatingLabel label={t(lang, 'roaming.invoice.foreign_organization')}>
                        <Form.Control name={`foreignCompany.name`}
                                      placeholder={t(lang, 'roaming.invoice.foreign_organization.placeholder')}
                                      {...form.register(`foreignCompany.name`)}
                        />
                    </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} xs={12} className="mb-2">
                    <FloatingLabel label={t(lang, 'roaming.common.address')}>
                        <Form.Control name={`foreignCompany.address`}
                                      placeholder={t(lang, 'roaming.common.address.placeholder')}
                                      {...form.register(`foreignCompany.address`)}
                        />
                    </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} xs={12} className="mb-2">
                    <FloatingLabel label={t(lang, 'roaming.common.bank_name')}>
                        <Form.Control name={`foreignCompany.bank`}
                                      placeholder={t(lang, 'roaming.common.bank_name.placeholder')}
                                      {...form.register(`foreignCompany.bank`)}
                        />
                    </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} xs={12} className="mb-2">
                    <FloatingLabel label={t(lang, 'roaming.common.bank_account')}>
                        <Form.Control name={`foreignCompany.account`}
                                      placeholder={t(lang, 'roaming.common.bank_account.placeholder')}
                                      {...form.register(`foreignCompany.account`)}
                        />
                    </FloatingLabel>
                </Form.Group>
            </Row>
        </Fragment>
    );
};

export default RoamingInvoiceFormForeignCompanyInfo;
