import React, {Fragment} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {Col, Row, Form, Card, FloatingLabel} from "react-bootstrap";
import FindCompanySearchable from "../../../organization/FindCompanySearchable";
import classNames from "classnames";
import FindPerson from "../../../edi/attorney/FindPerson";

const PartnerInfo = () => {
    const { register, setValue, formState: { errors }, control } = useFormContext()
    const lang = useSelector(selectLang)
    const t = Translate
    const onChangeHandler = company => {
        setValue(`partnerName`, company?.name, {shouldValidate: !!company})
        setValue(`partnerFizTin`, (company?.director_pinfl || company?.director_inn) || company?.person_num, {shouldValidate: !!company})
        setValue(`partnerFizFio`, company?.director_pinfl ? company?.director : company?.name, {shouldValidate: !!company})
    }

    const onCompanyDirectorChange = (director) => {
        if (director) {
            setValue(`partnerFizTin`, director.pinfl || director.inn)
            setValue(`partnerFizFio`, director.name)
        } else {
            setValue(`partnerFizTin`, '')
            setValue(`partnerFizFio`, '')
        }
    }

    return (
        <Fragment>
            <h4 className={'fs-8 fw-medium second-text ms-3'}>{t(lang, "roaming.verification_act.add.verification_act.partner_info.title")}</h4>

            <Card className="p-2">
                <Row>
                    <Col sm="6" className="mb-2">
                        <Controller
                            name={`partnerTin`}
                            control={control}
                            rules={{
                                required: "edi.common.forms.validations.is_required"
                            }}
                            render={({field}) => (
                                <FindCompanySearchable
                                    placeholder={t(lang, "roaming.verification_act.add.verification_act.partner_info.inn_or_pinfl")}
                                    id={'partnerTin'}
                                    onChange={(company) => {
                                        field.onChange(company?.person_num || company?.inn)
                                        onChangeHandler(company)
                                    }}
                                    defaultInn={field.value}
                                    inputProps={{
                                        isInvalid: errors?.partnerTin,
                                    }}
                                    inputGroupProps={{
                                        className: classNames('select',{
                                            'is-invalid': errors?.partnerTin,
                                        })
                                    }}
                                />
                            )}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.partnerTin?.message)}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-2">
                        <FloatingLabel label={t(lang, "roaming.verification_act.add.verification_act.partner_info.name")}>
                            <Form.Control
                                {...register(`partnerName`, {
                                    required: "edi.common.forms.validations.is_required"
                                })}
                                className={errors?.partnerName && 'is-invalid'}
                                placeholder={t(lang, "roaming.verification_act.add.verification_act.partner_info.name")}
                            />
                        </FloatingLabel>

                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.partnerName?.message)}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-2">
                        <Controller
                            name={'partnerFizTin'}
                            control={control}
                            rules={{
                                required: "edi.common.forms.validations.is_required"
                            }}
                            render={({field}) => (
                                <FindPerson
                                    placeholder={t(lang, "roaming.verification_act.add.verification_act.partner_info.person_inn_or_pinfl")}
                                    id={'partnerFizTin'}
                                    onChange={onCompanyDirectorChange}
                                    defaultInnOrPinfl={field.value}
                                    inputProps={{
                                        className: errors?.partnerFizTin && 'is-invalid',
                                    }}
                                    inputGroupProps={{
                                        className: errors?.partnerFizTin && 'is-invalid'
                                    }}
                                />
                            )}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.partnerFizTin?.message)}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-2">
                        <FloatingLabel label={t(lang, "roaming.verification_act.add.verification_act.partner_info.person_name")}>
                            <Form.Control
                                {...register(`partnerFizFio`, {
                                    required: "edi.common.forms.validations.is_required"
                                })}
                                className={errors?.partnerFizFio && 'is-invalid'}
                                placeholder={t(lang, "roaming.verification_act.add.verification_act.partner_info.person_name")}
                            />
                        </FloatingLabel>
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.partnerFizFio?.message)}
                        </Form.Control.Feedback>
                    </Col>
                </Row>
            </Card>
        </Fragment>
    );
};

export default PartnerInfo;
