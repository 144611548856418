import React from 'react';
import {useSelector} from "react-redux";
import ReactSelect from "react-select";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import {markOptions} from "../../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import CustomFloatingLabelSelect from "../../floating-custom-select/CustomFloatingLabelSelect";

const MarkProductTypeSelector = ({defaultProductType, onChange, selectProps}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    return (
        <CustomFloatingLabelSelect
            options={markOptions}
            isSearchable={!defaultProductType}
            isClearable={true}
            getOptionLabel={option => t(lang, option.label)}
            getOptionValue={option => option.value}
            label={t(lang, "roaming.common.items.mark_type")}
            onChange={(option) => onChange(option ? option.value : null)}
            value={markOptions.find(m => m.value === defaultProductType) || null}
            {...selectProps}
        />
    );
};

export default MarkProductTypeSelector;
