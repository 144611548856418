import React from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import ForgotPasswordForm from "../../../components/form/auth/forget-password/ForgotPasswordForm";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import {useNavigate} from "react-router-dom";
import {Card} from "react-bootstrap";

const ForgetPassword = () => {

    const lang = useSelector(selectLang);
    const t = Translate;
    const navigate = useNavigate()

    return (
        <Card className="w-100 h-100 overflow-hidden rounded-5 p-3">
            <ForgotPasswordForm
                header={
                    <div className={'position-relative'}>
                        <button onClick={() => navigate(-1)}
                                className={'main-text btn-back bg-secondary-davr position-absolute'}
                                style={{width: 35, height: 35, left: 0, bottom: "-10px"}}>
                            <IconArrowLeft/>
                        </button>
                        <h5 className={'text-center mb-3 text-dark'}>{t(lang, "authentication.forgot-password.title")}</h5>
                    </div>
                }
            />
        </Card>
    );
};

export default ForgetPassword;
