import React, {useEffect, useState} from 'react';
import CustomFloatingLabelSelect from "../../floating-custom-select/CustomFloatingLabelSelect";
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";

const SelectVillage = ({options, onChange, defaultVillageCode, isDisabled, placeholder, ...props}) => {

    const lang = useSelector(selectLang);
    const t = Translate;
    const [value, setValue] = useState(null)

    useEffect(() => {
        setValue(options.find(i => i.code === defaultVillageCode) || null)
    }, [options, defaultVillageCode]);

    const handleChange = (option) => {
        onChange(option)
        setValue(option)
    }

    return (
        <CustomFloatingLabelSelect
            label={placeholder}
            value={value}
            options={options}
            onChange={(option) => handleChange(option)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.code}
            hideSelectedOptions
            {...props}
        />
    );
};

export default SelectVillage;
