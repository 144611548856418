import React, {useEffect, useState} from 'react';
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {selectLang, updateShowInProgressAsync} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {selectAccountRolePermissions} from "../../../store/reducers/role/roleReducer";
import checkPermission from "../../../enum/permission/CheckPermission";
import {PermissionRoamingVerificationActUpdate} from "../../../enum/permission/RoamingPermission";
import {loadDraftVerificationAct} from "../../../store/reducers/roaming/vertification-act/vertificationActReducer";
import {
    DOCUMENT_SIGN_FAILED,
    DOCUMENT_SIGN_STARTED,
    DOCUMENT_SIGN_SUCCESS
} from "../../../eventbus/romaing/signEvents";
import EventBus from "../../../eventbus/EventBus";
import toast from "react-hot-toast";
import {getOrganizationBillingInfoAsync} from "../../../store/reducers/billing/billingReducer";
import VerificationActForm from "../../../components/roaming/verification-act/edit/VerificationActForm";
import dayjs from "dayjs";

const EditVerificationAct = () => {
    const [draftVerificationAct, setDraftVerificationAct] = useState({})
    const activeOrganization = useSelector(selectActiveOrganization)
    const navigate = useNavigate()
    const { id } = useParams();
    const dispatch = useDispatch()
    const lang = useSelector(selectLang)
    const t = Translate

    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingVerificationActUpdate))) {
            navigate('/roaming/verification-acts/draft')
        }
    }, [permission])

    const load = () => {
        loadDraftVerificationAct(id)
            .then(response => {
                let contentVerificationAct = JSON.parse(response.data.content)
                let verificationAct = {
                    verificationActId: contentVerificationAct.VerificationActId,
                    verificationActDoc: {
                        verificationActNo: contentVerificationAct.VerificationActDoc.VerificationActNo,
                        verificationActDate: dayjs(contentVerificationAct.VerificationActDoc.VerificationActDate).toDate(),
                        verificationActText: contentVerificationAct.VerificationActDoc.VerificationActText
                    },
                    ownerTin: contentVerificationAct.OwnerTin,
                    ownerName: contentVerificationAct.OwnerName,
                    ownerFizTin: contentVerificationAct.OwnerFizTin,
                    ownerFizFio: contentVerificationAct.OwnerFizFio,
                    partnerTin: contentVerificationAct.PartnerTin,
                    partnerName: contentVerificationAct.PartnerName,
                    partnerFizTin: contentVerificationAct.PartnerFizTin,
                    partnerFizFio: contentVerificationAct.PartnerFizFio,
                    verificationActContracts: contentVerificationAct.VerificationActContracts.map(verificationActContract => ({
                        contractNo: verificationActContract.ContractNo,
                        contractDate: dayjs(verificationActContract.ContractDate).toDate(),
                        verificationActContractItems: verificationActContract.VerificationActContractItems.map(verificationActContractItem => ({
                            ownerOperationDate: dayjs(verificationActContractItem.OwnerOperationDate).toDate(),
                            ownerOperationName: verificationActContractItem.OwnerOperationName,
                            ownerDebit: +verificationActContractItem.OwnerDebit,
                            ownerCredit: +verificationActContractItem.OwnerCredit,
                            partnerOperationDate: dayjs(verificationActContractItem.PartnerOperationDate).toDate(),
                            partnerOperationName: verificationActContractItem.PartnerOperationName,
                            partnerDebit: +verificationActContractItem.PartnerDebit,
                            partnerCredit: +verificationActContractItem.PartnerCredit
                        })),
                        openBalance: {
                            ownerDebit: +verificationActContract?.OpenBalance?.OwnerDebit || 0,
                            ownerCredit: +verificationActContract?.OpenBalance?.OwnerCredit || 0,
                            partnerDebit: +verificationActContract?.OpenBalance?.PartnerDebit || 0,
                            partnerCredit: +verificationActContract?.OpenBalance?.PartnerCredit || 0
                        },
                        closeBalance: {
                            ownerDebit: +verificationActContract?.CloseBalance?.OwnerDebit || 0,
                            ownerCredit: +verificationActContract?.CloseBalance?.OwnerCredit || 0,
                            partnerDebit: +verificationActContract?.CloseBalance?.PartnerDebit || 0,
                            partnerCredit: +verificationActContract?.CloseBalance?.PartnerCredit || 0
                        },
                        totalBalance: {
                            ownerDebit: +verificationActContract?.TotalBalance?.OwnerDebit || 0,
                            ownerCredit: +verificationActContract?.TotalBalance?.OwnerCredit || 0,
                            partnerDebit: +verificationActContract?.TotalBalance?.PartnerDebit || 0,
                            partnerCredit: +verificationActContract?.TotalBalance?.PartnerCredit || 0
                        }

                    })),
                    openBalance: {
                        ownerDebit: +contentVerificationAct?.OpenBalance?.OwnerDebit || 0,
                        ownerCredit: +contentVerificationAct?.OpenBalance?.OwnerCredit || 0,
                        partnerDebit: +contentVerificationAct?.OpenBalance?.PartnerDebit || 0,
                        partnerCredit: +contentVerificationAct?.OpenBalance?.PartnerCredit || 0,
                    },
                    turnoverBalance: {
                        ownerDebit: +contentVerificationAct?.TurnoverBalance?.OwnerDebit || 0,
                        ownerCredit: +contentVerificationAct?.TurnoverBalance?.OwnerCredit || 0,
                        partnerDebit: +contentVerificationAct?.TurnoverBalance?.PartnerDebit || 0,
                        partnerCredit: +contentVerificationAct?.TurnoverBalance?.PartnerCredit || 0,
                    },
                    closeBalance: {
                        ownerDebit: +contentVerificationAct?.CloseBalance?.OwnerDebit || 0,
                        ownerCredit: +contentVerificationAct?.CloseBalance?.OwnerCredit || 0,
                        partnerDebit: +contentVerificationAct?.CloseBalance?.PartnerDebit || 0,
                        partnerCredit: +contentVerificationAct?.CloseBalance?.PartnerCredit || 0,
                    }
                }
                setDraftVerificationAct(verificationAct)
            })
            .catch(error => {
                console.log(error);
                navigate('/roaming/verification-acts/inbox')
            })
    }

    useEffect(() => {
        load()
    }, [id, activeOrganization.inn])

    useEffect(() => {
        const signStartedHandler = EventBus.on(DOCUMENT_SIGN_STARTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })
        const signSuccessHandler = EventBus.on(DOCUMENT_SIGN_SUCCESS, () => {
            toast.success(t(lang, "edi.document.sign.notification.success"))
            dispatch(getOrganizationBillingInfoAsync());
            dispatch(updateShowInProgressAsync(false))
        });
        const signFailedHandler = EventBus.on(DOCUMENT_SIGN_FAILED, (data) => {
            toast.error(t(lang, data.message))
            dispatch(updateShowInProgressAsync(false))
        });

        return () => {
            EventBus.remove(DOCUMENT_SIGN_STARTED, signStartedHandler)
            EventBus.remove(DOCUMENT_SIGN_SUCCESS, signSuccessHandler)
            EventBus.remove(DOCUMENT_SIGN_FAILED, signFailedHandler)
        }
    }, [])

    return <VerificationActForm verificationAct={draftVerificationAct}/>

};

export default EditVerificationAct;
