import React from 'react';

const IconBasket = ({width = 36, height = 36, color = "none"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 36 36" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23 23C21.8954 23 21 23.8954 21 25C21 26.1046 21.8954 27 23 27C24.1046 27 25 26.1046 25 25C25 23.8954 24.1046 23 23 23ZM23 23H15.2939C14.8329 23 14.6019 23 14.4121 22.918C14.2447 22.8456 14.0994 22.7291 13.9935 22.5805C13.8749 22.414 13.8272 22.1913 13.7327 21.7505L11.2715 10.2646C11.1748 9.81363 11.1259 9.58838 11.0059 9.41992C10.9 9.27135 10.7548 9.15441 10.5873 9.08205C10.3975 9 10.1678 9 9.70653 9H9M12 12H24.8732C25.595 12 25.9555 12 26.1978 12.1504C26.41 12.2821 26.5653 12.4886 26.633 12.729C26.7104 13.0034 26.611 13.35 26.411 14.0435L25.0264 18.8435C24.9068 19.2581 24.8469 19.465 24.7256 19.6189C24.6185 19.7547 24.4772 19.861 24.317 19.9263C24.1361 20 23.9211 20 23.4921 20H13.7305M14 27C12.8954 27 12 26.1046 12 25C12 23.8954 12.8954 23 14 23C15.1046 23 16 23.8954 16 25C16 26.1046 15.1046 27 14 27Z"
                stroke="#58B3D3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default IconBasket;
