import {createSlice} from '@reduxjs/toolkit'

import {
    CANCEL_INVOICE_FAILED,
    CANCEL_INVOICE_REQUESTED,
    CANCEL_INVOICE_SUCCESS,
    DECLINE_INVOICE_FAILED,
    DECLINE_INVOICE_REQUESTED,
    DECLINE_INVOICE_SUCCESS,
    DELETE_INVOICE_FAILED,
    DELETE_INVOICE_REQUESTED,
    DELETE_INVOICE_SUCCESS,
    REGISTER_INVOICE_FAILED,
    REGISTER_INVOICE_REQUESTED,
    REGISTER_INVOICE_SUCCESS,
    SIGN_INVOICE_FAILED,
    SIGN_INVOICE_REQUESTED,
    SIGN_INVOICE_SUCCESS
} from "../../../../eventbus/edi/invoice/invoiceEvents";
import invoiceService from "../../../../store/services/edi/invoice/invoiceService";
import dayjs from 'dayjs';
import {roamingAttachAsync, roamingCancelAsync, roamingDeclineAsync, roamingSignAsync} from "../../eimzo/eimzoReducer";
import EventBus from "../../../../eventbus/EventBus";


const STORAGE_PRODUCT_CATALOGS = "productCatalogs";
const STORAGE_BANK_INFO = "bankInfo";

export const STORAGE_INVOICES = "InvoicesDataTableSettings"

const initialState = {
        filterOptions: {page: 1, limit: 10},
        invoices: [],
        count: 0,
        directors: [],
        accountants: [],
        releasers: [],
        invoicesDataTableSettings: {}
}

export const invoiceSlice = createSlice({
    name: "invoice",
    initialState,
    reducers: {
        updateFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
        updateInvoices: (state, action) => {
            state.invoices = action.payload
        },
        updateCount: (state, action) => {
            state.count = action.payload
        },

        createSigner: (state, action) => {
            const signer = action.payload;
            const signers = state[signer.arrayFieldName];
            signer.lastSelected = true;
            signers.map((s) => {
                s.lastSelected = false;
            });
            state[signer.arrayFieldName] = [...signers, signer];
            invoiceSlice.caseReducers.saveSigners(state);
        },
        changeActiveSigner: (state, action) => {
            const signer = action.payload;
            const signers = state[signer.arrayFieldName];
            signers.map((s) => {
                s.lastSelected = s.label === signer.label;
            })
            state[signer.arrayFieldName] = [...signers];
            invoiceSlice.caseReducers.saveSigners(state);
        },
        clearActiveSigner: (state, action) => {
            const signer = action.payload;
            if (signer) {
                const signers = state[signer.arrayFieldName];
                signers.map((s) => {
                    if (s.lastSelected)
                        s.lastSelected = false;
                })
                state[signer.arrayFieldName] = [...signers];
                invoiceSlice.caseReducers.saveSigners(state);
            }
        },
        deleteSigner: (state, action) => {
            const signer = action.payload;
            const signers = state[signer.arrayFieldName];
            const index = signers.findIndex(s => {
                return s.label === signer.label;
            })
            state[signer.arrayFieldName] = [...signers.slice(0, index), ...signers.slice(index + 1)];
            invoiceSlice.caseReducers.saveSigners(state);
        },
        saveSigners: (state) => {
            const json = {
                directors: [...state.directors],
                accountants: [...state.accountants],
                releasers: [...state.releasers],
            }
            localStorage.setItem('invoiceSigners', JSON.stringify(json));
        },
        loadSigners: (state) => {
            const invoiceSigners = JSON.parse(localStorage.getItem('invoiceSigners'));
            if (invoiceSigners) {
                state.directors = invoiceSigners.directors;
                state.accountants = invoiceSigners.accountants;
                state.releasers = invoiceSigners.releasers;
            }
        },

        loadInvoicesDataTableColumn: (state) => {
            let defaultColumn = {
                index: true,
                status: true,
                date: true,
                total_sum: true,
                branch: true,
                contractor: true,
                contract_num: true,
            };
            let storageColumn = JSON.parse(localStorage.getItem(STORAGE_INVOICES))
            if(!storageColumn) {
                localStorage.setItem(STORAGE_INVOICES, JSON.stringify(defaultColumn))
            }
            state.invoicesDataTableSettings = {...defaultColumn, ...storageColumn}
        },

        changeInvoicesDataTableColumn: (state, action) => {
            state.invoicesDataTableSettings = action.payload
            localStorage.setItem(STORAGE_INVOICES, JSON.stringify(state.invoicesDataTableSettings))
        },
    }
})

export const changeFilterOptionsAsync = ({number, contract_number, contract_date, date_start, date_end, updated_on_start, updated_on_end, ...params}) => dispatch => {
    dispatch(invoiceSlice.actions.updateFilterOptions({
        number: number || null,
        contract_number: contract_number || null,
        date_start: date_start && dayjs(date_start).format("YYYY-MM-DD"),
        date_end: date_end && dayjs(date_end).format("YYYY-MM-DD"),
        updated_on_start: updated_on_start && dayjs(updated_on_start).format("YYYY-MM-DD 00:00:00"),
        updated_on_end: updated_on_end && dayjs(updated_on_end).format("YYYY-MM-DD 23:59:59"),
        contract_date: contract_date && dayjs(contract_date).format("YYYY-MM-DD"),
        ...params
    }))
}
export const loadInvoicesAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    invoiceService.getInvoices(params)
        .then(response => {
            dispatch(invoiceSlice.actions.updateInvoices(response.data))
        })
        .catch(error => console.log(error))
}
export const loadCountAsync = (params) => (dispatch) => {
    let {limit, page, ...other} = params
    invoiceService.getCount(other)
        .then(response => {
            dispatch(invoiceSlice.actions.updateCount(response.data))
        })
        .catch(error => console.log(error))
}
export const loadInvoiceAsync = id => {
    return new Promise((resolve, reject) => {
        invoiceService.getInvoice(id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}
export const invoiceRegisterAsync = (payload) => {
    EventBus.dispatch(REGISTER_INVOICE_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        invoiceService.invoiceRegister(payload)
            .then((response) => {
                EventBus.dispatch(REGISTER_INVOICE_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(REGISTER_INVOICE_FAILED, error)
                reject(error.response.data);
            });
    });
};
export const invoiceDeleteAsync = ({id, comment}) => {
    EventBus.dispatch(DELETE_INVOICE_REQUESTED, {id, comment});

    return new Promise((resolve, reject) => {
        invoiceService.invoiceDelete({id, comment})
            .then((response) => {
                EventBus.dispatch(DELETE_INVOICE_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(DELETE_INVOICE_FAILED, error.message);
                reject(error)
            })
    });
};
export const printInvoiceAsync = (id) => {
    return new Promise((resolve, reject) => {
        invoiceService.printInvoice(id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
};
export const saveProductCatalogs = (items) => {
    let storageProductCatalogs = {};

    const storageValue = localStorage.getItem(STORAGE_PRODUCT_CATALOGS);
    if (storageValue)
        storageProductCatalogs = JSON.parse(storageValue);

    items.forEach((item) => {
        storageProductCatalogs[item.product] = {
            catalogClassCode: item.catalogClassCode,
            packageCode: item.packageCode,
            benefitId: item.benefitId,
            origin: item.origin
        }
    });
    localStorage.setItem(STORAGE_PRODUCT_CATALOGS, JSON.stringify(storageProductCatalogs));
};
export const saveBankInfo = (info) => {
    let storageBankInfo = {};

    const storageValue =  localStorage.getItem(STORAGE_BANK_INFO);
    if (storageValue)
        storageBankInfo = JSON.parse(storageValue);

    storageBankInfo[info.identifier] = {
        name: info.bankInfo.name,
        mfo: info.bankInfo.mfo,
        account: info.bankInfo.account
    };
    localStorage.setItem(STORAGE_BANK_INFO, JSON.stringify(storageBankInfo));
};

export const uploadInvoiceToExcelAsync = ({uri, id}) => {
    return new Promise((resolve, reject) => {
        invoiceService.uploadInvoiceToExcel({uri, id})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
};
export const uploadInvoicesToExcelAsync = ({uri, page, limit, ...filters}) => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};

    return new Promise((resolve, reject) => {
        invoiceService.uploadInvoicesToExcel({params: params, uri: uri})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
};
export const uploadInvoicesWithItemsToExcelAsync = ({uri, page, limit, ...filters}) => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};

    return new Promise((resolve, reject) => {
        invoiceService.uploadInvoicesWithItemsToExcel({params: params, uri: uri})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}


export const signInvoiceAsync = (invoice, certificate) => {
    EventBus.dispatch(SIGN_INVOICE_REQUESTED, invoice.id);
    return new Promise(async (resolve, reject) => {
        const signTicket = invoice.sign_ticket || (await invoiceService.getSignTicket(invoice.id)).data.sign_ticket;

        if (!invoice.executor.approve_info.is_approved) {
            roamingSignAsync(signTicket, certificate)
                .then((response) => {
                    EventBus.dispatch(SIGN_INVOICE_SUCCESS, invoice.id);
                    resolve(response.data);
                })
                .catch((error) => {
                    EventBus.dispatch(SIGN_INVOICE_FAILED, error);
                    reject(error);
                })
        } else if (!invoice.customer.approve_info.is_approved) {
            roamingAttachAsync(signTicket, certificate)
                .then((response) => {
                    EventBus.dispatch(SIGN_INVOICE_SUCCESS, invoice.id);
                    resolve(response.data);
                })
                .catch((error) => {
                    EventBus.dispatch(SIGN_INVOICE_FAILED, error);
                    reject(error);
                })
        }
    })
}
export const cancelInvoiceAsync = async (invoice, comment, certificate) => {
    try {
        EventBus.dispatch(CANCEL_INVOICE_REQUESTED, invoice.id);
        const result = await roamingCancelAsync(invoice.sign_ticket, comment, certificate)
        EventBus.dispatch(CANCEL_INVOICE_SUCCESS, invoice.id);
        return result
    } catch (error) {
        EventBus.dispatch(CANCEL_INVOICE_FAILED, error);
        return false
    }
}
export const declineInvoiceAsync = async (invoice, comment, certificate) => {
    try {
        EventBus.dispatch(DECLINE_INVOICE_REQUESTED, invoice.id);
        const result = await roamingDeclineAsync(invoice.sign_ticket, comment, certificate)
        EventBus.dispatch(DECLINE_INVOICE_SUCCESS, invoice.id);
        return result
    } catch (error) {
        EventBus.dispatch(DECLINE_INVOICE_FAILED, error);
        return false
    }
}



export const selectFilterOptions = (state) => state.invoice.filterOptions;
export const selectInvoices = (state) => state.invoice.invoices;
export const selectCount = (state) => state.invoice.count;
export const selectProductCatalogs = () => JSON.parse(localStorage.getItem(STORAGE_PRODUCT_CATALOGS));
export const selectBankInfo = () => JSON.parse(localStorage.getItem(STORAGE_BANK_INFO));
export const selectInvoicesDataTable = (state) => state.invoice.invoicesDataTableSettings

export const {loadInvoicesDataTableColumn} = invoiceSlice.actions
export const {changeInvoicesDataTableColumn} = invoiceSlice.actions

export const createSigner = invoiceSlice.actions.createSigner;
export const changeActiveSigner = invoiceSlice.actions.changeActiveSigner;
export const clearActiveSigner = invoiceSlice.actions.clearActiveSigner;
export const deleteSigner = invoiceSlice.actions.deleteSigner;
export const loadSigners = invoiceSlice.actions.loadSigners;
export const selectInvoiceSigners = (state) => {
    return {
        directors: state.invoice.directors,
        accountants: state.invoice.accountants,
        releasers: state.invoice.releasers,
        activeDirector: state.invoice.directors.find(d => d.lastSelected === true),
        activeAccountant: state.invoice.accountants.find(a => a.lastSelected === true),
        activeReleaser: state.invoice.releasers.find(r => r.lastSelected === true)
    }
};

export default invoiceSlice.reducer;
