import React from 'react';
import {useSelector} from "react-redux";
import {Translate} from "../../lang/lang";
import {selectLang} from "../../store/reducers/main/main";
import {Button, Alert} from "react-bootstrap";
import IconAlert from "./IconAlert";

const WarningAlert = ({variant, title, text}) => {
    const lang = useSelector(selectLang)
    const t = Translate
    return (
        <IconAlert variant={variant}>
            <Alert.Heading className="mb-0">{title}</Alert.Heading>
            <p className="mb-0 fw-medium mb-2">{text}</p>
            <div className="d-flex justify-content-end">
                <Button variant="outline-danger" href="https://t.me/+lpgw1qdQN0k1MWMy">{t(lang,"main.warning_alert.request_button")}</Button>
            </div>
        </IconAlert>
    );
};

export default WarningAlert;
