import React, {Fragment, useEffect, useRef, useState} from 'react';
import useQuery from "../../form/useQuery";
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {selectCurrentCertificate} from "../../../../store/reducers/eimzo/eimzoReducer";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import {FormProvider, useForm} from "react-hook-form";
import {
    getOrganizationInfoAsync,
    loadCompanyProductCatalogsAsync, loadMeasurementsAsync
} from "../../../../store/reducers/roaming/roamingReducer";
import axios from "axios";
import sendWaybillV2 from "../../../../pages/roaming/waybill-v2/SendWaybillV2";
import dayjs from "dayjs";
import {
    TypeAdditional,
    TypeCorrective,
    TypeDefault
} from "../../../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import {
    saveDraftWaybillV2Async,
    sendWaybillV2Async
} from "../../../../store/reducers/roaming/waybill-v2/romaingWaybillV2Reducer";
import toast from "react-hot-toast";
import WaybillFormV2 from "./WaybillFormV2";
import {Form} from "react-bootstrap";
import ErrorAlert from "../../../common/ErrorAlert";

export const Roadway = 1
export const Railway = 3


const SendWaybillFormV2 = ({draftWaybill, waybill}) => {

    const {shipment_id, type} = useQuery();

    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentCertificate = useSelector(selectCurrentCertificate);

    const [isSaving, setIsSaving] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const alertRef = useRef(null);

    const content = draftWaybill && JSON.parse(draftWaybill?.content) || null

    const transportType = content?.Roadway ? content.Roadway : content?.Railway ? content.Railway : null
    const waybillTransportType = waybill?.roadway ? waybill?.roadway : waybill?.railway ? waybill?.railway : null

    const methods = useForm({
        defaultValues: {
            executor: {
                inn_or_pinfl:
                    content?.Consignor?.TinOrPinfl ||
                    waybill?.executor_info?.inn || waybill?.executor_info?.pinfl ||
                    waybill?.executor?.inn || waybill?.executor?.inn_or_pinfl ||  null,
                name:
                    content?.Consignor?.Name ||
                    waybill?.executor_info?.name ||
                    waybill?.executor?.name || null,
            },
            customer: {
                inn_or_pinfl:
                    content?.Consignee?.TinOrPinfl ||
                    waybill?.customer_info?.inn || waybill?.customer_info?.pinfl ||
                    waybill?.customer?.inn || waybill?.customer?.inn_or_pinfl || null,
                name:
                    content?.Consignee?.Name ||
                    waybill?.customer_info?.name ||
                    waybill?.customer?.name || null,
            },
            freight_forwarder: {
                inn_or_pinfl:
                    content?.FreightForwarder?.TinOrPinfl ||
                    waybill?.executor_info?.inn || waybill?.executor_info?.pinfl ||
                    waybill?.freight_forwarder?.inn || waybill?.freight_forwarder?.inn_or_pinfl || null,
                name:
                    content?.FreightForwarder?.Name ||
                    waybill?.executor_info?.name ||
                    waybill?.freight_forwarder?.name || null,
            },
            carrier: {
                inn_or_pinfl: content?.Carrier?.TinOrPinfl || waybill?.carrier?.inn || waybill?.carrier?.inn_or_pinfl || null,
                name: content?.Carrier?.Name || waybill?.carrier?.name || null,
            },
            responsible_person: {
                inn_or_pinfl:
                    content?.ResponsiblePerson?.Pinfl ||
                    waybill?.responsible_person?.inn || waybill?.responsible_person?.inn_or_pinfl || null,
                name:
                    content?.ResponsiblePerson?.FullName ||
                    waybill?.responsible_person?.name || null,
            },
            driver: {
                inn_or_pinfl: content?.Roadway?.Driver?.Pinfl || waybill?.roadway?.driver?.inn || waybill?.roadway?.driver?.inn_or_pinfl || null,
                name: content?.Roadway?.Driver?.FullName || waybill?.roadway?.driver?.name || null,
            },
            loading_trustee: {
                pinfl: null,
                name: null
            },
            unloading_trustee: {
                pinfl: null,
                name: null
            },
            railway_loading_point: {
                station_id: null,
                station_name: null
            },
            railway_unloading_point: {
                station_id: null,
                station_name: null
            },
            client_contract_info: {
                number: content?.Client?.ContractNo || waybill?.client_contract_info?.number || waybill?.client?.contract?.number || null,
                date: content?.Client?.ContractDate ? new Date(content?.Client?.ContractDate) :  (waybill?.client_contract_info?.date || waybill?.client?.contract?.date) ? new Date(waybill?.client_contract_info?.date || waybill?.client?.contract?.date) : new Date()
            },
            payer_contract_info: {
                number: content?.Payer?.ContractNo || waybill?.payer_contract_info?.number || null,
                date: content?.Payer?.ContractDate ? new Date(content?.Payer?.ContractDate) :  waybill?.payer_contract_info?.date ? new Date(waybill?.payer_contract_info?.date) : new Date()
            },
            executor_is_freight_forwarder: waybill?.executor_info?.inn && true || false,
            customer_is_freight_forwarder: false,
            freight_forwarder_is_carrier:  waybill?.executor_info?.inn && true || false,
            responsible_person_is_driver: waybill?.executor_info?.inn && true || false,
            executor_is_client_employer: false,
            customer_is_client_employer: false,
            branch_code: waybill?.order?.branch?.code || null,
            waybill_type: content?.WaybillLocalType || +type ||  TypeDefault,
            oldWaybillDoc: {
                oldWaybillId: content?.WaybillLocalId || waybill?.id || '',
                oldWaybillNo: content?.WaybillDoc?.WaybillNo || waybill?.info?.number || null,
                oldWaybillDate: content?.WaybillDoc?.WaybillDate ? new Date(content?.WaybillDoc?.WaybillDate) : waybill?.info?.date ? new Date(waybill?.info?.date) : null
            },
            client_inn_or_pinfl: content?.Client?.TinOrPinfl || waybill?.client_contract_info?.inn_or_pinfl || waybill?.client?.inn_or_pinfl || null,
            delivery_type: content?.DeliveryType || waybill?.delivery_type || 2,
            transport_type: content?.TransportType || waybill?.transport_type || 1,
            total_distance: content?.TotalDistance?.toString() || waybill?.total_distance?.toString() || null,
            delivery_cost: content?.DeliveryCost?.toString() || waybill?.delivery_type?.toString() || null,
            total_delivery_cost: content?.TotalDistance?.toString() || waybill?.total_delivery_cost?.toString() || null,
            waybill_info: {
                number:
                    content?.WaybillDoc?.WaybillNo ||
                    waybill?.waybill_info?.number ||
                    waybill?.info?.number || null,
                date:
                    content?.WaybillDoc?.WaybillDate ? new Date(content?.WaybillDoc?.WaybillDate)
                        : waybill?.waybill_info?.date ? new Date(waybill?.waybill_info?.date)
                            : waybill?.info?.date ? new Date(waybill?.info?.date)
                                : new Date()
            },
            contract_info: {
                number:
                    content?.ContractDoc?.ContractNo ||
                    waybill?.contract_info?.number ||
                    waybill?.contract?.number || null,
                date:
                    content?.ContractDoc?.ContractDate ? new Date(content?.ContractDoc?.ContractDate)
                        : waybill?.contract_info?.date ? new Date(waybill?.contract_info?.date)
                            :  waybill?.contract?.date ? new Date(waybill?.contract?.date)
                                : new Date()
            },
            transport: {
                registration_number: waybill?.car_info?.serial_number || transportType?.Truck?.RegNo || waybillTransportType?.truck?.registration_number || null
            },
            railway: {
                train_carriage_number: transportType?.TrainCarriageNumber || waybillTransportType?.train_carriage_number || null,
                train_direction: transportType?.TrainDirection || waybillTransportType?.train_direction || null
            },
            loading_point: {
                company_address_id: null,
                branch_id: null,
                branch_code: null,
                region_code:transportType?.ProductGroups[0]?.LoadingPoint?.RegionId || waybillTransportType?.product_groups[0]?.loading_point?.region_code || null,
                region_name: transportType?.ProductGroups[0]?.LoadingPoint?.RegionName || waybillTransportType?.product_groups[0]?.loading_point?.region_name || null,
                district_code: transportType?.ProductGroups[0]?.LoadingPoint?.DistrictCode || waybillTransportType?.product_groups[0]?.loading_point?.district_code || null,
                district_name: transportType?.ProductGroups[0]?.LoadingPoint?.DistrictName || waybillTransportType?.product_groups[0]?.loading_point?.district_name || null,
                village_code: transportType?.ProductGroups[0]?.LoadingPoint?.MahallaId || waybillTransportType?.product_groups[0]?.loading_point?.village_code || null,
                village_name: transportType?.ProductGroups[0]?.LoadingPoint?.MahallaName || waybillTransportType?.product_groups[0]?.loading_point?.village_name || null,
                street: transportType?.ProductGroups[0]?.LoadingPoint?.Address || waybillTransportType?.product_groups[0]?.loading_point?.street || null,
                longitude: transportType?.ProductGroups[0]?.LoadingPoint?.Longitude || waybillTransportType?.product_groups[0]?.loading_point?.longitude || null,
                latitude: transportType?.ProductGroups[0]?.LoadingPoint?.Latitude || waybillTransportType?.product_groups[0]?.loading_point?.latitude || null,
            },
            unloading_point: {
                company_address_id: null,
                branch_id: null,
                branch_code: null,
                region_code: transportType?.ProductGroups[0]?.UnloadingPoint?.RegionId || waybillTransportType?.product_groups[0]?.unloading_point?.region_code || null,
                region_name: transportType?.ProductGroups[0]?.UnloadingPoint?.RegionName || waybillTransportType?.product_groups[0]?.unloading_point?.region_name || null,
                district_code: transportType?.ProductGroups[0]?.UnloadingPoint?.DistrictCode || waybillTransportType?.product_groups[0]?.unloading_point?.district_code || null,
                district_name: transportType?.ProductGroups[0]?.UnloadingPoint?.DistrictName || waybillTransportType?.product_groups[0]?.unloading_point?.district_name || null,
                village_code: transportType?.ProductGroups[0]?.UnloadingPoint?.MahallaId || waybillTransportType?.product_groups[0]?.unloading_point?.village_code || null,
                village_name: transportType?.ProductGroups[0]?.UnloadingPoint?.MahallaName || waybillTransportType?.product_groups[0]?.unloading_point?.village_name || null,
                street: transportType?.ProductGroups[0]?.UnloadingPoint?.Address || waybillTransportType?.product_groups[0]?.unloading_point?.street || null,
                longitude: transportType?.ProductGroups[0]?.UnloadingPoint?.Longitude || waybillTransportType?.product_groups[0]?.unloading_point?.longitude || null,
                latitude: transportType?.ProductGroups[0]?.UnloadingPoint?.Latitude || waybillTransportType?.product_groups[0]?.unloading_point?.latitude || null,
            },
            items:
                waybill?.items && waybill?.items?.length > 0 ? waybill.items.map(item => {
                        return {
                            name: item.product,
                            catalog_code: item.catalog_class_code,
                            package_code: item.package_code,
                            measurement: item.measurement,
                            quantity: item.quantity,
                            price: item.price,
                            total: item.total
                        }
                    })
                    : transportType?.ProductGroups[0]?.ProductInfo?.Products?.length > 0 ?
                        transportType?.ProductGroups[0]?.ProductInfo?.Products.map(item => {
                            return {
                                name: item.ProductName,
                                catalog_code: item.CatalogCode,
                                catalog_name: item.CatalogName,
                                package_code: item.PackageCode,
                                measurement: item.PackageName,
                                quantity: item.Amount,
                                price: item.Price,
                                total: item.DeliverySum,
                            }})
                        : waybillTransportType?.product_groups[0]?.products?.length > 0 ?
                            waybillTransportType?.product_groups[0]?.products.map(item => {
                                return {
                                    name: item.name,
                                    catalog_code: item.catalog?.code,
                                    catalog_name: item.catalog?.name,
                                    package_code: item.package?.code,
                                    measurement: item.package?.name,
                                    quantity: item.quantity,
                                    price: item.price,
                                    total: item.total,
                                }})
                            : [
                                {
                                    name: null,
                                    catalog_code: null,
                                    catalog_name: null,
                                    package_code: null,
                                    measurement: null,
                                    quantity: null,
                                    price: null,
                                    total: null,
                                }
                            ]
        }
    })

    useEffect(() => {
        if (shipment_id) {
            getOrganizationInfoAsync(waybill?.executor_info?.inn || waybill?.executor_info?.pinfl)
                .then(res => {
                    methods.setValue('responsible_person.inn_or_pinfl', res?.director_pinfl || res?.person_num)
                    methods.setValue('driver.inn_or_pinfl', res?.director_pinfl || res?.person_num)
                })
        }
    }, []);

    useEffect(() => {
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization?.inn, lang));
        dispatch(loadMeasurementsAsync(lang));

        return () => {
            setIsSaving(false);
            setIsSending(false);
        }
    }, []);

    const onSend = async (formData, event) => {
        const actionType = event.nativeEvent.submitter.name;
        const total_distance = typeof formData.total_distance === 'string' ? formData.total_distance.replace(/ /g, '') : formData.total_distance
        const delivery_cost = typeof formData.delivery_cost === 'string' ? formData.delivery_cost.replace(/ /g, '') : formData.delivery_cost
        const total_delivery_cost = typeof formData.total_delivery_cost === 'string' ? formData.total_delivery_cost.replace(/ /g, '') : formData.total_delivery_cost

        const data = {
            delivery_type: formData.delivery_type,
            transport_type: formData.transport_type,
            waybill_info: {
                number: formData.waybill_info.number,
                date: dayjs(formData.waybill_info.date).format('YYYY-MM-DD')
            },
            contract_info: formData?.contract_info?.number ? {
                number: formData.contract_info.number || null,
                date: formData.contract_info?.date ? dayjs(formData.contract_info.date).format('YYYY-MM-DD') : null
            } : null,
            customer_is_freight_forwarder: formData.customer_is_freight_forwarder,
            executor_is_freight_forwarder: formData.executor_is_freight_forwarder,
            freight_forwarder_is_carrier: formData.freight_forwarder_is_carrier,
            type: +formData.waybill_type,
            responsible_person: {
                inn_or_pinfl: formData.responsible_person.inn_or_pinfl,
                name:formData.responsible_person.name
            },
            responsible_person_is_driver: formData.responsible_person_is_driver,
            executor_is_client_employer: formData.client_inn_or_pinfl === formData.executor.inn_or_pinfl,
            customer_is_client_employer: formData.client_inn_or_pinfl === formData.customer.inn_or_pinfl,
            total_distance: +total_distance,
            delivery_cost: +delivery_cost,
            total_delivery_cost: +total_delivery_cost,
            client_contract_info: formData?.client_contract_info?.number ? {
                number: formData.client_contract_info?.number || null,
                date: formData.client_contract_info?.date ? dayjs(formData.client_contract_info.date).format('YYYY-MM-DD') : null
            } : null,
            payer_contract_info: formData?.payer_contract_info?.number ? {
                number: formData.payer_contract_info?.number || null,
                date: formData.payer_contract_info?.date ? dayjs(formData.payer_contract_info.date).format('YYYY-MM-DD') : null
            } : null,
            loading_point: {
                company_address_id: formData.loading_point.company_address_id,  // optional
                branch_id: formData.loading_point.branch_id, // optional
                branch_code: formData.loading_point.branch_code, // optional
                region_code: formData.loading_point.region_code, // required when company_address_id = null and branch_id = null
                region_name: formData.loading_point.region_name, // required when company_address_id = null and branch_id = null
                district_code: formData.loading_point.district_code, // required when company_address_id = null and branch_id = null
                district_name: formData.loading_point.district_name, // required when company_address_id = null and branch_id = null
                village_code: formData.loading_point.village_code, // required when company_address_id = null and branch_id = null
                village_name: formData.loading_point.village_name, // required when company_address_id = null and branch_id = null
                street: formData.loading_point.street, // required when company_address_id = null and branch_id = null
                longitude: +formData.loading_point.longitude, // required when company_address_id = null and branch_id = null
                latitude: +formData.loading_point.latitude,// required when company_address_id = null and branch_id = null
            },
            unloading_point: {
                company_address_id: formData.unloading_point.company_address_id,  // optional
                branch_id: formData.unloading_point.branch_id, // optional
                branch_code: formData.unloading_point.branch_code, // optional
                region_code: formData.unloading_point.region_code, // required when company_address_id = null and branch_id = null
                region_name: formData.unloading_point.region_name, // required when company_address_id = null and branch_id = null
                district_code: formData.unloading_point.district_code, // required when company_address_id = null and branch_id = null
                district_name: formData.unloading_point.district_name, // required when company_address_id = null and branch_id = null
                village_code: formData.unloading_point.village_code, // required when company_address_id = null and branch_id = null
                village_name: formData.unloading_point.village_name, // required when company_address_id = null and branch_id = null
                street: formData.unloading_point.street, // required when company_address_id = null and branch_id = null
                longitude: +formData.unloading_point.longitude, // required when company_address_id = null and branch_id = null
                latitude: +formData.unloading_point.latitude,// required when company_address_id = null and branch_id = null
            },
            items: formData.items.map(x => {
                return {
                    name: x.name,
                    catalog_code: x.catalog_code,
                    package_code: x.package_code?.code, // optional
                    measurement:  x.package_code?.code?.toString() || x.package_code?.toString() || x.measurement,  // package code/package name,
                    quantity: +x.quantity,
                    price: +x.price,
                    total: +x.total
                }})
        }

        if (data.delivery_type === 2) {
            data['contract_info'] = {
                number: formData.contract_info.number,
                date: dayjs(formData.contract_info.date).format('YYYY-MM-DD')
            }
            data['customer'] = {
                inn_or_pinfl: formData.customer.inn_or_pinfl,
                name: formData.customer.name
            }
        }

        if (!data.customer_is_freight_forwarder && !data.executor_is_freight_forwarder) {
            data['freight_forwarder'] = {
                inn_or_pinfl: formData.freight_forwarder.inn_or_pinfl,
                name:formData.freight_forwarder.name
            }
        }

        if (!data.freight_forwarder_is_carrier) {
            data['carrier'] = {
                inn_or_pinfl: formData.carrier.inn_or_pinfl,
                name:formData.carrier.name
            }
        }

        if (!data.responsible_person_is_driver) {
            data['driver'] = {
                inn_or_pinfl: formData.driver.inn_or_pinfl,
                name:formData.driver.name
            }
        }

        if (data.type === TypeAdditional || data.type === TypeCorrective) {
            data['old_waybill'] = {
                id: formData.oldWaybillDoc?.oldWaybillId,
                number: formData.oldWaybillDoc?.oldWaybillNo,
                date: dayjs(formData.oldWaybillDoc?.oldWaybillDate).format("YYYY-MM-DD")
            }
        }

        if (data.transport_type === Roadway) {
            data['transport'] = {
                registration_number: formData.transport?.registration_number?.registration_number,
                model: formData.transport?.registration_number?.model,
            }
        }

        if (data.transport_type === Railway) {
            delete data['loading_point']
            delete data['unloading_point']
            data['train_carriage_number'] = formData.railway?.train_carriage_number
            data['train_direction'] = formData.railway?.train_direction
            data['railway_loading_point'] = {
                station_id: formData.railway_unloading_point?.station_id?.station_id,
                station_name: formData.railway_unloading_point?.station_id?.station_name
            }
            data['railway_unloading_point'] = {
                station_id: formData.railway_unloading_point?.station_id?.station_id,
                station_name: formData.railway_unloading_point?.station_id?.station_name
            }
            data['loading_trustee'] = {
                pinfl: formData.loading_trustee?.pinfl,
                name: formData.loading_trustee?.name
            }
            data['unloading_trustee'] = {
                pinfl: formData.unloading_trustee?.pinfl,
                name: formData.unloading_trustee?.name
            }
        }

        const response = await axios.post('/document-generator/roaming/waybill-v2/generate/send-hash-code', data)
        if (actionType === 'save') {
            await saveDraftWaybillV2({
                id: response.data?.id,
                content: response.data?.hashcode
            })
        } else if (actionType === 'send') {
            await sendWaybillV2({
                id: response.data?.id,
                hashCode: response.data?.hashcode,
            });
        }
    }

    const saveDraftWaybillV2 = (data) => {
        setIsSaving(true);
        saveDraftWaybillV2Async(data)
            .then(() => {
                toast.success(t(lang, 'roaming.waybill.save.toast.saved_success'))
            })
            .catch(error => {
                setIsSaving(false);
                setErrorMessage(error?.message || null);
                alertRef?.current?.scrollIntoView();
            })
    }

    const sendWaybillV2 = ({id, hashCode}) => {
        setIsSending(true);

        sendWaybillV2Async(currentCertificate, lang, {id, hashCode})
            .then(() => {
                toast.success(t(lang, 'roaming.waybill.send.toast.register_success'))
            })
            .catch(error => {
                setIsSending(false);
                setErrorMessage(error.message || null);
                alertRef.current.scrollIntoView();
            })
    }

    return (
        <Fragment>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSend)}>
                    {errorMessage && <ErrorAlert ref={alertRef} errorMessage={errorMessage}/>}
                    <WaybillFormV2 shipmentId={shipment_id} isSending={isSending} isSaving={isSaving} content={content || waybill} />
                </Form>
            </FormProvider>
        </Fragment>
    );
};

export default SendWaybillFormV2;
