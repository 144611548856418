import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {useFormContext} from "react-hook-form";
import {
    changeActiveSigner, clearActiveSigner,
    selectReturnInvoiceActiveReleaser,
    selectReturnInvoiceReleasers
} from "../../../store/reducers/edi/return-invoice/returnInvoiceReducer";
import ActionButton from "../../common/ActionButton";
import CreatableSelect from "react-select/creatable";

const SelectReleaser = ({onCreate, onDelete, ...props}) => {
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const releasers = useSelector(selectReturnInvoiceReleasers);
    const activeReleaser = useSelector(selectReturnInvoiceActiveReleaser);
    const {setValue} = useFormContext()

    const formatOptionLabel = (option) => {
        const deleteOption = option => dispatch(onDelete(option));
        return (
            <div className="d-flex justify-content-between text-dark">
                <div>{option.label}</div>
                {!option.lastSelected && <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-1" onClick={(event) => {
                    event.stopPropagation();
                    deleteOption(option);
                }}
                />}
            </div>
        )
    }

    const createOption = (label) => {
        let newOption = {
            label,
            arrayFieldName: "releasers",
            lastSelected: false,
        }
        onCreate(newOption);
    };
    const formatCreateLabel = label => `${t(lang, "edi.common.add_button")} ${label}`;
    const noOptionsMessage = () => t(lang, "edi.common.releaser.no_options.message");

    useEffect(() => {
        if (activeReleaser) {
            setValue('releaser', activeReleaser.label)
        } else {
            setValue('releaser', '')
        }
    },[activeReleaser])

    return (
        <CreatableSelect options={releasers}
                         onChange={(releaser) => releaser ? dispatch(changeActiveSigner(releaser)) : dispatch(clearActiveSigner(activeReleaser))}
                         onCreateOption={createOption}
                         formatCreateLabel={formatCreateLabel}
                         formatOptionLabel={(option) => option?.__isNew__ ? option.label : formatOptionLabel(option)}
                         getOptionValue={option => option.label}
                         getOptionLabel={option => option.label}
                         noOptionsMessage={() => noOptionsMessage()}
                         value={activeReleaser}
                         placeholder={t(lang, "edi.common.button.select")}
                         isClearable
                         hideSelectedOptions
                         classNamePrefix="react-select"
                         {...props}
        />
    );
};

export default SelectReleaser;
