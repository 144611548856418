import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {Card, Col, FloatingLabel, Form, Row} from "react-bootstrap";
import classNames from "classnames";
import Cleave from "cleave.js/react";
const DeliveryForm = () => {

    const lang = useSelector(selectLang);
    const t = Translate;
    const {register, formState: {errors}, control, watch, setValue} = useFormContext();

    const totalDistance = useWatch({name: "total_distance", exact: true})
    const deliveryCost = useWatch({name: "delivery_cost", exact: true})

    useEffect(() => {
        if (deliveryCost || totalDistance) {
            recalculate()
        }
    }, [deliveryCost, totalDistance]);

    const recalculate = () => {
        const distance = parseFloat(totalDistance?.replace(/ /g, '')) || 0;
        const cost = parseFloat(deliveryCost?.replace(/ /g, '')) || 0;

        if (!isNaN(distance) && !isNaN(cost)) {
            setValue('total_delivery_cost', distance * cost);
        }
    }

    return (
        <Card className={'p-1 mt-3'}>
            <Row>
                <Form.Group as={Col} sm={12} md={4}>
                    <Controller
                        name={'total_distance'}
                        control={control}
                        rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                        render={({field}) => (
                            <FloatingLabel label={<>
                                {t(lang, "roaming.waybill.v2.send.total_distance")}
                                <span className="text-danger">*</span>
                            </>}>
                                <Cleave
                                    options={{
                                        numeral: true,
                                        delimiter: ' ',
                                    }}
                                    value={field.value}
                                    className={classNames('form-control', {
                                        'is-invalid': errors.total_distance,
                                    })}
                                    onChange={field.onChange}
                                    placeholder={t(lang, 'roaming.waybill.v2.send.total_distance:placeholder')}
                                />
                            </FloatingLabel>
                        )} />
                    <Form.Control.Feedback type={"invalid"} className={'d-block'}>
                        {errors.total_distance && errors.total_distance.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm={12} md={4}>
                    <Controller
                        name={'delivery_cost'}
                        control={control}
                        rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                        render={({field}) => (
                            <FloatingLabel label={<>
                                {t(lang, "roaming.waybill.v2.send.delivery_cost")}
                                <span className="text-danger">*</span>
                            </>}>
                                <Cleave
                                    options={{
                                        numeral: true,
                                        delimiter: ' '
                                    }}
                                    placeholder={t(lang, 'roaming.waybill.v2.send.delivery_cost:placeholder')}
                                    value={field.value}
                                    className={classNames('form-control', {
                                        'is-invalid': errors.delivery_cost,
                                    })}
                                    onChange={field.onChange}
                                />
                            </FloatingLabel>
                        )} />
                    <Form.Control.Feedback type={"invalid"} className={'d-block'}>
                        {errors.delivery_cost && errors.delivery_cost.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm={12} md={4}>
                    <Controller
                        name={'total_delivery_cost'}
                        control={control}
                        rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                        render={({field}) => (
                            <FloatingLabel label={<>
                                {t(lang, "roaming.waybill.v2.send.total_delivery_cost")}
                                <span className="text-danger">*</span>
                            </>}>
                                <Cleave
                                    options={{
                                        numeral: true,
                                        delimiter: ' '
                                    }}
                                    value={field.value}
                                    className={classNames('form-control', {
                                        'is-invalid': errors.total_delivery_cost,
                                    })}
                                    onChange={field.onChange}
                                    placeholder={t(lang, 'roaming.waybill.v2.send.total_delivery_cost:placeholder')}
                                />
                            </FloatingLabel>
                        )} />
                    <Form.Control.Feedback type={"invalid"} className={'d-block'}>
                        {errors.total_delivery_cost && errors.total_delivery_cost.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
        </Card>
    );
};

export default DeliveryForm;
