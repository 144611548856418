import React, {Fragment} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {Col, Row, Form, Card, FloatingLabel} from "react-bootstrap";
import FindCompanySearchable from "../../../organization/FindCompanySearchable";
import classNames from "classnames";
import ReactSelect from "react-select";

const MembersInfoForm = () => {
    const {register, formState: {errors}, control, setValue} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Fragment>
            <h4 className={'fs-8 fw-medium second-text ms-3'}>{t(lang, 'roaming.waybill.send.members_info_form.title')}</h4>

            <Row className={'mb-2'}>
                <Col xs={12} md={6}>
                    <Card className="p-2 d-flex flex-column gap-2 h-100">
                        <h4 className={'fs-8 fw-medium second-text ms-3'}>{t(lang, "roaming.waybill.send.members_info_form.customer")}</h4>
                        <Row>
                            <Form.Group as={Col} xs={12} md={6}>
                                <Controller
                                    control={control}
                                    name="customer.inn"
                                    rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                                    render={({field}) => (
                                        <FindCompanySearchable
                                            id={"customer.inn"}
                                            ref={field.ref}
                                            defaultInn={field.value}
                                            placeholder={t(lang, "roaming.waybill.send.members_info_form.customer.inn")}
                                            inputProps={{
                                                isInvalid: errors?.customer?.inn,
                                                placeholder: t(lang, "")
                                            }}
                                            inputGroupProps={{className: classNames('select',{'is-invalid': errors?.customer?.inn})}}
                                            onChange={(company) => {
                                                field.onChange(company?.person_num || company?.inn);
                                                setValue('customer.name', company?.name || '')
                                            }}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.customer?.inn?.message}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={6}>
                                <FloatingLabel label={t(lang, "roaming.waybill.send.members_info_form.customer.name")}>
                                    <Form.Control
                                        type="text"
                                        disabled={true}
                                        {...register('customer.name')}
                                        placeholder={t(lang, "roaming.waybill.send.members_info_form.customer.name.placeholder")}
                                    />
                                </FloatingLabel>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={6} className={'mt-2'}>
                                <Controller
                                    control={control}
                                    name="customer.seller.inn"
                                    rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                                    render={({field}) => (
                                        <FindCompanySearchable
                                            id={"customer.seller.inn"}
                                            placeholder={t(lang, 'roaming.waybill.send.members_info_form.seller.inn')}
                                            ref={field.ref}
                                            defaultInn={field.value}
                                            onChange={company => {
                                                field.onChange(company?.person_num || company?.inn);
                                                setValue('customer.seller.name', company?.name || '');
                                            }}
                                            inputProps={{
                                                isInvalid: errors?.customer?.seller?.inn,
                                                placeholder: ""
                                            }}
                                            inputGroupProps={{className: classNames('select',{'is-invalid': errors?.customer?.seller?.inn})}}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.customer?.seller?.inn?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={6} className={'mt-2'}>
                                <FloatingLabel label={t(lang, 'roaming.waybill.send.members_info_form.seller.name')}>
                                    <Form.Control
                                        type="text"
                                        disabled={true}
                                        {...register('customer.seller.name')}
                                        placeholder={t(lang, "roaming.waybill.send.members_info_form.seller.name.placeholder")}
                                    />
                                </FloatingLabel>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} className={'mt-2'}>
                                <FloatingLabel label={t(lang, 'roaming.waybill.send.members_info_form.point_of_loading', {number: 1})}>
                                    <Form.Control
                                        type="text"
                                        {...register('customer.first_point_of_loading')}
                                        placeholder={t(lang, 'roaming.waybill.send.members_info_form.point_of_loading', {number: 1})}
                                    />
                                </FloatingLabel>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} className={'mt-2'}>
                                <FloatingLabel label={t(lang, 'roaming.waybill.send.members_info_form.point_of_loading', {number: 2})}>
                                    <Form.Control
                                        type="text"
                                        {...register('customer.second_point_of_loading')}
                                        placeholder={t(lang, 'roaming.waybill.send.members_info_form.point_of_loading', {number: 2})}
                                    />
                                </FloatingLabel>

                            </Form.Group>
                            <Form.Group as={Col} xs={12} className={'mt-2'}>
                                <FloatingLabel label={t(lang, 'roaming.waybill.send.additional_information.point_of_redirect_name')}>
                                    <Form.Control
                                        type="text"
                                        {...register("point_of_redirect_name")}
                                        placeholder={t(lang, 'roaming.waybill.send.additional_information.point_of_redirect_name')}
                                    />
                                </FloatingLabel>
                            </Form.Group>
                        </Row>
                    </Card>
                </Col>

                <Col xs={12} md={6}>
                    <Card className="p-2 d-flex flex-column gap-2 h-100">
                        <h4 className={'fs-8 fw-medium second-text ms-3'}>{t(lang, 'roaming.waybill.send.members_info_form.carrier')}</h4>
                        <Row>
                            <Form.Group as={Col} xs={12} md={6}>
                                <FloatingLabel label={t(lang, "roaming.waybill.send.members_info_form.carrier.inn")}>
                                    <Form.Control
                                        type="text"
                                        disabled={true}
                                        {...register('carrier.inn')}
                                        placeholder={t(lang, "roaming.waybill.send.members_info_form.carrier.inn")}
                                    />
                                </FloatingLabel>

                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={6}>
                                <FloatingLabel label={t(lang, "roaming.waybill.send.members_info_form.carrier.name")}>
                                    <Form.Control
                                        type="text"
                                        disabled={true}
                                        {...register('carrier.name')}
                                        placeholder={t(lang, "roaming.waybill.send.members_info_form.carrier.name")}
                                    />
                                </FloatingLabel>

                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={6} className={'mt-2'}>
                                <Controller
                                    control={control}
                                    name="carrier.buyer.inn"
                                    rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                                    render={({field}) => (
                                        <FindCompanySearchable
                                            placeholder={t(lang, 'roaming.waybill.send.members_info_form.buyer.inn')}
                                            id={"carrier.buyer.inn"}
                                            ref={field.ref}
                                            defaultInn={field.value}
                                            onChange={(company) => {
                                                field.onChange(company?.person_num || company?.inn);
                                                setValue('carrier.buyer.name', company?.name || '');
                                            }}
                                            inputProps={{
                                                isInvalid: errors?.carrier?.buyer?.inn,
                                                placeholder: ""
                                            }}
                                            inputGroupProps={{className: classNames('select',{'is-invalid': errors?.carrier?.buyer?.inn})}}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.carrier?.buyer?.inn?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={6} className={'mt-2'}>
                                <FloatingLabel label={t(lang, 'roaming.waybill.send.members_info_form.buyer.name')}>
                                    <Form.Control
                                        type="text"
                                        disabled={true}
                                        {...register('carrier.buyer.name')}
                                        placeholder={t(lang, "roaming.waybill.send.members_info_form.buyer.name")}
                                    />
                                </FloatingLabel>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} className={'mt-2'}>
                                <FloatingLabel label={t(lang, 'roaming.waybill.send.members_info_form.point_of_unloading', {number: 1})}>
                                    <Form.Control
                                        type="text"
                                        {...register('carrier.first_point_of_unloading')}
                                        placeholder={t(lang, 'roaming.waybill.send.members_info_form.point_of_unloading', {number: 1})}
                                    />
                                </FloatingLabel>

                            </Form.Group>
                            <Form.Group as={Col} xs={12} className={'mt-2'}>
                                <FloatingLabel label={t(lang, 'roaming.waybill.send.members_info_form.point_of_unloading', {number: 2})}>
                                    <Form.Control
                                        type="text"
                                        {...register('carrier.second_point_of_unloading')}
                                        placeholder={t(lang, 'roaming.waybill.send.members_info_form.point_of_unloading', {number: 2})}
                                    />
                                </FloatingLabel>

                            </Form.Group>
                            <Form.Group as={Col} xs={12} className={'mt-2'}>
                                <FloatingLabel label={t(lang, 'roaming.waybill.send.additional_information.point_of_redirect_address')}>
                                    <Form.Control
                                        type="text"
                                        {...register("point_of_redirect_address")}
                                        placeholder={t(lang, 'roaming.waybill.send.additional_information.point_of_redirect_address')}
                                    />
                                </FloatingLabel>
                            </Form.Group>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default MembersInfoForm;
