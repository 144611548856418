import React, {Fragment, useEffect, useState} from 'react';
import {selectCount,changeFilterOptionsAsync} from "../../../store/reducers/roaming/act/actReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentCertificate} from "../../../store/reducers/eimzo/eimzoReducer";
import {FormProvider, useForm} from "react-hook-form";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import IconButton from "../../IconButton";
import {VIEW_DRAFT} from "../../../enum/roaming/TypeViewers";
import checkPermission from "../../../enum/permission/CheckPermission";
import {PermissionRoamingActCreate} from "../../../enum/permission/RoamingPermission";
import {Link, useNavigate} from "react-router-dom";
import toastPermission from "../../../enum/permission/ToastPermission";
import {faFilter, faPlus, faRefresh, faSignature} from "@fortawesome/free-solid-svg-icons";
import {Col, Collapse, Form, Row} from "react-bootstrap";
import Badge from "../../common/Badge";
import ActDataTableFilter from "./ActDataTableFilter";
import IconPlus from "../../../assets/icon/IconPlus";
import IconFilter from "../../../assets/icon/IconFilter";
import IconStroke from "../../../assets/icon/IconStroke";
import IconCaretDown from "../../../assets/icon/IconCaretDown";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import {scrollEvent} from "../../../helpers/scrollEvent";

const ActDataTableHeader = ({selectedRowIds, onClickBulkSign, page, typeViewer}) => {
    const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(false)
    const actCount = useSelector(selectCount)
    const currentCertificate = useSelector(selectCurrentCertificate)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const methods = useForm();
    const lang = useSelector(selectLang);
    const t = Translate;

    const onSubmitFilter = ({order_number, ...filters}) => {
        dispatch(changeFilterOptionsAsync({
            order_number: order_number || null,
            page: 1,
            ...filters
        }))
    }

    const onBulkSign = async () => {
        let selectAct = Object.keys(selectedRowIds).map((key) => {
            let value = selectedRowIds[key]
            if (value) {
                return page[key].original
            }
            return false
        })
        onClickBulkSign(selectAct)
        return selectAct
    }

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('roaming-act-data-table-header'))
        return () => window.removeEventListener('scroll', () => scrollEvent('roaming-act-data-table-header'))
    }, []);

    return (
        <Row id={'roaming-act-data-table-header'} className="flex-between-center align-items-center my-3 position-sticky z-index-1" style={{top: '4rem'}}>
            <Collapse in={isOpenFilterCollapse}>
                <Col sm={12}>
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                            <ActDataTableFilter/>
                        </Form>
                    </FormProvider>
                    <hr/>
                </Col>
            </Collapse>
            <Col cols={'auto'} className={'d-flex gap-3'}>
                <button onClick={() => navigate(-1)} className={'main-text btn-back'} style={{width: 35, height: 35}}>
                    <IconArrowLeft />
                </button>
                <div>
                    <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.act_form.title")}: {actCount}</h5>
                    <span className={'second-text fs-9'}>{t(lang, 'edi.navbar.routes.documents')}</span>
                </div>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                {Object.keys(selectedRowIds).length > 0 ? (
                    <div className="d-flex">
                        {typeViewer === VIEW_DRAFT ? <IconButton
                                onClick={onBulkSign}
                                disabled={!currentCertificate}
                                variant="phoenix-secondary"
                                size="sm"
                                type="button"
                                icon={faSignature}
                                iconClassName="me-1"
                                className="ms-2"
                            >{t(lang, "edi.attorney.datatable.header.navigation.sign")}
                            </IconButton>
                            :
                            ''
                        }
                    </div>
                ) : (
                    <div className={'d-flex gap-2'} id="orders-actions">
                        {   typeViewer !== VIEW_DRAFT
                            ?
                            <Fragment>
                                <IconButton
                                    variant="phoenix-secondary"
                                    size="sm"
                                    transform="shrink-3"
                                    className="btn-davr px-5 py-0 bg-white"
                                    icon={faRefresh}
                                    iconClassName={'fs-7 text-primary-davr'}
                                    onClick={methods.handleSubmit(onSubmitFilter)}
                                >
                                    <span className="d-none d-sm-inline-block ms-1 fs-8 text-nowrap fs-md-9">
                                        {t(lang, "edi.orders.order.resume_order_button")}
                                    </span>
                                </IconButton>

                                <IconButton
                                    onClick={() => setIsOpenFilterCollapse(!isOpenFilterCollapse)}
                                    className="btn-davr ps-2 pe-1 py-0 bg-white text-dark gap-2"
                                >
                                    <div className={'d-flex align-items-center'}>
                                        <IconFilter />
                                        <span className="d-none d-sm-inline-block ms-1 fs-8 text-nowrap fs-md-9">{t(lang, "roaming.invoices.table_header.filter")}</span>
                                    </div>
                                    <div className={'d-flex justify-content-between gap-2'}>
                                        <IconStroke />
                                        <IconCaretDown />
                                    </div>
                                </IconButton>
                            </Fragment>

                            : ''
                        }
                        <IconButton
                            className="btn-davr px-3 py-0 bg-primary-davr"
                            variant="phoenix-secondary"
                            size="sm"
                            transform="shrink-3"
                            as={Link}
                            to="/roaming/act/register"
                        >
                            <IconPlus />
                            <span className="d-none d-sm-inline-block ms-1 fs-8 text-white text-nowrap fs-md-9">{t(lang, "edi.common.button.create")}</span>

                        </IconButton>
                    </div>
                )}
            </Col>
        </Row>
    );
};

export default ActDataTableHeader;
