import React from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";

const DeclinedHistoryActionSeal = ({serialNumber, signingTime, fullName}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    return (
        <div className='border-300 my-2 p-2 border-warning text-warning' style={{borderRadius: '10px', border: '10px solid', background: 'rgba(248, 172, 89, 0.1)'}}>
            <div className="d-flex justify-content-between">
                <span className="me-3 text-secondary">№ {serialNumber}</span>
                <span className="text-secondary">{signingTime}</span>
            </div>
            <p className={`fs-7 fw-semi-bold text-center text-uppercase my-3`}>{t(lang, 'roaming.action_seal.declined_text')}</p>
            <p className="m-0 fs--1 text-secondary">
                {fullName}
            </p>
        </div>
    );
};

export default DeclinedHistoryActionSeal;
