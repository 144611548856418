import React, {Fragment, useState} from 'react';
import {useSelector} from "react-redux";
import {selectDateDayJSFormat, selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {autoActiveExtendPackage} from "../../../store/reducers/billing/billingReducer";
import {Card, Col, Table, Form, Row, ListGroup} from "react-bootstrap";
import dayjs from "dayjs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfinity} from "@fortawesome/free-solid-svg-icons";
import Badge from "../../common/Badge";

const ActivePackageCard = ({activePackage, setActivePackage}) => {
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    const [autoExtend, setAutoExtend] = useState(!!activePackage.is_auto_extend)

    const autoActivePackageSwitch = (id, e) => {
        setAutoExtend(e.target.checked)
        let params = {
            active_package_id : id,
            is_auto_extend: e.target.checked
        }
        autoActiveExtendPackage(params)
    }
    return (
        <Fragment>

            <ListGroup.Item className={'d-flex justify-content-between cursor-pointer'} onClick={()=> setActivePackage(activePackage)}>
                <span>{activePackage.package.name}</span>
                <Badge variant={'phoenix'} bg={'success-davr'} className={'rounded-3 d-flex align-items-center'}>
                    <p className="mb-1 d-flex justify-content-between gap-3">
                        {t(lang, 'main.billing.active_packages_card.package_expires_on')}:
                        <span className="">
                            {dayjs(activePackage.expires_at).format(currentDateDayJSFormat)}
                        </span>
                    </p>
                </Badge>
            </ListGroup.Item>

            {/*<Col xs={12} lg={6} className="mt-3 fs--1">
                <div className={'bg-200 h-100'}>
                    <div className="border-bottom p-2">
                        <div className="text-center text-sm-start d-block d-sm-flex justify-content-between align-items-center">
                            <Card.Title>{activePackage.package.name}</Card.Title>
                            <div>
                                <p className="mb-1">{t(lang, 'main.billing.active_packages_card.package_expires_on')}:
                                    <span className="text-danger"> {dayjs(activePackage.expires_at).format(currentDateDayJSFormat)}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="p-0">
                        <Table responsive>
                            <thead className="bg-white">
                            <tr>
                                <th className="w-25 fw-semi-bold">{t(lang, 'main.billing.active_packages_card.table.operation')}</th>
                                <th className={'fw-semi-bold'}>{t(lang, 'main.billing.active_packages_card.table.limit_count')}</th>
                                <th className={'fw-semi-bold'}>{t(lang, 'main.billing.active_packages_card.table.used_count')}</th>
                                <th className={'fw-semi-bold'}>{t(lang, 'main.billing.active_packages_card.table.left_count')}</th>
                                <th className="text-end fw-semi-bold">{t(lang, 'main.billing.active_packages_card.table.over_limit_count')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {activePackage.package.items.map(item => (
                                <tr key={item.code}>
                                    <td className="w-25">{item.name}</td>
                                    <td className="text-center">
                                        {item.is_unlimited ? <FontAwesomeIcon className="text-black" icon={faInfinity} /> : <span>{item.limit}</span>}
                                    </td>
                                    <td className="text-center">{item.used_count}</td>
                                    <td className="text-center">
                                        {item.is_unlimited ? <FontAwesomeIcon className="text-black" icon={faInfinity} /> : <span>{item.limit - item.used_count}</span>}
                                    </td>
                                    <td className="text-end">{
                                        item.is_unlimited ? '-' : t(lang, 'main.billing.all_packages_card.active_packages.package.over_limit_sum', {sum: item.over_limit_price})}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        {activePackage?.package?.price > 0 && <Form.Group as={Row} className={'justify-content-end align-items-center'} controlId={'switch'+activePackage.id}>
                            <Form.Label column sm={'auto'} className={'text-end pe-0'}>
                                {t(lang, 'main.billing.balance_info_card.organization.auto_extend.title')}
                            </Form.Label>
                            <Col sm={'auto'}>
                                <Form.Check
                                    onChange={(e)=>autoActivePackageSwitch(activePackage.id, e)}
                                    checked={autoExtend}
                                    type='switch'
                                />
                            </Col>
                        </Form.Group>
                        }
                    </div>
                </div>
            </Col>*/}
        </Fragment>
    );
};

export default ActivePackageCard;
