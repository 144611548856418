import React, {Fragment} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {Col, Row, Form, Card, FloatingLabel} from "react-bootstrap";
import FindCompanySearchable from "../../../organization/FindCompanySearchable";
import classNames from "classnames";

const ActFormOwnerBuyer = () => {
    const {register, formState: {errors}, control, setValue} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;

    const onChangeCompanyBuyer = company => {
        setValue('ActBuyer', {
            inn: company?.inn || '',
            name: company?.name || '',
            pinfl: company?.person_num || company?.inn,
        }, {shouldValidate: true});
    };

    return (
        <Fragment>
            <Row className={'mb-5 mt-3'}>
               <Col xs={12} md={6}>
                   <h4 className={'fs-8 fw-medium second-text ms-3'}>{t(lang, "roaming.act_form_seller_title")}</h4>
                   <Card className="p-2 h-100">
                       <Row>
                           <Col xs={12} md={6}>
                               <Form.Group>
                                   <FloatingLabel label={t(lang, "roaming.waybill.send.shipment_items_form.table.product")}>
                                       <Form.Control
                                           type='number'
                                           disabled={true}
                                           placeholder={t(lang, "roaming.waybill.send.shipment_items_form.table.product")}
                                           className='input-spin-none'
                                           {...register('ActSeller.inn', {
                                               pattern: /^[0-9]+$/,
                                               required: t(lang, "edi.common.forms.validations.is_required")
                                           })}
                                       />
                                   </FloatingLabel>
                               </Form.Group>
                           </Col>
                           <Col xs={12} md={6}>
                               <Form.Group>
                                   <FloatingLabel label={t(lang, "edi.common.inn_or_pinfl")}>
                                       <Form.Control
                                           type='number'
                                           disabled={true}
                                           placeholder={t(lang, "edi.common.inn_or_pinfl")}
                                           className='input-spin-none'
                                           {...register('ActSeller.pinfl')}
                                       />
                                   </FloatingLabel>

                               </Form.Group>
                           </Col>
                           <Col xs={12} md={6} className={'mt-2'}>
                               <Form.Group>
                                   <FloatingLabel label={t(lang, "roaming.act_form_seller_branch")}>
                                       <Form.Control
                                           placeholder={t(lang, "roaming.act_form_seller_branch")}
                                           name='SellerBranchName'
                                           {...register(`SellerBranchName`)}
                                       />
                                   </FloatingLabel>
                               </Form.Group>
                           </Col>
                           <Col xs={12} md={6} className={'mt-2'}>
                               <Form.Group>
                                   <FloatingLabel label={t(lang, "roaming.act_form_seller_branch_code")}>
                                       <Form.Control
                                           placeholder={t(lang, "roaming.act_form_seller_branch_code")}
                                           name='SellerBranchCode'
                                           {...register(`SellerBranchCode`)}
                                       />
                                   </FloatingLabel>
                               </Form.Group>
                           </Col>
                       </Row>
                   </Card>
               </Col>
               <Col xs={12} md={6}>
                    <h4 className={'fs-8 fw-medium second-text ms-3'}>{t(lang, "roaming.act_form_buyer_name")}</h4>
                    <Card className="p-2 h-100">
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group>
                                    <Controller
                                        name="ActBuyer.inn"
                                        control={control}
                                        render={({field}) => (
                                            <FindCompanySearchable
                                                id={'ActBuyer.inn'}
                                                placeholder={t(lang, "roaming.act_form_seller_inn")}
                                                defaultInn={field.value}
                                                onChange={(company) => {
                                                    onChangeCompanyBuyer(company);
                                                }}
                                                inputProps={{
                                                    isInvalid: errors?.ActBuyer?.inn,
                                                }}
                                                inputGroupProps={{
                                                    className: classNames('select',{
                                                        'is-invalid': errors?.ActBuyer?.inn,
                                                    })
                                                }}
                                                ref={field.ref}
                                            />
                                        )}
                                        rules={{
                                            pattern: /^[0-9]+$/,
                                            required: t(lang, "edi.common.forms.validations.is_required")
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.ActBuyer?.inn?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group>
                                    <FloatingLabel label={t(lang, "edi.common.inn_or_pinfl")}>
                                        <Form.Control
                                            disabled={true}
                                            {...register('ActBuyer.pinfl')}
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} className={'mt-2'}>
                                <Form.Group>
                                    <FloatingLabel label={t(lang, "roaming.act_form_seller_branch")}>
                                        <Form.Control
                                            placeholder={t(lang, "roaming.act_form_seller_branch_placeholder")}
                                            name='BuyerBranchName'
                                            {...register(`BuyerBranchName`)}
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} className={'mt-2'}>
                                <Form.Group>
                                    <FloatingLabel label={t(lang, "roaming.act_form_seller_branch_code")}>
                                        <Form.Control
                                            placeholder={t(lang, "roaming.act_form_seller_branch_code_placeholder")}
                                            name='BuyerBranchCode'
                                            {...register(`BuyerBranchCode`)}
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default ActFormOwnerBuyer;
