import React, {Fragment, useEffect, useState} from 'react';
import {Translate} from "../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {Button, Col, Modal, OverlayTrigger, Row, Tooltip, Form} from "react-bootstrap";
import Divider from "./Divider";
import IconButton from "../IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import ReactSelect from "react-select";
import FalconCloseButton from "./FalconCloseButton";
import CustomFloatingLabelSelect from "../floating-custom-select/CustomFloatingLabelSelect";
import classNames from "classnames";

const CatalogPackageLinkSelector = ({onChange, catalogPackages, defaultCatalogPackage, linkProps, textButton}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [show, setShow] = useState(false);
    const [catalogPackage, setCatalogPackage] = useState(null);

    const link = <span className="text-danger cursor-pointer fs--1"
                       onClick={() => setShow(true)}
                       {...linkProps}
    >
        {catalogPackage ? `${catalogPackage.code}` : t(lang, 'items.common.click_to_select_package_code')}
    </span>

    const onChangeHandler = (option) => {
        setCatalogPackage(option);
        onChange(option);
        option && setShow(false);
    }

    useEffect(() => {
        if (textButton) {
            setCatalogPackage(Array.isArray(catalogPackages) ? catalogPackages.find(i => i.code === defaultCatalogPackage) : defaultCatalogPackage)
        } else {
            if (defaultCatalogPackage?.code !== catalogPackage?.code) {
                setCatalogPackage(defaultCatalogPackage);
                onChange(defaultCatalogPackage);
            }
        }
    }, [catalogPackages, defaultCatalogPackage])

    return (
        <Fragment>
            {!textButton ?
                <Fragment>
                    {catalogPackage && <OverlayTrigger overlay={<Tooltip>{catalogPackage.name_ru}</Tooltip>}>
                        {link}
                    </OverlayTrigger>}
                </Fragment> :
                <Button variant="phoenix-secondary"
                        className={classNames('w-100')}
                        onClick={() => setShow(true)}>
                    {catalogPackage ? catalogPackage?.code : textButton || t(lang, 'edi.common.button.select')}
                </Button>}
            <Modal show={show}
                   className={'davr-modal'}
                   onHide={() => setShow(false)}
                   size="xl"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, 'items.common.catalog_package_link_selector_modal.title')}</Modal.Title>
                    <FalconCloseButton onClick={() => setShow(false)}/>
                </Modal.Header>
                <Modal.Body>
                    <Row className="gy-2">
                        <Col xs={12}>
                            <Form.Group>
                                <CustomFloatingLabelSelect
                                    label={t(lang, 'items.common.tax.package_code')}
                                    options={catalogPackages}
                                    isClearable
                                    hideSelectedOptions
                                    defaultMenuIsOpen={!catalogPackage}
                                    getOptionLabel={option => option.name_ru}
                                    getOptionValue={option => option.code}
                                    placeholder={t(lang, "items.common.select")}
                                    value={null}
                                    onChange={onChangeHandler}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {catalogPackage &&
                        <>
                            <Divider/>
                            <Row>
                                <Col xs={12}>
                                    <h5 className="text-info">{t(lang, 'items.common.catalog_package_link_selector.chosen_catalog_package')}</h5>
                                    <div className="mt-3 fw-bold fs--1">
                                        <span className="text-primary">{catalogPackage.code}</span> |
                                        <span className="text-dark">{catalogPackage.name_ru}</span>
                                        <IconButton
                                            variant="phoenix-secondary"
                                            size="sm"
                                            icon={faTrash}
                                            iconClassName={"text-danger"}
                                            className="ms-2 fw-semi-bold"
                                            title={t(lang, 'items.common.delete')}
                                            onClick={() => onChangeHandler(null)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShow(false)}>
                        {t(lang, 'items.common.cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default CatalogPackageLinkSelector;
