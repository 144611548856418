import React from 'react';

const IconRussian = ({width = 24, height = 24, color = "none"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.5 11C2.5 9.57165 2.50106 8.55069 2.60538 7.77481C2.70776 7.01331 2.90128 6.56319 3.23223 6.23223C3.56319 5.90128 4.01331 5.70776 4.77481 5.60538C5.55069 5.50106 6.57165 5.5 8 5.5H16C17.4283 5.5 18.4493 5.50106 19.2252 5.60538C19.9867 5.70776 20.4368 5.90128 20.7678 6.23223C21.0987 6.56319 21.2922 7.01331 21.3946 7.77481C21.4989 8.55069 21.5 9.57165 21.5 11V13C21.5 14.4283 21.4989 15.4493 21.3946 16.2252C21.2922 16.9867 21.0987 17.4368 20.7678 17.7678C20.4368 18.0987 19.9867 18.2922 19.2252 18.3946C18.4493 18.4989 17.4283 18.5 16 18.5H8C6.57165 18.5 5.55069 18.4989 4.77481 18.3946C4.01331 18.2922 3.56319 18.0987 3.23223 17.7678C2.90128 17.4368 2.70776 16.9867 2.60538 16.2252C2.50106 15.4493 2.5 14.4283 2.5 13V11Z"
                fill="#1A47B8" stroke="#58B3D3"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8.00007 19H8.00007H16.0001C18.8285 19 20.2427 19 21.1214 18.1213C21.8287 17.414 21.9667 16.3597 21.9936 14.5H2.00659C2.03349 16.3597 2.17144 17.414 2.87875 18.1213C3.75743 19 5.17165 19 8.00007 19Z"
                  fill="#F93939"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M16.0001 5H8.00007C5.17165 5 3.75743 5 2.87875 5.87868C2.17144 6.58599 2.03349 7.64032 2.00659 9.5H21.9936C21.9667 7.64032 21.8287 6.58599 21.1214 5.87868C20.2427 5 18.8285 5 16.0001 5Z"
                  fill="white"/>
        </svg>

    );
};

export default IconRussian;
