import React from 'react';

const IconArrowLeft = ({width = 24, height = 24, color = "none"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M17 12H7M7 12L11 16M7 12L11 8" stroke="#58B3D3" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>

    );
};

export default IconArrowLeft;
