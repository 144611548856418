import React, {Fragment, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {selectAccountRolePermissions} from "../../../store/reducers/role/roleReducer";
import checkPermission from "../../../enum/permission/CheckPermission";
import {PermissionRoamingShipmentView} from "../../../enum/permission/RoamingPermission";
import EventBus from "../../../eventbus/EventBus";
import {
    CARRIER_CANCEL_WAYBILL_SUCCEED, CUSTOMER_ACCEPT_WAYBILL_SUCCEED,
    CUSTOMER_DECLINE_WAYBILL_SUCCEED
} from "../../../eventbus/romaing/waybill/roamingWaybillEvents";
import toast from "react-hot-toast";
import {getOrganizationBillingInfoAsync} from "../../../store/reducers/billing/billingReducer";
import {loadWaybillAsync} from "../../../store/reducers/roaming/waybill/roamingWaybillReducer";
import SpinnerComponent from "../../../components/common/SpinnerComponent";
import Waybill from "../../../components/roaming/waybill/view/Waybill";

const ViewWaybill = ({modalId}) => {
    const {id} = useParams();
    const [waybill, setWaybill] = useState(null);
    const activeOrganization = useSelector(selectActiveOrganization);
    const dispatch = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;

    const navigate = useNavigate();
    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingShipmentView))) {
            navigate('/roaming/waybills/inbox')
        }
    }, [permission])

    useEffect(() => {
        loadWaybill();
    }, [modalId, id, activeOrganization])

    useEffect(() => {
        const onCarrierCancelWaybillSucceedHandler = EventBus.on(CARRIER_CANCEL_WAYBILL_SUCCEED, () => {
            toast.success(t(lang, 'roaming.waybill.cancel_waybill.success.toast'));
            loadWaybill();
        });

        const onCustomerDeclineWaybillSucceedHandler = EventBus.on(CUSTOMER_DECLINE_WAYBILL_SUCCEED, () => {
            toast.success(t(lang, 'roaming.waybill.decline_waybill.success.toast'));
            loadWaybill();
        });

        const onCustomerAcceptWaybillSucceedHandler = EventBus.on(CUSTOMER_ACCEPT_WAYBILL_SUCCEED, () => {
            dispatch(getOrganizationBillingInfoAsync());
            toast.success(t(lang, 'roaming.waybill.accept_waybill.success.toast'));
            loadWaybill();
        });

        return () => {
            EventBus.remove(CARRIER_CANCEL_WAYBILL_SUCCEED, onCarrierCancelWaybillSucceedHandler);
            EventBus.remove(CUSTOMER_DECLINE_WAYBILL_SUCCEED, onCustomerDeclineWaybillSucceedHandler);
            EventBus.remove(CUSTOMER_ACCEPT_WAYBILL_SUCCEED, onCustomerAcceptWaybillSucceedHandler);
        }
    }, [])

    const loadWaybill = () => {
        loadWaybillAsync(modalId ? modalId : id).then(loadedWaybill => {
            setWaybill(loadedWaybill)
        }).catch(error => console.log(error))
    }

    return (
        <Fragment>
            {waybill && <Waybill waybill={waybill} />}
            {!waybill && <SpinnerComponent />}
        </Fragment>
    );
};

export default ViewWaybill;
