import {createSlice} from "@reduxjs/toolkit";
import returnShipmentService from "../../../services/edi/return-shipment/returnShipmentService";
import EventBus from "../../../../eventbus/EventBus";
import {
    CUSTOMER_APPROVE_RETURN_SHIPMENT_FAILED,
    CUSTOMER_APPROVE_RETURN_SHIPMENT_REQUESTED,
    CUSTOMER_APPROVE_RETURN_SHIPMENT_SUCCESS,
    DELETE_RETURN_SHIPMENT_FAILED,
    DELETE_RETURN_SHIPMENT_REQUESTED, DELETE_RETURN_SHIPMENT_SUCCESS,
    EXECUTOR_APPROVE_RETURN_SHIPMENT_FAILED,
    EXECUTOR_APPROVE_RETURN_SHIPMENT_REQUESTED,
    EXECUTOR_APPROVE_RETURN_SHIPMENT_SUCCESS,
    REGISTER_RETURN_SHIPMENT_FAILED,
    REGISTER_RETURN_SHIPMENT_REQUESTED,
    REGISTER_RETURN_SHIPMENT_SUCCEED
} from "../../../../eventbus/edi/return-shipment/returnShipmentEvents";
export const STORAGE_RETURN_SHIPMENT = "ReturnShipmentDataTableSettings"

const initialState = {
    cars: [],
    drivers: [],
    carSerials: [],
    returnShipments: [],
    countReturnShipments: 0,
    filterOptions: {page: 1, limit: 10},
    returnShipmentDataTableSettings: {}
}
const returnShipmentReducer = createSlice({
    name: "return-shipment",
    initialState,
    reducers:{
        updateReturnShipments: (state, action) => {
            state.returnShipments = action.payload
        },
        updateCountReturnShipments: (state, action) => {
            state.countReturnShipments = action.payload
        },
        updateFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },

        loadProviders: (state) => {
            const storage = JSON.parse(localStorage.getItem('returnShipmentProvidersInfo'));

            if (storage?.['drivers']) {
                state.drivers = storage['drivers'];
            }

            if (storage?.['cars']) {
                state.cars = storage['cars'];
            }

            if (storage?.['carSerials']) {
                state.carSerials = storage['carSerials'];
            }
        },


        saveProviders: (state) => {
            const data = {
                cars: state.cars,
                drivers: state.drivers,
                carSerials: state.carSerials,
            };
            localStorage.setItem('returnShipmentProvidersInfo', JSON.stringify(data))
        },

        loadReturnShipmentDataTableColumn: (state) => {
            let storageColumn = JSON.parse(localStorage.getItem(STORAGE_RETURN_SHIPMENT))
            if(!storageColumn) {
                storageColumn = {
                    index: true,
                    number: true,
                    date: true,
                    total_sum: true,
                    branch: true,
                    contractor: true,
                };
                localStorage.setItem(STORAGE_RETURN_SHIPMENT, JSON.stringify(storageColumn))
            }
            state.returnShipmentDataTableSettings = storageColumn
        },

        changeReturnShipmentDataTableColumn: (state, action) => {
            state.returnShipmentDataTableSettings = action.payload
            localStorage.setItem(STORAGE_RETURN_SHIPMENT, JSON.stringify(state.returnShipmentDataTableSettings))
        },
    }
})

export const loadReturnShipments = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    returnShipmentService.getReturnShipments(params)
        .then(response => {
            dispatch(returnShipmentReducer.actions.updateReturnShipments(response.data))
        })
        .catch(error => console.log(error))
}

export const loadReturnShipmentAsync = (id) => {
    return new Promise((resolve, reject) => {
        returnShipmentService.getReturnShipment(id)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    })
}

export const returnShipmentCustomerApproveAsync = ({id, comment}) => {
    EventBus.dispatch(CUSTOMER_APPROVE_RETURN_SHIPMENT_REQUESTED, {id, comment})

    return new Promise((resolve, reject) => {
        returnShipmentService.returnShipmentCustomerApprove({id, comment})
            .then(response => {
                EventBus.dispatch(CUSTOMER_APPROVE_RETURN_SHIPMENT_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(CUSTOMER_APPROVE_RETURN_SHIPMENT_FAILED, error)
                reject(error)
            })
    });
}

export const returnShipmentExecutorApproveAsync = ({id, comment}) => {
    EventBus.dispatch(EXECUTOR_APPROVE_RETURN_SHIPMENT_REQUESTED, {id, comment});

    return new Promise((resolve, reject) => {
        returnShipmentService.returnShipmentExecutorApprove({id, comment})
            .then((response) => {
                EventBus.dispatch(EXECUTOR_APPROVE_RETURN_SHIPMENT_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(EXECUTOR_APPROVE_RETURN_SHIPMENT_FAILED, error);
                reject(error)
            });
    });
};

export const loadCountReturnShipments = (params) => (dispatch) => {
    let {limit, page, ...others} = params
    returnShipmentService.getCountReturnShipments(others)
        .then(response => {
            dispatch(returnShipmentReducer.actions.updateCountReturnShipments(response.data))
        })
        .catch(error => console.log(error))
}

export const changeFilterOptionsAsync = (params) => (dispatch) => {
    return dispatch(returnShipmentReducer.actions.updateFilterOptions(params))
}

export const registerReturnShipmentAsync = (payload) => {
    EventBus.dispatch(REGISTER_RETURN_SHIPMENT_REQUESTED);

    return new Promise((resolve, reject) => {
        returnShipmentService.registerReturnShipment(payload)
            .then(response => {
                EventBus.dispatch(REGISTER_RETURN_SHIPMENT_SUCCEED, response.data);
                resolve(response.data)
            }).catch(error => {
            EventBus.dispatch(REGISTER_RETURN_SHIPMENT_FAILED, error)
            reject(error)
        })
    })
}

export const deleteReturnShipmentAsync = ({id, comment}) => {
    EventBus.dispatch(DELETE_RETURN_SHIPMENT_REQUESTED, {id, comment});

    return new Promise((resolve, reject) => {
        returnShipmentService.deleteReturnShipment({id, comment})
            .then(response => {
                EventBus.dispatch(DELETE_RETURN_SHIPMENT_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(DELETE_RETURN_SHIPMENT_FAILED, error.message);
                reject(error);
            })
    })
};

export const printReturnShipmentAsync = (id) => {
    return new Promise((resolve, reject) => {
        returnShipmentService.printReturnShipment(id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export const uploadReturnShipmentToExcelAsync = ({uri, id}) => {
    return new Promise((resolve, reject) => {
        returnShipmentService.uploadReturnShipmentToExcel({uri, id})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
};
export const uploadReturnShipmentsToExcelAsync = ({uri, page, limit, ...filters}) => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};

    return new Promise((resolve, reject) => {
        returnShipmentService.uploadReturnShipmentsToExcel({params: params, uri: uri})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
};
export const uploadReturnShipmentsWithItemsToExcelAsync = ({uri, page, limit, ...filters}) => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};

    return new Promise((resolve, reject) => {
        returnShipmentService.uploadReturnShipmentsWithItemsToExcel({params: params, uri: uri})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
};

export const returnShipmentLoadProviders = returnShipmentReducer.actions.loadProviders;
export const returnShipmentSaveProviders = returnShipmentReducer.actions.saveProviders;

export const selectReturnShipmentDataTable = (state) => state.returnShipment.returnShipmentDataTableSettings

export const {loadReturnShipmentDataTableColumn,changeReturnShipmentDataTableColumn} = returnShipmentReducer.actions

export const selectReturnShipmentCarOptions = (state) => state.returnShipment.cars;
export const selectReturnShipmentDriverOptions = (state) => state.returnShipment.drivers;
export const selectReturnShipmentCarSerialOptions = (state) => state.returnShipment.carSerials;

export const selectReturnShipments = (state) => state.returnShipment.returnShipments
export const selectCountReturnShipments = (state) => state.returnShipment.countReturnShipments
export const selectFilterOptions = (state) => state.returnShipment.filterOptions
export default returnShipmentReducer.reducer
