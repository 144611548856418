import React, {useEffect, useRef, useState} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import ReactSelect from "react-select";
import {selectDistricts} from "../../../store/reducers/roaming/roamingReferenceReducer";
import CustomFloatingLabelSelect from "../../floating-custom-select/CustomFloatingLabelSelect";

const RoamingInvoiceFormDistrictSelector = ({defaultDistrictId, onChange, selectProps, label}) => {
    const districts = useSelector(selectDistricts);
    const [value, setValue] = useState(null);

    useEffect(() => {
        setValue(districts.find(d => d.districtId === defaultDistrictId))
    }, [defaultDistrictId]);
    const handleChange = (option) => {
        onChange(option)
    }

    return (
        <div className={'select'}>
            <CustomFloatingLabelSelect
                options={districts}
                isClearable={true}
                isSearchable={!defaultDistrictId}
                label={label}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.districtId}
                value={value}
                onChange={option => handleChange(option ? option.districtId : null)}
                {...selectProps}
            />
        </div>

    );
};

export default RoamingInvoiceFormDistrictSelector;
