import React, {Fragment, useEffect, useState} from 'react';
import {RESPONSIBLE_PERSON, VIEW_DRAFTS, VIEW_INBOX, VIEW_OUTBOX} from "../../../enum/roaming/waybill/WaybillStatus";
import {useDispatch, useSelector} from "react-redux";
import {Controller, useFormContext} from "react-hook-form";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {selectDateFormat, selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {getOrganizationInfoAsync, loadRoamingBranchesAsync} from "../../../store/reducers/roaming/roamingReducer";
import {Card, Col, Collapse, Row, Form, FloatingLabel, Button} from "react-bootstrap";
import DatePickerComponent from "../../common/DatePickerComponent";
import FindCompanySearchable from "../../organization/FindCompanySearchable";
import CustomFloatingLabelSelect from "../../floating-custom-select/CustomFloatingLabelSelect";
import {
    deliveryTypeOptions,
    transportTypeOptions
} from "../../../store/reducers/roaming/waybill-v2/romaingWaybillV2Reducer";

const WaybillV2DatatableFilter = ({viewer, isCollapsed}) => {

    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const dateFormat = useSelector(selectDateFormat);
    const activeOrganization = useSelector(selectActiveOrganization);

    const {register, setValue} = useFormContext()

    const [customer, setCustomer] = useState(null);
    const [executor, setExecutor] = useState(null);
    const [customerBranches, setCustomerBranches] = useState([]);
    const [executorBranches, setExecutorBranches] = useState([]);
    const [customerBranch, setCustomerBranch] = useState(null);
    const [executorBranch, setExecutorBranch] = useState(null);

    const showExecutorFilter = viewer === VIEW_INBOX || viewer === RESPONSIBLE_PERSON;
    const showCustomerFilter = viewer === VIEW_OUTBOX || viewer === RESPONSIBLE_PERSON || viewer === VIEW_DRAFTS;
    const showResponsiblePersonFilter = viewer !== RESPONSIBLE_PERSON;

    // set default executor || customer

    const setDefaultExecutorCustomer = async () => {
        switch (viewer) {
            case VIEW_INBOX:
                setCustomer(await getOrganizationInfoAsync(activeOrganization.pinfl || activeOrganization.inn));
                break;
            case VIEW_OUTBOX:
                setExecutor(await getOrganizationInfoAsync(activeOrganization.pinfl || activeOrganization.inn));
                break;
            case VIEW_DRAFTS:
                setExecutor(await getOrganizationInfoAsync(activeOrganization.pinfl || activeOrganization.inn));
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        setDefaultExecutorCustomer()
    }, [viewer, activeOrganization])

    // set customer_inn and load branches

    const setCustomerLoadBranches = async (branches) => {
        if (customer) {
            setValue('customer_inn', customer.person_num || customer.inn);
            setCustomerBranches(await dispatch(loadRoamingBranchesAsync(customer.person_num || customer.inn)));
        } else {
            setValue('customer_inn', null);
            setCustomerBranches([]);
            setCustomerBranch(null);
        }
    }

    useEffect(() => {
        setCustomerLoadBranches()
    }, [customer])

    // set executor_inn and load branches

    const setExecutorLoadBranches = async (branches) => {
        if (executor) {
            setValue('executor_inn', executor.person_num || executor.inn);
            setExecutorBranches(await dispatch(loadRoamingBranchesAsync(executor.person_num || executor.inn)));
        } else {
            setValue('executor_inn', null);
            setExecutorBranches([]);
            setExecutorBranch(null);
        }
    }

    useEffect(() => {
        setExecutorLoadBranches()
    }, [executor])

    // set customer branch
    useEffect(() => {
        if (customerBranch)
            setValue('customer_branch_code', customerBranch.number);
        else
            setValue('customer_branch_code', null);
    }, [customerBranch])

    // set executor branch
    useEffect(() => {
        if (executorBranch)
            setValue('executor_branch_code', executorBranch.number);
        else
            setValue('executor_branch_code', null);
    }, [executorBranch])

    return (
        <Fragment>
            <Collapse in={isCollapsed}>
                <div>
                    <Card className={'p-1'}>
                        <Row className='mb-3 g-3'>
                            <Form.Group as={Col} md={12} lg={3}>
                                <Controller
                                    name="date_start"
                                    render={({field}) => (
                                        <DatePickerComponent
                                            field={field}
                                            isClearable
                                            placeholderText={t(lang, "admin.common.payments.datatable.navigation.filter.date_start")}
                                            dateFormat={dateFormat}
                                        />
                                    )}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={12} lg={3}>
                                <Controller
                                    name="date_end"
                                    render={({field}) => (
                                        <DatePickerComponent
                                            field={field}
                                            isClearable
                                            placeholderText={t(lang, "admin.common.payments.datatable.navigation.filter.date_end")}
                                            dateFormat={dateFormat}
                                        />
                                    )}
                                />
                            </Form.Group>

                            {showCustomerFilter && <Form.Group as={Col} md={12} lg={3}>
                                <Controller name={'customer_inn'} render={({field}) => (
                                    <FindCompanySearchable
                                        onChange={setCustomer}
                                        placeholder={t(lang, "roaming.common.customer")}
                                    />
                                )}/>
                            </Form.Group>}

                            {showExecutorFilter && <Form.Group as={Col} md={12} lg={3}>
                                <Controller name={'executor_inn'} render={({field}) => (
                                    <FindCompanySearchable
                                        onChange={setExecutor}
                                        placeholder={t(lang, "roaming.common.executor")}
                                    />
                                )}/>
                            </Form.Group>}

                            {showResponsiblePersonFilter && <Form.Group as={Col} md={12} lg={3}>
                                <Controller name={'responsible_person_inn_or_pinfl'} render={({field}) => (
                                    <FindCompanySearchable
                                        onChange={(option) => field.onChange(option?.inn || option?.pinfl)}
                                        placeholder={t(lang, "roaming.waybill.v2.send.responsible_person")}
                                    />
                                )}/>
                            </Form.Group>}

                            <Form.Group as={Col} md={12} lg={3}>
                                <Controller
                                    name="created_at_start"
                                    render={({field}) => (
                                        <DatePickerComponent
                                            field={field}
                                            isClearable
                                            placeholderText={t(lang, "roaming.waybill.v2.datatable.header.filter.created_at_start")}
                                            dateFormat={dateFormat}
                                        />
                                    )}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={12} lg={3}>
                                <Controller
                                    name="created_at_end"
                                    render={({field}) => (
                                        <DatePickerComponent
                                            field={field}
                                            isClearable
                                            placeholderText={t(lang, "roaming.waybill.v2.datatable.header.filter.created_at_end")}
                                            dateFormat={dateFormat}
                                        />
                                    )}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={12} lg={3}>
                                <Controller
                                    name="contract_date"
                                    render={({field}) => (
                                        <DatePickerComponent
                                            field={field}
                                            isClearable
                                            placeholderText={t(lang, "roaming.invoice.mass_add.column.contract_date")}
                                            dateFormat={dateFormat}
                                        />
                                    )}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={12} lg={3}>
                                <FloatingLabel label={t(lang, 'roaming.invoice.mass_add.column.contract_number')}>
                                    <Form.Control
                                        defaultValue={null}
                                        {...register('contract_number')}
                                        placeholder={t(lang, 'roaming.invoice.mass_add.column.contract_number')}
                                    />
                                </FloatingLabel>
                            </Form.Group>

                            <Form.Group as={Col} md={12} lg={3}>
                                <FloatingLabel label={t(lang, "roaming.waybill.send.details_form.shipment_number")}>
                                    <Form.Control
                                        defaultValue={null}
                                        {...register('number')}
                                        placeholder={t(lang, 'roaming.waybill.send.details_form.shipment_number')}
                                    />
                                </FloatingLabel>
                            </Form.Group>

                            <Form.Group as={Col} md={12} lg={3}>
                                <Controller
                                    name="delivery_type"
                                    render={({field}) => (
                                        <CustomFloatingLabelSelect
                                            hideSelectedOptions={true}
                                            options={deliveryTypeOptions}
                                            getOptionValue={option => option.value}
                                            getOptionLabel={option => t(lang, option.label)}
                                            onChange={option => field.onChange(option?.value)}
                                            label={t(lang, 'roaming.waybill.send.delivery_details_form.delivery_type')}
                                        />
                                    )}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={12} lg={3}>
                                <Controller
                                    name="transport_type"
                                    render={({field}) => (
                                        <CustomFloatingLabelSelect
                                            hideSelectedOptions={true}
                                            options={transportTypeOptions}
                                            getOptionValue={option => option.value}
                                            getOptionLabel={option => t(lang, option.text)}
                                            onChange={option => field.onChange(option?.value)}
                                            label={t(lang, 'roaming.waybill.send.delivery_details_transport_type')}
                                        />
                                    )}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={12} lg={3}>
                                <Controller name="executor_branch_code"
                                            render={({field}) => (
                                                <CustomFloatingLabelSelect
                                                    options={executorBranches}
                                                    label={t(lang, "roaming.invoices.datatable.filter.executor_branch_code")}
                                                    noOptionsMessage={() => t(lang, "roaming.invoices.datatable.filter.branches_not_found")}
                                                    hideSelectedOptions
                                                    isClearable
                                                    value={executorBranch}
                                                    onChange={setExecutorBranch}
                                                    getOptionLabel={option => option?.system_code ? `${option.system_name} - (${option.system_code})` : option.name}
                                                    getOptionValue={option => option.number}
                                                />
                                            )}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={12} lg={3}>
                                <Controller name="customer_branch_code"
                                            render={({field}) => (
                                                <CustomFloatingLabelSelect
                                                    options={customerBranches}
                                                    label={t(lang, "roaming.invoices.datatable.filter.customer_branch_code")}
                                                    noOptionsMessage={() => t(lang, "roaming.invoices.datatable.filter.branches_not_found")}
                                                    hideSelectedOptions
                                                    value={customerBranch}
                                                    onChange={setCustomerBranch}
                                                    isClearable
                                                    getOptionLabel={option => option?.system_code ? `${option.system_name} - (${option.system_code})` : option.name}
                                                    getOptionValue={option => option.number}
                                                />
                                            )}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md={12} lg={9} className="d-flex align-items-end justify-content-end">
                                <Button className={'btn-primary-davr'} variant="phoenix-secondary" type='submit'>{t(lang, "edi.return_invoice.datatable.header.filter.show")}</Button>
                            </Form.Group>
                        </Row>
                    </Card>

                </div>
            </Collapse>

        </Fragment>
    );
};

export default WaybillV2DatatableFilter;
