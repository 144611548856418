import React from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {useFormContext} from "react-hook-form";
import {Form} from "react-bootstrap";

const RoleForm = () => {
    const {register, formState: {errors}} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;
    return (
        <Form.Group className="mb-3">
            <Form.Label>{t(lang, "hippo.user.role.form.role_name")}</Form.Label>
            <Form.Control
                isInvalid={errors?.Name?.message}
                placeholder={t(lang, "hippo.user.role.form.role_name")}
                type="text"
                {...register('Name', {
                    required: t(lang, 'edi.common.forms.validations.is_required')
                })}
            />
            <Form.Control.Feedback type='invalid'>
                {errors?.Name && errors.Name.message}
            </Form.Control.Feedback>
        </Form.Group>
    );
};

export default RoleForm;
