export const AwaitCustomerApprove = 0
export const AwaitExecutorApprove = 1
export const ExecutorEdited = 2
export const ExecutorDeclined = 3
export const ExecutorApproved = 4
export const Stopped = 5
export const Finished = 6
export const Closed = 7
export const Expired = 8
export const InProgress = 9


export const setVehiclesInfoLocalStorage = (localStorageOptions, vehicleInto) => {
    localStorageOptions.filter((driver) => {
        const duplicateIndex = vehicleInto?.filter((d) => d?.label || d?.is_active).findIndex((d) => d.label === driver.label);
        if (duplicateIndex === -1) {
            vehicleInto.push({
                label: driver.label,
                is_active: driver.lastSelected
            });
            return true;
        }
        return false;
    });

    return  vehicleInto
}

export const getStatusColor = (status) => {
    switch (status) {
        case AwaitExecutorApprove:
            return {
                bg: 'secondary',
            }
        case ExecutorEdited:
            return {
                bg: 'warning',
                variant: 'phoenix'
            }
        case ExecutorDeclined:
            return {
                bg: 'danger',
            }
        case ExecutorApproved:
            return {
                bg: 'primary',
                variant: 'phoenix'
            }
        case Stopped:
            return {
                bg: 'danger',
                variant: 'phoenix'
            }
        case Finished:
            return {
                bg: 'success',
                variant: 'phoenix'
            }
        case Closed:
            return {
                bg: 'info',
                variant: 'phoenix'
            }
        case Expired:
            return {
                bg:'warning',
            }
        case InProgress:
            return {
                bg: 'success',
                color: 'phoenix'
            }
        default:
            return {
                bg: 'secondary',
                variant: 'phoenix'
            }
    }
}

export const GetStatusText = (status) => {
    switch (status) {
        case AwaitExecutorApprove:
            return 'edi.order.status.await_customer_approve' // 'Подтверждён заказчиком'
        case ExecutorEdited:
            return 'edi.order.status.executor_edited' // 'Изменён поставщиком'
        case ExecutorDeclined:
            return 'edi.order.status.executor_declined' // 'Отклонён поставщиком'
        case ExecutorApproved:
            return 'edi.order.status.executor_approved' // 'Подтверждён поставщиком'
        case Stopped:
            return 'edi.order.status.stopped' // 'Приостановлен поставщиком'
        case Finished:
            return 'edi.order.status.finished' // 'Выполнен'
        case Closed:
            return 'edi.order.status.closed' // 'Закрыт'
        case Expired:
            return 'edi.order.status.expired' // 'Просрочен'
        case InProgress:
            return 'edi.order.status.in_progress' // 'Поставка'
        default:
            return 'edi.order.status.default' // 'Не подтверждён'
    }
}


class OrderWrapper {
    isStopOrderAllowed(status) {
        switch (status) {
            case ExecutorApproved:
            case InProgress:
                return true;
            default:
                return false;
        }
    }
}
const OrderStatus = (id, name) => {
    return {
        id,
        name
    }
}

export const OrderStatuses = [
    OrderStatus(AwaitCustomerApprove, GetStatusText(AwaitCustomerApprove)),
    OrderStatus(AwaitExecutorApprove, GetStatusText(AwaitExecutorApprove)),
    OrderStatus(ExecutorEdited, GetStatusText(ExecutorEdited)),
    OrderStatus(ExecutorDeclined, GetStatusText(ExecutorDeclined)),
    OrderStatus(ExecutorApproved, GetStatusText(ExecutorApproved)),
    OrderStatus(Stopped, GetStatusText(Stopped)),
    OrderStatus(Finished, GetStatusText(Finished)),
    OrderStatus(Closed, GetStatusText(Closed)),
    OrderStatus(Expired, GetStatusText(Expired)),
    OrderStatus(InProgress, GetStatusText(InProgress)),
]

const orderWrapper = new OrderWrapper();
export default orderWrapper;
