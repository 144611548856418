import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import PasswordChangeForm from "./PasswordChangeForm";
import {Card} from "react-bootstrap";

const ChangePassword = () => {
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Fragment>
            <h4 className={'fs-8 fw-semi-bold second-text'}>{t(lang, 'edi.user.settings.change_password.title')}</h4>
            <Card.Footer className="bg-card p-2 rounded-2">
                <PasswordChangeForm/>
            </Card.Footer>
        </Fragment>
    );
};

export default ChangePassword;
