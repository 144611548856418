import {createSlice} from "@reduxjs/toolkit";

import BillingService from "../../services/billing/billingService";
import EventBus from "../../../eventbus/EventBus";
import {
    ACTIVATE_DEMO_FAILED,
    ACTIVATE_DEMO_REQUESTED,
    ACTIVATE_DEMO_SUCCEED, ACTIVATE_PACKAGE_FAILED,
    ACTIVATE_PACKAGE_REQUESTED, ACTIVATE_PACKAGE_SUCCEED
} from "../../../eventbus/billingEvents";
import dayjs from "dayjs";
import billingService from "../../services/billing/billingService";

export const EDI_SERVICE_CODE = "edi";
export const POS_SERVICE_CODE = "pos";
export const WAREHOUSE_MANAGEMENT_SERVICE_CODE = "warehouse_management";

const initialState = {
    isLoading:false,
    packages: [],
    organizations: [],
    organizationsCount: 0,
    organizationBankPaymentsError: [],
    organizationBankPaymentsErrorCount: 0,
    isEdiServiceActive: false,
    isWarehouseServiceActive: false,
    isPosServiceActive: false,
    organizationBillingInfo: null,
    filterOptions: {page: 1, limit: 10},
    filterOptionsPayment: {
        page: 1,
        limit: 10,
        created_on_start: dayjs(new Date()).format('YYYY-MM-DD'),
        created_on_end: dayjs(new Date()).format('YYYY-MM-DD'),
    },
    filterOptionsBankPaymentsError: {
        page: 1,
        limit: 10,
        created_on_start: dayjs(new Date()).format('YYYY-MM-DD'),
        created_on_end: dayjs(new Date()).format('YYYY-MM-DD'),
    },
    payments: [],
    paymentsCount: 0,

    // own billing organization

    ownPayments: [],
    ownPaymentsCount: 0,
    paymentFilterOptions: {page: 1, limit: 10},
    boughtPackages: [],
    boughtPackagesCount: 0,
    boughtFilterOptions: {page: 1, limit: 10},
    charges: [],
    chargesCount: 0,
    chargesFilterOptions: {page: 1, limit: 10},
}

const billingSlice = createSlice({
    name: 'billing',
    initialState,
    reducers:{
        updateIsEdiServiceActive: (state, action) => {
            state.isEdiServiceActive = action.payload.active_packages.some(p => p.package.service.code === EDI_SERVICE_CODE);
        },
        updateIsWarehouseServiceActive: (state, action) => {
            state.isWarehouseServiceActive = action.payload.active_packages.some(p => p.package.service.code === WAREHOUSE_MANAGEMENT_SERVICE_CODE)
        },
        updateIsPosServiceActive: (state, action) => {
            state.isPosServiceActive = action.payload.active_packages.some(p => p.package.service.code === POS_SERVICE_CODE)
        },
        updatePackages: (state, action) => {
            state.packages = action.payload;
        },
        updateOrganizationBillingInfo: (state, action) => {
            state.organizationBillingInfo = action.payload;
        },
        updateIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        updateSystemOrganizations: (state, action) => {
            state.organizations = action.payload;
        },
        updateSystemOrganizationsCount: (state, action) => {
            state.organizationsCount = action.payload;
        },
        updateSystemOrganizationsBankPaymentsError: (state, action) => {
            state.organizationBankPaymentsError = action.payload
        },
        updateSystemOrganizationsBankPaymentsErrorCount: (state, action) => {
            state.organizationBankPaymentsErrorCount = action.payload
        },
        updateOrganizationsFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
        updateOrganizationsFilterOptionsPayment: (state, action) => {
            state.filterOptionsPayment = {...state.filterOptionsPayment, ...action.payload}
        },
        clearOrganizationsFilterOptionsPayment: (state, action) => {
            state.filterOptionsPayment = action.payload
        },
        updateOrganizationsFilterOptionBankPaymentsError: (state, action) => {
            state.filterOptionsBankPaymentsError = {...state.filterOptionsBankPaymentsError, ...action.payload}
        },
        clearOrganizationsFilterOptionBankPaymentsError: (state,action) => {
            state.filterOptionsBankPaymentsError = action.payload
        },
        updatePayments: (state, action) => {
            state.payments = action.payload;
        },
        updatePaymentsCount: (state, action) => {
            state.paymentsCount = action.payload;
        },

        // own billing organization

        getOwnPayments: (state, {payload}) => {
            state.ownPayments = payload
        },
        getOwnPaymentsCount: (state, {payload}) => {
            state.ownPaymentsCount = payload
        },
        updateOwnPaymentsFilterOptions: (state, {payload}) => {
            state.paymentFilterOptions = {...state.paymentFilterOptions, ...payload}
        },
        getBoughtPackages: (state, {payload}) => {
            state.boughtPackages = payload
        },
        getBoughtPackagesCount: (state, {payload}) => {
            state.boughtPackagesCount = payload
        },
        updateBoughtFilterOptions: (state, {payload}) => {
            state.boughtFilterOptions = {...state.boughtFilterOptions, ...payload}
        },
        getCharges: (state, {payload}) => {
            state.charges = payload
        },
        getChargesCount: (state, {payload}) => {
            state.chargesCount = payload
        },
        updateOwnChargesFilterOptions: (state, {payload}) => {
            state.chargesFilterOptions = {...state.chargesFilterOptions, ...payload}
        },
        updateOwnChargesAdminFilterOptions: (state, {payload}) => {
            state.adminChargesFilterOptions = {...state.adminChargesFilterOptions, ...payload}
        },
    }
})

export const selectBillingPackages = (state) => state.billing.packages;
export const selectBillingIsLoading = (state) => state.billing.isLoading;
export const selectOrganizationBillingInfo = (state) => state.billing.organizationBillingInfo;
export const selectOrganizationActivePackages = (state) => state.billing.organizationBillingInfo?.active_packages;
export const selectOrganizationServiceDemoUses = (state) => state.billing.organizationBillingInfo?.service_demo_uses || [];
export const selectOrganizationGetPayments = (state) => state.billing.payments
export const selectOrganizationGetPaymentsCount = (state) => state.billing.paymentsCount
export const selectIsEDIServiceActive = (state) => state.billing.isEdiServiceActive;
export const selectIsWarehouseServiceActive = (state) => state.billing.isWarehouseServiceActive;
export const selectIsPosServiceActive = (state) => state.billing.isPosServiceActive;

export const selectSystemOrganizations = (state) => state.billing.organizations;
export const selectSystemOrganizationsCount = (state) => state.billing.organizationsCount;
export const selectSystemOrganizationsBankPaymentsError = (state) => state.billing.organizationBankPaymentsError
export const selectSystemOrganizationBankPaymentsErrorCount = (state) => state.billing.organizationBankPaymentsErrorCount
export const selectSystemOrganizationsFilterOptions = (state) => state.billing.filterOptions;
export const selectSystemOrganizationsFilterOptionsPayment = (state) => state.billing.filterOptionsPayment;
export const selectSystemOrganizationsFilterOptionsBankPaymentsError = (state) => state.billing.filterOptionsBankPaymentsError

// own billing organization selects

export const selectCharges = (state) => state.billing.charges
export const selectChargesCount = (stata) => stata.billing.chargesCount
export const selectChargesFilter = (state) => state.billing.chargesFilterOptions

export const selectOwnPayments = (state) => state.billing.ownPayments
export const selectOwnPaymentsCount = (state) => state.billing.ownPaymentsCount
export const selectOwnPaymentsFilter = (state) => state.billing.paymentFilterOptions

export const selectBoughtPackages = (state) => state.billing.boughtPackages
export const selectBoughtPackagesCount = (state) => state.billing.boughtPackagesCount
export const selectBoughtFilter = (state) => state.billing.boughtFilterOptions

export const getOrganizationBillingInfoAsync = () => (dispatch) => {
    dispatch(billingSlice.actions.updateIsLoading(true));
    console.log('res')

    return new Promise((resolve, reject) => {
        BillingService.getOrganizationBillingInfo()
            .then(response => {
                dispatch(billingSlice.actions.updateOrganizationBillingInfo(response.data));
                dispatch(billingSlice.actions.updateIsEdiServiceActive(response.data));
                dispatch(billingSlice.actions.updateIsWarehouseServiceActive(response.data));
                dispatch(billingSlice.actions.updateIsPosServiceActive(response.data));
                dispatch(billingSlice.actions.updateIsLoading(false));
                resolve(true);
            }).catch(() => true)
    })
}

export const activateDemoPeriodAsync = ({serviceCode}) => {
    EventBus.dispatch(ACTIVATE_DEMO_REQUESTED);

    return new Promise((resolve, reject) => {
        BillingService.activateDemoPeriod({serviceCode})
            .then(response => {
                EventBus.dispatch(ACTIVATE_DEMO_SUCCEED);
                resolve(response.data)
            })
            .catch(error => {
                EventBus.dispatch(ACTIVATE_DEMO_FAILED);
                reject()
            })
    })
}

export const autoActiveExtendPackage = (params) => {
    BillingService.setAutoExtendPackage(params)
        .then(() => null)
        .catch(() => null)
}

export const activatePackageAsync = ({packageId}) => {
    EventBus.dispatch(ACTIVATE_PACKAGE_REQUESTED);

    return new Promise((resolve, reject) => {
        BillingService.activatePackage({packageId})
            .then(response => {
                EventBus.dispatch(ACTIVATE_PACKAGE_SUCCEED);
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(ACTIVATE_PACKAGE_FAILED);
                reject();
            })
    })
}

export const loadChargesAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};
    return new Promise((resolve, reject) => {
        billingService.getCharges(params)
            .then(response => {
                dispatch(billingSlice.actions.getCharges(response.data))
                resolve(response.data)
            })
            .catch(error => {
                reject()
            })
    })
}

export const changeFilterChargesAsync = (params) => (dispatch) => {
    return  dispatch(billingSlice.actions.updateOwnChargesFilterOptions(params))
}
export const changeFilterChargesAdminAsync = (params) => (dispatch) => {
    return  dispatch(billingSlice.actions.updateOwnChargesAdminFilterOptions(params))
}

export const loadChargesCount = (params) => (dispatch) => {
    const {skip, limit, ...filter} = params
    return new Promise((resolve, reject) =>  {
        billingService.getChargesCount(filter)
            .then(response => {
                dispatch(billingSlice.actions.getChargesCount(response.data))
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export const loadBillingPackagesAsync = () => dispatch => {
    return new Promise((resolve, reject) => {
        BillingService.getBillingPackages()
            .then(response => {
                dispatch(billingSlice.actions.updatePackages(response.data));
                resolve();
            }).catch(error => reject(error))
    })
}

export const changeFilterOwnPaymentAsync = (params) => (dispatch) => {
    return  dispatch(billingSlice.actions.updateOwnPaymentsFilterOptions(params))
}

export const loadOwnPayments = ({page,limit}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip,limit}
    return new Promise((resolve, reject) => {
        billingService.getOwnPayments(params)
            .then(response => {
                dispatch(billingSlice.actions.getOwnPayments(response.data))
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export const loadOwnPaymentsCount = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        billingService.getOwnPaymentsCount()
            .then(response => {
                dispatch(billingSlice.actions.getOwnPaymentsCount(response.data))
            })
            .catch(error => {
                reject(error)
            })
    })
}

export const changeFilterBoughtAsync = (params) => (dispatch) => {
    return  dispatch(billingSlice.actions.updateBoughtFilterOptions(params))
}

export const loadBoughtPackages = ({page,limit}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip,limit}
    return new Promise((resolve, reject) => {
        billingService.getBought(params)
            .then(response => {
                dispatch(billingSlice.actions.getBoughtPackages(response.data))
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export const loadBoughtPackagesCount = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        billingService.getBoughtCount()
            .then(response => {
                dispatch(billingSlice.actions.getBoughtPackagesCount(response.data))
            })
            .catch(error => {
                reject(error)
            })
    })
}

export default billingSlice.reducer
