import React, {Fragment, useCallback, useState} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {useForm} from "react-hook-form";
import {StopOrderAsync} from "../../../store/reducers/edi/order/orderReducer";
import ActionProgressBar from "../../common/ActionProgressBar";
import IconButton from "../../IconButton";
import {Button, Form, Modal, Spinner} from 'react-bootstrap';
import PropTypes from "prop-types";
import {faPause} from "@fortawesome/free-solid-svg-icons";

const BulkStopOrder = ({orders, loadOrders}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    // show progress modal
    const [showProgressBar, setShowProgressBar] = useState(false);
    const handleShowProgressBar = () => setShowProgressBar(true);
    const handleCloseProgressBar = () => {
        loadOrders();
        setBulkActionResult([]);
        setShowProgressBar(false);
    };

    // bulk action result
    const [bulkActionResult, setBulkActionResult] = useState([]);
    const updateActionResult = (result) => setBulkActionResult(prev => [...prev, result]);

    const form = useForm({
        defaultValues: {
            comment: ''
        }
    });

    const onSubmit = useCallback( async (formData) => {
        handleClose();
        handleShowProgressBar();
        for (let order of orders) {
            try {
                await StopOrderAsync({
                    id: order.id,
                    comment: formData.comment
                });
                updateActionResult({
                    number: order.info.number,
                    date: order.info.date,
                    contractor: {
                        name: order.executor.name,
                        inn: order.executor.inn
                    }
                });
            } catch (error) {
                updateActionResult({
                    number: order.info.number,
                    date: order.info.date,
                    contractor: {
                        name: order.executor.name,
                        inn: order.executor.inn
                    },
                    error: error
                })            }
        }
    }, [orders])

    return (
        <React.Fragment>
            <ActionProgressBar show={showProgressBar}
                               result={bulkActionResult}
                               count={orders.length}
                               onClose={handleCloseProgressBar}
            />
            <IconButton size="sm"
                        onClick={handleShow}
                        variant="phoenix-danger"
                        iconClassName="me-1"
                        icon={faPause}
                        className="m-1 fw-semi-bold"
            >
                {t(lang, 'edi.orders.order.stop_order_button')}
            </IconButton>
            {show &&
                <Modal size="md" show={true} onHide={handleClose}>
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>{t(lang, "edi.orders.order.stop_order_modal.title")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>{t(lang, 'edi.common.label.comment')}</Form.Label>
                                <Form.Control as="textarea" rows={3}
                                              placeholder={t(lang, 'edi.common.leave_comment')}
                                              {...form.register('comment')}
                                />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                {t(lang, "edi.common.cancel_button")}
                            </Button>
                            <Button variant="danger"
                                    className="d-flex align-items-center"
                                    disabled={form.formState.isSubmitting}
                                    onClick={form.handleSubmit(onSubmit)}>
                                {form.formState.isSubmitting &&
                                    <Spinner className="me-1" animation="border" role="status" size="sm"/>}
                                {t(lang, "edi.orders.order.stop_order_button")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            }
        </React.Fragment>
    );
};

export default BulkStopOrder;

BulkStopOrder.propTypes = {
    orders: PropTypes.array
};

BulkStopOrder.defaultProps = {
    orders: []
};
