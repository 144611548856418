import {createSlice} from "@reduxjs/toolkit";
import organizationBindingService from "../../services/organizationBinding/organizationBinding";
import referenceDataAggregatorService
    from "../../services/referenceDataAggregator/referenceDataAggregatorService";


const initialState = {
    ownerBindingsLoading: false,
    ownerBindings: [],
    ownerBindingsFilter: {},
    ownerBindingsPagination: {page: 1, limit: 10},
    supervisorBindingsLoading: false,
    supervisorBindings: [],
    supervisorBindingsFilter: {},
    supervisorBindingsPagination: {page: 1, limit: 10},
    supervisorItemsLoading: false,
    supervisorItems: [],
    supervisorItemsCategories: [],
    supervisorItemsFilter: {
        searchTerm: '',
        categoryId: null
    },
    supervisorItemsPagination: {page: 1, limit: 10},
    supervisorSalesLoading: false,
    supervisorSalesCount: 0,
    supervisorSales: [],
    supervisorSalesFilter: {
        dateStart: new Date().getTime(),
        dateEnd: new Date().getTime(),
    },
    supervisorSalesPagination: {page: 1, limit: 10},
    supervisorSaleLoading: false,
    supervisorSale: null
};

const organizationBindingSlice = createSlice({
    name: 'organizationBinding',
    initialState,
    reducers: {
        setOwnerBindingsLoading: (state, action) => {
            state.ownerBindingsLoading = action.payload;
        },
        setOwnerBindings: (state, action) => {
            state.ownerBindings = action.payload;
        },
        setOwnerBindingsFilter: (state, action) => {
            state.ownerBindingsFilter = action.payload;
        },
        setOwnerBindingsPagination: (state, action) => {
            state.ownerBindingsPagination = action.payload;
        },
        setSupervisorBindingsLoading: (state, action) => {
            state.supervisorBindingsLoading = action.payload;
        },
        setSupervisorBindings: (state, action) => {
            state.supervisorBindings = action.payload;
        },
        setSupervisorBindingsFilter: (state, action) => {
            state.supervisorBindingsFilter = action.payload;
        },
        setSupervisorBindingsPagination: (state, action) => {
            state.supervisorBindingsPagination = action.payload;
        },
        setSupervisorItemsLoading: (state, action) => {
            state.supervisorItemsLoading = action.payload;
        },
        setSupervisorItems: (state, action) => {
            state.supervisorItems = action.payload;
        },
        setSupervisorItemsCategories: (state, action) => {
            state.supervisorItemsCategories = action.payload;
        },
        setSupervisorItemsFilter: (state, action) => {
            state.supervisorItemsFilter = action.payload;
        },
        setSupervisorItemsPagination: (state, action) => {
            state.supervisorItemsPagination = action.payload;
        },
        setSupervisorSalesLoading: (state, action) => {
            state.supervisorSalesLoading = action.payload;
        },
        setSupervisorSalesCount: (state, action) => {
            state.supervisorSalesCount = action.payload;
        },
        setSupervisorSales: (state, action) => {
            state.supervisorSales = action.payload;
        },
        setSupervisorSalesFilter: (state, action) => {
            state.supervisorSalesFilter = action.payload;
        },
        setSupervisorSalesPagination: (state, action) => {
            state.supervisorSalesPagination = action.payload;
        },
        setSupervisorSaleLoading: (state, action) => {
            state.supervisorSaleLoading = action.payload;
        },
        setSupervisorSale: (state, action) => {
            state.supervisorSale = action.payload;
        }
    }
})
export default organizationBindingSlice.reducer;

export const selectOwnerBindingsLoading = state => state.organizationBinding.ownerBindingsLoading;
export const selectOwnerBindings = state => state.organizationBinding.ownerBindings;
export const selectOwnerBindingsFilter = state => state.organizationBinding.ownerBindingsFilter;
export const selectOwnerBindingsPagination = state => state.organizationBinding.ownerBindingsPagination;
export const selectSupervisorBindingsLoading = state => state.organizationBinding.supervisorBindingsLoading;
export const selectSupervisorBindings = state => state.organizationBinding.supervisorBindings;
export const selectSupervisorBindingsFilter = state => state.organizationBinding.supervisorBindingsFilter;
export const selectSupervisorBindingsPagination = state => state.organizationBinding.supervisorBindingsPagination;

export const selectSupervisorItemsLoading = state => state.organizationBinding.supervisorItemsLoading;
export const selectSupervisorItems = state => state.organizationBinding.supervisorItems;
export const selectSupervisorItemsCategories = state => state.organizationBinding.supervisorItemsCategories;
export const selectSupervisorItemsFilter = state => state.organizationBinding.supervisorItemsFilter;
export const selectSupervisorItemsPagination = state => state.organizationBinding.supervisorItemsPagination;

export const selectSupervisorSalesCount = state => state.organizationBinding.supervisorSalesCount;
export const selectSupervisorSalesLoading = state => state.organizationBinding.supervisorSalesLoading;
export const selectSupervisorSales = state => state.organizationBinding.supervisorSales;
export const selectSupervisorSalesFilter = state => state.organizationBinding.supervisorSalesFilter;
export const selectSupervisorSalesPagination = state => state.organizationBinding.supervisorSalesPagination;
export const selectSupervisorSaleLoading = state => state.organizationBinding.supervisorSaleLoading;
export const selectSupervisorSale = state => state.organizationBinding.supervisorSale;


export const setOwnerBindingsFilter = (params) => (dispatch) => {
    dispatch(organizationBindingSlice.actions.setOwnerBindingsFilter(params))
};
export const setOwnerBindingsPagination = (params) => (dispatch) => {
    dispatch(organizationBindingSlice.actions.setOwnerBindingsPagination(params))
};

export const setSupervisorBinding = (id) => (dispatch) => {}
export const setSupervisorBindingsPagination = (params) => (dispatch) => {
    dispatch(organizationBindingSlice.actions.setSupervisorBindingsPagination(params))
};
export const setSupervisorBindingsFilter = (params) => (dispatch) => {
    dispatch(organizationBindingSlice.actions.setSupervisorBindingsFilter(params))
};
export const setSupervisorItemsPagination = (params) => (dispatch) => {
    dispatch(organizationBindingSlice.actions.setSupervisorItemsPagination(params));
};
export const setSupervisorItemsFilter = (params) => (dispatch) => {
    dispatch(organizationBindingSlice.actions.setSupervisorItemsFilter(params));
}

export const setSupervisorSalesFilter = (params) => (dispatch) => {
    dispatch(organizationBindingSlice.actions.setSupervisorSalesFilter(params));
};
export const setSupervisorSalesPagination = (params) => (dispatch) => {
    dispatch(organizationBindingSlice.actions.setSupervisorSalesPagination(params));
};

export const register = (payload) => {
    return new Promise((resolve, reject) => {
        organizationBindingService.register(payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
};
export const edit = (id, payload) => {
    return new Promise((resolve, reject) => {
        organizationBindingService.edit(id, payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
};

export const loadOwnerBindings = (params) => (dispatch) => {
    dispatch(organizationBindingSlice.actions.setOwnerBindingsLoading(true));
    organizationBindingService.getOwnerBindings(params)
        .then((response) => {
            dispatch(organizationBindingSlice.actions.setOwnerBindings(response.data));
            dispatch(organizationBindingSlice.actions.setOwnerBindingsLoading(false));
        })
        .catch((error) => {
            dispatch(organizationBindingSlice.actions.setOwnerBindings([]));
            dispatch(organizationBindingSlice.actions.setOwnerBindingsLoading(false));
        })
};
export const loadSupervisorBindings = (params) => (dispatch) => {
    dispatch(organizationBindingSlice.actions.setSupervisorBindingsLoading(true));
    organizationBindingService.getSupervisorBindings(params)
        .then((response) => {
            dispatch(organizationBindingSlice.actions.setSupervisorBindings(response.data));
            dispatch(organizationBindingSlice.actions.setSupervisorBindingsLoading(false));
        })
        .catch((error) => {
            dispatch(organizationBindingSlice.actions.setSupervisorBindings([]));
            dispatch(organizationBindingSlice.actions.setSupervisorBindingsLoading(false));
        })
};
export const bindItem = (payload) => {
    return new Promise((resolve, reject) => {
        organizationBindingService.bindItem(payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
};
export const bindWarehouse = (payload) => {
    return new Promise((resolve, reject) => {
        organizationBindingService.bindWarehouse(payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
};
export const loadOrganizationBinding = (id) => {
    return new Promise((resolve, reject) => {
        organizationBindingService.getOrganizationBinding(id)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
};
export const loadSupervisorItems = (id, params) => (dispatch) => {
    dispatch(organizationBindingSlice.actions.setSupervisorItemsLoading(true));
    referenceDataAggregatorService.getOrganizationBindingItems(id, params)
        .then((response) => {
            dispatch(organizationBindingSlice.actions.setSupervisorItems(response.data.data));
            dispatch(organizationBindingSlice.actions.setSupervisorItemsCategories(response.data.categories));
            dispatch(organizationBindingSlice.actions.setSupervisorItemsLoading(false));
        })
        .catch((error) => {
            dispatch(organizationBindingSlice.actions.setSupervisorItems([]));
            dispatch(organizationBindingSlice.actions.setSupervisorItemsCategories([]));
            dispatch(organizationBindingSlice.actions.setSupervisorItemsLoading(false));
        })
};
export const loadSupervisorSales = (id, params) => (dispatch) => {
    dispatch(organizationBindingSlice.actions.setSupervisorSalesLoading(true));
    referenceDataAggregatorService.getOrganizationBindingSales(id, params)
        .then((response) => {
            dispatch(organizationBindingSlice.actions.setSupervisorSales(response.data));
            dispatch(organizationBindingSlice.actions.setSupervisorSalesLoading(false));
        })
        .catch((error) => {
            dispatch(organizationBindingSlice.actions.setSupervisorSales([]));
            dispatch(organizationBindingSlice.actions.setSupervisorSalesLoading(false));
        })
};
export const loadSupervisorSalesCount = (id, params) => (dispatch) => {
    referenceDataAggregatorService.getOrganizationBindingSalesCount(id, params)
        .then((response) => {
            dispatch(organizationBindingSlice.actions.setSupervisorSalesCount(response.data));
        })
        .catch((error) => {
            dispatch(organizationBindingSlice.actions.setSupervisorSalesCount(0));
        })
}

export const loadSupervisorSale = (bindingId, saleId) => {
    return new Promise((resolve, reject) => {
        referenceDataAggregatorService.getOrganizationBindingSale(bindingId,saleId)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
};
