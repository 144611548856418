import React from 'react';

const IconCancel = ({width = 24, height = 24, color = "none"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
                fill="#FF3B30"/>
            <path d="M9.99999 9.99999L8.00001 8.00001M8.00001 8.00001L6 6M8.00001 8.00001L10 6M8.00001 8.00001L6 10"
                  stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default IconCancel;
