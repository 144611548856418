import React, {cloneElement, Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useFormContext} from "react-hook-form";
import {
    activateCertificateAsync,
    selectCurrentCertificate,
    setCurrentCertificate
} from "../../store/reducers/eimzo/eimzoReducer";
import {selectLang} from "../../store/reducers/main/main";
import {Translate} from "../../lang/lang";
import checkPermission from "../../enum/permission/CheckPermission";
import toastPermission from "../../enum/permission/ToastPermission";
import toast from "react-hot-toast";
import {Button, Modal} from "react-bootstrap";
import SelectCertificate from "../eimzo/SelectCertificate";

const SignActionWithSelectCertificateWrapper = ({children, isForm, permission}) => {

    const currentCertificate = useSelector(selectCurrentCertificate);
    const methodsContext = useFormContext();
    const [show, setShow] = useState(false);
    const { props, ...element} = children
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;
    const handleClose = () => setShow(false);

    const handleShow = () => {
        if (!isForm) {
            setShow(true)
        }
    };

    useEffect(() => {
        if (isForm && !Object.keys(methodsContext?.formState?.errors).length && methodsContext?.formState?.submitCount && !currentCertificate?.keyId) {
            setShow(true)
        }
    }, [methodsContext?.formState?.submitCount]);

    const onSelectCertificate = certificate => {
        activateCertificateAsync(certificate)
            .then(activatedCertificate => {
                dispatch(setCurrentCertificate(activatedCertificate));
                setShow(false);
                toast.success('Certificate success activated');
            })
            .catch(e => {
                toast.error('Error')
                console.log(e)
            });
    }

    return (
        <Fragment>
            { currentCertificate ?
                children :
                cloneElement(element,
                    {...props, onClick: handleShow, disabled: false},
                )
            }

            <Modal backdrop="static" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, 'roaming.components.select_certificate.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SelectCertificate
                        onChange={onSelectCertificate}
                        placeholder={t(lang, 'roaming.components.select_certificate.title')}
                    />
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button onClick={handleClose}>{t(lang, 'edi.common.button.close')}</Button>
                    {children}
                </Modal.Footer>
            </Modal>

        </Fragment>
    );
};

export default SignActionWithSelectCertificateWrapper;
