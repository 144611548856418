import React, {Fragment} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import FindCompanySearchable from "../../organization/FindCompanySearchable";
import {Form} from "react-bootstrap";
import PropTypes from "prop-types";

const RoamingInvoiceFormItemRowCommittent = ({itemFieldName, item}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();

    const onChangeCompany = (company) => {
        if (company) {
            form.setValue(`${itemFieldName}.committentName`, company.name);
            form.setValue(`${itemFieldName}.committentVatRegCode`, company.vat_reg_code);
            form.setValue(`${itemFieldName}.committentVatRegStatus`, company.vat_rate_status);
        } else {
            form.setValue(`${itemFieldName}.committentName`, '');
            form.setValue(`${itemFieldName}.committentVatRegCode`, '');
            form.setValue(`${itemFieldName}.committentVatRegStatus`, '');
        }
    };
    return (
        <Fragment>
            <td style={{minWidth: '200px', width: '200px'}} className="p-0">
                <Controller
                    name={`${itemFieldName}.committentTin`}
                    shouldUnregister={true}
                    rules={{
                        required: t(lang, 'items.common.validations.is_required')
                    }}
                    render={({field, fieldState: {error}}) => (
                        <div className={'mt-1 mx-auto w-90'}>
                            <FindCompanySearchable
                                defaultInn={item.committentTin}
                                onChange={(company) => {
                                    onChangeCompany(company);
                                    field.onChange(company ? (company.person_num || company.inn) : null)
                                }}
                                inputProps={{
                                    isSearchable: !field.value,
                                    menuPortalTarget: document.body,
                                    styles: {
                                        control: base => ({
                                            ...base,
                                            height: 40,
                                            minHeight: 40,
                                            border: !!error ? '1px solid #e63757' : 'none'
                                        }),
                                        menuPortal: (provided) => ({
                                            ...provided,
                                            zIndex: 9999
                                        })
                                    }
                                }}
                            />
                        </div>
                    )}
                />
            </td>
            <td style={{minWidth: '200px', width: '200px'}} className="p-0">
                <Controller name={`${itemFieldName}.committentName`}
                            shouldUnregister={true}
                            rules={{
                                required: t(lang, 'items.common.validations.is_required')
                            }}
                            render={({field, fieldState: {error}}) => (
                                <Form.Control name={`${itemFieldName}.committentName`}
                                              className="px-1 mt-1 mx-auto w-90"
                                              style={{height: '40px'}}
                                              value={item.committentName}
                                              isInvalid={!!error}
                                              onChange={(e) => field.onChange(e.target.value)}
                                />
                            )}
                />
            </td>
            <td style={{minWidth: '120px', width: '120px'}} className="p-0">
                <Controller name={`${itemFieldName}.committentVatRegCode`}
                            shouldUnregister={true}
                            render={({field}) => (
                                <Form.Control name={`${itemFieldName}.committentVatRegCode`}
                                              className="px-1 mt-1 mx-auto w-90"
                                              style={{height: '40px'}}
                                              value={item.committentVatRegCode}
                                              onChange={(e) => field.onChange(e.target.value)}
                                />
                            )}
                />
            </td>
            <td style={{minWidth: '100px', width: '100px'}} className="p-0">
                <Controller name={`${itemFieldName}.committentVatRegStatus`}
                            shouldUnregister={true}
                            render={({field}) => (
                                <Form.Control name={`${itemFieldName}.committentVatRegStatus`}
                                              className="px-1 mt-1 mx-auto w-90"
                                              style={{height: '40px'}}
                                              value={item.committentVatRegStatus}
                                              onChange={(e) => field.onChange(e.target.value)}
                                />
                            )}
                />
            </td>
        </Fragment>
    );
};

RoamingInvoiceFormItemRowCommittent.propTypes = {
    itemFieldName: PropTypes.string,
    isEditOrCopy: PropTypes.bool,
};

RoamingInvoiceFormItemRowCommittent.defaultProps = {
    itemFieldName: '',
    isEditOrCopy: false,
};

export default RoamingInvoiceFormItemRowCommittent;
