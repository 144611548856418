import React, {Fragment, useEffect} from 'react';
import {Controller, useFieldArray, useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {Card, Dropdown, Table, Form} from "react-bootstrap";
import IconButton from "../../../IconButton";
import {faEye, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import VerificationActContracts from "./VerificationActContracts";
import Cleave from "cleave.js/react";

const Acts = () => {
    const { formState: { errors }, control, setValue, watch } = useFormContext();
    const {fields : fieldsContract, append : appendContract, remove : removeContract } = useFieldArray({ control: control, name: "verificationActContracts" })
    const watchFieldsContract = watch(`verificationActContracts`)
    const lang = useSelector(selectLang);
    const t = Translate;

    const addFieldContract = () => {
        appendContract({
            contractNo: "",
            contractDate: "",
            verificationActContractItems: [
                {
                    ownerOperationDate: "",
                    ownerOperationName: "",
                    ownerDebit: 0.00,
                    ownerCredit: 0.00,
                    partnerOperationDate: "",
                    partnerOperationName: "",
                    partnerDebit: 0.00,
                    partnerCredit: 0.00
                }
            ],
            openBalance: {
                ownerDebit: 0.00,
                ownerCredit: 0.00,
                partnerDebit: 0.00,
                partnerCredit: 0.00
            },
            closeBalance: {
                ownerDebit: 0.00,
                ownerCredit: 0.00,
                partnerDebit: 0.00,
                partnerCredit: 0.00
            },
            totalBalance: {
                ownerDebit: 0.00,
                ownerCredit: 0.00,
                partnerDebit: 0.00,
                partnerCredit: 0.00
            }
        })
    }

    const removeFieldContract = index => {
        removeContract(index)
    }

    const onChangeSumHandler = () => {
        let sumOpenDebit = watchFieldsContract.reduce((total, debit) => total + +debit.totalBalance.ownerDebit, 0)
        let sumOpenCredit = watchFieldsContract.reduce((total, credit) =>  total + +credit.totalBalance.ownerCredit, 0)
        let sumCloseDebit = 0
        let sumCloseCredit = 0
        if(sumOpenDebit > sumOpenCredit) {
            sumCloseDebit = sumOpenDebit - sumOpenCredit
        } else {
            sumCloseCredit = sumOpenCredit - sumOpenDebit
        }
        setValue(`turnoverBalance.ownerDebit`, sumOpenDebit)
        setValue(`turnoverBalance.ownerCredit`, sumOpenCredit)
        setValue(`turnoverBalance.partnerDebit`, sumOpenCredit)
        setValue(`turnoverBalance.partnerCredit`, sumOpenDebit)
        setValue(`closeBalance.ownerDebit`, sumCloseDebit)
        setValue(`closeBalance.ownerCredit`, sumCloseCredit)
        setValue(`closeBalance.partnerDebit`, sumCloseCredit)
        setValue(`closeBalance.partnerCredit`, sumCloseDebit)
    }

    useEffect(() => {
        onChangeSumHandler()
    }, [watchFieldsContract]);

    return (
        <Fragment>
            <h4 className={'fs-8 fw-medium second-text ms-3'}>{t(lang, "roaming.verification_act.add.verification_act.contracts.title")}</h4>

            <Card className={'py-1 px-2 rounded-4 border-0'}>
                <Table responsive className="scrollbar view-table border-900 mb-0 fs-9 second-text fw-semi-bold">
                    <thead className="text-dark border-bottom">
                    <tr className="position-relative py-2 fs-9 second-text fw-semi-bold">
                        <th scope="col" colSpan="4"
                            className="fw-semi-bold text-center">{t(lang, "roaming.verification_act.add.verification_act.contracts.owner_info", {owner: watch("ownerName") || t(lang, "roaming.verification_act.add.verification_act.contracts.owner_info.default")})}</th>
                        <th scope="col" colSpan="5"
                            className="fw-semi-bold text-center">{t(lang, "roaming.verification_act.add.verification_act.contracts.partner_info", {partner: watch("partnerName") || t(lang, "roaming.verification_act.add.verification_act.contracts.partner_info.default")})}</th>
                    </tr>
                    <tr className="position-relative py-2 fs-9 second-text fw-semi-bold">
                        <th scope="col" style={{width: "12.5%"}}
                            className="fw-semi-bold text-center align-middle">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.owner.date")}</th>
                        <th scope="col" style={{width: "12.5%"}}
                            className="fw-semi-bold text-center align-middle">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.owner.document")}</th>
                        <th scope="col" style={{width: "12.5%"}}
                            className="fw-semi-bold text-center align-middle">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.owner.debit")}</th>
                        <th scope="col" style={{width: "12.5%"}}
                            className="fw-semi-bold text-center align-middle">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.owner.credit")}</th>
                        <th scope="col" style={{width: "12.5%"}}
                            className="fw-semi-bold text-center align-middle">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.partner.date")}</th>
                        <th scope="col" style={{width: "12.5%"}}
                            className="fw-semi-bold text-center align-middle">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.partner.document")}</th>
                        <th scope="col" style={{width: "12.5%"}}
                            className="fw-semi-bold text-center align-middle">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.partner.debit")}</th>
                        <th scope="col" style={{width: "12.5%"}}
                            className="fw-semi-bold text-center align-middle">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.partner.credit")}</th>
                        <th className="fw-semi-bold text-center align-middle"/>
                    </tr>
                    </thead>

                    {fieldsContract.map((contract, index) => (
                        <VerificationActContracts key={contract.id} index={index} contract={contract}
                                                  appendContract={addFieldContract} removeContract={removeFieldContract}
                                                  onChange={onChangeSumHandler}/>
                    ))}

                    <tfoot>
                    <tr>
                        <td style={{width: "25%"}} className="text-center align-middle"
                            colSpan="2">
                            <Form.Control disabled={true} defaultValue={t(lang, "roaming.verification_act.add.verification_act.contracts.table.foot.turnover_balance.title")} />
                        </td>
                        <td style={{width: "12.5%"}} className="text-center align-middle p-1">
                            <Controller
                                name={`turnoverBalance.ownerDebit`}
                                control={control}
                                rules={{
                                    required: "edi.common.forms.validations.is_required"
                                }}
                                render={({field}) => (
                                    <Cleave
                                        {...field}
                                        options={{
                                            numeral: true,
                                            delimiter: ' ',
                                            numeralDecimalScale: 2
                                        }}
                                        placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.owner.debit")}
                                        className={`${errors?.count && 'is-invalid'} p-2 form-control`}
                                        disabled
                                    />
                                )}
                            />
                        </td>
                        <td style={{width: "12.5%"}} className="text-center align-middle p-1">
                            <Controller
                                name={`turnoverBalance.ownerCredit`}
                                control={control}
                                rules={{
                                    required: "edi.common.forms.validations.is_required"
                                }}
                                render={({field}) => (
                                    <Cleave
                                        {...field}
                                        options={{
                                            numeral: true,
                                            delimiter: ' ',
                                            numeralDecimalScale: 2
                                        }}
                                        placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.owner.credit")}
                                        className={`${errors?.count && 'is-invalid'} p-2 form-control`}
                                        disabled
                                    />
                                )}
                            />
                        </td>
                        <td style={{width: "25%"}} className="text-center align-middle"
                            colSpan="2">
                            <Form.Control disabled={true} defaultValue={t(lang, "roaming.verification_act.add.verification_act.contracts.table.foot.turnover_balance.title")} />
                        </td>
                        <td style={{width: "12.5%"}} className="text-center align-middle p-1">
                            <Controller
                                name={`turnoverBalance.partnerDebit`}
                                control={control}
                                rules={{
                                    required: "edi.common.forms.validations.is_required"
                                }}
                                render={({field}) => (
                                    <Cleave
                                        {...field}
                                        options={{
                                            numeral: true,
                                            delimiter: ' ',
                                            numeralDecimalScale: 2
                                        }}
                                        placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.partner.debit")}
                                        className={`${errors?.count && 'is-invalid'} p-2 form-control`}
                                        disabled
                                    />
                                )}
                            />
                        </td>
                        <td style={{width: "12.5%"}} className="text-center align-middle p-1">
                            <Controller
                                name={`turnoverBalance.partnerCredit`}
                                control={control}
                                rules={{
                                    required: "edi.common.forms.validations.is_required"
                                }}
                                render={({field}) => (
                                    <Cleave
                                        {...field}
                                        options={{
                                            numeral: true,
                                            delimiter: ' ',
                                            numeralDecimalScale: 2
                                        }}
                                        placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.partner.credit")}
                                        className={`${errors?.count && 'is-invalid'} p-2 form-control`}
                                        disabled
                                    />
                                )}
                            />
                        </td>
                        <td
                            style={{width: "12.5%"}}
                            className="px-0"
                        >
                            <div className="d-flex invisible">
                                <IconButton
                                    variant="phoenix-primary"
                                    icon={faPlus}
                                    size="sm"
                                    className="mx-1 px-2"
                                />
                                <IconButton
                                    variant="phoenix-danger"
                                    icon={faTrash}
                                    size="sm"
                                    className="mx-1 px-2"
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-center  align-middle"
                            colSpan="2">
                            <Form.Control disabled={true} defaultValue={t(lang, "roaming.verification_act.add.verification_act.contracts.table.foot.close_balance.title")} />
                        </td>
                        <td className="text-center  align-middlep-1">
                            <Controller
                                name={`closeBalance.ownerDebit`}
                                control={control}
                                rules={{
                                    required: "edi.common.forms.validations.is_required"
                                }}
                                render={({field}) => (
                                    <Cleave
                                        {...field}
                                        options={{
                                            numeral: true,
                                            delimiter: ' ',
                                            numeralDecimalScale: 2
                                        }}
                                        placeholder="Кредит"
                                        className={`${errors?.count && 'is-invalid'} p-2 form-control`}
                                        disabled
                                    />
                                )}
                            />
                        </td>
                        <td className="text-center  align-middlep-1">
                            <Controller
                                name={`closeBalance.ownerCredit`}
                                control={control}
                                rules={{
                                    required: "edi.common.forms.validations.is_required"
                                }}
                                render={({field}) => (
                                    <Cleave
                                        {...field}
                                        options={{
                                            numeral: true,
                                            delimiter: ' ',
                                            numeralDecimalScale: 2
                                        }}
                                        placeholder="Кредит"
                                        className={`${errors?.count && 'is-invalid'} p-2 form-control`}
                                        disabled
                                    />
                                )}
                            />
                        </td>
                        <td className="text-center  align-middle"
                            colSpan="2">
                            <Form.Control disabled={true} defaultValue={t(lang, "roaming.verification_act.add.verification_act.contracts.table.foot.close_balance.title")} />
                        </td>
                        <td className="text-center align-middle p-1">
                            <Controller
                                name={`closeBalance.partnerDebit`}
                                control={control}
                                rules={{
                                    required: "edi.common.forms.validations.is_required"
                                }}
                                render={({field}) => (
                                    <Cleave
                                        {...field}
                                        options={{
                                            numeral: true,
                                            delimiter: ' ',
                                            numeralDecimalScale: 2
                                        }}
                                        placeholder="Кредит"
                                        className={`${errors?.count && 'is-invalid'} p-2 form-control`}
                                        disabled
                                    />
                                )}
                            />
                        </td>
                        <td className="text-center align-middle p-1">
                            <Controller
                                name={`closeBalance.partnerCredit`}
                                control={control}
                                rules={{
                                    required: "edi.common.forms.validations.is_required"
                                }}
                                render={({field}) => (
                                    <Cleave
                                        {...field}
                                        options={{
                                            numeral: true,
                                            delimiter: ' ',
                                            numeralDecimalScale: 2
                                        }}
                                        placeholder="Кредит"
                                        className={`${errors?.count && 'is-invalid'} p-2 form-control`}
                                        disabled
                                    />
                                )}
                            />
                        </td>
                        <td className=""></td>
                    </tr>
                    </tfoot>
                </Table>
            </Card>

        </Fragment>
    );
};

export default Acts;
