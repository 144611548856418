import React, {Fragment} from 'react';
import {Spinner} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectShowInProgress} from "../../store/reducers/main/main";

const InProgress = () => {
    const showInProgress = useSelector(selectShowInProgress)
    const styleInProgress = {
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        background: "rgba(255,255,255, .5)",
        zIndex: 1030
    }
    return (
        <Fragment>
            {
                showInProgress &&
                <div className='position-fixed d-flex justify-content-center align-items-center' style={styleInProgress}>
                    <Spinner animation="border" variant="primary" style={{width: "50px", height: "50px"}} />
                </div>
            }
        </Fragment>
    );
};

export default InProgress;
