import React, {Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectDateFormat, selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {useFormContext, Controller} from "react-hook-form";
import {createSigner, deleteSigner, loadSigners} from "../../../../store/reducers/edi/invoice/invoiceReducer";
import {Button, Col, Row, Spinner, Form, Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import InvoiceItems from "./form-blocks/InvoiceItems";
import InvoiceMember from "./form-blocks/InvoiceMember";
import SelectDirector from "../../signare/SelectDirector";
import SelectReleaser from "../../signare/SelectReleaser";
import SelectAccountant from "../../signare/SelectAccountant";
import PropTypes from "prop-types";

const InvoiceForm = ({loading, existingNumber}) => {

    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const dateFormat = useSelector(selectDateFormat);
    const {register, formState: {errors}} = useFormContext();

    useEffect(() => {
        dispatch(loadSigners());
    }, []);

    return (
        <Fragment>
            <Row>
                <Col xs={12}>
                    <div className="bg-200 p-3 mb-3">
                        <Row>
                            <Col xs={12} className={'mb-2'}>
                                <h4>{t(lang, "edi.common.invoice")}</h4>
                            </Col>
                            <Col xs={12} sm={6} className="mb-2">
                                <Form.Label>
                                    {t(lang, "edi.common.invoice.number")}
                                    <span className={'text-danger'}>*</span>
                                </Form.Label>
                                <Form.Control name="invoiceInfo.number"
                                              type="text"
                                              placeholder={t(lang, "edi.common.invoice.number.placeholder")}
                                              isInvalid={errors?.invoiceInfo?.number}
                                              isValid={Object.keys(errors).length > 0 && !errors?.invoiceInfo?.number}
                                              {...register('invoiceInfo.number', {
                                                  required: t(lang, "edi.common.forms.validations.is_required"),
                                                  validate: (value) => {
                                                      if (existingNumber) {
                                                          if (value === existingNumber)
                                                              return t(lang, "edi.common.forms.validations.is_exists", {name: t(lang, "edi.common.invoice.number")});
                                                      }
                                                      return true;
                                                  }
                                              })}

                                />
                                <Form.Control.Feedback
                                    type="invalid">{errors?.invoiceInfo?.number?.message}</Form.Control.Feedback>
                            </Col>
                            <Col xs={12} sm={6} className="mb-2">
                                <Form.Label>
                                    {t(lang, "edi.common.invoice.date")}
                                    <span className={'text-danger'}>*</span>
                                </Form.Label>
                                <Controller name={'invoiceInfo.date'}
                                            rules={{
                                                required: t(lang, "edi.common.forms.validations.is_required")
                                            }}
                                            render={({field}) => (
                                                <DatePicker
                                                    placeholderText={t(lang, "edi.common.invoice.date.placeholder")}
                                                    selected={field.value}
                                                    onChange={field.onChange}
                                                    dateFormat={dateFormat}
                                                    disabled
                                                    wrapperClassName={classNames({
                                                        'is-invalid': errors?.invoiceInfo?.date,
                                                    })}
                                                    className={classNames('form-control', {
                                                        'is-invalid': errors?.invoiceInfo?.date,
                                                        'is-valid': Object.keys(errors).length > 0 && !errors?.invoiceInfo?.date
                                                    })}
                                                />
                                            )}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{errors?.invoiceInfo?.date?.message}</Form.Control.Feedback>
                            </Col>
                            <Col xs={12} sm={6} className="mb-2">
                                <Form.Label>
                                    {t(lang, "edi.common.shipment.number")}
                                    <span className={'text-danger'}>*</span>
                                </Form.Label>
                                <Form.Control name="shipmentInfo.number"
                                              type="text"
                                              placeholder={t(lang, "edi.common.shipment.number.placeholder")}
                                              disabled
                                              isInvalid={errors?.shipmentInfo?.number}
                                              isValid={Object.keys(errors).length > 0 && !errors?.shipmentInfo?.number}
                                              {...register('shipmentInfo.number', {
                                                  required: t(lang, "edi.common.forms.validations.is_required")
                                              })}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{errors?.shipmentInfo?.number?.message}</Form.Control.Feedback>
                            </Col>
                            <Col xs={12} sm={6} className="mb-2">
                                <Form.Label>
                                    {t(lang, "edi.common.shipment.date")}
                                    <span className={'text-danger'}>*</span>
                                </Form.Label>
                                <Controller name={'shipmentInfo.date'}
                                            rules={{
                                                required: t(lang, "edi.common.forms.validations.is_required")
                                            }}
                                            render={({field}) => (
                                                <DatePicker
                                                    placeholderText={t(lang, "edi.common.invoice.date.placeholder")}
                                                    selected={field.value}
                                                    onChange={field.onChange}
                                                    dateFormat={dateFormat}
                                                    disabled
                                                    wrapperClassName={classNames({
                                                        'is-invalid': errors?.shipmentInfo?.date,
                                                    })}
                                                    className={classNames('form-control', {
                                                        'is-invalid': errors?.shipmentInfo?.date,
                                                        'is-valid': Object.keys(errors).length > 0 && !errors?.shipmentInfo?.date
                                                    })}
                                                />
                                            )}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{errors?.shipmentInfo?.date?.message}</Form.Control.Feedback>
                            </Col>
                            <Col xs={12} sm={6} className="mb-2">
                                <Form.Label>
                                    {t(lang, "edi.common.contract.number")}
                                    <span className={'text-danger'}>*</span>
                                </Form.Label>
                                <Form.Control name="contractInfo.number"
                                              placeholder={t(lang, "edi.common.contract.number.placeholder")}
                                              type="text"
                                              isInvalid={errors?.contractInfo?.number}
                                              isValid={Object.keys(errors).length > 0 && !errors?.contractInfo?.number}
                                              disabled
                                              {...register('contractInfo.number', {
                                                  required: t(lang, "edi.common.forms.validations.is_required")
                                              })}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{errors?.contractInfo?.number?.message}</Form.Control.Feedback>
                            </Col>
                            <Col xs={12} sm={6} className="mb-2">
                                <Form.Label>
                                    {t(lang, "edi.common.contract.date")}
                                    <span className={'text-danger'}>*</span>
                                </Form.Label>
                                <Controller name={'contractInfo.date'}
                                            rules={{
                                                required: t(lang, "edi.common.forms.validations.is_required")
                                            }}
                                            render={({field}) => (
                                                <DatePicker
                                                    placeholderText={t(lang, "edi.common.invoice.date.placeholder")}
                                                    selected={field.value}
                                                    onChange={field.onChange}
                                                    dateFormat={dateFormat}
                                                    disabled
                                                    wrapperClassName={classNames({
                                                        'is-invalid': errors?.contractInfo?.date,
                                                    })}
                                                    className={classNames('form-control', {
                                                        'is-invalid': errors?.contractInfo?.date,
                                                        'is-valid': Object.keys(errors).length > 0 && !errors?.contractInfo?.date
                                                    })}
                                                />
                                            )}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{errors?.contractInfo?.date?.message}</Form.Control.Feedback>
                            </Col>
                            <Col xs={12} className="mb-2">
                                <Form.Label>
                                    {t(lang, "edi.common.shipping_address")}
                                </Form.Label>
                                <Form.Control name="deliveryAddress"
                                              type="text"
                                              placeholder={t(lang, "edi.common.shipping_address.placeholder")}
                                              isValid={Object.keys(errors).length > 0 && !errors?.deliveryAddress}
                                              {...register('deliveryAddress')}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
                {/*<Col xs={12} lg={6}>*/}
                {/*    <div className={'d-flex align-items-start justify-content-end h-100'}>*/}
                {/*        <Button type="submit" disabled={loading}>*/}
                {/*            {loading && <Spinner className="align-middle me-2" animation="border" role="switch"*/}
                {/*                                 size="sm"/>}*/}
                {/*            {t(lang, "edi.common.save_button")}*/}
                {/*        </Button>*/}
                {/*    </div>*/}
                {/*</Col>*/}
                <Col xs={12} lg={6}>
                    <div className={'bg-200 p-3 h-100'}>
                        <Row>
                            <h4>{t(lang, "edi.common.customer")}</h4>
                            <Row>
                                <InvoiceMember rootName={'customer'}/>
                            </Row>
                        </Row>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="bg-200 p-3 h-100">
                        <Row>
                            <h4>{t(lang, "edi.common.executor")}</h4>
                            <Row>
                                <InvoiceMember rootName={'executor'}/>
                            </Row>
                        </Row>
                    </div>
                </Col>
                <Col xs={12} className="mt-4">
                    {/*<div className={'bg-200'}>*/}
                    <InvoiceItems/>
                    {/*</div>*/}
                </Col>
                <Col xs={12} className="mt-4">
                    <div className={'bg-200 p-3'}>
                        <h4>{t(lang, "edi.common.signers")}</h4>
                        <Row>
                            <Col xs={12} sm={6}>
                                <Row>
                                    <Col xs={12} className="mb-2">
                                        <Form.Label>{t(lang, "edi.common.director")}</Form.Label>
                                        <Controller name={"director"}
                                                    render={({field}) => (
                                                        <SelectDirector
                                                            onCreate={(director) => {
                                                                console.log(director)
                                                                dispatch(createSigner(director));
                                                                field.onChange(director.label);
                                                            }}
                                                            onDelete={deleteSigner}
                                                        />
                                                    )}
                                        />
                                    </Col>
                                    <Col xs={12} className="mb-2">
                                        <Form.Label>{t(lang, "edi.common.accountant")}</Form.Label>
                                        <Controller name={"accountant"}
                                                    render={({field}) => (
                                                        <SelectAccountant
                                                            onCreate={(accountant) => {
                                                                dispatch(createSigner(accountant));
                                                                field.onChange(accountant.label);
                                                            }}
                                                            onDelete={deleteSigner}
                                                        />
                                                    )}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Label>{t(lang, "edi.common.releaser")}</Form.Label>
                                        <Controller name={"releaser"}
                                                    render={({field}) => (
                                                        <SelectReleaser
                                                            onCreate={(releaser) => {
                                                                dispatch(createSigner(releaser));
                                                                field.onChange(releaser.label);
                                                            }}
                                                            onDelete={deleteSigner}
                                                        />
                                                    )}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} sm={6}>
                                <Row>
                                    <Col xs={12} className="mb-2">
                                        <Form.Label>{t(lang, "edi.common.shipper")}</Form.Label>
                                        <Form.Control
                                            placeholder={t(lang, "edi.common.shipper.placeholder")}
                                            type="text"
                                            isValid={Object.keys(errors).length > 0 && !errors?.proxyInfo?.name}
                                            {...register('proxyInfo.name')}
                                        />
                                    </Col>
                                    <Col xs={12} className="mb-2">
                                        <Form.Label>{t(lang, "edi.common.attorney.number")}</Form.Label>
                                        <Form.Control
                                            placeholder={t(lang, "edi.common.attorney.number.placeholder")}
                                            type="text"
                                            isValid={Object.keys(errors).length > 0 && !errors?.proxyInfo?.number}
                                            {...register('proxyInfo.number')}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Label>{t(lang, "edi.common.attorney.date")}</Form.Label>
                                        <Controller name="proxyInfo.date"
                                                    render={
                                                        ({field}) => (
                                                            <DatePicker
                                                                placeholderText={t(lang, "edi.common.attorney.date.placeholder")}
                                                                selected={field.value}
                                                                onChange={field.onChange}
                                                                dateFormat="dd.MM.yyyy"
                                                                className={classNames('form-control', {
                                                                    'is-valid': Object.keys(errors).length > 0
                                                                })}
                                                            />
                                                        )
                                                    }
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-end mt-3">
                                <Button type="submit" disabled={loading}>
                                    {loading && <Spinner className="align-middle me-2" animation="border" role="switch"
                                                         size="sm"/>}
                                    {t(lang, "edi.common.save_button")}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Fragment>
    );
};
InvoiceForm.propTypes = {
    loading: PropTypes.bool,
    existingNumber: PropTypes.string
};

InvoiceForm.defaultProps = {
    loading: false,
    existingNumber: ''
};

export default InvoiceForm;
