import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    getCarrierWaybillsCount,
    getCustomerWaybillsCount, getWaybillDraftsCount, loadCarrierWaybillsAsync,
    loadCustomerWaybillsAsync, loadWaybillDraftsAsync,
    selectWaybillsFilterOptions
} from "../../../store/reducers/roaming/waybill/roamingWaybillReducer";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import {selectAccountRolePermissions} from "../../../store/reducers/role/roleReducer";
import {VIEW_DRAFT, VIEW_INBOX, VIEW_OUTBOX} from "../../../enum/roaming/TypeViewers";
import checkPermission from "../../../enum/permission/CheckPermission";
import {PermissionRoamingShipmentView} from "../../../enum/permission/RoamingPermission";
import PermissionBadge from "../../../components/common/PermissionBadge";
import WaybillDataTable from "../../../components/roaming/waybill/WaybillDataTable";

const RoamingWaybills = () => {
    const {viewer} = useParams();
    const dispatch = useDispatch();
    const filter = useSelector(selectWaybillsFilterOptions);
    const activeOrganization = (useSelector(selectActiveOrganization));
    const viewer_inn_or_pinfl = activeOrganization.pinfl || activeOrganization.inn;
    const t = Translate;
    const lang = useSelector(selectLang);
    //important
    useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (viewer === VIEW_INBOX) {
            dispatch(loadCustomerWaybillsAsync({...filter, viewer_inn: viewer_inn_or_pinfl}));
            dispatch(getCustomerWaybillsCount({viewer_inn: viewer_inn_or_pinfl}));
        } else if (viewer === VIEW_OUTBOX) {
            dispatch(loadCarrierWaybillsAsync({...filter, viewer_inn: viewer_inn_or_pinfl}));
            dispatch(getCarrierWaybillsCount({viewer_inn: viewer_inn_or_pinfl}));
        } else if (viewer === VIEW_DRAFT) {
            dispatch(loadWaybillDraftsAsync({...filter, viewer_inn: viewer_inn_or_pinfl}));
            dispatch(getWaybillDraftsCount({viewer_inn: viewer_inn_or_pinfl}));
        }
    }, [activeOrganization, filter, viewer])


    return <WaybillDataTable viewer={viewer} />

};

export default RoamingWaybills;
