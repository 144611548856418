import React, {useEffect, useState} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {selectExchangeTypes} from "../../../store/reducers/roaming/roamingReferenceReducer";
import CustomFloatingLabelSelect from "../../floating-custom-select/CustomFloatingLabelSelect";

const RoamingInvoiceFormExchangeTypeSelector = ({defaultExchangeType, onChange}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const exchangeTypes = useSelector(selectExchangeTypes);

    const [value, setValue] = useState(null);

    useEffect(() => {
        setValue(exchangeTypes.find(o => o.prefix === defaultExchangeType))
    }, [defaultExchangeType]);

    const onChangeHandler = (option) => {
        onChange(option);
        setValue(option)
    };

    return (
        <div className={'select'}>
            <CustomFloatingLabelSelect
                options={exchangeTypes}
                label={t(lang, "roaming.invoice.exchange.type")}
                getOptionLabel={option => `${option.name} (${option.prefix})`}
                getOptionValue={option => option.prefix}
                isClearable
                onChange={option => onChangeHandler(option ? option.prefix : null)}
                value={value}
            />
        </div>
    );
};

export default RoamingInvoiceFormExchangeTypeSelector;
