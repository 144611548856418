import {createSlice} from "@reduxjs/toolkit";
import roleService from "../../services/role/roleService";
import EventBus from "../../../eventbus/EventBus";
import {
    ADD_ROLE_FAILED, ADD_ROLE_REQUESTED, ADD_ROLE_SUCCESS,
    DELETE_ROLE_FAILED,
    DELETE_ROLE_REQUESTED,
    DELETE_ROLE_SUCCESS,
    EDIT_ACCOUNT_ROLE_FAILED,
    EDIT_ACCOUNT_ROLE_REQUESTED,
    EDIT_ACCOUNT_ROLE_SUCCESS,
    EDIT_ROLE_FAILED, EDIT_ROLE_REQUESTED,
    EDIT_ROLE_SUCCESS
} from "../../../eventbus/roleEvents";


const initialState = {
    roles: [],
    accountRoles: [],
    permissions: []
}
export const roleSlice = createSlice({
    name: "role",
    initialState,
    reducers: {
        updateRoles: (state, action) => {
            state.roles = action.payload;
        },
        updateAccountRoles: (state, action) => {
            state.accountRoles = action.payload;
        },
        updatePermissions: (state, action) => {
            state.permissions = action.payload.role.permissions
        }
    }
})



export const loadRolesAsync = () => (dispatch) => {
    roleService.getRoles()
        .then((response) => {
            dispatch(roleSlice.actions.updateRoles(response.data));
        })
        .catch(error => {
            console.log(error);
        });
}

export const loadAccountRolesAsync = () => (dispatch) => {
    roleService.getAccountRoles()
        .then((response) => {
            dispatch(roleSlice.actions.updateAccountRoles(response.data));
        })
        .catch(error => {
            console.log(error);
        });
}

export const addRoleAsync = (payload) => () => {
    EventBus.dispatch(ADD_ROLE_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        roleService.addRole(payload)
            .then(response => {
                EventBus.dispatch(ADD_ROLE_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(ADD_ROLE_FAILED, error)
                reject(error);
            })
    });
}
export const editRoleAsync = (id, payload) => {
    return new Promise((resolve, reject) => {
        EventBus.dispatch(EDIT_ROLE_REQUESTED, payload);

        roleService.editRole(id, payload)
            .then((response) => {
                EventBus.dispatch(EDIT_ROLE_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(EDIT_ROLE_FAILED, error);
                reject(error)
            })
    })
}

export const bindRoleIntoAccountAsync = (data) => {
    return new Promise((resolve, reject) => {
        EventBus.dispatch(EDIT_ACCOUNT_ROLE_REQUESTED, data.roleId);

        roleService.bindRoleIntoAccount(data)
            .then((response) => {
                EventBus.dispatch(EDIT_ACCOUNT_ROLE_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(EDIT_ACCOUNT_ROLE_FAILED, error);
                reject(error)
            })
    })
}

export const deleteRoleAsync = ({id}) => {
    EventBus.dispatch(DELETE_ROLE_REQUESTED, {id});

    return new Promise((resolve, reject) => {
        roleService.deleteRole({id})
            .then((response) => {
                EventBus.dispatch(DELETE_ROLE_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(DELETE_ROLE_FAILED, error.message);
                reject(error)
            })
    });
};

export const loadAccountPermissionAsync = () => (dispatch) => {
    roleService.getAccountPermission()
        .then((response) => {
            dispatch(roleSlice.actions.updatePermissions(response.data));
        })
        .catch(error => {
            console.log(error);
        });
}


export const selectAccountRolePermissions = (state) => state.role.permissions

export const selectRoles = (state) => {
    return state.role.roles
};

export const selectAccountRoles = (state) => {
    return state.role.accountRoles
};

export default roleSlice.reducer
