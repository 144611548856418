import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {selectAccountRolePermissions} from "../../../store/reducers/role/roleReducer";
import checkPermission from "../../../enum/permission/CheckPermission";
import {PermissionRoamingActUpdate} from "../../../enum/permission/RoamingPermission";
import {loadActDraftAsync} from "../../../store/reducers/roaming/act/actReducer";
import SpinnerComponent from "../../../components/common/SpinnerComponent";
import DraftActForm from "../../../components/roaming/act/form/DraftActForm";

const DraftActEdit = () => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const [draftAct, setDraftAct] = useState(null);
    const {id} = useParams();
    const navigate = useNavigate();

    const dispatch = useDispatch()
    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingActUpdate))) {
            navigate(-1)
        }
    }, [permission])

    useEffect(() => {
        loadDraftAct()
    }, [id, activeOrganization]);


    const loadDraftAct = async () => {
        try {
            const  getDraftAct = await loadActDraftAsync(id)
            {setDraftAct(JSON.parse(getDraftAct?.data?.content))}
        }catch (error) {
            navigate("../register")
        }
    }

    return (
        <Fragment>
            {draftAct && <DraftActForm draftAct={draftAct}/>}
            {!draftAct && <SpinnerComponent />}
        </Fragment>
    );
};

export default DraftActEdit;
