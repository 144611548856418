import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import {useForm} from "react-hook-form";
import EventBus from "../../../eventbus/EventBus";
import {RESET_PASSWORD_FAILED, RESET_PASSWORD_REQUESTED, RESET_PASSWORD_SUCCESS} from "../../../eventbus/authEvents";
import toast from "react-hot-toast";
import {changePasswordAsync} from "../../../store/reducers/auth/authReducer";
import {Button, FloatingLabel, Form, Spinner} from "react-bootstrap";
import classNames from "classnames";

const PasswordChangeForm = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch();
    const [buttonDisabled, setButtonDisabled] = useState(false);

    let {register, handleSubmit, formState: {errors}, watch, reset} = useForm({
        mode: "onSubmit",
    });

    const onSubmit = formData => {
        dispatch(changePasswordAsync(formData.oldPassword, formData.password));
    };

    useEffect(() => {
        const onResetPasswordSuccessHandler = EventBus.on(RESET_PASSWORD_SUCCESS, () => {
            toast.success(t(lang,"edi.common.toast.success"));
        });

        const onResetPasswordFailedHandler = EventBus.on(RESET_PASSWORD_FAILED, () => {
            toast.error(t(lang,"edi.common.toast.error"));
        });

        return () => {
            EventBus.remove(RESET_PASSWORD_SUCCESS, onResetPasswordSuccessHandler);
            EventBus.remove(RESET_PASSWORD_FAILED, onResetPasswordFailedHandler);
        };
    }, []);

    useEffect(() => {
        const onResetPasswordRequestedHandler = EventBus.on(RESET_PASSWORD_REQUESTED, () => {
            setButtonDisabled(true);
        });
        const onResetPasswordFailedHandler = EventBus.on(RESET_PASSWORD_FAILED, () => {
            setButtonDisabled(false);
        });
        const onResetPasswordSuccessHandler = EventBus.on(RESET_PASSWORD_SUCCESS, () => {
            setButtonDisabled(false);
            reset();
        });

        return () => {
            EventBus.remove(RESET_PASSWORD_REQUESTED, onResetPasswordRequestedHandler);
            EventBus.remove(RESET_PASSWORD_FAILED, onResetPasswordFailedHandler);
            EventBus.remove(RESET_PASSWORD_SUCCESS, onResetPasswordSuccessHandler);
        };
    }, []);

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
        >
            <Form.Group className="mb-3 text-start">
                <FloatingLabel label={t(lang, "edi.user.settings.change_password.old_password.placeholder")}>
                    <Form.Control  {...register("oldPassword", {
                        required: {
                            value: true,
                            message: t(lang, "edi.common.forms.validations.is_required")
                        },
                    })}
                                   placeholder={t(lang, "edi.user.settings.change_password.old_password.placeholder")}
                                   name="oldPassword"
                                   type="password"
                                   isInvalid={errors?.oldPassword?.message}
                                   isValid={Object.keys(errors).length > 0 && !errors?.oldPassword?.message}
                    />
                </FloatingLabel>

                <Form.Control.Feedback type="invalid">
                    {errors?.oldPassword?.message}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 text-start">
                <FloatingLabel label={t(lang, "edi.user.settings.change_password.new_password.placeholder")}>
                    <Form.Control  {...register("password", {
                        required: {
                            value: true,
                            message: t(lang, "edi.common.forms.validations.is_required")
                        },
                    })
                                   }
                                   placeholder={t(lang, "edi.user.settings.change_password.new_password.placeholder")}
                                   name="password"
                                   type="password"
                                   isInvalid={errors?.password?.message}
                                   isValid={Object.keys(errors).length > 0 && !errors?.password?.message}
                    />
                </FloatingLabel>

                <Form.Control.Feedback type="invalid">
                    {errors?.password?.message}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 text-start">
                <FloatingLabel label={t(lang, "edi.user.settings.change_password.confirm_password.placeholder")}>
                    <Form.Control {...register("confirmPassword", {
                        required: {
                            value: true,
                            message: t(lang, "edi.common.forms.validations.is_required")
                        },
                        validate: value =>
                            value === watch("password") || t(lang, "edi.common.forms.validations.do_not_match", {name: t(lang, "edi.common.forms.keywords.passwords")})
                    })}
                                  placeholder={t(lang, "edi.user.settings.change_password.confirm_password.placeholder")}
                                  name="confirmPassword"
                                  type="password"
                                  isInvalid={errors?.confirmPassword?.message}
                                  isValid={Object.keys(errors).length > 0 && !errors?.confirmPassword?.message}
                    />
                </FloatingLabel>

                <Form.Control.Feedback type="invalid">
                    {errors?.confirmPassword?.message}
                </Form.Control.Feedback>
            </Form.Group>
            <Button
                type="submit"
                className="btn-davr px-3 py-0 bg-primary-davr text-white w-100"
                variant="falcon-default"
                disabled={buttonDisabled}
            >
                {buttonDisabled && <Spinner animation="border" role="status" size="sm"/>}
                <span style={{marginLeft: buttonDisabled && '4px'}}>
                    {t(lang, "edi.user.settings.change_password.submit_button")}
                </span>
            </Button>
        </Form>
    );
};

export default PasswordChangeForm;
