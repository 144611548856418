import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, updateShowInProgressAsync} from "../../../store/reducers/main/main";
import {selectAccountRolePermissions} from "../../../store/reducers/role/roleReducer";
import {Translate} from "../../../lang/lang";
import checkPermission from "../../../enum/permission/CheckPermission";
import {PermissionRoamingEmpowermentCreate} from "../../../enum/permission/RoamingPermission";
import EventBus from "../../../eventbus/EventBus";
import {
    EMPOWERMENT_DRAFT_FAILED,
    EMPOWERMENT_DRAFT_REQUESTED, EMPOWERMENT_DRAFT_SUCCESS,
    EMPOWERMENT_SEND_FAILED,
    EMPOWERMENT_SEND_REQUESTED,
    EMPOWERMENT_SEND_SUCCESS
} from "../../../eventbus/romaing/empowerment/empowermentEvents";
import toast from "react-hot-toast";
import EmpowermentSendForm from "../../../components/roaming/empowerments/form/EmpowermentSendForm";

const SendEmpowerment = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const permission = useSelector(selectAccountRolePermissions)
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingEmpowermentCreate))) {
            navigate(-1)
        }
    }, [permission])

    useEffect(() => {
        const onEmpowermentSentRequestedHandler = EventBus.on(EMPOWERMENT_SEND_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onEmpowermentSentSuccessHandler = EventBus.on(EMPOWERMENT_SEND_SUCCESS, empowerment => {
            dispatch(updateShowInProgressAsync(false))
            toast.success(t(lang, 'roaming.toast.empowerment_send_success'))
            navigate(`/roaming/empowerment/${empowerment.id}`)
        })

        const onEmpowermentSentFailedHandler = EventBus.on(EMPOWERMENT_SEND_FAILED, (error) => {
            dispatch(updateShowInProgressAsync(false))
            toast.error(error.message)
        })

        const onEmpowermentDraftRequestedHandler = EventBus.on(EMPOWERMENT_DRAFT_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onEmpowermentDraftSuccessHandler = EventBus.on(EMPOWERMENT_DRAFT_SUCCESS, empowermentId => {
            dispatch(updateShowInProgressAsync(false))
            toast.success(t(lang, 'roaming.act_form_save_success'))
            navigate(`../draft-empowerment/${empowermentId.id}`)
        })

        const onEmpowermentDraftFailedHandler = EventBus.on(EMPOWERMENT_DRAFT_FAILED, () => {
            dispatch(updateShowInProgressAsync(false))
            toast.success(t(lang, 'roaming.act_form_save_error'))
        })

        return () => {
            EventBus.remove(EMPOWERMENT_SEND_REQUESTED, onEmpowermentSentRequestedHandler);
            EventBus.remove(EMPOWERMENT_SEND_SUCCESS, onEmpowermentSentSuccessHandler);
            EventBus.remove(EMPOWERMENT_SEND_FAILED, onEmpowermentSentFailedHandler);
            EventBus.remove(EMPOWERMENT_DRAFT_REQUESTED, onEmpowermentDraftRequestedHandler);
            EventBus.remove(EMPOWERMENT_DRAFT_SUCCESS, onEmpowermentDraftSuccessHandler);
            EventBus.remove(EMPOWERMENT_DRAFT_FAILED, onEmpowermentDraftFailedHandler);
        }
    }, [])

    return (
        <EmpowermentSendForm />
    );
};

export default SendEmpowerment;
