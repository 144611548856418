import React, {Fragment, useEffect, useState} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {
    getCompanyAddressAsync,
    getWaybillDistrictAsync, getWaybillRegionAsync,
    getWaybillVillageAsync, selectRegion
} from "../../../../store/reducers/roaming/waybill-v2/romaingWaybillV2Reducer";
import branchService from "../../../../store/services/branch/branchService";
import {Card, Col, Form, Row} from "react-bootstrap";
import SelectRegion from "../SelectRegion";
import SelectDistrict from "../../SelectDistrict";
import SelectVillage from "../SelectVillage";

const AdditionalInformationV2Point = ({ pointName, inn, title, customer, isEditing, content, branchCode }) => {

    const lang = useSelector(selectLang);
    const t = Translate;
    const regions = useSelector(selectRegion);
    const dispatch = useDispatch();
    const { register, setValue, getValues, watch, formState: { errors }, control } = useFormContext();

    const [companyAddresses, setCompanyAddresses] = useState([]);
    const [companyAddressesValue, setCompanyAddressesValue] = useState(false);
    const [roamingAddress, setRoamingAddress] = useState(null);
    const [villages, setVillages] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [roamingBranches, setRoamingBranches] = useState([]);

    const values = getValues();

    const getDistrictsAsync = (reg_code, dist_code_or_name) => {
        getWaybillDistrictAsync(reg_code)
            .then((res) => {
                console.log('Districts:', res);
                setDistricts(res);
                const district = res.find(i => i.code === dist_code_or_name || i.name === dist_code_or_name);
                setValue(`${pointName}.district_code`, district?.code);
                setValue(`${pointName}.district_name`, district?.name);

                if (isEditing) {
                    getVillage(district?.soato_code);
                }
            });
    };

    const getVillage = (soato_code) => {
        getWaybillVillageAsync(soato_code)
            .then((res) => {
                console.log('Villages:', res);
                setVillages(res);
            });
    };

    useEffect(() => {
        if (inn) {
            getCompanyAddressAsync(inn)
                .then((res) => setCompanyAddresses(res))
                .catch(() => setCompanyAddresses([]));
            dispatch(getWaybillRegionAsync());
        }

        if (customer) {
            branchService.getContractorBranches(inn)
                .then((res) => {
                    setRoamingBranches(res.data);
                    if (!isEditing) {
                        resetFormPoint();
                    }
                })
                .catch(() => setRoamingBranches([]));
        } else if (!isEditing) {
            resetFormPoint();
        }
    }, [inn]);

    useEffect(() => {
        if (isEditing || content) {
            const regionCode = values[pointName]?.region_code;
            const districtCode = values[pointName]?.district_code;
            const villageCode = values[`${pointName}`]?.village_code;
            if (regionCode && districtCode) {
                getDistrictsAsync(regionCode, districtCode, villageCode);
            }
        }
    }, []);

    useEffect(() => {
        if (roamingAddress) {
            setValue(`${pointName}.branch_id`, roamingAddress.id);
            setValue(`${pointName}.branch_code`, roamingAddress.code);
            setValue(`${pointName}.region_code`, roamingAddress.roaming_address?.region_code);
            setValue(`${pointName}.district_code`, roamingAddress.roaming_address?.district_code);
            setValue(`${pointName}.district_name`, roamingAddress.roaming_address?.district_name);
            setValue(`${pointName}.region_name`, roamingAddress.roaming_address?.region_name);
            setValue(`${pointName}.street`, roamingAddress.roaming_address?.street);
            setValue(`${pointName}.village_code`, roamingAddress.roaming_address?.village_code);
            setValue(`${pointName}.village_name`, roamingAddress.roaming_address?.village_name);
            setValue(`${pointName}.other`, roamingAddress.roaming_address?.other);
            setValue(`${pointName}.longitude`, roamingAddress.roaming_address?.longitude);
            setValue(`${pointName}.latitude`, roamingAddress.roaming_address?.latitude);
        }
    }, [roamingAddress]);

    useEffect(() => {
        if (branchCode) {
            const address = roamingBranches.find(i => i.code === branchCode);
            setRoamingAddress(address);
        }
    }, [branchCode, roamingBranches]);

    const handleChangeCompanyAddress = (option) => {
        if (option) {
            setCompanyAddressesValue(true);
            getDistrictsAsync(option.region_code, option.district_code);
        } else {
            setCompanyAddressesValue(false);
            setValue(`${pointName}.district_code`, null);
            setValue(`${pointName}.district_name`, null);
        }
        setValue(`${pointName}.company_address_id`, option?.id || null);
        setValue(`${pointName}.region_code`, option?.region_code || null);
        setValue(`${pointName}.region_name`, option?.region_name || null);
        setValue(`${pointName}.street`, option?.street || null);
        setValue(`${pointName}.village_code`, option?.village_code || null);
        setValue(`${pointName}.village_name`, option?.village_name || null);
        setValue(`${pointName}.other`, option?.other || null);
        setValue(`${pointName}.longitude`, option?.longitude || null);
        setValue(`${pointName}.latitude`, option?.latitude || null);
    };

    const handleChangeRegion = (option) => {
        if (option) {
            getDistrictsAsync(option.code, option.name);
        } else {
            setDistricts([]);
            setVillages([]);
        }
        setValue(`${pointName}.region_code`, option?.code || null);
        setValue(`${pointName}.region_name`, option?.name || null);
        setValue(`${pointName}.district_code`, null);
        setValue(`${pointName}.district_name`, null);
        setValue(`${pointName}.village`, null);
        setValue(`${pointName}.village`, null);
    };

    const handleChangeDistrict = (option) => {
        if (option) {
            getVillage(option.soato_code);
        } else {
            setVillages([]);
        }
        setValue(`${pointName}.district_code`, option?.code || null);
        setValue(`${pointName}.district_name`, option?.name || null);
    };

    const handleChangeVillage = (option) => {
        setValue(`${pointName}.village_code`, option?.code || null);
        setValue(`${pointName}.village_name`, option?.name || null);
    };

    const resetFormPoint = () => {
        setRoamingAddress(null);
        setValue(`${pointName}.branch_id`, null);
        setValue(`${pointName}.branch_code`, null);
        setValue(`${pointName}.region_code`, null);
        setValue(`${pointName}.district_code`, null);
        setValue(`${pointName}.district_name`, null);
        setValue(`${pointName}.region_name`, null);
        setValue(`${pointName}.street`, null);
        setValue(`${pointName}.village_code`, null);
        setValue(`${pointName}.village_name`, null);
        setValue(`${pointName}.other`, null);
        setValue(`${pointName}.longitude`, null);
        setValue(`${pointName}.latitude`, null);
    };

    return (
        <Fragment>
            <h4 className={'fs-8 fw-medium second-text'}>{t(lang, title)}</h4>
            <Card className={'p-1'}>
                <Row>
                    <Form.Group as={Col} sm={12} md={6}>
                        <Controller
                            name={`${pointName}.region_code`}
                            rules={{
                                required: t(lang, "roaming.common.forms.validations.is_required")
                            }}
                            render={({ field }) => (
                                <SelectRegion
                                    placeholder={<>
                                        {t(lang, "roaming.waybill.v2.send.region")}
                                        <span className="text-primary-davr">*</span>
                                    </>}
                                    isClearable
                                    isDisabled={companyAddressesValue}
                                    options={regions}
                                    defaultRegionId={field.value}
                                    onChange={handleChangeRegion}
                                />
                            )}
                        />
                        <Form.Control.Feedback type={'invalid'} className={'d-block'}>
                            {errors[pointName]?.region_code?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm={12} md={6}>
                        <Controller
                            name={`${pointName}.district_code`}
                            rules={{
                                required: t(lang, "roaming.common.forms.validations.is_required")
                            }}
                            render={({ field }) => (
                                <SelectDistrict
                                    placeholder={<>
                                        {t(lang, "roaming.waybill.v2.send.district")}
                                        <span className="text-primary-davr">*</span>
                                    </>}
                                    isClearable
                                    isDisabled={companyAddressesValue}
                                    options={districts}
                                    defaultDistrictId={field.value}
                                    onChange={handleChangeDistrict}
                                />
                            )}
                        />
                        <Form.Control.Feedback type={'invalid'} className={'d-block'}>
                            {errors[pointName]?.district_code?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm={12} md={6} className={'mb-1'}>
                        <Controller
                            name={`${pointName}.village_code`}
                            render={({ field }) => (
                                <SelectVillage
                                    placeholder={t(lang, "roaming.waybill.v2.send.village")}
                                    isClearable
                                    options={villages}
                                    defaultVillageCode={field.value}
                                    onChange={handleChangeVillage}
                                />
                            )} />
                    </Form.Group>
                    <Form.Group as={Col} sm={12} md={6}>
                        <Form.Control
                            readOnly={!!roamingAddress || companyAddressesValue}
                            placeholder={t(lang, "roaming.waybill.v2.send.street")}
                            {...register(`${pointName}.street`)}
                        />
                    </Form.Group>
                    <Form.Group as={Col} sm={12} md={6}>
                        <Form.Control
                            defaultValue={values[pointName]?.longitude}
                            readOnly={!!roamingAddress || companyAddressesValue}
                            placeholder={t(lang, 'roaming.waybill.v2.send.lan')}
                            {...register(`${pointName}.longitude`)}
                        />
                    </Form.Group>
                    <Form.Group as={Col} sm={12} md={6}>
                        <Form.Control
                            defaultValue={values[pointName]?.latitude}
                            readOnly={!!roamingAddress || companyAddressesValue}
                            placeholder={t(lang, 'roaming.waybill.v2.send.lat')}
                            {...register(`${pointName}.latitude`)}
                        />
                    </Form.Group>
                </Row>
            </Card>
        </Fragment>
    );
};

export default AdditionalInformationV2Point;
