import React, {Fragment} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {selectLang} from "../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {Button, Col, Row, Form, Card} from "react-bootstrap";
import {ActStatuses} from "../../../enum/roaming/act/ActStatus";
import SelectActStatus from "./SelectActStatus";

const ActDataTableFilter = () => {
    const {control} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;
    return (
        <Fragment>
            <Card className={'p-1'}>
                <Row className="justify-content-between align-items-center">
                    <Form.Group as={Col} md={12} controlId="status">
                        <Controller
                            control={control}
                            name="status"
                            render={({field}) => (
                                <SelectActStatus
                                    statuses={ActStatuses}
                                    isClearable
                                    placeholder={t(lang, "edi.order.datatable.header.filter.status")}
                                    onChange={statusId => field.onChange(statusId || null)}
                                />
                            )}
                        />
                    </Form.Group>
                </Row>
            </Card>
            <Form.Group sm="auto" controlId="showChange" className={"justify-content-end d-flex w-100"}>
                <Button className="btn-davr mt-2 px-3 py-0 bg-primary-davr float-end" variant="primary" type='submit'>
                    {t(lang, "edi.order.datatable.header.filter.show")}
                </Button>
            </Form.Group>
        </Fragment>
    );
};

export default ActDataTableFilter;
