import React, {Fragment, useEffect} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {selectDateFormat, selectLang} from "../../../../store/reducers/main/main";
import {Col, Row, Form, Card, FloatingLabel} from "react-bootstrap";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import EmpowermentMember from "./EmpowermentMember";
import EmpowermentPerson from "./EmpowermentPerson";
import EmpowermentItems from "./EmpowermentItems";
import DatePickerComponent from "../../../common/DatePickerComponent";
import IconArrowLeft from "../../../../assets/icon/IconArrowLeft";
import IconButton from "../../../IconButton";
import SelectCertificateWrapperComponent from "../../../common/SelectCertificateWrapperComponent";
import IconSignature from "../../../../assets/icon/IconSignature";
import {faSignature} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {scrollEvent} from "../../../../helpers/scrollEvent";

const EmpowermentForm = ({draftEmpowerment}) => {
    const {register, formState: {errors}, control, watch} = useFormContext();
    const activeOrganization = useSelector(selectActiveOrganization);
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;
    const navigate = useNavigate()

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('roaming-empowerment-form-header'))
        return () => window.removeEventListener('scroll', () => scrollEvent('roaming-empowerment-form-header'))
    }, []);

    return (
        <Fragment>

            <Row id={'roaming-empowerment-form-header'} className={'my-2 justify-content-between align-items-center position-sticky'} style={{top: '4rem'}}>
                <Col xl={6} sm={12} className={'d-flex align-items-center gap-3'}>
                    <button type={"button"} onClick={() => navigate(-1)} className={'main-text btn-back'} style={{width: 35, height: 35}}>
                        <IconArrowLeft />
                    </button>
                    <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.common.new.empowerment")}</h5>
                </Col>
                <Col xl={6} sm={12} className={'d-flex justify-content-end gap-2'}>
                    <IconButton
                        className={'btn-davr bg-primary-davr-outline'}
                        name="draft" variant="phoenix-warning" size="lg"
                        type="submit"
                    >
                        {t(lang, "roaming.act_form_save")}
                    </IconButton>
                    <SelectCertificateWrapperComponent
                        size="lg"
                        className={'btn-davr bg-primary-davr gap-1 text-white'}
                        submitButton={
                            <IconButton
                                        variant="phoenix-primary"
                                        size="lg"
                                        className="w-100 mt-2 mt-md-0 w-md-auto fw-semi-bold"
                                        name="send"
                                        type="submit"
                                        form="ContractForm"
                            >
                                {t(lang, "roaming.common.send_button")}
                            </IconButton>
                        }
                        openDialogButtonText={t(lang, "roaming.common.send_button")}/>
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={6}>
                    <h4 className={'fs-8 fw-medium second-text ms-3'}>{t(lang, "roaming.common.empowerment")}</h4>
                    <Card className="p-2">
                        <Row>
                            <Col xs={12}>
                                <FloatingLabel label={t(lang, "roaming.common.empowerment.number")}>
                                    <Form.Control
                                        type="text"
                                        placeholder={t(lang, "roaming.common.empowerment.number")}
                                        isInvalid={errors?.empowermentInfo?.number}
                                        isValid={Object.keys(errors).length > 0 && !errors?.empowermentInfo?.number}
                                        {...register('empowermentInfo.number', {
                                            required: t(lang, 'roaming.validations.is_required'),
                                        })}
                                    />
                                </FloatingLabel>
                                <Form.Control.Feedback type="invalid">{errors?.empowermentInfo?.number?.message}</Form.Control.Feedback>
                            </Col>
                            <Col xs={12} md={6} className="mt-2">
                                <Controller control={control}
                                            name="empowermentInfo.start"
                                            rules={{
                                                required: t(lang, 'roaming.validations.is_required'),
                                            }}
                                            render={
                                                ({field}) => (
                                                    <DatePickerComponent
                                                        field={field}
                                                        placeholderText={t(lang, "roaming.common.empowerment.start_date")}
                                                        wrapperClassName={classNames({
                                                            'is-invalid': errors?.empowermentInfo?.start
                                                        })}
                                                        className={classNames('form-control', {
                                                            'is-invalid': errors.empowermentInfo?.start,
                                                            'is-valid': Object.keys(errors).length > 0 && !errors?.empowermentInfo?.start
                                                        })}
                                                    />
                                                )
                                            }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.empowermentInfo?.start?.message}
                                </Form.Control.Feedback>
                            </Col>
                            <Col xs={12} md={6} className={'mt-2'}>
                                <Controller control={control}
                                            name="empowermentInfo.end"
                                            rules={{
                                                required: t(lang, 'roaming.validations.is_required'),
                                                validate: (value) => {
                                                    if(value < watch('empowermentInfo.start'))
                                                        return t(lang, "roaming.validations.empowerment.empowerment_end_date_more_than_empowerment_start_date");
                                                    return true;
                                                }
                                            }}
                                            render={
                                                ({field}) => (
                                                    <DatePickerComponent
                                                        field={field}
                                                        placeholderText={t(lang, "roaming.common.empowerment.end_date.placeholder")}
                                                        wrapperClassName={classNames({
                                                            'is-invalid': errors?.empowermentInfo?.end
                                                        })}
                                                        className={classNames('form-control', {
                                                            'is-invalid': errors.empowermentInfo?.end,
                                                            'is-valid': Object.keys(errors).length > 0 && !errors?.empowermentInfo?.end
                                                        })}
                                                    />
                                                )
                                            }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.empowermentInfo?.end?.message}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={12} md={6}>
                    <h4 className={'fs-8 fw-medium second-text ms-3'}>{t(lang, "roaming.common.contract")}</h4>
                    <Card className="p-2">
                        <Row>
                            <Col xs={12}>
                                <FloatingLabel label={t(lang, "roaming.common.contract.number")}>
                                    <Form.Control
                                        type="text"
                                        isInvalid={errors?.contractInfo?.number}
                                        isValid={Object.keys(errors).length > 0 && !errors?.contractInfo?.number}
                                        placeholder={t(lang, "roaming.common.contract.number")}
                                        {...register('contractInfo.number', {
                                            required: t(lang, 'roaming.validations.is_required'),
                                        })}
                                    />
                                </FloatingLabel>
                                <Form.Control.Feedback type="invalid">{errors?.contractInfo?.number?.message}</Form.Control.Feedback>
                            </Col>
                            <Col xs={12} className={'mt-2'}>
                                <Controller control={control}
                                            name="contractInfo.date"
                                            rules={{
                                                required: t(lang, 'roaming.validations.is_required'),
                                                validate: (value) => {
                                                    if(value > watch('empowermentInfo.start'))
                                                        return t(lang, "roaming.validations.empowerment.contract_date_more_than_empowerment_start_date")
                                                    return true;
                                                }
                                            }}
                                            render={
                                                ({field}) => (
                                                    <DatePickerComponent
                                                        field={field}
                                                        placeholderText={t(lang, "roaming.common.contract.date")}
                                                        wrapperClassName={classNames({
                                                            'is-invalid': errors?.contractInfo?.date
                                                        })}
                                                        className={classNames('form-control', {
                                                            'is-invalid': errors?.contractInfo?.date,
                                                            'is-valid': Object.keys(errors).length > 0 && !errors?.contractInfo?.date
                                                        })}
                                                    />
                                                )
                                            }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.contractInfo?.date?.message}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col className={'mt-2'} xs={12} md={6}>
                    <EmpowermentMember
                        root="customerInfo"
                        inn={activeOrganization?.inn}
                        draftEmpowerment={draftEmpowerment}
                        title={t(lang, "roaming.common.customer")}
                    />
                </Col>
                <Col className={'mt-2'} xs={12} md={6}>
                    <EmpowermentMember
                        root="executorInfo"
                        inn={draftEmpowerment?.SellerTin || draftEmpowerment?.executor?.inn}
                        draftEmpowerment={draftEmpowerment}
                        title={t(lang, "roaming.common.executor")}
                    />
                </Col>
                <Col xs={12} className={'mt-2'}>
                    <EmpowermentPerson draftEmpowerment={draftEmpowerment}/>
                </Col>
                <Col xs={12} className={'mt-2'}>
                    <EmpowermentItems root={'items'}/>
                </Col>
            </Row>
        </Fragment>
    );
};

export default EmpowermentForm;
