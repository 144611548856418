import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

const dateFormats = [
    'DD.MM.YYYY', 'YYYY.MM.DD',
    'DD-MM-YYYY', 'YYYY-MM-DD',
    'DD/MM/YYYY', 'YYYY/MM/DD',
];

export const dynamicDateFormatter = (value, format) => {
    dayjs.extend(customParseFormat)

    if (value.length > 0) {
        for (let fmt of dateFormats) {
            const v = value.substr(0, fmt.length);
            const d = dayjs(v, fmt, 'ru').format(format);
            const isValidDate = dayjs(d).isValid()

            if (isValidDate)
                return d
        }
    }

    return null
}
