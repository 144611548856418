import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import {selectDateFormat, selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {getTransportsAsync} from "../../../../store/reducers/roaming/waybill-v2/romaingWaybillV2Reducer";
import {Card, Col, Row, Form, FloatingLabel} from "react-bootstrap";
import FindCompanySearchable from "../../../organization/FindCompanySearchable";
import SelectClientEmployer from "../SelectClientEmployer";
import DatePickerComponent from "../../../common/DatePickerComponent";
import {Roadway} from "./SendWaybillFormV2";

const MembersInfoFormV2 = ({isEditing, shipmentId}) => {

    const dispatch = useDispatch()
    const {register, formState: {errors}, control, setValue, getValues, watch} = useFormContext();
    const activeOrganization = useSelector(selectActiveOrganization);
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    const [clientEmployer, setClientEmployer] = useState([])

    const values = getValues()
    const carrier = useWatch({name: 'carrier.inn_or_pinfl', exact: true})
    const customer = useWatch({name: 'customer.inn_or_pinfl', exact: true})
    const freight_forwarder = useWatch({name: 'freight_forwarder.inn_or_pinfl', exact: true})
    const responsible_person = useWatch({name: 'responsible_person.inn_or_pinfl', exact: true})
    const executor_is_freight_forwarder = useWatch({name: 'executor_is_freight_forwarder', exact: true})
    const customer_is_freight_forwarder = useWatch({name: 'customer_is_freight_forwarder', exact: true})
    const freight_forwarder_is_carrier = useWatch({name: 'freight_forwarder_is_carrier', exact: true})
    const responsible_person_is_driver = useWatch({name: 'responsible_person_is_driver', exact: true})
    const transportType = useWatch({name: "transport_type", exact: true})

    useEffect(() => {
        if (activeOrganization) {
            setValue('executor.inn_or_pinfl', activeOrganization?.inn)
            setValue('executor.name', activeOrganization?.name)
        }
    }, [activeOrganization]);

    useEffect(() => {
        if (values.delivery_type === 1) {
            setValue('customer.inn_or_pinfl', values.executor.inn_or_pinfl)
            setValue('customer.name', values.executor.name)
        }
    }, [values.delivery_type]);

    useEffect(() => {
        if (carrier) {
            dispatch(getTransportsAsync(carrier))
        }
    }, [carrier]);

    useEffect(() => {
        if (customer) {
            onChangeCustomerIsFreightForwarder(customer_is_freight_forwarder)
        }
    }, [])

    useEffect(() => {
        if (freight_forwarder) {
            onChangeFreightForwarderIsCarrier(freight_forwarder_is_carrier)
        }
    }, []);

    useEffect(() => {
        if (responsible_person) {
            onChangeResponsiblePersonIsDriver(responsible_person_is_driver)
        }
    }, []);

    const onChangeExecutorIsFreightForwarder = (e) => {
        if (e) {
            setValue('freight_forwarder.inn_or_pinfl', values.executor.inn_or_pinfl)
            setValue('freight_forwarder.name', values.executor.name)
        } else {
            setValue('freight_forwarder.inn_or_pinfl', null)
            setValue('freight_forwarder.name', null)
        }
    }
    const onChangeCustomerIsFreightForwarder = (e) => {
        if (e) {
            setValue('freight_forwarder.inn_or_pinfl', values.customer.inn_or_pinfl)
            setValue('freight_forwarder.name', values.customer.name)
        } else {
            setValue('freight_forwarder.inn_or_pinfl', null)
            setValue('freight_forwarder.name', null)
        }
    }
    const onChangeFreightForwarderIsCarrier = (e) => {
        if (e) {
            setValue('carrier.inn_or_pinfl', values.freight_forwarder.inn_or_pinfl)
            setValue('carrier.name', values.freight_forwarder.name)
        } else {
            setValue('carrier.inn_or_pinfl', null)
            setValue('carrier.name', null)
        }
    }
    const onChangeResponsiblePersonIsDriver = (e) => {
        if (e) {
            setValue('driver.inn_or_pinfl', values.responsible_person.inn_or_pinfl)
            setValue('driver.name', values.responsible_person.name)
        } else {
            setValue('driver.inn_or_pinfl', null)
            setValue('driver.name', null)
        }
    }

    const changeClientEmployer = (option, e) => {
        let index
        if (option) {
            index = clientEmployer.findIndex(i => i.type === e)
            if (index !== -1) clientEmployer.splice(index, 1, {...option, type: e})
            else clientEmployer.push({...option, type: e})
        } else {
            index = clientEmployer.findIndex(i => i.type === e)
            if (index !== -1) clientEmployer.splice(index, 1)
        }
        setClientEmployer([...clientEmployer])
    }

    return (
        <Fragment>
            <div className={'grid-cols-2 gap-4 mt-3'}>
                <div className={' w-100'}>
                    <h4 className={'fs-8 fw-medium second-text'}>{t(lang, "roaming.waybill.v2.send.executor")}</h4>
                    <Card className={'p-1 d-flex flex-column gap-1 h-86'}>
                        <Form.Group as={Col} sm={12}>
                            <Controller
                                name={`executor.inn_or_pinfl`}
                                control={control}
                                rules={{
                                    required: t(lang, "edi.common.forms.validations.is_required")
                                }}
                                render={({field}) => (
                                    <FindCompanySearchable
                                        onChange={(executor) => {
                                            field.onChange(executor ? executor?.person_num ? executor?.person_num : executor?.inn : null)
                                            setValue('executor.name', executor?.short_name || executor?.name)
                                            changeClientEmployer(executor, 'executor')
                                        }}
                                        defaultInn={field.value}
                                        disabled={activeOrganization?.inn && true}
                                        placeholder={<>
                                            {t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl")}
                                            <span className="text-primary-davr">*</span>
                                        </>}
                                        inputProps={{
                                            className: `${errors?.executor?.inn_or_pinfl && 'is-invalid'} w-100`,
                                        }}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.executor?.inn_or_pinfl?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} sm={12}>
                            <FloatingLabel label={<>
                                {t(lang, 'roaming.waybill.send.members_info_form.customer.name')}
                                <span className="text-primary-davr">*</span>
                            </>}>
                                <Form.Control
                                    type="text"
                                    readOnly={activeOrganization?.name && true}
                                    placeholder={<>
                                        {t(lang, 'roaming.waybill.send.members_info_form.customer.name')}
                                        <span className="text-primary-davr">*</span>
                                    </>}
                                    {...register('executor.name')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Controller
                            control={control}
                            name={'executor_is_freight_forwarder'}
                            render={({field}) => (
                                <Form.Check
                                    disabled={customer_is_freight_forwarder}
                                    id='executor_is_freight_forwarder'
                                    type={'checkbox'}
                                    style={{right: '10px'}}
                                    label='Как перевозщик'
                                    {...register('executor_is_freight_forwarder')}
                                    defaultChecked={field.value}
                                    onChange={(e) => {
                                        onChangeExecutorIsFreightForwarder(e.target.checked)
                                        field.onChange(e.target.checked)
                                    }}
                                />
                            )}
                        />
                    </Card>
                </div>

                <div className={' w-100'}>
                    <h4 className={'fs-8 fw-medium second-text'}>{t(lang, "roaming.waybill.v2.send.customer")}</h4>
                    <Card className={'p-1 d-flex flex-column gap-1 h-86'}>
                        <Form.Group as={Col} sm={12}>
                            <Controller
                                name={`customer.inn_or_pinfl`}
                                control={control}
                                rules={{
                                    required: t(lang, "edi.common.forms.validations.is_required")
                                }}
                                render={({field}) => (
                                    <FindCompanySearchable
                                        onChange={(customer) => {
                                            field.onChange(customer ? customer?.person_num ? customer?.person_num : customer?.inn : null)
                                            setValue('customer.name', customer?.short_name || customer?.name)
                                            changeClientEmployer(customer, 'customer')
                                        }}
                                        defaultInn={field.value}
                                        disabled={values.delivery_type === 1}
                                        placeholder={<>
                                            {t(lang, "roaming.waybill.v2.send.customer")}
                                            <span className="text-primary-davr">*</span>
                                        </>}
                                        inputProps={{
                                            className: `${errors?.customer?.inn_or_pinfl && 'is-invalid'} w-100`,
                                        }}
                                    />
                                )}
                            />
                            <Form.Control.Feedback className={'d-block'} type="invalid">
                                {errors?.customer?.inn_or_pinfl?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} sm={12}>
                            <FloatingLabel label={<>
                                {t(lang, 'roaming.waybill.send.members_info_form.customer.name')}
                                <span className="text-primary-davr">*</span>
                            </>}>
                                <Form.Control
                                    type="text"
                                    readOnly={true}
                                    placeholder={<>
                                        {t(lang, 'roaming.waybill.send.members_info_form.customer.name')}
                                        <span className="text-primary-davr">*</span>
                                    </>}
                                    {...register('customer.name')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Controller
                            control={control}
                            name={'customer_is_freight_forwarder'}
                            render={({field}) => (
                                <Form.Check
                                    disabled={executor_is_freight_forwarder}
                                    id='customer_is_freight_forwarder'
                                    type={'checkbox'}
                                    style={{right: '10px'}}
                                    label='Как перевозщик'
                                    defaultChecked={field.value}
                                    onChange={(e) => {
                                        onChangeCustomerIsFreightForwarder(e.target.checked)
                                        field.onChange(e.target.checked)
                                    }}
                                />
                            )}/>
                    </Card>
                </div>

                <div className={' w-100'}>
                    <h4 className={'fs-8 fw-medium second-text'}>{t(lang, "roaming.waybill.v2.send.freight_forwarder")}</h4>
                    <Card className={'p-1 d-flex flex-column gap-1 h-86'}>
                        <Form.Group as={Col} sm={12}>
                            <Controller
                                name={`freight_forwarder.inn_or_pinfl`}
                                control={control}
                                rules={{
                                    required: t(lang, "edi.common.forms.validations.is_required")
                                }}
                                render={({field}) => (
                                    <FindCompanySearchable
                                        disabled={shipmentId ? false : (values.executor_is_freight_forwarder || values.customer_is_freight_forwarder)}
                                        onChange={(freight_forwarder) => {
                                            field.onChange(freight_forwarder ? freight_forwarder?.person_num ? freight_forwarder?.person_num : freight_forwarder?.inn : null)
                                            // field.onChange(freight_forwarder ? freight_forwarder?.inn : null)
                                            setValue('freight_forwarder.name', freight_forwarder?.short_name || freight_forwarder?.name)
                                        }}
                                        defaultInn={field.value}
                                        placeholder={<>
                                            {t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl")}
                                            <span className="text-primary-davr">*</span>
                                        </>}
                                        inputProps={{

                                            className: `${errors?.freight_forwarder?.inn_or_pinfl && 'is-invalid'} w-100`,
                                            placeholder: t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl")
                                        }}
                                    />
                                )}
                            />
                            <Form.Control.Feedback className={'d-block'} type="invalid">
                                {errors?.freight_forwarder?.inn_or_pinfl?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} sm={12}>
                            <FloatingLabel label={<>
                                {t(lang, 'roaming.waybill.send.members_info_form.customer.name')}
                                <span className="text-primary-davr">*</span>
                            </>}>
                                <Form.Control
                                    type="text"
                                    readOnly={true}
                                    placeholder={<>
                                        {t(lang, 'roaming.waybill.send.members_info_form.customer.name')}
                                        <span className="text-primary-davr">*</span>
                                    </>}
                                    {...register('freight_forwarder.name')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Controller
                            control={control}
                            name={'freight_forwarder_is_carrier'}
                            render={({field}) => (
                                <Form.Check
                                    id='freight_forwarder_is_carrier'
                                    type={'checkbox'}
                                    style={{right: '10px'}}
                                    label='Как доставшик'
                                    defaultChecked={field.value}
                                    onChange={(e) => {
                                        onChangeFreightForwarderIsCarrier(e.target.checked)
                                        field.onChange(e.target.checked)
                                    }}
                                />
                            )}/>
                    </Card>
                </div>

                <div className={' w-100'}>
                    <h4 className={'fs-8 fw-medium second-text'}>{t(lang, "roaming.waybill.v2.send.carrier")}</h4>
                    <Card className={'p-1 d-flex flex-column gap-1 h-86'}>
                        <Form.Group as={Col} sm={12}>
                            <Controller
                                name={`carrier.inn_or_pinfl`}
                                control={control}
                                rules={{
                                    required: t(lang, "edi.common.forms.validations.is_required")
                                }}
                                render={({field}) => (
                                    <FindCompanySearchable
                                        placeholder={<>
                                            {t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl")}
                                            <span className="text-primary-davr">*</span>
                                        </>}
                                        disabled={values.freight_forwarder_is_carrier}
                                        onChange={(carrier) => {
                                            field.onChange(carrier ? carrier?.person_num ? carrier?.person_num : carrier?.inn : null)
                                            if (carrier) {
                                                setValue('carrier.name', carrier?.short_name || carrier?.name)
                                            } else if (!isEditing) {
                                                setValue('carrier.name', null)
                                            }
                                        }}
                                        defaultInn={field.value}
                                        inputProps={{
                                            className: `${errors?.carrier?.inn_or_pinfl && 'is-invalid'} w-100`,
                                        }}
                                    />
                                )}
                            />
                            <Form.Control.Feedback className={'d-block'} type="invalid">
                                {errors?.carrier?.inn_or_pinfl?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} sm={12}>
                            <FloatingLabel label={<>
                                {t(lang, 'roaming.waybill.send.members_info_form.customer.name')}<span
                                className="text-primary-davr">*</span>
                            </>}>
                                <Form.Control
                                    type="text"
                                    readOnly={true}
                                    placeholder={<>
                                        {t(lang, 'roaming.waybill.send.members_info_form.customer.name')}<span
                                        className="text-primary-davr">*</span>
                                    </>}
                                    {...register('carrier.name')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                    </Card>
                </div>

                {
                    !(
                        values.executor.inn_or_pinfl === values.freight_forwarder.inn_or_pinfl ||
                        values.customer.inn_or_pinfl === values.freight_forwarder.inn_or_pinfl) &&
                    <div className={' w-100'}>
                        <h4 className={'fs-8 fw-medium second-text'}>{t(lang, "roaming.waybill.v2.send.freight_forwarder.customer")}</h4>
                        <Card className={'p-1 d-flex flex-column gap-1 h-86'}>
                            <Form.Group as={Col} sm={12}>
                                <Controller
                                    name={'client_inn_or_pinfl'}
                                    rules={{
                                        required: t(lang, "edi.common.forms.validations.is_required")
                                    }}
                                    render={({field}) => (
                                        <SelectClientEmployer
                                            executorIsCustomer={values.executor.inn_or_pinfl === values.customer.inn_or_pinfl}
                                            options={clientEmployer}
                                            onChange={(option) => {
                                                field.onChange(option?.inn)
                                            }}
                                            placeholder={<>
                                                {t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl")}
                                                <span className="text-primary-davr">*</span>
                                            </>}
                                            defaultId={field.value}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback className={'d-block'} type="invalid">
                                    {errors?.client_inn_or_pinfl?.message}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <div>
                                <Row>
                                    <Form.Group as={Col} sm={6}>
                                        <FloatingLabel label={<>
                                            {t(lang, "roaming.waybill.send.details_form.contract_number")}
                                            <span className="text-primary-davr">*</span>
                                        </>}>
                                            <Form.Control
                                                type="text"
                                                placeholder={<>
                                                    {t(lang, "roaming.waybill.send.details_form.contract_number")}
                                                    <span className="text-primary-davr">*</span>
                                                </>}
                                                {...register('client_contract_info.number', {
                                                    required: t(lang, "edi.common.forms.validations.is_required")
                                                })}
                                            />
                                        </FloatingLabel>
                                        <Form.Control.Feedback className={'d-block'} type="invalid">
                                            {errors?.client_contract_info?.number?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} sm={6}>
                                        <Controller
                                            name={"client_contract_info.date"}
                                            control={control}
                                            render={({field}) => (
                                                <DatePickerComponent
                                                    field={field}
                                                    isClearable
                                                    dateFormat={dateFormat}
                                                    onChange={(date) => field.onChange(date)}
                                                    className={'form-control'}
                                                    placeholderText={<>
                                                        {t(lang, "roaming.waybill.send.details_form.contract_date")}
                                                        <span className="text-primary-davr">*</span>
                                                    </>}
                                                />
                                            )}
                                        />
                                        <Form.Control.Feedback className={'d-block'} type="invalid">
                                            {errors?.client_contract_info?.date?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </div>
                        </Card>
                    </div>
                }

                {values.freight_forwarder.inn_or_pinfl !== values.carrier.inn_or_pinfl &&
                    <Col sm={12} lg={6} className={' w-100'}>
                        <h4 className={'fs-8 fw-medium second-text'}>{t(lang, "roaming.waybill.view.customer")}</h4>
                        <Card className={'p-1 d-flex flex-column gap-1 h-86'}>
                            <Form.Group as={Col} sm={12}>
                                <FloatingLabel label={<>
                                    {t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl")}
                                    <span className="text-primary-davr">*</span>
                                </>}>
                                    <Form.Control
                                        value={freight_forwarder ? `${freight_forwarder} - ${values.freight_forwarder.name}` : ""}
                                        placeholder={<>
                                            {t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl")}
                                            <span className="text-primary-davr">*</span>
                                        </>}
                                        disabled={true}
                                    />
                                </FloatingLabel>
                            </Form.Group>
                            <div>
                                <Row>
                                    <Form.Group as={Col} sm={6}>
                                        <FloatingLabel label={<>
                                            {t(lang, "roaming.waybill.send.details_form.contract_number")}
                                            <span className="text-primary-davr">*</span>
                                        </>}>
                                            <Form.Control
                                                type="text"
                                                placeholder={<>
                                                    {t(lang, "roaming.waybill.send.details_form.contract_number")}
                                                    <span className="text-primary-davr">*</span>
                                                </>}
                                                {...register('payer_contract_info.number', {
                                                    required: t(lang, "edi.common.forms.validations.is_required")
                                                })}
                                            />
                                        </FloatingLabel>
                                        <Form.Control.Feedback className={'d-block'} type="invalid">
                                            {errors?.payer_contract_info?.number?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={6}>
                                        <Controller
                                            name={"payer_contract_info.date"}
                                            control={control}
                                            render={({field}) => (
                                                <DatePickerComponent
                                                    field={field}
                                                    isClearable
                                                    dateFormat={dateFormat}
                                                    placeholderText={<>
                                                        {t(lang, "roaming.waybill.send.details_form.contract_date")}
                                                        <span className="text-primary-davr">*</span>
                                                    </>}
                                                />
                                            )}
                                        />
                                        <Form.Control.Feedback className={'d-block'} type="invalid">
                                            {errors?.payer_contract_info?.date?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                }

                <div className={' w-100'}>
                    <h4 className={'fs-8 fw-medium second-text'}>{t(lang, "roaming.waybill.v2.send.responsible_person")}</h4>
                    <Card className={'p-1 d-flex flex-column gap-1 h-86'}>
                        <Form.Group as={Col} sm={12}>
                            <Controller
                                name={`responsible_person.inn_or_pinfl`}
                                control={control}
                                rules={{
                                    required: t(lang, "edi.common.forms.validations.is_required")
                                }}
                                render={({field}) => (
                                    <FindCompanySearchable
                                        placeholder={<>
                                            {t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl")}
                                            <span className="text-primary-davr">*</span>
                                        </>}
                                        onChange={(responsible_person) => {
                                            field.onChange(responsible_person ? responsible_person?.director_pinfl ? responsible_person?.director_pinfl : responsible_person?.person_num ? responsible_person?.person_num : responsible_person?.inn : null)
                                            setValue('responsible_person.name', responsible_person?.short_name || responsible_person?.name)
                                        }}
                                        defaultInn={field.value}
                                        inputProps={{
                                            className: `${errors?.responsible_person?.inn_or_pinfl && 'is-invalid'} w-100`,
                                        }}
                                    />
                                )}
                            />
                            <Form.Control.Feedback className={'d-block'} type="invalid">
                                {errors?.responsible_person?.inn_or_pinfl?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} sm={12}>
                            <FloatingLabel label={<>
                                {t(lang, 'roaming.waybill.send.members_info_form.customer.name')}<span
                                className="text-primary-davr">*</span>
                            </>}>
                                <Form.Control
                                    type="text"
                                    readOnly={true}
                                    placeholder={<>
                                        {t(lang, 'roaming.waybill.send.members_info_form.customer.name')}<span
                                        className="text-primary-davr">*</span>
                                    </>}
                                    {...register('responsible_person.name')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        {+transportType === Roadway && <Controller
                            control={control}
                            name={'responsible_person_is_driver'}
                            render={({field}) => (
                                <Form.Check
                                    id='responsible_person_is_driver'
                                    type={'checkbox'}
                                    style={{right: '10px'}}
                                    label='Как водитель'
                                    defaultChecked={field.value}
                                    onChange={(e) => {
                                        onChangeResponsiblePersonIsDriver(e.target.checked)
                                        field.onChange(e.target.checked)
                                    }}
                                />
                            )}/>}
                    </Card>
                </div>

                {
                    <div className={' w-100'}>
                        <h4 className={'fs-8 fw-medium second-text'}>{t(lang, "roaming.waybill.v2.send.driver")}</h4>
                        <Card className={'p-1 d-flex flex-column gap-1 h-86'}>
                            <Form.Group as={Col} sm={12}>
                                <Controller
                                    name={`driver.inn_or_pinfl`}
                                    control={control}
                                    rules={{
                                        required: t(lang, "edi.common.forms.validations.is_required")
                                    }}
                                    render={({field}) => (
                                        <FindCompanySearchable
                                            placeholder={<>
                                                {t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl")}
                                                <span className="text-primary-davr">*</span>
                                            </>}
                                            disabled={values.responsible_person_is_driver}
                                            onChange={(driver) => {
                                                field.onChange(driver ? driver?.person_num ? driver?.person_num : driver?.inn : null)
                                                setValue('driver.name', driver?.short_name || driver?.name)
                                            }}
                                            defaultInn={field.value}
                                            inputProps={{
                                                className: `${errors?.driver?.inn_or_pinfl && 'is-invalid'} w-100`,
                                            }}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback className={'d-block'} type="invalid">
                                    {errors?.driver?.inn_or_pinfl?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} sm={12}>
                                <FloatingLabel label={<>
                                    {t(lang, 'roaming.waybill.send.members_info_form.customer.name')}<span
                                    className="text-primary-davr">*</span>
                                </>}>
                                    <Form.Control
                                        type="text"
                                        readOnly={true}
                                        placeholder={<>
                                            {t(lang, 'roaming.waybill.send.members_info_form.customer.name')}<span
                                            className="text-primary-davr">*</span>
                                        </>}
                                        {...register('driver.name')}
                                    />
                                </FloatingLabel>
                            </Form.Group>
                        </Card>
                    </div>
                }
            </div>
        </Fragment>
    );
};

export default MembersInfoFormV2;
