import React, {Fragment} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {changeDateFormat, DateFormatOptions, selectDateFormat, selectLang} from "../../../store/reducers/main/main";
import dayjs from "dayjs";
import {FloatingLabel, Form} from "react-bootstrap";
import {Translate} from "../../../lang/lang";

const DateFormatChooser = () => {
    const dateFormat = useSelector(selectDateFormat);
    const dispatch = useDispatch();
    const now = new Date();
    const lang = useSelector(selectLang);
    const t = Translate;
    const onChange = (dateFormat) => {
        dispatch(changeDateFormat(dateFormat));
    }
    return (
        <Fragment>
            <FloatingLabel label={t(lang, 'hippo.user.settings.profile.date')}>
                <Form.Select size="sm"
                             value={dateFormat}
                             onChange={(event) => onChange(event.target.value)}
                >
                    {DateFormatOptions.map(dateFormat => {
                        return (
                            <option value={dateFormat} key={dateFormat}>
                                {dayjs(now).format(dateFormat.toUpperCase())}
                            </option>
                        )
                    })}
                </Form.Select>
            </FloatingLabel>
        </Fragment>
    );
};

export default DateFormatChooser;
