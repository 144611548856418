import React from 'react';

const IconCloudUpload = ({width = 24, height = 24, color = "none"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 25 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10516_1122)">
                <path
                    d="M12.5 16V10M12.5 10L9.5 12M12.5 10L15.5 12M23.5 15C23.5 12.7909 21.7091 11 19.5 11C19.4764 11 19.4532 11.0002 19.4297 11.0006C18.9447 7.60802 16.0267 5 12.5 5C9.70335 5 7.29019 6.64004 6.16895 9.01082C3.56206 9.18144 1.5 11.3498 1.5 13.9999C1.5 16.7613 3.73858 19.0001 6.5 19.0001L19.5 19C21.7091 19 23.5 17.2091 23.5 15Z"
                    stroke="#58B3D3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_10516_1122">
                    <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default IconCloudUpload;
