import {createSlice} from "@reduxjs/toolkit";
import {
    activateCertificateAsync,
    createPkcs7WithTimestamp,
    setCurrentCertificate,
    unsetCurrentCertificate
} from "../eimzo/eimzoReducer";
import {CertificatePfx} from "../../../plugins/eimzo";
import AuthService from "../../services/auth/AuthService";
import authService from "../../services/auth/AuthService";
import EventBus from "../../../eventbus/EventBus";
import {
    ADD_USER_ORGANIZATION_SUCCESS,
    FORGOT_PASSWORD_REQUESTED,
    FORGOT_PASSWORD_RESTORE_FAILED,
    FORGOT_PASSWORD_RESTORED,
    LOGIN_FAILED,
    LOGIN_REQUESTED,
    LOGIN_SUCCESS,
    LOGOUT,
    REGISTRATION_FAILED,
    REGISTRATION_REQUESTED,
    REGISTRATION_SUCCESS,
    REMOVE_ACCOUNT_SESSION_FAILED,
    REMOVE_ACCOUNT_SESSION_REQUESTED,
    REMOVE_ACCOUNT_SESSION_SUCCEED,
    RESET_PASSWORD_FAILED,
    RESET_PASSWORD_REQUESTED,
    RESET_PASSWORD_SUCCESS
} from "../../../eventbus/authEvents";

export const INITIALIZATION_STATE_NOT_STARTED = 0
export const INITIALIZATION_STATE_LOADING = 1
export const INITIALIZATION_STATE_COMPLETE = 2

export const USER_TYPE_ADMIN = 1;

const STORAGE_IS_LOGIN_WITH_CERTIFICATE = "STORAGE_IS_LOGIN_WITH_CERTIFICATE"


const initialState = {
    isAuthorized: false,
    account: null,
    initializationState: INITIALIZATION_STATE_NOT_STARTED,
    users: [],
    clients: [],
    status: null
}

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state) => {
            state.isAuthorized = true;
        },
        logout: (state) => {
            state.isAuthorized = false;
            state.status = false
        },
        setAccount: (state, action) => {
            state.account = action.payload;
        },
        setUsers: (state, action) => {
            state.users = action.payload
        },
        setClients: (state, action)=> {
            state.clients = action.payload
        },
        setInitializationState: (state, action) => {
            state.initializationState = action.payload
        },
        setActiveOrganization(state, action) {
            const account = state.account;
            const id = action.payload;
            for (let i = 0; i < account.organizations.length; i++) {
                account.organizations[i].isActive = account.organizations[i].id === id;
            }
            state.account = account;
        },
        setIsLoginWithCertificate: (state, action) => {
            localStorage.setItem(STORAGE_IS_LOGIN_WITH_CERTIFICATE, JSON.stringify(action.payload));
        },
        unauthorized :(state) => {
            state.status = false
        }
    }
})


export const selectIsAuthorized = state => state.auth.isAuthorized;
export const authStatus = state => state.auth.status;
export const selectIsLoginWithCertificate = state => JSON.parse(localStorage.getItem(STORAGE_IS_LOGIN_WITH_CERTIFICATE)) || 0;
export const selectInitializationState = state => state.auth.initializationState;

export const selectAccount = state => state.auth.account;

export const selectIsAdmin = state => state.auth?.account?.type === USER_TYPE_ADMIN;

export const selectOrganizations = state => state.auth.account.organizations;

export const selectUsers = state => state.auth.users;

export const selectClients = state => state.auth.clients;

export const selectActiveOrganization = state => state.auth?.account?.organizations.find(o => o.isActive) || null

export const switchActiveOrganization = organization => async dispatch => {
    await authService.switchOrganization(organization.id);
    dispatch(authSlice.actions.setActiveOrganization(organization.id))
    dispatch(unsetCurrentCertificate())
};

export const getUsernameExistsAsync = username => {
    return new Promise((resolve) => {
        AuthService.getUsernameExist(username)
            .then(response => {
                resolve(response.data.exists);
            });
    });
};

export const getInnExistsAsync = inn => {
    return new Promise(resolve => {
        AuthService.getCompanyExist(inn)
            .then(response => {
                resolve(response.data.exists);
            });
    });
};

export const loginAsync = (username, password) => dispatch => {
    EventBus.dispatch(LOGIN_REQUESTED, {username, password});

    return new Promise((resolve, reject) => {
        AuthService.login(username, password)
            .then((response) => {
                dispatch(initializeAsync());
                dispatch(authSlice.actions.setIsLoginWithCertificate(0));
                resolve();
                localStorage.setItem("token", response.data.token)
            })
            .catch(() => {
                EventBus.dispatch(LOGIN_FAILED, username);
                reject()
            });
    })
};

export const loginWithCertificateAsync = (certificate) => async dispatch => {
    let errorMessage;
    await activateCertificateAsync(certificate)
        .then(async (activatedCertificate) => {
            const pfxCertificate = new CertificatePfx(certificate);
            const inn = pfxCertificate.innNumber || pfxCertificate.pinfl;
            await createPkcs7WithTimestamp(inn, activatedCertificate.keyId)
                .then(async ({pkcs7: signature, signatureHex, signerSerialNumber}) => {
                    await AuthService.loginWithCertificate(signature)
                        .then((response) => {
                            dispatch(setCurrentCertificate(activatedCertificate));
                            dispatch(initializeAsync(inn));
                            dispatch(authSlice.actions.setIsLoginWithCertificate(1));
                            localStorage.setItem("token", response.data.token)
                        })
                        .catch((error) => {
                            errorMessage = {message: "authentication.login.error.loginWithCertificate"}
                            EventBus.dispatch(LOGIN_FAILED, errorMessage);
                        })
                })
                .catch((error) => {
                    errorMessage = {message: "authentication.login.error.createPkcs7"}
                    EventBus.dispatch(LOGIN_FAILED, errorMessage);
                })
        })
        .catch((error) => {
            errorMessage = {message: "authentication.login.error.activateCertificateAsync"}
            EventBus.dispatch(LOGIN_FAILED, errorMessage);
        })
};

export const logoutAsync = () => dispatch => {
    AuthService.logout()
        .catch(e => {
            console.error(e);
        })
        .finally(() => {
            EventBus.dispatch(LOGOUT);
            dispatch(authSlice.actions.logout());
        });
}

export const sendForgotPasswordVerificationCodeAsync = username => {
    return new Promise((resolve, reject) => {
        AuthService.getForgotPasswordVerifyConfirmation(username).then(response => {
            resolve(response.data)
        }, reject)
    })
}

export const getRegistrationVerifyConfirmationTicketAsync = inn => {
    return new Promise((resolve, reject) => {
        AuthService.getRegistrationVerifyConfirmationTicket(inn).then(response => {
            resolve(response.data)
        }, reject)
    });
}

export const getRegistrationVerifyHashCodeAsync = inn => {
    return new Promise((resolve, reject) => {
        AuthService.getVerifyHashcode(inn).then(response => {
            resolve(response.data)
        }, reject)
    });
}

export const registerAsync = ({inn, name, fullName, signature, username, password, confirmCode, confirmTicket, referral_agent_code}) => {
    EventBus.dispatch(REGISTRATION_REQUESTED, {username});

    return new Promise((resolve, reject) => {
        AuthService.register({inn, name, fullName, signature, username, password, confirmCode, confirmTicket, referral_agent_code})
            .then(() => {
                EventBus.dispatch(REGISTRATION_SUCCESS, {username, password});
                resolve();
            })
            .catch(e => {
                EventBus.dispatch(REGISTRATION_FAILED, {username, inn});
                reject({
                    invalidConfirmationCode: e.response?.data?.invalid_confirmation_code || false,
                    referralAgentNotFound: e.response?.data?.referral_agent_not_found || false
                })

            })
    });
}

export const AddUserAsync = async ({username, password, name, confirmCode, confirmTicket}) => {
    try {
        const response = await AuthService.addUser({username, password, name, confirmCode, confirmTicket})
        EventBus.dispatch(ADD_USER_ORGANIZATION_SUCCESS, username);
        return response.data;
    }
    catch (error){
        let {invalid_confirmation_code} = error.response.data;
        throw {invalidConfirmationCodeUserAdd: invalid_confirmation_code};

    }
};

export const removeUserAsync = ({username}) => {
    return new Promise((resolve, reject) => {
        AuthService.deleteUser({username})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject()
            })
    });
};

export const getOrganizationUsersAsync = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        AuthService.getOrganizationUsers()
            .then(response => {
                dispatch(authSlice.actions.setUsers(response.data))
                resolve(response.data);
            })
            .catch(errors => console.log(errors))
    });
};

export const changePasswordAsync = (oldPassword, newPassword) => () => {
    EventBus.dispatch(RESET_PASSWORD_REQUESTED, {oldPassword, newPassword});

    AuthService.changePassword({oldPassword, newPassword}).then(() => {
        EventBus.dispatch(RESET_PASSWORD_SUCCESS);
    })
        .catch(() => {
            EventBus.dispatch(RESET_PASSWORD_FAILED, {oldPassword, newPassword});
        });
};

export const initializeAsync = (inn) => dispatch => {
    dispatch(authSlice.actions.setInitializationState(INITIALIZATION_STATE_LOADING));
    AuthService.getMe()
        .then(response => {
            EventBus.dispatch(LOGIN_SUCCESS, response.data.username);
            const account = response.data;

            for (let i = 0; i < account.organizations.length; i++) {
                if (account.organizations[i].id === account.active_organization.id) {
                    account.organizations[i].isActive = true;
                }
            }

            dispatch(authSlice.actions.setAccount(account));
            dispatch(authSlice.actions.login());
        })
        .catch(e => {
            dispatch(authSlice.actions.unauthorized())
            console.log(e);
        })
        .finally(() => {
            dispatch(authSlice.actions.setInitializationState(INITIALIZATION_STATE_COMPLETE));
        });
};

export const resetPasswordAsync = ({username, new_password, confirm_ticket, confirm_code}) => {
    return new Promise((resolve, reject) => {
        EventBus.dispatch(FORGOT_PASSWORD_REQUESTED, username)

        AuthService.resetPassword({
            username,
            new_password,
            confirm_ticket,
            confirm_code
        }).then(() => {
            EventBus.dispatch(FORGOT_PASSWORD_RESTORED, username);
            resolve();
        }).catch(error => {
            reject({
                invalidConfirmationCode: error?.response?.data?.invalid_confirmation_code
            });
            EventBus.dispatch(FORGOT_PASSWORD_RESTORE_FAILED);
        })
    });
};


export const loadOrganizationAccountsAsync = (id) => {
    return new Promise((resolve, reject) => {
        AuthService.getAdminOrganizationAccounts(id)
            .then(response => resolve(response.data))
            .catch(error => reject())
    })
}

export const loadAccountSessionsAsync = (id) => {
    return new Promise((resolve, reject) => {
        AuthService.getAdminAccountSessions(id)
            .then(response => resolve(response.data))
            .catch(error => reject())
    })
}

export const removeAccountSessionAsync = ({accountId, token}) => {
    EventBus.dispatch(REMOVE_ACCOUNT_SESSION_REQUESTED, accountId);

    return new Promise((resolve, reject) => {
        AuthService.removeAdminAccountSession({accountId, token})
            .then(response => {
                EventBus.dispatch(REMOVE_ACCOUNT_SESSION_SUCCEED, accountId);
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(REMOVE_ACCOUNT_SESSION_FAILED, error);
                reject(error);
            })
    })
}

export const getAdminClientCredentialsAsync = () => {
    return new Promise((resolve, reject) => {
        AuthService.getAdminClientCredentials()
            .then(response => {
                console.log(response)
                resolve(response)
            })
            .catch((error) => {
                console.log(error)
                reject()
            })
    })
}

export const registerAdminClientCredentialsAsync = () => {
    return new Promise((resolve, reject) => {
        AuthService.registerAdminClientCredentials()
            .then((response) => {
                console.log(response)
                resolve(response)
            })
            .catch((error) => {
                console.log(error)
                reject()
            })
    })
}

export const setOrganizationInn = (data) => {
    return new Promise((resolve, reject) => {
        AuthService.setOrganizationInn(data)
            .then(() => resolve(true))
            .catch(() => reject(true))
    })
}


export default authSlice.reducer
