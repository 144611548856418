import axios from "axios";

class SignService {
    getSignHashCode(id) {
        return axios.get(`/roaming/sign/${id}`)
    }

    signDocument(id, {operatorUid, operatorSignature, roamingSignature, comment, certificateSerial, certificateOwner}) {
        return axios.post(`/roaming/sign/${id}`, {
            operator_uid: operatorUid,
            operator_signature: operatorSignature,
            roaming_signature: roamingSignature,
            comment: comment,
            certificate_serial: certificateSerial,
            certificate_owner: certificateOwner
        })
    }
}

const singService = new SignService()
export default singService
