import {RouteObject, createBrowserRouter, createHashRouter} from 'react-router-dom'
import App from "./App";
import MainLayout from "./layout/MainLayout";
import RoamingInvoices from "./pages/roaming/invoice/RoamingInvoices";
import RoamingContracts from "./pages/roaming/contract/RoamingContracts";
import RoamingEmpowerments from "./pages/roaming/empowerment/RoamingEmpowerments";
import RoamingVerificationActs from "./pages/roaming/verification-act/RoamingVerificationActs";
import RoamingWaybills from "./pages/roaming/waybill/RoamingWaybills";
import RoamingActs from "./pages/roaming/act/RoamingActs";
import RoamingFreeForms from "./pages/roaming/free-forms/RoamingFreeForms";
import RoamingExcelRegistries from "./pages/roaming/excel-registries/RoamingExcelRegistries";
import RoamingLayout from "./layout/RoamingLayout";
import EdiLayout from "./layout/EdiLayout";
import EdiOrders from "./pages/edi/order/EdiOrders";
import EdiBranches from "./pages/edi/branches/EdiBranches";
import EdiContractFormations from "./pages/edi/contract-formations/EdiContractFormations";
import EdiAttorneys from "./pages/edi/attorneys/EdiAttorneys";
import EdiShipments from "./pages/edi/shipments/EdiShipments";
import EdiInvoices from "./pages/edi/invoices/EdiInvoices";
import EdiReturnInvoices from "./pages/edi/return-invoices/EdiReturnInvoices";
import EdiReturnShipmentsDataTable from "./components/edi/return-shipment/EdiReturnShipmentsDataTable";
import EdiReturnOrders from "./pages/edi/return-orders/EdiReturnOrders";
import SettingLayout from "./layout/SettingLayout";
import InfoOrganization from "./pages/settings/InfoOrganization";
import Systems from "./pages/settings/Systems";
import Sample from "./pages/settings/Sample";
import Role from "./pages/settings/Role";
import UserLayout from "./layout/UserLayout";
import Billing from "./pages/user/Billing";
import Currency from "./pages/user/Currency";
import Notification from "./pages/user/Notification";
import AuthLayout from "./layout/AuthLayout";
import SignIn from "./pages/authentication/sign-in/SignIn";
import SignOut from "./pages/authentication/sign-out/SignOut";
import Register from "./pages/authentication/sign-up/Register";
import ForgetPassword from "./pages/authentication/forget-password/ForgetPassword";
import Dashboard from './pages/dashboard/Dashboard';
import RoamingDashboard from "./pages/dashboard/RoamingDashboard";
import EdiDashboard from "./pages/dashboard/EdiDashboard";
import React from "react";
import AddOrder from './components/edi/order/AddOrder';
import OrderView from "./pages/edi/order/OrderView";
import ShipmentView from "./pages/edi/shipments/ShipmentView";
import InvoiceView from "./pages/edi/invoices/InvoiceView";
import InvoiceRegister from "./components/edi/invoice/forms/InvoiceRegister";
import AddShipment from "./pages/edi/shipments/AddShipment";
import ShipmentExecutorEdit from "./pages/edi/shipments/ShipmentExecutorEdit";
import ShipmentCustomerEdit from "./pages/edi/shipments/ShipmentCustomerEdit";
import ViewReturnOrder from "./pages/edi/return-orders/ViewReturnOrder";
import ReturnShipment from "./pages/edi/return-shipments/ReturnShipment";
import RegisterReturnOrder from "./pages/edi/return-orders/RegisterReturnOrder";
import ViewReturnShipment from "./pages/edi/return-shipments/ViewReturnShipment";
import RegisterReturnShipment from "./pages/edi/return-shipments/RegisterReturnShipment";
import ViewReturnInvoice from "./pages/edi/return-invoices/ViewReturnInvoice";
import ReturnInvoiceRegister from "./pages/edi/return-invoices/ReturnInvoiceRegister";
import ViewAttorney from "./pages/edi/attorneys/ViewAttorney";
import AddAttorney from "./pages/edi/attorneys/AddAttorney";
import MassImportAttorney from "./pages/edi/attorneys/MassImportAttorney";
import EditAttorney from "./pages/edi/attorneys/EditAttorney";
import ViewContractFormation from "./pages/edi/contract-formations/ViewContractFormation";
import EditContractFormation from "./pages/edi/contract-formations/EditContractFormation";
import RegisterContractFormation from "./pages/edi/contract-formations/RegisterContractFormation";
import {VIEW_INBOX} from "./enum/roaming/TypeViewers";
import RoamingNavigate from "./components/roaming/RoamingNavigate";
import ViewContract from "./pages/roaming/contract/ViewContract";
import ViewDraftContract from "./pages/roaming/contract/ViewDraftContract";
import SendContract from "./pages/roaming/contract/SendContract";
import EditContract from "./pages/roaming/contract/EditContract";
import FillContract from "./pages/roaming/contract/FillContract";
import ViewRoamingInvoice from "./pages/roaming/invoice/ViewRoamingInvoice";
import ViewRoamingInvoiceDraft from "./pages/roaming/invoice/ViewRoamingInvoiceDraft";
import SendRoamingInvoice from "./pages/roaming/invoice/SendRoamingInvoice";
import MassAddRoamingInvoice from "./pages/roaming/invoice/MassAddRoamingInvoice";
import ViewEmpowerment from "./pages/roaming/empowerment/ViewEmpowerment";
import SendEmpowerment from "./pages/roaming/empowerment/SendEmpowerment";
import FillEmpowerment from "./pages/roaming/empowerment/FillEmpowerment";
import ViewVerificationAct from "./pages/roaming/verification-act/ViewVerificationAct";
import ViewDraftVerificationAct from "./components/roaming/verification-act/view/ViewDraftVerificationAct";
import SendVerificationAct from "./pages/roaming/verification-act/SendVerificationAct";
import EditVerificationAct from "./pages/roaming/verification-act/EditVerificationAct";
import FillVerificationAct from "./pages/roaming/verification-act/FillVerificationAct";
import ViewWaybill from "./pages/roaming/waybill/ViewWaybill";
import ViewDraftWaybill from "./pages/roaming/waybill/ViewDraftWaybill";
import SendWaybill from "./pages/roaming/waybill/SendWaybill";
import EditWaybill from "./components/roaming/waybill/edit/EditWaybill";
import FillWaybill from "./components/roaming/waybill/FillWaybill";
import DraftActView from "./pages/roaming/act/DraftActView";
import ViewAct from "./pages/roaming/act/ViewAct";
import SendAct from "./pages/roaming/act/SendAct";
import DraftActEdit from "./pages/roaming/act/DraftActEdit";
import FillAct from "./pages/roaming/act/FillAct";
import ViewFreeFormDocument from "./pages/roaming/free-forms/ViewFreeFormDocument";
import SendFreeFormDocument from "./pages/roaming/free-forms/SendFreeFormDocument";
import ViewExcelRegistry from "./pages/roaming/excel-registries/ViewExcelRegistry";
import Error404 from "./pages/error/Error404";
import Authentication from "./pages/authentication/Authentication";
import LoginWithCertificate from "./components/form/auth/login/LoginWithCertificate";
import LoginWithUsername from "./components/form/auth/login/LoginWithUsername";
import RegistrationWithoutInn from "./pages/authentication/sign-up/RegistrationWithoutInn";
import WarehouseLayout from "./layout/WarehouseLayout";
import ItemLayout from "./layout/ItemLayout";
import ItemDataTable from "./pages/warehouse/item/ItemDataTable";
import DraftEmpowerment from "./pages/roaming/empowerment/DraftEmpowerment";
import Packages from "./components/user/billing/Packages";
import BillingLayout from "./layout/BillingLayout";
import WaybillsV2 from "./pages/roaming/waybill-v2/WaybillsV2";
import SendWaybillV2 from "./pages/roaming/waybill-v2/SendWaybillV2";
import EditWaybillV2 from "./pages/roaming/waybill-v2/EditWaybillV2";
import ViewDraftWaybillV2 from "./pages/roaming/waybill-v2/ViewDraftWaybillV2";
import ViewWaybillV2 from "./pages/roaming/waybill-v2/ViewWaybillV2";
import ChooseOrganization from "./components/form/auth/chooseOrganization/ChooseOrganization";

const routes = [
    {
        element: <App/>,
        children: [
            {
                path: "/authentication/",
                element: <AuthLayout />,
                children: [
                    {
                        path: '/authentication/',
                        element: <Authentication />
                    },
                    {
                        path: 'login',
                        element: <LoginWithUsername />
                    },
                    {
                        path: 'select-organization',
                        element: <ChooseOrganization/>
                    },
                    {
                        path: 'login/with-certificate',
                        element: <LoginWithCertificate />
                    },
                    {
                        path: 'logout',
                        element: <SignOut />
                    },
                    {
                        path: 'register/without-inn',
                        element: <RegistrationWithoutInn />
                    },
                    {
                        path: 'register',
                        element: <Register />
                    },
                    {
                        path: 'forgot-password',
                        element: <ForgetPassword />
                    }
                ]
            },
            {
                path: "/",
                element: (
                    <MainLayout/>
                ),
                children: [
                    {
                      path: "*",
                      element: <Error404 />
                    },
                    {
                        path: "/",
                        element: (
                            <RoamingLayout/>
                        ),
                        children: [
                            {
                                index: true,
                                element: <RoamingDashboard />
                            },
                            // INVOICE
                            {
                                path: "/roaming/invoice/send",
                                element: <SendRoamingInvoice/>,
                            },
                            {
                                path: "/roaming/invoice/mass-add",
                                element: <MassAddRoamingInvoice/>,
                            },
                            {
                                path: "/roaming/invoice/:id",
                                element: <ViewRoamingInvoice/>,
                            },
                            {
                                path: "/roaming/invoice/draft/:id",
                                element: <ViewRoamingInvoiceDraft/>,
                            },
                            {
                                path: "/roaming/invoices/:viewer",
                                element: <RoamingInvoices/>,
                            },
                            // CONTRACT
                            {
                                path: "/roaming/contract/:id",
                                element: <ViewContract/>,
                            },
                            {
                                path: "/roaming/contract/draft/edit/:id",
                                element: <EditContract/>,
                            },
                            {
                                path: "/roaming/contract/draft/:id",
                                element: <ViewDraftContract/>,
                            },
                            {
                                path: "/roaming/contract/actions/:id",
                                element: <FillContract/>,
                            },
                            {
                                path: "/roaming/contract/send",
                                element: <SendContract/>,
                            },
                            {
                                path: "/roaming/contracts/:type_viewer",
                                element: <RoamingContracts/>,
                            },
                            // EMPOWERMENT
                            {
                                path: "/roaming/empowerment/send",
                                element: <SendEmpowerment/>
                            },
                            {
                                path: "/roaming/empowerment/draft-edit/:id",
                                element: <SendEmpowerment/>
                            },
                            {
                                path: "/roaming/empowerment/actions/:id",
                                element: <FillEmpowerment/>
                            },
                            {
                                path: "/roaming/empowerment/:id",
                                element: <ViewEmpowerment/>
                            },
                            {
                                path: "/roaming/draft-empowerment/:id",
                                element: <DraftEmpowerment/>
                            },
                            {
                                path: "/roaming/empowerments/:viewer",
                                element: <RoamingEmpowerments/>
                            },
                            // VERIFICATION ACT
                            {
                                path: "/roaming/verification-act/send",
                                element: <SendVerificationAct/>
                            },
                            {
                                path: "/roaming/verification-act/actions/:id",
                                element: <FillVerificationAct/>
                            },
                            {
                                path: "/roaming/verification-act/draft/edit/:id",
                                element: <EditVerificationAct/>
                            },
                            {
                                path: "/roaming/verification-act/draft/:id",
                                element: <ViewDraftVerificationAct/>
                            },
                            {
                                path: "/roaming/verification-act/:id",
                                element: <ViewVerificationAct/>
                            },
                            {
                                path: "/roaming/verification-acts/:type_viewer",
                                element: <RoamingVerificationActs/>
                            },
                            // WAYBILL
                            {
                                path: "/roaming/waybill/send",
                                element: <SendWaybill/>
                            },
                            {
                                path: "/roaming/waybill/edit/:id",
                                element: <EditWaybill/>
                            },
                            {
                                path: "/roaming/waybill/actions/:id",
                                element: <FillWaybill/>
                            },
                            {
                                path: "/roaming/waybill/draft/:id",
                                element: <ViewDraftWaybill/>
                            },
                            {
                                path: "/roaming/waybill/:id",
                                element: <ViewWaybill/>
                            },
                            {
                                path: "/roaming/waybills/:viewer",
                                element: <RoamingWaybills/>
                            },
                            // WAYBILL-V2
                            {
                                path: "/roaming/waybill-v2/send",
                                element: <SendWaybillV2 />
                            },
                            {
                                path: '/roaming/waybill-v2/edit/:id',
                                element: <EditWaybillV2 />
                            },
                            {
                                path: '/roaming/waybill-v2/draft/:id',
                                element: <ViewDraftWaybillV2 />
                            },
                            {
                                path: '/roaming/waybill-v2/:id',
                                element: <ViewWaybillV2 />
                            },
                            {
                                path: "/roaming/v2-waybills/:viewer",
                                element: <WaybillsV2 />
                            },
                            // ACT
                            {
                                path: "/roaming/act/register",
                                element: <SendAct/>
                            },
                            {
                                path: "/roaming/act/draft-edit/:id",
                                element: <DraftActEdit/>
                            },
                            {
                                path: "/roaming/act/actions/:id",
                                element: <FillAct/>
                            },
                            {
                                path: "/roaming/act/:id",
                                element: <ViewAct/>
                            },
                            {
                                path: "/roaming/draft-act/:id",
                                element: <DraftActView/>
                            },
                            {
                                path: "/roaming/acts/:type_viewer",
                                element: <RoamingActs/>
                            },
                            // FREE-FORM
                            {
                                path: "/roaming/free-forms/send",
                                element: <SendFreeFormDocument/>
                            },
                            {
                                path: "/roaming/free-form/:id",
                                element: <ViewFreeFormDocument/>
                            },
                            {
                                path: "/roaming/free-forms/:viewType",
                                element: <RoamingFreeForms/>
                            },
                            // EXCEL_REGISTRIES
                            {
                                path: `*`,
                                element: <RoamingNavigate/>
                            },
                        ]
                    },
                    {
                        path: "/user/",
                        element: <UserLayout />,
                        children: [
                            {
                                path: 'billing',
                                element: <Billing />
                            },
                            {
                                path: 'packages',
                                element: <Packages/>
                            },
                            {
                                path: 'currency',
                                element: <Currency />
                            },
                            {
                                path: 'notification',
                                element: <Notification />
                            },
                            {
                                path: 'info-organization',
                                element: <InfoOrganization />
                            },
                            {
                                path: 'systems',
                                element: <Systems />
                            },
                            {
                                path: 'sample',
                                element: <Sample />
                            },
                            {
                                path: 'role',
                                element: <Role />
                            },
                        ]
                    }
                ]
            },
        ]
    }
]

// export const router = createBrowserRouter(routes)
export const router = createHashRouter(routes)

export default routes
