import  {createSlice} from "@reduxjs/toolkit";
import EventBus from "../../../eventbus/EventBus";
import {EDI_SETTINGS_FAILED, EDI_SETTINGS_REQUEST, EDI_SETTINGS_SUCCESS} from "../../../eventbus/ediSettingsEvent";
import settingsService from "../../services/settings/settingsService";

const initialState = {}

const ediSettingsSlice = createSlice({
    name: "ediSettings",
    initialState,
    reducers:{}
})
export const selectEdiSettings = (state) => state.settings?.settings?.edi;

export const updateEdiSettings = ({payload}) => {
    EventBus.dispatch(EDI_SETTINGS_REQUEST)
    return new Promise((resolve, reject) => {
        settingsService.updateEdiSettings({payload})
            .then((response) => {
                EventBus.dispatch(EDI_SETTINGS_SUCCESS)
                resolve(response.data)
            })
            .catch((error) => {
                EventBus.dispatch(EDI_SETTINGS_FAILED)
                reject(error);
            })
    })
}

export default ediSettingsSlice.reducer
