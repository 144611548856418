import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import {ActStatuses} from "../../../enum/roaming/act/ActStatus";
import Select from "react-select";
import PropTypes from "prop-types";
import {roamingInvoiceTypes} from "../../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import CustomFloatingLabelSelect from "../../floating-custom-select/CustomFloatingLabelSelect";

const SelectActStatus = ({onChange, id, placeholder, defaultId, ...props}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const [value, setValue] = useState(null);

    const options = ActStatuses.map((status) => {
        return {
            value: status.id,
            label: t(lang, status.name),
        };
    })


    useEffect(() => {
        setValue(options.find(o => o.value === defaultId) || null)
    }, [defaultId]);

    const onChangeHandler = (option) => {
        onChange(option ? option.value : null);
        setValue(option)
    }

    return (
            <CustomFloatingLabelSelect
                value={value}
                label={placeholder}
                onChange={onChangeHandler}
                options={options}
                {...props}
            />
    );
};

SelectActStatus.propTypes = {
    onChange: PropTypes.func
}
SelectActStatus.defaultProps = {
    onChange: () => {
    }
}

export default SelectActStatus;
