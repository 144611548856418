import React, {Fragment} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectDateFormat, selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import DatePicker from "react-datepicker";
import {Col, Row, Form, Card, FloatingLabel} from "react-bootstrap";
import DatePickerComponent from "../../../common/DatePickerComponent";

const VerificationActInfo = () => {
    const {register, getFieldState, control} = useFormContext();
    const errors = getFieldState('verificationActDoc').error || [];
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Fragment>
            <h4 className={'fs-8 fw-medium second-text ms-3'}>{t(lang, "roaming.verification_act.add.verification_act.info.title")}</h4>

            <Card className="p-2">
                <Row>
                    <Col sm="6" className="mb-2">
                        <FloatingLabel label={t(lang, "roaming.verification_act.add.verification_act.info.number")}>
                            <Form.Control
                                {...register("verificationActDoc.verificationActNo", {
                                    required: "edi.common.forms.validations.is_required"
                                })}
                                className={errors?.verificationActNo?.message && "is-invalid"}
                                placeholder={t(lang, "roaming.verification_act.add.verification_act.info.number")}
                            />
                        </FloatingLabel>
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.verificationActNo?.message)}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-2">
                        <Controller
                            control={control}
                            name="verificationActDoc.verificationActDate"
                            rules={{
                                required: "edi.common.forms.validations.is_required"
                            }}
                            render={({field}) => (
                                <DatePickerComponent
                                    field={field}
                                    wrapperClassName={errors?.verificationActDate?.message && "is-invalid"}
                                    className={`${errors?.verificationActDate?.message && "is-invalid"} form-control`}
                                    placeholderText={t(lang, "roaming.verification_act.add.verification_act.info.date")}
                                />
                            )}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.verificationActDate?.message)}
                        </Form.Control.Feedback>
                    </Col>
                </Row>
            </Card>
        </Fragment>
    );
};

export default VerificationActInfo;
