import React, {Fragment} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {selectDateFormat, selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {Col, Row, Form, FormControl, Card, FloatingLabel} from "react-bootstrap";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import DatePickerComponent from "../../../common/DatePickerComponent";

const ActFormInfo = () => {
    const {register, formState: {errors}, control, watch} = useFormContext()
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;


    return (
        <Fragment>
            <h4 className={'fs-8 fw-medium second-text ms-3'}>{t(lang, "roaming.act_form.title")}</h4>
            <Card className="p-2">
                <Row>
                    <Col xs={12} md={6}>
                        <Row>
                            <Col xs={12} className="d-flex flex-column my-2">
                                <FloatingLabel label={t(lang, "roaming.act_form.act_number")}>
                                    <FormControl
                                        name="act.info"
                                        type="text"
                                        placeholder={t(lang, "roaming.act_form.act_number")}
                                        {...register("act.info", {
                                            required: t(lang, "edi.common.forms.validations.is_required")
                                        })}
                                        isInvalid={errors?.act?.info}
                                    />
                                </FloatingLabel>

                                <Form.Control.Feedback type="invalid">
                                    {errors?.act?.info?.message}
                                </Form.Control.Feedback>
                            </Col>
                            <Col xs={12} className="d-flex flex-column my-2">
                                <Controller
                                    control={control}
                                    name='act.date'
                                    render={({field}) => (
                                        <DatePickerComponent
                                            field={field}
                                            placeholderText={t(lang, "roaming.act_form.act_date")}
                                            wrapperClassName={classNames({
                                                'is-invalid': errors?.act?.date,
                                            })}
                                            className={classNames('form-control', {
                                                'is-invalid': errors?.act?.date,
                                            })}
                                            maxDate={new Date()}
                                        />
                                    )}
                                    rules={{
                                        required: t(lang, "edi.common.forms.validations.is_required"),
                                        validate: (value) => {
                                            if(value < watch('contract.date'))
                                                return t(lang, "roaming.act_form.date_validation");
                                            return true;
                                        }
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.act?.date?.message}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} md={6}>
                        <Row>
                            <Col xs={12} className="d-flex flex-column my-2">
                                <FloatingLabel label={t(lang, "roaming.act_form_contract_number")}>
                                    <FormControl
                                        name="contract.number"
                                        type="text"
                                        placeholder={t(lang, "roaming.act_form_contract_number")}
                                        {...register("contract.number", {
                                            required: t(lang, "edi.common.forms.validations.is_required"),
                                        })}
                                        isInvalid={errors?.contract?.number}
                                    />
                                </FloatingLabel>

                                <Form.Control.Feedback type="invalid">
                                    {errors?.contract?.number?.message}
                                </Form.Control.Feedback>
                            </Col>
                            <Col xs={12} className="d-flex flex-column my-2">
                                <Controller
                                    control={control}
                                    name='contract.date'
                                    render={({field}) => (
                                        <DatePickerComponent
                                            field={field}
                                            placeholderText={t(lang, "roaming.act_form_contract_date")}
                                            wrapperClassName={classNames({
                                                'is-invalid': errors?.contract?.date,
                                            })}
                                            dateFormat={dateFormat}
                                            className={classNames('form-control', {
                                                'is-invalid': errors?.contract?.date,
                                            })}
                                            maxDate={new Date()}
                                        />
                                    )}
                                    rules={{
                                        required: t(lang, "edi.common.forms.validations.is_required"),
                                        validate: (value) => {
                                            if(value > watch('act.date'))
                                                return t(lang, "roaming.act_form.date_validation");
                                            return true;
                                        }
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.contract?.date?.message}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Col>

                    <Col className="d-flex flex-column my-2">
                        <FloatingLabel label={t(lang, "roaming.act_form_act_comment")}>
                            <Form.Control as="textarea"
                                          style={{ height: '150px' }}
                                          {...register('ActComment', {
                                              required: t(lang, "edi.common.forms.validations.is_required")
                                          })}
                                          placeholder={t(lang, "roaming.act_form_act_comment")}
                                          className={classNames('form-control', {
                                              'is-invalid': errors?.ActComment,
                                          })}
                            />
                        </FloatingLabel>
                        <Form.Control.Feedback type="invalid">
                            {errors?.ActComment?.message}
                        </Form.Control.Feedback>
                    </Col>
                </Row>
            </Card>
        </Fragment>
    );
};

export default ActFormInfo;
