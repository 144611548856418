import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {Controller, useFormContext} from "react-hook-form";
import {Translate} from "../../../../lang/lang";
import {selectLang} from "../../../../store/reducers/main/main";
import {Col, Row, Form, Card, FloatingLabel} from "react-bootstrap";
import SelectDeliveryType from "../../../common/SelectDeliveryType";

const DeliveryDetailsForm = () => {
    const lang = useSelector(selectLang);
    const {register} = useFormContext();
    const t = Translate;

    const deliveryTypeOptions = [
        {value: 1, label: "roaming.waybill.send.delivery_details_form.delivery_type.from_warehouse_to_warehouse"},
        {value: 2, label: "roaming.waybill.send.delivery_details_form.delivery_type.from_seller_to_buyer"},
    ];

    const trailerTypeOptions = [
        {value: 1, label: "roaming.waybill.send.delivery_details_form.trailer_type.trailer"},
        {value: 2, label: "roaming.waybill.send.delivery_details_form.trailer_type.semi_trailer"},
    ]

    return (
        <Fragment>
            <h4 className={'fs-8 fw-medium second-text ms-3'}>{t(lang, 'roaming.waybill.send.delivery_details_form.title')}</h4>

            <Card className="p-2">
                <Row className={'mb-2'}>

                    <Col className="my-1" md="12">
                        <Form.Group>
                            <FloatingLabel label={t(lang, 'roaming.waybill.send.delivery_details_form.driver')}>
                                <Form.Control
                                    type="text"
                                    {...register('driver')}
                                    placeholder={t(lang, 'roaming.waybill.send.delivery_details_form.driver')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                    </Col>

                    <Col className="my-1" sx="12" md="4">
                        <Form.Group>
                            <Controller
                                name="delivery_type"
                                render={({field}) => (
                                    <SelectDeliveryType
                                        id={"delivery_type"}
                                        options={deliveryTypeOptions}
                                        placeholder={t(lang, 'roaming.waybill.send.delivery_details_form.delivery_type')}
                                        onChange={field.onChange}
                                        defaultType={field.value}
                                    />
                                )}
                            />
                        </Form.Group>
                    </Col>
                    <Col className="my-1" sx="12" md="4">
                        <Form.Group>
                            <FloatingLabel label={t(lang, 'roaming.waybill.send.delivery_details_form.truck_register_number')}>
                                <Form.Control
                                    type="text"
                                    {...register('truck.register_number')}
                                    placeholder={t(lang, 'roaming.waybill.send.delivery_details_form.truck_register_number')}
                                />
                            </FloatingLabel>

                        </Form.Group>
                    </Col>
                    <Col className="my-1" sx="12" md="4">
                        <Form.Group>
                            <FloatingLabel label={t(lang, 'roaming.waybill.send.delivery_details_form.trailer_register_number')}>
                                <Form.Control
                                    type="text"
                                    {...register('trailer.register_number')}
                                    placeholder={t(lang, 'roaming.waybill.send.delivery_details_form.trailer_register_number')}
                                />
                            </FloatingLabel>

                        </Form.Group>
                    </Col>

                    <Col className="my-1" sx="12" md="4">
                        <Form.Group>
                            <Controller
                                name="trailer.type"
                                render={({field}) => (
                                    <SelectDeliveryType
                                        defaultType={field.value}
                                        onChange={field.onChange}
                                        id={"trailer.type"}
                                        options={trailerTypeOptions}
                                        placeholder={t(lang, 'roaming.waybill.send.delivery_details_form.trailer_type')}
                                    />
                                )}
                            />
                        </Form.Group>
                    </Col>
                    <Col className="my-1" sx="12" md="4">
                        <Form.Group>
                            <FloatingLabel label={t(lang, 'roaming.waybill.send.delivery_details_form.truck_model')}>
                                <Form.Control
                                    type="text"
                                    {...register('truck.model')}
                                    placeholder={t(lang, 'roaming.waybill.send.delivery_details_form.truck_model')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                    </Col>
                    <Col className="my-1" sx="12" md="4">
                        <Form.Group>
                            <FloatingLabel label={t(lang, 'roaming.waybill.send.delivery_details_form.trailer_model')}>
                                <Form.Control
                                    type="text"
                                    {...register('trailer.model')}
                                    placeholder={t(lang, 'roaming.waybill.send.delivery_details_form.trailer_model')}
                                />
                            </FloatingLabel>
                        </Form.Group>
                    </Col>
                </Row>
            </Card>

        </Fragment>
    );
};

export default DeliveryDetailsForm;
