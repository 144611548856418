import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentCertificate} from "../../../store/reducers/eimzo/eimzoReducer";
import {selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {
    getWaybillV2CustomerCount,
    getWaybillV2DraftsCount,
    getWaybillV2ExecutorCount,
    getWaybillV2ResponsiblePersonCount,
    loadWaybillV2CustomerAsync,
    loadWaybillV2DraftsAsync,
    loadWaybillV2ExecutorAsync,
    loadWaybillV2ResponsiblePersonAsync,
    selectWaybillsV2FilterOptions,
    sendWaybillV2Async,
    transportTypeOptions,
    waybillAcceptAsync,
    waybillCancelAsync,
    waybillDeclineAsync,
    waybillResponsiblePersonAccept,
    waybillResponsiblePersonGive,
    waybillResponsiblePersonReject,
    waybillResponsiblePersonReturn,
    waybillResponsiblePersonTillReturn,
    waybillReturnAcceptAsync
} from "../../../store/reducers/roaming/waybill-v2/romaingWaybillV2Reducer";
import {selectMeasurements} from "../../../store/reducers/roaming/roamingReducer";
import {getHistoriesActions, WaybillV2Wrapper} from "../../../enum/roaming/waybill-v2/WaybillV2Wrapper";
import {RESPONSIBLE_PERSON, VIEW_DRAFTS, VIEW_INBOX, VIEW_OUTBOX} from "../../../enum/roaming/waybill/WaybillStatus";
import {
    CONSIGNOR_CANCEL_FAILED,
    CONSIGNOR_CANCEL_SUCCEED,
    CUSTOMER_ACCEPT_FAILED,
    CUSTOMER_ACCEPT_SUCCEED, CUSTOMER_DECLINE_FAILED,
    CUSTOMER_DECLINE_SUCCEED,
    RESPONSIBLE_PERSON_ACCEPT_FAILED,
    RESPONSIBLE_PERSON_ACCEPT_SUCCEED, RESPONSIBLE_PERSON_GIVE_FAILED,
    RESPONSIBLE_PERSON_GIVE_SUCCEED, RESPONSIBLE_PERSON_REJECT_FAILED,
    RESPONSIBLE_PERSON_REJECT_SUCCEED,
    RESPONSIBLE_PERSON_RETURN_FAILED,
    RESPONSIBLE_PERSON_RETURN_SUCCEED,
    RESPONSIBLE_PERSON_TILL_RETURN_FAILED,
    RESPONSIBLE_PERSON_TILL_RETURN_SUCCEED, RETURN_ACCEPT_FAILED,
    RETURN_ACCEPT_SUCCEED,
    WAYBILL_V2_SYNC_SUCCESS
} from "../../../eventbus/romaing/waybill-v2/roamingWaybillV2Events";
import EventBus from "../../../eventbus/EventBus";
import {DeliveryTypeOptions} from "../../../store/reducers/roaming/waybill/roamingWaybillReducer";
import toast from "react-hot-toast";
import ErrorAlert from "../../common/ErrorAlert";
import WarningAlert from "../../common/WarningAlert";
import {Card, Col, Row, Spinner, Table} from "react-bootstrap";
import {scrollEvent} from "../../../helpers/scrollEvent";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import Badge from "../../common/Badge";
import {getWaybillV2Status} from "../../../enum/roaming/waybill-v2/WaybillV2Status";
import WaybillV2ResponsiblePersonReject from "./action/WaybillV2ResponsiblePersonReject";
import WaybillV2ResponsiblePersonAccept from "./action/WaybillV2ResponsiblePersonAccept";
import WaybillV2ResponsiblePersonTillReturn from "./action/WaybillV2ResponsiblePersonTillReturn";
import WaybillV2ResponsiblePersonGive from "./action/WaybillV2ResponsiblePersonGive";
import WaybillV2ResponsiblePersonReturn from "./action/WaybillV2ResponsiblePersonReturn";
import WaybillV2CustomerAccept from "./action/WaybillV2CustomerAccept";
import WaybillV2CustomerDecline from "./action/WaybillV2CustomerDecline";
import WaybillV2ConsignorCancel from "./action/WaybillV2ConsignorCancel";
import WaybillV2ReturnAccepted from "./action/WaybillV2ReturnAccepted";
import IconButton from "../../IconButton";
import {Link} from "react-router-dom";
import {faClone, faSignature} from "@fortawesome/free-solid-svg-icons";
import SelectCertificateWrapperComponent from "../../common/SelectCertificateWrapperComponent";
import WaybillV2DraftDelete from "./WaybillV2DraftDelete";
import WaybillV2Sync from "./action/WaybillV2Sync";
import WaybillV2ViewTable from "./WaybillV2ViewTable";
import numeral from "numeral";
import WaybillHistoryActions from "./action/WaybillHistoryActions";
const WaybillV2 = ({waybill, content = null, loadWaybill}) => {

    const {viewer} = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isSending, setIsSending] = useState(false);
    const alertRef = useRef(null);
    const numberFormat = useSelector(selectNumberFormat);
    const filter = useSelector(selectWaybillsV2FilterOptions);
    const measurements = useSelector(selectMeasurements);
    const lang = useSelector(selectLang);
    const t = Translate;

    const waybillWrapper = new WaybillV2Wrapper(waybill)

    const viewer_inn_or_pinfl = activeOrganization.pinfl || activeOrganization.inn;
    const isDraftWaybill = !waybill.status && content;

    const allowCancel = !isDraftWaybill && waybillWrapper.allowCancel(viewer_inn_or_pinfl)
    const allowExecutorReturnAccept = !isDraftWaybill && waybillWrapper.allowExecutorReturnAccept(viewer_inn_or_pinfl)
    const allowCustomerAccept = !isDraftWaybill && waybillWrapper.allowCustomerAccept(viewer_inn_or_pinfl)
    const allowCustomerDecline = !isDraftWaybill && waybillWrapper.allowCustomerDecline(viewer_inn_or_pinfl)
    const allowCreateInvoice = !isDraftWaybill && waybillWrapper.allowCreateInvoice(viewer_inn_or_pinfl);

    const isResponsiblePersonRejectedOrAccepted = !isDraftWaybill && waybillWrapper.isResponsiblePersonRejectedOrAccepted(viewer_inn_or_pinfl)
    const isResponsiblePersonReturnOrGiven = !isDraftWaybill && waybillWrapper.isResponsiblePersonReturnOrGiven(viewer_inn_or_pinfl)
    const isResponsiblePersonReturn = !isDraftWaybill && waybillWrapper.isResponsiblePersonReturn(viewer_inn_or_pinfl)

    const [eventMessage, setEventMessage] = useState(null);

    const loadWaybills = () => {
        if (viewer === VIEW_INBOX) {
            dispatch(loadWaybillV2CustomerAsync({...filter}));
            dispatch(getWaybillV2CustomerCount({...filter}));
        } else if (viewer === VIEW_OUTBOX) {
            dispatch(loadWaybillV2ExecutorAsync({...filter}));
            dispatch(getWaybillV2ExecutorCount({...filter}));
        } else if (viewer === VIEW_DRAFTS) {
            dispatch(loadWaybillV2DraftsAsync({...filter}));
            dispatch(getWaybillV2DraftsCount({...filter}));
        } else if (viewer === RESPONSIBLE_PERSON) {
            dispatch(loadWaybillV2ResponsiblePersonAsync({...filter}));
            dispatch(getWaybillV2ResponsiblePersonCount({...filter}));
        }
    }

    useEffect(() => {

        // responsible person

        const onRejectResponsiblePerson = EventBus.on(RESPONSIBLE_PERSON_REJECT_SUCCEED, () => {
            loadWaybill()
            loadWaybills()
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
            });
        })

        const onAcceptResponsiblePerson = EventBus.on(RESPONSIBLE_PERSON_ACCEPT_SUCCEED, () => {
            loadWaybill()
            loadWaybills()
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
            });
        })

        const onTillReturnResponsiblePerson = EventBus.on(RESPONSIBLE_PERSON_TILL_RETURN_SUCCEED, () => {
            loadWaybill()
            loadWaybills()
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
            });
        })

        const onGiveResponsiblePerson = EventBus.on(RESPONSIBLE_PERSON_GIVE_SUCCEED, () => {
            loadWaybill()
            loadWaybills()
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
            });
        })

        const onReturnResponsiblePerson = EventBus.on(RESPONSIBLE_PERSON_RETURN_SUCCEED, () => {
            loadWaybill()
            loadWaybills()
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
            });
        })


        const onSyncWaybillSuccessHandler = EventBus.on(WAYBILL_V2_SYNC_SUCCESS, () => {
            loadWaybill()
            loadWaybills()
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
                text: t(lang, 'roaming.invoice.alert.invoice_sync_success')
            });
        })

        // success
        const onConsignorCancelSuccessHandler = EventBus.on(CONSIGNOR_CANCEL_SUCCEED, () => {
            loadWaybill()
            loadWaybills()
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
            })
        })

        const onConsignorReturnAcceptSuccessHandler = EventBus.on(RETURN_ACCEPT_SUCCEED, () => {
            loadWaybill()
            loadWaybills()
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
            })
        })

        const onCustomerAcceptSuccessHandler = EventBus.on(CUSTOMER_ACCEPT_SUCCEED, () => {
            loadWaybill()
            loadWaybills()
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
            })
        })

        const onCustomerDeclineSuccessHandler = EventBus.on(CUSTOMER_DECLINE_SUCCEED, () => {
            loadWaybill()
            loadWaybills()
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
            })
        })

        // responsible person fail

        const onRejectResponsiblePersonFail = EventBus.on(RESPONSIBLE_PERSON_REJECT_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error?.message
            })
        })

        const onAcceptResponsiblePersonFail = EventBus.on(RESPONSIBLE_PERSON_ACCEPT_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error?.message
            })
        })

        const onTillReturnResponsiblePersonFail = EventBus.on(RESPONSIBLE_PERSON_TILL_RETURN_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error?.message
            })
        })

        const onGiveResponsiblePersonFail = EventBus.on(RESPONSIBLE_PERSON_GIVE_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error?.message
            })
        })

        const onReturnResponsiblePersonFail = EventBus.on(RESPONSIBLE_PERSON_RETURN_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error?.message
            })
        })

        // fail
        const onConsignorCancelFailHandler = EventBus.on(CONSIGNOR_CANCEL_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error?.message
            })
        })

        const onConsignorReturnAcceptFailHandler = EventBus.on(RETURN_ACCEPT_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error?.message
            })
        })

        const onCustomerAcceptFailHandler = EventBus.on(CUSTOMER_ACCEPT_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error?.message
            })
        })

        const onCustomerDeclineFailHandler = EventBus.on(CUSTOMER_DECLINE_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error?.message
            })
        })

        return () => {
            EventBus.remove(WAYBILL_V2_SYNC_SUCCESS, onSyncWaybillSuccessHandler)

            EventBus.remove(RESPONSIBLE_PERSON_REJECT_SUCCEED, onRejectResponsiblePerson)
            EventBus.remove(RESPONSIBLE_PERSON_ACCEPT_SUCCEED, onAcceptResponsiblePerson)
            EventBus.remove(RESPONSIBLE_PERSON_TILL_RETURN_SUCCEED, onTillReturnResponsiblePerson)
            EventBus.remove(RESPONSIBLE_PERSON_GIVE_SUCCEED, onGiveResponsiblePerson)
            EventBus.remove(RESPONSIBLE_PERSON_RETURN_SUCCEED, onReturnResponsiblePerson)
            EventBus.remove(CONSIGNOR_CANCEL_SUCCEED, onConsignorCancelSuccessHandler)
            EventBus.remove(RETURN_ACCEPT_SUCCEED, onConsignorReturnAcceptSuccessHandler)
            EventBus.remove(CUSTOMER_ACCEPT_SUCCEED, onCustomerAcceptSuccessHandler)
            EventBus.remove(CUSTOMER_DECLINE_SUCCEED, onCustomerDeclineSuccessHandler)

            EventBus.remove(RESPONSIBLE_PERSON_REJECT_FAILED, onRejectResponsiblePersonFail)
            EventBus.remove(RESPONSIBLE_PERSON_ACCEPT_FAILED, onAcceptResponsiblePersonFail)
            EventBus.remove(RESPONSIBLE_PERSON_TILL_RETURN_FAILED, onTillReturnResponsiblePersonFail)
            EventBus.remove(RESPONSIBLE_PERSON_GIVE_FAILED, onGiveResponsiblePersonFail)
            EventBus.remove(RESPONSIBLE_PERSON_RETURN_FAILED, onReturnResponsiblePersonFail)
            EventBus.remove(CONSIGNOR_CANCEL_FAILED, onConsignorCancelFailHandler)
            EventBus.remove(RETURN_ACCEPT_FAILED, onConsignorReturnAcceptFailHandler)
            EventBus.remove(CUSTOMER_ACCEPT_FAILED, onCustomerAcceptFailHandler)
            EventBus.remove(CUSTOMER_DECLINE_FAILED, onCustomerDeclineFailHandler)
        }

    }, []);

    const getItemMeasurementText = (id) => measurements.find(measurement => measurement.measureId === id) || null
    const totalAmount = waybill.roadway.product_groups[0]?.products.reduce((accumulator, item) => item.total + accumulator, 0)

    const getDeliveryTypeText = (id) => {
        const deliveryType = DeliveryTypeOptions.find(type => type.value === id);
        return deliveryType?.label;
    }

    const getTransportTypeText = (id) => {
        const transportType = transportTypeOptions.find(type => type.value === id);
        return transportType?.text;
    }

    const signWaybill = () => {
        setLoading(true);

        sendWaybillV2Async(currentCertificate, lang, {
            id: waybill.id,
            hashCode: content
        })
            .then(() => {
                navigate(`/roaming/v2-waybills/outbox`)
                toast.success(t(lang, 'roaming.waybill.send.toast.register_success'))
            })
            .catch(error => {
                setIsSending(false);
                setErrorMessage(error?.message || null);
                alertRef.current.scrollIntoView();
            })
            .finally(() => setLoading(false))
    }

    const innOrPinfLabel = (innOrPinfl) => {
        if (innOrPinfl?.length === 9) {
            return {
                name: "edi.common.company",
                inn: "edi.common.label.inn"
            }
        } else {
            return {
                name: "task.board.member.name",
                inn: "roaming.common.pinfl"
            }
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('roaming-waybill-v2-view'))
        return () => window.removeEventListener('scroll', () => scrollEvent('roaming-waybill-v2-view'))
    }, []);

    return (
        <Fragment>
            {errorMessage && <ErrorAlert ref={alertRef} errorMessage={errorMessage}/>}
            {eventMessage &&
                <WarningAlert text={eventMessage.text} title={eventMessage.title} variant={eventMessage.variant}/>}


            <Row className={'align-items-center justify-content-between my-3 position-sticky'} style={{top: '4rem'}}
                 id={'roaming-waybill-v2-view'}>
                <Col lg={"auto"} className={'d-flex gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'}
                            style={{width: 35, height: 35}}>
                        <IconArrowLeft/>
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "edi.navbar.routes.edo.waybill.v2")} № {waybill.info.number}</h5>
                        <div className={'d-flex align-items-center gap-2'}>
                            <span className={'second-text fs-9'}>
                                {waybill.created_at}
                            </span>
                            {waybill.status && <Badge bg={getWaybillV2Status(waybill.status).variant}
                                                      variant={'phoenix'}
                                                      className={'px-2 py-1 fs-9 text-uppercase text-decoration-none fw-medium border-0 rounded-4'}>
                                {t(lang, getWaybillV2Status(waybill.status).label)}
                            </Badge>}
                        </div>
                    </div>
                </Col>
                <Col lg={"auto"} className={'d-flex justify-content-end align-items-center gap-2'}>
                    {
                        isResponsiblePersonRejectedOrAccepted && <Fragment>
                            <WaybillV2ResponsiblePersonReject
                                id={waybill.id}
                                requestApi={waybillResponsiblePersonReject}
                                btnText={"roaming.waybill.v2.title.responsible.person.rejected"}
                                variant={"falcon-danger"}
                                mTitle={"roaming.waybill.v2.responsible.person.reject"}
                            />

                            <WaybillV2ResponsiblePersonAccept
                                id={waybill.id}
                                requestApi={waybillResponsiblePersonAccept}
                                btnText={"roaming.waybill.v2.title.responsible.person.accepted"}
                                variant={'falcon-success'}
                            />
                        </Fragment>
                    }

                    {
                        isResponsiblePersonReturnOrGiven && <Fragment>
                            <WaybillV2ResponsiblePersonTillReturn
                                id={waybill.id}
                                requestApi={waybillResponsiblePersonTillReturn}
                                btnText={"roaming.waybill.v2.title.responsible.person.returned"}
                                variant={'falcon-danger'}
                                mTitle={"roaming.waybill.v2.responsible.person.till.return"}
                            />

                            <WaybillV2ResponsiblePersonGive
                                id={waybill.id}
                                requestApi={waybillResponsiblePersonGive}
                                btnText={"roaming.waybill.send.additional_information.giver_fio"}
                            />
                        </Fragment>
                    }

                    {
                        isResponsiblePersonReturn && <WaybillV2ResponsiblePersonReturn
                            id={waybill.id}
                            requestApi={waybillResponsiblePersonReturn}
                            btnText={"roaming.waybill.v2.title.responsible.person.returned"}
                            variant={'falcon-danger'}
                            mTitle={"roaming.waybill.v2.responsible.person.till.return"}
                        />
                    }

                    {
                        allowCustomerAccept && <WaybillV2CustomerAccept
                            id={waybill.id}
                            requestApi={waybillAcceptAsync}
                            btnText={"roaming.waybill.v2.title.consignee.accepted"}
                        />
                    }

                    {
                        allowCustomerDecline && <WaybillV2CustomerDecline
                            id={waybill.id}
                            requestApi={waybillDeclineAsync}
                            btnText={"roaming.waybill.v2.title.consignee.rejected"}
                            variant={"falcon-danger"}
                        />
                    }

                    {
                        allowCancel && <WaybillV2ConsignorCancel
                            id={waybill.id}
                            requestApi={waybillCancelAsync}
                            btnText={'roaming.free_form_document.view.action.cancel'}
                            variant={"falcon-default"}
                        />
                    }

                    {
                        allowExecutorReturnAccept && <WaybillV2ReturnAccepted
                            id={waybill.id}
                            requestApi={waybillReturnAcceptAsync}
                            btnText={"roaming.free_form_document.view.action.accept"}
                        />
                    }

                    {isDraftWaybill && <WaybillV2DraftDelete id={waybill.id} hasTitle={true} size="sm"/>}
                    {!isDraftWaybill && <Fragment>
                        <WaybillV2Sync id={waybill.id}/>
                        <Link to={`/roaming/waybill-v2/send?waybill_id=${waybill.id}`}>
                            <IconButton size='sm'
                                        iconClassName={'text-primary-davr'}
                                        className={'btn-white-davr'}
                                        variant='phoenix-secondary'
                            >
                                {t(lang, 'roaming.invoice.copy')}
                            </IconButton>
                        </Link>
                    </Fragment>}

                    {allowCreateInvoice && <Link to={`/roaming/invoice/send?waybill_v2_id=${waybill.id}`}>
                        <IconButton size="sm"
                                    className={'btn-white-davr'}
                                    variant='phoenix-secondary'
                        >
                            {t(lang, 'edi.common.new_invoice_button')}
                        </IconButton>
                    </Link>}

                    {isDraftWaybill && (
                        <Fragment>
                            <IconButton
                                to={`/roaming/waybill-v2/edit/${waybill.id}`}
                                as={Link}
                                size="sm"
                                className={'btn-white-davr'}
                                iconClassName={'text-primary-davr'}
                                transform="shrink-3"
                                variant="phoenix-secondary"
                                icon="pencil"
                            >
                                <span className="d-none d-sm-inline-block ms-1">{t(lang, 'roaming.common.edit')}</span>
                            </IconButton>

                            <Link to={`/roaming/waybill-v2/send?draft_waybill_id=${waybill.id}`}>
                                <IconButton size='sm'
                                            variant='phoenix-secondary'
                                            className='btn-white-davr'
                                >
                                    {t(lang, 'roaming.invoice.copy')}
                                </IconButton>
                            </Link>

                            <SelectCertificateWrapperComponent
                                submitButton={
                                    <IconButton onClick={signWaybill} id="sendButton"
                                                size="sm" transform="shrink-3"
                                                name={'send'}
                                                className={'btn-primary-davr'}
                                                variant="phoenix-secondary"
                                                icon={faSignature}>
                                        {loading &&
                                            <Spinner className='align-middle me-2'
                                                     animation='border' role='switch'
                                                     size='sm'/>}
                                        {t(lang, 'roaming.common.send')}
                                    </IconButton>
                                }
                                openDialogButtonText={t(lang, 'roaming.common.send')}
                            />
                        </Fragment>
                    )}
                </Col>
            </Row>

            <Card className={'p-1'}>
                <Card.Body className={'pb-0'}>
                    <h2 className="fw-semi-bold text-center">
                        {t(lang, "roaming.dashboard.new.waybill.title")}<i>{waybill.notes}</i>
                    </h2>
                    <h5 className="fw-semi-bold text-center second-text">
                        <i>{waybill.id}</i>
                    </h5>
                </Card.Body>
                <Card.Footer as={Row} className={'border-0 justify-content-between fs-8 pb-3'}>
                    <Col xs={12} md={4} className={'px-1'}>
                        <h4 className={'fs-8 fw-medium second-text'}>{t(lang, 'warehouse.operation.item.common.info')}</h4>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span
                                        className={'second-text d-block text-truncate'}>{t(lang, "main.templates.type.shipment")}</span>
                                </Col>
                                <Col md={8}>
                                    <span
                                        className={'main-text d-block h-100 text-truncate'}>{waybill.info?.number}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span
                                        className={'second-text d-block text-truncate'}>{t(lang, "roaming.waybill.send.delivery_details_form.delivery_type")}</span>
                                </Col>
                                <Col md={8}>
                                    <span
                                        className={'main-text d-block h-100 text-truncate'}>{t(lang, getDeliveryTypeText(waybill?.delivery_type))}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>

                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span
                                        className={'second-text d-block text-truncate'}>{t(lang, "roaming.waybill.send.delivery_details_transport_type")}</span>
                                </Col>
                                <Col md={8}>
                                    <span
                                        className={'main-text d-block h-100 text-truncate'}>{t(lang, getTransportTypeText(waybill?.transport_type))}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>

                            {
                                waybill.roadway?.truck?.registration_number && <Row className={'py-1'}>
                                    <Col md={4}>
                                        <span
                                            className={'second-text d-block text-truncate'}>{t(lang, "roaming.waybill.view.transport")}</span>
                                    </Col>
                                    <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                        <span className={'me-1'}>{waybill.roadway?.truck?.model}</span>
                                             /
                                        <span className={'ms-1'}>{waybill.roadway?.truck?.registration_number}</span>
                                    </span>
                                        <hr className={'m-0 line-color'}/>
                                    </Col>
                                </Row>
                            }
                            {
                                !waybill.roadway?.truck?.registration_number && <Fragment>
                                    <Row className={'py-1'}>
                                        <Col md={4}>
                                            <span className={'second-text d-block text-truncate'}>
                                                {t(lang, "roaming.waybill.send.details_form.train_carriage_number")}
                                            </span>
                                        </Col>
                                        <Col md={8}>
                                            <span className={'main-text d-block h-100 text-truncate'}>
                                                {waybill.roadway?.trainCarriageNumber}
                                            </span>
                                            <hr className={'m-0 line-color'}/>
                                        </Col>
                                    </Row>
                                    <Row className={'py-1'}>
                                        <Col md={4}>
                                            <span className={'second-text d-block text-truncate'}>
                                                {t(lang, "roaming.waybill.send.details_form.train_direction")}
                                            </span>
                                        </Col>
                                        <Col md={8}>
                                            <span className={'main-text d-block h-100 text-truncate'}>
                                                {waybill.roadway?.trainDirection}
                                            </span>
                                            <hr className={'m-0 line-color'}/>
                                        </Col>
                                    </Row>
                                </Fragment>
                            }
                            {
                                (waybill.payer_contract_info?.date || waybill.client_contract_info?.date) && <Fragment>
                                    {waybill.client_contract_info?.date && <Row className={'py-1'}>
                                        <Col md={4}>
                                            <span className={'second-text d-block text-truncate'}>
                                                {t(lang, "roaming.waybill.v2.view.client.contract")}
                                            </span>
                                        </Col>
                                        <Col md={8}>
                                            <span className={'main-text d-block h-100 text-truncate'}>
                                                {waybill.client_contract_info?.number}
                                            </span>
                                            <hr className={'m-0 line-color'}/>
                                        </Col>
                                    </Row>}
                                    {waybill.payer_contract_info?.date && <Row className={'py-1'}>
                                        <Col md={4}>
                                            <span className={'second-text d-block text-truncate'}>
                                                {t(lang, "roaming.waybill.v2.view.payer.contract")}
                                            </span>
                                        </Col>
                                        <Col md={8}>
                                            <span className={'main-text d-block h-100 text-truncate'}>
                                                {waybill.payer_contract_info?.number}
                                            </span>
                                            <hr className={'m-0 line-color'}/>
                                        </Col>
                                    </Row>}
                                </Fragment>
                            }
                        </div>
                    </Col>
                    <Col xs={12} md={4} className={'px-1'}>
                        <h4 className={'fs-8 fw-medium second-text'}>{t(lang, 'roaming.waybill.v2.send.loading_point.title')}</h4>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.waybill.v2.send.region")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                        {waybill.roadway?.product_groups[0]?.loading_point?.region_name}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.waybill.v2.send.district")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                        {waybill.roadway?.product_groups[0]?.loading_point?.district_name}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.waybill.v2.send.village")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                        {waybill.roadway?.product_groups[0]?.loading_point?.village_name}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.waybill.v2.send.street")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                        {waybill.roadway?.product_groups[0]?.loading_point?.address || waybill.roadway?.product_groups[0]?.loading_point?.street}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.waybill.v2.send.lan")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                        {waybill.roadway?.product_groups[0]?.loading_point?.latitude}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.waybill.v2.send.lat")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                        {waybill.roadway?.product_groups[0]?.loading_point?.longitude}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} md={4} className={'px-1'}>
                        <h4 className={'fs-8 fw-medium second-text'}>{t(lang, 'roaming.waybill.v2.send.unloading_point.title')}</h4>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.waybill.v2.send.region")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                        {waybill.roadway?.product_groups[0]?.unloading_point?.region_name}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.waybill.v2.send.district")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                        {waybill.roadway?.product_groups[0]?.unloading_point?.district_name}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.waybill.v2.send.village")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                        {waybill.roadway?.product_groups[0]?.unloading_point?.village_name}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.waybill.v2.send.street")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                        {waybill.roadway?.product_groups[0]?.unloading_point?.address || waybill.roadway?.product_groups[0]?.unloading_point?.street}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.waybill.v2.send.lan")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                        {waybill.roadway?.product_groups[0]?.unloading_point?.latitude}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.waybill.v2.send.lat")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                        {waybill.roadway?.product_groups[0]?.unloading_point?.longitude}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Card.Footer>
            </Card>

            <Card className={'mt-3'}>
                <Card.Footer as={Row} className={'border-0 justify-content-between fs-8 pb-3'}>
                    <Col xs={12} md={6} className={'px-1 mb-2'}>
                        <h4 className={'fs-8 fw-medium second-text'}>{t(lang, 'roaming.waybill.v2.send.executor')}</h4>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.act_form_seller_name")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                        {waybill.executor?.name}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.common.inn")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                        {waybill.executor?.inn || waybill.executor?.inn_or_pinfl}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className={'px-1 mb-2'}>
                        <h4 className={'fs-8 fw-medium second-text'}>{t(lang, 'roaming.waybill.v2.send.customer')}</h4>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.act_form_seller_name")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                        {waybill.customer?.name}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.common.inn")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                       {waybill.customer?.inn || waybill.customer?.inn_or_pinfl}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className={'px-1 mb-2'}>
                        <h4 className={'fs-8 fw-medium second-text'}>{t(lang, 'roaming.waybill.v2.send.freight_forwarder')}</h4>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.act_form_seller_name")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                        {waybill.freight_forwarder?.name}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.common.inn")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                       {waybill.freight_forwarder?.inn || waybill.customer?.inn_or_pinfl}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className={'px-1 mb-2'}>
                        <h4 className={'fs-8 fw-medium second-text'}>{t(lang, 'roaming.waybill.v2.send.carrier')}</h4>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.act_form_seller_name")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                        {waybill.carrier?.name}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.common.inn")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                       {waybill.carrier?.inn || waybill.carrier?.inn_or_pinfl}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className={'px-1 mb-2'}>
                        <h4 className={'fs-8 fw-medium second-text'}>{t(lang, 'roaming.waybill.v2.send.responsible_person')}</h4>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.act_form_seller_name")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                        {waybill.responsible_person?.name}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.common.inn")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                       {waybill.responsible_person?.inn || waybill.responsible_person?.inn_or_pinfl}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    {waybill.roadway.driver?.inn &&  <Col xs={12} md={6} className={'px-1'}>
                        <h4 className={'fs-8 fw-medium second-text'}>{t(lang, 'roaming.waybill.v2.send.driver')}</h4>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.act_form_seller_name")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                        {waybill.roadway.driver?.name}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.common.inn")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                       {waybill.roadway.driver?.inn || waybill.roadway.driver?.inn_or_pinfl}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>}
                    {waybill.client_contract_info?.date &&  <Col xs={12} md={6} className={'px-1'}>
                        <h4 className={'fs-8 fw-medium second-text'}>{t(lang, 'roaming.waybill.v2.send.freight_forwarder.customer')}</h4>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.act_form_seller_name")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                        {waybill.client_contract_info?.name}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.common.inn")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                       {waybill.client_contract_info?.inn || waybill.client_contract_info?.inn_or_pinfl}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>}
                    {waybill.client_contract_info?.date &&  <Col xs={12} md={6} className={'px-1'}>
                        <h4 className={'fs-8 fw-medium second-text'}>{t(lang, 'roaming.waybill.view.customer')}</h4>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.act_form_seller_name")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                        {waybill.payer_contract_info?.name}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text d-block text-truncate'}>
                                        {t(lang, "roaming.common.inn")}
                                    </span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text d-block h-100 text-truncate'}>
                                       {waybill.payer_contract_info?.inn || waybill.payer_contract_info?.inn_or_pinfl}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>}

                </Card.Footer>
            </Card>

            <Card className={'mt-2 p-2'}>
                <Card.Body className={'p-0 border border-1 rounded-2'}>
                    <WaybillV2ViewTable waybill={waybill} />
                    <Row className="g-0 justify-content-end">
                        <Col xs="auto">
                            <Table borderless size="sm" className="fs--1 text-end">
                                <tbody>
                                <tr className="border-top">
                                    <th className="text-900">{t(lang, 'roaming.common.items.total')}:</th>
                                    <td className="fw-semi-bold">
                                        {numeral.formats[numberFormat].format(totalAmount)}
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <Row className={'align-items-stretch mt-3'}>
                {waybill?.histories?.map((item, index) => <Col
                    className={'h-100'}
                    key={index}
                    xs={6} md={3}
                >
                    <WaybillHistoryActions
                        fullName={item.certificate.full_name}
                        serialNumber={item.certificate.serial_number}
                        signingTime={item.certificate.signing_time}
                        action={getHistoriesActions(item.action)}
                    />
                </Col>)}
            </Row>

        </Fragment>
    );
};

export default WaybillV2;
