import React, {Fragment, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentCertificate} from "../../../../store/reducers/eimzo/eimzoReducer";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import checkPermission from "../../../../enum/permission/CheckPermission";
import {PermissionRoamingShipmentAccept} from "../../../../enum/permission/RoamingPermission";
import toastPermission from "../../../../enum/permission/ToastPermission";
import {customerAcceptWaybillAsync} from "../../../../store/reducers/roaming/waybill/roamingWaybillReducer";
import SelectCertificateWrapperComponent from "../../../common/SelectCertificateWrapperComponent";
import IconButton from "../../../IconButton";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {Button, Modal, Spinner} from "react-bootstrap";

const CustomerAcceptWaybill = ({id, ...props}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const lang = useSelector(selectLang);
    const t = Translate;

    const handleClose = () => setShow(false);
    const handleShow = () => {
        if (dispatch(checkPermission(PermissionRoamingShipmentAccept))) setShow(true)
        else dispatch(toastPermission())
    };

    const acceptWaybillHandler = () => {
        setLoading(true);

        customerAcceptWaybillAsync({
            id: id,
            certificate: currentCertificate,
        })
            .then(() => handleClose())
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }

    return (
        <Fragment>
            <SelectCertificateWrapperComponent permission={PermissionRoamingShipmentAccept}
                                               submitButton={
                                                   <IconButton
                                                       size="sm"
                                                       {...props}
                                                       icon={faCheck}
                                                       id="acceptWaybill"
                                                       iconClassName="me-1"
                                                       onClick={handleShow}
                                                       variant="phoenix-primary"
                                                       className={'fw-semi-bold'}
                                                   >{t(lang, 'roaming.common.accept')}</IconButton>
                                               }
                                               openDialogButtonText={t(lang, 'roaming.common.accept')}
            />

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, 'roaming.waybill.accept_waybill.modal.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>
                        {t(lang, 'roaming.waybill.accept_waybill.modal.description')}
                    </h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>{t(lang, 'roaming.common.close')}</Button>

                    <Button variant="primary" onClick={acceptWaybillHandler}>
                        {loading && <Spinner className="me-1" animation="border" role="status" size="sm"/>}
                        {t(lang, 'roaming.common.accept')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default CustomerAcceptWaybill;
