const EventBus = {
    on(event, callback) {
        const handler = (e) => callback(e.detail)
        document.addEventListener(event, handler)
        return handler
    },
    dispatch(event, data) {
        console.log('Event:', event, data);
        document.dispatchEvent(new CustomEvent(event, { detail: data }));
    },
    remove(event, handler) {
        document.removeEventListener(event, handler);
    },
}

export default EventBus
