import React, {Fragment, useEffect} from 'react';
import {useFormContext} from "react-hook-form";
import {selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {Col, Row, Form, Card} from "react-bootstrap";

const VerificationActDescription = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const { register, getFieldState, setValue, watch } = useFormContext();
    const errors = getFieldState('verificationActDoc').error || [];
    const {ownerName, ownerFizFio, partnerName, partnerFizFio} = watch()

    useEffect(() => {
        setValue('verificationActDoc.verificationActText', t(lang, "roaming.verification_act.add.verification_act.description.text.value", {
            owner: ownerName || "_________",
            fio_owner: ownerFizFio || "_________",
            partner: partnerName || "_________",
            partner_fio: partnerFizFio || "_________"
        }))
    }, [ownerName, ownerFizFio, partnerName, partnerFizFio, lang]);

    return (
        <Fragment>
            <h4 className={'fs-8 fw-medium second-text ms-3'}>{t(lang, "roaming.verification_act.add.verification_act.description.title")}</h4>

            <Card className="p-2 pb-3">
                <Row>
                    <Col sm="12">
                        <Form.Label>{t(lang, "roaming.verification_act.add.verification_act.description.text")}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={10}
                            {...register("verificationActDoc.verificationActText", {
                                required: "edi.common.forms.validations.is_required"
                            })}
                            className={
                                errors?.verificationActText?.message && "is-invalid"
                            }
                            placeholder={t(lang, "roaming.verification_act.add.verification_act.description.text")}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.verificationActText?.message)}
                        </Form.Control.Feedback>
                    </Col>
                </Row>
            </Card>
        </Fragment>
    );
};

export default VerificationActDescription;
