import React, {Fragment, useEffect} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {Controller, useFormContext} from "react-hook-form";
import {Button, Card, FloatingLabel, Form, Modal} from "react-bootstrap";
import classNames from "classnames";
import Cleave from "cleave.js/react";
const AddUserStepTwo = ({invalidConfirmCode}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const {trigger, formState: {errors}, control} = useFormContext()


    useEffect(() => {
        if (invalidConfirmCode)
            trigger();
    }, [invalidConfirmCode]);

    return (
        <Card className={'p-1'}>
            <Form.Group className="text-start">
                <Controller
                    control={control}
                    name='confirmCode'
                    render={({field}) => (
                        <FloatingLabel label={t(lang,"edi.common.users_organization_sms")}>
                            <Cleave
                                placeholder={t(lang,"edi.common.users_organization_sms")}
                                options={{numericOnly:true}}
                                onChange={(confirmCode) => field.onChange(confirmCode)}
                                className={classNames('form-control', {
                                    'is-invalid': errors?.confirmCode,
                                    'is-valid': Object.keys(errors).length > 0 && !errors?.confirmCode,
                                })}
                            />
                        </FloatingLabel>
                    )}
                    rules={{
                        required: t(lang, "edi.common.forms.validations.is_required"),
                        pattern: {
                            value: /^\d{6}$/,
                            message: t(lang, 'authentication.forgot-password.confirmation_code.format')
                        },
                        validate: value => {
                            if (invalidConfirmCode && invalidConfirmCode === value){
                                return t(lang,"authentication.forgot-password.confirmation_code.is_invalid")
                            }
                        }
                    }}
                />
                <Form.Control.Feedback type="invalid">
                    {errors?.confirmCode?.message}
                </Form.Control.Feedback>
            </Form.Group>
        </Card>
    );
};

export default AddUserStepTwo;
