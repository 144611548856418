import React, {Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {selectCount} from "../../../store/reducers/roaming/contracts/roamingContractReducer";
import IconButton from "../../IconButton";
import checkPermission from "../../../enum/permission/CheckPermission";
import {PermissionRoamingContractCreate} from "../../../enum/permission/RoamingPermission";
import {Link, useNavigate} from "react-router-dom";
import toastPermission from "../../../enum/permission/ToastPermission";
import {Col, Row} from "react-bootstrap";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import Badge from "../../common/Badge";
import PropTypes from "prop-types";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import IconPlus from "../../../assets/icon/IconPlus";
import {scrollEvent} from "../../../helpers/scrollEvent";
const ContractDataTableHeader = () => {
    const navigate = useNavigate()
    const lang = useSelector(selectLang)
    const t = Translate
    const contractCount = useSelector(selectCount)

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('roaming-contract-data-table-header'));
        return () => window.removeEventListener('scroll', () => scrollEvent('roaming-contract-data-table-header'))
    }, []);

    return (
        <Fragment>
            <Row id={'roaming-contract-data-table-header'} className="flex-between-center align-items-center my-3 position-sticky z-index-1" style={{top: '4rem'}}>
                <Col cols={'auto'} className={'d-flex gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'}
                            style={{width: 35, height: 35}}>
                        <IconArrowLeft/>
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.contracts.datatable.title")}: {contractCount}</h5>
                        <span className={'second-text fs-9'}>{t(lang, 'edi.navbar.routes.documents')}</span>
                    </div>
                </Col>
                <Col cols={'auto'} className={'d-flex align-items-center justify-content-end gap-2'}>
                    <IconButton
                        as={Link}
                        size="sm"
                        transform="shrink-3"
                        className="btn-davr px-3 py-0 bg-primary-davr"
                        to="/roaming/contract/send"
                    >
                        <IconPlus />
                        <span className="d-none d-sm-inline-block ms-1 fs-8 text-white text-nowrap fs-md-9">{t(lang, "roaming.contracts.datatable.register_contract")}</span>
                    </IconButton>
                </Col>
            </Row>
        </Fragment>
    );
};

ContractDataTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default ContractDataTableHeader;
